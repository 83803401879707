/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Input, Layout, Row, Select, Space, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListUserRequest } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchListUser, listUserSelector } from '../../redux/slice/userSlice';
import { mbTrim } from '../../lib/utils';
import { Constant, USER_GROUP } from '../../config/constant';
import { SearchOutlined } from '@ant-design/icons';
import icEdit from '../../assets/images/ic-edit.svg';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import IcPlus from '../../assets/images/ic-plus.svg';
import StatusFilter from '../../components/Modal/StatusFilter';
import '../../assets/styles/listUsers.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import EditUser from './EditUser/EditUser';
import CreateUser from './AddUser/CreateUser';
import { fetchAccount } from '../../redux/slice/auth/accountSlice';
import avatarDaily from '../../assets/images/avatarDefault/avatar-daily.png';
import avatarDriver from '../../assets/images/avatarDefault/avatar-tai-xe.png';
import avatarUser from '../../assets/images/avatarDefault/avatar-user.png';
import logoCarlink from '../../assets/images/avatarDefault/logo-carlink.png';
import TutorialsVideo from '../../components/TutorialsVideo';
import { handleGetUrlGuide } from '../../utils/helpers';
import { PERMISSIONS } from '../../config/permission';

function ListUser() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [status, setStatus] = useState<boolean>();
  const [keyword, setKeyword] = useState<ListUserRequest>({});
  const [page, setPage] = useState<number>(1);
  const [group, setGroup] = useState<string>();
  const [detailUser, setDetailUser] = useState({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { loading, users, pagination } = useAppSelector(listUserSelector);
  const accountUser = useAppSelector((state) => state.authAccount?.user);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);

  const handleDisabledAddUser = () => {
    if (accountUser.group === USER_GROUP.CARLINK) {
      const checkPermission = accountUser.permissions.includes(PERMISSIONS.CARLINK.manager_carlink_account);
      return checkPermission;
    }
    if (accountUser.group === USER_GROUP.CAR_COMPANY) {
      const checkPermission = accountUser.permissions.includes(PERMISSIONS.CAR_COMPANY.manager_company_account);
      return checkPermission;
    }
    if (accountUser.group === USER_GROUP.AGENT) {
      const checkPermission = accountUser.permissions.includes(PERMISSIONS.AGENT.manager_agent_account);
      return checkPermission;
    }
    if (accountUser.group === USER_GROUP.MAIN_AGENT) {
      const checkPermission = accountUser.permissions.includes(PERMISSIONS.MAIN_AGENT.manager_main_agent_account);
      return checkPermission;
    }
    return false;
  };
  const disabledAction = handleDisabledAddUser() ? false : true;

  const dispatch = useAppDispatch();

  const renderRowTable = (record: any) => {
    return record.status ? '' : 'bg-disabled';
  };
  const handleError = (e: any, record: any) => {
    e.target.onerror = null;
    if (record?.group === USER_GROUP.CAR_COMPANY || record?.group===USER_GROUP.MAIN_AGENT ||  record?.group===USER_GROUP.CARLINK) {
      return record?.type_account?.type === 'Tài xế' ? (e.target.src = avatarDriver) : (e.target.src = avatarUser);
    }
    if (record?.group === USER_GROUP.AGENT ) {
      return (e.target.src = avatarDaily);
    }
  };

  const handleChooseAvatar = (record: any) => {
    if (record?.group === USER_GROUP.CARLINK) {
      return logoCarlink;
    }
    if (record?.group === USER_GROUP.CAR_COMPANY) {
      return record?.type_account?.type === 'Tài xế' ? avatarDriver : avatarUser;
    }
    if (record?.group === USER_GROUP.AGENT) {
      return avatarDaily;
    }
    return avatarUser;
  };

  const columns: any = [
    // {
    //   title: "STT",
    //   key: "id",
    //   dataIndex: "id",
    //   sorter: (a: any, b: any) => a?.id - b?.id,
    //   render: (_: any, record: any) => {
    //     return (
    //       <Space size="middle">
    //         <FontAwesomeIcon
    //           icon={faCircle}
    //           className={`${record.status ? "green" : "danger"} p-1`}
    //           size="xs"
    //         />
    //         <Typography className="text-color">CL{record.id}</Typography>
    //       </Space>
    //     );
    //   },
    // },
    {
      title: 'STT',
      key: 'id',
      // sorter: (a: any, b: any) => {
      //   const indexA = (page - 1) * limit + users.indexOf(a) + 1;
      //   const indexB = (page - 1) * limit + users.indexOf(b) + 1;
      //   return indexA - indexB;
      // },
      render: (_: any, record: any, index: number) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <FontAwesomeIcon icon={faCircle} className={`${record.status ? 'green' : 'danger'} p-1`} size="xs" />
            <Typography className="text-color">{(page - 1) * limit + index + 1}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Tên truy cập',
      dataIndex: 'username',
      key: 'username',
      sorter: (a: any, b: any) => a.username?.localeCompare(b.username),
    },
    {
      title: 'Tên hiển thị',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      render: (text: any, record: any) => {
        // const valueAvatar = record?.group === USER_GROUP.CARLINK ? avatarLogo : avatarDefault
        return (
          <div className="content-user-name">
            <Image
              src={record?.avatar ? record?.avatar : handleChooseAvatar(record)}
              onError={(e: any) => handleError(e, record)}
            />{' '}
            <Typography className="text-color">{text}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Loại tài khoản',
      dataIndex: 'type_account',
      key: 'type_account',
      render: (text: any, record: any) => (
        <>
          {text?.name ? (
            <Typography className="text-color">{`${text?.name} (${text?.type})`} </Typography>
          ) : (
            <Typography className="text-color">{text} </Typography>
          )}
        </>
      ),
    },
    ...(accountUser.group === USER_GROUP.CAR_COMPANY
      ? [
          {
            title: 'Văn phòng nhà xe',
            dataIndex: 'office_name',
            key: 'office_name',
            // align: "center",
          },
        ]
      : []),
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_: any, record: any) => (
        <>
          <Space size="middle" onClick={() => showModal(record)} style={{ cursor: 'pointer' }}>
            <Image src={icEdit} preview={false} />
          </Space>
        </>
      ),
    },
  ];

  const showModal = (item: any) => {
    if (item?.driver?.id) {
      setDetailUser({
        ...item,
        permissions: ['driver'],
        driver_id: item?.driver?.id,
      });
    } else {
      setDetailUser(item);
    }
    setOpen(true);
  };

  const openModalCreateUser = () => {
    setOpenModal(true);
  };

  const onSearch = (value: ListUserRequest) => {
    form.setFields([{ name: 'keyword', value: mbTrim(value.keyword) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(form.getFieldsValue(['keyword']));
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const handleChangeStatus = (value: boolean | undefined) => {
    setStatus(value);
  };

  const callListUserApi = () => {
    dispatch(
      fetchListUser({
        ...keyword,
        group,
        page,
        status,
        limit,
      }),
    );
  };

  useEffect(() => {
    callListUserApi();
  }, [dispatch, keyword, page, status, limit, group]);

  const onChangeGroup = (value: string) => {
    setGroup(value);
  };

  useEffect(() => {
    dispatch(fetchAccount());
  }, []);

  const chooseTypeGroup =
    accountUser.group === USER_GROUP.CARLINK
      ? [
          { value: '', label: t('Tất cả tài khoản') },
          {
            value: USER_GROUP.CARLINK,
            label: t('Tài khoản Carlink'),
          },
          {
            value: USER_GROUP.CAR_COMPANY,
            label: t('Tài khoản nhà xe'),
          },
          {
            value: USER_GROUP.MAIN_AGENT,
            label: t('Tài khoản tổng đại lý'),
          },
        ]
      : [
          { value: '', label: t('Tất cả tài khoản') },
          {
            value: USER_GROUP.CAR_COMPANY,
            label: t('Tài khoản nhà xe'),
          },
          {
            value: USER_GROUP.AGENT,
            label: t('Tài khoản đại lý'),
          },
        ];

  return (
    <Layout className="min-vh-100 wrapper_list_users">
      <Card>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        <Row className="fw-500 mt-4 mx-3">
          <Button
            type="primary"
            className={'btn-success btn-add'}
            onClick={openModalCreateUser}
            size="large"
            disabled={disabledAction}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t('Thêm tài khoản mới')}
          </Button>
        </Row>

        <Form
          className="mg-16 mt-3 mx-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          onFinish={onSearch}
          colon={false}
          style={{ position: 'relative' }}
        >
          <Form.Item className="label-search mb-0" labelAlign="left" labelCol={{ md: 0 }}>
            <Row gutter={10}>
              <Col xs={24} sm={12} md={10} lg={10} xl={7} xxl={5}>
                <Form.Item name="keyword">
                  <Input
                    placeholder="Nhập ID/Tên truy cập/tên hiển thị để tìm kiếm"
                    size="large"
                    className="input-h36 padding-normal input-search-user"
                    autoComplete="off"
                    prefix={
                      <SearchOutlined
                        style={{
                          color: '#435869',
                          fontSize: '14.5px',
                          marginRight: 5,
                        }}
                      />
                    }
                  />
                </Form.Item>
              </Col>
              {accountUser.group !== USER_GROUP.AGENT && (
                <Col>
                  <Form.Item name="group">
                    <Select
                      className="input-h36 input-search-user"
                      placeholder={t('Tất cả tài khoản')}
                      size="large"
                      onChange={onChangeGroup}
                      style={{ width: 200 }}
                      options={chooseTypeGroup}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col className="text-center">
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  className={'carlink-btn btn-search btn-small padding-normal'}
                >
                  {t('Tìm kiếm')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Space size={10} align="center" style={{ position: 'absolute', right: 0, top: 7 }}>
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 10)} />
          </Space>
        </Form>

        <Table
          locale={{
            triggerDesc: 'Sắp xếp tăng dần',
            triggerAsc: 'Hủy sắp xếp',
            cancelSort: 'Sắp xếp giảm dần',
          }}
          bordered={false}
          loading={loading}
          rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          // scroll={{ y: 600 }}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={users?.length > 0 ? users : []}
          className="mx-3"
        />
      </Card>
      <>
        {open ? (
          <EditUser
            listTutorials={listTutorials}
            callListUserApi={callListUserApi}
            setOpen={setOpen}
            open={open}
            detailUser={detailUser}
            setDetailUser={setDetailUser}
            infoUser={accountUser}
            disabledAction={disabledAction}
          />
        ) : (
          <></>
        )}
      </>
      <>
        {openModal ? (
          <CreateUser
            listTutorials={listTutorials}
            callListUserApi={callListUserApi}
            setOpen={setOpenModal}
            open={openModal}
            infoUser={accountUser}
            disabledAction={disabledAction}
          />
        ) : (
          <></>
        )}
      </>
    </Layout>
  );
}

export default ListUser;
