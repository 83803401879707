import React from "react";
import { IconProps } from "../../types/common";

const IconLock = ({ className, width, height }: IconProps) => {
  return (
    <svg
      className={className}
      width={width || 24}
      height={height || 24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.16732 5.33366V5.83366H4.66732H4.00065C3.54346 5.83366 3.16732 6.2098 3.16732 6.66699V13.3337C3.16732 13.7908 3.54346 14.167 4.00065 14.167H12.0007C12.4578 14.167 12.834 13.7908 12.834 13.3337V6.66699C12.834 6.2098 12.4578 5.83366 12.0007 5.83366H11.334H10.834V5.33366V4.00033C10.834 2.43647 9.56451 1.16699 8.00065 1.16699C6.43679 1.16699 5.16732 2.43647 5.16732 4.00033V5.33366ZM10.5007 5.33366V5.83366H10.0007H6.00065H5.50065V5.33366V4.00033C5.50065 2.61752 6.61784 1.50033 8.00065 1.50033C9.38346 1.50033 10.5007 2.61752 10.5007 4.00033V5.33366ZM9.83398 10.0003C9.83398 11.0098 9.01013 11.8337 8.00065 11.8337C6.99117 11.8337 6.16732 11.0098 6.16732 10.0003C6.16732 8.99085 6.99117 8.16699 8.00065 8.16699C9.01013 8.16699 9.83398 8.99085 9.83398 10.0003Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default IconLock;
