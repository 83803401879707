import { Button, Checkbox, Flex, Form, Image, Input, Modal, Row, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useState } from 'react';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';

const ModalExtraConfirm: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  loading,
  setIsShowAlert,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  const handleChangeAlert = (e: CheckboxChangeEvent) => {
    setIsShowAlert(e.target.checked);
  };

  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      width={417}
      className="modal-extra-confirm"
    >
      <Flex className="mt-3 mb-3">
        <Image preview={false} src={IconExclamationCircle} alt="Icon Exclamation Circle" style={{ width: '20px' }} />
        <Typography.Text className="pl-15">
          <h5 className="fs-18 fw-700">Tăng cường chuyến</h5>
          <p className="fs-16 fw-500" style={{ color: '#435869' }}>
            Nếu bạn muốn tăng cường chuyến với một loại xe khác, vui lòng chuyển tới lịch chạy và tạo lịch chạy theo
            ngày.
          </p>
        </Typography.Text>
      </Flex>
      <Form.Item name="not_alert" className="d-none">
        <Input />
      </Form.Item>

      <Checkbox className="fs-16 fw-500" style={{ color: '#435869' }} onChange={handleChangeAlert}>
        Không thông báo lại
      </Checkbox>
      <Row className="d-flex justify-content-end" style={{ marginTop: '36px' }}>
        <>
          <Button
            key="ok"
            type="primary"
            loading={loading}
            className="w-100 h-btn-48 btn-success"
            htmlType="submit"
            onClick={() => modalHandleOk()}
          >
            Đi tới lịch chạy
          </Button>
          <Button
            loading={loading}
            key="cancel"
            className=" w-100 h-btn-48 me-3 mt-1 btn-know-it"
            onClick={() => modalHandleCancel()}
          >
            Tôi đã hiểu
          </Button>
        </>
      </Row>
    </Modal>
  );
};

export default ModalExtraConfirm;
