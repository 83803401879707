import { Col, Form, Modal, Row, Spin, Tag } from "antd";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toast } from "react-toastify";
import { putInfoRegisterApi } from "../../api/listRegister";
import { registerConfirmAPI } from "../../api/notify";
import { fetchNotification } from "../../redux/slice/notify/notificationSlice";
import { fetchCountRegistrations } from "../../redux/slice/register/countRegistrations";

const InfoSignUp = ({
  open,
  setOpen,
  detailInfo,
  fetchDataRegisterApi,
}: any) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);

  const handleOk = () => {
    form.submit();
    createRegisterApi();
  };

  const createRegisterApi = async () => {
    setLoading(true);
    const valueBody = {
      id: accountDetail?.id,
      username: accountDetail?.username,
      name: accountDetail?.name,
    };
    try {
      const response = await putInfoRegisterApi(
        detailInfo?.registration_id,
        valueBody
      );

      if (response?.status === 200) {
        toast.success("Thao tác thành công");
        fetchDataRegisterApi();
        const resConfirm = await registerConfirmAPI({
          register_id: detailInfo?.registration_id,
          type: "software",
        });
        if (resConfirm.status === 200) {
          let query = `page=1&limit=20`;
          dispatch(fetchNotification({ query }));
          dispatch(fetchCountRegistrations());
        }
        handleCancel();
      }
    } catch (error) {
      toast.error("Thao tác thất bại");
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Spin spinning={loading}>
      <Modal
        title="Thông tin đăng ký dùng thử phần mềm"
        open={open}
        onOk={handleOk}
        maskClosable={false}
        width={840}
        cancelText="Đóng"
        okText="Nhận xử lý"
        onCancel={handleCancel}
        className="wrapper-modal-info-register"
        footer={(_, { OkBtn, CancelBtn }) => (
          <div className="info-btn-register">
            <CancelBtn />
            {detailInfo?.handled_status === 0 && <OkBtn />}
          </div>
        )}
      >
        <Col style={{ marginTop: 20 }}>
          <Row>
            <Col className="name-info">
              {detailInfo?.registration_source === "agent"
                ? "Tên đại lý:"
                : "Tên công ty:"}
            </Col>
            <Col className="content-info">
              {detailInfo?.contact_company_name}
            </Col>
          </Row>
          <Row>
            <Col className="name-info">Quy mô:</Col>
            <Col className="content-info">
              {detailInfo?.contact_company_size}
            </Col>
          </Row>
          <Row>
            <Col className="name-info">Địa chỉ:</Col>
            <Col className="content-info">{detailInfo?.contact_address}</Col>
          </Row>
          <Row>
            <Col className="name-info">Người liên hệ:</Col>
            <Col className="content-info">{detailInfo?.contact_name}</Col>
          </Row>
          <Row>
            <Col className="name-info">Điện thoại:</Col>
            <Col className="content-info">{detailInfo?.contact_phone}</Col>
          </Row>
          <Row>
            <Col className="name-info">Email:</Col>
            <Col className="content-info">{detailInfo?.contact_email}</Col>
          </Row>
          <Row style={{ display: "flex" }}>
            <Col className="name-info">Lời nhắn:</Col>
            <Col className="content-info">{detailInfo?.contact_message}</Col>
          </Row>
          <Row>
            <Col className="name-info">Trạng thái:</Col>
            <Col className="content-info">
              <div
                className={
                  detailInfo?.handled_status === 0
                    ? "handled_status_tag2"
                    : "handled_status_tag"
                }
              >
                {detailInfo?.handled_status === 0 ? "Chưa xử lý" : "Đã xử lý"}
              </div>
            </Col>
          </Row>
          {detailInfo?.username_handle && (
            <Row>
              <Col className="name-info">Người xử lý:</Col>
              <Col className="content-info">{detailInfo?.username_handle}</Col>
            </Row>
          )}
        </Col>
      </Modal>
    </Spin>
  );
};

export default InfoSignUp;
