import {
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  type FormProps,
} from 'antd';
import { useEffect, useState } from 'react';

import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';
import icEdit from '../../assets/images/ic-edit.svg';
import IcPlus from '../../assets/images/ic-plus.svg';
import IcUnLock from '../../assets/images/ic-unlock.svg';
import icNope from '../../assets/images/trips/ic-nope.svg';
import TabsFilterStatus from '../../components/TabsFilterStatus/TabsFilterStatus';
import { Constant, TabType } from '../../config/constant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAllListRoute } from '../../redux/slice/tickets/ticketsSlice';
import { handleGetUrlGuide } from '../../utils/helpers';
import './tickets.scss';

import type { GetProps } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TutorialsVideo from '../../components/TutorialsVideo';
import { formattedPrice, getPlatformPromotion } from '../../lib/utils';
import {
  createPromotion,
  getListPromotion,
  updatePromotion,
  updateStatusPromotion,
} from '../../redux/slice/promotions/promotionSlice';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

dayjs.extend(customParseFormat);
type FieldTypeForm = {
  range_date: string[];
  title: string;
  route_id: number[];
  platform: string;
  discount: string;
  use_valid: string;
};

const { RangePicker } = DatePicker;

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current < dayjs().endOf('day');
};
const platFormItem = [
  {
    id: 1,
    value: 'all',
    label: 'Tất cả nền tảng',
  },
  {
    id: 2,
    value: 'web',
    label: 'Nền tảng Web',
  },
  {
    id: 3,
    value: 'mobile',
    label: 'Nền tảng Mobile',
  },
];

function SettingPromotion() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [statusDetail, setStatusDetail] = useState(false);
  const [idTicketUpdate, setIdTicketUpdate] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<TabType>(TabType.ALL);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const [form] = Form.useForm();
  const watchRouteId = Form.useWatch('route_id', form);

  const pagination = useAppSelector((state) => state?.tickets?.pagination);
  const { listPromotion } = useAppSelector((state) => state.listPromotion);
  const loadingListTickets = useAppSelector((state) => state?.tickets?.loading);

  const onSubmit: FormProps<FieldTypeForm>['onFinish'] = async (values) => {
    const payload = {
      route_id: values?.route_id?.map((route: any) => route?.id),
      title: values?.title,
      platform: values.platform,
      use_valid: values.use_valid,
      discount: values.discount,
      start_date: dayjs(values?.range_date?.[0]).format('YYYY-MM-DD'),
      end_date: dayjs(values?.range_date?.[1]).format('YYYY-MM-DD'),
    };
    if (isUpdate) {
      const response = await dispatch(updatePromotion({ ...payload, id: idTicketUpdate }));
      if (response?.payload?.success) {
        dispatch(
          getListPromotion({
            page,
            limit,
            status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
          }),
        );
        form.resetFields();
        setOpenCreate(false);
      } else {
        if (response?.payload?.response?.data?.errors) {
          const listErrors = response?.payload?.response?.data?.errors;
          form.setFields(
            Object.keys(listErrors).map((fieldName) => ({
              name: fieldName,
              errors: [listErrors[fieldName]],
            })),
          );
        }
      }
    } else {
      const response = await dispatch(createPromotion(payload));
      if (response?.payload?.success) {
        dispatch(
          getListPromotion({
            page,
            limit,
            status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
          }),
        );
        form.resetFields();
        setOpenCreate(false);
      } else {
        if (response?.payload?.response?.data?.errors) {
          const listErrors = response?.payload?.response?.data?.errors;
          form.setFields(
            Object.keys(listErrors).map((fieldName) => ({
              name: fieldName,
              errors: [listErrors[fieldName]],
            })),
          );
        }
      }
    }
  };

  const handleOkModal = async () => {
    setIsModalVisible(false);
    if (statusDetail) {
      setStatusDetail(false);
    } else {
      setStatusDetail(true);
    }
    const response = await dispatch(updateStatusPromotion({ id: idTicketUpdate, status: !statusDetail }));
    if (response?.payload?.success) {
      dispatch(
        getListPromotion({
          page,
          limit,
          status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
        }),
      );
      setOpenCreate(false);
      form.resetFields();
    } else {
      if (response?.payload?.response?.data?.errors) {
        const listErrors = response?.payload?.response?.data?.errors;
        form.setFields(
          Object.keys(listErrors).map((fieldName) => ({
            name: fieldName,
            errors: [listErrors[fieldName]],
          })),
        );
      }
    }
  };
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const cancelModal = () => {
    setOpenCreate(false);
    setIsUpdate(false);
    form.resetFields();
  };

  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      form.scrollToField(firstErrorField.name);
    }
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = (record: any) => {
    setIdTicketUpdate(record?.id);
    setOpenCreate(true);
    setIsUpdate(true);
    form.setFieldsValue({
      title: record?.title,
      range_date: [dayjs(record?.start_date), dayjs(record?.end_date)],
      route_id: record?.route?.map((routeItem: any) => ({
        id: routeItem?.route_id,
        name: `${routeItem?.depart_point}-${routeItem?.arrive_point}`,
      })),
      platform: record.platform,
      discount: record.discount,
      use_valid: record?.use_valid,
    });
    setStatusDetail(record?.status);
  };

  const columns: any = [
    {
      title: 'Tên chương trình',
      dataIndex: 'title',
      key: 'title',
      render: (_: any, record: any) => (
        <Space size="middle">
          {record.status ? (
            <FontAwesomeIcon icon={faCircle} className="green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="danger" size="xs" />
          )}
          <Typography className="text-color">{record.title}</Typography>
        </Space>
      ),
    },
    // {
    //   title: 'Tuyến xe áp dụng',
    //   dataIndex: '',
    //   key: 'route',
    //   render: (record: any) => (
    //     <>
    //       {record?.route?.map((routeItem: any, index: number) => (
    //         <Typography.Text key={index}>
    //           {`${routeItem?.depart_point} - ${routeItem?.arrive_point}`}
    //           {index < record.route.length - 1 && <br />}
    //         </Typography.Text>
    //       ))}
    //     </>
    //   ),
    // },
    {
      title: 'Nền tảng áp dụng',
      dataIndex: 'platform',
      key: 'route',
      render: (text: string) => (
        <>
          <Typography.Text>{text ? getPlatformPromotion(text) : ''}</Typography.Text>
        </>
      ),
    },
    {
      title: 'Từ ngày',
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: (a: any, b: any) => moment(a.start_date).unix() - moment(b.start_date).unix(),
      render: (date: any) => moment(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Đến ngày',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '10%',
      render: (date: any) => moment(date).format('DD/MM/YYYY'),
      sorter: (a: any, b: any) => moment(a.end_date).unix() - moment(b.end_date).unix(),
    },
    {
      title: 'Giảm',
      dataIndex: 'discount',
      key: 'discount',
      render: (discount: string) => (
        <>
          <Typography.Text>{`${discount ? `${formattedPrice(discount)} VNĐ` : ''}`}</Typography.Text>
        </>
      ),
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Chỉnh sửa">
            <button className="Table__button__edit" onClick={() => handleUpdate(record)}>
              <Image width={17} height={17} className="icon-edit mr-20" src={icEdit} preview={false} />
            </button>
          </Tooltip>
        </Space>
      ),
      align: 'center',
      width: '120px',
    },
  ];

  const routes = useAppSelector((state) => state?.tickets?.listRoute);

  const renderOptionsRoute = () => {
    const optionsRoute = routes?.length
      ? routes?.map((item) => ({
          value: item.id,
          label: `${item.depart_point} - ${item.arrive_point}`,
        }))
      : [];
    return optionsRoute?.filter((item) => !watchRouteId?.find((route: any) => route?.id === item?.value));
  };

  useEffect(() => {
    dispatch(getAllListRoute({ keyword: '' }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getListPromotion({
        page,
        limit,
        status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
      }),
    );
  }, [dispatch, activeTab, limit, page]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const filterOptionRoute = (
    input: string,
    option?: {
      label: string;
      value: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const handleChangeTab = (key: TabType) => {
    setActiveTab(key);
    setPage(1);
  };
  return (
    <Layout className="min-vh-70 list-ticket">
      <Card>
        <TabsFilterStatus activeTab={activeTab} onChangeTab={handleChangeTab} />
        <Row className="fw-500 mt-4 mx-3 mb-3 d-flex justify-content-between">
          <Button type="primary" className={'btn-success height-btn-40'} onClick={() => setOpenCreate(true)}>
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t('Thêm chương trình khuyến mại')}
          </Button>
          {/* <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 5)} /> */}
        </Row>
        <Table
          locale={{
            triggerDesc: 'Sắp xếp tăng dần',
            triggerAsc: 'Hủy sắp xếp',
            cancelSort: 'Sắp xếp giảm dần',
          }}
          dataSource={listPromotion?.length > 0 ? listPromotion : []}
          rowKey="id"
          rowClassName={(record: any) => (record?.status ? '' : 'bg-disabled')}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          className="mx-3 list-trips"
          loading={loadingListTickets}
        />
        <Modal
          centered
          className="create-ticket-setting Modal-ticket"
          open={openCreate}
          onOk={() => setOpenCreate(false)}
          onCancel={() => cancelModal()}
          styles={{
            body: { maxHeight: '90vh', overflowY: 'auto', overflowX: 'hidden' },
          }}
          width={900}
          footer={[]}
          maskClosable={false}
        >
          <Flex align="center" justify="space-between" className="mr-20">
            <Typography.Title className="fs-20 fw-500 mb-0">
              Chi tiết thông tin chương trình khuyến mại
            </Typography.Title>
            {/* <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 5)} /> */}
          </Flex>

          <Form
            className="mg-16 mt-30 fw-500 text-color-default"
            form={form}
            labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
            requiredMark={false}
            initialValues={{ platform: 'all' }}
            onFinish={onSubmit}
            onFieldsChange={(changedFields, allFields) => {
              scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
            }}
            colon={false}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Col>
                  <span className="required">{t(`Tên chương trình`)}</span>
                  <Form.Item<FieldTypeForm>
                    name="title"
                    className="form_reason"
                    rules={[{ required: true, message: 'Vui lòng chọn tên chương trình' }]}
                  >
                    <Input placeholder={t('Nhập tên chương trình')} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span className="required">{t(`Nền tảng áp dụng`)}</span>
                  <Form.Item<FieldTypeForm>
                    name="platform"
                    className="form_reason"
                    rules={[{ required: true, message: 'Vui lòng chọn tên chương trình' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Chọn nền tảng áp dụng"
                      optionFilterProp="children"
                      filterOption={filterOption}
                      options={platFormItem}
                      defaultValue={'all'}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                  <Form.List name="route_id">
                    {(fields, { add, remove }) => (
                      <>
                        <div className="form__route">
                          <span className="required">Tuyến xe áp dụng</span>
                          <Form.Item
                            className="form-item-name"
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng chọn tuyến xe',
                              },
                            ]}
                          >
                            <Select
                              className="input-h36"
                              showSearch
                              placeholder="Chọn tuyến"
                              filterOption={filterOptionRoute}
                              size="large"
                              style={{ width: '100%' }}
                              options={renderOptionsRoute()}
                              optionRender={(option) => (
                                <Space className="w-100 fw-500 text-color-default">
                                  <Image width={28} height={26} src={icPlus} preview={false} />
                                  <span>{option.data.label}</span>
                                </Space>
                              )}
                              onChange={(value, option: any) => {
                                add({
                                  id: value,
                                  name: option?.label,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div style={{ padding: '0 8px' }}>
                          {fields.map((field) => {
                            return (
                              <Flex className="form__route__item" align="center" key={field.key}>
                                <Button
                                  type="text"
                                  onClick={() => remove(field.name)}
                                  className="p-0 h-auto"
                                  style={{ marginRight: '8px' }}
                                >
                                  <Image
                                    width={37}
                                    height={37}
                                    src={icCancel}
                                    preview={false}
                                    style={{ width: '100%' }}
                                  />
                                </Button>
                                <Form.Item className="d-none" name={[field.name, 'id']}>
                                  <Input disabled />
                                </Form.Item>
                                <Form.Item className="form-name d-flex flex-grow-1" name={[field.name, 'name']}>
                                  <Input disabled />
                                </Form.Item>
                              </Flex>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </Form.List>
                </Col> */}
              </Col>
              <Col span={12}>
                <Col span={24} className="time-approve">
                  <span className="required">Thời gian áp dụng</span>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập thời gian áp dụng',
                      },
                    ]}
                    className="fw-500 fs-16 text-color-default form_range_date"
                    name="range_date"
                  >
                    <RangePicker
                      placeholder={['Từ ngày', 'Đến ngày']}
                      disabledDate={disabledDate}
                      format="DD/MM/YYYY"
                      className="w-100 min-h-36"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="price-promote">
                  <span className="required">Giá trị khuyến mại cho một đơn hàng</span>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập giá trị khuyến mại',
                      },
                    ]}
                    className="fw-500 fs-16 text-color-default form_range_date"
                    name="discount"
                  >
                    <InputNumber
                      className="w-100 min-h-36"
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      addonAfter={
                        <span style={{ color: '#949494' }} className="fs-16 font-normal">
                          VNĐ
                        </span>
                      }
                      parser={(value) => value?.replace(/[^0-9]/g, '') as unknown as number}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Col span={24} className="required">
                  <span className="required">Số lần áp dụng trên một tài khoản</span>
                  <Form.Item<FieldTypeForm>
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập số lần áp dụng trên 1 tài khoản',
                      },
                    ]}
                    className="fw-500 fs-16 form-item required"
                    name="use_valid"
                  >
                    <InputNumber
                      suffix={<>lần</>}
                      className="w-100"
                      controls={false}
                      placeholder="Nhập số lần áp dụng"
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {/* <p className="text-danger" style={{ paddingLeft: 8 }}>
              *Giá trị khuyến mại chỉ được áp dụng 1 lần cho mỗi tài khoản
            </p> */}

            <div className={`z-index1200 mt-5 d-flex ${isUpdate ? 'justify-content-between' : 'justify-content-end'}`}>
              {isUpdate && (
                <div className="d-flex justify-content-start">
                  {statusDetail ? (
                    <Button
                      className="btn-block-red h-btn-48"
                      danger
                      type="primary"
                      htmlType="button"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Image src={icNope} preview={false} className="pb-1 pe-2" />
                      {t('Khóa chương trình khuyến mại')}
                    </Button>
                  ) : (
                    <Button
                      className="btn-active-green h-btn-48"
                      type="primary"
                      htmlType="button"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Image src={IcUnLock} preview={false} className="pb-1 pe-2" />
                      {t('Mở khóa chương trình khuyến mại')}
                    </Button>
                  )}
                </div>
              )}
              <div className={`${isUpdate ? '' : ''}`}>
                <Button
                  key="cancel"
                  className={`${isUpdate ? 'w-160' : 'w-200'} h-btn-48`}
                  onClick={() => cancelModal()}
                >
                  {t('Đóng')}
                </Button>
                <Button
                  key="ok"
                  type="primary"
                  style={{ marginLeft: '16px' }}
                  className={`${isUpdate ? 'w-160' : 'w-200'} mr-0 ml-1 h-btn-48 btn-success`}
                  htmlType="submit"
                >
                  {t('Lưu')}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          title={
            <Flex align="center">
              <Image preview={false} width={22} height={22} src={IconExclamationCircle} alt="Icon Exclamation Circle" />
              <p style={{ marginLeft: '16px', marginBottom: 0 }}>
                {statusDetail ? 'Khóa chương trình khuyến mại' : 'Mở khóa chương trình khuyến mại'}
              </p>
            </Flex>
          }
          open={isModalVisible}
          onCancel={handleCancelModal}
          onOk={handleOkModal}
          okText={statusDetail ? <p>Khóa ngay</p> : <p>Mở khóa ngay</p>}
          cancelText="Trở lại"
        >
          <p style={{ paddingLeft: '38px' }}>
            {statusDetail
              ? 'Bạn đang thao tác khóa chương trình khuyến mại này, bạn có chắc chắn muốn khóa?'
              : 'Bạn đang thao tác mở khóa chương trình khuyến mại này, bạn có chắc chắn muốn mở khóa?'}
          </p>
        </Modal>
      </Card>
    </Layout>
  );
}

export default SettingPromotion;
