import { getListReportDetailMainAgentAPI, getListReportDetailMainAgentExcelAPI } from '../../../api/report';
import { ParamsReport, ReportDetailState, ValueOptions } from '../../../types/report';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Constant } from '../../../config/constant';
import { toast } from 'react-toastify';

const limit_pagination: any = window.localStorage.getItem('limit_pagination');
const limitPagination = isNaN(Number(limit_pagination)) ? Constant.PAGE_SIZE : Number(limit_pagination);

const initialState = {
  listReport: [],
  loading: false,
  pagination: {
    total: 0,
    per_page: limitPagination,
    current_page: Constant.DEFAULT_PAGE,
    last_page: Constant.DEFAULT_PAGE,
  },
  listRoute: [],
  listTimeRoute: [{ value: '', label: 'Tất cả' }],
  total_price: 0,
  total_insurance: 0,
  total_commission_main_agent: 0,
  total_real_revenue: 0,
  total_actually_paid_carlink: 0,
  total_promotion: 0,
  filter: {
    booking_date: 'false',
    from: '',
    to: '',
    route_id: '',
    depart_time: '',
    payment_method: '',
    company_id: undefined,
    options: '',
  },
} as ReportDetailState;

export const reportDetailMainAgentSlice = createSlice({
  name: 'reportDetailMainAgent',
  initialState,
  reducers: {
    setListAllRoutes(state, action) {
      state.listRoute = action.payload;
    },
    setFilterBookingDate(state, action: PayloadAction<string>) {
      state.filter.booking_date = action.payload;
    },
    setFilterRangeDate(state, action: PayloadAction<any>) {
      state.filter.from = action.payload?.[0];
      state.filter.to = action.payload?.[1];
    },
    setFilterRoute(state, action: PayloadAction<number | string>) {
      state.filter.route_id = action.payload;
    },
    setFilterDepartTime(state, action: PayloadAction<number | string>) {
      state.filter.depart_time = action.payload;
    },
    setFilterPaymentMethod(state, action: PayloadAction<string>) {
      state.filter.payment_method = action.payload;
    },
    setFilterCompany(state, action: PayloadAction<number | undefined>) {
      state.filter.company_id = action.payload;
    },
    setFilterOptions(state, action: PayloadAction<string>) {
      state.filter.options = action.payload;
    },
    setListTimeRoute(state, action: PayloadAction<ValueOptions[]>) {
      state.listTimeRoute = action.payload;
    },
    setPerPage(state, action: PayloadAction<number>) {
      state.pagination.per_page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListReportDetailMainAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListReportDetailMainAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.listReport = action?.payload?.data;
        state.pagination.total = action.payload?.pagination?.total;
        state.pagination.per_page = action.payload?.pagination?.per_page;
        state.pagination.last_page = action.payload?.pagination?.last_page;
        state.pagination.current_page = action.payload?.pagination?.current_page;
        state.total_price = action.payload?.total_price;
        state.total_insurance = action.payload?.total_insurance;
        state.total_commission_main_agent = action.payload?.total_commission_main_agent;
        state.total_real_revenue = action.payload?.total_real_revenue;
        state.total_actually_paid = action.payload?.total_actually_paid;
        state.total_actually_paid_carlink = action.payload?.total_actually_paid_carlink;
        state.total_promotion = action.payload?.total_promotion;
      })
      .addCase(getListReportDetailMainAgent.rejected, (state, action: any) => {
        const listErros = action?.payload?.response?.data?.errors;
        if (listErros) {
          Object.keys(listErros)?.forEach((fieldName) => {
            toast.error(`${[listErros[fieldName]]}`);
          });
        }
        state.loading = false;
      })
      .addCase(getListReportDetailMainAgentExcel.pending, (state) => {})
      .addCase(getListReportDetailMainAgentExcel.fulfilled, (state, action) => {})
      .addCase(getListReportDetailMainAgentExcel.rejected, (state, action: any) => {
        const listErros = action?.payload?.response?.data?.errors;
        if (listErros) {
          Object.keys(listErros)?.forEach((fieldName) => {
            toast.error(`${[listErros[fieldName]]}`);
          });
        }
      });
  },
});

export const getListReportDetailMainAgent = createAsyncThunk(
  'ReportDetailMainAgent/getAll',
  async (params: ParamsReport, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListReportDetailMainAgentAPI(params);
      if (response) {
        return response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getListReportDetailMainAgentExcel = createAsyncThunk(
  'ReportDetailMainAgent/getAllExcel',
  async (params: ParamsReport, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListReportDetailMainAgentExcelAPI(params);
      if (response) {
        return response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const {
  setPerPage,
  setListAllRoutes,
  setFilterBookingDate,
  setFilterRangeDate,
  setFilterRoute,
  setFilterDepartTime,
  setFilterPaymentMethod,
  setFilterCompany,
  setFilterOptions,
  setListTimeRoute,
} = reportDetailMainAgentSlice.actions;
