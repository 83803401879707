import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { postHoldingTicketApi } from '../../../api/booking';
import { Toast } from '../../../config/constant';
import { BookingState, ParamsHolding } from '../../../types/booking';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

// @ts-ignore
export const postHoldingTicket = createAsyncThunk(
  'admin/bookings/holding',
  async (params: ParamsHolding, { dispatch, rejectWithValue }) => {
    try {
      const response = await postHoldingTicketApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: response?.data?.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const holdingBookingSlice = createSlice({
  name: 'holdingBooking',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
    dataBooking: [],
    bookingType:'',
    ticketCode:''
  } as BookingState,
  reducers: {
    setCreate: (state: BookingState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
      state.dataBooking = payload?.data?.data;
      state.ticketCode = payload?.data?.data?.ticket_code
    },
    setCreateError: (state: BookingState, { payload }) => {
      const { errors } = payload?.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    setBookingType: (state: BookingState, action: PayloadAction<string>) => {
      state.bookingType = action.payload;
    },
    resetHoldingBooking: (state: BookingState) => {
      state.success = false;
      state.message = '';
      state.status = 0;
      state.bookingType='';
    },
    resetBookingType:(state: BookingState)=>{
      state.bookingType=''
    },
    resetDataBooking: (state: BookingState) => {
      state.dataBooking = [];
      state.ticketCode=''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postHoldingTicket.pending, (state: BookingState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(postHoldingTicket.fulfilled, (state: BookingState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(postHoldingTicket.rejected, (state: BookingState, actions) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = actions?.payload;
    });
  },
});

export const holdingBookingSelector = (state: RootState) => state.holdingBooking;
export const { setCreate, setCreateError, resetHoldingBooking, resetDataBooking,setBookingType,resetBookingType } = holdingBookingSlice.actions;
