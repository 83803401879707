import { getListReportCancelAPI, getListReportCancelExcelAPI } from "../../../api/report";
import { ParamsReport, ReportCancelState, ValueOptions } from "../../../types/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    listDepartTime: [{ value: '', label: 'Tất cả' }],
    total_price: 0,
    total_price_cancel: 0,
    total_price_refund: 0,
    total_real_revenue: 0,
    filter: {
        booking_date: 'false',
        from: '',
        to: '',
        route_id: '',
        depart_time: '',
        reason_cancel: ''
    }
} as ReportCancelState;

export const reportCancelAgentSlice = createSlice({
    name: 'reportCancelAgent',
    initialState,
    reducers: {
        setFilterBookingDate(state, action: PayloadAction<string>) {
            state.filter.booking_date = action.payload;
        },
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setFilterRoute(state, action: PayloadAction<number | string>) {
            state.filter.route_id = action.payload;
        },
        setFilterDepartTime(state, action: PayloadAction<number | string>) {
            state.filter.depart_time = action.payload;
        },
        setFilterReasonCancel(state, action: PayloadAction<string>) {
            state.filter.reason_cancel = action.payload;
        },
        setListDepartTime(state, action: PayloadAction<ValueOptions[]>) {
            state.listDepartTime = action.payload;
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportCancelAgent.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportCancelAgent.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_price = action.payload?.total_price;
                state.total_price_cancel = action.payload?.total_price_cancel;
                state.total_price_refund = action.payload?.total_price_refund;
                state.total_real_revenue = action.payload?.total_real_revenue;
            }).addCase(getListReportCancelAgent.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportCancelAgentExcel.pending, (state) => {
            }).addCase(getListReportCancelAgentExcel.fulfilled, (state, action) => {
            }).addCase(getListReportCancelAgentExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            })
    }
})

export const getListReportCancelAgent = createAsyncThunk('ReportCancelAgent/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportCancelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportCancelAgentExcel = createAsyncThunk('ReportCancelAgent/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportCancelExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setFilterBookingDate, setPerPage, setFilterRoute, setFilterDepartTime, setListDepartTime, setFilterRangeDate, setFilterReasonCancel } = reportCancelAgentSlice.actions;