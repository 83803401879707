import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Constant } from "../../config/constant";

function StatusFilter({
  status,
  onChangeStatus,
}: {
  status: boolean | undefined;
  onChangeStatus: Function;
}) {
  const { t } = useTranslation();

  return (
    <Row gutter={8} className="fw-500">
      <Col>
        <Button
          type="text"
          className={`fw-500 ${
            status === undefined ? "btn-status-active-green" : "text-color"
          }`}
          onClick={() => {
            onChangeStatus(undefined);
          }}
        >
          {t("Tất cả")}
        </Button>
      </Col>
      <Col className="border-start border-end">
        <Button
          type="text"
          className={`fw-500 ${
            status === Constant.STATUS_ACTIVE
              ? "btn-status-active-green"
              : "text-color"
          }`}
          onClick={() => {
            onChangeStatus(Constant.STATUS_ACTIVE);
          }}
        >
          {t("Đang hoạt động")}
        </Button>
      </Col>
      <Col>
        <Button
          type="text"
          className={`fw-500 ${
            status === Constant.STATUS_INACTIVE
              ? "btn-status-lock-red"
              : "text-color"
          }`}
          onClick={() => {
            onChangeStatus(Constant.STATUS_INACTIVE);
          }}
        >
          {t("Đã khóa")}
        </Button>
      </Col>
    </Row>
  );
}

export default StatusFilter;
