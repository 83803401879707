import { getListReportFeeDepartAPI, getListReportFeeDepartExcelAPI } from "../../../api/report";
import { ParamsReport, ReportFeeDepart, ValueOptions } from "../../../types/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    listRoute: [],
    listTimeRoute: [{ value: '', label: 'Tất cả' }],
    total_price: 0,
    total_trip: 0,
    total_price_depart_fee: 0,
    filter: {
        from: '',
        to: '',
        options: undefined,
        route_id: '',
        depart_time: '',
    }
} as ReportFeeDepart;

export const reportFeeDepartSlice = createSlice({
    name: 'reportFeeDepart',
    initialState,
    reducers: {
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setFilterReportFor(state, action: PayloadAction<string>) {
            state.filter.options = action.payload;
        },
        setFilterRoute(state, action: PayloadAction<number | string>) {
            state.filter.route_id = action.payload;
        },
        setFilterDepartTime(state, action: PayloadAction<number | string>) {
            state.filter.depart_time = action.payload;
        },
        setListTimeRoute(state, action: PayloadAction<ValueOptions[]>) {
            state.listTimeRoute = action.payload;
        },
        setListAllRoutes(state, action) {
            state.listRoute = action.payload;
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportFeeDepart.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportFeeDepart.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_price = action.payload?.total_price;
                state.total_trip = action.payload?.total_trip;
                state.total_price_depart_fee = action.payload?.total_price_depart_fee;
            }).addCase(getListReportFeeDepart.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportFeeDepartExcel.pending, (state) => {
            }).addCase(getListReportFeeDepartExcel.fulfilled, (state, action) => {
            }).addCase(getListReportFeeDepartExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            })
    }
})

export const getListReportFeeDepart = createAsyncThunk('ReportFeeDepart/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportFeeDepartAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportFeeDepartExcel = createAsyncThunk('ReportFeeDepart/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportFeeDepartExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setPerPage, setListAllRoutes, setFilterReportFor, setFilterRangeDate, setFilterRoute, setFilterDepartTime, setListTimeRoute } = reportFeeDepartSlice.actions;