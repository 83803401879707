import { Button, Result } from 'antd';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const ManageEmployee = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Result
        status="404"
        title="Chức năng này đang phát triển."
        extra={
          <Button onClick={() => navigate(`/`)} type="primary">
            Trang chủ
          </Button>
        }
      />
    </div>
  );
}

export default ManageEmployee