import { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";
import { Rule } from "antd/es/form";
import {
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import "../../assets/styles/app.scss";
import "../../config/i18n/i18n";
import { listUserSelector } from "../../redux/slice/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  createDriver,
  driverSelector,
  setDriver,
  updateDriver,
  updateStatusDriver,
} from "../../redux/slice/driverSlice";
import { Constant } from "../../config/constant";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "./DriverModal.scss";
import moment from "moment";
import dayjs from "dayjs";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import { FormProps } from "antd/lib";
import IconLock from "../../components/Icons/IconLock";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { isUrlImage } from "../../lib/utils";
import TutorialsVideo from "../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../utils/helpers";
import icClose from "../../assets/images/listOffice/icClose.svg";


const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

type FieldTypeForm = {
  name: string;
  date_of_birth: string;
  phone: string;
  license_class: string;
  date_expired_license: string;
  portrait_photo: string;
  license_front: string;
  license_rear: string;
  report_reason: string;
};

type FieldTypeConfirm = {
  reason: string;
};

function DriverModal({
  open,
  setOpen,
  onCancelSuccess,
  listTutorials
}: {
  open: boolean;
  setOpen: Function;
  onCancelSuccess: Function;
  listTutorials: any;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // const [formConfirm] = Form.useForm();
  const [userStatus, setUserStatus] = useState(Boolean);
  const [insuranceImg, setInsuranceImg] = useState<any[]>([]);
  const [licenseImg, setLicenseImg] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const { user, loading, success } = useAppSelector(listUserSelector);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [avatar, setAvatar] = useState<UploadFile[]>([]);
  const [license_front, setlicenseFront] = useState<UploadFile[]>([]);
  const [license_rear, setlicenseRear] = useState<UploadFile[]>([]);
  const handleCancel = () => setPreviewOpen(false);
  const [openModalConfim, setOpenModalConfirm] = useState<boolean>(false);
  const [otherReasonValue, setOtherReasonValue] = useState<string>("");
  const portraitPhoto = Form.useWatch("portrait_photo", form);


  const [driverReasonReport, setDriverReasonReport] = useState<
    | {
        key: string | undefined;
        value: string | undefined;
      }
    | undefined
  >();
  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const onChangeInsuranceImage = (imageList: ImageListType) => {
    setInsuranceImg(imageList as never[]);
  };

  const onChangeLicenseImage = (imageList: ImageListType) => {
    setLicenseImg(imageList as never[]);
  };
  const onChangeLicenseImage2 = (imageList: ImageListType) => {
    setLicenseImg(imageList as never[]);
  };

  const {
    loading: loadingCreate,
    status: statusResponseCreate,
    driver,
    errorDetail,
  } = useAppSelector(driverSelector);

  const handleModalCancel = () => {
    setOpen(false);
    form.resetFields();
    form.setFieldsValue({ report_reason: null });
    setAvatar([]);
    setlicenseFront([]);
    setlicenseRear([]);
    setInsuranceImg([]);
    setDriverReasonReport(undefined);
    setOtherReasonValue("");
    dispatch(setDriver(undefined));
  };

  const handleCancelModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const license_classes: any[] | undefined = [
    {
      value: "B1",
      label: t("B1"),
    },
    {
      value: "B2",
      label: t("B2"),
    },
    {
      value: "C",
      label: t("C"),
    },
    {
      value: "D",
      label: t("D"),
    },
    {
      value: "E",
      label: t("E"),
    },
    {
      value: "F",
      label: t("F"),
    },
  ];

  const dateOfBirthRules = [
    {
      required: true,
      message: t("Vui lòng nhập ngày sinh."),
    },
  ];

  const phoneRules = [
    {
      whitespace: true,
      message: t("Số điện thoại không đúng định dạng."),
    },
    {
      required: true,
      message: t("Vui lòng nhập số điện thoại."),
    },
    {
      number: true,
      message: t("Số điện thoại không đúng định dạng."),
    },
  ];

  const licenseClassRules = [
    {
      required: true,
      message: t("Vui lòng nhập hạng bằng lái."),
    },
    {
      enum: ["b1", "b2", "c", "d", "e", "f"],
      message: t("Hạng bằng lái không hợp lệ"),
    },
  ];

  const dateExpiredLicenseRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập hạn bằng lái."),
    },
  ];

  const nameRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập họ và tên"),
    },
  ];

  const avatarRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng chọn ảnh."),
    },
  ];

  const frontLicenseRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng chọn ảnh."),
    },
  ];

  const rearLicenseRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng chọn ảnh."),
    },
  ];

  const handleErrorUpload = (errors: any, files: any) => {
    if (errors?.acceptType) {
      toast.error(
        "Ảnh tải lên phải thuộc 1 trong 4 định dạng sau: png, jpg, jpeg, gif."
      );
    }
    if (errors?.maxNumber) {
      toast.error("Vui lòng tải lên tối đa 1 ảnh.");
    }
  };

  useEffect(() => {
    if (statusResponseCreate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
  }, [statusResponseCreate]);

  useEffect(() => {
    if (driver && driver?.type === "driver") {
      form.setFieldsValue({
        name: driver?.name,
        phone: driver?.phone,
        license_class: driver?.license_class,
        date_of_birth: dayjs(driver?.date_of_birth),
        date_expired_license: dayjs(driver?.date_expired_license),
      });
      if (driver?.note) {
        form.setFieldsValue({ report_reason: driver?.note?.key });
        setDriverReasonReport(driver?.note);
        if (driver?.note?.key === "other") {
          setOtherReasonValue(driver?.note?.value);
        }
      }
      form.setFieldsValue({
        portrait_photo: [{ dataURL: driver?.portrait_photo }],
      });
      setInsuranceImg([{ dataURL: driver?.portrait_photo }]);
      form.setFieldsValue({
        license_front: [{ dataURL: driver?.license_front }],
      });
      form.setFieldsValue({
        license_rear: [{ dataURL: driver?.license_rear }],
      });
    } else {
      form.resetFields();
      form.setFieldsValue({ report_reason: null });
      setAvatar([]);
      setlicenseFront([]);
      setlicenseRear([]);
      setInsuranceImg([]);
      setDriverReasonReport(undefined);
      setOtherReasonValue("");
    }
    setOpenModalConfirm(false);
  }, [driver, form]);

  useEffect(() => {
    if (errorDetail) {
      form.setFields(
        Object.keys(errorDetail).map((fieldName) => ({
          name: fieldName,
          errors: [errorDetail[fieldName]],
        }))
      );
    }
  }, [errorDetail]);

  const onSubmitConfirm: FormProps<FieldTypeConfirm>["onFinish"] = async (
    values
  ) => {
    if (!driver?.status) {
      const isPastDate = moment(driver?.date_expired_license).isBefore(
        moment()
      );
      if (isPastDate) {
        toast.error("Vui lòng cập nhật hạn bằng lái và đổi ảnh bằng lái");
      } else {
        dispatch(
          updateStatusDriver({
            id: driver?.id,
            status: !driver?.status,
            reason: values?.reason,
            type: driver?.type,
          })
        )
          .then((response) => {
            setOpen(false);
            form.resetFields();
            toast.success(response?.payload?.message);
          })
          .catch((err) => {
            toast.error("Thao tác thất bại");
          });
      }
    } else {
      dispatch(
        updateStatusDriver({
          id: driver?.id,
          status: !driver?.status,
          reason: values?.reason,
          type: driver?.type,
        })
      )
        .then((response) => {
          toast.success(response?.payload?.message);
          form.resetFields();
          setOpen(false);
        })
        .catch((err) => {
          toast.error("Thao tác thất bại");
        });
    }
  };
  const onFinish = (values: any) => {
    values.date_of_birth = dayjs(values?.date_of_birth).format("YYYY-MM-DD");
    values.date_expired_license = dayjs(values?.date_expired_license).format(
      "YYYY-MM-DD"
    );
    if (insuranceImg[0]?.dataURL) {
      values.portrait_photo = insuranceImg[0]?.dataURL;
    } else {
      values.portrait_photo = undefined;
    }

    values.license_front = form.getFieldValue("license_front")?.[0]?.dataURL;
    if (
      !form.getFieldValue("license_front")?.[0]?.dataURL?.includes("http://")
    ) {
      values.license_front = form.getFieldValue("license_front")?.[0]?.dataURL;
    } else {
      values.license_front = undefined;
    }

    values.license_rear = form.getFieldValue("license_rear")?.[0]?.dataURL;
    if (
      !form.getFieldValue("license_rear")?.[0]?.dataURL?.includes("http://")
    ) {
      values.license_rear = form.getFieldValue("license_rear")?.[0]?.dataURL;
    } else {
      values.license_rear = undefined;
    }
    values.company_id = 1;
    values.type = "driver";
    if (driver) {
      values.id = driver?.id;
      if (isUrlImage(values.portrait_photo)) {
        delete values.portrait_photo;
      }
      if (isUrlImage(values.license_front)) {
        delete values.license_front;
      }
      if (isUrlImage(values.license_rear)) {
        delete values.license_rear;
      }

      dispatch(updateDriver(values));
    } else {
      dispatch(createDriver(values));
    }
  };

  useEffect(() => {
    if (success) {
      if (user?.status === 1) {
        setUserStatus(true);
      } else {
        setUserStatus(false);
      }
    }
  }, [success]);

  return (
    <>
      <Modal
        className="driver-modal"
        forceRender
        maskClosable={false}
        closeIcon={false}
        // title={driver ? t("Chi tiết thông tin tài xế") : t("Thêm tài xế")}
        title={
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text className="fw-600 fs-18 ">
              {driver ? t("Chi tiết thông tin tài xế") : t("Thêm tài xế")}
            </Typography.Text>
            <Space size={10} align="center">
              <TutorialsVideo
                url_youtube={handleGetUrlGuide(listTutorials, 17)}
              />
              <Image
                src={icClose}
                style={{ cursor: "pointer" }}
                preview={false}
                onClick={handleModalCancel}
              />
            </Space>
          </Row>
        }
        centered
        open={open}
        onCancel={handleModalCancel}
        width={1045}
        styles={{
          body: { maxHeight: "100vh", overflowY: "auto", overflowX: "hidden" },
        }}
        footer={
          <Row className="justify-content-between w-100" align={"middle"}>
            <Col>
              {driver ? (
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => setOpenModalConfirm(true)}
                    size="large"
                    className={`driver-modal__button__lock h-auto d-flex align-items-center justify-content-center ${
                      driver?.status ? "btn-block-red" : "btn-active-green"
                    }`}
                    disabled={loadingCreate}
                  >
                    <IconLock width={16} height={16} />
                    {driver?.status ? t("Khóa tài xế") : t("Mở khóa tài xế")}
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Col>
            <Row gutter={16} align={"middle"}>
              <Col>
                <Button
                  key="back"
                  onClick={handleModalCancel}
                  size="large"
                  className="driver-modal__button__cancel h-auto"
                  disabled={loadingCreate}
                >
                  {t("Đóng")}
                </Button>
              </Col>
              <Col>
                <Button
                  key="submit"
                  type="primary"
                  loading={loadingCreate}
                  size="large"
                  onClick={form.submit}
                  className="driver-modal__button__submit h-auto"
                  htmlType="submit"
                  // disabled={loadingCreate}
                >
                  {t("Lưu")}
                </Button>
              </Col>
            </Row>
          </Row>
        }
      >
        <Form
          name="driver-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          className="driver-form mt-0 w-95"
          labelCol={{ span: 12 }}
          colon={false}
          labelAlign={"left"}
          labelWrap
          initialValues={{
            report_reason: driverReasonReport ? driverReasonReport?.key : null,
          }}
        >
          <Spin spinning={loadingCreate}>
            <div className="driver-form__container w-100">
              <Row gutter={20}>
                <Col xs={24} md={8}>
                  <Form.Item<FieldTypeForm>
                    name="name"
                    className="form-item-name"
                    label={t("Họ và tên")}
                    required={true}
                    rules={nameRules}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item<FieldTypeForm>
                    name="date_of_birth"
                    className="required"
                    label={t("Ngày sinh")}
                    required={true}
                    rules={dateOfBirthRules}
                    validateTrigger={["onChange", "onBlur"]}
                    validateFirst
                  >
                    <DatePicker
                      // open={true}
                      className="w-100 input-height"
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item<FieldTypeForm>
                    name="phone"
                    label={t("Số điện thoại")}
                    className="required"
                    required={true}
                    rules={phoneRules}
                    validateTrigger={["onChange", "onBlur"]}
                    validateFirst
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item<FieldTypeForm>
                    name="license_class"
                    label={t("Hạng bằng lái")}
                    className="required"
                    required={true}
                    rules={licenseClassRules}
                    validateTrigger={["onChange", "onBlur"]}
                    validateFirst
                  >
                    <Select
                      showSearch
                      placeholder={t("Hạng bằng lái")}
                      options={license_classes}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} className="label-name">
                  <Form.Item<FieldTypeForm>
                    name="date_expired_license"
                    required={true}
                    label={t("Hạn bằng lái")}
                    rules={dateExpiredLicenseRules}
                    validateTrigger={["onChange", "onBlur"]}
                    className="required"
                    validateFirst
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      className="w-100 input-height"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} md={24}>
                  <Form.Item<FieldTypeForm>
                    // name="portrait_photo"
                    className=" portrait-photo"
                    label={t("Ảnh chân dung")}
                    rules={avatarRules}
                    validateTrigger={["onChange", "onBlur"]}
                    required={true}
                  >
                    <ImageUploading
                      value={insuranceImg}
                      multiple
                      onChange={onChangeInsuranceImage}
                      maxNumber={1}
                      acceptType={["jpg", "gif", "png", "jpeg"]}
                      onError={handleErrorUpload}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => {
                        return (
                          <div className="Upload">
                            {imageList?.map((image, index) => {
                              return (
                                <div key={index} className="Upload__item">
                                  <div className="Upload__item__wrapper">
                                    <img
                                      className="Upload__item__image"
                                      src={image?.dataURL}
                                      alt=""
                                      width="100"
                                    />
                                    <div className="Upload__item__remove">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setPreviewImage(
                                            image?.dataURL as any
                                          );
                                          setOpenPreview(true);
                                        }}
                                        className="border-0 bg-transparent"
                                      >
                                        <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                      <button
                                        type="button"
                                        className="border-0 bg-transparent"
                                        onClick={() => {
                                          onImageRemove(0);
                                        }}
                                      >
                                        <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {imageList?.length <= 0 && (
                              <div className="Upload__item">
                                <button
                                  type="button"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  className="Upload__item__button"
                                  {...dragProps}
                                >
                                  <div>
                                    <div className="fs-18 fw-500 text-color-default">
                                      <UploadOutlined className="fs-24" /> Tải
                                      ảnh lên
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </ImageUploading>
                    {driver && (
                      <Flex className="mt-8" align="center">
                        <Typography.Text className="text-color-gray-3A3A3A d-block fs-16 mr-8">
                          {driver?.status
                            ? "Tài xế đang hoạt động"
                            : "Tài xế đang bị khóa"}
                        </Typography.Text>
                        {driver?.status ? (
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="p-1 green fs-10"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="p-1 fs-10 danger"
                          />
                        )}
                      </Flex>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item<FieldTypeForm>
                    name="license_front"
                    className="form-item-select text-left license-photo"
                    label={t("Bằng lái xe mặt trước")}
                    rules={frontLicenseRules}
                    validateTrigger={["onChange", "onBlur"]}
                    required={true}
                  >
                    <ImageUploading
                      value={form.getFieldValue("license_front")}
                      multiple
                      onChange={onChangeLicenseImage}
                      maxNumber={1}
                      acceptType={["jpg", "gif", "png", "jpeg"]}
                      onError={handleErrorUpload}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => {
                        return (
                          <div className="Upload">
                            {imageList?.map((image, index) => {
                              return (
                                <div key={index} className="Upload__item">
                                  <div className="Upload__item__wrapper">
                                    <img
                                      className="Upload__item__image"
                                      src={image?.dataURL}
                                      alt=""
                                      width="100"
                                    />
                                    <div className="Upload__item__remove">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setPreviewImage(
                                            image?.dataURL as any
                                          );
                                          setOpenPreview(true);
                                        }}
                                        className="border-0 bg-transparent"
                                      >
                                        <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                      <button
                                        type="button"
                                        className="border-0 bg-transparent"
                                        onClick={() => onImageRemove(index)}
                                      >
                                        <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {imageList?.length <= 0 && (
                              <div className="Upload__item">
                                <button
                                  type="button"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  className="Upload__item__button"
                                  {...dragProps}
                                >
                                  <div>
                                    <div className="fs-18 fw-500 text-color-default">
                                      <UploadOutlined className="fs-24" /> Tải
                                      ảnh lên
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </ImageUploading>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item<FieldTypeForm>
                    name="license_rear"
                    className="form-item-select text-left license-photo"
                    label={t("Bằng lái xe mặt sau")}
                    rules={rearLicenseRules}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <ImageUploading
                      value={form.getFieldValue("license_rear")}
                      multiple
                      onChange={onChangeLicenseImage2}
                      maxNumber={1}
                      acceptType={["jpg", "gif", "png", "jpeg"]}
                      onError={handleErrorUpload}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => {
                        return (
                          <div className="Upload">
                            {imageList?.map((image, index) => {
                              return (
                                <div key={index} className="Upload__item">
                                  <div className="Upload__item__wrapper">
                                    <img
                                      className="Upload__item__image"
                                      src={image?.dataURL}
                                      alt=""
                                      width="100"
                                    />
                                    <div className="Upload__item__remove">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setPreviewImage(
                                            image?.dataURL as any
                                          );
                                          setOpenPreview(true);
                                        }}
                                        className="border-0 bg-transparent"
                                      >
                                        <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                      <button
                                        type="button"
                                        className="border-0 bg-transparent"
                                        onClick={() => onImageRemove(index)}
                                      >
                                        <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {imageList?.length <= 0 && (
                              <div className="Upload__item">
                                <button
                                  type="button"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  className="Upload__item__button"
                                  {...dragProps}
                                >
                                  <div>
                                    <div className="fs-18 fw-500 text-color-default">
                                      <UploadOutlined className="fs-24" /> Tải
                                      ảnh lên
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </ImageUploading>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Modal
              footer={null}
              open={openPreview}
              onCancel={() => setOpenPreview(false)}
              width={700}
            >
              <div className="w-100" style={{ padding: "16px" }}>
                <img className="w-100" src={previewImage} alt="preview" />
              </div>
            </Modal>
          </Spin>
        </Form>
      </Modal>
      {driver ? (
        <Modal
          open={openModalConfim}
          onCancel={handleCancelModalConfirm}
          footer={null}
          className="Modal-confirm"
        >
          <Flex>
            <Image
              preview={false}
              width={22}
              height={22}
              src={IconExclamationCircle}
              alt="Icon Exclamation Circle"
            />
            <Flex vertical={true} className="ms-4">
              <Typography.Title className="fs-6 fw-bold">
                {driver?.status ? "Khóa tài xế" : "Mở tài xế"}
              </Typography.Title>
              <Typography.Text>
                {driver?.status
                  ? "Bạn đang thao tác khóa tài xế này, bạn có chắc chắn muốn khóa?"
                  : "Bạn đang thao tác mở khóa tài xế này, bạn có chắc chắn muốn khóa?"}
              </Typography.Text>
            </Flex>
          </Flex>
          <Form
            form={form}
            layout="vertical"
            className="mt-4"
            onFinish={onSubmitConfirm}
          >
            {driver?.status && (
              <Form.Item<FieldTypeConfirm>
                className="required"
                name="reason"
                label={
                  <Typography.Text className="mb-1 fs-6">Lý do</Typography.Text>
                }
                required={true}
                rules={[{ required: true, message: "Vui lòng nhập lý do" }]}
              >
                <Input.TextArea></Input.TextArea>
              </Form.Item>
            )}
            <Flex gap="middle" justify="end">
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  onClick={() => setOpenModalConfirm(false)}
                >
                  Trở lại
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {driver?.status ? "Khóa ngay" : "Mở khóa ngay"}
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </Modal>
      ) : null}
    </>
  );
}

export default DriverModal;
