import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Image, Pagination, Select, Space, Table, TableProps, Tabs, Typography, type TabsProps } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icLock from '../../assets/images/ic-lock-api.svg';
import IcUnLock from '../../assets/images/ic-unlock-api.svg';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import { Constant, TabType } from '../../config/constant';
import './Api.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getListFollowAPI } from '../../redux/slice/followApi/getListFollowApi';
import iconNext from '../../assets/images/ic-next.svg';
import iconPrev from '../../assets/images/ic-prev.svg';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { resetUpdateFollow, updateStatusFollowApi } from '../../redux/slice/followApi/lockFollowApi';

const itemsTabs: TabsProps['items'] = [
  {
    key: TabType.ALL,
    label: <p className="tab-title tab-title--first">Tất cả</p>,
  },
  {
    key: TabType.ACTIVE,
    label: <p className="tab-title">Hoạt động bình thường</p>,
  },
  {
    key: TabType.LOCK,
    label: <p className="tab-danger tab-title">Hoạt động đáng nghi</p>,
  },
];

const optionsType = [
  {
    value: 'main_agent',
    label: 'Tổng đại Lý',
  },
  {
    value: 'company',
    label: 'Nhà xe',
  },
];

interface DataType {
  name: string;
  type: string;
  domain: string;
  lastAction: string;
  time: string;
  id: number;
  api_key?: any;
  created_at?: string;
  action: string;
  is_dangios?: any;
  ip?: string;
}

const ApiFollow = () => {
  const { listFollow, pagination, loading } = useAppSelector((state) => state.listFollowApi);
  const { status: statusLockFollow } = useAppSelector((state) => state.updateFollowApi);
  const [activeTab, setActiveTab] = useState<TabType | string>(TabType.ALL);
  const [openModalLockApi, setOpenModalLockApi] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<any>();
  const [type, setType] = useState<string>('main_agent');
  const [statusDetail, setStatusDetail] = useState<number>(0);
  const [idDetail, setIdDetail] = useState<any>();
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleLockApi = (record: any) => {
    setOpenModalLockApi(true);
    setIdDetail(record?.id);
    setStatusDetail(record?.api_key?.status);
  };
  const columns: TableProps<DataType>['columns'] = [
    {
      title: '',
      width: '1%',
      render: (_, record) => (
        <Space size="small">
          <FontAwesomeIcon
            icon={faCircle}
            className={record?.api_key?.status === 0 ? 'dot-cancelled' : !record?.is_dangios ? 'green' : 'danger'}
            size="xs"
          />
        </Space>
      ),
    },
    {
      title: 'STT',
      align: 'center',
      width: '1%',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Typography>{id < 10 ? `0${id}` : id}</Typography>,
      sorter: (a, b) => Number(a?.id) - Number(b?.id),
    },
    {
      title: 'Tên API',
      dataIndex: 'api_key',
      width: '10%',
      render: (_, record) => <Typography>{record?.api_key?.name}</Typography>,
      sorter: (a, b) => a?.api_key?.name.localeCompare(b?.api_key?.name),
    },
    {
      title: 'API Key',
      dataIndex: 'api_key',
      width: '25%',
      render: (_, record) => <Typography>{record?.api_key?.api_key}</Typography>,
      sorter: (a, b) => a?.api_key?.name.localeCompare(b?.api_key?.name),
    },
    {
      title: 'Phân loại',
      dataIndex: 'api_key',
      width: '10%',
      render: (_, record) => (
        <Typography>
          {record?.api_key?.apikeyable_type === 'App\\Models\\MainAgent' ? 'Tổng đại lý' : 'Nhà xe'}
        </Typography>
      ),
      sorter: (a, b) => a?.api_key?.apikeyable_type.localeCompare(b?.api_key?.apikeyable_type),
    },
    {
      title: 'Tên miền',
      dataIndex: '',
      width: '8%',
      render: (_, record) => <Typography>{record?.api_key?.domain}</Typography>,
    },
    {
      title: 'Hành động cuối',
      width: '18%',
      dataIndex: 'action',
      render: (text) => <Typography>{text}</Typography>,
      sorter: (a, b) => a?.action.localeCompare(b?.action),
    },
    {
      title: 'Thời gian',
      width: '13%',
      dataIndex: 'time',
      render: (_, record) => (
        <Typography>{`${record?.created_at ? moment(record?.created_at).format('DD/MM/YYYY HH:mm') : ''}`}</Typography>
      ),
    },
    {
      title: 'IP',
      dataIndex: 'IP',
      width: '8%',
      render: (_, record) => <Typography>{record?.ip}</Typography>,
    },
    {
      title: 'Chức năng',
      width: '6%',
      render: (record) => (
        <Button className="no-borderer btn-lock" onClick={() => handleLockApi(record)}>
          <Image src={record?.api_key?.status === 1 ? icLock : IcUnLock} preview={false} width={16} />
        </Button>
      ),
    },
  ];
  const onCancelSuccess = useCallback(() => {
    dispatch(resetUpdateFollow());
    dispatch(getListFollowAPI({ status, page, type, limit }));
    setOpenModalLockApi(false);
  }, [dispatch]);

  const handlePagination = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };
  const handleChangeTab = (key: string) => {
    setActiveTab(key);
  };
  const renderRowTable = (record: any) => {
    return record?.api_key?.status === 0 ? 'bg-canceled' : record?.is_dangios ? 'bg-doubt' : '';
  };
  const handleCancelActive = () => {
    setOpenModalLockApi(false);
  };
  const handleActive = () => {
    dispatch(updateStatusFollowApi({ id: idDetail, status: statusDetail === 0 ? 1 : 0 }));
  };
  useEffect(() => {
    dispatch(
      getListFollowAPI({
        status: activeTab === '1' ? 1 : undefined,
        page,
        limit,
        type,
        is_dangios: activeTab === '2' ? 1 : 0,
      }),
    );
  }, [dispatch, status, page, limit, type, activeTab]);

  useEffect(() => {
    if (statusLockFollow === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
  }, [statusLockFollow]);
  return (
    <div className="ApiFollow bg-white">
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        items={itemsTabs}
        onChange={handleChangeTab}
        className={` ApiFollow__tabs ${activeTab === TabType.LOCK ? 'danger' : ''}`}
      />
      <Select
        className="ApiFollow__select mt-3"
        placeholder="Chọn phân loại"
        options={optionsType}
        defaultValue={'main_agent'}
        onChange={(value) => setType(value)}
      />
      <Table
        locale={{
          triggerDesc: 'Sắp xếp tăng dần',
          triggerAsc: 'Hủy sắp xếp',
          cancelSort: 'Sắp xếp giảm dần',
        }}
        dataSource={listFollow?.length ? listFollow : []}
        rowClassName={renderRowTable}
        columns={columns}
        pagination={false}
        loading={loading}
      />
      <Pagination
        onChange={handlePagination}
        current={page}
        total={pagination?.total}
        pageSize={pagination?.per_page ?? limitPagination}
        showSizeChanger={true}
        prevIcon={<img src={iconPrev} alt="" />}
        nextIcon={<img src={iconNext} alt="" />}
        className="mt-1 text-end"
      />
      <ModalConfirm
        modalOpen={openModalLockApi}
        isAgentBus={true}
        okText={statusDetail ? t('Khóa ngay') : t('Mở ngay')}
        cancelText={t('Trở lại')}
        modalTitle={statusDetail ? t('Khoá API') : t('Mở API')}
        modalContent={
          statusDetail
            ? t('Bạn đang thao tác khóa API này, có chắc chắn muốn khóa?')
            : t('Bạn đang thao tác mở API này, có chắc chắn muốn mở?')
        }
        onCancel={handleCancelActive}
        onOk={handleActive}
      />
    </div>
  );
};

export default ApiFollow;
