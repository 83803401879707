import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../config/constant";
import { isArray } from "lodash";
import { RegisterAdvertiseState } from "../../types/listRegisterAdvertise";
import { toast } from "react-toastify";
import { getListRegisterAdvertiseAPI } from "../../pages/listRegisterAdvertise/api";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listRegisterAdvertise: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    }
} as RegisterAdvertiseState;

export const listRegisterAdvertiseSlice = createSlice({
    name: 'listRegisterAdvertise',
    initialState,
    reducers: {
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.pagination.current_page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getListRegisterAdvertise.pending, (state) => {
            state.loading = true;
        }).addCase(getListRegisterAdvertise.fulfilled, (state, action) => {
            state.listRegisterAdvertise = action.payload?.data;
            state.pagination = action.payload?.pagination;
            state.loading = false;
        }).addCase(getListRegisterAdvertise.rejected, (state) => {
            state.loading = false;
            toast.error('Có lỗi xảy ra vui lòng thử lại!')
        })
    }
})

export const getListRegisterAdvertise = createAsyncThunk('ListRegisterAdvertise/getList', async (params: {
    page?: number;
    limit?: number;
    is_handle?: number;
    keyword?: string;
}, {
    dispatch, rejectWithValue
}) => {
    try {
        const res = await getListRegisterAdvertiseAPI(params)
        if (res?.status === 200) {
            if (isArray(res?.data?.data)) {
                return res?.data;
            } else {
                return res?.data?.data;
            }
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setPerPage, setCurrentPage } = listRegisterAdvertiseSlice.actions;