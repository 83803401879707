/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import "../../assets/styles/infoCompany.scss";
import { ACTION_NAME, messageUpdateSuccess } from "../../config/message";
import { checkAuthorization } from "../../api";
import ReactQuill from "react-quill";
import { validateContentPolicy } from "../../config/validators/validatePolicy";
import { moduleReactQuill } from "../../config/reactQuill";
import "./InsuranceConfig.scss";
import {
  getInsuranceConfigApi,
  updateInsuranceConfigApi,
} from "../../api/insurance";
import { useAppSelector } from "../../redux/hooks";
import { USER_GROUP } from "../../config/constant";

const InsuranceConfig: React.FC<any> = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);

  const handleDetailInsuranceApi = async () => {
    setLoadingAgent(true);
    try {
      const res = await getInsuranceConfigApi();
      if (res.status === 200) {
        setDataDetail(res.data.data);
      }
    } catch (error) {}
    setLoadingAgent(false);
  };

  const onFinish = async (values: any) => {
    try {
      const res = await updateInsuranceConfigApi({ ...values });
      if (res.status === 200) {
        messageUpdateSuccess(ACTION_NAME.INSURANCE);
        handleDetailInsuranceApi();
        setIsEdit(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const handleSubmit = () => {
    form.submit(); // Gọi phương thức submit của form instance
  };

  useEffect(() => {
    handleDetailInsuranceApi();
  }, []);

  const setFields = () => {
    form.setFieldsValue({
      name: dataDetail?.name,
      insurance_value_price: dataDetail?.policy_value[0].insurance_value_price,
      content: dataDetail?.content,
    });
  };
  useEffect(() => {
    setFields();
  }, [dataDetail, form]);

  return (
    <Spin spinning={loadingAgent}>
      <Card className="card-insurance-config">
        <Col>
          {/* <input type="text" value={value} /> */}
          <Form
            name="insurance config"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            className="form-insurance-config"
          >
            <Row gutter={[10, 10]}>
              <Row gutter={[12, 10]}>
                <Col xl={12}>
                  <Form.Item
                    name="name"
                    label={t("Tên chính sách")}
                    className="required mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên chính sách",
                      },
                    ]}
                  >
                    <Input readOnly={!isEdit} style={{ width: 350 }} />
                  </Form.Item>
                </Col>
                <Col xl={12}>
                  <Form.Item
                    name="insurance_value_price"
                    label={t("Giá trị bảo hiểm")}
                    className="required mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập giá trị bảo hiểm",
                      },
                    ]}
                  >
                    <InputNumber
                      readOnly={!isEdit}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      style={{ width: 350 }}
                      addonAfter={
                        <Space size={6}>
                          <span>{t("VND")}</span>/<span>{t("ghế")}</span>
                        </Space>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                className={!isEdit ? "disabled-content" : ""}
                style={{ width: "100%" }}
              >
                <Col md={24} lg={18} xl={16} xxl={16}>
                  <Form.Item
                    name="content"
                    label={t("Nội dung bảo hiểm")}
                    className="required"
                    rules={validateContentPolicy}
                  >
                    <ReactQuill readOnly={!isEdit} theme="snow" modules={moduleReactQuill} />
                  </Form.Item>
                </Col>
              </Row>
            </Row>
            <Row
              style={
                accountDetail?.group === USER_GROUP.CARLINK
                  ? {}
                  : { display: "none" }
              }
              className="container-row-btn"
              gutter={24}
            >
              {isEdit ? (
                <Col style={{ display: "flex", gap: 10 }}>
                  <Button onClick={() => setIsEdit(false)}>Trở lại</Button>
                  <Button type="primary" onClick={handleSubmit}>
                    Lưu
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button
                    className="btn-update-insurance"
                    onClick={() => setIsEdit(true)}
                  >
                    Chỉnh sửa
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Col>
      </Card>
    </Spin>
  );
};

export default InsuranceConfig;
