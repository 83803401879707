import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, Modal, Row, Typography } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useState } from 'react';
import ReactImageUploading, { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../redux/hooks';

function ModalImageTypeBus({
  representImageCar,
  setRepresentImageCar,
  busImg,
  setBusImg,
  formTypeBus,
  setFileListDelete,
}: any) {
  const [previewImage, setPreviewImage] = useState('');
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const listServices = useAppSelector((state) => state?.listService?.listServices) as any;

  const onChangeBusImage = (imageList: ImageListType) => {
    setBusImg(imageList as never[]);
  };
  const handleErrorUploadBusImg = (errors: any, files: any) => {
    if (errors?.acceptType) {
      toast.error('Ảnh tải lên phải thuộc 1 trong 4 định dạng sau: png, jpg, jpeg, gif.');
    }
    if (errors?.maxNumber) {
      toast.error('Vui lòng tải lên tối đa 12 ảnh.');
    }
  };
  const handleErrorUpload = (errors: any, files: any) => {
    if (errors?.acceptType) {
      toast.error('Ảnh tải lên phải thuộc 1 trong 4 định dạng sau: png, jpg, jpeg, gif.');
    }
    if (errors?.maxNumber) {
      toast.error('Vui lòng tải lên tối đa 1 ảnh.');
    }
  };

  const onChangeInsuranceImage = (imageList: ImageListType) => {
    setRepresentImageCar(imageList as never[]);
  };
  const onChangeService = (checkedValues: any) => {};
  return (
    <>
      <Row className='mt-2'>
        <Col span={4}>
          <Typography>
            Tiện ích xe <span className="required"></span>
          </Typography>
        </Col>
        <Col span={20}>
          <Form.Item name="service">
            <Checkbox.Group
              className="text-color-default fw-500"
              options={listServices}
              onChange={onChangeService}
              style={{ gridGap: '17px 50px' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={6}>
          <Typography>
            Ảnh đại diện <span className="required"></span>
          </Typography>
        </Col>
        <Col span={18}>
          <Typography>
            Ảnh xe <span className="required"></span>
          </Typography>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={6}>
          <Form.Item
            name="portrait_photo"
            className=" portrait-photo"
            style={{ width: '200px' }}
            validateTrigger={['onChange', 'onBlur']}
          >
            <ReactImageUploading
              value={representImageCar}
              // value={formTypeBus.getFieldValue('portrait_photo')}
              multiple
              onChange={onChangeInsuranceImage}
              maxNumber={1}
              acceptType={['jpg', 'gif', 'png', 'jpeg']}
              onError={handleErrorUpload}
            >
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => {
                return (
                  <div className="Upload">
                    {imageList?.map((image, index) => {
                      return (
                        <div key={index} className="Upload__item" style={{ height: '200px' }}>
                          <div className="Upload__item__wrapper">
                            <img className="Upload__item__image" src={image?.dataURL} alt="" width="100" />
                            <div className="Upload__item__remove">
                              <button
                                type="button"
                                onClick={() => {
                                  setPreviewImage(image?.dataURL as any);
                                  setOpenPreview(true);
                                }}
                                className="border-0 bg-transparent"
                              >
                                <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                              </button>
                              <button
                                type="button"
                                className="border-0 bg-transparent"
                                onClick={() => {
                                  onImageRemove(0);
                                }}
                              >
                                <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {imageList?.length <= 0 && (
                      <div className="Upload__item type-bus-image">
                        <button
                          type="button"
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          className="Upload__item__button"
                          {...dragProps}
                        >
                          <div>
                            <div className="fs-15 fw-500 text-color-default">
                              <UploadOutlined className="fs-15" /> Tải ảnh lên
                            </div>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                );
              }}
            </ReactImageUploading>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            name="bus_img"
            className="list_image_car type_bus"
            rules={[{ required: true, message: 'Vui lòng nhập ảnh xe' }]}
          >
            <ReactImageUploading
              multiple
              value={busImg}
              onChange={onChangeBusImage}
              maxNumber={20}
              acceptType={['jpg', 'gif', 'png', 'jpeg']}
              onError={handleErrorUploadBusImg}
            >
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                <div className="Upload">
                  <Row className="h-100" gutter={[16, 16]}>
                    {imageList?.length > 0 &&
                      imageList?.map((image, index) => (
                        <Col span={6} key={index} className="Upload__item" style={{ height: '200px' }}>
                          <div className="Upload__item__wrapper">
                            <img className="Upload__item__image" src={image?.dataURL} alt="" />
                            <div className="Upload__item__remove">
                              <button
                                onClick={() => {
                                  setPreviewImage(image?.dataURL as any);
                                  setOpenPreview(true);
                                }}
                                className="border-0 bg-transparent"
                                type="button"
                              >
                                <EyeOutlined className="fs-15 fw-500 white icon-remove" />
                              </button>
                              <button
                                className="border-0 bg-transparent"
                                onClick={() => {
                                  const startIndex = image?.dataURL?.indexOf('/bus-type/images/');
                                  setFileListDelete(
                                    (fileList: []) => [...fileList, image?.dataURL?.slice(startIndex)] as any,
                                  );
                                  onImageRemove(index);
                                }}
                                type="button"
                              >
                                <DeleteOutlined className="fs-15 fw-500 white icon-remove" />
                              </button>
                            </div>
                          </div>
                        </Col>
                      ))}
                    <Col span={6} className="Upload__item  type-bus-image">
                      <button
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        className="Upload__item__button"
                        {...dragProps}
                        type="button"
                      >
                        <div>
                          <div className="fs-15 fw-500 text-color-default">
                            <UploadOutlined className="fs-15" /> Tải ảnh lên
                          </div>
                        </div>
                      </button>
                    </Col>
                  </Row>
                </div>
              )}
            </ReactImageUploading>
          </Form.Item>
          <Modal footer={null} open={openPreview} onCancel={() => setOpenPreview(false)} width={700}>
            <div className="w-100" style={{ padding: '16px' }}>
              <img className="w-100" src={previewImage} alt="preview" />
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default ModalImageTypeBus;
