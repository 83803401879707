/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { useTranslation } from "react-i18next";
import "../../assets/styles/infoCompany.scss";
import { getCompanyDetailApi, updateCompanyApi } from "../../api/busCompany";
import { ACTION_NAME, messageUpdateSuccess } from "../../config/message";
import { checkAuthorization } from "../../api";
import axios from "axios";
import { URL_API_VIET_QR } from "../../api/vietqr";
import { useAppSelector } from "../../redux/hooks";
import { UploadOutlined } from "@ant-design/icons";
import { filterOptionSearch, getBase64 } from "../../lib/utils";

const InfoCompany: React.FC<any> = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [listBank, setListBank] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const [displayPrice, setDisplayPrice] = useState<any>(0);

  const image: any = dataDetail?.logo
    ? [
        {
          uid: "-1",
          name: "No avatar",
          status: "done",
          url: dataDetail?.logo,
        },
      ]
    : [];

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewTitle, setPreviewTitle] = useState("");

  // upload image
  const handleCancelImage = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // Giữ chỉ một tệp trong fileList
    setFileList(newFileList?.slice(-1));
  };

  const onChangePrintTicket = (checked: boolean) => {
    setDisplayPrice(checked ? 1 : 0);
  };

  useEffect(() => {
    setFileList(image);
  }, [dataDetail?.logo]);

  const handleListQR = () => {
    try {
      axios
        .get(URL_API_VIET_QR)
        .then((response) => {
          const res = response?.data;
          setListBank(res?.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {}
  };

  const listBankSelect = listBank.map((item: any) => {
    return { value: item?.bin, label: `${item?.name} (${item?.code})` };
  });

  useEffect(() => {
    handleListQR();
  }, []);

  const handleDetailCompanyApi = async () => {
    setLoading(true);
    try {
      const res = await getCompanyDetailApi();
      if (res.status === 200) {
        setDataDetail(res.data.data);
      }
    } catch (error) {}
    setLoading(false);
  };

  const onSearch = (value: string) => {};

  const onLoad = () => {
    form.setFieldsValue({
      company_name: dataDetail?.company_name,
      name: dataDetail?.name,
      address: dataDetail?.address,
      bank_account_name: dataDetail?.bank_account_name,
      phone: dataDetail?.phone,
      email: dataDetail?.email,
      debit_limit: dataDetail?.debit_limit,
      bank: dataDetail?.bank,
      bank_account_number: dataDetail?.bank_account_number,
      fax: dataDetail?.fax,
      office_name: dataDetail?.representative_office,
      representative_name: dataDetail?.representative_name,
      tax_code: dataDetail?.tax_code,
      position: dataDetail?.position,
      commission_percent: dataDetail?.commission_percent,
      depart_fee: dataDetail?.depart_fee,
      export_ticket_fee: dataDetail?.export_ticket_fee,
      relevant_emails: dataDetail?.relevant_emails,
    });
  };
  useEffect(() => {
    onLoad();
    setDisplayPrice(dataDetail?.display_price ? 1 : 0);
  }, [dataDetail, form]);

  const onFinish = async (values: any) => {
    values.logo = fileList[0]?.thumbUrl;
    values.display_price = displayPrice;
    try {
      const res = await updateCompanyApi(values);
      if (res.status === 200) {
        messageUpdateSuccess(ACTION_NAME.CAR_COMPANY);
        handleDetailCompanyApi();
        setIsEdit(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };
  // if (isEdit) {
  // }

  const handleSubmit = () => {
    form.submit(); // Gọi phương thức submit của form instance
  };

  useEffect(() => {
    handleDetailCompanyApi();
  }, []);

  return (
    <Spin spinning={loading}>
      <Card className="wrapper-info-company">
        <Col>
          <Form
            name="info-car-company"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            // validateTrigger="onSubmit"
            className="form-info-company"
          >
            <div>
              <Row>
                <Col>
                  <Form.Item
                    name="company_name"
                    // className="required"
                    className="form-item-name required"
                    label={t("Tên công ty")}
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên công ty"),
                      },
                    ]}
                    validateFirst
                  >
                    <Input disabled={!isEdit} className="w-914" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="name"
                    className="required"
                    label={t("Tên nhà xe")}
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên nhà xe"),
                      },
                    ]}
                    validateFirst
                  >
                    <Input disabled className="w-914" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="address"
                    label={t("Địa chỉ")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập địa chỉ"),
                      },
                    ]}
                  >
                    <Input disabled className="w-914" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="representative_name"
                    label={t("Đại diện")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên người đại diện"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="position"
                    label={t("Chức vụ")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập chức vụ"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="tax_code"
                    label={t("Mã số thuế (MSDN)")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập mã số thuế"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="office_name"
                    label={t("VPGD")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên văn phòng"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="phone"
                    label={t("Điện thoại")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập số điện thoại"),
                      },
                    ]}
                  >
                    <Input disabled className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item name="fax" label={t("Fax")}>
                    <Input disabled={!isEdit} className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    className="required"
                    name="email"
                    label={t("Email liên hệ")}
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập số điện thoại"),
                      },
                    ]}
                  >
                    <Input disabled className="w-355" />
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: 25 }}>
                  <Form.Item
                    name="relevant_emails"
                    label={t("Email nhận mặt vé")}
                  >
                    <Input disabled className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col style={{ display: "none" }}>
                  <Form.Item
                    style={{ width: 535 }}
                    name="depart_fee"
                    label={t("Phí xuất bến")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập phí xuất bến"),
                      },
                    ]}
                  >
                    <Input disabled className="w-355" suffix={"VND"} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="commission_percent" label={t("Chiết khấu")}>
                    <Input
                      value={dataDetail?.commission_percent}
                      disabled
                      type="number"
                      suffix={"%"}
                      min={0}
                      style={{ width: 104 }}
                    />
                    <span className="commission_percent-note">
                      cho đối tác Carlink
                    </span>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="export_ticket_fee"
                    className="required"
                    label={t("Phí xuất vé")}
                  >
                    <Input
                      value={dataDetail?.export_ticket_fee}
                      disabled
                      type="number"
                      suffix={"%"}
                      min={0}
                      style={{ width: 104 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col>
                  <Form.Item
                    name="bank_account_number"
                    label={t("Tài khoản số")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập số tài khoản"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} type="number" className="w-355" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="bank_account_name"
                    label={t("Chủ tài khoản")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên chủ tài khoản"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    name="bank"
                    label={t("Tại ngân hàng")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên ngân hàng"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={onSearch}
                      filterOption={filterOptionSearch}
                      disabled={!isEdit}
                      className="w-914"
                      // onChange={handleChange}
                      options={listBankSelect}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  name="display_price"
                  label="Cho phép in giá vé lên mặt vé"
                  className="required"
                >
                  <Switch
                    disabled={!isEdit}
                    checked={displayPrice === 1 ? true : false}
                    onChange={onChangePrintTicket}
                  />
                </Form.Item>
              </Row>
              <Row gutter={24}>
                <Col style={{ width: "434px" }}>
                  <Form.Item
                    className={
                      !isEdit
                        ? "upload-image upload-image-info-agent image-disabled"
                        : "upload-image upload-image-info-agent"
                    }
                    name="logo"
                    label={t("Logo")}
                  >
                    <Upload
                      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      disabled={!isEdit}
                    >
                      {fileList.length >= 1 ? null : (
                        <>
                          <UploadOutlined /> {t("Tải ảnh lên")}
                        </>
                      )}
                    </Upload>
                    <Modal
                      open={previewOpen}
                      footer={null}
                      onCancel={handleCancelImage}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                </Col>
                <Col className="w-435">
                  <Form.Item name="web_logo" label={t("Mẫu website")}>
                    <Button disabled className="logo-info-company">
                      Không có mẫu
                    </Button>
                  </Form.Item>
                </Col>
                {isEdit ? (
                  <Col className="container-btn-company ">
                    <Button onClick={() => setIsEdit(false)}>Trở lại</Button>
                    <Button type="primary" onClick={handleSubmit}>
                      Lưu
                    </Button>
                  </Col>
                ) : (
                  <Button
                    style={!accountDetail?.is_admin ? { display: "none" } : {}}
                    // disabled={!accountDetail?.is_admin}
                    className="btn-info-company"
                    onClick={() => setIsEdit(true)}
                  >
                    Chỉnh sửa
                  </Button>
                )}
              </Row>
            </div>
          </Form>
        </Col>
      </Card>
    </Spin>
  );
};

export default InfoCompany;
