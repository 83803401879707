import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { USER_GROUP } from "../../config/constant";
import ReportDetailAgent from "./ReportDetailAgent";
import ReportDetailCompany from "./ReportDetailCompany";
import ReportDetailMainAgent from "./ReportDetailMainAgent";

const ReportDetail = () => {
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  if (accountDetail?.group === USER_GROUP.CAR_COMPANY) {
    return <ReportDetailCompany />;
  }
  if (accountDetail?.group === USER_GROUP.AGENT) {
    return <ReportDetailAgent />;
  }
  if (accountDetail?.group === USER_GROUP.MAIN_AGENT) {
    return <ReportDetailMainAgent />
  }
  return <></>;
};

export default ReportDetail;
