import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { cancelTripAPI, createExtraTripAPI, createTripAPI } from '../../../api/trips';
import { Toast } from '../../../config/constant';
import { Action, CREATE_ERROR, CREATE_SUCCESS, UPDATE_SUCCESS } from '../../../config/message';
import { TripState } from '../../../types/trip';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

// @ts-ignore
export const createTrip = createAsyncThunk('admin/create/trip', async (params: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await createTripAPI(params);
    const { data, status } = response;
    if (status) {
      dispatch(setCreate({ status: status, data: data }));
      dispatch(
        setToast({
          message: UPDATE_SUCCESS(Action.TRIP),
          status: true,
          type: Toast.TYPE_SUCCESS,
        }),
      );
    }
    return true;
  } catch (error: any) {
    const { response } = error;
    dispatch(setCreateError(response));
    dispatch(
      setToast({
        message: response.data.message,
        status: true,
        type: Toast.TYPE_ERROR,
      }),
    );
    return rejectWithValue(getErrorMessage(error));
  }
});

export const cancelTrip = createAsyncThunk('admin/cancelTrip', async (params: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await cancelTripAPI(params);
    const { data, status } = response;
    if (status) {
      dispatch(setDeleteTrip({ status: status, data: data }));
      dispatch(
        setToast({
          message: CREATE_SUCCESS(Action.DELETE_TRIP),
          status: true,
          type: Toast.TYPE_SUCCESS,
        }),
      );
    }
    return true;
  } catch (error: any) {
    const { response } = error;
    dispatch(
      setToast({
        message: response.data.message,
        status: true,
        type: Toast.TYPE_ERROR,
      }),
    );
    return rejectWithValue(getErrorMessage(error));
  }
});
export const createExtraTrip = createAsyncThunk(
  'admin/create/extraTrip',
  async (params: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await createExtraTripAPI(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreateExtra({ status: status, data: data }));
        dispatch(
          setToast({
            message: 'Xóa điều hành thành công',
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: CREATE_ERROR(Action.EXTRA_TRIP) + ': ' + response.data.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const createTripSlice = createSlice({
  name: 'createTrip',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    errorDetail: 0,
    statusExtra: 0,
    statusDeleteTrip: 0,
  } as TripState,
  reducers: {
    setCreate: (state: TripState, { payload }) => {
      state.status = payload?.status;
    },
    setCreateExtra: (state: TripState, { payload }) => {
      state.statusExtra = payload?.status;
    },
    setDeleteTrip: (state: TripState, { payload }) => {
      state.statusDeleteTrip = payload?.status;
    },
    resetCancelTrip: (state: TripState) => {
      state.statusDeleteTrip = 0;
    },
    setCreateError: (state: TripState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetRegisterCreateTrip: (state: TripState) => {
      state.success = false;
      state.status = 0;
      state.message = '';
      state.errorDetail = null;
      state.statusExtra = 0;
      state.statusDeleteTrip = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTrip.pending, (state: TripState) => {
      state.loading = true;
    });
    builder.addCase(createTrip.fulfilled, (state: TripState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(createTrip.rejected, (state: TripState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const createTripSelector = (state: RootState) => state.createTrip;
export const { setCreate, setCreateError, resetRegisterCreateTrip, setCreateExtra, setDeleteTrip, resetCancelTrip } =
  createTripSlice.actions;
