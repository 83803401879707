import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  TimePicker,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import dayjs from 'dayjs';
import { Lunar } from 'lunar-typescript';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import iconAuto from '../../assets/images/ic-auto-operated.svg';
import icArrow from '../../assets/images/ic-box-arrow-right.svg';
import icEdit from '../../assets/images/ic-edit.svg';
import icInfo from '../../assets/images/ic-info.svg';
import IcUnLock from '../../assets/images/ic-unlock.svg';
import iconAutoCancel from '../../assets/images/trips/ic-auto-cancel.svg';
import iconAutoDepart from '../../assets/images/trips/ic-auto-depart.svg';
import icCancel from '../../assets/images/trips/ic-cancel.svg';
import iconManualCancel from '../../assets/images/trips/ic-hand-cancel.svg';
import iconManualDepart from '../../assets/images/trips/ic-hand-depart.svg';
import icNope from '../../assets/images/trips/ic-nope.svg';
import icPlusGreen from '../../assets/images/trips/ic-plus-green.svg';
import icPlus from '../../assets/images/trips/ic-plus.svg';
import ModalAlertBalance from '../../components/Modal/ModalAlertBalance';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import ModalExtraConfirm from '../../components/Modal/ModalExtraConfirm';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant, DateFormat, DateFormatSearch, DRIVER, EXTRA_DRIVER, Toast, YearFormat } from '../../config/constant';
import { calculateTimeSum, filterOption, getStatusTrip, isOutDate } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchListBus, listBusSelector } from '../../redux/slice/buses/bus';
import { getAllAvailableBusType, listBusTypeSelector } from '../../redux/slice/busTypes/listBusTypeSlice';
import { driverSelector, fetchListDriver, fetchListExtraDriver } from '../../redux/slice/driverSlice';
import { getAllRoute, routeSelector } from '../../redux/slice/routeSlice';
import { setToast } from '../../redux/slice/toastNotifySlice';
import {
  cancelTrip,
  createExtraTrip,
  createTrip,
  createTripSelector,
  resetCancelTrip,
  resetRegisterCreateTrip,
} from '../../redux/slice/trips/createTrip';
import { fetchListTrip, listTripSelector } from '../../redux/slice/trips/tripSlice';
import { departTrip, resetUpdateTrip, updateStatusTrip, updateTripSelector } from '../../redux/slice/trips/updateTrip';
import { TripParams } from '../../types/trip';
import { handleGetUrlGuide } from '../../utils/helpers';
import './ListTrips.scss';

function ListTrip() {
  const { loading: loadingUpdate, status: statusUpdate, message } = useAppSelector(updateTripSelector);
  const {
    loading: loadingCreate,
    status: statusCreate,
    statusExtra,
    statusDeleteTrip,
  } = useAppSelector(createTripSelector);
  const { buses } = useAppSelector(listBusSelector);
  const { drivers, extra_drivers } = useAppSelector(driverSelector);
  const { loading, trips, pagination } = useAppSelector(listTripSelector);
  const { listAllAvailableBusType } = useAppSelector(listBusTypeSelector);
  const { listAllRoute } = useAppSelector(routeSelector);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Link } = Typography;
  const { t } = useTranslation();
  const now = dayjs();
  const [formSearch] = Form.useForm();
  const [form] = Form.useForm();
  const [status, setStatus] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCancelTrip, setIsModalCancelTrip] = useState(false);
  const [isModalVisibleDepart, setIsModalVisibleDepart] = useState(false);
  const [isModalVisibleDepartInform, setIsModalVisibleDepartInform] = useState(false);
  const [onValueDepart, setOnValueDepart] = useState();
  const [statusDetail, setStatusDetail] = useState();
  const [isOpenModalExtra, setOpenModalExtra] = useState(false);
  const [idDetail, setIdDetail] = useState('');
  const [routeDetail, setRouteDetail] = useState('');
  const [scheduleDetailId, setSheduleDetailid] = useState('');
  const [timeDepartDetail, setTimeDepartDetail] = useState('');
  const [selectBus, setSelectBus] = useState<undefined | string>(undefined);
  const [keywordDriver, setKeywordDriver] = useState<any>();
  const [keywordExtraDriver, setKeywordExtraDriver] = useState<any>();
  const [keywordRoute, setKeywordRoute] = useState<any>();
  const [KeywordBusList, setKeywordBusList] = useState<any>();
  const [isDepart, setIsDepart] = useState<boolean>(false);
  const [busIdSelected, setBusIdSelected] = useState<any>();
  const [busDataDetail, setBusDataDetail] = useState<any>();
  const [autoOperated, setAutoOperated] = useState<boolean>(false);
  const [searchBusType, setSearchBusType] = useState<number | undefined>();
  const [isExtraDepart, setIsExtraDepart] = useState<boolean>(false);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [travelTime, setTravelTime] = useState<string>('');
  const [provinceId, setProvinceId] = useState<any>('');
  const [tripId, setTripId] = useState<any>(undefined);
  const busId = Form.useWatch('bus_id', form);
  const driverList = Form.useWatch('drivers', form);
  const extraDriverList = Form.useWatch('extra_drivers', form);
  const paramsDate = dayjs(Form.useWatch('date_search', formSearch)).format(DateFormatSearch);
  const dateSearch = dayjs(Form.useWatch('date_search', formSearch)).format(YearFormat);
  const dateConvert = dayjs(Form.useWatch('date_search', formSearch)).format(DateFormat);
  const parsedParamsDate = dayjs(paramsDate, 'DD-MM-YYYY');
  const [isShowAlert, setIsShowAlert] = useState(false);
  const isSameDate = parsedParamsDate.format('DD-MM-YYYY') === now.format('DD-MM-YYYY');
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);

  const filterOptionRoute = (
    input: string,
    option?: {
      label?: any;
      value?: any;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  function onSearchRoute(value: any) {
    setKeywordRoute(value);
  }

  const onChangeSearchRoute = (value: any) => {
    setPage(1);
    setProvinceId(value);
  };
  const RouteOptions: SelectProps['options'] = listAllRoute?.length
    ? listAllRoute?.map((item: any) => {
        return {
          value: item.id,
          label: `${item?.depart_point} - ${item?.arrive_point}`,
        };
      })
    : [];

  const onChangeStatus = (value: any | undefined) => {
    setStatus(value);
  };
  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const cancelModal = () => {
    setOpenCreate(false);
    setIsExtraDepart(false);
    setIsDetail(false);
    setIsEdit(false);
    setBusIdSelected(undefined);
    form.resetFields();
  };
  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      form.scrollToField(firstErrorField.name);
    }
  };

  const handleOkModal = () => {
    if (idDetail) dispatch(updateStatusTrip({ schedule_id: idDetail, status: statusDetail }));
  };

  function onDepart() {
    setIsDepart(true);
    setIsModalVisibleDepartInform(true);
  }

  const handleModalCancel = useCallback(() => {
    setIsDetail(false);
    setStatusDetail(undefined);
    setIsModalCancelTrip(false);
    setIdDetail('');
    setAutoOperated(false);
    setRouteDetail('');
    setSheduleDetailid('');
    setTimeDepartDetail('');
    setTravelTime('');
    setOpenCreate(false);
    setBusIdSelected(undefined);
    setIsModalVisibleDepart(false);
    setIsModalVisibleDepartInform(false);
    setIsModalVisible(false);
    form.resetFields();
    cancelModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, form, setOpenCreate]);

  const onFieldDetail = (record: any) => {
    setIsDetail(true);
    setStatusDetail(record?.status);
    setTravelTime(record?.travel_time ?? '00:00');
    setIdDetail(record?.schedule_id);
    setAutoOperated(record?.auto_operated);
    setBusDataDetail(record?.bus);
    setBusIdSelected(record?.bus_type?.id);
    setRouteDetail(`${record?.route?.depart_point} - ${record?.route?.arrive_point}`);
    setSheduleDetailid(record?.schedule_id);
    setTimeDepartDetail(record.depart_time);
    setTripId(record?.trip_id);
    form.setFieldsValue({
      bus_id: record?.bus?.id,
      bus_id_selected: record?.bus?.id,
      plate_number: record?.bus?.plate_number,
      bus_name_selected: record?.bus?.name,
      bus_type_selected: record?.bus_type?.name,
      drivers: record?.drivers,
      extra_drivers: record?.extra_drivers,
    });
    setOpenCreate(true);
  };

  const onRegister = (values: any) => {
    values.depart_time = dayjs(values?.depart_time, 'HH:mm').format('HH:mm');
    const driver = values?.drivers?.map((item: any) => {
      return item?.id;
    });
    if (!driver?.length) {
      return dispatch(
        setToast({
          message: 'Thiếu thông tin tài xế',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    }
    const extraDriver = values?.extra_drivers?.map((item: any) => {
      return item?.id;
    });
    const checkExtraDriver = extraDriver.some((ele: any) => ele === undefined);
    if (checkExtraDriver) {
      return dispatch(
        setToast({
          message: 'Thiếu thông tin phụ xe',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    }
    const params: any = {
      schedule_id: scheduleDetailId,
      bus_id: busId,
      drivers: driver,
      trip_id: tripId,
      extra_drivers: extraDriver,
      status: 'not_departed',
      depart_date: paramsDate.split('-').reverse().join('-'),
    };
    if (isDepart) {
      params['status'] = 'departed';
      dispatch(departTrip(params));
      setIsDepart(false);
    } else if (isExtraDepart) {
      params['arrive_time'] = values.arrive_time;
      params['travel_time'] = travelTime;
      params['depart_time'] = values.depart_time;
      dispatch(createExtraTrip(params));
    } else {
      // if (values.bus_id_selected) {
      //   params['bus_id'] = values.bus_id_selected;
      // }
      dispatch(createTrip(params));
    }
  };

  const onRegisterDepart = (values: any) => {
    const driver = values?.drivers?.map((item: any) => {
      return item?.id;
    });
    const extraDriver = values?.extra_drivers?.map((item: any) => {
      return item?.id;
    });
    const params = {
      schedule_id: values.schedule_id,
      bus_id: values.bus?.id,
      drivers: driver,
      extra_drivers: extraDriver,
      status: 'departed',
      depart_date: paramsDate.split('-').reverse().join('-'),
    };
    dispatch(departTrip(params));
    setIsDepart(false);
  };
  const cancelModalCancelTrip = () => {
    setIsModalCancelTrip(false);
  };
  const handleOkModalTrip = () => {
    dispatch(
      cancelTrip({
        schedule_id: scheduleDetailId,
        depart_date: paramsDate.split('-').reverse().join('-'),
      }),
    );
  };
  const columns: ColumnsType<TripParams> = [
    {
      title: 'Giờ đi',
      width: '8%',
      key: 'depart_trip',
      align: 'center',
      sorter: (a, b) => a?.depart_time.localeCompare(b?.depart_time),
      render: (record: any) => <Space size="middle">{record?.depart_time?.slice(0, 5)}</Space>,
    },
    {
      title: 'Tuyến đường',
      width: '18%',
      key: 'route',
      sorter: (a, b) => a?.depart_time.localeCompare(b?.depart_time),
      render: (record: any) => (
        <Space size="middle">
          {record?.is_diagram ? (
            `${record?.route?.depart_point} - ${record?.route?.arrive_point}`
          ) : (
            <Typography
              style={
                record?.status === 'departed'
                  ? { color: '#3AB54A' }
                  : record?.status === 'canceled'
                  ? { color: '#949494' }
                  : {}
              }
            >
              {record?.route?.depart_point} -{' '}
              <span>
                {record?.route?.arrive_point}
                <span className="red">*</span>
              </span>
            </Typography>
          )}
        </Space>
      ),
    },
    {
      title: 'Loại xe',
      key: 'busType',
      width: '18%',
      sorter: (a, b) => a?.bus_type?.name.localeCompare(b?.bus_type?.name),
      render: (record: any) => <Space size="middle">{record?.bus_type?.name}</Space>,
    },
    {
      title: 'Biển số',
      key: 'plateNumber',
      width: '6%',
      render: (record: any) => <Space size="middle">{record?.bus?.plate_number}</Space>,
    },
    {
      title: 'Tài xế',
      key: 'drivers',
      width: '14%',
      render: (record: any) => {
        return (
          <Space size="middle" style={{ display: 'block' }} key={record?.no}>
            {record?.drivers?.map((user: any, index: number) => (
              <span key={user?.id || index}>{user?.name}</span>
            ))}
          </Space>
        );
      },
    },
    {
      title: 'Phụ xe',
      key: 'extra_drivers',
      width: '14%',
      render: (record: any) => {
        return (
          <Space size="middle" style={{ display: 'block' }}>
            {record?.extra_drivers?.map((user: any, index: number) => (
              <span key={user?.id || index}>{user?.name}</span>
            ))}
          </Space>
        );
      },
    },
    {
      title: '',
      width: '1%',
      key: 'depart-trip',
      render: (record: any) => (
        <Space style={{ marginLeft: '5px' }}>
          {
            <Typography>
              {record?.status === 'departed' ? (
                record?.is_depart_manual ? (
                  <Tooltip title={'Xuất bến bằng tay'} key={'xbt'} placement="top">
                    <Image src={iconManualDepart} alt="" preview={false} width={20} height={20} />
                  </Tooltip>
                ) : (
                  <Tooltip title={'Xuất bến tự động'} key={'xba'} placement="top">
                    <Image src={iconAutoDepart} preview={false} width={20} height={20} />
                  </Tooltip>
                )
              ) : record?.status === 'not_departed' && record?.auto_operated ? (
                <Tooltip title={'Điều hành tự động'} key={'dht'} placement="top">
                  <Image src={iconAuto} preview={false} width={20} height={20} />
                </Tooltip>
              ) : record?.is_cancel_manual && record?.status === 'canceled' ? (
                <Tooltip title={'Hủy chuyến bằng tay'} key={'hbt'} placement="top">
                  <Image src={iconManualCancel} preview={false} width={20} height={20} />
                </Tooltip>
              ) : record?.status === 'canceled' ? (
                <Tooltip title={'Hủy chuyến tự động'} key={'htd'} placement="top">
                  <Image src={iconAutoCancel} preview={false} width={20} height={20} />
                </Tooltip>
              ) : (
                ''
              )}
            </Typography>
          }
        </Space>
      ),
    },
    {
      title: 'Tình trạng',
      sorter: (a, b) => a.status - b.status,
      width: '12%',
      render: (record: any) => (
        <Space>
          {
            <Typography>
              <span
                style={
                  record?.status === 'canceled'
                    ? { color: '#D81717' }
                    : record?.status === 'departed'
                    ? { color: '#3AB54A' }
                    : {}
                }
              >
                {` ${getStatusTrip(record?.status)}`}
              </span>
            </Typography>
          }
        </Space>
      ),
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      width: '16%',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle">
          {record?.status === 'departed' ? (
            <>
              <Tooltip title="Xem chi tiết">
                <Link
                  onClick={() => {
                    onFieldDetail(record);
                  }}
                >
                  <Image width={17} height={17} className="icon-edit mr-20" src={icInfo} preview={false} />
                </Link>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Chỉnh sửa">
                <Link
                  onClick={() => {
                    onFieldDetail(record);
                  }}
                >
                  <Image width={17} height={17} className="icon-edit mr-20" src={icEdit} preview={false} />
                </Link>
              </Tooltip>
              {record?.status === 'not_departed' && (
                <Tooltip title="Xuất bến" className="disabled">
                  <Link
                    onClick={() => {
                      setIsModalVisibleDepart(true);
                      setOnValueDepart(record);
                      setIsEdit(record?.is_edit);
                    }}
                  >
                    <Image width={17} height={17} className="icon-edit" src={icArrow} preview={false} />
                  </Link>
                </Tooltip>
              )}
            </>
          )}
        </Space>
      ),
      align: 'center',
    },
  ];

  const renderRowTable = (record: any) => {
    return record.status === 'canceled'
      ? 'bg-canceled'
      : record.status === 'departed'
      ? 'bg-departed'
      : record?.auto_operated
      ? ''
      : '';
  };
  const cancelModalAlert = () => {
    setOpenModalAlert(false);
  };
  const handleModalCancelModalExtra = () => {
    setOpenModalExtra(false);
  };
  const handleConfirmExtra = () => {
    navigate('/bus-management/schedules');
  };
  const handleChangeBus = (value: string, option: any) => {
    setSelectBus(value);
    if (isExtraDepart) {
      form.setFieldsValue({
        bus_name_selected: option.bus_name,
        bus_type_selected: option.bus_type,
        bus_id: option.value,
      });
    }
    form.setFieldsValue({
      bus_name_selected: option.bus_name,
      bus_id: value,
    });
  };

  function onSearchBus(value: any) {
    setKeywordBusList(value);
  }
  const filterOptionBus = (
    input: string,
    option?: {
      label: string;
      value: string;
      bus_name: string;
      bus_type: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const onChangeBusType = (value: number) => {
    setSearchBusType(value);
    setPage(1);
  };
  const BusOptions: SelectProps['options'] = listAllAvailableBusType?.length
    ? listAllAvailableBusType?.map((item: any) => {
        return {
          value: item.id,
          label: `${item.name}`,
        };
      })
    : [];

  const filterOptionDriver = (
    input: string,
    option?: {
      label: string;
      value: string;
      date_of_birth: string;
      phone: string;
      date_expired_license: string;
      license_class: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onSearchDriver = (value: string) => {
    setKeywordDriver(value);
  };
  const handleChangeDriver = (option: any, index: any) => {
    const currentDriverValues = form.getFieldValue('drivers');
    currentDriverValues[index] = {
      id: option.value,
      name: option.label,
      phone: option.phone,
      date_of_birth: dayjs(option.date_of_birth).format(DateFormat),
      license_class: option.license_class,
      date_expired_license: dayjs(option.date_expired_license).format(DateFormat),
    };
    form.setFieldsValue({
      drivers: currentDriverValues,
    });
  };

  const onSearchExtraDriver = (value: string) => {
    setKeywordExtraDriver(value);
  };
  const handleChangeExtraDriver = (option: any, index: any) => {
    const formData = form.getFieldsValue();
    const { extra_drivers } = formData;
    extra_drivers[index] = {
      id: option.value,
      name: option.label,
      phone: option.phone,
      date_of_birth: dayjs(option.date_of_birth).format(DateFormat),
    };
    form.setFieldsValue({
      extra_drivers: extra_drivers,
    });
  };

  useEffect(() => {
    dispatch(
      fetchListTrip({
        route_id: provinceId,
        date: dateSearch,
        bus_type_id: searchBusType,
        status: status,
        page: page,
        limit: limit,
      }),
    );
  }, [dispatch, provinceId, searchBusType, dateSearch, status, page, limit]);
  useEffect(() => {
    dispatch(
      fetchListBus({
        keyword: KeywordBusList,
        status: true,
        bus_type_id: busIdSelected,
      }),
    );
  }, [dispatch, KeywordBusList, busIdSelected]);

  useEffect(() => {
    dispatch(getAllAvailableBusType({ keyword: KeywordBusList }));
  }, [dispatch, KeywordBusList]);

  useEffect(() => {
    dispatch(
      fetchListDriver({
        keyword: keywordDriver,
        status: Constant.STATUS_ACTIVE,
        type: DRIVER,
      }),
    );
  }, [dispatch, keywordDriver]);

  useEffect(() => {
    dispatch(
      fetchListExtraDriver({
        keyword: keywordExtraDriver,
        status: Constant.STATUS_ACTIVE,
        type: EXTRA_DRIVER,
      }),
    );
  }, [dispatch, keywordExtraDriver]);

  useEffect(() => {
    dispatch(getAllRoute({ keyword: keywordRoute }));
  }, [dispatch, keywordRoute]);
  const handleCancelModalConfirm = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (statusCreate === Constant.DEFAULT_STATUS) {
      handleModalCancel();
      dispatch(resetRegisterCreateTrip());
      dispatch(
        fetchListTrip({
          route_id: provinceId,
          date: dateSearch,
          bus_type_id: searchBusType,
          status: status,
          page: page,
          limit,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreate, page, status, provinceId, dateSearch, searchBusType, limit]);

  useEffect(() => {
    if (statusExtra === Constant.DEFAULT_STATUS) {
      handleModalCancel();
      dispatch(resetRegisterCreateTrip());
      dispatch(
        fetchListTrip({
          route_id: provinceId,
          date: dateSearch,
          bus_type_id: searchBusType,
          status: status,
          page: page,
          limit,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusExtra, page, status, provinceId, dateSearch, searchBusType, limit]);

  useEffect(() => {
    if (statusUpdate === Constant.DEFAULT_STATUS) {
      handleModalCancel();
      handleCancelModalConfirm();
      dispatch(
        fetchListTrip({
          route_id: provinceId,
          date: dateSearch,
          bus_type_id: searchBusType,
          status: status,
          page: page,
          limit,
        }),
      );
      dispatch(resetUpdateTrip());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdate, page, status, provinceId, dateSearch, searchBusType, limit]);

  const updateArriveTime = (e: any) => {
    let inputValue = dayjs(e, 'HH:mm').format('HH:mm');
    if (inputValue?.length > 5) {
      inputValue = inputValue?.slice(0, 5);
    }
    inputValue = inputValue?.replace(/[^\d:]/g, '');
    const timePattern = /^([0-9]{1,}|[01]?[0-9]|2[0-4]):([0-5][0-9])$/;
    if (timePattern.test(inputValue)) {
      form.setFieldsValue({
        arrive_time: calculateTimeSum(travelTime, inputValue),
      });
    }
  };
  useEffect(() => {
    if (message === 'Tài khoản của bạn đã không đủ số dư. Vui lòng nạp thêm tiền để sử dụng dịch vụ.') {
      setOpenModalAlert(true);
      setOpenCreate(false);
      setIsModalVisibleDepart(false);
      dispatch(resetUpdateTrip());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (statusDeleteTrip === Constant.DEFAULT_STATUS) {
      handleModalCancel();
      dispatch(resetCancelTrip());
      dispatch(
        fetchListTrip({
          route_id: provinceId,
          date: dateSearch,
          bus_type_id: searchBusType,
          status: status,
          page: page,
          limit,
        }),
      );
    }
  }, [statusDeleteTrip, page, status, provinceId, dateSearch, searchBusType, limit, dispatch]);

  return (
    <Layout className="min-vh-70 list_trip">
      <Card>
        <Row gutter={8} className="fw-500">
          <Col>
            <Button
              type="text"
              className={`fw-500 ${status === undefined ? 'btn-status-active-green' : 'text-color'}`}
              onClick={() => {
                onChangeStatus(undefined);
                setPage(1);
              }}
            >
              {t('Tất cả')}
            </Button>
          </Col>
          <Col className="border-start border-end">
            <Button
              type="text"
              className={`fw-500 ${status === 'not_departed' ? 'btn-status-active-green' : 'text-color'}`}
              onClick={() => {
                onChangeStatus('not_departed');
                setPage(1);
              }}
            >
              {t('Chưa xuất bến')}
            </Button>
          </Col>
          <Col className="border-start border-end">
            <Button
              type="text"
              className={`fw-500 ${status === 'departed' ? 'btn-status-active-green' : 'text-color'}`}
              onClick={() => {
                onChangeStatus('departed');
                setPage(1);
              }}
            >
              {t('Đã xuất bến')}
            </Button>
          </Col>
          <Col>
            <Button
              type="text"
              className={`fw-500 ${status === 'canceled' ? 'btn-status-lock-red' : 'text-color'}`}
              onClick={() => {
                onChangeStatus('canceled');
                setPage(1);
              }}
            >
              {t('Đã hủy')}
            </Button>
          </Col>
        </Row>
        <Form
          className="mt-30 mx-3 trip-search"
          form={formSearch}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          colon={false}
        >
          <Row gutter={10} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={12} sm={12} md={4}>
              <Form.Item name="route_id_search">
                <Form.Item name="depart_province">
                  <Select
                    className="input-h36 mt-2"
                    showSearch
                    onSearch={onSearchRoute}
                    filterOption={filterOptionRoute}
                    placeholder="Chọn tuyến đường"
                    size="large"
                    onChange={onChangeSearchRoute}
                    style={{ width: '100%' }}
                    options={[{ value: '', label: 'Tất cả' }, ...RouteOptions]}
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Form.Item name="date_search" className="data-picker">
                <DatePicker
                  allowClear={false}
                  className="w-100 input-height fw-500 text-color-default"
                  defaultValue={dayjs(new Date())}
                  format={DateFormat}
                  cellRender={(current: any) => {
                    // @ts-ignore
                    let d = Lunar.fromDate(new Date(current));
                    const lunar = d.getDay();
                    const lunarMonth = d.getMonth();
                    return (
                      <div>
                        <div className="fs-14 fw-500">{current.date()}</div>
                        <div className="fs-12 lunar fw-500">
                          {lunar}
                          {
                            // eslint-disable-next-line eqeqeq
                            lunar == 1 || current.date() == 1 ? `/${lunarMonth}` : null
                          }
                        </div>
                      </div>
                    );
                  }}
                  placeholder={t('Chọn ngày')}
                  onChange={() => setPage(1)}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Form.Item name="bus_type_id_search">
                <Select
                  className="input-h36"
                  showSearch
                  filterOption={filterOption}
                  placeholder="Loại xe"
                  size="large"
                  onChange={onChangeBusType}
                  style={{ width: '100%' }}
                  options={[{ value: '', label: 'Tất cả' }, ...BusOptions]}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 13)} />
            </Col>
          </Row>
        </Form>
        <Table
          locale={{
            triggerDesc: 'Sắp xếp tăng dần',
            triggerAsc: 'Hủy sắp xếp',
            cancelSort: 'Sắp xếp giảm dần',
          }}
          dataSource={trips?.length > 0 ? trips : []}
          rowKey="id"
          rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          className="mx-3 list-trips"
          loading={loading}
        />
        <Modal
          centered
          className="create-trips"
          open={openCreate}
          maskClosable={false}
          onOk={() => setOpenCreate(false)}
          onCancel={() => cancelModal()}
          styles={{
            body: { maxHeight: '90vh', overflowY: 'auto', overflowX: 'hidden' },
          }}
          width={1210}
          footer={[]}
        >
          <Row>
            <Col>
              <Typography.Text className="fw-600 fs-20 text-color-gray-3A3A3A">
                {t(`Chi tiết thông tin chuyến`)}
              </Typography.Text>
            </Col>

            <Col xs={18} sm={18} md={18} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div style={{ marginTop: '-10px' }}>
                <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 13)} />
              </div>
            </Col>
          </Row>

          <Form
            className="mg-16 mt-30 fw-500 text-color-default"
            form={form}
            labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
            requiredMark={false}
            onFinish={onRegister}
            initialValues={{
              extra_drivers: [],
              drivers: [],
              arrive_time: '00:00',
            }}
            onFieldsChange={(changedFields, allFields) => {
              scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
            }}
            colon={false}
          >
            <Row className="d-flex align-items-center mb-3">
              <Col span={isExtraDepart ? 24 : 17} className="d-flex align-items-center align-self-end">
                <Row gutter={30}>
                  <Col>
                    <Row>
                      <span>Tuyến:</span>
                      <span className="fw-600 ms-3"> {routeDetail}</span>
                    </Row>
                  </Col>
                  <Col>
                    <Row gutter={4}>
                      <span>Ngày: </span>
                      <span className="fw-600 ms-3"> {dateConvert} </span>
                    </Row>
                  </Col>
                  <Col>
                    <Row gutter={4}>
                      <span>Tổng thời gian: </span>
                      <span className="fw-600 ms-3"> {travelTime} </span>
                    </Row>
                  </Col>
                  {isExtraDepart && (
                    <Col span={4}>
                      <Row gutter={10}>
                        <Col>
                          <span>Giờ đi:</span>
                        </Col>
                        <Col span={13} className="mt--5">
                          <Form.Item name={'depart_time'}>
                            <TimePicker
                              className="time-picker"
                              suffixIcon={null}
                              showNow={false}
                              onChange={updateArriveTime}
                              needConfirm={false}
                              format={'HH:mm'}
                              placeholder="00:00"
                              required={true}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {isExtraDepart && (
                    <Col span={4}>
                      <Row gutter={10}>
                        <Col>
                          <span>Giờ đến:</span>
                        </Col>
                        <Col span={12} className="mt--5">
                          <Form.Item name={'arrive_time'}>
                            <Input disabled={true} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {!isExtraDepart && (
                    <Col>
                      <Row>
                        <span>{t('Giờ đi : ')}</span>
                        <span className="fw-600 ms-3"> {timeDepartDetail.slice(0, 5)} </span>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
              {!isExtraDepart && (
                <Col span={7} className="d-flex justify-content-between">
                  {idDetail && statusDetail === 'not_departed' && (
                    <Button key="ok" className="height-btn-36" onClick={onDepart}>
                      <Image width={13} height={16} src={icArrow} preview={false} className="mb-1 me-1 mr-20" />
                      <span className="ms-2 text-color-default">{t('Xuất bến')}</span>
                    </Button>
                  )}
                  {isOutDate(dateSearch) && !isExtraDepart && (
                    <Button
                      key="trips"
                      className="height-btn-36"
                      onClick={() => {
                        setIsExtraDepart(true);
                        !isShowAlert && setOpenModalExtra(true);
                        form.resetFields();
                      }}
                    >
                      <Image width={13} height={16} src={icPlus} preview={false} className="mb-1 me-1 mr-20" />
                      <span className="ms-2 text-color-default">{t('Tăng cường chuyến')}</span>
                    </Button>
                  )}
                </Col>
              )}
            </Row>
            <Spin spinning={false} className="mb-5">
              <Typography.Text className="mt-4 text-color-gray-7B7B7B">{t(`Chọn xe`)}</Typography.Text>
              <Row gutter={24} className="mt-2">
                <Col xs={8} sm={8} md={4}>
                  <span>{t(`Biển số xe`)}</span>
                  <Form.Item
                    name="plate_number"
                    className="fw-500 mt-1"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng chọn xe'),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onChange={handleChangeBus}
                      value={selectBus}
                      placeholder={t('Tìm để chọn nhà xe')}
                      onSearch={onSearchBus}
                      filterOption={filterOptionBus}
                      disabled={statusDetail !== 'not_departed' && !isExtraDepart}
                      options={
                        buses?.length > 0
                          ? buses?.map((item: any) => ({
                              value: item?.id,
                              bus_id: item?.id,
                              label: item?.plate_number,
                              bus_type: item?.bus_type?.name,
                              bus_name: item?.name,
                            }))
                          : []
                      }
                    />
                  </Form.Item>
                  <Form.Item name="bus_id" className={'d-none'}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="bus_id_selected" className={'d-none'}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={6}>
                  <span>{t(`Loại xe`)}</span>
                  <Form.Item name="bus_type_selected">
                    <Input placeholder={t('Loại xe')} disabled className="mt-1" />
                  </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={6}>
                  <span>{t(`Tên xe`)}</span>
                  <Form.Item name="bus_name_selected">
                    <Input placeholder={t('Tên xe')} disabled className="mt-1" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Typography.Text className="text-color-gray-7B7B7B">{t(`Chọn tài xế`)}</Typography.Text>
              </Row>
              <Form.List name="drivers">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(({ key, name, ...restField }) => (
                      <Row gutter={24} className="" key={key}>
                        <Col xs={8} sm={8} md={6}>
                          <span className="required">{t(`Họ và tên`)}</span>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng chọn tài xế',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              onChange={(value: any, option: any) => handleChangeDriver(option, name)}
                              value={selectBus}
                              className="mt-1"
                              placeholder={t('Chọn tài xế')}
                              onSearch={onSearchDriver}
                              filterOption={filterOptionDriver}
                              disabled={statusDetail !== 'not_departed' && !isExtraDepart}
                              options={
                                drivers?.length > 0
                                  ? drivers?.map((item: any) => ({
                                      value: item?.id,
                                      label: item?.name,
                                      date_of_birth: item?.date_of_birth,
                                      phone: item?.phone,
                                      date_expired_license: item?.date_expired_license,
                                      license_class: item?.license_class,
                                    }))
                                  : []
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <span>{t(`Số điện thoại`)}</span>
                          <Form.Item {...restField} name={[name, 'phone']}>
                            <Input placeholder="Số điện thoại" disabled className="mt-1" />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <span>{t(`Ngày sinh`)}</span>
                          <Form.Item {...restField} name={[name, 'date_of_birth']}>
                            <Input placeholder="Ngày sinh" disabled className="mt-1" />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <span>{t(`Hạng bằng lái`)}</span>
                          <Form.Item {...restField} name={[name, 'license_class']}>
                            <Input placeholder="Hạng bằng lái" disabled className="mt-1" />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <span>{t(`Hạn bằng lái`)}</span>
                          <Form.Item {...restField} name={[name, 'date_expired_license']}>
                            <Input placeholder="Hạn bằng lái" disabled className="mt-1" />
                          </Form.Item>
                        </Col>
                        {(statusDetail === 'not_departed' || isExtraDepart) && (
                          <Tooltip title="Xóa">
                            <Button
                              type="text"
                              onClick={() => remove(name)}
                              className="mt-20 h-btn-48  ps-1 pe-1"
                              disabled={statusDetail !== 'not_departed'}
                            >
                              <Image width={36} height={36} src={icCancel} preview={false} className="mt-1" />
                            </Button>
                          </Tooltip>
                        )}
                      </Row>
                    ))}
                    {(fields?.length <= 2 && statusDetail !== 'departed' && statusDetail !== 'canceled') ||
                    (fields?.length <= 2 && isExtraDepart) ? (
                      <Form.Item>
                        <Button
                          type="text"
                          onClick={() => add()}
                          className="w-160 h-btn-48 text-color-green-default d-flex justify-content-start align-items-center"
                          disabled={
                            driverList?.length && drivers?.length ? driverList?.length === drivers?.length : false
                          }
                        >
                          Thêm tài xế
                          <Image width={11} height={11} src={icPlusGreen} preview={false} className="mb-3 ms-2" />
                        </Button>
                      </Form.Item>
                    ) : null}
                  </>
                )}
              </Form.List>

              <Row className="mt-4 mb-3">
                <Typography.Text className="text-color-gray-7B7B7B">{t(`Chọn phụ xe`)}</Typography.Text>
              </Row>
              <Form.List name="extra_drivers">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(({ key, name, ...restField }) => (
                      <Row gutter={24} className="" key={key}>
                        <Col xs={8} sm={8} md={6}>
                          <span className="required">{t(`Họ và tên`)}</span>
                          <Form.Item {...restField} name={[name, 'name']}>
                            <Select
                              showSearch
                              className="mt-1"
                              onChange={(value: any, option: any) => handleChangeExtraDriver(option, name)}
                              value={selectBus}
                              placeholder={t('Chọn phụ xe')}
                              onSearch={onSearchExtraDriver}
                              filterOption={filterOptionDriver}
                              disabled={statusDetail !== 'not_departed' && !isExtraDepart}
                              options={
                                extra_drivers?.length > 0
                                  ? extra_drivers?.map((item: any) => ({
                                      value: item?.id,
                                      label: item?.name,
                                      date_of_birth: item?.date_of_birth,
                                      phone: item?.phone,
                                      date_expired_license: item?.date_expired_license,
                                      license_class: item?.license_class,
                                    }))
                                  : []
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <span>{t(`Số điện thoại`)}</span>
                          <Form.Item {...restField} name={[name, 'phone']}>
                            <Input placeholder="Số điện thoại" disabled className="mt-1" />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <span>{t(`Ngày sinh`)}</span>
                          <Form.Item {...restField} name={[name, 'date_of_birth']}>
                            <Input placeholder="Ngày sinh" disabled className="mt-1" />
                          </Form.Item>
                        </Col>
                        {(statusDetail === 'not_departed' || isExtraDepart) && (
                          <Tooltip title="Xóa">
                            <Button type="text" onClick={() => remove(name)} className="mt-20 h-btn-48 ps-1 pe-1">
                              <Image width={36} height={36} src={icCancel} preview={false} className="mt-1" />
                            </Button>
                          </Tooltip>
                        )}
                      </Row>
                    ))}
                    {(fields?.length <= 2 && statusDetail !== 'departed' && statusDetail !== 'canceled') ||
                    (fields?.length <= 2 && isExtraDepart) ? (
                      <Form.Item>
                        <Button
                          type="text"
                          onClick={() => add()}
                          className="w-160 h-btn-48 text-color-green-default d-flex justify-content-start align-items-center"
                          disabled={
                            extraDriverList?.length && extraDriverList?.length
                              ? extraDriverList?.length === extra_drivers?.length
                              : false
                          }
                        >
                          Thêm phụ xe
                          <Image width={11} height={11} src={icPlusGreen} preview={false} className="mb-3 ms-2" />
                        </Button>
                      </Form.Item>
                    ) : null}
                  </>
                )}
              </Form.List>
            </Spin>
            {(statusDetail !== 'departed' || isExtraDepart || !isEdit) && (
              <Row className="ant-modal-footer z-index1200 mt-5">
                {isDetail && statusDetail !== 'departed' && !isExtraDepart && isSameDate && (
                  <Col span={6} className="pl-30 d-flex justify-content-start">
                    {statusDetail === 'not_departed' ? (
                      <Button
                        className="btn-block-red w-160 h-btn-48"
                        danger
                        type="primary"
                        htmlType="button"
                        onClick={() => setIsModalVisible(true)}
                      >
                        <Image src={icNope} preview={false} className="pb-1 pe-2" />
                        {t('Hủy chuyến')}
                      </Button>
                    ) : (
                      <>
                        <Button
                          className="btn-active-green w-160 h-btn-48"
                          danger
                          type="primary"
                          htmlType="button"
                          onClick={() => setIsModalVisible(true)}
                        >
                          <Image src={IcUnLock} preview={false} className="pb-1 pe-2" />
                          {t('Mở chuyến')}
                        </Button>
                      </>
                    )}
                    {idDetail &&
                      statusDetail !== 'canceled' &&
                      statusDetail !== 'departed' &&
                      !autoOperated &&
                      busDataDetail && (
                        <Button
                          className="btn-block-red w-160 h-btn-48 ml-12"
                          danger
                          type="primary"
                          htmlType="button"
                          onClick={() => setIsModalCancelTrip(true)}
                        >
                          <Image src={icNope} preview={false} className="pb-1 pe-2" />
                          {t('Hủy điều hành')}
                        </Button>
                      )}
                  </Col>
                )}
                <Col
                  span={isDetail && !isExtraDepart && isSameDate ? 13 : 23}
                  className="d-flex justify-content-end"
                  style={
                    idDetail && !isExtraDepart ? { columnGap: '12px', paddingRight: '15px' } : { columnGap: '12px' }
                  }
                >
                  <Button
                    key="cancel"
                    className="w-200 h-btn-48"
                    disabled={loadingCreate}
                    onClick={() => cancelModal()}
                  >
                    {t('Đóng')}
                  </Button>
                  {(statusDetail === 'not_departed' || isExtraDepart) && (
                    <Button
                      key="ok"
                      type="primary"
                      className="w-200 h-btn-48 btn-success"
                      disabled={loadingCreate}
                      htmlType="submit"
                    >
                      {t('Lưu')}
                    </Button>
                  )}
                </Col>
                <Col span={6}>
                  <Button key="ok" type="primary" className="d-none w-270 h-btn-48 btn-success"></Button>
                </Col>
              </Row>
            )}
            <ModalExtraConfirm
              modalOpen={isOpenModalExtra}
              onCancel={handleModalCancelModalExtra}
              onOk={handleConfirmExtra}
              form={form}
              isShowAlert={isShowAlert}
              setIsShowAlert={setIsShowAlert}
            />
          </Form>
        </Modal>
      </Card>
      <ModalConfirm
        loading={loadingUpdate}
        modalOpen={isModalVisible}
        okText={statusDetail == 'not_departed' ? t('Hủy chuyến') : t('Mở chuyến')}
        cancelText={t('Đóng')}
        modalTitle={statusDetail == 'not_departed' ? t('Hủy chuyến') : t('Mở chuyến')}
        modalContent={
          statusDetail == 'not_departed'
            ? t('Bạn đang thao tác hủy chuyến này, bạn có chắc chắn muốn hủy chuyến?')
            : t('Bạn đang thao tác mở chuyến này, bạn có chắc chắn muốn mở chuyến ?')
        }
        onCancel={handleModalCancel}
        onOk={handleOkModal}
      />
      <ModalConfirm
        loading={loadingUpdate}
        modalOpen={isModalCancelTrip}
        okText={t('Hủy điều hành')}
        cancelText={t('Đóng')}
        modalTitle={'Hủy điều hành'}
        modalContent={t('Bạn đang thao tác hủy điều hành, các cấu hình thiết lập trước đó sẽ bị hủy ?')}
        onCancel={cancelModalCancelTrip}
        onOk={handleOkModalTrip}
      />

      <ModalConfirm
        loading={loadingUpdate}
        modalOpen={isModalVisibleDepart}
        okText={t('Xuất bến')}
        cancelText={t('Đóng')}
        modalTitle={t('Xuất bến')}
        modalContent={t('Bạn đang thao tác xuất bến chuyến đi này, bạn có chắc chắn muốn xuất bến?')}
        onCancel={handleModalCancel}
        onOk={() => onRegisterDepart(onValueDepart)}
      />

      <ModalConfirm
        loading={loadingUpdate}
        modalOpen={isModalVisibleDepartInform}
        okText={t('Xuất bến')}
        cancelText={t('Đóng')}
        modalTitle={t('Xuất bến')}
        modalContent={t('Bạn đang thao tác xuất bến chuyến đi này, bạn có chắc chắn muốn xuất bến?')}
        onCancel={handleModalCancel}
        onOk={() => form.submit()}
      />
      <ModalAlertBalance modalOpen={openModalAlert} onCancel={cancelModalAlert} onOk={() => setOpenModalAlert(false)} />
    </Layout>
  );
}

export default ListTrip;
