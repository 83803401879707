import { UploadOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { RcFile } from "antd/es/upload";
import { UploadFile } from "antd/lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/styles/desktop/busCompany.scss";
import "../../assets/styles/listAgent.scss";
import {
  phoneRequiredRules,
  validatorEmail,
} from "../../config/validators/validators";
import { getBase64 } from "../../lib/utils";
import { createAgentApi } from "../../api/agent";
import { checkAuthorization } from "../../api";
import {
  validateNameAgent,
  validateAddressAgent,
  validateCommissionPercentAgent,
} from "../../config/validators/validateAgent";
import { ACTION_NAME, messageCreateSuccess } from "../../config/message";
import { AgentType, Constant } from "../../config/constant";
import TutorialsVideo from "../../components/TutorialsVideo";
import icClose from "../../assets/images/listOffice/icClose.svg";
import { handleGetUrlGuide } from "../../utils/helpers";


function CreateAgent({ openCreate, setOpenCreate, handleListAgentAPI, listTutorials }: any) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageLogo, setImageLogo] = useState<any>();

  const onChange = (value: string) => {
  };

  const onSearch = (value: string) => {
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    // setImageLogo("")
    setOpenCreate(false);
  };

  const handleOk = () => {
    form.submit();
  };

  //HANDLE SUBMIT
  const onFinish = async (values: any) => {
    values.logo = imageLogo;
    try {
      const res = await createAgentApi(values);
      if (res.status === Constant.DEFAULT_STATUS) {
        handleListAgentAPI();
        messageCreateSuccess(ACTION_NAME.AGENT);
        setOpenCreate(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  // upload image
  const handleCancelImage = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList.length === 0) {
      setFileList(newFileList);
    } else {
      setFileList(newFileList.slice(-1));
      if (fileList.length !== 0 && fileList[0].url === undefined) {
        const dataBase64 = await getBase64(fileList[0].originFileObj as RcFile);
        setImageLogo(dataBase64);
      }
    }
  };

  return (
    <Modal
      open={openCreate}
      title={
        <Row style={{ display: "flex", justifyContent: "space-between" , marginBottom: 20}}>
          <Typography.Text className="fw-600 fs-18 ">
            {t("Thêm đại lý mới")}
          </Typography.Text>
          <Space size={10} align="center">
            <TutorialsVideo
              url_youtube={handleGetUrlGuide(listTutorials, 11)}
            />
            <Image
              src={icClose}
              style={{ cursor: "pointer" }}
              preview={false}
              onClick={handleCancel}
            />
          </Space>
        </Row>
      }
      maskClosable={false}
      onCancel={handleCancel}
      className="modal-edit-user customize-modal"
      cancelText="Hủy" // Đổi tên nút Cancel
      okText="Lưu"
      onOk={handleOk}
      width={980}
      closable={false}
      footer={(_, { OkBtn, CancelBtn }: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 24,
          }}
          className="footer-edit-modal-office"
        >
          <div className="modal-btn-user modal-btn-agent d-flex gap-12">
            <CancelBtn className="modal-cancel-btn" />
            <OkBtn />
          </div>
        </div>
      )}
    >
      <Form
        name="agent-basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="form-create-agent form-edit-user height-auto"
      >
        {/* <Spin> */}
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="name"
              label={t("Tên đại lý")}
              className="required"
              rules={validateNameAgent}
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="phone"
              rules={phoneRequiredRules}
              label={t("Số điện thoại")}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="address"
              label={t(" Địa chỉ ")}
              rules={validateAddressAgent}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={validatorEmail}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="type"
              label="Loại đại lý"
              rules={[
                {
                  required: true,
                  message: t("Vui lòng chọn loại đại lý"),
                },
              ]}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Select
                showSearch
                placeholder=""
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={AgentType}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="commission_percent"
              label={t("Chiết khấu")}
              rules={validateCommissionPercentAgent}
              className="required"
              validateFirst
            >
              <Input type="number" suffix={"%"} style={{ width: 104 }} min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item className="upload-image required-none" label={t("Logo")}>
              <Upload
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : (
                  <>
                    <UploadOutlined /> {t("Tải ảnh lên")}
                  </>
                )}
              </Upload>
              <Modal
                open={previewOpen}
                footer={null}
                onCancel={handleCancelImage}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="note"
          label={t("Ghi chú")}
          className="input-group note"
        >
          <Input.TextArea
            rows={4}
            cols={9}
            style={{ height: 96, width: 781, resize: "none" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateAgent;
