import { Col, Form, Input, Row } from 'antd';
import { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/desktop/busBooking.scss';
function ModalBooking2() {
  const { t } = useTranslation();
  const NameRules: Rule[] = [
    {
      pattern: /^[^\s].*$/,
      message: t('Tên không được chứa chỉ khoảng trắng'),
    },
  ];
  return (
    <div style={{ height: '410px', width: '843px' }}>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="contact_phone"
            label={t('Số điện thoại')}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const numberRegex = /^[0-9]+$/;
                  const startsWithPlus84 = /^\+84/;
                  if (
                    (numberRegex.test(value) || startsWithPlus84.test(value)) &&
                    value.length >= 8 &&
                    value.length <= 11
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Số điện thoại không hợp lệ');
                  }
                },
              },
            ]}
          >
            <Input className="mt-1" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="contact_name"
            label={t('Họ và tên')}
            validateTrigger={['onChange', 'onBlur']}
            rules={NameRules}
          >
            <Input className="mt-1" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="contact_email"
            label={t('Email')}
            rules={[{ type: 'email', message: t('Email không đúng định dạng') }, { required: false }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Input className="mt-1" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="contact_note" label={t('Ghi chú')}>
            <Input className="mt-1" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default ModalBooking2;
