import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Flex,
  Image,
  Table,
  Select,
  Button,
  Divider,
  GetProps,
  DatePicker,
  Typography,
  ConfigProvider,
  type FormProps,
  type TableColumnsType,
  type DatePickerProps,
} from "antd";
import "dayjs/locale/vi";
import dayjs from "dayjs";
import moment from "moment";
import "./Report.scss";
import {
  filterOptionSelect,
  formatPrice,
  handleGetUrlGuide,
} from "../../utils/helpers";
import IconFileExport from "../../components/Icons/IconFileExport";
import IconExportExcel from "../../components/Icons/IconExportExcel";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import {
  DataReport,
  OfficeData,
  TimeRouteData,
  ValueOptions,
} from "../../types/report";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant } from "../../config/constant";
import { toast } from "react-toastify";
import {
  getListCompanyMainAgentAPI,
  getListDepartTimeByRouteAPI,
  getListRouteAPI,
} from "../../api/report";
import { RouteItemProps } from "../../types";
import { isNumber } from "lodash";
import ExcelJS from "exceljs";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setFilterBookingDate,
  setFilterDepartTime,
  setFilterCompany,
  setFilterRangeDate,
  setFilterRoute,
  setListDepartTime,
  setFilterReasonCancel,
  setPerPage,
  getListReportCancelMainAgent,
  getListReportCancelMainAgentExcel,
} from "../../redux/slice/report/reportCancelMainAgentSlice";
import TutorialsVideo from "../../components/TutorialsVideo";
import { optionsBookingDate } from "./ReportDetailCompany";
import TextDisplay from "../../components/Display/TextDisplay";
import iconSwap from "../../assets/images/orders/icon-swap.svg";
import DateDisplay from "../../components/Display/DateDisplay";
import { handleGetCancelReason } from "./models/constants";

dayjs.locale("vn");
const { RangePicker } = DatePicker;
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

type FieldType = {
  booking_date: string;
  range_date_report: string;
  route_id: number | string;
  depart_time: number | string;
  reason_cancel: string;
  company_id: number;
};

const ReportCancelMainAgent = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [timeReport, setTimeReport] = useState<RangePickerProps["value"]>();
  const [optionsRoute, setOptionsRoute] = useState<ValueOptions[]>([]);
  const [optionsCompany, setOptionsCompany] = useState<ValueOptions[]>([
    { value: "", label: "Tất cả" },
  ]);

  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );
  const {
    filter: listFilter,
    listDepartTime,
    loading: loadingListReport,
    listReport,
    pagination,
    total_price,
    total_cancel_fee,
    total_refund_customer,
    total_carlink_refund,
    total_main_agent_paid,
  } = useAppSelector((state) => state?.reportCancelMainAgent);

  const watchBookingDate = Form.useWatch("booking_date", form);
  const watchRouteId = Form.useWatch("route_id", form);
  const watchDepartTime = Form.useWatch("depart_time", form);
  const watchReasonCancel = Form.useWatch("reason_cancel", form);
  const watchCompany = Form.useWatch("company_id", form);

  const handleGetListRoute = async () => {
    const response = await getListRouteAPI();
    if (response) {
      const listRoute: ValueOptions[] = response?.data?.data?.map(
        (route: RouteItemProps) => ({
          value: route?.id,
          label: `${route?.depart_point} - ${route?.arrive_point}`,
        })
      );
      listRoute.unshift({
        value: "",
        label: "Tất cả các tuyến",
      });
      setOptionsRoute(listRoute);
    }
  };

  const handleChangeRoute = async (value: number | string) => {
    const response = await getListDepartTimeByRouteAPI(value);
    if (response) {
      const listTimeRoute: ValueOptions[] = response?.data?.data?.map(
        (time: TimeRouteData) => ({
          value: dayjs(time?.depart_time, "HH:mm").format("HH:mm"),
          label: dayjs(time?.depart_time, "HH:mm").format("HH:mm"),
        })
      );
      listTimeRoute.unshift({
        value: "",
        label: "Tất cả",
      });
      dispatch(setFilterRoute(value));
      dispatch(setListDepartTime(listTimeRoute));
      dispatch(setFilterDepartTime(""));
      form.setFieldValue("depart_time", { value: "", label: "Giờ đi" });
    }
  };

  const optionsReasonCancel = [
    {
      value: "",
      label: "Tất cả",
    },
    {
      value: "customer",
      label: "Khách hàng huỷ",
    },
    {
      value: "car_company",
      label: "Nhà xe huỷ",
    },
    {
      value: "agent",
      label: "Đại lý hủy",
    },
    {
      value: "Không liên lạc được với khách",
      label: "Không liên lạc được với khách",
    },
    {
      value: "Khách hàng yêu cầu hủy",
      label: "Khách hàng yêu cầu hủy",
    },
    {
      value: "Khách cân nhắc lại",
      label: "Khách cân nhắc lại",
    },
    {
      value: "Quá hạn giữ chỗ",
      label: "Quá hạn giữ chỗ",
    },
    {
      value: "cancel_note",
      label: "Lý do khác",
    },
  ];

  const handleGetListCompany = async () => {
    const response = await getListCompanyMainAgentAPI();
    if (response) {
      let listCompany: ValueOptions[] = response?.data?.data?.map(
        (agent: OfficeData) => ({
          value: agent?.id,
          label: agent?.name,
        })
      );
      listCompany = [{ value: "", label: "Tất cả" }, ...listCompany];
      setOptionsCompany(listCompany);
    }
  };

  useEffect(() => {
    handleGetListRoute();
    handleGetListCompany();
  }, []);

  const onChangePage = async (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      dispatch(setPerPage(pageSize));
    } else {
      setPage(page);
    }
    await dispatch(
      getListReportCancelMainAgent({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        route_id: watchRouteId?.label ? watchRouteId?.value : watchRouteId,
        depart_time: watchDepartTime?.label
          ? watchDepartTime?.value
          : watchDepartTime,
        reason_cancel: watchReasonCancel?.label
          ? watchReasonCancel?.value
          : watchReasonCancel,
        company_id: isNumber(watchCompany) ? watchCompany : undefined,
        page: pagination?.per_page !== pageSize ? Constant.DEFAULT_PAGE : page,
        limit: pageSize,
      })
    );
  };

  const onFinish: FormProps<any>["onFinish"] = async () => {
    setPage(Constant.DEFAULT_PAGE);
    const response = await dispatch(
      getListReportCancelMainAgent({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        route_id: watchRouteId?.label ? watchRouteId?.value : watchRouteId,
        depart_time: watchDepartTime?.label
          ? watchDepartTime?.value
          : watchDepartTime,
        reason_cancel: watchReasonCancel?.label
          ? watchReasonCancel?.value
          : watchReasonCancel,
        company_id: isNumber(watchCompany) ? watchCompany : undefined,
        page: Constant.DEFAULT_PAGE,
        limit: pagination?.per_page || limitPagination,
      })
    );
    if (response?.payload?.success) {
      toast.success(response?.payload?.message);
    }
  };

  const handleGetListReportBegin = async () => {
    setPage(Constant.DEFAULT_PAGE);
    if (listFilter?.from && listFilter?.to) {
      await dispatch(
        getListReportCancelMainAgent({
          booking_date: listFilter?.booking_date,
          from: dayjs(listFilter?.from).format("YYYY-MM-DD"),
          to: dayjs(listFilter?.to).format("YYYY-MM-DD"),
          route_id: listFilter?.route_id,
          depart_time: listFilter?.depart_time,
          reason_cancel: listFilter?.reason_cancel,
          company_id: isNumber(listFilter?.company_id)
            ? listFilter?.company_id
            : undefined,
          page: Constant.DEFAULT_PAGE,
          limit: pagination?.per_page || limitPagination,
        })
      );
    }
  };

  useEffect(() => {
    handleGetListReportBegin();
    if (listFilter?.from && listFilter?.to) {
      setTimeReport([
        dayjs(listFilter?.from, "YYYY-MM-DD"),
        dayjs(listFilter?.to, "YYYY-MM-DD"),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: TableColumnsType<DataReport> = [
    {
      title: "Mã vé",
      dataIndex: "ticket_code",
      width: 200,
      render: (ticket_code) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={ticket_code} />
        </Typography.Text>
      ),
      sorter: (a, b) => `${a?.ticket_code}`.localeCompare(`${b?.ticket_code}`),
      fixed: "left",
    },
    {
      title: "Họ và tên",
      dataIndex: "customer",
      width: 200,
      render: (customer) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={customer} />
        </Typography.Text>
      ),
    },
    {
      title: "Số điện thoại",
      dataIndex: "customer_phone",
      width: 200,
      render: (customer_phone) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={customer_phone} />
        </Typography.Text>
      ),
    },
    {
      title: "Tuyến",
      dataIndex: "route",
      render: (route, record) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {route ? `${route?.depart_point} - ${route?.arrive_point}` : "N/A"}
          {record?.round_trip && (
            <img
              className="object-fit-scale ml-8"
              width={20}
              height={20}
              src={iconSwap}
              alt=""
            />
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      render: (depart_date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <DateDisplay date={depart_date} dateFormat="DD/MM/YYYY" />
        </Typography.Text>
      ),
      sorter: (a, b) =>
        moment(a.depart_date).unix() - moment(b.depart_date).unix(),
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      render: (depart_time) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_time ? moment(depart_time, "HH:mm").format("HH:mm") : "N/A"}
        </Typography.Text>
      ),
      sorter: (a, b) =>
        moment(a.depart_time, "HH:mm").unix() -
        moment(b.depart_time, "HH:mm").unix(),
    },
    {
      title: "Ngày đặt",
      dataIndex: "booking_date",
      render: (booking_date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <DateDisplay date={booking_date} dateFormat="DD/MM/YYYY" />
        </Typography.Text>
      ),
      sorter: (a, b) =>
        moment(a.booking_date).unix() - moment(b.booking_date).unix(),
    },
    {
      title: "Nhà xe",
      dataIndex: "company_name",
      render: (company_name) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={company_name} />
        </Typography.Text>
      ),
    },
    {
      title: "Lý do hủy",
      dataIndex: "reason_cancel",
      render: (reason_cancel) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {reason_cancel ? handleGetCancelReason(reason_cancel) : "N/A"}
        </Typography.Text>
      ),
      sorter: (a, b) =>
        `${a?.reason_cancel}`?.localeCompare(`${b?.reason_cancel}`),
    },
    {
      title: "Giá vé",
      width: 200,
      dataIndex: "price",
      align: "right",
      render: (price) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {price ? formatPrice(price, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.price) - Number(b?.price),
    },
    {
      title: "Phí huỷ",
      dataIndex: "cancel_fee",
      align: "right",
      render: (cancel_fee) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {cancel_fee ? formatPrice(cancel_fee, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.cancel_fee) - Number(b?.cancel_fee),
    },
    {
      title: "Số tiền hoàn khách",
      dataIndex: "refund_customer",
      align: "right",
      render: (refund_customer) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {refund_customer ? formatPrice(refund_customer, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.refund_customer) - Number(b?.refund_customer),
    },
    {
      title: "Carlink hoàn trả",
      dataIndex: "carlink_refund",
      align: "right",
      render: (carlink_refund) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {carlink_refund ? formatPrice(carlink_refund, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.carlink_refund) - Number(b?.carlink_refund),
    },
    {
      title: "Tổng đại lý thanh toán",
      dataIndex: "main_agent_paid",
      align: "right",
      render: (main_agent_paid) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {main_agent_paid ? formatPrice(main_agent_paid, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.main_agent_paid) - Number(b?.main_agent_paid),
    },
  ];

  const handleGetLabelReasonCancel = (value: string) => {
    const reasonCurrent = optionsReasonCancel.find(
      (time) => time.value === value
    );
    return reasonCurrent?.label || "Tất cả";
  };

  const handleGetLabelCompany = (value: number | string) => {
    const agentCurrent = optionsCompany?.find(
      (agent) => agent?.value === value
    );
    return agentCurrent?.label || "Tất cả";
  };

  const handleGetLabelRoute = (value: number | string) => {
    const routeCurrent = optionsRoute?.find((route) => route?.value === value);
    return routeCurrent?.label || "Tất cả các tuyến";
  };

  const handleGetLabelTypeDateRoute = (value: string) => {
    const typeCurrent = optionsBookingDate?.find(
      (type) => type.value === value
    );
    return typeCurrent?.label;
  };

  const handleGetLabelTimeRoute = (value: string) => {
    const timeCurrent = listDepartTime.find(
      (time: any) => time.value === value
    );
    return timeCurrent?.label || "Tất cả";
  };

  const onChangeTimeReport = (value: RangePickerProps["value"]) => {
    setTimeReport(value);
    dispatch(setFilterRangeDate(value));
  };

  const columnsExport: IExcelColumn[] = [
    {
      title: "Mã Vé",
      dataIndex: "ticket_code",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Họ và tên",
      dataIndex: "customer",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "customer_phone",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Tuyến",
      dataIndex: "route",
      width: 40,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Ngày đặt",
      dataIndex: "booking_date",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Nhà xe",
      dataIndex: "company_name",
      width: 40,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Lý do hủy",
      dataIndex: "reason_cancel",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Giá vé",
      dataIndex: "price",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Phí hủy",
      dataIndex: "cancel_fee",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Số tiền hoàn khách",
      dataIndex: "refund_customer",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Carlink hoàn trả",
      dataIndex: "carlink_refund",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Tổng đại lý thanh toán",
      dataIndex: "main_agent_paid",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
  ];

  const handleExportExcel = async () => {
    const response: any = await dispatch(
      getListReportCancelMainAgentExcel({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        route_id: watchRouteId?.label ? watchRouteId?.value : watchRouteId,
        depart_time: watchDepartTime?.label
          ? watchDepartTime?.value
          : watchDepartTime,
        reason_cancel: watchReasonCancel?.label
          ? watchReasonCancel?.value
          : watchReasonCancel,
        company_id: isNumber(watchCompany) ? watchCompany : undefined,
      })
    );
    if (!response?.error) {
      const listData = response?.payload?.data;
      const total_price = response?.payload?.total_price;
      const total_cancel_fee = response?.payload?.total_cancel_fee;
      const total_refund_customer = response?.payload?.total_refund_customer;
      const total_carlink_refund = response?.payload?.total_carlink_refund;
      const total_main_agent_paid = response?.payload?.total_main_agent_paid;

      const listDataExcel =
        listData?.length > 0
          ? listData?.map((report: DataReport) => ({
              ticket_code: report?.ticket_code ? report?.ticket_code : "N/A",
              customer: report?.customer ? report?.customer : "N/A",
              customer_phone: report?.customer_phone
                ? report?.customer_phone
                : "N/A",
              route: report?.route
                ? `${report?.route?.depart_point} - ${report?.route?.arrive_point}`
                : "N/A",
              depart_date: report?.depart_date
                ? `${moment(report?.depart_date).format("DD/MM/YYYY")}`
                : "N/A",
              depart_time: report?.depart_time
                ? `${moment(report?.depart_time, "HH:mm").format("HH:mm")}`
                : "N/A",
              booking_date: report?.booking_date
                ? `${moment(report?.booking_date).format("DD/MM/YYYY")}`
                : "N/A",
              company_name: report?.company_name ? report?.company_name : "N/A",
              reason_cancel: report?.reason_cancel
                ? handleGetCancelReason(report?.reason_cancel)
                : "N/A",
              price: report?.price ? Number(report?.price) : 0,
              cancel_fee: report?.cancel_fee ? Number(report?.cancel_fee) : 0,
              refund_customer: report?.refund_customer
                ? Number(report?.refund_customer)
                : 0,
              carlink_refund: report?.carlink_refund
                ? Number(report?.carlink_refund)
                : 0,
              main_agent_paid: report?.main_agent_paid
                ? Number(report?.main_agent_paid)
                : 0,
            }))
          : [];
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Báo cáo hoàn huỷ");
      const header = columnsExport.map((col) => ({
        header: col.title,
        key: col.dataIndex as string,
      }));
      worksheet.columns = header;
      columnsExport.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width || 15;
        worksheet.getColumn(index + 1).alignment = {
          horizontal:
            (col?.__style__?.h as
              | "center"
              | "left"
              | "right"
              | "fill"
              | "justify") || "left",
          vertical: "middle",
        };
        worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || "general";
      });
      worksheet.addRows(listDataExcel);
      worksheet.getRow(1).font = {
        name: "Times New Roman",
        size: 12,
        bold: true,
      };
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (colNumber < 15) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "3AB54A" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
      let firstRowHasData = false;
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (cell.value) {
          firstRowHasData = true;
          return;
        }
      });
      if (firstRowHasData) {
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber > 1) {
            row.eachCell((cell) => {
              cell.font = { name: "Times New Roman", size: 12 };
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          }
        });
      } else {
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            let hasData = false;
            row.eachCell((cell) => {
              if (cell.value) {
                hasData = true;
                return;
              }
            });
            if (hasData) {
              row.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        });
      }
      const startColumnIndex = 1;

      const subLastRow = worksheet.addRow([]);
      subLastRow.getCell(1).value = "TỔNG (VND)";
      subLastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      subLastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };
      const endColumnIndexTotal = startColumnIndex + columnsExport.length - 6;
      worksheet.mergeCells(
        subLastRow.number,
        1,
        subLastRow.number,
        endColumnIndexTotal
      );
      subLastRow.getCell(10).value = total_price;
      subLastRow.getCell(11).value = total_cancel_fee;
      subLastRow.getCell(12).value = total_refund_customer;
      subLastRow.getCell(13).value = total_carlink_refund;
      subLastRow.getCell(14).value = total_main_agent_paid;
      subLastRow.eachCell((cell) => {
        cell.font = { name: "Times New Roman", size: 14, bold: true };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      const lastRow = worksheet.addRow([]);
      lastRow.getCell(1).value = "@Bản quyền thuộc nhà xe Carlink";
      lastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      lastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };

      const endColumnIndex = startColumnIndex + columnsExport.length - 1;
      worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Báo cáo hoàn huỷ.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const onChangeCompany = (value: number) => {
    dispatch(setFilterCompany(value));
  };

  const onChangeBookingDate = (type: string) => {
    dispatch(setFilterBookingDate(type));
  };

  const onChangeTimeRoute = (value: number | string) => {
    dispatch(setFilterDepartTime(value));
  };

  const onChangeReasonCancel = (value: string) => {
    dispatch(setFilterReasonCancel(value));
  };

  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate: DatePickerProps["disabledDate"] = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return (
          Math.abs(current.diff(from, "days")) >=
          systemConfig?.export_report_month
        );
      }
      return false;
    },
    [systemConfig?.export_report_month]
  );

  return (
    <div className="Report bg-white">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          booking_date: listFilter?.booking_date,
          range_date_report: [
            listFilter?.from
              ? dayjs(listFilter?.from, "DD/MM/YYYY")
              : undefined,
            listFilter?.to ? dayjs(listFilter?.to, "DD/MM/YYYY") : undefined,
          ],
          route_id:
            listFilter?.route_id === ""
              ? { value: listFilter?.route_id, label: "Chọn tuyến" }
              : listFilter?.route_id,
          depart_time:
            listFilter?.depart_time === ""
              ? { value: "", label: "Giờ đi" }
              : listFilter?.depart_time,
          reason_cancel:
            listFilter?.reason_cancel === ""
              ? { value: "", label: "Lý do hủy" }
              : listFilter?.reason_cancel,
          company_id: listFilter?.company_id
            ? listFilter?.company_id
            : { value: "", label: "Chọn nhà xe" },
        }}
      >
        <Row gutter={12}>
          <Col className="Report__typeDateReport">
            <Form.Item<FieldType>
              className="required"
              name="booking_date"
              label="Khoảng thời gian"
              rules={[
                { required: true, message: "Vui lòng chọn khoảng thời gian" },
              ]}
            >
              <Select
                placeholder="Theo ngày khởi hành"
                options={optionsBookingDate}
                onChange={onChangeBookingDate}
              />
            </Form.Item>
          </Col>
          <Col className="Report__rangeDateReport">
            <Form.Item<FieldType>
              name="range_date_report"
              rules={[
                { required: true, message: "Vui lòng chọn khoảng thời gian" },
              ]}
            >
              <RangePicker
                format="DD/MM/YYYY"
                disabledDate={disabledDaysDate}
                onChange={onChangeTimeReport}
                popupClassName="Report__timeReport__picker"
                placeholder={["Từ ngày", "Đến ngày"]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex align="center" gap={"small"} className="mb-24">
          <Image
            preview={false}
            width={20}
            height={20}
            className="d-flex"
            src={IconExclamationCircle}
            alt="Icon Exclamation Circle"
          />
          <Typography.Text className="fs-14 fw-500 text-color">
            Vui lòng chọn khoảng thời gian để xem báo cáo
          </Typography.Text>
        </Flex>
        <Typography.Text className="fs-6 fw-500 text-color d-block mb-8">
          Bộ lọc nâng cao
        </Typography.Text>
        <Flex gap={"small"}>
          <Form.Item<FieldType> className="Report__agent" name="company_id">
            <Select
              onChange={onChangeCompany}
              options={optionsCompany}
              showSearch
              filterOption={filterOptionSelect}
            />
          </Form.Item>
          <Form.Item<FieldType> className="Report__route" name="route_id">
            <Select
              onChange={handleChangeRoute}
              options={optionsRoute}
              popupClassName="Report__popup"
              showSearch
              filterOption={filterOptionSelect}
            />
          </Form.Item>
          <Form.Item<FieldType>
            className="Report__timeRoute"
            name="depart_time"
          >
            <Select
              onChange={onChangeTimeRoute}
              options={listDepartTime}
              showSearch
              filterOption={filterOptionSelect}
            />
          </Form.Item>
          <Form.Item<FieldType>
            className="Report__reasonCancel"
            style={{ width: "280px" }}
            name="reason_cancel"
          >
            <Select
              onChange={onChangeReasonCancel}
              options={optionsReasonCancel}
            />
          </Form.Item>
        </Flex>
        <Flex align="center" justify="space-between">
          <Form.Item className="Report__submit">
            <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
              <Button
                disabled={
                  timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)
                    ? false
                    : true
                }
                className="d-flex align-items-center h-auto"
                type="primary"
                htmlType="submit"
              >
                <IconFileExport width={16} height={16} />
                Xem báo cáo
              </Button>
            </ConfigProvider>
          </Form.Item>
          <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 2)} />
        </Flex>
        <Divider />
        <Flex>
          <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
            <Button
              ghost
              disabled={
                timeReport?.length === 2 || (listFilter?.from && listFilter?.to)
                  ? false
                  : true
              }
              onClick={handleExportExcel}
              className={`Report__exportExcel d-flex align-items-center mr-12 h-auto ${
                timeReport?.length === 2 || (listFilter?.from && listFilter?.to)
                  ? ""
                  : "disabled"
              }`}
              type="primary"
            >
              <IconExportExcel
                color={`${
                  timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)
                    ? "#435869"
                    : "#949494"
                }`}
                width={16}
                height={16}
              />
              Xuất Excel
            </Button>
          </ConfigProvider>
        </Flex>
        <Typography.Text className="d-block text-color-gray-3A3A3A fs-18 fw-600 mt-24 mb-8">
          Báo cáo hoàn huỷ ({handleGetLabelTypeDateRoute(watchBookingDate)})
        </Typography.Text>
        <Flex wrap="wrap" align="center" className="Report__filter">
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Từ ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[0]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Đến ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[1]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Tuyến:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelRoute(watchRouteId)}
            </Typography.Text>
          </Flex>
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Giờ đi:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelTimeRoute(watchDepartTime)}
            </Typography.Text>
          </Flex>
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Lý do hủy:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelReasonCancel(watchReasonCancel)}
            </Typography.Text>
          </Flex>
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Nhà xe:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelCompany(watchCompany)}
            </Typography.Text>
          </Flex>
        </Flex>
      </Form>
      <Table
        className="Report__table"
        locale={{
          triggerDesc: "Sắp xếp tăng dần",
          triggerAsc: "Hủy sắp xếp",
          cancelSort: "Sắp xếp giảm dần",
        }}
        scroll={{ x: 2600 }}
        pagination={{
          total: pagination?.total,
          current: page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ["bottomRight"],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        dataSource={listReport?.length > 0 ? listReport : []}
        loading={loadingListReport}
        columns={columns}
        summary={(pageData) => {
          return (
            listReport?.length > 0 &&
            page === pagination?.last_page && (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Flex align="center" gap="middle">
                      <Typography.Text className="text-color-default fw-700 fs-16">
                        TỔNG
                      </Typography.Text>
                      <Typography.Text className="text-color-default fw-500 fs-16">
                        (VND)
                      </Typography.Text>
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Flex align="center" gap="middle">
                      {" "}
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Flex align="center" gap="middle">
                      {" "}
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Flex align="center" gap="middle">
                      {" "}
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Flex align="center" gap="middle">
                      {" "}
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <Flex align="center" gap="middle">
                      {" "}
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <Flex align="center" gap="middle">
                      {" "}
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <Flex align="center" gap="middle">
                      {" "}
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <Flex align="center" gap="middle">
                      {" "}
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={9}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {total_price ? formatPrice(total_price, 0, ",") : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={10}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {total_cancel_fee
                        ? formatPrice(total_cancel_fee, 0, ",")
                        : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={11}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {total_refund_customer
                        ? formatPrice(total_refund_customer, 0, ",")
                        : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={12}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {total_carlink_refund
                        ? formatPrice(total_carlink_refund, 0, ",")
                        : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={13}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {total_main_agent_paid
                        ? formatPrice(total_main_agent_paid, 0, ",")
                        : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          );
        }}
      />
    </div>
  );
};

export default ReportCancelMainAgent;
