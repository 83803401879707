/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Flex,
  Form,
  Image,
  Layout,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { ListUserRequest } from "../../types";
import { mbTrim } from "../../lib/utils";
import { Constant, TIMER_DEPOSIT } from "../../config/constant";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import StatusFilter from "./StatusFilter";
import "../../assets/styles/listOffice.scss";
import icEye from "../../assets/images/listSignUp/icEye.png";
import InfoSignUp from "./InfoSignUp";
import "./ManageSignUp.scss";
import { getListRegisterApi } from "../../api/listRegister";
import TextDisplay from "../../components/Display/TextDisplay";
import { handleDisplayTimeToNow } from "../../utils/helpers";
import icCarCompany from "../../assets/images/listSignUp/car_company.svg";
import avatarDaily from "../../assets/images/avatarDefault/avatar-daily.png";

function ManageSignUp() {
  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? DEFAULT_PAGE_SIZE
    : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [status, setStatus] = useState<any>("");
  const [keyword, setKeyword] = useState<ListUserRequest>({});
  const [page, setPage] = useState<number>(1);
  const [companySize, setCompanySize] = useState<string>("");
  const [detailInfo, setDetailInfo] = useState();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState<any>();

  const listCompanySize = [
    { value: "<10 xe" },
    { value: "10-20 xe" },
    { value: "20-50 xe" },
    { value: ">50 xe" },
  ];

  let query = `page=${page}&limit=${limit}&status=${status}&companySize=${companySize}`;

  const fetchDataRegisterApi = async () => {
    setIsLoading(true);
    try {
      const response: any = await getListRegisterApi(query);
      if (response?.status === 200) {
        setData(response?.data?.data);
        setPagination(response?.data?.pagination);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataRegisterApi();
    // Thiết lập interval để gọi API mỗi 30 giây
    const intervalId = setInterval(fetchDataRegisterApi, TIMER_DEPOSIT);
    // Dọn dẹp interval khi component bị unmount
    return () => clearInterval(intervalId);
  }, [query]);

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: any }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      key: "id",
      width: 95,
      render: (_: any, record: any, index: number) => {
        const handleRegistrationSource = () => {
          if (record?.registration_source === "company") {
            return icCarCompany;
          }
          if (record?.registration_source === "agent") {
            return avatarDaily;
          }
          return "";
        };

        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <img src={handleRegistrationSource()} alt="" width={20} />
            <Typography className="text-color">
              {(page - 1) * limit + index + 1}
            </Typography>
          </div>
        );
      },
    },
    {
      title: "Tên Công ty",
      dataIndex: "contact_company_name",
      key: "contact_company_name",
      sorter: (a: any, b: any) =>
        a?.contact_company_name?.localeCompare(b?.contact_company_name),
      render: (name, record) => {
        return (
          <Flex className="flex-column">
            <Typography.Text className="fs-16 text-color fw-500">
              <TextDisplay text={name} />
            </Typography.Text>
            <Typography.Text className="fs-14 text-color-green-default fw-500">
              {handleDisplayTimeToNow(record?.created_at as string)}
            </Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Quy mô",
      dataIndex: "contact_company_size",
      key: "contact_company_size",
      render: (text) => {
        return (
          <Typography className="text-color" style={{ minWidth: 100 }}>
            {text}
          </Typography>
        );
      },
    },
    {
      title: "Người liên hệ",
      dataIndex: "contact_name",
      key: "contact_name",
      width: 180,
    },
    {
      title: "Địa chỉ",
      dataIndex: "contact_address",
      key: "contact_address",
    },
    {
      title: "Tình trạng",
      dataIndex: "handled_status",
      key: "handled_status",
      align: "center",
      width: 90,
      render: (text, record) => {
        return (
          <div className={text === 0 ? "handled_status_tag2" : "handled_status_tag"}>
            {text === 0 ? "Chưa xử lý" : "Đã xử lý"}
          </div>
        );
      },
    },
    {
      title: "Chức năng",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 140,
      render: (_, record) => {
        return (
          <>
            <Space
              size="middle"
              onClick={() => showModal(record)}
              style={{ cursor: "pointer" }}
            >
              <Tooltip placement="left" title="Xem thông tin đăng ký">
                <Image src={icEye} preview={false} />
              </Tooltip>
            </Space>
          </>
        );
      },
    },
  ];

  const showModal = (item: any) => {
    setDetailInfo(item);
    setOpen(true);
  };

  const onSearch = (value: ListUserRequest) => {
    form.setFields([{ name: "keyword", value: mbTrim(value.keyword) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(form.getFieldsValue(["keyword"]));
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const handleChangeStatus = (value: number | undefined) => {
    setStatus(value);
  };

  const onChangeCompanySize = (value: string) => {
    setCompanySize(value);
  };

  const selectCompanySize =
    listCompanySize?.length > 0
      ? listCompanySize.map((item: any) => ({
          value: item.value,
          label: item.value,
        }))
      : [];
  const selectAll = { value: "", label: "Tất cả" };

  return (
    <Layout className="min-vh-100 wrapper_list_register">
      <Card>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        <Form
          className="mg-16 mt-3 mx-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          onFinish={onSearch}
          colon={false}
          style={{ position: "relative" }}
        >
          <Form.Item
            className="label-search mb-0"
            labelAlign="left"
            labelCol={{ md: 0 }}
          >
            <Row gutter={10}>
              <Col>
                <Form.Item name="group">
                  <Select
                    className="input-h36 input-search-user"
                    placeholder={t("Chọn tất cả")}
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeCompanySize}
                    style={{ width: 300 }}
                    options={[selectAll, ...selectCompanySize]}
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>

        <Table
          locale={{
            triggerDesc: "Sắp xếp tăng dần",
            triggerAsc: "Hủy sắp xếp",
            cancelSort: "Sắp xếp giảm dần",
          }}
          bordered={false}
          loading={isLoading}
          // rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          // scroll={{ y: 600 }}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={data?.length > 0 ? data : []}
          className="mx-3"
        />
        <Typography className="text-color footer-register">
          Dữ liệu được lấy từ form đăng ký trên trang chủ của{" "}
          <a target="blank" rel="noopener" href={"https://carlink.com.vn/"}>
            carlink.com.vn
          </a>{" "}
        </Typography>
      </Card>

      <InfoSignUp
        open={open}
        setOpen={setOpen}
        detailInfo={detailInfo}
        fetchDataRegisterApi={fetchDataRegisterApi}
      />
    </Layout>
  );
}

export default ManageSignUp;
