export const SIDEBAR_WIDTH = 265;
export const THEME_LIGHT = 'light';
export const THEME_DARK = 'realDark';
export const MAX_LENGTH = 10;
export const SUPER_ADMIN = 'super-admin';
export const ADMIN = 'admin';
export const VIEWER = 'viewer';
export const regExpSpace = new RegExp(/[^\s+]/);
export const regUrlTwoByte = new RegExp(/[\uFF10-\uFF19]/g);

export const Constant = {
  DEFAULT_STATUS: 200,
  FORBIDDEN: 403,
  UN_AUTH: 'UnAuthenticated',
  UN_AUTH_CODE: 401,
  DEFAULT_PAGE: 1,
  PAGE_SIZE: 10,
  STATUS_ACTIVE: true,
  STATUS_INACTIVE: false,
  YET_EXPORT: 1,
  EXPORTED: 2,
  CANCEL_EXPORT: 3,
  RED_SEAT: 'red-seat',
  BROWN_SEAT: 'brown-seat',
  YELLOW_SEAT: 'yellow-seat',
  BLUE_SEAT: 'blue-seat',
  GREEN_SEAT: 'green-seat',
  PURPLE_SEAT: 'purple-seat',
  ORANGE_SEAT: 'orange-seat',
  HORIZONTAL_DIRECTION: 0,
  VERTICAL_DIRECTION: 1,
  DEFAULT_SEAT: 0,
  DRIVER_SEAT: 1,
  EXTRA_DRIVER_SEAT: 2,
  NORMAL_SEAT: 3,
  BED_SEAT: 4,
  SINGLE_CABIN: 5,
  DOUBLE_CABIN: 6,
  MASSAGE_SEAT: 7,
  STATUS_HOLDING: 0,
  STATUS_PAID: 1,
  STATUS_CANCEL: 2,
  HOLD: 'reserve',
  CASH: 'cash',
  BANK: 'bank_transfer',
  TIMER_BOOKING: 5000,
  TIMER_CUSTOMER: 8000,
  TIMER_DETAIL: 30000,
  TIMER_SEARCH: 2000,
  BOOKING_SINGLE: 'single_info',
  BOOKING_CONTRACT: 'contract',
  BOOKING_MULTI: 'same_info',
  BOOKING_MULTI_CUSTOM: 'custom_info',
  STATUS_NOT_COMPLETE: 'not_complete',
  STATUS_COMPLETED: 'completed',
  STATUS_CANCELED: 'canceled',
  CUSTOMER_HOLD: 'reserve',
  CUSTOMER_PAID: 'paid',
  CUSTOMER_CANCEL: 'cancel',
};

export const Toast = {
  TYPE_ERROR: 'error',
  TYPE_SUCCESS: 'success',
};

export const TIMER_DEPOSIT = 30000
export const ADJUST_UP = 1;
export const ADJUST_DOWN = 0;
export const DRIVER = 'driver';
export const EXTRA_DRIVER = 'extra_driver';

export const facility_status = [''];

export const DEFAULT_REPEAT = 1;

export const TYPE_MINUTE = 'm';
export const TYPE_PRICE_UP = 1;
export const TYPE_PRICE_DOWN = 0;
export const TYPE_HOUR = 'h';
export const REPEAT_ALL_DAY = 3;
export const YEAR_DEFAULT = 3;

export type HolidayType = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export const CalendarFormat = 'YYYY-MM-DD HH:mm:ss';
export const YearFormat = 'YYYY-MM-DD';
export const HourFormat = 'HH';
export const TimeFormat = 'HH:mm';
export const MinuteFormat = 'mm';
export const DateFormat = 'DD/MM/YYYY';
export const DateFormatSearch = 'DD-MM-YYYY';

export const USER_GROUP = {
  CARLINK: 'carlink',
  CAR_COMPANY: 'car_company',
  AGENT: 'agent',
  MAIN_AGENT: 'main_agent'
};

export const TEXT_PERMISSIONS = {
  CARLINK: 'Carlink',
  CAR_COMPANY: 'Nhà xe',
  AGENT: 'Đại lý',
  MAIN_AGENT: 'Tổng đại lý'
};

export const StatusTrip = {
  canceled: 'Hủy chuyến',
  not_departed: 'Chưa xuất bến',
  departed: 'Đã xuất bến',
};

export const AgentType = [
  { value: 'enterprise', label: 'Đại lý doanh nghiệp' },
  { value: 'personal', label: 'Đại lý cá nhân' },
];

export const ContractType = [
  { value: 'not_complete', label: 'Chưa hoàn thành' },
  { value: 'completed', label: 'Đã hoàn thành' },
  { value: 'canceled', label: 'Đã hủy' },
];

export enum TabType {
  ALL = '0',
  ACTIVE = '1',
  LOCK = '2',
}
