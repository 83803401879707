import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Flex, Form, Image, Radio, Space, Table, Tooltip, Typography } from 'antd';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { ColumnsType } from 'antd/es/table';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { useEffect, useState } from 'react';
import iconAgent from '../../assets/images/ic-agent.svg';
import iconCarGroup from '../../assets/images/ic-car-company.svg';
import icEdit from '../../assets/images/ic-edit.svg';
import iconExport from '../../assets/images/ic-excel.svg';
import iconDriver from '../../assets/images/ic-volang.svg';
import { Constant, YearFormat } from '../../config/constant';
import { formattedPrice, getStatusBooking } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetPriceBooking } from '../../redux/slice/bookings/createBookingSlice';
import {
  getListBookingDetail,
  getListCustomerBooking,
  listBookingSelector,
} from '../../redux/slice/bookings/listBooking';
import ModalBooking from './ModalBooking';
import ModalHistoryBooking from './ModalHistoryBooking';
import CountdownComponent from './diagrams/Countdown';
import ModalInfoInsurance from '../../components/Modal/ModalInfoInsurance';
import { LockOutlined } from '@ant-design/icons';

const options = [
  { label: 'Tất cả trạng thái', value: '' },
  { label: 'Đang giữ chỗ', value: 'reserve' },
  { label: 'Đã thanh toán', value: 'paid' },
  { label: 'Đã hủy', value: 'cancel' },
];
function CustomerList({ listBooking, formSearch, activeKey }: any) {
  const { listCustomerBooking, extra } = useAppSelector(listBookingSelector);
  const { listBookingDetail } = useAppSelector(listBookingSelector);
  const dispatch = useAppDispatch();
  const [filterStatus, setFilterStatus] = useState<any>('');
  const [isDetail, setIsDetail] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [timeManage, setTimeManage]: any = useState<any>([]);
  const [openInfoInsurance, setOpenInfoInsurance] = useState(false);
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [dataPolicy, setDataPolicy] = useState<any>();
  const [dataExportCsv, setDataExportCsv] = useState<any>([]);
  const searchDateBooking = dayjs(Form.useWatch('date_booking', formSearch)).format(YearFormat);
  const getDetailBooking = (record: any) => {
    setDataPolicy(record?.policy?.insurance_policy);
    if (record?.status === 'cancel') {
      dispatch(getListBookingDetail({ ticket_code: record?.ticket_code, is_diagram: record?.is_diagram }));
      setOpenModalHistory(true);
    } else {
      setIsDetail(true);
      dispatch(getListBookingDetail({ ticket_code: record?.ticket_code, is_diagram: false }));
      setOpenDetail(true);
    }
  };
  const columns1: ColumnsType<any> = [
    {
      title: 'Mã đặt vé',
      dataIndex: '',
      key: 'ticket_code',
      width: '150px',
      render: (record) => (
        <Space size="middle">
          {listBooking?.contract?.code ? (
            <FontAwesomeIcon icon={faCircle} className={`green`} size="xs" />
          ) : (
            <>
              {record?.status === 'paid' ? (
                <>
                  <img
                    src={record?.is_driver_pay ? iconDriver : record?.payer?.agent?.id ? iconAgent : iconCarGroup}
                    alt=""
                  />
                </>
              ) : (
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`${
                    record?.status === 'cancel' ? 'red' : record?.status === 'reserve' ? 'yellow' : 'green'
                  }`}
                  size="xs"
                />
              )}
              <Typography>{record?.ticket_code}</Typography>
            </>
          )}
        </Space>
      ),
      sorter: (a: any, b: any) => a?.ticket_code.localeCompare(b?.ticket_code),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '160px',
      render: (text) => <Typography>{text}</Typography>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customer_phone',
      key: 'customer_phone',
      width: '130px',
      render: (text) => <Typography>{text}</Typography>,
    },
    {
      title: 'Đón tại',
      dataIndex: 'pickup_location',
      key: 'pickup_location',
      width: '180px',
      render: (text, record) => <Typography>{record?.pickup_extra ?? text}</Typography>,
    },
    {
      title: 'Trả tại',
      dataIndex: 'drop_off_location',
      key: 'drop_off_location',
      width: '180px',
      render: (text, record) => <Typography>{record?.drop_off_extra ?? text}</Typography>,
    },
    {
      title: 'TG giữ chỗ',
      dataIndex: '',
      key: 'expired_at',
      width: '130px',
      render: (text, record) => (
        <Typography className="text-start ml-3 time-reserve" style={{ color: '#F8B507' }}>
          {record?.expired_at ? <CountdownComponent timerCountdown={record} /> : ''}
        </Typography>
      ),
    },
    {
      title: 'Nhân viên đặt vé',
      dataIndex: 'orderer',
      key: 'orderer',
      width: '165px',
      render: (text) => <Typography>{text?.name}</Typography>,
    },
    listBooking?.schedule?.is_diagram
      ? {
          title: 'Ghế',
          dataIndex: 'seat_number',
          key: 'seat_number',
          width: '100px',
          render: (text) => <Typography>{text}</Typography>,
        }
      : { width: 0 },
    !listBooking?.contract?.code
      ? {
          title: 'Giá vé',
          dataIndex: 'price',
          key: 'price',
          width: '110px',
          sorter: (a: any, b: any) => a?.price - b?.price,
          render: (text) => <Typography>{formattedPrice(text)}</Typography>,
        }
      : {
          width: '0',
        },
    {
      title: 'Tiền thực thu',
      dataIndex: '',
      key: 'price_commission',
      width: '150px',
      render: (record) => (
        <Typography>
          {record?.booking_for_agent
            ? record?.price
            : record?.payer?.agent?.id
            ? record?.final_price
            : record?.final_price}
        </Typography>
      ),
    },
    !listBooking?.schedule?.is_diagram
      ? {
          title: ' ',
          dataIndex: 'action',
          key: 'action',
          render: (_, record: any) => (
            <Space size="middle">
              <Tooltip title="Chỉnh sửa">
                <Link
                  onClick={() => {
                    getDetailBooking(record);
                  }}
                >
                  <Image width={17} height={17} className="icon-edit mr-20" src={icEdit} preview={false} />
                </Link>
              </Tooltip>
            </Space>
          ),
          align: 'center',
          width: '60px',
        }
      : {
          width: '50px',
        },
  ];
  const columnsExport: IExcelColumn[] = [
    {
      title: 'Mã đặt vé',
      __excelTitle__: 'Mã đặt vé',
      dataIndex: 'ticket_code',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
    },
    {
      title: 'Họ và tên',
      dataIndex: 'customer_name',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
      __excelTitle__: 'Họ và tên',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customer_phone',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Số điện thoại',
    },
    {
      title: 'Đón tại',
      dataIndex: 'pickup_location',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 25,
      },
      __excelTitle__: 'Đón tại',
      width: 25,
    },
    {
      title: 'Trả tại',
      dataIndex: 'drop_off_location',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 25,
      },
      __excelTitle__: 'Trả tại',
      width: 25,
    },
    {
      title: 'Nhân viên đặt vé',
      dataIndex: 'orderer',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 22,
      },
      __excelTitle__: 'Nhân viên đặt vé',
      width: 22,
    },
    {
      title: 'Ghế',
      dataIndex: listBooking?.schedule?.is_diagram ? 'seat_number' : ' ',
      align: 'center',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 10,
      },
      __excelTitle__: 'Ghế',
    },

    {
      title: 'Giá vé',
      dataIndex: 'price',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Giá vé',
    },
    {
      title: 'Tiền thực thu',
      dataIndex: 'price_commission',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Tiền thực thu',
    },
    {
      title: 'Người thu',
      dataIndex: 'person_pay',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Người thu',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      __excelTitle__: 'Trang thái',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
    },
  ];
  const onChange: any = (e: any) => {
    setFilterStatus(e.target.value);
  };
  useEffect(() => {
    if (timeManage?.length) {
      let timers: NodeJS.Timeout[] = [];
      const handleUpdateStatus = async (): Promise<void> => {
        await dispatch(
          getListCustomerBooking({
            schedule_id: listBooking?.schedule?.id,
            depart_date: searchDateBooking,
            status: filterStatus,
          }),
        );
      };
      const setTimer = (index: number): void => {
        const timerId: NodeJS.Timeout = setTimeout(() => {
          if (isDetail) {
            // CLEAR ALL TIMER
            timers.forEach(clearTimeout);
            return;
          }
          handleUpdateStatus();
          setTimer(index);
        }, Constant.TIMER_CUSTOMER);
        timers[index] = timerId;
      };
      // Start timers
      for (let i: number = 0; i < timeManage?.length; i++) {
        setTimer(i);
      }
      return (): void => {
        timers.forEach(clearTimeout);
      };
    }
  }, [timeManage, dispatch, searchDateBooking, listBooking, filterStatus, isDetail]);
  useEffect(() => {
    if (
      listBooking &&
      searchDateBooking &&
      ((+activeKey === 2 && listBooking?.schedule?.is_diagram) ||
        (+activeKey === 1 && !listBooking?.schedule?.is_diagram))
    ) {
      if (!listBooking?.schedule?.is_diagram) {
        setTimeManage([
          {
            id: listBooking?.schedule?.id,
          },
        ]);
      }
      dispatch(
        getListCustomerBooking({
          schedule_id: listBooking?.schedule?.id,
          depart_date: searchDateBooking,
          status: filterStatus,
        }),
      );
    }
  }, [dispatch, filterStatus, listBooking, activeKey, searchDateBooking]);
  useEffect(() => {
    if (listCustomerBooking?.length) {
      const tmpListCustomerBooking = listCustomerBooking?.map((item: any) => {
        return {
          ...item,
          orderer: item?.orderer.name,
          status: getStatusBooking(item?.status),
          pickup_location: item?.pickup_location ?? item?.pickup_extra,
          drop_off_location: item?.drop_off_location ?? item?.drop_off_extra,
          price_commission: item?.is_driver_pay && item?.orderer?.agent?.id ? item?.price : item?.price_commission,
        };
      });
      setDataExportCsv(tmpListCustomerBooking);
    } else {
      setDataExportCsv([]);
    }
  }, [listCustomerBooking]);

  const handleClick = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Danh sách hành khách');
    const header = columnsExport.map((col) => ({ header: col.title, key: col.dataIndex as string }));
    worksheet.columns = header;

    columnsExport.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width || 15;
    });
    header.forEach((_, index) => {
      const cell = worksheet.getCell(1, index + 1);
      cell.font = { name: 'Times New Roman', size: 12, bold: true };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3AB54A' } };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    worksheet.addRows(dataExportCsv);
    worksheet.eachRow((row, rowNumber) => {
      let hasData = false;
      if (rowNumber > 1) {
        row.eachCell((cell) => {
          if (cell) {
            hasData = true;
          }
        });
        if (hasData) {
          row.eachCell((cell) => {
            cell.font = { name: 'Times New Roman', size: 12 };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
        }
      }
    });
    const lastRow = worksheet.addRow([]);
    lastRow.getCell(1).value = '@Bản quyền thuộc nhà xe Carlink ';
    lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    lastRow.getCell(1).font = { name: 'Times New Roman', size: 12 };
    const startColumnIndex = 2;
    const endColumnIndex = startColumnIndex + columnsExport.length - 1;
    worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Danh sách hành khách.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  const cancelModal = () => {
    setIsDetail(false);
    setOpenDetail(false);
    dispatch(resetPriceBooking());
    setDataPolicy(undefined);
  };
  const handleOkModalBooking = () => {};
  const cancelModalHistory = () => {
    setOpenModalHistory(false);
  };

  return (
    <>
      <div className="table-booking">
        <Flex gap={12} className="justify-center items-center">
          <Button className="btn-export" onClick={() => handleClick()}>
            <img src={iconExport} alt="excel" />
            Xuất excel
          </Button>
          {extra?.is_lock_all && (
            <Flex gap={8}>
              <Typography className="fs-16 fw-600 text-danger">Chuyến đã bị khóa</Typography>
              <LockOutlined size={14} className="text-danger fs-14" />
            </Flex>
          )}
        </Flex>

        {!listBooking?.contract?.code && (
          <Radio.Group
            options={options}
            value={filterStatus}
            className="mt-3 fw-500 payment-checkbox-group customer-list"
            onChange={onChange}
          />
        )}

        <Table
          locale={{
            triggerDesc: 'Sắp xếp giảm dần',
            triggerAsc: 'Sắp xếp tăng dần',
            cancelSort: 'Hủy sắp xếp',
          }}
          dataSource={listCustomerBooking?.length > 0 && listBooking?.schedule?.id ? listCustomerBooking : []}
          rowKey="id"
          columns={columns1}
          className="list-bus mt-2"
          pagination={false}
        />
      </div>
      <ModalBooking
        okText={'Lưu'}
        modalOpen={openDetail}
        onCancel={cancelModal}
        cancelText="Đóng"
        onOk={handleOkModalBooking}
        listBooking={listBooking}
        formSearch={formSearch}
        isDetail={isDetail}
        listBookingDetail={listBookingDetail}
      />
      <ModalHistoryBooking
        modalOpen={openModalHistory}
        onOk={() => setOpenModalHistory(false)}
        onCancel={cancelModalHistory}
        historyDetail={listBookingDetail}
        isCancel={true}
        listBooking={listBooking}
      />
      <ModalInfoInsurance
        modalOpen={openInfoInsurance}
        onCancel={() => setOpenInfoInsurance(false)}
        onOk={() => false}
        dataPolicy={dataPolicy}
      />
    </>
  );
}
export default CustomerList;
