import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Flex,
  Image,
  Table,
  Select,
  Button,
  Divider,
  GetProps,
  DatePicker,
  Typography,
  ConfigProvider,
  type FormProps,
  type TableColumnsType,
} from "antd";
import "dayjs/locale/vi";
import dayjs from "dayjs";
import moment from "moment";
import "./Report.scss";
import { formatPrice, handleGetUrlGuide } from "../../utils/helpers";
import IconFileExport from "../../components/Icons/IconFileExport";
import IconExportExcel from "../../components/Icons/IconExportExcel";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import {
  DataReport,
  OfficeData,
  TimeRouteData,
  ValueOptions,
} from "../../types/report";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant } from "../../config/constant";
import { toast } from "react-toastify";
import {
  getListCompanyAPI,
  getListDepartTimeByRouteAPI,
  getListRouteAPI,
} from "../../api/report";
import { RouteItemProps } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setFilterBookingDate,
  setFilterDepartTime,
  setFilterRangeDate,
  setFilterRoute,
  setListTimeRoute,
  setFilterOptions,
  setFilterCompany,
  getListReportFeeExportTicketCompanyCarlink,
  getListReportFeeExportTicketCompanyCarlinkExcel,
  setPerPage,
} from "../../redux/slice/report/reportFeeExportTicketCompanyCarlinkSlice";
import ExcelJS from "exceljs";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import { TypeOptions, optionsOptions } from "./ReportFeeDepart";
import { optionsBookingDate } from "./ReportDetailCompany";
import TutorialsVideo from "../../components/TutorialsVideo";

dayjs.locale("vn");
const { RangePicker } = DatePicker;
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

type FieldType = {
  booking_date: string;
  range_date_report: string;
  options: string;
  route_id: number | string;
  depart_time: number | string;
  company_id: number;
};

const ReportFeeExportTicketCompanyCarlink = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [timeReport, setTimeReport] = useState<RangePickerProps["value"]>();
  const [optionsRoute, setOptionsRoute] = useState<ValueOptions[]>([]);
  const [typeReportFor, setTypeReportFor] = useState<TypeOptions>(
    TypeOptions.report_detail
  );
  const [optionsCompany, setOptionsCompany] = useState<ValueOptions[]>([]);

  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

  const watchBookingDate = Form.useWatch("booking_date", form);
  const watchOptions = Form.useWatch("options", form);
  const watchRoute = Form.useWatch("route_id", form);
  const watchTimeRoute = Form.useWatch("depart_time", form);
  const watchCompany = Form.useWatch("company_id", form);

  const pagination = useAppSelector(
    (state) => state?.reportFeeExportTicketCompanyCarlink?.pagination
  );
  const listReport = useAppSelector(
    (state) => state?.reportFeeExportTicketCompanyCarlink?.listReport
  );
  const loadingListReport = useAppSelector(
    (state) => state?.reportFeeExportTicketCompanyCarlink?.loading
  );
  const totalPriceExportTicketFee = useAppSelector(
    (state) =>
      state?.reportFeeExportTicketCompanyCarlink?.total_price_export_ticket_fee
  );
  const totalPrice = useAppSelector(
    (state) => state?.reportFeeExportTicketCompanyCarlink?.total_price
  );
  const totalTicketPaid = useAppSelector(
    (state) => state?.reportFeeExportTicketCompanyCarlink?.total_ticket_paid
  );
  const listTimeRoute = useAppSelector(
    (state) => state?.reportFeeExportTicketCompanyCarlink?.listTimeRoute
  );
  const listFilter = useAppSelector(
    (state) => state?.reportFeeExportTicketCompanyCarlink?.filter
  );
  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );

  const handleGetListRoute = async () => {
    const response = await getListRouteAPI();
    if (response) {
      const listRoute: ValueOptions[] = response?.data?.data?.map(
        (route: RouteItemProps) => ({
          value: route?.id,
          label: `${route?.depart_point} - ${route?.arrive_point}`,
        })
      );
      listRoute.unshift({
        value: "",
        label: "Tất cả các tuyến",
      });
      setOptionsRoute(listRoute);
    }
  };

  const handleGetListCompany = async () => {
    const response = await getListCompanyAPI();
    if (response) {
      let listCompany: ValueOptions[] = response?.data?.data?.map(
        (agent: OfficeData) => ({
          value: agent?.id,
          label: agent?.name,
        })
      );
      listCompany = [{ value: "", label: "Tất cả" }, ...listCompany];
      setOptionsCompany(listCompany);
    }
  };

  useEffect(() => {
    setTypeReportFor(listFilter?.options as TypeOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetListCompany();
  }, []);

  const handleChangeRoute = async (value: number | string) => {
    const response = await getListDepartTimeByRouteAPI(value);
    if (response) {
      const listTimeRoute: ValueOptions[] = response?.data?.data?.map(
        (time: TimeRouteData) => ({
          value: dayjs(time?.depart_time, "HH:mm").format("HH:mm"),
          label: dayjs(time?.depart_time, "HH:mm").format("HH:mm"),
        })
      );
      listTimeRoute.unshift({
        value: "",
        label: "Tất cả",
      });
      dispatch(setFilterRoute(value));
      dispatch(setListTimeRoute(listTimeRoute));
      dispatch(setFilterDepartTime(""));
      form.setFieldValue("depart_time", { value: "", label: "Giờ đi" });
    }
  };

  useEffect(() => {
    handleGetListRoute();
  }, []);

  const onChangePage = async (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      dispatch(setPerPage(pageSize));
    } else {
      setPage(page);
    }
    await dispatch(
      getListReportFeeExportTicketCompanyCarlink({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        options: watchOptions,
        route_id: watchRoute?.label ? watchRoute?.value : watchRoute,
        depart_time: watchTimeRoute?.label
          ? watchTimeRoute?.value
          : watchTimeRoute,
        company_id: watchCompany?.label ? watchCompany?.value : watchCompany,
        page: pagination?.per_page !== pageSize ? Constant.DEFAULT_PAGE : page,
        limit: pageSize,
      })
    );
  };

  const onFinish: FormProps<any>["onFinish"] = async () => {
    if (watchOptions === TypeOptions.report_detail) {
      setTypeReportFor(TypeOptions.report_detail);
    } else {
      setTypeReportFor(TypeOptions.report_total);
    }
    setPage(Constant.DEFAULT_PAGE);
    const response = await dispatch(
      getListReportFeeExportTicketCompanyCarlink({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        options: watchOptions,
        route_id: watchRoute?.label ? watchRoute?.value : watchRoute,
        depart_time: watchTimeRoute?.label
          ? watchTimeRoute?.value
          : watchTimeRoute,
        company_id: watchCompany?.label ? watchCompany?.value : watchCompany,
        page: Constant.DEFAULT_PAGE,
        limit: pagination?.per_page || limitPagination,
      })
    );
    if (response?.payload?.success) {
      toast.success(response?.payload?.message);
    }
  };

  const handleGetListReportBegin = async () => {
    setPage(Constant.DEFAULT_PAGE);
    if (listFilter?.from && listFilter?.to && listFilter?.options) {
      await dispatch(
        getListReportFeeExportTicketCompanyCarlink({
          booking_date: watchBookingDate,
          from: dayjs(listFilter?.from).format("YYYY-MM-DD"),
          to: dayjs(listFilter?.to).format("YYYY-MM-DD"),
          options: listFilter?.options,
          route_id: listFilter?.route_id,
          depart_time: listFilter?.depart_time,
          company_id: listFilter?.company_id,
          page: Constant.DEFAULT_PAGE,
          limit: pagination?.per_page || limitPagination,
        })
      );
    }
  };

  useEffect(() => {
    handleGetListReportBegin();
    if (listFilter?.from && listFilter?.to) {
      setTimeReport([
        dayjs(listFilter?.from, "YYYY-MM-DD"),
        dayjs(listFilter?.to, "YYYY-MM-DD"),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsReportDetail: TableColumnsType<DataReport> = [
    {
      title: "Mã vé",
      dataIndex: "ticket_code",
      render: (ticket_code) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {ticket_code ? ticket_code : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Tuyến",
      dataIndex: "route",
      render: (route) => (
        <Typography.Text className="fs-16 text-color fw-500">{`${route?.depart_point} - ${route?.arrive_point}`}</Typography.Text>
      ),
      sorter: (a, b) =>
        `${a?.route?.depart_point} - ${a?.route?.arrive_point}`.localeCompare(
          `${b?.route?.depart_point} - ${b?.route?.arrive_point}`
        ),
    },
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      render: (depart_date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_date ? moment(depart_date).format("DD/MM/YYYY") : ""}
        </Typography.Text>
      ),
      sorter: (a, b) => {
        if (
          a &&
          a?.depart_date &&
          a?.depart_date.length &&
          b &&
          b?.depart_date &&
          b?.depart_date.length
        ) {
          return a?.depart_date.localeCompare(b?.depart_date);
        } else if (a && a?.depart_date && a?.depart_date?.length) {
          // that means be has null license_class, so a will come first.
          return -1;
        } else if (b && b?.depart_date && b?.depart_date?.length) {
          // that means a has null license_class so b will come first.
          return 1;
        }
        // both license_class has null value so there will be no order change.
        return 0;
      },
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      render: (depart_time) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_time ? moment(depart_time, "HH:mm").format("HH:mm") : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Ngày đặt",
      dataIndex: "created_at",
      render: (created_at) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {created_at ? moment(created_at).format("DD/MM/YYYY") : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Thuộc nhà xe",
      dataIndex: "company",
      render: (company) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {company?.name ? company?.name : ""}
        </Typography.Text>
      ),
    },
    {
      title: "% Phí xuất vé",
      dataIndex: "export_ticket_fee_percent",
      align: "right",
      render: (export_ticket_fee_percent) => {
        return (
          <Typography.Text className="fs-16 text-color fw-500">
            {`${export_ticket_fee_percent ? export_ticket_fee_percent : "0%"}${
              export_ticket_fee_percent !== 0 ? "%" : ""
            }`}
          </Typography.Text>
        );
      },
    },
    {
      title: "Giá vé",
      dataIndex: "price",
      align: "right",
      render: (price) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {price ? formatPrice(price, 0, ",") : 0}
        </Typography.Text>
      ),
    },
    {
      title: "Phí xuất vé",
      dataIndex: "export_ticket_fee",
      align: "right",
      render: (export_ticket_fee) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {export_ticket_fee ? formatPrice(export_ticket_fee, 0, ",") : 0}
        </Typography.Text>
      ),
    },
  ];
  const columnsReportTotal: TableColumnsType<DataReport> = [
    {
      title: "Tên nhà xe",
      dataIndex: "company",
      render: (company) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {company?.name ? company?.name : ""}
        </Typography.Text>
      ),
    },
    {
      title: "% Phí xuất vé",
      dataIndex: "export_ticket_fee",
      align: "right",
      render: (export_ticket_fee) => {
        return (
          <Typography.Text className="fs-16 text-color fw-500">
            {`${export_ticket_fee ? export_ticket_fee : "0%"}${
              export_ticket_fee !== 0 ? "%" : ""
            }`}
          </Typography.Text>
        );
      },
    },
    {
      title: "Số lượng vé đã bán",
      dataIndex: "total_bookings",
      align: "right",
      render: (total_bookings) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {total_bookings ? formatPrice(total_bookings, 0, ",") : 0}
        </Typography.Text>
      ),
    },
    {
      title: "Phí xuất vé",
      dataIndex: "total_export_ticket_fee",
      align: "right",
      render: (total_export_ticket_fee) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {total_export_ticket_fee
            ? formatPrice(total_export_ticket_fee, 0, ",")
            : 0}
        </Typography.Text>
      ),
    },
  ];

  const handleGetLabelRoute = (value: number | string) => {
    const routeCurrent = optionsRoute?.find((route) => route?.value === value);
    return routeCurrent?.label || "Tất cả các tuyến";
  };

  const handleGetLabelTimeRoute = (value: string) => {
    const timeCurrent = listTimeRoute.find((time) => time.value === value);
    return timeCurrent?.label || "Tất cả";
  };

  const handleGetLabelTypeReport = (value: string) => {
    const typeCurrent = optionsOptions?.find((type) => type?.value === value);
    return typeCurrent?.label;
  };

  const onChangeTimeReport = (value: RangePickerProps["value"]) => {
    setTimeReport(value);
    dispatch(setFilterRangeDate(value));
  };

  const onChangeTimeRoute = (value: number | string) => {
    dispatch(setFilterDepartTime(value));
  };

  const onChangeOptions = (value: TypeOptions) => {
    dispatch(setFilterOptions(value));
  };

  const onChangeCompany = (value: number) => {
    dispatch(setFilterCompany(value));
  };

  const columnsReportDetailExcel: IExcelColumn[] = [
    {
      title: "Mã vé",
      dataIndex: "ticket_code",
      width: 40,
      __excelTitle__: "Mã vé",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Tuyến",
      dataIndex: "route",
      width: 40,
      __excelTitle__: "Tuyến",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      __excelTitle__: "Ngày đi",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      __excelTitle__: "Giờ đi",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Ngày đặt",
      dataIndex: "created_at",
      __excelTitle__: "Ngày đặt",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Thuộc nhà xe",
      dataIndex: "company",
      __excelTitle__: "Thuộc nhà xe",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "% Phí xuất vé",
      dataIndex: "export_ticket_fee_percent",
      __excelTitle__: "% Phí xuất vé",
      __numFmt__: "0.0%",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Giá vé",
      dataIndex: "price",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Giá vé",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Phí xuất vé",
      dataIndex: "export_ticket_fee",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Phí xuất vé",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
  ];
  const columnsReportTotalExcel: IExcelColumn[] = [
    {
      title: "Tên nhà xe",
      dataIndex: "company",
      width: 40,
      __excelTitle__: "Tên nhà xe",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "% Phí xuất vé",
      dataIndex: "export_ticket_fee",
      __excelTitle__: "% Phí xuất vé",
      __numFmt__: "0.0%",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Số lượng vé đã bán	",
      dataIndex: "total_bookings",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Số lượng vé đã bán	",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Phí xuất vé",
      dataIndex: "total_export_ticket_fee",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Phí xuất vé",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
  ];

  const handleExportExcel = async () => {
    const response: any = await dispatch(
      getListReportFeeExportTicketCompanyCarlinkExcel({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        route_id: watchRoute?.label ? watchRoute?.value : watchRoute,
        company_id: watchCompany?.label ? watchCompany?.value : watchCompany,
        depart_time: watchTimeRoute?.label
          ? watchTimeRoute?.value
          : watchTimeRoute,
        options: watchOptions,
      })
    );
    if (!response?.error) {
      const listData = response?.payload?.data;
      const columnsExport =
        watchOptions === TypeOptions.report_detail
          ? columnsReportDetailExcel
          : columnsReportTotalExcel;

      const totalPrice = response?.payload?.total_price;
      const totalBookingPaid = response?.payload?.total_booking_paid;

      const totalPriceExportTicketFee =
        response?.payload?.total_price_export_ticket_fee;

      const listDataExcel =
        listData?.length > 0
          ? watchOptions === TypeOptions.report_detail
            ? listData?.map((report: DataReport) => ({
                ticket_code: report?.ticket_code ? report?.ticket_code : "",
                route: `${report?.route?.depart_point} - ${report?.route?.arrive_point}`,
                depart_date: report?.depart_date
                  ? `${moment(report?.depart_date).format("DD/MM/YYYY")}`
                  : "",
                depart_time: report?.depart_time
                  ? `${moment(report?.depart_time, "HH:mm").format("HH:mm")}`
                  : "",
                created_at: report?.created_at
                  ? `${moment(report?.created_at).format("DD/MM/YYYY")}`
                  : "",
                company: report?.company?.name ? report?.company?.name : "",
                export_ticket_fee_percent:
                  Number(report?.export_ticket_fee_percent) / 100,
                price: report?.price ? Number(report?.price) : 0,
                export_ticket_fee: report?.export_ticket_fee
                  ? Number(report?.export_ticket_fee)
                  : 0,
              }))
            : listData?.map((report: DataReport) => ({
                company: report?.company?.name ? report?.company?.name : "",
                export_ticket_fee: Number(report?.export_ticket_fee) / 100,
                total_bookings: report?.total_bookings
                  ? Number(report?.total_bookings)
                  : 0,
                total_export_ticket_fee: report?.total_export_ticket_fee
                  ? Number(report?.total_export_ticket_fee)
                  : 0,
              }))
          : [];
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Báo cáo phí xuất vé nhà xe");
      const header = columnsExport.map((col) => ({
        header: col.title,
        key: col.dataIndex as string,
      }));
      worksheet.columns = header;
      columnsExport.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width || 15;
        worksheet.getColumn(index + 1).alignment = {
          horizontal:
            (col?.__style__?.h as
              | "center"
              | "left"
              | "right"
              | "fill"
              | "justify") || "left",
          vertical: "middle",
        };
        worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || "general";
      });
      worksheet.addRows(listDataExcel);
      worksheet.getRow(1).font = {
        name: "Times New Roman",
        size: 12,
        bold: true,
      };
      if (watchOptions === TypeOptions.report_total) {
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          if (colNumber < 5) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "3AB54A" },
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        });
      } else {
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          if (colNumber < 10) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "3AB54A" },
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        });
      }
      let firstRowHasData = false;
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (cell.value) {
          firstRowHasData = true;
          return;
        }
      });
      if (firstRowHasData) {
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber > 1) {
            row.eachCell((cell) => {
              cell.font = { name: "Times New Roman", size: 12 };
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          }
        });
      } else {
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            let hasData = false;
            row.eachCell((cell) => {
              if (cell.value) {
                hasData = true;
                return;
              }
            });
            if (hasData) {
              row.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        });
      }
      const startColumnIndex = 1;

      const subLastRow = worksheet.addRow([]);
      subLastRow.getCell(1).value = "TỔNG (VND)";
      subLastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      subLastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };
      const endColumnIndexTotal =
        watchOptions === TypeOptions.report_detail
          ? startColumnIndex + columnsExport.length - 3
          : startColumnIndex + columnsExport.length - 3;
      worksheet.mergeCells(
        subLastRow.number,
        1,
        subLastRow.number,
        endColumnIndexTotal
      );
      if (watchOptions === TypeOptions.report_total) {
        subLastRow.getCell(3).value = totalBookingPaid;
        subLastRow.getCell(4).value = totalPriceExportTicketFee;
      } else {
        subLastRow.getCell(8).value = totalPrice;
        subLastRow.getCell(9).value = totalPriceExportTicketFee;
      }
      subLastRow.eachCell((cell) => {
        cell.font = { name: "Times New Roman", size: 14, bold: true };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      const lastRow = worksheet.addRow([]);
      lastRow.getCell(1).value = "@Bản quyền thuộc nhà xe Carlink";
      lastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      lastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };

      const endColumnIndex = startColumnIndex + columnsExport.length - 1;
      worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Báo cáo phí xuất vé nhà xe.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };
  const handleGetLabelCompany = (value: number | string) => {
    const agentCurrent = optionsCompany?.find(
      (agent) => agent?.value === value
    );
    return agentCurrent?.label || "Tất cả";
  };
  const filterOption = (
    input: string,
    option?: {
      label?: any;
      value?: any;
    }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onChangeBookingDate = (type: string) => {
    dispatch(setFilterBookingDate(type));
  };

  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return (
          Math.abs(current.diff(from, "days")) >=
          systemConfig?.export_report_month
        );
      }
      return false;
    },
    [systemConfig?.export_report_month]
  );
  return (
    <div className="Report bg-white">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          booking_date: listFilter?.booking_date,
          range_date_report: [
            listFilter?.from
              ? dayjs(listFilter?.from, "DD/MM/YYYY")
              : undefined,
            listFilter?.to ? dayjs(listFilter?.to, "DD/MM/YYYY") : undefined,
          ],
          options: listFilter?.options,
          route_id:
            listFilter?.route_id === ""
              ? { value: listFilter?.route_id, label: "Chọn tuyến" }
              : listFilter?.route_id,
          depart_time:
            listFilter?.depart_time === ""
              ? { value: "", label: "Giờ đi" }
              : listFilter?.depart_time,
          company_id: listFilter?.company_id
            ? listFilter?.company_id
            : { value: "", label: "Chọn nhà xe" },
        }}
      >
        <Row gutter={12}>
          <Col className="Report__typeDateReport">
            <Form.Item<FieldType>
              name="booking_date"
              className="required"
              label="Khoảng thời gian"
            >
              <Select
                placeholder="Theo ngày khởi hành"
                options={optionsBookingDate}
                onChange={onChangeBookingDate}
              />
            </Form.Item>
          </Col>
          <Col className="Report__rangeDateReport">
            <Form.Item<FieldType>
              name="range_date_report"
              rules={[
                { required: true, message: "Vui lòng chọn khoảng thời gian" },
              ]}
            >
              <RangePicker
                format="DD/MM/YYYY"
                disabledDate={disabledDaysDate}
                onChange={onChangeTimeReport}
                popupClassName="Report__timeReport__picker"
                placeholder={["Từ ngày", "Đến ngày"]}
              />
            </Form.Item>
          </Col>
          <Col className="Report__typeDateReport">
            <Form.Item<FieldType>
              className="required"
              name="options"
              label="Loại báo cáo"
              rules={[
                { required: true, message: "Vui lòng chọn báo cáo theo" },
              ]}
            >
              <Select
                onChange={onChangeOptions}
                placeholder="Loại báo cáo"
                options={optionsOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex align="center" gap={"small"} className="mb-24">
          <Image
            preview={false}
            width={20}
            height={20}
            className="d-flex"
            src={IconExclamationCircle}
            alt="Icon Exclamation Circle"
          />
          <Typography.Text className="fs-14 fw-500 text-color">
            Vui lòng chọn các thông tin bên trên để xem báo cáo
          </Typography.Text>
        </Flex>
        <Typography.Text className="fs-6 fw-500 text-color d-block mb-8">
          Bộ lọc nâng cao
        </Typography.Text>
        <Flex gap={"small"}>
          <Form.Item<FieldType> className="Report__route" name="route_id">
            <Select
              onChange={handleChangeRoute}
              options={optionsRoute}
              popupClassName="Report__popup"
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          {watchOptions === TypeOptions.report_detail && (
            <>
              <Form.Item<FieldType>
                className="Report__timeRoute"
                name="depart_time"
              >
                <Select onChange={onChangeTimeRoute} options={listTimeRoute} />
              </Form.Item>
              <Form.Item<FieldType> className="Report__agent" name="company_id">
                <Select
                  onChange={onChangeCompany}
                  options={optionsCompany}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </>
          )}
        </Flex>
        <Flex align="center" justify="space-between">
          <Form.Item className="Report__submit">
            <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
              <Button
                disabled={
                  (timeReport?.length === 2 ||
                    (listFilter?.from && listFilter?.to)) &&
                  watchOptions
                    ? false
                    : true
                }
                className="d-flex align-items-center h-auto"
                type="primary"
                htmlType="submit"
              >
                <IconFileExport width={16} height={16} />
                Xem báo cáo
              </Button>
            </ConfigProvider>
          </Form.Item>
          <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 2)} />
        </Flex>
        <Divider />
        <Flex>
          <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
            <Button
              ghost
              disabled={
                (timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)) &&
                watchOptions
                  ? false
                  : true
              }
              onClick={handleExportExcel}
              className={`Report__exportExcel d-flex align-items-center mr-12 h-auto ${
                (timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)) &&
                watchOptions
                  ? ""
                  : "disabled"
              }`}
              type="primary"
            >
              <IconExportExcel
                color={`${
                  (timeReport?.length === 2 ||
                    (listFilter?.from && listFilter?.to)) &&
                  watchOptions
                    ? "#435869"
                    : "#949494"
                }`}
                width={16}
                height={16}
              />
              Xuất Excel
            </Button>
          </ConfigProvider>
        </Flex>
        <Typography.Text className="d-block text-color-gray-3A3A3A fs-18 fw-600 mt-24 mb-8">
          Báo cáo phí xuất vé nhà xe (Theo ngày khởi hành)
        </Typography.Text>
        <Flex wrap="wrap" align="center" className="Report__filter">
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Từ ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[0]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Đến ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[1]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          {watchOptions && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Loại báo cáo:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {handleGetLabelTypeReport(watchOptions)}
              </Typography.Text>
            </Flex>
          )}
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Tuyến:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelRoute(watchRoute)}
            </Typography.Text>
          </Flex>
          {watchOptions === TypeOptions.report_detail && (
            <>
              <Flex align="center" className="Report__filter__item">
                <Typography.Text className="d-block fs-16 fw-500">
                  Giờ đi:
                </Typography.Text>
                <Typography.Text className="d-block fs-16 fw-600">
                  {handleGetLabelTimeRoute(watchTimeRoute)}
                </Typography.Text>
              </Flex>
              <Flex align="center" className="Report__filter__item">
                <Typography.Text className="d-block fs-16 fw-500">
                  Nhà xe:
                </Typography.Text>
                <Typography.Text className="d-block fs-16 fw-600">
                  {handleGetLabelCompany(watchCompany)}
                </Typography.Text>
              </Flex>
            </>
          )}
        </Flex>
      </Form>
      <Table
        className="Report__table"
        locale={{
          triggerDesc: "Sắp xếp tăng dần",
          triggerAsc: "Hủy sắp xếp",
          cancelSort: "Sắp xếp giảm dần",
        }}
        pagination={{
          total: pagination?.total,
          current: page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ["bottomRight"],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        dataSource={listReport?.length > 0 ? listReport : []}
        loading={loadingListReport}
        columns={
          typeReportFor === TypeOptions.report_detail
            ? columnsReportDetail
            : columnsReportTotal
        }
        summary={(pageData) => {
          return (
            listReport?.length > 0 &&
            page === pagination?.last_page && (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={
                      typeReportFor === TypeOptions.report_detail ? 7 : 2
                    }
                  >
                    <Flex align="center" gap="middle">
                      <Typography.Text className="text-color-default fw-700 fs-16">
                        TỔNG
                      </Typography.Text>
                      <Typography.Text className="text-color-default fw-500 fs-16">
                        (VND)
                      </Typography.Text>
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    align="right"
                    index={typeReportFor === TypeOptions.report_detail ? 7 : 2}
                  >
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {typeReportFor === TypeOptions.report_detail
                        ? totalPrice
                          ? formatPrice(totalPrice, 0, ",")
                          : 0
                        : totalTicketPaid
                        ? formatPrice(totalTicketPaid, 0, ",")
                        : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    align="right"
                    index={typeReportFor === TypeOptions.report_detail ? 8 : 3}
                  >
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {totalPriceExportTicketFee
                        ? formatPrice(totalPriceExportTicketFee, 0, ",")
                        : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          );
        }}
      />
    </div>
  );
};

export default ReportFeeExportTicketCompanyCarlink;
