import { Button, Col, Modal, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import iconAlert from '../../assets/images/ic-alert-balance.svg';
import { modalProps } from '../../types';

const ModalAlertBalanceAgent: React.FC<modalProps> = ({ okText, cancelText, modalOpen, onCancel, onOk, loading }) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const navigate = useNavigate();

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
    navigate('/dashboard/account-deposit');
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      width={570}
      className="modal-alert-balance"
    >
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col>
          <img src={iconAlert} alt="" />
        </Col>
      </Row>
      <Typography className="fw-600 fs-24 text-center mt-28" style={{ color: '#3a3a3a' }}>
        Không đủ số dư
      </Typography>
      <Typography className="fw-400 fs-20 text-center mt-16 mw-500 mb-40 text-alert-balance">
        Rất tiếc, số dư của đại lý không đủ để thực hiện thao tác này, Đại lý vui lòng nạp thêm tiền để không gián đoạn
        các dịch vụ.
      </Typography>
      <Row className="d-flex justify-content-center align-item-center">
        <Button
          key="cancel"
          className=" w-244 h-btn-46 me-3 btn-back agent"
          loading={loading}
          onClick={() => modalHandleCancel()}
        >
          Trở lại
        </Button>
        <Button
          key="ok"
          loading={loading}
          type="primary"
          className="w-244 h-btn-46 btn-success"
          htmlType="submit"
          style={{ paddingRight: '0' }}
          onClick={() => modalHandleOk()}
        >
          Đi tới nạp tiền
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalAlertBalanceAgent;
