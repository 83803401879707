import React, { useState } from "react";
import { Form, Input } from "antd";

interface PriceInputProps {
  onChange?: (value: any) => void;
  placeholder?: string;
  value?: any;
  name: any;
  rules?: any;
  form: any;
}

export const formatNumber = (value: number) => {
  return value.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const PriceInputGroupSeat: React.FC<PriceInputProps> = ({
  form,
  onChange,
  placeholder,
  name,
  rules,
}) => {
  const [inputValue, setInputValue] = useState<string>("0");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const currentValues = form.getFieldValue("price_json");
    const numericValue = parseFloat(rawValue?.replace(/[^0-9.]/g, "")); // Lấy ra số từ chuỗi
    if (!isNaN(numericValue)) {
      currentValues[name] = {
        name: currentValues[name].name,
        seat_price: formatNumber(numericValue),
      };
      form.setFieldsValue({
        price_json: currentValues,
      });
    } else {
      currentValues[name] = {
        name: currentValues[name].name,
        seat_price: 0,
      };
      form.setFieldsValue({
        price_json: currentValues,
      });
    }

    if (onChange) onChange(formatNumber(numericValue));
  };

  // Hàm định dạng số với dấu phân tách phần ngàn
  return (
    <Form.Item
      name={[name, "seat_price"]}
      className="form-item-name required"
      validateTrigger={["onChange", "onBlur"]}
      dependencies={["is_diagram"]}
      rules={[
        ({ getFieldValue }) => ({
          validator(_: any, value: any) {
            if (getFieldValue("is_diagram")) {
              if (value) {
                const data = Number(value?.replace(/,/g, ""));
                if (data === 0 || !data) {
                  return Promise.reject(new Error("Vui lòng nhập giá nhóm ghế"));
                } else {
                  if (data > 100000000) {
                    return Promise.reject(
                      new Error("Vui lòng nhập tối đa 100,000,000")
                    );
                  } else {
                    return Promise.resolve();
                  }
                }
              } else {
                return Promise.reject(new Error("Vui lòng nhập giá nhóm ghế"));
              }
            } else {
              return Promise.resolve();
            }
          },
        }),
      ]}
    >
      <Input
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder ?? ""}
        addonAfter="VND"
      />
    </Form.Item>
  );
};

export default PriceInputGroupSeat;
