import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { convertTimeFormat, formatDate } from '../lib/utils';
import { useAppSelector } from '../redux/hooks';
import { handleGetUrlGuide } from '../utils/helpers';
import TutorialsVideo from './TutorialsVideo';

function HeaderBooking({ listBooking, title, dataCustomerBooking, isListTicket, isContract }: any) {
  const { t } = useTranslation();
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  return (
    <>
      {isContract && (
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography className="fw-600 fs-18">{t(title)}</Typography>
          <div style={{ marginRight: '40px' }}>
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 8)} />
          </div>
        </Row>
      )}
      <Row gutter={12} className="mt-2">
        <Col>
          <Typography className="history-info">
            Tuyến:
            <span className="history-detail">
              {` ${
                isListTicket
                  ? dataCustomerBooking?.route?.depart_point
                  : listBooking?.schedule?.route?.depart_point ?? ''
              } - ${
                isListTicket
                  ? dataCustomerBooking?.route?.arrive_point
                  : listBooking?.schedule?.route?.arrive_point ?? ''
              }`}
            </span>
          </Typography>
        </Col>
        <Col>
          <Typography className="history-info">
            Ngày đi:
            <span className="history-detail">
              {isListTicket
                ? ` ${formatDate(dataCustomerBooking?.depart_date, 'DD/MM/YYYY')}`
                : ` ${formatDate(listBooking?.travel_info?.depart_date, 'DD/MM/YYYY')}` ?? ''}
            </span>
          </Typography>
        </Col>
        <Col>
          <Typography className="history-info">
            Giờ đi:
            <span className="history-detail">
              {isListTicket
                ? ` ${convertTimeFormat(dataCustomerBooking?.depart_time)}`
                : ` ${convertTimeFormat(listBooking?.schedule?.depart_time)}` ?? ''}
            </span>
          </Typography>
        </Col>
        <Col>
          <Typography className="history-info">
            Ngày đến:
            <span className="history-detail">
              {isListTicket
                ? ` ${formatDate(dataCustomerBooking?.arrive_date, 'DD/MM/YYYY')}`
                : ` ${formatDate(listBooking?.travel_info?.arrive_time, 'DD/MM/YYYY')}` ?? ''}
            </span>
          </Typography>
        </Col>
        <Col>
          <Typography className="history-info">
            Giờ đến:
            <span className="history-detail">
              {isListTicket
                ? ` ${convertTimeFormat(dataCustomerBooking?.arrive_time)}`
                : ` ${convertTimeFormat(listBooking?.schedule?.arrive_time)}` ?? ''}
            </span>
          </Typography>
        </Col>
      </Row>
    </>
  );
}

export default HeaderBooking;
