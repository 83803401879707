import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Modal,
  Row,
  Select,
  Statistic,
  Switch,
  Tooltip,
  Typography
} from 'antd';
import { CountdownProps } from 'antd/lib';
import dayjs from 'dayjs';
import { Lunar } from 'lunar-typescript';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../assets/styles/desktop/busBooking.scss';
import SeatMapRender from '../../../components/SeatMapRender';
import { DateFormat } from '../../../config/constant';
import { convertTimeFormat } from '../../../lib/utils';
import { useAppSelector } from '../../../redux/hooks';
import { listBookingSelector } from '../../../redux/slice/bookings/listBooking';

const ModalChangeBooking: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  listBooking,
  formSearch,
  targetTime,
  setTargetTicket,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const { Countdown } = Statistic;
  const [activeKey, setActiveKey] = useState<string>('1');
  const { t } = useTranslation();
  const [formChangeBooking]: [FormInstance] = Form.useForm();
  const provinceId = Form.useWatch('route_id', formSearch);
  const onChangeInsurance = () => {};
  const { listFilterBooking } = useAppSelector(listBookingSelector);
  const listRoute = listFilterBooking?.length > 0 && listFilterBooking?.map((item: any) => item?.route);
  const [departTimeOptions, setDepartTimeOptions] = useState<any>([]);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };
  const modalHandleCancel = () => {
    onCancel();
  };

  const onChange: CountdownProps['onChange'] = (val) => {};
  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      formChangeBooking.scrollToField(firstErrorField.name);
    }
  };
  const onFinishCountdown: CountdownProps['onFinish'] = () => {
    setTargetTicket(undefined);
    modalHandleCancel();
  };
  const onChangeRoute = (value: number, option: any) => {
    const selectedRoute = listFilterBooking.find((item: any) => item.route.id === value);
    if (selectedRoute) {
      const updatedOptions = selectedRoute.depart_times.map((departTime: string, index: number) => {
        const emptySeats = selectedRoute.empty_seats[index];
        return {
          value: departTime,
          label: `${convertTimeFormat(departTime)} (Còn ${emptySeats} ghế trống)`,
          key: `${departTime}_${index}`,
        };
      });
      formSearch.setFieldsValue({
        depart_time: updatedOptions[0]?.value,
      });
      setDepartTimeOptions(updatedOptions);
    }
    setActiveKey('1');
  };
  const filterOptionRoute = (
    input: string,
    option?: {
      label: string;
      value: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const onFinish = (values: any) => {
    if (+activeKey === 1) {
    } else {
    }
  };
  useEffect(() => {
    if (provinceId) {
      formChangeBooking.setFieldValue('route_id', provinceId);
    }
  }, [provinceId, formChangeBooking]);
  useEffect(() => {
    if (listFilterBooking?.length) {
      const selectedRoute = listFilterBooking?.find((item: any) => item.route.id === provinceId);
      if (selectedRoute) {
        const updatedOptions = selectedRoute.depart_times.map((departTime: string, index: number) => {
          const emptySeats = selectedRoute.empty_seats[index];
          return {
            value: departTime,
            label: `${convertTimeFormat(departTime)} (Còn ${emptySeats} ghế trống)`,
            key: `${departTime}_${index}`,
          };
        });
        setDepartTimeOptions(updatedOptions);
      }
    }
  }, [listFilterBooking, provinceId, formSearch]);

  return (
    <Modal
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      width={900}
      maskClosable={false}
      className="modal-bus-booking"
    >
      <Card className="card-modal-booking mt-1">
        <Form
          layout="vertical"
          className="form-booking-multi"
          form={formChangeBooking}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          labelAlign={'left'}
          labelWrap
          onFieldsChange={(allFields) => {
            scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
          }}
        >
          <Typography.Text className="fw-600 fs-18 ">{t('Đổi vé')}</Typography.Text>
          <Row className="body-change-book mt-3">
            <Col>
              <Countdown
                className="countdown-booking "
                title="Thời gian còn lại:"
                value={targetTime}
                onChange={onChange}
                format="mm:ss"
                onFinish={onFinishCountdown}
              />
            </Col>
          </Row>
          <Row gutter={36} className="mt-3">
            <Col>
              <Typography className="fw-500 fs-16">
                Mã vé: <span className="fw-600 fs-16"> 56UN7E</span>
              </Typography>
            </Col>
            <Col>
              <Typography className="fw-500 fs-16">
                Họ và tên:<span className="fw-600 fs-16"> Lê Phương Anh</span>
              </Typography>
            </Col>
            <Col>
              <Typography className="fw-500 fs-16">
                Số điện thoại:<span className="fw-600 fs-16"> 0389688901</span>
              </Typography>
            </Col>
          </Row>
          <Divider style={{ margin: '16px 0', height: '1px', background: '#dcdcdc' }} />
          <div className="change-booking-content">
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item name="route_id" className="form-item-name" label="Tuyến">
                  <Select
                    className="input-h36"
                    showSearch
                    suffixIcon={<></>}
                    onChange={(value: any, option: any) => onChangeRoute(value, option)}
                    placeholder="Chọn tuyến"
                    size="large"
                    disabled
                    style={{ width: '100%' }}
                    options={
                      listRoute?.length
                        ? listRoute?.map((item: any) => {
                            return {
                              value: item.id,
                              label: `${item.depart_point} - ${item.arrive_point}`,
                            };
                          })
                        : []
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="date_booking" className="data-picker booking" label="Ngày đi">
                  <DatePicker
                    className="w-100 input-height"
                    format={DateFormat}
                    placeholder={t('Chọn ngày đi')}
                    changeOnBlur
                    defaultValue={dayjs(new Date())}
                    allowClear={false}
                    cellRender={(current: any) => {
                      // @ts-ignore
                      let d = Lunar.fromDate(new Date(current));
                      const lunar = d.getDay();
                      const lunarMonth = d.getMonth();
                      return (
                        <div>
                          <div className="fs-14 fw-500">{current.date()}</div>
                          <div className="fs-12 lunar fw-500">
                            {lunar}
                            {
                              // eslint-disable-next-line
                              lunar == 1 || current.date() == 1 ? `/${lunarMonth}` : null
                            }
                          </div>
                        </div>
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <span className="fw-500" style={{ color: '#435869' }}>
                  Giờ đi
                </span>
                <Form.Item name="depart_time" className="required">
                  <Select
                    filterOption={filterOptionRoute}
                    size="large"
                    style={{ width: '100%' }}
                    options={
                      departTimeOptions?.length
                        ? departTimeOptions?.map((item: any) => {
                            return {
                              value: item?.value,
                              label: item?.label,
                              key: item?.key,
                            };
                          })
                        : []
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
                <Form.Item name="is_insurance" className="booking-input-disable">
                  <Switch onChange={onChangeInsurance} className="mt-3" />
                </Form.Item>
                <Typography.Text className="fw-500 fs-14">{t(`Bảo hiểm chuyến đi (10% giá vé) `)}</Typography.Text>
                <Tooltip title={'Xem chi tiết chính sách bảo hiểm'}>
                  <InfoCircleFilled style={{ color: '#F8B507' }} />
                </Tooltip>
              </Col>
              <Col className="mt-3 pr-1">
                <Typography className="fs-14 fw-500">
                  <InfoCircleOutlined style={{ color: '#F8B507' }} /> Chỉ được chọn ghế cùng giá
                </Typography>
              </Col>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '5px' }}>
              <Col>
                {listBooking?.schedule?.bus_type?.seat_map?.count_floor && (
                  <div className="seat-render-custom">
                    <SeatMapRender
                      column={listBooking?.schedule?.bus_type?.seat_map?.column ?? 0}
                      row={listBooking?.schedule?.bus_type?.seat_map?.row ?? 0}
                      seatCount={listBooking?.schedule?.bus_type?.seat_map?.count_floor}
                      seatMatrixData={listBooking?.schedule?.bus_type?.seat_map}
                      isChangeBooking={true}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>

          <Row className="d-flex" style={{ columnGap: '12px', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ display: 'flex', columnGap: '12px' }}>
              <Typography className="fw-500 fs-16">
                Ghế đang chọn:
                <span style={{ color: '#3AB54A' }} className="fw-600">
                  {' A3'}
                </span>
              </Typography>
              <Typography className="fw-500 fs-16">
                Giá ghế:
                <span style={{ color: '#3AB54A' }} className="fw-600">
                  {'500,000'}
                </span>
              </Typography>
            </Col>
            <Col style={{ columnGap: '12px', display: 'flex' }}>
              <Button key="cancel" className=" w-160 h-btn-48" onClick={() => modalHandleCancel()}>
                {cancelText}
              </Button>
              <Button key="ok" type="primary" className="w-160 h-btn-48 btn-success" htmlType="submit">
                {okText}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};
export default ModalChangeBooking;
