import { CreateBusTypeState, ListBusTypeRequest, UpdateBusTypeState } from '../types/busType';
import { getAxiosClientWithToken } from './index';

export const getListBusTypeApi = (params: ListBusTypeRequest) => {
  return getAxiosClientWithToken().get('/bus-type', { params });
};
export const getListBusTypeDetailApi = (id: number) => {
  return getAxiosClientWithToken().get(`/bus-type/${id}`);
};
export const updateListBusTypeApi = (params: UpdateBusTypeState) => {
  const restClient = getAxiosClientWithToken();
  return restClient.put(`/bus-type/${params.id}`, params);
};
export const updateListBusTypeStatusApi = (params: { id: string; status: boolean }) => {
  const restClient = getAxiosClientWithToken();
  return restClient.put(`/bus-type/status/${params.id}`, { status: params.status ? 0 : 1 });
};
export const createBusTypeApi = (params: CreateBusTypeState) => {
  return getAxiosClientWithToken().post(`/bus-type`, params);
};
export const getAllBusTypeApi = () => {
  return getAxiosClientWithToken().get(`/bus-type`);
};
export const getAvailableBusTypeApi = (params: ListBusTypeRequest) => {
  return getAxiosClientWithToken().get(`/bus-type/get-all-bus-type`, { params });
};
