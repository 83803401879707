import { Image } from 'antd';
import icAPI from '../../assets/images/ic-api.svg';
import icCar from '../../assets/images/ic-car.svg';
import icChair from '../../assets/images/ic-chair.svg';
import icEmployee from '../../assets/images/ic-employee.svg';
import icNewFeed from '../../assets/images/ic-new-feed.svg';
import icNotify from '../../assets/images/ic-notify.svg';
import icOperating from '../../assets/images/ic-operating.svg';
import icReport from '../../assets/images/ic-report.svg';
import icText from '../../assets/images/ic-text.svg';
import icUser from '../../assets/images/ic-user.svg';
import IconSystemSetting from '../../assets/images/IconSystemSetting.svg';
import icHome from '../../assets/images/sidebar/icHome.svg';
import { USER_GROUP } from '../../config/constant';
import { PERMISSIONS } from '../../config/permission';
import config from '../../config/routes';
import AccountDeposit from '../../pages/accountDeposit/AccountDeposit';
import AccountDepositCarlink from '../../pages/accountDepositCarlink/AccountDepositCarlink';
import AccountDepositPromotion from '../../pages/accountDepositPromotion/AccountDepositPromotion';
import ManageAgent from '../../pages/agents/ManageAgent';
import BusBooking from '../../pages/bookings/BusBooking';
import ListContract from '../../pages/bookings/contracts/ListContract';
import ListTicket from '../../pages/bookings/tickets/ListTicket';
import ManageBusCompany from '../../pages/busCompanys/ManageBusCompany';
import ListBus from '../../pages/buses/ListBus';
import InfoAgent from '../../pages/client/InfoAgent';
import InfoCompany from '../../pages/client/InfoCompany';
import ListDriver from '../../pages/drivers/ListDriver';
import GeneralAgents from '../../pages/generalAgents/GeneralAgents';
import ManageSignUp from '../../pages/manageSignUp/ManageSignUp';
import ManageSystem from '../../pages/manageSystem/ManageSystem';
import Notify from '../../pages/notify/Notify';
import ListOffice from '../../pages/offices/ListOffice';
import PickupLocation from '../../pages/pickupLocation';
import ListPolicy from '../../pages/policies/ListPolicy';
import Route from '../../pages/routes';
import Schedule from '../../pages/schedules';
import ListService from '../../pages/services/ListService';
import SystemConfig from '../../pages/systemConfig/SystemConfig';
import SystemDepartureFee from '../../pages/systemDepartureFee/SystemDepartureFee';
import SystemGuide from '../../pages/systemGuide/SystemGuide';
import SettingSchedulePrice from '../../pages/tickets/SettingSchedulePrice';
import ListTrip from '../../pages/trips/ListTrips';
import TypeBus from '../../pages/typeBuses/TypeBus';
import ListUser from '../../pages/users/ListUser';
import InfoMainAgent from '../../pages/client/InfoMainAgent';
import DepositMainAgent from '../../pages/accountDepositCarlink/DepositMainAgent';
import HistoryDepositMainAgent from '../../pages/historyDepositMainAgent/HistoryDepositMainAgent';
import InsuranceConfig from '../../pages/insuranceConfig/InsuranceConfig';
import ListRegisterAdvertise from '../../pages/listRegisterAdvertise/views/ListRegisterAdvertise';
import SettingPromotion from '../../pages/promotions/SettingPromotion';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  route: {
    path: '/',
    routes: [
      {
        path: config.routes.home,
        routes: [
          {
            path: config.routes.home,
            name: 'Trang chủ',
            disable: false,
            component: <InfoCompany />,
            icon: <Image src={icHome} preview={false} />,
            parent: true,
            home: true,
            parentGroup: [USER_GROUP.CARLINK],
          },
          {
            path: config.routes.dashboard,
            name: 'Bảng tin',
            disable: true,
            icon: <Image src={icNewFeed} preview={false} />,
            parent: true,
            parentGroup: [USER_GROUP.CARLINK],
            routes: [
              {
                path: config.routes.companyInfo,
                disable: false,
                name: 'Thông tin nhà xe',
                component: <InfoCompany />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
                displayGroup: [USER_GROUP.CAR_COMPANY],
              },
              {
                path: config.routes.agentInfo,
                disable: false,
                name: 'Thông tin đại lý',
                component: <InfoAgent />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.CAR_COMPANY, USER_GROUP.MAIN_AGENT],
              },
              {
                path: config.routes.mainAgentInfo,
                disable: false,
                name: 'Thông tin tổng đại lý',
                component: <InfoMainAgent />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT],
              },
              {
                path: config.routes.policy,
                disable: false,
                name: 'Chính sách nhà xe',
                component: <ListPolicy />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.MAIN_AGENT],
              },
              {
                path: config.routes.accountDeposit,
                disable: false,
                name: 'Nạp tiền tài khoản',
                component: <AccountDeposit />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.CAR_COMPANY, USER_GROUP.MAIN_AGENT],
                checkIsAdmin: true,
              },
              {
                path: config.routes.accountDepositMainAgent,
                disable: false,
                name: 'Nạp tiền tài khoản',
                component: <DepositMainAgent />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT],
                checkIsAdmin: true,
              },
              {
                path: config.routes.accountPromotion,
                disable: false,
                name: 'Nạp tiền khuyến mãi nhà xe',
                component: <AccountDepositPromotion />,
                blockGroup: [USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
                authority: [PERMISSIONS.CARLINK.manager_financial_carlink],
                checkIsAdmin: true,
              },

              {
                path: config.routes.accountDepositCarCompany,
                disable: false,
                name: 'Quản lý nạp tiền nhà xe',
                component: <AccountDepositCarlink />,
                blockGroup: [USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
                authority: [PERMISSIONS.CARLINK.manager_financial_carlink],
                checkIsAdmin: true,
              },
              {
                path: config.routes.historyDepositMainAgent,
                disable: false,
                name: 'Quản lý nạp tiền tổng đại lý',
                component: <HistoryDepositMainAgent />,
                blockUser: [USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
                authority: [PERMISSIONS.CARLINK.manager_financial_carlink],
                isAdmin: true,
              },
              {
                path: config.routes.insuranceConfig,
                disable: false,
                name: 'Cấu hình bảo hiểm',
                component: <InsuranceConfig />,
                blockUser: [USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
                authority: [PERMISSIONS.CARLINK.manager_financial_carlink],
                isAdmin: true,
              },
              {
                path: config.routes.insuranceConfig,
                disable: false,
                name: 'Chính sách bảo hiểm',
                component: <InsuranceConfig />,
                blockUser: [USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT, USER_GROUP.CARLINK],
                authority: [PERMISSIONS.MAIN_AGENT.manager_main_agent_account],
                isAdmin: true,
              },
              {
                path: config.routes.accountDepositCarlink,
                disable: false,
                name: 'Nạp tiền hệ thống Carlink',
                component: <AccountDepositCarlink />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
                checkIsAdmin: true,
              },
              {
                path: config.routes.accountDepositAgent,
                disable: false,
                name: 'Quản lý nạp tiền đại lý',
                component: <AccountDeposit />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
                checkIsAdmin: true,
              },
              {
                path: config.routes.accountDepositMainAgent,
                disable: false,
                name: 'Nạp tiền hệ thống Carlink',
                component: <AccountDeposit />,
                blockGroup: [USER_GROUP.CARLINK, USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
                checkIsAdmin: true,
              },
              {
                path: config.routes.listRegisterTrial,
                disable: false,
                name: 'DS đăng ký sử dụng PM',
                countRegisterPM: true,
                component: <ManageSignUp />,
                blockGroup: [USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
              },
              {
                path: config.routes.listRegisterAdvertise,
                disable: false,
                name: 'DS đăng ký quảng cáo',
                countRegisterAD: true,
                component: <ListRegisterAdvertise />,
                blockGroup: [USER_GROUP.CAR_COMPANY, USER_GROUP.AGENT, USER_GROUP.MAIN_AGENT],
              },
              {
                path: config.routes.listPromotion,
                disable: false,
                name: 'Chương trình khuyến mại',
                countRegisterAD: true,
                component: <SettingPromotion />,
                blockGroup: [USER_GROUP.AGENT, USER_GROUP.CARLINK],
              },
            ],
          },
          {
            path: config.routes.listAgencyManagement,
            name: 'DS nhà xe/đại lý',
            disable: true,
            icon: <Image src={icCar} preview={false} />,
            parent: true,
            parentGroup: [USER_GROUP.CARLINK],
            routes: [
              {
                path: config.routes.listCarCompany,
                disable: false,
                name: 'Danh sách nhà xe',
                component: ManageBusCompany,
                authority: [PERMISSIONS.CARLINK.update_company],
              },
              {
                path: config.routes.listGeneralAgency,
                disable: false,
                name: 'Danh sách tổng đại lý',
                component: GeneralAgents,
                authority: [PERMISSIONS.CARLINK.update_company],
              },
              {
                path: config.routes.listAgent,
                disable: false,
                name: 'Danh sách đại lý',
                component: ManageAgent,
                authority: [PERMISSIONS.CAR_COMPANY.manager_agent_account_for_company],
              },
            ],
          },
          {
            path: config.routes.bookingManagement,
            redirect: '/',
            disable: true,
            name: 'Quản lý đặt vé',
            parent: true,
            parentGroup: [USER_GROUP.CARLINK, USER_GROUP.MAIN_AGENT],
            icon: <Image src={icChair} preview={false} />,
            children: [
              {
                path: config.routes.booking,
                disable: false,
                name: 'Đặt vé',
                component: BusBooking,
                authority: [
                  PERMISSIONS.CAR_COMPANY.manager_company_order_print_ticket,
                  PERMISSIONS.AGENT.manager_agent_order_print_ticket,
                  // PERMISSIONS.MAIN_AGENT.manager_main_agent_order_print_ticket,
                ],
              },
              {
                path: config.routes.listTicket,
                disable: false,
                name: 'Danh sách vé đã bán',
                component: ListTicket,
                authority: [
                  PERMISSIONS.CAR_COMPANY.manager_company_order_print_ticket,
                  PERMISSIONS.AGENT.manager_agent_order_print_ticket,
                  // PERMISSIONS.MAIN_AGENT.manager_main_agent_order_print_ticket,
                ],
              },
              {
                path: config.routes.listContract,
                disable: false,
                name: 'Danh sách hợp đồng thuê xe',
                component: ListContract,
                authority: [
                  PERMISSIONS.CAR_COMPANY.manager_company_order_print_ticket,
                  PERMISSIONS.AGENT.manager_agent_order_print_ticket,
                  // PERMISSIONS.MAIN_AGENT.manager_main_agent_order_print_ticket,
                ],
              },
            ],
          },
          {
            exact: true,
            path: config.routes.orderManagement,
            redirect: '/',
            disable: true,
            name: 'DS đơn hàng online',
            icon: <Image src={icText} preview={false} />,
            parent: true,
            parentGroup: [USER_GROUP.CAR_COMPANY],
            children: [
              {
                path: config.routes.orderPending,
                name: 'Đơn hàng chưa xử lý',
                disable: false,
                component: './Welcome',
                displayGroup: [USER_GROUP.CAR_COMPANY],
                hasApi: true,
                // displayGroup: [USER_GROUP.CARLINK],
                orderCount: true,
                authority: [
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_publish_ticket,
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_order_print_ticket,
                  // PERMISSIONS.CAR_COMPANY.manager_company_publish_ticket,
                  // PERMISSIONS.CAR_COMPANY.manager_company_order_print_ticket,
                ],
              },
              {
                path: config.routes.orderSuccess,
                name: 'Đơn hàng đã xử lý',
                disable: false,
                component: './Welcome',
                // displayGroup: [USER_GROUP.CARLINK],
                displayGroup: [USER_GROUP.CAR_COMPANY],
                hasApi: true,
                authority: [
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_publish_ticket,
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_order_print_ticket,
                  // PERMISSIONS.CAR_COMPANY.manager_company_publish_ticket,
                  // PERMISSIONS.CAR_COMPANY.manager_company_order_print_ticket,
                ],
              },
              {
                path: config.routes.orderTicket,
                name: 'Danh sách vé theo mã',
                disable: false,
                component: './Welcome',
                hasApi: true,
                displayGroup: [USER_GROUP.CAR_COMPANY],
                // displayGroup: [USER_GROUP.CARLINK],
                authority: [
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_publish_ticket,
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_order_print_ticket,
                  // PERMISSIONS.CAR_COMPANY.manager_company_publish_ticket,
                  // PERMISSIONS.CAR_COMPANY.manager_company_order_print_ticket,
                ],
              },
            ],
          },
          {
            path: '/bus-management',
            name: 'Quản lý điều hành',
            disable: true,
            redirect: '/',
            parent: true,
            parentGroup: [USER_GROUP.CARLINK],
            icon: <Image src={icOperating} preview={false} />,
            routes: [
              {
                path: config.routes.listTrip,
                disable: false,
                name: 'Quản lý điều hành xe',
                component: ListTrip,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating],
              },
              {
                path: config.routes.listSchedule,
                disable: false,
                name: 'Quản lý lịch chạy',
                component: <Schedule />,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating],
              },
              {
                path: config.routes.listRoute,
                disable: false,
                name: 'Quản lý tuyến xe',
                component: <Route />,
                authority: [
                  PERMISSIONS.CAR_COMPANY.manager_operating,
                  // ROLE_PERMISSIONS.manager_operating_carlink,
                ],
              },
              {
                path: config.routes.listOffice,
                disable: false,
                name: 'Quản lý văn phòng nhà xe',
                component: <ListOffice />,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating],
              },
              {
                path: config.routes.listPickUpLocation,
                disable: false,
                name: 'Quản lý điểm đón / trả',
                component: <PickupLocation />,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating],
              },
              {
                path: config.routes.listBus,
                disable: false,
                name: 'Quản lý xe',
                component: ListBus,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating],
              },

              {
                path: config.routes.listDriver,
                disable: false,
                name: 'Quản lý tài xế / phụ xe',
                component: <ListDriver />,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating],
              },
              {
                path: config.routes.listTicketSetting,
                disable: false,
                name: 'Quản lý lịch tăng giảm giá vé',
                component: SettingSchedulePrice,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating],
              },
              {
                path: config.routes.listBusType,
                disable: false,
                name: 'Quản lý loại xe & sơ đồ ghế',
                component: <TypeBus />,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating, PERMISSIONS.CARLINK.manager_operating_carlink],
              },
              {
                path: config.routes.listService,
                disable: false,
                name: 'Quản lý tiện ích xe',
                component: <ListService />,
                authority: [PERMISSIONS.CAR_COMPANY.manager_operating, PERMISSIONS.CARLINK.manager_operating_carlink],
              },
            ],
          },
          {
            path: 'report-manager',
            disable: true,
            name: 'Báo cáo',
            parent: true,
            parentGroup: [USER_GROUP.CARLINK],
            icon: <Image src={icReport} preview={false} />,
            routes: [
              {
                path: config.routes.reportDetail,
                disable: false,
                name: 'Báo cáo chi tiết',
                component: './Welcome',
                authority: [
                  PERMISSIONS.CAR_COMPANY.manager_company_report,
                  PERMISSIONS.AGENT.manager_agent_report,
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_report,
                ],
              },
              {
                path: config.routes.reportRoute,
                disable: false,
                name: 'Báo cáo tổng hợp theo tuyến',
                component: './Welcome',
                authority: [
                  PERMISSIONS.CAR_COMPANY.manager_company_report,
                  PERMISSIONS.AGENT.manager_agent_report,
                  // PERMISSIONS.MAIN_AGENT.manager_main_agent_report,
                ],
              },
              {
                path: config.routes.reportTotal,
                disable: false,
                name: 'Báo cáo tổng hợp',
                component: './Welcome',
                authority: [
                  PERMISSIONS.AGENT.manager_agent_report,
                  // PERMISSIONS.MAIN_AGENT.manager_main_agent_report,
                ],
              },
              {
                path: config.routes.reportAgent,
                disable: false,
                name: 'Báo cáo tổng hợp theo đại lý',
                component: './Welcome',
                authority: [PERMISSIONS.CAR_COMPANY.manager_company_report],
              },
              {
                path: config.routes.reportDriver,
                disable: false,
                name: 'Báo cáo tài xế, phụ xe',
                component: './Welcome',
                authority: [PERMISSIONS.CAR_COMPANY.manager_company_report],
              },
              {
                path: config.routes.reportCustomer,
                disable: false,
                name: 'Báo cáo khách hàng',
                component: './Welcome',
                authority: [PERMISSIONS.CAR_COMPANY.manager_company_report],
              },
              {
                path: config.routes.reportCancel,
                disable: false,
                name: 'Báo cáo hoàn huỷ',
                component: './Welcome',
                authority: [
                  PERMISSIONS.CAR_COMPANY.manager_company_report,
                  PERMISSIONS.AGENT.manager_agent_report,
                  PERMISSIONS.CARLINK.manager_operating_carlink,
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_report,
                ],
              },
              {
                path: config.routes.reportContract,
                disable: false,
                name: 'Báo cáo hợp đồng thuê xe',
                component: './Welcome',
                authority: [
                  PERMISSIONS.CAR_COMPANY.manager_company_report,
                  PERMISSIONS.AGENT.manager_agent_report,
                  // PERMISSIONS.MAIN_AGENT.manager_main_agent_report,
                ],
              },
              {
                path: config.routes.reportRechargeAgent,
                disable: false,
                name: 'Báo cáo tiền nạp đại lý',
                component: './Welcome',
                authority: [PERMISSIONS.CAR_COMPANY.manager_company_report],
              },
              {
                path: config.routes.reportRecharge,
                disable: false,
                name: 'Báo cáo tiền nạp',
                component: './Welcome',
                authority: [
                  PERMISSIONS.AGENT.manager_agent_report,
                  // PERMISSIONS.MAIN_AGENT.manager_main_agent_report,
                ],
              },
              {
                path: config.routes.reportBalanceChange,
                disable: false,
                name: 'Báo cáo biến động số dư',
                component: './Welcome',
                authority: [PERMISSIONS.AGENT.manager_agent_report, PERMISSIONS.MAIN_AGENT.manager_main_agent_report],
              },
              {
                path: config.routes.reportRechargeCarlink,
                disable: false,
                name: 'Báo cáo tiền nạp Carlink',
                component: './Welcome',
                authority: [PERMISSIONS.CAR_COMPANY.manager_company_report],
              },
              {
                name: 'Báo cáo phí xuất bến',
                path: config.routes.reportFeeDepart,
                disable: false,
                authority: [PERMISSIONS.CAR_COMPANY.manager_company_report],
              },
              {
                name: 'Báo cáo phí xuất vé',
                path: config.routes.reportExportTicket,
                disable: false,
                authority: [PERMISSIONS.CAR_COMPANY.manager_company_report],
              },
              {
                name: 'Báo cáo nạp tiền nhà xe',
                path: config.routes.reportRechargeCompany,
                disable: false,
                authority: [PERMISSIONS.CARLINK.manager_carlink_report],
              },
              {
                name: 'Báo cáo phí xuất bến nhà xe',
                path: config.routes.reportFeeDepartCompany,
                disable: false,
                authority: [PERMISSIONS.CARLINK.manager_carlink_report],
              },
              {
                name: 'Báo cáo phí xuất vé nhà xe',
                path: config.routes.reportFeeExportTicketCompany,
                disable: false,
                authority: [PERMISSIONS.CARLINK.manager_carlink_report],
              },
              {
                name: 'Báo cáo nạp tiền KM nhà xe',
                path: config.routes.reportDepositPromotionCompany,
                disable: false,
                authority: [PERMISSIONS.CARLINK.manager_carlink_report],
              },
              {
                name: 'Báo cáo nguồn vé API',
                path: config.routes.reportSourceAPI,
                disable: false,
                authority: [PERMISSIONS.CARLINK.manager_carlink_report],
              },
            ],
          },
          {
            path: config.routes.userManagement,
            disable: true,
            name: 'Quản lý tài khoản',
            parent: true,
            parentGroup: [USER_GROUP.CARLINK],
            icon: <Image src={icUser} preview={false} />,
            routes: [
              {
                path: config.routes.listUser,
                disable: false,
                name: 'Danh sách tài khoản',
                component: <ListUser />,
                authority: [
                  PERMISSIONS.CARLINK.manager_carlink_account,
                  PERMISSIONS.CAR_COMPANY.manager_company_account,
                  PERMISSIONS.AGENT.manager_agent_account,
                  PERMISSIONS.MAIN_AGENT.manager_main_agent_account,
                ],
              },
            ],
          },
          {
            path: config.routes.apiManagement,
            redirect: '/',
            disable: true,
            name: 'Quản lý API',
            parent: true,
            parentGroup: [USER_GROUP.CARLINK],
            icon: <Image src={icAPI} preview={false} />,
            children: [
              {
                path: config.routes.listFollow,
                disable: false,
                name: 'Theo dõi API',
                onlySuperAdmin: true,
              },
              {
                path: config.routes.listApi,
                disable: false,
                name: 'Danh sách API',
                onlySuperAdmin: true,
              },
            ],
          },
          {
            path: config.routes.employeeManagement,
            disable: true,
            name: 'Quản lý nhân sự',
            parent: true,
            parentGroup: [USER_GROUP.AGENT, USER_GROUP.CAR_COMPANY, USER_GROUP.CARLINK, USER_GROUP.MAIN_AGENT],
            icon: <Image src={icEmployee} preview={false} />,
            routes: [
              {
                path: config.routes.listEmployee,
                disable: false,
                name: 'Quản lý nhân viên',
                component: './Welcome',
                authority: [
                  // PERMISSIONS.CARLINK.manager_carlink_account,
                  // ROLE_PERMISSIONS.manager_company_account,
                  // ROLE_PERMISSIONS.manager_agent_account,
                ],
              },
              {
                path: config.routes.listSalary,
                disable: false,
                name: 'Quản lý tiền lương',
                component: './Welcome',
                authority: [
                  // PERMISSIONS.CARLINK.manager_carlink_account,
                  // ROLE_PERMISSIONS.manager_company_account,
                  // ROLE_PERMISSIONS.manager_agent_account,
                ],
              },
              {
                path: config.routes.listChecking,
                disable: false,
                name: 'Quản lý chấm công',
                component: './Welcome',
                authority: [
                  // PERMISSIONS.CARLINK.manager_carlink_account,
                  // ROLE_PERMISSIONS.manager_company_account,
                  // ROLE_PERMISSIONS.manager_agent_account,
                ],
              },
              {
                path: config.routes.listWork,
                disable: false,
                name: 'Quản lý công việc',
                component: './Welcome',
                authority: [
                  // PERMISSIONS.CARLINK.manager_carlink_account,
                  // ROLE_PERMISSIONS.manager_company_account,
                  // ROLE_PERMISSIONS.manager_agent_account,
                ],
              },
            ],
          },
          {
            path: config.routes.systemManagement,
            disable: true,
            name: 'Quản lý hệ thống',
            parent: true,
            parentGroup: [USER_GROUP.CARLINK],
            icon: <Image src={IconSystemSetting} preview={false} />,
            routes: [
              {
                path: config.routes.listInfoCarlink,
                disable: false,
                name: 'Thông tin Carlink',
                component: <ManageSystem />,
                blockGroup: [USER_GROUP.AGENT, USER_GROUP.CAR_COMPANY],
                onlySuperAdmin: true,
              },
              {
                path: config.routes.systemConfig,
                disable: false,
                name: 'Cấu hình hệ thống',
                component: <SystemConfig />,
                blockGroup: [USER_GROUP.AGENT, USER_GROUP.CAR_COMPANY],
                onlySuperAdmin: true,
              },
              {
                path: config.routes.systemGuide,
                disable: false,
                name: 'Cấu hình hướng dẫn sử dụng',
                component: <SystemGuide />,
                blockGroup: [USER_GROUP.AGENT, USER_GROUP.CAR_COMPANY],
                onlySuperAdmin: true,
              },
              {
                path: config.routes.systemDepartureFee,
                disable: false,
                name: 'Cấu hình phí xuất bến',
                component: <SystemDepartureFee />,
                blockGroup: [USER_GROUP.AGENT, USER_GROUP.CAR_COMPANY],
                onlySuperAdmin: true,
              },
            ],
          },
          {
            path: config.routes.notificationManagement,
            disable: true,
            name: 'Thông báo',
            parent: true,
            parentGroup: [USER_GROUP.CARLINK],
            icon: <Image src={icNotify} preview={false} />,
            routes: [
              {
                path: config.routes.listNotification,
                disable: false,
                name: 'Thông báo của hệ thống',
                component: <Notify />,
                blockGroup: [],
              },
            ],
          },
        ],
      },
    ],
  },
};
