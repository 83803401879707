/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Form,
  Input,
  Row,
  Modal,
  Divider,
  Space,
  Image,
  Button,
  message,
  Upload,
  Popconfirm,
  Typography,
} from "antd";
import "../../../assets/styles/createOffice.scss";
// import { validateNameOffice } from "../../../config/validators/validateOffice";
import ReactQuill from "react-quill";
import { getPolicyStatusByIdAPI, updatePolicyAPI } from "../../../api/policies";
import { checkAuthorization } from "../../../api";
import { moduleReactQuill } from "../../../config/reactQuill";
import { useEffect, useState } from "react";
import IcPlus from "../../../assets/images/policy/plus.svg";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import { ACTION_NAME, messageUpdateSuccess } from "../../../config/message";
import { Constant, USER_GROUP } from "../../../config/constant";
import {
  validateContentPolicy,
  validateNamePolicy,
  validatePricePolicy,
  validateTimePolicy,
} from "../../../config/validators/validatePolicy";
import IcInfoWarning from "../../../assets/images/policy/info-warning.svg";
import IconExclamationCircle from "../../../assets/images/ExclamationCircle.svg";
import IcLock from "../../../assets/images/ic-lock.svg";
import TutorialsVideo from "../../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../../utils/helpers";
import icClose from "../../../assets/images/listOffice/icClose.svg";

type FileType = Parameters<any>[0];

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const UpdatePolicy = ({
  setOpen,
  open,
  callListPolicyApi,
  detailPolicy,
  detailUser,
  listTutorials

}: any) => {
  const imageUser: any = detailPolicy?.policy_value[0]?.qr_code_img
    ? [
        {
          uid: "-1",
          name: "No avatar",
          status: "done",
          url: detailPolicy?.policy_value[0]?.qr_code_img,
        },
      ]
    : [];
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]); // Khởi tạo fileList rỗng
  const [listFields, setListFields] = useState(0);
  const [loading, setLoading] = useState(false)
  // value insurance
  const [valueInsurance, setValueInsurance] = useState(false);

  const handleCancelImage = () => setPreviewOpen(false);
  const { t } = useTranslation();

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // Giữ chỉ một tệp trong fileList
    setFileList(newFileList.slice(-1));
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOkModal = async () => {
    try {
      const res = await getPolicyStatusByIdAPI(detailPolicy?.id, {
        status: !detailPolicy?.status,
      });
      if (res.status === Constant.DEFAULT_STATUS) {
        callListPolicyApi();
        messageUpdateSuccess(ACTION_NAME.POLiCY);
        setOpen(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true)
    if (detailPolicy?.type === 3) {
      values.qr_code_img = fileList[0]?.thumbUrl;
    }
    if (detailPolicy?.type === 2) {
      values.cancel_refund_value = values.cancel_refund_value.map(
        (item: any) => {
          return {
            before_departure_hour: +item.before_departure_hour,
            cancel_fee_percent: +item.cancel_fee_percent,
          };
        }
      );
    }

    try {
      const res = await updatePolicyAPI(detailPolicy?.id, {
        type: detailPolicy?.type,
        ...values,
      });
      if (res.status === Constant.DEFAULT_STATUS) {
        callListPolicyApi();
        messageUpdateSuccess(ACTION_NAME.POLiCY);
        setOpen(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
    setLoading(false)
  };

  useEffect(() => {
    setListFields((prev) => prev + 1);
    setValueInsurance(
      detailPolicy?.policy_value[0]?.insurance_value_price ? false : true
    );
    // valueInsurance
  }, []);

  const styles = {
    color: "#3AB54A",
    fontWeight: 600,
    cursor: "pointer",
  };

  const stylesCursor = {
    cursor: "pointer",
    margin: "0 5px",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#7B7B7B",
  };

  const validateInsurancePolicy: any = [
    {
      required: true,
      message: "Vui lòng nhập giá trị bảo hiểm",
    },
    {
      validator(_:any, value:any) {
        if (value < 0 || value > 100) {
          return Promise.reject(new Error('Giá trị phải từ 0 đến 100'));
        }
        return Promise.resolve();
      },
    },
   
  ];
  const validateInsurancePrice: any = [
    {
      required: true,
      message: "Vui lòng nhập giá trị bảo hiểm",
    },
    {
      validator(_:any, value:any) {
        if (value < 0 || value > 1000000000) {
          return Promise.reject(new Error('Giá trị phải từ 0 đến 1000000000'));
        }
        return Promise.resolve();
      },
    },
   
  ];

  useEffect(() => {
    let dataPolicy = {};
    switch (detailPolicy?.type) {
      case 0:
        dataPolicy = {
          name: detailPolicy?.name,
          content: detailPolicy?.content,
        };
        break;
      case 1:
        dataPolicy = {
          name: detailPolicy?.name,
          content: detailPolicy?.content,
          insurance_value_price:
            detailPolicy?.policy_value[0]?.insurance_value_price,
          insurance_value_percent:
            detailPolicy?.policy_value[0]?.insurance_value_percent,
        };
        break;
      case 2:
        dataPolicy = {
          name: detailPolicy?.name,
          content: detailPolicy?.content,
          cancel_refund_value: detailPolicy?.policy_value || [],
        };
        break;
      case 3:
        dataPolicy = {
          name: detailPolicy?.name,
          content: detailPolicy?.content,
          insurance_value_price: detailPolicy?.policy_value[0]?.qr_code_img,
        };
        break;
      default:
        // Handle any other cases or errors
        break;
    }

    form.setFieldsValue({
      ...dataPolicy,
    });
  }, [detailPolicy?.id, form, detailPolicy]);

  useEffect(() => {
    if (fileList?.length === 0 && detailPolicy?.type === 3) {
      setFileList(imageUser);
    }
  }, [detailPolicy?.policy_value[0]?.qr_code_img]);

  const checkGroup = detailUser?.group === USER_GROUP.AGENT;
  return (
    <>
      <Modal
        title={ <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 15,
          }}
        >
          <Typography.Text className="fw-600 fs-18 ">
            {t("Chi tiết thông tin chính sách")}
          </Typography.Text>
          <Space size={10} align="center">
            <TutorialsVideo
              url_youtube={handleGetUrlGuide(listTutorials, 14)}
            />
            <Image
              src={icClose}
              style={{ cursor: "pointer" }}
              preview={false}
              onClick={handleCancel}
            />
          </Space>
        </Row>}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={false}
        okText="Lưu"
        cancelText="Đóng"
        width={834}
        styles={{
          body: { height: '580px', overflowY: 'auto', overflowX: 'hidden' },
        }}
        className="modal-create-policy modal-create-office scroll-custom-modal"
        footer={(_, { OkBtn, CancelBtn }: any) => (
          <div
            style={checkGroup ? {display: "none"} : {
              display: "flex",
              justifyContent: "space-between",
              marginTop: 24,
            }}
            className="footer-edit-modal-office"
          >
            <>
              <Popconfirm
                placement="topLeft"
                className="popconfirm-custom"
                title={
                  detailPolicy?.status
                    ? t("Khóa chính sách")
                    : t("Mở chính sách")
                }
                description={
                  <div className="content-pop-des">
                    {detailPolicy?.status
                      ? t(
                          "Bạn đang thao tác khóa chính sách này, bạn có chắc chắn muốn khóa?"
                        )
                      : t(
                          "Bạn đang thao tác mở khóa chính sách này, bạn có chắc chắn muốn mở khóa?"
                        )}
                  </div>
                }
                okText={detailPolicy?.status ? t("Khóa ngay") : t("Mở ngay")}
                cancelText={t("Trở lại")}
                okButtonProps={{ className: "custom-ok-button-class" }} // Tùy chỉnh lớp CSS cho nút OK
                cancelButtonProps={{ className: "custom-cancel-button-class" }} // Tùy chỉnh lớp CSS cho nút Hủy
                onConfirm={handleOkModal}
                icon={
                  <Image
                    preview={false}
                    width={20}
                    src={IconExclamationCircle}
                    alt="Icon Exclamation Circle"
                  />
                }
              >
                <Col className="block-user">
                  <Button
                    className={
                      detailPolicy?.status
                        ? "btn-block-red"
                        : "btn-active-green"
                    }
                    danger
                    type="primary"
                    htmlType="button"
                    // onClick={showModal}
                  >
                    <Image src={IcLock} preview={false} className="pb-1 pe-2" />
                    {detailPolicy?.status
                      ? t("Khóa chính sách")
                      : t("Mở chính sách")}
                  </Button>
                </Col>
              </Popconfirm>
            </>
            <div className="modal-btn-user d-flex gap-12">
              <CancelBtn className="modal-cancel-btn" />
              <OkBtn />
            </div>
          </div>
        )}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="form-create-office"
        >
          <Row gutter={16}>
            <Col span={11}>
              <Form.Item
                name="name"
                label={t("Tên chính sách")}
                rules={validateNamePolicy}
                className="required"
              >
                <Input placeholder={t("Nhập tên chính sách")} />
              </Form.Item>
            </Col>
            {detailPolicy?.type === 1 && (
              <Col span={11}>
                <Form.Item
                  name={
                    valueInsurance
                      ? "insurance_value_percent"
                      : "insurance_value_price"
                  }
                  label={t("Giá trị bảo hiểm")}
                  className="required mb-0"
                  rules={valueInsurance ? validateInsurancePolicy : validateInsurancePrice}
                >
                  <Input
                    className="policy-input-fare"
                    type="number"
                    addonAfter={
                      <Space size={12}>
                        <Divider type="vertical" style={{ marginRight: 4 }} />
                        <span
                          style={valueInsurance ? stylesCursor : styles}
                          onClick={() => setValueInsurance(false)}
                        >
                          {t("VND")}
                        </span>
                        <span
                          style={valueInsurance ? styles : stylesCursor}
                          onClick={() => setValueInsurance(true)}
                        >
                          {t("% theo giá vé")}
                        </span>
                      </Space>
                    }
                  />
                </Form.Item>
                <Space size={4} className="info-warning-policy">
                  {" "}
                  <Image src={IcInfoWarning} alt="logo" preview={false} />
                  {t(
                    "Giá trị bảo hiểm được tính cho 1 hành khách trên 1 chặng"
                  )}
                </Space>
              </Col>
            )}
          </Row>
          {detailPolicy?.type === 2 && (
            <Form.List name="cancel_refund_value">
              {(fields, { add, remove }) => {
                setListFields(fields?.length);
                return (
                  <>
                    {fields?.map(({ key, name, ...restField }) => (
                      <Row gutter={16}>
                        <Col span={9}>
                          <Form.Item
                            {...restField}
                            name={[name, "before_departure_hour"]}
                            label={t("Trước giờ khởi hành")}
                            className="required"
                            rules={validateTimePolicy}
                          >
                            <Input
                              className="policy-input-fare"
                              type="number"
                              addonAfter={
                                <Space size={12}>
                                  <Divider
                                    type="vertical"
                                    style={{ marginRight: 4 }}
                                  />
                                  <span style={stylesCursor}>Giờ</span>
                                </Space>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "cancel_fee_percent"]}
                            label={t("Phí hủy")}
                            className="required"
                            rules={validatePricePolicy}
                          >
                            <Input
                              className="policy-input-fare"
                              type="number"
                              addonAfter={
                                <Space size={12}>
                                  <Divider type="vertical" />
                                  <span style={stylesCursor}>%</span>
                                </Space>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col className="btn-delete-timeline">
                          <Button
                            type="default"
                            icon={<CloseOutlined />}
                            onClick={() => {
                              if (fields?.length === 1) {
                                message.warning(
                                  "Không được phép xóa trường cuối cùng"
                                );
                              } else {
                                remove(name);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    {fields?.length === 0 && listFields && add()}
                    {fields?.length < 3 && (
                      <Space className="add-a-timeline" onClick={() => add()}>
                        Thêm mốc thời gian
                        <Image src={IcPlus} alt="logo" preview={false} />
                      </Space>
                    )}
                  </>
                );
              }}
            </Form.List>
          )}

          {detailPolicy?.type === 3 && (
            <Row>
              <Form.Item name="qr_code_img" label="Mã QR thanh toán">
                <ImgCrop quality={1} aspect={35 / 35}>
                  <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList?.length === 0 && (
                      <div>
                        <div style={{ marginTop: 8 }}>
                          <UploadOutlined /> {t("Tải ảnh lên")}
                        </div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancelImage}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </Form.Item>
            </Row>
          )}

          <Row gutter={16} style={{maxWidth:'99%'}}>
            <Col span={24}>
              <Form.Item
                name="content"
                label={t("Nội dung chính sách")}
                className="required"
                rules={validateContentPolicy}
              >
                <ReactQuill theme="snow" modules={moduleReactQuill} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatePolicy;
