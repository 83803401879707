import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {ListBusRequest, ListBusState} from "../../../types";
import {Constant, Toast} from "../../../config/constant";
import {getErrorMessage} from "../../../api";
import {getListBusAPI} from "../../../api/bus";
import {setToast} from "../toastNotifySlice";


// @ts-ignore
export const fetchListBus = createAsyncThunk('admin/bus', async (params: ListBusRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getListBusAPI(params);
        const {
            data,
            status
        } = response;
        if (status === Constant.DEFAULT_STATUS) {
            dispatch(setListBus({buses: data.data, pagination: data.pagination, status: status}));
            dispatch(setToast({
                message: 'Truy cập bị Từ chối! Người dùng hết phiên đăng nhập',
                type: Toast.TYPE_ERROR,
                statusCode: Constant.UN_AUTH_CODE
            }))
            return true;
        }
    } catch (error: any) {
        dispatch(setListBus(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});
export const listBusSlice = createSlice({
    name: 'listBus',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        buses: [],
        pagination: {
            total: 0,
            per_page: Constant.PAGE_SIZE,
            current_page: Constant.DEFAULT_PAGE,
            last_page: Constant.DEFAULT_PAGE,
        },
    } as ListBusState,
    reducers: {
        setListBus: (state: ListBusState, {payload}) => {
            const {buses, pagination, status} = payload;
            state.buses = buses;
            state.pagination = pagination;
            state.status = status;
        },
        setListBusLoad: (state: ListBusState) => {
            state.buses = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchListBus.pending, (state: ListBusState) => {
            state.loading = true;
        });
        builder.addCase(fetchListBus.fulfilled, (state: ListBusState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(fetchListBus.rejected, (state: ListBusState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
    },
});

export const listBusSelector = (state: RootState) => state.listBus;
export const {setListBus, setListBusLoad} = listBusSlice.actions;
