import { Statistic } from 'antd';
import { useEffect, useState } from 'react';

const CountdownComponent = ({ seatEntriesExpired, timerCountdown }: any) => {
  const { Countdown } = Statistic;
  const [countdownValue, setCountdownValue] = useState<number | null>(null);
  useEffect(() => {
    if (seatEntriesExpired?.expired_at !== undefined) {
      const newCountdownValue: number = new Date().getTime() + (+seatEntriesExpired.expired_at + 1) * 1000;

      setCountdownValue(newCountdownValue);
    }
  }, [seatEntriesExpired]);

  useEffect(() => {
    if (timerCountdown?.expired_at !== undefined) {
      const newCountdownValue: number = new Date().getTime() + (+timerCountdown.expired_at + 1) * 1000;
      setCountdownValue(newCountdownValue);
    }
  }, [timerCountdown]);
  return (
    (seatEntriesExpired?.expired_at || timerCountdown?.expired_at) && (
      <div className="fs-14 fw-500 time-hold mr-5">
        {countdownValue && (
          <Countdown
            className="fs-14 fw-500"
            value={countdownValue}
            format="HH:mm:ss"
            style={timerCountdown?.expired_at ? { color: '#F8B507' } : {}}
          />
        )}
      </div>
    )
  );
};

export default CountdownComponent;
