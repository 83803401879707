import { IExcelColumn } from "antd-table-saveas-excel/app";

export enum TabStatusOrderProcessed {
  UN_PROCESSED = 'unprocessed',
  PROCESSED = 'processed',
  PAID = 'paid',
  CANCEL = 'canceled'
}

export enum TypeModalOrder {
  PROCESSED,
  PENDING,
}

export const columnsExcelProcessed: IExcelColumn[] = [
  {
    title: "STT",
    dataIndex: "key",
    width: 10,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Họ và tên",
    dataIndex: "customer_name",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Mã đơn hàng",
    dataIndex: "booking_code",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Hành trình",
    dataIndex: "route",
    width: 40,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Ngày đi",
    dataIndex: "depart_date",
    width: 25,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Giờ đi",
    dataIndex: "depart_time",
    width: 15,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Ngày đặt",
    dataIndex: "created_at",
    width: 20,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "SL vé",
    dataIndex: "amount",
    __cellType__: "TypeNumeric",
    __numFmt__: "#,##0",
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      h: "right",
    },
  },
  {
    title: "Thanh toán",
    width: 40,
    dataIndex: "payment_method",
    __cellType__: "TypeNumeric",
    __numFmt__: "#,##0",
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      h: "right",
    },
  },
  {
    title: "Tổng tiền đơn hàng",
    dataIndex: "total_price",
    __cellType__: "TypeNumeric",
    __numFmt__: "#,##0",
    width: 40,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      h: "right",
    },
  },
  {
    title: "Tổng tiền đã xuất",
    dataIndex: "total_export_price",
    __cellType__: "TypeNumeric",
    __numFmt__: "#,##0",
    width: 40,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      h: "right",
    },
  },
];

export const columnsExcelPending: IExcelColumn[] = [
  {
    title: "STT",
    dataIndex: "key",
    width: 10,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Họ và tên",
    dataIndex: "customer_name",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Mã đơn hàng",
    dataIndex: "booking_code",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Hành trình",
    dataIndex: "route",
    width: 40,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Ngày đi",
    dataIndex: "depart_date",
    width: 25,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Giờ đi",
    dataIndex: "depart_time",
    width: 15,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Ngày đặt",
    dataIndex: "created_at",
    width: 20,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Nguồn vé",
    width: 25,
    dataIndex: "source",
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "SL vé",
    dataIndex: "amount",
    __cellType__: "TypeNumeric",
    __numFmt__: "#,##0",
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      h: "right",
    },
  },
  {
    title: "Thanh toán",
    width: 40,
    dataIndex: "payment_method",
    __cellType__: "TypeNumeric",
    __numFmt__: "#,##0",
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      h: "right",
    },
  },
  {
    title: "Tổng tiền",
    dataIndex: "total_price",
    __cellType__: "TypeNumeric",
    __numFmt__: "#,##0",
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      h: "right",
    },
  },
];

export const columnsHistory: IExcelColumn[] = [
  {
    title: "Thời gian",
    dataIndex: "time",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Nhân viên",
    dataIndex: "staff",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Thao tác",
    dataIndex: "action",
    width: 200,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      wrapText: true,
    },
  },
];

export const columnsExcelOrderTicket: IExcelColumn[] = [
  {
    title: "STT",
    dataIndex: "key",
    width: 15,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Mã vé",
    dataIndex: "ticket_code",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Mã đơn hàng",
    dataIndex: "booking_code",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Họ và tên",
    dataIndex: "customer_name",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Số điện thoại",
    dataIndex: "customer_phone",
    width: 30,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Hành trình",
    dataIndex: "route",
    width: 40,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Ngày đi",
    dataIndex: "depart_date",
    width: 25,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Giờ đi",
    dataIndex: "depart_time",
    width: 15,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Ngày đặt",
    dataIndex: "created_at",
    width: 20,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
    },
  },
  {
    title: "Giá vé",
    dataIndex: "price",
    __cellType__: "TypeNumeric",
    __numFmt__: "#,##0",
    width: 20,
    __style__: {
      fontName: "Times New Roman",
      fontSize: 14,
      border: true,
      borderColor: "black",
      h: "right",
    },
  },
]

export const handleGetCancelReason = (cancel_reason: string, payer?: string) => {
  switch (cancel_reason) {
    case "car_company":
      return "Đã hủy vé do nhà xe hủy!";
    case "customer":
      return "Đã hủy vé do khách hàng hủy!";
    case "agent":
      return "Đã hủy vé do đại lý hủy!";
    case "overdue":
      return "Tự động hủy bởi hệ thống do hết thời gian giữ chỗ!";
    case "system":
      return "Tự động hủy bởi hệ thống do hết thời gian giữ chỗ!";
    case "Hủy vé do quá hạn giữ chỗ":
      return "Tự động hủy bởi hệ thống do hết thời gian giữ chỗ!";
    default:
      return `Vé được hủy bởi ${payer} (Lý do: ${cancel_reason})`;
  }
}