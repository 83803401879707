import React, { useCallback, useEffect, useState } from "react";
import IconExportExcel from "../../../components/Icons/IconExportExcel";
import {
  Form,
  Select,
  DatePicker,
  Button,
  ConfigProvider,
  Flex,
  Input,
  Table,
  Typography,
  Tabs,
  type TabsProps,
} from "antd";
import type { TableProps } from "antd";
import TutorialsVideo from "../../../components/TutorialsVideo";
import { formatPrice, handleDisplayTimeToNow, handleGetUrlGuide } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import iconAction from "../../../assets/images/ic-edit.svg";
import ExcelJS from "exceljs";
import {
  getListOrdersTicket,
  setCurrentPage,
  setFilter,
  setKeyword,
  setPerPage,
  setStatus,
} from "../../../redux/slice/orders/ordersTicketSlice";
import dayjs from "dayjs";
import { Constant } from "../../../config/constant";
import { OrderTicketItemParams } from "../../../types/order";
import moment from "moment";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { toast } from "react-toastify";
import { getListOrdersAPI, getOrderDetailAPI } from "../api";
import { isArray } from "lodash";
import "../styles/Order.scss";
import {
  columnsExcelOrderTicket,
  TabStatusOrderProcessed,
} from "../models/constants";
import iconSearch from "../../../assets/images/icons/icon-search.svg";
import iconHistory from "../../../assets/images/orders/icon-history.svg";
import TextDisplay from "../../../components/Display/TextDisplay";

import statusCancel from "../../../assets/images/orders/status-cancel.svg";
import statusPaid from "../../../assets/images/orders/status-paid.svg";
import statusPending from "../../../assets/images/orders/status-pending.svg";
import ModalTicket from "../components/ModalTicket";
import ModalHistoryTicketAll from "../components/ModalHistoryTicketAll";

type FieldType = {
  date_filter: string;
  range_date_filter: string[];
  keyword: string;
  status: string;
};

const { RangePicker } = DatePicker;

const optionsDateFilter = [
  {
    value: "depart_date",
    label: "Theo ngày khởi hành",
  },
  {
    value: "created_at",
    label: "Theo ngày đặt vé",
  },
];

const itemsTabs: TabsProps["items"] = [
  {
    key: "",
    label: <p className="tab-title tab-title--first">Tất cả</p>,
  },
  {
    key: TabStatusOrderProcessed.UN_PROCESSED,
    label: <p className="tab-title">Đang giữ chỗ</p>,
  },
  {
    key: TabStatusOrderProcessed.PAID,
    label: <p className="tab-title">Đã thanh toán</p>,
  },
  {
    key: TabStatusOrderProcessed.CANCEL,
    label: <p className="tab-danger tab-title">Đã hủy</p>,
  },
];

export const handleCheckArray = (pattern: string[], array: string[]) => {
  if (pattern?.length === array?.length) {
    return array?.every((role: string) => pattern?.includes(role));
  } else {
    return false;
  }
};

const handleDisplayStatusTicket = (status: string) => {
  if ("cancel" === status) {
    return statusCancel;
  }
  if ("reserve" === status) {
    return statusPending;
  }
  if ("paid" === status) {
    return statusPaid;
  }
};

const OrderTicket = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);
  const [openModalTicket, setOpenModalTicket] = useState<boolean>(false);
  const [ticketData, setTicketData] = useState<OrderTicketItemParams>(
    {} as OrderTicketItemParams
  );
  const [openModalHistoryTicketAll, setOpenModalHistoryTicketAll] =
    useState<boolean>(false);

  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );
  const {
    filter: listFilter,
    listTicket,
    pagination,
    timer,
  } = useAppSelector((state) => state.ordersTicket);

  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

  const onChangeActive = (key: string) => {
    dispatch(setStatus(key));
    dispatch(setCurrentPage(1));
    dispatch(setKeyword(form.getFieldValue("keyword")));
  };

  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return (
          Math.abs(current.diff(from, "days")) >=
          systemConfig?.export_report_month
        );
      }
      return false;
    },
    [systemConfig?.export_report_month]
  );

  const handleGetListOrders = useCallback(async () => {
    await dispatch(
      getListOrdersTicket({
        page: pagination?.current_page,
        keyword: listFilter?.keyword ? listFilter?.keyword : "",
        date_filter: listFilter?.date_filter,
        from: dayjs(listFilter?.from, "YYYY-MM-DD", true).isValid()
          ? dayjs(listFilter?.from).format("YYYY-MM-DD")
          : undefined,
        to: dayjs(listFilter?.to, "YYYY-MM-DD", true).isValid()
          ? dayjs(listFilter?.to).format("YYYY-MM-DD")
          : undefined,
        status: listFilter?.status,
        limit: pagination?.per_page || limitPagination,
        is_full: "0",
        is_detail_booking: "1",
      })
    );
  }, [
    dispatch,
    listFilter?.keyword,
    listFilter?.date_filter,
    pagination?.current_page,
    listFilter?.from,
    listFilter?.to,
    pagination?.per_page,
    listFilter?.status,
    limitPagination,
  ]);

  useEffect(() => {
    handleGetListOrders();
  }, [handleGetListOrders]);

  useEffect(() => {
    const timerID = setInterval(() => {
      handleGetListOrders();
    }, Number(systemConfig?.refresh_time ? systemConfig?.refresh_time : 10) * 1000);
    if (pagination?.current_page !== 1 || !timer) {
      clearInterval(timerID);
    }
    return () => clearInterval(timerID);
  }, [
    handleGetListOrders,
    timer,
    pagination?.current_page,
    systemConfig?.refresh_time,
  ]);

  const onSearchKeyWord = (value: string) => {
    dispatch(setKeyword(value));
    dispatch(setCurrentPage(1));
  };

  const handleGetDetailTicket = async ({
    booking_code,
    ticket_code,
  }: {
    booking_code: string;
    ticket_code: string;
  }) => {
    try {
      const res = await getOrderDetailAPI({
        ticket_code,
        booking_code,
      });
      if (res?.status === 200) {
        setTicketData(res?.data?.data);
        setOpenModalTicket(true);
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra vui lòng thử lại!");
    }
  };

  const columns: TableProps<OrderTicketItemParams>["columns"] = [
    {
      title: "STT",
      dataIndex: "key",
      render: (value, record, index) => (
        <Flex className="Order__column__booking-code" align="center">
          <Flex style={{ width: "36px" }} align="center" justify="center">
            <img
              height={16}
              className="object-fit-scale w-auto"
              src={handleDisplayStatusTicket(record?.status)}
              alt=""
            />
          </Flex>
          <Flex className="flex-column">
            <Typography.Text className="fs-16 text-color fw-500 text-nowrap">
              {index +
                1 +
                (pagination?.current_page - 1) * pagination?.per_page}
            </Typography.Text>
          </Flex>
        </Flex>
      ),
    },
    {
      title: "Mã vé",
      dataIndex: "ticket_code",
      render: (ticket_code, record) => (
        <Flex align="start" className="flex-column">
          <Typography.Text className="fs-16 text-color fw-500">
            <TextDisplay text={ticket_code} />
          </Typography.Text>
          <Typography.Text className="fs-14 text-color-green-default fw-500">
            {handleDisplayTimeToNow(record?.created_at)}
          </Typography.Text>
        </Flex>
      ),
      sorter: (a: any, b: any) => a?.ticket_code.localeCompare(b?.ticket_code),
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "booking_code",
      render: (booking_code) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={booking_code} />
        </Typography.Text>
      ),
      sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
    },
    {
      title: "Họ và tên",
      dataIndex: "customer",
      render: (customer) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={customer?.name} />
        </Typography.Text>
      ),
    },
    {
      title: "Số điện thoại",
      dataIndex: "customer",
      render: (customer) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={customer?.phone} />
        </Typography.Text>
      ),
    },
    {
      title: "Hành trình",
      dataIndex: "route",
      render: (route, record) => (
        <Flex align="center">
          <Typography.Text className="fs-16 text-color fw-500">
            {route ? `${route?.depart_point} - ${route?.arrive_point}` : "N/A"}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      render: (depart_date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_date ? moment(depart_date).format("DD/MM/YYYY") : ""}
        </Typography.Text>
      ),
      sorter: (a: any, b: any) =>
        moment(a?.depart_date).unix() - moment(b?.depart_date).unix(),
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      render: (depart_time) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_time ? moment(depart_time, "HH:mm").format("HH:mm") : ""}
        </Typography.Text>
      ),
      sorter: (a, b) =>
        moment(a.depart_time, "HH:mm").unix() -
        moment(b.depart_time, "HH:mm").unix(),
    },
    {
      title: "Ngày đặt",
      dataIndex: "created_at",
      hidden: true,
      render: (created_at) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {created_at ? moment(created_at).format("DD/MM/YYYY") : ""}
        </Typography.Text>
      ),
      sorter: (a: any, b: any) =>
        moment(a?.created_at).unix() - moment(b?.created_at).unix(),
    },
    {
      title: "Giá vé",
      dataIndex: "price",
      align: "right",
      render: (price) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {price ? formatPrice(price, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a: any, b: any) => {
        return Number(a?.price) - Number(b?.price);
      },
    },
    {
      title: "Chức năng",
      dataIndex: "ticket_code",
      align: "center",
      render: (ticket_code, record) => (
        <Flex
          onClick={() =>
            handleGetDetailTicket({
              ticket_code: ticket_code,
              booking_code: record?.booking_code,
            })
          }
          className="Order__column__action"
          align="center"
          justify="center"
        >
          <img
            width={24}
            height={24}
            className="object-fit-scale"
            src={iconAction}
            alt=""
          />
        </Flex>
      ),
    },
  ];

  const handleExportExcel = async () => {
    try {
      setLoadingExcel(true);
      const res = await getListOrdersAPI({
        keyword: listFilter?.keyword ? listFilter?.keyword : "",
        date_filter: listFilter?.date_filter,
        from: dayjs(listFilter?.from, "YYYY-MM-DD", true).isValid()
          ? dayjs(listFilter?.from).format("YYYY-MM-DD")
          : undefined,
        to: dayjs(listFilter?.to, "YYYY-MM-DD", true).isValid()
          ? dayjs(listFilter?.to).format("YYYY-MM-DD")
          : undefined,
        status: listFilter?.status,
        is_full: "1",
        is_detail_booking: "1",
      });
      if (isArray(res?.data?.data)) {
        const listData = res?.data?.data;
        const listDataExcel =
          listData?.length > 0
            ? listData?.map((order: OrderTicketItemParams, index: number) => ({
                key: index + 1,
                ticket_code: order?.ticket_code ? order?.ticket_code : "N/A",
                booking_code: order?.booking_code ? order?.booking_code : "N/A",
                customer_name: order?.customer.name
                  ? order?.customer.name
                  : "N/A",
                customer_phone: order?.customer.phone
                  ? order?.customer.phone
                  : "N/A",
                route: order?.route
                  ? `${order?.route?.depart_point} - ${order?.route?.arrive_point}`
                  : "N/A",
                depart_date: order?.depart_date
                  ? `${moment(order?.depart_date).format("DD/MM/YYYY")}`
                  : "N/A",
                depart_time: order?.depart_time
                  ? `${moment(order?.depart_time, "HH:mm").format("HH:mm")}`
                  : "N/A",
                created_at: order?.created_at
                  ? `${moment(order?.created_at).format("DD/MM/YYYY")}`
                  : "N/A",
                price: order?.price ? Number(order?.price) : 0,
              }))
            : [];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Danh sách theo mã vé");
        const header = columnsExcelOrderTicket.map((col) => ({
          header: col.title,
          key: col.dataIndex as string,
        }));
        worksheet.columns = header;
        columnsExcelOrderTicket.forEach((col, index) => {
          worksheet.getColumn(index + 1).width = col.width || 15;
          worksheet.getColumn(index + 1).alignment = {
            horizontal:
              (col?.__style__?.h as
                | "center"
                | "left"
                | "right"
                | "fill"
                | "justify") || "left",
            vertical: "middle",
          };
          worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || "general";
        });
        worksheet.addRows(listDataExcel);
        worksheet.getRow(1).font = {
          name: "Times New Roman",
          size: 12,
          bold: true,
        };
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          if (colNumber < 11) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "3AB54A" },
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        });
        let firstRowHasData = false;
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          if (cell.value) {
            firstRowHasData = true;
            return;
          }
        });
        if (firstRowHasData) {
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber > 1) {
              row.eachCell((cell) => {
                cell.font = { name: "Times New Roman", size: 12 };
                cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
              });
            }
          });
        } else {
          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
              let hasData = false;
              row.eachCell((cell) => {
                if (cell.value) {
                  hasData = true;
                  return;
                }
              });
              if (hasData) {
                row.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
              }
            }
          });
        }

        const lastRow = worksheet.addRow([]);
        lastRow.getCell(1).value = "@Bản quyền thuộc nhà xe Carlink";
        lastRow.getCell(1).alignment = {
          vertical: "middle",
          horizontal: "left",
        };
        lastRow.getCell(1).font = {
          name: "Times New Roman",
          size: 14,
          bold: true,
        };
        const endColumnIndex = 0 + columnsExcelOrderTicket.length;
        worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Danh sách theo mã vé.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
      setLoadingExcel(false);
    } catch (error) {
      setLoadingExcel(false);
      toast.error("Có lỗi xảy ra vui lòng thử lại!");
    }
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      dispatch(setCurrentPage(Constant.DEFAULT_PAGE));
      dispatch(setPerPage(pageSize));
    } else {
      dispatch(setCurrentPage(page));
    }
  };

  const onFinish = (values: FieldType) => {
    dispatch(
      setFilter({
        keyword: values?.keyword ? values?.keyword : "",
        date_filter: values?.date_filter,
        from: values?.range_date_filter?.[0]
          ? values?.range_date_filter?.[0]
          : "",
        to: values?.range_date_filter?.[1]
          ? values?.range_date_filter?.[1]
          : "",
        status: listFilter?.status,
      })
    );
    dispatch(setCurrentPage(1));
  };

  return (
    <div className="Order">
      <Tabs
        defaultActiveKey={listFilter?.status}
        activeKey={listFilter?.status}
        items={itemsTabs}
        onChange={onChangeActive}
        className={`Order__tabs ${
          listFilter?.status === TabStatusOrderProcessed.CANCEL ? "danger" : ""
        }`}
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          keyword: listFilter?.keyword ? listFilter?.keyword : "",
          date_filter: listFilter?.date_filter
            ? listFilter?.date_filter
            : "depart_date",
          range_date_filter: [
            listFilter?.from
              ? dayjs(listFilter?.from, "DD/MM/YYYY")
              : undefined,
            listFilter?.to ? dayjs(listFilter?.to, "DD/MM/YYYY") : undefined,
          ],
        }}
      >
        <Flex className="Order__form" align="start" justify="space-between">
          <Flex className="Order__form" align="center">
            <div className="Order__keyword">
              <Form.Item<FieldType> name="keyword">
                <Input.Search
                  placeholder="Nhập mã đơn / mã vé để tìm kiếm"
                  suffix={
                    <img
                      className="object-fit-scale"
                      width={16}
                      height={16}
                      src={iconSearch}
                      alt=""
                    />
                  }
                  onSearch={onSearchKeyWord}
                />
              </Form.Item>
            </div>
            <div className="Order__dateFilter">
              <Form.Item<FieldType>
                name="date_filter"
                rules={[
                  { required: true, message: "Vui lòng chọn khoảng thời gian" },
                ]}
              >
                <Select
                  placeholder="Theo ngày khởi hành"
                  options={optionsDateFilter}
                />
              </Form.Item>
            </div>
            <div className="Order__rangeDate">
              <Form.Item<FieldType> name="range_date_filter">
                <RangePicker
                  disabledDate={disabledDaysDate}
                  format="DD/MM/YYYY"
                  popupClassName="Order__rangeDate__picker"
                  placeholder={["Từ ngày", "Đến ngày"]}
                />
              </Form.Item>
            </div>
            <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
              <Form.Item>
                <Button
                  ghost
                  className="Order__exportExcel d-flex align-items-center h-auto"
                  type="primary"
                  htmlType="submit"
                >
                  <img
                    className="object-fit-scale"
                    width={16}
                    height={16}
                    src={iconSearch}
                    alt=""
                  />
                  Xem
                </Button>
              </Form.Item>
            </ConfigProvider>
            <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
              <Form.Item>
                <Button
                  ghost
                  className="Order__history d-flex align-items-center h-auto"
                  type="primary"
                  onClick={() => setOpenModalHistoryTicketAll(true)}
                >
                  <img
                    width={16}
                    height={16}
                    className="object-fit-scale"
                    src={iconHistory}
                    alt=""
                  />
                  Lịch sử thao tác vé
                </Button>
              </Form.Item>
            </ConfigProvider>
            <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
              <Form.Item>
                <Button
                  ghost
                  className="Order__exportExcel d-flex align-items-center h-auto"
                  type="primary"
                  loading={loadingExcel}
                  onClick={handleExportExcel}
                >
                  <IconExportExcel color={"#435869"} width={16} height={16} />
                  Xuất Excel
                </Button>
              </Form.Item>
            </ConfigProvider>
          </Flex>
          <Flex align="center" className="Order__tutorials">
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 8)} />
          </Flex>
        </Flex>
      </Form>
      <Table
        className="Order__table"
        dataSource={listTicket?.length > 0 ? listTicket : []}
        locale={{
          triggerDesc: "Sắp xếp tăng dần",
          triggerAsc: "Hủy sắp xếp",
          cancelSort: "Sắp xếp giảm dần",
        }}
        rowClassName={(record) =>
          record?.status === "cancel" ? "bg-disabled" : ""
        }
        pagination={{
          total: pagination?.total,
          current: pagination?.current_page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ["bottomRight"],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        columns={columns}
      />
      <ModalTicket
        getListTicket={handleGetListOrders}
        ticketData={ticketData}
        openModalTicket={openModalTicket}
        setOpenModalTicket={setOpenModalTicket}
      />
      <ModalHistoryTicketAll
        open={openModalHistoryTicketAll}
        setOpen={setOpenModalHistoryTicketAll}
      />
    </div>
  );
};

export default OrderTicket;
