import React from "react";
import { IconProps } from "../../types/common";

const IconFileExport = ({ className, width, height }: IconProps) => {
  return (
    <svg
      className={className}
      width={width || 24}
      height={height || 24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.66602 6.00065H12.3327L8.66602 2.33398V6.00065ZM3.99935 1.33398H9.33268L13.3327 5.33398V13.334C13.3327 13.6876 13.1922 14.0267 12.9422 14.2768C12.6921 14.5268 12.353 14.6673 11.9993 14.6673H3.99935C3.64573 14.6673 3.30659 14.5268 3.05654 14.2768C2.80649 14.0267 2.66602 13.6876 2.66602 13.334V2.66732C2.66602 1.92732 3.25935 1.33398 3.99935 1.33398ZM4.66602 13.334H5.99935V9.33398H4.66602V13.334ZM7.33268 13.334H8.66602V8.00065H7.33268V13.334ZM9.99935 13.334H11.3327V10.6673H9.99935V13.334Z"
        fill="white"
      />
    </svg>
  );
};

export default IconFileExport;
