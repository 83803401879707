import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
  Tooltip,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { Lunar } from 'lunar-typescript';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import icPlus from '../../assets/images/schedules/ic-plus-box.svg';
import icCancel from '../../assets/images/trips/ic-cancel.svg';
import NumberInputName from '../../components/NumberInputName';
import PriceInputFormListTransship from '../../components/PriceInputFormListTransship';
import { DateFormat, TYPE_HOUR, TYPE_MINUTE, TYPE_PRICE_DOWN, TYPE_PRICE_UP } from '../../config/constant';

import { calculateTimeSum, isFindIndex } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchListPickupLocation,
  getPickUpLocationTransfer,
  pickupLocationSelector,
} from '../../redux/slice/pickupLocationSlice';
import './Schedules.scss';

export enum ScheduleType {
  specific_date = 'specific_date',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  days = 'days',
}

const travelPattern = /^\d{1,2}:(?:[0-5][0-9]|59)$/;

// @ts-ignore
function ModalTab2({ form, isDetail, tabActive }) {
  const { t } = useTranslation();
  const { listPickUpTransfer } = useAppSelector(pickupLocationSelector);
  const dispatch = useAppDispatch();
  const watchTranssipArrive = Form.useWatch('transship_arrive', form);
  const watchTransshipDepart = Form.useWatch('transship_depart', form);
  const frequencyType = Form.useWatch('frequency_type');
  const renderPickupLocations = () => {
    const filteredOptions = listPickUpTransfer
      .map((item) => {
        if (isFindIndex(watchTransshipDepart, item.id)) {
          return null;
        }
        return {
          value: item.id,
          label: item.name,
        };
      })
      .filter((option) => option !== null);

    return filteredOptions;
  };

  const renderPickupLocationsArrive = () => {
    const filteredOptions = listPickUpTransfer
      .map((item) => {
        if (isFindIndex(form.getFieldValue('transship_arrive'), item.id)) {
          return null;
        }
        return {
          value: item.id,
          label: item.name,
        };
      })
      .filter((option) => option !== null);

    return filteredOptions;
  };
  const pickupLocationOption: void | any[] = listPickUpTransfer?.length ? renderPickupLocations() : [];
  const pickupLocationOptionArrive: void | any[] = listPickUpTransfer?.length ? renderPickupLocationsArrive() : [];
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < moment(new Date());
  };
  const daysInWeek = [
    { value: 'Monday', label: 'Thứ hai' },
    { value: 'Tuesday', label: 'Thứ ba' },
    { value: 'Wednesday', label: 'Thứ tư' },
    { value: 'Thursday', label: 'Thứ năm' },
    { value: 'Friday', label: 'Thứ sáu' },
    { value: 'Saturday', label: 'Thứ bảy' },
    { value: 'Sunday', label: 'Chủ nhật' },
  ];

  const handleChangeScheduleType = (value: string) => {
    form.setFieldsValue({ frequency_value: null });
  };

  const daysInMonthCheckbox = Array.from({ length: 31 }, (_, index) => {
    const checkboxValue = index + 1;
    return (
      <Col span={3} key={checkboxValue}>
        <Checkbox value={checkboxValue < 10 ? `0${checkboxValue}` : `${checkboxValue}`}>{checkboxValue}</Checkbox>
      </Col>
    );
  });

  const daysInWeekCheckbox = daysInWeek.map((item) => {
    return (
      <Col span={6}>
        <Checkbox value={item?.value}>{item?.label}</Checkbox>
      </Col>
    );
  });

  const filterOptionPickup = (
    input: string,
    option?: {
      label: string;
      value: string;
    },
  ) => (option?.label ?? '').toLowerCase()?.includes(input.toLowerCase());

  useEffect(() => {
    dispatch(fetchListPickupLocation({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPickUpLocationTransfer({}));
  }, [dispatch]);

  const updateArriveTime = (values: any) => {
    const timePattern = /^([0-9]{1,}|[01]?[0-9]|2[0-4]):([0-5][0-9])$/;

    const travelTime = form.getFieldValue('travel_time');
    const departTimeValue = dayjs(form.getFieldValue('depart_time')).format('HH:mm');
    const departTimeNumber = departTimeValue?.replace(/:/g, '');
    if (Number(departTimeNumber) <= 2359) {
      if (timePattern.test(departTimeValue) && travelPattern.test(travelTime)) {
        const formattedTravelTime = travelTime;
        if (formattedTravelTime !== '00:00') {
          return form.setFieldsValue({
            arrive_time: calculateTimeSum(formattedTravelTime, departTimeValue),
          });
        }
      }
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Row gutter={8}>
        <Col xs={24} md={11} className={'depart_arrive_time'}>
          <Row gutter={16}>
            <Col xs={24} md={7}>
              <span className="required">{t('Tổng thời gian')}</span>
              <Form.Item
                name="travel_time"
                className="form-item-name required mt-2"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (tabActive !== '1') {
                        if ((value && value === '00:00') || !value) {
                          return Promise.reject(new Error('Vui lòng nhập tổng thời gian'));
                        }
                        if (!travelPattern.test(value)) {
                          return Promise.reject(new Error('Vui lòng nhập đúng định dạng 00:00'));
                        }
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input placeholder="00:00" onChange={updateArriveTime} />
              </Form.Item>
            </Col>
            <Col xs={24} md={7}>
              <span className="required">{t('Giờ đi')}</span>
              <Form.Item
                name="depart_time"
                className="form-item-name required mt-2"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: tabActive !== '1',
                    message: t('Vui lòng nhập giờ đi'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const departTimeNumber = dayjs(value).format('HH:mm')?.replace(/:/g, '');
                      if (Number(departTimeNumber) > 2359) {
                        return Promise.reject(new Error('Vui lòng nhập đúng định dạng thời gian'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <TimePicker
                  suffixIcon={null}
                  showNow={false}
                  onChange={updateArriveTime}
                  needConfirm={false}
                  defaultValue={dayjs('00:00', 'HH:mm')}
                  format={'HH:mm'}
                  placeholder="00:00"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={7}>
              <span className="required">{t('Giờ đến')}</span>
              <Form.Item
                name="arrive_time"
                className="form-item-name required mt-2"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: tabActive !== '1',
                    message: t('Vui lòng nhập giờ đến'),
                  },
                ]}
              >
                <Input placeholder="00:00" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={10}>
              <span className="required">{t('Mở chuyến trước')}</span>
              <Form.Item
                name="open_before_time"
                className="form-item-name required mt-2 open-before-time"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (tabActive !== '1') {
                        if (!getFieldValue('open_before')) {
                          return Promise.reject(new Error('Vui lòng nhập thời gian mở chuyến trước'));
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Flex className="input-flex-container" gap={0} align="center" vertical={false}>
                  <NumberInputName name={'open_before'} form={form} />
                  <Divider type="vertical" />
                  <Flex className="input-suffix-container" align="center">
                    <span className="fw-600 text-color-green-default">{t('Ngày')}</span>
                  </Flex>
                </Flex>
              </Form.Item>
            </Col>
            <Col xs={24} md={11} className={'box-input-time'}>
              <span>Đóng chuyến trước</span>
              <Form.Item
                name={'close_before_time_input'}
                className="form-item-name mt-2 pickup-time"
                validateTrigger={['onChange', 'onBlur']}
              >
                <Flex className="input-flex-container" gap={0} align="center" vertical={false}>
                  <NumberInputName name={'close_before_time'} form={form} placeholder="Số phút / giờ" />
                  <Divider type="vertical" />
                  <Form.Item name={'close_before_type'} validateTrigger={['onChange', 'onBlur']}>
                    <Radio.Group size="small">
                      <Radio.Button value={TYPE_MINUTE}>Phút</Radio.Button>
                      <Radio.Button value={TYPE_HOUR}>{t('Giờ')}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Flex>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} className="schedule-input">
          <Row gutter={12}>
            <Col xs={24} md={15}>
              <span className="required">{t('Thiết lập lịch chạy')}</span>
              <Form.Item
                name="frequency_type"
                className="form-item-name required mt-2"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: tabActive !== '1',
                    message: t('Vui lòng thiết lập lịch chạy'),
                  },
                ]}
              >
                <Select
                  className="input-h36"
                  placeholder="Chọn thiết lập"
                  size="large"
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  onChange={handleChangeScheduleType}
                  options={[
                    { value: 'specific_date', label: t('Chọn ngày') },
                    { value: 'daily', label: t('Hàng ngày') },
                    { value: 'weekly', label: t('Hàng tuần') },
                    { value: 'monthly', label: t('Hàng tháng') },
                  ]}
                />
              </Form.Item>
            </Col>
            {frequencyType === ScheduleType.specific_date && (
              <Col xs={24} md={8}>
                <Form.Item
                  name="frequency_value"
                  label=""
                  style={{ marginTop: '33px' }}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: tabActive !== '1',
                      message: t('Vui lòng thiết lập lịch chạy'),
                    },
                  ]}
                >
                  {frequencyType === ScheduleType.specific_date && (
                    <DatePicker
                      className="w-60 input-height"
                      placeholder={t('Chọn ngày')}
                      disabledDate={disabledDate}
                      allowClear={false}
                      cellRender={(current: any) => {
                        // @ts-ignore
                        let d = Lunar.fromDate(new Date(current));
                        const lunar = d.getDay();
                        const lunarMonth = d.getMonth();
                        return (
                          <div>
                            <div className="fs-14 fw-500">{current.date()}</div>
                            <div className="fs-12 lunar fw-500">
                              {lunar}
                              {
                                // eslint-disable-next-line eqeqeq
                                lunar == 1 || current.date() == 1 ? `/${lunarMonth}` : null
                              }
                            </div>
                          </div>
                        );
                      }}
                      format={DateFormat}
                    />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            {frequencyType !== ScheduleType.specific_date && (
              <Col xs={24} md={24}>
                <div></div>
                <Form.Item
                  name="frequency_value"
                  rules={[
                    {
                      required:
                        !!(
                          form.getFieldValue('frequency_type') &&
                          form.getFieldValue('frequency_type') !== ScheduleType.daily
                        ) && tabActive !== '1',
                      message: t('Vui lòng thiết lập lịch chạy'),
                    },
                  ]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  {frequencyType === ScheduleType.weekly && (
                    <Checkbox.Group className="days-select-checkbox">
                      <Row>{daysInWeekCheckbox}</Row>
                    </Checkbox.Group>
                  )}
                  {frequencyType === ScheduleType.monthly && (
                    <Checkbox.Group className="days-select-checkbox">
                      <Row>{daysInMonthCheckbox}</Row>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={5}>
          <span>Thời gian giữ chỗ</span>
          <Form.Item
            name={'reservation_time_close'}
            className="form-item-name required mt-2 pickup-time"
            validateTrigger={['onChange', 'onBlur']}
          >
            <Flex className="input-flex-container" gap={0} align="center" vertical={false}>
              <NumberInputName name={'reservation_time'} form={form} placeholder="Số phút / giờ" />
              <Divider type="vertical" />
              <Form.Item name={'reservation_type'} validateTrigger={['onChange', 'onBlur']}>
                <Radio.Group size="small">
                  <Radio.Button value={TYPE_MINUTE}>{t('Phút')}</Radio.Button>
                  <Radio.Button value={TYPE_HOUR}>{t('Giờ')}</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
      <Col xs={24} md={6}>
        <span className="required">{t('Điểm đón')}</span>
        <Form.Item
          name="is_depart_transship"
          className="form-item-name required mt-2"
          validateTrigger={['onChange', 'onBlur']}
          dependencies={['is_contract']}
          rules={[{ required: tabActive !== '1', message: 'Vui lòng chọn điểm đón' }]}
        >
          <Select
            className="input-h36 w-100"
            size="large"
            options={[
              { value: false, label: t('Không trung chuyển đón') },
              { value: true, label: t('Trung chuyển đón') },
            ]}
          />
        </Form.Item>
      </Col>
      <Form.List name="transship_depart">
        {(fields, { add, remove }) => (
          <>
            <Col xs={24} md={19}>
              <Form.Item
                name="pickup_location"
                className="form-item-name required pickup-location-select"
                validateTrigger={['onChange', 'onBlur']}
              >
                <Select
                  className="input-h36 w-100"
                  showSearch
                  placeholder={'Chọn điểm đón'}
                  size="large"
                  onChange={(value, option: any) => {
                    if (isFindIndex(watchTransshipDepart, value)) {
                      toast.error('Điểm này đã được chọn');
                    } else {
                      add({
                        id: value,
                        pickup_location_name: option.label,
                        is_extra_price: TYPE_PRICE_UP,
                        extra_price_value: 0,
                        pickup_time: undefined,
                        diff_day: 0,
                      });
                    }
                  }}
                  filterOption={filterOptionPickup}
                  options={pickupLocationOption}
                  optionRender={(option) => (
                    <Space className="w-100 ">
                      <Image width={28} height={26} src={icPlus} preview={false} />
                      <span className={'text-color-default fw-500 '}>{option.data.label}</span>
                    </Space>
                  )}
                />
              </Form.Item>
            </Col>

            <div>
              {fields.map((field) => {
                return (
                  <Row gutter={7} key={field.key}>
                    <Form.Item className="d-none" name={[field.name, 'id']}>
                      <Input placeholder="" disabled />
                    </Form.Item>
                    <Col style={{ height: '50px' }} span={1}>
                      <Tooltip title="Xóa">
                        <Button type="text" onClick={() => remove(field.name)} className="p-0">
                          <Image width={37} height={37} src={icCancel} preview={false} style={{ width: '100%' }} />
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col xs={24} md={7}>
                      <Form.Item className="pickup-location-name" name={[field.name, 'pickup_location_name']}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={5} className="time-schedule-block">
                      <Form.Item
                        name={[field.name, 'pickup_time']}
                        className="form-item-name form-item__pickup_time--depart required pickup-time"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập giờ đón',
                          },
                        ]}
                      >
                        <TimePicker
                          suffixIcon={null}
                          showNow={false}
                          needConfirm={false}
                          format={'HH:mm'}
                          placeholder="00:00"
                          onChange={(value: any) => {
                            const currentTime = form.getFieldValue('transship_depart');
                            currentTime[field.name] = {
                              ...currentTime[field.name],
                              pickup_time: value,
                            };
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                      <Form.Item
                        name={[field.name, 'diff_day']}
                        className="form-item-name form-item__diff_day required diff-day"
                        initialValue={0}
                      >
                        <Select
                          options={[
                            { value: 0, label: 'Trong ngày' },
                            { value: 1, label: '1 ngày sau' },
                            { value: 2, label: '2 ngày sau' },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={6} className="extra-price-block">
                      <Form.Item
                        name={[field.name, 'extra_price']}
                        className="form-item-name required extra_price"
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Flex className="input-flex-container component" gap={0} align="center" vertical={false}>
                          <Form.Item name={[field.name, 'is_extra_price']} validateTrigger={['onChange', 'onBlur']}>
                            <Radio.Group size="small">
                              <Radio.Button value={TYPE_PRICE_UP}>{t('Tăng')}</Radio.Button>
                              <Radio.Button value={TYPE_PRICE_DOWN}>{t('Giảm')}</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                          <Divider type="vertical" />
                          <PriceInputFormListTransship name={field.name} form={form} formList={'transship_depart'} />
                        </Flex>
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </>
        )}
      </Form.List>
      <Col xs={24} md={6}>
        <span className="required">{t('Điểm trả')}</span>
        <Form.Item
          name="is_arrive_transship"
          className="form-item-name required mt-2"
          validateTrigger={['onChange', 'onBlur']}
          dependencies={['is_contract']}
          rules={[{ required: tabActive !== '1', message: 'Vui lòng chọn điểm trả' }]}
        >
          <Select
            className="input-h36 w-100"
            size="large"
            options={[
              { value: false, label: t('Không trung chuyển trả') },
              { value: true, label: t('Trung chuyển trả') },
            ]}
          />
        </Form.Item>
      </Col>
      <Form.List name="transship_arrive">
        {(fields, { add, remove }) => (
          <>
            <Col xs={24} md={19}>
              <Form.Item
                name="pickup_location"
                className="form-item-name required pickup-location-select"
                validateTrigger={['onChange', 'onBlur']}
              >
                <Select
                  className="input-h36 w-100"
                  showSearch
                  placeholder={'Chọn điểm trả'}
                  size="large"
                  onChange={(value, option: any) => {
                    if (isFindIndex(watchTranssipArrive, value)) {
                      toast.error('Điểm này đã được chọn');
                    } else {
                      add({
                        id: value,
                        pickup_location_name: option.label,
                        is_extra_price: TYPE_PRICE_UP,
                        extra_price_value: 0,
                        pickup_time: undefined,
                        diff_day: 0,
                      });
                    }
                  }}
                  filterOption={filterOptionPickup}
                  options={pickupLocationOptionArrive}
                  optionRender={(option) => (
                    <Space className="w-100 fw-500 text-color-default">
                      <Image width={28} height={26} src={icPlus} preview={false} />
                      <span>{option.data.label}</span>
                    </Space>
                  )}
                />
              </Form.Item>
            </Col>
            <div className="pb-5">
              {fields.map(({ key, name }) => {
                return (
                  <Row gutter={9} key={key}>
                    <Form.Item className="d-none" name={[name, 'id']}>
                      <Input placeholder="" disabled />
                    </Form.Item>
                    <Col style={{ height: '50px' }} span={1}>
                      <Tooltip title="Xóa">
                        <Button type="text" onClick={() => remove(name)} className="p-0">
                          <Image width={37} height={37} src={icCancel} preview={false} />
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col xs={24} md={7}>
                      <Form.Item className="pickup-location-name" name={[name, 'pickup_location_name']}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={5}>
                      <Form.Item
                        name={[name, 'pickup_time']}
                        className="form-item-name form-item__pickup_time--arrive required pickup-time"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập giờ trả',
                          },
                        ]}
                      >
                        <TimePicker
                          onChange={(value: any) => {
                            if (!value) {
                              value = undefined;
                            }
                            const currentTime = form.getFieldValue('transship_arrive');
                            currentTime[name] = {
                              ...currentTime[name],
                              pickup_time: value,
                            };
                            form.setFieldsValue({
                              transship_arrive: currentTime,
                            });
                          }}
                          suffixIcon={null}
                          showNow={false}
                          needConfirm={false}
                          format={'HH:mm'}
                          placeholder="00:00"
                          required={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                      <Form.Item
                        name={[name, 'diff_day']}
                        className="form-item-name form-item__diff_day required diff-day"
                        initialValue={0}
                      >
                        <Select
                          options={[
                            { value: 0, label: 'Trong ngày' },
                            { value: 1, label: '1 ngày sau' },
                            { value: 2, label: '2 ngày sau' },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={6} className="extra-price-block">
                      <Form.Item
                        name={[name, 'extra_price']}
                        className="form-item-name required extra_price"
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Flex className="input-flex-container component" gap={0} align="center" vertical={false}>
                          <Form.Item
                            name={[name, 'is_extra_price']}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                message: t('Vui lòng nhập giá'),
                              },
                            ]}
                          >
                            <Radio.Group size="small">
                              <Radio.Button value={TYPE_PRICE_UP}>{t('Tăng')}</Radio.Button>
                              <Radio.Button value={TYPE_PRICE_DOWN}>{t('Giảm')}</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                          <Divider type="vertical" />
                          <PriceInputFormListTransship name={name} form={form} formList={'transship_arrive'} />
                        </Flex>
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
}

export default ModalTab2;
