import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { createBusTypeApi } from '../../../api/busType';
import { BusTypeState, CreateBusTypeState, ListBusTypeState } from '../../../types/busType';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';
import { Action, CREATE_ERROR, CREATE_SUCCESS } from '../../../config/message';
import { Toast } from '../../../config/constant';

// @ts-ignore
export const createBusType = createAsyncThunk(
  'admin/create/companyTypeBus',
  async (params: CreateBusTypeState, { dispatch, rejectWithValue }) => {
    try {
      const response = await createBusTypeApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
        dispatch(
          setToast({
            message: CREATE_SUCCESS(Action.TYPE_BUS),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: CREATE_ERROR(Action.TYPE_BUS),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const createBusTypeSlice = createSlice({
  name: 'createBusType',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as BusTypeState,
  reducers: {
    setCreate: (state: ListBusTypeState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setCreateError: (state: ListBusTypeState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetRegisterCreateBusType: (state: ListBusTypeState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBusType.pending, (state: BusTypeState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createBusType.fulfilled, (state: BusTypeState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(createBusType.rejected, (state: BusTypeState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const createBusTypeSelector = (state: RootState) => state.createBusType;
export const { setCreate, setCreateError, resetRegisterCreateBusType } = createBusTypeSlice.actions;
