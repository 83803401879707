import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { cancelBookingApi } from '../../../api/booking';
import { Toast } from '../../../config/constant';
import { Action, CANCEL_TICKET_ERROR, CANCEL_TICKET_SUCCESS } from '../../../config/message';
import { BookingState, CancelBooking } from '../../../types/booking';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

// @ts-ignore
export const cancelBooking = createAsyncThunk(
  'admin/bookings/cancel',
  async (params: CancelBooking, { dispatch, rejectWithValue }) => {
    try {
      const response = await cancelBookingApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: CANCEL_TICKET_SUCCESS(Action.CANCEL_TICKET),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message: CANCEL_TICKET_ERROR(Action.CANCEL_TICKET),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const cancelBookingSlice = createSlice({
  name: 'cancelBooking',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as BookingState,
  reducers: {
    setUpdate: (state: BookingState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setUpdateError: (state: BookingState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetCancelBooking: (state: BookingState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cancelBooking.pending, (state: BookingState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(cancelBooking.fulfilled, (state: BookingState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(cancelBooking.rejected, (state: BookingState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const cancelBookingSelector = (state: RootState) => state.cancelBooking;
export const { setUpdateError, setUpdate, resetCancelBooking } = cancelBookingSlice.actions;
