import React, { useCallback, useEffect, useState } from "react";
import "../styles/ListRegisterAdvertise.scss";
import {
  Flex,
  Select,
  Table,
  Tabs,
  Typography,
  type TableColumnsType,
  type TabsProps,
} from "antd";
import TextDisplay from "../../../components/Display/TextDisplay";
import { handleDisplayTimeToNow } from "../../../utils/helpers";
import { RegisterAdvertiseParams } from "../../../types/listRegisterAdvertise";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getListRegisterAdvertise,
  setCurrentPage,
  setPerPage,
} from "../../../redux/slice/listRegisterAdvertise";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant, TIMER_DEPOSIT } from "../../../config/constant";
import ModalRegisterDetail from "../components/ModalRegisterDetail";
import icEye from "../../../assets/images/listSignUp/icEye.png";
import {
  optionsPackageAdvertise,
  RegisterAdvertise,
} from "../models/constants";

const itemsTabs: TabsProps["items"] = [
  {
    key: RegisterAdvertise.ALL,
    label: <p className="tab-title tab-title--first">Tất cả</p>,
  },
  {
    key: RegisterAdvertise.UN_PROCESSED,
    label: <p className="tab-title">Chưa xử lý</p>,
  },
  {
    key: RegisterAdvertise.PROCESSED,
    label: <p className="tab-title">Đã xử lý</p>,
  },
];

const handleGetIsHandle = (value: RegisterAdvertise) => {
  switch (value) {
    case RegisterAdvertise.ALL:
      return undefined;
    case RegisterAdvertise.UN_PROCESSED:
      return 0;
    case RegisterAdvertise.PROCESSED:
      return 1;
    default:
      return undefined;
  }
};

const ListRegisterAdvertise = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<RegisterAdvertiseParams>(
    {} as RegisterAdvertiseParams
  );
  const [isHandle, setIsHandle] = useState<RegisterAdvertise>(
    RegisterAdvertise.ALL
  );
  const { loading, listRegisterAdvertise, pagination } = useAppSelector(
    (state) => state.listRegisterAdvertise
  );
  const [keyword, setKeyword] = useState<string>("");
  const columns: TableColumnsType<RegisterAdvertiseParams> = [
    {
      title: "STT",
      dataIndex: "key",
      align: "center",
      render: (_, record, index) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {index + 1}
        </Typography.Text>
      ),
    },
    {
      title: "Người liên hệ",
      dataIndex: "name",
      render: (name, record) => (
        <Flex className="flex-column">
          <Typography.Text className="fs-16 text-color fw-500">
            <TextDisplay text={name} />
          </Typography.Text>
          <Typography.Text className="fs-14 text-color-green-default fw-500">
            {handleDisplayTimeToNow(record?.created_at as string)}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (email) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={email} />
        </Typography.Text>
      ),
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      render: (phone) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={phone} />
        </Typography.Text>
      ),
    },
    {
      title: "Gói quảng cáo đăng ký",
      dataIndex: "package_name",
      render: (package_name) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={package_name} />
        </Typography.Text>
      ),
    },
    {
      title: "Giá trị gói",
      dataIndex: "package_value",
      render: (package_value) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={package_value} />
        </Typography.Text>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "is_handle",
      render: (is_handle) =>
        is_handle ? (
          <Flex
            className="status status--processed"
            justify="center"
            align="center"
          >
            Đã xử lý
          </Flex>
        ) : (
          <Flex className="status" justify="center" align="center">
            Chưa xử lý
          </Flex>
        ),
    },
    {
      title: "Chức năng",
      dataIndex: "status",
      render: (_, record) => (
        <Flex
          align="center"
          justify="center"
          onClick={() => {
            setData(record);
            setOpen(true);
          }}
          className="cursor-pointer"
        >
          <img
            width={22}
            height={22}
            className="object-fit-scale"
            src={icEye}
            alt=""
          />
        </Flex>
      ),
    },
  ];

  const handleGetListRegisterAdvertise = useCallback(() => {
    dispatch(
      getListRegisterAdvertise({
        page: pagination?.current_page,
        limit: pagination?.per_page,
        is_handle: handleGetIsHandle(isHandle),
        keyword: keyword,
      })
    );
  }, [
    dispatch,
    pagination?.current_page,
    pagination?.per_page,
    isHandle,
    keyword,
  ]);

  useEffect(() => {
    handleGetListRegisterAdvertise();
    // Thiết lập interval để gọi API mỗi 30 giây
    const intervalId = setInterval(handleGetListRegisterAdvertise, TIMER_DEPOSIT);
    // Dọn dẹp interval khi component bị unmount
    return () => clearInterval(intervalId);
  }, [handleGetListRegisterAdvertise]);

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      dispatch(setCurrentPage(Constant.DEFAULT_PAGE));
      dispatch(setPerPage(pageSize));
    } else {
      dispatch(setCurrentPage(page));
    }
  };

  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
  };

  const onChangeHandle = (value: string) => {
    setIsHandle(value as RegisterAdvertise);
  };
  return (
    <div className="ListRegisterAdvertise">
      <Tabs
        defaultActiveKey={isHandle}
        activeKey={isHandle}
        onChange={onChangeHandle}
        items={itemsTabs}
        className="ListRegisterAdvertise__tabs"
      />
      <Select
        className="ListRegisterAdvertise__select"
        value={keyword}
        onChange={handleChangeKeyword}
        options={optionsPackageAdvertise}
      />
      <Table
        loading={loading}
        dataSource={
          listRegisterAdvertise?.length > 0 ? listRegisterAdvertise : []
        }
        className="ListRegisterAdvertise__table"
        columns={columns}
        pagination={{
          total: pagination?.total,
          current: pagination?.current_page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ["bottomRight"],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
      />
      <ModalRegisterDetail
        handleGetListAdvertise={handleGetListRegisterAdvertise}
        data={data}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default ListRegisterAdvertise;
