export const validateNameAgent = [
  {
    required: true,
    message: "Vui lòng nhập tên",
  },
  {
    pattern: /^[^\s].*$/,
    message: "Tên không được chứa chỉ khoảng trắng",
  },
];

export const validateAddressAgent = [
  {
    required: true,
    message: "Vui lòng nhập địa chỉ",
  },
];

export const validateCommissionPercentAgent = [
  {
    required: true,
    message: "Vui lòng nhập giá trị chiết khấu",
  },
  {
    validator: (_: any, value: any) => {
      if (value && (isNaN(value) || value > 100)) {
        return Promise.reject(new Error("Giá trị không được vượt quá 100"));
      }
      return Promise.resolve();
    },
  },
];
