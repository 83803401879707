export const validateCommissionPercentCarCompany = [
    {
      required: true,
      message: "Vui lòng nhập chiết khấu",
    },
    {
      validator: (_: any, value: any) => {
        if (value && (isNaN(value) || value > 100)) {
          return Promise.reject(new Error("Chiết khấu không được vượt quá 100%"));
        }
        if (value && value < 5) {
          return Promise.reject(new Error("Chiết khấu không được nhỏ hơn 5%"));
        }
        return Promise.resolve();
      },
    },
  ];

export const validateTicketFeeCarCompany = [
    {
      required: true,
      message: "Vui lòng nhập phí xuất vé",
    },
    {
      validator: (_: any, value: any) => {
        if (value && (isNaN(value) || value > 100)) {
          return Promise.reject(new Error("Phí xuất vé không được vượt quá 100%"));
        }
        if (value && value < 1) {
          return Promise.reject(new Error("Phí xuất vé không được nhỏ hơn 1%"));
        }
        return Promise.resolve();
      },
    },
  ];
  