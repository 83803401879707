import { getListReportRechargeCarlinkAPI, getListReportRechargeCarlinkExcelAPI } from "../../../api/report";
import { ParamsReport, ReportRechargeCarlink } from "../../../types/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    total_prices: 0,
    filter: {
        options: 'request_time',
        from: '',
        to: '',
        is_promotion: 0,
    }
} as ReportRechargeCarlink;

export const reportRechargeCarlinkSlice = createSlice({
    name: 'reportRechargeCarlink',
    initialState,
    reducers: {
        setFilterOptions(state, action: PayloadAction<string>) {
            state.filter.options = action.payload;
        },
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setFilterIsPromotion(state, action: PayloadAction<number>) {
            state.filter.is_promotion = action.payload;
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportRechargeCarlink.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportRechargeCarlink.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_prices = action.payload?.total_prices;
            }).addCase(getListReportRechargeCarlink.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportRechargeCarlinkExcel.pending, (state) => {
            }).addCase(getListReportRechargeCarlinkExcel.fulfilled, (state, action) => {
            }).addCase(getListReportRechargeCarlinkExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            })
    }
})

export const getListReportRechargeCarlink = createAsyncThunk('ReportRechargeCarlink/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportRechargeCarlinkAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportRechargeCarlinkExcel = createAsyncThunk('ReportRechargeCarlink/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportRechargeCarlinkExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const {  setPerPage, setFilterOptions, setFilterRangeDate, setFilterIsPromotion } = reportRechargeCarlinkSlice.actions;