import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { lockAllSeatApi, unlockAllSeatApi } from '../../../api/booking';
import { Toast } from '../../../config/constant';
import { BookingState, LockAllSeatParams } from '../../../types/booking';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

export const postLockAllSeat = createAsyncThunk(
  'admin/bookings/lockAll',
  async (params: LockAllSeatParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await lockAllSeatApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: response?.data?.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const postUnlockAllSeat = createAsyncThunk(
  'admin/bookings/unlockAll',
  async (params: LockAllSeatParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await unlockAllSeatApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUnLock({ status: status, data: data }));
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: response?.data?.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const lockAllSeatSlice = createSlice({
  name: 'lockAllSeat',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
    isLocked: false,
  } as BookingState,
  reducers: {
    setCreate: (state: BookingState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
      state.isLocked = true;
    },
    setCreateError: (state: BookingState, { payload }) => {
      state.status = payload?.status;
    },
    resetLockAllSeat: (state: BookingState) => {
      state.success = false;
      state.message = '';
      state.status = 0;
    },
    setUnLock: (state: BookingState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
      state.isLocked = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postLockAllSeat.pending, (state: BookingState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(postLockAllSeat.fulfilled, (state: BookingState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(postLockAllSeat.rejected, (state: BookingState, actions) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = actions?.payload;
    });
  },
});

export const lockAllSeatSelector = (state: RootState) => state.lockAllSeat;
export const { setCreate, setCreateError, resetLockAllSeat, setUnLock } = lockAllSeatSlice.actions;
