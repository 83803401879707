import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getErrorMessage} from '../../../api';
import {updateCompanyApi, updateListBusCompanyDetailApi, updateListBusCompanyStatusApi} from '../../../api/busCompany';
import {Toast} from '../../../config/constant';
import {Action, UPDATE_ERROR, UPDATE_SUCCESS} from '../../../config/message';
import {CompanyState, UpdateCompanyState} from '../../../types';
import {RootState} from '../../store';
import {setToast} from '../toastNotifySlice';

// @ts-ignore
export const updateBusCompanyDetail = createAsyncThunk(
    'admin/updateCompany/detail',
    async (params: UpdateCompanyState, {dispatch, rejectWithValue}) => {
        try {
            const response = await updateListBusCompanyDetailApi(params);
            const {data, status} = response;
            if (status) {
                dispatch(setUpdate({status: status, data: data}));
                dispatch(
                    setToast({
                        message: UPDATE_SUCCESS(Action.BUS_COMPANY),
                        status: true,
                        type: Toast.TYPE_SUCCESS,
                    }),
                );
            }
            return true;
        } catch (error: any) {
            const {response} = error;
            dispatch(setUpdateError(response));
            dispatch(
                setToast({
                    message: UPDATE_ERROR(Action.BUS_COMPANY),
                    status: true,
                    type: Toast.TYPE_ERROR,
                }),
            );
            return rejectWithValue(getErrorMessage(error));
        }
    },
);
export const updateBusCompanyStatus = createAsyncThunk(
    'admin/updateCompanyStatus',
    async (
        params: {
            id: string;
            status: boolean;
        },
        {dispatch, rejectWithValue},
    ) => {
        try {
            const response = await updateListBusCompanyStatusApi(params);
            const {data, status} = response;
            if (status) {
                dispatch(setUpdate({status: status, data: data}));
                dispatch(
                    setToast({
                        message: UPDATE_SUCCESS(Action.BUS_COMPANY),
                        status: true,
                        type: Toast.TYPE_SUCCESS,
                    }),
                );
            }
            return true;
        } catch (error: any) {
            const {response} = error;
            dispatch(setUpdateError(response));
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const updateCompany = createAsyncThunk(
    'admin/updateCompany',
    async (
        params: any,
        {dispatch, rejectWithValue},
    ) => {
        try {
            const response = await updateCompanyApi(params);
            const {data, status} = response;
            if (status) {
                dispatch(setUpdate({status: status, data: data}));
                dispatch(
                    setToast({
                        message: UPDATE_SUCCESS(Action.BUS_COMPANY),
                        status: true,
                        type: Toast.TYPE_SUCCESS,
                    }),
                );
            }
            return true;
        } catch (error: any) {
            const {response} = error;
            dispatch(
                setToast({
                    message: UPDATE_ERROR(Action.BUS_COMPANY),
                    status: true,
                    type: Toast.TYPE_ERROR,
                }),
            );
            dispatch(setUpdateError(response));
            return rejectWithValue(getErrorMessage(error));
        }
    },
);
export const updateBusCompanySlice = createSlice({
    name: 'updateCompany',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        message: '',
        errorDetail: null,
    } as CompanyState,
    reducers: {
        setUpdate: (state: CompanyState, {payload}) => {
            const {message} = payload.data;
            state.status = payload?.status;
            state.message = message;
        },
        setUpdateError: (state: CompanyState, {payload}) => {
            const {errors} = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetUpdate: (state: CompanyState) => {
            state.success = false;
            state.message = '';
            state.errorDetail = null;
            state.status = 0;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateBusCompanyDetail.pending, (state: CompanyState) => {
            state.loading = true;
        });
        builder.addCase(updateBusCompanyDetail.fulfilled, (state: CompanyState, res) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateBusCompanyDetail.rejected, (state: CompanyState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });

        builder.addCase(updateBusCompanyStatus.pending, (state: CompanyState) => {
            state.loading = true;
        });
        builder.addCase(updateBusCompanyStatus.fulfilled, (state: CompanyState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateBusCompanyStatus.rejected, (state: CompanyState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });

        builder.addCase(updateCompany.pending, (state: CompanyState) => {
            state.loading = true;
        });
        builder.addCase(updateCompany.fulfilled, (state: CompanyState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateCompany.rejected, (state: CompanyState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });
    },
});

export const updateBusCompanySelector = (state: RootState) => state.updateCompanyBusSlice;
export const {setUpdate, setUpdateError, resetUpdate} = updateBusCompanySlice.actions;
