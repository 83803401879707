import { getListReportBalanceChangeAgentAPI, getListReportBalanceChangeAgentExcelAPI } from "../../../api/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParamsReport, ReportBalanceChange } from "../../../types/report";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    filter: {
        from: '',
        to: '',
        options: '',
    }
} as ReportBalanceChange;

export const reportBalanceChangeAgentSlice = createSlice({
    name: 'reportBalanceChangeAgent',
    initialState,
    reducers: {
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setFilterOptions(state, action: PayloadAction<string>) {
            state.filter.options = action.payload;
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportBalanceChangeAgent.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportBalanceChangeAgent.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;

            }).addCase(getListReportBalanceChangeAgent.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportBalanceChangeAgentExcel.pending, (state) => {
            }).addCase(getListReportBalanceChangeAgentExcel.fulfilled, (state, action) => {
            }).addCase(getListReportBalanceChangeAgentExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach(fieldName => {
                        toast.error(`${[listErros[fieldName]]}`)
                    })
                }
            })
    }
})

export const getListReportBalanceChangeAgent = createAsyncThunk('ReportBalanceChangeAgent/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportBalanceChangeAgentAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportBalanceChangeAgentExcel = createAsyncThunk('ReportBalanceChangeAgent/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportBalanceChangeAgentExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setFilterRangeDate, setFilterOptions, setPerPage } = reportBalanceChangeAgentSlice.actions;