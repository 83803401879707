import { InfoCircleFilled } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Rule } from 'antd/es/form';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/desktop/busBooking.scss';
import HeaderBooking from '../../components/HeaderBooking';
import { Constant, Toast, USER_GROUP, YearFormat } from '../../config/constant';
import { phoneRequiredRules, validatorEmail } from '../../config/validators/validators';
import { convertTimeFormat, filterOption, formattedPrice, maxValidator } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/slice/authSlice';
import { resetCancelBooking } from '../../redux/slice/bookings/cancelBookingSlice';
import { createBooking, createBookingSelector, resetBooking } from '../../redux/slice/bookings/createBookingSlice';
import { destroyBooking, resetDestroyBooking } from '../../redux/slice/bookings/destroyBookingSlice';
import {
  holdingBookingSelector,
  resetBookingType,
  resetDataBooking,
} from '../../redux/slice/bookings/holdingBookingSlice';
import { getBookingInfo } from '../../redux/slice/bookings/listBooking';
import { resetUpdateBooking } from '../../redux/slice/bookings/updateBooking';
import { setToast } from '../../redux/slice/toastNotifySlice';
import { paymentAgentContractOptions, paymentContractOptions } from '../../utils/bookingItem';
import ModalInfoInsurance from '../../components/Modal/ModalInfoInsurance';
const BusContractBooking: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  listBooking,
  formSearch,
}) => {
  const { status: statusCreateUpdate, price, loading: loadingCreate } = useAppSelector(createBookingSelector);
  const { listCustomers } = useAppSelector(createBookingSelector);
  const { group } = useAppSelector(selectAuth);
  const { dataBooking, bookingType, loading } = useAppSelector(holdingBookingSelector);
  const { t } = useTranslation();
  const [formContract] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const [paymentMethod, setPaymentMethod] = useState();
  const [isRepresentChecked, setIsRepresentChecked] = useState(false);
  const [openInfoInsurance, setOpenInfoInsurance] = useState(false);
  const [customerPhone, setCustomerPhone] = useState<string>('');
  const methodType = Form.useWatch('contract_type', formContract);
  const provinceId = Form.useWatch('route_id', formSearch);
  const searchDateBooking = dayjs(Form.useWatch('date_booking', formSearch)).format(YearFormat);
  const searchDepartTime = Form.useWatch('depart_time', formSearch);
  const pickUpValue = Form.useWatch('pickup_location_id', formContract);
  const dropOffValue = Form.useWatch('drop_off_location_id', formContract);
  const isInSurance = Form.useWatch('is_insurance', formContract);

  const dispatch = useAppDispatch();
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
    formContract.resetFields();
    setIsRepresentChecked(false);
    dispatch(resetDataBooking());
    dispatch(resetBookingType());
    const tmpDataDestroy = {
      ticket_code: [dataBooking?.ticket_code],
    };
    dispatch(destroyBooking(tmpDataDestroy));
  };
  const onChangePayment = ({ target: { value } }: RadioChangeEvent) => {
    setPaymentMethod(value);
  };
  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      formContract.scrollToField(firstErrorField.name);
    }
  };
  const onFinish = (values: any) => {
    const contractsData = values.contracts.reduce((acc: any, contract: any) => {
      acc[contract.name_seat] = {
        customer_phone: contract.phone_contract,
        customer_email: contract.email_contract,
        customer_name: contract.name_contract,
      };
      return acc;
    }, {});
    if (isRepresentChecked) {
      const tmpDataRepresent = {
        booking_type: 'contract_same',
        is_diagram: listBooking?.schedule?.is_diagram,
        is_insurance: values.is_insurance ? values.is_insurance : false,
        ticket_code: [dataBooking?.ticket_code],
        payment_method: values.payment_method,
        schedule_id: listBooking?.schedule?.is_diagram ? undefined : listBooking?.schedule?.id,
        depart_date: listBooking?.schedule?.is_diagram ? undefined : searchDateBooking,
        pickup_extra: pickUpValue === 'other' ? values.pickup_extra : undefined,
        drop_off_extra: dropOffValue === 'other' ? values.drop_off_extra : undefined,
        pickup_location_id: values.pickup_location_id === 'other' ? null : values.pickup_location_id,
        drop_off_location_id: values.drop_off_location_id === 'other' ? null : values.drop_off_location_id,
        contract_type: values.contract_type ? values.contract_type : 'personal',
        partner_phone: values.phone,
        partner_name: values.name,
        partner_position: methodType === 'enterprise' ? values.partner_position : undefined,
        partner_company: methodType === 'enterprise' ? values.partner_company : undefined,
        partner_tax: methodType === 'enterprise' ? values.partner_tax : undefined,
        partner_email: values.partner_email,
        partner_address: values.partner_address,
        note: values.note,
      };
      dispatch(createBooking(tmpDataRepresent));
    } else {
      const isContractDataValid = Object.values(contractsData).every((contract: any) => {
        return contract.customer_phone && contract.customer_name;
      });
      if (isContractDataValid) {
        const tmpDataNotPresent = {
          booking_type: 'contract_custom',
          schedule_id: listBooking?.schedule?.is_diagram ? undefined : listBooking?.schedule?.id,
          is_insurance: values.is_insurance ? values.is_insurance : false,
          depart_date: listBooking?.schedule?.is_diagram ? undefined : searchDateBooking,
          is_diagram: listBooking?.schedule?.is_diagram,
          ticket_code: [dataBooking?.ticket_code],
          payment_method: values.payment_method,
          pickup_extra: pickUpValue === 'other' ? values.pickup_extra : undefined,
          drop_off_extra: dropOffValue === 'other' ? values.drop_off_extra : undefined,
          pickup_location_id: values.pickup_location_id === 'other' ? null : values.pickup_location_id,
          drop_off_location_id: values.drop_off_location_id === 'other' ? null : values.drop_off_location_id,
          contract_type: values.contract_type ? values.contract_type : 'personal',
          custom_info: contractsData,
          partner_phone: values.phone,
          partner_name: values.name,
          partner_email: values.partner_email,
          partner_position: methodType === 'enterprise' ? values.partner_position : undefined,
          partner_company: methodType === 'enterprise' ? values.partner_company : undefined,
          partner_tax: methodType === 'enterprise' ? values.partner_tax : undefined,
          partner_address: values.partner_address,
          note: values.note,
        };
        dispatch(createBooking(tmpDataNotPresent));
      } else {
        dispatch(
          setToast({
            message: 'Vui lòng nhập thông tin hành khách',
            status: true,
            type: Toast.TYPE_ERROR,
          }),
        );
      }
    }
  };

  const NameRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập họ và tên'),
    },
    {
      pattern: /^[^\s].*$/,
      message: t('Tên không được chứa chỉ khoảng trắng'),
    },
    maxValidator(255, 'Số kí tự vượt quá 255 từ'),
  ];
  const NameCompanyRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập tên công ty'),
    },
    maxValidator(255, 'Số kí tự vượt quá 255 từ'),
  ];
  const TaxRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập MST'),
    },
  ];

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsRepresentChecked(e.target.checked);
    formContract.setFieldsValue({ is_represent: e.target.checked });
  };
  const onChangeInsurance = (checked: boolean) => {};
  const onCancelSuccess = useCallback(() => {
    onCancel();
    dispatch(resetUpdateBooking());
    dispatch(resetBooking());
    dispatch(resetCancelBooking());
    formContract.resetFields();
    dispatch(resetDestroyBooking());
    dispatch(resetDataBooking());
    dispatch(resetBookingType());
    dispatch(
      getBookingInfo({
        route_id: provinceId,
        depart_date: searchDateBooking,
        depart_time: convertTimeFormat(searchDepartTime),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formContract, provinceId, searchDateBooking, searchDepartTime]);

  const initialValue =
    listBooking?.schedule?.bus_type?.seat_map?.seats?.map((seatName: any) => ({ name_seat: seatName })) ?? [];
  useEffect(() => {
    if (dataBooking && bookingType === 'contract') {
      formContract.setFieldsValue({
        ticket_code: dataBooking?.ticket_code,
        total_price: dataBooking?.price,
        bus_type: listBooking?.schedule?.bus_type?.name,
        contract_value: formattedPrice(dataBooking?.price),
      });
    }
  }, [dataBooking, formContract, bookingType, listBooking]);
  useEffect(() => {
    if (statusCreateUpdate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateUpdate]);

  const handleOptionPickup = () => {
    let options: any = [];
    options = options.concat(
      listBooking?.schedule?.transship_depart
        ? listBooking.schedule.transship_depart.map((item: any) => ({
            value: item.id,
            label: `(${convertTimeFormat(item.pickup_time)}) ${item.pickup_location_name}`,
          }))
        : [],
    );
    if (
      listBooking?.schedule?.is_depart_transship ||
      listBooking?.contract?.code ||
      listBooking?.schedule?.is_contract
    ) {
      options.push({
        value: 'other',
        label: 'Nhập điểm trung chuyển khác',
      });
    }
    return options;
  };
  const handleOptionDropOff = () => {
    let options: any = [];
    options = options.concat(
      listBooking?.schedule?.transship_arrive
        ? listBooking.schedule.transship_arrive.map((item: any) => {
            return {
              value: item.id,
              label: `(${convertTimeFormat(item?.pickup_time)}) ${item.pickup_location_name}`,
            };
          })
        : [],
    );
    if (
      listBooking?.schedule?.is_arrive_transship ||
      listBooking?.contract?.code ||
      listBooking?.schedule?.is_contract
    ) {
      options.push({
        value: 'other',
        label: 'Nhập điểm trung chuyển khác',
      });
    }
    return options;
  };
  const handleInputChange = (value: string) => {
    setCustomerPhone(value);
  };
  const onFillCustomer = (value: string) => {
    if (listCustomers?.length) {
      const selectedCustomer = listCustomers?.find((customer: any) => customer.phone === value);
      if (selectedCustomer) {
        formContract.setFieldsValue({
          name: selectedCustomer.name,
          phone: selectedCustomer.phone,
        });
      }
    }
  };
  const onChangePhone = (keyword: any) => {
    onFillCustomer(keyword);
  };
  const formatNumber = (value: number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const numericValue = parseFloat(rawValue.replace(/[^0-9.]/g, ''));
    if (!isNaN(numericValue)) {
      formContract.setFieldsValue({
        contract_value: formatNumber(numericValue),
      });
    } else
      formContract.setFieldsValue({
        contract_value: 0,
      });
    formatNumber(numericValue);
  };
  useEffect(() => {
    if (isInSurance) {
      formContract.setFieldValue('contract_value', formattedPrice(dataBooking?.price_with_insurance));
      if (group === USER_GROUP.AGENT) {
        formContract.setFieldValue('commission_price', formattedPrice(dataBooking?.price_commission_insurance));
      }
    } else {
      formContract.setFieldValue('contract_value', formattedPrice(dataBooking?.price));
      if (group === USER_GROUP.AGENT) {
        formContract.setFieldValue('commission_price', formattedPrice(dataBooking?.price_commission));
      }
    }
  }, [price, formContract, group, dataBooking, isInSurance]);
  return (
    <Modal
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      width={900}
      maskClosable={false}
      confirmLoading={loading || loadingCreate}
      className="modal-bus-booking"
    >
      <Spin spinning={loadingCreate || loading}>
        <Card className="card-modal-booking">
          <HeaderBooking listBooking={listBooking} title={'Hợp đồng thuê xe'} isContract={true} />
          <Form
            layout="vertical"
            className="mt-3"
            form={formContract}
            onFinish={onFinish}
            autoComplete="off"
            validateTrigger="onSubmit"
            labelCol={{ span: 12 }}
            colon={false}
            labelAlign={'left'}
            initialValues={{
              is_insurance: false,
              is_represent: false,
            }}
            labelWrap
            onFieldsChange={(allFields) => {
              scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
            }}
          >
            <div className="form-booking-block contract">
              <Row gutter={16}>
                <Col span={7}>
                  <Form.Item name="contract_type" className="fw-500" label={t('Loại hình hợp đồng')}>
                    <Select
                      defaultValue={'personal'}
                      options={[
                        { value: 'personal', label: 'Cá nhân' },
                        { value: 'enterprise', label: 'Doanh nghiệp' },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="ticket_code" label={t('Mã hợp đồng')}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                {methodType === 'enterprise' && (
                  <Col span={7}>
                    <Form.Item name="bus_type" label={t('Loại xe')}>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={12}>
                <Col span={6}>
                  <Form.Item
                    name="phone"
                    label={t('Số điện thoại')}
                    className="require"
                    rules={phoneRequiredRules}
                    required={true}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input
                      className="input-h36 input-phone"
                      placeholder={t('Nhập số điện thoại')}
                      size="large"
                      value={customerPhone}
                      onChange={(e) => handleInputChange(e.target.value)}
                      onBlur={(e) => {
                        onChangePhone(customerPhone);
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    name="name"
                    label={t('Họ và tên')}
                    className="require"
                    rules={NameRules}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input placeholder="Nhập họ và tên" />
                  </Form.Item>
                </Col>
                {methodType === 'enterprise' ? (
                  <Col span={9}>
                    <Form.Item name="partner_position" label={t('Chức vụ')}>
                      <Input placeholder="Nhập chức vụ" />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={9}>
                    <Form.Item name="partner_email" label={t('Email')} validateTrigger={['onChange', 'onBlur']}>
                      <Input placeholder="Nhập email" />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {methodType === 'enterprise' && (
                <>
                  <Row gutter={12}>
                    <Col span={10}>
                      <Form.Item
                        name="partner_company"
                        label={t('Công ty')}
                        className="require"
                        rules={NameCompanyRules}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input placeholder="Nhập tên công ty" />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        name="partner_tax"
                        label={t('Mã số thuế')}
                        className="require"
                        rules={TaxRules}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input placeholder="Nhập MST" />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item
                        name="partner_email"
                        label={t('Email')}
                        className="require"
                        rules={validatorEmail}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input placeholder="Nhập email công ty" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="partner_address" label={t('Địa chỉ')} className="require">
                        <Input placeholder="Nhập địa chỉ công ty" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="pickup_location_id"
                    className="form-item-name require"
                    label={t('Điểm xuất phát')}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      className="input-h36"
                      placeholder="Nhập điểm xuất phát"
                      size="large"
                      style={{ width: '100%' }}
                      options={handleOptionPickup()}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="drop_off_location_id"
                    className="form-item-name require"
                    label={t('Điểm trả khách')}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      className="input-h36"
                      placeholder="Nhập điểm trả"
                      size="large"
                      style={{ width: '100%' }}
                      options={handleOptionDropOff()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ display: 'flex' }}>
                {pickUpValue === 'other' && (
                  <Col span={12}>
                    <Form.Item
                      name="pickup_extra"
                      className="form-item-name require"
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input
                        className="input-h36"
                        placeholder="Chọn điểm đón khác"
                        size="large"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                )}
                {pickUpValue !== 'other' && dropOffValue === 'other' && <Col span={12}></Col>}
                {dropOffValue === 'other' && (
                  <Col span={12}>
                    <Form.Item
                      name="drop_off_extra"
                      className="form-item-name require"
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input
                        className="input-h36"
                        placeholder="Nhập điểm trả khác"
                        size="large"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={20}>
                <Col span={6}>
                  <Form.Item
                    name="contract_value"
                    label={t('Giá trị hợp đồng')}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input suffix="VND " onChange={handleChange} disabled />
                  </Form.Item>
                  {isInSurance && (
                    <Typography className="fs-14 fw-500 text-insurance mt--10">
                      {listBooking?.policy?.insurance_policy?.percent
                        ? `+${listBooking?.policy?.insurance_policy?.percent}% tiền bảo hiểm`
                        : `+${formattedPrice(dataBooking?.price_with_insurance - dataBooking?.price)}đ tiền bảo hiểm`}
                    </Typography>
                  )}
                </Col>
                {group === USER_GROUP.AGENT && (
                  <Col span={6}>
                    <Form.Item
                      name="commission_price"
                      label={t('Tổng tiền đại lý trả')}
                      className="booking-input-disable price"
                    >
                      <Input suffix="VND" disabled />
                    </Form.Item>
                    <Typography className="fs-14 fw-500 text-discount">
                      {`Đã trừ ${
                        listBooking?.schedule?.agent?.commission_percent
                          ? listBooking?.schedule?.agent?.commission_percent
                          : '0'
                      }% chiết khấu`}
                    </Typography>
                  </Col>
                )}
                <Col span={group === USER_GROUP.AGENT && listBooking?.schedule?.agent?.commission_percent ? 12 : 18}>
                  <Form.Item name="note" label={t('Ghi chú chuyến')}>
                    <Input placeholder="Nhập ghi chú" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item name="payment_method" label={t('Phương thức thanh toán')} className="require">
                    <Radio.Group
                      options={group === USER_GROUP.AGENT ? paymentAgentContractOptions : paymentContractOptions}
                      onChange={onChangePayment}
                      value={paymentMethod}
                      buttonStyle="solid"
                      className="payment-checkbox-group contract"
                    />
                  </Form.Item>
                </Col>
                {(listBooking?.policy?.insurance_policy?.percent || listBooking?.policy?.insurance_policy?.price) && (
                  <Col
                    className="d-flex"
                    style={
                      group === 'agent'
                        ? { columnGap: '11px', marginTop: '20px', marginLeft: '-30px' }
                        : { columnGap: '11px', marginTop: '20px', marginLeft: '4px' }
                    }
                  >
                    <Form.Item name="is_insurance" className="booking-input-disable mt-3">
                      <Switch onChange={onChangeInsurance} />
                    </Form.Item>
                    <>
                      <div className="mt-4" style={{ columnGap: '5px' }}>
                        <Typography.Text className="fw-500 fs-14">
                          {t(
                            `Bảo hiểm chuyến đi (${
                              listBooking?.policy?.insurance_policy?.percent
                                ? `${listBooking?.policy?.insurance_policy?.percent}% giá vé`
                                : `${formattedPrice(listBooking?.policy?.insurance_policy?.price)}đ/ghế`
                            }) `,
                          )}
                        </Typography.Text>
                        <Tooltip title={'Xem chi tiết chính sách bảo hiểm'}>
                          <InfoCircleFilled style={{ color: '#F8B507' }} onClick={() => setOpenInfoInsurance(true)} />
                        </Tooltip>
                      </div>
                    </>
                  </Col>
                )}
              </Row>
              <Row gutter={20} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col>
                  <Typography className="fs-16 fw-600">{t('Danh sách hành khách')}</Typography>
                </Col>
                <Col>
                  <Form.Item name="is_represent">
                    <Checkbox checked={isRepresentChecked} onChange={onCheckboxChange}>
                      {t('Lấy thông tin người đại diện')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="customer-list-contract">
                {listBooking?.schedule?.is_diagram && (
                  <Col span={2}>
                    <Typography>{t('Ghế')}</Typography>{' '}
                  </Col>
                )}
                <Col span={8}>
                  <Typography>{t('Họ và tên')}</Typography>
                </Col>
                <Col span={listBooking?.schedule?.is_diagram ? 6 : 8} className="pl-9">
                  <Typography>{t('Số điện thoại')}</Typography>
                </Col>
                <Col span={8} className="pl-9">
                  <Typography>{t('Email')}</Typography>
                </Col>
              </Row>
              <Form.List name={`contracts`} initialValue={initialValue}>
                {(fields) => {
                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key}>
                          <Row gutter={18} className="mt-1">
                            {listBooking?.schedule?.is_diagram && (
                              <Col span={2}>
                                <Form.Item {...restField} name={[name, 'name_seat']} className="input-seat">
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                            )}

                            <Col span={8}>
                              <Form.Item name={[name, 'name_contract']} className="group-input color">
                                <Input placeholder="Nhập họ và tên" />
                              </Form.Item>
                            </Col>
                            <Col span={listBooking?.schedule?.is_diagram ? 6 : 8}>
                              <Form.Item name={[name, 'phone_contract']} className={'btn-group-color'}>
                                <Input placeholder="Nhập SĐT" />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item name={[name, 'email_contract']}>
                                <Input placeholder="Nhập email" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </div>
            <Row className="d-flex justify-content-end mt-3">
              <Button key="cancel" className=" w-160 h-btn-48 mr-16" onClick={() => modalHandleCancel()}>
                {cancelText}
              </Button>
              <Button
                key="ok"
                type="primary"
                className="w-160 h-btn-48 btn-success"
                htmlType="submit"
                onClick={() => modalHandleOk()}
              >
                {okText}
              </Button>
            </Row>
          </Form>
        </Card>
        <ModalInfoInsurance
          modalOpen={openInfoInsurance}
          onCancel={() => setOpenInfoInsurance(false)}
          onOk={() => false}
          listBooking={listBooking}
        />
      </Spin>
    </Modal>
  );
};
export default BusContractBooking;
