import { toast } from 'react-toastify';

export const Message = {
  START_DATE: 'Vui lòng nhập ngày bắt đầu',
  END_DATE: 'Vui lòng nhập ngày kết thúc',
  NOTE: 'Vui lòng nhập lý do',
  ROUTE: 'Vui lòng chọn tuyến xe',
  UN_AUTH: 'Truy cập bị Từ chối! Người dùng hết phiên đăng nhập',
};
export const CREATE_SUCCESS = (attribute: string) => `Tạo ${attribute} thành công`;
export const CREATE_ERROR = (attribute: string) => `Tạo ${attribute} thất bại`;
export const UPDATE_SUCCESS = (attribute: string) => `Cập nhật ${attribute} thành công`;
export const DEPART_SUCCESS = (attribute: string) => `Xuất bến ${attribute} thành công`;
export const UPDATE_ERROR = (attribute: string) => `Cập nhật ${attribute} thất bại`;
export const DEPART_ERROR = (attribute: string) => `Xuất bến ${attribute} thất bại`;
export const CANCEL_TICKET_SUCCESS = (attribute: string) => `${attribute} thành công`;
export const CANCEL_TICKET_ERROR = (attribute: string) => `${attribute} thất bại`;
export const DUPLICATE_SUCCESS = (attribute: string) => `Nhân bản ${attribute} thành công`;
export const DUPLICATE_ERROR = (attribute: string) => `Nhân bản ${attribute} thất bại`;

export const messageCreateSuccess = (message: string) => {
  toast.success(`Tạo ${message} thành công`);
};

export const messageCreateError = (message: string) => {
  toast.error(`Tạo ${message} thất bại`);
};

export const messageUpdateSuccess = (message: string) => {
  toast.success(`Cập nhật ${message} thành công`);
};

export const messageUpdateError = (message: string) => {
  toast.error(`Cập nhật ${message} thất bại`);
};

export const messageWarning = (message: string) => {
  toast.warning(`Vui lòng chọn ${message}`);
};

export const Action = {
  BUS: 'xe',
  SERVICE: 'tiện ích',
  TYPE_BUS: 'loại xe',
  AGENT: 'đại lý',
  BUS_COMPANY: 'nhà xe',
  USER: 'người dùng',
  PERMISSION: 'phân quyền',
  SCHEDULE: 'lịch chạy',
  BOOKING: 'Đặt chỗ',
  LOCK_BOOKING: 'khóa ghế',
  TRIP: 'chuyến đi',
  CANCEL_TICKET: 'Hủy vé',
  CASH: 'Thanh toán',
  RESERVE: 'Đặt vé',
  EXTRA_TRIP: 'chuyến đi tăng cường',
  BOOKING_DRIVER: 'tài xế',
  MAIL_BOOKING: 'gửi mail',
  CANCEL_CONTRACT: 'Hủy hợp đồng',
  MOVE_TICKET: 'Chuyển vé',
  DELETE_TRIP: 'Xóa điều hành',
};

export const ACTION_NAME = {
  USER: 'người dùng',
  PERMISSION: 'phân quyền',
  POLiCY: 'chính sách',
  OFFICE: 'văn phòng',
  CARLINK: 'carlink',
  CAR_COMPANY: 'nhà xe',
  AGENT: 'đại lý',
  REPOSIT: 'nạp tiền',
  NOTIFICATION: 'thông báo',
  INSURANCE: 'bảo hiểm',
};
