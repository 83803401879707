import {Rule} from "antd/es/form";

export const validateNoStartingNumber =
    (additionalArgument: any) => (_: any, value: any) => {
        // Kiểm tra xem giá trị không bắt đầu bằng số
        const startsWithNumberRegex = /^\d/;

        if (!startsWithNumberRegex.test(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject(
                `${additionalArgument} không được bắt đầu bằng số`
            );
        }
    };

export const validateNoSpecialChars =
    (additionalArgument: any) => (_: any, value: any) => {
        // Kiểm tra xem giá trị không chứa ký tự đặc biệt
        const specialCharsRegex = /[!@#\$%\^\&*\)\(+=._-]/;

        if (!specialCharsRegex.test(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject(`${additionalArgument} không chứa ký tự đặc biệt`);
        }
    };

export const validateNoNumber = (additionalArgument: any) => (_: any, value: any) => {
    // Kiểm tra xem giá trị không chứa số và ký tự đầu không phải số
    const containsNumberRegex = /\d/;
    const startsWithNonNumberRegex = /^[^\d]/;

    if (!containsNumberRegex.test(value) || startsWithNonNumberRegex.test(value)) {
        return Promise.resolve();
    } else {
        return Promise.reject(`${additionalArgument} không được chứa số ở đầu`);
    }
};

export const validatePhone = (_: any, value: any) => {
    // Bỏ qua validate nếu giá trị rỗng
    if (value.length === 0) {
        return Promise.resolve();
    }
    // Kiểm tra xem giá trị có phải là một số và có độ dài từ 8 đến 11 chữ số
    const numberRegex = /^[0-9]+$/;

    // Kiểm tra nếu giá trị bắt đầu bằng +84
    const startsWithPlus84 = /^\+84/;

    if (
        (numberRegex.test(value) || startsWithPlus84.test(value)) &&
        value.length >= 8 &&
        value.length <= 11
    ) {
        return Promise.resolve();
    } else {
        return Promise.reject("Số điện thoại không hợp lệ");
    }
};
export const validatePhone2 = (_: any, value: any) => {
    // Bỏ qua validate nếu giá trị rỗng
    if (value.length === 0) {
        return Promise.resolve();
    }

    // Kiểm tra xem giá trị có phải là một số và có độ dài từ 8 đến 11 chữ số
    const numberRegex = /^[0-9]+$/;

    // Kiểm tra nếu giá trị bắt đầu bằng +84
    const startsWithPlus84 = /^\+84/;

    if (
        (numberRegex.test(value) || startsWithPlus84.test(value)) &&
        value.length >= 8 &&
        value.length <= 11
    ) {
        return Promise.resolve();
    } else {
        return Promise.reject("Số điện thoại không hợp lệ");
    }
};


const phoneValidator = ({getFieldValue}: any) => ({
    validator(_: any, value: any) {
        // Kiểm tra nếu trường không rỗng thì mới validate
        if (getFieldValue("phone")) {
            return validatePhone2(_, value);
        } else {
            return Promise.resolve();
        }
    },
});

export const phoneRules = [
    {required: false, message: "Vui lòng nhập số điện thoại"},
    phoneValidator,
];

export const phoneRulesRequired = [
    {required: true, message: "Vui lòng nhập số điện thoại"},
    phoneValidator,
];

export const validatorEmail: any = [
    {required: true, message: "Vui lòng nhập email"},
    {type: "email", message: "Email không đúng định dạng"},
]

export const validatorEmailOptional: any = [
    {required: false, message: "Vui lòng nhập email"},
    {type: "email", message: "Email không đúng định dạng"},
]

export const phoneRequiredRules = [
    {required: true, message: "Vui lòng nhập số điện thoại"},
    phoneValidator,
];

export const timeValidator: (option: any, message: string) => Rule = (max, message) => {
    return () => ({
        validator(_, value) {
            if (value && (value === max)) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};