import { getAxiosClientWithToken } from './index';
import { CancelTripParams, TripRequest } from '../types/trip';

export const getListTripAPI = (params?: TripRequest) => {
  return getAxiosClientWithToken().get('/trips', { params });
};

export const createTripAPI = (params: any) => {
  const restClient = getAxiosClientWithToken();
  return restClient.post('/trips', params);
};

export const createExtraTripAPI = (params: any) => {
  const restClient = getAxiosClientWithToken();
  return restClient.post('/trips/extra', params);
};

export const departTripAPI = (params: any) => {
  const restClient = getAxiosClientWithToken();
  return restClient.post(`/trips/depart`, params);
};

export const cancelTripAPI = (params: CancelTripParams) => {
  const restClient = getAxiosClientWithToken();
  return restClient.post(`/trips/cancel`, params);
};

export const updateStatusTripAPI = (params: { schedule_id: any; cancel_reason?: string; status: any }) => {
  const restClient = getAxiosClientWithToken();
  return restClient.put(`/trips/status`, {
    ...params,
    status: params?.status === 'not_departed' ? false : true,
  });
};
