import React from "react";
import { IconProps } from "../../types/common";

const IconWarning = ({ className, width, height }: IconProps) => {
  return (
    <svg
      className={className}
      width={width || 24}
      height={height || 24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g id="ph:warning-fill">
        <path
          id="Vector"
          d="M18.5008 14.6946L11.6688 2.82974C11.4981 2.53906 11.2544 2.29803 10.9618 2.13057C10.6692 1.9631 10.3379 1.875 10.0008 1.875C9.66373 1.875 9.33247 1.9631 9.0399 2.13057C8.74732 2.29803 8.50359 2.53906 8.33287 2.82974L1.50084 14.6946C1.33657 14.9757 1.25 15.2955 1.25 15.6211C1.25 15.9468 1.33657 16.2665 1.50084 16.5477C1.66938 16.8401 1.91269 17.0825 2.20581 17.2498C2.49893 17.4172 2.83129 17.5036 3.16881 17.5001H16.8329C17.1701 17.5033 17.5022 17.4168 17.795 17.2494C18.0878 17.0821 18.3309 16.8399 18.4993 16.5477C18.6638 16.2667 18.7506 15.947 18.7509 15.6214C18.7512 15.2957 18.6649 14.9759 18.5008 14.6946ZM9.37584 8.12505C9.37584 7.95929 9.44169 7.80032 9.5589 7.68311C9.67611 7.5659 9.83508 7.50005 10.0008 7.50005C10.1666 7.50005 10.3256 7.5659 10.4428 7.68311C10.56 7.80032 10.6258 7.95929 10.6258 8.12505V11.2501C10.6258 11.4158 10.56 11.5748 10.4428 11.692C10.3256 11.8092 10.1666 11.8751 10.0008 11.8751C9.83508 11.8751 9.67611 11.8092 9.5589 11.692C9.44169 11.5748 9.37584 11.4158 9.37584 11.2501V8.12505ZM10.0008 15.0001C9.81542 15.0001 9.63416 14.9451 9.47999 14.8421C9.32582 14.739 9.20566 14.5926 9.1347 14.4213C9.06374 14.25 9.04518 14.0615 9.08135 13.8797C9.11753 13.6978 9.20681 13.5308 9.33792 13.3996C9.46904 13.2685 9.63608 13.1792 9.81794 13.1431C9.9998 13.1069 10.1883 13.1255 10.3596 13.1964C10.5309 13.2674 10.6773 13.3875 10.7803 13.5417C10.8834 13.6959 10.9383 13.8771 10.9383 14.0626C10.9383 14.3112 10.8396 14.5496 10.6637 14.7255C10.4879 14.9013 10.2495 15.0001 10.0008 15.0001Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default IconWarning;
