import type { MenuDataItem, ProSettings } from '@ant-design/pro-components';
import { PageContainer, ProLayout } from '@ant-design/pro-components';
import {
  Avatar,
  Badge,
  Col,
  Dropdown,
  Flex,
  Image,
  List,
  Menu,
  MenuProps,
  Popover,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import { Content, Footer } from 'antd/es/layout/layout';
import classNames from 'classnames';
import { CSSProperties, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import notifyIcon from '../../assets/images/ic-notification.svg';
import IconOrder from '../../assets/images/icon-header-order.svg';
import logo from '../../assets/images/logo.svg';
import icCircle from '../../assets/images/notify/icCircle2.svg';
import icCheckDouble from '../../assets/images/notify/rg-check-double.svg';
import { Constant, THEME_LIGHT, Toast, USER_GROUP } from '../../config/constant';
import '../../config/i18n/i18n';
import { formatTime } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAccount } from '../../redux/slice/auth/accountSlice';
import { selectAuth, setShowMenu } from '../../redux/slice/authSlice';
import { asyncLogout } from '../../redux/slice/loginSlice';
import { resetToast, toastSelector } from '../../redux/slice/toastNotifySlice';
import route from './_route';

import { getSystemConfigApi } from '../../api/system';
import imgAppStore from '../../assets/images/footer/appstore.png';
import imgGooglePlay from '../../assets/images/footer/googleplay.png';
import avatarDefault from '../../assets/images/header/avatar_default.png';
import icContractList from '../../assets/images/header/icContractList.svg';
import icListTicket from '../../assets/images/header/icListTicket.svg';
import icProfile from '../../assets/images/header/icProfile.svg';
import icTicket from '../../assets/images/header/icTicket.svg';
import icYoutube from '../../assets/images/header/icYoutube.svg';
import icYT from '../../assets/images/header/icYT.svg';
import avatarLogo from '../../assets/images/header/logo_carlink.png';
import icLogout from '../../assets/images/header/sign-out.svg';
import ImgLogo from '../../assets/images/notify/logo.png';
import { PERMISSIONS } from '../../config/permission';
import DetailNotify from '../../pages/notify/DetailNotify';
import EditUser from '../../pages/users/EditUser/EditUser';
import { listBookingSelector, resetDataMoveTicket } from '../../redux/slice/bookings/listBooking';
import { fetchNotification } from '../../redux/slice/notify/notificationSlice';
import { getListTutorials } from '../../redux/slice/system/tutorialsSlice';
import { fetchSystemConfig } from '../../redux/slice/systemConfig/systemConfig';
import { IconWarning } from '../Icons';
import { resetMoveTicket } from '../../redux/slice/bookings/moveTicketSlice';
import { checkLogin } from '../../utils/jwt';
import { getAmountOrder } from '../../redux/slice/orders/ordersPendingSlide';
import { fetchCountRegistrations } from '../../redux/slice/register/countRegistrations';
import ring from '../../assets/audios/sound-school.mp3';

type Props = {
  containerClass?: string;
  contentStyle?: CSSProperties;
};

/**
 * Creates Base Layout
 * @param { Props } props
 * @return html
 * */
const BaseLayout = (props: Props) => {
  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const { isLogin, role, reloadDetail } = useAppSelector(selectAuth);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const [detailUser, setDetailUser] = useState({ ...accountDetail });
  const { message, status, type } = useAppSelector(toastSelector);
  const { dataMoveTicket, isMoveTicket } = useAppSelector(listBookingSelector);
  const countRegistrations = useAppSelector((state) => state?.countRegistrations?.result);

  const { result, isFetching } = useAppSelector((state: any) => state?.listNotification);
  const { timer: timerProcessed } = useAppSelector((state) => state.ordersProcessed);
  const { amount } = useAppSelector((state) => state.ordersPending);
  const { timer: timerTicket } = useAppSelector((state) => state.ordersTicket);
  const { isShowMenu } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { containerClass = '', contentStyle } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [detailModal, setDetailModal] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [timeManage, setTimeManage]: any = useState<any>([]);
  const checkBookTickets = accountDetail?.permissions?.includes(PERMISSIONS?.AGENT?.manager_agent_order_print_ticket);
  const [balanceLimit, setBalanceLimit] = useState<number>(0);
  const valueAvatar = accountDetail?.group === USER_GROUP.CARLINK ? avatarLogo : avatarDefault;
  const prevAmountOrder = useRef(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleGetlistSystemConfig = useCallback(async () => {
    try {
      const response = await getSystemConfigApi();
      if (response?.status === 200) {
        if (accountDetail?.group === USER_GROUP.CAR_COMPANY) {
          setBalanceLimit(response?.data?.data?.warning_balance_company);
        }
        if (accountDetail?.group === USER_GROUP.AGENT) {
          setBalanceLimit(response?.data?.data?.warning_balance_agent);
        }
        if (accountDetail?.group === USER_GROUP.MAIN_AGENT) {
          setBalanceLimit(response?.data?.data?.warning_balance_main_agent);
        }
      }
    } catch (error) {}
  }, [accountDetail?.group]);

  // const handleListRegisterPM = useCallback(() => {
  //   dispatch(fetchListRegister({ query: "" }));
  // }, [dispatch]);

  // useEffect(() => {
  //   handleListRegisterPM();
  // }, []);
  useEffect(() => {
    audioRef.current = new Audio(ring);
    const preloadAudio = () => {
      if (audioRef.current) {
        audioRef.current
          .play()
          .then(() => {
            audioRef.current?.pause();
          })
          .catch((err) => {});
      }
    };

    preloadAudio();

    return () => {
      // Cleanup the audio on component unmount
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const playSound = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => {});
    }
  }, []);
  useEffect(() => {
    dispatch(fetchCountRegistrations());
  }, []);

  const handleGetAmountOrder = useCallback(() => {
    dispatch(getAmountOrder());
  }, [dispatch]);
  useEffect(() => {
    const timerID = setInterval(() => {
      if (
        accountDetail?.group === USER_GROUP.CARLINK ||
        accountDetail.group === USER_GROUP.CAR_COMPANY ||
        accountDetail?.group === USER_GROUP.MAIN_AGENT
      ) {
        handleGetAmountOrder();
      }
    }, Number(systemConfig?.refresh_time ? systemConfig?.refresh_time : 10) * 1000);
    if (!timerProcessed || !timerTicket) {
      clearInterval(timerID);
    }
    return () => clearInterval(timerID);
  }, [handleGetAmountOrder, systemConfig?.refresh_time, timerProcessed, timerTicket, accountDetail?.group]);

  useEffect(() => {
    if (
      accountDetail?.group === USER_GROUP.CARLINK ||
      accountDetail.group === USER_GROUP.CAR_COMPANY ||
      accountDetail?.group === USER_GROUP.MAIN_AGENT
    ) {
      handleGetAmountOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetail?.group]);

  useEffect(() => {
    if (amount) {
      if (amount > prevAmountOrder.current) {
        playSound();
      }
      prevAmountOrder.current = amount;
    }
  }, [amount, playSound]);

  useEffect(() => {
    handleGetlistSystemConfig();
  }, [handleGetlistSystemConfig]);

  const onLogout = () => {
    dispatch(asyncLogout());
  };

  useEffect(() => {
    if (status) {
      if (type === Toast.TYPE_ERROR) {
        toast.error(message, { autoClose: 3000 });
      } else {
        toast.success(message, { autoClose: 3000 });
      }
      dispatch(resetToast());
    }
  }, [dispatch, status]);

  useLayoutEffect(() => {
    if (!isLogin || !checkLogin()) {
      localStorage.clear();
      dispatch(asyncLogout());
      navigate('/login', { replace: true });
    }
  }, [navigate, dispatch, isLogin]);

  useLayoutEffect(() => {
    if ((dataMoveTicket?.ticket_code || isMoveTicket) && window.location.pathname !== '/booking-management/booking') {
      dispatch(resetDataMoveTicket());
      dispatch(resetMoveTicket());
    }
  }, [dispatch, dataMoveTicket, isMoveTicket, window.location.pathname]);

  const handleShowInfoUser = () => {
    setOpenInfo(true);
  };

  const accountActionItems: MenuProps['items'] = [
    {
      key: 'setting',
      label: (
        <div className="header-layout-setting">
          <Image src={icProfile} preview={false} />
          <Typography className="font-text-header" onClick={handleShowInfoUser}>
            Thông tin tài khoản
          </Typography>
        </div>
      ),
    },
    ...(accountDetail.group === USER_GROUP.AGENT || accountDetail.group === USER_GROUP.CAR_COMPANY
      ? [
          {
            key: 'setting',
            label: (
              <div className="header-layout-setting" onClick={() => navigate('/booking-management/booking')}>
                <Image src={icTicket} preview={false} />
                <Typography className="font-text-header">Đặt vé</Typography>
              </div>
            ),
          },
          {
            key: 'setting',
            label: (
              <div className="header-layout-setting" onClick={() => navigate('/booking-management/tickets')}>
                <Image src={icListTicket} preview={false} />
                <Typography className="font-text-header">Danh sách vé đã bán</Typography>
              </div>
            ),
          },
          {
            key: 'setting',
            label: (
              <div className="header-layout-setting" onClick={() => navigate('/booking-management/contract')}>
                <Image src={icContractList} preview={false} />
                <Typography className="font-text-header">Danh sách hợp đồng thuê xe</Typography>
              </div>
            ),
          },
        ]
      : []),
    ...(accountDetail.group === USER_GROUP.MAIN_AGENT
      ? [
          {
            key: 'setting',
            label: (
              <div className="header-layout-setting" onClick={() => navigate('/list-oder-online/pending')}>
                <Image src={icContractList} preview={false} />
                <Typography className="font-text-header">Danh sách đơn hàng online</Typography>
              </div>
            ),
          },
        ]
      : []),
    {
      key: 'logout',
      label: (
        <div className="header-layout-setting">
          <Image src={icLogout} preview={false} />
          <Typography className="font-text-header">Đăng xuất</Typography>
        </div>
      ),
    },
  ];

  const onMenuClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'profile':
        break;
      case 'logout':
        onLogout();
        break;
      default:
        break;
    }
  };

  const settings: ProSettings | undefined = {
    fixSiderbar: true,
    layout: 'mix',
    splitMenus: true,
    navTheme: THEME_LIGHT,
    contentWidth: 'Fluid',
  };
  const handleCollapsedChange = (collapsed: boolean) => {
    setIsCollapsed(true);
    if (isCollapsed || collapsed) dispatch(setShowMenu(collapsed));
  };
  const menuDataRender = (menuList: any[]): MenuDataItem[] => {
    return menuList.map((item) => {
      const localItem = {
        ...item,
        children: item.children ? menuDataRender(item.children) : [],
      };
      let isHidden = false;
      // Check Type is_admin
      if (item?.checkIsAdmin) {
        isHidden = !accountDetail?.is_admin;
      }

      // Check Permissions
      if (item?.authority && role?.length > 0) {
        isHidden = !role?.some((role: string) => item?.authority?.includes(role));
      }
      // Check Group
      if (item?.blockGroup) {
        isHidden = item?.blockGroup?.includes(accountDetail?.group) ? true : isHidden;
      }
      // Display Group
      if (item?.displayGroup) {
        isHidden = item?.displayGroup?.includes(accountDetail?.group) ? false : isHidden;
      }
      // Check Menu Parent
      if (localItem?.children?.length > 0) {
        let hasChildren = localItem?.children?.some((item: any) => !item?.hideInMenu);
        if (localItem?.parentGroup && !hasChildren) {
          hasChildren = localItem?.parentGroup?.includes(accountDetail?.group);
        }
        isHidden = !hasChildren;
      }
      // Check SuperAdmin
      if (item?.onlySuperAdmin) {
        if (!accountDetail?.is_superadmin) {
          isHidden = true;
        }
      }
      if (item?.hasApi) {
        if (!accountDetail?.has_api) {
          isHidden = true;
        }
      }
      return {
        ...localItem,
        hideInMenu: isHidden,
      };
    });
  };

  const showModalEdit = (item: any) => {
    setDetailData(item);
    setDetailModal(true);
  };

  const handleCheckIcNotify = () => {
    if (result?.length > 0) {
      const checkIcNotify = result?.filter((item: any) => item.status === true);
      return checkIcNotify?.length > 0 ? true : false;
    }
    return false;
  };

  let query = `page=1&limit=20`;
  const handleListNotify = () => {
    dispatch(fetchNotification({ query }));
  };

  useEffect(() => {
    handleListNotify();
  }, [query]);

  const handleAccountDetail = () => {
    dispatch(fetchAccount());
  };

  const handleGetListTutorials = useCallback(() => {
    dispatch(getListTutorials());
  }, [dispatch]);

  useEffect(() => {
    handleGetListTutorials();
  }, [handleGetListTutorials]);

  const handleConfigSystem = useCallback(() => {
    dispatch(fetchSystemConfig());
  }, [dispatch]);

  useEffect(() => {
    handleConfigSystem();
  }, [handleConfigSystem]);

  useEffect(() => {
    handleAccountDetail();
  }, []);

  useEffect(() => {
    setDetailUser({ ...accountDetail });
  }, [accountDetail?.id]);

  useEffect(() => {
    if (accountDetail?.id) {
      setTimeManage([
        {
          id: accountDetail?.id,
        },
      ]);
    }
  }, [accountDetail]);

  useEffect(() => {
    handleListNotify();
  }, [query]);

  useEffect(() => {
    let timers: NodeJS.Timeout[] = [];
    const handleUpdateAccount = async (): Promise<void> => {
      await dispatch(fetchAccount());
      await dispatch(fetchNotification({ query }));
      await dispatch(fetchCountRegistrations());
    };
    const setTimer = (index: number): void => {
      const timerId: NodeJS.Timeout = setTimeout(
        () => {
          if (
            !isLogin ||
            window.location.pathname === '/dashboard/info-company' ||
            window.location.pathname === '/dashboard/info-main-agent' ||
            window.location.pathname === '/dashboard/info-agent' ||
            reloadDetail
          ) {
            // Clear all timers
            timers.forEach(clearTimeout);
            return;
          }
          handleUpdateAccount();
          setTimer(index);
        },
        accountDetail?.refresh_header_time ? accountDetail?.refresh_header_time * 1000 : Constant.TIMER_DETAIL,
      );
      timers[index] = timerId;
    };
    for (let i: number = 0; i < timeManage?.length; i++) {
      setTimer(i);
    }
    return (): void => {
      timers.forEach(clearTimeout);
    };
  }, [timeManage, isLogin, dispatch, reloadDetail]);
  const getIsWarning = useCallback(() => {
    if (accountDetail?.group === USER_GROUP.CARLINK) {
      return false;
    }
    if (accountDetail?.group === USER_GROUP.AGENT) {
      return balanceLimit >= Number(accountDetail?.agent?.balance?.replace(/\$\s?|(,*)/g, ''));
    }
    if (accountDetail?.group === USER_GROUP.CAR_COMPANY) {
      return balanceLimit >= Number(accountDetail?.car_company?.balance?.replace(/\$\s?|(,*)/g, ''));
    }
    if (accountDetail?.group === USER_GROUP.MAIN_AGENT) {
      const debt = Math.floor(parseFloat(accountDetail?.main_agent?.debt_amount));
      return Number(accountDetail?.main_agent?.balance?.replace(/\$\s?|(,*)/g, '')) < -(debt - balanceLimit);
    }
  }, [
    accountDetail?.agent?.balance,
    accountDetail?.group,
    balanceLimit,
    accountDetail?.car_company?.balance,
    accountDetail?.main_agent?.debt_amount,
    accountDetail?.main_agent?.balance,
  ]);
  // @ts-ignore
  return (
    <div className="vh-100">
      <ProLayout
        onCollapse={handleCollapsedChange}
        collapsed={isShowMenu}
        className={isShowMenu ? `${THEME_LIGHT}` : `${THEME_LIGHT} not-collapsed`}
        logo={
          <Link style={{ display: 'block' }} to="/">
            <Image src={logo} alt="icon moon" preview={false} />
          </Link>
        }
        title={false}
        {...route}
        breadcrumbProps={undefined}
        breakpoint="xxl"
        menuDataRender={menuDataRender}
        avatarProps={{
          src: detailUser?.avatar ? detailUser?.avatar : valueAvatar,
          size: 'small',
          title: accountDetail?.name,
          render: (props, dom) => {
            return (
              <>
                {accountDetail?.group === USER_GROUP.AGENT && checkBookTickets && (
                  <Col className="header-layout-account-balance" style={{ marginRight: 8 }}>
                    <div className="text-account-balance">{t('Số dư:')}</div>
                    <span className="white text-account-balance">
                      {accountDetail?.agent?.balance ? accountDetail?.agent?.balance : 0} VND
                    </span>
                  </Col>
                )}
                {accountDetail?.group === USER_GROUP.MAIN_AGENT && (
                  <Col className="header-layout-account-balance" style={{ marginRight: 8 }}>
                    <div className="text-account-balance">{t('Số dư:')}</div>
                    <span className="white text-account-balance">
                      {accountDetail?.main_agent?.balance ? accountDetail?.main_agent?.balance : 0} VND
                    </span>
                  </Col>
                )}
                {accountDetail?.group === USER_GROUP.CAR_COMPANY && (
                  <Col className="header-layout-account-balance" style={{ marginRight: 8 }}>
                    <div className="text-account-balance">{t('Số dư:')}</div>
                    <span className="white text-account-balance">
                      {accountDetail?.car_company?.balance ? accountDetail?.car_company?.balance : 0} VND
                    </span>
                  </Col>
                )}

                <Dropdown
                  overlayClassName={THEME_LIGHT === 'light' ? 'header-dropdown-user' : 'header-dropdown-user dark'}
                  arrow
                  overlay={<Menu onClick={onMenuClick} className="rounded-3 px-1" items={accountActionItems} />}
                  trigger={['click']}
                >
                  {dom}
                </Dropdown>
              </>
            );
          },
        }}
        actionsRender={(props) => {
          if (props.isMobile) return [];
          return [
            props.layout !== 'side' && document.body.clientWidth > 960 ? (
              // <Search
              //     placeholder={t('Tìm chuyến')}
              //     className='input-search-home'
              // />
              <></>
            ) : undefined,
            <>
              {(accountDetail?.group === USER_GROUP.CARLINK ||
                accountDetail.group === USER_GROUP.CAR_COMPANY ||
                accountDetail?.group === USER_GROUP.MAIN_AGENT) && (
                <Link to="/list-oder-online/pending" className="header-order position-relative d-flex">
                  <Image src={IconOrder} width={21} alt="gift" preview={false} />
                  {!!amount && (
                    <Flex align="center" justify="center" className="header-order__account position-absolute">
                      {Number(amount) > 99 ? '+99' : amount}
                    </Flex>
                  )}
                </Link>
              )}
              <Popover
                title={<h3 className="title-popover">{t('Thông báo')}</h3>}
                content={
                  <List
                    className="wrapper-popover-notify"
                    loading={isFetching}
                    // bordered
                    // pagination={{
                    //   total: pagination?.total,
                    //   current: page,
                    //   pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
                    //   onChange: onChangePage,
                    //   showSizeChanger: true,
                    // }}
                    itemLayout="horizontal"
                    dataSource={result?.length > 0 ? result : []}
                    renderItem={(item: any) => (
                      <List.Item
                        onClick={() => showModalEdit(item)}
                        className={item?.status ? 'cursor-pointer' : 'list-item-notify'}
                      >
                        <Skeleton avatar title={false} loading={item.loading} active>
                          <List.Item.Meta
                            avatar={<Avatar src={ImgLogo} />}
                            title={
                              <Space className="space-title-notify" size={12}>
                                <Typography className="title-notify">{item?.title}</Typography>
                              </Space>
                            }
                            description={
                              <>
                                <div className="container-description-notify">
                                  <Typography className="text-description">{item?.content} </Typography>
                                  <div className="icon-notify">
                                    {item?.status ? (
                                      <Image src={icCircle} preview={false} />
                                    ) : (
                                      <Image src={icCheckDouble} preview={false} />
                                    )}
                                  </div>
                                </div>
                                <span className="title-time">
                                  {/* {formatTime(
                                    getIsoTimestamp(item?.since_date)
                                  )} */}
                                  {formatTime(item?.created_at)}
                                </span>
                              </>
                            }
                          />
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                }
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
              >
                <Col className="header-gift notify ms-2">
                  <Badge dot={handleCheckIcNotify()} style={{ width: '10px', height: '10px' }}>
                    <Image src={notifyIcon} width={21} alt="gift" preview={false} />
                  </Badge>
                </Col>
              </Popover>
              {/* <a> */}
              <a
                href="https://www.youtube.com/@phanmemnhaxecarlink/videos"
                target="_blank"
                rel="noreferrer"
                className="header-layout-document-yt"
              >
                <Image src={icYoutube} preview={false} />
              </a>

              {/* </a> */}
            </>,
          ];
        }}
        menuFooterRender={(props) => {
          if (props?.collapsed) return undefined;
          return (
            <a
              href="https://www.youtube.com/@phanmemnhaxecarlink/videos"
              target="_blank"
              rel="noreferrer"
              className="footer-layout-document-yt"
            >
              <Image src={icYT} preview={false} />
              <span className="text-document-yt">Tài liệu HDSD phần mềm</span>
            </a>
          );
        }}
        menuItemRender={(menuItemProps, defaultDom) => {
          return menuItemProps?.disable ? (
            <Link
              className={`menu-item__parent menu-item ${menuItemProps?.children ? '' : 'menu-item__disabled'}`}
              to={'#'}
            >
              {defaultDom}
            </Link>
          ) : menuItemProps?.home ? (
            <Link className={`menu-item menu-item__parent`} to={menuItemProps.path || '/'}>
              {defaultDom}
            </Link>
          ) : menuItemProps?.orderCount ? (
            <Link
              className="menu-item menu-item__submenu d-flex align-items-center gap-x-8"
              to={menuItemProps.path || '/'}
            >
              {defaultDom}
              {!!amount && (
                <Flex
                  align="center"
                  justify="center"
                  className="text-white fw-500 fs-12"
                  style={{
                    width: '34px',
                    height: '20px',
                    borderRadius: '4px',
                    backgroundColor: '#D81717',
                  }}
                >
                  {amount > 99 ? '+99' : amount}
                </Flex>
              )}
            </Link>
          ) : menuItemProps?.countRegisterPM ? (
            <Link
              className="menu-item menu-item__submenu d-flex align-items-center gap-x-8"
              to={menuItemProps.path || '/'}
            >
              {defaultDom}
              {!!countRegistrations?.demoregistrasion && (
                <Flex
                  align="center"
                  justify="center"
                  className="text-white fw-500 fs-12"
                  style={{
                    width: '34px',
                    height: '20px',
                    borderRadius: '4px',
                    backgroundColor: '#D81717',
                  }}
                >
                  {countRegistrations?.demoregistrasion > 99 ? '+99' : countRegistrations?.demoregistrasion}
                </Flex>
              )}
            </Link>
          ) : menuItemProps?.countRegisterAD ? (
            <Link
              className="menu-item menu-item__submenu d-flex align-items-center gap-x-8"
              to={menuItemProps.path || '/'}
            >
              {defaultDom}
              {!!countRegistrations?.registerAdversiting && (
                <Flex
                  align="center"
                  justify="center"
                  className="text-white fw-500 fs-12"
                  style={{
                    width: '34px',
                    height: '20px',
                    borderRadius: '4px',
                    backgroundColor: '#D81717',
                  }}
                >
                  {countRegistrations?.registerAdversiting > 99 ? '+99' : countRegistrations?.registerAdversiting}
                </Flex>
              )}
            </Link>
          ) : (
            <Link className={`menu-item menu-item__submenu`} to={menuItemProps.path || '/'}>
              {defaultDom}
            </Link>
          );
        }}
        {...settings}
      >
        <PageContainer className="vh-85">
          {getIsWarning() && (
            <Flex className="warning" align="center">
              <IconWarning width={20} height={20} />
              {accountDetail?.group === USER_GROUP.MAIN_AGENT ? (
                <Typography.Text className="white fs-16 fw-600">Số dư của bạn sắp hết</Typography.Text>
              ) : (
                <Typography.Text className="white fs-16 fw-600">
                  {accountDetail?.agent
                    ? accountDetail?.agent?.balance?.replace(/\$\s?|(,*)/g, '') > 0
                      ? 'Số dư của bạn sắp hết'
                      : 'Số dư của bạn đã hết'
                    : accountDetail?.car_company?.balance?.replace(/\$\s?|(,*)/g, '') > 0
                    ? 'Số dư của bạn sắp hết'
                    : 'Số dư của bạn đã hết'}
                </Typography.Text>
              )}
              <Typography.Text className="white fs-16 fw-500">-</Typography.Text>
              <Typography.Text className="white fs-16 fw-500">
                Hãy nạp thêm tiền để hệ thống không bị gián đoạn!
              </Typography.Text>
              {accountDetail?.group === USER_GROUP.CAR_COMPANY && (
                <Link to="/dashboard/account-deposit-carlink" className="warning__recharge white fs-16 fw-600">
                  Đi đến nạp tiền
                </Link>
              )}
              {accountDetail?.group === USER_GROUP.AGENT && (
                <Link to="/dashboard/account-deposit" className="warning__recharge white fs-16 fw-600">
                  Đi đến nạp tiền
                </Link>
              )}
              {accountDetail?.group === USER_GROUP.MAIN_AGENT && (
                <Link to="/dashboard/account-deposit-main-agent" className="warning__recharge white fs-16 fw-600">
                  Đi đến nạp tiền
                </Link>
              )}
            </Flex>
          )}

          <Content className={classNames('main-container background-white', containerClass)} style={contentStyle}>
            <div className="site-layout-background">
              <Outlet />
            </div>
          </Content>
        </PageContainer>
        <Footer>
          <Row>
            <Col span={16} className="company-name">
              CarLink Joint Stock Company
            </Col>
            <Col span={8} className="d-flex justify-content-end fs-16 fw-400 text-color-gray-757575">
              Version v1.68.12
            </Col>
          </Row>
          <Row className="text-default">
            <Col span={16} className="fs-16 fw-400 text-color-default">
              Địa chỉ: Toà nhà 1-2, Dolphin Plaza, 28 Phố Trần Bình, Mỹ Đình, Nam Từ Liêm, Hà Nội
            </Col>
            <Col span={8} className="d-flex justify-content-end fs-16 fw-400 text-color-gray-757575">
              © 2023 CARLINK. All rights reserved.
            </Col>
          </Row>
          <Row className="footer-logo-app" style={{ marginTop: 20 }}>
            <Col className="fs-16 fw-400 text-color">
              Bạn có thể tải ứng dụng tài xế của chúng tôi tại{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.CarlinkJSC.CarlinkDriver&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                Google Play
              </a>{' '}
              và{' '}
              <a href="https://apps.apple.com/us/app/carlink-driver/id6504156727" target="_blank" rel="noreferrer">
                App Store
              </a>
            </Col>
            <Col xs={24} style={{ display: 'flex', gap: 16, marginTop: 10 }}>
              <a href="https://apps.apple.com/us/app/carlink-driver/id6504156727" target="_blank" rel="noreferrer">
                <Image src={imgAppStore} preview={false} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.CarlinkJSC.CarlinkDriver&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={imgGooglePlay} preview={false} />
              </a>
            </Col>
          </Row>
        </Footer>
      </ProLayout>

      <DetailNotify detailData={detailData} isModalOpen={detailModal} setIsModalOpen={setDetailModal} />
      <>
        {openInfo ? (
          <EditUser
            callListUserApi={handleAccountDetail}
            setOpen={setOpenInfo}
            open={openInfo}
            detailUser={detailUser}
            setDetailUser={setDetailUser}
            infoUser={accountDetail}
          />
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default BaseLayout;
