import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import {
  getAllBusTypeApi,
  getAvailableBusTypeApi,
  getListBusTypeApi,
  getListBusTypeDetailApi,
} from '../../../api/busType';
import { Constant } from '../../../config/constant';
import { BusTypeState, ListBusTypeRequest } from '../../../types/busType';
import { RootState } from '../../store';

// @ts-ignore
export const getListBusType = createAsyncThunk(
  'admin/busType',
  async (params: ListBusTypeRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListBusTypeApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListBusType(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListBusType(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const getAllBusType = createAsyncThunk('admin/allBusType', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response: any = await getAllBusTypeApi();
    const { data, status } = response;
    if (status === Constant.DEFAULT_STATUS) {
      dispatch(setListAllBusType(data));
      return true;
    }
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});
export const getAllAvailableBusType = createAsyncThunk(
  'admin/allAvailableBusType',
  async (params: ListBusTypeRequest, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await getAvailableBusTypeApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListAllAvailableBusType(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const getListBusTypeDetail = createAsyncThunk(
  'admin/listBusTypeDetail',
  async (id: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListBusTypeDetailApi(id);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListBusTypeDetail(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListBusTypeDetail(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const listBusTypeSlice = createSlice({
  name: 'listBusType',
  initialState: {
    error: false,
    loading: false,
    success: false,
    listBusType: [],
    listAllBusType: [],
    pagination: [],
    status: 0,
    message: '',
    listBusTypeDetail: [],
    errorDetail: null,
    listAllAvailableBusType: [],
  } as BusTypeState,
  reducers: {
    setListBusType: (state: BusTypeState, { payload }) => {
      state.listBusType = payload?.data;
      state.pagination = payload?.pagination;
    },
    setListBusTypeDetail: (state: BusTypeState, { payload }) => {
      state.listBusTypeDetail = payload?.data;
    },
    setListBusTypeLoad: (state: BusTypeState) => {
      state.listBusType = [];
    },
    setListAllBusType: (state: BusTypeState, { payload }) => {
      state.listAllBusType = payload?.data;
    },
    setListAllAvailableBusType: (state: BusTypeState, { payload }) => {
      state.listAllAvailableBusType = payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListBusType.pending, (state: BusTypeState) => {
      state.loading = true;
    });
    builder.addCase(getListBusType.fulfilled, (state: BusTypeState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListBusType.rejected, (state: BusTypeState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getListBusTypeDetail.pending, (state: BusTypeState) => {
      state.loading = true;
    });
    builder.addCase(getListBusTypeDetail.fulfilled, (state: BusTypeState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListBusTypeDetail.rejected, (state: BusTypeState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const listBusTypeSelector = (state: RootState) => state.listBusType;
export const {
  setListBusType,
  setListBusTypeDetail,
  setListBusTypeLoad,
  setListAllBusType,
  setListAllAvailableBusType,
} = listBusTypeSlice.actions;
