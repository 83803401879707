import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { createApiAPI, editApiAPI } from '../../../api/followApi';
import { Toast } from '../../../config/constant';
import { BusTypeState, ListBusTypeState } from '../../../types/busType';
import { CreateApiParams } from '../../../types/follow';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

// @ts-ignore
export const createApi = createAsyncThunk(
  'admin/create/createAPI',
  async (params: CreateApiParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await createApiAPI(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
        dispatch(
          setToast({
            message: 'Tạo API thành công',
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: response?.message?.data ?? 'Tạo API thất bại',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const updateApi = createAsyncThunk(
  'admin/create/updateAPI',
  async (params: CreateApiParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await editApiAPI(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
        dispatch(
          setToast({
            message: 'Cập nhật API thành công',
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: response?.message?.data ?? 'Cập nhật API thất bại',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const createApiSlice = createSlice({
  name: 'createAPI',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as BusTypeState,
  reducers: {
    setCreate: (state: ListBusTypeState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setCreateError: (state: ListBusTypeState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetCreateApi: (state: ListBusTypeState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createApi.pending, (state: BusTypeState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createApi.fulfilled, (state: BusTypeState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(createApi.rejected, (state: BusTypeState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const createApiSelector = (state: RootState) => state.createApi;
export const { setCreate, setCreateError, resetCreateApi } = createApiSlice.actions;
