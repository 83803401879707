import { getAxiosClient, getAxiosClientWithToken } from "./index";
import HttpService from "../utils/http";
import { LoginRequest } from "../redux/slice/loginSlice";

export const login = (params: LoginRequest) => {
  return getAxiosClient.post('login', params);
  // return HttpService.post("/login", params);
};

export const logoutApi = () => {
  return getAxiosClientWithToken().post("logout");
  // return HttpService.post("/logout", {});
};

export const getUserData = () => {
  return getAxiosClientWithToken().get("admin/profile");
};

export const refreshTokenAPI = () => {
  return getAxiosClientWithToken(true).post("refresh");
};
