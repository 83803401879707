export const validateContentPolicy = [
  { required: true, message: "Vui lòng nhập nội dung chính sách" },
];

export const validateNamePolicy = [
  { required: true, message: "Vui lòng nhập tên chính sách" },
];

export const validateInsurancePolicy = [
  {
    required: true,
    message: "Vui lòng nhập giá trị bảo hiểm",
  },
  {
    validator: (_: any, value: any) => {
      if (value && (isNaN(value) || value > 100)) {
        return Promise.reject(new Error("Giá trị không được vượt quá 100"));
      }
      return Promise.resolve();
    },
  },
];

export const validateTimePolicy = [
  { required: true, message: "Vui lòng nhập giờ khởi hành" },
];

export const validatePricePolicy = [
  { required: true, message: "Vui lòng nhập phí hủy" },
  {
    validator: (_: any, value: any) => {
      if (value && (isNaN(value) || value > 100)) {
        return Promise.reject(new Error("Giá trị không được vượt quá 100"));
      }
      return Promise.resolve();
    },
  },
];
