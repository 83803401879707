import { ListDistrictParams, ListWardParams } from '../types/province';
import { getAxiosClientWithToken } from './index';

export const getListProvincesAPI = () => {
  return getAxiosClientWithToken().get('/area/provinces');
};

export const getListDistrictAPI = (params: ListDistrictParams) => {
  return getAxiosClientWithToken().get('/area/districts', { params });
};
export const getListWardAPI = (params: ListWardParams) => {
  return getAxiosClientWithToken().get('/area/wards', { params });
};
