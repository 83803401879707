import { getAxiosClientWithToken } from "../../../api";
import { CancelTicketParams, HistoryTicketParams, OrderDetailParams, ParamsGetListOrder } from "../../../types/order";

export const getListOrdersAPI = (params?: ParamsGetListOrder) => {
    return getAxiosClientWithToken().get('/orders', { params });
};

export const exportAllTicketsAPI = (booking_code: string) => {
    return getAxiosClientWithToken().post('/orders/confirm-all', { booking_code: booking_code });
};

export const exportTicketAPI = (ticket_code: string) => {
    return getAxiosClientWithToken().post('/orders/confirm', { ticket_code: ticket_code });
};

export const cancelAllTicketsAPI = (params: CancelTicketParams) => {
    return getAxiosClientWithToken().post('/orders/cancel-all', { booking_code: params?.booking_code, cancel_note: params?.cancel_note });
};

export const cancelTicketAPI = (params: CancelTicketParams) => {
    return getAxiosClientWithToken().post('/orders/cancel', { ticket_code: params?.ticket_code, cancel_note: params?.cancel_note });
};

export const historyTicketAPI = (params: HistoryTicketParams) => {
    return getAxiosClientWithToken().get('/orders/history', { params });
};

export const historyOrderExcelAPI = (params: HistoryTicketParams) => {
    return getAxiosClientWithToken().get('/orders/history/all', { params });
};

export const getListStaffAPI = () => {
    return getAxiosClientWithToken().get('/orders/list-staff');
};

export const getAmountOrderAPI = () => {
    return getAxiosClientWithToken().get(`/count/orders`);
};

export const getOrderDetailAPI = (params: OrderDetailParams) => {
    return getAxiosClientWithToken().get(`/orders/detail`, { params });
};