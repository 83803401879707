/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Card, Col, Flex, Image, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";
import icArrowLeft from "../../../assets/images/home/arrowLeft.svg";
import {
  getListDashboardAgent,
  getListDashboardCompany,
  getListDashboardRoute,
  getListDashboardUser,
} from "../../../api/dashboard";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant, USER_GROUP } from "../../../config/constant";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { checkPermissionManagerAccount, formatTime } from "../../../lib/utils";
import TextDisplay from "../../../components/Display/TextDisplay";

type ColumnsType<T> = TableProps<T>["columns"];

interface DataType {
  name: {
    first: string;
    last: string;
  };
  gender: string;
  email: string;
  login: {
    uuid: string;
  };
}

const columnsCarCompany: ColumnsType<DataType> = [
  // {
  //   title: "Tên nhà xe",
  //   dataIndex: "name",
  // },
  {
    title: "Tên nhà xe",
    dataIndex: "name",
    render: (name, record: any) => (
      <Flex className="Order__column__booking-code" align="center">
        <Flex className="flex-column">
          <Typography.Text className="fs-16 text-color fw-500">
            <TextDisplay text={name ? name : "N/A"} />
          </Typography.Text>
          <Typography.Text className="fs-14 text-color-green-default fw-500">
            {formatTime(record?.created_at)}
          </Typography.Text>
        </Flex>
      </Flex>
    ),
    // sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
  },
  {
    title: "Số điện thoại",
    dataIndex: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
    render: (text) => <Typography className="text-color">{text}</Typography>,
  },
  // {
  //   title: "",
  //   dataIndex: "is_new",
  //   render: (text) => {
  //     return <>{text && <Image src={icRealTime} preview={false} />}</>;
  //   },
  // },
];

const columnsAgent: ColumnsType<DataType> = [
  // {
  //   title: "Tên đại lý",
  //   dataIndex: "name",
  // },
  {
    title: "Tên đại lý",
    dataIndex: "name",
    render: (name, record: any) => (
      <Flex className="Order__column__booking-code" align="center">
        <Flex className="flex-column">
          <Typography.Text className="fs-16 text-color fw-500">
            <TextDisplay text={name ? name : "N/A"} />
          </Typography.Text>
          <Typography.Text className="fs-14 text-color-green-default fw-500">
            {formatTime(record?.created_at)}
          </Typography.Text>
        </Flex>
      </Flex>
    ),
    // sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
  },
  {
    title: "Thuộc nhà xe",
    dataIndex: "company",
  },
  {
    title: "Chiết khấu",
    dataIndex: "commission",
    render: (text) => <Typography className="text-color">{text}%</Typography>,
  },
  // {
  //   title: "",
  //   dataIndex: "is_new",
  //   render: (text) => {
  //     return <>{text && <Image src={icRealTime} preview={false} />}</>;
  //   },
  // },
];
const columnsRoute: ColumnsType<DataType> = [
  // {
  //   title: "Tuyến",
  //   dataIndex: "name",
  //   render: (text, record: any) => (
  //     <Typography className="text-color">
  //       {record?.depart_point} - {record?.arrive_point}
  //     </Typography>
  //   ),
  // },
  {
    title: "Tuyến",
    dataIndex: "name",
    render: (name, record: any) => (
      <Flex className="Order__column__booking-code" align="center">
        <Flex className="flex-column">
          <Typography.Text className="fs-16 text-color fw-500">
            <TextDisplay
              text={`${record?.depart_point} - ${record?.arrive_point}`}
            />
          </Typography.Text>
          <Typography.Text className="fs-14 text-color-green-default fw-500">
            {formatTime(record?.created_at)}
          </Typography.Text>
        </Flex>
      </Flex>
    ),
    // sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
  },
  {
    title: "Nhà xe",
    dataIndex: "company",
  },
  {
    title: "Giá tiền (VND)",
    dataIndex: "price",
    render: (text) => (
      <Typography style={{ color: "#3AB54A" }}>
        {text?.min_price} - {text?.max_price}
      </Typography>
    ),
  },
  // {
  //   title: "",
  //   dataIndex: "is_new",
  //   render: (text) => {
  //     return <>{text && <Image src={icRealTime} preview={false} />}</>;
  //   },
  // },
];

const Carlink = () => {
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const managerAgentViewing = accountDetail?.permissions?.includes(
    "manager_agent_viewing"
  );

  const navigate = useNavigate();
  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? DEFAULT_PAGE_SIZE
    : Number(limit_pagination);

  // vé đặt mới
  const [dataUser, setDataUser] = useState<DataType[]>([]);
  const [limitUser, setLimitUser] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const [loadingUser, setLoadingUser] = useState(false);
  const [paginationUser, setPaginationUser] = useState<any>();
  const [pageUser, setPageUser] = useState<number>(1);

  // nhà xe mới gia nhập
  const [dataCompany, setDataCompany] = useState<DataType[]>([]);
  const [limitCompany, setLimitCompany] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [paginationCompany, setPaginationCompany] = useState<any>();
  const [pageCompany, setPageCompany] = useState<number>(1);

  // đại lý mới
  const [dataAgent, setDataAgent] = useState<DataType[]>([]);
  const [limitAgent, setLimitAgent] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [paginationAgent, setPaginationAgent] = useState<any>();
  const [pageAgent, setPageAgent] = useState<number>(1);

  // tuyến mới tạo
  const [dataRoute, setDataRoute] = useState<DataType[]>([]);
  const [limitRoute, setLimitRoute] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [paginationRoute, setPaginationRoute] = useState<any>();
  const [pageRoute, setPageRoute] = useState<number>(1);

  const columnsUser: ColumnsType<DataType> = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    // },
    {
      title: "STT",
      key: "id",

      render: (_: any, record: any, index: number) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <Typography className="text-color">
              {(pageUser - 1) * limitUser + index + 1}
            </Typography>
          </div>
        );
      },
    },
    {
      title: "Tên hiển thị",
      dataIndex: "name",
      render: (name, record: any) => (
        <Flex className="Order__column__booking-code" align="center">
          <Flex className="flex-column">
            <Typography.Text className="fs-16 text-color fw-500">
              <TextDisplay text={name ? name : "N/A"} />
            </Typography.Text>
            <Typography.Text className="fs-14 text-color-green-default fw-500">
              {formatTime(record?.created_at)}
            </Typography.Text>
          </Flex>
        </Flex>
      ),
      // sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
    },
    // {
    //   title: "Tên hiển thị",
    //   dataIndex: "name",
    // },
    {
      title: "Loại tài khoản",
      dataIndex: "group",
      render: (text) => {
        let textGroup;
        if (text === USER_GROUP.CARLINK) {
          textGroup = "Nội bộ Carlink";
        }
        if (text === USER_GROUP.CAR_COMPANY) {
          textGroup = "Nhà xe";
        }
        if (text === USER_GROUP.AGENT) {
          textGroup = "Đại lý";
        }
        return <Typography className="text-color">{textGroup}</Typography>;
      },
    },
    {
      title: "Văn phòng",
      dataIndex: "creator",
    },
    // {
    //   title: "",
    //   dataIndex: "is_new",
    //   render: (text) => {
    //     return <>{text && <Image src={icRealTime} preview={false} />}</>;
    //   },
    // },
  ];

  const handleNavigateUser = () => {
    if (checkPermissionManagerAccount(accountDetail)) {
      navigate("/user/list");
    } else {
    }
  };

  const onChangePageUser = (page: number, pageSize: number) => {
    if (paginationUser?.per_page !== pageSize) {
      setPageUser(Constant.DEFAULT_PAGE);
      setLimitUser(pageSize);
      return;
    }
    setPageUser(page);
  };

  const onChangePageCompany = (page: number, pageSize: number) => {
    if (paginationCompany?.per_page !== pageSize) {
      setPageCompany(Constant.DEFAULT_PAGE);
      setLimitCompany(pageSize);
      return;
    }
    setPageCompany(page);
  };

  const onChangePageAgent = (page: number, pageSize: number) => {
    if (paginationAgent?.per_page !== pageSize) {
      setPageAgent(Constant.DEFAULT_PAGE);
      setLimitAgent(pageSize);
      return;
    }
    setPageAgent(page);
  };

  const onChangePageRoute = (page: number, pageSize: number) => {
    if (paginationRoute?.per_page !== pageSize) {
      setPageRoute(Constant.DEFAULT_PAGE);
      setLimitRoute(pageSize);
      return;
    }
    setPageRoute(page);
  };

  let queryUser = `page=${pageUser}&limit=${limitUser}`;
  const handleListDashboardUser = async () => {
    setLoadingUser(true);
    try {
      const res: any = await getListDashboardUser(queryUser);
      if (res.status === 200) {
        setDataUser(res?.data?.data);
        const { current_page, total, per_page } = res?.data;
        setPaginationUser({ current_page, total, per_page });
      }
    } catch (error) {}
    setLoadingUser(false);
  };

  let queryCompany = `page=${pageCompany}&limit=${limitCompany}`;
  const handleListDashboardCompany = async () => {
    setLoadingCompany(true);
    try {
      const res: any = await getListDashboardCompany(queryCompany);
      if (res.status === 200) {
        setDataCompany(res?.data?.data);
        const { current_page, total, per_page } = res?.data;
        setPaginationCompany({ current_page, total, per_page });
      }
    } catch (error) {}
    setLoadingCompany(false);
  };

  let queryAgent = `page=${pageAgent}&limit=${limitAgent}`;
  const handleListDashboardAgent = async () => {
    setLoadingAgent(true);
    try {
      const res: any = await getListDashboardAgent(queryAgent);
      if (res.status === 200) {
        setDataAgent(res?.data?.data);
        const { current_page, total, per_page } = res?.data;
        setPaginationAgent({ current_page, total, per_page });
      }
    } catch (error) {
      // checkAuthorization(error)
    }
    setLoadingAgent(false);
  };

  let queryRoute = `page=${pageRoute}&limit=${limitRoute}`;
  const handleListDashboardRoute = async () => {
    setLoadingRoute(true);
    try {
      const res: any = await getListDashboardRoute(queryRoute);
      if (res.status === 200) {
        setDataRoute(res?.data?.data);
        const { current_page, total, per_page } = res?.data;
        setPaginationRoute({ current_page, total, per_page });
      }
    } catch (error) {
      // checkAuthorization(error)
    }
    setLoadingRoute(false);
  };

  useEffect(() => {
    handleListDashboardUser();
  }, [queryUser]);

  useEffect(() => {
    handleListDashboardCompany();
  }, [queryCompany]);

  useEffect(() => {
    if (managerAgentViewing) {
      handleListDashboardAgent();
    }
  }, [queryAgent]);

  useEffect(() => {
    handleListDashboardRoute();
  }, [queryRoute]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <Row gutter={[16, 16]}>
            <Col md={24}>
              <Card className="wrapper-page-home">
                <Row className="header-home">
                  <Col className="text-header-left">Nhà xe mới gia nhập</Col>
                  <Col
                    className="text-header-right"
                    onClick={() => navigate("/agency-management/bus-company")}
                  >
                    Danh sách nhà xe <Image src={icArrowLeft} preview={false} />
                  </Col>
                </Row>
                <Table
                  columns={columnsCarCompany}
                  rowKey={(record: any) => record?.id}
                  dataSource={dataCompany?.length > 0 ? dataCompany : []}
                  pagination={{
                    total: paginationCompany?.total,
                    current: pageCompany,
                    pageSize: paginationCompany?.per_page ?? limitPagination,
                    position: ["bottomRight"],
                    onChange: onChangePageCompany,
                    showSizeChanger: true,
                  }}
                  loading={loadingCompany}
                />
              </Card>
            </Col>
            <Col md={24}>
              <Card className="wrapper-page-home">
                <Row className="header-home">
                  <Col className="text-header-left">Tài khoản mới tạo</Col>
                  <Col
                    className="text-header-right"
                    onClick={handleNavigateUser}
                  >
                    Quản lý tài khoản{" "}
                    <Image src={icArrowLeft} preview={false} />
                  </Col>
                </Row>
                <Table
                  columns={columnsUser}
                  rowKey={(record: any) => record?.id}
                  dataSource={dataUser?.length > 0 ? dataUser : []}
                  pagination={{
                    total: paginationUser?.total,
                    current: pageUser,
                    pageSize: paginationUser?.per_page ?? limitPagination,
                    position: ["bottomRight"],
                    onChange: onChangePageUser,
                    showSizeChanger: true,
                  }}
                  loading={loadingUser}
                />
              </Card>
            </Col>
         
          </Row>
        </Col>
        <Col md={12}>
          <Row gutter={[16, 16]}>
          {managerAgentViewing && (
              <Col md={24}>
                <Card className="wrapper-page-home">
                  <Row className="header-home">
                    <Col className="text-header-left">Đại lý mới</Col>
                    <Col
                      className="text-header-right"
                      onClick={() => navigate("/agency-management/agent")}
                    >
                      Danh sách đại lý{" "}
                      <Image src={icArrowLeft} preview={false} />
                    </Col>
                  </Row>
                  <Table
                    columns={columnsAgent}
                    rowKey={(record: any) => record?.id}
                    dataSource={dataAgent?.length > 0 ? dataAgent : []}
                    pagination={{
                      total: paginationAgent?.total,
                      current: pageAgent,
                      pageSize: paginationAgent?.per_page ?? limitPagination,
                      position: ["bottomRight"],
                      onChange: onChangePageAgent,
                      showSizeChanger: true,
                    }}
                    loading={loadingAgent}
                  />
                </Card>
              </Col>
            )}
            
            <Col md={24}>
              <Card className="wrapper-page-home">
                <Row className="header-home">
                  <Col className="text-header-left">Tuyến mới tạo</Col>
                </Row>
                <Table
                  columns={columnsRoute}
                  rowKey={(record: any) => record?.id}
                  dataSource={dataRoute?.length > 0 ? dataRoute : []}
                  pagination={{
                    total: paginationRoute?.total,
                    current: pageRoute,
                    pageSize: paginationRoute?.per_page ?? limitPagination,
                    position: ["bottomRight"],
                    onChange: onChangePageRoute,
                    showSizeChanger: true,
                  }}
                  loading={loadingRoute}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Carlink;
