import { Button, Modal, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { formattedPrice } from '../../lib/utils';

const ModalInfoInsurance: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  listBooking,
  dataPolicy,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      centered
      width={632}
      footer={[]}
      className="modal-confirm-block"
    >
      <Typography className="fs-18 text-black fw-600">
        Chính sách bảo hiểm <span className="fs-14 text-blue fw-500">(Bảo hiểm đã mua sẽ không hỗ trợ hoàn trả)</span>
      </Typography>
      <div className="d-flex header-info-insurance mt-3">
        <Typography className="pl-7 item-value-insurance text-blue fw-500">Giá trị bảo hiểm:</Typography>
        {listBooking?.policy?.insurance_policy?.price || dataPolicy?.price ? (
          <>
            <Typography className="text-green">
              {listBooking?.policy?.insurance_policy?.price
                ? formattedPrice(listBooking?.policy?.insurance_policy?.price)
                : formattedPrice(dataPolicy?.price)}
            </Typography>
            <Typography>đ / ghế</Typography>
          </>
        ) : (
          <>
            <Typography className="text-green">
              {listBooking?.policy?.insurance_policy?.percent
                ? listBooking?.policy?.insurance_policy?.percent
                : dataPolicy?.percent}
            </Typography>
            <Typography>% / ghế</Typography>
          </>
        )}
      </div>
      <div
        className='"d-flex content-info-insurance mt-3'
        dangerouslySetInnerHTML={{
          __html: listBooking?.policy?.insurance_policy?.content
            ? listBooking?.policy?.insurance_policy?.content
            : dataPolicy?.content,
        }}
      ></div>
      <Row className="d-flex justify-content-end mt-3">
        <>
          <Button key="cancel" className=" w-160 h-btn-48 me-3 mr-0" onClick={() => modalHandleCancel()}>
            Đóng
          </Button>
        </>
      </Row>
    </Modal>
  );
};

export default ModalInfoInsurance;
