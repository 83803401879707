import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { updateDriverBookingApi } from '../../../api/booking';
import { Toast } from '../../../config/constant';
import { Action, UPDATE_ERROR, UPDATE_SUCCESS } from '../../../config/message';
import { BookingState, ParamsDriverBooking } from '../../../types/booking';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

export const updateDriverBooking = createAsyncThunk(
  'admin/bookings/update-driver',
  async (params: ParamsDriverBooking, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateDriverBookingApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdateDriver({ status: status, data: data }));
        dispatch(
          setToast({
            message: UPDATE_SUCCESS(Action.BOOKING_DRIVER),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      dispatch(
        setToast({
          message: UPDATE_ERROR(Action.BOOKING_DRIVER),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const updateDriverBookingSlice = createSlice({
  name: 'updateDriverBooking',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as BookingState,
  reducers: {
    setUpdateDriver: (state: BookingState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setUpdateDriverError: (state: BookingState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetDriverBooking: (state: BookingState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateDriverBooking.pending, (state: BookingState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(updateDriverBooking.fulfilled, (state: BookingState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(updateDriverBooking.rejected, (state: BookingState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const updateDriverBookingSelector = (state: RootState) => state.driverBooking;
export const { setUpdateDriver, setUpdateDriverError, resetDriverBooking } = updateDriverBookingSlice.actions;
