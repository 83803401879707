import {getAxiosClientWithToken} from "./index";
import {handleFormData} from "../lib/utils";

import { IDPickupLocation, ListPickupLocationRequest, UpdatePickupLocation, PickupLocationParams, ParamsLocationTransfer } from "../types/pickupLocations";

export const getListPickupLocationsAPI = (params: ListPickupLocationRequest) => {
    return getAxiosClientWithToken().get('pickup-locations', {params});
};

export const getListPickupLocationsApi = (params: ListPickupLocationRequest) => {
    return getAxiosClientWithToken().get(`pickup-locations?${params}`);
};

export const getListProvinceWithPickupLocationsAPI = () => {
    return getAxiosClientWithToken().get('pickup-locations/provinces');
};

export const getPickupLocationAPI = (params: IDPickupLocation) => {
    return getAxiosClientWithToken().get(`pickup-locations/${params.id}`);
};

export const createPickupLocationAPI = (params: PickupLocationParams) => {
    const formData = handleFormData(params);
    return getAxiosClientWithToken().post(`pickup-locations`, formData);
};

export const updatePickupLocationAPI = (id: IDPickupLocation, params: UpdatePickupLocation) => {
    return getAxiosClientWithToken().put(`pickup-locations/${id}`,params);
};

export const updateStatusPickupLocationAPI = (id: IDPickupLocation, status: boolean | undefined) => {
    // const formData = handleFormData(params);
    return getAxiosClientWithToken().put(`pickup-locations/status/${id}`, {status: status});
};

export const deletePickupLocationAPI = (id: string | number) => {
    return getAxiosClientWithToken().delete(`pickup-locations/${id}`);
};

export const getLocationTransferAPI = (params:ParamsLocationTransfer)=>{
   return getAxiosClientWithToken().get(`pickup-locations/transfer`,{params})
}