import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Image, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import iconAgent from '../../../assets/images/ic-agent-2.svg';
import iconCarGroup from '../../../assets/images/ic-house-car.png';
import iconDriver from '../../../assets/images/ic-volang2.svg';
import '../../../assets/styles/desktop/busBooking.scss';
import { typeSeatItems } from '../../../components/BusTypeMatrix';
import { reasonOptions } from '../../../components/Modal/ModalCancelTicket';
import { DateFormat } from '../../../config/constant';
import { formattedPrice, getPaymentMethod } from '../../../lib/utils';
import { useAppDispatch } from '../../../redux/hooks';
import { getListBookingDetail } from '../../../redux/slice/bookings/listBooking';
import ModalHistoryBooking from '../ModalHistoryBooking';

function ListTicketCancel({ listBooking, listBookingDetail, refs }: any) {
  const dispatch = useAppDispatch();
  const [openModalHistory, setOpenModalHistory] = useState(false);

  const cancelModalHistory = () => {
    setOpenModalHistory(false);
  };
  const handleHistoryCancel = (ticket_code: string, seat_number: string) => {
    setOpenModalHistory(true);
    dispatch(getListBookingDetail({ seat_number: seat_number, ticket_code: ticket_code }));
  };
  return (
    <div
      ref={(el) => {
        refs.current['cancel'] = el;
      }}
    >
      {listBooking?.schedule?.id && listBooking?.cancel_booking?.length > 0 && (
        <Typography className="fw-600 title-card mt-3"> Vé đã hủy</Typography>
      )}
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
        {listBooking?.cancel_booking?.map(
          (seatEntry: any, entryIndex: number) =>
            seatEntry?.customer && (
              <Row key={entryIndex} gutter={[12, 12]} className="mt-2 w-100">
                <Col key={entryIndex} className="w-100">
                  <Card
                    key={entryIndex}
                    className={`mt-1 ant-card-diagram driver`}
                    cover={
                      <div className={`bus-diagram-card`}>
                        <div className="header-card-diagram">
                          <Typography className="fw-600 title-card">
                            <Image
                              src={
                                typeSeatItems?.find((item: any) => {
                                  const matchingSeats = listBooking?.schedule.bus_type.seat_map.floor1
                                    .concat(listBooking?.schedule.bus_type.seat_map.floor2)
                                    .flat()
                                    .filter((seat: any) => seat.name === seatEntry?.seat_number);

                                  return matchingSeats.length > 0 && +item?.id === +matchingSeats[0]?.type;
                                })?.image
                              }
                              alt=""
                              height={32}
                              preview={false}
                              className={`img-type-seat`}
                            />
                            <p className={`seat-name-booking ${seatEntry?.ticket_code ? 'ticket' : ''}`}>
                              {seatEntry?.seat_number}
                            </p>
                            <span className="seat-name-booking">{seatEntry?.ticket_code}</span>
                          </Typography>
                          <div style={{ display: 'block', textAlign: 'end' }}>
                            <Typography className="fw-500 fs-14">
                              {seatEntry?.orderer?.name === 'BusBooking' ? 'Carlink API' : seatEntry?.orderer?.name}
                            </Typography>
                            <Typography className="fw-500 fs-14 reason-ticket">
                              {reasonOptions?.find((item: any) => item?.value === seatEntry?.cancel_reason)?.label}
                            </Typography>
                          </div>
                        </div>
                        <Typography className="fw-600 mt-1">{seatEntry?.customer?.name}</Typography>
                        <Typography className="fw-500">
                          {seatEntry?.orderer?.name === 'BusBooking'
                            ? 'Số điện thoại: N/A'
                            : seatEntry?.customer?.phone}
                        </Typography>
                        <div className="address-bus fw-500 fs-14">
                          <Typography className="fs-14">
                            {seatEntry?.pickup_location?.location_name
                              ? `${
                                  seatEntry?.pickup_location?.location_name
                                } (${seatEntry?.pickup_location?.time?.slice(0, 5)}${
                                  (seatEntry?.pickup_location?.diff_day || seatEntry?.drop_off_location?.diff_day) > 0
                                    ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                        ?.add(seatEntry?.pickup_location?.diff_day, 'day')
                                        ?.format(DateFormat)}`
                                    : ''
                                })`
                              : seatEntry?.pickup_extra}
                          </Typography>
                          <Typography className="fs-14">
                            {seatEntry?.drop_off_location?.location_name
                              ? `${
                                  seatEntry?.drop_off_location?.location_name
                                } (${seatEntry?.drop_off_location?.time?.slice(0, 5)}${
                                  (seatEntry?.pickup_location?.diff_day || seatEntry?.drop_off_location?.diff_day) > 0
                                    ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                        ?.add(seatEntry?.drop_off_location?.diff_day, 'day')
                                        ?.format(DateFormat)}`
                                    : ''
                                })`
                              : seatEntry?.drop_off_extra}
                          </Typography>
                        </div>
                        <div>
                          <Typography className="fw-500 fs-14 ">{`${
                            seatEntry?.cancel_note ? `*${seatEntry?.cancel_note}` : ''
                          }`}</Typography>
                        </div>
                        {!(seatEntry?.orderer?.name === 'BusBooking') ? (
                          <div className="price-item" style={{ paddingBottom: '12px' }}>
                            {seatEntry &&
                              seatEntry?.payment_method !== 'reserve' &&
                              seatEntry?.payment_method !== 'paid_at_company' && (
                                <>
                                  {seatEntry?.is_driver_pay ? (
                                    <div
                                      className="d-flex"
                                      style={{ justifyContent: 'center', alignItems: 'center', columnGap: '4px' }}
                                    >
                                      <img src={iconDriver} alt="" width={12} />
                                      <Typography className="fw-500 fs-12">
                                        {`Tài xế đã thu bằng ${getPaymentMethod(seatEntry?.payment_method)} ${
                                          seatEntry?.commission_price ? formattedPrice(seatEntry?.commission_price) : 0
                                        }VNĐ `}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <>
                                      {(seatEntry?.orderer?.agent?.id || seatEntry?.payer) &&
                                      !listBooking?.contract?.id ? (
                                        <div
                                          className="d-flex"
                                          style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            columnGap: '4px',
                                          }}
                                        >
                                          <img
                                            src={seatEntry?.payer?.agent?.id ? iconAgent : iconCarGroup}
                                            alt=""
                                            width={12}
                                          />
                                          <Typography className="fw-500 fs-12">
                                            {`${
                                              seatEntry?.payer?.agent?.name
                                                ? seatEntry?.payer?.agent?.name
                                                : seatEntry?.payer?.name
                                            } ${
                                              seatEntry?.payer?.agent?.id ? 'đã thanh toán bằng ' : 'đã thu '
                                            } ${getPaymentMethod(seatEntry?.payment_method)} ${
                                              seatEntry?.price_change
                                                ? seatEntry?.price_change
                                                : (seatEntry?.payer?.agent?.id
                                                ? formattedPrice(seatEntry?.commission_price)
                                                : formattedPrice(seatEntry?.price))
                                            }VNĐ`}
                                          </Typography>
                                        </div>
                                      ) : (
                                        <Typography
                                          className="fw-500 fs-12 d-flex"
                                          style={{
                                            maxWidth: '400px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            columnGap: '4px',
                                          }}
                                        >
                                          <img
                                            src={seatEntry?.payer?.agent?.id ? iconAgent : iconCarGroup}
                                            width={12}
                                            alt=""
                                          />
                                          {`${seatEntry?.orderer?.name} ${
                                            seatEntry?.payer?.agent?.id ? 'đã thanh toán bằng' : 'đã thu'
                                          } ${getPaymentMethod(seatEntry?.payment_method)} ${
                                            formattedPrice(seatEntry?.commission_price) ?? 0
                                          }VNĐ ${
                                            seatEntry?.payment_method === 'cash' ? ' tiền mặt' : ' chuyển khoản'
                                          } tại ${seatEntry?.orderer?.agent?.id ? `đại lý` : `nhà xe `}`}
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="btn-diagram-block">
                          <Button
                            className="btn-diagram"
                            onClick={() => handleHistoryCancel(seatEntry?.ticket_code, seatEntry?.seat_number)}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        </div>
                      </div>
                    }
                  />
                </Col>
              </Row>
            ),
        )}
        <ModalHistoryBooking
          modalOpen={openModalHistory}
          onOk={() => setOpenModalHistory(false)}
          onCancel={cancelModalHistory}
          listBooking={listBooking}
          historyDetail={listBookingDetail}
          isCancel={true}
        />
      </div>
    </div>
  );
}
export default ListTicketCancel;
