import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Image, Modal, Row, Typography } from 'antd';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';
import { modalProps } from '../../types';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/desktop/busBooking.scss'

const ModalCancelSchedule: React.FC<modalProps> = ({ okText, cancelText, modalTitle, modalOpen, onCancel, onOk }) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const { t } = useTranslation();

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
    >
      <Flex className="mt-3 mb-1">
        <Image preview={false} width={20} src={IconExclamationCircle} alt="Icon Exclamation Circle" />
        <Typography.Text className="pl-15">
          <h5 className="fw-700">{modalTitle}</h5>
          <p className="fw-500">Bạn đang thao tác hủy chuyến này, bạn có chắc chắn muốn hủy?</p>
        </Typography.Text>
      </Flex>
      <Typography>{t('Lý do')}</Typography>
      <Form.Item name="note" className="form-item-name mt-2" validateTrigger={['onChange', 'onBlur']}>
        <TextArea maxLength={100} placeholder="" style={{ height: 200, resize: 'none' }}/>
      </Form.Item>
      <Row className="d-flex justify-content-end mt-4">
        <>
          <Button key="cancel" className="w-78 h-btn-32 me-3 btn-back agent" onClick={() => modalHandleCancel()}>
            Trở lại
          </Button>
          <Button
            key="ok"
            type="primary"
            className="w-132 h-btn-32 btn-success mr-0"
            htmlType="submit"
            onClick={() => modalHandleOk()}
          >
            {okText}
          </Button>
        </>
      </Row>
    </Modal>
  );
};

export default ModalCancelSchedule;
