/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Radio, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import "../../../../config/i18n/i18n";
import "../../../../assets/styles/listUsers.scss";
import {
  phoneRules,
  validatorEmail,
  validatorEmailOptional,
} from "../../../../config/validators/validators";
import { validateMobileUser } from "../../../../config/validators/validateUser";
import { getListDriverAPI } from "../../../../api/driver";
import { USER_GROUP } from "../../../../config/constant";

const CreateInfoUserContact: React.FC<any> = ({
  detailUser,
  handleOnchangeInput,
  setActiveThree,
  activeThree,
  callCreateUserAPI,
  setDetailUser,
  infoUser,
}: any) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [type, setType] = useState<any>("driver");
  const [listDriver, setListDriver] = useState([]);

  const handleListDriver = async () => {
    try {
      const res = await getListDriverAPI({ type });
      if (res.status === 200) {
        setListDriver(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (infoUser?.group === USER_GROUP.CAR_COMPANY) {
      handleListDriver();
    }
  }, [type]);

  const listSelectDriver = listDriver.map((item: any) => {
    return { value: item?.id, label: item?.name };
  });

  const placementChange = (e: any) => {
    setType(e.target.value);
  };

  const onLoad = () => {
    form.setFieldsValue({
      ...detailUser,
    });
  };

  const onFinish = async (values: any) => {
    await callCreateUserAPI();
    setActiveThree(false);
  };

  const filteredObjects: any = listDriver.filter(
    (obj: any) => obj.id === detailUser?.driver_id
  );

  const checkValidateEmail = () => {
    if (infoUser?.group === USER_GROUP.CARLINK) {
      return detailUser?.group === USER_GROUP.CARLINK ? false : true;
    } else if (infoUser?.group === USER_GROUP.CAR_COMPANY) {
      return detailUser?.group === USER_GROUP.CAR_COMPANY
        ? false
        : true;
    } else if (infoUser?.group === USER_GROUP.AGENT) {
      return detailUser?.group === USER_GROUP.AGENT ? false : true;
    }
  };

  useEffect(() => {
    onLoad();
  }, [detailUser?.driver_id, detailUser?.company_id, detailUser?.agent_id]);

  useEffect(() => {
    if (activeThree) {
      form.submit();
      setActiveThree(false);
    }
  }, [activeThree]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="create-user-contact"
      autoComplete="off"
      // validateTrigger="onSubmit"
      className="form-create-user form-edit-user"
    >
      <div className="container-form-user">
        {detailUser.permissions[0] === "driver" && (
          <Row>
            <Col>
              <Form.Item
                name="driver_id"
                className="form-item-name required item-driver"
                label={t("Tài xế, phụ xe")}
              >
                <Select
                  style={{ width: 244 }}
                  value={detailUser.driver_id}
                  onChange={(value) => {
                    const filterPhone: any = listDriver.filter(
                      (obj: any) => obj.id === value
                    );
                    setDetailUser({
                      ...detailUser,
                      driver_id: value,
                      mobile: filterPhone[0]?.phone,
                    });
                  }}
                  placeholder="Tài xế, phụ xe"
                  dropdownRender={(menu) => (
                    <>
                      <Radio.Group
                        style={{ margin: "2px 5px 6px" }}
                        value={type}
                        onChange={placementChange}
                      >
                        <Radio.Button value="driver">
                          {t("Tài xế")}
                        </Radio.Button>
                        <Radio.Button value="extra_driver">
                          {t("Phụ xe")}
                        </Radio.Button>
                      </Radio.Group>
                      {menu}
                    </>
                  )}
                  options={listSelectDriver}
                />
                <Button disabled className="btn-driver">
                  {filteredObjects[0]?.type === "driver" ? "Tài xế" : "Phụ xe"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="mobile"
              className="form-item-name required"
              label={t("Điện thoại đi động")}
              rules={validateMobileUser}
              validateFirst
            >
              <Input
                name="mobile"
                disabled={detailUser.permissions[0] === "driver"}
                value={detailUser?.mobile}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="phone"
              className="form-item-name"
              label={t("Điện thoại cố định")}
              rules={phoneRules}
            >
              <Input
                name="phone"
                value={detailUser?.phone}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="email"
              className= {checkValidateEmail() ? "required" : ""}
              label={t("Email")}
              rules={checkValidateEmail() ? validatorEmail : validatorEmailOptional}
              validateFirst
            >
              <Input
                name="email"
                value={detailUser?.email}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="fax" label={t("Fax")}>
              <Input
                name="fax"
                value={detailUser?.fax}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="whatapp" label={t("WhatApp")}>
              <Input
                name="whatapp"
                value={detailUser?.whatapp}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="skype" label={t("Skype")}>
              <Input
                name="skype"
                value={detailUser?.skype}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="address"
              className="field-note"
              label={t("Địa chỉ")}
              style={{ marginBottom: 0 }}
            >
              <Input
                name="address"
                value={detailUser?.address}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default CreateInfoUserContact;
