import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {BusParams, ListBusState} from "../../../types";
import {createBusAPI} from "../../../api/bus";
import {getErrorMessage} from "../../../api";
import {setToast} from "../toastNotifySlice";
import {Toast} from "../../../config/constant";
import {Action, CREATE_ERROR, CREATE_SUCCESS} from "../../../config/message";


// @ts-ignore
export const createBus = createAsyncThunk('admin/create/bus', async (params: BusParams, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await createBusAPI(params);
        const {
            data,
            status
        } = response;
        if (status) {
            dispatch(setCreate({status: status, data: data}));
            dispatch(setToast({
                message: CREATE_SUCCESS(Action.BUS),
                status: true,
                type: Toast.TYPE_SUCCESS,
            }))
        }
        return true;
    } catch (error: any) {
        const {response} = error;
        dispatch(setCreateError(response));
        dispatch(setToast({
            message: CREATE_ERROR(Action.BUS),
            status: true,
            type: Toast.TYPE_ERROR,
        }))
        return rejectWithValue(getErrorMessage(error));
    }
});
export const createBusSlice = createSlice({
    name: 'createBus',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        message: '',
        errorDetail: null,
    } as ListBusState,
    reducers: {
        setCreate: (state: ListBusState, {payload}) => {
            const {message} = payload.data;
            state.status = payload?.status;
            state.message = message;
        },
        setCreateError: (state: ListBusState, {payload}) => {
            const {errors} = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetRegisterCreateBus: (state: ListBusState) => {
            state.success = false;
            state.status = 0;
            state.message = '';
            state.errorDetail = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createBus.pending, (state: ListBusState) => {
            state.loading = true;
        });
        builder.addCase(createBus.fulfilled, (state: ListBusState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(createBus.rejected, (state: ListBusState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });
    },
});

export const createBusSelector = (state: RootState) => state.createBus;
export const {setCreate, setCreateError, resetRegisterCreateBus} = createBusSlice.actions;
