import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { getErrorMessage } from "../../../api";
import { setToast } from "../toastNotifySlice";
import { Action, UPDATE_ERROR, UPDATE_SUCCESS } from "../../../config/message";
import { Toast } from "../../../config/constant";
import { updateScheduleAPI, updateStatusScheduleAPI } from "../../../api/schedules";
import { ListScheduleState, ScheduleParams } from "../../../types/schedule";


// @ts-ignore
export const updateSchedule = createAsyncThunk('admin/update/Schedule', async (params: ScheduleParams, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateScheduleAPI(params);
        const {
            data,
            status
        } = response;
        if (status) {
            dispatch(setUpdate({ status: status, data: data }));
            dispatch(setToast({
                message: UPDATE_SUCCESS(Action.SCHEDULE),
                status: true,
                type: Toast.TYPE_SUCCESS,
            }))
        }
        return true;
    } catch (error: any) {
        const { response } = error;
        // dispatch(setUpdateError(response));
        dispatch(setToast({
            message: response?.data?.message ?? UPDATE_ERROR(Action.SCHEDULE),
            status: true,
            type: Toast.TYPE_ERROR,
        }))
        return rejectWithValue(getErrorMessage(error));
    }
});


export const updateStatusSchedule = createAsyncThunk('admin/update/Status/Schedule', async (
    params: {
        id: any,
        status: boolean
    },
    {
        dispatch,
        rejectWithValue
    }) => {
    try {
        const response = await updateStatusScheduleAPI(params);
        const {
            data,
            status
        } = response;
        if (status) {
            dispatch(setUpdate({ status: status, data: data }));
            dispatch(setToast({
                message: data?.message,
                status: true,
                type: Toast.TYPE_SUCCESS,
            }))
        }
        return true;
    } catch (error: any) {
        const { response } = error;
        dispatch(setUpdateError(response));
        dispatch(setToast({
            message: response?.data?.message,
            status: true,
            type: Toast.TYPE_ERROR,
        }))
        return rejectWithValue(getErrorMessage(error));
    }
});
export const updateScheduleSlice = createSlice({
    name: 'updateSchedule',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        message: '',
        errorDetail: null,
    } as ListScheduleState,
    reducers: {
        setUpdate: (state: ListScheduleState, { payload }) => {
            const { message } = payload.data;
            state.status = payload?.status;
            state.message = message;
        },
        setUpdateError: (state: ListScheduleState, { payload }) => {
            const { errors } = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetUpdateSchedule: (state: ListScheduleState) => {
            state.success = false;
            state.status = 0;
            state.message = '';
            state.errorDetail = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateSchedule.pending, (state: ListScheduleState) => {
            state.loading = true;
        });
        builder.addCase(updateSchedule.fulfilled, (state: ListScheduleState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateSchedule.rejected, (state: ListScheduleState, { payload }) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });
        builder.addCase(updateStatusSchedule.pending, (state: ListScheduleState) => {
            state.loading = true;
        });
        builder.addCase(updateStatusSchedule.fulfilled, (state: ListScheduleState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateStatusSchedule.rejected, (state: ListScheduleState, { payload }) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });

    },
});

export const updateScheduleSelector = (state: RootState) => state.updateSchedule;
export const { setUpdate, setUpdateError, resetUpdateSchedule } = updateScheduleSlice.actions;
