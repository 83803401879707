import { Button, Modal, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { modalProps } from '../../types';
import '../../assets/styles/desktop/busBooking.scss';

const ModalInfoApi: React.FC<modalProps> = ({ okText, cancelText, modalOpen, onCancel, onOk, loading }) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  return (
    <Modal
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      width={759}
      footer={[]}
    >
      <div className="mt-3 mb-3">
        <h3 className="fw-600 fs-20">Thông tin về API</h3>
        <div>
          <Typography className="fw-400 fs-20 t-b">
            1. Mỗi một tài khoản có thể tạo đến <span className="color-alert">30 API Key</span>
          </Typography>
          <Typography className="fw-400 fs-20 t-b">
            2. <span className="color-alert">Không nên tiết lộ API Key</span> của bạn cho bất kỳ ai để tránh mất quyền
            mất dữ liệu
          </Typography>
          <Typography className="fw-400 fs-20 t-b">
            3. Bạn chỉ nên truy cập vào các IP đáng tin cậy để tăng tính bảo mật cho tài khoản của mình
          </Typography>
          <Typography className="fw-400 fs-20 t-b">
            4. Bạn sẽ <span className="color-alert">không thể tạo API nếu chưa hoàn thành thông tin </span>tài khoản của
            mình
          </Typography>
          <Typography className="fw-400 fs-20 t-b">
            5. Số lượng IP tĩnh gắn cho mỗi API tối đa là 3 cách nhau dấu /
          </Typography>
        </div>
      </div>

      <Row className="d-flex justify-content-end">
        <>
          <Button
            key="cancel"
            className=" w-160 h-btn-48 me-3 btn-back fs-16 fw-600 t-b"
            loading={loading}
            onClick={() => modalHandleCancel()}
          >
            Thoát
          </Button>
        </>
      </Row>
    </Modal>
  );
};

export default ModalInfoApi;
