import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Pagination, Row, Select, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Link from 'antd/es/typography/Link';
import { FormInstance } from 'antd/lib';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getListGeneralAgentAPI } from '../../api/user';
import iconAlertApi from '../../assets/images/ic-alert-api.svg';
import icEdit from '../../assets/images/ic-edit.svg';
import ModalInfoApi from '../../components/Modal/ModalInfoApi';
import { Constant } from '../../config/constant';
import { mbTrim } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getListBusCompanyActive } from '../../redux/slice/busCompanys/listBusCompanySlice';
import { createApi, createApiSelector, resetCreateApi, updateApi } from '../../redux/slice/followApi/createApi';
import { getGenerateCodeApi, getListAPI } from '../../redux/slice/followApi/getListFollowApi';
import CreateApiFollow from './CreateApiFollow';
import { resetUpdateFollow, updateFollowApiSelector } from '../../redux/slice/followApi/lockFollowApi';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import iconNext from '../../assets/images/ic-next.svg';
import iconPrev from '../../assets/images/ic-prev.svg';

const ApiManagement = () => {
  const { t } = useTranslation();
  const { listApi, loading, paginationApi: pagination, generateCode } = useAppSelector((state) => state.listFollowApi);
  const { status } = useAppSelector(createApiSelector);
  const { status: statusLock } = useAppSelector(updateFollowApiSelector);
  const [formSearch]: [FormInstance] = Form.useForm();
  const [keyword, setKeyword] = useState<any>({});
  const [openModalInfoApi, setOpenModalInfoApi] = useState<boolean>(false);
  const [listGeneralAgent, setListGeneralAgent] = useState<any>([]);
  const [openModalApi, setOpenModalApi] = useState<boolean>(false);
  const [type, setType] = useState<string>('main_agent');
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [idDetail, setIdDetail] = useState('');
  const [statusDetail, setStatusDetail] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [formApi]: [FormInstance] = Form.useForm();
  const [page, setPage] = useState<number>(1);
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const handleSearch = (values: any) => {
    formSearch.setFields([{ name: 'keyword', value: mbTrim(values.keyword) }]);
    // setPage(Constant.DEFAULT_PAGE);
    setKeyword(formSearch.getFieldsValue(['keyword']));
  };
  const getDetailApi = (record: any) => {
    setOpenModalApi(true);
    setIsDetail(true);
    setIdDetail(record?.id);
    formApi.setFieldsValue({
      name: record?.name,
      key: record?.api_key,
      api_type: record?.is_company === true ? 'car_company' : 'agent_general',
      agent_general: record?.is_company === true ? undefined : record?.apikeyable?.id,
      car_company: record?.is_company ? record?.apikeyable?.id : undefined,
      domain: record?.apikeyable?.domain,
      last_action: record?.last_action,
      ip: record?.ip,
    });
    setStatusDetail(record?.status === 1 ? true : false);
  };
  const columns1: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '4%',
      render: (text) => <Typography className="text-center">{text < 10 ? `0${text}` : text}</Typography>,
      sorter: (a, b) => +a?.id - +b?.id,
    },
    {
      title: 'Tên API',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: 'API Key',
      dataIndex: 'api_key',
      key: 'key_api',
      width: '45%',
      render: (text) => <Typography>{text}</Typography>,
      sorter: (a, b) => a?.api_key?.localeCompare(b?.api_key),
    },
    {
      title: 'Phân loại',
      dataIndex: 'apikeyable_type',
      key: 'type_api',
      width: '15%',
      render: (text) => <Typography>{text}</Typography>,
    },
    {
      title: 'Tên miền',
      dataIndex: '',
      key: 'domain_name',
      width: '10%',
      render: (_, record) => <Typography>{record?.apikeyable?.domain}</Typography>,
      sorter: (a, b) => a?.apikeyable?.domain?.localeCompare(b?.apikeyable?.domain),
    },
    {
      title: 'Hành động cuối',
      dataIndex: 'last_action',
      key: 'last_action',
      width: '20%',
      sorter: (a, b) => a?.last_action?.localeCompare(b?.last_action),
    },
    {
      title: ' ',
      dataIndex: 'action',
      key: 'action',

      render: (_, record: any) => (
        <Space size="middle">
          <Tooltip title="Chỉnh sửa">
            <Link
              onClick={() => {
                getDetailApi(record);
              }}
            >
              <Image width={17} height={17} className="icon-edit mr-20" src={icEdit} preview={false} />
            </Link>
          </Tooltip>
        </Space>
      ),
      align: 'center',
      width: '5%',
    },
  ];
  const handleModalApiCancel = () => {
    setOpenModalApi(false);
    setIsDetail(false);
  };
  const handleCreateApi = () => {
    setIsDetail(false);
    setOpenModalApi(true);
    dispatch(getGenerateCodeApi());
    formApi.resetFields();
  };
  const onFinish = (values: any) => {
    const tmpDataCreateApi = {
      name: values.name,
      api_key: values.key,
      type: values.api_type === 'agent_general' ? 'main_agent' : 'company',
      id: values.api_type === 'agent_general' ? values.agent_general : values.car_company,
      domain: values.domain,
      ip: values.ip,
      id_detail: idDetail ? +idDetail : undefined,
    };
    if (isDetail) {
      dispatch(updateApi(tmpDataCreateApi));
    } else {
      dispatch(createApi(tmpDataCreateApi));
    }
  };
  const onCancelSuccess = useCallback(() => {
    dispatch(resetCreateApi());
    dispatch(resetUpdateFollow());
    dispatch(getListAPI({ ...keyword, type: type, status: undefined }));
    handleModalApiCancel();
  }, [dispatch, formApi]);
  
  const handlePagination = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  useEffect(() => {
    dispatch(getListBusCompanyActive({ limit: 100 }));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getListAPI({ ...keyword, type: type, status: undefined, page, limit }));
  }, [dispatch, type, keyword, page, limit]);
  const handleListGeneralAgent = async () => {
    try {
      const testStatus = true;
      let queryGeneralAgent = `status=${testStatus}`;
      const res = await getListGeneralAgentAPI(queryGeneralAgent);
      setListGeneralAgent(res?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    handleListGeneralAgent();
  }, []);
  const renderRowTable = (record: any) => {
    return record.status === 1 ? '' : 'bg-canceled';
  };
  useEffect(() => {
    if (status === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
  }, [status]);
  useEffect(() => {
    if (statusLock === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
  }, [statusLock]);

  useEffect(() => {
    dispatch(getGenerateCodeApi());
  }, [dispatch]);
  return (
    <div className="ApiFollow bg-white">
      <Form
        className="mg-16 mt-1"
        form={formSearch}
        labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
        requiredMark={false}
        onFinish={handleSearch}
        colon={false}
        initialValues={{ type: 'main_agent' }}
      >
        <div className="d-flex align-center space-between">
          <Row gutter={8} className="mw-80">
            <Col>
              <Button
                type="primary"
                className="w-84 mh-35 btn-success mr-0 d-flex align-center"
                onClick={() => handleCreateApi()}
              >
                <PlusOutlined /> Tạo API mới
              </Button>
            </Col>
            <Col>
              <Form.Item name={'type'}>
                <Select
                  className="select-type-api"
                  placeholder={'Chọn phân loại'}
                  options={[
                    { value: 'main_agent', label: 'Tổng đại lý' },
                    { value: 'company', label: 'Nhà xe' },
                  ]}
                  onChange={(value) => setType(value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'keyword'}>
                <Input
                  placeholder={t('Nhập tên API để tìm kiếm')}
                  className="search-ticket"
                  suffix={<SearchOutlined className="ic-search-input" />}
                  size="large"
                  style={{ width: '100%', maxHeight: '36px' }}
                />
              </Form.Item>
            </Col>
            <Col className="text-center">
              <Button
                htmlType="submit"
                type="primary"
                className={'btn-search height-btn-36'}
                onSubmit={handleSearch}
                size="large"
              >
                {t('Tìm kiếm')}
              </Button>
            </Col>
          </Row>
          <Typography style={{ color: '#EA8C00', cursor: 'pointer' }} onClick={() => setOpenModalInfoApi(true)}>
            Thông tin về API <img src={iconAlertApi} alt="" />
          </Typography>
        </div>
      </Form>
      <Table
        locale={{
          triggerDesc: 'Sắp xếp giảm dần',
          triggerAsc: 'Sắp xếp tăng dần',
          cancelSort: 'Hủy sắp xếp',
        }}
        dataSource={listApi?.length > 0 ? listApi : []}
        rowKey="api_management"
        className="mt-3"
        columns={columns1}
        pagination={false}
        loading={loading}
        rowClassName={renderRowTable}
      />
      <Pagination
        onChange={handlePagination}
        current={page}
        total={pagination?.total}
        pageSize={pagination?.per_page ?? limitPagination}
        showSizeChanger={true}
        prevIcon={<img src={iconPrev} alt="" />}
        nextIcon={<img src={iconNext} alt="" />}
        className="mt-1 text-end"
      />
      <ModalInfoApi modalOpen={openModalInfoApi} onCancel={() => setOpenModalInfoApi(false)} />

      <Form
        className="mg-16 mt-3"
        name={'formAPiManager'}
        form={formApi}
        onFinish={onFinish}
        initialValues={{ api_type: 'car_company', key: !isDetail ? generateCode : undefined }}
        autoComplete="off"
        validateTrigger="onSubmit"
      >
        <CreateApiFollow
          modalOpen={openModalApi}
          onCancel={() => handleModalApiCancel()}
          formApi={formApi}
          isDetail={isDetail}
          listGeneralAgent={listGeneralAgent}
          statusDetail={statusDetail}
          idDetail={idDetail}
        />
      </Form>
    </div>
  );
};

export default ApiManagement;
