import React, { useCallback, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Flex,
  Image,
  Table,
  Select,
  Button,
  Divider,
  GetProps,
  DatePicker,
  Typography,
  ConfigProvider,
  type FormProps,
  type TableColumnsType,
  type DatePickerProps,
} from 'antd';
import 'dayjs/locale/vi';
import dayjs from 'dayjs';
import moment from 'moment';
import './Report.scss';
import { formatPrice, handleGetUrlGuide } from '../../utils/helpers';
import IconFileExport from '../../components/Icons/IconFileExport';
import IconExportExcel from '../../components/Icons/IconExportExcel';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';
import { DataReport, OfficeData, TimeRouteData, ValueOptions } from '../../types/report';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { Constant } from '../../config/constant';
import { toast } from 'react-toastify';
import { getListDepartTimeByRouteAPI, getListOfficeAPI, getListRouteAPI } from '../../api/report';
import { RouteItemProps } from '../../types';
import { isNumber } from 'lodash';
import ExcelJS from 'exceljs';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getListReportDetailCompany,
  getListReportDetailCompanyExcel,
  setFilterBookingDate,
  setFilterDepartTime,
  setFilterOffice,
  setFilterOptions,
  setFilterPaymentMethod,
  setFilterRangeDate,
  setFilterRoute,
  setListTimeRoute,
  setPerPage,
} from '../../redux/slice/report/reportDetailCompanySlice';
import TutorialsVideo from '../../components/TutorialsVideo';
import { formattedPrice } from '../../lib/utils';

dayjs.locale('vn');
const { RangePicker } = DatePicker;
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

export enum TypePaymentMethod {
  All = '',
  Bank = 'bank_transfer',
  Cash = 'cash',
  Agent = 'agent_balance',
}

export const optionsBookingDate = [
  {
    value: 'false',
    label: 'Theo ngày khởi hành',
  },
  {
    value: 'true',
    label: 'Theo ngày đặt vé',
  },
];

export const optionsPayMethod = [
  {
    value: TypePaymentMethod.All,
    label: 'Tất cả',
  },
  {
    value: TypePaymentMethod.Bank,
    label: 'Chuyển khoản',
  },
  {
    value: TypePaymentMethod.Cash,
    label: 'Tiền mặt',
  },
  {
    value: TypePaymentMethod.Agent,
    label: 'Đại lý nạp',
  },
];

export const handleGetLabelPayMethod = (value: string) => {
  const methodCurrent = optionsPayMethod.find((method) => method.value === value);
  return methodCurrent?.label || 'Tất cả';
};

export const handleGetLabelPayMethodTable = (value: string) => {
  const methodCurrent = optionsPayMethod.find((method) => method.value === value);
  return methodCurrent?.label || 'N/A';
};

type FieldType = {
  booking_date: string;
  range_date_report: string;
  route_id: number | string;
  depart_time: number | string;
  payment_method: string;
  office_id: number | string;
};

const ReportDetailCompany = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [timeReport, setTimeReport] = useState<RangePickerProps['value']>();
  const [optionsRoute, setOptionsRoute] = useState<ValueOptions[]>([]);
  const [optionsOffice, setOptionsOffice] = useState<ValueOptions[]>([]);

  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? Constant.PAGE_SIZE : Number(limit_pagination);

  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);

  const {
    filter: listFilter,
    listTimeRoute,
    total_price: totalPrice,
    total_real_revenue: totalRealRevenue,
    loading: loadingListReport,
    listReport,
    pagination,
    total_promotion,
  } = useAppSelector((state) => state?.reportDetailCompany);

  const watchBookingDate = Form.useWatch('booking_date', form);
  const watchRouteId = Form.useWatch('route_id', form);
  const watchDepartTime = Form.useWatch('depart_time', form);
  const watchPaymentMethod = Form.useWatch('payment_method', form);
  const watchOfficeId = Form.useWatch('office_id', form);

  const handleGetListRoute = async () => {
    const response = await getListRouteAPI();
    if (response) {
      const listRoute: ValueOptions[] = response?.data?.data?.map((route: RouteItemProps) => ({
        value: route?.id,
        label: `${route?.depart_point} - ${route?.arrive_point}`,
      }));
      listRoute.unshift({
        value: '',
        label: 'Tất cả các tuyến',
      });
      setOptionsRoute(listRoute);
    }
  };

  const handleGetListOffice = async () => {
    const response = await getListOfficeAPI();
    if (response) {
      let listOffice: ValueOptions[] = response?.data?.data?.map((office: OfficeData) => ({
        value: office?.id,
        label: office?.name,
      }));
      listOffice = [
        { value: '', label: 'Tất cả' },
        { value: 'all_company', label: 'Nhà xe' },
        { value: 'all_agent', label: 'Tất cả đại lý' },
        { value: 'carlink_api', label: 'Carlink API' },
        { value: 'web_company', label: 'Web nhà xe' },
        ...listOffice,
      ];
      setOptionsOffice(listOffice);
    }
  };

  const handleChangeRoute = async (value: number | string) => {
    const response = await getListDepartTimeByRouteAPI(value);
    if (response) {
      const listTimeRoute: ValueOptions[] = response?.data?.data?.map((time: TimeRouteData) => ({
        value: dayjs(time?.depart_time, 'HH:mm').format('HH:mm'),
        label: dayjs(time?.depart_time, 'HH:mm').format('HH:mm'),
      }));
      listTimeRoute.unshift({
        value: '',
        label: 'Tất cả',
      });
      dispatch(setFilterRoute(value));
      dispatch(setListTimeRoute(listTimeRoute));
      dispatch(setFilterDepartTime(''));
      form.setFieldValue('depart_time', { value: '', label: 'Giờ đi' });
    }
  };

  useEffect(() => {
    handleGetListRoute();
    handleGetListOffice();
  }, []);

  const onChangePage = async (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      dispatch(setPerPage(pageSize));
    } else {
      setPage(page);
    }
    await dispatch(
      getListReportDetailCompany({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format('YYYY-MM-DD'),
        to: dayjs(timeReport?.[1]).format('YYYY-MM-DD'),
        route_id: watchRouteId?.label ? watchRouteId?.value : watchRouteId,
        depart_time: watchDepartTime?.label ? watchDepartTime?.value : watchDepartTime,
        payment_method: watchPaymentMethod?.label ? watchPaymentMethod?.value : watchPaymentMethod,
        office_id: isNumber(watchOfficeId) ? watchOfficeId : undefined,
        options: isNumber(watchOfficeId) ? '' : watchOfficeId?.label ? watchOfficeId?.value : watchOfficeId,
        page: pagination?.per_page !== pageSize ? Constant.DEFAULT_PAGE : page,
        limit: pageSize,
      }),
    );
  };

  const onFinish: FormProps<any>['onFinish'] = async () => {
    setPage(Constant.DEFAULT_PAGE);
    const response = await dispatch(
      getListReportDetailCompany({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format('YYYY-MM-DD'),
        to: dayjs(timeReport?.[1]).format('YYYY-MM-DD'),
        route_id: watchRouteId?.label ? watchRouteId?.value : watchRouteId,
        depart_time: watchDepartTime?.label ? watchDepartTime?.value : watchDepartTime,
        payment_method: watchPaymentMethod?.label ? watchPaymentMethod?.value : watchPaymentMethod,
        office_id: isNumber(watchOfficeId) ? watchOfficeId : undefined,
        options: isNumber(watchOfficeId) ? '' : watchOfficeId?.label ? watchOfficeId?.value : watchOfficeId,
        page: Constant.DEFAULT_PAGE,
        limit: pagination?.per_page || limitPagination,
      }),
    );
    if (response?.payload?.success) {
      toast.success(response?.payload?.message);
    }
  };

  const handleGetListReportBegin = async () => {
    setPage(Constant.DEFAULT_PAGE);
    if (listFilter?.from && listFilter?.to) {
      await dispatch(
        getListReportDetailCompany({
          booking_date: listFilter?.booking_date,
          from: dayjs(listFilter?.from).format('YYYY-MM-DD'),
          to: dayjs(listFilter?.to).format('YYYY-MM-DD'),
          route_id: listFilter?.route_id,
          depart_time: listFilter?.depart_time,
          payment_method: listFilter?.payment_method,
          office_id: isNumber(listFilter?.office_id) ? listFilter?.office_id : undefined,
          options: isNumber(watchOfficeId) ? '' : listFilter?.options,
          page: Constant.DEFAULT_PAGE,
          limit: pagination?.per_page || limitPagination,
        }),
      );
    }
  };

  useEffect(() => {
    handleGetListReportBegin();
    if (listFilter?.from && listFilter?.to) {
      setTimeReport([dayjs(listFilter?.from, 'YYYY-MM-DD'), dayjs(listFilter?.to, 'YYYY-MM-DD')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: TableColumnsType<DataReport> = [
    {
      title: 'Mã vé',
      dataIndex: 'ticket_code',
      render: (ticket_code) => (
        <Typography.Text className="fs-16 text-color fw-500">{ticket_code ? ticket_code : ''}</Typography.Text>
      ),
    },
    {
      title: 'Tuyến',
      dataIndex: 'route',
      render: (route) => (
        <Typography.Text className="fs-16 text-color fw-500">{`${route?.depart_point} - ${route?.arrive_point}`}</Typography.Text>
      ),
      sorter: (a, b) =>
        `${a?.route?.depart_point} - ${a?.route?.arrive_point}`.localeCompare(
          `${b?.route?.depart_point} - ${b?.route?.arrive_point}`,
        ),
    },
    {
      title: 'Ngày đi',
      dataIndex: 'depart_date',
      render: (depart_date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_date ? moment(depart_date).format('DD/MM/YYYY') : ''}
        </Typography.Text>
      ),
      sorter: (a, b) => {
        if (a && a?.depart_date && a?.depart_date.length && b && b?.depart_date && b?.depart_date.length) {
          return a?.depart_date.localeCompare(b?.depart_date);
        } else if (a && a?.depart_date && a?.depart_date?.length) {
          // that means be has null license_class, so a will come first.
          return -1;
        } else if (b && b?.depart_date && b?.depart_date?.length) {
          // that means a has null license_class so b will come first.
          return 1;
        }
        // both license_class has null value so there will be no order change.
        return 0;
      },
    },
    {
      title: 'Giờ đi',
      dataIndex: 'depart_time',
      render: (depart_time) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_time ? moment(depart_time, 'HH:mm').format('HH:mm') : ''}
        </Typography.Text>
      ),
    },
    {
      title: 'Ngày đặt',
      dataIndex: 'created_at',
      render: (created_at) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {created_at ? moment(created_at).format('DD/MM/YYYY') : ''}
        </Typography.Text>
      ),
    },
    {
      title: 'PTTT',
      dataIndex: 'payment_method',
      render: (payment_method) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {handleGetLabelPayMethodTable(payment_method)}
        </Typography.Text>
      ),
      sorter: (a, b) => `${a?.payment_method}`.localeCompare(`${b?.payment_method}`),
    },
    {
      title: 'VP đặt vé',
      dataIndex: 'user',
      render: (user) => <Typography.Text className="fs-16 text-color fw-500">{user ? user : ''}</Typography.Text>,
    },
    {
      title: 'VP xuất vé',
      dataIndex: 'office_export_ticket',
      render: (office_export_ticket) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {office_export_ticket ? office_export_ticket : ''}
        </Typography.Text>
      ),
    },
    {
      title: '% Chiết khấu',
      dataIndex: 'commission_percent',
      align: 'right',
      render: (commission_percent) => {
        return (
          <Typography.Text className="fs-16 text-color fw-500">
            {`${commission_percent ? commission_percent : '0%'}${commission_percent !== 0 ? '%' : ''}`}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Khuyến mãi',
      dataIndex: 'price_promotion',
      align: 'right',
      render: (price_promotion) => {
        return (
          <Typography.Text className="fs-16 text-color fw-500">
            {`${price_promotion ? formattedPrice(price_promotion) : '0'}`}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Giá vé',
      width: 200,
      dataIndex: 'price',
      align: 'right',
      render: (price) => (
        <Typography.Text className="fs-16 text-color fw-500">{price ? formatPrice(price, 0, ',') : 0}</Typography.Text>
      ),
    },
    {
      title: 'Thực thu',
      dataIndex: 'real_revenue',
      align: 'right',
      render: (real_revenue) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {real_revenue ? formatPrice(real_revenue, 0, ',') : 0}
        </Typography.Text>
      ),
    },
  ];

  const handleGetLabelRoute = (value: number | string) => {
    const routeCurrent = optionsRoute?.find((route) => route?.value === value);
    return routeCurrent?.label || 'Tất cả các tuyến';
  };

  const handleGetLabelTypeDateRoute = (value: string) => {
    const typeCurrent = optionsBookingDate?.find((type) => type.value === value);
    return typeCurrent?.label;
  };

  const handleGetLabelOffice = (value: string) => {
    const officeCurrent = optionsOffice.find((office) => office.value === value);
    return officeCurrent?.label || 'Tất cả';
  };

  const handleGetLabelTimeRoute = (value: string) => {
    const timeCurrent = listTimeRoute.find((time) => time.value === value);
    return timeCurrent?.label || 'Tất cả';
  };

  const onChangeTimeReport = (value: RangePickerProps['value']) => {
    setTimeReport(value);
    dispatch(setFilterRangeDate(value));
  };

  const columnsExport: IExcelColumn[] = [
    {
      title: 'Mã vé',
      __excelTitle__: 'Mã vé',
      dataIndex: 'ticket_code',
      width: 30,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
    },
    {
      title: 'Tuyến',
      dataIndex: 'route',
      width: 40,
      __excelTitle__: 'Tuyến',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
    },
    {
      title: 'Ngày đi',
      dataIndex: 'depart_date',
      __excelTitle__: 'Ngày đi',
      width: 15,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
    },
    {
      title: 'Giờ đi',
      dataIndex: 'depart_time',
      __excelTitle__: 'Giờ đi',
      width: 15,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
    },
    {
      title: 'Ngày đặt',
      dataIndex: 'created_at',
      __excelTitle__: 'Ngày đặt',
      width: 15,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
    },
    {
      title: 'PTTT',
      dataIndex: 'payment_method',
      __excelTitle__: 'PTTT',
      width: 20,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
    },
    {
      title: 'VP đặt vé',
      dataIndex: 'user',
      __excelTitle__: 'Văn phòng đặt vé',
      width: 40,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
    },
    {
      title: 'VP xuất vé',
      dataIndex: 'office_export_ticket',
      __excelTitle__: 'Văn phòng xuất vé',
      width: 40,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
    },
    {
      title: '% Chiết khấu',
      dataIndex: 'commission_percent',
      __excelTitle__: '% Chiết khấu',
      __numFmt__: '0.0%',
      width: 20,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        h: 'right',
      },
    },
    {
      title: 'Tiền chiết khấu',
      dataIndex: 'commission',
      __cellType__: 'TypeNumeric',
      __excelTitle__: 'Tiền chiết khấu',
      __numFmt__: '#,##0',
      width: 20,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        h: 'right',
      },
    },
    {
      title: 'Giá vé',
      dataIndex: 'price',
      __cellType__: 'TypeNumeric',
      __excelTitle__: 'Giá vé',
      __numFmt__: '#,##0',
      width: 20,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        h: 'right',
      },
    },
    {
      title: 'Thực thu',
      dataIndex: 'real_revenue',
      __cellType__: 'TypeNumeric',
      __excelTitle__: 'Thực thu',
      __numFmt__: '#,##0',
      width: 20,
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        h: 'right',
      },
    },
  ];

  const handleExportExcel = async () => {
    const response: any = await dispatch(
      getListReportDetailCompanyExcel({
        booking_date: watchBookingDate,
        from: dayjs(timeReport?.[0]).format('YYYY-MM-DD'),
        to: dayjs(timeReport?.[1]).format('YYYY-MM-DD'),
        route_id: watchRouteId?.label ? watchRouteId?.value : watchRouteId,
        depart_time: watchDepartTime?.label ? watchDepartTime?.value : watchDepartTime,
        payment_method: watchPaymentMethod?.label ? watchPaymentMethod?.value : watchPaymentMethod,
        office_id: isNumber(watchOfficeId) ? watchOfficeId : undefined,
        options: isNumber(watchOfficeId) ? '' : watchOfficeId?.label ? watchOfficeId?.value : watchOfficeId,
      }),
    );
    if (!response?.error) {
      const listData = response?.payload?.data;
      const totalCommission = response?.payload?.total_commission;
      const totalPrice = response?.payload?.total_price;
      const totalRealRevenue = response?.payload?.total_real_revenue;
      const listDataExcel =
        listData?.length > 0
          ? listData?.map((report: DataReport) => ({
              ticket_code: report?.ticket_code ? report?.ticket_code : '',
              route: `${report?.route?.depart_point} - ${report?.route?.arrive_point}`,
              depart_date: report?.depart_date ? `${moment(report?.depart_date).format('DD/MM/YYYY')}` : '',
              depart_time: report?.depart_time ? `${moment(report?.depart_time, 'HH:mm').format('HH:mm')}` : '',
              created_at: report?.created_at ? `${moment(report?.created_at).format('DD/MM/YYYY')}` : '',
              payment_method: `${handleGetLabelPayMethodTable(report?.payment_method as string)}`,
              user: report?.user ? report?.user : '',
              commission_percent: Number(report?.commission_percent) / 100,
              commission: report?.commission ? Number(report?.commission) : 0,
              price: report?.price ? Number(report?.price) : 0,
              office_export_ticket: report?.office_export_ticket ? report?.office_export_ticket : '',
              real_revenue: report?.real_revenue ? Number(report?.real_revenue) : 0,
            }))
          : [];
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Báo cáo chi tiết');
      const header = columnsExport.map((col) => ({
        header: col.title,
        key: col.dataIndex as string,
      }));
      worksheet.columns = header;
      columnsExport.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width || 15;
        worksheet.getColumn(index + 1).alignment = {
          horizontal: (col?.__style__?.h as 'center' | 'left' | 'right' | 'fill' | 'justify') || 'left',
          vertical: 'middle',
        };
        worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || 'general';
      });
      worksheet.addRows(listDataExcel);
      worksheet.getRow(1).font = {
        name: 'Times New Roman',
        size: 12,
        bold: true,
      };
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (colNumber < 13) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '3AB54A' },
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        }
      });
      let firstRowHasData = false;
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (cell.value) {
          firstRowHasData = true;
          return;
        }
      });
      if (firstRowHasData) {
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber > 1) {
            row.eachCell((cell) => {
              cell.font = { name: 'Times New Roman', size: 12 };
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
            });
          }
        });
      } else {
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            let hasData = false;
            row.eachCell((cell) => {
              if (cell.value) {
                hasData = true;
                return;
              }
            });
            if (hasData) {
              row.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
            }
          }
        });
      }
      const startColumnIndex = 1;

      const subLastRow = worksheet.addRow([]);
      subLastRow.getCell(1).value = 'TỔNG (VND)';
      subLastRow.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'left',
      };
      subLastRow.getCell(1).font = {
        name: 'Times New Roman',
        size: 14,
        bold: true,
      };
      const endColumnIndexTotal = startColumnIndex + columnsExport.length - 4;
      worksheet.mergeCells(subLastRow.number, 1, subLastRow.number, endColumnIndexTotal);
      subLastRow.getCell(10).value = totalCommission;
      subLastRow.getCell(11).value = totalPrice;
      subLastRow.getCell(12).value = totalRealRevenue;
      subLastRow.eachCell((cell) => {
        cell.font = { name: 'Times New Roman', size: 14, bold: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      const lastRow = worksheet.addRow([]);
      lastRow.getCell(1).value = '@Bản quyền thuộc nhà xe Carlink';
      lastRow.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'left',
      };
      lastRow.getCell(1).font = {
        name: 'Times New Roman',
        size: 14,
        bold: true,
      };

      const endColumnIndex = startColumnIndex + columnsExport.length - 1;
      worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Báo cáo chi tiết.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const onChangeBookingDate = (type: string) => {
    dispatch(setFilterBookingDate(type));
  };

  const onChangeTimeRoute = (value: number | string) => {
    dispatch(setFilterDepartTime(value));
  };

  const onChangePaymentMethod = (value: string) => {
    dispatch(setFilterPaymentMethod(value));
  };

  const onChangeOffice = (value: number | string | undefined) => {
    if (value === undefined || isNumber(value)) {
      dispatch(setFilterOffice(value));
      dispatch(setFilterOptions(''));
    } else {
      dispatch(setFilterOptions(value));
      dispatch(setFilterOffice());
    }
  };

  const filterOption = (
    input: string,
    option?: {
      label?: any;
      value?: any;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate: DatePickerProps['disabledDate'] = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return Math.abs(current.diff(from, 'days')) >= systemConfig?.export_report_month;
      }
      return false;
    },
    [systemConfig?.export_report_month],
  );

  return (
    <div className="Report bg-white">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          booking_date: listFilter?.booking_date,
          range_date_report: [
            listFilter?.from ? dayjs(listFilter?.from, 'DD/MM/YYYY') : undefined,
            listFilter?.to ? dayjs(listFilter?.to, 'DD/MM/YYYY') : undefined,
          ],
          route_id:
            listFilter?.route_id === '' ? { value: listFilter?.route_id, label: 'Chọn tuyến' } : listFilter?.route_id,
          depart_time: listFilter?.depart_time === '' ? { value: '', label: 'Giờ đi' } : listFilter?.depart_time,
          payment_method:
            listFilter?.payment_method === ''
              ? { value: '', label: 'Phương thức thanh toán' }
              : listFilter?.payment_method,
          office_id: listFilter?.office_id
            ? listFilter?.office_id
            : listFilter?.options === ''
            ? { value: '', label: 'Văn phòng' }
            : listFilter?.options,
        }}
      >
        <Row gutter={12}>
          <Col className="Report__typeDateReport">
            <Form.Item<FieldType>
              className="required"
              name="booking_date"
              label="Khoảng thời gian"
              rules={[{ required: true, message: 'Vui lòng chọn khoảng thời gian' }]}
            >
              <Select placeholder="Theo ngày khởi hành" options={optionsBookingDate} onChange={onChangeBookingDate} />
            </Form.Item>
          </Col>
          <Col className="Report__rangeDateReport">
            <Form.Item<FieldType>
              name="range_date_report"
              rules={[{ required: true, message: 'Vui lòng chọn khoảng thời gian' }]}
            >
              <RangePicker
                format="DD/MM/YYYY"
                disabledDate={disabledDaysDate}
                onChange={onChangeTimeReport}
                popupClassName="Report__timeReport__picker"
                placeholder={['Từ ngày', 'Đến ngày']}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex align="center" gap={'small'} className="mb-24">
          <Image
            preview={false}
            width={20}
            height={20}
            className="d-flex"
            src={IconExclamationCircle}
            alt="Icon Exclamation Circle"
          />
          <Typography.Text className="fs-14 fw-500 text-color">
            Vui lòng chọn khoảng thời gian để xem báo cáo
          </Typography.Text>
        </Flex>
        <Typography.Text className="fs-6 fw-500 text-color d-block mb-8">Bộ lọc nâng cao</Typography.Text>
        <Flex gap={'small'}>
          <Form.Item<FieldType> className="Report__route" name="route_id">
            <Select
              onChange={handleChangeRoute}
              options={optionsRoute}
              popupClassName="Report__popup"
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item<FieldType> className="Report__timeRoute" name="depart_time">
            <Select onChange={onChangeTimeRoute} options={listTimeRoute} showSearch filterOption={filterOption} />
          </Form.Item>
          <Form.Item<FieldType> className="Report__payMethod" name="payment_method">
            <Select onChange={onChangePaymentMethod} options={optionsPayMethod} />
          </Form.Item>
          <Form.Item<FieldType> name="office_id" className="Report__office">
            <Select onChange={onChangeOffice} options={optionsOffice} showSearch filterOption={filterOption} />
          </Form.Item>
        </Flex>
        <Flex align="center" justify="space-between">
          <Form.Item className="Report__submit">
            <ConfigProvider theme={{ token: { colorPrimary: '#435869' } }}>
              <Button
                disabled={timeReport?.length === 2 || (listFilter?.from && listFilter?.to) ? false : true}
                className="d-flex align-items-center h-auto"
                type="primary"
                htmlType="submit"
              >
                <IconFileExport width={16} height={16} />
                Xem báo cáo
              </Button>
            </ConfigProvider>
          </Form.Item>
          <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 2)} />
        </Flex>
        <Divider />
        <Flex>
          <ConfigProvider theme={{ token: { colorPrimary: '#435869' } }}>
            <Button
              ghost
              disabled={timeReport?.length === 2 || (listFilter?.from && listFilter?.to) ? false : true}
              onClick={handleExportExcel}
              className={`Report__exportExcel d-flex align-items-center mr-12 h-auto ${
                timeReport?.length === 2 || (listFilter?.from && listFilter?.to) ? '' : 'disabled'
              }`}
              type="primary"
            >
              <IconExportExcel
                color={`${timeReport?.length === 2 || (listFilter?.from && listFilter?.to) ? '#435869' : '#949494'}`}
                width={16}
                height={16}
              />
              Xuất Excel
            </Button>
          </ConfigProvider>
        </Flex>
        <Typography.Text className="d-block text-color-gray-3A3A3A fs-18 fw-600 mt-24 mb-8">
          Báo cáo chi tiết ({handleGetLabelTypeDateRoute(watchBookingDate)})
        </Typography.Text>
        <Flex wrap="wrap" align="center" className="Report__filter">
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">Từ ngày:</Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[0]).format('DD/MM/YYYY')}
              </Typography.Text>
            </Flex>
          )}
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">Đến ngày:</Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[1]).format('DD/MM/YYYY')}
              </Typography.Text>
            </Flex>
          )}
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">Tuyến:</Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">{handleGetLabelRoute(watchRouteId)}</Typography.Text>
          </Flex>
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">Giờ đi:</Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelTimeRoute(watchDepartTime)}
            </Typography.Text>
          </Flex>
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">PTTT:</Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelPayMethod(watchPaymentMethod)}
            </Typography.Text>
          </Flex>
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">Văn phòng:</Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">{handleGetLabelOffice(watchOfficeId)}</Typography.Text>
          </Flex>
        </Flex>
      </Form>
      <Table
        className="Report__table"
        locale={{
          triggerDesc: 'Sắp xếp tăng dần',
          triggerAsc: 'Hủy sắp xếp',
          cancelSort: 'Sắp xếp giảm dần',
        }}
        pagination={{
          total: pagination?.total,
          current: page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ['bottomRight'],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        dataSource={listReport?.length > 0 ? listReport : []}
        loading={loadingListReport}
        columns={columns}
        summary={(pageData) => {
          return (
            listReport?.length > 0 &&
            page === pagination?.last_page && (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={9}>
                    <Flex align="center" gap="middle">
                      <Typography.Text className="text-color-default fw-700 fs-16">TỔNG</Typography.Text>
                      <Typography.Text className="text-color-default fw-500 fs-16">(VND)</Typography.Text>
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={9}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {total_promotion ? formatPrice(total_promotion, 0, ',') : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={10}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {totalPrice ? formatPrice(totalPrice, 0, ',') : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={11}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {totalRealRevenue ? formatPrice(totalRealRevenue, 0, ',') : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          );
        }}
      />
    </div>
  );
};

export default ReportDetailCompany;
