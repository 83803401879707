import axios from 'axios';
import { BASE_URL_MANAGER } from '.';
import { CreateApiParams, FollowAPIParams } from '../types/follow';
import { getAccessToken } from '../utils/jwt';

export const getAxiosManagerApiClient = axios.create({
  baseURL: BASE_URL_MANAGER,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    Accept: 'application/json',
    Cache: 'no-cache',
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
});
function getAuthToken(token: string | null) {
  return `Bearer ${token}`;
}

getAxiosManagerApiClient.interceptors.request.use((request) => {
  request.headers['Authorization'] = getAuthToken(getAccessToken());
  return request;
});
export const getAxiosManagerApiWithToken = (refreshToken?: boolean) => {
  return getAxiosManagerApiClient;
};
export const listFollowAPI = (params: FollowAPIParams) => {
  return getAxiosManagerApiWithToken().get(`/logs/all`, { params });
};
export const listApiAPI = (params: {
  keyword: string;
  type: string;
  status?: number;
  page?: number;
  limit?: number;
}) => {
  return getAxiosManagerApiWithToken().get(`/all`, { params });
};
export const editApiAPI = (params: CreateApiParams) => {
  return getAxiosManagerApiWithToken().put(`/${params.id_detail}`, params);
};
export const createApiAPI = (params: CreateApiParams) => {
  return getAxiosManagerApiWithToken().post(`/generate`, params);
};
export const updateStatusApiAPI = (params: { id: string; status: number }) => {
  return getAxiosManagerApiWithToken().patch(`/update-status/${params.id}`, {
    status: params.status,
  });
};
export const getApiKeyApiAPI = () => {
  return getAxiosManagerApiWithToken().get(`/generate-code`);
};
