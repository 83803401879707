import {
  FileTextOutlined,
  FormOutlined,
  HistoryOutlined,
  InfoCircleFilled,
  PrinterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Image,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { Option } from 'antd/es/mentions';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { TabsProps } from 'antd/lib';
import dayjs from 'dayjs';
import { Lunar } from 'lunar-typescript';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconAgent from '../../assets/images/ic-agent.svg';
import iconCarlinkApi from '../../assets/images/ic-carlink-api.svg';
import iconDote from '../../assets/images/ic-dot.svg';
import iconCarGroup from '../../assets/images/ic-house-car-2.png';
import iconLine from '../../assets/images/ic-line.svg';
import iconManual from '../../assets/images/ic-manual-booking.png';
import iconDriver from '../../assets/images/ic-volang.svg';
import '../../assets/styles/desktop/typeBus.scss';
import ModalAlertBalance from '../../components/Modal/ModalAlertBalance';
import ModalAlertBalanceAgent from '../../components/Modal/ModalAlertBalanceAgent';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant, DateFormat, USER_GROUP, YearFormat } from '../../config/constant';
import {
  convertTimeFormat,
  formatDate,
  getColorBookingTrip,
  getStatusBookingTrip,
  isPermission,
} from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/slice/authSlice';
import {
  createBookingSelector,
  filterBookingCustomer,
  resetBooking,
  resetPriceBooking,
} from '../../redux/slice/bookings/createBookingSlice';
import { destroyBooking } from '../../redux/slice/bookings/destroyBookingSlice';
import { getTicketCodeBooking } from '../../redux/slice/bookings/getTicketCodeSlice';
import {
  holdingBookingSelector,
  postHoldingTicket,
  resetHoldingBooking,
  setBookingType,
} from '../../redux/slice/bookings/holdingBookingSlice';
import {
  getBookingInfo,
  getBookingSearch,
  getListBookingDetail,
  getListFilterBooking,
  listBookingSelector,
  resetDetectLoading,
  resetListBooking,
  setDataSearchBooking,
} from '../../redux/slice/bookings/listBooking';
import { routeSelector } from '../../redux/slice/routeSlice';
import { handleGetUrlGuide } from '../../utils/helpers';
import BookingScheduleModal from './BookingScheduleModal';
import BusRentContract from './BusContractBooking';
import CustomerList from './CustomerList';
import DetailScheduleBooking from './DetailScheduleBooking';
import BusDiagram from './diagrams/BusDiagram';
import ModalPrint from './documentBooking/ModalPrint';
import ModalBooking from './ModalBooking';
import ModalHistoryBooking from './ModalHistoryBooking';
import ModalMultiBooking from './ModalMultiBooking';
import ScheduleBookingList from './ScheduleBookingList';

function BusBooking() {
  const { t } = useTranslation();
  const { loading: loadingRoute } = useAppSelector(routeSelector);
  const {
    listBooking: dataListBooking,
    listFilterBooking,
    listBookingSearch,
    loadingListBooking,
    noLoading,
    listBookingDetail,
    loading: loadingDetail,
    dataSearchBooking,
    loadingRoute: loadingFilterRoute,
    extra,
    isMoveTicket,
    dataMoveTicket,
  } = useAppSelector(listBookingSelector);
  const accountUser = useAppSelector((state) => state.authAccount?.user);
  const { group } = useAppSelector(selectAuth);
  const { status: statusHolding, dataBooking } = useAppSelector(holdingBookingSelector);
  const { bookingType } = useAppSelector(holdingBookingSelector);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const listRoute = listFilterBooking?.length > 0 && listFilterBooking?.map((item: any) => item?.route);
  const { message } = useAppSelector(createBookingSelector);
  const dispatch = useAppDispatch();
  const [formSearch]: [FormInstance] = Form.useForm();
  const [listBooking, setListBooking] = useState<any>();
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [openModalSchedule, setOpenModalSchedule] = useState(false);
  const [openBusContract, setOpenBusContract] = useState(false);
  const [openDetailScheduleBooking, setOpenDetailScheduleBooking] = useState(false);
  const [provinceId, setProvinceId] = useState<number | undefined>();
  const [reserveCount, setReserveCount] = useState(0);
  const [cancelCount, setCancelCount] = useState(0);
  const [driverCount, setDriverCount] = useState(0);
  const [agentCount, setAgentCount] = useState(0);
  const [carCount, setCarCount] = useState(0);
  const [carlinkAPICount, setCarlinkAPICount] = useState(0);
  const [paymentCount, setPaymentCount] = useState(0);
  const [isContract, setIsContract] = useState(false);
  const [departTime, setDepartTime] = useState<string | undefined>();
  const [activeKey, setActiveKey] = useState<string>('1');
  const [isGetHistory, setIsGetHistory] = useState(false);
  const [keywordTicket, setKeywordTicket] = useState<any>({});
  const [departTimeOptions, setDepartTimeOptions] = useState<any>([]);
  const [openPrintModalBooking, setOpenPrintModalBooking] = useState(false);
  const [openModalMulti, setOpenModalMulti] = useState(false);
  const [targetTime, setTargetTime] = useState(0);
  const [ticketCode, setTicketCode] = useState('');
  const [isBookingNoDiagram, setIsBookingNoDiagram] = useState<boolean>(false);
  const [isSearchBooking, setIsSearchBooking] = useState<boolean>(false);
  const [openModalAlertAgent, setOpenModalAlertAgent] = useState(false);
  const [statusFloor, setStatusFloor] = useState<number>(1);
  const [isDetail, setIsDetail] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const searchDateBooking = dayjs(Form.useWatch('date_booking', formSearch)).format(YearFormat);
  const dateSelect = dayjs(Form.useWatch('date_booking', formSearch));
  const routeId = Form.useWatch('route_id', formSearch);
  const timeBooking = dayjs(`${listBooking?.travel_info?.arrive_time}T${listBooking?.schedule?.arrive_time}`);
  const now = dayjs();
  const limit = DEFAULT_PAGE_SIZE;
  const isBooking = timeBooking.isBefore(now);
  const refs = useRef<any>({});

  const scrollToTicket = (code: string) => {
    setTimeout(() => {
      const element = refs.current[code];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, Constant.TIMER_SEARCH);
  };

  const cancelModalHistory = () => {
    setOpenModalHistory(false);
    setIsGetHistory(false);
  };

  const cancelModalSchedule = () => {
    setOpenModalSchedule(false);
  };
  const cancelModalContract = () => {
    setOpenBusContract(false);
    setIsContract(false);
    dispatch(resetBooking());
  };
  const cancelModalDetailSchedule = () => {
    setOpenDetailScheduleBooking(false);
  };
  const cancelModalMulti = () => {
    setOpenModalMulti(false);
    setIsBookingNoDiagram(false);
    setTicketCode('');
    if (!listBooking?.schedule?.is_diagram) {
    }
  };
  const resetTargetTicket = (value: any) => {};

  const handleSearch = () => {};

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Sơ đồ',
      children: (
        <BusDiagram
          listBooking={listBooking}
          formSearch={formSearch}
          openDetailScheduleBooking={openDetailScheduleBooking}
          isContract={isContract}
          statusFloor={statusFloor}
          setStatusFloor={setStatusFloor}
          refs={refs}
        />
      ),
    },
    {
      key: '2',
      label: 'Hành khách',
      children: <CustomerList listBooking={listBooking} formSearch={formSearch} activeKey={activeKey} />,
    },
    {
      key: '3',
      label: 'Trung chuyển',
      children: <ScheduleBookingList listBooking={listBooking} formSearch={formSearch} />,
    },
  ];
  const itemNotDiagram: TabsProps['items'] = [
    {
      key: '1',
      label: 'Hành khách',
      children: <CustomerList listBooking={listBooking} formSearch={formSearch} activeKey={activeKey} />,
    },
    {
      key: '2',
      label: 'Trung chuyển',
      children: <ScheduleBookingList listBooking={listBooking} formSearch={formSearch} />,
    },
  ];
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  function onSearchRoute() {}
  function onSearchTicket(value: any) {
    setKeywordTicket(value);
  }
  const cancelModalAlertAgent = () => {
    setOpenModalAlertAgent(false);
  };
  const filterOptionRoute = (
    input: string,
    option?: {
      label: string;
      value: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const onChangeRoute = (value: number) => {
    setProvinceId(value);
    const selectedRoute = listFilterBooking.find((item: any) => item.route.id === value);
    if (selectedRoute) {
      const updatedOptions = selectedRoute.depart_times.map((departTime: string, index: number) => {
        const emptySeats = selectedRoute.empty_seats[index];
        const isDiagrams = selectedRoute.is_diagram[index];
        dispatch(setDataSearchBooking({ empty_seat: selectedRoute.empty_seats[0] }));
        return {
          value: departTime,
          label: (
            <>
              {convertTimeFormat(departTime)} (Còn {emptySeats} ghế trống)
              {!isDiagrams && <span className="required"></span>}
            </>
          ),
          key: `${departTime}_${index}`,
        };
      });
      setDepartTime(updatedOptions[0]?.value);

      formSearch.setFieldsValue({
        depart_time: updatedOptions[0]?.value,
        keyword: undefined,
      });
      dispatch(setDataSearchBooking({ depart_time: updatedOptions[0]?.value }));
      setStatusFloor(1);
      setDepartTimeOptions(updatedOptions);
    }
    setActiveKey('1');
    dispatch(setDataSearchBooking({ province_id: value }));
  };
  useEffect(() => {
    if (routeId && listFilterBooking?.length > 0 && dataSearchBooking?.depart_date) {
      const selectedRoute = listFilterBooking?.find((item: any) => item?.route?.id === routeId);
      if (selectedRoute) {
        const updatedOptions = selectedRoute?.depart_times?.map((departTime: string, index: number) => {
          const emptySeats = selectedRoute?.empty_seats[index];
          const isDiagrams = selectedRoute.is_diagram[index];
          return {
            value: departTime,
            label: (
              <>
                {convertTimeFormat(departTime)} (Còn {emptySeats} ghế trống)
                {!isDiagrams && <span className="required"></span>}
              </>
            ),
            key: `${departTime}_${index}`,
          };
        });
        setDepartTime(dataSearchBooking?.depart_time);
        setDepartTimeOptions(updatedOptions);
      }
      setActiveKey('1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeId, listFilterBooking, formSearch]);

  const onChangeTicket = () => {
    setIsSearchBooking(true);
  };
  const handleOkModalBooking = () => {};
  const onChangeDepartTime = (value: string) => {
    setDepartTime(value);
    setStatusFloor(1);
    setActiveKey('1');
    const selectedRoute = listFilterBooking?.find((item: any) => item.route.id === provinceId);
    if (selectedRoute) {
      const i = selectedRoute.depart_times.findIndex((time: any) => time === value);
      const empty = i !== -1 ? selectedRoute.empty_seats[i] : selectedRoute.empty_seats[0];
      dispatch(setDataSearchBooking({ empty_seat: empty }));
    }
  };

  const handleOpenSchedule = () => {
    setOpenModalSchedule(true);
  };
  useEffect(() => {
    if (listBooking && listBooking?.booking && listBooking?.booking.data) {
      const reserveBookings = listBooking.booking.data.filter(
        (entry: any) =>
          (entry?.customer &&
            (entry.payment_method === 'reserve' || entry?.payment_method === 'paid_at_company') &&
            entry.status !== 'cancel') ||
          (entry?.payment_method === 'bank_transfer' &&
            (entry?.source === 'openapi' || entry?.source === 'company') &&
            entry?.status === 'reserve'),
      );
      setReserveCount(reserveBookings?.length ?? 0);
      const paymentBookings = listBooking.booking.data.filter(
        (entry: any) => entry?.customer && entry.status === 'paid' && entry.status !== 'cancel',
      );
      setPaymentCount(paymentBookings?.length ?? 0);
      setCancelCount(listBooking?.cancel_booking?.length ?? 0);
      const driverBookings = listBooking.booking.data.filter((entry: any) => entry?.customer && entry.is_driver_pay);
      setDriverCount(driverBookings?.length ?? 0);
      const agentBooking = listBooking.booking.data.filter(
        (entry: any) => entry?.customer && entry.payment_method === 'agent_balance' && !entry.is_driver_pay,
      );
      const carlinkAPIBookings = listBooking.booking.data.filter(
        (entry: any) =>
          entry?.customer && (entry?.source === 'openapi' || entry?.source === 'company') && entry?.status === 'paid',
      );
      setCarlinkAPICount(carlinkAPIBookings?.length ?? 0);
      setAgentCount(agentBooking?.length ?? 0);
      const carBooking = listBooking.booking.data.filter(
        (entry: any) =>
          entry?.customer &&
          (entry.payment_method === 'cash' || entry.payment_method === 'bank_transfer') &&
          !entry.is_driver_pay &&
          entry?.status !== 'reserve',
      );
      setCarCount(carBooking?.length ?? 0);
    }

    if (!listBooking?.schedule?.id) {
      setIsSearchBooking(false);
    }
  }, [listBooking]);
  useEffect(() => {
    if (searchDateBooking && routeId && departTime) {
      dispatch(resetDetectLoading());
      dispatch(
        setDataSearchBooking({ depart_time: departTime, depart_date: searchDateBooking, province_id: provinceId }),
      );
      dispatch(
        getBookingInfo({
          route_id: routeId,
          depart_date: searchDateBooking,
          depart_time: convertTimeFormat(departTime),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchDateBooking, routeId, departTime]);
  useEffect(() => {
    if ((!provinceId && !departTime && dataSearchBooking?.depart_date) || !searchDateBooking) {
      formSearch.setFieldsValue({
        route_id: dataSearchBooking?.province_id,
        date_booking: dayjs(dataSearchBooking?.depart_date),
        depart_time: dataSearchBooking?.depart_time,
      });
    }
  }, [provinceId, departTime, dataSearchBooking, formSearch, searchDateBooking]);
  useEffect(() => {
    if (searchDateBooking) {
      dispatch(getListFilterBooking({ depart_date: searchDateBooking }));
    }
    if (!dataSearchBooking?.province_id && !dataSearchBooking?.depart_time) {
      dispatch(resetListBooking());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchDateBooking]);

  useEffect(() => {
    if (listFilterBooking?.length) {
      const selectedRoute = listFilterBooking?.find((item: any) => item.route.id === provinceId);
      if (selectedRoute) {
        const updatedOptions = selectedRoute.depart_times.map((departTime: string, index: number) => {
          const emptySeats = selectedRoute.empty_seats[index];
          const isDiagrams = selectedRoute.is_diagram[index];
          dispatch(setDataSearchBooking({ empty_seat: selectedRoute.empty_seats[0] }));
          return {
            value: departTime,
            label: (
              <>
                {convertTimeFormat(departTime)} (Còn {emptySeats} ghế trống)
                {!isDiagrams && <span className="required"></span>}
              </>
            ),
            key: `${departTime}_${index}`,
          };
        });
        setDepartTimeOptions(updatedOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFilterBooking, provinceId, formSearch]);
  useEffect(() => {
    dispatch(getBookingSearch({ keyword: keywordTicket, limit }));
  }, [dispatch, keywordTicket, limit]);
  useEffect(() => {
    if (listBooking) {
      formSearch.setFieldValue('list_booking', listBooking);
      formSearch.setFieldValue('bus_type_id', listBooking?.schedule?.bus_type?.name);
    }
  }, [formSearch, listBooking, provinceId]);

  const driversList = listBooking?.trip?.drivers?.length
    ? listBooking?.trip?.drivers?.map((driver: any) => `${driver.name} (${driver.phone})`).join(', ')
    : '';
  const extraDriverList = listBooking?.trip?.extra_drivers?.length
    ? listBooking?.trip?.extra_drivers?.map((extraDriver: any) => `${extraDriver.name}`).join(', ')
    : '';
  const onDetailSearch = (
    routeId: number,
    depart_Date: string,
    departTime: string,
    customerPhone: string,
    ticketCode: string,
    isDiagram?: boolean,
    status?: string,
    floor_ticket?: string,
  ) => {
    formSearch.setFieldsValue({
      keyword: customerPhone,
      route_id: routeId,
      depart_time: departTime,
      date_booking: dayjs(depart_Date),
    });
    if (status === 'cancel') {
      scrollToTicket('cancel');
    } else {
      if (statusFloor === 1 && floor_ticket === 'floor2') {
        setStatusFloor(2);
      }
      if (statusFloor === 2 && floor_ticket === 'floor1') {
        setStatusFloor(1);
      }
      scrollToTicket(ticketCode);
      setIsDetail(true);
      setOpenDetail(true);
      dispatch(getListBookingDetail({ ticket_code: ticketCode, is_diagram: isDiagram }));
    }
    dispatch(resetDetectLoading());
    setProvinceId(routeId);
    dispatch(setDataSearchBooking({ depart_time: departTime, depart_date: depart_Date, province_id: routeId }));
    dispatch(
      getBookingInfo({
        route_id: routeId,
        depart_date: depart_Date,
        depart_time: convertTimeFormat(departTime),
      }),
    );
  };
  const cancelModal = () => {
    setIsDetail(false);
    setOpenDetail(false);
    dispatch(resetPriceBooking());
  };
  const handleCreateContract = () => {
    setOpenBusContract(true);
    dispatch(filterBookingCustomer({}));
    dispatch(setBookingType('contract'));
    setIsContract(true);
    const createBookingData = {
      schedule_id: +listBooking?.schedule?.id,
      hold_type: 'contract',
      depart_date: searchDateBooking,
    };
    dispatch(postHoldingTicket(createBookingData));
  };
  const handleOpenNoDiagram = () => {
    setOpenModalMulti(true);
    setIsBookingNoDiagram(true);
    setTargetTime(new Date().getTime() + 5 * 60 * 1000);
    if (!listBooking?.schedule?.is_diagram) {
      dispatch(getTicketCodeBooking(1));
    }
  };
  const filteredItems = items.filter((item) => {
    if (group === USER_GROUP.AGENT && item.key === '3') {
      return false;
    }
    return true;
  });
  const filteredItemsNotDiagram = itemNotDiagram.filter((item) => {
    if (group === USER_GROUP.AGENT && item.key === '2') {
      return false;
    }
    return true;
  });
  useEffect(() => {
    if (dataListBooking) {
      setListBooking(dataListBooking);
    }
  }, [dataListBooking, departTime, isSearchBooking]);

  useEffect(() => {
    if (statusHolding === Constant.DEFAULT_STATUS) {
      if (bookingType === 'contract') resetHoldingBooking();
    }
    if (bookingType === 'contract' && !(statusHolding === 200 || statusHolding === 0)) {
      setOpenBusContract(false);
      dispatch(resetHoldingBooking());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusHolding]);

  // const useBeforeUnload = (message: string, onUnloadConfirmed: () => void) => {
  //   const dispatch = useAppDispatch();
  //   useEffect(() => {
  //     const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //       event.preventDefault();
  //       onUnloadConfirmed();
  //       event.returnValue = message;
  //       return message;
  //     };
  //     window.addEventListener('beforeunload', handleBeforeUnload);
  //     return () => {
  //       window.removeEventListener('beforeunload', handleBeforeUnload);
  //     };
  //   }, [message, onUnloadConfirmed, dispatch]);
  // };
  // const handleUnloadConfirmed = () => {
  //   if (dataBooking?.ticket_code) {
  //     dispatch(destroyBooking({ ticket_code: [dataBooking?.ticket_code] }));
  //   }
  // };
  // useBeforeUnload('Are you sure you want to leave? Any unsaved changes will be lost.', handleUnloadConfirmed);
  const cancelModalAlert = () => {
    setOpenModalAlert(false);
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day');
  };
  useEffect(() => {
    if (message === 'Tài khoản nhà xe không đủ để xuất vé.') {
      setOpenModalAlert(true);
      if (dataBooking?.ticket_code) {
        dispatch(
          destroyBooking({
            ticket_code: [dataBooking?.ticket_code],
          }),
        );
      }
      setOpenBusContract(false);
      dispatch(resetBooking());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <Layout className="min-vh-100 list_user agent-container bus-booking-container">
      <Spin spinning={loadingListBooking && !noLoading}>
        <Card>
          <Form
            className="mg-16 form-search-booking"
            form={formSearch}
            labelCol={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24 }}
            requiredMark={false}
            onFinish={handleSearch}
            colon={false}
          >
            <Row className="w-100">
              <Form.Item className="label-search w-100" labelAlign="left" labelCol={{ md: 0 }} name="form-search">
                <Row gutter={12}>
                  <Col span={5}>
                    <Form.Item name="list_booking" className="d-none">
                      <Input />
                    </Form.Item>
                    <Form.Item name="empty_seat" className="d-none">
                      <Input />
                    </Form.Item>
                    <Form.Item name="keyword">
                      <Select
                        placeholder={t('Nhập SĐT / mã vé để tìm kiếm')}
                        showSearch
                        disabled={isMoveTicket && dataMoveTicket?.ticket_code}
                        className="search-ticket"
                        suffixIcon={<SearchOutlined className="ic-search-input" />}
                        onSearch={onSearchTicket}
                        onChange={onChangeTicket}
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                          String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          String(option.props.value).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        size="large"
                        style={{ width: '100%' }}
                        getPopupContainer={() => {
                          let ticketSearch: any = document.body.getElementsByClassName('search-ticket');
                          return ticketSearch[0];
                        }}
                        optionLabelProp="value"
                      >
                        {listBookingSearch?.length > 0 &&
                          listBookingSearch?.map((item: any) => {
                            const optionValue =
                              item?.source === 'BusBooking'
                                ? `${item?.ticket_code}`
                                : `${item?.ticket_code}(${item?.customer_phone})`;
                            return (
                              <Option key={optionValue} value={optionValue}>
                                <div
                                  onClick={() =>
                                    onDetailSearch(
                                      item?.schedule?.route?.id,
                                      item?.depart_date,
                                      item?.schedule?.depart_time,
                                      item?.customer_phone,
                                      item?.ticket_code,
                                      item?.schedule?.is_diagram,
                                      item?.status,
                                      item?.floor_ticket,
                                    )
                                  }
                                >
                                  <Space className="ticket-search-header">
                                    <Typography className="fs-16 fw-600 d-flex" style={{ columnGap: '4px' }}>
                                      {`${item?.ticket_code}`}
                                      <Typography className="fw-500">{`(${
                                        item?.source === 'BusBooking' ? 'N/A' : item?.customer_phone
                                      })`}</Typography>
                                    </Typography>
                                  </Space>
                                  <Typography>{`${item?.schedule?.route?.depart_point} - ${item?.schedule?.route?.arrive_point}`}</Typography>
                                  <Space className="ticket-search-header">
                                    <Typography>{`${convertTimeFormat(item?.schedule?.depart_time)} | ${formatDate(
                                      item?.depart_date,
                                      'DD/MM/YYYY',
                                    )}`}</Typography>
                                    <Button
                                      className={`btn-booking-search ${
                                        item?.status === 'cancel'
                                          ? 'cancel'
                                          : item?.status === 'reserve'
                                          ? 'reserve'
                                          : 'payment'
                                      }`}
                                    >
                                      <span className="btn-search-content">{`${
                                        item?.status === 'cancel'
                                          ? 'Vé hủy'
                                          : item?.status === 'reserve'
                                          ? 'Đang giữ chỗ'
                                          : 'Đã thanh toán'
                                      }`}</span>
                                    </Button>
                                  </Space>
                                </div>
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name="date_booking" className="data-picker booking">
                      <DatePicker
                        className="w-100 input-height"
                        format={DateFormat}
                        placeholder={t('Chọn ngày đi')}
                        disabledDate={isMoveTicket ? disabledDate : undefined}
                        changeOnBlur
                        defaultValue={dayjs(new Date())}
                        allowClear={false}
                        cellRender={(current: any) => {
                          // @ts-ignore
                          let d = Lunar.fromDate(new Date(current));
                          const lunar = d.getDay();
                          const lunarMonth = d.getMonth();
                          return (
                            <div>
                              <div className="fs-14 fw-500">{current.date()}</div>
                              <div className="fs-12 lunar fw-500">
                                {lunar}
                                {
                                  // eslint-disable-next-line
                                  lunar == 1 || current.date() == 1 ? `/${lunarMonth}` : null
                                }
                              </div>
                            </div>
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="route_id" className="form-item-name">
                      <Select
                        className="input-h36"
                        loading={loadingRoute || loadingFilterRoute}
                        showSearch
                        disabled={isMoveTicket && dataMoveTicket?.ticket_code}
                        placeholder="Chọn tuyến"
                        onSearch={onSearchRoute}
                        onChange={(value: any) => onChangeRoute(value)}
                        filterOption={filterOptionRoute}
                        size="large"
                        style={{ width: '100%' }}
                        options={
                          listRoute?.length
                            ? listRoute?.map((item: any) => {
                                return {
                                  value: item.id,
                                  label: `${item.depart_point} - ${item.arrive_point}`,
                                };
                              })
                            : []
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="depart_time" className="form-item-name required depart-time">
                      <Select
                        className="input-h36"
                        onChange={(value: any) => onChangeDepartTime(value)}
                        filterOption={filterOptionRoute}
                        size="large"
                        style={{ width: '100%' }}
                        loading={!listBooking?.schedule?.bus_type?.seat_map?.seat_number}
                        getPopupContainer={() => {
                          let ticketSearch: any = document.body.getElementsByClassName('depart-time');
                          return ticketSearch[0];
                        }}
                        options={
                          departTimeOptions?.length
                            ? departTimeOptions?.map((item: any) => {
                                return {
                                  value: item?.value,
                                  label: item?.label,
                                  key: item?.key,
                                };
                              })
                            : []
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="bus_type_id"
                      className="form-item-name required"
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input className="input-h36 fw-500" placeholder="Loại xe" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Row>
          </Form>
          {listBooking?.schedule?.id ? (
            <>
              <Space style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
                <Row gutter={12}>
                  <div>
                    <Col className="d-flex items-center" style={{ columnGap: '7px' }}>
                      <Typography className="fw-600">{t('Thông tin chuyến')}</Typography>
                      {listBooking?.contract?.code && (
                        <>
                          <div
                            style={{
                              width: '4px',
                              height: '4px',
                              borderRadius: '50%',
                              backgroundColor: '#3a3a3a',
                              margin: '0px 5px',
                            }}
                          ></div>
                          <Button className="fs-16 fw-600 btn-success" style={{ color: '#fff', marginLeft: '5px' }}>
                            XE HỢP ĐỒNG
                          </Button>
                        </>
                      )}
                      {!listBooking?.schedule?.is_diagram && listBooking?.schedule?.id && (
                        <span className="fs-14 fw-500 ml-15" style={{ color: '#EA8C00' }}>
                          <InfoCircleFilled style={{ color: '#EA8C00' }} /> Chuyến này không hỗ trợ chọn chỗ trước
                        </span>
                      )}
                    </Col>
                  </div>
                </Row>
                <div>
                  <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 9)} />
                </div>
              </Space>
              <Row gutter={12} className="mt-2">
                <Col>
                  <Typography className="history-info">
                    Tuyến:
                    <span className="history-detail">
                      {` ${listBooking?.schedule?.route?.depart_point ?? ''} - ${
                        listBooking?.schedule?.route?.arrive_point ?? ''
                      }`}
                    </span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="history-info">
                    Ngày đi:
                    <span className="history-detail">
                      {listBooking?.travel_info?.depart_date
                        ? ` ${formatDate(listBooking?.travel_info?.depart_date, 'DD/MM/YYYY')}`
                        : ''}
                    </span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="history-info">
                    Giờ đi:
                    <span className="history-detail">{` ${convertTimeFormat(
                      listBooking?.schedule?.depart_time,
                    )}`}</span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="history-info">
                    Ngày đến:
                    <span className="history-detail">
                      {` ${formatDate(listBooking?.travel_info?.arrive_time, 'DD/MM/YYYY')}`}
                    </span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="history-info">
                    Giờ đến:
                    <span className="history-detail">{` ${convertTimeFormat(
                      listBooking?.schedule?.arrive_time,
                    )}`}</span>
                  </Typography>
                </Col>
              </Row>
              {listBooking?.contract?.code && (
                <Row gutter={20} className="mt-2">
                  <Col>
                    <Typography className="fw-500">
                      {t('Mã hợp đồng')}: <span className="fw-600">{listBooking?.contract?.code}</span>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography className="fw-500">
                      {t('Giá trị hợp đồng')}:
                      <span className="fw-600">{` ${
                        listBooking?.contract?.contract_value ? `${listBooking?.contract?.contract_value}đ` : '0đ'
                      }`}</span>
                    </Typography>
                  </Col>
                  {listBooking?.trip?.status === 'canceled' && (
                    <>
                      <span className="fs-14 fw-600" style={{ color: '#D81717' }}>
                        (Chuyến đã hủy)
                      </span>
                    </>
                  )}
                </Row>
              )}
              <Row gutter={13} className={listBooking?.contract?.code ? 'mt-2' : 'mt-2'}>
                <Col>
                  <Typography className="fw-500">
                    {t('Tài xế')}: <span className="fw-600">{driversList}</span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="fw-500">
                    {t('Phụ xe')}: <span className="fw-600">{extraDriverList}</span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="fw-500">
                    {t('Biển số xe')}: <span className="fw-600">{listBooking?.trip?.bus?.plate_number}</span>
                  </Typography>
                </Col>
                {!listBooking?.contract && (
                  <>
                    <Col>
                      <Typography className="fw-500">
                        {t('Tỉ lệ lấp đầy')}:
                        <span className="fw-600">
                          {listBooking?.schedule?.bus_type?.seat_map?.seat_number
                            ? ` ${paymentCount + reserveCount}/${
                                listBooking?.schedule?.bus_type?.seat_map?.seat_number ?? ''
                              }`
                            : ''}
                        </span>
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className="fw-500">
                        {t('Tổng tiền')}:
                        <span className="fw-600">
                          {`   ${
                            listBooking?.booking?.commission_total
                              ? listBooking?.booking?.commission_total
                                ? `${listBooking?.booking?.commission_total}đ`
                                : '0đ'
                              : ''
                          } `}
                        </span>
                      </Typography>
                    </Col>
                  </>
                )}
                <Col>
                  <Typography className="fw-500">
                    {t('Tình trạng')}:
                    <span
                      className={`fw-600 ${
                        dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                        listBooking?.schedule?.open_before === 0
                          ? 'red'
                          : listBooking
                          ? getColorBookingTrip(listBooking?.trip?.status)
                          : ''
                      }`}
                    >
                      {dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                      listBooking?.schedule?.open_before === 0
                        ? ' Ghế chưa mở bán'
                        : listBooking
                        ? ` ${getStatusBookingTrip(listBooking?.trip?.status)}`
                        : ''}
                    </span>
                  </Typography>
                </Col>
              </Row>
              <Row className="mt-2" gutter={12}>
                {!listBooking?.schedule?.is_diagram && (
                  <Col>
                    <Button
                      className="fw-500 h-btn-36 btn-success w-100 text-white"
                      htmlType="button"
                      onClick={() => handleOpenNoDiagram()}
                      disabled={
                        !listBooking?.schedule?.id ||
                        isBooking ||
                        listBooking?.trip?.status === 'canceled' ||
                        listBooking?.contract?.code ||
                        extra?.is_lock_all
                      }
                    >
                      <Typography>{t('Đặt vé')}</Typography>
                    </Button>
                  </Col>
                )}
                {group !== USER_GROUP.AGENT && (
                  <>
                    <Col>
                      <Button
                        className="fw-500 h-btn-36 gray-border-btn"
                        htmlType="button"
                        onClick={() => setOpenDetailScheduleBooking(true)}
                        disabled={
                          !listBooking?.schedule?.id || !isPermission(accountUser?.permissions, 'manager_operating')
                        }
                      >
                        <Typography>
                          <FormOutlined /> {t('Điều hành chuyến')}
                        </Typography>
                      </Button>
                    </Col>
                    {listBooking?.schedule?.is_diagram && (
                      <Col>
                        {listBooking?.schedule?.id ? (
                          <Button
                            className="fw-500 h-btn-36 gray-border-btn"
                            htmlType="button"
                            onClick={() => setOpenPrintModalBooking(true)}
                          >
                            <Typography>
                              <PrinterOutlined />
                              {' In phơi'}
                            </Typography>
                          </Button>
                        ) : (
                          <Button
                            className="fw-500 h-btn-36 gray-border-btn"
                            htmlType="button"
                            disabled={!listBooking?.schedule?.id}
                          >
                            <Typography>
                              <PrinterOutlined />
                              {' In phơi'}
                            </Typography>
                          </Button>
                        )}
                      </Col>
                    )}
                  </>
                )}
                <Col>
                  <Button
                    className="fw-500 h-btn-36 gray-border-btn"
                    htmlType="button"
                    onClick={() => {
                      setOpenModalHistory(true);
                      setIsGetHistory(true);
                    }}
                  >
                    <Typography>
                      <HistoryOutlined />
                      {t(' Lịch sử thao tác vé')}
                    </Typography>
                  </Button>
                </Col>
                {listBooking?.schedule?.is_contract && (
                  <Col>
                    <Button
                      className="fw-500 h-btn-36 gray-border-btn"
                      htmlType="button"
                      onClick={() => handleCreateContract()}
                      disabled={
                        !listBooking?.schedule?.id ||
                        listBooking?.trip?.status === 'departed' ||
                        isBooking ||
                        listBooking?.trip?.status === 'canceled' ||
                        listBooking?.contract?.code ||
                        listBooking?.booking?.data?.length > 0
                      }
                    >
                      <Typography>
                        <FileTextOutlined />
                        {t(' Hợp đồng thuê xe')}
                      </Typography>
                    </Button>
                  </Col>
                )}
                <Col>
                  <Button
                    className="fw-500 h-btn-36 gray-border-btn"
                    htmlType="button"
                    onClick={() => handleOpenSchedule()}
                    disabled={!listBooking?.schedule?.id}
                  >
                    <Typography>{t(' Xem lịch trình')}</Typography>
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3 w-100">
                <Col className="floor-block w-100">
                  <div className="status-seat">
                    {+activeKey !== 1 || (+activeKey === 1 && !listBooking?.schedule?.is_diagram) ? (
                      <>
                        <Space style={{ columnGap: '12px', alignItems: 'end', justifyContent: 'center' }}>
                          <div className="d-flex block-status-ticket" style={{ columnGap: '6px' }}>
                            <Typography className="align-center fw-500">
                              <img src={iconCarGroup} alt="" />
                              {`${t('Nhà xe thu:')} (${carCount})`}
                            </Typography>
                            <Typography className="align-center fw-500">
                              <img src={iconAgent} alt="" />
                              {`${t('Đại lý thu:')} (${agentCount})`}
                            </Typography>
                            <Typography className="align-center fw-500">
                              <img src={iconDriver} alt="" />
                              {`${t('Tài xế thu:')} (${driverCount})`}
                            </Typography>
                            <Typography className="align-center fw-500">
                              <img src={iconCarlinkApi} alt="" />
                              {`${t('Carlink API thu:')} (${carlinkAPICount})`}
                            </Typography>
                          </div>
                          <div className="d-flex block-status-ticket" style={{ columnGap: '6px' }}>
                            <Typography className="align-center fw-500">
                              <FontAwesomeIcon icon={faCircle} className="yellow" size="xs" />
                              {`${t('Đang giữ chỗ:')} (${reserveCount})`}
                            </Typography>
                            <Typography className="align-center fw-500">
                              <FontAwesomeIcon icon={faCircle} style={{ color: '#D81717' }} size="xs" />
                              {`${t('Đã hủy:')} (${cancelCount})`}
                            </Typography>
                            <Typography className="fw-500">
                              {t('Tổng vé đã đặt:')} {paymentCount + reserveCount}
                            </Typography>
                          </div>
                        </Space>
                      </>
                    ) : (
                      <>
                        <Space style={{ columnGap: '12px', alignItems: 'end', justifyContent: 'center' }}>
                          <div className="d-flex block-status-ticket" style={{ columnGap: '6px' }}>
                            <Typography className="align-center fw-500">
                              <img src={iconCarGroup} alt="" />
                              {`${t('Nhà xe thu:')} (${carCount})`}
                            </Typography>
                            <Typography className="align-center fw-500">
                              <img src={iconAgent} alt="" />
                              {`${t('Đại lý thu:')} (${agentCount})`}
                            </Typography>
                            <Typography className="align-center fw-500">
                              <img src={iconDriver} alt="" />
                              {`${t('Tài xế thu:')} (${driverCount})`}
                            </Typography>
                            <Typography className="align-center fw-500">
                              <img src={iconCarlinkApi} alt="" />
                              {`${t('Carlink API thu:')} (${carlinkAPICount})`}
                            </Typography>
                          </div>
                          <div className="d-flex block-status-ticket" style={{ columnGap: '6px' }}>
                            <Typography className="align-center fw-500">
                              <FontAwesomeIcon icon={faCircle} className="yellow" size="xs" />
                              {`${t('Đang giữ chỗ:')} (${reserveCount})`}
                            </Typography>
                            <Typography className="align-center fw-500">
                              <FontAwesomeIcon icon={faCircle} style={{ color: '#D81717' }} size="xs" />
                              {`${t('Đã hủy:')} (${cancelCount})`}
                            </Typography>
                            <Typography className="fw-500">
                              {t('Tổng vé đã đặt:')} {paymentCount + reserveCount}
                            </Typography>
                          </div>
                        </Space>
                      </>
                    )}
                  </div>
                  <Tabs
                    defaultActiveKey={'1'}
                    activeKey={activeKey}
                    className="tabs-booking"
                    items={!listBooking?.schedule?.is_diagram ? filteredItemsNotDiagram : filteredItems}
                    onChange={onChange}
                    tabBarGutter={2}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Typography className="fs-16 fw-500 text-manual">
                {`Bạn vui lòng chọn ngày -> chọn tuyến -> chọn chuyến để đặt vé (nhập mã vé, số điện thoại để tìm kiếm)`}
              </Typography>
              <Typography className="fs-16 fw-600 mt-3">Hướng dẫn đặt vé</Typography>
              <Row>
                <Col span={1} className="d-flex justify-content-center align-center row-gap5 mt-2 mw-18">
                  <div className="d-flex justify-content-center align-center row-gap5">
                    <img src={iconDote} alt="" />
                    <img src={iconLine} alt="" />
                  </div>
                  <div className="d-flex justify-content-center align-center row-gap5">
                    <img src={iconDote} alt="" />
                    <img src={iconLine} alt="" />
                  </div>
                  <div className="d-flex justify-content-center align-center">
                    <img src={iconDote} alt="" />
                  </div>
                </Col>
                <Col span={16} className="fw-500 fs-16 mt-2 ml-3">
                  <Typography>Chọn chỗ mong muốn trên sơ đồ để đặt vé</Typography>
                  <p></p>
                  <Typography>Điền thông tin theo yêu cầu và chọn phương thức thanh toán</Typography>
                  <p></p>
                  <Typography className="mt-2">Bấm đặt vé để hoàn tất</Typography>
                </Col>
              </Row>
              <Row className="mt-3 align-center">
                <Col span={16}>
                  <Image preview={false} src={iconManual} width={885} />
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Spin>
      <ModalHistoryBooking
        modalOpen={openModalHistory}
        onOk={() => setOpenModalHistory(false)}
        onCancel={cancelModalHistory}
        listBooking={listBooking}
        isGetHistory={isGetHistory}
      />
      <BookingScheduleModal
        modalOpen={openModalSchedule}
        onOk={() => setOpenModalSchedule(false)}
        onCancel={cancelModalSchedule}
        formSearch={formSearch}
      />
      <BusRentContract
        modalOpen={openBusContract}
        onOk={() => false}
        onCancel={cancelModalContract}
        listBooking={listBooking}
        formSearch={formSearch}
        okText="Lưu"
        cancelText="Đóng"
      />
      <DetailScheduleBooking
        modalOpen={openDetailScheduleBooking}
        onOk={() => false}
        onCancel={cancelModalDetailSchedule}
        okText="Lưu"
        cancelText="Đóng"
        listBooking={listBooking}
        formSearch={formSearch}
      />
      <ModalPrint
        modalOpen={openPrintModalBooking}
        onOk={() => false}
        okText="Lưu"
        cancelText="Đóng"
        onCancel={() => setOpenPrintModalBooking(false)}
        listBooking={listBooking}
        group={group}
        searchDateBooking={searchDateBooking}
      />
      <ModalAlertBalance modalOpen={openModalAlert} onCancel={cancelModalAlert} onOk={() => setOpenModalAlert(false)} />

      <ModalMultiBooking
        okText="Đặt vé"
        modalOpen={openModalMulti}
        onCancel={cancelModalMulti}
        cancelText="Đóng"
        onOk={() => false}
        listBooking={listBooking}
        formSearch={formSearch}
        targetTime={targetTime}
        setTargetTicket={resetTargetTicket}
        isNotDiagram={true}
        ticketCode={ticketCode}
        isBookingNoDiagram={isBookingNoDiagram}
      />
      <ModalBooking
        okText={'Lưu'}
        modalOpen={openDetail}
        onCancel={cancelModal}
        cancelText="Đóng"
        onOk={handleOkModalBooking}
        listBooking={listBooking}
        formSearch={formSearch}
        isDetail={isDetail}
        listBookingDetail={listBookingDetail}
        loading={loadingDetail}
      />
      <ModalAlertBalanceAgent
        modalOpen={openModalAlertAgent}
        onCancel={cancelModalAlertAgent}
        onOk={() => setOpenModalAlertAgent(false)}
      />
    </Layout>
  );
}
export default BusBooking;
