import {getAxiosClientWithToken} from "./index";
// import { listPolicyRequest, officeParams } from "../types/office";
import { listPolicyRequest, policyParams } from "../types/policy";

export const getListPolicyAPI = (params: listPolicyRequest) => {
    return getAxiosClientWithToken().get(`/policies?`, {params});
};

export const createPolicyAPI = (params: policyParams) => {
    return getAxiosClientWithToken().post(`/policies`, params);
};

export const updatePolicyAPI = (id: any, params: policyParams) => {
    return getAxiosClientWithToken().put(`policies/${id}`, params);
};

export const deletePolicyAPI = (id: string | number) => {
    return getAxiosClientWithToken().delete(`policies/${id}`);
};

export const getPolicyByIdAPI = (id: any) => {
    return getAxiosClientWithToken().get(`policies/${id}`)
}

export const getPolicyStatusByIdAPI = (id:any,  params: any) => {
    return getAxiosClientWithToken().put(`policies/status/${id}`, params)
}

// export const getPolicyStatusByIdApi = (id: any, params: any) => {
//     return getAxiosClientWithToken().put(`/policies/status/${id}`, params)
// }
