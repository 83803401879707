import { getListReportTotalRouteAPI, getListReportTotalRouteExcelAPI } from "../../../api/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParamsReport, ReportTotalRouteState } from "../../../types/report";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    listRoute: [],
    total_prices: 0,
    total_bookings: 0,
    total_prices_paid_at_company: 0,
    total_prices_agent_balance: 0,
    total_commissions: 0,
    filter: {
        booking_date: 'false',
        from: '',
        to: '',
        route_id: '',
    }
} as ReportTotalRouteState;

export const reportTotalRouteAgentSlice = createSlice({
    name: 'reportTotalRouteAgent',
    initialState,
    reducers: {
        setFilterBookingDate(state, action: PayloadAction<string>) {
            state.filter.booking_date = action.payload;
        },
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setFilterRoute(state, action: PayloadAction<number | string>) {
            state.filter.route_id = action.payload;
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportTotalRouteAgent.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportTotalRouteAgent.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_commissions = action.payload?.total_commissions;
                state.total_prices = action.payload?.total_prices;
                state.total_bookings = action.payload?.total_bookings;
                state.total_prices_paid_at_company = action.payload?.total_prices_paid_at_company;
                state.total_prices_agent_balance = action.payload?.total_prices_agent_balance;
                
            }).addCase(getListReportTotalRouteAgent.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportTotalRouteAgentExcel.pending, (state) => {
            }).addCase(getListReportTotalRouteAgentExcel.fulfilled, (state, action) => {
            }).addCase(getListReportTotalRouteAgentExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach(fieldName => {
                        toast.error(`${[listErros[fieldName]]}`)
                    })
                }
            })
    }
})

export const getListReportTotalRouteAgent = createAsyncThunk('ReportTotalRouteAgent/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportTotalRouteAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportTotalRouteAgentExcel = createAsyncThunk('ReportTotalRouteAgent/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportTotalRouteExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setPerPage, setFilterBookingDate, setFilterRangeDate, setFilterRoute } = reportTotalRouteAgentSlice.actions;