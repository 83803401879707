import React, { useState } from 'react';
import { OrderTicketItemParams } from '../../../types/order';
import { Button, ConfigProvider, Flex, Modal } from 'antd';
import '../styles/ModalTicket.scss';
import iconClose from '../../../assets/images/listUser/iconsClose.svg';
import { formatPrice } from '../../../utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Constant, USER_GROUP } from '../../../config/constant';
import iconCheck from '../../../assets/images/icons/icon-check.svg';
import iconCancel from '../../../assets/images/icons/icon-cancel.svg';
import avatar from '../../../assets/images/orders/avatar.png';
import iconHistory from '../../../assets/images/orders/icon-history.svg';
import ticketCancel from '../../../assets/images/orders/ticket-cancel.svg';
import ticketReserve from '../../../assets/images/orders/ticket-reserve.svg';
import ticketPaid from '../../../assets/images/orders/ticket-paid.svg';
import iconPhone from '../../../assets/images/orders/icon-phone.svg';
import iconEmail from '../../../assets/images/orders/icon-email.svg';
import iconPrint from '../../../assets/images/orders/icon-print.svg';
import moment from 'moment';
import ModalExport from './ModalExport';
import { cancelTicketAPI, exportTicketAPI } from '../api';
import { toast } from 'react-toastify';
import ModalCancel from './ModalCancel';
import ModalHistoryTicket from './ModalHistoryTicket';
import ModalPrintTicket from '../../bookings/tickets/ModalPrintTicket';
import { resetReloadDetail, setReloadDetail } from '../../../redux/slice/authSlice';
import { setTimer } from '../../../redux/slice/orders/ordersTicketSlice';
import { getAmountOrder } from '../../../redux/slice/orders/ordersPendingSlide';
import { handleGetCancelReason } from '../models/constants';
import { getSendBookingMail } from '../../../redux/slice/bookings/sendMailSlice';

interface ModalTicketProps {
  ticketData: OrderTicketItemParams;
  setOpenModalTicket: React.Dispatch<React.SetStateAction<boolean>>;
  openModalTicket: boolean;
  getListTicket: () => Promise<void>;
}

const ModalTicket = ({ ticketData, getListTicket, setOpenModalTicket, openModalTicket }: ModalTicketProps) => {
  const dispatch = useAppDispatch();
  const accountDetail = useAppSelector((state) => state.authAccount?.user);

  const [openModalExport, setOpenModalExport] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);

  const [openModalHistory, setOpenModalHistory] = useState<boolean>(false);
  const [openModalPrint, setOpenModalPrint] = useState<boolean>(false);

  const handleExportTicket = async (ticket_code: string) => {
    setLoadingExport(true);
    try {
      const res = await exportTicketAPI(ticket_code);
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        getListTicket();
        setOpenModalExport(false);
        setOpenModalTicket(false);
        dispatch(getAmountOrder());
      } else {
        toast.error('Có lỗi xảy ra vui lòng thử lại!');
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra vui lòng thử lại!');
    }
    setLoadingExport(false);
  };

  const handleCancelTicket = async ({ cancel_note }: { cancel_note: string }) => {
    setLoadingCancel(true);
    try {
      const res = await cancelTicketAPI({
        ticket_code: ticketData?.ticket_code,
        cancel_note,
      });
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        getListTicket();
        dispatch(getAmountOrder());
        setOpenModalTicket(false);
      } else {
        toast.error('Có lỗi xảy ra vui lòng thử lại!');
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra vui lòng thử lại!');
    }
    setLoadingCancel(false);
  };
  return (
    <Modal
      footer={null}
      maskClosable={false}
      open={openModalTicket}
      onCancel={() => setOpenModalTicket(false)}
      width={960}
      closeIcon={false}
      rootClassName="ModalTicket"
    >
      <Flex className="mb-2" align="center" justify="space-between">
        <p className="ModalTicket__title mb-0">Thông tin vé</p>
        <Flex className="ModalTicket__close" align="center" justify="center" onClick={() => setOpenModalTicket(false)}>
          <img className="object-fit-scale" width={24} height={24} src={iconClose} alt="" />
        </Flex>
      </Flex>
      <Flex align="center" className="gap-x-40 text-color mb-16">
        <p className="mb-0 fw-500">
          Mã vé: <span className="ml-2 fw-600">{ticketData?.ticket_code}</span>
        </p>
        <Flex className="gap-x-8 text-color" align="center">
          <p className="mb-0 fw-500">Giá vé: </p>
          <p className="mb-0 fw-600">{formatPrice(ticketData?.price, 0, ',')}đ</p>
          {!!ticketData?.price && (
            <p className="mb-0 fw-500">
              {ticketData?.payment_method === 'bank_transfer' ? '(Chuyển khoản ngân hàng)' : ''}
            </p>
          )}
        </Flex>
      </Flex>
      <Flex className="gap-x-8 mb-20" align="center">
        {accountDetail?.group !== USER_GROUP.CARLINK && ticketData?.status === 'reserve' && (
          <Button
            className="ModalOrderInfo__button__done d-flex align-items-center gap-x-8 h-auto"
            type="primary"
            onClick={() => setOpenModalExport(true)}
            loading={loadingExport}
          >
            <img width={16} height={16} className="object-fit-scale" src={iconCheck} alt="" />
            <p className="mb-0 text-white fw-500 leading-20">Xuất vé</p>
          </Button>
        )}
        {ticketData?.status !== 'reserve' && (
          <ConfigProvider theme={{ token: { colorPrimary: '#435869' } }}>
            <Button
              className="ModalOrderInfo__button__history d-flex align-items-center gap-x-8 h-auto"
              type="primary"
              onClick={() => setOpenModalHistory(true)}
            >
              <img width={16} height={16} className="object-fit-scale" src={iconHistory} alt="" />
              <p className="mb-0 text-color-default fw-500 leading-20">Lịch sử thao tác vé</p>
            </Button>
          </ConfigProvider>
        )}
        {ticketData?.status !== 'reserve' && (
          <>
            <Button
              className="ModalOrderInfo__button__history d-flex align-items-center gap-x-8 h-auto fs-14"
              loading={loadingExport}
              onClick={() => {
                dispatch(getSendBookingMail({ ticket_code: ticketData?.ticket_code }));
              }}
            >
              <img width={16} height={16} className="object-fit-scale" src={iconEmail} alt="" />
              <span className="fs-14 font-medium text-blue">Gửi mail</span>
            </Button>
          </>
        )}
        {ticketData?.status === 'paid' && (
          <ConfigProvider theme={{ token: { colorPrimary: '#435869' } }}>
            <Button
              className="ModalOrderInfo__button__history d-flex align-items-center gap-x-8 h-auto"
              type="primary"
              onClick={() => {
                setOpenModalPrint(true);
                dispatch(setReloadDetail());
                dispatch(setTimer(false));
              }}
            >
              <img width={16} height={16} className="object-fit-scale" src={iconPrint} alt="" />
              <p className="mb-0 text-color-default fw-500 leading-20">In vé</p>
            </Button>
          </ConfigProvider>
        )}
        {accountDetail?.group !== USER_GROUP.CARLINK && ticketData?.status !== 'cancel' && (
          <Button
            loading={loadingCancel}
            className="ModalOrderInfo__button__cancel d-flex align-items-center gap-x-8 h-auto"
            type="primary"
            onClick={() => setOpenModalCancel(true)}
          >
            <img width={16} height={16} className="object-fit-scale" src={iconCancel} alt="" />
            <p className="mb-0 fw-500 leading-20">Hủy vé</p>
          </Button>
        )}
      </Flex>
      <div className="ModalTicket__ticket">
        <div className="Ticket">
          <Flex className="gap-x-20 mb-20" justify="space-between" align="center">
            <Flex className="gap-x-16" align="center">
              <Flex className="Ticket__avatar" align="center" justify="center">
                <img
                  width={40}
                  height={40}
                  className="object-fit-cover"
                  src={accountDetail?.group === USER_GROUP.CAR_COMPANY ? accountDetail?.car_company?.logo : avatar}
                  alt=""
                />
              </Flex>
              <Flex justify="space-between" className="flex-column h-100">
                <Flex align="center">
                  <p className="text-color fw-600 fs-16 leading-24 mb-0">{ticketData?.customer?.name}</p>
                  {ticketData?.status === 'cancel' && (
                    <Flex className="gap-x-8 ml-32" align="center">
                      <img width={14} height={14} className="object-fit-scale" src={ticketCancel} alt="" />
                      <p className="mb-0 text-danger fw-500">
                        {ticketData?.cancel_reason
                          ? handleGetCancelReason(ticketData?.cancel_reason, ticketData?.payer)
                          : ''}
                      </p>
                    </Flex>
                  )}
                  {ticketData?.status === 'paid' && (
                    <Flex className="gap-x-8 ml-32" align="center">
                      <img width={14} height={14} className="object-fit-scale" src={ticketPaid} alt="" />
                      <p className="mb-0 text-color-green-default fw-500">Đã xuất vé!</p>
                    </Flex>
                  )}
                  {ticketData?.status === 'reserve' && (
                    <Flex className="gap-x-8 ml-32" align="center">
                      <img width={14} height={14} className="object-fit-scale" src={ticketReserve} alt="" />
                      <p style={{ color: '#009EF8' }} className="mb-0 fw-500">
                        Đang chờ xác nhận thanh toán!
                      </p>
                    </Flex>
                  )}
                </Flex>
                <Flex className="gap-x-28" align="center">
                  <Flex className="gap-x-8" align="center">
                    <img width={20} height={20} className="object-fit-scale" src={iconPhone} alt="" />
                    <p className="fs-16 fw-400 leading-24 text-color mb-0">{ticketData?.customer?.phone}</p>
                  </Flex>
                  <Flex className="gap-x-8" align="center">
                    <img width={20} height={20} className="object-fit-scale" src={iconEmail} alt="" />
                    <p className="fs-16 fw-400 leading-24 text-color mb-0">{ticketData?.customer?.email}</p>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex className="flex-column gap-y-12">
            <Flex align="start">
              <p className="Ticket__property">Tuyến</p>
              <p className="Ticket__desc">
                {ticketData?.route?.depart_point} - {ticketData?.route?.arrive_point}
              </p>
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Ngày, giờ đi</p>
              <p className="Ticket__desc">
                {moment(ticketData?.depart_time, 'HH:mm').format('HH:mm')} -{' '}
                {moment(ticketData?.depart_date).format('DD/MM/YYYY')}
              </p>
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Ngày, giờ đến</p>
              <p className="Ticket__desc">
                {moment(ticketData?.arrive_time, 'HH:mm').format('HH:mm')} -{' '}
                {moment(ticketData?.arrive_date).format('DD/MM/YYYY')}
              </p>
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Điểm đón</p>
              <p className="Ticket__desc">
                {`${
                  ticketData?.pickup_extra
                    ? moment(ticketData?.depart_time, 'HH:mm').format('HH:mm')
                    : moment(ticketData?.pickup_location?.time, 'HH:mm').format('HH:mm')
                } - ${
                  ticketData?.pickup_extra ? 'Điểm trung chuyển khác' : ticketData?.pickup_location?.location_name
                }`}
                <br />
                <span className="Ticket__desc__location">
                  {ticketData?.pickup_extra ? ticketData?.pickup_extra : ticketData?.pickup_location?.address}
                </span>
              </p>
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Điểm trả</p>
              <p className="Ticket__desc">
                {`${
                  ticketData?.drop_off_extra
                    ? moment(ticketData?.arrive_time, 'HH:mm').format('HH:mm')
                    : moment(ticketData?.drop_off_location?.time, 'HH:mm').format('HH:mm')
                } - ${
                  ticketData?.drop_off_extra ? 'Điểm trung chuyển khác' : ticketData?.drop_off_location?.location_name
                }`}
                <br />
                <span className="Ticket__desc__location">
                  {ticketData?.drop_off_extra ? ticketData?.drop_off_extra : ticketData?.drop_off_location?.address}
                </span>
              </p>
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Nhà xe</p>
              <p className="Ticket__desc">{ticketData?.company_name}</p>
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Loại xe</p>
              <p className="Ticket__desc">{ticketData?.bus_type}</p>
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Mã ghế</p>
              <p className="Ticket__desc">
                {ticketData?.is_diagram ? ticketData?.seat_number : 'Chuyến không hỗ trợ chọn chỗ'}
              </p>
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Bảo hiểm chuyến đi</p>
              {ticketData?.is_insurance ? (
                <p className="Ticket__desc">
                  Có{' '}
                  <span className="ml-4 text-color-green-default">
                    (+{formatPrice(ticketData?.insurance_fee, 0, ',')}đ)
                  </span>
                </p>
              ) : (
                <p className="Ticket__desc">Không</p>
              )}
            </Flex>
            <Flex align="start">
              <p className="Ticket__property">Giá vé</p>
              <p className="Ticket__desc">{formatPrice(ticketData?.price, 0, ',')}đ</p>
            </Flex>
            {accountDetail?.group !== USER_GROUP.CAR_COMPANY && (
              <Flex align="start">
                <p className="Ticket__property">Số tiền xuất vé</p>
                <p className="Ticket__desc">
                  {formatPrice(ticketData?.price_export, 0, ',')}đ{' '}
                  {accountDetail?.group === USER_GROUP.CARLINK
                    ? '(Số tiền trả cho nhà xe)'
                    : '(Số tiền trả cho Carlink API chưa bao gồm phí BH)'}
                </p>
              </Flex>
            )}
            <Flex align="start">
              <p className="Ticket__property">Ghi chú</p>
              <p className="Ticket__desc">{ticketData?.note}</p>
            </Flex>
          </Flex>
        </div>
      </div>
      {ticketData?.status !== 'reserve' && (
        <Flex className="mt-14 gap-x-8" align="center">
          <p className="mb-0 fw-500" style={{ color: '#647c8f' }}>
            Nhân viên xử lý:
          </p>
          <p className="mb-0 text-color-default fw-500">{ticketData?.payer}</p>
        </Flex>
      )}
      <ModalExport
        title="Xuất vé"
        desc="Bạn đang thao tác xuất vé này, bạn có chắc chắn muốn xuất?"
        open={openModalExport}
        setOpen={setOpenModalExport}
        onExport={() => handleExportTicket(ticketData?.ticket_code)}
      />
      <ModalCancel
        status={ticketData?.status}
        open={openModalCancel}
        setOpen={setOpenModalCancel}
        onCancelTicket={handleCancelTicket}
        ticket_code={ticketData?.ticket_code}
        total_price={ticketData?.price ? ticketData?.price : 0}
        refund={ticketData?.cancel_info?.refund ? ticketData?.cancel_info?.refund : 0}
        total_cancel_fee={ticketData?.cancel_info?.cancel_fee ? ticketData?.cancel_info?.cancel_fee : 0}
      />
      <ModalHistoryTicket
        openHistory={openModalHistory}
        setOpenHistory={setOpenModalHistory}
        ticket_item={ticketData}
      />
      <ModalPrintTicket
        modalOpen={openModalPrint}
        onCancel={() => {
          setOpenModalPrint(false);
          dispatch(resetReloadDetail());
          dispatch(setTimer(true));
        }}
        onOk={() => setOpenModalPrint(false)}
        listOrder={ticketData}
        isOrder={true}
      />
    </Modal>
  );
};

export default ModalTicket;
