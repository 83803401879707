import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getErrorMessage} from '../../../api';
import {updateAgentApi, updateAgentStatusApi, updateListAgentDetailApi} from '../../../api/agent';
import {AgentState, AgentUpdatePram} from '../../../types/agent';
import {RootState} from '../../store';
import {setToast} from '../toastNotifySlice';
import {Action, UPDATE_ERROR, UPDATE_SUCCESS} from '../../../config/message';
import {Toast} from '../../../config/constant';

// @ts-ignore

export const updateAgentDetail = createAsyncThunk(
    'admin/updateAgent/detail',
    async (params: AgentUpdatePram, {dispatch, rejectWithValue}) => {
        try {
            const response = await updateListAgentDetailApi(params);
            const {data, status} = response;
            if (status) {
                dispatch(setUpdateAgent({status: status, data: data}));
                dispatch(
                    setToast({
                        message: UPDATE_SUCCESS(Action.AGENT),
                        status: true,
                        type: Toast.TYPE_SUCCESS,
                    }),
                );
            }
            return true;
        } catch (error: any) {
            const {response} = error;
            dispatch(setUpdateErrorAgent(response));
            dispatch(
                setToast({
                    message: UPDATE_ERROR(Action.BUS),
                    status: true,
                    type: Toast.TYPE_ERROR,
                }),
            );
            return rejectWithValue(getErrorMessage(error));
        }
    },
);
export const updateAgentStatus = createAsyncThunk(
    'admin/updateAgentStatus',
    async (
        params: {
            id: string;
            status: boolean;
        },
        {dispatch, rejectWithValue},
    ) => {
        try {
            const response = await updateAgentStatusApi(params);
            const {data, status} = response;
            if (status) {
                dispatch(setUpdateAgent({status: status, data: data}));
                dispatch(
                    setToast({
                        message: UPDATE_SUCCESS(Action.AGENT),
                        status: true,
                        type: Toast.TYPE_SUCCESS,
                    }),
                );
            }
            return true;
        } catch (error: any) {
            const {response} = error;
            dispatch(setUpdateErrorAgent(response));
            dispatch(
                setToast({
                    message: UPDATE_ERROR(Action.BUS),
                    status: true,
                    type: Toast.TYPE_ERROR,
                }),
            );
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const updateAgent = createAsyncThunk(
    'admin/updateAgent',
    async (
        params: any,
        {dispatch, rejectWithValue},
    ) => {
        try {
            const response = await updateAgentApi(params);
            const {data, status} = response;
            if (status) {
                dispatch(setUpdateAgent({status: status, data: data}));
                dispatch(
                    setToast({
                        message: UPDATE_SUCCESS(Action.AGENT),
                        status: true,
                        type: Toast.TYPE_SUCCESS,
                    }),
                );
            }
            return true;
        } catch (error: any) {
            const {response} = error;
            dispatch(setUpdateErrorAgent(response));
            dispatch(
                setToast({
                    message: UPDATE_ERROR(Action.BUS),
                    status: true,
                    type: Toast.TYPE_ERROR,
                }),
            );
            return rejectWithValue(getErrorMessage(error));
        }
    },
);
export const updateAgentSlice = createSlice({
    name: 'updateAgent',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        message: '',
        errorDetail: null,
    } as AgentState,
    reducers: {
        setUpdateAgent: (state: AgentState, {payload}) => {
            const {message} = payload.data;
            state.status = payload?.status;
            state.message = message;
        },
        setUpdateErrorAgent: (state: AgentState, {payload}) => {
            const {errors} = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetUpdateAgent: (state: AgentState) => {
            state.success = false;
            state.message = '';
            state.errorDetail = null;
            state.status = 0;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateAgentDetail.pending, (state: AgentState) => {
            state.loading = true;
        });
        builder.addCase(updateAgentDetail.fulfilled, (state: AgentState, res) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateAgentDetail.rejected, (state: AgentState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });

        builder.addCase(updateAgentStatus.pending, (state: AgentState) => {
            state.loading = true;
        });
        builder.addCase(updateAgentStatus.fulfilled, (state: AgentState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateAgentStatus.rejected, (state: AgentState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });
    },
});

export const updateAgentSelector = (state: RootState) => state.updateAgentSlice;
export const {setUpdateAgent, setUpdateErrorAgent, resetUpdateAgent} = updateAgentSlice.actions;
