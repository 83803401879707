/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import "../../../../config/i18n/i18n";
import "../../../../assets/styles/listUsers.scss";
import {
  phoneRules,
  validatorEmail,
  validatorEmailOptional,
} from "../../../../config/validators/validators";
import { validateMobileUser } from "../../../../config/validators/validateUser";
import { getListDriverAPI } from "../../../../api/driver";
import { USER_GROUP } from "../../../../config/constant";
import { hasManagerCompanyAccount } from "../../../../lib/utils";

const EditInfoUserContact: React.FC<any> = ({
  detailUser,
  handleOnchangeInput,
  setActiveThree,
  activeThree,
  callUpdateUserAPI,
  setDetailUser,
  infoUser,
  checkManagerAccount,
  setActiveKey
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [type, setType] = useState<any>("");
  const [listDriver, setListDriver] = useState([]);

  const onLoad = () => {
    form.setFieldsValue({
      ...detailUser,
      driver_id: detailUser?.driver?.id,
    });
  };

  const handleListDriver = async () => {
    try {
      const res = await getListDriverAPI({ type });
      if (res.status === 200) {
        setListDriver(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (infoUser?.group === USER_GROUP.CAR_COMPANY) {
      handleListDriver();
    }
  }, [type]);

  const listSelectDriver = listDriver.map((item: any) => {
    return { value: item?.id, label: item?.name };
  });

  const placementChange = (e: any) => {
    setType(e.target.value);
  };

  const onFinish = async () => {
    // if(infoUser.group !== USER_GROUP.CAR_COMPANY || !hasManagerCompanyAccount(detailUser?.permissions)) {
    // }else {
    //   setActiveKey("4");
    // }
    await callUpdateUserAPI();
    setActiveThree(false);
  };

  const filteredObjects: any = listDriver.filter((obj: any) => obj.id === detailUser?.driver_id);

  useEffect(() => {
    onLoad();
  }, [detailUser?.driver_id]);
  
  useEffect(() => {
    if (activeThree) {
      form.submit();
      setActiveThree(false);
    }
  }, [activeThree]);
  return (
    <Form
      name="create-user-contact"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      // validateTrigger="onSubmit"
      className="form-edit-user contact create-user-contact form-create-user"
    >
      <div className="container-form-user">
        {detailUser.permissions[0] === "driver" && (
          <Row>
            <Col>
              <Form.Item
                name="driver_id"
                className="form-item-name required item-driver"
                label={t("Tài xế, phụ xe")}
              >
                <Select
                  style={{ width: 244 }}
                  value={detailUser.driver_id}
                  onChange={(value) => {
                    const filterPhone: any = listDriver.filter((obj: any) => obj.id === value);
                    setDetailUser({ ...detailUser, driver_id: value, mobile: filterPhone[0]?.phone })
                  }
                  }
                  placeholder="Tài xế, phụ xe"
                  dropdownRender={(menu) => (
                    <>
                      <Radio.Group
                        style={{ margin: "2px 5px 6px" }}
                        value={type}
                        onChange={placementChange}
                      >
                        <Radio.Button value="driver">
                          {t("Tài xế")}
                        </Radio.Button>
                        <Radio.Button value="extra_driver">
                          {t("Phụ xe")}
                        </Radio.Button>
                      </Radio.Group>
                      {menu}
                    </>
                  )}
                  options={listSelectDriver}
                />
                <Button disabled className="btn-driver">{filteredObjects[0]?.type === "driver" ? "Tài xế" : "Phụ xe"}</Button>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="mobile"
              
              className="form-item-name required"
              label={t("Điện thoại đi động")}
              rules={validateMobileUser}
              validateFirst
            >
              <Input
                disabled={detailUser.permissions[0] === "driver" ? detailUser.permissions[0] === "driver" : !checkManagerAccount()}
                name="mobile"
                value={detailUser?.mobile}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="phone"
              className="form-item-name"
              label={t("Điện thoại cố định")}
              rules={phoneRules}
            >
              <Input
                name="phone"
                value={detailUser?.phone}
                onChange={handleOnchangeInput}
                disabled={!checkManagerAccount()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="email"
              className= {detailUser?.is_admin ? "required" : ""}
              label={t("Email")}
              rules={detailUser?.is_admin ? validatorEmail : validatorEmailOptional}
              validateFirst
            >
              <Input
                name="email"
                value={detailUser?.email}
                onChange={handleOnchangeInput}
                disabled={!checkManagerAccount()}
              />
            </Form.Item>
          </Col>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="fax" label={t("Fax")}>
              <Input
                name="fax"
                value={detailUser?.fax}
                onChange={handleOnchangeInput}
                disabled={!checkManagerAccount()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="whatapp" label={t("WhatApp")}>
              <Input
                name="whatapp"
                value={detailUser?.whatapp}
                onChange={handleOnchangeInput}
                disabled={!checkManagerAccount()}
              />
            </Form.Item>
          </Col>
          <Col xl={11} xxl={11} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="skype" label={t("Skype")}>
              <Input
                name="skype"
                value={detailUser?.skype}
                onChange={handleOnchangeInput}
                disabled={!checkManagerAccount()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="address"
              className="field-note"
              label={t("Địa chỉ")}
              style={{ marginBottom: 0 }}
            >
              <Input
                name="address"
                value={detailUser?.address}
                onChange={handleOnchangeInput}
                disabled={!checkManagerAccount()}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default EditInfoUserContact;
