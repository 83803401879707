import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from "../store";
import { IDDriver, ListDriverRequest, ListDriverState, UpdateDriver } from "../../types";
import { Constant } from "../../config/constant";
import {
    createDriverAPI,
    getDriverAPI,
    getListDriverAPI,
    updateDriverAPI,
    updateStatusDriverAPI
} from "../../api/driver";
import { getErrorMessage } from "../../api";
import { toast } from "react-toastify";


// @ts-ignore

//Create driver
export const createDriver = createAsyncThunk('driver/create', async (params: DriverParams, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await createDriverAPI(params);
        if (response?.data?.success) {
            toast.success(response?.data?.message);
            return response?.data;
        }
    } catch (error: any) {
        const { response } = error;
        toast.error(response?.data?.message)
        dispatch(setError(response));
        return rejectWithValue(getErrorMessage(error));
    }
});

export const updateDriver = createAsyncThunk('driver/update', async (params: UpdateDriver, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateDriverAPI(params.id, params);
        if (response?.data?.success) {
            toast.success(response?.data?.message);
            return response?.data;
        }
    } catch (error: any) {
        const { response } = error;
        toast.error(response?.data?.message);
        dispatch(setError(response));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

// List driver
export const fetchListDriver = createAsyncThunk('driver/list', async (params: ListDriverRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getListDriverAPI(params);
        const {
            data,
            success,
            pagination
        } = response?.data;
        if (success) {
            dispatch(setListDriver({ drivers: data, pagination: pagination }));
            return true;
        }
    } catch (error: any) {
        dispatch(setListDriver(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const fetchListExtraDriver = createAsyncThunk('extra_driver/list', async (params: ListDriverRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getListDriverAPI(params);
        const {
            data,
            success,
            pagination
        } = response?.data;
        if (success) {
            dispatch(setListExtraDriver({ drivers: data, pagination: pagination }));
            return true;
        }
    } catch (error: any) {
        dispatch(setListExtraDriver(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const fetchInfoDriver = createAsyncThunk('driver/find', async (params: IDDriver, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getDriverAPI(params);

        const {
            data,
            success,
        } = response?.data;
        if (success) {
            dispatch(setDriver(data));
            return true;
        }
    } catch (error: any) {
        dispatch(setDriver(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const updateStatusDriver = createAsyncThunk('driver/update-status', async (params: UpdateDriver, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateStatusDriverAPI(params.id, params.status, params?.reason, params?.type);
        const {
            success,
        } = response?.data;

        if (success) {
            // dispatch(setDriver({status: success, data: data}));
            return response.data;
        }
    } catch (error: any) {
        dispatch(setDriver(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const driverSlice = createSlice({
    name: 'listDriver',
    initialState: {
        error: false,
        loading: false,
        success: false,
        successUpdate: false,
        status: false,
        message: '',
        errorDetail: null,
        drivers: [],
        extra_drivers: [],
        driver: {
            id: null,
            name: null,
            date_of_birth: '',
            license_class: null,
            date_expired_license: '',
            portrait_photo: '',
            license_front: '',
            license_rear: '',
            phone: null,
            type: null,
            note: null,
            company: null,
            status: undefined,
        },
        pagination: {
            total: 0,
            per_page: Constant.PAGE_SIZE,
            current_page: Constant.DEFAULT_PAGE,
            last_page: Constant.DEFAULT_PAGE,
        },
    } as ListDriverState,
    reducers: {
        setListDriver: (state: ListDriverState, { payload }) => {
            const { drivers, pagination } = payload;
            state.drivers = drivers;
            state.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setListExtraDriver: (state: ListDriverState, { payload }) => {
            const { drivers, pagination } = payload;
            state.extra_drivers = drivers;
            state.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setDriver: (state: ListDriverState, { payload }) => {
            state.driver = payload;
        },
        setError: (state: ListDriverState, { payload }) => {
            const { errors } = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetState: (state: ListDriverState) => {
            state.success = false;
            state.message = '';
            state.errorDetail = null;
        },
    },
    extraReducers: (builder) => {
        //Create driver
        builder.addCase(createDriver.pending, (state: ListDriverState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(createDriver.fulfilled, (state: ListDriverState, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.driver = action.payload.data;
        });
        builder.addCase(createDriver.rejected, (state: ListDriverState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });

        // Update Driver
        builder.addCase(updateDriver.pending, (state: ListDriverState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(updateDriver.fulfilled, (state: ListDriverState, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.message = action?.payload?.message;
            state.driver = action.payload.data;
        });
        builder.addCase(updateDriver.rejected, (state: ListDriverState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });


        // Update status Driver
        builder.addCase(updateStatusDriver.pending, (state: ListDriverState) => {
            state.loading = true;
            state.successUpdate = false;
        });
        builder.addCase(updateStatusDriver.fulfilled, (state: ListDriverState, action) => {
            state.loading = false;
            state.successUpdate = true;
            state.error = false;
            state.message = action?.payload?.message;
            state.driver = action.payload.data;
        });
        builder.addCase(updateStatusDriver.rejected, (state: ListDriverState) => {
            state.loading = false;
            state.successUpdate = false;
            state.error = true;
        });

        // List driver
        builder.addCase(fetchListDriver.pending, (state: ListDriverState) => {
            state.loading = true;
        });
        builder.addCase(fetchListDriver.fulfilled, (state: ListDriverState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(fetchListDriver.rejected, (state: ListDriverState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });

        // Info Driver
        builder.addCase(fetchInfoDriver.pending, (state: ListDriverState) => {
            state.loading = true;
        });
        builder.addCase(fetchInfoDriver.fulfilled, (state: ListDriverState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(fetchInfoDriver.rejected, (state: ListDriverState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
    },
});

export const driverSelector = (state: RootState) => state.driver;
export const { setListDriver, setDriver, setListExtraDriver, resetState, setError } = driverSlice.actions;
