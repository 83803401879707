import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listOfficeRequest, listOfficeState } from "../../../types/office";
import { RootState } from "../../store";
import { getListOfficeAPI, getOfficeByIdAPI } from "../../../api/offices";
import { getErrorMessage } from "../../../api";
import { Constant } from "../../../config/constant";

export const fetchListOffice = createAsyncThunk(
  "admin/listOffice",
  async (params: listOfficeRequest, { dispatch, rejectWithValue }) => {
    //... logic xử lý và dispatch action
    try {
      const response = await getListOfficeAPI(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListOffice(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListOffice(error));
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getDetailOffice = createAsyncThunk(
  "admin/getDetailOffice",
  async (id: any, { dispatch, rejectWithValue }) => {
    //... logic xử lý và dispatch action
    try {
      const response = await getOfficeByIdAPI(id);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setDetailOffice(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setDetailOffice(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  }
);

export const listOfficeSlice = createSlice({
  name: "listPolicy",
  initialState: {
    error: false,
    loading: false,
    success: false,
    listOffices: [],
    detailOffice: {},
    pagination: {
      total: 0,
      per_page: 10,
      current_page: 1,
      last_page: 1,
    },
    status: 0,
  } as listOfficeState,
  reducers: {
    setListOffice: (state: listOfficeState, { payload }) => {
      //... logic xử lý khi set danh sách văn phòng
      state.listOffices = payload?.data;
      state.pagination = payload?.pagination;
    },
    setDetailOffice: (state: listOfficeState, { payload }) => {
      state.detailOffice = payload?.data;
    },
    setListOfficeLoad: (state: listOfficeState) => {
      //... logic xử lý khi loading danh sách văn phòng
      state.listOffices = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListOffice.pending, (state: listOfficeState) => {
      //... logic xử lý khi thunk đang chờ xử lý
      state.loading = true;
    });
    builder.addCase(fetchListOffice.fulfilled, (state: listOfficeState) => {
      //... logic xử lý khi thunk xử lý thành công
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchListOffice.rejected, (state: listOfficeState) => {
      //... logic xử lý khi thunk xử lý bị từ chối
      state.loading = false;
      state.success = false;
      state.error = true;
    });

    builder.addCase(getDetailOffice.pending, (state: listOfficeState) => {
      state.loading = true;
    });
    builder.addCase(getDetailOffice.fulfilled, (state: listOfficeState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getDetailOffice.rejected, (state: listOfficeState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const listOfficeSelector = (state: RootState) => state.listOffice;
export const { setListOffice, setListOfficeLoad, setDetailOffice } =
  listOfficeSlice.actions;
