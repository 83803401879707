import React from "react";
import { Col, Form, Row, Switch } from "antd";
import "../../../../config/i18n/i18n";
import { userProps } from "../../../../types";
import { TEXT_PERMISSIONS, USER_GROUP } from "../../../../config/constant";
import { useTranslation } from "react-i18next";

const EditUserPermissions: React.FC<userProps> = ({
  dataPermissions,
  detailUser,
  setDetailUser,
  infoUser,
}: any) => {
  const { t } = useTranslation();
 
  const [form] = Form.useForm();

  const handleDisabledCarlink = () => {
    if(detailUser?.is_admin && infoUser?.group === USER_GROUP.CARLINK) {
      return true;
    }else if(detailUser?.id === infoUser?.id) {
      return true;
    }else {
      return false;
    }
  }
  const handleDisabledCarCompany = () => {
    if(detailUser?.is_admin && infoUser?.group === USER_GROUP.CAR_COMPANY) {
      return true;
    }else if(detailUser?.id === infoUser?.id) {
      return true;
    }else {
      return false;
    }
  }

  const handleDisabledAgent = () => {
    if(detailUser?.is_admin && infoUser?.group === USER_GROUP.AGENT) {
      return true;
    }else if(detailUser?.id === infoUser?.id) {
      return true;
    }else {
      return false;
    }
  }

  const listPermissionCarlink =
    Object.keys(dataPermissions)?.length > 0 ? dataPermissions[TEXT_PERMISSIONS.CARLINK] : [];

  const objectDriver = { name: "driver", id: 999, title: "Quyền tài, phụ xe" };
  const listPermissionCar =
    Object.keys(dataPermissions)?.length > 0
      ? dataPermissions[TEXT_PERMISSIONS.CAR_COMPANY]
      : [];
  let newListPermissionCar = [...listPermissionCar];
  if(infoUser?.group === USER_GROUP.CAR_COMPANY) {
    newListPermissionCar = [...listPermissionCar, objectDriver];
  }

  const listPermissionAgent =
    Object.keys(dataPermissions)?.length > 0 ? dataPermissions[TEXT_PERMISSIONS.AGENT] : [];

  const listPermissionMainAgent =
    Object.keys(dataPermissions)?.length > 0 ? dataPermissions[TEXT_PERMISSIONS.MAIN_AGENT] : [];

  const handlePermissionGroup = () => {
    if (detailUser?.group === USER_GROUP.CARLINK) {
      return (
        <Col xxl={18} xl={18} xs={18}>
          {listPermissionCarlink &&
            listPermissionCarlink.length > 0 &&
            listPermissionCarlink.map((item: any) => {
              return (
                <Form.Item
                  className="m-0 ps-3 w-100 list-permission border-permission"
                  style={{ borderBottom: "0.5px solid #D3D3D3" }}
                  key={item?.id}
                  name={`${item?.name}`}
                  label={item?.title}
                >
                  <Switch
                    disabled={handleDisabledCarlink()}
                    defaultChecked={detailUser.permissions.includes(item?.name)}
                    onChange={(checked) => {
                      setDetailUser((prevState: any) => ({
                        ...prevState,
                        permissions: checked
                          ? [...prevState.permissions, item?.name]
                          : prevState.permissions.filter(
                              (permission: any) => permission !== item?.name
                            ),
                      }));
                    }}
                  />
                </Form.Item>
              );
            })}
        </Col>
      );
    } else if (detailUser?.group === USER_GROUP.CAR_COMPANY) {
      return (
        <Col xxl={18} xl={18} xs={18}>
        {newListPermissionCar &&
          newListPermissionCar?.length > 0 &&
          newListPermissionCar.map((item: any) => {
            return (
              <Form.Item
                className="m-0 ps-3 w-100 list-permission border-permission"
                style={{ borderBottom: "0.5px solid #D3D3D3" }}
                key={item?.id}
                name={`${item?.name}`}
                label={item?.title}
              >
                <Switch
                  disabled={handleDisabledCarCompany()}
                  checked={detailUser.permissions.includes(item?.name)}
                  onChange={(checked) => {
                    if (item?.name === "driver") {
                      setDetailUser((prevState: any) => ({
                        ...prevState,
                        permissions: checked ? ["driver"] : [],
                      }));
                    } else if (item?.name !== "driver") {
                      setDetailUser((prevState: any) => ({
                        ...prevState,
                        permissions: checked
                          ? [...prevState.permissions, item?.name].filter(
                              (permission: any) => permission !== "driver"
                            )
                          : prevState.permissions.filter(
                              (permission: any) => permission !== item?.name
                            ),
                      }));
                    }
                  }}
                />
              </Form.Item>
            );
          })}
      </Col>
      );
    } 
    else if (detailUser?.group === USER_GROUP.AGENT) {
      return (
        <Col xxl={18} xl={18} xs={18}>
          {listPermissionAgent &&
            listPermissionAgent?.length > 0 &&
            listPermissionAgent.map((item: any) => {
              return (
                <Form.Item
                  className="m-0 ps-3 w-100 list-permission border-permission"
                  style={{ borderBottom: "0.5px solid #D3D3D3" }}
                  key={item?.id}
                  name={`${item?.name}`}
                  label={item?.title}
                >
                  <Switch
                    disabled={handleDisabledAgent()}
                    defaultChecked={detailUser.permissions.includes(item?.name)}
                    onChange={(checked) => {
                      setDetailUser((prevState: any) => ({
                        ...prevState,
                        permissions: checked
                          ? [...prevState.permissions, item?.name]
                          : prevState.permissions.filter(
                              (permission: any) => permission !== item?.name
                            ),
                      }));
                    }}
                  />
                </Form.Item>
              );
            })}
        </Col>
      );
    }
    else if (detailUser?.group === USER_GROUP.MAIN_AGENT) {
      return (
        <Col xxl={18} xl={18} xs={18}>
          {listPermissionMainAgent &&
            listPermissionMainAgent?.length > 0 &&
            listPermissionMainAgent.map((item: any) => {
              return (
                <Form.Item
                  className="m-0 ps-3 w-100 list-permission border-permission"
                  style={{ borderBottom: "0.5px solid #D3D3D3" }}
                  key={item?.id}
                  name={`${item?.name}`}
                  label={item?.title}
                >
                  <Switch
                    disabled={handleDisabledAgent()}
                    defaultChecked={detailUser.permissions.includes(item?.name)}
                    onChange={(checked) => {
                      setDetailUser((prevState: any) => ({
                        ...prevState,
                        permissions: checked
                          ? [...prevState.permissions, item?.name]
                          : prevState.permissions.filter(
                              (permission: any) => permission !== item?.name
                            ),
                      }));
                    }}
                  />
                </Form.Item>
              );
            })}
        </Col>
      );
    }
  };

  const handleTextGroup = () => {
    if (detailUser?.group === USER_GROUP.CARLINK) {
      return t("Quyền Carlink");
    }
    if (detailUser?.group === USER_GROUP.CAR_COMPANY) {
      return t("Nhà xe");
    }
    if (detailUser?.group === USER_GROUP.AGENT) {
      return t("Đại lý");
    }
    if (detailUser?.group === USER_GROUP.MAIN_AGENT) {
      return t("Tổng đại lý");
    }
  };

  return (
    <Form
      name="user-permissions"
      form={form}
      autoComplete="off"
      validateTrigger="onSubmit"
      labelCol={{ span: 20 }}
      wrapperCol={{ span: 4 }}
      className="form-edit-user permission form-create-user"
    >
      <Row
        className={
          detailUser?.group === USER_GROUP.CAR_COMPANY
            ? "container-form-user h-container-user"
            : "container-form-user"
        }
      >
        <Col
          className="border d-flex justify-content-center align-items-center fs-20 fw-bold bg-green-light"
          span={6}
        >
          {handleTextGroup()}
        </Col>
        {handlePermissionGroup()}
      </Row>
    </Form>
  );
};

export default EditUserPermissions;
