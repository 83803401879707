import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { getListAgentActiveApi, getListAgentDetailApi } from '../../../api/agent';
import { getListAgentApi } from '../../../api/user';
import { Constant } from '../../../config/constant';
import { AgentState, ListAgentRequest } from '../../../types/agent';
import { RootState } from '../../store';

// @ts-ignore
export const getListAgent = createAsyncThunk(
  'admin/listAgent',
  async (params: ListAgentRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListAgentApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListAgent(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListAgent(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const getListAgentDetail = createAsyncThunk(
  'admin/listAgentDetail',
  async (id: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListAgentDetailApi(id);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListAgentDetail(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListAgentDetail(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const getListAgentActive = createAsyncThunk(
  'admin/listAgentActive',
  async (params: { company_id: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListAgentActiveApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListAgentActive(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const listAgentSlice = createSlice({
  name: 'listAgent',
  initialState: {
    error: false,
    loading: false,
    success: false,
    listAgent: [],
    pagination: [],
    status: 0,
    message: '',
    listAgentDetail: {},
    errorDetail: null,
    listAgentActive: {},
  } as AgentState,
  reducers: {
    setListAgent: (state: AgentState, { payload }) => {
      state.listAgent = payload?.data;
      state.pagination = payload?.pagination;
    },
    setListAgentDetail: (state: AgentState, { payload }) => {
      state.listAgentDetail = payload?.data;
    },
    setListAgentLoad: (state: AgentState) => {
      state.listAgent = [];
    },
    setListAgentActive: (state: AgentState, { payload }) => {
      state.listAgentActive = payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListAgent.pending, (state: AgentState) => {
      state.loading = true;
    });
    builder.addCase(getListAgent.fulfilled, (state: AgentState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListAgent.rejected, (state: AgentState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getListAgentDetail.pending, (state: AgentState) => {
      state.loading = true;
    });
    builder.addCase(getListAgentDetail.fulfilled, (state: AgentState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListAgentDetail.rejected, (state: AgentState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const listAgentSelector = (state: RootState) => state.listAgentSlice;
export const { setListAgent, setListAgentDetail, setListAgentLoad, setListAgentActive } = listAgentSlice.actions;
