import { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './assets/styles/app.scss';
import BaseLayout from './components/layouts/BaseLayout';
import Loader from './components/layouts/Loader';
import config from './config/routes';
import NotFound from './pages/Error404';
import Forbidden from './pages/Forbidden';
import InternalServer from './pages/InternalServer';
import AccountDeposit from './pages/accountDeposit/AccountDeposit';
import AccountDepositCarlink from './pages/accountDepositCarlink/AccountDepositCarlink';
import AccountDepositPromotion from './pages/accountDepositPromotion/AccountDepositPromotion';
import ManageAgent from './pages/agents/ManageAgent';
import ApiFollow from './pages/api/ApiFollow';
import ApiManagement from './pages/api/ApiManagement';
import BusBooking from './pages/bookings/BusBooking';
import ListContract from './pages/bookings/contracts/ListContract';
import ListTicket from './pages/bookings/tickets/ListTicket';
import ManageBusCompany from './pages/busCompanys/ManageBusCompany';
import ListBus from './pages/buses/ListBus';
import InfoAgent from './pages/client/InfoAgent';
import InfoCompany from './pages/client/InfoCompany';
import ListDriver from './pages/drivers/ListDriver';
import GeneralAgents from './pages/generalAgents/GeneralAgents';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import ManageCheking from './pages/manageEmployee/ManageChecking';
import ManageEmployee from './pages/manageEmployee/ManageEmployee';
import ManageSalary from './pages/manageEmployee/ManageSalary';
import ManageWork from './pages/manageEmployee/ManageWork';
import ManageSignUp from './pages/manageSignUp/ManageSignUp';
import ManageSystem from './pages/manageSystem/ManageSystem';
import Notify from './pages/notify/Notify';
import ListOffice from './pages/offices/ListOffice';
import PickupLocation from './pages/pickupLocation';
import ListPolicy from './pages/policies/ListPolicy';
import ReportBalanceChange from './pages/report/ReportBalanceChange';
import ReportCancel from './pages/report/ReportCancel';
import ReportContract from './pages/report/ReportContract';
import ReportCustomer from './pages/report/ReportCustomer';
import ReportDepositPromotionCompanyCarlink from './pages/report/ReportDepositPromotionCompanyCarlink';
import ReportDetail from './pages/report/ReportDetail';
import ReportDriver from './pages/report/ReportDriver';
import ReportExportTicket from './pages/report/ReportExportTicket';
import ReportFeeExportBus from './pages/report/ReportFeeDepart';
import ReportFeeDepartCompanyCarlink from './pages/report/ReportFeeDepartCompanyCarlink';
import ReportFeeExportTicketCompanyCarlink from './pages/report/ReportFeeExportTicketCompanyCarlink';
import ReportRechargeAgent from './pages/report/ReportRechargeAgent';
import ReportRechargeCarlink from './pages/report/ReportRechargeCarlink';
import ReportRechargeCompany from './pages/report/ReportRechargeCompany';
import ReportRechargeCompanyCarlink from './pages/report/ReportRechargeCompanyCarlink';
import ReportTotal from './pages/report/ReportTotal';
import ReportTotalAgent from './pages/report/ReportTotalAgent';
import ReportTotalRoute from './pages/report/ReportTotalRoute';
import ListRoute from './pages/routes';
import Schedule from './pages/schedules';
import ListService from './pages/services/ListService';
import SystemConfig from './pages/systemConfig/SystemConfig';
import SystemDepartureFee from './pages/systemDepartureFee/SystemDepartureFee';
import SystemGuide from './pages/systemGuide/SystemGuide';
import SettingSchedulePrice from './pages/tickets/SettingSchedulePrice';
import ListTrip from './pages/trips/ListTrips';
import TypeBus from './pages/typeBuses/TypeBus';
import ListUser from './pages/users/ListUser';
import DepositMainAgent from './pages/accountDepositCarlink/DepositMainAgent';
import InfoMainAgent from './pages/client/InfoMainAgent';
import OrderPending from './pages/orders/views/OrderPending';
import OrderProcessed from './pages/orders/views/OrderProcessed';
import HistoryDepositMainAgent from './pages/historyDepositMainAgent/HistoryDepositMainAgent';
import ReportSourceAPI from './pages/report/ReportSourceAPI';
import InsuranceConfig from './pages/insuranceConfig/InsuranceConfig';
import OrderTicket from './pages/orders/views/OrderTicket';
import ListRegisterAdvertise from './pages/listRegisterAdvertise/views/ListRegisterAdvertise';
import SettingPromotion from './pages/promotions/SettingPromotion';
import SegmentSetting from './pages/schedules/SegmentSetting';
import LocationSetting from './pages/schedules/LocationSetting';

const App = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      {/* <Select defaultValue="vi" style={{ width: 120 }} onChange={(value) => changeLanguage(value)}>
        <Option value="en">English</Option>
        <Option value="vi">Việt Nam</Option>
      </Select> */}
      <Routes>
        <Route path={config.routes.home} element={<BaseLayout />}>
          <Route path={config.routes.home} element={<Home />} />
          <Route path={config.routes.dashboard}>
            <Route path={config.routes.policy} element={<ListPolicy />} />
            <Route path={config.routes.companyInfo} element={<InfoCompany />} />
            <Route path={config.routes.agentInfo} element={<InfoAgent />} />
            <Route path={config.routes.mainAgentInfo} element={<InfoMainAgent />} />
            <Route path={config.routes.accountDeposit} element={<AccountDeposit />} />
            <Route path={config.routes.listRegisterTrial} element={<ManageSignUp />} />
            <Route path={config.routes.listRegisterAdvertise} element={<ListRegisterAdvertise />} />
            <Route path={config.routes.accountPromotion} element={<AccountDepositPromotion />} />
            <Route path={config.routes.accountDepositAgent} element={<AccountDeposit />} />
            <Route path={config.routes.accountDepositCarlink} element={<AccountDepositCarlink />} />
            <Route path={config.routes.accountDepositCarCompany} element={<AccountDepositCarlink />} />
            <Route path={config.routes.accountDepositMainAgent} element={<DepositMainAgent />} />
            <Route path={config.routes.historyDepositMainAgent} element={<HistoryDepositMainAgent />} />
            <Route path={config.routes.insuranceConfig} element={<InsuranceConfig />} />
            <Route path={config.routes.listPromotion} element={<SettingPromotion />} />
          </Route>
          <Route path={config.routes.userManagement}>
            <Route path={config.routes.listUser} element={<ListUser />} />
          </Route>
          <Route path={config.routes.operationManagement}>
            <Route path={config.routes.listBus} element={<ListBus />} />
            <Route path={config.routes.listService} element={<ListService />} />
            <Route path={config.routes.listDriver} element={<ListDriver />} />
            <Route path={config.routes.listRoute} element={<ListRoute />} />
            <Route path={config.routes.listTicketSetting} element={<SettingSchedulePrice />} />
            <Route path={config.routes.listPickUpLocation} element={<PickupLocation />} />
            <Route path={config.routes.listBusType} element={<TypeBus />} />
            <Route path={config.routes.listOffice} element={<ListOffice />} />
            <Route path={config.routes.listTrip} element={<ListTrip />} />
            <Route path={config.routes.listSchedule} element={<Schedule />} />
            {/* <Route path={config.routes.listSchedule} element={<Schedule />}> */}
            <Route path={config.routes.segmentSetting} element={<SegmentSetting />} />
            <Route path={config.routes.locationSetting} element={<LocationSetting />} />
            {/* </Route> */}
          </Route>
          <Route path={config.routes.listAgencyManagement}>
            <Route path={config.routes.listCarCompany} element={<ManageBusCompany />} />

            <Route path={config.routes.listAgent} element={<ManageAgent />} />
            <Route path={config.routes.list} element={<ManageAgent />} />
            <Route path={config.routes.listGeneralAgency} element={<GeneralAgents />} />
          </Route>

          <Route path={config.routes.bookingManagement}>
            <Route path={config.routes.booking} element={<BusBooking />} />
            <Route path={config.routes.listTicket} element={<ListTicket />} />
            <Route path={config.routes.listContract} element={<ListContract />} />
          </Route>

          <Route path={config.routes.employeeManagement}>
            <Route path={config.routes.listEmployee} element={<ManageEmployee />} />
            <Route path={config.routes.listSalary} element={<ManageSalary />} />
            <Route path={config.routes.listChecking} element={<ManageCheking />} />
            <Route path={config.routes.listWork} element={<ManageWork />} />
          </Route>
          <Route path={config.routes.systemManagement}>
            <Route path={config.routes.list} element={<ManageSystem />} />
            <Route path={config.routes.systemConfig} element={<SystemConfig />} />
            <Route path={config.routes.systemGuide} element={<SystemGuide />} />
            <Route path={config.routes.systemDepartureFee} element={<SystemDepartureFee />} />
          </Route>
          <Route path={config.routes.notificationManagement}>
            <Route path={config.routes.list} element={<Notify />} />
          </Route>
          <Route path={config.routes.orderManagement}>
            <Route path={config.routes.orderPending} element={<OrderPending />} />
            <Route path={config.routes.orderSuccess} element={<OrderProcessed />} />
            <Route path={config.routes.orderTicket} element={<OrderTicket />} />
          </Route>
          <Route path={config.routes.reportManagement}>
            <Route path={config.routes.reportDetail} element={<ReportDetail />} />
            <Route path={config.routes.reportRoute} element={<ReportTotalRoute />} />
            <Route path={config.routes.reportAgent} element={<ReportTotalAgent />} />
            <Route path={config.routes.reportDriver} element={<ReportDriver />} />
            <Route path={config.routes.reportCustomer} element={<ReportCustomer />} />
            <Route path={config.routes.reportCancel} element={<ReportCancel />} />
            <Route path={config.routes.reportContract} element={<ReportContract />} />
            <Route path={config.routes.reportRechargeAgent} element={<ReportRechargeCompany />} />
            <Route path={config.routes.reportRecharge} element={<ReportRechargeAgent />} />
            <Route path={config.routes.reportTotal} element={<ReportTotal />} />
            <Route path={config.routes.reportBalanceChange} element={<ReportBalanceChange />} />
            <Route path={config.routes.reportRechargeCarlink} element={<ReportRechargeCarlink />} />
            <Route path={config.routes.reportFeeDepart} element={<ReportFeeExportBus />} />
            <Route path={config.routes.reportExportTicket} element={<ReportExportTicket />} />
            <Route path={config.routes.reportRechargeCompany} element={<ReportRechargeCompanyCarlink />} />
            <Route path={config.routes.reportFeeDepartCompany} element={<ReportFeeDepartCompanyCarlink />} />
            <Route
              path={config.routes.reportFeeExportTicketCompany}
              element={<ReportFeeExportTicketCompanyCarlink />}
            />
            <Route
              path={config.routes.reportDepositPromotionCompany}
              element={<ReportDepositPromotionCompanyCarlink />}
            />
            <Route path={config.routes.reportSourceAPI} element={<ReportSourceAPI />} />
          </Route>
          <Route path={config.routes.apiManagement}>
            <Route path={config.routes.listFollow} element={<ApiFollow />} />
            <Route path={config.routes.listApi} element={<ApiManagement />} />
          </Route>
        </Route>
        <Route path={config.routes.login} element={<Login />} />
        <Route path={config.routes.forbidden} element={<Forbidden />} />
        <Route path={config.routes.internalSever} element={<InternalServer />} />
        <Route path={config.routes.error404} element={<NotFound />} />
        <Route path={config.routes.notFound} element={<NotFound />} />
      </Routes>
      <ToastContainer />
      <Loader />
    </>
  );
};

export default App;
