import { Col, Form, Image, Modal, Row, Space, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import iconTransfer from '../../assets/images/arrow-schedule.svg';
import iconGlobal from '../../assets/images/ic-global.svg';
import iconImage from '../../assets/images/ic-image.svg';
import HeaderBooking from '../../components/HeaderBooking';
import ModalLocationShow from '../../components/Modal/ModalLocationShow';
import { convertMinutes, convertTimeFormat } from '../../lib/utils';

function BookingScheduleModal({ okText, cancelText, modalOpen, onOk, onCancel, formSearch }: any) {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const [isShowList, setIsShowList] = useState(false);
  const listBooking = Form.useWatch('list_booking', formSearch);
  const [listImage, setListImage] = useState([]);
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);
  const cancelShowList = () => {
    setIsShowList(false);
  };

  return (
    <Modal
      open={isModalOpen}
      cancelText={cancelText}
      onCancel={modalHandleCancel}
      onOk={modalHandleOk}
      okText={okText}
      footer={[]}
      width={950}
      maskClosable={false}
      className="booking-schedule-modal scroll-custom-modal"
      styles={{
        body: { maxHeight: '580px', minHeight: '300px', overflowY: 'auto', overflowX: 'hidden' },
      }}
    >
      <Typography className="fs-18 fw-600 mb-3">{'Lịch trình chuyến'}</Typography>
      <HeaderBooking listBooking={listBooking} />
      <Row gutter={25} className="mt-4" style={{ maxWidth: '98%' }}>
        <Col span={10}>
          <div className="transfer-group">
            <Typography className="fs-16 fw-500"> Điểm đón</Typography>
            <span className="transfer">{`${
              listBooking?.schedule?.is_depart_transship ? 'Có' : 'Không'
            } trung chuyển`}</span>
          </div>

          {listBooking?.schedule?.transship_depart?.map((transship: any, index: number) => {
            return (
              <Space key={index} className="transfer-address mt-3">
                <Typography className="hour-text">{convertTimeFormat(transship?.pickup_time)}</Typography>
                <Typography style={{ width: '350px', color: '#435869', columnGap: '6px' }} className="fw-500 d-flex">
                  <div style={{ cursor: 'pointer' }}>
                    <Tooltip title={transship?.address} key={'edt'} placement="top">
                      {`${transship?.pickup_location_name} (${dayjs(listBooking?.travel_info?.depart_date)
                        ?.add(transship?.diff_day, 'day')
                        ?.format('DD/MM/YYYY')})`}
                    </Tooltip>

                    {transship?.pickup_location_images?.length ? (
                      <img
                        src={iconImage}
                        style={{ marginLeft: '3px' }}
                        alt=""
                        className={`img-location-btn`}
                        onClick={() => {
                          setListImage(transship?.pickup_location_images);
                          setIsShowList(true);
                        }}
                      />
                    ) : (
                      <Tooltip
                        title={'Vui lòng cập nhật ảnh vào điểm đón trả, văn phòng'}
                        key={`img1-${index}`}
                        placement="right"
                      >
                        <img
                          src={iconImage}
                          style={{ marginLeft: '3px' }}
                          alt=""
                          className={`img-location-btn disabled`}
                        />
                      </Tooltip>
                    )}

                    {transship?.map_link ? (
                      <a href={`${transship.map_link}`} target="blank" rel="noopener" style={{ marginLeft: '3px' }}>
                        <img src={iconGlobal} alt="" className={`img-location-btn`} />
                      </a>
                    ) : (
                      <Tooltip
                        title={'Vui lòng cập nhật link google map vào điểm đón trả, văn phòng'}
                        key={`lgg1-${index}`}
                        placement="right"
                      >
                        <img
                          src={iconGlobal}
                          alt=""
                          className={`img-location-btn disabled`}
                          style={{ marginLeft: '3px' }}
                        />
                      </Tooltip>
                    )}
                  </div>

                  <br />
                </Typography>
              </Space>
            );
          })}
        </Col>
        <Col span={3}>
          <Typography className="fs-16 fw-600" style={{ color: '#435869' }}>
            {convertMinutes(listBooking?.travel_info?.travel_time)}
          </Typography>
          <Image preview={false} src={iconTransfer} width={60} style={{ marginTop: '-10px' }}></Image>
        </Col>
        <Col span={11}>
          <Typography className="transfer-group">
            <Typography className="fs-16 fw-500"> Điểm trả</Typography>

            <span className="transfer">{`${
              listBooking?.schedule?.is_arrive_transship ? 'Có' : 'Không'
            } trung chuyển`}</span>
          </Typography>
          {listBooking?.schedule?.transship_arrive?.map((transship: any, index: number) => {
            return (
              <Space className="transfer-address mt-3 d-flex" key={index}>
                <Typography className="hour-text">{convertTimeFormat(transship?.pickup_time)}</Typography>
                <Typography style={{ width: '350px', color: '#435869', columnGap: '6px' }} className="fw-500 d-flex">
                  <div className="fw-500">
                    <span style={{ cursor: 'pointer' }}>
                      <Tooltip title={transship?.address} key={'edt'} placement="top">
                        {`${transship?.pickup_location_name} (${dayjs(listBooking?.travel_info?.depart_date)
                          ?.add(transship?.diff_day, 'day')
                          ?.format('DD/MM/YYYY')})`}
                      </Tooltip>

                      {transship?.pickup_location_images?.length ? (
                        <img
                          src={iconImage}
                          alt=""
                          style={{ marginLeft: '3px' }}
                          className={`img-location-btn`}
                          onClick={() => {
                            setListImage(transship?.pickup_location_images);
                            setIsShowList(true);
                          }}
                        />
                      ) : (
                        <Tooltip
                          title={'Vui lòng cập nhật ảnh vào điểm đón trả, văn phòng'}
                          key={`img2-${index}`}
                          placement="right"
                        >
                          <img
                            src={iconImage}
                            alt=""
                            style={{ marginLeft: '3px' }}
                            className={`img-location-btn disabled`}
                          />
                        </Tooltip>
                      )}
                      {transship?.map_link ? (
                        <a href={`${transship.map_link}`} target="blank" rel="noopener" style={{ marginLeft: '3px' }}>
                          <img src={iconGlobal} alt="" className={`img-location-btn`} />
                        </a>
                      ) : (
                        <Tooltip
                          title={'Vui lòng cập nhật link google map vào điểm đón trả, văn phòng'}
                          key={`lgg${index}`}
                          placement="right"
                        >
                          <img
                            src={iconGlobal}
                            alt=""
                            className={`img-location-btn disabled`}
                            style={{ marginLeft: '3px' }}
                          />
                        </Tooltip>
                      )}
                    </span>
                  </div>

                  <br />
                </Typography>
              </Space>
            );
          })}
        </Col>
      </Row>
      <ModalLocationShow modalOpen={isShowList} onCancel={cancelShowList} onOk={modalHandleOk} listImage={listImage} />
    </Modal>
  );
}
export default BookingScheduleModal;
