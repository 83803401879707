import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSystemConfigApi } from "../../../api/system";

// First, create the thunk
export const fetchSystemConfig = createAsyncThunk(
  "systemConfig/fetchSystemConfig",
  async () => {
    try {
      const response = await getSystemConfigApi();
      return response.data;
    } catch (error) {
      
    }
  }
);

const initialState: any = {
  isFetching: true,
  result: {},
  total: "0"
};

export const systemConfigSlide = createSlice({
  name: "systemConfig",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchSystemConfig.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(fetchSystemConfig.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(fetchSystemConfig.fulfilled, (state, action) => {
      state.isFetching = false;
      state.result = action.payload?.data;   
    });
  },
});

export const {} = systemConfigSlide.actions;

export default systemConfigSlide.reducer;
