import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { OrderTicketState, ParamsGetListOrder } from "../../../types/order";
import { getListOrdersAPI } from "../../../pages/orders/api";
import { isArray } from "lodash";
import { TabStatusOrderProcessed } from "../../../pages/orders/models/constants";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listTicket: [],
    timer: true,
    filter: {
        keyword: '',
        date_filter: 'depart_date',
        from: '',
        to: '',
        status: '',
    },
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    }
} as OrderTicketState;

export const ordersTicketSlide = createSlice({
    name: 'ordersTicket',
    initialState,
    reducers: {
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.pagination.current_page = action.payload;
        },
        setKeyword(state, action: PayloadAction<string>) {
            state.filter.keyword = action.payload;
        },
        setFilter(state, action) {
            state.filter = action.payload;
        },
        setTimer(state, action: PayloadAction<boolean>) {
            state.timer = action.payload;
        },
        setStatus(state, action: PayloadAction<string>) {
            state.filter.status = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getListOrdersTicket.pending, (state) => {
        }).addCase(getListOrdersTicket.fulfilled, (state, action) => {
            state.listTicket = action.payload?.data;
            state.pagination = action.payload?.pagination;
        }).addCase(getListOrdersTicket.rejected, (state) => {
        })
    }
})

export const getListOrdersTicket = createAsyncThunk('OrdersTicket/getList', async (params: ParamsGetListOrder, {
    dispatch, rejectWithValue
}) => {
    try {
        const res = await getListOrdersAPI(params)
        if (res?.status === 200) {
            if (isArray(res?.data?.data)) {
                return res?.data;
            } else {
                return res?.data?.data;
            }
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setKeyword, setFilter, setPerPage, setCurrentPage, setTimer, setStatus } = ordersTicketSlide.actions;