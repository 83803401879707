import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../api';
import { getPermissions } from '../../api/user';
import { RootState } from '../store';

export type AuthState = {
  email: string;
  lang?: string;
  name: string;
  id: string;
  isLogin: boolean;
  role: string[];
  loading: boolean;
  success: boolean;
  error: boolean;
  isShowMenu: any;
  listPermissions: any;
  loadingPermissions: boolean;
  successPermissions: boolean;
  errorPermissions: boolean;
  group: string;
  accountUser?: any;
  reloadDetail?: boolean;
  is_superadmin: boolean;
  export_report_day?: number | null;
};

// @ts-ignore

export const fetchPermissions = createAsyncThunk('permissions', async (params, { dispatch, rejectWithValue }) => {
  try {
    const response = await getPermissions();
    const { data, success } = response?.data;
    if (success) {
      dispatch(listPermissions(data));
      return true;
    }
  } catch (error: any) {
    dispatch(listPermissions(error));
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    email: '',
    name: '',
    id: '',
    lang: 'vi',
    isLogin: false,
    role: [],
    reloadDetail: false,
    loading: false,
    success: false,
    isShowMenu: false,
    error: false,
    group: '',
    export_report_day: null,
    accountUser: [],
    loadingPermissions: false,
    successPermissions: false,
    errorPermissions: false,
    listPermissions: null,
    is_superadmin: false,
  } as AuthState,
  reducers: {
    setAuth: (state: AuthState, action) => {
      const { user } = action.payload;
      state.email = user.email;
      state.name = user.name;
      state.id = user.id;
      state.isLogin = true;
      state.role = user?.permissions;
      state.group = user?.group;
      state.accountUser = user;
      state.is_superadmin = user?.is_superadmin;
      state.export_report_day = user?.export_report_day;
    },
    setShowMenu: (state: AuthState, action) => {
      state.isShowMenu = action.payload;
    },
    setRole: (state: AuthState, action) => {
      state.role = action.payload;
    },
    logout: (state: AuthState) => {
      state.email = '';
      state.name = '';
      state.id = '';
      state.isLogin = false;
      state.role = [];
      state.group = '';
      state.accountUser = [];
      state.is_superadmin = false;
    },
    setReloadDetail: (state: AuthState) => {
      state.reloadDetail = true;
    },
    resetReloadDetail: (state: AuthState) => {
      state.reloadDetail = false;
    },
    listPermissions: (state: AuthState, { payload }) => {
      state.listPermissions = payload;
    },
    setLanguage: (state: AuthState, action) => {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    // listPermissions
    builder.addCase(fetchPermissions.pending, (state: AuthState) => {
      state.loadingPermissions = true;
    });
    builder.addCase(fetchPermissions.fulfilled, (state: AuthState) => {
      state.loadingPermissions = false;
      state.successPermissions = true;
      state.errorPermissions = false;
    });
    builder.addCase(fetchPermissions.rejected, (state: AuthState) => {
      state.loadingPermissions = false;
      state.successPermissions = false;
      state.errorPermissions = true;
    });
  },
});

export const selectAuth = (state: RootState) => state.auth;
export const { setAuth, setShowMenu, logout, setRole, listPermissions, setReloadDetail, resetReloadDetail } =
  authSlice.actions;
