import React from 'react';
import TextDisplay from '../TextDisplay';
import moment from 'moment';

interface DateDisplayProps {
  className?: string;
  dateFormat?: string;
  date?: string | Date | number;
}

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

const DateDisplay = ({ className, date, dateFormat = DEFAULT_DATE_FORMAT }: DateDisplayProps) => {
  if (!date) {
    return <TextDisplay />;
  }
  if (!moment(date).isValid()) {
    return <TextDisplay text={date?.toString()} />;
  }
  return <span className={className}>{moment(date).format(dateFormat)}</span>;
};

export default DateDisplay;
