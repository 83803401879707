import {getAxiosClientWithToken} from "./index";
import {handleFormData} from "../lib/utils";
// import {IDUser, ListUserRequest, UpdateUser, UserParams} from "../types";
import { IDRoute, ListRouteRequest, UpdateRoute, RouteParams } from "../types/route";

export const getListRoutesAPI = (params: ListRouteRequest) => {
    return getAxiosClientWithToken().get('/routes', {params});
};

export const getAllListRoutesAPI = (params: ListRouteRequest) => {
    return getAxiosClientWithToken().get('/routes/get-all', {params});
};

export const getListProvinceWithRoutesAPI = () => {
    return getAxiosClientWithToken().get('/routes/provinces');
};

export const getRouteAPI = (params: IDRoute) => {
    return getAxiosClientWithToken().get(`routes/${params.id}`);
};

export const createRouteAPI = (params: RouteParams) => {
    const formData = handleFormData(params);
    return getAxiosClientWithToken().post(`/routes`, formData);
};

export const updateRouteAPI = (id: IDRoute, params: UpdateRoute) => {
    return getAxiosClientWithToken().put(`routes/${id}`,params);
};

export const updateStatusRouteAPI = (id: IDRoute, status: boolean | undefined) => {
    // const formData = handleFormData(params);
    return getAxiosClientWithToken().put(`routes/status/${id}`, {status: status});
};

export const deleteRouteAPI = (id: string | number) => {
    return getAxiosClientWithToken().delete(`routes/${id}`);
};

export const getAllRouteApi = (params: ListRouteRequest)=>{
    return getAxiosClientWithToken().get('routes/get-all',{params})
}