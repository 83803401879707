import { AgentUpdatePram, CreateAgentState, ListAgentRequest } from '../types/agent';
import { getAxiosClientWithToken } from './index';

export const getListAgentApi = (params: ListAgentRequest) => {
  return getAxiosClientWithToken().get('/agent', { params });
};
export const getDetailAgentApi = () => {
  return getAxiosClientWithToken().get('/agent/detail');
};
export const getListAgentActiveApi = (params: { company_id: number }) => {
  return getAxiosClientWithToken().get('/agent/active', { params });
};
export const getListAgentDetailApi = (id: number) => {
  return getAxiosClientWithToken().get(`/agent/${id}`);
};
export const updateListAgentDetailApi = (params: AgentUpdatePram) => {
  const restClient = getAxiosClientWithToken();
  return restClient.put(`/agent/${params.id}`, params);
};
export const updateAgentDetailApi = (id: any, params: AgentUpdatePram) => {
  return getAxiosClientWithToken().put(`agent/${id}`, params);
};
export const updateAgentStatusApi = (params: { id: string; status: boolean }) => {
  const restClient = getAxiosClientWithToken();
  return restClient.put(`/agent/status/${params.id}`, {
    status: params.status ? 0 : 1,
  });
};
export const changeAgentStatusApi = (id: any, params: any) => {
  return getAxiosClientWithToken().put(`/agent/status/${id}`, params);
};

export const updateAgentApi = (params: any) => {
  const restClient = getAxiosClientWithToken();
  return restClient.put(`/agent/info-agent`, params);
};
export const createAgentApi = (params: CreateAgentState) => {
  return getAxiosClientWithToken().post(`/agent`, params);
};

// http://192.168.1.99:8000/api/v1/agent/info-transfer

export const getAgentInfoTransferApi = () => {
  return getAxiosClientWithToken().get('/agent/info-transfer');
};
export const getCreateAgentDepositApi = (params: any) => {
  return getAxiosClientWithToken().post('/agent/deposit', params);
};

export const getCreateAgentDepositQRApi = (params: any) => {
  return getAxiosClientWithToken().get(`/agent/get-deposit-qr?${params}`);
};

export const getCompanyInfoTransferApi = () => {
  return getAxiosClientWithToken().post('/company/deposit');
};
