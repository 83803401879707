import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { createBusCompanyApi } from '../../../api/busCompany';
import { CreateCompanyState, ListCompanyState } from '../../../types';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';
import { Action, CREATE_ERROR, CREATE_SUCCESS } from '../../../config/message';
import { Toast } from '../../../config/constant';

// @ts-ignore
export const createCompanyBus = createAsyncThunk(
  'admin/create/companyBus',
  async (params: CreateCompanyState, { dispatch, rejectWithValue }) => {
    try {
      const response = await createBusCompanyApi(params);
      const { data, status } = response;
      if (status) { dispatch(setCreate({ status: status, data: data }));
      dispatch(
        setToast({
          message: CREATE_SUCCESS(Action.BUS_COMPANY),
          status: true,
          type: Toast.TYPE_SUCCESS,
        }),
      );
    }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setToast({
        message: CREATE_ERROR(Action.BUS_COMPANY),
        status: true,
        type: Toast.TYPE_ERROR,
    }))
      dispatch(setCreateError(response));
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const createBusCompanySlice = createSlice({
  name: 'createCompanyBus',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as ListCompanyState,
  reducers: {
    setCreate: (state: ListCompanyState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setCreateError: (state: ListCompanyState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetRegisterCreateBusCompany: (state: ListCompanyState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCompanyBus.pending, (state: ListCompanyState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createCompanyBus.fulfilled, (state: ListCompanyState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(createCompanyBus.rejected, (state: ListCompanyState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const createCompanyBusSelector = (state: RootState) => state.createBusCompanySlice;
export const { setCreate, setCreateError, resetRegisterCreateBusCompany } = createBusCompanySlice.actions;
