import { getAxiosClientWithToken } from "./index";

export const getGeneralAgentApi = (params: any) => {
    return getAxiosClientWithToken().get(`/main-agent?${params}`);
};

export const updateGeneralAgentAPI = (id: any , body: any ) => {
    return getAxiosClientWithToken().put(`/main-agent/${id}`, body);
};  

export const getGeneralAgentByIdAPI = (id: any) => {
    return getAxiosClientWithToken().get(`/main-agent/${id}`)
}

export const createGeneralAgentAPI = (body: any) => {
    return getAxiosClientWithToken().post(`/main-agent`, body);
};

export const getGeneralAgentStatusByIdAPI = (id:any,  body: any) => {
    return getAxiosClientWithToken().put(`/main-agent/status/${id}`, body)
}

export const getInfoGeneralAgentApi = () => {
    return getAxiosClientWithToken().get(`/main-agent/information`);
};

export const getInfoDepositGeneralAgentApi = () => {
    return getAxiosClientWithToken().get(`/main-agent/info-transfer`);
};

export const updateInfoGeneralAgentApi = (body: any) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put(`/main-agent/information`, body);
};

export const getCreateMainAgentDepositApi = (params: any) => {
    return getAxiosClientWithToken().post('/main-agent/deposit', params);
};

export const getCreateMainAgentDepositQRApi = (params: any) => {
    return getAxiosClientWithToken().get(`/main-agent/get-deposit-qr?${params}`);
};

export const callUpdateDepositMainAgentApi = (params: any) => {
    return getAxiosClientWithToken().post(`/system/update-deposit/main-agent`, params);
};
