import { Button, Card, Checkbox, Col, Form, Image, Input, Layout, Radio, Row, Space, Typography } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconBusVertical from '../assets/images/ic-bus-dung.svg';
import iconBusHorizontal from '../assets/images/ic-bus-ngang.svg';
import type2 from '../assets/images/typeBus/ic-assistant-seat.svg';
import type4 from '../assets/images/typeBus/ic-bed-seat.svg';
import type5 from '../assets/images/typeBus/ic-cabin.svg';
import type6 from '../assets/images/typeBus/ic-double-cabin.svg';
import type1 from '../assets/images/typeBus/ic-driver-seat.svg';
import type3 from '../assets/images/typeBus/ic-normal-seat.svg';
import type7 from '../assets/images/typeBus/ic-seat-massage.svg';

import { Constant } from '../config/constant';
import { SeatRenderProps } from '../types/seatType';
import { TypeSeatItems } from '../utils/typeCarItems';

export const typeSeatItems = [
  { id: 1, name: 'Ghế tài xế', image: type1, type: Constant.DRIVER_SEAT },
  { id: 2, name: 'Ghế phụ xe', image: type2, type: Constant.EXTRA_DRIVER_SEAT },
  { id: 3, name: 'Ghế ngồi', image: type3, type: Constant.NORMAL_SEAT },
  { id: 4, name: 'Ghế giường nằm', image: type4, type: Constant.BED_SEAT },
  { id: 5, name: 'Cabin đơn', image: type5, type: Constant.SINGLE_CABIN },
  { id: 6, name: 'Cabin đôi', image: type6, type: Constant.DOUBLE_CABIN },
  { id: 7, name: 'Ghế massage', image: type7, type: Constant.MASSAGE_SEAT },
];

const BusTypeMatrix = ({
  column,
  row,
  isReset = false,
  handleReset,
  statusFloor,
  isCopy,
  handleCopy,
  isDelete,
  handleDelete,
  seatMatrixData,
  onMatrixDataChange,
  isFillName,
  onResetFillName,
  formTypeBus,
  isDeleteName,
  onResetDeleteName,
  record,
}: SeatRenderProps) => {
  const { t } = useTranslation();
  const [seatMatrix, setSeatMatrix] = useState<{ [key: string]: any }>({
    floor1: [],
    floor2: [],
    group_seat: [],
  });
  const [disableTypeSeat, setDisableTypeSeat] = useState<boolean>(true);
  const [typeSeat, setTypeSeat] = useState(0);
  const [typeCounts, setTypeCounts] = useState<{ [key: number]: number }>({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  });
  const [busDirection, setBusDirection] = useState<number>(formTypeBus.getFieldValue('bus_direction') ?? 0);
  const currentValues = Form.useWatch('seat_json', formTypeBus) || [];

  useEffect(() => {
    if (isReset) {
      const resetFloorData = () =>
        Array.from({ length: row }, (_, rowIndex) =>
          Array.from({ length: column }, (_, colIndex) => ({
            id: `${rowIndex}-${colIndex}`,
            selected: false,
            type: 0,
            name: '',
            color: '',
            deleted: false,
            disable: false,
          })),
        );

      setSeatMatrix((prevMatrixData: any) => ({
        ...prevMatrixData,
        [`floor${statusFloor}`]: resetFloorData(),
      }));
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset, statusFloor]);

  useEffect(() => {
    if (isDelete) {
      setSeatMatrix((prevMatrix) => ({
        ...prevMatrix,
        floor2: Array.from({ length: row }, (_, rowIndex) =>
          Array.from({ length: column }, (_, colIndex) => ({
            id: `${rowIndex}-${colIndex}`,
            selected: false,
            type: 0,
            name: '',
            color: '',
            deleted: false,
          })),
        ),
      }));

      handleDelete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDelete, statusFloor]);

  useEffect(() => {
    if (isFillName) {
      setSeatMatrix((prevMatrix: any) => {
        const newMatrix = { ...prevMatrix };
        let seatNumber = 1;
        newMatrix[`floor${statusFloor}`] = newMatrix[`floor${statusFloor}`].map((row: any[], rowIndex: number) =>
          row.map((seat: any, colIndex: number) => {
            let updatedSeat = { ...seat };
            if (!seat?.name && seat?.type) {
              if (seat.type === 1) {
                updatedSeat.name = 'TÀI XẾ';
              } else if (seat.type === 2) {
                updatedSeat.name = 'PHỤ XE';
              } else if (seat.type > 2) {
                updatedSeat.name = `floor${statusFloor}` === 'floor1' ? `A${seatNumber}` : `B${seatNumber}`;
                seatNumber++;
              } else {
                updatedSeat.name = '';
              }
            }
            return updatedSeat;
          }),
        );
        return newMatrix;
      });
      onResetFillName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFillName, statusFloor]);

  useEffect(() => {
    if (isDeleteName) {
      setSeatMatrix((prevMatrix: any) => {
        const newMatrix = { ...prevMatrix };
        ['floor1', 'floor2'].forEach((floorKey) => {
          newMatrix[floorKey] = newMatrix[floorKey].map((row: any[]) =>
            row.map((seat: any) => ({
              ...seat,
              name: '',
            })),
          );
        });
        return newMatrix;
      });
      onResetDeleteName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteName]);

  useEffect(() => {
    if (isCopy) {
      setSeatMatrix((prevMatrix: any) => {
        const newMatrix = { ...prevMatrix };
        newMatrix.floor2 = newMatrix.floor1.map((row: any[]) =>
          row.map((item: any) => ({
            ...item,
            name: '',
          })),
        );
        return newMatrix;
      });

      handleCopy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopy, statusFloor]);

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number, colIndex: number) => {
    const { value } = e.target;
    setSeatMatrix((prevMatrix) => {
      const newMatrix = {
        ...prevMatrix,
        [`floor${statusFloor}`]: prevMatrix[`floor${statusFloor}`].map((row: any) =>
          row.map((seat: any) => (seat.id === `${rowIndex}-${colIndex}` ? { ...seat, name: value } : seat)),
        ),
      };
      return newMatrix;
    });
  };

  const handleCheckboxChange = (rowIndex: number, colIndex: number) => {
    if (record?.id) {
      return;
    } else {
      setSeatMatrix((prevMatrix) => {
        const newMatrix = {
          ...prevMatrix,
          [`floor${statusFloor}`]: prevMatrix[`floor${statusFloor}`].map((row: any) =>
            row.map((seat: any) =>
              seat.id === `${rowIndex}-${colIndex}` && seat.type === Constant.DEFAULT_SEAT
                ? { ...seat, selected: !seat.selected }
                : seat,
            ),
          ),
        };
        return newMatrix;
      });
    }
  };

  const onChangeTypeSeat = ({ target: { value } }: RadioChangeEvent) => {
    setDisableTypeSeat(false);
    setTypeSeat(value);
    setSeatMatrix((prevMatrix: any) => {
      const newMatrix = { ...prevMatrix };
      newMatrix[`floor${statusFloor}`]?.forEach((row: any, rowIndex: number) => {
        row?.forEach((seat: any, colIndex: number) => {
          if (seat?.selected) {
            newMatrix[`floor${statusFloor}`][rowIndex][colIndex] = {
              ...seat,
              selected: false,
              type: value,
              name: value === 1 ? 'TÀI XẾ' : value === 2 ? 'PHỤ XE' : '',
            };
          }
        });
      });

      return newMatrix;
    });
  };

  const onDeleteTypeSeat = (rowIndex: number, colIndex: number) => {
    setSeatMatrix((prevMatrix: any) => {
      const newMatrix = {
        ...prevMatrix,
        [`floor${statusFloor}`]: prevMatrix[`floor${statusFloor}`].map((row: any) =>
          row.map((seat: any) => {
            if (seat.id === `${rowIndex}-${colIndex}`) {
              return {
                id: `${rowIndex}-${colIndex}`,
                selected: false,
                type: 0,
                name: '',
                color: '',
                deleted: false,
              };
            }
            return seat;
          }),
        ),
      };
      return newMatrix;
    });
  };

  useEffect(() => {
    const createMatrixData = () =>
      Array.from({ length: row }, (_, rowIndex) =>
        Array.from({ length: column }, (_, colIndex) => ({
          id: `${rowIndex}-${colIndex}`,
          selected: false,
          type: 0,
          name: '',
          color: '',
          deleted: false,
        })),
      );

    const matrixData = {
      floor1: createMatrixData(),
      floor2: createMatrixData(),
      seat_number: 0,
      group_seat: [],
    };

    if (seatMatrixData) {
      setSeatMatrix(seatMatrixData);

      if (seatMatrixData.row !== row || seatMatrixData.column !== column) {
        setSeatMatrix(matrixData);
      }
    } else if (row < 15 && column < 15) {
      setSeatMatrix(matrixData);
    }
  }, [column, row, seatMatrixData]);

  useEffect(() => {
    let anySelected = false;
    const newMatrix = { ...seatMatrix };
    newMatrix[`floor${statusFloor}`]?.forEach((row: any) => {
      row.forEach((seat: any) => {
        if (seat.selected) {
          anySelected = true;
        }
        if (!anySelected) {
          setTypeSeat(0);
        }
      });
    });
    setDisableTypeSeat(!anySelected);
  }, [seatMatrix, statusFloor]);

  useEffect(() => {
    let count = 0;
    const newTypeCounts: { [key: string]: number } = {
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
    };
    const newMatrix: any = JSON.parse(JSON.stringify(seatMatrix));
    ['floor1', 'floor2']?.forEach((floorKey) => {
      newMatrix[floorKey]?.forEach((row: any) => {
        row.forEach((seat: any) => {
          if (seat.type in newTypeCounts) {
            newTypeCounts[seat.type as keyof typeof newTypeCounts]++;
          }
          if (seat.type >= Constant.NORMAL_SEAT && seat.type <= Constant.MASSAGE_SEAT) {
            count++;
            seat.seat_number = count;
          }
        });
      });
    });

    setTypeCounts(newTypeCounts);
    if (onMatrixDataChange) {
      onMatrixDataChange({ ...seatMatrix, seat_number: count });
    }
    const seatMapArray: any = [];
    ['floor1', 'floor2'].forEach((floorKey) => {
      seatMapArray.push(
        ...seatMatrix[floorKey].map((row: any[]) =>
          row.filter((seat: any) => seat.type > Constant.EXTRA_DRIVER_SEAT && seat.name).map((seat: any) => seat.name),
        ),
      );
    });
    currentValues['seat_map'] = {
      ...seatMatrix,
      row: formTypeBus.getFieldValue('row'),
      column: formTypeBus.getFieldValue('column'),
      count_floor: seatMatrix?.count_floor,
      group_seat: seatMatrix?.group_seat,
      seats: seatMapArray?.flat(),
    };
    formTypeBus.setFieldsValue({
      seat_number: count,
      seat_json: seatMatrix,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seatMatrix, formTypeBus, currentValues]);

  const handleChangeDirection = () => {
    setBusDirection(
      busDirection === Constant.HORIZONTAL_DIRECTION ? Constant.VERTICAL_DIRECTION : Constant.HORIZONTAL_DIRECTION,
    );
  };
  useEffect(() => {
    formTypeBus.setFieldsValue({ bus_direction: busDirection });
  }, [busDirection, formTypeBus]);

  return (
    <>
      <Layout className="seat-system-block">
        <Form.Item className="d-none" name="bus_direction" initialValue={0}>
          <Input />
        </Form.Item>
        <Form.Item name="seat_json" className="seat-json-group">
          <Row className="type-seat mt-3">
            <Col span={4}>
              <Image
                style={{ cursor: 'pointer' }}
                src={busDirection === 0 ? iconBusVertical : iconBusHorizontal}
                preview={false}
                width={54}
                onClick={() => handleChangeDirection()}
              />
            </Col>
            <Col span={20} className="type-seat-list">
              {Object.entries(typeCounts).map(([type, count]) => (
                <Space key={type}>
                  <Typography.Text>
                    {+type !== Constant.DEFAULT_SEAT &&
                      `${typeSeatItems.find((seat) => seat.id === +type)?.name}:  ${count}`}
                  </Typography.Text>
                </Space>
              ))}
            </Col>
          </Row>
          <div className="seat-render-content ">
            {seatMatrix[`floor${statusFloor}`]?.map((rowSeats: any, rowIndex: number) => (
              <Row key={rowIndex} className="seat-list">
                {rowSeats.map((seatFloors: any, colIndex: number) => (
                  <Col key={colIndex}>
                    <Card
                      style={{ width: 70 }}
                      className={`card-seat-item`}
                      cover={
                        <Space
                          className={`seat-block-item type-bus${
                            typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image ? ' ic-type' : ''
                          }`}
                          onClick={() => handleCheckboxChange(rowIndex, colIndex)}
                        >
                          <Checkbox
                            className="seat-checkbox-item"
                            checked={seatFloors?.type > 0 ? false : seatFloors.selected}
                          />
                          <Space className="ic-type-seat">
                            {typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image && (
                              <Image
                                src={typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image}
                                alt=""
                                width={52}
                                preview={false}
                                className={`img-type-seat ${
                                  busDirection === Constant.HORIZONTAL_DIRECTION ? 'horizontal' : ''
                                }`}
                              />
                            )}
                          </Space>
                        </Space>
                      }
                    >
                      <div className="form-name">
                        {!seatMatrixData &&
                          typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image && (
                            <Button
                              className="btn-delete"
                              onClick={() => onDeleteTypeSeat(rowIndex, colIndex)}
                            ></Button>
                          )}
                        <Form.Item
                          rules={[seatFloors.type > Constant.EXTRA_DRIVER_SEAT ? { required: true, message: '' } : {}]}
                          validateTrigger="onChange"
                        >
                          <Input
                            placeholder={seatFloors.type !== Constant.DEFAULT_SEAT ? `Mã ghế` : ''}
                            width={'100%'}
                            value={seatFloors.name}
                            onChange={(e) => handleNameInputChange(e, rowIndex, colIndex)}
                            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                            disabled={seatFloors.type === Constant.DEFAULT_SEAT || seatMatrixData}
                            className="seat-name-input"
                          />
                        </Form.Item>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
          <Row className="seat-type-item">
            <Col xs={24} xxl={24} className="mr-25">
              <Space className="group-type-seat w-100">
                <Typography.Text className="group-seat-title">{t('Set loại ghế')}</Typography.Text>
                <Radio.Group
                  options={TypeSeatItems}
                  onChange={onChangeTypeSeat}
                  value={typeSeat}
                  optionType="button"
                  disabled={disableTypeSeat}
                />
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </Layout>
    </>
  );
};
export default BusTypeMatrix;
