import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { postCancelContractApi } from '../../../api/contract';
import { Toast } from '../../../config/constant';
import { Action, CANCEL_TICKET_ERROR, CANCEL_TICKET_SUCCESS } from '../../../config/message';
import { CancelContractRequest, ContractState } from '../../../types/contract';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

// @ts-ignore
export const cancelContract = createAsyncThunk(
  'admin/contract/cancel',
  async (params: CancelContractRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await postCancelContractApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: CANCEL_TICKET_SUCCESS(Action.CANCEL_CONTRACT),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message: CANCEL_TICKET_ERROR(Action.CANCEL_CONTRACT),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const cancelContractSlice = createSlice({
  name: 'cancelBooking',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as ContractState,
  reducers: {
    setUpdate: (state: ContractState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setUpdateError: (state: ContractState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetCancelContract: (state: ContractState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cancelContract.pending, (state: ContractState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(cancelContract.fulfilled, (state: ContractState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(cancelContract.rejected, (state: ContractState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const cancelContractSelector = (state: RootState) => state.cancelContract;
export const { setUpdateError, setUpdate, resetCancelContract } = cancelContractSlice.actions;
