/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  UploadFile,
} from "antd";
import { useTranslation } from "react-i18next";
import "../../assets/styles/infoCompany.scss";
import { ACTION_NAME, messageUpdateSuccess } from "../../config/message";
import { checkAuthorization } from "../../api";
import { URL_API_VIET_QR } from "../../api/vietqr";
import axios from "axios";
import { filterOptionSearch } from "../../lib/utils";
import "./ManageSystem.scss"
import { getDetailInfoSystemApi, updateSystemInfoApi } from "../../api/system";
import { useAppSelector } from "../../redux/hooks";

const ManageSystem: React.FC<any> = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [listBank, setListBank] = useState([]);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const [fileList, setFileList] = useState<UploadFile[]>([]);


  const image: any = dataDetail?.logo
    ? [
        {
          uid: "-1",
          name: "No avatar",
          status: "done",
          url: dataDetail?.logo,
        },
      ]
    : [];

  useEffect(() => {
    setFileList(image);
  }, [dataDetail?.logo]);

  const handleDetailAgentApi = async () => {
    setLoadingAgent(true);
    try {
      const res = await getDetailInfoSystemApi();
      if (res.status === 200) {
        setDataDetail(res.data.data);
      }
    } catch (error) {}
    setLoadingAgent(false);
  };

  const handleListQR = () => {
    try {
      axios
        .get(URL_API_VIET_QR)
        .then((response) => {
          const res = response.data;
          setListBank(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {}
  };

  const listBankSelect = listBank.map((item: any) => {
    return { value: item?.bin, label: `${item?.name} (${item?.code})` };
  });

  useEffect(() => {
    handleListQR();
  }, []);

  form.setFieldsValue({
    company_name: dataDetail?.company_name,
    representative_name: dataDetail?.representative_name,
    position: dataDetail?.position,
    tax_code: dataDetail?.tax_code,
    fax: dataDetail?.fax,
    address: dataDetail?.address,
    bank_account_name: dataDetail?.bank_account_name,
    bank_account_number: dataDetail?.bank_account_number,
    bank: dataDetail?.bank,
    email: dataDetail?.email,
    phone: dataDetail?.phone,
  });

  const onFinish = async (values: any) => {
    values.logo = fileList[0]?.thumbUrl;
    try {
      const res = await updateSystemInfoApi({ ...values });
      if (res.status === 200) {
        messageUpdateSuccess(ACTION_NAME.CARLINK);
        handleDetailAgentApi();
        setIsEdit(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const handleSubmit = () => {
    form.submit(); // Gọi phương thức submit của form instance
  };

  const onSearch = (value: string) => {
  };

  useEffect(() => {
    handleDetailAgentApi();
  }, []);

  return (
    <Spin spinning={loadingAgent}>
      <Card className="wrapper-info-company wrapper-manage-system">
        <Col>
          <Form
            name="info-car-company"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            // validateTrigger="onSubmit"
            className="form-info-company"
          >
            <div>
              <Row>
                <Col>
                  <Form.Item
                    name="company_name"
                    className="required"
                    label={t("Tên công ty")}
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên đại lý"),
                      },
                    ]}
                    validateFirst
                  >
                    <Input disabled={!isEdit} className="w-841" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="address"
                    label={t("Địa chỉ")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập địa chỉ"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-841" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="representative_name"
                    label={
                      dataDetail?.type !== "personal"
                        ? t("Đại diện")
                        : t("Chủ đại lý")
                    }
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên người đại diện"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    name="position"
                    label={t("Chức vụ")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập chức vụ"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    name="tax_code"
                    label={t("Mã số thuế (MSDN)")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập mã số thuế"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="phone"
                    label={t("Điện thoại")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập số điện thoại"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-355" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item name="fax" label={t("Fax")}>
                    <Input disabled={!isEdit} className="w-355" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item name="email" label={t("Email")}>
                    <Input disabled={!isEdit} className="w-355" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
              <Col>
                  <Form.Item
                    name="bank_account_number"
                    label={t("Tài khoản số")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập số tài khoản"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} type="number"  className="w-282"  />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col>
                  <Form.Item
                    name="bank_account_name"
                    label={t("Chủ tài khoản")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên chủ tài khoản"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-841" />
                  </Form.Item>
                </Col>
              </Row>
             
              <Row>
                <Col>
                  <Form.Item
                    name="bank"
                    label={t("Tại ngân hàng")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên ngân hàng"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={onSearch}
                      optionFilterProp="label" // Đây là phần cần chỉnh sửa
                      filterOption={filterOptionSearch}
                      disabled={!isEdit}
                      className="w-841"
                      // onChange={handleChange}
                      options={listBankSelect}
                    />
                  </Form.Item>
                </Col>
              </Row>
          
              <Row gutter={24}>
                <Col style={{ width: "520px", height: "180px" }}>
                 
                </Col>
                {isEdit ? (
                  <Col className="container-btn-company ">
                    <Button onClick={() => setIsEdit(false)}>Trở lại</Button>
                    <Button type="primary" onClick={handleSubmit}>
                      Lưu
                    </Button>
                  </Col>
                ) : (
                  <Button
                    style={!accountDetail?.is_admin ? { display: "none" } : {}}
                    // type="primary"
                    className="btn-info-company"
                    onClick={() => setIsEdit(true)}
                  >
                    Chỉnh sửa
                  </Button>
                )}
              </Row>
            </div>
          </Form>
        </Col>
      </Card>
    </Spin>
  );
};

export default ManageSystem;
