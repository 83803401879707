import { UploadOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { Rule } from "antd/es/form";
import { ColumnsType } from "antd/es/table";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { RcFile } from "antd/es/upload/interface";
import dayjs from "dayjs";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import icEdit from "../../assets/images/ic-edit.svg";
import IcLock from "../../assets/images/ic-lock.svg";
import IcPlus from "../../assets/images/ic-plus.svg";
import IcTimeOutDisable from "../../assets/images/ic-time-out-off.svg";
import IcTimeOut from "../../assets/images/ic-time-out.svg";
import IcUnLock from "../../assets/images/ic-unlock.svg";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import StatusFilter from "../../components/Modal/StatusFilter";
import SeatMapRender from "../../components/SeatMapRender";
import { Constant, DateFormat, YearFormat } from "../../config/constant";
import { formatDate, mbTrim, useRedirectStatusError } from "../../lib/utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchListBus, listBusSelector } from "../../redux/slice/buses/bus";
import {
  createBus,
  createBusSelector,
  resetRegisterCreateBus,
} from "../../redux/slice/buses/createBus";
import { toast } from "react-toastify";
import {
  resetUpdateBus,
  updateBus,
  updateBusSelector,
  updateStatusBus,
} from "../../redux/slice/buses/updateBus";
import {
  getAllAvailableBusType,
  listBusTypeSelector,
} from "../../redux/slice/busTypes/listBusTypeSlice";
import { fetchAllService } from "../../redux/slice/services/serviceSlice";
import { ListBusParams, ListBusRequest } from "../../types";
import "./ListBus.scss";
import ImageUploading, { ImageListType } from "react-images-uploading";
import TutorialsVideo from "../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../utils/helpers";
import icClose from "../../assets/images/listOffice/icClose.svg";
import ReactImageUploading from "react-images-uploading";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const { Link } = Typography;
export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function ListBus() {
  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? DEFAULT_PAGE_SIZE
    : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const watchGetDefaultImage = Form.useWatch("get_default_image", form);
  const [status, setStatus] = useState<any>(undefined);
  const [keyword, setKeyword] = useState<ListBusRequest>({});
  const [keywordBus, setKeywordBus] = useState<any>({});
  const [keywordBusCreate, setKeywordBusCreate] = useState<any>({});
  const [page, setPage] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [fileListDelete, setFileListDelete] = useState<[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statusDetail, setStatusDetail] = useState(false);
  const [idDetail, setIdDetail] = useState("");
  const [seatRender, setSeatRender] = useState<any>();
  const [isSubmit, setIsSubmit] = useState(false);
  const [busImg, setBusImg] = useState([]);
  const [licenseImg, setLicenseImg] = useState<any[]>([]);
  const [insuranceImg, setInsuranceImg] = useState<any[]>([]);
  const [thumbnail, setThumbnail] = useState<any[]>([]);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState("");
  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );

  const {
    loading: loadingCreate,
    status: statusResponseCreate,
    errorDetail: errorCreate,
  } = useAppSelector(createBusSelector);
  const { status: statusResponseUpdate, errorDetail: errorUpdate } =
    useAppSelector(updateBusSelector);

  const listServices = useAppSelector(
    (state) => state?.listService?.listServices
  ) as any;
  const { listAllAvailableBusType } = useAppSelector(listBusTypeSelector);
  const {
    loading,
    buses,
    pagination,
    status: statusResponse,
  } = useAppSelector(listBusSelector);

  const paramsSearchBusType = Form.useWatch("bus_type_search", form);

  const NameRule: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập tên xe"),
    },
  ];

  const PlateRule: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập biển số xe"),
    },
    {
      pattern: new RegExp(/[0-9]{2}[A-Z]{1}[0-9]{4,6}/),
      message: t("Vui lòng nhập biển số xe theo định dạng 30A12345"),
    },
  ];

  const LicenseRule: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập thời gian hết hạn"),
    },
  ];
  const InsuranceRule: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập thời gian hết hạn"),
    },
  ];

  const TypeBusRule: Rule[] = [
    {
      required: true,
      message: t("Vui lòng chọn loại xe"),
    },
  ];
  const onChangeStatus = (value: boolean | undefined) => {
    setStatus(value);
  };
  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const onChangeGroupBusInput = (values: any, option: any) => {
    setSeatRender(option.seatMap);
  };

  const onChange = (checkedValues: any) => {};

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment(new Date());
  };

  useRedirectStatusError(statusResponse);

  const cancelModal = () => {
    setOpenCreate(false);
    setIsUpdate(false);
    form.resetFields();
    setInsuranceImg([]);
    setLicenseImg([]);
    setThumbnail([]);
    setFileListDelete([]);
    setSeatRender(undefined);
  };

  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      form.scrollToField(firstErrorField.name);
    }
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  const handleOkModal = () => {
    setIsModalVisible(false);
    if (statusDetail) {
      setStatusDetail(false);
    } else {
      setStatusDetail(true);
    }
    dispatch(updateStatusBus({ id: idDetail, status: statusDetail }));
  };

  const onSearch = (value: ListBusRequest) => {
    form.setFields([{ name: "keyword", value: mbTrim(value.keyword) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(form.getFieldsValue(["keyword"]));
  };

  const onRegister = (values: any) => {
    if (isSubmit) {
      if (
        !form
          .getFieldValue("bus_license_img")?.[0]
          ?.dataURL?.includes("http://")
      ) {
        values.bus_license_img =
          form.getFieldValue("bus_license_img")?.[0]?.dataURL;
      } else {
        values.bus_license_img = undefined;
      }
      if (
        !form.getFieldValue("insurance_img")?.[0]?.dataURL?.includes("http")
      ) {
        values.insurance_img =
          form.getFieldValue("insurance_img")?.[0]?.dataURL;
      } else {
        values.insurance_img = undefined;
      }

      if (form.getFieldValue("thumbnail")?.length === 0) {
        values.thumbnail = "";
      } else {
        if (!form.getFieldValue("thumbnail")?.[0]?.dataURL?.includes("https")) {
          values.thumbnail = form.getFieldValue("thumbnail")?.[0]?.dataURL;
        } else {
          values.thumbnail = undefined;
        }
      }

      let listBusImages = busImg?.map((image: any) => image?.dataURL);
      values.bus_img = listBusImages?.filter(
        (image) => !image?.includes("https://")
      );

      if (fileListDelete.length) {
        values.bus_img_delete = fileListDelete;
      }

      values.name = form.getFieldValue("name")?.toString().replace(",", ".");
      values.insurance_deadline = formatDate(
        form.getFieldValue("insurance_deadline"),
        YearFormat
      );
      values.bus_license_deadline = formatDate(
        form.getFieldValue("bus_license_deadline"),
        YearFormat
      );
      values.plate_number = form.getFieldValue("plate_number");
      values.bus_type_id = form.getFieldValue("bus_type_id");
      values.seat_map_id = form.getFieldValue("bus_type_id");
      values.service = form.getFieldValue("service");
      values.get_default_image = form.getFieldValue("get_default_image")
        ? 1
        : 0;
      if (isUpdate) {
        values.id = idDetail;
        dispatch(updateBus(values));
      } else {
        dispatch(createBus(values));
      }
      setSeatRender(undefined);
      setIsSubmit(false);
    }
  };

  const onFieldDetail = (record: any) => {
    setSeatRender(record?.bus_type?.seat_map);
    setIsUpdate(true);
    setStatusDetail(record.status);
    setIdDetail(record.id);
    if (record.insurance_img) {
      setInsuranceImg([{ dataURL: record.insurance_img }]);
      form.setFieldsValue({
        insurance_img: [{ dataURL: record.insurance_img }],
      });
    }

    if (record.bus_license_img) {
      setLicenseImg([{ dataURL: record.bus_license_img }]);
      form.setFieldsValue({
        bus_license_img: [{ dataURL: record.bus_license_img }],
      });
    }

    if (record.thumbnail) {
      setThumbnail([{ dataURL: record.thumbnail }]);
      form.setFieldsValue({
        thumbnail: [{ dataURL: record.thumbnail }],
      });
    }

    if (record?.bus_img?.length > 0) {
      setBusImg(
        record?.bus_img?.map((image: string) => ({
          dataURL: image,
        }))
      );
      form.setFieldsValue({
        bus_img: record?.bus_img?.map((image: string) => ({
          dataURL: image,
        })),
      });
    }

    form.setFieldsValue({
      name: record.name,
      insurance_deadline: dayjs(record.insurance_deadline),
      bus_license_deadline: dayjs(record.bus_license_deadline),
      plate_number: record.plate_number,
      bus_type_id: record?.bus_type?.id,
      service: record?.service?.map((item: any) => {
        return item.id;
      }),
      get_default_image: record?.get_default_image,
    });
    setOpenCreate(true);
  };

  const onCancelSuccess = useCallback(() => {
    dispatch(resetRegisterCreateBus());
    dispatch(resetUpdateBus());
    dispatch(fetchListBus({ ...keyword, page, status, limit }));
    cancelModal();
  }, [dispatch, form, setOpenCreate]);

  useEffect(() => {
    dispatch(
      fetchListBus({
        ...keyword,
        page,
        status,
        limit,
        bus_type_id: paramsSearchBusType,
      })
    );
  }, [
    status,
    dispatch,
    keyword,
    page,
    limit,
    statusDetail,
    paramsSearchBusType,
  ]);

  useEffect(() => {
    dispatch(getAllAvailableBusType({ keyword: keywordBus }));
  }, [dispatch, keywordBus]);
  useEffect(() => {
    dispatch(getAllAvailableBusType({ keyword: keywordBusCreate }));
  }, [dispatch, keywordBusCreate]);

  useEffect(() => {
    if (statusResponseCreate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
      dispatch(resetRegisterCreateBus);
    }
  }, [statusResponseCreate]);
  // Test
  useEffect(() => {
    if (statusResponseUpdate === Constant.DEFAULT_STATUS) {
      setIdDetail("");
      onCancelSuccess();
    }
  }, [statusResponseUpdate]);

  useEffect(() => {
    if (errorCreate) {
      form.setFields(
        Object.keys(errorCreate).map((fieldName) => ({
          name: fieldName,
          errors: [errorCreate[fieldName]],
        }))
      );
    }
  }, [errorCreate]);

  useEffect(() => {
    if (errorUpdate) {
      form.setFields(
        Object.keys(errorUpdate).map((fieldName) => ({
          name: fieldName,
          errors: [errorUpdate[fieldName]],
        }))
      );
    }
  }, [errorUpdate]);
  useEffect(() => {
    dispatch(fetchAllService());
  }, [dispatch]);

  useRedirectStatusError(statusResponse);
  const columns: ColumnsType<ListBusParams> = [
    {
      title: "Tên xe",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      render: (_, record) => (
        <Space size="middle">
          {record.status ? (
            <FontAwesomeIcon icon={faCircle} className="green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="danger" size="xs" />
          )}
          <Typography className="text-color">{record.name}</Typography>
        </Space>
      ),
    },
    {
      title: "Biển số",
      dataIndex: "plate_number",
      key: "plate_number",
      width: 130,
    },
    {
      title: "Loại xe",
      dataIndex: "id",
      key: "id",
      render: (_, record) => (
        <Space size="middle">
          <Typography className="text-color">
            {record?.bus_type?.name}
          </Typography>
        </Space>
      ),
      sorter: (a, b) => a?.bus_type?.name.localeCompare(b?.bus_type?.name),
    },
    {
      title: "Số chỗ",
      dataIndex: "seat_number",
      key: "seat_number",
      align: "center",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Typography className="text-color">
              {record?.bus_type?.seat_number}
            </Typography>
          </Space>
        );
      },
      sorter: (a, b) => a.seat_number - b.seat_number,
    },
    {
      title: "Hạn đăng kiểm",
      dataIndex: "bus_license_deadline",
      key: "bus_license_deadline",
      sorter: (a, b) =>
        moment(a.bus_license_deadline).unix() -
        moment(b.bus_license_deadline).unix(),
      render: (_, record) => (
        <Row>
          {moment(record.bus_license_deadline).unix() <
          moment(new Date()).unix() ? (
            <>
              <Typography className="text-time-out me-2">
                {moment(record.bus_license_deadline).format("DD/MM/YYYY")}
              </Typography>
              <Image
                src={record.status ? IcTimeOut : IcTimeOutDisable}
                width={14}
                height={14}
                preview={false}
                className="mb-1"
              />
            </>
          ) : (
            <Typography className="text-color">
              {moment(record.bus_license_deadline).format("DD/MM/YYYY")}
            </Typography>
          )}
        </Row>
      ),
      width: "16%",
    },
    {
      title: "Tiện ích xe",
      dataIndex: "id",
      render: (_, record) => (
        <div className={record.status ? "" : "color-disabled"}>
          {record.service?.map((item: any) => {
            return (
              <>
                {item.icon && !item?.company_id ? (
                  <Image
                    width={25}
                    height={25}
                    src={item.icon}
                    className="p-1"
                    preview={false}
                  />
                ) : null}
              </>
            );
          })}
        </div>
      ),
    },
    {
      title: "Chức năng",
      dataIndex: "action",
      key: "action",
      render: () => (
        <Space size="middle">
          <Link>
            <Image src={icEdit} width={17} height={17} preview={false} />
          </Link>
        </Space>
      ),
      align: "center",
    },
  ];

  function onSearchBusType(value: any) {
    setKeywordBusCreate(value);
  }

  const BusOptions: SelectProps["options"] = listAllAvailableBusType?.length
    ? listAllAvailableBusType?.map((item) => {
        return {
          value: item.id,
          label: item.name,
          seatMap: item.seat_map,
        };
      })
    : [];
  const filterOptionBus = (
    input: string,
    option?: {
      label?: any;
      value?: any;
      seatMap?: any;
    }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeBusImage = (imageList: ImageListType) => {
    setBusImg(imageList as never[]);
  };

  const onChangeLicenseImage = (imageList: ImageListType) => {
    setLicenseImg(imageList as never[]);
  };

  const onChangeInsuranceImage = (imageList: ImageListType) => {
    setInsuranceImg(imageList as never[]);
  };

  const onChangeThumbnail = (imageList: ImageListType) => {
    setThumbnail(imageList as never[]);
  };

  const handleErrorUploadBusImg = (errors: any, files: any) => {
    if (errors?.acceptType) {
      toast.error(
        "Ảnh tải lên phải thuộc 1 trong 4 định dạng sau: png, jpg, jpeg, gif."
      );
    }
    if (errors?.maxNumber) {
      toast.error("Vui lòng tải lên tối đa 12 ảnh.");
    }
  };

  const handleErrorUpload = (errors: any, files: any) => {
    if (errors?.acceptType) {
      toast.error(
        "Ảnh tải lên phải thuộc 1 trong 4 định dạng sau: png, jpg, jpeg, gif."
      );
    }
    if (errors?.maxNumber) {
      toast.error("Vui lòng tải lên tối đa 1 ảnh.");
    }
  };
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({ is_represent: e.target.checked });
  };

  return (
    <Layout className="ListBus min-vh-70 list_user">
      <Card>
        <StatusFilter status={status} onChangeStatus={onChangeStatus} />
        <Row className="fw-500 mt-4 mx-3">
          <Button
            type="primary"
            className={"btn-success height-btn-40"}
            onClick={() => setOpenCreate(true)}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t("Thêm xe mới")}
          </Button>
        </Row>
        <Form
          className="mg-16 mt-3 mx-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          onFinish={onSearch}
          colon={false}
          style={{ position: "relative" }}
        >
          <Row gutter={10}>
            <Col>
              <Form.Item name="keyword">
                <Input
                  placeholder={t("Nhập Tên xe / Biển số")}
                  style={{ width: 300 }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Item name="bus_type_search">
                <Select
                  className="input-h36"
                  showSearch
                  placeholder="Loại xe"
                  onSearch={onSearchBusType}
                  filterOption={filterOptionBus}
                  options={[{ value: "", label: "Tất cả" }, ...BusOptions]}
                />
              </Form.Item>
            </Col>
            <Col className="text-center">
              <Button
                htmlType="submit"
                type="primary"
                className={"btn-search height-btn-36"}
                size="large"
              >
                Tìm kiếm
              </Button>
            </Col>
          </Row>
          <Space
            size={10}
            align="center"
            style={{ position: "absolute", right: 0, top: 7 }}
          >
            <TutorialsVideo
              url_youtube={handleGetUrlGuide(listTutorials, 16)}
            />
          </Space>
        </Form>
        <Table
          locale={{
            triggerDesc: "Sắp xếp tăng dần",
            triggerAsc: "Hủy sắp xếp",
            cancelSort: "Sắp xếp giảm dần",
          }}
          dataSource={buses?.length > 0 ? buses : undefined}
          rowKey="id"
          rowClassName={(record) => (record?.status ? "" : "bg-disabled")}
          onRow={(record) => ({
            onClick: () => onFieldDetail(record),
          })}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          className="mt-1 mx-3 list-bus"
          loading={loading}
        />

        <Modal
          centered
          className="create-bus bus"
          maskClosable={false}
          closeIcon={false}
          title={
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              <Typography.Text className="fw-600 fs-18 ">
                {isUpdate ? t(`Chỉnh sửa thông tin xe`) : t(`Tạo xe mới`)}
              </Typography.Text>
              <Space size={10} align="center">
                <TutorialsVideo
                  url_youtube={handleGetUrlGuide(listTutorials, 16)}
                />
                <Image
                  src={icClose}
                  style={{ cursor: "pointer" }}
                  preview={false}
                  onClick={cancelModal}
                />
              </Space>
            </Row>
          }
          open={openCreate}
          onOk={() => setOpenCreate(false)}
          onCancel={() => cancelModal()}
          styles={{
            body: {
              maxHeight: "700px",
              overflowY: "auto",
              overflowX: "hidden",
            },
          }}
          width={1745}
          footer={[]}
        >
          <Form
            className="ListBus__form mg-16 "
            form={form}
            labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
            requiredMark={false}
            onFinish={onRegister}
            onFieldsChange={(changedFields, allFields) => {
              scrollToErrorField(
                allFields.filter(
                  (field) => field.errors && field.errors.length > 0
                )
              );
            }}
            colon={false}
          >
            <Spin spinning={loadingCreate}>
              <Row gutter={18}>
                <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
                  <span className={"required fw-500"}>Tên xe</span>
                  <Form.Item
                    required={true}
                    name="name"
                    rules={NameRule}
                    validateFirst
                  >
                    <Input
                      className="fw-500 fs-16 text-color"
                      placeholder="Nhập Tên xe"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
                  <span className={"required fw-500"}>{t(`Biển số xe`)}</span>
                  <Form.Item
                    required={true}
                    name="plate_number"
                    rules={PlateRule}
                  >
                    <Input
                      className="fw-500 fs-16 text-color"
                      placeholder={t("Nhập biển số xe")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
                  <span className={"required fw-500"}>
                    {t(`Hạn đăng kiểm`)}{" "}
                  </span>
                  <Form.Item
                    name="bus_license_deadline"
                    className="data-picker"
                    rules={LicenseRule}
                    required={true}
                  >
                    <DatePicker
                      className="w-100 input-height fw-500 fs-16 ListBus__form__date"
                      format={DateFormat}
                      disabledDate={disabledDate}
                      placeholder={t("Chọn ngày")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
                  <span className={"required fw-500"}>Hạn bảo hiểm xe</span>
                  <Form.Item
                    required={true}
                    name="insurance_deadline"
                    className="data-picker"
                    rules={InsuranceRule}
                  >
                    <DatePicker
                      className="w-100 input-height fw-500 fs-16 ListBus__form__date"
                      format={DateFormat}
                      disabledDate={disabledDate}
                      placeholder={t("Chọn ngày")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
                  <span className={"required fw-500"}>{t(`Loại xe`)} </span>
                  <Form.Item
                    required={true}
                    name="bus_type_id"
                    rules={TypeBusRule}
                  >
                    <Select
                      className="input-h36 text-color"
                      defaultValue={""}
                      showSearch
                      placeholder="Loại xe"
                      onChange={onChangeGroupBusInput}
                      onSearch={onSearchBusType}
                      filterOption={filterOptionBus}
                      options={[...BusOptions]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {seatRender?.count_floor && (
                <div className="seat-render-custom">
                  <SeatMapRender
                    column={seatRender.column ?? 0}
                    row={seatRender?.row ?? 0}
                    seatCount={seatRender?.count_floor}
                    seatMatrixData={seatRender}
                  />
                </div>
              )}
              <Row className="mt-4">
                <Col span={3}>
                  <span className={"required fw-500"}>{t(`Tiện ích xe`)} </span>
                </Col>
                <Col span={21} className={"ps-4"}>
                  <Form.Item name="service">
                    <Checkbox.Group
                      className="text-color-default fw-500"
                      options={listServices}
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={11} xxl={7} className="mr-25">
                  <span className="required fw-500">Giấy đăng kiểm xe</span>
                  <Form.Item
                    name="bus_license_img"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập ảnh đăng kiểm xe",
                      },
                    ]}
                    className="image-insurance"
                  >
                    <ImageUploading
                      value={form.getFieldValue("bus_license_img")}
                      multiple
                      onChange={onChangeLicenseImage}
                      maxNumber={1}
                      acceptType={["jpg", "gif", "png", "jpeg"]}
                      onError={handleErrorUpload}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => {
                        return (
                          <div className="Upload">
                            {imageList?.map((image, index) => (
                              <div key={index} className="Upload__item">
                                <div className="Upload__item__wrapper">
                                  <img
                                    className="Upload__item__image"
                                    src={image?.dataURL}
                                    alt=""
                                    width="100"
                                  />
                                  <div className="Upload__item__remove">
                                    <button
                                      onClick={() => {
                                        setPreviewImage(image?.dataURL as any);
                                        setOpenPreview(true);
                                      }}
                                      className="border-0 bg-transparent"
                                    >
                                      <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                    </button>
                                    <button
                                      className="border-0 bg-transparent"
                                      onClick={() => onImageRemove(index)}
                                    >
                                      <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {imageList?.length <= 0 && (
                              <div className="Upload__item">
                                <button
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  className="Upload__item__button"
                                  {...dragProps}
                                >
                                  <div>
                                    <div className="fs-18 fw-500 text-color-default">
                                      <UploadOutlined className="fs-24" /> Tải
                                      ảnh lên
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </ImageUploading>
                  </Form.Item>
                </Col>
                <Col xs={11} xxl={7}>
                  <span className={"required fw-500"}>Bảo hiểm xe</span>
                  <Form.Item
                    name="insurance_img"
                    className="image-insurance"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập ảnh bảo hiểm xe",
                      },
                    ]}
                  >
                    <ImageUploading
                      value={form.getFieldValue("insurance_img")}
                      multiple
                      onChange={onChangeInsuranceImage}
                      maxNumber={1}
                      acceptType={["jpg", "gif", "png", "jpeg"]}
                      onError={handleErrorUpload}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => {
                        return (
                          <div className="Upload">
                            {imageList?.map((image, index) => (
                              <div key={index} className="Upload__item">
                                <div className="Upload__item__wrapper">
                                  <img
                                    className="Upload__item__image"
                                    src={image?.dataURL}
                                    alt=""
                                    width="100"
                                  />
                                  <div className="Upload__item__remove">
                                    <button
                                      onClick={() => {
                                        setPreviewImage(image?.dataURL as any);
                                        setOpenPreview(true);
                                      }}
                                      className="border-0 bg-transparent"
                                    >
                                      <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                    </button>
                                    <button
                                      className="border-0 bg-transparent"
                                      onClick={() => onImageRemove(index)}
                                    >
                                      <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {imageList?.length <= 0 && (
                              <div className="Upload__item">
                                <button
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  className="Upload__item__button"
                                  {...dragProps}
                                >
                                  <div>
                                    <div className="fs-18 fw-500 text-color-default">
                                      <UploadOutlined className="fs-24" /> Tải
                                      ảnh lên
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </ImageUploading>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="get_default_image"
                valuePropName="checked"
                className="mt-4"
              >
                <Checkbox onChange={onCheckboxChange}>
                  {t("Lấy ảnh từ màn Quản lý sơ đồ xe")}
                </Checkbox>
              </Form.Item>

              <Row className="mt-4">
                <Col xs={24}>
                  <span className={"required fw-500"}>Ảnh đại diện xe</span>
                  <Form.Item
                    name="thumbnail"
                    className=" thumbnail"
                    style={{ width: "200px" }}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: !watchGetDefaultImage,
                        message: "Vui lòng nhập ảnh đại diện xe",
                      },
                    ]}
                  >
                    <ReactImageUploading
                      value={form.getFieldValue("thumbnail")}
                      multiple
                      onChange={onChangeThumbnail}
                      maxNumber={1}
                      acceptType={["jpg", "gif", "png", "jpeg"]}
                      onError={handleErrorUpload}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => {
                        return (
                          <div className="Upload">
                            {imageList?.map((image, index) => {
                              return (
                                <div
                                  key={index}
                                  className="Upload__item"
                                  style={{ height: "200px" }}
                                >
                                  <div className="Upload__item__wrapper">
                                    <img
                                      className="Upload__item__image"
                                      src={image?.dataURL}
                                      alt=""
                                      width="100"
                                    />
                                    <div className="Upload__item__remove">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setPreviewImage(
                                            image?.dataURL as any
                                          );
                                          setOpenPreview(true);
                                        }}
                                        className="border-0 bg-transparent"
                                      >
                                        <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                      <button
                                        type="button"
                                        className="border-0 bg-transparent"
                                        onClick={() => {
                                          onImageRemove(0);
                                        }}
                                      >
                                        <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {imageList?.length <= 0 && (
                              <div className="Upload__item type-bus-image">
                                <button
                                  type="button"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  className="Upload__item__button"
                                  {...dragProps}
                                >
                                  <div>
                                    <div className="fs-15 fw-500 text-color-default">
                                      <UploadOutlined className="fs-15" /> Tải
                                      ảnh lên
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </ReactImageUploading>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={24}>
                  <span className="required fw-500">Ảnh xe</span>
                  <Form.Item
                    name="bus_img"
                    className="list_image_car"
                    rules={[
                      {
                        required: !watchGetDefaultImage,
                        message: "Vui lòng nhập ảnh xe",
                      },
                    ]}
                  >
                    <ImageUploading
                      multiple
                      value={form.getFieldValue("bus_img")}
                      onChange={onChangeBusImage}
                      maxNumber={20}
                      acceptType={["jpg", "gif", "png", "jpeg"]}
                      onError={handleErrorUploadBusImg}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="Upload">
                          <Row className="h-100" gutter={[16, 16]}>
                            {imageList?.length > 0 &&
                              imageList?.map((image, index) => (
                                <Col
                                  span={6}
                                  key={index}
                                  className="Upload__item"
                                  style={{ height: "200px" }}
                                >
                                  <div className="Upload__item__wrapper">
                                    <img
                                      className="Upload__item__image"
                                      src={image?.dataURL}
                                      alt=""
                                    />
                                    <div className="Upload__item__remove">
                                      <button
                                        onClick={() => {
                                          setPreviewImage(
                                            image?.dataURL as any
                                          );
                                          setOpenPreview(true);
                                        }}
                                        className="border-0 bg-transparent"
                                      >
                                        <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                      <button
                                        className="border-0 bg-transparent"
                                        onClick={() => {
                                          const startIndex =
                                            image?.dataURL?.indexOf(
                                              "/bus/bus_img/"
                                            );
                                          setFileListDelete(
                                            (fileList) =>
                                              [
                                                ...fileList,
                                                image?.dataURL?.slice(
                                                  startIndex
                                                ),
                                              ] as any
                                          );
                                          onImageRemove(index);
                                        }}
                                      >
                                        <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                      </button>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            <Col
                              span={6}
                              className="Upload__item type-bus-image"
                            >
                              <button
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                onClick={onImageUpload}
                                className="Upload__item__button"
                                {...dragProps}
                              >
                                <div>
                                  <div className="fs-15 fw-500 text-color-default">
                                    <UploadOutlined className="fs-15" /> Tải ảnh
                                    lên
                                  </div>
                                </div>
                              </button>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </ImageUploading>
                  </Form.Item>
                </Col>
              </Row>
            </Spin>
            <Row className="ant-modal-footer z-index1200">
              {isUpdate && (
                <Col
                  span={6}
                  className="d-flex justify-content-start"
                  style={{ paddingLeft: "30px" }}
                >
                  {statusDetail ? (
                    <Button
                      className="btn-block-red w-160 h-btn-48"
                      danger
                      type="primary"
                      htmlType="button"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Image
                        src={IcLock}
                        preview={false}
                        className="pb-1 pe-2"
                      />
                      {t("Khoá xe")}
                    </Button>
                  ) : (
                    <Button
                      className="btn-active-green w-160 h-btn-48"
                      danger
                      type="primary"
                      htmlType="button"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Image
                        src={IcUnLock}
                        preview={false}
                        className="pb-1 pe-2"
                      />
                      {t("Mở khóa xe")}
                    </Button>
                  )}
                </Col>
              )}
              <Col
                span={isUpdate ? 18 : 24}
                className="d-flex justify-content-end"
                style={{ paddingRight: "15px", columnGap: "12px" }}
              >
                <Button
                  key="cancel"
                  className="w-200 h-btn-48"
                  disabled={loadingCreate}
                  onClick={() => cancelModal()}
                >
                  Đóng
                </Button>
                <Button
                  key="ok"
                  type="primary"
                  className="w-200 h-btn-48 btn-success"
                  disabled={loadingCreate}
                  htmlType="submit"
                  onClick={() => setIsSubmit(true)}
                >
                  Lưu
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  key="ok"
                  type="primary"
                  className="d-none w-270 h-btn-48 btn-success"
                ></Button>
              </Col>
            </Row>
          </Form>
          <Modal
            footer={null}
            open={openPreview}
            onCancel={() => setOpenPreview(false)}
            width={700}
          >
            <div className="w-100" style={{ padding: "16px" }}>
              <img className="w-100" src={previewImage} alt="preview" />
            </div>
          </Modal>
        </Modal>

        <ModalConfirm
          modalOpen={isModalVisible}
          okText={statusDetail ? t("Khóa ngay") : t("Mở ngay")}
          cancelText={t("Đóng")}
          modalTitle={statusDetail ? t("Khoá xe") : t("Mở xe")}
          modalContent={
            statusDetail
              ? t("Bạn đang thao tác khóa xe này, bạn có chắc chắn muốn khóa?")
              : t("Bạn đang thao tác mở xe này, bạn có chắc chắn muốn mở?")
          }
          onCancel={handleCancelModal}
          onOk={handleOkModal}
        />
      </Card>
    </Layout>
  );
}

export default ListBus;
