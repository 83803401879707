import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  Flex,
  Image,
} from "antd";
import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";
import { Rule } from "antd/es/form";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import "../../assets/styles/app.scss";
import "../../config/i18n/i18n";
import { listUserSelector } from "../../redux/slice/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./DriverModal.scss";

import {
  createDriver,
  driverSelector,
  setDriver,
  updateDriver,
  updateStatusDriver,
} from "../../redux/slice/driverSlice";
import { Constant, YearFormat } from "../../config/constant";
import dayjs from "dayjs";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import IconLock from "../../components/Icons/IconLock";
import TutorialsVideo from "../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../utils/helpers";
import icClose from "../../assets/images/listOffice/icClose.svg";


// dayjs.extend(customParseFormat);
const dateFormat = "DD/MM/YYYY";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function ExtraDriverModal({
  open,
  setOpen,
  onCancelSuccess,
  listTutorials
}: {
  open: boolean;
  setOpen: Function;
  onCancelSuccess: Function;
  listTutorials: any
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [userStatus, setUserStatus] = useState(Boolean);

  const dispatch = useAppDispatch();
  const { user, loading, success } = useAppSelector(listUserSelector);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [avatar, setAvatar] = useState<UploadFile[]>([]);
  const handleCancel = () => setPreviewOpen(false);
  const [isModalConfirmVisible, setIsModalConfirmVisible] =
    useState<boolean>(false);

  const {
    loading: loadingCreate,
    success: statusSuccessCreate,
    successUpdate: statusUpdateSuccess,
    driver,
    errorDetail,
  } = useAppSelector(driverSelector);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChangeAvatar: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setAvatar(newFileList);
    form.setFieldsValue({
      portrait_photo: newFileList,
    });
  };

  const handleModalCancel = () => {
    setOpen(false);
    form.resetFields();
    setAvatar([]);
    dispatch(setDriver(undefined));
  };

  const handleCancelModalConfirm = () => {
    setIsModalConfirmVisible(false);
  };

  const dateOfBirthRules = [
    {
      required: true,
      message: t("Vui lòng nhập ngày sinh."),
    },
  ];

  const phoneRules = [
    {
      whitespace: true,
      message: t("Số điện thoại không đúng định dạng."),
    },
    {
      required: true,
      message: t("Vui lòng nhập số điện thoại."),
    },
    {
      number: true,
      message: t("Số điện thoại không đúng định dạng."),
    },
  ];

  const nameRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập họ và tên"),
    },
  ];

  const avatarRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng chọn ảnh."),
    },
  ];

  useEffect(() => {
    if (statusSuccessCreate === Constant.STATUS_ACTIVE) {
      // setOpen(false);
      handleModalCancel();
      onCancelSuccess();
    }
  }, [statusSuccessCreate]);

  useEffect(() => {
    if (statusUpdateSuccess === Constant.STATUS_ACTIVE) {
      onCancelSuccess();
    }
  }, [statusUpdateSuccess]);

  useEffect(() => {
    if (driver && driver.type === "extra_driver") {
      form.setFieldsValue({
        name: driver?.name,
        phone: driver?.phone,
        date_of_birth: dayjs(driver?.date_of_birth),
      });
      if (driver?.portrait_photo) {
        setAvatar([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: driver?.portrait_photo,
          },
        ]);
        form.setFieldsValue({
          portrait_photo: driver?.portrait_photo,
        });
      }
    } else {
      form.resetFields();
      setAvatar([]);
    }
    setIsModalConfirmVisible(false);
  }, [driver]);

  useEffect(() => {
    if (errorDetail) {
      form.setFields(
        Object.keys(errorDetail).map((fieldName) => ({
          name: fieldName,
          errors: [errorDetail[fieldName]],
        }))
      );
    }
  }, [errorDetail]);

  const handleLockDriver =
    (id: number, status: boolean, type: string) => (event: any) => {
      dispatch(
        updateStatusDriver({
          id: id,
          status: status,
          type: type,
        })
      )
        .then((response) => {
          toast.success(response?.payload?.message);
          setOpen(false);
        })
        .catch((err) => {
          toast.error("Thao tác thất bại");
        });
    };

  const onFinish = (values: any) => {
    values.date_of_birth = dayjs(values?.date_of_birth).format(YearFormat);
    values.portrait_photo = avatar[0]?.thumbUrl;
    values.company_id = 1;
    values.type = "extra_driver";
    if (driver) {
      values.id = driver?.id;
      dispatch(updateDriver(values));
    } else {
      dispatch(createDriver(values));
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t("Tải ảnh lên")}</div>
    </div>
  );

  useEffect(() => {
    if (success) {
      if (user?.status === 1) {
        setUserStatus(true);
      } else {
        setUserStatus(false);
      }
    }
  }, [success]);

  return (
    <>
      <Modal
        className="carlink-modal driver-modal ExtraDriverModal"
        forceRender
        maskClosable={false}
        // title={driver ? t("Chi tiết thông tin phụ xe") : t("Thêm phụ xe")}
        closeIcon={false}
        title={
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text className="fw-600 fs-18 ">
              {driver ? t("Chi tiết thông tin phụ xe") : t("Thêm phụ xe")}
            </Typography.Text>
            <Space size={10} align="center">
              <TutorialsVideo
                url_youtube={handleGetUrlGuide(listTutorials, 17)}
              />
              <Image
                src={icClose}
                style={{ cursor: "pointer" }}
                preview={false}
                onClick={handleModalCancel}
              />
            </Space>
          </Row>}
        centered
        open={open}
        onCancel={handleModalCancel}
        width={900}
        footer={
          <Row className="justify-content-between w-100" align={"middle"}>
            <Col>
              {driver ? (
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => setIsModalConfirmVisible(true)}
                    size="large"
                    className={`ExtraDriverModal__button__lock h-auto d-flex align-items-center justify-content-center ${
                      driver?.status ? "btn-block-red" : "btn-active-green"
                    }`}
                    disabled={loadingCreate}
                  >
                    <IconLock width={16} height={16} />
                    {driver?.status ? t("Khóa phụ xe") : t("Mở khóa phụ xe")}
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Col>
            <Row gutter={16} align={"middle"}>
              <Col>
                <Button
                  key="back"
                  onClick={handleModalCancel}
                  size="large"
                  className="ExtraDriverModal__button__cancel h-auto"
                  disabled={loadingCreate}
                >
                  {t("Đóng")}
                </Button>
              </Col>
              <Col>
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={form.submit}
                  size="large"
                  className="ExtraDriverModal__button__submit h-auto"
                  htmlType="submit"
                  disabled={loadingCreate}
                >
                  {t("Lưu")}
                </Button>
              </Col>
            </Row>
          </Row>
        }
      >
        <Form
          name="extra-driver-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          className="extra-driver-form mt-0"
          labelCol={{ span: 12 }}
          colon={false}
          labelAlign={"left"}
          labelWrap
        >
          <Spin spinning={loadingCreate}>
            <Space direction="vertical" size={30} style={{ width: "100%" }}>
              <Row gutter={32}>
                <Col xs={24} md={12}>
                  <Row gutter={0}>
                    <Col xs={24} md={24}>
                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                      <Form.Item
                        name="portrait_photo"
                        className="form-item-select text-left portrait-photo"
                        label={t("Ảnh chân dung")}
                        rules={avatarRules}
                        required={true}
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        {/* <ImgCrop quality={1} aspect={220 / 320}> */}
                          <Upload
                            action={`${process.env.REACT_APP_API_URL}/preview`}
                            listType="picture-card"
                            fileList={avatar}
                            onPreview={handlePreview}
                            onChange={handleChangeAvatar}
                          >
                            {avatar?.length >= 1 ? null : uploadButton}
                          </Upload>
                        {/* </ImgCrop> */}
                        {driver && (
                          <Flex className="mt-8" align="center">
                            <Typography.Text className="text-color-gray-3A3A3A d-block fs-16 mr-8">
                              {driver?.status
                                ? "Phụ xe đang hoạt động"
                                : "Phụ xe đang bị khóa"}
                            </Typography.Text>
                            {driver?.status ? (
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="p-1 green fs-10"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="p-1 fs-10 danger"
                              />
                            )}
                          </Flex>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12}>
                  <Row gutter={20}>
                    <Col xs={24} md={24}>
                      <Form.Item
                        name="name"
                        className="form-item-name required"
                        label={t("Họ và tên")}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={nameRules}
                        required={true}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <Form.Item
                        name="date_of_birth"
                        className="required"
                        required={true}
                        label={t("Ngày sinh")}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={dateOfBirthRules}
                        validateFirst
                      >
                        <DatePicker
                          className="w-100 input-height"
                          format={dateFormat}
                          changeOnBlur
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={224}>
                      <Form.Item
                        name="phone"
                        label={t("Số điện thoại")}
                        required={true}
                        validateTrigger={["onChange", "onBlur"]}
                        className="required"
                        rules={phoneRules}
                        validateFirst
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Space>
          </Spin>
        </Form>
      </Modal>
      {driver ? (
        <ModalConfirm
          modalOpen={isModalConfirmVisible}
          okText={driver && driver?.status ? t("Khóa ngay") : t("Mở ngay")}
          cancelText={t("Đóng")}
          modalTitle={
            driver && driver?.status ? t("Khoá phụ xe") : t("Mở phụ xe")
          }
          modalContent={
            driver && driver?.status
              ? t(
                  "Bạn đang thao tác khóa phụ xe này, bạn có chắc chắn muốn khóa?"
                )
              : t("Bạn đang thao tác mở phụ xe này, bạn có chắc chắn muốn mở?")
          }
          onCancel={handleCancelModalConfirm}
          onOk={handleLockDriver(driver?.id, !driver?.status, driver?.type)}
        />
      ) : null}
    </>
  );
}

export default ExtraDriverModal;
