/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Table,
  Typography,
  Tag,
  message,
  Space,
  Spin,
  Modal,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import IcMoneyLinear from "../../assets/images/accountDeposit/money-linear.png";
import IcMiCopy from "../../assets/images/accountDeposit/mi_copy.svg";
import IcWarningInfo from "../../assets/images/accountDeposit/warning-info.svg";
import IcMiCopyGreen from "../../assets/images/accountDeposit/mi_copy_green.svg";
import StatusFilter from "./StatusFilter";
import { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { formatPrice, formatPriceInput } from "../../lib/utils";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import IcPrice from "../../assets/images/accountDeposit/icPrice.svg";
import { useEffect, useRef, useState } from "react";



import {
  getAgentInfoTransferApi,
  getCreateAgentDepositApi,
  getCreateAgentDepositQRApi,
} from "../../api/agent";
import { fetchHistory } from "../../redux/slice/history/historySlice";
import { ACTION_NAME, messageCreateSuccess } from "../../config/message";
import { checkAuthorization } from "../../api";
import { validateAmountDeposit } from "../../config/validators/validateDeposit";
import "./AccountDeposit.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { URL_API_VIET_QR } from "../../api/vietqr";
import Summary from "../../components/summary/Summary";
import { TIMER_DEPOSIT } from "../../config/constant";

const { RangePicker } = DatePicker;


function DepositAgent() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination')
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const [form] = Form.useForm();
  const [listBank, setListBank] = useState<any>([]);
  const dispatch = useAppDispatch();
  const {
    result,
    pagination,
    isFetching,
    total_confirmed,
    total_cancel,
    total_waiting,
  } = useAppSelector((state) => state?.history);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);

  const [spinning, setSpinning] = useState<boolean>(false);

  const { t } = useTranslation();
  const [status, setStatus] = useState<any>("");
  const [page, setPage] = useState<number>(1);
  const [depositAmount, setDepositAmount] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [qrCode, setQrCode] = useState<any>({});
  const [loadingBtnQR, setLoadingBtnQR] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [dateRange, setDateRange] = useState<any>("");

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  };

  const handleOk = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleIsOpen = () => {
    const checkNumber: any = parseFloat(formatPrice(depositAmount));
    const checkValue = formatPrice(depositAmount);

    function isNumeric(value: any) {
      // Regular expression for numeric characters
      const numericRegex = /^\d+$/;
      // Check if the value matches the numeric regex
      return numericRegex.test(value);
    }

    if (!depositAmount) {
      toast.error("Bạn hãy nhập số tiền cần nạp");
    } else if (!isNumeric(checkValue)) {
      toast.error("Số tiền không đúng định dạng");
    } else if (checkNumber < 500000) {
      toast.error("Số tiền tối thiểu cần nạp là 500.000");
    } else {
      setIsOpen(true);
    }
  };

  const handleIsCancel = () => {
    setIsOpen(false);
  };

  const [infoTransfer, setInfoTransfer] = useState<any>({
    agent_balance: "",
    agent_name: "",
    transfer_info: {
      bank_account_number: "",
      bank_account_name: "",
      bank: "",
      content: "",
      payment_method: "",
    },
  });

  const handleChangeStatus = (value: boolean | undefined) => {
    setStatus(value);
  };

  const onChangeDepositAmount = (e: any) => {
    setDepositAmount(formatPriceInput(e.target?.value));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      width: 210,
    },
    {
      title: "Nội dung chuyển khoản",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Tài khoản nạp",
      dataIndex: "deposit_user",
      key: "deposit_user",
    },
    {
      title: "Tài khoản thao tác",
      dataIndex: "confirm_user",
      key: "confirm_user",
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 90,
      render: (_, record) => {
        const status = record?.status; // Destructure status from record
        let color;

        switch (status) {
          case "wait_confirm":
            color = "wait_confirm_deposit_btn";
            break;
          case "confirmed":
            color = "confirmed_deposit_btn"; // Consistent green for awaiting confirmation and confirmed
            break;
          case "cancel":
            color = "cancel_deposit_btn"; // Set red for cancelled status
            break;
          default:
            color = "default_deposit_btn";
        }

        let textStatus;
        if (status === "wait_confirm") {
          textStatus = "Chờ xác nhận";
        }
        if (status === "confirmed") {
          textStatus = "Đã xác nhận";
        }
        if (status === "cancel") {
          textStatus = "Đã từ chối";
        }

        return (
          <Tag color={"#000000"} className={color} key={record?.id}>
            {textStatus}
          </Tag>
        );
      },
    },
    {
      title: "Ngày yêu cầu",
      dataIndex: "deposit_date",
      key: "deposit_date",
      width: 220,
    },
  ];

  const handleAgentInfoTransferApi = async () => {
    setSpinning(true);
    try {
      const res = await getAgentInfoTransferApi();
      setInfoTransfer(res.data.data);
    } catch (error) {}
    setSpinning(false);
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(1);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const inputRef: any = useRef(null);

  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      message.success("Đã sao chép nội dung");
    }
  };

  useEffect(() => {
    handleAgentInfoTransferApi();
  }, []);

  let query = `page=${page}&limit=${limit}&status=${status}`;
  let actionSearch = `status=${status}`
  if (dateRange) {
    const [startDate, endDate] = dateRange;

    query += `&from=${startDate.format("YYYY-MM-DD")}&to=${endDate.format(
      "YYYY-MM-DD"
    )}`;
    actionSearch += `&from=${startDate.format("YYYY-MM-DD")}&to=${endDate.format(
      "YYYY-MM-DD"
    )}`;
  }
  const handleListHistory = () => {
    dispatch(fetchHistory({ query }));
  };
  useEffect(() => {
    handleListHistory();
     // Thiết lập interval để gọi API mỗi 30 giây
     const intervalId = setInterval(handleListHistory, TIMER_DEPOSIT);
    
     // Dọn dẹp interval khi component bị unmount
     return () => clearInterval(intervalId);
  }, [query]);

  const handleCreateReposit = async () => {
    setIsLoading(true);
    const values = {
      amount: formatPrice(depositAmount),
      content: infoTransfer?.transfer_info?.content,
    };
    try {
      const res = await getCreateAgentDepositApi(values);
      if (res.status === 200) {
        handleListHistory();
        handleAgentInfoTransferApi();
        setDepositAmount("");
        messageCreateSuccess(ACTION_NAME.REPOSIT);
        setOpen(false);
        setIsOpen(false);
      }
    } catch (error) {
      checkAuthorization(error);
    }
    setIsLoading(false);
  };
  const handleCreateRepositQR = async () => {
    setLoadingBtnQR(true);

    let query = `amount=${formatPrice(depositAmount)}&content=${
      infoTransfer?.transfer_info?.content
    }`;
    try {
      const res = await getCreateAgentDepositQRApi(query);
      if (res.status === 200) {
        setQrCode(res?.data?.data);
        handleOk();
      }
    } catch (error) {
      checkAuthorization(error);
    }
    setLoadingBtnQR(false);
  };

  const handleListQR = () => {
    try {
      axios
        .get(URL_API_VIET_QR)
        .then((response) => {
          const res = response?.data;
          setListBank(res?.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {}
  };

  const handleNameBank = (): any => {
    let nameBank: any = "";
    if (listBank?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return (nameBank = listBank?.filter((item: any) => {
        return item.bin === infoTransfer?.transfer_info?.bank;
      }));
    }
    return {};
  };

  useEffect(() => {
    handleListQR();
  }, []);

  useEffect(() => {
    setPage(1)
  }, [actionSearch])

  return (
    <>
      <Spin spinning={spinning}>
        <Card className="container-card-deposit">
          <Row gutter={8}>
            <Col className="text-deposit">Đại lý nạp tiền: </Col>
            <Col className="text-deposit fw-600">
              {infoTransfer?.agent_name}
            </Col>
          </Row>
          <Row gutter={16} style={{ alignItems: "center" }}>
            <Col>
              <Image src={IcMoneyLinear} preview={false} />
            </Col>
            <Row>
              <Col span={24} className="text-deposit">
                Số dư hiện tại
              </Col>
              <Col span={24} className="text-deposit fw-700">
                {/* {infoTransfer?.agent_balance ? infoTransfer?.agent_balance : 0}{" "} */}
                {accountDetail?.agent?.balance ? accountDetail?.agent?.balance : 0}
                VND
              </Col>
            </Row>
          </Row>
          <Col>
            <Form layout="vertical">
              <Form.Item
                className="form-item-name required-deposit"
                label={t("Số tiền cần nạp:")}
                validateFirst
                rules={validateAmountDeposit}
              >
                <Input
                  suffix={"VND"}
                  name="depositAmount"
                  value={depositAmount}
                  onChange={onChangeDepositAmount}
                  style={{ width: 348 }}
                  placeholder="Nhập số tiền (tối thiểu 500,000đ)"
                />
              </Form.Item>
            </Form>
          </Col>
          <Row gutter={8}>
            <Col className="text-deposit">Hình thức nạp tiền:</Col>
            <Col className="text-deposit fw-600">
              {infoTransfer?.transfer_info?.payment_method}
            </Col>
          </Row>
          <Col className="info-deposit">
            <Col className="text-deposit fw-600">Thông tin chuyển khoản</Col>
            <Row gutter={30}>
              <Col className="text-deposit w-210">Số tài khoản</Col>{" "}
              <Col className="text-deposit fw-600">
                {infoTransfer?.transfer_info?.bank_account_number}
              </Col>
            </Row>
            <Row gutter={30}>
              <Col className="text-deposit w-210">Chủ tài khoản</Col>{" "}
              <Col className="text-deposit fw-600 capitalized">
                {infoTransfer?.transfer_info?.bank_account_name}
              </Col>
            </Row>
            <Row gutter={30}>
              <Col className="text-deposit w-210">Ngân hàng</Col>
              <Col className="text-deposit fw-600">
              {handleNameBank()?.length > 0 ? `${handleNameBank()[0]?.name} (${handleNameBank()[0]?.code})` : ""}
              </Col>
            </Row>
            <Row gutter={30}>
              <Col className="text-deposit w-210">Nội dung chuyển khoản</Col>
              <Col className="col-content-deposit">
                <Button
                  className="text-deposit"
                  style={{ minWidth: 141 }}
                  disabled
                >
                  {infoTransfer?.transfer_info?.content}
                </Button>{" "}
                <Button
                  className="text-deposit text-green-deposit"
                  onClick={handleCopyClick}
                  icon={
                    <Image
                      src={true ? IcMiCopyGreen : IcMiCopy}
                      preview={false}
                    />
                  }
                >
                  Copy
                </Button>
                <input
                  ref={inputRef}
                  defaultValue={infoTransfer?.transfer_info?.content}
                  style={{ position: "absolute", left: "-9999px" }} // Ẩn ô nhập văn bản này khỏi giao diện
                />
              </Col>
            </Row>
          </Col>
          <Row gutter={12}>
            <Image src={IcWarningInfo} preview={false} />
            <Col className="text-deposit" style={{ fontSize: 14 }}>
              Sau khi nạp xong, hệ thống sẽ gửi thông báo cho nhà xe, bạn cần
              chờ để nhà xe phê duyệt số tiền nạp trên.
            </Col>
          </Row>
          <Space size={12}>
            <Button
              className="btn-deposit-submit"
              type="primary"
              onClick={handleIsOpen}
              ghost
            >
              Nạp tiền
            </Button>
            <Button
              onClick={handleCreateRepositQR}
              loading={loadingBtnQR}
              className="btn-deposit-submit"
              type="primary"
              ghost
            >
              Nạp tiền bằng mã QR
            </Button>
            <Modal
              open={isOpen}
              closable={false}
              width={600}
              onOk={handleIsOpen}
              onCancel={handleIsCancel}
              className="wrapper-confirm-deposit-modal"
              title={
                <div style={{ display: "flex", gap: 20 }}>
                  <Image
                    preview={false}
                    width={20}
                    src={IconExclamationCircle}
                    alt="Icon Exclamation Circle"
                  />
                  <Typography className="confirm-title-deposit-modal">
                    Xác nhận nạp tiền
                  </Typography>
                </div>
              }
              footer={(_) => {
                return (
                  <>
                    <Col className="footer-content-confirm-deposit d-flex gap-12">
                      <Button className="fs-18" onClick={handleIsCancel}>
                        {t("Trở lại")}
                      </Button>
                      <Button
                        className="btn-active-green fs-18"
                        type="primary"
                        htmlType="button"
                        loading={isLoading}
                        onClick={handleCreateReposit}
                      >
                        Xác nhận nạp tiền
                      </Button>
                    </Col>
                  </>
                );
              }}
            >
              <div className="confirm-content-deposit-modal">
                <Typography
                  style={{ marginBottom: 36, marginTop: 10 }}
                  className="text-confirm-deposit-modal fs-20"
                >
                  {t(
                    "Hệ thống sẽ gửi thông báo cho nhà xe, bạn sẽ cần chờ để nhà xe xác nhận."
                  )}
                </Typography>
                <Col>
                  <Row gutter={20}>
                    <Col className="price-confirm-deposit-modal">
                      <Image src={IcPrice} />
                      <Typography className="text-confirm-deposit-modal fs-20">
                        Số tiền:
                      </Typography>
                    </Col>
                    <Col className="text-confirm-deposit-modal fs-20 fw-600">
                      {formatPriceInput(depositAmount ? depositAmount : 0)} VND
                    </Col>
                  </Row>
                </Col>
              </div>
            </Modal>

            <Modal
              title=""
              open={open}
              onOk={handleOk}
              onCancel={handleCancel}
              closable={false}
              width={600}
              className="wrapper-modal-qr"
              footer={(_, { OkBtn, CancelBtn }: any) => (
                <div
                  style={{
                    display: "flex",
                    marginTop: 24,
                  }}
                >
                  <>
                    <Col className="block-btn-qr d-flex gap-12">
                      <Button className="fs-18" onClick={handleCancel}>
                        {t("Trở lại")}
                      </Button>
                      <Button
                        className="btn-active-green fs-18"
                        type="primary"
                        htmlType="button"
                        loading={isLoading}
                        onClick={handleCreateReposit}
                      >
                        Xác nhận nạp tiền
                      </Button>
                    </Col>
                  </>
                </div>
              )}
            >
              <div className="container-pop-qr">
                <Col className="text-qr fw-500 fs-18">
                  {t("Quét mã để chuyển tiền tới")}
                </Col>
                <Col className="text-qr fw-700 fs-20 uppercase">
                  {infoTransfer?.transfer_info?.bank_account_name}
                </Col>
                <Col className="text-qr fw-500 fs-18">
                  {infoTransfer?.transfer_info?.bank_account_number}
                </Col>
                <Image
                  className="image-qr-code"
                  src={qrCode?.QR_code}
                  preview={false}
                />
                <Row style={{ marginBottom: 16 }}>
                  <Col xxl={24} xs={24} className="text-qr fw-500 fs-18">
                    Số tiền:
                    <span
                      style={{ marginLeft: 12 }}
                      className="text-confirm-deposit-modal fw-700"
                    >
                      {formatPriceInput(depositAmount ? depositAmount : 0)} VND
                    </span>
                  </Col>
                  <Col xxl={24} xs={24} className="text-qr fw-500 fs-18">
                    Nội dung chuyển khoản:
                    <span
                      style={{ marginLeft: 12 }}
                      className="text-confirm-deposit-modal fw-700"
                    >
                      {infoTransfer?.transfer_info?.content}
                    </span>
                  </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Col xxl={24} xs={24} className="text-qr-warning fs-16">
                    Vui lòng bấm xác nhận nạp tiền nếu bạn đã chuyển tiền thành
                    công
                  </Col>
                </Row>
              </div>
            </Modal>
          </Space>
        </Card>
      </Spin>
      <Card>
        <Row className="fw-500">
          <Typography className="title-account-deposit">
            {t("Lịch sử yêu cầu nạp tiền")}
          </Typography>
        </Row>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        <Form
          className="mg-16 mt-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          colon={false}
        >
          <Form.Item
            className="label-search mb-0"
            labelAlign="left"
            labelCol={{ md: 0 }}
          >
            <Row gutter={10}>
                <Col>
                  <Form.Item name="group">
                    <RangePicker
                      style={{ width: 304 }}
                      format="DD/MM/YYYY"
                      picker="date"
                      placeholder={["Từ ngày", "Đến ngày"]}
                      onChange={handleDateRangeChange}
                    />
                  </Form.Item>
                </Col>
            </Row>
          </Form.Item>
        </Form>
        <Table
          locale={{
            triggerDesc: "Sắp xếp tăng dần",
            triggerAsc: "Hủy sắp xếp",
            cancelSort: "Sắp xếp giảm dần",
          }}
          bordered={false}
          loading={isFetching}
          // rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          dataSource={result?.length > 0 ? result : []}
          summary={(pageData) => {
            return (
              result?.length > 0 &&
              page === pagination?.last_page && (
                <Summary
                  total_confirmed={total_confirmed}
                  total_waiting={total_waiting}
                  total_cancel={total_cancel}
                  colSpan={5}
                />
              )
            );
          }}
        />
      </Card>
    </>
  );
}

export default DepositAgent;
