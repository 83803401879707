/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Upload,
  Modal,
  Select,
  DatePicker,
  Row,
  Radio,
} from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import "../../../../config/i18n/i18n";
import { userProps } from "../../../../types";
import "../../../../assets/styles/listUsers.scss";
import {
  getListCompanyAPI,
  getListGeneralAgentAPI,
} from "../../../../api/user";
import {
  passwordConfirmationRules,
  validateFirstNameUser,
  validateGenderUser,
  validateLastNameUser,
  validateNameUser,
  validatePasswordUser,
  validateStrongPassword,
  validateStrongPasswordConfirm,
  validateUsername,
} from "../../../../config/validators/validateUser";
import {
  DateFormat,
  USER_GROUP,
  YearFormat,
} from "../../../../config/constant";
import { checkAuthorization } from "../../../../api";
import { getListOfficeAPI } from "../../../../api/user";
import { getListAgentApi } from "../../../../api/user";

const CreateInfoUser: React.FC<userProps> = ({
  detailUser,
  handleOnchangeInput,
  setDetailUser,
  activeOne,
  setActiveKey,
  setActiveOne,
  infoUser,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [searchCompany, setSearchCompany] = useState("");
  const [listCompany, setListCompany] = useState<any>([]);
  const [listOffice, setListOffice] = useState<any>();
  const [listAgent, setListAgent] = useState<any>();
  const [listGeneralAgent, setListGeneralAgent] = useState<any>([]);

  const imageUser: any = detailUser?.avatar
    ? [
        {
          uid: "-1",
          name: "No avatar",
          status: "done",
          url: detailUser?.avatar,
        },
      ]
    : [];
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  // upload image
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList.length === 0) {
      setFileList(newFileList);
      detailUser.avatar = "";
    } else {
      setFileList(newFileList.slice(-1));
      if (fileList.length !== 0 && fileList[0].url === undefined) {
        const dataBase64 = await getBase64(fileList[0].originFileObj as RcFile);
        detailUser.avatar = dataBase64;
      }
    }
  };

  const onFinish = (values: any) => {
    setActiveKey("2");
    setActiveOne(false);
  };

  const disabledDate = (current: any) => {
    // Lấy ngày hiện tại
    const today = new Date();

    // Đặt giờ, phút, giây, và millisecond về 0 để so sánh ngày
    today.setHours(0, 0, 0, 0);

    // Chỉ cho phép chọn ngày trong quá khứ hoặc ngày hiện tại
    return current && current.valueOf() > today;
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value: string) => {
    setSearchCompany(value);
  };

  const filterOptionGender = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (fileList.length === 0) {
      setFileList(imageUser);
    }
  }, [detailUser.avatar]);

  const gender: any = [
    {
      value: 0,
      label: "Nam",
    },
    {
      value: 1,
      label: "Nữ",
    },
    {
      value: 2,
      label: "Khác",
    },
  ];

  const handleSelectGroup = () => {
    if (detailUser.group === USER_GROUP.CAR_COMPANY) {
      return (
        listOffice?.length >= 0 &&
        listOffice?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } else if (detailUser.group === USER_GROUP.AGENT) {
      return (
        listAgent?.length >= 0 &&
        listAgent?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }
  };

  const callListCompanyAPI = async () => {
    if (infoUser?.group === USER_GROUP.CARLINK) {
      try {
        const query = { page: 1, limit: 20, status: 1, keyword: searchCompany };
        const res = await getListCompanyAPI(query);
        if (res.data.success) {
          setListCompany(res.data.data);
        }
      } catch (error) {
        checkAuthorization(error);
      }
    }
  };

  const callListOfficeAPI = async () => {
    if (infoUser?.group === USER_GROUP.CAR_COMPANY) {
      try {
        const query = {};
        const res = await getListOfficeAPI(query);
        setListOffice(res.data.data);
        if (res.data.success) {
        }
      } catch (error) {
        checkAuthorization(error);
      }
    }
  };
  const callListAgentAPI = async () => {
    if (infoUser?.group === USER_GROUP.CAR_COMPANY) {
      try {
        const query = {};
        const res = await getListAgentApi(query);
        setListAgent(res.data.data);
        if (res.data.success) {
        }
      } catch (error) {
        checkAuthorization(error);
      }
    }
  };

  const handleLabelImageUser = () => {
    if (infoUser?.group !== detailUser?.group) {
      return "Ảnh đại diện";
    } else {
      return "Ảnh đại diện";
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      callListCompanyAPI();
    }, 400);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchCompany]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      callListOfficeAPI();
    }, 400);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchCompany]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      callListAgentAPI();
    }, 400);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchCompany]);

  useEffect(() => {
    if (activeOne) {
      form.submit();
      setActiveOne(false);
    } else {
    }
  }, [activeOne]);

  const handleListGeneralAgent = async () => {
    try {
      const testStatus = true;
      let queryGeneralAgent = `status=${testStatus}`;
      const res = await getListGeneralAgentAPI(queryGeneralAgent);
      setListGeneralAgent(res?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    handleListGeneralAgent();
  }, []);

  return (
    <Form
      name="user-basic"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      // validateTrigger="onSubmit"
      className="form-create-user form-edit-user"
    >
      <div className="container-form-user h-container-user">
        <Row>
          <Col xs={24} md={24} xl={11} xxl={11}>
            <Form.Item
              name="username"
              className="form-item-name required"
              label={t("Tên truy cập")}
              rules={validateUsername}
            >
              <Input
                autoComplete="off"
                name="username"
                value={detailUser?.username}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="custom-row">
          <Col xs={24} md={24} xl={11} xxl={11} style={{ marginRight: 24 }}>
            <Form.Item
              name="password"
              className="required"
              label={t("Mật khẩu")}
              rules={
                infoUser?.is_strong_password
                  ? validateStrongPassword
                  : validatePasswordUser
              }
              validateFirst
            >
              <Input.Password
                className="h-36"
                type="password"
                name="password"
                autoComplete="off"
                value={detailUser?.password}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={11} xxl={12} className="label-form-user">
            <Form.Item
              name="password_confirmation"
              label={t("Nhập lại mật khẩu")}
              dependencies={["password"]}
              className="required"
              validateFirst
              labelCol={{ span: 12 }}
              rules={
                infoUser?.is_strong_password
                  ? validateStrongPasswordConfirm
                  : passwordConfirmationRules
              }
            >
              <Input.Password
                name="password_confirmation"
                value={detailUser?.password_confirmation}
                onChange={handleOnchangeInput}
                className="h-36"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="custom-row">
          <Col xs={24} md={24} xl={11} xxl={11} style={{ marginRight: 24 }}>
            <Form.Item
              name="first_name"
              label={t("Họ và tên đệm")}
              className="required"
              rules={validateFirstNameUser}
              validateFirst
            >
              <Input
                name="first_name"
                value={detailUser?.first_name}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={11} xxl={12} className="label-form-user">
            <Form.Item
              name="last_name"
              label={t("Tên")}
              labelCol={{ span: 12 }}
              rules={validateLastNameUser}
              className="required"
              validateFirst
            >
              <Input
                name="last_name"
                value={detailUser?.last_name}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24} xl={11} xxl={11}>
            <Form.Item
              className="required"
              name="name"
              label={t("Tên hiển thị")}
              rules={validateNameUser}
              validateFirst
            >
              <Input
                name="name"
                value={detailUser?.name}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24} xl={11} xxl={11}>
            <Form.Item
              name="sex"
              className="form-item-select text-left required-none"
              label={t("Giới tính")}
              rules={validateGenderUser}
            >
              <Select
                showSearch
                value={+detailUser.sex}
                filterOption={filterOptionGender}
                onChange={(value) =>
                  setDetailUser({ ...detailUser, sex: value })
                }
                options={gender}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24} xl={11} xxl={11}>
            <Form.Item
              name="birth"
              label={t("Ngày sinh")}
              className="data-picker required-none"
            >
              <DatePicker
                className="w-100 h-36"
                disabledDate={disabledDate}
                format={DateFormat}
                placeholder={DateFormat}
                onChange={(value: any) =>
                  setDetailUser({
                    ...detailUser,
                    birth: moment(new Date(value))
                      .format(YearFormat)
                      .toString(),
                  })
                }
                value={detailUser?.birth}
              />
            </Form.Item>
          </Col>
        </Row>
        {infoUser?.group === USER_GROUP.CARLINK && (
          <Row>
            <Col xs={24} md={24} xl={14} xxl={14}>
              <Form.Item
                label={t("Loại tài khoản")}
                name="group"
                className="required-none"
              >
                <Radio.Group
                  className="custom-radio-group"
                  onChange={(e) =>
                    setDetailUser({ ...detailUser, group: e.target.value })
                  }
                  defaultValue={detailUser?.group}
                >
                  <Radio value={USER_GROUP.CARLINK}>Nội bộ Carlink </Radio>
                  <Radio value={USER_GROUP.CAR_COMPANY}>Nhà xe </Radio>
                  <Radio value={USER_GROUP.MAIN_AGENT}>Tổng đại lý </Radio>
                </Radio.Group>
                <Form.Item
                  labelCol={{ span: 24 }} //whole column
                  name="car_company"
                  className="car_company"
                  rules={[
                    {
                      required:
                        detailUser?.group === USER_GROUP.CARLINK ? false : true,
                      message: "Vui lòng chọn nhà xe",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      detailUser?.group === USER_GROUP.CARLINK ? true : false
                    }
                    style={{ marginTop: 8, width: 242 }}
                    showSearch
                    onChange={(value) => {
                      setDetailUser(
                        detailUser?.group === USER_GROUP.MAIN_AGENT
                          ? {
                              ...detailUser,
                              main_agent_id: value,
                            }
                          : {
                              ...detailUser,
                              company_id: value,
                            }
                      );
                    }}
                    value={
                      detailUser?.group === USER_GROUP.MAIN_AGENT
                        ? detailUser.main_agent_id
                        : detailUser.company_id
                    }
                    placeholder={
                      detailUser?.group === USER_GROUP.MAIN_AGENT
                        ? t("Tìm để chọn tổng đại lý")
                        : t("Tìm để chọn nhà xe")
                    }
                    onSearch={onSearch}
                    filterOption={filterOption}
                    options={
                      detailUser?.group === USER_GROUP.MAIN_AGENT
                        ? listGeneralAgent.length >= 0 &&
                          listGeneralAgent?.map((item: any) => ({
                            value: item?.id,
                            label: item?.name,
                          }))
                        : listCompany.length >= 0 &&
                          listCompany?.map((item: any) => ({
                            value: item?.id,
                            label: item?.name,
                          }))
                    }
                  />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        )}
        {infoUser?.group === USER_GROUP.CAR_COMPANY && (
          <Row>
            <Col xs={24} md={24} xl={11} xxl={11}>
              <Form.Item
                label={t("Loại tài khoản")}
                name="group"
                className="required-none"
              >
                <Radio.Group
                  className="custom-radio-group"
                  onChange={(e) =>
                    setDetailUser({ ...detailUser, group: e.target.value })
                  }
                  defaultValue={detailUser?.group}
                >
                  <Radio value={"car_company"}>Nhân viên nhà xe</Radio>
                  <Radio value={"agent"}>Đại lý</Radio>
                </Radio.Group>
                <Form.Item
                  labelCol={{ span: 24 }} //whole column
                  name={
                    detailUser?.group === USER_GROUP.CAR_COMPANY
                      ? "car_company"
                      : "agent"
                  }
                  className="car_company"
                  rules={[
                    {
                      required:
                        detailUser?.group === USER_GROUP.CARLINK ? false : true,
                      message:
                        detailUser?.group === USER_GROUP.CAR_COMPANY
                          ? t("Vui lòng chọn văn phòng")
                          : t("Vui lòng chọn đại lý"),
                    },
                  ]}
                >
                  <Select
                    disabled={
                      detailUser?.group === USER_GROUP.CARLINK ? true : false
                    }
                    style={{ marginTop: 8 }}
                    showSearch
                    onChange={(value) => {
                      // const filterAgent: any = listAgent.filter(
                      //   (obj: any) => obj.id === value
                      // );
                      setDetailUser(
                        detailUser?.group === USER_GROUP.CAR_COMPANY
                          ? { ...detailUser, office_id: value }
                          : {
                              ...detailUser,
                              agent_id: value,
                              // mobile: filterAgent[0]?.phone,
                              // email: filterAgent[0]?.email,
                            }
                      );
                    }}
                    value={detailUser.office_id}
                    placeholder={
                      detailUser.group === USER_GROUP.CAR_COMPANY
                        ? t("Tìm để chọn văn phòng")
                        : t("Tìm để chọn đại lý")
                    }
                    onSearch={onSearch}
                    filterOption={filterOption}
                    options={handleSelectGroup()}
                  />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24} md={24} xl={11} xxl={11}>
            <Form.Item
              className="upload-image required-none"
              label={handleLabelImageUser()}
            >
              <Upload
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : (
                  <>
                    <UploadOutlined /> {t("Tải ảnh lên")}
                  </>
                )}
              </Upload>
              <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
          </Col>
        </Row>
        <Row className="note-edit-user">
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="note"
              label={t("Ghi chú")}
              style={{ marginBottom: 0 }}
            >
              <TextArea
                style={{ height: 88 }}
                name="note"
                value={detailUser?.note}
                onChange={handleOnchangeInput}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default CreateInfoUser;
