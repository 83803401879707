import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { createBookingApi, getFilterCustomerApi, getPriceBookingApi, postCheckPinCodeApi } from '../../../api/booking';
import { Constant, Toast } from '../../../config/constant';
import { Action, CANCEL_TICKET_ERROR, CANCEL_TICKET_SUCCESS } from '../../../config/message';
import { BookingState, ParamsCreateBooking, ParamsPriceBooking } from '../../../types/booking';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

export const createBooking = createAsyncThunk(
  'admin/bookings/create',
  async (params: ParamsCreateBooking, { dispatch, rejectWithValue }) => {
    try {
      const response = await createBookingApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
        dispatch(
          setToast({
            message: CANCEL_TICKET_SUCCESS(
              params?.payment_method === 'reserve' || params?.payment_method === 'paid_at_company'
                ? Action.RESERVE
                : Action.CASH,
            ),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: response?.data?.message ?? CANCEL_TICKET_ERROR(Action.BOOKING),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const filterBookingCustomer = createAsyncThunk(
  'admin/listCustomers',
  async (params: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getFilterCustomerApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListCustomer(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getPriceBooking = createAsyncThunk(
  'admin/getPriceBooking',
  async (params: ParamsPriceBooking, { dispatch, rejectWithValue }) => {
    try {
      const response = await getPriceBookingApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setPrice(data));

        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const checkBookingPinCode = createAsyncThunk(
  'admin/checkPinCode',
  async (params: { pin_code: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await postCheckPinCodeApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setCheckPinCode(data));
        dispatch(
          setToast({
            message: response?.data?.message,
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
        return true;
      }
    } catch (error: any) {
      const { response } = error;
      dispatch(setCheckPinCode(error));
      dispatch(
        setToast({
          message: response?.data?.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const createBookingSlice = createSlice({
  name: 'createBooking',
  initialState: {
    error: false,
    loading: false,
    loadingPin: false,
    success: false,
    status: 0,
    message: '',
    messagePinCode: '',
    errorDetail: null,
    price: null,
    listCustomers: [],
    booking_for_agent_id: undefined,
    statusPin: '',
    pin_code: '',
  } as BookingState,
  reducers: {
    setCreate: (state: BookingState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setCreateError: (state: BookingState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetBooking: (state: BookingState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
    setListCustomer: (state: BookingState, { payload }) => {
      state.listCustomers = payload?.data;
    },
    resetListCustomers: (state: BookingState) => {
      state.listCustomers = [];
    },
    setPrice: (state: BookingState, { payload }) => {
      state.price = payload;
    },
    resetPriceBooking: (state: BookingState) => {
      state.price = null;
    },
    setBookingForAgentId: (state: BookingState, { payload }) => {
      state.booking_for_agent_id = payload;
    },
    setCheckPinCode: (state: BookingState, { payload }) => {
      state.statusPin = payload?.success;
    },
    setPinCode: (state: BookingState, { payload }) => {
      state.pin_code = payload;
    },
    resetPinCode: (state: BookingState) => {
      state.pin_code = '';
      state.message = '';
      state.messagePinCode = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBooking.pending, (state: BookingState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createBooking.fulfilled, (state: BookingState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(createBooking.rejected, (state: BookingState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
    builder.addCase(checkBookingPinCode.pending, (state: BookingState) => {
      state.loadingPin = true;
    });
    builder.addCase(checkBookingPinCode.fulfilled, (state: BookingState) => {
      state.loadingPin = false;
      state.statusPin = false;
    });
    builder.addCase(checkBookingPinCode.rejected, (state: BookingState, { payload }) => {
      state.loadingPin = false;
      state.messagePinCode = payload;
    });
  },
});

export const createBookingSelector = (state: RootState) => state.createBooking;
export const {
  setCreate,
  setCreateError,
  resetBooking,
  setListCustomer,
  setPrice,
  resetPriceBooking,
  resetListCustomers,
  setBookingForAgentId,
  setCheckPinCode,
  setPinCode,
  resetPinCode,
} = createBookingSlice.actions;
