import { Avatar, Button, Col, Modal, Row, Typography } from "antd";
import { formatTime } from "../../lib/utils";
import ImgLogo from "../../assets/images/notify/logo.png";


function DetailNotify ({detailData, isModalOpen, setIsModalOpen}: any) {
    const handleCancel = () => {
        setIsModalOpen(false);
      };
    return <>
       <Modal
          className="container-modal-notify"
          title={
            <Row gutter={17} className="title-detail-modal">
              <Col>
                <Avatar src={ImgLogo} />
              </Col>
              <Col>
                <Typography className="title-detail-notify">
                  Hệ thống Carlink
                </Typography>
                <span className="time-detail-notify">
                  {formatTime(detailData?.created_at)}
                </span>
              </Col>
            </Row>
          }
          open={isModalOpen}
          width={840}
          onCancel={handleCancel}
          footer={() => (
            <div className="footer-detail-notify">
              <Button onClick={handleCancel}>Đóng</Button>
            </div>
          )}
        >
          <Typography className="title-content-notify">
            {detailData?.title}
          </Typography>
          <div className="text-content-notify">{detailData?.content}</div>
        </Modal>
    </>

}

export default DetailNotify;