import { getAxiosClientWithToken } from "./index";

export const getSystemInfo = () => {
    return getAxiosClientWithToken().get("/system/info");
}

export const updateSystemInfoApi = (params: any) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put(`/system/info`, params);
};

export const getDetailInfoSystemApi = () => {
    return getAxiosClientWithToken().get("/system/info");
};

export const callUpdateDepositSystemApi = (params: any) => {
    return getAxiosClientWithToken().post(`/system/update-deposit`, params);
};

export const getPromotionHistory = (params: any) => {
    return getAxiosClientWithToken().get(`/system/promotional-deposit?${params}`);
};

export const createPromotionHistoryApi = (params: any) => {
    return getAxiosClientWithToken().post(`/system/promotional-deposit`, params);
};

export const getSystemConfigApi = () => {
    return getAxiosClientWithToken().get(`/system/setting`)

}
export const putSystemConfigApi = (body: any) => {
    return getAxiosClientWithToken().put(`/system/setting`, body)

}

export const postSystemDepartFee = (body: any) => {
    return getAxiosClientWithToken().post(`/system/depart_fee`, body);
};
