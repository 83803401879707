import React from "react";
import { USER_GROUP } from "../../config/constant";
import { useAppSelector } from "../../redux/hooks";
import ReportBalanceChangeAgent from "./ReportBalanceChangeAgent";
import ReportBalanceChangeMainAgent from "./ReportBalanceChangeMainAgent";

const ReportBalanceChange = () => {
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  if (accountDetail?.group === USER_GROUP.AGENT) {
    return <ReportBalanceChangeAgent />;
  }
  if (accountDetail?.group === USER_GROUP.MAIN_AGENT) {
    return <ReportBalanceChangeMainAgent />;
  }
  return <></>;
};

export default ReportBalanceChange;
