import { data } from './../../pages/client/index';
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {IDUser, ListUserState, UpdateUser} from "../../types";
import {Constant} from "../../config/constant";
import {getListUserAPI, updateUserAPI} from "../../api/user";
import {getErrorMessage} from "../../api";
import { log } from "console";


// @ts-ignore

// List user
export const fetchListUser = createAsyncThunk('admin/user', async (params: ListUserRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getListUserAPI(params);
        const {
            data,
        } = response;
        if (data.success) {
            dispatch(setListUser({users: data?.data, pagination: data.pagination}));
            return true;
        }
    } catch (error: any) {
        dispatch(setListUser(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});


export const fetchUpdateUser = createAsyncThunk('admin/update/user', async (params: UpdateUser, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateUserAPI(params.id,params);
        const {
            data,
            success,
        } = response?.data;

        if (success) {
            dispatch(setUser(data));
            return true;
        }
    } catch (error: any) {
        dispatch(setUser(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const listUserSlice = createSlice({
    name: 'listUser',
    initialState: {
        error: false,
        loading: false,
        success: false,
        successUpdate: false,
        status: 0,
        users: [],
        user: {
            id: null,
            name: null,
            username: null,
            email: null,
            first_name: null,
            last_name: null,
            sex: null,
            birth: null,
            address: null,
            mobile: null,
            phone: null,
            status: null,
            group: null,
            note: null,
            fax: null,
            avatar: null,
            whatapp: null,
            skype: null,
            company: null,
            agent: null,
        },
        pagination: {
            total: 0,
            per_page: Constant.PAGE_SIZE,
            current_page: Constant.DEFAULT_PAGE,
            last_page: Constant.DEFAULT_PAGE,
        },
    } as ListUserState,
    reducers: {
        setListUser: (state: ListUserState, {payload}) => {
            const {users, pagination} = payload;
            state.users = users;
            state.pagination = pagination;
            state.status = payload?.response?.status;
        },

        setUser: (state: ListUserState, {payload}) => {
            state.user = payload;
        },
    },
    extraReducers: (builder) => {
        // List User
        builder.addCase(fetchListUser.pending, (state: ListUserState) => {
            state.loading = true;
        });
        builder.addCase(fetchListUser.fulfilled, (state: ListUserState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(fetchListUser.rejected, (state: ListUserState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });


        // Update User
        builder.addCase(fetchUpdateUser.pending, (state: ListUserState) => {
            state.loading = true;
        });
        builder.addCase(fetchUpdateUser.fulfilled, (state: ListUserState) => {
            state.loading = false;
            state.successUpdate = true;
            state.error = false;
        });
        builder.addCase(fetchUpdateUser.rejected, (state: ListUserState) => {
            state.loading = false;
            state.successUpdate = false;
            state.error = true;
        });
    },
});

export const listUserSelector = (state: RootState) => state.listUsers;
export const {setListUser, setUser} = listUserSlice.actions;
