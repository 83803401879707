import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPromotionHistory } from "../../../api/system";

// First, create the thunk
export const fetchHistoryPromotion = createAsyncThunk(
  "system/fetchHistoryPromotion",
  async ({ query }: { query: string }) => {
    try {
      const response = await getPromotionHistory(query);
      return response.data;
    } catch (error) {
      
    }
  }
);

const initialState: any = {
  isFetching: true,
  pagination: {
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
  },
  result: [],
  total: "0"
};

export const historyPromotionSlide = createSlice({
  name: "system",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchHistoryPromotion.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(fetchHistoryPromotion.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(fetchHistoryPromotion.fulfilled, (state, action) => {
      state.isFetching = false;
      state.pagination = action.payload?.pagination;
      state.result = action.payload?.data;
      state.total = action.payload?.total;
   
    });
  },
});

export const {} = historyPromotionSlide.actions;

export default historyPromotionSlide.reducer;
