import React, { useEffect, useLayoutEffect, useState } from "react";
import "../../assets/styles/login.scss";
import {
  Alert,
  Button,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Spin,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../config/i18n/i18n";

// import img svg
import logoIntroduce from "../../assets/images/Logo 5.svg";
import IconWeb from "../../assets/images/Web.svg";
import IconPhone from "../../assets/images/Phone.svg";
import IconHuman from "../../assets/images/auth/human.svg";
import IconEmail from "../../assets/images/Email.svg";
import IconArrowRight from "../../assets/images/arrowright.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  asyncLogin,
  loginSelector,
  LoginValidate,
  resetLoginState,
} from "../../redux/slice/loginSlice";
import { useFormValidate } from "../../lib/utils";
import { Rule } from "antd/lib/form";
import { isString } from "lodash";
import { selectAuth } from "../../redux/slice/authSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { destroyLogged } from "../../utils/jwt";

function Login() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { error, loading, messages } = useAppSelector(loginSelector);
  const { isLogin } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const [capVal, setCapVal] = useState<boolean>(false);

  const SITE_KEY_CAPTCHA = process.env.REACT_APP_SITE_KEY_CAPTCHA;

  const [messageErrors, setMessageErrors] = useFormValidate<
    string | LoginValidate
  >(form);

  const emailRules: Rule[] = [
    {
      whitespace: true,
      message: t("Vui lòng nhập tài khoản"),
    },
    {
      required: true,
      message: t("Vui lòng nhập tài khoản"),
    },
  ];

  const passwordRules = [
    {
      required: true,
      message: t("Vui lòng nhập mật khẩu"),
    },
  ];
  const onFinish = (values: { account: string; password: string }) => {
    dispatch(asyncLogin(values));
  };

  useEffect(() => {
    if (error) {
      setMessageErrors(messages);
      dispatch(resetLoginState());
    }
  }, [dispatch, error, messages, setMessageErrors]);

  useEffect(() => {
    if (isLogin) {
      dispatch(resetLoginState());
      navigate("/", { replace: true });
      return;
    } else {
      destroyLogged(); 
    }
  }, [navigate, dispatch, isLogin]);

  const handleClickButtonLogin = () => {
    if (messageErrors) setMessageErrors("");
  };
  const renderLoginForm = () => {
    return (
      <Layout className="form-login--wapper">
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
        >
          {messageErrors && isString(messageErrors) && (
            <Alert
              message={messageErrors}
              className="mb-3"
              type="error"
              showIcon
            />
          )}
          <Form.Item
            label={t("ID Đăng nhập")}
            rules={emailRules}
            name="account"
            validateFirst
          >
            <Input placeholder={t("Tên truy cập hoặc email")} />
          </Form.Item>

          <Form.Item
            label={t("Mật khẩu")}
            rules={passwordRules}
            name="password"
            validateFirst
          >
            <Input.Password placeholder={t("Nhập mật khẩu")} />
          </Form.Item>

          <Row style={{ marginTop: 28, marginBottom: 15 }}>
            <Col xs={24}>
              <ReCAPTCHA
                sitekey={`${SITE_KEY_CAPTCHA}`}
                onChange={(val: any) => setCapVal(val)}
              />
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            {/* <Col className="mt-3"></Col> */}
            <Button
              className="btn-green mt-2"
              type="primary"
              htmlType="submit"
              disabled={!capVal}
              onClick={handleClickButtonLogin}
            >
              {t("Đăng Nhập")}
            </Button>
            <Link
              className="sign-up-trial"
              target="_blank"
              rel="noopener noreferrer"
              to="https://carlink.com.vn/"
            >
              <Typography.Text className="blue">
                {t("Đăng ký dùng thử?")}
              </Typography.Text>
            </Link>
          </Form.Item>
        </Form>
      </Layout>
    );
  };

  return (
    <Layout className="page-login--wapper">
      <Spin spinning={loading}>
        <Row>
          <Col className="login-col-left" span={9}>
            <Row className="login-introduce d-flex">
              <Col>
                <Flex
                  className="login-introduce-logo"
                  justify="center"
                  align="center"
                >
                  <Image
                    preview={false}
                    height={105}
                    src={logoIntroduce}
                    alt="logo"
                  />
                </Flex>
                <Row className="register-btn">
                  <Typography.Text className="login-introduce-text white">
                    {t(
                      "Hệ thống website dành riêng cho nhà xe, đại lý hàng đầu Việt Nam. Công cụ chuyển đổi số hoá mang tới trải nghiệm ưu việt, với nhiều tính năng hấp dẫn, đơn giản, dễ sử dụng. Liên kết hệ thống nhà xe trên toàn quốc. Đảm bảo tính bảo mật thông tin khách hàng. Đa dạng phương thức thanh toán. Hãy trải nghiệm ngay!"
                    )}
                  </Typography.Text>
                  <Flex justify="center" align="center">
                    <Link
                      className="btn-white"
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://carlink.com.vn/"
                    >
                      <Typography.Text className="green">
                        {t("Đăng ký dùng thử")}
                        <Image
                          preview={false}
                          width={19}
                          style={{ marginBottom: "5px" }}
                          src={IconArrowRight}
                          alt="Icon Arrow Right"
                        />
                      </Typography.Text>
                    </Link>
                  </Flex>
                </Row>
              </Col>
            </Row>
            <Row className="h-100 d-flex align-items-end mb-5 mt-5">
              <Col className="login-introduce--contact">
                <Row>
                  <Image
                    preview={false}
                    width={16}
                    src={IconWeb}
                    alt="Icon Web"
                  />
                  <Typography.Text className="white">
                    {t("Website:")}
                    <Link
                      className="info-footer"
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://carlink.com.vn/"
                    >
                      carlink.com.vn
                    </Link>
                  </Typography.Text>
                </Row>
                <Row>
                  <Image
                    preview={false}
                    width={16}
                    src={IconPhone}
                    alt="Icon Phone"
                  />
                  <Typography.Text className="white">
                    {t("Hotline:")} <strong>0962 505 028</strong>
                  </Typography.Text>
                </Row>
                <Row>
                  <Image
                    preview={false}
                    width={16}
                    src={IconEmail}
                    alt="Icon Email"
                  />
                  <Typography.Text className="white">
                    {t("Email:")}
                    <Link
                      className="info-footer"
                      target="_blank"
                      rel="noopener noreferrer"
                      to="mailto:info@carlink.com.vn"
                    >
                      info@carlink.com.vn
                    </Link>
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="login-col--right w-100" span={15}>
            <Col className="backgound-image w-100"></Col>
            <Row className="form-login d-flex justify-content-start align-items-center">
              <Col span={14}>
                {/*<Row className='form-login-logo w-100 justify-content-center'>*/}
                {/*    <Image preview={false} width={266} src={logoLogin} alt="logo"/>*/}
                {/*</Row>*/}
                <Row justify="center">
                  <Typography.Text className="blod text-welcome">
                    {t("Chào mừng bạn đến với")}{" "}
                    <strong className="green">Carlink!</strong>
                  </Typography.Text>
                </Row>
              </Col>
              <Row className="w-100">
                <Col span={14} className="pr-5">
                  {/* <Tabs
                    moreIcon={<p className="d-none"></p>}
                    defaultActiveKey="1"
                    centered
                    items={new Array(2).fill(null).map((_, i) => {
                      const id = String(i + 1);
                      return {
                        label:
                          i === 0
                            ? t("Đăng nhập bằng ID")
                            : t("Đăng nhập bằng mã QR"),
                        key: id,
                        children: i === 0 ? renderLoginForm() : LoginQRForm(),
                      };
                    })}
                  /> */}
                  <Typography.Title className="text-center text-color-green-default fs-24 fw-600">
                    {t("Đăng nhập bằng ID")}
                  </Typography.Title>
                  {renderLoginForm()}
                </Col>
                <Col span={8} className="d-flex justify-content-center">
                  <Image
                    preview={false}
                    className="icon-human"
                    src={IconHuman}
                    alt="Icon human"
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Layout>
  );
}

export default Login;
