/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  DatePicker,
  Popconfirm,
  Image,
} from "antd";
import { deleteNotifyAPI, updateNotifyAPI } from "../../api/notify";
import { Constant, USER_GROUP } from "../../config/constant";
import { ACTION_NAME, messageUpdateSuccess } from "../../config/message";
import { checkAuthorization } from "../../api";
import { useTranslation } from "react-i18next";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import IcLock from "../../assets/images/ic-lock.svg";
import dayjs from "dayjs";
import { validateDateRange } from "../../lib/utils";
import DetailNotify from "./DetailNotify";
import { useAppDispatch } from "../../redux/hooks";
import { fetchNotification } from "../../redux/slice/notify/notificationSlice";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const EditNotify = ({
  setIsModalOpen,
  isModalOpen,
  handleListNotify,
  detailData,
  accountUser,
}: any) => {
  const [form] = Form.useForm();
  const [dateRange, setDateRange] = useState<any>("");
  const dateFormat = "YYYY/MM/DD";
  const dispatch = useAppDispatch();


  const { t } = useTranslation();

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let query = `page=1&limit=10`;

  const onFinish = async (values: any) => {
    if (dateRange) {
      const [startDate, endDate] = dateRange;
      values.since_date = startDate.format("YYYY-MM-DD");
      values.to_date = endDate.format("YYYY-MM-DD");
    }
    try {
      const res = await updateNotifyAPI(detailData?.id, values);
      if (res.status === Constant.DEFAULT_STATUS) {
        handleListNotify();
        messageUpdateSuccess(ACTION_NAME.NOTIFICATION);
        handleCancel();
        dispatch(fetchNotification({query}));
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const handleOkModal = async () => {
    try {
      const res = await deleteNotifyAPI(detailData?.id);
      if (res.status === Constant.DEFAULT_STATUS) {
        handleListNotify();
        messageUpdateSuccess(ACTION_NAME.NOTIFICATION);
        handleCancel();
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  useEffect(() => {
    const dayjsStartDate = detailData?.since_date
      ? dayjs(detailData.since_date)
      : null;
    const dayjsEndDate = detailData?.to_date ? dayjs(detailData.to_date) : null;
    if (detailData) {
      // Use Moment.js objects for initial values
      form.setFieldsValue({
        title: detailData.title,
        content: detailData.content,
        group: [dayjsStartDate, dayjsEndDate],
      });
      setDateRange([dayjsStartDate, dayjsEndDate]);
    }
  }, [detailData?.id]);

  return (
    <>
      {accountUser?.is_superadmin ? (
        <Modal
          title="Chỉnh sửa thông tin"
          open={isModalOpen}
          onOk={handleOk}
          width={840}
          onCancel={handleCancel}
          className="wrapper-modal-notify"
          footer={() => (
            <div className="modal-footer-notify">
              <>
                <Popconfirm
                  placement="topLeft"
                  className="popconfirm-custom"
                  title={t("Khóa thông báo")}
                  description={
                    <div className="content-pop-des">
                      {t(
                        "Bạn đang thao tác khóa thông báo này, bạn có chắc chắn muốn khóa?"
                      )}
                    </div>
                  }
                  okText={t("Khóa ngay")}
                  cancelText={t("Trở lại")}
                  okButtonProps={{ className: "custom-ok-button-class" }} // Tùy chỉnh lớp CSS cho nút OK
                  cancelButtonProps={{
                    className: "custom-cancel-button-class",
                  }} // Tùy chỉnh lớp CSS cho nút Hủy
                  onConfirm={handleOkModal}
                  icon={
                    <Image
                      preview={false}
                      width={20}
                      src={IconExclamationCircle}
                      alt="Icon Exclamation Circle"
                    />
                  }
                >
                  <Col>
                    <Button
                      className={detailData?.is_deleted ? "" : "btn-block-red"}
                      style={{ width: 180 }}
                      danger
                      type="primary"
                      htmlType="button"
                      disabled={detailData?.is_deleted}
                      // onClick={showModal}
                    >
                      <Image
                        src={IcLock}
                        preview={false}
                        className="pb-1 pe-2"
                      />
                      {t("Khóa thông báo")}
                    </Button>
                  </Col>
                </Popconfirm>
              </>
              <div className="d-flex gap-12">
                <Button onClick={handleCancel}>Hủy</Button>
                <Button type="primary" onClick={handleOk}>
                  Lưu
                </Button>
              </div>
            </div>
          )}
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Tiêu đề"
              rules={[{ required: true, message: "Vui lòng nhập tiêu đề" }]}
            >
              <Input placeholder="Tối thiểu 10 ký tự - tối đa 70 ký tự" />
            </Form.Item>
            <Col>
              <Form.Item
                name="group"
                label="Chọn ngày"
                rules={[
                  {
                    required: false,
                    validator: validateDateRange,
                    message: "Vui lòng chọn ngày hợp lệ",
                  },
                ]}
              >
                <RangePicker
                  style={{ width: 350 }}
                  format="DD/MM/YYYY"
                  picker="date"
                  placeholder={["Từ ngày", "Đến ngày"]}
                  // Pass Moment.js objects for initial values (handle null cases)
                  defaultValue={[
                    dayjs(detailData.since_date, dateFormat),
                    dayjs(detailData.to_date, dateFormat),
                  ]}
                  onChange={handleDateRangeChange}
                  // disabledDate={(current) => current && current.isBefore(today)} // Vô hiệu hóa ngày trước ngày hiện tại
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="content"
              label="Nội dung"
              rules={[
                { required: true, message: "Vui lòng nhập nội dung" },
                { min: 50, message: "Tiêu đề tối thiểu 50 ký tự" },
                { max: 500, message: "Nội dung không được quá 500 ký tự" },
              ]}
            >
              <TextArea
                placeholder="Tối thiểu 50 ký tự - tối đa 500 ký tự"
                autoSize={{ minRows: 5, maxRows: 6 }}
              />
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        <DetailNotify
          detailData={detailData}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

export default EditNotify;
