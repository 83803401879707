import {handleFormData} from "../lib/utils";
import {CreateCompanyState, ListCompanyRequest, UpdateCompanyState} from "../types";
import {getAxiosClientWithToken} from "./index";

export const getListCompany = () => {
    return getAxiosClientWithToken().get('/company');
};
export const getManageBusCompanyApi = (params:any) => {
    return getAxiosClientWithToken().get(`/company?${params}`);
};
export const getListBusCompanyApi = (params: ListCompanyRequest) => {
    return getAxiosClientWithToken().get('/company', {params});
};
export const getCompanyDetailApi = () => {
    return getAxiosClientWithToken().get('/company/detail');
};
export const getListAgent = () => {
    return getAxiosClientWithToken().get('/agent');
};
export const getListBusCompanyDetailApi = (id: number) => {
    return getAxiosClientWithToken().get(`/company/${id}`);
};
export const updateListBusCompanyDetailApi = (params: UpdateCompanyState) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put(`/company/${params.id}`, params);
};

export const updateCompanyApi = (params: any) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put(`/company/info-company`, params);
};
export const updateListBusCompanyStatusApi = (params: { id: string, status: boolean }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put(`/company/status/${params.id}`, {status: params.status ? 0 : 1});
};
export const createBusCompanyApi = (params: CreateCompanyState) => {
    const formData = handleFormData(params);
    return getAxiosClientWithToken().post(`/company`, formData);
};