import React, {useState} from 'react';
import {Form, Input} from 'antd';
import dayjs from "dayjs";

interface PriceInputProps {
    onChange?: (value: any) => void;
    placeholder?: string;
    value?: any;
    name: any;
    form: any
    formList: any;
}

export const formatNumber = (value: number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const PriceInput: React.FC<PriceInputProps> = ({formList, form, onChange, placeholder, name}) => {
    const [inputValue, setInputValue] = useState<string>('0');
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;
        const currentValues = form.getFieldValue(formList);
        const numericValue = parseFloat(rawValue.replace(/[^0-9.]/g, '')); // Lấy ra số từ chuỗi
        if (!isNaN(numericValue)) {
            currentValues[name] = {
                id: currentValues[name].id,
                extra_price_value: formatNumber(numericValue),
                is_extra_price: currentValues[name].is_extra_price,
                pickup_time: currentValues[name]?.pickup_time,
                pickup_location_name: currentValues[name].pickup_location_name,
                diff_day:currentValues[name].diff_day
            }
            form.setFieldsValue({
                formList: currentValues,
            });
        } else {
            currentValues[name] = {
                id: currentValues[name].id,
                extra_price_value: 0,
                is_extra_price: currentValues[name].is_extra_price,
                pickup_time: dayjs(currentValues[name]?.pickup_time, 'HH:mm'),
                pickup_location_name: currentValues[name].pickup_location_name,
                diff_day:currentValues[name].diff_day
            }
            form.setFieldsValue({
                formList: currentValues,
            });
        }

        if (onChange) onChange(formatNumber(numericValue));
    };

    // Hàm định dạng số với dấu phân tách phần ngàn

    return (
        <Form.Item
            name={[name, 'extra_price_value']}
            className='form-item-name required'
            validateTrigger={['onChange', 'onBlur']}
        >
            <Input
                onChange={handleChange}
                placeholder={placeholder ?? ""}
                addonAfter='VND'
            />
        </Form.Item>
    );
};

export default PriceInput;