import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

function StatusFilter({
  status,
  onChangeStatus,
}: {
  status: number | any;
  onChangeStatus: Function;
}) {
  const { t } = useTranslation();

  return (
    <Row gutter={8} className="fw-500">
      <Col>
        <Button
          type="text"
          className={`fw-500 ${
            status === "" ? "btn-status-active-green" : "text-color"
          }`}
          onClick={() => {
            onChangeStatus("");
          }}
        >
          {t("Tất cả")}
        </Button>
      </Col>
      <Col className="border-start border-end">
        <Button
          type="text"
          className={`fw-500 ${
            status === 1
              ? "btn-status-active-green"
              : "text-color"
          }`}
          onClick={() => {
            onChangeStatus(1);
          }}
        >
          {t("Đã xử lý")}
        </Button>
      </Col>
      <Col>
        <Button
          type="text"
          className={`fw-500 ${
            status === 0
              ? "btn-status-lock-red"
              : "text-color"
          }`}
          onClick={() => {
            onChangeStatus(0);
          }}
        >
          {t("Chưa xử lý")}
        </Button>
      </Col>
    </Row>
  );
}

export default StatusFilter;
