import { PlusOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Image, Input, Modal, Row, Select, TimePicker, Tooltip, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconExport from '../../assets/images/ic-excel.svg';
import iconLock from '../../assets/images/ic-lock-opera.svg';
import IcUnLock from '../../assets/images/ic-unlock-seat.svg';
import icCancel from '../../assets/images/trips/ic-cancel.svg';
import icPlusGreen from '../../assets/images/trips/ic-plus-green.svg';
import '../../assets/styles/desktop/busBooking.scss';
import ModalAlertBalance from '../../components/Modal/ModalAlertBalance';
import ModalCancelSchedule from '../../components/Modal/ModalCancelSchedule';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant, DRIVER, DateFormat, EXTRA_DRIVER, Toast, USER_GROUP, YearFormat } from '../../config/constant';
import { calculateTimeSum, convertTimeFormat, formatDate, isOutDate, isPermission } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/slice/authSlice';
import {
  getBookingInfo,
  getListFilterBooking,
  listBookingSelector,
  setDataSearchBooking,
} from '../../redux/slice/bookings/listBooking';
import {
  lockAllSeatSelector,
  postLockAllSeat,
  postUnlockAllSeat,
  resetLockAllSeat,
} from '../../redux/slice/bookings/lockAllSeatSlice';
import { fetchListBus, listBusSelector } from '../../redux/slice/buses/bus';
import { driverSelector, fetchListDriver, fetchListExtraDriver } from '../../redux/slice/driverSlice';
import { setToast } from '../../redux/slice/toastNotifySlice';
import {
  createExtraTrip,
  createTrip,
  createTripSelector,
  resetRegisterCreateTrip,
} from '../../redux/slice/trips/createTrip';
import { departTrip, resetUpdateTrip, updateStatusTrip, updateTripSelector } from '../../redux/slice/trips/updateTrip';
import { handleGetUrlGuide } from '../../utils/helpers';
const DetailScheduleBooking: React.FC<any> = ({ okText, cancelText, modalOpen, onCancel, onOk, formSearch }) => {
  const { group, isLogin, listPermissions } = useAppSelector(selectAuth);
  const accountUser = useAppSelector((state) => state.authAccount?.user);
  const { loading: loadingUpdate, status: statusUpdate, message } = useAppSelector(updateTripSelector);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const { listBooking } = useAppSelector(listBookingSelector);
  const { status: statusLock } = useAppSelector(lockAllSeatSelector);
  const { status: statusCreate, statusExtra } = useAppSelector(createTripSelector);
  const { buses } = useAppSelector(listBusSelector);
  const { drivers, extra_drivers } = useAppSelector(driverSelector);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [formSchedule]: [FormInstance] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const [isOpenModalCancelSchedule, setIsOpenModalCancelSchedule] = useState(false);
  const [selectDriver, setSelectDriver] = useState<undefined | string>(undefined);
  const [keywordDriver, setKeywordDriver] = useState<any>();
  const [keywordExtraDriver, setKeywordExtraDriver] = useState<any>();
  const [selectBus, setSelectBus] = useState<undefined | string>(undefined);
  const [isModalVisibleDepart, setIsModalVisibleDepart] = useState(false);
  const [isModalLockSeat, setIsModalLockSeat] = useState(false);
  const [isModalUnlockSeat, setIsModalUnlockSeat] = useState(false);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [KeywordBusList, setKeywordBusList] = useState<any>();
  const [isDepart, setIsDepart] = useState<boolean>(false);
  const [isExtraDepart, setIsExtraDepart] = useState<boolean>(false);
  const [travelTime, setTravelTime] = useState<string>('');
  const provinceId = Form.useWatch('route_id', formSearch);
  const searchDateBooking = dayjs(Form.useWatch('date_booking', formSearch)).format(YearFormat);
  const searchDepartTime = Form.useWatch('depart_time', formSearch);
  const departTimeSchedule = Form.useWatch('depart_time', formSchedule);
  const driverList = Form.useWatch('drivers', formSchedule);
  const extraDriverList = Form.useWatch('extra_drivers', formSchedule);
  const noteReason = Form.useWatch('note', formSchedule);
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
    setTravelTime('');
    setIsExtraDepart(false);
  };
  const handleModalCancel = () => {
    setIsModalVisibleDepart(false);
    setIsOpenModalCancelSchedule(false);
    onCancel();
  };
  const handleModalLockCancel = () => {
    setIsModalLockSeat(false);
  };
  const handleModalUnLockCancel = () => {
    setIsModalUnlockSeat(false);
  };
  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      formSchedule.scrollToField(firstErrorField.name);
    }
  };
  const onSearchDriver = (value: string) => {
    setKeywordDriver(value);
  };
  const handleChangeBus = (value: string, option: any) => {
    setSelectBus(value);
    formSchedule.setFieldsValue({
      bus_name_selected: option.bus_name,
      bus_type_selected: option.bus_type,
      bus_id: option.value,
    });
  };
  const onSearchExtraDriver = (value: string) => {
    setKeywordExtraDriver(value);
  };
  const handleChangeDriver = (value: string, option: any, index: any) => {
    setSelectDriver(value);
    const currentDriverValues = formSchedule.getFieldValue('drivers');
    currentDriverValues[index] = {
      id: option.value,
      name: option.label,
      phone: option.phone,
      date_of_birth: dayjs(option.date_of_birth).format(DateFormat),
      license_class: option.license_class,
      date_expired_license: dayjs(option.date_expired_license).format(DateFormat),
    };
    formSchedule.setFieldsValue({
      drivers: currentDriverValues,
    });
  };
  const handleChangeExtraDriver = (option: any, index: any) => {
    const formData = formSchedule.getFieldsValue();
    const { extra_drivers } = formData;
    extra_drivers[index] = {
      id: option.value,
      name: option.label,
      phone: option.phone,
      date_of_birth: dayjs(option.date_of_birth).format(DateFormat),
    };
    formSchedule.setFieldsValue({
      extra_drivers: extra_drivers,
    });
  };

  const filterOptionDriver = (
    input: string,
    option?: {
      label: string;
      value: string;
      date_of_birth: string;
      phone: string;
      date_expired_license: string;
      license_class: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  function onSearchBus(value: any) {
    setKeywordBusList(value);
  }
  const filterOptionBus = (
    input: string,
    option?: {
      label: string;
      value: string;
      bus_name: string;
      bus_type: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const onCancelSuccess = useCallback(() => {
    onCancel();
    modalHandleCancel();
    formSchedule.resetFields();
    setIsModalVisibleDepart(false);
    dispatch(resetRegisterCreateTrip());
    dispatch(
      getBookingInfo({
        route_id: provinceId,
        depart_date: searchDateBooking,
        depart_time: convertTimeFormat(searchDepartTime),
      }),
    );
    dispatch(getListFilterBooking({ depart_date: searchDateBooking }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formSearch, provinceId, searchDateBooking, searchDepartTime]);

  const cancelModalAlert = () => {
    setOpenModalAlert(false);
  };

  const onCancelExtraSuccess = useCallback(() => {
    if (departTimeSchedule) {
      dispatch(
        getBookingInfo({
          route_id: provinceId,
          depart_date: searchDateBooking,
          depart_time: convertTimeFormat(departTimeSchedule),
        }),
      );
      if (searchDateBooking) {
        dispatch(getListFilterBooking({ depart_date: searchDateBooking }));
      }
      formSearch.setFieldsValue({
        depart_time: convertTimeFormat(departTimeSchedule),
      });
      dispatch(
        setDataSearchBooking({
          depart_time: convertTimeFormat(departTimeSchedule),
        }),
      );
    }
    onCancel();
    modalHandleCancel();
    setIsModalVisibleDepart(false);
    dispatch(resetRegisterCreateTrip());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formSearch, provinceId, searchDateBooking, departTimeSchedule]);

  const onRegisterDepart = () => {
    const driverDepart = listBooking?.trip?.drivers?.map((item: any) => {
      return item?.id;
    });
    const extraDriverDepart = listBooking?.trip?.drivers?.map((item: any) => {
      return item?.id;
    });
    if (
      listBooking?.trip?.id &&
      listBooking?.trip?.status !== 'departed' &&
      listBooking?.trip?.status !== 'canceled' &&
      listBooking?.trip?.drivers?.length
    ) {
      const params = {
        trip_id: listBooking?.trip?.id,
        schedule_id: listBooking?.schedule?.id,
        bus_id: listBooking?.trip?.bus?.id,
        drivers: driverDepart,
        extra_drivers: extraDriverDepart,
        status: 'departed',
        depart_date: listBooking?.travel_info?.depart_date,
      };
      dispatch(departTrip(params));
    } else {
      dispatch(
        setToast({
          message: 'Chưa đủ thông tin để xuất bến',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    }
  };
  const onRegisterLock = () => {
    setIsModalLockSeat(false);
    if (listBooking?.schedule?.id) {
      dispatch(
        postLockAllSeat({
          schedule_id: listBooking?.schedule?.id,
          depart_date: listBooking?.travel_info?.depart_date,
          is_diagram: listBooking?.schedule?.is_diagram ? listBooking?.schedule?.is_diagram : false,
        }),
      );
    }
  };
  const onRegisterUnLock = () => {
    setIsModalLockSeat(false);
    if (listBooking?.schedule?.id) {
      dispatch(
        postUnlockAllSeat({
          schedule_id: listBooking?.schedule?.id,
          depart_date: listBooking?.travel_info?.depart_date,
          is_diagram: listBooking?.schedule?.is_diagram ? listBooking?.schedule?.is_diagram : false,
        }),
      );
    }
  };
  const onFinish = (values: any) => {
    const driver = values?.drivers?.map((item: any) => {
      return item?.id;
    });

    if (!driver?.length) {
      return dispatch(
        setToast({
          message: 'Vui lòng chọn tài xế',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    }
    const extraDriver = values?.extra_drivers?.map((item: any) => {
      return item?.id;
    });
    const tmpCreateTripData: any = {
      trip_id: listBooking?.trip?.id,
      schedule_id: listBooking?.schedule?.id,
      bus_id: values.bus_license,
      drivers: driver,
      extra_drivers: extraDriver,
      status: 'not_departed',
      depart_date: listBooking?.travel_info?.depart_date,
    };
    if (isDepart) {
      tmpCreateTripData['status'] = 'departed';
      dispatch(departTrip(tmpCreateTripData));
      setIsDepart(false);
    } else if (isExtraDepart) {
      tmpCreateTripData['arrive_time'] = values.arrive_time;
      tmpCreateTripData['travel_time'] = travelTime;
      tmpCreateTripData['depart_time'] = values.depart_time;
      dispatch(createExtraTrip(tmpCreateTripData));
    } else {
      dispatch(createTrip(tmpCreateTripData));
    }
  };
  const handleCancelSchedule = () => {
    setIsOpenModalCancelSchedule(false);
  };
  const handleOkSchedule = () => {
    dispatch(
      updateStatusTrip({
        schedule_id: listBooking?.schedule?.id,
        status: listBooking?.trip?.status,
        cancel_reason: noteReason,
      }),
    );
  };
  useEffect(() => {
    if (listBooking && !isExtraDepart) {
      setTravelTime(listBooking?.schedule?.travel_time ?? '00:00');
      formSchedule.setFieldsValue({
        bus_license: listBooking.trip?.bus?.id,
        bus_type_selected: listBooking.trip?.bus?.bus_type?.name,
        bus_name_selected: listBooking.trip?.bus?.name,
        drivers: listBooking.trip?.drivers,
        extra_drivers: listBooking.trip?.extra_drivers,
      });
    }
  }, [listBooking, formSchedule, buses, isExtraDepart]);
  
  useEffect(() => {
    if (group !== USER_GROUP.AGENT && isLogin && isPermission(accountUser?.permissions, 'manager_operating')) {
      dispatch(
        fetchListDriver({
          keyword: keywordDriver,
          status: Constant.STATUS_ACTIVE,
          type: DRIVER,
        }),
      );
    }
  }, [dispatch, keywordDriver, group, isLogin, accountUser]);
  useEffect(() => {
    if (group !== USER_GROUP.AGENT && isLogin && isPermission(accountUser?.permissions, 'manager_operating')) {
      if (listBooking?.schedule?.bus_type?.id) {
        dispatch(
          fetchListBus({
            keyword: KeywordBusList,
            status: true,
            bus_type_id: listBooking?.schedule?.bus_type?.id,
          }),
        );
      }
    }
  }, [dispatch, KeywordBusList, group, listBooking, isLogin, accountUser]);

  useEffect(() => {
    if (group !== USER_GROUP.AGENT && isLogin && isPermission(accountUser?.permissions, 'manager_operating')) {
      dispatch(
        fetchListExtraDriver({
          keyword: keywordExtraDriver,
          status: Constant.STATUS_ACTIVE,
          type: EXTRA_DRIVER,
        }),
      );
    }
  }, [dispatch, keywordExtraDriver, group, isLogin, accountUser]);

  useEffect(() => {
    if (statusCreate === Constant.DEFAULT_STATUS) {
      setIsModalOpen(false);
      modalHandleCancel();
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreate]);

  useEffect(() => {
    if (statusExtra === Constant.DEFAULT_STATUS) {
      setIsModalOpen(false);
      modalHandleCancel();
      onCancelExtraSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusExtra]);

  const updateArriveTime = (e: any) => {
    let inputValue = dayjs(e, 'HH:mm').format('HH:mm');
    if (inputValue?.length > 5) {
      inputValue = inputValue.slice(0, 5);
    }
    inputValue = inputValue.replace(/[^\d:]/g, '');
    const timePattern = /^([0-9]{1,}|[01]?[0-9]|2[0-4]):([0-5][0-9])$/;
    if (timePattern.test(inputValue)) {
      formSchedule.setFieldsValue({
        arrive_time: calculateTimeSum(travelTime, inputValue),
      });
    }
  };
  useEffect(() => {
    if (statusUpdate === Constant.DEFAULT_STATUS) {
      handleModalCancel();
      dispatch(resetUpdateTrip());
      dispatch(
        getBookingInfo({
          route_id: provinceId,
          depart_date: searchDateBooking,
          depart_time: convertTimeFormat(searchDepartTime),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdate]);
  useEffect(() => {
    if (statusLock === Constant.DEFAULT_STATUS) {
      handleModalCancel();
      setIsModalUnlockSeat(false);
      dispatch(resetLockAllSeat());
      dispatch(
        getBookingInfo({
          route_id: provinceId,
          depart_date: searchDateBooking,
          depart_time: convertTimeFormat(searchDepartTime),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusLock]);

  useEffect(() => {
    if (message === 'Tài khoản của bạn đã không đủ số dư. Vui lòng nạp thêm tiền để sử dụng dịch vụ.') {
      setOpenModalAlert(true);
      setIsModalOpen(false);
      setIsOpenModalCancelSchedule(false);
      setIsModalVisibleDepart(false);
      dispatch(resetUpdateTrip());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={modalHandleOk}
        onCancel={modalHandleCancel}
        cancelText={cancelText}
        okText={okText}
        footer={[]}
        width={1118}
        maskClosable={false}
        centered
        className="modal-bus-booking"
      >
        <Card className="card-modal-booking">
          <Row>
            <Col>
              <Typography.Text className="fw-600 fs-18 "> {t('Chi tiết thông tin chuyến')}</Typography.Text>
            </Col>
            <Col xs={18} sm={18} md={18} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div style={{ marginTop: '-10px' }}>
                <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 13)} />
              </div>
            </Col>
          </Row>
          <Form
            layout="vertical"
            className="mt-1 form-schedule-booking detail"
            form={formSchedule}
            style={{ color: '#435869' }}
            onFinish={onFinish}
            autoComplete="off"
            validateTrigger="onSubmit"
            labelCol={{ span: 12 }}
            colon={false}
            labelAlign={'left'}
            labelWrap
            initialValues={{
              extra_drivers: [],
              drivers: [],
            }}
            onFieldsChange={(allFields) => {
              scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
            }}
          >
            <Row>
              <Row gutter={22} className="mt-2">
                <Col>
                  <Typography className="history-info schedule">
                    {'Tuyến: '}
                    <span className="history-detail">
                      {`${listBooking?.schedule?.route?.depart_point ?? ''} - ${
                        listBooking?.schedule?.route?.arrive_point ?? ''
                      }`}
                    </span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="history-info schedule">
                    {'Ngày: '}
                    <span className="history-detail">
                      {formatDate(listBooking?.travel_info?.depart_date, 'DD/MM/YYYY') ?? ''}
                    </span>
                  </Typography>
                </Col>
                {isExtraDepart && (
                  <>
                    <Col>
                      <Row gutter={4}>
                        <span className="history-info">Tổng thời gian:</span>
                        <span className="fw-600 ms-3">{travelTime} </span>
                      </Row>
                    </Col>
                    <Col span={4}>
                      <Row gutter={10}>
                        <Col>
                          <span className="history-info">Giờ đi:</span>
                        </Col>
                        <Col span={13} className="mt--5">
                          <Form.Item name={'depart_time'} className="depart-input">
                            <TimePicker
                              className="time-picker"
                              suffixIcon={null}
                              showNow={false}
                              onChange={updateArriveTime}
                              needConfirm={false}
                              format={'HH:mm'}
                              placeholder="00:00"
                              required={true}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
                {isExtraDepart && (
                  <Col span={4}>
                    <Row gutter={10}>
                      <Col>
                        <span className="history-info">Giờ đến:</span>
                      </Col>
                      <Col span={12} className="mt--5">
                        <Form.Item name={'arrive_time'} className="depart-input">
                          <Input disabled={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                )}
                {!isExtraDepart && (
                  <>
                    <Col>
                      <Typography className="history-info">
                        Giờ đi:
                        <span className="history-detail"> {convertTimeFormat(listBooking?.schedule?.depart_time)}</span>
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className="history-info">
                        Giờ đến:
                        <span className="history-detail"> {convertTimeFormat(listBooking?.schedule?.arrive_time)}</span>
                      </Typography>
                    </Col>
                  </>
                )}
              </Row>
              {!isExtraDepart && isOutDate(searchDateBooking) && (
                <Col className="btn-group-detail-schedule">
                  {listBooking?.trip?.status !== 'departed' && listBooking?.trip?.status !== 'canceled' && (
                    <Button
                      className="btn-export"
                      disabled={!listBooking?.trip?.drivers?.length || !listBooking?.trip?.bus}
                      onClick={() => {
                        setIsModalVisibleDepart(true);
                      }}
                    >
                      <img src={iconExport} alt="excel" />
                      {t('Xuất bến')}
                    </Button>
                  )}
                  <Button
                    className="btn-export"
                    style={{ columnGap: '0px' }}
                    onClick={() => {
                      setIsExtraDepart(true);
                      formSchedule.resetFields();
                    }}
                  >
                    <PlusOutlined /> {t('Tăng cường chuyến')}
                  </Button>
                </Col>
              )}
            </Row>

            <div className="form-booking-block">
              <Typography className="fw-16 fw-500 text-gray mt-2">{t('Chọn xe')}</Typography>
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item name="bus_license" className="fw-500 require" label={t('Biển số xe')}>
                    <Select
                      showSearch
                      onChange={handleChangeBus}
                      disabled={listBooking?.trip?.status !== 'not_departed' && !isExtraDepart}
                      value={selectBus}
                      placeholder={t('Tìm để chọn nhà xe')}
                      onSearch={onSearchBus}
                      filterOption={filterOptionBus}
                      options={
                        buses?.length > 0
                          ? buses?.map((item: any) => ({
                              value: item?.id,
                              label: item?.plate_number,
                              bus_type: item?.bus_type?.name,
                              bus_name: item?.name,
                            }))
                          : []
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name="bus_type_selected" label={t('Loại xe')}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name="bus_name_selected" label={t('Tên xe')}>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Typography className="fw-16 fw-500 text-gray mt-2">{t('Chọn tài xế')}</Typography>
              <Form.List name="drivers">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={12} className="mt-1" key={key}>
                        <Col xs={8} sm={8} md={6}>
                          <Form.Item {...restField} name={[name, 'name']} label={t(`Họ và tên`)} className="require">
                            <Select
                              showSearch
                              placeholder={t('Chọn tài xế')}
                              onChange={(value: any, option: any) => handleChangeDriver(value, option, name)}
                              value={selectDriver}
                              onSearch={onSearchDriver}
                              filterOption={filterOptionDriver}
                              disabled={listBooking?.trip?.status !== 'not_departed' && !isExtraDepart}
                              options={
                                drivers?.length > 0
                                  ? drivers?.map((item: any) => ({
                                      value: item?.id,
                                      label: item?.name,
                                      date_of_birth: item?.date_of_birth,
                                      phone: item?.phone,
                                      date_expired_license: item?.date_expired_license,
                                      license_class: item?.license_class,
                                    }))
                                  : []
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <Form.Item {...restField} name={[name, 'phone']} label={`Số điện thoại`}>
                            <Input placeholder="Số điện thoại" disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <Form.Item {...restField} name={[name, 'date_of_birth']} label={t(`Ngày sinh`)}>
                            <Input placeholder="Ngày sinh" disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <Form.Item {...restField} name={[name, 'license_class']} label={t(`Hạng bằng lái`)}>
                            <Input placeholder="Hạng bằng lái" disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <Form.Item {...restField} name={[name, 'date_expired_license']} label={t(`Hạn bằng lái`)}>
                            <Input placeholder="Hạn bằng lái" disabled />
                          </Form.Item>
                        </Col>
                        {(listBooking?.trip?.status === 'not_departed' || isExtraDepart) && (
                          <Tooltip title="Xóa">
                            <Button type="text" onClick={() => remove(name)} className="mt-28 h-btn-48  ps-1 pe-1">
                              <Image width={36} height={36} src={icCancel} preview={false} />
                            </Button>
                          </Tooltip>
                        )}
                      </Row>
                    ))}
                    {fields.length <= 2 ? (
                      <Form.Item>
                        <Button
                          type="text"
                          onClick={() => add()}
                          className="text-color-green-default d-flex justify-content-start align-items-center btn-add-driver"
                          disabled={
                            (drivers?.length && driverList?.length ? driverList?.length === drivers?.length : false) ||
                            (listBooking?.trip?.status !== 'not_departed' && !isExtraDepart)
                          }
                        >
                          {t(`Thêm tài xế`)}
                          <Image width={14} height={14} src={icPlusGreen} preview={false} className="mb-3 ms-2" />
                        </Button>
                      </Form.Item>
                    ) : null}
                  </>
                )}
              </Form.List>
              <Typography className="fw-16 fw-500 text-gray mt-2">{t('Chọn phụ xe')}</Typography>

              <Form.List name="extra_drivers">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={12} className="mt-1" key={key}>
                        <Col xs={8} sm={8} md={6}>
                          <Form.Item {...restField} name={[name, 'name']} label={t(`Họ và tên`)}>
                            <Select
                              showSearch
                              onChange={(value: any, option: any) => handleChangeExtraDriver(option, name)}
                              value={selectBus}
                              placeholder={t('Chọn phụ xe')}
                              onSearch={onSearchExtraDriver}
                              filterOption={filterOptionDriver}
                              disabled={listBooking?.trip?.status !== 'not_departed' && !isExtraDepart}
                              options={
                                extra_drivers?.length > 0
                                  ? extra_drivers?.map((item: any) => ({
                                      value: item?.id,
                                      label: item?.name,
                                      date_of_birth: item?.date_of_birth,
                                      phone: item?.phone,
                                      date_expired_license: item?.date_expired_license,
                                      license_class: item?.license_class,
                                    }))
                                  : []
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <Form.Item {...restField} name={[name, 'phone']} label={t(`Số điện thoại`)}>
                            <Input placeholder="Số điện thoại" disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={4}>
                          <Form.Item {...restField} name={[name, 'date_of_birth']} label={t(`Ngày sinh`)}>
                            <Input placeholder="Ngày sinh" disabled />
                          </Form.Item>
                        </Col>
                        {(listBooking?.trip?.status === 'not_departed' || isExtraDepart) && (
                          <Tooltip title="Xóa">
                            <Button type="text" onClick={() => remove(name)} className="mt-28 h-btn-48 ps-1 pe-1">
                              <Image width={36} height={36} src={icCancel} preview={false} />
                            </Button>
                          </Tooltip>
                        )}
                      </Row>
                    ))}
                    {fields.length <= 2 ? (
                      <Form.Item className="mt-1">
                        <Button
                          type="text"
                          onClick={() => add()}
                          className="text-color-green-default d-flex justify-content-start align-items-center btn-add-driver"
                          disabled={
                            (extra_drivers?.length && extraDriverList?.length
                              ? extraDriverList?.length === extra_drivers?.length
                              : false) ||
                            (listBooking?.trip?.status !== 'not_departed' && !isExtraDepart)
                          }
                        >
                          {t('Thêm phụ xe')}
                          <Image width={14} height={14} src={icPlusGreen} preview={false} className="mb-3 ms-2" />
                        </Button>
                      </Form.Item>
                    ) : null}
                  </>
                )}
              </Form.List>
            </div>
            {((listBooking?.trip?.status !== 'departed' && listBooking?.trip?.status !== 'canceled') ||
              isExtraDepart) && (
              <Row className="d-flex mt-3">
                <Col span={4} className="d-flex justify-content-start" style={{ columnGap: '12px' }}>
                  {listBooking?.trip?.status !== 'departed' && listBooking?.trip?.status !== 'canceled' && (
                    <Button
                      key="pdf-contract"
                      type="primary"
                      className="mw-164 h-btn-48 fw-700"
                      htmlType="button"
                      danger
                      style={{ backgroundColor: '#D81717' }}
                      onClick={() => setIsOpenModalCancelSchedule(true)}
                    >
                      <span>
                        <StopOutlined />
                        {' Hủy chuyến'}
                      </span>
                    </Button>
                  )}
                </Col>
                <Col span={8}>
                  <div className="d-flex" style={{ columnGap: '16px' }}>
                    <Button
                      key="pdf-contract"
                      type="primary"
                      className="mw-164 h-btn-48 fw-700 btn-lock-all"
                      htmlType="button"
                      onClick={() => setIsModalLockSeat(true)}
                    >
                      <span>
                        <Image src={iconLock} preview={false} />
                        {' Khóa toàn bộ ghế'}
                      </span>
                    </Button>
                    <Button
                      key="pdf-contract"
                      type="primary"
                      className="mw-164 h-btn-48 fw-700 btn-lock-all"
                      htmlType="button"
                      onClick={() => setIsModalUnlockSeat(true)}
                    >
                      <span>
                        <img src={IcUnLock} alt="" />
                        {'Mở khóa toàn bộ ghế'}
                      </span>
                    </Button>
                  </div>
                </Col>
                <Col span={12} className="d-flex justify-content-end gap-12">
                  <Button key="cancel" className=" w-160 h-btn-48" onClick={() => modalHandleCancel()}>
                    {cancelText}
                  </Button>
                  <Button
                    key="ok"
                    type="primary"
                    className="w-160 h-btn-48 btn-success"
                    htmlType="submit"
                    onClick={() => modalHandleOk()}
                  >
                    {okText}
                  </Button>
                </Col>
              </Row>
            )}
            <ModalCancelSchedule
              modalOpen={isOpenModalCancelSchedule}
              okText={t('Hủy chuyến')}
              cancelText={t('Trở lại')}
              modalTitle={t('Hủy chuyến')}
              onCancel={handleCancelSchedule}
              onOk={handleOkSchedule}
            />
          </Form>
        </Card>
      </Modal>
      <ModalConfirm
        loading={loadingUpdate}
        modalOpen={isModalVisibleDepart}
        okText={t('Xuất bến')}
        cancelText={t('Đóng')}
        modalTitle={t('Xuất bến')}
        modalContent={t('Bạn đang thao tác xuất bến chuyến đi này, bạn có chắc chắn muốn xuất bến?')}
        onCancel={handleModalCancel}
        onOk={() => onRegisterDepart()}
      />
      {/* <ModalConfirm
        modalOpen={isModalLockSeat}
        okText={!isLocked ? t('Khóa ngay') : t('Mở khóa')}
        cancelText={t('Trở lại')}
        modalTitle={!isLocked ? t('Khóa toàn bộ ghế còn trống') : t('Mở khóa toàn bộ ghế còn trống')}
        modalContent={
          !isLocked
            ? t('Bạn đang thao tác khóa toàn bộ các ghế còn trống, bạn có chắc chắn muốn khóa?')
            : t('Bạn đang thao tác mở khóa toàn bộ các ghế, bạn có chắc chắn muốn mở?')
        }
        onCancel={handleModalLockCancel}
        onOk={() => onRegisterLock()}
      /> */}
      <ModalConfirm
        modalOpen={isModalLockSeat}
        okText={t('Khóa ngay')}
        cancelText={t('Trở lại')}
        modalTitle={t('Khóa toàn bộ ghế còn trống')}
        modalContent={t('Bạn đang thao tác khóa toàn bộ các ghế còn trống, bạn có chắc chắn muốn khóa?')}
        onCancel={handleModalLockCancel}
        onOk={() => onRegisterLock()}
      />
      <ModalConfirm
        modalOpen={isModalUnlockSeat}
        okText={t('Mở khóa')}
        cancelText={t('Trở lại')}
        modalTitle={t('Mở khóa toàn bộ ghế còn trống')}
        modalContent={t('Bạn đang thao tác mở khóa toàn bộ các ghế, bạn có chắc chắn muốn mở?')}
        onCancel={handleModalUnLockCancel}
        onOk={() => onRegisterUnLock()}
      />
      <ModalAlertBalance modalOpen={openModalAlert} onCancel={cancelModalAlert} onOk={() => setOpenModalAlert(false)} />
    </>
  );
};
export default DetailScheduleBooking;
