import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getInfoUserAPI} from "../../../api/user";
import {setRole} from "../authSlice";

// First, create the thunk
export const fetchAccount = createAsyncThunk(
    "account/fetchAccount",
    async (_, {
        dispatch,
    }) => {
        const response: any = await getInfoUserAPI();
        if (response.status === 200) {
            dispatch(setRole(response.data.data.permissions))
            window.localStorage.setItem('limit_pagination', response?.data?.data?.limit_pagination)
            return response.data.data;

        }
    }
);

const initialState: any = {
    isLoading: false,
    user: {},
};

export const accountSlide = createSlice({
    name: "account",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setUserLoginInfo: (state, action) => {
            state.user = action.payload
        },

        setEmptyInfoUser: (state) => {
            state.user = {}
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchAccount.pending, (state, action) => {
            if (action.payload) {
                state.isLoading = true;
            }
        });

        builder.addCase(fetchAccount.fulfilled, (state: any, action) => {
            if (action.payload) {
                state.isLoading = false;
                state.user = action.payload
            }
        });

        builder.addCase(fetchAccount.rejected, (state, action) => {
            if (action.payload) {
                state.isLoading = false;
            }
        });
    },
});

export const {setUserLoginInfo, setEmptyInfoUser} = accountSlide.actions;

export default accountSlide.reducer;
