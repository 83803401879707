import { getListReportCancelCarlinkAPI, getListReportCancelCarlinkExcelAPI } from "../../../api/report";
import { ParamsReport, ReportCancelState, ValueOptions } from "../../../types/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    listRoute: [],
    listDepartTime: [{ value: '', label: 'Tất cả' }],
    total_price: 0,
    total_cancel_fee: 0,
    total_refund_fee_carlink: 0,
    filter: {
        booking_date: 'false',
        from: '',
        to: '',
        route_id: '',
        depart_time: '',
        reason_cancel: '',
        company_id: undefined,
        main_agent_id: undefined,
    }
} as ReportCancelState;

export const reportCancelCarlinkSlice = createSlice({
    name: 'reportCancelCarlink',
    initialState,
    reducers: {
        setListAllRoutes(state, action) {
            state.listRoute = action.payload;
        },
        setFilterBookingDate(state, action: PayloadAction<string>) {
            state.filter.booking_date = action.payload;
        },
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setFilterRoute(state, action: PayloadAction<number | string>) {
            state.filter.route_id = action.payload;
        },
        setFilterDepartTime(state, action: PayloadAction<number | string>) {
            state.filter.depart_time = action.payload;
        },
        setFilterReasonCancel(state, action: PayloadAction<string>) {
            state.filter.reason_cancel = action.payload;
        },
        setFilterCompany(state, action: PayloadAction<number | undefined>) {
            state.filter.company_id = action.payload;
        },
        setListDepartTime(state, action: PayloadAction<ValueOptions[]>) {
            state.listDepartTime = action.payload;
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        },
        setFilterMainAgentId(state, action: PayloadAction<number | undefined>) {
            state.filter.main_agent_id = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportCancelCarlink.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportCancelCarlink.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_price = action.payload?.total_price;
                state.total_cancel_fee = action.payload?.total_cancel_fee;
                state.total_refund_fee_carlink = action.payload?.total_refund_fee_carlink;
            }).addCase(getListReportCancelCarlink.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportCancelCarlinkExcel.pending, (state) => {
            }).addCase(getListReportCancelCarlinkExcel.fulfilled, (state, action) => {
            }).addCase(getListReportCancelCarlinkExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            })
    }
})

export const getListReportCancelCarlink = createAsyncThunk('ReportCancelCarlink/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportCancelCarlinkAPI(params);
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportCancelCarlinkExcel = createAsyncThunk('ReportCancelCarlink/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportCancelCarlinkExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setPerPage, setListAllRoutes, setFilterBookingDate, setFilterRangeDate, setFilterRoute, setFilterDepartTime, setFilterReasonCancel, setFilterCompany, setListDepartTime, setFilterMainAgentId } = reportCancelCarlinkSlice.actions;