import { Button, Card, Checkbox, Flex, Form, Layout, Select, SelectProps, Timeline, Typography } from 'antd';
import { CheckboxProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconRoute from '../../assets/images/segments/icon-route.svg';
import SeatMapRender from '../../components/SeatMapRender';
import StepSchedules from '../../components/StepSchedules';
import { Message } from '../../config/message';
import { convertOptionCheckBox } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAllAvailableBusType, listBusTypeSelector } from '../../redux/slice/busTypes/listBusTypeSlice';
import { fetchListRoute, getAllRoute, routeSelector } from '../../redux/slice/routeSlice';
import { getAllProvinces, ListScheduleSelector } from '../../redux/slice/schdules/scheduleSlice';
import { listColor } from '../../utils/listColorItem';
import SegmentSettingModal from './ModalSchedules/SegmentSettingModal';
import { useNavigate } from 'react-router-dom';

function SegmentSetting() {
  const { t } = useTranslation();
  const { loading: loadingRoute } = useAppSelector(routeSelector);
  const { listSegment } = useAppSelector(ListScheduleSelector);
  const { listAllRoute } = useAppSelector(routeSelector);
  const { listAllAvailableBusType } = useAppSelector(listBusTypeSelector);
  const [form] = Form.useForm();
  const [keywordBus, setKeywordBus] = useState<any>({});
  const [openModalSegment, setOpenModalSegment] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [routeActive, setRouteActive] = useState<any>();
  const [seatRender, setSeatRender] = useState<any>();
  const [allSeatName, setAllSeatName] = useState<any>();
  const [seatData, setSeatData] = useState<any>();
  const [groupSeat, setGroupSeat] = useState<any>();
  const [segmentTimelineItem, setSegmentTimelineItem] = useState<any>([]);
  const [keyword, setKeyword] = useState<any>({});
  const watchRoute = Form.useWatch('route_id', form);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const RouteRules = [
    {
      required: true,
      message: t(Message.ROUTE),
    },
  ];
  const BusTypeRules = [
    {
      required: true,
      message: t('Vui lòng chọn loại xe'),
    },
  ];
  function onSearchRoute(value: any) {
    setKeyword(value);
  }
  function onSearchBusType(value: any) {
    setKeywordBus(value);
  }
  const filterOptionRoute = (
    input: string,
    option?: {
      label: string;
      value: string;
    },
  ) => (option?.label ?? '').toLowerCase()?.includes(input.toLowerCase());
  const filterOptionBus = (
    input: string,
    option?: {
      label?: any;
      value?: any;
      seatMap?: any;
    },
  ) => (option?.label ?? '').toLowerCase()?.includes(input.toLowerCase());

  const BusOptions: SelectProps['options'] = listAllAvailableBusType?.length
    ? listAllAvailableBusType?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
          seatMap: item?.seat_map,
        };
      })
    : [];
  const findColor = (keyIndex: any) => {
    const item = groupSeat?.find((_: any, index: any) => +index === +keyIndex);
    return item ? item?.group_color : null;
  };

  const onChangeDepart: CheckboxProps['onChange'] = (e) => {};
  const onChangeArrive: CheckboxProps['onChange'] = (e) => {};
  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      form.scrollToField(firstErrorField.name);
    }
  };
  function onChangeGroup(values: any, option: any) {
    form.setFieldsValue({ agent_seats: [] });
    form.setFieldsValue({ seat_count: option?.seatMap?.seat_number });
    setSeatRender(option?.seatMap);
    setGroupSeat(option?.seatMap?.group_seat);
    let seatDataOption: any = [];
    let dataOption: any = [];
    option?.seatMap?.group_seat?.map((item: any) => {
      return (seatDataOption = [...seatDataOption, ...item.selected_seat]);
    });
    setSeatData(seatDataOption);
    convertOptionCheckBox(dataOption, [...seatDataOption]?.sort());
    setAllSeatName(dataOption);
    form.setFieldsValue({ agent_seats: seatDataOption });
  }
  const handleChangeRoute = (value: any, option: any) => {
    setRouteActive(option?.routeItem);
    setIsActive(false);
  };
  const onFinish = (values: any) => {
    navigate('/bus-management/locations');
  };
  useEffect(() => {
    dispatch(getAllAvailableBusType({ keyword: keywordBus }));
  }, [dispatch, keywordBus]);

  useEffect(() => {
    dispatch(fetchListRoute({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllRoute({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllProvinces());
  }, [dispatch]);
  useEffect(() => {
    if (isActive) {
      form.setFieldsValue({
        arrive_point: routeActive?.arrive_point,
        arrive_id: routeActive?.arrive_province?.name,
        depart_point: routeActive?.depart_point,
        depart_id: routeActive?.depart_province?.name,
      });
    }
  }, [isActive, routeActive]);
  useEffect(() => {
    if (listSegment?.depart_point && listSegment?.arrive_point) {
      setSegmentTimelineItem([
        {
          children: (
            <Flex gap={8} className="block-bg-color">
              <p className={`hour-time-line color ${listColor[0]}`} style={{ width: '50px' }}>
                {dayjs(listSegment?.depart_time)?.format('hh:mm')}
              </p>
              <div className={`btn-time-line ${listColor[0]}`}>{listSegment?.depart_point}</div>
            </Flex>
          ),
          dot: (
            <div className="block-color">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="8" cy="8" r="6" stroke-width="4" className={listColor[0]} />
              </svg>
            </div>
          ),
        },
        ...(listSegment?.segment?.length
          ? listSegment?.segment?.map((segment: any, index: number) => ({
              children: (
                <Flex gap={8} key={index} className="block-bg-color">
                  <p
                    className={`hour-time-line color ${listColor[(index + 1) % listColor?.length]}`}
                    style={{ width: '50px' }}
                  >
                    {dayjs(segment?.arrive_time)?.format('hh:mm')}
                  </p>
                  <div className={`btn-time-line ${listColor[(index + 1) % listColor?.length]}`}>{segment?.name}</div>
                </Flex>
              ),
              dot: (
                <div className="block-color">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <circle
                      cx="8"
                      cy="8"
                      r="6"
                      strokeWidth="4"
                      className={listColor[(index + 1) % listColor?.length]}
                    />
                  </svg>
                </div>
              ),
            }))
          : []),
        {
          children: (
            <Flex gap={8} className="block-bg-color">
              <p
                className={`hour-time-line color ${listColor[listSegment?.segment?.length + 1]}`}
                style={{ width: '50px' }}
              >
                {listSegment?.arrive_time}
              </p>
              <div className={`btn-time-line ${listColor[listSegment?.segment?.length + 1]}`}>
                {listSegment?.arrive_point}
              </div>
            </Flex>
          ),
          dot: (
            <div className="block-color">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="8" cy="8" r="6" stroke-width="4" className={listColor[listSegment?.segment?.length + 1]} />
              </svg>
            </div>
          ),
        },
      ]);
    }
  }, [listSegment]);
  return (
    <Layout className="min-vh-70 segment-booking ">
      <Card>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          labelCol={{ span: 12 }}
          colon={false}
          labelAlign={'left'}
          initialValues={{
            segment: [{}],
            depart_time: dayjs().startOf('day').hour(0).minute(0),
            arrive_time: '00:00',
            is_depart_transship: false,
            is_arrive_transship: false,
            travel_time: '00:00',
          }}
          labelWrap
          onFieldsChange={(allFields) => {
            scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
          }}
        >
          <StepSchedules step={1} />
          <div className="segment-block">
            <div className="mt-4 border-b-segment pb-3">
              <Flex gap={16}>
                <img src={iconRoute} alt="" />
                <div>
                  <Typography.Text className="fw-600 fs-18 title-segment">Thiết lập chặng</Typography.Text> <br />
                  <Typography.Text>Xác định điểm bắt đầu, điểm dừng và điểm kết thúc cho hành trình.</Typography.Text>
                </div>
              </Flex>
            </div>
            <div className="segment-content flex mt-4">
              <div className="route">
                <div className="fs-16 fw-600 text-white title">Tuyến</div>
                <Flex gap={12} justify="start" align="center">
                  <Form.Item
                    name="route_id"
                    className="form-item-name route-select mt-3"
                    validateTrigger={['onChange', 'onBlur']}
                    rules={RouteRules}
                  >
                    <Select
                      className="input-h36 mt-1 w-100"
                      loading={loadingRoute}
                      showSearch
                      placeholder="Chọn tuyến"
                      onSearch={onSearchRoute}
                      filterOption={filterOptionRoute}
                      onChange={(value, option) => handleChangeRoute(value, option)}
                      size="large"
                      options={
                        listAllRoute?.length
                          ? listAllRoute?.map((item) => {
                              return {
                                value: item?.id,
                                label: `${item?.depart_point} - ${item?.arrive_point}`,
                                routeItem: item,
                              };
                            })
                          : []
                      }
                    />
                  </Form.Item>
                  <Form.Item name="" className="mt-4"></Form.Item>
                  <Button
                    htmlType="button"
                    className={`btn-setting mt-2 fs-16 fw-600 ${watchRoute ? 'active' : ''}`}
                    onClick={() => {
                      if (watchRoute) {
                        setOpenModalSegment(true);
                        setIsActive(true);
                      }
                    }}
                  >
                    Thiết lập chặng
                  </Button>
                </Flex>
                <Flex gap={28} className="transship-block border-b">
                  <Form.Item name="is_depart_transship">
                    <Checkbox onChange={onChangeDepart}>
                      <Typography className="fw-500">Trung chuyển đón</Typography>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="is_arrive_transship">
                    <Checkbox onChange={onChangeArrive}>
                      <Typography className="fw-500">Trung chuyển trả</Typography>
                    </Checkbox>
                  </Form.Item>
                </Flex>
                <Timeline
                  className="mt-4 block-color"
                  items={segmentTimelineItem?.length > 0 ? segmentTimelineItem : []}
                />
              </div>
              <div className="bus-type">
                <div className="fs-16 fw-600 text-white title">Loại xe</div>
                <Form.Item
                  name="bus_type_id"
                  className="form-item-name bustype-select required  mt-4"
                  validateTrigger={['onChange', 'onBlur']}
                  rules={BusTypeRules}
                >
                  <Select
                    className="input-h36"
                    showSearch
                    placeholder="Loại xe"
                    onChange={(value: any, option: any) => onChangeGroup(value, option)}
                    onSearch={onSearchBusType}
                    filterOption={filterOptionBus}
                    options={BusOptions}
                  />
                </Form.Item>
                <div className="mt-2">
                  {seatRender?.count_floor && (
                    <>
                      <Flex className="mt-2" align="center" gap={28}>
                        {groupSeat?.length > 0 &&
                          groupSeat?.map((item: any, index: number) => (
                            <Flex align="center" gap={8}>
                              <div className={`block-color-seat ${findColor(index)}`}></div>
                              <span className="block-color-name">{item?.name}</span>
                            </Flex>
                          ))}
                      </Flex>
                      <div className="seat-render-custom">
                        <SeatMapRender
                          column={seatRender?.column ?? 0}
                          row={seatRender?.row ?? 0}
                          seatCount={seatRender?.count_floor}
                          seatMatrixData={seatRender}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <SegmentSettingModal
            modalOpen={openModalSegment}
            onCancel={() => {
              setOpenModalSegment(false);
              setIsActive(false);
            }}
            onOk={() => {}}
            routeActive={routeActive}
            isActive={isActive}
          />
        </Form>
      </Card>
    </Layout>
  );
}

export default SegmentSetting;
