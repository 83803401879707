import { SearchOutlined } from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { ColumnsType } from 'antd/es/table';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import Link from 'antd/es/typography/Link';
import { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icEdit from '../../../assets/images/ic-edit.svg';
import iconExport from '../../../assets/images/ic-excel.svg';
import iconNext from '../../../assets/images/ic-next.svg';
import iconPrev from '../../../assets/images/ic-prev.svg';
import StatusFilterBooking from '../../../components/Modal/StatusFilterBooking';
import { Constant, DateFormat, USER_GROUP, YearFormat } from '../../../config/constant';
import { convertTimeFormat, formatTime, getStatusBooking, mbTrim } from '../../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAccount } from '../../../redux/slice/auth/accountSlice';
import { selectAuth } from '../../../redux/slice/authSlice';
import { createBookingSelector, resetPriceBooking } from '../../../redux/slice/bookings/createBookingSlice';
import {
  getListBookingDetail,
  getListCustomerBooking,
  listBookingSelector,
} from '../../../redux/slice/bookings/listBooking';
import { ListBookingCustomerType } from '../../../types/booking';
import ModalBooking from '../ModalBooking';
import ModalHistoryBooking from '../ModalHistoryBooking';

function ListTicket() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    listCustomerBooking,
    loadingCustomerBooking: loadingListCustomer,
    paginationCustomer,
    listBookingDetail,
    loading: loadingDetail,
  } = useAppSelector(listBookingSelector);
  const { status: statusCreateUpdate } = useAppSelector(createBookingSelector);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const { group } = useAppSelector(selectAuth);
  const [formSearch]: [FormInstance] = Form.useForm();
  const [status, setStatus] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<ListBookingCustomerType>({});
  const [dataExportCsv, setDataExportCsv] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [dataCustomerBooking, setDataCustomerBooking] = useState([]);
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const { RangePicker } = DatePicker;
  const filterDate = Form.useWatch('date_filter', formSearch);
  const rangeDate = Form.useWatch('range_date', formSearch);
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const currentDate = dayjs();

  const getDetailBooking = (record: any) => {
    if (record?.status === 'cancel') {
      setOpenModalHistory(true);
    } else {
      setIsDetail(true);
      setOpenDetail(true);
    }
    dispatch(getListBookingDetail({ ticket_code: record?.ticket_code, is_diagram: record?.is_diagram }));
    setDataCustomerBooking(record);
  };
  const cancelModal = () => {
    setIsDetail(false);
    setOpenDetail(false);
    dispatch(resetPriceBooking());
    setDataCustomerBooking([]);
  };
  const handleOkModalBooking = () => {};
  const cancelModalHistory = () => {
    setOpenModalHistory(false);
  };
  const columns1: ColumnsType<any> = [
    {
      title: 'Mã đặt vé',
      dataIndex: '',
      key: 'ticket_code',
      width: '120px',
      render: (record) => (
        <Space size="middle" style={{ display: 'flex', columnGap: '18px' }}>
          <Flex align="center" style={{ columnGap: '8px' }}>
            <FontAwesomeIcon
              icon={faCircle}
              className={`${record?.status === 'cancel' ? 'red' : record?.status === 'reserve' ? 'yellow' : 'green'}`}
              size="xs"
            />
          </Flex>
          <div>
            <Typography>{record?.ticket_code}</Typography>
            <p className="text-green fs-14 fw-500 mb-0"> {formatTime(record?.created_at)}</p>
          </div>
        </Space>
      ),
      sorter: (a: any, b: any) => a?.ticket_code.localeCompare(b?.ticket_code),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '120px',
    },
    {
      title: 'Số điện thoại',
      dataIndex: '',
      key: 'customer_phone',
      width: '110px',
      render: (record) => (
        <Typography>
          {record?.status === 'cancel' && record?.orderer?.name === 'Carlink API' ? 'N/A' : record?.customer_phone}
        </Typography>
      ),
    },
    {
      title: 'Hành trình',
      dataIndex: '',
      key: 'trip',
      width: '200px',
      render: (record) => (
        <Typography key={record.route?.depart_point + record.route?.arrive_point}>
          {record?.is_diagram ? (
            `${record?.route?.depart_point ?? ''} - ${record?.route?.arrive_point ?? ''}`
          ) : (
            <>
              {record?.route?.depart_point ?? ''} - {record?.route?.arrive_point ?? ''} <span className="red">*</span>
            </>
          )}
        </Typography>
      ),
    },
    {
      title: 'Ngày đi',
      dataIndex: 'depart_date',
      key: 'depart_date',
      width: '100px',
      render: (text) => <Typography>{text ? dayjs(text).format(DateFormat) : ''}</Typography>,
      sorter: (a, b) => dayjs(a.depart_date).unix() - dayjs(b.depart_date).unix(),
    },
    {
      title: 'Giờ đi',
      dataIndex: 'depart_time',
      key: 'depart_time',
      width: '80px',
      sorter: (a, b) => a?.depart_time.localeCompare(b?.depart_time),
      render: (text) => <Typography>{convertTimeFormat(text)}</Typography>,
    },
    // {
    //   title: 'Ngày đặt',
    //   dataIndex: 'order_date',
    //   key: 'order_date',
    //   width: '100px',
    //   render: (text) => <Typography>{dayjs(text).format('DD/MM/YYYY')}</Typography>,
    //   sorter: (a, b) => dayjs(a.order_date).unix() - dayjs(b.order_date).unix(),
    // },
    {
      title: 'Nguồn vé',
      dataIndex: '',
      key: 'source',
      width: '120px',
      render: (record) => (
        <Typography>{record?.orderer?.agent ? record?.orderer?.agent?.name : record?.orderer?.name}</Typography>
      ),
    },
    {
      title: ' ',
      dataIndex: 'action',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle" key={record.ticket_id}>
          <Tooltip title="Chỉnh sửa">
            <Link
              onClick={() => {
                getDetailBooking(record);
              }}
            >
              <Image width={17} height={17} className="icon-edit mr-20" src={icEdit} preview={false} />
            </Link>
          </Tooltip>
        </Space>
      ),
      align: 'center',
      width: '60px',
    },
  ];

  const columnsExport: IExcelColumn[] = [
    {
      title: 'Mã đặt vé',
      __excelTitle__: 'Mã đặt vé',
      dataIndex: 'ticket_code',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
    },
    {
      title: 'Họ và tên',
      dataIndex: 'customer_name',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 20,
      },
      width: 20,
      __excelTitle__: 'Họ và tên',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customer_phone',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Số điện thoại',
    },
    {
      title: 'Hành trình',
      __excelTitle__: 'Hành trình',
      dataIndex: 'pickup_location',

      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        width: 20,
        borderColor: 'black',
      },
      width: 20,
    },
    {
      title: 'Ngày đi',
      dataIndex: 'depart_date',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Ngày đi',
    },
    {
      title: 'Giờ đi',
      dataIndex: 'depart_time',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Giờ đi',
    },
    {
      title: 'Ngày đặt',
      dataIndex: 'order_date',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Ngày đặt',
    },
    {
      title: 'Nguồn vé',
      dataIndex: 'orderer',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
      },
      __excelTitle__: 'Nguồn vé',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      __excelTitle__: 'Trang thái',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      render: (text) => <Typography>{getStatusBooking(text)}</Typography>,
    },
  ];
  const onChangeStatus = (value: number | undefined) => {
    setStatus(value);
  };
  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword({ ...keyword, keyword: e.target.value });
  };

  const handleSearch = (values: any) => {
    formSearch.setFields([{ name: 'keyword', value: mbTrim(`${values.keyword}`) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(formSearch.getFieldsValue(['keyword']));
  };

  const handlePagination = (page: number, pageSize: number) => {
    if (paginationCustomer?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  useEffect(() => {
    dispatch(
      getListCustomerBooking({
        ...keyword,
        status,
        page,
        limit,
        from: rangeDate
          ? dayjs(rangeDate[0]).format(YearFormat)
          : accountDetail?.export_report_day
          ? dayjs(currentDate.subtract(accountDetail?.export_report_day, 'day')).format('YYYY-MM-DD')
          : undefined,
        to: rangeDate ? dayjs(rangeDate[1]).format(YearFormat) : undefined,
        date_filter: filterDate ? filterDate : 'depart_date',
      }),
    );
  }, [dispatch, status, page, limit, keyword, rangeDate, filterDate, accountDetail]);
  useEffect(() => {
    if (listCustomerBooking?.length) {
      const tmpListCustomerBooking = listCustomerBooking?.map((item: any) => {
        return {
          ...item,
          orderer: item?.orderer?.agent ? item?.orderer?.agent?.name : item?.orderer?.name,
          status: getStatusBooking(item?.status),
        };
      });
      setDataExportCsv(tmpListCustomerBooking);
    } else {
      setDataExportCsv([]);
    }
  }, [listCustomerBooking]);

  const handleClick = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Danh sách hành khách');
    const header = columnsExport.map((col) => ({ header: col.title, key: col.dataIndex as string }));
    worksheet.columns = header;

    columnsExport.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width || 15;
    });
    worksheet.addRows(dataExportCsv);
    worksheet.getRow(1).font = { name: 'Times New Roman', size: 12, bold: true };
    worksheet.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3AB54A' } };
    worksheet.getRow(1).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    let firstRowHasData = false;
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      if (cell.value) {
        firstRowHasData = true;
        return;
      }
    });

    if (firstRowHasData) {
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber > 1) {
          row.eachCell((cell) => {
            cell.font = { name: 'Times New Roman', size: 12 };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
        }
      });
    } else {
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          let hasData = false;
          row.eachCell((cell) => {
            if (cell.value) {
              hasData = true;
              return;
            }
          });
          if (hasData) {
            row.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          }
        }
      });
    }
    const lastRow = worksheet.addRow([]);
    lastRow.getCell(1).value = '@Bản quyền thuộc nhà xe Carlink ';
    lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    lastRow.getCell(1).font = { name: 'Times New Roman', size: 12 };
    const startColumnIndex = 2;
    const endColumnIndex = startColumnIndex + columnsExport.length - 1;
    worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Danh sách vé đã bán.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const disabledRangeDaysDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    const exportDay = accountDetail?.export_report_day || 30;
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= exportDay;
    }
    return false;
  };

  useEffect(() => {
    if (statusCreateUpdate === Constant.DEFAULT_STATUS) {
      dispatch(
        getListCustomerBooking({
          ...keyword,
          status,
          page,
          limit,
          from: rangeDate
            ? dayjs(rangeDate[0]).format(YearFormat)
            : accountDetail?.export_report_day
            ? dayjs(currentDate.subtract(accountDetail?.export_report_day, 'day')).format('YYYY-MM-DD')
            : undefined,
          to: rangeDate ? dayjs(rangeDate[1]).format(YearFormat) : undefined,
          date_filter: filterDate ? filterDate : 'depart_date',
        }),
      );
      if (group === USER_GROUP.AGENT) {
        dispatch(fetchAccount());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateUpdate,status, page, limit, keyword, rangeDate, filterDate, accountDetail]);
  return (
    <Layout className="min-vh-100 list_user agent agent-container">
      <Spin spinning={+page !== 1 ? loadingListCustomer : false}>
        <Card>
          <StatusFilterBooking status={status} onChangeStatus={onChangeStatus} setPage={setPage} isContract={false} />
          <div className="table-booking">
            <Form
              className="mg-16 mt-3"
              form={formSearch}
              labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
              requiredMark={false}
              onFinish={handleSearch}
              colon={false}
            >
              <Form.Item className="label-search" labelAlign="left" labelCol={{ md: 0 }} name="form-search">
                <Row gutter={12}>
                  <Col span={5}>
                    <Form.Item name="keyword">
                      <Input
                        placeholder={t('Nhập SĐT / mã vé để tìm kiếm')}
                        suffix={<SearchOutlined className="ic-search-input" />}
                        onChange={handleKeywordChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={'date_filter'}>
                      <Select
                        defaultValue="depart_date"
                        options={[
                          { value: 'depart_date', label: 'Theo ngày khởi hành' },
                          { value: 'order_date', label: 'Theo ngày đặt vé' },
                        ]}
                        onChange={() => setPage(1)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="range_date" className="data-picker booking">
                      <RangePicker
                        placeholder={['Từ ngày', 'Đến ngày']}
                        style={{ height: '36px' }}
                        format={DateFormat}
                        onChange={() => setPage(1)}
                        disabledDate={disabledRangeDaysDate}
                        minDate={
                          accountDetail?.export_report_day
                            ? currentDate.subtract(accountDetail?.export_report_day, 'day')
                            : undefined
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button className="btn-export" onClick={() => handleClick()}>
                      <img src={iconExport} alt="excel" />
                      Xuất excel
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
            <Table
              locale={{
                triggerDesc: 'Sắp xếp giảm dần',
                triggerAsc: 'Sắp xếp tăng dần',
                cancelSort: 'Hủy sắp xếp',
              }}
              dataSource={listCustomerBooking?.length > 0 ? listCustomerBooking : []}
              rowKey="ticket_id"
              columns={columns1}
              className="list-bus"
              pagination={false}
              loading={false}
            />
            <div className="pagination-table agency mt-3">
              <Pagination
                onChange={handlePagination}
                current={page}
                total={paginationCustomer?.total}
                pageSize={paginationCustomer?.per_page ?? limitPagination}
                showSizeChanger={true}
                prevIcon={<img src={iconPrev} alt="" />}
                nextIcon={<img src={iconNext} alt="" />}
              />
            </div>
          </div>
        </Card>
        <ModalBooking
          okText={'Lưu'}
          modalOpen={openDetail}
          onCancel={cancelModal}
          cancelText={'Đóng'}
          onOk={handleOkModalBooking}
          formSearch={formSearch}
          isDetail={isDetail}
          listBookingDetail={listBookingDetail}
          loading={loadingDetail}
          isListTicket={true}
          dataCustomerBooking={dataCustomerBooking}
        />
        <ModalHistoryBooking
          modalOpen={openModalHistory}
          onOk={() => setOpenModalHistory(false)}
          onCancel={cancelModalHistory}
          historyDetail={listBookingDetail}
          isCancel={true}
          dataCustomerBooking={dataCustomerBooking}
        />
      </Spin>
    </Layout>
  );
}
export default ListTicket;
