import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { getErrorMessage } from '../../../api';
import { setToast } from '../toastNotifySlice';
import { Action, DEPART_SUCCESS, UPDATE_ERROR, UPDATE_SUCCESS } from '../../../config/message';
import { Toast } from '../../../config/constant';
import { departTripAPI, updateStatusTripAPI } from '../../../api/trips';
import { TripState } from '../../../types/trip';

// @ts-ignore
export const updateTrip = createAsyncThunk(
  'admin/update/Trip',
  async (params: { schedule_id: any; cancel_reason?: string; status: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateStatusTripAPI(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: UPDATE_SUCCESS(Action.TRIP),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message: response.data.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const updateStatusTrip = createAsyncThunk(
  'admin/update/Status/Trip',
  async (params: { schedule_id: any; cancel_reason?: string; status: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateStatusTripAPI(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: UPDATE_SUCCESS(Action.TRIP),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message: UPDATE_ERROR(Action.TRIP) + ': ' + response.data.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const departTrip = createAsyncThunk('admin/depart/Trip', async (params: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await departTripAPI(params);
    const { data, status } = response;
    if (status) {
      dispatch(setUpdate({ status: status, data: data }));
      dispatch(
        setToast({
          message: DEPART_SUCCESS(Action.TRIP),
          status: true,
          type: Toast.TYPE_SUCCESS,
        }),
      );
    }
    return true;
  } catch (error: any) {
    const { response } = error;
    dispatch(setUpdateError(response));
    dispatch(
      setToast({
        message: response.data.message,
        status: true,
        type: Toast.TYPE_ERROR,
      }),
    );
    return rejectWithValue(getErrorMessage(error));
  }
});
export const updateTripSlice = createSlice({
  name: 'updateTrip',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
    messageDepart:''
  } as TripState,

  reducers: {
    setUpdate: (state: TripState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setUpdateError: (state: TripState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetUpdateTrip: (state: TripState) => {
      state.success = false;
      state.status = 0;
      state.message = '';
      state.errorDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTrip.pending, (state: TripState) => {
      state.loading = true;
    });
    builder.addCase(updateTrip.fulfilled, (state: TripState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(updateTrip.rejected, (state: TripState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });

    builder.addCase(updateStatusTrip.pending, (state: TripState) => {
      state.loading = true;
    });
    builder.addCase(updateStatusTrip.fulfilled, (state: TripState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(updateStatusTrip.rejected, (state: TripState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
    builder.addCase(departTrip.rejected, (state: TripState, { payload }) => {
      state.error = true;
      state.message = payload;
    });
  },
});

export const updateTripSelector = (state: RootState) => state.updateTrip;
export const { setUpdate, setUpdateError, resetUpdateTrip } = updateTripSlice.actions;
