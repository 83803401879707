export const paymentOptions = [
  { label: 'Giữ chỗ', value: 'reserve' },
  { label: 'Tiền mặt', value: 'cash' },
  { label: 'Chuyển khoản ngân hàng', value: 'bank_transfer' },
  { label: 'Tài khoản đại lý', value: 'agent_balance', disabled: true },
  { label: 'Tại nhà xe', value: 'paid_at_company', disabled: true },
];
export const paymentMultiOptions = [
  { label: 'Giữ chỗ', value: 'reserve' },
  { label: 'Tiền mặt', value: 'cash' },
  { label: 'Chuyển khoản ngân hàng', value: 'bank_transfer' },
];
export const paymentMultiDepartedOptions = [
  { label: 'Tiền mặt', value: 'cash' },
  { label: 'Chuyển khoản ngân hàng', value: 'bank_transfer' },
];
export const paymentAgentOptions = [
  { label: 'Giữ chỗ', value: 'reserve' },
  { label: 'Tài khoản đại lý', value: 'agent_balance' },
  { label: 'Tại nhà xe', value: 'paid_at_company' },
];
export const paymentAgentDepartOptions = [{ label: 'Tài khoản đại lý', value: 'agent_balance' }];
export const paymentAgentBalanceOptions = [
  { label: 'Giữ chỗ', value: 'reserve', disabled: true },
  { label: 'Tài khoản đại lý', value: 'agent_balance' },
  { label: 'Tại nhà xe', value: 'paid_at_company' },
];
export const paymentPaidCompanyOptions = [
  { label: 'Giữ chỗ', value: 'reserve', disabled: true },
  { label: 'Tiền mặt', value: 'cash' },
  { label: 'Chuyển khoản ngân hàng', value: 'bank_transfer' },
  { label: 'Tài khoản đại lý', value: 'agent_balance', disabled: true },
  { label: 'Tại nhà xe', value: 'paid_at_company', disabled: true },
];
export const agentBalanceOption = [
  {
    label: 'Tài khoản đại lý',
    value: 'agent_balance',
    disabled: true,
  },
];
export const cashOption = [
  {
    label: 'Tiền mặt',
    value: 'cash',
    disabled: true,
  },
];
export const bankOption = [
  {
    label: 'Chuyển khoản ngân hàng',
    value: 'bank_transfer',
    disabled: true,
  },
];

export const agentContractPaidOptions = [{ label: 'Tài khoản đại lý', value: 'agent_balance', disabled: true }];
export const paymentContractOptions = [
  { label: 'Tiền mặt', value: 'cash' },
  { label: 'Chuyển khoản ngân hàng', value: 'bank_transfer' },
];
export const paymentAgentContractOptions = [{ label: 'Tài khoản đại lý', value: 'agent_balance' }];

export const validatorEmailBooking: any = [{ type: 'email', message: 'Email không đúng định dạng' }];
