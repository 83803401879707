/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  Card,
  Image,
  List,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd";
import IcPlus from "../../assets/images/ic-plus.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ImgLogo from "../../assets/images/notify/logo.png";
import "../../assets/styles/listNotify.scss";
import AddNotify from "./AddNotify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchNotify } from "../../redux/slice/notify/notifySlice";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant, USER_GROUP } from "../../config/constant";
import EditNotify from "./EditNotify";
import { fetchAccount } from "../../redux/slice/auth/accountSlice";
import moment from "moment";
import icNotify1 from "../../assets/images/notify/icNotify1.svg";
import icNotify2 from "../../assets/images/notify/icNotify2.svg";
import icNotify3 from "../../assets/images/notify/icNotify3.png";
import icNotify4 from "../../assets/images/notify/icNotify4.svg";

function Notify() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination')
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const accountUser = useAppSelector((state) => state.authAccount?.user);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const [detailData, setDetailData] = useState({});
  const { result, pagination, isFetching } = useAppSelector(
    (state: any) => state?.listNotify
  );
  const dispatch = useAppDispatch();

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModalEdit = (item: any) => {
    setDetailData(item);
    setOpenEdit(true);
  };

  const handleTimeNotify = (item: any) => {
    if (item?.status) {
      return (
        <Tag bordered={false} color="processing" className="tag-happenning">
          <Image src={icNotify2} preview={false} /> Đang diễn ra
        </Tag>
      );
    } else if (!item?.since_date || !item?.to_date) {
      return;
    }

    const startDate = moment();
    const sinceDate = moment(item?.since_date);
    const toDate = moment(item?.to_date);

    if (startDate.isBefore(sinceDate)) {
      return (
        <Tag bordered={false} color="warning" className="tag-upcoming">
          {" "}
          <Image src={icNotify1} preview={false} /> Sắp diễn ra
        </Tag>
      );
    } else if (startDate.isAfter(toDate)) {
      return (
        <Tag bordered={false} color="default" className="tag-completed">
          <Image src={icNotify4} preview={false} /> Đã kết thúc
        </Tag>
      );
    } else if (startDate.isBetween(sinceDate, toDate)) {
      return (
        <Tag bordered={false} color="#DCF3FF" className="tag-happenning">
          <Image src={icNotify2} preview={false} /> Đang diễn ra
        </Tag>
      );
    } else {
      return;
    }
  };

  let query = `page=${page}&limit=${limit}`;
  const handleListNotify = () => {
    dispatch(fetchNotify({ query }));
  };

  useEffect(() => {
    handleListNotify();
  }, [query]);

  useEffect(() => {
    dispatch(fetchAccount());
  }, []);

  return (
    <Card className="wrapper-notify">
      {accountUser?.is_superadmin && (
        <Row>
          <Button
            type="primary"
            size="large"
            onClick={showModal}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t("Thêm thông báo")}
          </Button>
        </Row>
      )}
      <List
        className="demo-loadmore-list"
        loading={isFetching}
        // bordered
        pagination={{
          total: pagination?.total,
          current: page,
          pageSize: pagination?.per_page ?? limitPagination,
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        itemLayout="horizontal"
        dataSource={result?.length > 0 ? result : []}
        renderItem={(item: any) => (
          <List.Item className={item?.is_deleted ? "list-item-notify" : ""}>
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar src={ImgLogo} />}
                title={
                  <Space className="space-title-notify" size={12}>
                    <Typography
                      className="title-notify cursor-pointer"
                      onClick={() => showModalEdit(item)}
                    >
                      {item?.title}
                    </Typography>
                    {item?.is_deleted ? (
                      <Tag bordered={false} color="red" className="tag-locked">
                        <Image src={icNotify3} preview={false} /> Đã khóa
                      </Tag>
                    ) : (
                      handleTimeNotify(item)
                    )}
                  </Space>
                }
                description={
                  <Typography className="content-notify">
                    {item?.content}
                    {/* {`từ ${item?.since_date} đến ${item?.to_date}`} */}
                  </Typography>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
      {isModalOpen && (
        <AddNotify
          handleListNotify={handleListNotify}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          accountUser={accountUser}
        />
      )}
      {openEdit && (
        <EditNotify
          handleListNotify={handleListNotify}
          isModalOpen={openEdit}
          setIsModalOpen={setOpenEdit}
          detailData={detailData}
          accountUser={accountUser}
        />
      )}
    </Card>
  );
}

export default Notify;
