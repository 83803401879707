import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Flex,
  Image,
  Table,
  Select,
  Button,
  Divider,
  GetProps,
  DatePicker,
  Typography,
  ConfigProvider,
  type FormProps,
  type TableColumnsType,
} from "antd";
import "dayjs/locale/vi";
import dayjs from "dayjs";
import moment from "moment";
import "./Report.scss";
import { formatPrice, handleGetUrlGuide } from "../../utils/helpers";
import IconFileExport from "../../components/Icons/IconFileExport";
import IconExportExcel from "../../components/Icons/IconExportExcel";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import { DataReport, TimeRouteData, ValueOptions } from "../../types/report";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant } from "../../config/constant";
import { toast } from "react-toastify";
import {
  getListDepartTimeByRouteAPI,
  getListDriverAPI,
  getListRouteAPI,
} from "../../api/report";
import { RouteItemProps } from "../../types";
import { optionsBookingDate } from "./ReportDetailCompany";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getListReportDriver,
  getListReportDriverExcel,
  setFilterBookingDate,
  setFilterDepartTime,
  setFilterDriver,
  setFilterRangeDate,
  setFilterReportFor,
  setFilterRoute,
  setListTimeRoute,
  setPerPage
} from "../../redux/slice/report/reportDriverSlice";
import ExcelJS from "exceljs";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import TutorialsVideo from "../../components/TutorialsVideo";

dayjs.locale("vn");
const { RangePicker } = DatePicker;
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

export enum TypeReportFor {
  ticket_price = "ticket_price",
  driver_fees = "driver_fees",
}

const optionsReportFor = [
  {
    value: TypeReportFor.ticket_price,
    label: "Tiền vé",
  },
  {
    value: TypeReportFor.driver_fees,
    label: "Chi phí tài xế",
  },
];

const optionsPayMethod = [
  {
    value: "cash",
    label: "Tiền mặt",
  },
  {
    value: "bank_transfer",
    label: "Chuyển khoản",
  },
  {
    value: "agent_balance",
    label: "Đại lý thanh toán",
  },
];

type FieldType = {
  type_date_report: string;
  range_date_report: string;
  route: number | string;
  time_route: number | string;
  driver: number;
  report_for: string;
};

const ReportDriver = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [timeReport, setTimeReport] = useState<RangePickerProps["value"]>();
  const [optionsRoute, setOptionsRoute] = useState<ValueOptions[]>([]);
  const [optionsDriver, setOptionsDriver] = useState<ValueOptions[]>([]);
  const watchReportFor = Form.useWatch("report_for", form);

  const [typeReportFor, setTypeReportFor] = useState<TypeReportFor>(
    TypeReportFor.ticket_price
  );

  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

  const pagination = useAppSelector((state) => state?.reportDriver?.pagination);
  const listReport = useAppSelector((state) => state?.reportDriver?.listReport);
  const loadingListReport = useAppSelector(
    (state) => state?.reportDriver?.loading
  );
  const totalPrice = useAppSelector(
    (state) => state?.reportDriver?.total_price
  );
  const totalRealRevenue = useAppSelector(
    (state) => state?.reportDriver?.total_real_revenue
  );
  const listTimeRoute = useAppSelector(
    (state) => state?.reportDriver?.listTimeRoute
  );
  const listFilter = useAppSelector((state) => state?.reportDriver?.filter);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);

  const watchTypeDateReport = Form.useWatch("type_date_report", form);
  const watchRoute = Form.useWatch("route", form);
  const watchTimeRoute = Form.useWatch("time_route", form);
  const watchDriver = Form.useWatch("driver", form);

  const handleGetListRoute = async () => {
    const response = await getListRouteAPI();
    if (response) {
      const listRoute: ValueOptions[] = response?.data?.data?.map(
        (route: RouteItemProps) => ({
          value: route?.id,
          label: `${route?.depart_point} - ${route?.arrive_point}`,
        })
      );
      listRoute.unshift({
        value: "",
        label: "Tất cả các tuyến",
      });
      setOptionsRoute(listRoute);
    }
  };

  const handleChangeRoute = async (value: number | string) => {
    const response = await getListDepartTimeByRouteAPI(value);
    if (response) {
      const listTimeRoute: ValueOptions[] = response?.data?.data?.map(
        (time: TimeRouteData) => ({
          value: dayjs(time?.depart_time, "HH:mm").format("HH:mm"),
          label: dayjs(time?.depart_time, "HH:mm").format("HH:mm"),
        })
      );
      listTimeRoute.unshift({
        value: "",
        label: "Tất cả",
      });
      dispatch(setFilterRoute(value));
      dispatch(setListTimeRoute(listTimeRoute));
      dispatch(setFilterDepartTime(""));
      form.setFieldValue("time_route", { value: "", label: "Giờ đi" });
    }
  };

  const handleGetListDriver = async () => {
    const response = await getListDriverAPI();
    if (response) {
      const listDriver: ValueOptions[] = response?.data?.data?.map(
        (driver: any) => ({
          value: driver?.id,
          label: driver?.name,
        })
      );
      setOptionsDriver([{ value: "", label: "Tất cả" }, ...listDriver]);
    }
  };

  useEffect(() => {
    handleGetListRoute();
    handleGetListDriver();
  }, []);

  const onChangePage = async (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      dispatch(setPerPage(pageSize));
    } else {
      setPage(page);
    }
    await dispatch(
      getListReportDriver({
        booking_date: watchTypeDateReport,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        report_for: watchReportFor,
        driver_id: watchDriver?.label ? watchDriver?.value : watchDriver,
        route_id: watchRoute?.label ? watchRoute?.value : watchRoute,
        depart_time: watchTimeRoute?.label
          ? watchTimeRoute?.value
          : watchTimeRoute,
        page: pagination?.per_page !== pageSize ? Constant.DEFAULT_PAGE : page,
        limit: pageSize,
      })
    );
  };

  const onFinish: FormProps<any>["onFinish"] = async () => {
    if (watchReportFor === TypeReportFor.ticket_price) {
      setTypeReportFor(TypeReportFor.ticket_price);
    } else {
      setTypeReportFor(TypeReportFor.driver_fees);
    }
    setPage(Constant.DEFAULT_PAGE);
    const response = await dispatch(
      getListReportDriver({
        booking_date: watchTypeDateReport,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        report_for: watchReportFor,
        driver_id: watchDriver?.label ? watchDriver?.value : watchDriver,
        route_id: watchRoute?.label ? watchRoute?.value : watchRoute,
        depart_time: watchTimeRoute?.label
          ? watchTimeRoute?.value
          : watchTimeRoute,
        page: Constant.DEFAULT_PAGE,
        limit: pagination?.per_page || limitPagination,
      })
    );
    if (response?.payload?.success) {
      toast.success(response?.payload?.message);
    }
  };

  const handleGetListReportBegin = async () => {
    setPage(Constant.DEFAULT_PAGE);
    if (listFilter?.from && listFilter?.to && listFilter?.report_for) {
      await dispatch(
        getListReportDriver({
          booking_date: listFilter?.booking_date,
          from: dayjs(listFilter?.from).format("YYYY-MM-DD"),
          to: dayjs(listFilter?.to).format("YYYY-MM-DD"),
          report_for: listFilter?.report_for,
          driver_id: listFilter?.driver_id,
          route_id: listFilter?.route_id,
          depart_time: listFilter?.depart_time,
          page: Constant.DEFAULT_PAGE,
          limit: pagination?.per_page || limitPagination,
        })
      );
    }
  };

  useEffect(() => {
    handleGetListReportBegin();
    if (listFilter?.from && listFilter?.to) {
      setTimeReport([
        dayjs(listFilter?.from, "YYYY-MM-DD"),
        dayjs(listFilter?.to, "YYYY-MM-DD"),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTypeReportFor(listFilter?.report_for as TypeReportFor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsTicketPrice: TableColumnsType<DataReport> = [
    {
      title: "Mã vé",
      dataIndex: "ticket_code",
      render: (ticket_code) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {ticket_code ? ticket_code : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      render: (depart_date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_date ? moment(depart_date).format("DD/MM/YYYY") : ""}
        </Typography.Text>
      ),
      sorter: (a, b) => {
        if (
          a &&
          a?.depart_date &&
          a?.depart_date.length &&
          b &&
          b?.depart_date &&
          b?.depart_date.length
        ) {
          return a?.depart_date.localeCompare(b?.depart_date);
        } else if (a && a?.depart_date && a?.depart_date?.length) {
          // that means be has null license_class, so a will come first.
          return -1;
        } else if (b && b?.depart_date && b?.depart_date?.length) {
          // that means a has null license_class so b will come first.
          return 1;
        }
        // both license_class has null value so there will be no order change.
        return 0;
      },
    },
    {
      title: "Tuyến",
      dataIndex: "route",
      render: (route) => (
        <Typography.Text className="fs-16 text-color fw-500">{`${route?.depart_point} - ${route?.arrive_point}`}</Typography.Text>
      ),
      sorter: (a, b) =>
        `${a?.route?.depart_point} - ${a?.route?.arrive_point}`.localeCompare(
          `${b?.route?.depart_point} - ${b?.route?.arrive_point}`
        ),
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      render: (depart_time) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_time ? moment(depart_time, "HH:mm").format("HH:mm") : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Tài xế thu",
      dataIndex: "driver",
      render: (driver) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {driver?.[0]?.name ? driver?.[0]?.name : ""}
        </Typography.Text>
      ),
      sorter: (a, b) =>
        `${a?.driver?.[0]?.name}`?.localeCompare(`${b?.driver?.[0]?.name}`),
    },
    {
      title: "PTTT",
      dataIndex: "payment_method",
      render: (payment_method) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {handleGetLabelPayMethodTable(payment_method)}
        </Typography.Text>
      ),
    },
    {
      title: "Giá vé",
      dataIndex: "price",
      align: "right",
      render: (price) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {price ? formatPrice(price, 0, ",") : 0}
        </Typography.Text>
      ),
    },
    {
      title: "Thực thu",
      dataIndex: "real_revenue",
      align: "right",
      render: (real_revenue) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {real_revenue ? formatPrice(real_revenue, 0, ",") : 0}
        </Typography.Text>
      ),
    },
  ];
  const columnsDriverFees: TableColumnsType<DataReport> = [
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      render: (depart_date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_date ? moment(depart_date).format("DD/MM/YYYY") : ""}
        </Typography.Text>
      ),
      sorter: (a, b) => {
        if (
          a &&
          a?.depart_date &&
          a?.depart_date.length &&
          b &&
          b?.depart_date &&
          b?.depart_date.length
        ) {
          return a?.depart_date.localeCompare(b?.depart_date);
        } else if (a && a?.depart_date && a?.depart_date?.length) {
          // that means be has null license_class, so a will come first.
          return -1;
        } else if (b && b?.depart_date && b?.depart_date?.length) {
          // that means a has null license_class so b will come first.
          return 1;
        }
        // both license_class has null value so there will be no order change.
        return 0;
      },
    },
    {
      title: "Tuyến",
      dataIndex: "route",
      render: (route) => (
        <Typography.Text className="fs-16 text-color fw-500">{`${route?.depart_point} - ${route?.arrive_point}`}</Typography.Text>
      ),
      sorter: (a, b) =>
        `${a?.route?.depart_point} - ${a?.route?.arrive_point}`.localeCompare(
          `${b?.route?.depart_point} - ${b?.route?.arrive_point}`
        ),
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      render: (depart_time) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_time ? moment(depart_time, "HH:mm").format("HH:mm") : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Tài xế",
      dataIndex: "driver",
      render: (driver) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {driver?.[0]?.name ? driver?.[0]?.name : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Cầu đường",
      dataIndex: "tolls",
      align: "right",
      render: (tolls) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {tolls ? formatPrice(tolls, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.tolls) - Number(b?.tolls),
    },
    {
      title: "Tiền dầu",
      dataIndex: "oil_money",
      align: "right",
      render: (oil_money) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {oil_money ? formatPrice(oil_money, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.oil_money) - Number(b?.oil_money),
    },
    {
      title: "Rửa xe",
      dataIndex: "wash_money",
      align: "right",
      render: (wash_money) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {wash_money ? formatPrice(wash_money, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.wash_money) - Number(b?.wash_money),
    },
    {
      title: "Tiền ăn",
      dataIndex: "foods",
      align: "right",
      render: (foods) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {foods ? formatPrice(foods, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.foods) - Number(b?.foods),
    },
    {
      title: "Bến bãi",
      dataIndex: "parking",
      align: "right",
      render: (parking) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {parking ? formatPrice(parking, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.parking) - Number(b?.parking),
    },
    {
      title: "Tổng tiền",
      dataIndex: "price",
      align: "right",
      render: (price) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {price ? formatPrice(price, 0, ",") : 0}
        </Typography.Text>
      ),
      sorter: (a, b) => Number(a?.price) - Number(b?.price),
    },
  ];

  const handleGetLabelTypeDateRoute = (value: string) => {
    const typeCurrent = optionsBookingDate?.find(
      (type) => type.value === value
    );
    return typeCurrent?.label;
  };

  const handleGetLabelRoute = (value: number | string) => {
    const routeCurrent = optionsRoute?.find((route) => route?.value === value);
    return routeCurrent?.label || "Tất cả các tuyến";
  };

  const handleGetLabelTimeRoute = (value: string) => {
    const timeCurrent = listTimeRoute.find((time) => time.value === value);
    return timeCurrent?.label || "Tất cả";
  };

  const handleGetLabelDriver = (value: number | string) => {
    const driverCurrent = optionsDriver?.find(
      (driver) => driver?.value === value
    );
    return driverCurrent?.label || "Tất cả";
  };

  const handleGetLabelTypeReport = (value: string) => {
    const typeCurrent = optionsReportFor?.find((type) => type?.value === value);
    return typeCurrent?.label;
  };

  const handleGetLabelPayMethodTable = (value: string) => {
    const methodCurrent = optionsPayMethod.find(
      (method) => method.value === value
    );
    return methodCurrent?.label || "";
  };

  const onChangeTimeReport = (value: RangePickerProps["value"]) => {
    setTimeReport(value);
    dispatch(setFilterRangeDate(value));
  };

  const onChangeTimeRoute = (value: number | string) => {
    dispatch(setFilterDepartTime(value));
  };

  const onChangeTypeDateReport = (type: string) => {
    dispatch(setFilterBookingDate(type));
  };

  const onChangeDriver = (value: number | undefined) => {
    dispatch(setFilterDriver(value));
  };

  const onChangeReportFor = (value: TypeReportFor) => {
    if (value === TypeReportFor.driver_fees) {
      form.setFieldValue("type_date_report", "false");
      dispatch(setFilterBookingDate("false"));
    }
    dispatch(setFilterReportFor(value));
  };

  const columnsExportTicketPrice: IExcelColumn[] = [
    {
      title: "Mã vé",
      __excelTitle__: "Mã vé",
      dataIndex: "ticket_code",
      width: 35,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      __excelTitle__: "Ngày đi",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Ngày đặt",
      dataIndex: "created_at",
      __excelTitle__: "Ngày đặt",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Tuyến",
      dataIndex: "route",
      width: 40,
      __excelTitle__: "Tuyến",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      __excelTitle__: "Giờ đi",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Tài xế thu",
      __excelTitle__: "Tài xế thu",
      dataIndex: "driver_collect",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "PTTT",
      __excelTitle__: "PTTT",
      dataIndex: "payment_method",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Giá vé",
      dataIndex: "price",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Giá vé",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Thực thu",
      dataIndex: "real_revenue",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Thực thu",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
  ];
  const columnsExportDriverFees: IExcelColumn[] = [
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      __excelTitle__: "Ngày đi",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Tuyến",
      dataIndex: "route",
      width: 40,
      __excelTitle__: "Tuyến",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      __excelTitle__: "Giờ đi",
      width: 15,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Tài xế",
      __excelTitle__: "Tài xế",
      dataIndex: "driver",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Cầu đường",
      __excelTitle__: "Cầu đường",
      dataIndex: "tolls",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Tiền dầu",
      __excelTitle__: "Tiền dầu",
      dataIndex: "oil_money",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Rửa xe",
      __excelTitle__: "Rửa xe",
      dataIndex: "wash_money",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Tiền ăn",
      __excelTitle__: "Tiền ăn",
      dataIndex: "foods",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Bến bãi",
      __excelTitle__: "Bến bãi",
      dataIndex: "parking",
      __cellType__: "TypeNumeric",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Tổng tiền",
      dataIndex: "price",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Tổng tiền",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
  ];

  const handleExportExcel = async () => {
    const response: any = await dispatch(
      getListReportDriverExcel({
        booking_date: watchTypeDateReport,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        route_id: watchRoute?.label ? watchRoute?.value : watchRoute,
        depart_time: watchTimeRoute?.label
          ? watchTimeRoute?.value
          : watchTimeRoute,
        driver_id: watchDriver?.label ? watchDriver?.value : watchDriver,
        report_for: watchReportFor,
      })
    );
    if (!response?.error) {
      const listData = response?.payload?.data;
      const columnsExport =
        watchReportFor === TypeReportFor.ticket_price
          ? columnsExportTicketPrice
          : columnsExportDriverFees;
      const totalPrice = response?.payload?.total_price;
      const totalRealRevenue = response?.payload?.total_real_revenue;
      const listDataExcel =
        listData?.length > 0
          ? watchReportFor === TypeReportFor.ticket_price
            ? listData?.map((report: DataReport) => ({
                ticket_code: report?.ticket_code ? report?.ticket_code : "",
                route: `${report?.route?.depart_point} - ${report?.route?.arrive_point}`,
                depart_date: report?.depart_date
                  ? `${moment(report?.depart_date).format("DD/MM/YYYY")}`
                  : "",
                depart_time: report?.depart_time
                  ? `${moment(report?.depart_time, "HH:mm").format("HH:mm")}`
                  : "",
                created_at: report?.created_at
                  ? `${moment(report?.created_at).format("DD/MM/YYYY")}`
                  : "",
                driver_collect: report?.driver?.[0]?.name
                  ? report?.driver?.[0]?.name
                  : "",
                payment_method: handleGetLabelPayMethodTable(
                  report?.payment_method as string
                ),
                price: report?.price ? Number(report?.price) : 0,
                real_revenue: report?.real_revenue
                  ? Number(report?.real_revenue)
                  : 0,
              }))
            : listData?.map((report: DataReport) => ({
                route: `${report?.route?.depart_point} - ${report?.route?.arrive_point}`,
                depart_date: report?.depart_date
                  ? `${moment(report?.depart_date).format("DD/MM/YYYY")}`
                  : "",
                depart_time: report?.depart_time
                  ? `${moment(report?.depart_time, "HH:mm").format("HH:mm")}`
                  : "",
                price: report?.price ? Number(report?.price) : 0,
                driver: report?.driver?.[0]?.name
                  ? report?.driver?.[0]?.name
                  : "",
                tolls: report?.tolls ? Number(report?.tolls) : 0,
                oil_money: report?.oil_money ? Number(report?.oil_money) : 0,
                wash_money: report?.wash_money ? Number(report?.wash_money) : 0,
                foods: report?.foods ? Number(report?.foods) : 0,
                parking: report?.parking ? Number(report?.parking) : 0,
              }))
          : [];
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Báo cáo tài xế, phụ xe");
      const header = columnsExport.map((col) => ({
        header: col.title,
        key: col.dataIndex as string,
      }));
      worksheet.columns = header;
      columnsExport.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width || 15;
        worksheet.getColumn(index + 1).alignment = {
          horizontal:
            (col?.__style__?.h as
              | "center"
              | "left"
              | "right"
              | "fill"
              | "justify") || "left",
          vertical: "middle",
        };
        worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || "general";
      });
      worksheet.addRows(listDataExcel);
      worksheet.getRow(1).font = {
        name: "Times New Roman",
        size: 12,
        bold: true,
      };
      if (watchReportFor === TypeReportFor.driver_fees) {
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          if (colNumber < 11) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "3AB54A" },
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        });
      } else {
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          if (colNumber < 10) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "3AB54A" },
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        });
      }
      let firstRowHasData = false;
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (cell.value) {
          firstRowHasData = true;
          return;
        }
      });
      if (firstRowHasData) {
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber > 1) {
            row.eachCell((cell) => {
              cell.font = { name: "Times New Roman", size: 12 };
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          }
        });
      } else {
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            let hasData = false;
            row.eachCell((cell) => {
              if (cell.value) {
                hasData = true;
                return;
              }
            });
            if (hasData) {
              row.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        });
      }
      const startColumnIndex = 1;

      const subLastRow = worksheet.addRow([]);
      subLastRow.getCell(1).value = "TỔNG (VND)";
      subLastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      subLastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };

      let endColumnIndexTotal = 0;

      if (watchReportFor === TypeReportFor.driver_fees) {
        endColumnIndexTotal = startColumnIndex + columnsExport.length - 2;
      } else {
        endColumnIndexTotal = startColumnIndex + columnsExport.length - 3;
      }
      worksheet.mergeCells(
        subLastRow.number,
        1,
        subLastRow.number,
        endColumnIndexTotal
      );
      if (watchReportFor === TypeReportFor.driver_fees) {
        subLastRow.getCell(10).value = totalPrice;
      } else {
        subLastRow.getCell(8).value = totalPrice;
        subLastRow.getCell(9).value = totalRealRevenue;
      }
      subLastRow.eachCell((cell) => {
        cell.font = { name: "Times New Roman", size: 14, bold: true };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      const lastRow = worksheet.addRow([]);
      lastRow.getCell(1).value = "@Bản quyền thuộc nhà xe Carlink";
      lastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      lastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };

      const endColumnIndex = startColumnIndex + columnsExport.length - 1;
      worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Báo cáo tài xế, phụ xe.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const filterOption = (
    input: string,
    option?: {
      label?: any;
      value?: any;
    }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return (
          Math.abs(current.diff(from, "days")) >=
          systemConfig?.export_report_month
        );
      }
      return false;
    },
    [systemConfig?.export_report_month]
  );

  return (
    <div className="Report bg-white">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          type_date_report: listFilter?.booking_date,
          range_date_report: [
            listFilter?.from
              ? dayjs(listFilter?.from, "DD/MM/YYYY")
              : undefined,
            listFilter?.to ? dayjs(listFilter?.to, "DD/MM/YYYY") : undefined,
          ],
          report_for: listFilter?.report_for,
          route:
            listFilter?.route_id === ""
              ? { value: listFilter?.route_id, label: "Chọn tuyến" }
              : listFilter?.route_id,
          time_route:
            listFilter?.depart_time === ""
              ? { value: "", label: "Giờ đi" }
              : listFilter?.depart_time,
          driver:
            listFilter?.driver_id === ""
              ? { value: "", label: "Tài xế / phụ xe" }
              : listFilter?.driver_id,
        }}
      >
        <Row gutter={12}>
          <Col className="Report__typeDateReport">
            <Form.Item<FieldType>
              className="required"
              name="type_date_report"
              label="Khoảng thời gian"
              rules={[
                { required: true, message: "Vui lòng chọn khoảng thời gian" },
              ]}
            >
              <Select
                placeholder="Theo ngày khởi hành"
                options={optionsBookingDate}
                disabled={watchReportFor === TypeReportFor.driver_fees}
                onChange={onChangeTypeDateReport}
              />
            </Form.Item>
          </Col>
          <Col className="Report__rangeDateReport">
            <Form.Item<FieldType>
              name="range_date_report"
              rules={[
                { required: true, message: "Vui lòng chọn khoảng thời gian" },
              ]}
            >
              <RangePicker
                format="DD/MM/YYYY"
                disabledDate={disabledDaysDate}
                onChange={onChangeTimeReport}
                popupClassName="Report__timeReport__picker"
                placeholder={["Từ ngày", "Đến ngày"]}
              />
            </Form.Item>
          </Col>
          <Col className="Report__typeDateReport">
            <Form.Item<FieldType>
              className="required"
              name="report_for"
              label="Báo cáo theo"
              rules={[
                { required: true, message: "Vui lòng chọn báo cáo theo" },
              ]}
            >
              <Select
                onChange={onChangeReportFor}
                placeholder="Loại báo cáo"
                options={optionsReportFor}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex align="center" gap={"small"} className="mb-24">
          <Image
            preview={false}
            width={20}
            height={20}
            className="d-flex"
            src={IconExclamationCircle}
            alt="Icon Exclamation Circle"
          />
          <Typography.Text className="fs-14 fw-500 text-color">
            Vui lòng chọn các thông tin bên trên để xem báo cáo
          </Typography.Text>
        </Flex>
        <Typography.Text className="fs-6 fw-500 text-color d-block mb-8">
          Bộ lọc nâng cao
        </Typography.Text>
        <Flex gap={"small"}>
          <Form.Item<FieldType> className="Report__route" name="route">
            <Select
              onChange={handleChangeRoute}
              options={optionsRoute}
              popupClassName="Report__popup"
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item<FieldType> className="Report__timeRoute" name="time_route">
            <Select
              onChange={onChangeTimeRoute}
              options={listTimeRoute}
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item<FieldType> className="Report__driver" name="driver">
            <Select
              placeholder="Chọn tài xế / phụ xe"
              options={optionsDriver}
              onChange={onChangeDriver}
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
        </Flex>
        <Flex align="center" justify="space-between">
          <Form.Item className="Report__submit">
            <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
              <Button
                disabled={
                  (timeReport?.length === 2 ||
                    (listFilter?.from && listFilter?.to)) &&
                  watchReportFor
                    ? false
                    : true
                }
                className="d-flex align-items-center h-auto"
                type="primary"
                htmlType="submit"
              >
                <IconFileExport width={16} height={16} />
                Xem báo cáo
              </Button>
            </ConfigProvider>
          </Form.Item>
          <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 2)} />
        </Flex>
        <Divider />
        <Flex>
          <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
            <Button
              ghost
              disabled={
                (timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)) &&
                watchReportFor
                  ? false
                  : true
              }
              onClick={handleExportExcel}
              className={`Report__exportExcel d-flex align-items-center mr-12 h-auto ${
                (timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)) &&
                watchReportFor
                  ? ""
                  : "disabled"
              }`}
              type="primary"
            >
              <IconExportExcel
                color={`${
                  (timeReport?.length === 2 ||
                    (listFilter?.from && listFilter?.to)) &&
                  watchReportFor
                    ? "#435869"
                    : "#949494"
                }`}
                width={16}
                height={16}
              />
              Xuất Excel
            </Button>
          </ConfigProvider>
        </Flex>
        <Typography.Text className="d-block text-color-gray-3A3A3A fs-18 fw-600 mt-24 mb-8">
          Báo cáo tài xế / phụ xe (
          {handleGetLabelTypeDateRoute(watchTypeDateReport)})
        </Typography.Text>
        <Flex wrap="wrap" align="center" className="Report__filter">
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Từ ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[0]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Đến ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[1]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          {watchReportFor && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Báo cáo theo:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {handleGetLabelTypeReport(watchReportFor)}
              </Typography.Text>
            </Flex>
          )}
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Tuyến:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelRoute(watchRoute)}
            </Typography.Text>
          </Flex>
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Giờ đi:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelTimeRoute(watchTimeRoute)}
            </Typography.Text>
          </Flex>
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Tài xế, phụ xe:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelDriver(watchDriver)}
            </Typography.Text>
          </Flex>
        </Flex>
      </Form>
      <Table
        className="Report__table"
        locale={{
          triggerDesc: "Sắp xếp tăng dần",
          triggerAsc: "Hủy sắp xếp",
          cancelSort: "Sắp xếp giảm dần",
        }}
        pagination={{
          total: pagination?.total,
          current: page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ["bottomRight"],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        dataSource={listReport?.length > 0 ? listReport : []}
        loading={loadingListReport}
        columns={
          typeReportFor === TypeReportFor.ticket_price
            ? columnsTicketPrice
            : columnsDriverFees
        }
        summary={(pageData) => {
          return (
            listReport?.length > 0 &&
            page === pagination?.last_page && (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={
                      typeReportFor === TypeReportFor.ticket_price ? 6 : 9
                    }
                  >
                    <Flex align="center" gap="middle">
                      <Typography.Text className="text-color-default fw-700 fs-16">
                        TỔNG
                      </Typography.Text>
                      <Typography.Text className="text-color-default fw-500 fs-16">
                        (VND)
                      </Typography.Text>
                    </Flex>
                  </Table.Summary.Cell>
                  {typeReportFor === TypeReportFor.ticket_price && (
                    <Table.Summary.Cell align="right" index={6}>
                      <Typography.Text className="text-color-default text-end fw-700 fs-16">
                        {totalPrice ? formatPrice(totalPrice, 0, ",") : 0}
                      </Typography.Text>
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell
                    align="right"
                    index={typeReportFor === TypeReportFor.ticket_price ? 7 : 9}
                  >
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {typeReportFor === TypeReportFor.driver_fees
                        ? totalPrice
                          ? formatPrice(totalPrice, 0, ",")
                          : 0
                        : totalRealRevenue
                        ? formatPrice(totalRealRevenue, 0, ",")
                        : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          );
        }}
      />
    </div>
  );
};

export default ReportDriver;
