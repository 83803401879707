import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BookingState } from '../../../types/booking';
import { getCodeTicketApi } from '../../../api/booking';
import { Constant } from '../../../config/constant';
import { RootState } from '../../store';
import { getErrorMessage } from '../../../api';

export const getTicketCodeBooking = createAsyncThunk(
  'admin/generate-code',
  async (amount: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCodeTicketApi(amount);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setTicketCode(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getTicketCodeSlice = createSlice({
  name: 'ticketCode',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
    ticketCode: null,
  } as BookingState,
  reducers: {
    setTicketCode: (state: BookingState, { payload }) => {
      state.ticketCode = payload?.data?.ticket_code;
    },
    resetTicketCode: (state: BookingState) => {
      state.ticketCode = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTicketCodeBooking.pending, (state: BookingState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getTicketCodeBooking.fulfilled, (state: BookingState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getTicketCodeBooking.rejected, (state: BookingState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const getTicketCodeSelector = (state: RootState) => state.ticketCode;
export const { setTicketCode, resetTicketCode } = getTicketCodeSlice.actions;
