import { Button, Form, Input, Modal } from "antd";
import { Constant } from "../../config/constant";
import { checkAuthorization } from "../../api";
import { toast } from "react-toastify";
import { createTutorialsApi } from "../../api/tutorials";

const CreateGuideModal = ({
  setOpenTutorial,
  openTutorial,
  handleTutorialApi,
}: any) => {
  const [form] = Form.useForm();
  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpenTutorial(false);
  };

  const onFinish = async (values: any) => {
    try {
      const res = await createTutorialsApi(values);
      if (res.status === Constant.DEFAULT_STATUS) {
        handleTutorialApi();
        toast.success("Thao tác thành công");
        handleCancel();
        form.resetFields([])
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  return (
    <>
      <Modal
        title="Thêm hướng dẫn sử dụng"
        open={openTutorial}
        onOk={handleOk}
        maskClosable={false}
        width={840}
        onCancel={handleCancel}
        className="wrapper-modal-notify"
        footer={() => (
          <div className="modal-footer-notify">
            <Button onClick={handleCancel}>Hủy</Button>
            <Button type="primary" onClick={handleOk}>
              Lưu
            </Button>
          </div>
        )}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="function"
            label="Tên chức năng"
            rules={[
              { required: true, message: "Vui lòng nhập tên chức năng" },
              { min: 10, message: "Chức năng tối thiểu 10 ký tự" },
              { max: 70, message: "Chức năng tối đa 70 ký tự" },
            ]}
          >
            <Input placeholder="Vui lòng nhập tên chức năng" />
          </Form.Item>
          <Form.Item
            name="url_youtube"
            label="URL Youtube"
            rules={[
              { required: true, message: "Vui lòng nhập tên chức năng" },
              { min: 10, message: "URL youtube tối thiểu 10 ký tự" },
              { max: 70, message: "URL youtube tối đa 70 ký tự" },
            ]}
          >
            <Input placeholder="Vui lòng nhập url youtube" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateGuideModal;
