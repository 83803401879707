export enum RegisterAdvertise {
    ALL = 'ALL',
    UN_PROCESSED = 'UN_PROCESSED',
    PROCESSED = 'PROCESSED',
}
export const optionsPackageAdvertise = [
    {
        value: "",
        label: "Tất cả gói quảng cáo",
    },
    {
        value: "Top Banner",
        label: "Top Banner",
    },
    {
        value: "Middle Banner",
        label: "Middle Banner",
    },
    {
        value: "Bottom Banner",
        label: "Bottom Banner",
    },
    {
        value: "Top tuyến đường (Top 7 - 9)",
        label: "Top tuyến đường (Top 7 - 9)",
    },
    {
        value: "Top nhà xe (Top 1 - 4)",
        label: "Top nhà xe (Top 1 - 4)",
    },
    {
        value: "Top nhà xe (Top 5 - 8)",
        label: "Top nhà xe (Top 5 - 8)",
    },
    {
        value: "Top tuyến đường (Top 1 - 3)",
        label: "Top tuyến đường (Top 1 - 3)",
    },
    {
        value: "Top tuyến đường (Top 4 - 6)",
        label: "Top tuyến đường (Top 4 - 6)",
    },
];