import React from "react";
import { Form, Input } from "antd";
interface InputProps {
  onChange?: (value: any) => void;
  placeholder?: string;
  name: string;
  form: any;
  disabled ?:any;
}

const NumberInputName: React.FC<InputProps> = ({
  form,
  name,
  onChange,
  placeholder,
  disabled
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = form.getFieldValue(name);
    const numericValue = parseFloat(rawValue.replace(/[^0-9.]/g, "")); // Lấy ra số từ chuỗi
    if (!isNaN(numericValue)) {
      form.setFieldsValue({
        [`${name}`]: numericValue,
      });
    } else {
      form.setFieldsValue({
        [`${name}`]: 0,
      });
    }
    if (onChange) onChange(numericValue);
  };
  // Hàm định dạng số với dấu phân tách phần ngàn
  return (
    <Form.Item name={name} validateTrigger={["onChange", "onBlur"]}>
      <Input
        defaultValue={0}
        onChange={handleChange}
        placeholder={placeholder ?? ""}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default NumberInputName;
