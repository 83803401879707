/* eslint-disable react-hooks/exhaustive-deps */
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Upload,
  UploadProps,
  message,
} from "antd";
import { RcFile } from "antd/es/upload";
import { UploadFile } from "antd/lib";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/styles/desktop/busCompany.scss";
import "../../assets/styles/listAgent.scss";
import {
  phoneRequiredRules,
  validatorEmail,
  validatorEmailOptional,
} from "../../config/validators/validators";
import { getBase64 } from "../../lib/utils";
import IconClose from "../../assets/images/listUser/iconsClose.svg";
import { checkAuthorization } from "../../api";
import {
  validateNameAgent,
  validateAddressAgent,
} from "../../config/validators/validateAgent";
import { ACTION_NAME, messageUpdateSuccess } from "../../config/message";
import { Constant } from "../../config/constant";
import {
  validateCommissionPercentCarCompany,
  validateTicketFeeCarCompany,
} from "../../config/validators/validateCarCompany";
import { updateListBusCompanyDetailApi, updateListBusCompanyStatusApi } from "../../api/busCompany";
import IcLock from '../../assets/images/ic-lock.svg';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';

function EditBus({
  openCreate,
  setOpenCreate,
  handleListBusApi,
  detailData,
}: any) {
  const image: any = detailData?.logo
    ? [
        {
          uid: "-1",
          name: "No avatar",
          status: "done",
          url: detailData?.logo,
        },
      ]
    : [];
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageLogo, setImageLogo] = useState<any>();
  const [displayPrice, setDisplayPrice] = useState<any>(0);
  const [loading, setLoading] = useState(false)

  const props: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    setOpenCreate(false);
  };

  const handleOk = () => {
    form.submit();
  };

  //HANDLE SUBMIT
  const onFinish = async (values: any) => {
    setLoading(true)
    values.logo = fileList[0]?.thumbUrl;
    values.display_price = displayPrice;
    // values.relevant_emails = ["nhaxehaivan2@gmail.com"]
    try {
      const response: any = await updateListBusCompanyDetailApi({
        id: detailData?.id,
        ...values,
      });
      if (response.status === Constant.DEFAULT_STATUS) {
        messageUpdateSuccess(ACTION_NAME.CAR_COMPANY);
        handleListBusApi();
        setOpenCreate(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
    setLoading(false)
  };

  // upload image
  const handleCancelImage = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList.length === 0) {
      setFileList(newFileList);
    } else {
      setFileList(newFileList.slice(-1));
      if (fileList.length !== 0 && fileList[0].url === undefined) {
        const dataBase64 = await getBase64(fileList[0].originFileObj as RcFile);
        setImageLogo(dataBase64);
      }
    }
  };

  const handleOkModal = async () => {
    try {
      const res = await updateListBusCompanyStatusApi( {id: detailData?.id,
        status: detailData?.status ? true : false,
      });
      if (res.status === Constant.DEFAULT_STATUS) {
        handleListBusApi();
        messageUpdateSuccess(ACTION_NAME.CAR_COMPANY);
        setOpenCreate(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const onChangePrintTicket = (checked: boolean) => {
    setDisplayPrice(checked ? 1 : 0);
  }

  useEffect(() => {
    setFileList(image);
  }, [detailData?.logo, openCreate]);

  useEffect(() => {
    form.setFieldsValue({
      id: detailData?.id,
      name: detailData?.name,
      email: detailData?.email,
      address: detailData?.address,
      note: detailData?.note,
      logo: detailData?.logo,
      phone: detailData?.phone,
      export_ticket_fee: detailData?.export_ticket_fee,
      commission_percent: detailData?.commission_percent,
      relevant_emails: detailData?.relevant_emails,
      display_price: detailData?.display_price
    });
    setDisplayPrice(detailData?.display_price ? 1 : 0)
  }, [detailData?.id, openCreate]);
  return (
    <Modal
      open={openCreate}
      title={
        <div className="title-modal-user" style={{ marginBottom: 20 }}>
          <div>Chỉnh sửa thông tin nhà xe</div>
          <div className="info-title-right">
            <Image
              onClick={handleCancel}
              style={{ cursor: "pointer" }}
              src={IconClose}
              alt="logo icon"
              preview={false}
            />
          </div>
        </div>
      }
      confirmLoading={loading}
      maskClosable={false}
      onCancel={handleCancel}
      className="modal-edit-user customize-modal"
      cancelText="Hủy" // Đổi tên nút Cancel
      okText="Lưu"
      onOk={handleOk}
      width={980}
      closable={false}
      footer={(_, { OkBtn, CancelBtn }: any) => (
        <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 24,
        }}
          className="footer-edit-modal-office"
        >
           <>
            <Popconfirm
              placement="topLeft"
              className="popconfirm-custom"
              title={detailData?.status ? t('Khóa nhà xe') : t('Mở nhà xe')}
              description={
                <div className='content-pop-des'>
                  {
                    detailData?.status
                      ? t('Bạn đang thao tác khóa nhà xe này, bạn có chắc chắn muốn khóa?')
                      : t('Bạn đang thao tác mở nhà xe này, bạn có chắc chắn muốn mở khóa?')
                  }
                </div>
              }
              okText={detailData?.status ? t('Khóa ngay') : t('Mở ngay')}
              cancelText={t('Trở lại')}
              okButtonProps={{ className: 'custom-ok-button-class' }} // Tùy chỉnh lớp CSS cho nút OK
              cancelButtonProps={{ className: 'custom-cancel-button-class' }} // Tùy chỉnh lớp CSS cho nút Hủy
              onConfirm={handleOkModal}
              icon={<Image preview={false} width={20} src={IconExclamationCircle} alt="Icon Exclamation Circle" />}
            >
              <Col className="block-user">
                <Button
                  style={{ width: 180, height: 48 }}
                  className={detailData?.status ? 'btn-block-red' : 'btn-active-green'}
                  danger
                  type="primary"
                  htmlType="button"
                  // onClick={showModal}
                >
                  <Image src={IcLock} preview={false} className="pb-1 pe-2" />
                  {detailData?.status ? t('Khóa nhà xe') : t('Mở nhà xe')}
                </Button>
              </Col>
            </Popconfirm>
          </>
          <div className="modal-btn-user modal-btn-agent d-flex gap-12">
            <CancelBtn className="modal-cancel-btn" />
            <OkBtn />
          </div>
        </div>
      )}
    >
      <Form
        name="agent-basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="form-create-agent form-edit-user height-auto"
      >
        {/* <Spin> */}
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="name"
              label={t("Tên nhà xe")}
              className="required"
              rules={validateNameAgent}
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="phone"
              rules={phoneRequiredRules}
              label={t("Số điện thoại")}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="email"
              label="Email liên hệ"
              rules={validatorEmail}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="relevant_emails"
              label="Email nhận mặt vé"
              rules={validatorEmailOptional}
              labelCol={{ span: 12 }}
              // className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24} xl={24} xxl={24}>
            <Form.Item
              name="address"
              label={t(" Địa chỉ ")}
              rules={validateAddressAgent}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item className="upload-image required-none" label={t("Logo")}>
              <Upload
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : (
                  <>
                    <UploadOutlined /> {t("Tải ảnh lên")}
                  </>
                )}
              </Upload>
              <Modal
                open={previewOpen}
                footer={null}
                onCancel={handleCancelImage}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              className="upload-image required-none"
              label={t("Mẫu website")}
            >
              <Upload {...props} disabled={true}>
                <Button
                  disabled={true}
                  className="text-color"
                  icon={<UploadOutlined />}
                >
                  Chọn mẫu Website
                </Button>
              </Upload>
              <Modal
                open={previewOpen}
                footer={null}
                onCancel={handleCancelImage}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12} style={{ display: "flex" }}>
            <Form.Item
              name="commission_percent"
              label="Chiết khấu"
              className="input-group required discount"
              rules={validateCommissionPercentCarCompany}
            >
              <Input
                type="number"
                suffix={"%"}
                style={{ width: 140, height: "unset !important" }}
                min={5}
              />
            </Form.Item>
            <span
              className="discount-carlink text-color fw-400"
              style={{ width: 250, marginTop: 6 }}
            >
              (cho đối tác carlink)
            </span>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="export_ticket_fee"
              label={t("Phí xuất vé")}
              rules={validateTicketFeeCarCompany}
              className="input-group discount required"
            >
              <Input
                type="number"
                suffix={"%"}
                style={{ width: 140, height: "unset !important" }}
                min={1}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item name="display_price" label="Cho phép in giá vé lên mặt vé" className="required">
            <Switch checked={displayPrice === 1 ? true : false} onChange={onChangePrintTicket} />
          </Form.Item>
        </Row>
        <Form.Item
          name="note"
          label={t("Ghi chú")}
          className="input-group note"
        >
          <Input.TextArea
            rows={4}
            cols={9}
            style={{ height: 96, width: 781, resize: "none" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditBus;
