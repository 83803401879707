import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Flex, Form, Layout, SelectProps, Typography } from 'antd';
import { CheckboxProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconLocation from '../../assets/images/segments/icon-location.svg';
import iconSetting from '../../assets/images/schedules/icon-setting.svg';
import '../../assets/styles/scheduleSetting.scss';
import StepSchedules from '../../components/StepSchedules';
import { Message } from '../../config/message';
import { convertOptionCheckBox } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAllAvailableBusType, listBusTypeSelector } from '../../redux/slice/busTypes/listBusTypeSlice';
import { fetchListRoute, getAllRoute, routeSelector } from '../../redux/slice/routeSlice';
import { getAllProvinces, ListScheduleSelector } from '../../redux/slice/schdules/scheduleSlice';
import SegmentSettingModal from './ModalSchedules/SegmentSettingModal';

function LocationSetting() {
  const { t } = useTranslation();
  const { loading: loadingRoute } = useAppSelector(routeSelector);
  const { listSegment } = useAppSelector(ListScheduleSelector);
  const { listAllRoute } = useAppSelector(routeSelector);
  const { listAllAvailableBusType } = useAppSelector(listBusTypeSelector);
  const [form] = Form.useForm();
  const [keywordBus, setKeywordBus] = useState<any>({});
  const [openModalSegment, setOpenModalSegment] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [routeActive, setRouteActive] = useState<any>();
  const [seatRender, setSeatRender] = useState<any>();
  const [allSeatName, setAllSeatName] = useState<any>();
  const [seatData, setSeatData] = useState<any>();
  const [groupSeat, setGroupSeat] = useState<any>();
  const [segmentTimelineItem, setSegmentTimelineItem] = useState<any>([]);
  const [keyword, setKeyword] = useState<any>({});
  const watchRoute = Form.useWatch('route_id', form);

  const dispatch = useAppDispatch();

  const RouteRules = [
    {
      required: true,
      message: t(Message.ROUTE),
    },
  ];
  const BusTypeRules = [
    {
      required: true,
      message: t('Vui lòng chọn loại xe'),
    },
  ];
  function onSearchRoute(value: any) {
    setKeyword(value);
  }
  function onSearchBusType(value: any) {
    setKeywordBus(value);
  }
  const filterOptionRoute = (
    input: string,
    option?: {
      label: string;
      value: string;
    },
  ) => (option?.label ?? '').toLowerCase()?.includes(input.toLowerCase());
  const filterOptionBus = (
    input: string,
    option?: {
      label?: any;
      value?: any;
      seatMap?: any;
    },
  ) => (option?.label ?? '').toLowerCase()?.includes(input.toLowerCase());

  const BusOptions: SelectProps['options'] = listAllAvailableBusType?.length
    ? listAllAvailableBusType?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
          seatMap: item?.seat_map,
        };
      })
    : [];
  const findColor = (keyIndex: any) => {
    const item = groupSeat?.find((_: any, index: any) => +index === +keyIndex);
    return item ? item?.group_color : null;
  };

  const onChangeDepart: CheckboxProps['onChange'] = (e) => {};
  const onChangeArrive: CheckboxProps['onChange'] = (e) => {};
  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      form.scrollToField(firstErrorField.name);
    }
  };
  function onChangeGroup(values: any, option: any) {
    form.setFieldsValue({ agent_seats: [] });
    form.setFieldsValue({ seat_count: option?.seatMap?.seat_number });
    setSeatRender(option?.seatMap);
    setGroupSeat(option?.seatMap?.group_seat);
    let seatDataOption: any = [];
    let dataOption: any = [];
    option?.seatMap?.group_seat?.map((item: any) => {
      return (seatDataOption = [...seatDataOption, ...item.selected_seat]);
    });
    setSeatData(seatDataOption);
    convertOptionCheckBox(dataOption, [...seatDataOption]?.sort());
    setAllSeatName(dataOption);
    form.setFieldsValue({ agent_seats: seatDataOption });
  }
  const handleChangeRoute = (value: any, option: any) => {
    setRouteActive(option?.routeItem);
    setIsActive(false);
  };
  const onFinish = (values: any) => {};
  useEffect(() => {
    dispatch(getAllAvailableBusType({ keyword: keywordBus }));
  }, [dispatch, keywordBus]);

  useEffect(() => {
    dispatch(fetchListRoute({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllRoute({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllProvinces());
  }, [dispatch]);
  return (
    <Layout className="min-vh-70 segment-booking ">
      <Card>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          labelCol={{ span: 12 }}
          colon={false}
          labelAlign={'left'}
          initialValues={{
            segment: [{}],
            depart_time: dayjs().startOf('day').hour(0).minute(0),
            arrive_time: '00:00',
            is_depart_transship: false,
            is_arrive_transship: false,
            travel_time: '00:00',
          }}
          labelWrap
          onFieldsChange={(allFields) => {
            scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
          }}
        >
          <StepSchedules step={2} />
          <div className="segment-block">
            <div className="mt-4 border-b-segment pb-3">
              <Flex gap={16}>
                <img src={iconLocation} alt="" />
                <div>
                  <Typography.Text className="fw-600 fs-18 title-segment">Thiết lập điểm đón trả</Typography.Text>{' '}
                  <br />
                  <Typography.Text className="fw-500 fs-16" style={{ color: '#4F5662' }}>{`Tuyến: ${
                    listSegment?.depart_point
                  } - ${listSegment?.arrive_point}, Giờ đi: ${dayjs(listSegment?.depart_time)?.format(
                    'hh:mm',
                  )}`}</Typography.Text>
                </div>
              </Flex>
            </div>
            <div className="location-content flex mt-4">
              <div className="location-content-item">
                <div>
                  <Flex gap={12} align="center">
                    <FontAwesomeIcon icon={faCircle} style={{ color: '#F8B407' }} size="xs" />
                    <Typography.Text className="fw-500 fs-16" style={{ color: '#4F5662' }}>
                      {`${listSegment?.depart_point} `}{' '}
                      <span className="fs-16 fw-500" style={{ color: '#E5AA15' }}>{` (4 điểm đón trả)`}</span>
                    </Typography.Text>
                  </Flex>
                </div>
                <img src={iconSetting} alt="" className="cursor-pointer" />
              </div>
              {listSegment?.segment?.map((segment: any, index: number) => (
                <div className="location-content-item">
                  <div>
                    <Flex gap={12} align="center">
                      <FontAwesomeIcon icon={faCircle} style={{ color: '#F8B407' }} size="xs" />
                      <Typography.Text className="fw-500 fs-16" style={{ color: '#4F5662' }}>
                        {`${segment?.name} `}
                        <span className="fs-16 fw-500" style={{ color: '#E5AA15' }}>{` (4 điểm đón trả)`}</span>
                      </Typography.Text>
                    </Flex>
                  </div>
                  <img src={iconSetting} alt="" className="cursor-pointer" />
                </div>
              ))}
              <div className="location-content-item">
                <div>
                  <Flex gap={12} align="center">
                    <FontAwesomeIcon icon={faCircle} style={{ color: '#F8B407' }} size="xs" />
                    <Typography.Text className="fw-500 fs-16" style={{ color: '#4F5662' }}>
                      {`${listSegment?.arrive_point} `}{' '}
                      <span className="fs-16 fw-500" style={{ color: '#E5AA15' }}>{` (4 điểm đón trả)`}</span>
                    </Typography.Text>
                  </Flex>
                </div>
                <img src={iconSetting} alt="" className="cursor-pointer" />
              </div>
            </div>
          </div>
          <SegmentSettingModal
            modalOpen={openModalSegment}
            onCancel={() => {
              setOpenModalSegment(false);
              setIsActive(false);
            }}
            onOk={() => {}}
            routeActive={routeActive}
            isActive={isActive}
          />
        </Form>
      </Card>
    </Layout>
  );
}

export default LocationSetting;
