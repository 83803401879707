import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography,
  Upload,
} from 'antd';
import IcPlus from '../../assets/images/ic-plus.svg';
import './ListService.scss';

import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { maxValidator } from '../../lib/utils';
import { Constant, TabType, USER_GROUP } from '../../config/constant';
import icEdit from '../../assets/images/ic-edit.svg';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Rule } from 'antd/es/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import TextArea from 'antd/es/input/TextArea';
import { fetchListService, updateService } from '../../redux/slice/services/serviceSlice';
import { createService } from '../../redux/slice/services/serviceSlice';
import { updateStatusService } from '../../redux/slice/services/updateService';
import ImgCrop from 'antd-img-crop';
import IcLock from '../../assets/images/ic-lock.svg';
import IcUnLock from '../../assets/images/ic-unlock.svg';
import { ServiceParams } from '../../types';
import { toast } from 'react-toastify';
import TabsFilterStatus from '../../components/TabsFilterStatus/TabsFilterStatus';
import TutorialsVideo from '../../components/TutorialsVideo';
import { handleGetUrlGuide } from '../../utils/helpers';
import icClose from '../../assets/images/listOffice/icClose.svg';

const { Link } = Typography;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function ListService() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [page, setPage] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [iconImg, setIconImg] = useState<UploadFile[]>([]);
  const [iconImgDelete, setIconImgDelete] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statusDetail, setStatusDetail] = useState(false);
  const [idDetail, setIdDetail] = useState('');
  const [isCompany, setIsCompany] = useState(null);
  const accountUser = useAppSelector((state) => state.authAccount?.user);
  const [activeTab, setActiveTab] = useState<TabType>(TabType.ALL);

  const pagination = useAppSelector((state) => state?.listService?.pagination);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const listServices = useAppSelector((state) => state?.listService?.listServices);
  const loadingListServices = useAppSelector((state) => state?.listService?.loading);
  const NameRule: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập tên tiện ích'),
    },
    maxValidator(255, 'Số kí tự vượt quá 255 từ'),
  ];
  const NoteRule: Rule[] = [maxValidator(1000, 'Số kí tự vượt quá 1000 từ')];

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };
  const cancelModal = () => {
    setOpenCreate(false);
    setIsDetail(false);
    form.resetFields();
    setIconImg([]);
    setIconImgDelete('');
  };

  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      form.scrollToField(firstErrorField.name);
    }
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  const handleOkModal = () => {
    setIsModalVisible(false);
    if (statusDetail) {
      setStatusDetail(false);
    } else {
      setStatusDetail(true);
    }
    dispatch(updateStatusService({ id: idDetail, status: statusDetail }));
  };

  const onFieldDetail = (record: any) => {
    if (record?.company || accountUser?.group === USER_GROUP.CARLINK) {
      setIsCompany(record?.company_id);
      setIsDetail(true);
      setStatusDetail(record.status);
      setIdDetail(record.id);
      form.setFieldsValue({
        name: record.name,
        note: record.note,
        image_icon: record?.icon,
      });
      if (record.icon) {
        setIconImg([
          {
            uid: '-1',
            name: 'icon_img',
            status: 'done',
            url: record?.icon,
          },
        ]);
      } else setIconImg([]);
      setOpenCreate(true);
    } else {
      toast.error(' Bạn không có quyền thực hiện chức năng này');
    }
  };
  const onRegister = async (values: any) => {
    values.name = form.getFieldValue('name')?.toString().replace(',', '.');
    values.note = form.getFieldValue('note')?.toString().replace(',', '.');
    values.icon = iconImg[0]?.thumbUrl ? iconImg[0]?.thumbUrl : '';
    if (iconImgDelete) {
      values.icon_delete = iconImgDelete;
    }
    if (isDetail) {
      values.id = idDetail;
      // dispatch(updateService(values));
      const response = await dispatch(updateService({ ...values }));
      if (response?.payload?.success) {
        dispatch(
          fetchListService({
            page,
            limit,
            status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
          }),
        );
        form.resetFields();
        setOpenCreate(false);
        setIconImg([]);
        setIconImgDelete('');
      } else {
        if (response?.payload?.response?.data?.errors) {
          const listErrors = response?.payload?.response?.data?.errors;
          form.setFields(
            Object.keys(listErrors).map((fieldName) => ({
              name: fieldName,
              errors: [listErrors[fieldName]],
            })),
          );
        }
      }
    } else {
      // dispatch(createService(values));
      const response = await dispatch(createService(values));
      if (response?.payload?.success) {
        dispatch(
          fetchListService({
            page,
            limit,
            status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
          }),
        );
        form.resetFields();
        setOpenCreate(false);
        setIconImg([]);
        setIconImgDelete('');
      } else {
        if (response?.payload?.response?.data?.errors) {
          const listErrors = response?.payload?.response?.data?.errors;
          form.setFields(
            Object.keys(listErrors).map((fieldName) => ({
              name: fieldName,
              errors: [listErrors[fieldName]],
            })),
          );
        }
      }
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };
  const handleChangeIconImg: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    form.setFields([
      {
        name: 'bus_license_img',
        value: newFileList,
        touched: true,
        errors: [],
      },
    ]);
    setIconImg(newFileList);
  };
  useEffect(() => {
    dispatch(
      fetchListService({
        page,
        status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
        limit,
      }),
    );
  }, [activeTab, dispatch, page, limit]);
  const columns: ColumnsType<ServiceParams> = [
    {
      title: 'Tên tiện ích',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      render: (_, record) => (
        <Space size="middle">
          {record?.status ? (
            <FontAwesomeIcon icon={faCircle} className="green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="danger" size="xs" />
          )}
          <Typography className="text-color">{record?.name}</Typography>
        </Space>
      ),
    },
    {
      title: 'Biểu tượng',
      width: 130,
      align: 'center',
      render: (_, record) => (
        <Space>{record?.icon ? <Image width={20} height={20} src={record.icon} preview={false} /> : null}</Space>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Tạo bởi',
      dataIndex: 'company',
      key: 'company',
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => {
        if (a?.company) {
          if (b?.company) {
            return 0;
          } else {
            return -1;
          }
        } else {
          if (b?.company) {
            return 1;
          } else {
            return 0;
          }
        }
      },
      render: (company, record: any) => (company ? record?.company?.name : 'Carlink'),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => {
        if (a && a?.created_at && a?.created_at.length && b && b?.created_at && b?.created_at.length) {
          return a?.created_at.localeCompare(b?.created_at);
        } else if (a && a?.created_at && a?.created_at?.length) {
          // that means be has null license_class, so a will come first.
          return -1;
        } else if (b && b?.created_at && b?.created_at?.length) {
          // that means a has null license_class so b will come first.
          return 1;
        }
        // both license_class has null value so there will be no order change.
        return 0;
      },
      width: 150,
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link onClick={() => onFieldDetail(record)}>
            <Image width={17} height={17} className="icon-edit" src={icEdit} preview={false} />
          </Link>
        </Space>
      ),
      align: 'center',
      width: '120px',
    },
  ];
  const onRemove = (file: any) => {
    if (isDetail) {
      const startIndex = file.url.indexOf('/services/icon/');
      if (startIndex !== -1) {
        // Extract the substring starting from the index found using slice
        const extractedString = file.url.slice(startIndex);
        // @ts-ignore
        setIconImgDelete(extractedString);
      }
    }
  };
  const handleChangeTab = (key: TabType) => {
    setActiveTab(key);
    setPage(1);
  };
  // @ts-ignore
  return (
    <Layout className="min-vh-70 ListService">
      <Card>
        <TabsFilterStatus activeTab={activeTab} onChangeTab={handleChangeTab} />
        <Row
          className="fw-500 mt-4 mx-3"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button type="primary" className={'btn-success height-btn-40'} onClick={() => setOpenCreate(true)}>
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t('Thêm tiện ích')}
          </Button>
          <Space size={10} align="center">
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 12)} />
          </Space>
        </Row>
        <Table
          locale={{
            triggerDesc: 'Sắp xếp tăng dần',
            triggerAsc: 'Hủy sắp xếp',
            cancelSort: 'Sắp xếp giảm dần',
          }}
          dataSource={listServices?.length > 0 ? listServices : []}
          rowKey="id"
          rowClassName={(record) => (record?.status ? '' : 'bg-disabled')}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          className="mt-4 mx-3 list-service"
          loading={loadingListServices}
        />
        <Modal
          centered
          title={
            <Row style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
              <Typography.Text className="fw-600 fs-18 ">{t('Tạo tiện ích xe')}</Typography.Text>
              <Space size={10} align="center">
                <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 12)} />
                <Image src={icClose} style={{ cursor: 'pointer' }} preview={false} onClick={cancelModal} />
              </Space>
            </Row>
          }
          className="create-service"
          open={openCreate}
          closeIcon={false}
          maskClosable={false}
          onOk={() => setOpenCreate(false)}
          onCancel={() => cancelModal()}
          styles={{
            body: { maxHeight: '90vh', overflowY: 'auto', overflowX: 'hidden' },
          }}
          width={819}
          footer={[]}
        >
          {/* <Typography.Text className="fw-bold fs-20">
            {t(`Tạo tiện ích`)}
          </Typography.Text> */}

          <Form
            className="mg-16 mt-3"
            form={form}
            labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
            requiredMark={false}
            onFinish={onRegister}
            onFieldsChange={(changedFields, allFields) => {
              scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
            }}
            colon={false}
          >
            <Spin spinning={false} className="mb-5">
              <Row gutter={24} className="mt-4">
                <Col xs={24} sm={24} md={14}>
                  <Form.Item
                    name="name"
                    rules={NameRule}
                    label={t(`Tên tiện ích`)}
                    validateFirst
                    className="required"
                    required={true}
                  >
                    <Input placeholder={t('Nhập tên tiện ích')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} className="mt-4">
                <Col xs={23}>
                  <Typography.Text>{t(`Ghi chú`)}</Typography.Text>
                  <Form.Item name="note" className="field-note" rules={NoteRule}>
                    <TextArea rows={1} placeholder={t('Nhập ghi chú')} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <Typography.Text>{t(`Ảnh biểu tượng (không bắt buộc)`)}</Typography.Text>
                  <Form.Item className="image-icon" name="image_icon">
                    <ImgCrop quality={1} aspect={35 / 35}>
                      <Upload
                        action={`${process.env.REACT_APP_API_URL}/preview`}
                        listType="picture-card"
                        fileList={iconImg}
                        onPreview={handlePreview}
                        onChange={handleChangeIconImg}
                        onRemove={onRemove}
                        className="mt-2"
                      >
                        {iconImg.length >= 1 ? null : (
                          <div>
                            <div style={{ marginTop: 8 }}>
                              <UploadOutlined /> {t('Tải ảnh lên')}
                            </div>
                          </div>
                        )}
                      </Upload>
                    </ImgCrop>
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </Form.Item>
                  <div className="description-icon">{t(`file .png, kích thước 20x20 / 24x24 / 32x32 (pixel)`)}</div>
                </Col>
              </Row>
            </Spin>
            <Row className="ant-modal-footer z-index1200 mt-5">
              {isDetail && (
                <Col span={6} className="d-flex justify-content-start">
                  {statusDetail ? (
                    <Button
                      className="btn-block-red w-180 h-btn-48"
                      danger
                      type="primary"
                      htmlType="button"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Image src={IcLock} preview={false} className="pb-1 pe-2" />
                      {t('Khoá tiện ích')}
                    </Button>
                  ) : (
                    <Button
                      className="btn-active-green w-180 h-btn-48"
                      danger
                      type="primary"
                      htmlType="button"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Image src={IcUnLock} preview={false} className="pb-1 pe-2" />
                      {t('Mở khóa tiện ích')}
                    </Button>
                  )}
                </Col>
              )}
              <Col span={isDetail ? 18 : 24} className="d-flex justify-content-end" style={{ columnGap: '12px' }}>
                <Button key="cancel" className="w-180 h-btn-48" disabled={false} onClick={() => cancelModal()}>
                  {t('Đóng')}
                </Button>
                <Button
                  key="ok"
                  type="primary"
                  className="w-180 h-btn-48 btn-success mr-0"
                  disabled={false}
                  htmlType="submit"
                >
                  {t('Lưu')}
                </Button>
              </Col>
              <Col span={6}>
                <Button key="ok" type="primary" className="d-none w-180 h-btn-48 btn-success"></Button>
              </Col>
            </Row>
          </Form>
        </Modal>

        <ModalConfirm
          modalOpen={isModalVisible}
          okText={statusDetail ? t('Khóa ngay') : t('Mở ngay')}
          cancelText={t('Đóng')}
          modalTitle={statusDetail ? t('Khoá tiện ích') : t('Mở tiện ích')}
          modalContent={
            statusDetail
              ? t('Bạn đang thao tác khóa tiện ích này, bạn có chắc chắn muốn khóa?')
              : t('Bạn đang thao tác mở tiện ích này, bạn có chắc chắn muốn mở?')
          }
          onCancel={handleCancelModal}
          onOk={handleOkModal}
        />
      </Card>
    </Layout>
  );
}

export default ListService;
