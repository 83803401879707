import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { USER_GROUP } from "../../config/constant";
import ReportTotalRouteCompany from "./ReportTotalRouteCompany";
import ReportTotalRouteAgent from "./ReportTotalRouteAgent";

const ReportTotalRoute = () => {
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  if (accountDetail?.group === USER_GROUP.CAR_COMPANY) {
    return <ReportTotalRouteCompany />;
  }
  if (accountDetail?.group === USER_GROUP.AGENT) {
    return <ReportTotalRouteAgent />;
  }
  return <></>;
};

export default ReportTotalRoute;