/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Image,
  Layout,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { ListUserRequest } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Constant, USER_GROUP } from "../../config/constant";
import icEdit from "../../assets/images/ic-edit.svg";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import IcPlus from "../../assets/images/ic-plus.svg";
import {
  fetchListPolicy,
  listPolicySelector,
} from "../../redux/slice/policies/listPolicySlice";
import "../../assets/styles/listPolicy.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import StatusFilter from "../../components/Modal/StatusFilter";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import TypePolicy from "./createPolicy/TypePolicy";
import UpdatePolicy from "./editPolicy/UpdatePolicy";
import TutorialsVideo from "../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../utils/helpers";

function ListPolicy() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination')
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [status, setStatus] = useState<boolean>();
  const [keyword, setKeyword] = useState<ListUserRequest>({});
  const [page, setPage] = useState<number>(1);
  // const [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZE);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [detailPolicy, setDetailPolicy] = useState<any>({});
  const [typePolicy, setTypePolicy] = useState(0);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );

  const { listPolicy, loading, pagination } =
    useAppSelector(listPolicySelector);

  const items: MenuProps["items"] = [
    {
      label: (
        <a onClick={() => handleCheckTypeModal(1)}>
          {t("Chính sách bảo hiểm")}
        </a>
      ),
      key: "1",
    },
    {
      label: (
        <a onClick={() => handleCheckTypeModal(3)}>
          {t("Chính sách thanh toán")}
        </a>
      ),
      key: "2",
    },
    {
      label: (
        <a onClick={() => handleCheckTypeModal(2)}>
          {t("Chính sách hủy vé và hoàn trả")}
        </a>
      ),
      key: "3",
    },
    {
      label: (
        <a onClick={() => handleCheckTypeModal(0)}>{t("Chính sách khác")}</a>
      ),
      key: "4",
    },
  ];

  const menuProps = {
    items,
  };

  const renderRowTable = (record: any) => {
    return record.status ? "" : "bg-disabled";
  };

  const handleCheckTypeModal = (type: number) => {
    setOpenModal(true);
    setTypePolicy(type);
  };

  const handleTypePolicy = (type: any) => {
    let textType = "";
    switch (type) {
      case 1:
        textType = "Chính sách bảo hiểm";
        break;
      case 2:
        textType = "Chính sách hủy vé và hoàn trả";
        break;
      case 3:
        textType = "Chính sách thanh toán";
        break;
      case 0:
        textType = "Chính sách khác";
        break;
      default:
        break;
    }
    return textType;
  };

  const columns: ColumnsType<any> = [
    {
      title: "Tên chính sách",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return (
          <Space size="middle">
            <FontAwesomeIcon
              icon={faCircle}
              className={`${record.status ? "green" : "danger"} p-1`}
              size="xs"
            />
            <Typography className="text-color">{record.name}</Typography>
          </Space>
        );
      },
    },
    {
      title: "Loại chính sách",
      dataIndex: "type",
      key: "type",
      render: (_, record) => {
        return (
          <Typography className="text-color">
            {handleTypePolicy(record?.type)}
          </Typography>
        );
      },
    },
    {
      title: "Tài khoản tạo",
      dataIndex: "policy_source",
      key: "policy_source",
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      width: 140,
      sorter: (a: any, b: any) => a.created_at?.localeCompare(b.created_at),
    },
    {
      title: "Chức năng",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 180,
      render: (_, record) => (
        <>
          <Space
            size="middle"
            onClick={() => showModal(record)}
            style={{ cursor: "pointer" }}
          >
            <Image src={icEdit} preview={false} />
          </Space>
        </>
      ),
    },
  ];

  const showModal = (item: any) => {
    setDetailPolicy(item);
    setOpen(true);
  };

  const handleChangeStatus = (value: boolean | undefined) => {
    setStatus(value);
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const callListPolicyApi = () => {
    dispatch(
      fetchListPolicy({
        ...keyword,
        page,
        status,
        limit,
      })
    );
  };

  useEffect(() => {
    callListPolicyApi();
  }, [dispatch, keyword, page, status, limit]);

  return (
    <Layout className="min-vh-100 wrapper_list_policy">
      <Card>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        {accountDetail?.group !== USER_GROUP.AGENT && (
          <Row
            className="fw-500 mt-4 mx-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Dropdown menu={menuProps} arrow>
              <Button
                size="large"
                type="primary"
                className={"btn-success btn-add"}
              >
                <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
                <Space>
                  {t("Thêm chính sách")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Space
              size={10}
              align="center"
              // style={{ float: "right" }}
            >
              <TutorialsVideo
                url_youtube={handleGetUrlGuide(listTutorials, 14)}
              />
            </Space>
          </Row>
        )}

        <Table
          locale={{
            triggerDesc: "Sắp xếp tăng dần",
            triggerAsc: "Hủy sắp xếp",
            cancelSort: "Sắp xếp giảm dần",
          }}
          bordered={false}
          loading={loading}
          rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={listPolicy?.length > 0 ? listPolicy : []}
          className="mx-3 mt-3"
        />
      </Card>
      <>
        {open ? (
          <UpdatePolicy
            callListPolicyApi={callListPolicyApi}
            setOpen={setOpen}
            open={open}
            detailPolicy={detailPolicy}
            detailUser={accountDetail}
            listTutorials={listTutorials}
          />
        ) : (
          <></>
        )}
      </>
      {openModal ? (
        <TypePolicy
          listTutorials={listTutorials}
          callListPolicyApi={callListPolicyApi}
          setOpen={setOpenModal}
          open={openModal}
          typePolicy={typePolicy}
          detailUser={accountDetail}
        />
      ) : (
        <></>
      )}
    </Layout>
  );
}

export default ListPolicy;
