import {Button, Result} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';

function NotFound() {
    const navigate = useNavigate();
    const redirectHome = () => {
        navigate(`/`);
    };
    return (
        <Result
            status='404'
            title='404'
            subTitle='Trang không khả dụng'
            extra={
                <Button type='primary' onClick={redirectHome}>
                    Trở lại
                </Button>
            }
        />
    );
}

export default NotFound;
