import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGeneralAgentApi } from "../../../api/generalAgent";

// First, create the thunk
export const fetchGeneralAgent = createAsyncThunk(
  "generalAgent/fetchGeneralAgent",
  async ({ query }: any) => {
    try {
      const response = await getGeneralAgentApi(query);
      return response.data;
    } catch (error) {
      
    }
  }
);

const initialState: any = {
  isFetching: true,
  result: {},
  total: "0"
};

export const generalAgentSlide = createSlice({
  name: "generalAgent",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchGeneralAgent.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(fetchGeneralAgent.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(fetchGeneralAgent.fulfilled, (state, action) => {
      state.isFetching = false;
      state.result = action.payload?.data;   
    });
  },
});

export const {} = generalAgentSlide.actions;

export default generalAgentSlide.reducer;
