import { validateNoSpecialChars, validatePhone2 } from "./validators";

export const validateNameOffice = [
  { required: true, message: "Vui lòng nhập tên văn phòng" },
  { min: 2, message: "Tên văn phòng phải có ít nhất 2 ký tự" },
  { validator: validateNoSpecialChars("Họ tên") },
];

export const validateProvinceOffice = [
  { required: true, message: "Vui lòng nhập tỉnh/ thành phố" },
];

export const validateDistrictOffice = [
  { required: true, message: "Vui lòng nhập quận/ huyện" },
];
export const validateWardOffice = [
  { required: true, message: "Vui lòng nhập phường/ xã" },
];

export const validateUserManageOffice = [
  { required: true, message: "Vui lòng nhập người quản lý" },
];

export const validateEmailOffice: any = [
  { required: true, message: "Vui lòng nhập email" },
  { type: "email", message: "Email không đúng định dạng" },
];

export const validateAddressOffice = [
  { required: true, message: "Vui lòng nhập địa chỉ" },
];

export const validateNoteOffice = [
  {
    required: false,
    message: "Vui lòng nhập ghi chú",
  },
];

const phoneValidator = ({ getFieldValue }: any) => ({
  validator(_: any, value: any) {
    // Kiểm tra nếu trường không rỗng thì mới validate
    if (getFieldValue("phone")) {
      return validatePhone2(_, value);
    } else {
      return Promise.resolve();
    }
  },
});

export const phoneRulesOffice = [
  { required: false, message: "Vui lòng nhập số điện thoại" },
  phoneValidator,
];
