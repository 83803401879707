import { BorderOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Input, Row, Space, Tooltip, Typography } from 'antd';
import { MenuProps } from 'antd/lib';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/desktop/typeBus.scss';
import SeatMapRender from '../../components/SeatMapRender';
import { Constant } from '../../config/constant';
const ModalGroupSeat = ({ record, tmpDataSeatMatrix, setTmpDataSeatMatrix, formTypeBus,group }: any) => {
  const { t } = useTranslation();
  const [isChooseGroup, setIsChooseGroup] = useState<number>(1);
  const [groupNameInput, setGroupNameInput] = useState<string>('');
  const [keyIndexList, setKeyIndexList] = useState<number>(0);
  const [checkboxValues, setCheckboxValues] = useState<string[]>([]);
  const [allCheckboxesHidden, setAllCheckboxesHidden] = useState(false);
  const [disableChooseSeat, setDisableChooseSeat] = useState(false);
  const selectedFieldKey = useRef<number>(0);
  const currentValues = Form.useWatch('group_seat', formTypeBus);
  const [keyIndexGroup, setKeyIndexGroup] = useState<number>(0);
  const dataSeatMap = Form.useWatch('seat_json');
  const [disableAddGroup, setDisableAddGroup] = useState(false);

  const items: MenuProps['items'] = [
    {
      label: <BorderOutlined className="input-group-color" style={{ background: '#FF6666' }} />,
      key: Constant.RED_SEAT,
    },
    {
      label: <BorderOutlined className="input-group-color" style={{ background: '#EA8C00' }} />,
      key: Constant.ORANGE_SEAT,
    },
    {
      label: <BorderOutlined className="input-group-color" style={{ background: '#E5C100' }} />,
      key: Constant.YELLOW_SEAT,
    },
    {
      label: <BorderOutlined className="input-group-color" style={{ background: '#009EF8' }} />,
      key: Constant.BLUE_SEAT,
    },
    {
      label: <BorderOutlined className="input-group-color" style={{ background: '#2DAD00' }} />,
      key: Constant.GREEN_SEAT,
    },
    {
      label: <BorderOutlined className="input-group-color" style={{ background: '#B946FF' }} />,
      key: Constant.PURPLE_SEAT,
    },
    {
      label: <BorderOutlined className="input-group-color" style={{ background: '#95561D' }} />,
      key: Constant.BROWN_SEAT,
    },
  ];

  const handleChooseColor: MenuProps['onClick'] = ({ key }) => {
    const currentValues = formTypeBus.getFieldValue('group_seat') || [];
    if (currentValues[selectedFieldKey.current]) {
      currentValues[selectedFieldKey.current] = {
        ...currentValues[selectedFieldKey.current],
        group_color: key,
      };
      const newDataSeatMap = { ...dataSeatMap, seat_map: { ...dataSeatMap.seat_map, group_seat: currentValues } };
      formTypeBus.setFieldsValue({ group_seat: currentValues, seat_json: newDataSeatMap });
    }
  };
  const handleActiveGroup = (value: number) => {
    setIsChooseGroup(value);
    const currentValues = formTypeBus.getFieldValue('group_seat') || [];
    currentValues[keyIndexGroup] = {
      name: currentValues['name_group'],
      selected_seat: checkboxValues,
    };
    currentValues['name_group'] = '';
    setKeyIndexGroup(keyIndexGroup + 1);
    const newDataSeatMap = { ...dataSeatMap };
    newDataSeatMap.seat_map.group_seat = currentValues;
    formTypeBus.setFieldsValue({ group: currentValues, seat_json: newDataSeatMap });
    setGroupNameInput('');
    setIsChooseGroup(1);
    setTmpDataSeatMatrix((prevMatrix: any) => {
      const newMatrix = { ...prevMatrix };
      ['floor1', 'floor2']?.forEach((floor) => {
        if (newMatrix?.seat_map?.[floor]) {
          newMatrix.seat_map[floor] = newMatrix.seat_map[floor].map((row: any) =>
            row.map((seat: any) => {
              if (checkboxValues.includes(seat.name)) {
                return {
                  ...seat,
                  selected: false,
                };
              }
              return seat;
            }),
          );
        }
      });
      return newMatrix;
    });
    setCheckboxValues([]);
  };
  const handleBtnChooseSeat = (value: number) => {
    setIsChooseGroup(value);
  };
  const handleActiveChoose = (value: number) => {
    setIsChooseGroup(value);
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupNameInput(e.target.value);
  };

  const handleCheckboxChange = (rowIndex: number, colIndex: number, selectedSeatNames: string[]) => {
    setCheckboxValues(selectedSeatNames);
  };

  useEffect(() => {
    const selectedSeats = currentValues?.flatMap((group: any) => group?.selected_seat || []);
    const allSeats = [
      ...(tmpDataSeatMatrix?.seat_map?.['floor1'] || []),
      ...(tmpDataSeatMatrix?.seat_map?.['floor2'] || []),
    ]
      .flat(2)
      .filter((seat: any) => seat.name && seat.type !== 1 && seat.type !== 2);
    let allSeatsSelected = false;
    if (allSeats.length > 0) {
      allSeatsSelected = allSeats.every((seat: any) => seat.name && selectedSeats?.includes(seat.name));
    }
    setDisableChooseSeat(allSeatsSelected);
    if (allSeats.length === 0) {
      setDisableChooseSeat(true);
    }
  }, [tmpDataSeatMatrix, currentValues]);
  useEffect(() => {
    if (checkboxValues.length > 0) {
      setDisableAddGroup(false);
    } else {
      setDisableAddGroup(true);
    }
  }, [checkboxValues, disableAddGroup]);

  useEffect(() => {
    if (tmpDataSeatMatrix && tmpDataSeatMatrix.seat_map) {
      tmpDataSeatMatrix.group_seat = currentValues;
    }
    if (currentValues?.length) {
      setKeyIndexGroup(currentValues.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpDataSeatMatrix, formTypeBus]);

  useEffect(() => {
    if (record?.seat_map?.group_seat?.length > 0) {
      setKeyIndexGroup(record.seat_map.group_seat.length);
    } else {
      setKeyIndexGroup(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.seat_map?.group_seat?.length]);
  useEffect(() => {
    if (isChooseGroup === 1) {
      setTmpDataSeatMatrix((prevMatrix: any) => {
        const newMatrix = { ...prevMatrix };
        ['floor1', 'floor2']?.forEach((floor) => {
          if (newMatrix?.seat_map?.[floor]) {
            newMatrix.seat_map[floor] = newMatrix.seat_map[floor].map((row: any) =>
              row.map((seat: any) => {
                if (checkboxValues.includes(seat.name)) {
                  return {
                    ...seat,
                    selected: false,
                  };
                }
                return seat;
              }),
            );
          }
        });
        return newMatrix;
      });
    }
  }, [isChooseGroup]);

  const countFloor = Form.useWatch('count_floor', formTypeBus);
  if (tmpDataSeatMatrix?.seat_map) {
    tmpDataSeatMatrix.seat_map.count_floor = countFloor;
  }
  return (
    <>
      <Row className="seat-form-item group" style={{ columnGap: '20px' }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item className="d-none" name="seat_type">
          <Input />
        </Form.Item>
          <Form.List name="group_seat">
            {(fields, { remove }) => (
              <>
                <Row gutter={18} key={`group-key`}>
                  <Col xs={8} sm={8} md={6}>
                    <Form.Item name="name_group" label={t(`Tên nhóm ghế`)} className="require">
                      <Input placeholder={t('Nhập tên nhóm ghế')} onChange={handleNameChange} disabled={(record?.creator === 'carlink' && group!=='carlink' )} />
                    </Form.Item>
                  </Col>

                  <Col xs={10} sm={10} md={10} className="mt-35">
                    {isChooseGroup === 1 && (
                      <Button
                        className="btn-choose-seat pl-1"
                        disabled={groupNameInput === '' || disableChooseSeat}
                        onClick={() => {
                          handleActiveChoose(2);
                        }}
                      >
                        Chọn ghế
                      </Button>
                    )}

                    {(isChooseGroup === 2 || isChooseGroup === 3) && (
                      <Space>
                        <Button className="btn-choose-seat" onClick={() => handleBtnChooseSeat(1)}>
                          Hủy
                        </Button>
                        <Button
                          className="btn-choose-seat group"
                          disabled={disableAddGroup}
                          onClick={() => handleActiveGroup(3)}
                        >
                          Thêm nhóm ghế
                        </Button>
                      </Space>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={6}>
                    <Typography.Text className="group-seat-name">{t(`Nhóm ghế`)}</Typography.Text>
                  </Col>
                  <Col xs={8} sm={8} md={6} className="pl-5">
                    <Typography.Text className="group-seat-name">{t(`Ghế đã chọn`)}</Typography.Text>
                  </Col>
                </Row>

                {fields.map(({ key, name, ...restField }, index) => {
                  const findColor = () => {
                    const item = currentValues?.find((_: any, ind: any) => +ind === +index);
                    return item?.group_color ?? null;
                  };

                  return (
                    <Row
                      gutter={18}
                      className="mt-1"
                      key={key}
                      onClick={() => {
                        selectedFieldKey.current = index;
                      }}
                    >
                      <Col xs={8} sm={8} md={6}>
                        <span className={`color-block ${findColor()} `}></span>
                        <Form.Item {...restField} name={[name, 'name']} className="group-input color" required>
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={8} sm={8} md={12}>
                        <Form.Item
                          {...restField}
                          name={[name, 'selected_seat']}
                          className={` ${!isChooseGroup ? 'btn-group-color' : ''} group-input`}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={8} sm={8} md={4} style={{ minWidth: '210px' }}>
                        <Form.Item {...restField} name={[name, 'group_color']}>
                          <Dropdown
                            menu={{ items, onClick: handleChooseColor }}
                            trigger={['click']}
                            overlayClassName={'dropdown-choose-color'}
                            key={`${key}-color`}
                            disabled={!currentValues?.[index]?.selected_seat?.length || (record?.creator === 'carlink' && group!=='carlink' ) || record?.id}
                          >
                            <Button
                              className={`btn-choose-seat w-100 ${!isChooseGroup ? 'btn-group-color' : ''}`}
                              onClick={(e) => e.preventDefault()}
                              disabled={true}
                            >
                              Chọn màu nhóm ghế
                            </Button>
                          </Dropdown>
                        </Form.Item>
                      </Col>
                      {!record?.id && (
                        <Tooltip title="Xóa nhóm ghế">
                          <div className="pl-1">
                            <Button
                              type="text"
                              onClick={() => {
                                remove(name);
                                setKeyIndexGroup(keyIndexGroup > 0 ? keyIndexGroup - 1 : keyIndexGroup);
                                if (fields.length === 1) {
                                  setKeyIndexList(0);
                                } else {
                                  setKeyIndexList(keyIndexList - 1);
                                }
                              }}
                              className={`btn-group-seat btn-delete-group `}
                            >
                              <DeleteOutlined style={{ color: '#D81717' }} />
                            </Button>
                          </div>
                        </Tooltip>
                      )}
                    </Row>
                  );
                })}
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <SeatMapRender
        column={dataSeatMap?.seat_map?.column ?? 0}
        row={dataSeatMap?.seat_map?.row ?? 0}
        seatMatrixData={tmpDataSeatMatrix?.seat_map}
        isChooseSeat={isChooseGroup}
        onCheckboxChange={handleCheckboxChange}
        formTypeBus={formTypeBus}
        setAllCheckboxesHidden={setAllCheckboxesHidden}
        allCheckboxesHidden={allCheckboxesHidden}
        isSelectedAllSeat={true}
      />
    </>
  );
};

export default ModalGroupSeat;
