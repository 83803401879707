import { Layout, Spin } from "antd";
import classNames from "classnames";

type Props = {
    force?: boolean;
};

const Loader = ({ force }: Props) => {
    return (
        <Layout.Content style={{zIndex:4,bottom:'50px',left:'50px'}} className={classNames('h-100 w-100 position-fixed d-flex justify-content-center align-items-center', {'d-none': !force})}>
            <Spin spinning size="large"/>
        </Layout.Content>
    );
};

export default Loader;
