import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {Constant} from "../../../config/constant";
import {getErrorMessage} from "../../../api";
import {ListTripState, TripRequest} from "../../../types/trip";
import {getListTripAPI} from "../../../api/trips";


// @ts-ignore
export const fetchListTrip = createAsyncThunk('admin/Trip', async (params?: TripRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getListTripAPI(params ?? undefined);
        const {
            data,
            status
        } = response;
        if (status === Constant.DEFAULT_STATUS) {
            dispatch(setListTrip({trips: data.data, pagination: data.pagination, status: status}));
            return true;
        }
    } catch (error: any) {
        const {
            status
        } = error.response;
        dispatch(setListTrip({status: status}));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});
export const listTripSlice = createSlice({
    name: 'ListTrip',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        trips: [],
        pagination: {
            total: 0,
            per_page: Constant.PAGE_SIZE,
            current_page: Constant.DEFAULT_PAGE,
            last_page: Constant.DEFAULT_PAGE,
        },
    } as ListTripState,
    reducers: {
        setListTrip: (state: ListTripState, {payload}) => {
            const {trips, pagination, status} = payload;
            state.trips = trips;
            state.pagination = pagination;
            state.status = status;
        },
        resetListTrip: (state: ListTripState) => {
            state.trips = [];
            state.status = Constant.DEFAULT_STATUS;
        },

        // setServices: (state: ListTripState, {payload}) => {
        //     const {service} = payload;
        //     let data: never[] = [];
        //     convertOption(data, service)
        //     state.ListTrips = data;
        // },
        // setListTripLoad: (state: ListTripState) => {
        //     state.services = [];
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchListTrip.pending, (state: ListTripState) => {
            state.loading = true;
        });
        builder.addCase(fetchListTrip.fulfilled, (state: ListTripState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(fetchListTrip.rejected, (state: ListTripState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
    },
});

export const listTripSelector = (state: RootState) => state.listTrip;
export const {setListTrip} = listTripSlice.actions;
