import {
  Button,
  ConfigProvider,
  DatePicker,
  Flex,
  Input,
  Modal,
  Select,
  Table,
  TableProps,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import iconClose from "../../../assets/images/listUser/iconsClose.svg";
import { IconExportExcel } from "../../../components/Icons";
import "../styles/ModalHistoryTicketAll.scss";
import iconSearch from "../../../assets/images/icons/icon-search.svg";
import TextDisplay from "../../../components/Display/TextDisplay";
import { formatPrice } from "../../../utils/helpers";
import moment from "moment";
import {
  getListStaffAPI,
  historyTicketAPI,
  historyOrderExcelAPI,
} from "../api";
import dayjs from "dayjs";
import { isArray } from "lodash";
import { HistoryParams } from "../../../types/order";
import { toast } from "react-toastify";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import ExcelJS from "exceljs";
import { columnsHistory, handleGetCancelReason } from "../models/constants";
import { useAppSelector } from "../../../redux/hooks";

const { RangePicker } = DatePicker;

interface ModalHistoryTicketAllProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalHistoryTicketAll = ({
  open,
  setOpen,
}: ModalHistoryTicketAllProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>();
  const [staffID, setStaffID] = useState<number>();
  const [dates, setDates] = useState<any>([]);
  const [data, setData] = useState<HistoryParams[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [listStaff, setListStaff] = useState<any[]>([]);

  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return (
          Math.abs(current.diff(from, "days")) >=
          systemConfig?.export_report_month
        );
      }
      return false;
    },
    [systemConfig?.export_report_month]
  );

  const onChangeKeyword = (event: any) => {
    setKeyword(event.target.value);
    setPage(1);
  };

  const onChangeStaffID = (value: number) => {
    setStaffID(value);
    setPage(1);
  };

  const onChangeRange = (dates: any, dateStrings: [string, string]) => {
    setDates(dates);
    setPage(1);
  };

  const handleGetListStaff = useCallback(async () => {
    try {
      const res = await getListStaffAPI();
      if (res?.status === 200) {
        if (isArray(res?.data?.data)) {
          setListStaff(res?.data?.data);
        } else {
          setListStaff(res?.data?.data?.data);
        }
      }
    } catch (error) {}
  }, []);

  const handleGetHistory = useCallback(async () => {
    setLoading(true);
    const params = {
      keyword: keyword ? keyword : "",
      staff_id: staffID ? staffID : undefined,
      from: dates?.[0] ? dayjs(dates?.[0]).format("YYYY-MM-DD") : "",
      to: dates?.[1] ? dayjs(dates?.[1]).format("YYYY-MM-DD") : "",
      page: page,
      is_full: "1",
    };
    try {
      const res = await historyTicketAPI(params);
      if (res?.status === 200) {
        if (isArray(res?.data?.data)) {
          setData(res?.data?.data);
        } else {
          setData(res?.data?.data?.data);
        }
        setTotal(res?.data?.pagination?.total);
        setPage(res?.data?.pagination?.current_page);
        setPageSize(res?.data?.pagination?.per_page);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra vui lòng thử lại!");
    }
  }, [dates, keyword, staffID, page]);

  useEffect(() => {
    if (open) {
      handleGetListStaff();
    }
  }, [handleGetListStaff, open]);

  useEffect(() => {
    if (open) {
      handleGetHistory();
    }
  }, [handleGetHistory, open]);

  const columns: TableProps<HistoryParams>["columns"] = [
    {
      title: "Thời gian",
      dataIndex: "booking_code",
      render: (order_date, record) => (
        <Flex className="flex-column gap-y-4">
          <p className="mb-0 text-color-default fs-16 fw-500">
            {moment(record?.order_date).format("DD/MM/YYYY")}
          </p>
          <p className="mb-0 text-color-gray-7B7B7B fw-500">
            {moment(record?.order_time, "HH:mm:ss").format("HH:mm:ss")}
          </p>
        </Flex>
      ),
    },
    {
      title: "Nhân viên",
      dataIndex: "staff",
      render: (staff) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={staff} />
        </Typography.Text>
      ),
    },
    {
      title: "Thao tác",
      dataIndex: "booking_code",
      render: (booking_code, record) => {
        switch (record?.status) {
          case "cancel":
            return (
              <Flex className="flex-column gap-y-4">
                <Flex className="gap-x-8 fs-16 fw-500" align="center">
                  <p className="text-danger mb-0">Hủy vé</p>
                  <p className="mb-0 text-color-default">
                    - {record?.ticket_code} - Hoàn trả:{" "}
                    {formatPrice(record?.refund, 0, ",")}đ - Phí hủy:{" "}
                    {formatPrice(record?.cancel_fee, 0, ",")}đ
                  </p>
                </Flex>
                <p className="mb-0 text-color-gray-7B7B7B fw-500">
                  Thuộc đơn hàng: {record?.booking_code} -{" "}
                  {handleGetCancelReason(record?.cancel_reason, record?.staff)}{" "}
                  - Giá vé: {formatPrice(record?.ticket_price_cancel, 0, ",")}đ
                </p>
              </Flex>
            );
          case "paid":
            return (
              <Flex className="flex-column gap-y-4">
                <Flex align="center" className="fs-16 fw-500 mb-0 gap-x-4">
                  <p className="text-color-green-default mb-0">
                    Xuất vé
                    <span className="text-color-default">
                      {" "}
                      - {record?.ticket_code} - Giá vé:{" "}
                      {formatPrice(record?.ticket_price_paid, 0, ",")}đ
                    </span>
                  </p>
                </Flex>
                <p className="mb-0 text-color-gray-7B7B7B fw-500">
                  Tiền bảo hiểm: {formatPrice(record?.insurance, 0, ",")}đ -
                  Thuộc đơn hàng: {record?.booking_code}
                </p>
              </Flex>
            );
          case "reserve":
            return (
              <Flex className="flex-column gap-y-4">
                <Flex align="center" className="fs-16 fw-500 mb-0 gap-x-4">
                  <p className="text-time-out mb-0">
                    Giữ chỗ
                    <span className="text-color-default">
                      {" "}
                      - {record?.ticket_code} - Giá vé:{" "}
                      {formatPrice(record?.ticket_price_reserve, 0, ",")}đ
                    </span>
                  </p>
                </Flex>
                <p className="mb-0 text-color-gray-7B7B7B fw-500">
                  Thuộc đơn hàng: {record?.booking_code}
                </p>
              </Flex>
            );
        }
      },
    },
  ];

  const handleExportExcel = async () => {
    setLoadingExport(true);
    const params = {
      keyword: keyword ? keyword : "",
      staff_id: staffID ? staffID : undefined,
      from: dates?.[0] ? dayjs(dates?.[0]).format("YYYY-MM-DD") : "",
      to: dates?.[1] ? dayjs(dates?.[1]).format("YYYY-MM-DD") : "",
      page: page,
      is_full: "1",
    };
    try {
      const res = await historyOrderExcelAPI(params);
      if (res?.status === 200) {
        if (isArray(res?.data?.data)) {
          const list = res?.data?.data;
          const listData =
            list?.length > 0
              ? list?.map((history: HistoryParams) => {
                  let actionText = "";
                  switch (history?.status) {
                    case "cancel":
                      actionText = `Hủy vé - ${
                        history?.ticket_code
                      } - Hoàn trả: ${formatPrice(
                        history?.refund,
                        0,
                        ","
                      )}đ - Phí hủy: ${formatPrice(
                        history?.cancel_fee,
                        0,
                        ","
                      )}đ - Thuộc đơn hàng: ${
                        history?.booking_code
                      } - ${`${handleGetCancelReason(
                        history?.cancel_reason,
                        history?.staff
                      )} - `}Giá vé: ${formatPrice(
                        history?.ticket_price_cancel,
                        0,
                        ","
                      )}đ`;
                      break;
                    case "paid":
                      actionText = `Xuất vé - ${
                        history?.ticket_code
                      } - Giá vé: ${formatPrice(
                        history?.ticket_price_paid,
                        0,
                        ","
                      )}đ - Thuộc đơn hàng: ${
                        history?.booking_code
                      } - Tiền bảo hiểm: ${formatPrice(
                        history?.insurance,
                        0,
                        ","
                      )}đ`;
                      break;
                    case "reserve":
                      actionText = `Giữ chỗ - ${
                        history?.ticket_code
                      } - Giá vé: ${formatPrice(
                        history?.ticket_price_reserve,
                        0,
                        ","
                      )}đ - Thuộc đơn hàng: ${history?.booking_code}`;
                      break;
                  }
                  return {
                    time: history?.order_date
                      ? `${moment(history?.order_date).format(
                          "DD/MM/YYYY"
                        )} - ${moment(history?.order_time, "HH:mm:ss").format(
                          "HH:mm:ss"
                        )}`
                      : "N/A",
                    staff: history?.staff ? history?.staff : "N/A",
                    action: actionText,
                  };
                })
              : [];
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Lịch sử thao tác vé");
          const header = columnsHistory.map((col) => ({
            header: col.title,
            key: col.dataIndex as string,
          }));
          worksheet.columns = header;
          columnsHistory.forEach((col, index) => {
            worksheet.getColumn(index + 1).width = col.width || 15;
            worksheet.getColumn(index + 1).alignment = {
              horizontal:
                (col?.__style__?.h as
                  | "center"
                  | "left"
                  | "right"
                  | "fill"
                  | "justify") || "left",
              vertical: "middle",
            };
            worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || "general";
          });
          worksheet.addRows(listData);
          worksheet.getRow(1).font = {
            name: "Times New Roman",
            size: 12,
            bold: true,
          };
          worksheet.getRow(1).eachCell((cell, colNumber) => {
            if (colNumber < 4) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "3AB54A" },
              };
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          });
          let firstRowHasData = false;
          worksheet.getRow(1).eachCell((cell, colNumber) => {
            if (cell.value) {
              firstRowHasData = true;
              return;
            }
          });
          if (firstRowHasData) {
            worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
              if (rowNumber > 1) {
                row.eachCell((cell) => {
                  cell.font = { name: "Times New Roman", size: 12 };
                  cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                  };
                });
              }
            });
          } else {
            worksheet.eachRow((row, rowNumber) => {
              if (rowNumber > 1) {
                let hasData = false;
                row.eachCell((cell) => {
                  if (cell.value) {
                    hasData = true;
                    return;
                  }
                });
                if (hasData) {
                  row.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                  };
                }
              }
            });
          }

          const lastRow = worksheet.addRow([]);
          lastRow.getCell(1).value = "@Bản quyền thuộc nhà xe Carlink";
          lastRow.getCell(1).alignment = {
            vertical: "middle",
            horizontal: "left",
          };
          lastRow.getCell(1).font = {
            name: "Times New Roman",
            size: 14,
            bold: true,
          };
          const endColumnIndex = 0 + columnsHistory.length;
          worksheet.mergeCells(
            lastRow.number,
            1,
            lastRow.number,
            endColumnIndex
          );

          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Lịch sử thao tác vé.xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      }
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
      toast.error("Có lỗi xảy ra vui lòng thử lại!");
    }
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  return (
    <Modal
      rootClassName="ModalHistoryAll"
      closeIcon={false}
      footer={false}
      maskClosable={false}
      width={1000}
      open={open}
    >
      <div>
        <Flex className="mb-12" align="center" justify="space-between">
          <p className="ModalHistoryAll__title mb-0">Lịch sử thao tác vé</p>
          <Flex
            className="ModalHistoryAll__close"
            onClick={() => {
              setOpen(false);
              setPage(1);
            }}
            align="center"
            justify="center"
          >
            <img
              className="object-fit-scale"
              width={24}
              height={24}
              src={iconClose}
              alt=""
            />
          </Flex>
        </Flex>
        <Flex className="gap-x-8 mb-24" align="center">
          <Flex className="ModalHistoryAll__form">
            <div className="ModalHistoryAll__form__wrapper">
              <Input
                value={keyword}
                onChange={onChangeKeyword}
                placeholder="Nhập mã vé"
                suffix={
                  <img
                    className="object-fit-scale"
                    width={16}
                    height={16}
                    src={iconSearch}
                    alt=""
                  />
                }
              />
              <Select
                placeholder="Nhân viên"
                value={staffID}
                allowClear
                options={
                  listStaff?.length > 0
                    ? [
                        { value: "", label: "Tất cả" },
                        ...listStaff?.map((staff) => ({
                          value: staff?.id,
                          label: staff?.name,
                        })),
                      ]
                    : [{ value: "", label: "Tất cả" }]
                }
                onChange={onChangeStaffID}
              />
              <RangePicker
                format="DD/MM/YYYY"
                placement="bottomRight"
                onChange={onChangeRange}
                value={dates}
                disabledDate={disabledDaysDate}
                placeholder={["Từ ngày", "Đến ngày"]}
              />
            </div>
          </Flex>
          <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
            <Button
              loading={loadingExport}
              onClick={handleExportExcel}
              ghost
              className="ModalHistoryAll__exportExcel d-flex align-items-center h-auto"
              type="primary"
            >
              <IconExportExcel color={"#435869"} width={16} height={16} />
              Xuất Excel
            </Button>
          </ConfigProvider>
        </Flex>
        <Table
          dataSource={data}
          loading={loading}
          className="ModalHistoryTicketAll__table"
          columns={columns}
          pagination={{
            total: total,
            current: page,
            pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: false,
          }}
        />
      </div>
    </Modal>
  );
};

export default ModalHistoryTicketAll;
