import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { getApiKeyApiAPI, listApiAPI, listFollowAPI } from '../../../api/followApi';
import { FollowAPIParams, FollowState } from '../../../types/follow';
import { RootState } from '../../store';

// @ts-ignore
export const getListFollowAPI = createAsyncThunk(
  'admin/log-all',
  async (params: FollowAPIParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await listFollowAPI(params);
      const { data } = response;
      dispatch(setListFollow(data));
      return true;
    } catch (error: any) {
      // dispatch(setListFollow(error));
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const getListAPI = createAsyncThunk(
  'admin/all-api',
  async (params: { keyword: string; type: string; status?: number,page?:number, limit?:number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await listApiAPI(params);
      const { data } = response;
      dispatch(setListApi(data));
      return true;
    } catch (error: any) {
      // dispatch(setListApi(error));
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const getGenerateCodeApi = createAsyncThunk(
  'admin/generate-code-api',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getApiKeyApiAPI();
      const { data } = response;
      dispatch(setGenerateCode(data));
      return true;
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const listFollowApiSlice = createSlice({
  name: 'followAPI',
  initialState: {
    error: false,
    loading: false,
    success: false,
    listFollow: [],
    listApi: [],
    loadingDetail: false,
    generateCode: '',
    pagination: [],
    paginationApi: [],
    status: 0,
    message: '',
    errorDetail: null,
  } as FollowState,
  reducers: {
    setListFollow: (state: FollowState, { payload }) => {
      state.listFollow = payload?.data;
      state.pagination = payload?.pagination;
    },
    setListApi: (state: FollowState, { payload }) => {
      state.listApi = payload?.data;
      state.paginationApi = payload?.pagination;
    },
    setGenerateCode: (state: FollowState, { payload }) => {
      state.generateCode = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListFollowAPI.pending, (state: FollowState) => {
      state.loading = true;
    });
    builder.addCase(getListFollowAPI.fulfilled, (state: FollowState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListFollowAPI.rejected, (state: FollowState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getListAPI.pending, (state: FollowState) => {
      state.loading = true;
    });
    builder.addCase(getListAPI.fulfilled, (state: FollowState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListAPI.rejected, (state: FollowState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const listFollowApiSelector = (state: RootState) => state.listFollowApi;
export const { setListFollow, setListApi, setGenerateCode } = listFollowApiSlice.actions;
