import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {BusParams, ListBusState} from "../../../types";
import {updateBusAPI, updateStatusBusAPI} from "../../../api/bus";
import {getErrorMessage} from "../../../api";
import {setToast} from "../toastNotifySlice";
import {Toast} from "../../../config/constant";
import {Action, UPDATE_ERROR, UPDATE_SUCCESS} from "../../../config/message";


// @ts-ignore
export const updateBus = createAsyncThunk('admin/update/bus', async (params: BusParams, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateBusAPI(params);
        const {
            data,
            status
        } = response;
        if (status)
            dispatch(setUpdate({status: status, data: data}));
        dispatch(setToast({
            message: UPDATE_SUCCESS(Action.BUS),
            status: true,
            type: Toast.TYPE_SUCCESS,
        }))
        return true;
    } catch (error: any) {
        const {response} = error;
        dispatch(setUpdateError(response));
        dispatch(setToast({
            message: UPDATE_ERROR(Action.BUS),
            status: true,
            type: Toast.TYPE_ERROR,
        }))
        return rejectWithValue(getErrorMessage(error));
    }
});

export const updateStatusBus = createAsyncThunk('admin/status/bus', async (params: { id: string, status: boolean }, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateStatusBusAPI(params);
        const {
            data,
            status
        } = response;
        if (status) {
            dispatch(setUpdate({status: status, data: data}));
            dispatch(setToast({
                message: UPDATE_SUCCESS(Action.BUS),
                status: true,
                type: Toast.TYPE_SUCCESS,
            }))
        }
        return true;
    } catch (error: any) {
        const {response} = error;
        dispatch(setUpdateError(response));
        dispatch(setToast({
            message: UPDATE_ERROR(Action.BUS),
            status: true,
            type: Toast.TYPE_ERROR,
        }))
        return rejectWithValue(getErrorMessage(error));
    }
});
export const updateBusSlice = createSlice({
    name: 'updateBus',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        message: '',
        errorDetail: null,
    } as ListBusState,
    reducers: {
        setUpdate: (state: ListBusState, {payload}) => {
            const {message} = payload.data;
            state.status = payload?.status;
            state.message = message;
        },
        setUpdateError: (state: ListBusState, {payload}) => {
            const {errors} = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetUpdateBus: (state: ListBusState) => {
            state.success = false;
            state.status = 0;
            state.message = '';
            state.errorDetail = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateBus.pending, (state: ListBusState) => {
            state.loading = true;
        });
        builder.addCase(updateBus.fulfilled, (state: ListBusState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateBus.rejected, (state: ListBusState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });

        builder.addCase(updateStatusBus.pending, (state: ListBusState) => {
            state.loading = true;
        });
        builder.addCase(updateStatusBus.fulfilled, (state: ListBusState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateStatusBus.rejected, (state: ListBusState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });
    },
});

export const updateBusSelector = (state: RootState) => state.updateBus;
export const {setUpdate, setUpdateError, resetUpdateBus} = updateBusSlice.actions;
