import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Button, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/slice/authSlice';
import { DocumentContract } from './DocumentContract';

const ModalPrintContract: React.FC<any> = ({ okText, cancelText, modalOpen, onCancel, onOk, listContractDetail }) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const { accountUser } = useAppSelector(selectAuth);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      width={1000}
      className="modal-print"
      maskClosable={false}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PDFViewer height={700} style={{ width: '100%' }}>
          <DocumentContract listContractDetail={listContractDetail} accountUser={accountUser} />
        </PDFViewer>
      </div>
      <Row className="d-flex justify-content-end mt-3" style={{ paddingBottom: '16px' }}>
        <Button key="cancel" className=" w-160 h-btn-48 me-3 btn-back agent" onClick={() => modalHandleCancel()}>
          Trở lại
        </Button>
        <PDFDownloadLink
          document={<DocumentContract listContractDetail={listContractDetail} accountUser={accountUser} />}
          fileName={`Chi tiết hợp đồng`}
        >
          <Button
            key="ok"
            type="primary"
            className="w-160 h-btn-48 btn-success"
            htmlType="submit"
            onClick={() => modalHandleOk()}
          >
            Tải xuống
          </Button>
        </PDFDownloadLink>
      </Row>
    </Modal>
  );
};

export default ModalPrintContract;
