import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { getErrorMessage } from '../../../api';
import { setToast } from '../toastNotifySlice';
import { Constant, Toast } from '../../../config/constant';
import { Action, CREATE_ERROR, CREATE_SUCCESS, DUPLICATE_ERROR, DUPLICATE_SUCCESS } from '../../../config/message';
import { DuplicateScheduleParams, ListScheduleState, ScheduleParams } from '../../../types/schedule';
import { createScheduleAPI, duplicateScheduleApi } from '../../../api/schedules';

// @ts-ignore
export const createSchedule = createAsyncThunk(
  'admin/create/schedule',
  async (params: ScheduleParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await createScheduleAPI(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
        dispatch(
          setToast({
            message: CREATE_SUCCESS(Action.SCHEDULE),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: CREATE_ERROR(Action.SCHEDULE),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const duplicateSchedule = createAsyncThunk(
  'admin/duplicate/schedule',
  async (params: DuplicateScheduleParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await duplicateScheduleApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setDuplicate({ status: status, data: data }));
        dispatch(
          setToast({
            message: DUPLICATE_SUCCESS(Action.SCHEDULE),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: DUPLICATE_ERROR(Action.SCHEDULE),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const createScheduleSlice = createSlice({
  name: 'createSchedule',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    statusDuplicate: 0,
    schedules: [],
    pagination: {
      total: 0,
      per_page: Constant.PAGE_SIZE,
      current_page: Constant.DEFAULT_PAGE,
      last_page: Constant.DEFAULT_PAGE,
    },
  } as ListScheduleState,
  reducers: {
    setCreate: (state: ListScheduleState, { payload }) => {
      state.status = payload?.status;
    },
    setDuplicate: (state: ListScheduleState, { payload }) => {
      state.statusDuplicate = payload?.status;
    },
    setCreateError: (state: ListScheduleState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetRegisterCreateSchedule: (state: ListScheduleState) => {
      state.success = false;
      state.status = 0;
      state.message = '';
      state.errorDetail = null;
      state.statusDuplicate = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSchedule.pending, (state: ListScheduleState) => {
      state.loading = true;
    });
    builder.addCase(createSchedule.fulfilled, (state: ListScheduleState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(createSchedule.rejected, (state: ListScheduleState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
    builder.addCase(duplicateSchedule.pending, (state: ListScheduleState) => {
      state.loading = true;
    });
    builder.addCase(duplicateSchedule.fulfilled, (state: ListScheduleState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(duplicateSchedule.rejected, (state: ListScheduleState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const createScheduleSelector = (state: RootState) => state.createSchedule;
export const { setCreate, setCreateError, resetRegisterCreateSchedule, setDuplicate } = createScheduleSlice.actions;
