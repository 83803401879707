import { validateNoNumber, validateNoSpecialChars, validateNoStartingNumber, validatePhone } from "./validators"

export const validateUsername = [
    {
      required: true,
      message: "Vui lòng nhập tên truy cập",
    },
    { min: 4, message: "Tên truy cập phải có ít nhất 4 ký tự" },
    {
      pattern: /^[a-zA-Z0-9]+$/,
      message: "Tên truy cập không dấu và ký tự đặc biệt!",
    },
    {
      whitespace: false,
      message: "Tên truy cập không được chứa khoảng trắng",
    },
  ]

  export const passwordValidator = (_:any, value:any) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{6,12}$/;
    if (!regex.test(value)) {
      return Promise.reject(new Error('Mật khẩu yêu cầu từ 6-12 ký tự ( trong đó có 1 chữ viết hoa, 1 số, 1 kí tự đặc biệt)'));
    }
    return Promise.resolve();
  };

  export const validateStrongPassword = [
    { required: true, message: "Vui lòng nhập mật khẩu" },
    { validator: passwordValidator }
  ]


  export const validatePasswordUser = [
    { required: true, message: "Vui lòng nhập mật khẩu" },
    { min: 6, message: "Mật khẩu phải có ít nhất 6 ký tự" },
  ]


  const validatePasswordConfirmation = ({ getFieldValue }: any) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Nhập lại password không khớp"));
    },
  });

  export const validateStrongPasswordConfirm = [
    { required: true, message: "Vui lòng nhập mật khẩu" },
    { validator: passwordValidator },
    validatePasswordConfirmation,
  ]
  
export const passwordConfirmationRules = [
    {
      required: true,
      message: "Vui lòng nhập lại mật khẩu",
    },
    {
      min: 6,
      message: "Mật khẩu phải có ít nhất 6 ký tự",
    },
    validatePasswordConfirmation,
  ];


  export const validateFirstNameUser = [
    { required: true, message: "Vui lòng nhập họ và tên" },
    { min: 2, message: "Họ tên phải có ít nhất 2 ký tự" },
    { validator: validateNoNumber("Họ tên") },
    { validator: validateNoSpecialChars("Họ tên") },
  ]

  export const validateLastNameUser = [
    { required: true, message: "Vui lòng nhập tên" },
    { min: 2, message: "Tên phải có ít nhất 2 ký tự" },
    { validator: validateNoNumber("Tên") },
    { validator: validateNoSpecialChars("Tên") },
  ]

  export const validateNameUser = [
    {
      required: true,
      message: "Vui lòng nhập tên hiển thị",

    },
    { min: 6, message: "Tên hiển thị phải có ít nhất 6 ký tự" },
    { validator: validateNoStartingNumber("Tên hiển thị") },
    { validator: validateNoSpecialChars("Tên hiển thị") },
  ]

  export const validateGenderUser: any = [
    { required: false, message: "Vui lòng nhập giới tính" },
    {
      type: "enum",
      enum: [0, 1, 2],
      message: "Giới tính không hợp lệ",
    },
  ]

  export const validateMobileUser = [
    {
      required: true,
      message: "Vui lòng nhập số điện thoại",
    },
    { validator: validatePhone },
  ]