import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Đăng ký dùng thử": "Sign up for a trial",
      "title": "test"
    }
  },
  vi: {
    translation: {
      'title' : {
        'index' : 'đ'
      },
      "title1": "Chuyển đ213123123123g!"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "vi", 
    fallbackLng: 'vi',
    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;
