import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {CreateUserState, UserParams} from "../../types";
import {createUserAPI} from "../../api/user";
import {getErrorMessage} from "../../api";


// @ts-ignore

// List user
export const createUser = createAsyncThunk('admin/user/create', async (params: UserParams, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await createUserAPI(params);
        const {
            data,
            success,
        } = response?.data;
        // if (success) {
        //     dispatch(setListUser({users: data.data, pagination: data.meta}));
        //     return true;
        // }
    } catch (error: any) {
        // dispatch(setListUser(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const userSlice = createSlice({
    name: 'listUser',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        user: {
            id: null,
            name: null,
            username: null,
            email: null,
            first_name: null,
            last_name: null,
            sex: null,
            birth: null,
            address: null,
            mobile: null,
            phone: null,
            status: null,
            group: null,
            note: null,
            fax: null,
            avatar: null,
            whatapp: null,
            skype: null,
            company: null,
            agent: null,
        },
    } as CreateUserState,
    reducers: {
        setInfoUser: (state: CreateUserState, {payload}) => {
            state.user = payload;
        },
    },
    extraReducers: (builder) => {
        // List User
        builder.addCase(createUser.pending, (state: CreateUserState) => {
            state.loading = true;
        });
        builder.addCase(createUser.fulfilled, (state: CreateUserState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(createUser.rejected, (state: CreateUserState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
    },
});

export const userSelector = (state: RootState) => state.user;
export const {setInfoUser} = userSlice.actions;
