import {
  Col,
  Form,
  Input,
  Row,
  Modal,
  Divider,
  Space,
  Image,
  Button,
  message,
  Upload,
  Typography,
} from "antd";
import "../../../assets/styles/createOffice.scss";
import ReactQuill from "react-quill";
import { createPolicyAPI } from "../../../api/policies";
import { checkAuthorization } from "../../../api";
import { moduleReactQuill } from "../../../config/reactQuill";
import { useEffect, useState } from "react";
import IcPlus from "../../../assets/images/policy/plus.svg";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import { ACTION_NAME, messageCreateSuccess } from "../../../config/message";
import { Constant } from "../../../config/constant";
import {
  validateContentPolicy,
  validateNamePolicy,
  validatePricePolicy,
  validateTimePolicy,
} from "../../../config/validators/validatePolicy";
import IcInfoWarning from "../../../assets/images/policy/info-warning.svg";
import TutorialsVideo from "../../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../../utils/helpers";
import icClose from "../../../assets/images/listOffice/icClose.svg";

type FileType = Parameters<any>[0];

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const TypePolicy = ({
  setOpen,
  open,
  callListPolicyApi,
  typePolicy,
  listTutorials,
}: any) => {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]); // Khởi tạo fileList rỗng
  const [listFields, setListFields] = useState(0);
  // value insurance
  const [valueInsurance, setValueInsurance] = useState(false);
  const handleCancelImage = () => setPreviewOpen(false);
  const { t } = useTranslation();

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // Giữ chỉ một tệp trong fileList
    setFileList(newFileList.slice(-1));
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish = async (values: any) => {
    if (typePolicy === 3) {
      values.qr_code_img = fileList[0]?.thumbUrl;
    }
    try {
      const res = await createPolicyAPI({ type: typePolicy, ...values });
      if (res.status === Constant.DEFAULT_STATUS) {
        callListPolicyApi();
        messageCreateSuccess(ACTION_NAME.POLiCY);
        setOpen(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  useEffect(() => {
    setListFields((prev) => prev + 1);
  }, []);

  const styles = {
    color: "#3AB54A",
    fontWeight: 600,
    cursor: "pointer",
  };

  const stylesCursor = {
    cursor: "pointer",
    margin: "0 5px",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#7B7B7B",
  };

  const validateInsurancePolicy: any = [
    {
      required: true,
      message: "Vui lòng nhập giá trị bảo hiểm",
    },
    valueInsurance && {
      validator: (_: any, value: any) => {
        if (value && (isNaN(value) || value > 100)) {
          return Promise.reject(new Error("Giá trị không được vượt quá 100"));
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <>
      <Modal
        title={
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 15,
            }}
          >
            <Typography.Text className="fw-600 fs-18 ">
              {t("Thêm chính sách nhà xe")}
            </Typography.Text>
            <Space size={10} align="center">
              <TutorialsVideo
                url_youtube={handleGetUrlGuide(listTutorials, 14)}
              />
              <Image
                src={icClose}
                style={{ cursor: "pointer" }}
                preview={false}
                onClick={handleCancel}
              />
            </Space>
          </Row>
        }
        styles={{
          body: { height: '580px', overflowY: 'auto', overflowX: 'hidden' },
        }}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu"
        maskClosable={false}
        closeIcon={false}
        cancelText="Đóng"
        width={834}
        className="modal-create-policy modal-create-office scroll-custom-modal"
        footer={(_, { OkBtn, CancelBtn }: any) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
            className="footer-edit-modal-office"
          >
            <div className="modal-btn-user d-flex gap-12">
              <CancelBtn className="modal-cancel-btn" />
              <OkBtn style={{ width: 140 }} />
            </div>
          </div>
        )}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="form-create-office"
        >
          <Row gutter={16}>
            <Col span={11}>
              <Form.Item
                name="name"
                label={t("Tên chính sách")}
                rules={validateNamePolicy}
                className="required"
              >
                <Input placeholder={t("Nhập tên chính sách")} />
              </Form.Item>
            </Col>
            {typePolicy === 1 && (
              <Col span={11}>
                <Form.Item
                  name={
                    valueInsurance
                      ? "insurance_value_percent"
                      : "insurance_value_price"
                  }
                  label={t("Giá trị bảo hiểm")}
                  className="required mb-0"
                  rules={validateInsurancePolicy}
                >
                  <Input
                    className="policy-input-fare"
                    type="number"
                    addonAfter={
                      <Space size={12}>
                        <Divider type="vertical" style={{ marginRight: 4 }} />
                        <span
                          style={valueInsurance ? stylesCursor : styles}
                          onClick={() => setValueInsurance(false)}
                        >
                          {t("VND")}
                        </span>
                        <span
                          style={valueInsurance ? styles : stylesCursor}
                          onClick={() => setValueInsurance(true)}
                        >
                          {t("% theo giá vé")}
                        </span>
                      </Space>
                    }
                  />
                </Form.Item>
                <Space size={4} className="info-warning-policy">
                  {" "}
                  <Image src={IcInfoWarning} alt="logo" preview={false} />
                  {t(
                    "Giá trị bảo hiểm được tính cho 1 hành khách trên 1 chặng"
                  )}
                </Space>
              </Col>
            )}
          </Row>
          {typePolicy === 2 && (
            <Form.List name="cancel_refund_value">
              {(fields, { add, remove }) => {
                setListFields(fields?.length);
                return (
                  <>
                    {fields?.map(({ key, name, ...restField }) => (
                      <Row gutter={16}>
                        <Col span={9}>
                          <Form.Item
                            {...restField}
                            name={[name, "before_departure_hour"]}
                            label={t("Trước giờ khởi hành")}
                            className="required"
                            rules={validateTimePolicy}
                          >
                            <Input
                              className="policy-input-fare"
                              type="number"
                              addonAfter={
                                <Space size={12}>
                                  <Divider
                                    type="vertical"
                                    style={{ marginRight: 4 }}
                                  />
                                  <span style={stylesCursor}>Giờ</span>
                                </Space>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "cancel_fee_percent"]}
                            label={t("Phí hủy")}
                            className="required"
                            rules={validatePricePolicy}
                          >
                            <Input
                              className="policy-input-fare"
                              type="number"
                              addonAfter={
                                <Space size={12}>
                                  <Divider type="vertical" />
                                  <span style={stylesCursor}>%</span>
                                </Space>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col className="btn-delete-timeline">
                          <Button
                            type="default"
                            icon={<CloseOutlined />}
                            onClick={() => {
                              if (fields?.length === 1) {
                                message.warning(
                                  "Không được phép xóa trường cuối cùng"
                                );
                              } else {
                                remove(name);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    {fields?.length === 0 && listFields && add()}
                    {fields?.length < 3 && (
                      <Space className="add-a-timeline" onClick={() => add()}>
                        Thêm mốc thời gian
                        <Image src={IcPlus} alt="logo" preview={false} />
                      </Space>
                    )}
                  </>
                );
              }}
            </Form.List>
          )}

          {typePolicy === 3 && (
            <Row>
              <Form.Item name="qr_code_img" label="Mã QR thanh toán">
                <ImgCrop quality={1} aspect={35 / 35}>
                  <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList?.length === 0 && (
                      <div>
                        <div style={{ marginTop: 8 }}>
                          <UploadOutlined /> {t("Tải ảnh lên")}
                        </div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancelImage}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </Form.Item>
            </Row>
          )}

          <Row gutter={16} style={{maxWidth:'99%'}}>
            <Col span={24}>
              <Form.Item
                name="content"
                label={t("Nội dung chính sách")}
                className="required"
                rules={validateContentPolicy}
              >
                <ReactQuill theme="snow" modules={moduleReactQuill} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default TypePolicy;
