import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Constant } from '../../config/constant';

function StatusFilterBooking({
  status,
  onChangeStatus,
  isContract,
  setPage
}: {
  status: any;
  onChangeStatus: Function;
  isContract: boolean | undefined;
  setPage?:any
}) {
  const { t } = useTranslation();

  return (
    <Row gutter={8} className="fw-500">
      <Col>
        <Button
          type="text"
          className={`fw-500 ${status === undefined ? 'btn-status-active-green' : 'text-color'}`}
          onClick={() => {
            onChangeStatus(undefined);
          }}
        >
          {t('Tất cả')}
        </Button>
      </Col>
      <Col className="border-start border-end">
        <Button
          type="text"
          className={`fw-500 ${
            status === Constant.CUSTOMER_HOLD || status === Constant.STATUS_NOT_COMPLETE
              ? 'btn-status-active-green'
              : 'text-color'
          }`}
          onClick={() => {
            onChangeStatus(isContract ? Constant.STATUS_NOT_COMPLETE : Constant.CUSTOMER_HOLD);
            setPage(1)
          }}
        >
          {isContract ? t('Chưa hoàn thành') : t('Đang giữ chỗ')}
        </Button>
      </Col>
      <Col>
        <Button
          type="text"
          className={`fw-500 ${status === Constant.CUSTOMER_PAID ||status === Constant.STATUS_COMPLETED ? 'btn-status-active-green' : 'text-color'}`}
          onClick={() => {
            onChangeStatus(isContract ? Constant.STATUS_COMPLETED : Constant.CUSTOMER_PAID);
            setPage(1)
          }}
        >
          {isContract ? t('Đã hoàn thành') : t('Đã thanh toán')}
        </Button>
      </Col>
      <Col>
        <Button
          type="text"
          className={`fw-500 ${status === Constant.CUSTOMER_CANCEL || status === Constant.STATUS_CANCELED ? 'btn-status-lock-red' : 'text-color'}`}
          onClick={() => {
            onChangeStatus(isContract ? Constant.STATUS_CANCELED : Constant.CUSTOMER_CANCEL);
            setPage(1)
          }}
        >
          {t('Đã hủy')}
        </Button>
      </Col>
    </Row>
  );
}

export default StatusFilterBooking;
