/* eslint-disable react-hooks/exhaustive-deps */
import { Layout } from "antd";
import DepositCompany from "./DepositCompany";
import DepositCarlink from "./DepositCarlink";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import { fetchAccount } from "../../redux/slice/auth/accountSlice";
import { USER_GROUP } from "../../config/constant";
function AccountDeposit() {
  const accountUser = useAppSelector((state) => state.authAccount?.user);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchAccount());
  }, []);

  const handleCheckAccountGroup = () => {
    if (accountUser?.group === USER_GROUP.CAR_COMPANY) {
      return <DepositCompany />;
    }
    if (accountUser?.group === USER_GROUP.CARLINK) {
      return <DepositCarlink />;
    }
    return <></>;
  };
  return (
    <Layout className="wrapper-account-deposit">
      {handleCheckAccountGroup()}
    </Layout>
  );
}

export default AccountDeposit;
