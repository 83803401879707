import axios from "axios";

export const getListRegisterApi = (query: any) => {
  return axios.get(
    `${process.env.REACT_APP_CMS_API_URL}/pages/demoregistration?${query}`,
    {
      headers: {
        "X-API-KEY": process.env.REACT_APP_X_API_KEY,
      },
    }
  );
};

export const putInfoRegisterApi = (id: number, valueBody: any) => {
  return axios.put(
    `${process.env.REACT_APP_CMS_API_URL}/pages/demoregistration/${id}`,
    {
      ...valueBody,
    },
    {
      headers: {
        "X-API-KEY": process.env.REACT_APP_X_API_KEY,
      },
    }
  );
};

export const getCountRegistrations = () => {
  return axios.get(`${process.env.REACT_APP_CMS_API_URL}/count-registrations`);
};
