import { HistoryOutlined, MailOutlined, PrinterOutlined, StopOutlined } from '@ant-design/icons';
import { Font } from '@react-pdf/renderer';
import { Button, Card, Col, Form, FormInstance, Modal, Row, Spin, Statistic, Tabs, Typography } from 'antd';
import { CountdownProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/desktop/busBooking.scss';
import HeaderBooking from '../../components/HeaderBooking';
import ModalCancelTicket from '../../components/Modal/ModalCancelTicket';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant, Toast, USER_GROUP, YearFormat } from '../../config/constant';
import { convertTimeFormat, formattedPrice } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getListAgentActive } from '../../redux/slice/agents/listAgentSlice';
import { fetchAccount } from '../../redux/slice/auth/accountSlice';
import { selectAuth, setReloadDetail } from '../../redux/slice/authSlice';
import {
  cancelBooking,
  cancelBookingSelector,
  resetCancelBooking,
} from '../../redux/slice/bookings/cancelBookingSlice';
import {
  createBooking,
  createBookingSelector,
  filterBookingCustomer,
  getPriceBooking,
  resetBooking,
  resetListCustomers,
  resetPinCode,
  setBookingForAgentId,
} from '../../redux/slice/bookings/createBookingSlice';
import { destroyBooking } from '../../redux/slice/bookings/destroyBookingSlice';
import {
  holdingBookingSelector,
  resetDataBooking,
  resetHoldingBooking,
} from '../../redux/slice/bookings/holdingBookingSlice';
import {
  getBookingInfo,
  getListFilterBooking,
  listBookingSelector,
  resetDataMoveTicket,
  resetListBookingDetail,
} from '../../redux/slice/bookings/listBooking';
import { moveTicketSelector, postUpdatePickUp, resetMoveTicket } from '../../redux/slice/bookings/moveTicketSlice';
import {
  getSendBookingMail,
  resetUpdateBooking,
  updateBookingSelector,
} from '../../redux/slice/bookings/updateBooking';
import { setToast } from '../../redux/slice/toastNotifySlice';
import { handleGetUrlGuide } from '../../utils/helpers';
import ModalBooking1 from './ModalBooking1';
import ModalBooking2 from './ModalBooking2';
import ModalHistoryBooking from './ModalHistoryBooking';
import ModalPrintTicket from './tickets/ModalPrintTicket';
Font.register({
  family: 'Inter',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter (web)/Inter-Light.woff', fontWeight: 300 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter (web)/Inter-Regular.woff', fontWeight: 400 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter (web)/Inter-Medium.woff', fontWeight: 500 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter (web)/Inter-Bold.woff', fontWeight: 600 },
  ],
});
const ModalBooking: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  listBooking,
  formSearch,
  isDetail,
  listBookingDetail,
  loading,
  targetTime,
  setTargetTicket,
  isMulti,
  isBooking,
  isListTicket,
  dataCustomerBooking,
  isDepart,
  isChangeTicket,
  setIsChangeTicket,
}) => {
  const { t } = useTranslation();
  const {
    status: statusCreateUpdate,
    price,
    loading: loadingCreate,
    errorDetail: errorCreate,
    booking_for_agent_id,
    statusPin,
    message: messageCreate,
    pin_code,
  } = useAppSelector(createBookingSelector);
  const { status: statusHoldingUpdate, dataBooking, loading: loadingHold } = useAppSelector(holdingBookingSelector);
  const { dataMoveTicket } = useAppSelector(moveTicketSelector);
  const {
    dataMoveTicket: dataMoveTicketDiagram,
    isMoveTicket,
    loadingListBookingDetail,
  } = useAppSelector(listBookingSelector);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const [openModalNumpad, setOpenModalNumpad] = useState<boolean>(false);
  const { group, name } = useAppSelector(selectAuth);
  const { status: statusCancel } = useAppSelector(cancelBookingSelector);
  const { loadingSend } = useAppSelector(updateBookingSelector);
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const { Countdown } = Statistic;
  const [isOpenModalCancelTicket, setIsOpenModalCancelTicket] = useState(false);
  const [formBooking]: [FormInstance] = Form.useForm();
  const dispatch = useAppDispatch();
  const isInSurance = Form.useWatch('is_insurance', formBooking);
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [keywordCustomer, setKeywordCustomer] = useState<any>();
  const [openModalPrint, setOpenModalPrint] = useState(false);
  const [activeKey, setActiveKey] = useState<string>('1');
  const [countdownFinished, setCountdownFinished] = useState(false);
  const isSendMail = Form.useWatch('send', formBooking);
  const totalPrice = Form.useWatch('total_price', formBooking);
  const commissionPrice = Form.useWatch('commission_price', formBooking);
  const payment = Form.useWatch('payment_method', formBooking);
  const reasonCancel = Form.useWatch('reason_cancel', formBooking);
  const provinceId = Form.useWatch('route_id', formSearch);
  const pickUpValue = Form.useWatch('pickup_location_id', formBooking);
  const dropOffValue = Form.useWatch('drop_off_location_id', formBooking);
  const seatNumber = Form.useWatch('name_seat', formBooking);
  const searchDateBooking = dayjs(Form.useWatch('date_booking', formSearch)).format(YearFormat);
  const searchDepartTime = Form.useWatch('depart_time', formSearch);
  const isAgentId = Form.useWatch('is_agent_id', formBooking);
  const agentId = Form.useWatch('agent_id', formBooking);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };
  const showTab = (key: string) => {
    setActiveKey(key);
  };
  const modalHandleCancel = () => {
    if (!isDetail && statusCreateUpdate !== Constant.DEFAULT_STATUS && dataBooking?.ticket_code) {
      dispatch(
        destroyBooking({
          ticket_code: [dataBooking?.ticket_code],
        }),
      );
    }
    setOpenModalNumpad(false);
    formBooking.resetFields();
    dispatch(resetListBookingDetail());
    onCancel();
    setKeywordCustomer('');
    showTab('1');
    dispatch(resetBooking());
    dispatch(resetDataBooking());
    setCountdownFinished(false);
    dispatch(resetListCustomers());
    if (dataMoveTicketDiagram?.ticket_code || isMoveTicket) {
      dispatch(resetDataMoveTicket());
      dispatch(resetMoveTicket());
    }
  };

  const onChange: CountdownProps['onChange'] = (val) => {
    if (typeof val === 'number' && val <= 0) {
    }
  };
  const onFinishCountdown: CountdownProps['onFinish'] = () => {
    setTargetTicket(undefined);
    setCountdownFinished(true);
  };

  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      formBooking.scrollToField(firstErrorField.name);
    }
  };
  const handleCancelTicket = () => {
    setIsOpenModalCancelTicket(false);
  };

  const onCancelSuccess = useCallback(() => {
    onCancel();
    dispatch(resetListBookingDetail());
    handleCancelTicket();
    setKeywordCustomer('');
    showTab('1');
    dispatch(resetUpdateBooking());
    dispatch(resetBooking());
    dispatch(resetCancelBooking());
    setOpenModalNumpad(false);
    formBooking.resetFields();
    if (!isMulti || isDetail) {
      dispatch(
        getBookingInfo({
          route_id: provinceId,
          depart_date: searchDateBooking,
          depart_time: convertTimeFormat(searchDepartTime),
        }),
      );
    }
    dispatch(getListFilterBooking({ depart_date: searchDateBooking }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formSearch, provinceId, searchDateBooking, searchDepartTime]);
  const handleOkeTicket = () => {
    if (formBooking.getFieldValue('reason_cancel')) {
      const cancelBookingData = {
        ticket_code: formBooking.getFieldValue('ticket_code'),
        seat_number: seatNumber,
        cancel_reason: formBooking.getFieldValue('reason_cancel'),
        cancel_note: formBooking.getFieldValue('reason_note'),
        is_send_mail: isSendMail?.length > 0 ? isSendMail.includes(1) : false,
        is_diagram:
          isListTicket && dataCustomerBooking ? dataCustomerBooking?.is_diagram : listBooking?.schedule?.is_diagram,
      };
      dispatch(cancelBooking(cancelBookingData));
    } else {
      dispatch(
        setToast({
          message: 'Vui lòng nhập lý do hủy',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    }
  };
  const onFinish = (values: any) => {
    if (isMoveTicket && dataMoveTicket?.ticket_code) {
      if (
        ((pickUpValue && pickUpValue !== 'other') ||
          (pickUpValue === 'other' && values.pickup_extra && values.pickup_extra?.trim().length >= 3)) &&
        ((dropOffValue && dropOffValue !== 'other') ||
          (dropOffValue === 'other' && values.drop_off_extra?.trim().length >= 3))
      ) {
        const dataNewPickUp = {
          pickup_location_id: values.pickup_location_id === 'other' ? null : values.pickup_location_id,
          drop_off_location_id: values.drop_off_location_id === 'other' ? null : values.drop_off_location_id,
          pickup_extra: pickUpValue === 'other' ? values.pickup_extra : undefined,
          drop_off_extra: dropOffValue === 'other' ? values.drop_off_extra : undefined,
          old_ticket_code: dataMoveTicket?.ticket_code,
          depart_date: listBooking?.travel_info?.depart_date,
          schedule_id: listBooking?.schedule?.id,
          depart_time: listBooking?.schedule?.depart_time,
          seat_number: dataMoveTicket?.seat_number,
          note: listBookingDetail?.note,
        };
        dispatch(postUpdatePickUp(dataNewPickUp));
      }
    } else {
      if (listBookingDetail?.source === USER_GROUP.CARLINK) {
        formBooking.setFieldValue('payment_method', 'paid_at_company');
      }
      if (
        (listBookingDetail?.source === USER_GROUP.CARLINK ||
          (listBookingDetail?.source !== USER_GROUP.CARLINK && payment)) &&
        ((pickUpValue && pickUpValue !== 'other') ||
          (pickUpValue === 'other' && values.pickup_extra && values.pickup_extra?.trim().length >= 3)) &&
        ((dropOffValue && dropOffValue !== 'other') ||
          (dropOffValue === 'other' && values.drop_off_extra?.trim().length >= 3))
      ) {
        values.total_price = values.total_price ? Number(values.total_price.toString().replace(/,/g, '')) : undefined;
        const dataCreateBooking = {
          seat_number: isDetail ? listBookingDetail?.seat_number : dataBooking?.seat_number,
          booking_type: isMulti ? Constant.BOOKING_MULTI : Constant.BOOKING_SINGLE,
          ticket_code: isDetail ? values.ticket_code : dataBooking?.ticket_code,
          customer_name: values.customer_name,
          customer_phone: values.customer_phone,
          is_diagram:
            isListTicket && dataCustomerBooking ? dataCustomerBooking?.is_diagram : listBooking?.schedule?.is_diagram,
          customer_email: values.customer_email,
          pickup_location_id: values.pickup_location_id === 'other' ? null : values.pickup_location_id,
          drop_off_location_id: values.drop_off_location_id === 'other' ? null : values.drop_off_location_id,
          pickup_extra: pickUpValue === 'other' ? values.pickup_extra : undefined,
          drop_off_extra: dropOffValue === 'other' ? values.drop_off_extra : undefined,
          payment_method: values.payment_method,
          is_insurance: values.is_insurance ? values.is_insurance : false,
          note: values.note,
          depart_date:
            !listBookingDetail?.schedule?.is_diagram || (dataCustomerBooking && dataCustomerBooking?.is_diagram)
              ? searchDateBooking
              : undefined,
          schedule_id:
            listBookingDetail?.schedule?.is_diagram || dataCustomerBooking?.is_diagram
              ? undefined
              : listBookingDetail?.schedule_id,
          contact_phone: values.contact_phone,
          contact_name: values.contact_name,
          contact_email: values.contact_email,
          contact_note: values.contact_note,
          booking_for_agent: values.is_agent_id ? values.is_agent_id : false,
          agent_id: isAgentId ? values.agent_id : undefined,
          price_change: pin_code ? values.total_price : undefined,
          pin_code: pin_code ? pin_code : undefined,
        };
        dispatch(setBookingForAgentId(values.agent_id));
        dispatch(createBooking(dataCreateBooking));
      } else {
        if (!payment) {
          dispatch(
            setToast({
              message: 'Vui lòng nhập phương thức thanh toán',
              status: true,
              type: Toast.TYPE_ERROR,
            }),
          );
        }
        if (
          (values.pickup_location_id === 'other' && !values.pickup_extra?.trim()) ||
          (values.drop_off_location_id === 'other' && !values.drop_off_extra?.trim())
        ) {
          dispatch(
            setToast({
              message: 'Vui lòng nhập điểm trung chuyển khác',
              status: true,
              type: Toast.TYPE_ERROR,
            }),
          );
        }
        if (!dropOffValue || !pickUpValue) {
          dispatch(
            setToast({
              message: 'Vui lòng nhập điểm đi và điểm đến',
              status: true,
              type: Toast.TYPE_ERROR,
            }),
          );
        }
      }
    }
  };

  const cancelModalHistory = () => {
    setOpenModalHistory(false);
  };

  const cancelModalPrint = () => {
    dispatch(setReloadDetail());
    setOpenModalPrint(false);
  };
  useEffect(() => {
    if (dataBooking && !isDetail) {
      if (listBooking?.schedule?.is_diagram) {
        formBooking.setFieldsValue({
          name_seat: dataBooking?.seat_number,
          ticket_code: dataBooking?.ticket_code,
        });
      } else {
        formBooking.setFieldsValue({
          ticket_code: dataBooking?.ticket_code,
        });
      }
    }
    if (
      !isDetail &&
      !formBooking.getFieldValue('total_price') &&
      !listBookingDetail?.is_move &&
      !(listBookingDetail?.source === 'openapi' && listBookingDetail?.source === 'company')
    ) {
      formBooking.setFieldsValue({
        total_price: listBookingDetail?.price,
      });
    }
  }, [dataBooking, formBooking, isInSurance, isDetail, listBookingDetail, price, listBooking, dataCustomerBooking]);

  useEffect(() => {
    if (
      (reasonCancel === 'car_company' || reasonCancel === 'agent') &&
      isDetail &&
      commissionPrice &&
      listBookingDetail?.status !== 'reserve'
    ) {
      formBooking.setFieldValue('refund', commissionPrice);
      formBooking.setFieldValue('cancel_fee', 0);
    }
    if (
      reasonCancel === 'customer' &&
      isDetail &&
      commissionPrice &&
      listBookingDetail?.status !== 'reserve' &&
      listBookingDetail?.status !== 'paid at company'
    ) {
      formBooking.setFieldValue('refund', listBookingDetail?.refund);
      formBooking?.setFieldValue('cancel_fee', listBookingDetail?.cancel_fee);
    }
    if (isDetail && listBookingDetail?.status === 'reserve') {
      formBooking.setFieldValue('refund', listBookingDetail?.refund);
      formBooking.setFieldValue('cancel_fee', listBookingDetail?.cancel_fee);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetail, reasonCancel, totalPrice, listBookingDetail]);

  useEffect(() => {
    if (statusHoldingUpdate === Constant.DEFAULT_STATUS) {
      dispatch(resetHoldingBooking());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusHoldingUpdate]);
  useEffect(() => {
    if (statusCreateUpdate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
      dispatch(fetchAccount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateUpdate]);

  useEffect(() => {
    if (statusCancel === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
      dispatch(fetchAccount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCancel]);

  useEffect(() => {
    if (isDetail) {
      formBooking.setFieldsValue({
        name_seat: dataMoveTicket?.seat_number
          ? dataMoveTicket?.seat_number
          : isListTicket
          ? listBookingDetail?.seat_number
          : listBooking?.schedule?.is_diagram
          ? listBookingDetail?.seat_number
          : undefined,
        ticket_code: listBookingDetail?.ticket_code,
        is_insurance: listBookingDetail?.is_insurance,
        customer_phone: !(
          listBookingDetail?.source === 'openapi' &&
          (listBookingDetail?.status === 'reserve' || listBookingDetail?.status === 'hold')
        )
          ? listBookingDetail?.customer?.phone
          : 'N/A',
        customer_name: listBookingDetail?.customer?.name,
        customer_email: !(
          listBookingDetail?.source === 'openapi' &&
          (listBookingDetail?.status === 'reserve' || listBookingDetail?.status === 'hold')
        )
          ? listBookingDetail?.customer?.email
          : 'N/A',
        pickup_location_id:
          isMoveTicket && dataMoveTicket?.ticket_code
            ? undefined
            : listBookingDetail?.pickup_extra
            ? 'other'
            : listBookingDetail?.pickup_location?.id
            ? listBookingDetail?.pickup_location?.id
            : undefined,
        drop_off_location_id:
          isMoveTicket && dataMoveTicket?.ticket_code
            ? undefined
            : listBookingDetail?.drop_off_extra
            ? 'other'
            : listBookingDetail?.drop_off_location?.id
            ? listBookingDetail?.drop_off_location?.id
            : undefined,
        total_price: listBookingDetail?.final_price,
        total_price1: listBookingDetail?.price,
        commission_price: formattedPrice(listBookingDetail?.commission_price),
        note: listBookingDetail?.note,
        payment_method: listBookingDetail?.payment_method,
        drop_off_extra: listBookingDetail?.drop_off_extra,
        pickup_extra: listBookingDetail?.pickup_extra,
        cancel_fee: listBookingDetail?.cancel_fee,
        refund: listBookingDetail?.refund,
        contact_phone: listBookingDetail?.contact?.phone,

        contact_name: listBookingDetail?.contact?.name,
        contact_email: listBookingDetail?.contact?.email,
        contact_note: listBookingDetail?.contact?.note,
        is_agent_id: listBookingDetail?.booking_for_agent ? true : false,
        agent_id: listBookingDetail?.booking_for_agent ? listBookingDetail?.booking_for_agent : '',
        final_price: listBookingDetail?.final_price,
      });
    } else {
      formBooking.resetFields();
      if (booking_for_agent_id) {
        formBooking.setFieldsValue({ agent_id: booking_for_agent_id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetail, formBooking, listBookingDetail]);

  const handleOpenPrint = () => {
    dispatch(setReloadDetail());
    setOpenModalPrint(true);
  };

  useEffect(() => {
    dispatch(filterBookingCustomer({}));
  }, [dispatch]);
  useEffect(() => {
    if (
      (price || !totalPrice) &&
      listBooking?.schedule?.id &&
      !listBookingDetail?.is_move &&
      !pin_code &&
      !listBookingDetail?.price_change
    ) {
      if (!(listBookingDetail?.source === 'openapi')) {
        formBooking.setFieldValue('total_price', price?.total_price);
      } else {
        formBooking.setFieldValue('total_price', listBookingDetail?.final_price);
      }
    }
    if (group === USER_GROUP.AGENT) {
      if (!listBookingDetail?.agent_commission && !listBookingDetail?.is_move) {
        formBooking.setFieldValue('commission_price', price?.commission_price);
      }
    }
    if (group === USER_GROUP.CAR_COMPANY) {
      if (isAgentId && agentId && !isListTicket) {
        formBooking.setFieldValue('commission_price', price?.commission_price);
      }
    }
  }, [
    price,
    pin_code,
    formBooking,
    totalPrice,
    commissionPrice,
    group,
    listBookingDetail,
    listBooking,
    isMoveTicket,
    dataMoveTicket,
    isAgentId,
    agentId,
    isListTicket,
  ]);

  useEffect(() => {
    if (pin_code) {
      if (
        !(
          listBookingDetail?.source === 'openapi' ||
          listBookingDetail?.source === 'company' ||
          listBookingDetail?.price_change
        )
      ) {
        formBooking.setFieldValue('total_price', price?.total_price);
      } else {
        formBooking.setFieldValue('total_price', listBookingDetail?.final_price);
      }
    } else {
      if (
        !(
          listBookingDetail?.source === 'openapi' ||
          listBookingDetail?.source === 'company' ||
          listBookingDetail?.price_change
        )
      ) {
        if (price?.total_price) {
          formBooking.setFieldValue('total_price', price?.total_price);
        } else {
          formBooking.setFieldValue('total_price', listBookingDetail?.final_price);
        }
      } else {
        formBooking.setFieldValue('total_price', listBookingDetail?.final_price);
      }
    }
  }, [pin_code, price, listBookingDetail]);

  useEffect(() => {
    if (
      listBooking?.schedule?.id &&
      modalOpen &&
      !isMoveTicket &&
      !dataMoveTicket?.ticket_code &&
      !listBookingDetail?.is_move
    ) {
      dispatch(
        getPriceBooking({
          booking_type: Constant.BOOKING_SINGLE,
          schedule_id: listBooking?.schedule?.id,
          seat_number: !isDetail ? dataBooking?.seat_number : listBookingDetail?.seat_number,
          is_insurance: isInSurance ? isInSurance : false,
          pickup_location_id: pickUpValue && pickUpValue !== 'other' ? pickUpValue : null,
          drop_off_location_id: dropOffValue && dropOffValue !== 'other' ? dropOffValue : null,
          depart_date: listBooking?.travel_info?.depart_date,
          agent_id: isAgentId && agentId ? agentId : undefined,
        }),
      );
    }
  }, [
    dispatch,
    modalOpen,
    pickUpValue,
    isInSurance,
    dropOffValue,
    dataBooking,
    listBooking,
    listBookingDetail,
    isDetail,
    isMoveTicket,
    dataMoveTicket,
    isAgentId,
    agentId,
  ]);

  useEffect(() => {
    if (errorCreate) {
      Object.keys(errorCreate).forEach((fieldName) => {
        const errorMessage = errorCreate[fieldName][0];
        dispatch(
          setToast({
            message: errorMessage,
            status: true,
            type: Toast.TYPE_ERROR,
          }),
        );
      });
    }
  }, [errorCreate, formBooking, dispatch]);

  const handleOpenHistoryModal = () => {
    setOpenModalHistory(true);
  };
  useEffect(() => {
    if (countdownFinished) {
      modalHandleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownFinished]);
  const loadFonts = useCallback(async () => {
    await Promise.all([
      { src: 'https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter (web)/Inter-Light.woff', fontWeight: 300 },
      { src: 'https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter (web)/Inter-Regular.woff', fontWeight: 400 },
      { src: 'https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter (web)/Inter-Medium.woff', fontWeight: 500 },
      { src: 'https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.19.3/Inter (web)/Inter-Bold.woff', fontWeight: 600 },
    ]);
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);
  useEffect(() => {
    dispatch(getListAgentActive({ company_id: listBooking?.schedule?.company?.id }));
  }, [dispatch]);

  useEffect(() => {
    if (messageCreate === 'Mã pin không chính xác') {
      setOpenModalNumpad(true);
      dispatch(resetPinCode());
    }
  }, [messageCreate, dispatch]);
  return (
    <Modal
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      centered
      footer={[]}
      width={940}
      maskClosable={false}
      className="modal-bus-booking diagram"
      confirmLoading={loading || loadingCreate || loadingSend || loadingHold}
    >
      <Card className="card-modal-booking w-100">
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Text className="fw-600 fs-18 ">{t('Thông tin hành khách')}</Typography.Text>
          <div style={{ marginRight: '30px' }}>
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 9)} />
          </div>
        </Row>
        <Spin spinning={loading || loadingCreate || loadingSend || loadingHold}>
          <Form
            layout="vertical"
            className=""
            form={formBooking}
            onFinish={onFinish}
            autoComplete="off"
            validateTrigger="onSubmit"
            labelAlign={'left'}
            labelWrap
            onFieldsChange={(allFields) => {
              scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
            }}
            initialValues={{ is_agent_id: false }}
          >
            <Row className={`time-countdown-block`}>
              <>
                {isDetail ? (
                  ''
                ) : (
                  <Row style={isDetail ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
                    <Col>
                      <HeaderBooking listBooking={listBooking} />
                    </Col>
                    <Col>
                      <Countdown
                        className="countdown-booking mt-2"
                        title="Thời gian còn lại:"
                        value={targetTime}
                        onChange={onChange}
                        format="mm:ss"
                        onFinish={onFinishCountdown}
                      />
                    </Col>
                  </Row>
                )}
              </>
              {isDetail ? (
                <>
                  <Row>
                    <HeaderBooking
                      listBooking={listBooking}
                      dataCustomerBooking={dataCustomerBooking}
                      isListTicket={isListTicket}
                    />
                  </Row>

                  <Row
                    className="mt-2 w-100"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    {listBookingDetail?.source !== 'openapi' && listBookingDetail?.source !== 'company' && (
                      <Col className="d-flex group-btn-detail-booking">
                        <Button onClick={() => handleOpenHistoryModal()}>
                          <HistoryOutlined /> Lịch sử
                        </Button>
                        <Button onClick={() => handleOpenPrint()}>
                          <PrinterOutlined /> In vé
                        </Button>
                        <Button onClick={() => dispatch(getSendBookingMail(listBookingDetail?.id))}>
                          <MailOutlined /> Gửi SMS / Email
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              ) : (
                ''
              )}
            </Row>
            <Row className="tab-booking-container mt-3">
              <Col className="w-100">
                <Tabs
                  type="card"
                  className="booking-modal-tabs"
                  activeKey={activeKey}
                  onChange={showTab}
                  items={[
                    {
                      label: 'Thông tin hành khách',
                      key: '1',
                      children: (
                        <ModalBooking1
                          formSearch={formSearch}
                          listBooking={listBooking}
                          isDetail={isDetail}
                          formBooking={formBooking}
                          listBookingDetail={listBookingDetail}
                          isListTicket={isListTicket}
                          dataCustomerBooking={dataCustomerBooking}
                          isBooking={isBooking}
                          isDepart={isDepart}
                          isChangeTicket={isChangeTicket}
                          setIsChangeTicket={setIsChangeTicket}
                          isMoveTicket={isMoveTicket}
                          dataMoveTicket={dataMoveTicket}
                          openModalNumpad={openModalNumpad}
                          setOpenModalNumpad={setOpenModalNumpad}
                        />
                      ),
                    },
                    {
                      label: 'Thông tin người liên hệ',
                      key: '2',
                      children: <ModalBooking2 />,
                    },
                  ]}
                />
              </Col>
              {/* <Col>
                <Select
                  className="input-h36"
                  placeholder="Đặt hộ đại lý"
                  size="large"
                  style={{ width: '100%' }}
                  // options={handleOptionPickup()}
                  showSearch
                  // filterOption={filterOption}
                />
              </Col> */}
            </Row>
            <Row className="d-flex mt--8  ">
              <Col span={3} className="d-flex justify-content-start ">
                {(listBooking?.trip?.status !== 'departed' || !isBooking) &&
                isDetail &&
                (listBookingDetail?.orderer?.name === name || group !== USER_GROUP.AGENT) &&
                !(listBookingDetail?.orderer?.agent?.id && !listBookingDetail?.payer?.agent?.id && group === 'agent') &&
                listBookingDetail?.source !== 'openapi' &&
                !listBooking?.contract?.code ? (
                  <Button
                    key="pdf-contract"
                    type="primary"
                    className="w-128 h-btn-48 fw-600"
                    htmlType="button"
                    danger
                    style={{ backgroundColor: '#D81717' }}
                    onClick={() => setIsOpenModalCancelTicket(true)}
                  >
                    <span>
                      <StopOutlined />
                      {' Hủy vé'}
                    </span>
                  </Button>
                ) : (
                  ''
                )}
              </Col>
              {(listBooking?.trip?.status !== 'departed' || !isBooking) && (
                <Col span={21} className="d-flex justify-content-end gap-12">
                  <Button key="cancel" className=" w-160 h-btn-48" onClick={() => modalHandleCancel()}>
                    {cancelText}
                  </Button>
                  {!listBooking?.contract?.code &&
                    listBookingDetail?.source !== 'openapi' &&
                    listBookingDetail?.source !== 'company' &&
                    (!listBooking?.price_schedule?.is_price_contact ||
                      (listBooking?.price_schedule?.is_price_contact && group !== USER_GROUP.AGENT)) && (
                      <Button
                        key="ok"
                        type="primary"
                        className="w-160 h-btn-48 btn-success"
                        style={{ marginRight: '5px' }}
                        htmlType="submit"
                        onClick={modalHandleOk}
                      >
                        {okText}
                      </Button>
                    )}
                </Col>
              )}
            </Row>
            <ModalCancelTicket
              modalOpen={isOpenModalCancelTicket}
              okText={t('Hủy vé')}
              cancelText={t('Trở lại')}
              modalTitle={t('Hủy vé')}
              onCancel={handleCancelTicket}
              onOk={handleOkeTicket}
              listBooking={listBooking}
              modalContent={t('Bạn đang thao tác hủy vé này, bạn có chắc chắn muốn hủy?')}
              isAgentId={isAgentId}
              agentId={agentId}
            />
            <ModalHistoryBooking
              modalOpen={openModalHistory}
              onOk={() => setOpenModalHistory(false)}
              onCancel={cancelModalHistory}
              historyDetail={listBookingDetail}
              listBooking={listBooking}
              isCancel={false}
            />
            <ModalPrintTicket
              modalOpen={openModalPrint}
              onCancel={cancelModalPrint}
              onOk={() => setOpenModalPrint(false)}
              listBooking={listBooking}
              listBookingDetail={listBookingDetail}
              dataCustomerBooking={dataCustomerBooking}
              loading={loadingListBookingDetail}
            />
          </Form>
        </Spin>
      </Card>
    </Modal>
  );
};
export default ModalBooking;
