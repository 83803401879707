import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Layout, Row, Select, SelectProps, Space, Table } from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Link from 'antd/es/typography/Link';
import IcPlus from '../../assets/images/ic-plus.svg';

import icEdit from '../../assets/images/ic-edit.svg';
import { fetchListProvincesWithRoute, fetchListRoute, routeSelector, setRoute } from '../../redux/slice/routeSlice';
import { RouteParams } from '../../types/route';
import { Constant } from '../../config/constant';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { filterOption, useRedirectStatusError } from '../../lib/utils';
import StatusFilter from '../../components/Modal/StatusFilter';
import RouteModal from './RouteModal';
import { fetchListProvince } from '../../redux/slice/provinceSlice';
import TutorialsVideo from '../../components/TutorialsVideo';
import { handleGetUrlGuide } from '../../utils/helpers';

function ListRoute() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [keyword, setKeyword] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [arrive_province_id, setArriveProvinceId] = useState<number | undefined>();
  const [depart_province_id, setDepartProvinceId] = useState<number | undefined>();
  const [status, setStatus] = useState<boolean>();
  const [openRouteModal, setOpenRouteModal] = useState<boolean>(false);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);

  const {
    loading,
    routes,
    provinces: provinceWithRoute,
    pagination,
    status: statusResponse,
  } = useAppSelector(routeSelector);

  const provinceOptions: SelectProps['options'] = provinceWithRoute.length
    ? provinceWithRoute.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    : [];

  const onChangeDepartProvince = (value: number) => {
    setDepartProvinceId(value);
  };

  const onChangeArriveProvince = (value: number) => {
    setArriveProvinceId(value);
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const handleChangeStatus = (status: boolean | undefined) => {
    setStatus(status);
  };

  const renderRowTable = (record: any) => {
    return record.status ? '' : 'bg-disabled';
  };

  useEffect(() => {
    dispatch(
      fetchListRoute({
        keyword,
        page,
        limit,
        status,
        depart_province_id,
        arrive_province_id,
      }),
    );
    dispatch(fetchListProvincesWithRoute());
  }, [dispatch, page, limit, status, depart_province_id, arrive_province_id]);

  useEffect(() => {
    dispatch(fetchListProvince());
  }, [dispatch]);

  const onCancelSuccess = useCallback(() => {
    dispatch(fetchListRoute({ keyword, page, limit }));
    dispatch(fetchListProvincesWithRoute());
    // cancelModal();
  }, [dispatch, page, limit, status]);

  const columns: ColumnsType<RouteParams> = [
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: '36px',
      render: (_, record) => (
        <Space size="middle">
          {record.status === Constant.STATUS_ACTIVE ? (
            <FontAwesomeIcon icon={faCircle} className="p-1 green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="p-1 danger" size="xs" />
          )}
        </Space>
      ),
    },
    {
      title: 'Tuyến',
      dataIndex: 'route_name',
      key: 'route_name',
      render: (_, record) => `${record.depart_point} - ${record.arrive_point}`,
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Điểm đầu',
      dataIndex: 'depart_province',
      key: 'depart_province',
      render: (_, record) => `${record.depart_province.name}`,
      // sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: 'Điểm cuối',
      dataIndex: 'license_class',
      key: 'license_class',
      render: (_, record) => `${record.arrive_province.name}`,
      // sorter: (a, b) => a.license_class.length - b.license_class.length,
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '120px',
      render: (_, record) => (
        <Space size="middle">
          <Link
            onClick={() => {
              dispatch(setRoute(record));
              setOpenRouteModal(true);
            }}
          >
            <Image src={icEdit} preview={false} />
          </Link>
        </Space>
      ),
    },
  ];

  useRedirectStatusError(statusResponse);

  return (
    <Layout className="min-vh-70 list_driver">
      <Card>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        <Row className="fw-500 mt-4 mx-3">
          <Button
            type="primary"
            className={'btn-success btn-add'}
            size="large"
            onClick={() => {
              dispatch(setRoute(undefined));
              setOpenRouteModal(true);
            }}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t('Thêm tuyến xe')}
          </Button>
        </Row>
        <Form
          className="mg-16 mt-3 mx-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          // onFinish={onSearch}
          colon={false}
          style={{ position: 'relative' }}
        >
          <Form.Item className="label-search mb-0" labelAlign="left" labelCol={{ md: 0 }}>
            <Row gutter={10}>
              <Col xs={12} sm={12} md={5}>
                <Form.Item name="depart_province">
                  <Select
                    className="input-h36"
                    showSearch
                    filterOption={filterOption}
                    placeholder="Điểm đầu"
                    size="large"
                    onChange={onChangeDepartProvince}
                    style={{ width: '100%' }}
                    options={[{ value: '', label: 'Tất cả' }, ...provinceOptions]}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={5}>
                <Form.Item name="arrive_province">
                  <Select
                    className="input-h36"
                    showSearch
                    filterOption={filterOption}
                    placeholder="Điểm cuối"
                    size="large"
                    onChange={onChangeArriveProvince}
                    style={{ width: '100%' }}
                    options={[{ value: '', label: 'Tất cả' }, ...provinceOptions]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Space size={10} align="center" style={{ position: 'absolute', right: 0, top: 7 }}>
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 18)} />
          </Space>
        </Form>
        <Table
          bordered={false}
          loading={loading}
          rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          rowKey={(record) => record?.id}
          columns={columns}
          dataSource={routes?.length > 0 ? routes : undefined}
          className="mt-0 mx-3"
        />
      </Card>
      <RouteModal
        listTutorials={listTutorials}
        open={openRouteModal}
        setOpen={setOpenRouteModal}
        onCancelSuccess={onCancelSuccess}
      />
    </Layout>
  );
}

export default ListRoute;
