import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import {
  getListAgent,
  getListBusCompanyApi,
  getListBusCompanyDetailApi,
  getListCompany,
} from '../../../api/busCompany';
import { Constant } from '../../../config/constant';
import { CompanyState, ListCompanyRequest } from '../../../types';
import { RootState } from '../../store';
import { getListCompanyActiveApi } from '../../../api/company';

// @ts-ignore
export const fetchListCompany = createAsyncThunk('admin/company', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await getListCompany();
    const { data, success } = response?.data;
    if (success) {
      dispatch(setListCompany(data.data));
      return true;
    }
  } catch (error: any) {
    dispatch(setListCompany(error));
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});

export const fetchListAgent = createAsyncThunk('admin/agents', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await getListAgent();
    const { data, success } = response?.data;
    if (success) {
      dispatch(setListAgent(data));
      return true;
    }
  } catch (error: any) {
    dispatch(setListAgent(error));
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});

export const getListBusCompany = createAsyncThunk(
  'admin/listBusCompany',
  async (params: ListCompanyRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListBusCompanyApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListBusCompany(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListBusCompany(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getListBusCompanyActive = createAsyncThunk(
  'admin/listBusCompanyActive',
  async (params: {limit:number}, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListCompanyActiveApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListBusCompanyActive(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const getListCompanyDetail = createAsyncThunk(
  'admin/listBusCompanyDetail',
  async (id: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListBusCompanyDetailApi(id);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListBusCompanyDetail(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListBusCompanyDetail(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const listBusCompanySlice = createSlice({
  name: 'company',
  initialState: {
    error: false,
    loading: false,
    success: false,
    company: [],
    agents: [],
    listCompany: [],
    pagination: [],
    loadingDetail: false,
    status: 0,
    message: '',
    listBusCompanyDetail: [],
    listBusCompanyActive: [],
    errorDetail: null,
  } as CompanyState,
  reducers: {
    setListCompany: (state: CompanyState, { payload }) => {
      state.company = payload;
    },
    setListAgent: (state: CompanyState, { payload }) => {
      state.agents = payload;
    },
    setListBusCompany: (state: any, { payload }: any) => {
      state.listCompany = payload?.data;
      state.pagination = payload?.pagination;
      state.status = +payload?.response?.status;
    },
    setListBusCompanyActive: (state: CompanyState, { payload }) => {
      state.listBusCompanyActive = payload?.data;
    },
    setListBusCompanyLoad: (state: CompanyState) => {
      state.listCompany = [];
    },
    setListBusCompanyDetail: (state: CompanyState, { payload }) => {
      state.listBusCompanyDetail = payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListCompany.pending, (state: CompanyState) => {
      state.loading = true;
    });
    builder.addCase(fetchListCompany.fulfilled, (state: CompanyState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchListCompany.rejected, (state: CompanyState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });

    builder.addCase(getListBusCompany.pending, (state: CompanyState) => {
      state.loading = true;
    });
    builder.addCase(getListBusCompany.fulfilled, (state: CompanyState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListBusCompany.rejected, (state: CompanyState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });

    builder.addCase(getListCompanyDetail.pending, (state: CompanyState) => {
      state.loadingDetail = true;
    });
    builder.addCase(getListCompanyDetail.fulfilled, (state: CompanyState) => {
      state.loadingDetail = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListCompanyDetail.rejected, (state: CompanyState) => {
      state.loadingDetail = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const listBusCompanySelector = (state: RootState) => state.listBusCompanySlice;
export const {
  setListCompany,
  setListAgent,
  setListBusCompany,
  setListBusCompanyDetail,
  setListBusCompanyLoad,
  setListBusCompanyActive,
} = listBusCompanySlice.actions;
