/* eslint-disable react-hooks/exhaustive-deps */
import {  useEffect, useState } from "react";
import { Card, Col, Flex, Image, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";
import { useAppSelector } from "../../../redux/hooks";
import icArrowLeft from "../../../assets/images/home/arrowLeft.svg";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant, USER_GROUP } from "../../../config/constant";
import { getListDashboardUser } from "../../../api/dashboard";
import { useNavigate } from "react-router-dom";
import { checkPermissionManagerAccount, formatTime, formatTimeDDMMYYYY, handleDisplayTime } from "../../../lib/utils";
import TextDisplay from "../../../components/Display/TextDisplay";
import statusPending from "../../../assets/images/orders/status-pending.svg";

type ColumnsType<T> = TableProps<T>["columns"];

interface DataType {
  name: {
    first: string;
    last: string;
  };
  gender: string;
  email: string;
  login: {
    uuid: string;
  };
}




const CarCompany = () => {
  const navigate = useNavigate()

  const limit_pagination: any = window.localStorage.getItem('limit_pagination')
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [dataUser, setDataUser] = useState<DataType[]>([]);
  const [limitUser, setLimitUser] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const [loadingUser, setLoadingUser] = useState(false);
  const [paginationUser, setPaginationUser] = useState<any>();
  const [pageUser, setPageUser] = useState<number>(1);
 
  const accountUser = useAppSelector((state) => state.authAccount?.user);

  const columnsUserAgent: ColumnsType<DataType> = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    // },
    {
      title: "STT",
      key: "id",
      
      render: (_: any, record: any, index: number) => {
        return (
          <div style={{display: "flex", alignItems: "center", gap: 5}}>
          
            <Typography className="text-color">{(pageUser - 1) * limitUser + index + 1}</Typography>
          </div>
        );
      },
    },
    {
      title: "Tên hiển thị",
      dataIndex: "name",
      render: (name, record: any) => (
        <Flex className="Order__column__booking-code" align="center">
          <Flex className="flex-column">
            <Typography.Text className="fs-16 text-color fw-500">
              <TextDisplay text={name ? name : "N/A"} />
            </Typography.Text>
            <Typography.Text className="fs-14 text-color-green-default fw-500">
              {formatTime(record?.created_at)}
            </Typography.Text>
          </Flex>
        </Flex>
      ),
      // sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
    },
    // {
    //   title: "Tên hiển thị",
    //   dataIndex: "name",
    // },
    {
      title: "Người tạo",
      dataIndex: "creator",
    },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
      render: (text) => (
        <Typography className="text-color">
          {formatTimeDDMMYYYY(text)}
        </Typography>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "is_new",
    //   render: (text) => {
    //     return (
    //       <>
    //         {text && <Image src={icRealTime} preview={false} />} 
    //       </>
    //     );
    //   },
    // },
  ];

  const columnsUser: ColumnsType<DataType> = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    // },
    {
      title: "STT",
      key: "id",
      
      render: (_: any, record: any, index: number) => {
        return (
          <div style={{display: "flex", alignItems: "center", gap: 5}}>
          
            <Typography className="text-color">{(pageUser - 1) * limitUser + index + 1}</Typography>
          </div>
        );
      },
    },
    // {
    //   title: "Tên hành khách",
    //   dataIndex: "name",
    //   render: (name, record: any) => (
    //     <Flex className="Order__column__booking-code" align="center">
    //       <Flex className="flex-column">
    //         <Typography.Text className="fs-16 text-color fw-500">
    //           <TextDisplay text={name ? name : "N/A"} />
    //         </Typography.Text>
    //         <Typography.Text className="fs-14 text-color-green-default fw-500">
    //           {formatTime(record?.created_at)}
    //         </Typography.Text>
    //       </Flex>
    //     </Flex>
    //   ),
    //   // sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
    // },
   
    {
      title: "Tên hiển thị",
      dataIndex: "name",
    },
    {
      title: "Loại tài khoản",
      dataIndex: "group",
      render: (text) => {
        let textGroup;
        if (text === USER_GROUP.CARLINK) {
          textGroup = "Nội bộ Carlink";
        }
        if (text === USER_GROUP.CAR_COMPANY) {
          textGroup = "Nhà xe";
        }
        if (text === USER_GROUP.AGENT) {
          textGroup = "Đại lý";
        }
        return <Typography className="text-color">{textGroup}</Typography>;
      },
    },
    {
      title: "Văn phòng",
      dataIndex: "creator",
    },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
      render: (text) => (
        <Typography className="text-color">
          {formatTimeDDMMYYYY(text)}
        </Typography>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "is_new",
    //   render: (text) => {
    //     return (
    //       <>
    //         {text && <Image src={icRealTime} preview={false} />} 
    //       </>
    //     );
    //   },
    // },
  ];

  const handleNavigateUser = () => {
    if (checkPermissionManagerAccount(accountUser)) {
      navigate("/user/list");
    } else {
    }
  };

  // vé đặt mới
 

  const onChangePageUser = (page: number, pageSize: number) => {
    if (paginationUser?.per_page !== pageSize) {
      setPageUser(Constant.DEFAULT_PAGE);
      setLimitUser(pageSize);
      return;
    }
    setPageUser(page);
  };

  let queryUser = `page=${pageUser}&limit=${limitUser}`;
  const handleListDashboardUser = async () => {
    setLoadingUser(true);
    try {
      const res: any = await getListDashboardUser(queryUser);
      if (res.status === 200) {
        setDataUser(res?.data?.data);
        const {current_page, total, per_page} = res?.data;
        setPaginationUser({current_page, total, per_page});
      }
    } catch (error) {}
    setLoadingUser(false);
  };

  useEffect(() => {
    handleListDashboardUser();
  }, [queryUser]);

  return (
    <>
        <Col >
          <Card className="wrapper-page-home">
            <Row className="header-home">
              <Col className="text-header-left">
                Tài khoản mới tạo   
              </Col>
              <Col className="text-header-right" onClick={handleNavigateUser}>
                Quản lý tài khoản <Image src={icArrowLeft} preview={false} />
              </Col>
            </Row>
            <Table
              columns={accountUser.group === USER_GROUP.CAR_COMPANY ? columnsUser : columnsUserAgent}
              rowKey={(record: any) => record?.id}
              dataSource={dataUser?.length > 0 ? dataUser : []}
              pagination={{
                total: paginationUser?.total,
                current: pageUser,
                pageSize: paginationUser?.per_page ?? limitPagination,
                position: ["bottomRight"],
                onChange: onChangePageUser,
                showSizeChanger: true,
              }}
              loading={loadingUser}
            />
          </Card>
        </Col>

    </>
  );
};

export default CarCompany;
