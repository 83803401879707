import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { getErrorMessage } from '../../api';
import { getListDistrictAPI, getListProvincesAPI, getListWardAPI } from '../../api/province';
import { Constant } from '../../config/constant';
import { ListDistrictParams, ListProvinceState, ListWardParams } from '../../types/province';
import { RootState } from '../store';

// @ts-ignore

// List Province
export const fetchListProvince = createAsyncThunk('provinces/list', async (_: void, { dispatch, rejectWithValue }) => {
  try {
    const response = await getListProvincesAPI();
    const { data, success, pagination } = response?.data;
    if (success) {
      dispatch(setListProvince({ provinces: data, pagination: pagination }));
      return true;
    }
  } catch (error: any) {
    dispatch(setListProvince(error));
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});

export const fetchListDistrict = createAsyncThunk(
  'provinces/district',
  async (params: ListDistrictParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListDistrictAPI(params);
      const { data, success } = response?.data;
      if (success) {
        dispatch(setListDistrict(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListDistrict(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const fetchListWard = createAsyncThunk(
  'provinces/ward',
  async (params: ListWardParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListWardAPI(params);
      const { data, success } = response?.data;
      if (success) {
        dispatch(setListWard(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListWard(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const provinceSlice = createSlice({
  name: 'provinceSlice',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: false,
    message: '',
    provinces: [],
    district: [],
    ward: [],
    province: {
      id: null,
      depart_province_id: undefined,
      arrive_province_id: undefined,
      depart_point: '',
      arrive_point: '',
      status: undefined,
    },
    pagination: {
      total: 0,
      per_page: Constant.PAGE_SIZE,
      current_page: Constant.DEFAULT_PAGE,
      last_page: Constant.DEFAULT_PAGE,
    },
  } as ListProvinceState,
  reducers: {
    setListProvince: (state: ListProvinceState, { payload }) => {
      const { provinces, pagination } = payload;
      state.provinces = provinces;
      state.pagination = pagination;
      state.status = payload?.response?.status;
    },

    setProvince: (state: ListProvinceState, { payload }) => {
      state.province = payload;
    },
    setListDistrict: (state: ListProvinceState, { payload }) => {
      state.district = payload;
    },
    setListWard: (state: ListProvinceState, { payload }) => {
      state.ward = payload;
    },
    resetState: (state: ListProvinceState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
    },
    resetLocation: (state: ListProvinceState) => {
      state.ward = [];
      state.district = [];
    },
  },
  extraReducers: (builder) => {
    // List Province
    builder.addCase(fetchListProvince.pending, (state: ListProvinceState) => {
      state.loading = true;
    });
    builder.addCase(fetchListProvince.fulfilled, (state: ListProvinceState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchListProvince.rejected, (state: ListProvinceState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const provinceSelector = (state: RootState) => state.province;
export const { setListProvince, setProvince, resetState, setListDistrict, setListWard, resetLocation } =
  provinceSlice.actions;
