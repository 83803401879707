import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { SelectProps } from 'antd/lib';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import icEdit from '../../assets/images/ic-edit.svg';
import IcLock from '../../assets/images/ic-lock.svg';
import iconNext from '../../assets/images/ic-next.svg';
import IcPlus from '../../assets/images/ic-plus.svg';
import iconPrev from '../../assets/images/ic-prev.svg';
import IcUnLock from '../../assets/images/ic-unlock.svg';
import '../../assets/styles/desktop/typeBus.scss';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant, Toast } from '../../config/constant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/slice/authSlice';
import {
  createBusType,
  createBusTypeSelector,
  resetRegisterCreateBusType,
} from '../../redux/slice/busTypes/createBusTypeSlice';
import { getAllBusType, getListBusType, listBusTypeSelector } from '../../redux/slice/busTypes/listBusTypeSlice';
import {
  resetUpdateBusType,
  updateBusTypeDetail,
  updateBusTypeSelector,
  updateBusTypeStatus,
} from '../../redux/slice/busTypes/updateBusTypeSlice';
import { setToast } from '../../redux/slice/toastNotifySlice';
import { ListBusTypeRequest } from '../../types/busType';
import { handleGetUrlGuide } from '../../utils/helpers';
import ModalGroupSeat from './ModalGroupSeat';
import ModalImageTypeBus from './ModalImageTypeBus';
import ModalTypeBus from './ModalTypeBus';
import { fetchAllService } from '../../redux/slice/services/serviceSlice';

function TypeBus() {
  const { t } = useTranslation();
  const { listBusType, pagination, listAllBusType, loading } = useAppSelector(listBusTypeSelector);
  const { status: statusResponseUpdate, errorDetail: errorUpdate } = useAppSelector(updateBusTypeSelector);
  const { status: statusResponseCreate, errorDetail: errorCreate } = useAppSelector(createBusTypeSelector);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const { group } = useAppSelector(selectAuth);
  const [formSearch] = Form.useForm();
  const [formTypeBus] = Form.useForm();
  const [status, setStatus] = useState<boolean>();
  const [openCreate, setOpenCreate] = useState(false);
  const [keyword, setKeyword] = useState<ListBusTypeRequest>({});
  const [page, setPage] = useState<number>(1);
  const [record, setRecord] = useState<any>(null);
  const [statusDetail, setStatusDetail] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileListDelete, setFileListDelete] = useState<[]>([]);
  const dispatch = useAppDispatch();
  const [activeKey, setActiveKey] = useState<string>('1');
  const [busTypeId, setBusTypeId] = useState<any>();
  const [dataForm, setDataForm] = useState<any>();
  const [countFloors, setCountFloors] = useState(1);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [representImageCar, setRepresentImageCar] = useState<any[]>([]);
  const [busImg, setBusImg] = useState([]);
  const [disableChangeTab, setDisableChangeTab] = useState(false);
  const [disableTab, setDisableTab] = useState<boolean>(false);
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const seatJson = Form.useWatch('seat_json', formTypeBus);
  const currentValues = formTypeBus.getFieldValue('seat_json') || [];
  const nameType = Form.useWatch('name_type', formTypeBus);
  const rowNum = formTypeBus.getFieldValue('row');
  const colNum = formTypeBus.getFieldValue('column');

  const cancelModal = () => {
    setOpenCreate(false);
    formTypeBus.resetFields();
    formTypeBus.setFieldsValue({ row: 0, column: 0 });
    setFileListDelete([]);
    setActiveKey('1');
    setIsCreate(false);
    setRepresentImageCar([]);
    setBusImg([]);
  };

  const onChangeStatus = (value: boolean | undefined) => {
    setStatus(value);
  };
  const renderRowTable = (record: any) => {
    return record.status ? '' : 'bg-disabled';
  };
  const handleTypeBusDetail = (record: any) => {
    setOpenCreate(true);
    setRecord(record);
    setActiveKey('1');
    setDisableTab(false);
    setStatusDetail(record?.status);
    if (record?.images?.length > 0) {
      setBusImg(
        record?.images?.map((image: string) => ({
          dataURL: image,
        })),
      );
      formTypeBus.setFieldsValue({
        bus_img: record?.images?.map((image: string) => ({
          dataURL: image,
        })),
      });
    }
    if (record?.thumbnail) {
      setRepresentImageCar([{ dataURL: record?.thumbnail }]);
      formTypeBus.setFieldsValue({
        portrait_photo: [{ dataURL: record.thumbnail }],
      });
    }
    formTypeBus.setFieldsValue({
      group_seat: record?.seat_map?.group_seat,
      name_type: record?.name,
      seat_number: record?.seat_map?.seat_number,
      row: record?.seat_map?.row,
      column: record?.seat_map?.column,
      count_floor: record?.seat_map?.count_floor,
      bus_direction: record?.seat_map?.bus_direction,
      seat_json: record?.seat_map,
      service: record?.service?.map((item: any) => {
        return item.id;
      }),
    });
  };

  const handleCreateTypeBus = () => {
    setOpenCreate(true);
    showTab('1');
    setRecord(null);
    setDisableTab(true);
    setCountFloors(1);
    setIsCreate(true);
  };
  const handleCancelActive = () => {
    setIsModalVisible(false);
  };
  const handleActive = () => {
    setIsModalVisible(false);
    if (statusDetail) {
      setStatusDetail(false);
    } else {
      setStatusDetail(true);
    }
    dispatch(updateBusTypeStatus({ id: record?.id, status: statusDetail }));
  };
  useEffect(() => {
    dispatch(getListBusType({ ...keyword, page, status, limit, id: busTypeId }));
  }, [status, dispatch, keyword, page, limit, busTypeId]);

  useEffect(() => {
    dispatch(getAllBusType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns: ColumnsType<any> = [
    {
      title: 'Loại xe',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      render: (_, record) => (
        <Space size="middle">
          {record.status ? (
            <FontAwesomeIcon icon={faCircle} className="green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="danger" size="xs" />
          )}
          <Typography className="text-color">{record.name}</Typography>
        </Space>
      ),
    },
    {
      title: 'Số chỗ',
      dataIndex: 'seatNumber',
      key: 'seat_number',
      align: 'center',
      render: (_, record) => (
        <Space size="middle">
          <Typography className="text-color">{record?.seat_map?.seat_number}</Typography>
        </Space>
      ),
    },
    {
      title: 'Nhóm ghế',
      dataIndex: 'seat_group',
      key: 'group_seat',
      render: (_, record) => (
        <Space size="middle" style={{ columnGap: '7px' }} key={record?.id}>
          {record?.seat_map?.group_seat.map((group: any, index: number) => (
            <Typography key={`${group.name}-${index}`} className="text-color">
              {group.name}
              {index < record?.seat_map?.group_seat.length - 1 ? ',' : ''}
            </Typography>
          ))}
        </Space>
      ),
    },
    {
      title: 'Tạo bởi',
      dataIndex: 'creator',
      key: 'creator',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <div onClick={() => handleTypeBusDetail(record)} style={{ cursor: 'pointer' }}>
            <Tooltip title={'Chỉnh sửa thông tin loại xe'} key={'edt'} placement="right">
              <Image src={icEdit} preview={false} width={17} height={17} />
            </Tooltip>
          </div>
        </Space>
      ),
      align: 'center',
    },
  ];

  const onSearch = () => {};
  const showTab = (key: string) => {
    setActiveKey(key);
    if (key === '2') {
      if (record?.id) {
      } else {
        if (disableChangeTab) {
          dispatch(
            setToast({
              message: 'Vui lòng nhập tên ghế',
              status: true,
              type: Toast.TYPE_ERROR,
            }),
          );
        }

        if (rowNum === 0 || colNum === 0) {
          dispatch(
            setToast({
              message: 'Vui lòng nhập số cột và số hàng',
              status: true,
              type: Toast.TYPE_ERROR,
            }),
          );
        }
      }
      if (!disableChangeTab && rowNum > 0 && colNum > 0) {
        setActiveKey(key);
        setDisableTab(false);

        if (key === '2') {
          formTypeBus.setFieldValue('seat_json', currentValues);
          setDataForm({
            seat_map: {
              ...currentValues,
            },
          });
        }
      } else {
        setActiveKey(key);
      }
    }
  };
  const handlePagination = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };
  const onChangeGroup = (values: any) => {
    setBusTypeId(values);
  };
  function onSearchBusType(value: any) {
    setKeyword(value);
  }
  const TypeBusOptions: SelectProps['options'] = listAllBusType?.length
    ? listAllBusType?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    : [];

  useEffect(() => {});
  const filterOptionBus = (
    input: string,
    option?: {
      label?: any;
      value?: any;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onCancelSuccess = useCallback(() => {
    dispatch(resetRegisterCreateBusType());
    dispatch(resetUpdateBusType());
    dispatch(getListBusType({ ...keyword, status, keyword, page, limit, id: busTypeId }));
    cancelModal();
    setActiveKey('1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setOpenCreate, page, limit, keyword, status, busTypeId]);

  const getUniqueTypes = (seatMap: any) => {
    if (seatMap) {
      const typesSet = new Set();
      const floors = ['floor1', 'floor2'];
      floors.forEach((floor) => {
        if (seatMap[floor] && Array.isArray(seatMap[floor])) {
          seatMap[floor].forEach((row: any) => {
            row.forEach((seat: any) => {
              if (seat.type !== 1 && seat.type !== 2 && seat.type !== 0) {
                typesSet.add(seat.type);
              }
            });
          });
        }
      });

      return Array.from(typesSet);
    } else {
      return undefined;
    }
  };

  const onFinish = (value: any) => {
    if (!formTypeBus.getFieldValue('portrait_photo')?.[0]?.dataURL?.includes('https')) {
      value.portrait_photo = formTypeBus.getFieldValue('portrait_photo')?.[0]?.dataURL;
    } else {
      value.portrait_photo = undefined;
    }
    // }
    let listBusImages = busImg?.map((image: any) => image?.dataURL);
    value.bus_img = listBusImages?.filter((image) => !image?.includes('https://'));

    if (fileListDelete.length) {
      value.images_delete = fileListDelete;
    }

    if (value.group_seat?.length > 0 || record?.id) {
      if (formTypeBus.getFieldValue('portrait_photo')?.length === 0) {
        dispatch(
          setToast({
            message: 'Vui lòng nhập ảnh đại diện ',
            status: true,
            type: Toast.TYPE_ERROR,
          }),
        );
      } else {
        const uniqueTypes = getUniqueTypes({
          floor1: value.seat_json.floor1.map((row: any) => row.map((seat: any) => ({ ...seat, selected: false }))),
          floor2: value.seat_json.floor2.map((row: any) => row.map((seat: any) => ({ ...seat, selected: false }))),
        });
        const tmpGroupSeatData = {
          id: record?.id,
          name: value.name_type,
          seat_number: value.seat_number,
          thumbnail: value.portrait_photo,
          images: value.bus_img,
          images_delete: value.images_delete,
          service: value.service,
          seat_map: record?.id
            ? undefined
            : {
                row: value.row,
                column: value.column,
                group_seat: value.group_seat,
                floor1: value.seat_json.floor1.map((row: any) =>
                  row.map((seat: any) => ({ ...seat, selected: false })),
                ),
                floor2: value.seat_json.floor2.map((row: any) =>
                  row.map((seat: any) => ({ ...seat, selected: false })),
                ),
                count_floor: value.count_floor || 1,
                seat_number: value.seat_number,
                seats: value.seat_json.seat_map?.seats,
                bus_direction: value.bus_direction,
              },
          seat_types: uniqueTypes,
        };
        if (record) {
          dispatch(updateBusTypeDetail(tmpGroupSeatData));
        } else {
          dispatch(createBusType(tmpGroupSeatData));
        }
      }
    } else {
      dispatch(
        setToast({
          message: 'Vui lòng nhập nhóm ghế',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    }
  };

  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      formTypeBus.scrollToField(firstErrorField.name);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    // eslint-disable-next-line array-callback-return
    errorInfo.errorFields.map((item: any) => {
      toast.error(item?.errors[0]);
    });
  };

  const handleSubmitTypeBus = () => {
    if (disableChangeTab) {
      dispatch(
        setToast({
          message: 'Vui lòng nhập tên ghế',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    }
    if (rowNum === 0 || colNum === 0) {
      dispatch(
        setToast({
          message: 'Vui lòng nhập số cột và số hàng',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    }
    if (nameType && rowNum > 0 && colNum > 0 && !disableChangeTab) {
      formTypeBus.setFields([
        {
          name: 'name_type',
          errors: undefined,
        },
      ]);
      formTypeBus.setFields([
        {
          name: 'row',
          errors: undefined,
        },
      ]);
      formTypeBus.setFields([
        {
          name: 'column',
          errors: undefined,
        },
      ]);
      setDataForm({
        seat_map: {
          ...currentValues,
        },
      });
      formTypeBus.setFieldValue('seat_json', currentValues);
      showTab('2');
    } else {
      if (!nameType) {
        formTypeBus.setFields([
          {
            name: 'name_type',
            errors: ['Vui lòng nhập tên'],
          },
        ]);
      }
      if (!rowNum) {
        formTypeBus.setFields([
          {
            name: 'row',
            errors: ['Vui lòng nhập hàng'],
          },
        ]);
      }
      if (!colNum) {
        formTypeBus.setFields([
          {
            name: 'column',
            errors: ['Vui lòng nhập cột'],
          },
        ]);
      }
    }
    if (+activeKey === 2) {
      setActiveKey('3');
    }
  };

  //HANDLE TOAST
  useEffect(() => {
    if (statusResponseCreate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
      dispatch(resetRegisterCreateBusType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusResponseCreate]);

  useEffect(() => {
    if (statusResponseUpdate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusResponseUpdate]);
  useEffect(() => {
    if (errorCreate) {
      formTypeBus.setFields(
        Object.keys(errorCreate).map((fieldName) => ({
          name: fieldName,
          errors: [errorCreate[fieldName]],
        })),
      );
      const error = Object.keys(errorCreate).map((fieldName) => ({
        errors: [errorCreate[fieldName]],
      }));
      error.map((item) => toast.error(item?.errors[0][0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCreate]);

  useEffect(() => {
    if (errorUpdate) {
      formTypeBus.setFields(
        Object.keys(errorUpdate).map((fieldName) => ({
          name: fieldName,
          errors: [errorUpdate[fieldName]],
        })),
      );
      const error = Object.keys(errorUpdate).map((fieldName) => ({
        errors: [errorUpdate[fieldName]],
      }));
      error.map((item) => toast.error(item?.errors[0][0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdate]);

  useEffect(() => {
    const allSeats = [...(seatJson?.['floor1'] || []), ...(seatJson?.['floor2'] || [])]
      .flat(2)
      .filter((seat: any) => seat.type > Constant.EXTRA_DRIVER_SEAT);
    const isDisableTab = allSeats.filter((seat) => !seat.name);
    if (isDisableTab.length > 0 || allSeats?.length === 0) {
      setDisableChangeTab(true);
    } else {
      setDisableChangeTab(false);
    }
  }, [seatJson, disableChangeTab]);
  useEffect(() => {
    dispatch(fetchAllService());
  }, [dispatch]);

  return (
    <>
      <Layout className="min-vh-70 list_user type-bus">
        <Card>
          {/* <StatusFilter status={status} onChangeStatus={onChangeStatus} /> */}
          <Row gutter={8} className="fw-500">
            <Col>
              <Button
                type="text"
                className={`fw-500 ${status === undefined ? 'btn-status-active-green' : 'text-color'}`}
                onClick={() => {
                  onChangeStatus(undefined);
                  setPage(1);
                }}
              >
                {t('Tất cả')}
              </Button>
            </Col>
            <Col className="border-start border-end">
              <Button
                type="text"
                className={`fw-500 ${status === Constant.STATUS_ACTIVE ? 'btn-status-active-green' : 'text-color'}`}
                onClick={() => {
                  onChangeStatus(Constant.STATUS_ACTIVE);
                  setPage(1);
                }}
              >
                {t('Đang hoạt động')}
              </Button>
            </Col>
            <Col>
              <Button
                type="text"
                className={`fw-500 ${status === Constant.STATUS_INACTIVE ? 'btn-status-lock-red' : 'text-color'}`}
                onClick={() => {
                  onChangeStatus(Constant.STATUS_INACTIVE);
                  setPage(1);
                }}
              >
                {t('Đã khóa')}
              </Button>
            </Col>
          </Row>
          <Row className="fw-500 mt-4 mx-3">
            <Button type="primary" className={'btn-success height-btn-40'} onClick={handleCreateTypeBus}>
              <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
              {t('Thêm loại xe')}
            </Button>
          </Row>
          <Form
            className="mg-16 mt-3 mx-3 form-typebus-1"
            form={formSearch}
            labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
            requiredMark={false}
            onFinish={onSearch}
            colon={false}
            onFinishFailed={onFinishFailed}
            scrollToFirstError={true}
            autoComplete="off"
            validateTrigger="onSubmit"
          >
            <Row gutter={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
                <Form.Item name="bus_type">
                  <Select
                    placeholder="Loại xe"
                    style={{ width: '100%' }}
                    className="select-type-bus"
                    showSearch
                    onChange={onChangeGroup}
                    onSearch={onSearchBusType}
                    filterOption={filterOptionBus}
                    options={[{ value: '', label: 'Tất cả' }, ...TypeBusOptions]}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              >
                <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 15)} />
              </Col>
            </Row>
          </Form>
          <Table
            locale={{
              triggerDesc: 'Sắp xếp giảm dần',
              triggerAsc: 'Sắp xếp tăng dần',
              cancelSort: 'Hủy sắp xếp',
            }}
            dataSource={listBusType?.length > 0 ? listBusType : undefined}
            rowKey="id"
            rowClassName={renderRowTable}
            columns={columns}
            className="mt-1 mx-3 list-bus"
            pagination={false}
            loading={loading}
          />
          <div className="pagination-table agency">
            <Pagination
              onChange={handlePagination}
              current={page}
              total={pagination?.total}
              pageSize={pagination?.per_page ?? limitPagination}
              showSizeChanger={true}
              prevIcon={<img src={iconPrev} alt="" />}
              nextIcon={<img src={iconNext} alt="" />}
            />
          </div>
        </Card>
      </Layout>

      <Modal
        centered
        className="create-type-car typebus-modal"
        open={openCreate}
        onOk={() => setOpenCreate(false)}
        onCancel={() => cancelModal()}
        styles={{
          body: { maxHeight: '90vh' },
        }}
        maskClosable={false}
        forceRender
        width={1236}
        footer={[
          <Row className="comp-modal-footer typebus" key={1}>
            {((record && record?.creator !== 'carlink') || (record && group === 'carlink')) && (
              <Col span={3} className="d-flex justify-content-start mt-2">
                {statusDetail ? (
                  <Button
                    className="btn-block-red type-bus w-160 h-btn-48"
                    danger
                    key={'btn-lock'}
                    type="primary"
                    htmlType="button"
                    onClick={() => setIsModalVisible(true)}
                  >
                    <Image src={IcLock} preview={false} className="pb-1 pe-2" />
                    {t('Khoá loại xe')}
                  </Button>
                ) : (
                  <Button
                    className="btn-active-green w-160 h-btn-48"
                    danger
                    key={'btn-unlock'}
                    type="primary"
                    htmlType="button"
                    onClick={() => setIsModalVisible(true)}
                  >
                    <Image src={IcUnLock} preview={false} className="pb-1 pe-2" />
                    {t('Mở loại xe')}
                  </Button>
                )}
              </Col>
            )}
            <Col
              span={(record && record?.creator !== 'carlink') || (record && group === 'carlink') ? 21 : 24}
              className="d-flex justify-content-end gap-12 mt-2"
            >
              {+activeKey === 1 || +activeKey === 2 ? (
                <>
                  <Button key="cancel" className="w-200 h-btn-48 btn-cancel" onClick={() => cancelModal()}>
                    {t('Đóng')}
                  </Button>
                  <Button
                    key="btn-next"
                    type="primary"
                    className="w-200 h-btn-48 btn"
                    style={{ fontWeight: '600px', color: '#fff' }}
                    htmlType="button"
                    onClick={() => handleSubmitTypeBus()}
                  >
                    {t('Tiếp tục')}
                  </Button>
                </>
              ) : (
                <>
                  <Button key="cancel" className="w-200 h-btn-48 btn-cancel" onClick={() => showTab('1')}>
                    {t('Trở lại')}
                  </Button>
                  {((record && record?.creator === 'carlink' && group === 'carlink') ||
                    (record?.creator !== 'carlink' && group !== 'carlink') ||
                    !record) && (
                    <Button
                      key="submit"
                      type="primary"
                      className="w-200 h-btn-48 btn-success"
                      htmlType="submit"
                      style={{ marginRight: 0 }}
                      onClick={formTypeBus.submit}
                    >
                      {t('Lưu')}
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>,
        ]}
      >
        <Form
          name="typebus-form"
          layout="vertical"
          form={formTypeBus}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          className="mt-0"
          labelCol={{ span: 12 }}
          colon={false}
          labelAlign={'left'}
          labelWrap
          initialValues={{
            seat_json: { seat_number: '' },
            group_seat: [],
          }}
          onFieldsChange={(allFields) => {
            scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
          }}
        >
          <Row>
            <Col className="mb-2">
              <Typography.Text className="fw-bold fs-20 ">{t(`Chi tiết thông tin loại xe`)}</Typography.Text>
            </Col>
            <Col xs={18} sm={18} md={18} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div style={{ marginTop: '-10px' }}>
                <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 15)} />
              </div>
            </Col>
          </Row>

          <Tabs
            type="card"
            activeKey={activeKey}
            onChange={showTab}
            items={[
              {
                label: 'Thiết lập loại xe',
                key: '1',
                children: <ModalTypeBus record={record} formTypeBus={formTypeBus} isCreate={isCreate} group={group} />,
              },
              {
                label: 'Thiết lập nhóm ghế',
                key: '2',
                children: (
                  <ModalGroupSeat
                    record={record}
                    tmpDataSeatMatrix={dataForm}
                    setTmpDataSeatMatrix={setDataForm}
                    formTypeBus={formTypeBus}
                    group={group}
                  />
                ),
                disabled: disableTab && activeKey !== '2',
              },
              {
                label: 'Ảnh loại xe và tiện ích',
                key: '3',
                children: (
                  <ModalImageTypeBus
                    formTypeBus={formTypeBus}
                    representImageCar={representImageCar}
                    setRepresentImageCar={setRepresentImageCar}
                    busImg={busImg}
                    setBusImg={setBusImg}
                    fileListDelete={fileListDelete}
                    setFileListDelete={setFileListDelete}
                  />
                ),
              },
            ]}
          />
        </Form>
        <ModalConfirm
          modalOpen={isModalVisible}
          isAgentBus={true}
          okText={statusDetail ? t('Khóa ngay') : t('Mở ngay')}
          cancelText={t('Trở lại')}
          modalTitle={statusDetail ? t('Khoá loại xe') : t('Mở loại xe')}
          modalContent={
            statusDetail
              ? t('Bạn đang thao tác khóa loại xe này, có chắc chắn muốn khóa?')
              : t('Bạn đang thao tác mở loại xe này, có chắc chắn muốn mở?')
          }
          onCancel={handleCancelActive}
          onOk={handleActive}
        />
      </Modal>
    </>
  );
}
export default TypeBus;
