import { InfoCircleFilled, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  Statistic,
  Switch,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { CountdownProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconBookingAgent from '../../assets/images/ic-booking-agent.svg';
import iconCancelPinCode from '../../assets/images/ic-cancel-pincode.svg';
import iconNumpad from '../../assets/images/ic-numpad.svg';
import '../../assets/styles/desktop/busBooking.scss';
import HeaderBooking from '../../components/HeaderBooking';
import ModalInfoInsurance from '../../components/Modal/ModalInfoInsurance';
import ModalNumpad from '../../components/Modal/ModalNumpad';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant, Toast, USER_GROUP, YearFormat } from '../../config/constant';
import { validateMobileUser } from '../../config/validators/validateUser';
import { convertTimeFormat, filterOption, formattedPrice } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { listAgentSelector } from '../../redux/slice/agents/listAgentSlice';
import { selectAuth } from '../../redux/slice/authSlice';
import {
  createBooking,
  createBookingSelector,
  getPriceBooking,
  resetBooking,
  resetPriceBooking,
  setBookingForAgentId,
  setPinCode,
} from '../../redux/slice/bookings/createBookingSlice';
import { destroyBooking, resetDestroyBooking } from '../../redux/slice/bookings/destroyBookingSlice';
import {
  getTicketCodeBooking,
  getTicketCodeSelector,
  resetTicketCode,
} from '../../redux/slice/bookings/getTicketCodeSlice';
import { holdingBookingSelector, resetDataBooking } from '../../redux/slice/bookings/holdingBookingSlice';
import { getBookingInfo, getListFilterBooking, listBookingSelector } from '../../redux/slice/bookings/listBooking';
import { setToast } from '../../redux/slice/toastNotifySlice';
import {
  paymentAgentDepartOptions,
  paymentAgentOptions,
  paymentMultiDepartedOptions,
  paymentMultiOptions,
  validatorEmailBooking,
} from '../../utils/bookingItem';
import { handleGetUrlGuide } from '../../utils/helpers';
import ModalMultiBooking1 from './ModalMultiBooking1';
import ModalMultiBooking2 from './ModalMultiBooking2';

const ModalMultiBooking: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  listBooking,
  formSearch,
  isMulti,
  targetTime,
  setTargetTicket,
  isBookingNoDiagram,
  isDepart,
}) => {
  const { listCustomers, price, booking_for_agent_id, pin_code } = useAppSelector(createBookingSelector);
  const { listAgentActive } = useAppSelector(listAgentSelector);
  const { group } = useAppSelector(selectAuth);
  const { ticketCode, loading: loadingTicket } = useAppSelector(getTicketCodeSelector);
  const { dataBooking, loading: loadingHold } = useAppSelector(holdingBookingSelector);
  const { status: statusCreateUpdate } = useAppSelector(createBookingSelector);
  const { dataSearchBooking } = useAppSelector(listBookingSelector);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const { Countdown } = Statistic;
  const [activeKey, setActiveKey] = useState<string>('1');
  const [formBooking]: [FormInstance] = Form.useForm();
  const [paymentMethod, setPaymentMethod] = useState(undefined);
  const [otp, setOtp] = useState<string>('');
  const [openInfoInsurance, setOpenInfoInsurance] = useState(false);
  const [numCustomer, setNumCustomer] = useState<number>(1);
  const [customerPhone, setCustomerPhone] = useState<string>('');
  const [countdownFinished, setCountdownFinished] = useState(false);
  const [openModalNumpad, setOpenModalNumpad] = useState<boolean>(false);
  const provinceId = Form.useWatch('route_id', formSearch);
  const searchDateBooking = dayjs(Form.useWatch('date_booking', formSearch)).format(YearFormat);
  const searchDepartTime = Form.useWatch('depart_time', formSearch);
  const pickUpValue = Form.useWatch('pickup_location_id', formBooking);
  const dropOffValue = Form.useWatch('drop_off_location_id', formBooking);
  const isInSurance = Form.useWatch('is_insurance', formBooking);
  const customInfo = Form.useWatch('custom_info', formBooking);
  const isRepresent = Form.useWatch('is_represent', formBooking);
  const isAgentId = Form.useWatch('is_agent_id', formBooking);
  const isAgentIdMulti = Form.useWatch('is_agent_id_multi', formBooking);
  const agentId = Form.useWatch('agent_id', formBooking);
  const agentIdMulti = Form.useWatch('agent_id_multi', formBooking);
  const [commissionPrice, setCommissionPrice] = useState(0);
  const commisionPrice = Form.useWatch('commission_price', formBooking);

  const dispatch = useAppDispatch();
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
    dispatch(resetTicketCode());
    setNumCustomer(1);
    dispatch(resetPriceBooking());
    dispatch(resetDataBooking());
    showTab('1');
    setOpenModalNumpad(false);
    const multiTicket = dataBooking?.length && dataBooking?.map((item: any) => item?.ticket_code);
    formBooking.resetFields();
    if (listBooking?.schedule?.is_diagram) {
      const tmpDataDestroy = {
        ticket_code: multiTicket,
      };
      dispatch(destroyBooking(tmpDataDestroy));
    }
    if (!listBooking?.schedule?.is_diagram) {
      setTargetTicket(undefined);
    }
    setCountdownFinished(false);
  };
  const onChange: CountdownProps['onChange'] = (val) => {};
  const showTab = (key: string) => {
    setActiveKey(key);
  };
  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      formBooking.scrollToField(firstErrorField.name);
    }
  };
  const onFinishCountdown: CountdownProps['onFinish'] = () => {
    setTargetTicket(undefined);
    setCountdownFinished(true);
  };
  const onChangePayment = ({ target: { value } }: RadioChangeEvent) => {
    setPaymentMethod(value);
  };

  const handleUpCustomer = () => {
    if (dataSearchBooking?.empty_seat && !listBooking?.schedule?.is_diagram) {
      if (numCustomer < +dataSearchBooking?.empty_seat) {
        setNumCustomer(numCustomer + 1);
      }
    } else {
      setNumCustomer(numCustomer + 1);
    }
  };
  const handleDownCustomer = () => {
    if (numCustomer > 1) {
      setNumCustomer(numCustomer - 1);
    }
  };
  const handleOptionPickup = () => {
    let options: any = [];
    options = options.concat(
      listBooking?.schedule?.transship_depart
        ? listBooking.schedule.transship_depart.map((item: any) => ({
            value: item.id,
            label: `(${convertTimeFormat(item.pickup_time)}) ${item.pickup_location_name}`,
          }))
        : [],
    );
    if (listBooking?.schedule?.is_depart_transship) {
      options.push({
        value: 'other',
        label: 'Nhập điểm trung chuyển khác',
      });
    }
    return options;
  };
  const handleOptionDropOff = () => {
    let options: any = [];
    options = options.concat(
      listBooking?.schedule?.transship_arrive
        ? listBooking.schedule.transship_arrive.map((item: any) => {
            return {
              value: item.id,
              label: `(${convertTimeFormat(item?.pickup_time)}) ${item.pickup_location_name}`,
            };
          })
        : [],
    );
    if (listBooking?.schedule?.is_arrive_transship) {
      options.push({
        value: 'other',
        label: 'Nhập điểm trung chuyển khác',
      });
    }
    return options;
  };
  const onChangePhone = (value: string) => {
    const selectedCustomer = listCustomers?.length && listCustomers?.find((customer: any) => customer.phone === value);
    if (selectedCustomer) {
      formBooking.setFieldsValue({
        customer_name: selectedCustomer.name,
        customer_email: selectedCustomer.email,
        customer_phone: selectedCustomer.phone,
      });
    }
  };
  const handleInputChange = (value: string) => {
    setCustomerPhone(value);
    setCustomerPhone(value);
  };
  const onCancelSuccess = useCallback(() => {
    onCancel();
    dispatch(resetTicketCode());
    setOpenModalNumpad(false);
    setNumCustomer(1);
    dispatch(resetPriceBooking());
    dispatch(resetDataBooking());
    showTab('1');
    dispatch(resetDestroyBooking());
    dispatch(resetBooking());
    formBooking.resetFields();
    dispatch(getListFilterBooking({ depart_date: searchDateBooking }));
    if (isMulti) {
      dispatch(
        getBookingInfo({
          route_id: provinceId,
          depart_date: searchDateBooking,
          depart_time: convertTimeFormat(searchDepartTime),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, provinceId, searchDateBooking, searchDepartTime]);
  const onFinish = (values: any) => {
    if (numCustomer === 1 && !listBooking?.schedule?.is_diagram) {
      if (
        ((values.pickup_location_id && values.pickup_location_id !== 'other') ||
          (pickUpValue === 'other' && values.pickup_extra && values.pickup_extra.trim().length >= 3)) &&
        ((values.drop_off_location_id && values.drop_off_location_id !== 'other') ||
          (dropOffValue === 'other' && values.drop_off_extra?.trim().length >= 3)) &&
        values.payment_method
      ) {
        const tmpDataNoDiagram = {
          is_diagram: false,
          ticket_code: ticketCode[0],
          schedule_id: listBooking?.schedule?.id,
          depart_date: searchDateBooking,
          booking_type: Constant.BOOKING_SINGLE,
          customer_name: values.customer_name,
          customer_phone: values.customer_phone,
          customer_email: values.customer_email,
          pickup_location_id: values.pickup_location_id === 'other' ? null : values.pickup_location_id,
          drop_off_location_id: values.drop_off_location_id === 'other' ? null : values.drop_off_location_id,
          pickup_extra: pickUpValue === 'other' ? values.pickup_extra : undefined,
          drop_off_extra: dropOffValue === 'other' ? values.drop_off_extra : undefined,
          payment_method: values.payment_method,
          is_insurance: values.is_insurance ? values.is_insurance : false,
          note: values.note,
          contact_email: values.contact_email,
          contact_phone: values.contact_phone,
          contact_name: values.contact_name,
          contact_note: values.contact_note,
          booking_for_agent: values.is_agent_id,
          agent_id: isAgentId ? values.agent_id : undefined,
          price_change: pin_code ? +values.total_price : undefined,
          pin_code: pin_code ? pin_code : undefined,
        };
        dispatch(setBookingForAgentId(values.agent_id));
        dispatch(createBooking(tmpDataNoDiagram));
      } else {
        if (!values?.payment_method) {
          dispatch(
            setToast({
              message: 'Vui lòng nhập phương thức thanh toán',
              status: true,
              type: Toast.TYPE_ERROR,
            }),
          );
        }
        if (
          (values.pickup_location_id === 'other' && !values.pickup_extra?.trim()) ||
          (values.drop_off_location_id === 'other' && !values.drop_off_extra?.trim())
        ) {
          dispatch(
            setToast({
              message: 'Vui lòng nhập điểm trung chuyển khác',
              status: true,
              type: Toast.TYPE_ERROR,
            }),
          );
        }
        if (!dropOffValue || !pickUpValue) {
          dispatch(
            setToast({
              message: 'Vui lòng nhập điểm đi và điểm đến',
              status: true,
              type: Toast.TYPE_ERROR,
            }),
          );
        }
      }
    } else {
      if (!values?.payment_method_multi) {
        dispatch(
          setToast({
            message: 'Vui lòng nhập phương thức thanh toán',
            status: true,
            type: Toast.TYPE_ERROR,
          }),
        );
      } else {
        if (isRepresent) {
          if (
            customInfo[0]?.customer_name &&
            customInfo[0]?.customer_phone &&
            customInfo[0]?.pickup_location_id &&
            customInfo[0]?.drop_off_location_id
          ) {
            const customIsRepresent = values.custom_info.reduce((acc: any, obj: any) => {
              const { seat, pickup_location_id, drop_off_location_id, ...rest } = obj;
              acc[seat] = {
                ...rest,
                customer_email: customInfo[0]?.customer_email,
                customer_name: customInfo[0]?.customer_name,
                customer_phone: customInfo[0]?.customer_phone,
                drop_off_location_id:
                  customInfo[0]?.drop_off_location_id === 'other' ? null : customInfo[0]?.drop_off_location_id,
                note: customInfo[0]?.note,
                pickup_location_id:
                  customInfo[0]?.pickup_location_id === 'other' ? null : customInfo[0]?.pickup_location_id,
              };
              return acc;
            }, {});
            const customNoDiagramIsPresent = values.custom_info.reduce((acc: any, obj: any, index: number) => {
              const { pickup_location_id, drop_off_location_id, ...rest } = obj;
              const num = index;
              acc[num] = {
                ...rest,
                customer_email: customInfo[0]?.customer_email,
                customer_name: customInfo[0]?.customer_name,
                customer_phone: customInfo[0]?.customer_phone,
                drop_off_location_id:
                  customInfo[0]?.drop_off_location_id === 'other' ? null : customInfo[0]?.drop_off_location_id,
                note: customInfo[0]?.note,
                pickup_location_id:
                  customInfo[0]?.pickup_location_id === 'other' ? null : customInfo[0]?.pickup_location_id,
              };
              return acc;
            }, {});
            const multiTicket = dataBooking?.length && dataBooking?.map((item: any) => item?.ticket_code);
            const dataCreateBooking = {
              is_diagram: listBooking?.schedule?.is_diagram ? true : false,
              schedule_id: listBooking?.schedule?.is_diagram ? undefined : listBooking?.schedule?.id,
              depart_date: listBooking?.schedule?.is_diagram ? undefined : searchDateBooking,
              booking_type: Constant.BOOKING_MULTI_CUSTOM,
              ticket_code: listBooking?.schedule?.is_diagram ? multiTicket : ticketCode,
              custom_info: listBooking?.schedule?.is_diagram ? customIsRepresent : customNoDiagramIsPresent,
              payment_method: values.payment_method_multi,
              price: values.price_multi,
              is_insurance: values.is_insurance_multi ? values.is_insurance_multi : false,
              amount: listBooking?.schedule?.is_diagram ? undefined : numCustomer,
              contact_email: values.contact_email,
              contact_phone: values.contact_phone,
              contact_name: values.contact_name,
              contact_note: values.contact_note,
              booking_for_agent: values.is_agent_id_multi,
              agent_id: isAgentIdMulti ? values.agent_id_multi : undefined,
              price_change: pin_code ? +values.price_multi : undefined,
              pin_code: pin_code ? pin_code : undefined,
            };
            dispatch(setBookingForAgentId(values.agent_id));
            dispatch(createBooking(dataCreateBooking));
          } else {
            dispatch(
              setToast({
                message: 'Vui lòng nhập đủ thông tin người đại diện',
                status: true,
                type: Toast.TYPE_ERROR,
              }),
            );
          }
        } else {
          const customInfoObject = values.custom_info.reduce((acc: any, obj: any) => {
            const { seat, ticket_code, pickup_location_id, drop_off_location_id, ...rest } = obj;
            const pickupLocationId = pickup_location_id === 'other' ? null : pickup_location_id;
            const dropOffLocationId = drop_off_location_id === 'other' ? null : drop_off_location_id;
            acc[seat] = {
              ...rest,
              pickup_location_id: pickupLocationId,
              drop_off_location_id: dropOffLocationId,
            };
            return acc;
          }, {});

          const multiTicket = dataBooking?.length && dataBooking?.map((item: any) => item?.ticket_code);
          const customNoDiagramObject = values.custom_info.reduce((acc: any, obj: any, index: number) => {
            const { ticket_code, pickup_location_id, drop_off_location_id, ...rest } = obj;
            const pickupLocationId = pickup_location_id === 'other' ? null : pickup_location_id;
            const dropOffLocationId = drop_off_location_id === 'other' ? null : drop_off_location_id;
            const num = index;
            acc[num] = {
              ...rest,
              pickup_location_id: pickupLocationId,
              drop_off_location_id: dropOffLocationId,
            };
            return acc;
          }, {});
          if (customInfoObject) {
            const isCheckBooking = Object.values(customInfoObject).every((obj: any) => {
              return (
                (obj.pickup_location_id !== undefined || obj.pickup_extra !== undefined) &&
                (obj.drop_off_location_id !== undefined || obj.drop_off_extra !== undefined)
              );
            });
            if (isCheckBooking) {
              const dataCreateBooking = {
                is_diagram: listBooking?.schedule?.is_diagram ? true : false,
                schedule_id: listBooking?.schedule?.is_diagram ? undefined : listBooking?.schedule?.id,
                depart_date: listBooking?.schedule?.is_diagram ? undefined : searchDateBooking,
                booking_type: Constant.BOOKING_MULTI_CUSTOM,
                ticket_code: listBooking?.schedule?.is_diagram ? multiTicket : ticketCode,
                custom_info: listBooking?.schedule?.is_diagram ? customInfoObject : customNoDiagramObject,
                payment_method: values.payment_method_multi,
                price: values.price_multi,
                is_insurance: values.is_insurance_multi ? values.is_insurance_multi : false,
                amount: listBooking?.schedule?.is_diagram ? undefined : numCustomer,
                contact_email: values.contact_email,
                contact_phone: values.contact_phone,
                contact_name: values.contact_name,
                contact_note: values.contact_note,
                booking_for_agent: values.is_agent_id_multi,
                agent_id: isAgentIdMulti ? values.agent_id_multi : undefined,
                price_change: pin_code ? +values.price_multi : undefined,
                pin_code: pin_code ? pin_code : undefined,
              };
              dispatch(createBooking(dataCreateBooking));
              dispatch(setBookingForAgentId(values.agent_id));
            } else {
              dispatch(
                setToast({
                  message: 'Vui lòng nhập điểm đón trả',
                  status: true,
                  type: Toast.TYPE_ERROR,
                }),
              );
            }
          }
        }
      }
    }
  };
  useEffect(() => {
    if (statusCreateUpdate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateUpdate]);

  const onChangeIsAgent = () => {
    const currentChecked = formBooking.getFieldValue('is_agent_id');
    formBooking.setFieldsValue({ is_agent_id: !currentChecked });
    if (isAgentId && booking_for_agent_id) {
      formBooking.setFieldValue('agent_id', booking_for_agent_id);
    }
  };
  const handleChangeAgentIdOption = (option: any) => {
    setCommissionPrice(option?.commission_percent);
  };
  const handleNumpadClick = () => {
    if (pin_code) {
      dispatch(setPinCode(null));
    } else {
      setOtp('');
      setOpenModalNumpad(true);
    }
  };
  const listAgentActiveOption = listAgentActive?.length
    ? listAgentActive?.map((item: any) => {
        return {
          commission_percent: item?.commission_percent,
          value: item?.id,
          label: (
            <div className="d-flex items-center gap-x-2">
              <img src={iconBookingAgent} alt="" />
              {item?.name}
            </div>
          ),
        };
      })
    : [];
  useEffect(() => {
    if (dataBooking?.length > 0 && isMulti) {
      const currentValues = formBooking.getFieldValue('custom_info') || [];
      if (Array.isArray(dataBooking)) {
        dataBooking?.forEach((item: any, index: any) => {
          currentValues[index] = {
            seat: item?.seat_number,
            ticket_code: item?.ticket_code,
          };
        });
      }
      dispatch(
        getPriceBooking({
          booking_type: Constant.BOOKING_MULTI_CUSTOM,
          schedule_id: listBooking?.schedule?.id,
          seat_number: dataBooking?.length && dataBooking?.map((item: any) => item?.seat_number),
          depart_date: listBooking?.travel_info?.depart_date,
          is_insurance: false,
          agent_id: isAgentId && agentId ? agentId : undefined,
        }),
      );
      const totalPrice = dataBooking?.length && dataBooking?.reduce((sum: any, ticket: any) => sum + ticket.price, 0);
      formBooking.setFieldsValue({
        price: dataBooking?.price,
        price_multi: formattedPrice(totalPrice),
        commission_price: formattedPrice(
          listBooking?.schedule?.agent?.commission_percent
            ? (totalPrice * (100 - +listBooking?.schedule?.agent?.commission_percent)) / 100
            : totalPrice,
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBooking, formBooking, isMulti, listBooking?.schedule?.agent?.commission_percent, isAgentId, agentId]);

  useEffect(() => {
    if (listBooking?.schedule?.id && modalOpen && numCustomer && !listBooking?.schedule?.is_diagram) {
      if (numCustomer === 1) {
        dispatch(
          getPriceBooking({
            booking_type: Constant.BOOKING_SINGLE,
            schedule_id: listBooking?.schedule?.id,
            amount: numCustomer,
            is_insurance: isInSurance ? isInSurance : false,
            pickup_location_id: pickUpValue && pickUpValue !== 'other' ? pickUpValue : null,
            drop_off_location_id: dropOffValue && dropOffValue !== 'other' ? dropOffValue : null,
            depart_date: listBooking?.travel_info?.depart_date,
            agent_id: isAgentId && agentId ? agentId : undefined,
          }),
        );
      }
      if (numCustomer > 1) {
        const isMultiInsurance = formBooking.getFieldValue('is_insurance_multi');
        const scheduleId = listBooking?.schedule?.id;
        if (scheduleId && dataBooking) {
          dispatch(
            getPriceBooking({
              booking_type: Constant.BOOKING_MULTI_CUSTOM,
              schedule_id: scheduleId,
              is_insurance: isMultiInsurance,
              depart_date: listBooking?.travel_info?.depart_date,
              amount: numCustomer,
              agent_id: isAgentIdMulti && agentIdMulti ? agentIdMulti : undefined,
            }),
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, modalOpen, pickUpValue, isInSurance, dropOffValue, listBooking, numCustomer, isAgentId, agentId]);

  useEffect(() => {
    if (listBooking?.schedule?.is_diagram) {
      if (price && !customInfo?.length) {
        formBooking.setFieldValue('price', price);
      }
      if (price && customInfo?.length > 0) {
        formBooking.setFieldValue('price_multi', price?.total_price);
        formBooking.setFieldValue('commission_price', price?.commission_price);
      }
    } else {
      if (!!price && numCustomer > 1) {
        if (activeKey === '1') {
          formBooking.setFieldValue('price_multi', price?.total_price);
          formBooking.setFieldValue('commission_price', price?.commission_price);
        }
      }
    }
  }, [price, formBooking, customInfo, listBooking, numCustomer, activeKey]);

  useEffect(() => {
    if (price && !listBooking?.schedule?.is_diagram && numCustomer === 1) {
      formBooking.setFieldValue('total_price', price?.total_price);
    }
    if (price && !listBooking?.schedule?.is_diagram && numCustomer === 1 && group === USER_GROUP.AGENT) {
      formBooking.setFieldValue('commission_price', price?.commission_price);
    }
    if (price && !listBooking?.schedule?.is_diagram && isAgentId && agentId) {
      formBooking.setFieldValue('commission_price', price?.commission_price);
    }
  }, [price, formBooking, listBooking, numCustomer, group, isAgentId, agentId]);
  useEffect(() => {
    if (ticketCode && activeKey !== '2' && !listBooking?.schedule?.is_diagram) {
      formBooking.setFieldValue('ticket_code', ticketCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketCode, listBooking, activeKey]);

  useEffect(() => {
    if (!listBooking?.schedule?.is_diagram && numCustomer && isBookingNoDiagram) {
      dispatch(getTicketCodeBooking(numCustomer));
      formBooking.setFieldValue('num_customer', numCustomer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numCustomer, dispatch, listBooking, isBookingNoDiagram]);

  useEffect(() => {
    if (countdownFinished) {
      modalHandleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownFinished]);

  useEffect(() => {
    if (booking_for_agent_id) {
      formBooking.setFieldValue('agent_id', booking_for_agent_id);
    }
  }, [booking_for_agent_id, formBooking]);
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={modalHandleOk}
        onCancel={modalHandleCancel}
        cancelText={cancelText}
        okText={okText}
        footer={[]}
        centered
        width={916}
        confirmLoading={loadingHold}
        className="modal-bus-booking"
        maskClosable={false}
      >
        <Card className="card-modal-booking">
          <Form
            layout="vertical"
            className="mt-2 form-booking-multi"
            form={formBooking}
            onFinish={onFinish}
            autoComplete="off"
            validateTrigger="onSubmit"
            name="form-booking-diagram"
            labelAlign={'left'}
            initialValues={{
              is_insurance_multi: false,
              is_represent: false,
              is_agent_id: false,
            }}
            labelWrap
            onFieldsChange={(allFields) => {
              scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
            }}
          >
            <Row>
              <Col span={18}>
                <Typography.Text className="fw-600 fs-18">{t('Thông tin hành khách')}</Typography.Text>
              </Col>
              <Col>
                <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 9)} />
              </Col>
            </Row>

            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Col>
                <HeaderBooking listBooking={listBooking} />
              </Col>
              <Col>
                <Countdown
                  className="countdown-booking mt-2"
                  title="Thời gian còn lại:"
                  value={targetTime}
                  onChange={onChange}
                  format="mm:ss"
                  onFinish={onFinishCountdown}
                />
              </Col>
            </Row>
            <Row>
              {!listBooking?.schedule?.is_diagram && (
                <Col className="d-flex">
                  <Typography className="fs-16 fw-500 mt-2">
                    Vui lòng chọn số lượng hành khách <span style={{ color: '#D81717' }}>*</span>
                  </Typography>
                  <div className="block-num-customer mt-1">
                    <Button className="btn-num btn-up" onClick={() => handleDownCustomer()}>
                      <span className="icon">
                        <MinusOutlined />
                      </span>
                    </Button>
                    <Typography className="fw-500 fs-16">{numCustomer}</Typography>
                    <Button
                      className="btn-num btn-down"
                      disabled={
                        dataSearchBooking?.empty_seat &&
                        !listBooking?.schedule?.is_diagram &&
                        +dataSearchBooking?.empty_seat === numCustomer
                      }
                      onClick={() => handleUpCustomer()}
                    >
                      <span className="icon">
                        <PlusOutlined />
                      </span>
                    </Button>
                  </div>
                  <Form.Item className="d-none" name="num_customer">
                    <Input></Input>
                  </Form.Item>
                </Col>
              )}
            </Row>
            {numCustomer === 1 && !dataBooking?.seat_number && !loadingHold && !listBooking?.schedule?.is_diagram ? (
              <Spin spinning={loadingTicket}>
                <Row className="tab-booking-container mt-3">
                  <Col className="w-100">
                    <Tabs
                      type="card"
                      className="booking-multi"
                      activeKey={activeKey}
                      onChange={showTab}
                      items={[
                        {
                          label: 'Nhập thông tin hành khách',
                          key: '1',
                          children: (
                            <>
                              <Row gutter={16} className="mt-2">
                                <Col span={5}>
                                  <Form.Item
                                    name="name_seat"
                                    label={t('Ghế đang chọn')}
                                    className="w-100 booking-input-disable"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item name="ticket_code" label={t('Mã vé')} className="booking-input-disable">
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                                <Col className="d-flex mt-4" style={{ columnGap: '11px' }}>
                                  <Form.Item name="is_insurance" className="booking-input-disable">
                                    <Switch
                                      disabled={
                                        !listBooking?.policy?.insurance_policy?.percent &&
                                        !listBooking?.policy?.insurance_policy?.price
                                      }
                                    />
                                  </Form.Item>
                                  <div className="mt-2" style={{ columnGap: '5px' }}>
                                    <Typography.Text className="fw-500 fs-14">
                                      {t(
                                        `Bảo hiểm chuyến đi (${
                                          listBooking?.policy?.insurance_policy?.percent
                                            ? `${listBooking?.policy?.insurance_policy?.percent}% giá vé`
                                            : `${formattedPrice(listBooking?.policy?.insurance_policy?.price)}đ/ghế`
                                        }) `,
                                      )}
                                    </Typography.Text>
                                    <Tooltip title={'Xem chi tiết chính sách bảo hiểm'}>
                                      <InfoCircleFilled
                                        style={{ color: '#F8B507' }}
                                        onClick={() => setOpenInfoInsurance(true)}
                                      />
                                    </Tooltip>
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={12}>
                                <Col span={6}>
                                  <Form.Item
                                    name="customer_phone"
                                    className="require"
                                    label={t('Số điện thoại')}
                                    validateFirst
                                    rules={validateMobileUser}
                                    validateTrigger={['onChange', 'onBlur']}
                                  >
                                    <Input
                                      className="input-h36 input-phone"
                                      placeholder={t('Nhập số điện thoại')}
                                      size="large"
                                      value={customerPhone}
                                      onChange={(e) => handleInputChange(e.target.value)}
                                      onBlur={() => onChangePhone(customerPhone)}
                                      onWheel={(event) => event.currentTarget.blur()}
                                      maxLength={12}
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={9}>
                                  <Form.Item
                                    name="customer_name"
                                    label={t('Họ và tên')}
                                    className="require"
                                    validateTrigger={['onChange', 'onBlur']}
                                  >
                                    <Input placeholder="Nhập họ và tên" />
                                  </Form.Item>
                                </Col>
                                <Col span={9}>
                                  <Form.Item
                                    name="customer_email"
                                    label={t('Email')}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={validatorEmailBooking}
                                  >
                                    <Input placeholder="Nhập email" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="pickup_location_id"
                                    className="form-item-name require"
                                    label={t('Đón tại')}
                                    validateTrigger={['onChange', 'onBlur']}
                                  >
                                    <Select
                                      showSearch
                                      filterOption={filterOption}
                                      className="input-h36"
                                      placeholder="Chọn điểm đón"
                                      size="large"
                                      style={{ width: '100%' }}
                                      options={handleOptionPickup()}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="drop_off_location_id"
                                    className="form-item-name require"
                                    label={t('Trả tại')}
                                    validateTrigger={['onChange', 'onBlur']}
                                  >
                                    <Select
                                      showSearch
                                      filterOption={filterOption}
                                      className="input-h36"
                                      placeholder="Chọn điểm đón"
                                      size="large"
                                      style={{ width: '100%' }}
                                      options={handleOptionDropOff()}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16} style={{ display: 'flex' }}>
                                {pickUpValue === 'other' && (
                                  <Col span={12}>
                                    <Form.Item
                                      name="pickup_extra"
                                      className="form-item-name require"
                                      validateTrigger={['onChange', 'onBlur']}
                                    >
                                      <Input
                                        className="input-h36"
                                        placeholder="Chọn điểm đón khác"
                                        size="large"
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                                {pickUpValue !== 'other' && dropOffValue === 'other' && <Col span={12}></Col>}
                                {dropOffValue === 'other' && (
                                  <Col span={12}>
                                    <Form.Item
                                      name="drop_off_extra"
                                      className="form-item-name require"
                                      validateTrigger={['onChange', 'onBlur']}
                                    >
                                      <Input
                                        className="input-h36"
                                        placeholder="Nhập điểm trả khác"
                                        size="large"
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                              </Row>
                              <Row gutter={20}>
                                <Col span={6}>
                                  {group !== USER_GROUP.AGENT ? (
                                    <Flex className="block-numpad-input">
                                      <Form.Item
                                        name="total_price"
                                        label={t('Tổng tiền')}
                                        className="booking-input-disable price"
                                      >
                                        <InputNumber
                                          suffix="VND"
                                          disabled={!pin_code}
                                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                          parser={(value) => value?.replace(/[^0-9]/g, '') as unknown as number}
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      <div
                                        className="btn-numpad"
                                        onClick={() => handleNumpadClick()}
                                        style={{ marginTop: '25px' }}
                                      >
                                        <img src={pin_code ? iconCancelPinCode : iconNumpad} alt="" />
                                      </div>
                                    </Flex>
                                  ) : (
                                    <>
                                      <Form.Item
                                        name="total_price"
                                        label={t('Tổng tiền vé')}
                                        className="booking-input-disable price"
                                      >
                                        <Input suffix="VND" disabled />
                                      </Form.Item>
                                    </>
                                  )}
                                  {isInSurance && (
                                    <Typography className="fs-14 fw-500 text-insurance">
                                      {listBooking?.policy?.insurance_policy?.percent
                                        ? `+${listBooking?.policy?.insurance_policy?.percent}% tiền bảo hiểm`
                                        : `+${formattedPrice(
                                            listBooking?.policy?.insurance_policy?.price,
                                          )}đ tiền bảo hiểm`}
                                    </Typography>
                                  )}
                                </Col>
                                {group === USER_GROUP.AGENT && (
                                  <Col span={6}>
                                    <Form.Item
                                      name="commission_price"
                                      label={t('Tổng tiền đại lý trả')}
                                      className="booking-input-disable price"
                                    >
                                      <Input suffix="VND" disabled />
                                    </Form.Item>
                                    <Typography className="fs-14 fw-500 text-discount">
                                      {`Đã trừ ${
                                        listBooking?.schedule?.agent?.commission_percent
                                          ? listBooking?.schedule?.agent?.commission_percent
                                          : '0'
                                      }% chiết khấu`}
                                    </Typography>
                                  </Col>
                                )}
                                {isAgentId && agentId && commissionPrice !== 0 ? (
                                  <Col span={6}>
                                    <Spin spinning={!commisionPrice}>
                                      <Form.Item
                                        name="commission_price"
                                        label={t('Tổng tiền đại lý trả')}
                                        className="booking-input-disable price"
                                      >
                                        <Input suffix="VND" disabled onLoad={agentId} onLoadedData={agentId} />
                                      </Form.Item>
                                      <Typography className="fs-14 fw-500 text-discount">{`Đã trừ ${commissionPrice}% chiết khấu`}</Typography>
                                    </Spin>
                                  </Col>
                                ) : (
                                  ''
                                )}
                                <Col span={group === USER_GROUP.AGENT || (isAgentId && agentId) ? 12 : 18}>
                                  <Form.Item name="note" label={t('Ghi chú')}>
                                    <Input placeholder="Nhập ghi chú" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Form.Item
                                    name="payment_method"
                                    label={t('Phương thức thanh toán')}
                                    className="require"
                                    required={true}
                                  >
                                    <Radio.Group
                                      options={
                                        group !== USER_GROUP.AGENT
                                          ? isDepart
                                            ? paymentMultiDepartedOptions
                                            : paymentMultiOptions
                                          : isDepart
                                          ? paymentAgentDepartOptions
                                          : paymentAgentOptions
                                      }
                                      onChange={onChangePayment}
                                      value={paymentMethod}
                                      buttonStyle="solid"
                                      className="payment-checkbox-group"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          ),
                        },
                        {
                          label: 'Nhập thông tin người liên hệ',
                          key: '2',
                          children: <ModalMultiBooking2 />,
                        },
                      ]}
                    />
                  </Col>
                </Row>
                {group === USER_GROUP.CAR_COMPANY ? (
                  <div className="agent-booking-block" style={{ position: 'absolute' }}>
                    <div onClick={onChangeIsAgent} className={`cursor-pointer h-fit`}>
                      <Form.Item name="is_agent_id" className="is-agent" valuePropName="checked">
                        <Checkbox />
                      </Form.Item>
                    </div>
                    <Form.Item name="agent_id" className="is-agent-select" required={isAgentId ? true : false}>
                      <Select
                        className="input-h36"
                        disabled={!isAgentId}
                        placeholder={
                          <div
                            className="d-flex justify-center items-center "
                            style={{ columnGap: '10px', width: '150px' }}
                          >
                            <img src={iconBookingAgent} alt="" /> Đặt hộ đại lý
                          </div>
                        }
                        size="large"
                        options={[
                          // {
                          //   value: '',
                          //   label: (
                          //     <div className="d-flex items-center gap-x-2">
                          //       <img src={iconBookingAgent} alt="" /> Không
                          //     </div>
                          //   ),
                          // },
                          ...listAgentActiveOption,
                        ]}
                        showSearch
                        onChange={(value, option) => handleChangeAgentIdOption(option)}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </div>
                ) : (
                  ''
                )}
              </Spin>
            ) : (
              <Row className="tab-booking-container mt-3">
                <Col className="w-100">
                  <Tabs
                    type="card"
                    className="booking-multi"
                    activeKey={activeKey}
                    onChange={showTab}
                    items={[
                      {
                        label: 'Nhập thông tin hành khách',
                        key: '1',
                        children: (
                          <ModalMultiBooking1
                            dataBooking={dataBooking}
                            listBooking={listBooking}
                            formBooking={formBooking}
                            activeKey={activeKey}
                            numCustomer={numCustomer}
                            loadingHold={loadingHold}
                            isDepart={isDepart}
                            openModalNumpad={openModalNumpad}
                            setOpenModalNumpad={setOpenModalNumpad}
                          />
                        ),
                      },
                      {
                        label: 'Nhập thông tin người liên hệ',
                        key: '2',
                        children: <ModalMultiBooking2 />,
                      },
                    ]}
                  />
                </Col>
              </Row>
            )}
            <Row className="d-flex justify-content-end" style={{ columnGap: '12px' }}>
              <Button key="cancel" className=" w-160 h-btn-48" onClick={() => modalHandleCancel()}>
                {cancelText}
              </Button>
              <Button
                key="ok"
                type="primary"
                className="w-160 h-btn-48 btn-success"
                style={{ marginRight: '5px' }}
                htmlType="submit"
              >
                {okText}
              </Button>
            </Row>
          </Form>
          <ModalNumpad
            modalOpen={openModalNumpad}
            onCancel={() => {
              setOpenModalNumpad(false);
              setOtp('');
            }}
            onOk={() => false}
            otp={otp}
            setOtp={setOtp}
          />
          <ModalInfoInsurance
            modalOpen={openInfoInsurance}
            onCancel={() => setOpenInfoInsurance(false)}
            onOk={() => false}
            listBooking={listBooking}
          />
        </Card>
      </Modal>
    </>
  );
};
export default ModalMultiBooking;
