import {
  BookingInfoState,
  CancelBooking,
  DestroyParams,
  ListBookingCustomerType,
  ListDepartTimeType,
  LockAllSeatParams,
  MoveTicketParams,
  ParamsBookingSearch,
  ParamsCreateBooking,
  ParamsDriverBooking,
  ParamsFilterBooking,
  ParamsHistoryBooking,
  ParamsHolding,
  ParamsPriceBooking,
  UpdateLocationParams,
  getBookingByIdType,
} from '../types/booking';
import { getAxiosClientWithToken } from './index';

export const postHoldingTicketApi = (params: ParamsHolding) => {
  return getAxiosClientWithToken().post('/bookings/hold', params);
};
export const createBookingApi = (params: ParamsCreateBooking) => {
  return getAxiosClientWithToken().post(`/bookings/update`, params);
};
export const cancelBookingApi = (params: CancelBooking) => {
  const restClient = getAxiosClientWithToken();
  return restClient.post(`/bookings/cancel`, params);
};
export const destroyHoldApi = (params: DestroyParams) => {
  const restClient = getAxiosClientWithToken();
  return restClient.delete(`/bookings/destroy-hold`, { params });
};
export const updateBookingStatusApi = (params: {
  id: string;
  status: number;
  seat_number: number;
  depart_date: string;
}) => {
  const restClient = getAxiosClientWithToken();
  return restClient.post(`/bookings/lock`, { ...params, status: params.status === 0 ? 'unlock' : 'lock' });
};
export const getBookingByIdApi = (params: getBookingByIdType) => {
  return getAxiosClientWithToken().get(`/bookings/detail`, { params });
};
export const getBookingInfoApi = (params: BookingInfoState) => {
  return getAxiosClientWithToken().get(`/bookings`, { params });
};
export const getListDepartTimeApi = (params: ListDepartTimeType) => {
  return getAxiosClientWithToken().get(`/bookings/get-depart-time`, { params });
};
export const getListBookingCustomerApi = (params: ListBookingCustomerType) => {
  return getAxiosClientWithToken().get(`/bookings/customers`, { params });
};
export const getListHistoryBookingApi = (params: ParamsHistoryBooking) => {
  return getAxiosClientWithToken().get(`/bookings/history`, { params });
};
export const getFilterBookingApi = (params: ParamsFilterBooking) => {
  return getAxiosClientWithToken().get(`/bookings/filter`, { params });
};
export const getBookingSearchApi = (params: ParamsBookingSearch) => {
  return getAxiosClientWithToken().get(`/bookings/search`, { params });
};
export const updateDriverBookingApi = (params: ParamsDriverBooking) => {
  return getAxiosClientWithToken().post(`/bookings/update-driver`, params);
};
export const getFilterCustomerApi = (params: any) => {
  return getAxiosClientWithToken().get(`/customer`, { params });
};
export const getPriceBookingApi = (params: ParamsPriceBooking) => {
  return getAxiosClientWithToken().get(`/bookings/price`, { params });
};
export const getBookingSendMailApi = (bookingId?: any) => {
  return getAxiosClientWithToken().get(`/bookings/send-mail?booking_id=${bookingId}`);
};
export const getSendMailOrderApi = (params: { ticket_code: string }) => {
  return getAxiosClientWithToken().get(`/orders/send-mail`, { params });
};
export const getBookingPrintTicketApi = (params?: any) => {
  return getAxiosClientWithToken().get(`/bookings/print-ticket`, { params });
};
export const getCodeTicketApi = (amount: number) => {
  return getAxiosClientWithToken().get(`/bookings/generate-code?amount=${amount}`);
};
export const getListStaffApi = () => {
  return getAxiosClientWithToken().get(`/bookings/list-staff`);
};
export const getBookingCustomerApi = (params: any) => {
  return getAxiosClientWithToken().get(`/bookings/customers?${params}`);
};
export const lockAllSeatApi = (params: LockAllSeatParams) => {
  return getAxiosClientWithToken().post(`/bookings/lock-all`, params);
};
export const unlockAllSeatApi = (params: LockAllSeatParams) => {
  return getAxiosClientWithToken().post(`/bookings/unlock-all`, params);
};
export const postMoveTicketApi = (params: MoveTicketParams) => {
  return getAxiosClientWithToken().post(`/bookings/move`, params);
};
export const postUpdatePickUpApi = (params: UpdateLocationParams) => {
  return getAxiosClientWithToken().post(`/bookings/update-pickup`, params);
};
export const postCheckPinCodeApi = (params: { pin_code: string }) => {
  return getAxiosClientWithToken().get(`/user/check-pin-code`, { params });
};
