import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const ModalLocationShow: React.FC<any> = ({ okText, cancelText, modalOpen, onCancel, onOk, listImage }) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const navigate = useNavigate();

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
    navigate('/dashboard/account-deposit-carlink');
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      width={800}
      className="modal-schedule-show"
    >
      { listImage?.length ===1? 
        <img src={listImage[0]} alt="" style={{maxWidth:'760px',objectFit:'contain'}}/>
      :
      <Swiper
        spaceBetween={30}
        observer={true}
        observeParents={true}
        parallax={true}
        loop={true}
        autoplay={true}
        simulateTouch={true}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination]}
        className="mySwiper"
      >
        {listImage?.map((img: any, index: string) => (
          <SwiperSlide key={index}>
            <img src={img} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
}
    </Modal>
  );
};

export default ModalLocationShow;
