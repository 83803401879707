import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRechargeHistories } from "../../../api/history";

// First, create the thunk
export const fetchHistory = createAsyncThunk(
  "news/fetchHistory",
  async ({ query }: { query: string }) => {
    try {
      const response = await getRechargeHistories(query);
      return response.data;
    } catch (error) {
      
    }
  }
);

const initialState: any = {
  isFetching: true,
  pagination: {
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
  },
  result: [],
  total_confirmed: "0",
  total_waiting: "0",
  total_cancel: "0"
};

export const historySlide = createSlice({
  name: "news",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchHistory.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(fetchHistory.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      state.isFetching = false;
      state.pagination = action.payload?.pagination;
      state.result = action.payload?.data;
      state.total_confirmed = action.payload?.total_confirmed;
      state.total_waiting = action.payload?.total_waiting;
      state.total_cancel = action.payload?.total_cancel;
    });
  },
});

export const {} = historySlide.actions;

export default historySlide.reducer;
