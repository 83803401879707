import { Defs, Document, Font, G, Image, Page, Path, Rect, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import { Constant, DateFormat, USER_GROUP } from '../../../config/constant';
import { convertTimeFormat, formattedPrice, getPaymentMethod } from '../../../lib/utils';
import dayjs from 'dayjs';
export const typeSeatItems1 = [
  {
    id: 1,
    name: 'Ghế tài xế',
    image: (
      <Svg width={28} height={20} viewBox="0 0 101 55">
        <Path
          d="M41 27.8046V27.3418V11.3563C41.414 9.52874 44.1053 5.87356 51.5579 5.87356C51.9719 4.24904 53.5453 1 56.5263 1H85.7158C86.8806 1.19042 89.1376 2.09512 89.4141 4.21644C89.9891 4.11017 90.621 4.04598 91.3053 4.04598C95.0316 4.04598 100 5.26437 100 12.5747V25.977V29.023V42.4253C100 49.7356 95.0316 50.954 91.3053 50.954C90.621 50.954 89.9891 50.8898 89.4141 50.7836C89.1376 52.9049 86.8806 53.8096 85.7158 54H56.5263C53.6323 54 52.065 50.9379 51.5971 49.2728H51.5579C44.1053 49.2728 41.414 45.6176 41 43.79V27.8046Z"
          fill="#E4E4E4"
        />
        <Path
          d="M41 27.8046V11.3563C41.414 9.52874 44.1053 5.87356 51.5579 5.87356M51.5579 5.87356H85.0947C86.3368 6.48276 88.8211 9.89425 88.8211 18.6667V27.8046M51.5579 5.87356C51.9719 4.24904 53.5453 1 56.5263 1H85.7158C86.9579 1.20307 89.4421 2.21839 89.4421 4.65517M41 27.3418V43.79C41.414 45.6176 44.1053 49.2728 51.5579 49.2728H85.0947C86.3368 48.6636 88.8211 45.2521 88.8211 36.4797V27.3418M85.7158 5.87356C86.3368 5.26437 88.3242 4.04598 91.3053 4.04598C95.0316 4.04598 100 5.26437 100 12.5747V29.023M85.7158 49.1264C86.3368 49.7356 88.3242 50.954 91.3053 50.954C95.0316 50.954 100 49.7356 100 42.4253V25.977M51.5579 49.1264C51.9719 50.751 53.5453 54 56.5263 54H85.7158C86.9579 53.7969 89.4421 52.7816 89.4421 50.3448"
          stroke="#ABABAB"
        />
        <Path
          d="M1 28C1 29.8385 1.36212 31.659 2.06569 33.3576C2.76925 35.0561 3.80048 36.5995 5.1005 37.8995C6.40053 39.1995 7.94387 40.2307 9.64243 40.9343C11.341 41.6379 13.1615 42 15 42C16.8385 42 18.659 41.6379 20.3576 40.9343C22.0561 40.2307 23.5995 39.1995 24.8995 37.8995C26.1995 36.5995 27.2307 35.0561 27.9343 33.3576C28.6379 31.659 29 29.8385 29 28C29 26.1615 28.6379 24.341 27.9343 22.6424C27.2307 20.9439 26.1995 19.4005 24.8995 18.1005C23.5995 16.8005 22.0561 15.7693 20.3576 15.0657C18.659 14.3621 16.8385 14 15 14C13.1615 14 11.341 14.3621 9.64243 15.0657C7.94387 15.7693 6.40053 16.8005 5.1005 18.1005C3.80048 19.4005 2.76925 20.9439 2.06569 22.6424C1.36212 24.341 1 26.1615 1 28Z"
          stroke="#435869"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M11.8887 27.9998C11.8887 28.8249 12.2164 29.6162 12.7999 30.1997C13.3833 30.7831 14.1747 31.1109 14.9998 31.1109M11.8887 27.9998C11.8887 27.1747 12.2164 26.3833 12.7999 25.7999C13.3833 25.2164 14.1747 24.8887 14.9998 24.8887C15.8249 24.8887 16.6162 25.2164 17.1997 25.7999C17.7831 26.3833 18.1109 27.1747 18.1109 27.9998M11.8887 27.9998L1.38867 24.8887M14.9998 31.1109C15.8249 31.1109 16.6162 30.7831 17.1997 30.1997C17.7831 29.6162 18.1109 28.8249 18.1109 27.9998M14.9998 31.1109V41.9998M18.1109 27.9998L28.6109 24.8887"
          stroke="#435869"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </Svg>
    ),
    type: Constant.DRIVER_SEAT,
  },
  {
    id: 2,
    name: 'Ghế phụ xe',
    image: (
      <Svg width={16} height={20} viewBox="0 0 25 55">
        <Path
          d="M1 27.8046V27.3418V11.3563C1.41404 9.52874 2.49997 5.87356 6.99998 5.87356C7.41402 4.24904 6.01895 1 9 1H10.2156C11.3804 1.19042 13.6374 2.09512 13.9139 4.21644C14.489 4.11017 15.1208 4.04598 15.8051 4.04598C19.5314 4.04598 24.4998 5.26437 24.4998 12.5747V25.977V29.023V42.4253C24.4998 49.7356 19.5314 50.954 15.8051 50.954C15.1208 50.954 14.489 50.8898 13.9139 50.7836C13.6374 52.9049 11.3804 53.8096 10.2156 54H9C6.106 54 7.33627 50.9379 7.03178 49.2728H6.99998C2.50003 49.2728 1.41404 45.6176 1 43.79V27.8046Z"
          fill="#E4E4E4"
        />
        <Path
          d="M1 27.8046V11.3563C1.41404 9.52874 2.49997 5.87356 6.99998 5.87356M6.99998 5.87356H9.59456C10.8367 6.48276 13.3209 9.89425 13.3209 18.6667V27.8046M6.99998 5.87356C7.41402 4.24904 6.01895 1 9 1L10.2156 1C11.4577 1.20307 13.9419 2.21839 13.9419 4.65517M1 27.3418V43.79C1.41404 45.6176 2.50003 49.2728 6.99998 49.2728H9.59456C10.8367 48.6636 13.3209 45.2521 13.3209 36.4797V27.3418M10.2156 5.87356C10.8367 5.26437 12.824 4.04598 15.8051 4.04598C19.5314 4.04598 24.4998 5.26437 24.4998 12.5747V29.023M10.2156 49.1264C10.8367 49.7356 12.824 50.954 15.8051 50.954C19.5314 50.954 24.4998 49.7356 24.4998 42.4253V25.977M6.99998 49.1264C7.41402 50.751 6.01895 54 9 54H10.2156C11.4577 53.7969 13.9419 52.7816 13.9419 50.3448"
          stroke="#ABABAB"
        />
      </Svg>
    ),
    type: Constant.EXTRA_DRIVER_SEAT,
  },
  {
    id: 3,
    name: 'Ghế ngồi',
    image: (
      <Svg width={20} height={16} viewBox="0 0 59 54">
        <Path
          d="M1 27.2988V11.1609C1.4 9.36782 4 5.78161 11.2 5.78161M11.2 5.78161H43.6C44.8 6.37931 47.2 9.72644 47.2 18.3333V27.2988M11.2 5.78161C11.6 4.18774 13.12 1 16 1H44.2C45.4 1.19923 47.8 2.1954 47.8 4.58621M1 26.8447V42.9827C1.4 44.7758 4 48.362 11.2 48.362H43.6C44.8 47.7643 47.2 44.4172 47.2 35.8103V26.8447M44.2 5.78161C44.8 5.18391 46.72 3.98851 49.6 3.98851C53.2 3.98851 58 5.18391 58 12.3563V28.4943M44.2 48.2184C44.8 48.8161 46.72 50.0115 49.6 50.0115C53.2 50.0115 58 48.8161 58 41.6437V25.5057M11.2 48.2184C11.6 49.8123 13.12 53 16 53H44.2C45.4 52.8008 47.8 51.8046 47.8 49.4138"
          stroke="#435869"
        />
      </Svg>
    ),
    type: Constant.NORMAL_SEAT,
  },
  {
    id: 4,
    name: 'Ghế giường nằm',
    image: (
      <Svg width={24} height={16} viewBox="0 0 87 55">
        <Path d="M1 27.1952V26.7324V10.7471C1.40613 8.91949 4.65517 5.87353 11.9655 5.87353C12.3716 4.24902 13.9149 1 16.8391 1H64.7042C65.9009 1.19946 68.2733 2.18265 68.357 4.52688C72.4866 4.24714 76.283 4.04596 77.4713 4.04596C81.1264 4.04596 86 5.26434 86 12.5746V25.9769V29.0228V42.425C86 49.7353 81.1264 50.9537 77.4713 50.9537C76.283 50.9537 72.4866 50.7525 68.3571 50.4728C68.2735 52.8173 65.901 53.8005 64.7042 54L16.8391 53.9997C14.0003 53.9997 12.463 50.9376 12.004 49.2725H11.9655C4.65517 49.2725 1.40613 45.0081 1 43.1806V27.1952Z" />
        <Path
          d="M1 27.1952V10.7471C1.40613 8.91949 4.65517 5.87353 11.9655 5.87353M11.9655 5.87353H54.6092C55.8276 6.48272 58.2644 9.8942 58.2644 18.6666V27.8044M11.9655 5.87353C12.3716 4.24902 13.9149 1 16.8391 1L64.7042 1C65.9225 1.20306 68.3593 2.21841 68.3593 4.65517M1 26.7324V43.1806C1.40613 45.0081 4.65517 49.2725 11.9655 49.2725H54.6092C55.8276 48.6633 58.2644 45.2518 58.2644 36.4795V27.3416M55.2184 5.87353C55.8276 5.26434 74.5471 4.04596 77.4713 4.04596C81.1264 4.04596 86 5.26434 86 12.5746V29.0228M55.2184 49.1261C55.8276 49.7353 74.5471 50.9537 77.4713 50.9537C81.1264 50.9537 86 49.7353 86 42.425V25.9769M11.9655 49.1261C12.3716 50.7506 13.9149 53.9997 16.8391 53.9997L64.7042 54C65.9225 53.7969 68.3593 52.7816 68.3593 50.3448"
          stroke="#435869"
        />
      </Svg>
    ),
    type: Constant.BED_SEAT,
  },
  {
    id: 5,
    name: 'Cabin đơn',
    image: (
      <Svg width={24} height={16} viewBox="0 0 87 55">
        <Path d="M1 5C1 2.79086 2.79086 1 5 1H115C117.209 1 119 2.79086 119 5V50C119 52.2091 117.209 54 115 54H5C2.79086 54 1 52.2091 1 50V5Z" />
        <Path d="M6 10C6 7.79086 7.79086 6 10 6H20C22.2091 6 24 7.79086 24 10V45C24 47.2091 22.2091 49 20 49H10C7.79086 49 6 47.2091 6 45V10Z" />
        <Path d="M31 13.908C31.3297 12.4252 31.5657 9.99741 37.5 9.99741C37.8297 8.67941 39.6263 6 42 6H94.7128C95.6843 6.16183 97.6101 6.95951 97.6781 8.86143C101.03 8.63447 104.112 8.47125 105.077 8.47125C108.044 8.47125 112 9.45975 112 15.3907V39.609C112 45.54 108.044 46.5285 105.077 46.5285C104.112 46.5285 101.03 46.3653 97.6781 46.1383C97.6103 48.0404 95.6843 48.8382 94.7128 49L42 48.9997C39.7201 48.9997 37.9725 46.5679 37.5459 45.2079L37.5434 45.2079C31.6091 45.2079 31.3297 41.7047 31 40.222V13.908Z" />
        <Path
          d="M37.5 9.99741C31.5657 9.99741 31.3297 12.4252 31 13.908V40.222C31.3297 41.7047 31.6091 45.2079 37.5434 45.2079L37.5459 45.2079M37.5 9.99741L86.5181 9.954C87.5071 10.4482 89.4852 13.216 89.4852 20.3332V34.7852C89.4852 41.9024 87.5071 44.6702 86.5181 45.1645L37.5459 45.2079M37.5 9.99741C37.8297 8.67941 39.6263 6 42 6H94.7128C95.6843 6.16183 97.6101 6.95951 97.6781 8.86143M37.5459 45.2079C37.5283 45.1519 37.513 45.0978 37.5 45.0457M37.5459 45.2079C37.9725 46.5679 39.7201 48.9997 42 48.9997L94.7128 49C95.6843 48.8382 97.6103 48.0404 97.6781 46.1383M87.0126 9.954C87.3061 9.66059 92.7807 9.193 97.6781 8.86143M97.6781 8.86143C101.03 8.63447 104.112 8.47125 105.077 8.47125C108.044 8.47125 112 9.45975 112 15.3907V39.609C112 45.54 108.044 46.5285 105.077 46.5285C104.112 46.5285 101.03 46.3653 97.6781 46.1383M97.6781 8.86143C97.6793 8.89577 97.6799 8.93046 97.6799 8.96552M87.0126 45.0457C87.3061 45.3391 92.7807 45.8067 97.6781 46.1383M97.6781 46.1383C97.6793 46.1041 97.6799 46.0695 97.6799 46.0345M5 54H115C117.209 54 119 52.2091 119 50V5C119 2.79086 117.209 1 115 1H5C2.79086 1 1 2.79086 1 5V50C1 52.2091 2.79086 54 5 54ZM10 49H20C22.2091 49 24 47.2091 24 45V10C24 7.79086 22.2091 6 20 6H10C7.79086 6 6 7.79086 6 10V45C6 47.2091 7.79086 49 10 49Z"
          stroke="#435869"
        />
      </Svg>
    ),
    type: Constant.SINGLE_CABIN,
  },
  {
    id: 6,
    name: 'Cabin đôi',
    image: (
      <Svg width={24} height={20} viewBox="0 0 120 90">
        <Path d="M1 27.1952V26.7324V10.7471C1.40613 8.91949 4.65517 5.87353 11.9655 5.87353C12.3716 4.24902 13.9149 1 16.8391 1H64.7042C65.9009 1.19946 68.2733 2.18265 68.357 4.52688C72.4866 4.24714 76.283 4.04596 77.4713 4.04596C81.1264 4.04596 86 5.26434 86 12.5746V25.9769V29.0228V42.425C86 49.7353 81.1264 50.9537 77.4713 50.9537C76.283 50.9537 72.4866 50.7525 68.3571 50.4728C68.2735 52.8173 65.901 53.8005 64.7042 54L16.8391 53.9997C14.0003 53.9997 12.463 50.9376 12.004 49.2725H11.9655C4.65517 49.2725 1.40613 45.0081 1 43.1806V27.1952Z" />
        <Path
          d="M1 27.1952V10.7471C1.40613 8.91949 4.65517 5.87353 11.9655 5.87353M11.9655 5.87353H54.6092C55.8276 6.48272 58.2644 9.8942 58.2644 18.6666V27.8044M11.9655 5.87353C12.3716 4.24902 13.9149 1 16.8391 1L64.7042 1C65.9225 1.20306 68.3593 2.21841 68.3593 4.65517M1 26.7324V43.1806C1.40613 45.0081 4.65517 49.2725 11.9655 49.2725H54.6092C55.8276 48.6633 58.2644 45.2518 58.2644 36.4795V27.3416M55.2184 5.87353C55.8276 5.26434 74.5471 4.04596 77.4713 4.04596C81.1264 4.04596 86 5.26434 86 12.5746V29.0228M55.2184 49.1261C55.8276 49.7353 74.5471 50.9537 77.4713 50.9537C81.1264 50.9537 86 49.7353 86 42.425V25.9769M11.9655 49.1261C12.3716 50.7506 13.9149 53.9997 16.8391 53.9997L64.7042 54C65.9225 53.7969 68.3593 52.7816 68.3593 50.3448"
          stroke="#435869"
        />
      </Svg>
    ),
    type: Constant.DOUBLE_CABIN,
  },
  {
    id: 7,
    name: 'Ghế massage',
    image: (
      <Svg width={20} height={16} viewBox="0 0 46 38">
        <Path d="M5.95775 8.03448C6.23173 6.7931 8.0126 4.31034 12.9443 4.31034C13.2182 3.2069 14.2594 1 16.232 1H35.5477C36.3184 1.12934 37.812 1.74385 37.995 3.18475C38.3755 3.11257 38.7936 3.06897 39.2464 3.06897C41.7122 3.06897 45 3.89655 45 8.86207V17.9655V20.0345V29.1379C45 34.1035 41.7122 34.931 39.2464 34.931C38.7936 34.931 38.3755 34.8874 37.995 34.8152C37.812 36.2561 36.3184 36.8707 35.5477 37H16.232C14.317 37 13.2798 34.9201 12.9702 33.7891H12.9443C8.0126 33.7891 6.23173 31.3063 5.95775 30.0649V29.1379C4.30516 29.1379 1 29.3 1 26.5V11.5C1 10.3333 1.99155 8.86207 5.95775 8.86207V8.03448Z" />
        <Path
          d="M5.95775 19.2069V8.86207M12.9443 4.31034C8.0126 4.31034 6.23173 6.7931 5.95775 8.03448V8.86207M12.9443 4.31034H35.1367C35.9586 4.72414 37.6025 7.04138 37.6025 13V19.2069M12.9443 4.31034C13.2182 3.2069 14.2594 1 16.232 1H35.5477C36.3696 1.13793 38.0135 1.82759 38.0135 3.48276M5.95775 18.8925V29.1379M37.6025 18.8925V25.0994C37.6025 31.058 35.9586 33.3753 35.1367 33.7891H12.9443C8.0126 33.7891 6.23173 31.3063 5.95775 30.0649V29.1379M35.5477 4.31034C35.9586 3.89655 37.2737 3.06897 39.2464 3.06897C41.7122 3.06897 45 3.89655 45 8.86207V20.0345M35.5477 33.6897C35.9586 34.1034 37.2737 34.931 39.2464 34.931C41.7122 34.931 45 34.1034 45 29.1379V17.9655M12.9443 33.6897C13.2182 34.7931 14.2594 37 16.232 37H35.5477C36.3696 36.8621 38.0135 36.1724 38.0135 34.5172M5.95775 29.1379C4.30516 29.1379 1 29.3 1 26.5C1 23.7 1 13.6667 1 11.5C1 10.3333 1.99155 8.86207 5.95775 8.86207"
          stroke="#435869"
        />
        <G clip-path="url(#clip0_3117_31196)">
          <Path
            d="M20.9258 31.5972L21.456 32.186C21.8716 31.7463 22.4233 31.5007 22.9972 31.5C23.571 31.4993 24.1232 31.7435 24.5397 32.1822L25.0695 31.5935C24.5126 30.9983 23.7696 30.6662 22.9969 30.667C22.2242 30.6678 21.4818 31.0009 20.9258 31.5972Z"
            fill="#435869"
          />
          <Path
            d="M19.3359 29.8307L19.8662 30.4194C20.7032 29.5103 21.827 29.0009 22.9974 29.0001C24.1679 28.9993 25.2922 29.5072 26.1302 30.4152L26.6601 29.8265C25.6816 28.762 24.3666 28.1662 22.9972 28.167C21.6279 28.1678 20.3134 28.7651 19.3359 29.8307Z"
            fill="#435869"
          />
          <Path
            d="M28.25 28.0592C26.8508 26.5263 24.9649 25.667 23 25.667C21.0351 25.667 19.1492 26.5263 17.75 28.0592V28.0688L18.2765 28.6538C19.5344 27.2748 21.2301 26.5014 22.9972 26.5006C24.7642 26.4998 26.4605 27.2718 27.7194 28.6496L28.25 28.06V28.0592Z"
            fill="#435869"
          />
        </G>
        <G clip-path="url(#clip1_3117_31196)">
          <Path
            d="M20.9258 6.40277L21.456 5.81402C21.8716 6.25373 22.4233 6.49929 22.9972 6.49999C23.571 6.50068 24.1232 6.25646 24.5397 5.81777L25.0695 6.40652C24.5126 7.00169 23.7696 7.33378 22.9969 7.33301C22.2242 7.33223 21.4818 6.99906 20.9258 6.40277Z"
            fill="#435869"
          />
          <Path
            d="M19.3359 8.16934L19.8662 7.58059C20.7032 8.48972 21.827 8.9991 22.9974 8.99988C24.1679 9.00065 25.2922 8.49277 26.1302 7.58476L26.6601 8.17351C25.6816 9.23798 24.3666 9.83379 22.9972 9.83301C21.6279 9.83223 20.3134 9.23493 19.3359 8.16934Z"
            fill="#435869"
          />
          <Path
            d="M28.25 9.94081C26.8508 11.4737 24.9649 12.333 23 12.333C21.0351 12.333 19.1492 11.4737 17.75 9.94081V9.93122L18.2765 9.34622C19.5344 10.7252 21.2301 11.4986 22.9972 11.4994C24.7642 11.5002 26.4605 10.7282 27.7194 9.35039L28.25 9.93997V9.94081Z"
            fill="#435869"
          />
        </G>
        <Defs>
          <clipPath id="clip0_3117_31196">
            <Rect width={12} height={8} fill="white" transform="translate(17 25)" />
          </clipPath>
          <clipPath id="clip1_3117_31196">
            <Rect width={12} height={8} fill="white" transform="matrix(1 0 0 -1 17 13)" />
          </clipPath>
        </Defs>
      </Svg>
    ),
    type: Constant.MASSAGE_SEAT,
  },
];

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontWeight: 300 },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 600 },
  ],
});

// Define styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F0F0F0',
    fontFamily: 'Roboto',
  },
  container: {
    margin: 10,
    paddingLeft: 8,
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontWeight: 500,
    color: '#435869',
  },
  textXs: {
    fontSize: 8,
    fontWeight: 500,
    color: '#435869',
  },
  logo: {
    width: 60,
    height: 50,
    objectFit: 'contain',
  },
  headerBooking: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
    columnGap: 14,
    margin: 2,
  },
  fw600: {
    fontWeight: 600,
  },
  fw500: {
    fontWeight: 500,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  cardContent: {
    padding: 8,
  },
  blockTicket: {
    width: 251,
    height: 175,
    borderRadius: 4,
    border: '1px solid #DCDCDC',
    columnGap: 15,
    display: 'flex',
    marginRight: 10,
  },
  mt50: {
    marginTop: 50,
  },
  blockDriver: {
    width: 251,
    height: 100,
    borderRadius: 4,
    border: '1px solid #DCDCDC',
    columnGap: 15,
    marginTop: 8,
    display: 'flex',
    marginRight: 10,
  },
  addressTicket: {
    paddingLeft: 4,
    borderLeft: '1px solid #435869',
    minHeight: 22,
  },
  mh100: {
    height: 50,
  },
  mt2: {
    marginTop: 5,
  },
  mt3: {
    marginTop: 3,
  },
  mt5: {
    marginTop: 5,
  },
  mt10: {
    marginTop: 10,
  },

  ml6: {
    marginLeft: 6,
  },
  bgSeatNoBooking: {
    backgroundColor: '#DCDCDC',
  },
  h158: {
    height: 158,
  },
  mt20: {
    marginTop: 13,
  },
  mauto: {
    margin: '0 auto',
  },
  mb: {
    marginBottom: 23,
  },
});

// Define your document component
export const MyDocument = ({ listBooking, group, searchDateBooking }: any) => {
  const driversList = listBooking?.trip?.drivers?.length
    ? listBooking?.trip?.drivers?.map((driver: any) => `${driver.name} (${driver.phone})`).join(', ')
    : '';
  const extraDriverList = listBooking?.trip?.extra_drivers?.length
    ? listBooking?.trip?.extra_drivers?.map((extraDriver: any) => `${extraDriver.name}`).join(', ')
    : '';
  const getImageUrlByType = (type: any) => {
    const imageSrc = typeSeatItems1?.find((item: any) => +item?.id === type)?.image;
    return imageSrc;
  };
  const calculateFinalPrice = (seatFloors: any) => {
    const basePriceStr = listBooking?.schedule?.price_json?.find((priceEntry: any) => {
      const groupSeat = listBooking?.schedule?.bus_type?.seat_map?.group_seat?.find((group: any) =>
        group?.selected_seat?.includes(seatFloors?.name),
      );
      return groupSeat && groupSeat?.name === priceEntry?.name;
    })?.seat_price;

    if (!basePriceStr && basePriceStr !== 0) return null;
    const basePrice = basePriceStr !== 0 ? parseFloat(basePriceStr.replace(/,/g, '')) : 0;
    let finalPrice = basePrice;
    if (listBooking?.price_schedule?.percent) {
      if (!listBooking?.price_schedule?.is_increase) {
        finalPrice *= (100 - listBooking?.price_schedule?.percent) / 100;
      } else {
        finalPrice *= (100 + listBooking?.price_schedule?.percent) / 100;
      }
    } else if (listBooking?.price_schedule?.price) {
      if (!listBooking?.price_schedule?.is_increase) {
        finalPrice -= listBooking?.price_schedule?.price;
      }
      finalPrice += listBooking?.price_schedule?.price;
    }
    return finalPrice;
  };

  return (
    <Document>
      <Page size="A4" style={[styles.page]}>
        <View style={styles.mb}>
          <View style={styles.container}>
            {listBooking?.schedule?.company?.logo ? (
              <Image style={styles.logo} src={listBooking?.schedule?.company?.logo} />
            ) : (
              <Text style={styles.logo}></Text>
            )}
          </View>
          <View style={[styles.headerBooking]}>
            <Text style={styles.text} wrap={false}>
              Tuyến: {listBooking?.schedule?.route?.depart_point ?? ''} -{' '}
              {listBooking?.schedule?.route?.arrive_point ?? ''}
            </Text>
            <Text style={styles.text} wrap={false}>
              Ngày: {listBooking?.schedule?.next_trip_date ?? ''}
            </Text>
            <Text style={[styles.text, styles.fw500]} wrap={false}>
              Giờ đi: {convertTimeFormat(listBooking?.schedule?.depart_time)}
            </Text>
            <Text style={[styles.text, styles.fw500]} wrap={false}>
              Giờ đến: {convertTimeFormat(listBooking?.schedule?.arrive_time)}
            </Text>
          </View>
          <View style={styles.headerBooking}>
            <Text style={[styles.text, styles.fw500]} wrap={false}>
              Tài xế: {driversList}
            </Text>
            <Text style={[styles.text, styles.fw500]} wrap={false}>
              Phụ xe: {extraDriverList}
            </Text>
            <Text style={[styles.text, styles.fw500]} wrap={false}>
              Biển số xe: {listBooking?.trip?.bus?.plate_number}
            </Text>
          </View>
        </View>
        <View style={[styles.container]}>
          <Text style={styles.text}>Tầng 1</Text>
          {listBooking?.schedule?.bus_type?.seat_map[`floor1`]?.map((rowSeats: any, rowIndex: number) => {
            return (
              <View key={rowIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                {rowSeats?.map((seatFloors: any, colIndex: number) => {
                  const seatEntries = listBooking?.booking?.data?.find(
                    (entry: any) => entry.seat_number === seatFloors.name,
                  );
                  const seatBooking = listBooking?.schedule?.agent_seats?.includes(
                    (seat: any) => seat === seatFloors?.name,
                  );
                  const seatCustomerEntries = listBooking?.customers?.data?.find(
                    (entry: any) => entry.seat_number === seatFloors.name,
                  );
                  return (
                    (seatFloors.name || seatFloors.type !== Constant.DEFAULT_SEAT) && (
                      <View
                        key={colIndex}
                        style={
                          seatFloors?.type > 2
                            ? [
                                styles.blockTicket,
                                styles.cardContent,
                                seatFloors?.type < Constant.NORMAL_SEAT ||
                                seatEntries?.status === 'locked' ||
                                !(seatFloors?.status !== 0 && seatEntries) ||
                                (!seatBooking &&
                                  group === USER_GROUP.AGENT &&
                                  !listBooking?.schedule?.agent_seats?.includes(seatFloors?.name))
                                  ? styles.bgSeatNoBooking
                                  : {},

                                +rowIndex % 4 === 0 && +rowIndex !== 0 ? styles.mt50 : styles.mt10,
                              ]
                            : [
                                styles.blockDriver,
                                styles.cardContent,
                                seatFloors?.type < Constant.NORMAL_SEAT ||
                                seatEntries?.status === 'locked' ||
                                !(seatFloors?.status !== 0 && seatEntries) ||
                                (!seatBooking &&
                                  group === USER_GROUP.AGENT &&
                                  !listBooking?.schedule?.agent_seats?.includes(seatFloors?.name))
                                  ? styles.bgSeatNoBooking
                                  : {},
                                +rowIndex % 4 === 0 && +rowIndex !== 0 ? styles.mt50 : styles.mt10,
                              ]
                        }
                      >
                        <View style={[styles.flex, styles.flexStart]}>
                          {getImageUrlByType(seatFloors?.type)}
                          <Text style={[styles.textXs, styles.ml6]}>
                            {seatFloors.name}
                            {!listBooking?.contract?.code
                              ? seatEntries?.ticket_code
                                ? `| ${seatEntries?.ticket_code}`
                                : ''
                              : ''}
                          </Text>
                        </View>
                        {seatFloors?.type === Constant.DRIVER_SEAT && (
                          <>
                            {listBooking?.trip?.drivers?.map((driver: any, index: number) => (
                              <View key={index} style={styles.mt3}>
                                <Text style={styles.text}>{driver?.name}</Text> <br />
                                <Text style={styles.textXs}> {driver?.phone}</Text>
                              </View>
                            ))}
                          </>
                        )}
                        {seatFloors?.type === Constant.EXTRA_DRIVER_SEAT && (
                          <>
                            {listBooking?.trip?.extra_drivers?.map((driver: any, index: number) => (
                              <View key={index} style={styles.mt3}>
                                <Text style={styles.text}>{driver.name}</Text> <br />
                                <Text style={styles.textXs}>{driver.phone}</Text>
                              </View>
                            ))}
                          </>
                        )}
                        {((seatFloors?.status !== 0 && !seatFloors.locked) ||
                          seatFloors?.type === Constant.DRIVER_SEAT ||
                          (seatBooking && group === USER_GROUP.AGENT) ||
                          seatFloors?.type === Constant.EXTRA_DRIVER_SEAT) && (
                          <View style={styles.mt3}>
                            <Text style={styles.textXs}>{seatEntries?.customer?.name}</Text>
                            <Text style={styles.textXs}>{seatEntries?.customer?.phone}</Text>
                            {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                              <>
                                <View style={[seatEntries ? styles.addressTicket : {}, styles.mt5]}>
                                  <Text style={styles.textXs}>
                                    {seatEntries?.pickup_location?.location_name
                                      ? `${
                                          seatEntries?.pickup_location?.location_name
                                        } (${seatEntries?.pickup_location?.time?.slice(0, 5)}${
                                          (seatEntries?.pickup_location?.diff_day ||
                                            seatEntries?.drop_off_location?.diff_day) > 0
                                            ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                ?.add(seatEntries?.pickup_location?.diff_day, 'day')
                                                ?.format(DateFormat)}`
                                            : ''
                                        })`
                                      : seatEntries?.pickup_extra}
                                  </Text>
                                  <Text style={styles.textXs}>
                                    {seatEntries?.drop_off_location?.location_name
                                      ? `${
                                          seatEntries?.drop_off_location?.location_name
                                        } (${seatEntries?.drop_off_location?.time?.slice(0, 5)}${
                                          (seatEntries?.pickup_location?.diff_day ||
                                            seatEntries?.drop_off_location?.diff_day) > 0
                                            ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                ?.add(seatEntries?.drop_off_location?.diff_day, 'day')
                                                ?.format(DateFormat)}`
                                            : ''
                                        })`
                                      : seatEntries?.drop_off_extra}
                                  </Text>
                                </View>
                                <View>
                                  <Text style={[styles.textXs, styles.mt2]}>{`${seatEntries?.note ?? ''}`}</Text>
                                </View>
                              </>
                            )}
                          </View>
                        )}
                        {!listBooking?.contract?.code && (
                          <View style={styles.mt3}>
                            {seatFloors?.type < Constant.NORMAL_SEAT && (
                              <Text style={styles.textXs}>{seatCustomerEntries?.customer_name}</Text>
                            )}
                            <div className="price-item mb-1">
                              <>
                                {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                  (seatBooking ||
                                    !(group === USER_GROUP.AGENT &&
                                    searchDateBooking &&
                                    listBooking?.schedule?.agent_seats_custom &&
                                    listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                      ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                          seatFloors?.name,
                                        ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                      : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                        !listBooking?.booked_seat?.includes(seatFloors?.name)) ||
                                    (group !== USER_GROUP.AGENT && seatEntries?.price)) && (
                                    <>
                                      <Text style={styles.textXs}>
                                        {(() => {
                                          const finalPrice = calculateFinalPrice(seatFloors);
                                          if (finalPrice === null) return null;
                                          const formattedPrice = finalPrice.toLocaleString('en-US');
                                          return seatEntries?.price
                                            ? `${seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'} ${
                                                seatEntries?.price
                                              }VNĐ`
                                            : `${
                                                seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'
                                              } ${formattedPrice} VNĐ`;
                                        })()}
                                      </Text>

                                      {listBooking?.price_schedule?.percent || listBooking?.price_schedule?.price ? (
                                        <Text style={styles.textXs}>
                                          {`${
                                            listBooking?.price_schedule?.is_increase
                                              ? 'Giá trên đã tăng: '
                                              : 'Giá trên đã giảm'
                                          } 
                                              ${
                                                listBooking?.price_schedule?.percent
                                                  ? `${listBooking?.price_schedule?.percent}%`
                                                  : `${formattedPrice(listBooking?.price_schedule?.price)}đ`
                                              }- ${listBooking?.price_schedule?.reason}`}
                                        </Text>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )}
                              </>
                              {seatFloors?.status !== 0 &&
                                seatEntries &&
                                seatEntries?.payment_method !== 'reserve' &&
                                seatEntries?.payment_method !== 'paid_at_company' && (
                                  <>
                                    {seatEntries?.is_driver_pay ? (
                                      <div>
                                        <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                          <Path
                                            d="M2 7.99995C2 8.78787 2.15519 9.56808 2.45672 10.296C2.75824 11.024 3.2002 11.6854 3.75734 12.2425C4.31449 12.7997 4.97592 13.2416 5.70387 13.5432C6.43181 13.8447 7.21202 13.9999 7.99995 13.9999C8.78787 13.9999 9.56808 13.8447 10.296 13.5432C11.024 13.2416 11.6854 12.7997 12.2425 12.2425C12.7997 11.6854 13.2416 11.024 13.5432 10.296C13.8447 9.56808 13.9999 8.78787 13.9999 7.99995C13.9999 7.21202 13.8447 6.43181 13.5432 5.70387C13.2416 4.97592 12.7997 4.31449 12.2425 3.75734C11.6854 3.2002 11.024 2.75824 10.296 2.45672C9.56808 2.15519 8.78787 2 7.99995 2C7.21202 2 6.43181 2.15519 5.70387 2.45672C4.97592 2.75824 4.31449 3.2002 3.75734 3.75734C3.2002 4.31449 2.75824 4.97592 2.45672 5.70387C2.15519 6.43181 2 7.21202 2 7.99995Z"
                                            stroke="#435869"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <Path
                                            d="M6.66646 7.9997C6.66646 8.35332 6.80694 8.69246 7.05698 8.9425C7.30703 9.19255 7.64616 9.33302 7.99978 9.33302M6.66646 7.9997C6.66646 7.64608 6.80694 7.30695 7.05698 7.0569C7.30703 6.80686 7.64616 6.66638 7.99978 6.66638C8.3534 6.66638 8.69254 6.80686 8.94258 7.0569C9.19263 7.30695 9.3331 7.64608 9.3331 7.9997M6.66646 7.9997L2.1665 6.66638M7.99978 9.33302C8.3534 9.33302 8.69254 9.19255 8.94258 8.9425C9.19263 8.69246 9.3331 8.35332 9.3331 7.9997M7.99978 9.33302V13.9996M9.3331 7.9997L13.8331 6.66638"
                                            stroke="#435869"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </Svg>
                                        <Text style={styles.textXs}>
                                          {`Tài xế đã thu bằng ${getPaymentMethod(seatEntries?.payment_method)} ${
                                            seatEntries?.commission_price
                                              ? formattedPrice(seatEntries?.commission_price)
                                              : 0
                                          }VNĐ `}
                                        </Text>
                                      </div>
                                    ) : (
                                      <>
                                        {(seatEntries?.orderer?.agent?.id || seatEntries?.payer) &&
                                        !listBooking?.contract?.id ? (
                                          <Text style={styles.textXs}>
                                            {seatEntries?.payer?.name
                                              ? seatEntries?.payer?.office_name
                                                ? `${seatEntries?.payer?.name}_${seatEntries?.payer?.office_name}`
                                                : seatEntries?.payer?.name
                                              : seatEntries?.orderer?.name}
                                            {seatEntries?.payer?.agent?.id ? ' đã thanh toán bằng ' : ' đã thu bằng '}
                                            {getPaymentMethod(seatEntries?.payment_method)}
                                            {formattedPrice(
                                              seatEntries?.commission_price
                                                ? !seatEntries?.payer?.agent?.id && seatEntries?.orderer?.agent?.id
                                                  ? ` ${formattedPrice(seatEntries?.price)}`
                                                  : ` ${formattedPrice(seatEntries?.commission_price)}`
                                                : 0,
                                            )}
                                            VNĐ
                                          </Text>
                                        ) : (
                                          <Text style={styles.textXs}>
                                            {`${seatEntries?.orderer?.name} ${
                                              seatEntries?.payer?.agent?.id ? 'đã thanh toán bằng ' : 'đã thu '
                                            } ${getPaymentMethod(seatEntries?.payment_method)} ${
                                              formattedPrice(seatEntries?.commission_price) ?? 0
                                            }VNĐ ${
                                              seatEntries?.payment_method === 'cash' ? ' tiền mặt' : ' chuyển khoản'
                                            } tại ${seatEntries?.payer?.agent?.id ? `đại lý` : `nhà xe `}`}
                                          </Text>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                            </div>
                          </View>
                        )}
                      </View>
                    )
                  );
                })}
              </View>
            );
          })}
        </View>
      </Page>
      {listBooking?.schedule?.bus_type?.seat_map?.count_floor === 2 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.container}>
            <Text style={styles.text}>Tầng 2</Text>
            {listBooking?.schedule?.bus_type?.seat_map?.count_floor === 2 &&
              listBooking?.schedule?.bus_type?.seat_map[`floor2`]?.map((rowSeats: any, rowIndex: number) => {
                return (
                  <View key={rowIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                    {rowSeats?.map((seatFloors: any, colIndex: number) => {
                      const seatEntries = listBooking?.booking?.data?.find(
                        (entry: any) => entry.seat_number === seatFloors.name,
                      );
                      const seatBooking = listBooking?.schedule?.agent_seats?.includes(
                        (seat: any) => seat === seatFloors?.name,
                      );
                      const seatCustomerEntries = listBooking?.customers?.data?.find(
                        (entry: any) => entry.seat_number === seatFloors.name,
                      );
                      return (
                        (seatFloors.name || seatFloors.type !== Constant.DEFAULT_SEAT) && (
                          <View
                            key={colIndex}
                            style={[
                              styles.blockTicket,
                              styles.cardContent,

                              seatFloors?.type < Constant.NORMAL_SEAT ||
                              seatEntries?.status === 'locked' ||
                              !(seatFloors?.status !== 0 && seatEntries && seatEntries?.payment_method !== 'reserve') ||
                              (!seatBooking &&
                                group === USER_GROUP.AGENT &&
                                !listBooking?.schedule?.agent_seats?.includes(seatFloors?.name))
                                ? styles.bgSeatNoBooking
                                : {},
                            ]}
                          >
                            <View style={[styles.flex, styles.flexStart]}>
                              {getImageUrlByType(seatFloors?.type)}
                              <Text style={[styles.textXs, styles.ml6]}>
                                {seatFloors.name}{' '}
                                {!listBooking?.contract?.code && seatEntries?.ticket_code
                                  ? `| ${seatEntries?.ticket_code}`
                                  : ''}
                              </Text>
                            </View>
                            {seatFloors?.type === Constant.DRIVER_SEAT && (
                              <>
                                {listBooking?.trip?.drivers?.map((driver: any, index: number) => (
                                  <View key={index} style={styles.mt3}>
                                    <Text style={styles.text}>{driver?.name}</Text> <br />
                                    <Text style={styles.textXs}> {driver?.phone}</Text>
                                  </View>
                                ))}
                              </>
                            )}
                            {seatFloors?.type === Constant.EXTRA_DRIVER_SEAT && (
                              <>
                                {listBooking?.trip?.extra_drivers?.map((driver: any, index: number) => (
                                  <View key={index} style={styles.mt3}>
                                    <Text style={styles.text}>{driver.name}</Text> <br />
                                    <Text style={styles.textXs}>{driver.phone}</Text>
                                  </View>
                                ))}
                              </>
                            )}
                            {((seatFloors?.status !== 0 && !seatFloors.locked) ||
                              seatFloors?.type === Constant.DRIVER_SEAT ||
                              (seatBooking && group === USER_GROUP.AGENT) ||
                              seatFloors?.type === Constant.EXTRA_DRIVER_SEAT) && (
                              <View style={styles.mt3}>
                                <Text style={styles.textXs}>{seatEntries?.customer?.name}</Text>
                                <Text style={styles.textXs}>{seatEntries?.customer?.phone}</Text>
                                {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                  <>
                                    <View style={[seatEntries ? styles.addressTicket : {}, styles.mt5]}>
                                      <Text style={styles.textXs}>
                                        {seatEntries?.pickup_location?.location_name
                                          ? `${
                                              seatEntries?.pickup_location?.location_name
                                            } (${seatEntries?.pickup_location?.time?.slice(0, 5)}${
                                              (seatEntries?.pickup_location?.diff_day ||
                                                seatEntries?.drop_off_location?.diff_day) > 0
                                                ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                    ?.add(seatEntries?.pickup_location?.diff_day, 'day')
                                                    ?.format(DateFormat)}`
                                                : ''
                                            })`
                                          : seatEntries?.pickup_extra}
                                      </Text>
                                      <Text style={styles.textXs}>
                                        {seatEntries?.drop_off_location?.location_name
                                          ? `${
                                              seatEntries?.drop_off_location?.location_name
                                            } (${seatEntries?.drop_off_location?.time?.slice(0, 5)}${
                                              (seatEntries?.pickup_location?.diff_day ||
                                                seatEntries?.drop_off_location?.diff_day) > 0
                                                ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                    ?.add(seatEntries?.drop_off_location?.diff_day, 'day')
                                                    ?.format(DateFormat)}`
                                                : ''
                                            })`
                                          : seatEntries?.drop_off_extra}
                                      </Text>
                                    </View>
                                    <View>
                                      <Text style={[styles.textXs, styles.mt2]}>{`${seatEntries?.note ?? ''}`}</Text>
                                    </View>
                                  </>
                                )}
                              </View>
                            )}
                            {!listBooking?.contract?.code && (
                              <View style={styles.mt3}>
                                {seatFloors?.type < Constant.NORMAL_SEAT && (
                                  <Text style={styles.textXs}>{seatCustomerEntries?.customer_name}</Text>
                                )}
                                <div className="price-item mb-1">
                                  <>
                                    {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                      (seatBooking ||
                                        !(group === USER_GROUP.AGENT &&
                                        searchDateBooking &&
                                        listBooking?.schedule?.agent_seats_custom &&
                                        listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                          ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                              seatFloors?.name,
                                            ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                          : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                            !listBooking?.booked_seat?.includes(seatFloors?.name)) ||
                                        (group !== USER_GROUP.AGENT && seatEntries?.price)) && (
                                        <>
                                          <Text style={styles.textXs}>
                                            {(() => {
                                              const finalPrice = calculateFinalPrice(seatFloors);
                                              if (finalPrice === null) return null;
                                              const formattedPrice = finalPrice.toLocaleString('en-US');
                                              return seatEntries?.price
                                                ? `${seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'} ${
                                                    seatEntries?.price
                                                  }VNĐ`
                                                : `${
                                                    seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'
                                                  } ${formattedPrice} VNĐ`;
                                            })()}
                                          </Text>

                                          {listBooking?.price_schedule?.percent ||
                                          listBooking?.price_schedule?.price ? (
                                            <Text style={styles.textXs}>
                                              {`${
                                                listBooking?.price_schedule?.is_increase
                                                  ? 'Giá trên đã tăng: '
                                                  : 'Giá trên đã giảm'
                                              } 
                                              ${
                                                listBooking?.price_schedule?.percent
                                                  ? `${listBooking?.price_schedule?.percent}%`
                                                  : `${formattedPrice(listBooking?.price_schedule?.price)}đ`
                                              }- ${listBooking?.price_schedule?.reason}`}
                                            </Text>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      )}
                                  </>
                                  {seatFloors?.status !== 0 &&
                                    seatEntries &&
                                    seatEntries?.payment_method !== 'reserve' &&
                                    seatEntries?.payment_method !== 'paid_at_company' && (
                                      <>
                                        {seatEntries?.is_driver_pay ? (
                                          <div>
                                            <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                              <Path
                                                d="M2 7.99995C2 8.78787 2.15519 9.56808 2.45672 10.296C2.75824 11.024 3.2002 11.6854 3.75734 12.2425C4.31449 12.7997 4.97592 13.2416 5.70387 13.5432C6.43181 13.8447 7.21202 13.9999 7.99995 13.9999C8.78787 13.9999 9.56808 13.8447 10.296 13.5432C11.024 13.2416 11.6854 12.7997 12.2425 12.2425C12.7997 11.6854 13.2416 11.024 13.5432 10.296C13.8447 9.56808 13.9999 8.78787 13.9999 7.99995C13.9999 7.21202 13.8447 6.43181 13.5432 5.70387C13.2416 4.97592 12.7997 4.31449 12.2425 3.75734C11.6854 3.2002 11.024 2.75824 10.296 2.45672C9.56808 2.15519 8.78787 2 7.99995 2C7.21202 2 6.43181 2.15519 5.70387 2.45672C4.97592 2.75824 4.31449 3.2002 3.75734 3.75734C3.2002 4.31449 2.75824 4.97592 2.45672 5.70387C2.15519 6.43181 2 7.21202 2 7.99995Z"
                                                stroke="#435869"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <Path
                                                d="M6.66646 7.9997C6.66646 8.35332 6.80694 8.69246 7.05698 8.9425C7.30703 9.19255 7.64616 9.33302 7.99978 9.33302M6.66646 7.9997C6.66646 7.64608 6.80694 7.30695 7.05698 7.0569C7.30703 6.80686 7.64616 6.66638 7.99978 6.66638C8.3534 6.66638 8.69254 6.80686 8.94258 7.0569C9.19263 7.30695 9.3331 7.64608 9.3331 7.9997M6.66646 7.9997L2.1665 6.66638M7.99978 9.33302C8.3534 9.33302 8.69254 9.19255 8.94258 8.9425C9.19263 8.69246 9.3331 8.35332 9.3331 7.9997M7.99978 9.33302V13.9996M9.3331 7.9997L13.8331 6.66638"
                                                stroke="#435869"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </Svg>
                                            <Text style={styles.textXs}>
                                              {`Tài xế đã thu bằng ${getPaymentMethod(seatEntries?.payment_method)} ${
                                                seatEntries?.commission_price
                                                  ? formattedPrice(seatEntries?.commission_price)
                                                  : 0
                                              }VNĐ `}
                                            </Text>
                                          </div>
                                        ) : (
                                          <>
                                            {(seatEntries?.orderer?.agent?.id || seatEntries?.payer) &&
                                            !listBooking?.contract?.id ? (
                                              <Text style={styles.textXs}>
                                                {seatEntries?.payer?.name
                                                  ? seatEntries?.payer?.office_name
                                                    ? `${seatEntries?.payer?.name}_${seatEntries?.payer?.office_name}`
                                                    : seatEntries?.payer?.name
                                                  : seatEntries?.orderer?.name}
                                                {seatEntries?.payer?.agent?.id
                                                  ? ' đã thanh toán bằng '
                                                  : ' đã thu bằng '}
                                                {getPaymentMethod(seatEntries?.payment_method)}
                                                {formattedPrice(
                                                  seatEntries?.commission_price
                                                    ? !seatEntries?.payer?.agent?.id && seatEntries?.orderer?.agent?.id
                                                      ? ` ${formattedPrice(seatEntries?.price)}`
                                                      : ` ${formattedPrice(seatEntries?.commission_price)}`
                                                    : 0,
                                                )}
                                                VNĐ
                                              </Text>
                                            ) : (
                                              <Text style={styles.textXs}>
                                                {`${seatEntries?.orderer?.name} ${
                                                  seatEntries?.payer?.agent?.id ? 'đã thanh toán bằng ' : 'đã thu '
                                                } ${getPaymentMethod(seatEntries?.payment_method)} ${
                                                  formattedPrice(seatEntries?.commission_price) ?? 0
                                                }VNĐ ${
                                                  seatEntries?.payment_method === 'cash' ? ' tiền mặt' : ' chuyển khoản'
                                                } tại ${seatEntries?.payer?.agent?.id ? `đại lý` : `nhà xe `}`}
                                              </Text>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                </div>
                              </View>
                            )}
                          </View>
                        )
                      );
                    })}
                  </View>
                );
              })}
          </View>
        </Page>
      )}
    </Document>
  );
};
