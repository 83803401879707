import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, DatePicker } from "antd";
import { createNotifyApi } from "../../api/notify";
import { Constant } from "../../config/constant";
import { ACTION_NAME, messageCreateSuccess } from "../../config/message";
import { checkAuthorization } from "../../api";
import dayjs from "dayjs";
import { validateDateRange } from "../../lib/utils";
import { useAppDispatch } from "../../redux/hooks";
import { fetchNotification } from "../../redux/slice/notify/notificationSlice";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const AddNotify = ({
  setIsModalOpen,
  isModalOpen,
  handleListNotify,
  accountUser,
}: any) => {
  const [form] = Form.useForm();
  const [dateRange, setDateRange] = useState<any>("");
  const today = dayjs().format("YYYY-MM-DD"); // Lấy ngày hiện tại
  const dispatch = useAppDispatch();

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let query = `page=1&limit=10`;
  const onFinish = async (values: any) => {
    if (dateRange) {
      const [startDate, endDate] = dateRange;
      values.since_date = startDate.format("YYYY-MM-DD");
      values.to_date = endDate.format("YYYY-MM-DD");
    }
    try {
      const res = await createNotifyApi(values);
      if (res.status === Constant.DEFAULT_STATUS) {
        handleListNotify();
        messageCreateSuccess(ACTION_NAME.NOTIFICATION);
        dispatch(fetchNotification({query}));
        handleCancel();
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  return (
    <>
      <Modal
        title="Thêm thông báo"
        open={isModalOpen}
        onOk={handleOk}
        maskClosable={false}
        width={840}
        onCancel={handleCancel}
        className="wrapper-modal-notify"
        footer={() => (
          <div className="modal-footer-notify">
            <Button onClick={handleCancel}>Hủy</Button>
            <Button type="primary" onClick={handleOk}>
              Lưu
            </Button>
          </div>
        )}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="title"
            label="Tiêu đề"
            rules={[
              { required: true, message: "Vui lòng nhập tiêu đề" },
              { min: 10, message: "Tiêu đề tối thiểu 10 ký tự" },
              { max: 70, message: "Tiêu đề tối đa 70 ký tự" },
            ]}
          >
            <Input placeholder="Tối thiểu 10 ký tự - tối đa 70 ký tự" />
          </Form.Item>
          <Col>
            <Form.Item
              name="group"
              label="Chọn ngày"
              rules={[
                {
                  validator: validateDateRange,
                  message: "Vui lòng chọn ngày hợp lệ",
                },
              ]}
            >
              <RangePicker
                style={{ width: 350 }}
                format="DD/MM/YYYY"
                picker="date"
                placeholder={["Từ ngày", "Đến ngày"]}
                onChange={handleDateRangeChange}
                disabledDate={(current) => current && current.isBefore(today)} // Vô hiệu hóa ngày trước ngày hiện tại
              />
            </Form.Item>
          </Col>
          <Form.Item
            name="content"
            label="Nội dung"
            rules={[
              { required: true, message: "Vui lòng nhập nội dung" },
              { min: 50, message: "Tiêu đề tối thiểu 50 ký tự" },
              { max: 500, message: "Nội dung không được quá 500 ký tự" },
            ]}
          >
            <TextArea
              placeholder="Tối thiểu 50 ký tự - tối đa 500 ký tự"
              autoSize={{ minRows: 5, maxRows: 6 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddNotify;
