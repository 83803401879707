import { Button, Col, Divider, Row } from "antd";
import { useTranslation } from "react-i18next";

function StatusFilter({
  status,
  onChangeStatus,
}: any) {
  const { t } = useTranslation();

  return (
    <Row
      gutter={8}
      className="fw-500 wrapper-status-account-deposit"
      style={{ display: "flex", alignItems: "center", marginBottom: 15 }}
    >
      <Col>
        <Button
          type="text"
          className={`fw-500 ${
            !status ? "btn-status-active-green" : "text-color"
          }`}
          onClick={() => {
            onChangeStatus("");
          }}
        >
          {t("Tất cả")}
        </Button>
      </Col>
      <Divider type="vertical" />
      <Col>
        <Button
          type="text"
          className={`fw-500 ${
            status === "wait_confirm"
              ? "btn-status-active-green"
              : "text-color"
          }`}
          onClick={() => {
            onChangeStatus("wait_confirm");
          }}
        >
          {t("Chưa xác nhận")}
        </Button>
      </Col>
      <Divider type="vertical" />
      <Col>
        <Button
          type="text"
          className={`fw-500 ${
            status === "confirmed"
              ? "btn-status-active-green"
              : "text-color"
          }`}
          onClick={() => {
            onChangeStatus("confirmed");
          }}
        >
          {t("Đã xác nhận")}
        </Button>
      </Col>
      <Divider type="vertical" />
      <Col>
        <Button
          type="text"
          className={`fw-500 ${
            status === "cancel"
              ? "btn-status-lock-red"
              : "text-color"
          }`}
          onClick={() => {
            onChangeStatus("cancel");
          }}
        >
          {t("Đã từ chối")}
        </Button>
      </Col>
    </Row>
  );
}

export default StatusFilter;
