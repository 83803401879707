import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Tutotial, TutotialsState } from "../../../types";
import { getListTutorialsApi } from "../../../api/tutorials";

const initialState = {
    listTutorials: []
} as TutotialsState

export const tutorialsSlice = createSlice({
    name: 'tutorials',
    initialState,
    reducers: {
        setListTutorials(state, action: PayloadAction<Tutotial[]>) {
            state.listTutorials = action.payload;
        }
    },
})

export const getListTutorials = createAsyncThunk('Tutorials/getListTutorials', async (_, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListTutorialsApi();
        dispatch(setListTutorials(response?.data?.data));
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const { setListTutorials } = tutorialsSlice.actions;
