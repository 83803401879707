import React, { useEffect, useState } from 'react';
import { Button, Flex, Image, Modal, Row, Typography } from 'antd';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';
import { modalProps } from '../../types';

const ModalConfirm: React.FC<modalProps> = ({
  okText,
  cancelText,
  modalTitle,
  modalContent,
  modalOpen,
  onCancel,
  onOk,
  isAgentBus,
  loading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      centered
      footer={[]}
      className="modal-confirm-block"
    >
      <Flex className="mb-3 confirm-modal">
        <Image preview={false} width={22} height={22} src={IconExclamationCircle} alt="Icon Exclamation Circle" />
        <Typography.Text className="pl-15">
          <h5 className="fw-600">{modalTitle}</h5>
          <p className="fw-500">{modalContent}</p>
        </Typography.Text>
      </Flex>

      <Row className="d-flex justify-content-end">
        {isAgentBus ? (
          <>
            <Button
              key="cancel"
              className=" w-160 h-btn-48 me-3 btn-back agent"
              loading={loading}
              onClick={() => modalHandleCancel()}
            >
              Trở lại
            </Button>
            <Button
              key="ok"
              loading={loading}
              type="primary"
              className="w-160 h-btn-48 btn-success mr-0"
              htmlType="submit"
              onClick={() => modalHandleOk()}
            >
              {okText}
            </Button>
          </>
        ) : (
          <>
            <Button loading={loading} key="cancel" className=" w-160 h-btn-48 me-3" onClick={() => modalHandleCancel()}>
              Đóng
            </Button>
            <Button
              key="ok"
              type="primary"
              loading={loading}
              className="w-160 h-btn-48 btn-success mr-0"
              htmlType="submit"
              onClick={() => modalHandleOk()}
            >
              Đồng ý
            </Button>
          </>
        )}
      </Row>
    </Modal>
  );
};

export default ModalConfirm;
