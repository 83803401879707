import { Button, Flex, Tabs } from 'antd';
import '../styles/ListTicket.scss';
import React, { useEffect, useState } from 'react';
import iconPrint from '../../../assets/images/orders/icon-print.svg';
import iconCheck from '../../../assets/images/orders/icon-check.svg';
import iconCancel from '../../../assets/images/orders/icon-cancel.svg';
import iconHistory from '../../../assets/images/orders/icon-history.svg';
import iconNext from '../../../assets/images/orders/icon-next-ticket.svg';
import iconPrev from '../../../assets/images/orders/icon-prev-ticket.svg';
import iconDepart from '../../../assets/images/orders/icon-depart.svg';
import iconArrive from '../../../assets/images/orders/icon-arrive.svg';
import iconEmail from '../../../assets/images/orders/icon-email.svg';
import { OrderItemParams } from '../../../types/order';
import moment from 'moment';
import { formatPrice } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import { cancelTicketAPI, exportTicketAPI } from '../api';
import { handleGetCancelReason, TypeModalOrder } from '../models/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { USER_GROUP } from '../../../config/constant';
import ModalExport from './ModalExport';
import ModalCancel from './ModalCancel';
import { TicketParams } from '../../../types/order';
import ModalHistoryTicket from './ModalHistoryTicket';
import ticketCancel from '../../../assets/images/orders/ticket-cancel.svg';
import ticketReserve from '../../../assets/images/orders/ticket-reserve.svg';
import ticketPaid from '../../../assets/images/orders/ticket-paid.svg';
import ModalPrintTicket from '../../bookings/tickets/ModalPrintTicket';
import { resetReloadDetail, setReloadDetail } from '../../../redux/slice/authSlice';
import { setTimer } from '../../../redux/slice/orders/ordersProcessedSlide';
import { getAmountOrder } from '../../../redux/slice/orders/ordersPendingSlide';
import statusCancel from '../../../assets/images/orders/status-cancel.svg';
import statusPaid from '../../../assets/images/orders/status-paid.svg';
import statusPendingDot from '../../../assets/images/orders/status-pending-dot.svg';
import { getSendBookingMail } from '../../../redux/slice/bookings/sendMailSlice';

const ListTicket = ({
  listTicket,
  getDetailOrder,
  typeModal,
  getListOrdersPending,
  getListOrdersProcessed,
}: {
  listTicket: OrderItemParams['data'];
  getDetailOrder: any;
  typeModal: TypeModalOrder;
  getListOrdersPending: () => Promise<void>;
  getListOrdersProcessed: () => Promise<void>;
}) => {
  const accountUser = useAppSelector((state) => state.authAccount?.user);
  const dispatch = useAppDispatch();
  const [ticketActive, setTicketActive] = useState<string>('');
  const [listTicketCode, setlistTicketCode] = useState<any>();

  const [ticketItemActive, setTicketItemActive] = useState<TicketParams>();

  const [openExport, setOpenExport] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
  const [openCancel, setOpenCancel] = useState<boolean>(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);

  const [openModalHistory, setOpenModalHistory] = useState<boolean>(false);

  const accountDetail = useAppSelector((state) => state.authAccount?.user);

  const handleGetStatusTicket = (status: string) => {
    switch (status) {
      case 'reserve':
        return statusPendingDot;
      case 'hold':
        return statusPendingDot;
      case 'cancel':
        return statusCancel;
      case 'paid':
        return statusPaid;
    }
  };

  useEffect(() => {
    setlistTicketCode(
      listTicket?.map((ticket) => ({
        key: `${ticket?.ticket_code}`,
        children: '',
        label: (
          <Flex align="center" justify="center" className="ListTicket__tabs__item gap-x-8">
            {ticket?.direct && <img src={ticket?.direct === 'depart' ? iconDepart : iconArrive} alt="" />}
            {ticket?.ticket_code}
            <img
              width={12}
              height={12}
              className="object-fit-scale"
              src={handleGetStatusTicket(ticket?.status)}
              alt=""
            />
          </Flex>
        ),
      })),
    );
    setTicketActive(listTicket?.[0]?.ticket_code);
    setTicketItemActive(listTicket?.[0]);
  }, [listTicket]);

  const handleExportTicket = async (ticket_code: string) => {
    setLoadingExport(true);
    try {
      const res = await exportTicketAPI(ticket_code);
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        dispatch(getAmountOrder());
        getDetailOrder();
        if (typeModal === TypeModalOrder.PENDING) {
          getListOrdersPending();
        } else {
          getListOrdersProcessed();
        }
        setOpenExport(false);
      } else {
        toast.error('Có lỗi xảy ra vui lòng thử lại!');
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra vui lòng thử lại!');
    }
    setLoadingExport(false);
  };

  const handleCancelTicket = async ({ cancel_note }: { cancel_note: string }) => {
    setLoadingCancel(true);
    try {
      const res = await cancelTicketAPI({
        ticket_code: ticketActive,
        cancel_note,
      });
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        dispatch(getAmountOrder());
        getDetailOrder();
        if (typeModal === TypeModalOrder.PENDING) {
          getListOrdersPending();
        } else {
          getListOrdersProcessed();
        }
      } else {
        toast.error('Có lỗi xảy ra vui lòng thử lại!');
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra vui lòng thử lại!');
    }
    setLoadingCancel(false);
  };

  const cancelModalPrint = () => {
    setOpenModalPrint(false);
    dispatch(resetReloadDetail());
    dispatch(setTimer(true));
  };

  const handleNext = () => {
    const index = listTicket?.findIndex((ticket) => ticket?.ticket_code === ticketActive);
    if (index < listTicket?.length - 1) {
      setTicketActive(listTicket?.[index + 1]?.ticket_code);
      setTicketItemActive(listTicket?.[index + 1]);
    }
  };
  const handlePrev = () => {
    const index = listTicket?.findIndex((ticket) => ticket?.ticket_code === ticketActive);
    if (index > 0) {
      setTicketActive(listTicket?.[index - 1]?.ticket_code);
      setTicketItemActive(listTicket?.[index - 1]);
    }
  };
  return (
    <div>
      <p className="ListTicket__title">Danh sách mã vé trong đơn hàng</p>
      <div className="ListTicket">
        <Tabs
          items={listTicketCode}
          indicator={undefined}
          activeKey={ticketActive}
          tabBarExtraContent={
            listTicket?.length > 1 ? (
              <Flex className="gap-x-8 ml-12 mr-12" align="center">
                <Flex className="ListTicket__button__prev" align="center" justify="center" onClick={handlePrev}>
                  <img width={26} height={26} src={iconPrev} alt="" />
                </Flex>
                <Flex className="ListTicket__button__next" align="center" justify="center" onClick={handleNext}>
                  <img width={26} height={26} src={iconNext} alt="" />
                </Flex>
              </Flex>
            ) : null
          }
          onChange={(value) => {
            setTicketActive(value);
            setTicketItemActive(listTicket?.find((ticket) => ticket?.ticket_code === value));
          }}
          className="ListTicket__tabs"
        />
        {listTicket?.map((ticket) => (
          <div className={`Ticket ${ticketActive === ticket?.ticket_code ? '' : 'd-none'}`}>
            <Flex className="gap-x-20 mb-14" justify="space-between" align="center">
              {ticket?.status === 'cancel' && (
                <Flex className="gap-x-8" align="center">
                  <img width={14} height={14} className="object-fit-scale" src={ticketCancel} alt="" />
                  <p className="mb-0 text-danger fw-500">
                    {ticket?.cancel_reason ? handleGetCancelReason(ticket?.cancel_reason, ticket?.payer) : ''}
                  </p>
                </Flex>
              )}
              {ticket?.status === 'paid' && (
                <Flex className="gap-x-8" align="center">
                  <img width={14} height={14} className="object-fit-scale" src={ticketPaid} alt="" />
                  <p className="mb-0 text-color-green-default fw-500">Đã xuất vé!</p>
                </Flex>
              )}
              {(ticket?.status === 'reserve' || ticket?.status === 'hold') && (
                <Flex className="gap-x-8" align="center">
                  <img width={14} height={14} className="object-fit-scale" src={ticketReserve} alt="" />
                  <p style={{ color: '#009EF8' }} className="mb-0 fw-500">
                    Đang chờ xác nhận thanh toán!
                  </p>
                </Flex>
              )}
              <Flex className="gap-x-8" align="center">
                {accountDetail?.group !== USER_GROUP.CARLINK && (
                  <>
                    {ticket?.status === 'paid' && (
                      <Button
                        className="Ticket__button"
                        onClick={() => {
                          setOpenModalPrint(true);
                          dispatch(setReloadDetail());
                          dispatch(setTimer(false));
                        }}
                      >
                        <img width={16} height={16} className="object-fit-scale" src={iconPrint} alt="" />
                        In vé
                      </Button>
                    )}
                  </>
                )}
                {accountDetail?.group !== USER_GROUP.CARLINK && ticket?.status === 'paid' && (
                  <>
                    <Button
                      className="Ticket__button"
                      loading={loadingExport}
                      onClick={() => {
                        dispatch(getSendBookingMail({ ticket_code: ticket?.ticket_code }));
                      }}
                    >
                      <img width={16} height={16} className="object-fit-scale" src={iconEmail} alt="" />
                      <span className="font-medium">Gửi mail</span>
                    </Button>
                  </>
                )}
                {accountDetail?.group !== USER_GROUP.CARLINK && (
                  <>
                    {(ticket?.status === 'reserve' || ticket?.status === 'hold') && (
                      <Button className="Ticket__button" loading={loadingExport} onClick={() => setOpenExport(true)}>
                        <img width={16} height={16} className="object-fit-scale" src={iconCheck} alt="" />
                        Xuất vé
                      </Button>
                    )}
                  </>
                )}
                <>
                  {ticket?.status !== 'reserve' && ticket?.status !== 'hold' && (
                    <Button className="Ticket__button" onClick={() => setOpenModalHistory(true)}>
                      <img width={16} height={16} className="object-fit-scale" src={iconHistory} alt="" />
                      Lịch sử
                    </Button>
                  )}
                </>
                {accountDetail?.group !== USER_GROUP.CARLINK && (
                  <>
                    {ticket?.status !== 'cancel' && (
                      <Button className="Ticket__button" onClick={() => setOpenCancel(true)} loading={loadingCancel}>
                        <img width={16} height={16} className="object-fit-scale" src={iconCancel} alt="" />
                        Hủy vé
                      </Button>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
            <Flex className="flex-column gap-y-12">
              <Flex align="start">
                <p className="Ticket__property">Họ và tên</p>
                <p className="Ticket__desc">{ticket?.customer?.name ? ticket?.customer?.name : 'N/A'}</p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Số điện thoại</p>
                <p className="Ticket__desc">{ticket?.customer?.phone ? ticket?.customer?.phone : 'N/A'}</p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Email</p>
                <p className="Ticket__desc">{ticket?.customer?.email ? ticket?.customer?.email : 'N/A'}</p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Tuyến</p>
                <p className="Ticket__desc">
                  {ticket?.route?.depart_point} - {ticket?.route?.arrive_point}
                </p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Ngày, giờ đi</p>
                <p className="Ticket__desc">
                  {moment(ticket?.depart_time, 'HH:mm').format('HH:mm')} -{' '}
                  {moment(ticket?.depart_date).format('DD/MM/YYYY')}
                </p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Ngày, giờ đến</p>
                <p className="Ticket__desc">
                  {moment(ticket?.arrive_time, 'HH:mm').format('HH:mm')} -{' '}
                  {moment(ticket?.arrive_date).format('DD/MM/YYYY')}
                </p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Điểm đón</p>
                <p className="Ticket__desc">
                  {`${
                    ticket?.pickup_extra
                      ? moment(ticket?.depart_time, 'HH:mm').format('HH:mm')
                      : moment(ticket?.pickup_location?.time, 'HH:mm').format('HH:mm')
                  } - ${ticket?.pickup_extra ? 'Điểm trung chuyển khác' : ticket?.pickup_location?.location_name}`}
                  <br />
                  <span className="Ticket__desc__location">
                    {ticket?.pickup_extra ? ticket?.pickup_extra : ticket?.pickup_location?.address}
                  </span>
                </p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Điểm trả</p>
                <p className="Ticket__desc">
                  {`${
                    ticket?.drop_off_extra
                      ? moment(ticket?.arrive_time, 'HH:mm').format('HH:mm')
                      : moment(ticket?.drop_off_location?.time, 'HH:mm').format('HH:mm')
                  } - ${ticket?.drop_off_extra ? 'Điểm trung chuyển khác' : ticket?.drop_off_location?.location_name}`}
                  <br />
                  <span className="Ticket__desc__location">
                    {ticket?.drop_off_extra ? ticket?.drop_off_extra : ticket?.drop_off_location?.address}
                  </span>
                </p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Nhà xe</p>
                <p className="Ticket__desc">{ticket?.company_name}</p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Loại xe</p>
                <p className="Ticket__desc">{ticket?.bus_type}</p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Mã ghế</p>
                <p className="Ticket__desc">
                  {ticket?.is_diagram ? ticket?.seat_number : 'Chuyến không hỗ trợ chọn chỗ'}
                </p>
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Bảo hiểm chuyến đi</p>
                {ticket?.is_insurance ? (
                  <p className="Ticket__desc">
                    Có{' '}
                    <span className="ml-4 text-color-green-default">
                      (+{formatPrice(ticket?.insurance_fee, 0, ',')}đ)
                    </span>
                  </p>
                ) : (
                  <p className="Ticket__desc">Không</p>
                )}
              </Flex>
              <Flex align="start">
                <p className="Ticket__property">Giá vé</p>
                <p className="Ticket__desc">{formatPrice(ticket?.price, 0, ',')}đ</p>
              </Flex>
              {accountDetail?.group !== USER_GROUP.CAR_COMPANY && (
                <Flex align="start">
                  <p className="Ticket__property">Số tiền xuất vé</p>
                  <p className="Ticket__desc">
                    {formatPrice(ticket?.price_export, 0, ',')}đ{' '}
                    {accountUser?.group === USER_GROUP.CARLINK
                      ? '(Số tiền trả cho nhà xe)'
                      : '(Số tiền trả cho Carlink API chưa bao gồm phí BH)'}
                  </p>
                </Flex>
              )}
              <Flex align="start">
                <p className="Ticket__property">Ghi chú</p>
                <p className="Ticket__desc">{ticket?.note}</p>
              </Flex>
            </Flex>
          </div>
        ))}
        <ModalExport
          title="Xuất vé trong đơn hàng"
          desc="Bạn đang thao tác xuất vé trong đơn hàng này, bạn có chắc chắn muốn xuất?"
          open={openExport}
          setOpen={setOpenExport}
          onExport={() => handleExportTicket(ticketActive)}
        />
        <ModalCancel
          open={openCancel}
          status={ticketItemActive?.status as string}
          setOpen={setOpenCancel}
          onCancelTicket={handleCancelTicket}
          ticket_code={ticketActive}
          total_price={ticketItemActive?.price ? ticketItemActive?.price : 0}
          refund={ticketItemActive?.cancel_info?.refund ? ticketItemActive?.cancel_info?.refund : 0}
          total_cancel_fee={ticketItemActive?.cancel_info?.cancel_fee ? ticketItemActive?.cancel_info?.cancel_fee : 0}
        />
        <ModalHistoryTicket
          openHistory={openModalHistory}
          setOpenHistory={setOpenModalHistory}
          ticket_item={ticketItemActive as TicketParams}
        />
        <ModalPrintTicket
          modalOpen={openModalPrint}
          onCancel={cancelModalPrint}
          onOk={() => setOpenModalPrint(false)}
          listOrder={ticketItemActive}
          isOrder={true}
          group={accountDetail?.group}
        />
      </div>
    </div>
  );
};

export default ListTicket;
