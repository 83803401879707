import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { getBookingSendMailApi, updateBookingStatusApi } from '../../../api/booking';
import { Toast } from '../../../config/constant';
import { Action, CREATE_ERROR } from '../../../config/message';
import { BookingState } from '../../../types/booking';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

export const updateStatusBooking = createAsyncThunk(
  'admin/bookings/lock',
  async (params: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateBookingStatusApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: data?.message,
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message: CREATE_ERROR(Action.LOCK_BOOKING),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const getSendBookingMail = createAsyncThunk(
  'admin/bookings/send-mail',
  async (bookingId: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getBookingSendMailApi(bookingId);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: 'Gửi mail thành công',
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message: response?.data?.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const updateBookingSlice = createSlice({
  name: 'updateBooking',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
    loadingSend: false,
  } as BookingState,
  reducers: {
    setUpdate: (state: BookingState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setUpdateError: (state: BookingState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetUpdateBooking: (state: BookingState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateStatusBooking.pending, (state: BookingState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(updateStatusBooking.fulfilled, (state: BookingState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(updateStatusBooking.rejected, (state: BookingState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
    builder.addCase(getSendBookingMail.pending, (state: BookingState) => {
      state.loadingSend = true;
      state.success = false;
    });
    builder.addCase(getSendBookingMail.fulfilled, (state: BookingState) => {
      state.loadingSend = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getSendBookingMail.rejected, (state: BookingState, { payload }) => {
      state.loadingSend = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const updateBookingSelector = (state: RootState) => state.updateBooking;
export const { setUpdateError, setUpdate, resetUpdateBooking } = updateBookingSlice.actions;
