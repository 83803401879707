/* eslint-disable react-hooks/exhaustive-deps */
import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Modal, Popconfirm, Row, Select, Space, Typography, Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkAuthorization } from '../../api';
import { changeAgentStatusApi, updateAgentDetailApi } from '../../api/agent';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';
import IcLock from '../../assets/images/ic-lock.svg';
import '../../assets/styles/desktop/busCompany.scss';
import '../../assets/styles/listAgent.scss';
import { AgentType, Constant } from '../../config/constant';
import { ACTION_NAME, messageUpdateSuccess } from '../../config/message';
import {
  validateAddressAgent,
  validateCommissionPercentAgent,
  validateNameAgent,
} from '../../config/validators/validateAgent';
import { phoneRequiredRules, validatorEmail } from '../../config/validators/validators';
import { getBase64 } from '../../lib/utils';
import TutorialsVideo from '../../components/TutorialsVideo';
import { handleGetUrlGuide } from '../../utils/helpers';
import icClose from "../../assets/images/listOffice/icClose.svg";



function UpdateAgent({ openCreate, setOpenCreate, detailData, handleListAgentAPI, listTutorials }: any) {
  const image: any = detailData?.logo
    ? [
        {
          uid: '-1',
          name: 'No avatar',
          status: 'done',
          url: detailData?.logo,
        },
      ]
    : [];
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewTitle, setPreviewTitle] = useState('');

  const onChange = (value: string) => {
  };

  const onSearch = (value: string) => {
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  const handleCancel = () => {
    if (!openCreate) {
      form.resetFields();
      setFileList([]);
    }
    setOpenCreate(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleOkModal = async () => {
    try {
      const res = await changeAgentStatusApi(detailData?.id, {
        status: detailData?.status ? 0 : 1,
      });
      if (res.status === Constant.DEFAULT_STATUS) {
        handleListAgentAPI();
        messageUpdateSuccess(ACTION_NAME.AGENT);
        setOpenCreate(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  //HANDLE SUBMIT

  const onFinish = async (values: any) => {
    values.logo = fileList[0]?.thumbUrl;
    try {
      const response: any = await updateAgentDetailApi(detailData?.id, values);
      if (response.status === Constant.DEFAULT_STATUS) {
        messageUpdateSuccess(ACTION_NAME.AGENT);
        handleListAgentAPI();
        setOpenCreate(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };
  // upload image
  const handleCancelImage = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    // Giữ chỉ một tệp trong fileList
    setFileList(newFileList.slice(-1));
  };

  useEffect(() => {
    setFileList(image);
  }, [detailData?.logo]);

  useEffect(() => {
    form.setFieldsValue({
      id: detailData?.id,
      name: detailData?.name,
      email: detailData?.email,
      address: detailData?.address,
      note: detailData?.note,
      logo: detailData?.logo,
      phone: detailData?.phone,
      type: detailData?.type,
      commission_percent: detailData?.commission_percent,
    });
  }, [detailData?.id]);

  return (
    <Modal
      open={openCreate}
      maskClosable={false}
      title={
        <Row style={{ display: "flex", justifyContent: "space-between" , marginBottom: 20}}>
        <Typography.Text className="fw-600 fs-18 ">
          {t("Chỉnh sửa thông tin đại lý")}
        </Typography.Text>
        <Space size={10} align="center">
          <TutorialsVideo
            url_youtube={handleGetUrlGuide(listTutorials, 11)}
          />
          <Image
            src={icClose}
            style={{ cursor: "pointer" }}
            preview={false}
            onClick={handleCancel}
          />
        </Space>
      </Row>
      }
      onCancel={handleCancel}
      className="modal-edit-user customize-modal"
      cancelText="Hủy" // Đổi tên nút Cancel
      okText="Lưu"
      onOk={handleOk}
      width={980}
      closable={false}
      footer={(_, { OkBtn, CancelBtn }: any) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 24,
          }}
          className="footer-edit-modal-office"
        >
          <>
            <Popconfirm
              placement="topLeft"
              className="popconfirm-custom"
              title={detailData?.status ? t('Khóa đại lý') : t('Mở đại lý')}
              description={
                <div className='content-pop-des'>
                  {
                    detailData?.status
                      ? t('Bạn đang thao tác khóa đại lý này, bạn có chắc chắn muốn khóa?')
                      : t('Bạn đang thao tác mở đại lý này, bạn có chắc chắn muốn mở khóa?')
                  }
                </div>
              }
              okText={detailData?.status ? t('Khóa ngay') : t('Mở ngay')}
              cancelText={t('Trở lại')}
              okButtonProps={{ className: 'custom-ok-button-class' }} // Tùy chỉnh lớp CSS cho nút OK
              cancelButtonProps={{ className: 'custom-cancel-button-class' }} // Tùy chỉnh lớp CSS cho nút Hủy
              onConfirm={handleOkModal}
              icon={<Image preview={false} width={20} src={IconExclamationCircle} alt="Icon Exclamation Circle" />}
            >
              <Col className="block-user">
                <Button
                  style={{ width: 180, height: 48 }}
                  className={detailData?.status ? 'btn-block-red' : 'btn-active-green'}
                  danger
                  type="primary"
                  htmlType="button"
                  // onClick={showModal}
                >
                  <Image src={IcLock} preview={false} className="pb-1 pe-2" />
                  {detailData?.status ? t('Khóa đại lý') : t('Mở đại lý')}
                </Button>
              </Col>
            </Popconfirm>
          </>
          <div className="modal-btn-user modal-btn-agent d-flex gap-12">
            <CancelBtn className="modal-cancel-btn" />
            <OkBtn />
          </div>
        </div>
      )}
    >
      <Form
        name="agent-basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="form-create-agent form-edit-user height-auto"
      >
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item name="name" label={t('Tên đại lý')} className="required" rules={validateNameAgent} validateFirst>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="phone"
              rules={phoneRequiredRules}
              label={t('Số điện thoại')}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="address"
              label={t(' Địa chỉ ')}
              rules={validateAddressAgent}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={validatorEmail}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
        <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="type"
              label="Loại đại lý"
              rules={[
                {
                  required: true,
                  message: t("Vui lòng chọn loại đại lý"),
                },
              ]}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Select
                showSearch
                placeholder=""
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={AgentType}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="commission_percent"
              label={t('Chiết khấu')}
              rules={validateCommissionPercentAgent}
              className="required"
              validateFirst
            >
              <Input type="number" suffix={'%'} style={{width: 104}} min={0} />
            </Form.Item>
            {/* <span className="text-discount-agent">{t('Số % chiết khấu nhà xe trả cho đại lý trên mỗi chuyến')}</span> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item className="upload-image required-none" label={t('Logo')}>
              <Upload
                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : (
                  <>
                    <UploadOutlined /> {t('Tải ảnh lên')}
                  </>
                )}
              </Upload>
              <Modal open={previewOpen} footer={null} onCancel={handleCancelImage}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="note" label={t('Ghi chú')} className="input-group note">
          <Input.TextArea rows={4} cols={9} style={{ height: 96, width: 781, resize: 'none' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateAgent;
