/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { useTranslation } from "react-i18next";
import "../../assets/styles/infoCompany.scss";
import { ACTION_NAME, messageUpdateSuccess } from "../../config/message";
import { checkAuthorization } from "../../api";
import { getDetailAgentApi, updateAgentApi } from "../../api/agent";
import { URL_API_VIET_QR } from "../../api/vietqr";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import { filterOptionSearch, getBase64 } from "../../lib/utils";
import { useAppSelector } from "../../redux/hooks";

const InfoAgent: React.FC<any> = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [listBank, setListBank] = useState([]);
  const [loadingAgent, setLoadingAgent] = useState(false)
  const accountDetail = useAppSelector((state) => state.authAccount?.user);

  const image: any = dataDetail?.logo
    ? [
        {
          uid: "-1",
          name: "No avatar",
          status: "done",
          url: dataDetail?.logo,
        },
      ]
    : [];

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewTitle, setPreviewTitle] = useState("");

  // upload image
  const handleCancelImage = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // Giữ chỉ một tệp trong fileList
    setFileList(newFileList?.slice(-1));
  };

  useEffect(() => {
    setFileList(image);
  }, [dataDetail?.logo]);

  const handleDetailAgentApi = async () => {
    setLoadingAgent(true)
    try {
      const res = await getDetailAgentApi();
      if (res.status === 200) {
        setDataDetail(res.data.data);
      }
    } catch (error) {}
    setLoadingAgent(false)
  };

  const handleListQR = () => {
    try {
      axios
        .get(URL_API_VIET_QR)
        .then((response) => {
          const res = response.data;
          setListBank(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {}
  };

  const listBankSelect = listBank.map((item: any) => {
    return { value: item?.bin, label: `${item?.name} (${item?.code})` };
  });

  useEffect(() => {
    handleListQR();
  }, []);

  const handleTextTypeAgent = () => {
    if (dataDetail?.type === "personal") {
      return "Đại lý cá nhân";
    }
    if (dataDetail?.type === "enterprise") {
      return "Đại lý doanh nghiệp";
    }
    return "";
  };

  form.setFieldsValue({
    company_name: dataDetail?.name,
    address: dataDetail?.address,
    bank_account_name: dataDetail?.bank_account_name,
    phone: dataDetail?.phone,
    email: dataDetail?.email,
    debit_limit: dataDetail?.debit_limit,
    bank: dataDetail?.bank,
    bank_account_number: dataDetail?.bank_account_number,
    fax: dataDetail?.fax,
    office_name: dataDetail?.office_name,
    representative_name: dataDetail?.representative_name,
    tax_code: dataDetail?.tax_code,
    position: dataDetail?.position,
    type: handleTextTypeAgent(),
    commission_percent: dataDetail?.commission_percent,
  });

  const onFinish = async (values: any) => {
    values.logo = fileList[0]?.thumbUrl;
    try {
      const res = await updateAgentApi({ ...values, type: dataDetail?.type });
      if (res.status === 200) {
        messageUpdateSuccess(ACTION_NAME.AGENT);
        handleDetailAgentApi();
        setIsEdit(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const handleSubmit = () => {
    form.submit(); // Gọi phương thức submit của form instance
  };

  const onSearch = (value: string) => {
  };

  useEffect(() => {
    handleDetailAgentApi();
  }, []);

  return (
    <Spin spinning={loadingAgent} >
      <Card className="wrapper-info-company">
        <Col>
          <Form
            name="info-car-company"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            // validateTrigger="onSubmit"
            className="form-info-company"
          >
            <div>
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Col>
                  <Form.Item
                    name="type"
                    // className="required"
                    className="form-item-name required"
                    label={t("Loại đại lý")}
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng chọn loại đại lý"),
                      },
                    ]}
                    validateFirst
                  >
                    {/* <Select
                      className="w-221"
                      placeholder={t("Chọn loại đại lý")}
                      disabled={!isEdit}
                      options={AgentType}
                    /> */}
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="company_name"
                    className="required"
                    label={t("Tên đại lý")}
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên đại lý"),
                      },
                    ]}
                    validateFirst
                  >
                    <Input disabled className="w-841" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="address"
                    label={t("Địa chỉ")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập địa chỉ"),
                      },
                    ]}
                  >
                    <Input disabled className="w-841" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="representative_name"
                    label={
                      dataDetail?.type !== "personal"
                        ? t("Đại diện")
                        : t("Chủ đại lý")
                    }
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên người đại diện"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              {dataDetail?.type !== "personal" && (
                <Row>
                  <Col>
                    <Form.Item
                      name="position"
                      label={t("Chức vụ")}
                      className="required"
                      rules={[
                        {
                          required: true,
                          message: t("Vui lòng nhập chức vụ"),
                        },
                      ]}
                    >
                      <Input disabled={!isEdit} className="w-221" />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Item
                    name="tax_code"
                    label={t("Mã số thuế (MSDN)")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập mã số thuế"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              {dataDetail?.type !== "personal" && (
                <Row>
                  <Col>
                    <Form.Item
                      name="office_name"
                      label={t("VPGD")}
                      className="required"
                      rules={[
                        {
                          required: true,
                          message: t("Vui lòng nhập tên văn phòng"),
                        },
                      ]}
                    >
                      <Input disabled={!isEdit} className="w-221" />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Item
                    name="phone"
                    label={t("Điện thoại")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập số điện thoại"),
                      },
                    ]}
                  >
                    <Input disabled className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              {dataDetail?.type !== "personal" && (
                <Row>
                  <Col>
                    <Form.Item name="fax" label={t("Fax")}>
                      <Input disabled={!isEdit} className="w-355" />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Item name="email" label={t("Email")}>
                    <Input disabled className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col>
                  <Form.Item
                    name="bank_account_number"
                    label={t("Tài khoản số")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập số tài khoản"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} type="number" className="w-355" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="bank_account_name"
                    label={t("Chủ tài khoản")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên chủ tài khoản"),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-282" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    name="bank"
                    label={t("Tại ngân hàng")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập tên ngân hàng"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={onSearch}
                      optionFilterProp="label" // Đây là phần cần chỉnh sửa
                      filterOption={filterOptionSearch}
                      disabled={!isEdit}
                      className="w-841"
                      // onChange={handleChange}
                      options={listBankSelect}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="commission_percent"
                    label={t("Chiết khấu")}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t("Vui lòng nhập chiết khấu"),
                      },
                    ]}
                  >
                    <Input disabled suffix={"%"} min={0} style={{ width: 104 }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col style={{ width: "520px" }}>
                  <Form.Item
                    className={
                      !isEdit
                        ? "upload-image upload-image-info-agent image-disabled"
                        : "upload-image upload-image-info-agent"
                    }
                    name="logo"
                    label={t("Logo")}
                  >
                    <Upload
                      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      disabled={!isEdit}
                    >
                      {fileList.length >= 1 ? null : (
                        <>
                          <UploadOutlined /> {t("Tải ảnh lên")}
                        </>
                      )}
                    </Upload>
                    <Modal
                      open={previewOpen}
                      footer={null}
                      onCancel={handleCancelImage}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                </Col>
                {isEdit ? (
                  <Col className="container-btn-company ">
                    <Button onClick={() => setIsEdit(false)}>Trở lại</Button>
                    <Button type="primary" onClick={handleSubmit}>
                      Lưu
                    </Button>
                  </Col>
                ) : (
                  <Button
                    style={!accountDetail?.is_admin ? { display: "none" } : {}}
                    // type="primary"
                    className="btn-info-company"
                    onClick={() => setIsEdit(true)}
                  >
                    Chỉnh sửa
                  </Button>
                )}
              </Row>
            </div>
          </Form>
        </Col>
      </Card>
    </Spin>
  );
};

export default InfoAgent;
