import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { updateListBusTypeApi, updateListBusTypeStatusApi } from '../../../api/busType';
import { BusTypeState, UpdateBusTypeState } from '../../../types/busType';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';
import { Action, UPDATE_ERROR, UPDATE_SUCCESS } from '../../../config/message';
import { Toast } from '../../../config/constant';

// @ts-ignore
export const updateBusTypeDetail = createAsyncThunk(
  'admin/updateBusType/detail',
  async (params: UpdateBusTypeState, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateListBusTypeApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: UPDATE_SUCCESS(Action.TYPE_BUS),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message:  response?.data?.message ??  UPDATE_ERROR(Action.BUS),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const updateBusTypeStatus = createAsyncThunk(
  'admin/updateBusTypeStatus',
  async (
    params: {
      id: string;
      status: boolean;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await updateListBusTypeStatusApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: UPDATE_SUCCESS(Action.TYPE_BUS),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message: UPDATE_ERROR(Action.BUS),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const updateBusTypeSlice = createSlice({
  name: 'updateBusType',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as BusTypeState,
  reducers: {
    setUpdate: (state: BusTypeState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setUpdateError: (state: BusTypeState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetUpdateBusType: (state: BusTypeState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBusTypeDetail.pending, (state: BusTypeState) => {
      state.loading = true;
    });
    builder.addCase(updateBusTypeDetail.fulfilled, (state: BusTypeState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(updateBusTypeDetail.rejected, (state: BusTypeState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });

    builder.addCase(updateBusTypeStatus.pending, (state: BusTypeState) => {
      state.loading = true;
    });
    builder.addCase(updateBusTypeStatus.fulfilled, (state: BusTypeState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(updateBusTypeStatus.rejected, (state: BusTypeState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const updateBusTypeSelector = (state: RootState) => state.updateBusType;
export const { setUpdate, setUpdateError, resetUpdateBusType } = updateBusTypeSlice.actions;
