import { getListReportTotalAPI, getListReportTotalExcelAPI } from "../../../api/report";
import { ParamsReport, ReportTotal } from "../../../types/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },  
    total_paid_bookings: 0,
    total_prices_paid_at_company: 0,
    total_prices_agent_balance: 0,
    total_commissions: 0,
    total_prices: 0,
    filter: {
        booking_date: 'false',
        from: '',
        to: '',
    }
} as ReportTotal;

export const reportTotalSlice = createSlice({
    name: 'reportTotal',
    initialState,
    reducers: {
        setFilterBookingDate(state, action: PayloadAction<string>) {
            state.filter.booking_date = action.payload;
        },
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportTotal.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportTotal.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_paid_bookings = action.payload?.total_paid_bookings
                state.total_prices_paid_at_company = action.payload?.total_prices_paid_at_company
                state.total_prices_agent_balance = action.payload?.total_prices_agent_balance
                state.total_commissions = action.payload?.total_commissions
                state.total_prices = action.payload?.total_prices
            }).addCase(getListReportTotal.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportTotalExcel.pending, (state) => {
            }).addCase(getListReportTotalExcel.fulfilled, (state, action) => {
            }).addCase(getListReportTotalExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            })
    }
})

export const getListReportTotal = createAsyncThunk('ReportTotal/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportTotalAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportTotalExcel = createAsyncThunk('ReportTotal/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportTotalExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setPerPage, setFilterBookingDate, setFilterRangeDate } = reportTotalSlice.actions;