import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Modal, Popconfirm, Row, Select, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import { checkAuthorization } from '../../../api';
import { getOfficeStatusByIdApi, updateOfficeAPI } from '../../../api/offices';
import IconExclamationCircle from '../../../assets/images/ExclamationCircle.svg';
import IcLock from '../../../assets/images/ic-lock.svg';
import icClose from '../../../assets/images/listOffice/icClose.svg';
import IcMap from '../../../assets/images/listOffice/map.svg';
import '../../../assets/styles/createOffice.scss';
import TutorialsVideo from '../../../components/TutorialsVideo';
import { Constant } from '../../../config/constant';
import { ACTION_NAME, messageUpdateSuccess } from '../../../config/message';
import {
  validateAddressOffice,
  validateDistrictOffice,
  validateEmailOffice,
  validateNameOffice,
  validateNoteOffice,
  validateProvinceOffice,
  validateUserManageOffice,
  validateWardOffice,
} from '../../../config/validators/validateOffice';
import { phoneRulesRequired } from '../../../config/validators/validators';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchListDistrict, fetchListWard, resetLocation } from '../../../redux/slice/provinceSlice';
import { handleGetUrlGuide } from '../../../utils/helpers';

const EditOffice = ({ setOpen, open, callListOfficeApi, listUser, listProvince, detailOffice, listTutorials }: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [previewImage, setPreviewImage] = useState('');
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [fileListDelete, setFileListDelete] = useState<[]>([]);
  const [busImg, setBusImg] = useState([]);
  const { district, ward } = useAppSelector((state) => state?.province);
  const provinceId = Form.useWatch('province_id', form);
  const districtId = Form.useWatch('district_id', form);
  const onChangeBusImage = (imageList: ImageListType) => {
    setBusImg(imageList as never[]);
  };

  const handleErrorUpload = (errors: any, files: any) => {
    if (errors?.acceptType) {
      toast.error('Ảnh tải lên phải thuộc 1 trong 4 định dạng sau: png, jpg, jpeg, gif.');
    }
    if (errors?.maxNumber) {
      toast.error('Vui lòng tải lên tối đa 5 ảnh');
    }
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancelModal = () => {
    setOpen(false);
    dispatch(resetLocation());
  };

  function handleLocationClick() {
    if (navigator.geolocation) {
      form.setFieldsValue({
        longitude: 'loading ...',
        latitude: 'loading ...',
      });

      navigator.geolocation.getCurrentPosition(successLocate, errorLocate);
    } else {
      toast.error('Geolocation not supported');
    }
  }

  function successLocate(position: any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    form.setFieldsValue({
      longitude: longitude,
      latitude: latitude,
    });
  }

  function errorLocate() {
    form.setFieldsValue({
      longitude: '',
      latitude: '',
    });
    toast.error('Không thể truy xuất vị trí của bạn');
  }
  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: any }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleOkModal = async () => {
    try {
      const res = await getOfficeStatusByIdApi(detailOffice?.id, {
        status: !detailOffice?.status,
      });
      if (res.status === Constant.DEFAULT_STATUS) {
        callListOfficeApi();
        messageUpdateSuccess(ACTION_NAME.OFFICE);
        setOpen(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const onFinish = async (values: any) => {
    let listImages = busImg?.map((image: any) => image?.dataURL);
    values.images = listImages?.filter((image) => !image?.includes('https://'));

    if (fileListDelete.length > 0) {
      values.images_delete = fileListDelete;
    }
    try {
      const response: any = await updateOfficeAPI(detailOffice?.id, values);
      if (response.status === Constant.DEFAULT_STATUS) {
        messageUpdateSuccess(ACTION_NAME.OFFICE);
        callListOfficeApi();
        setOpen(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      name: detailOffice?.name,
      name_online: detailOffice?.name_online,
      email: detailOffice?.email,
      province_id: detailOffice?.province?.id,
      user_id: detailOffice?.user?.id,
      phone: detailOffice?.phone,
      address: detailOffice?.address,
      latitude: detailOffice?.latitude,
      longitude: detailOffice?.longitude,
      note: detailOffice?.note,
      map_link: detailOffice?.map_link,
      ward_id: detailOffice?.ward?.id ? detailOffice?.ward?.id : undefined,
      district_id: detailOffice?.district?.id,
      images: detailOffice?.images?.map((image: string) => ({
        dataURL: image,
      })),
    });
  }, [detailOffice?.id, form, detailOffice]);
  const selectProvince =
    listProvince?.length > 0
      ? listProvince.map((item: any) => ({
          value: +item.id,
          label: item.name,
        }))
      : [];
  const selectDistrict =
    district?.length > 0
      ? district.map((item: any) => ({
          value: +item.id,
          label: item.name,
        }))
      : [];
  const selectWard =
    ward?.length > 0
      ? ward.map((item: any) => ({
          value: +item.id,
          label: item.name,
        }))
      : [];

  const selectUser =
    listUser?.length > 0
      ? listUser.map((item: any) => ({
          value: +item.id,
          label: item.name,
        }))
      : [];
  const handleChangeProvince = (value: any, option: any) => {
    dispatch(fetchListDistrict({ province_id: value }));
    form.setFieldValue('district_id', undefined);
    form.setFieldValue('ward_id', undefined);
  };
  const handleChangeWard = (value: any, option: any) => {
    dispatch(fetchListWard({ district_id: value }));
    form.setFieldValue('ward_id', undefined);
  };
  useEffect(() => {
    if (provinceId) {
      dispatch(fetchListDistrict({ province_id: provinceId }));
    }
  }, [provinceId, dispatch]);

  useEffect(() => {
    if (districtId) {
      dispatch(fetchListWard({ district_id: districtId }));
    }
  }, [districtId, dispatch]);
  return (
    <>
      <Modal
        title={
          <Row style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
            <Typography.Text className="fw-600 fs-18 ">{t('Chỉnh sửa thông tin văn phòng')}</Typography.Text>
            <Space size={10} align="center">
              <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 3)} />
              <Image src={icClose} style={{ cursor: 'pointer' }} preview={false} onClick={handleCancelModal} />
            </Space>
          </Row>
        }
        open={open}
        onOk={handleOk}
        maskClosable={false}
        closeIcon={false}
        onCancel={handleCancelModal}
        okText="Lưu"
        styles={{
          body: { height: '580px', overflowY: 'auto', overflowX: 'hidden' },
        }}
        cancelText="Đóng"
        width={834}
        className="modal-create-office scroll-custom-modal"
        footer={(_, { OkBtn, CancelBtn }: any) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 24,
            }}
            className="footer-edit-modal-office"
          >
            <>
              <Popconfirm
                placement="topLeft"
                className="popconfirm-custom"
                title={detailOffice?.status ? t('Khóa văn phòng') : t('Mở văn phòng')}
                description={
                  <div className="content-pop-des">
                    {detailOffice?.status
                      ? t('Bạn đang thao tác khóa văn phòng này, bạn có chắc chắn muốn khóa?')
                      : t('Bạn đang thao tác mở khóa văn phòng này, bạn có chắc chắn muốn mở khóa?')}
                  </div>
                }
                okText={detailOffice?.status ? t('Khóa ngay') : t('Mở ngay')}
                cancelText={t('Trở lại')}
                okButtonProps={{ className: 'custom-ok-button-class' }} // Tùy chỉnh lớp CSS cho nút OK
                cancelButtonProps={{ className: 'custom-cancel-button-class' }} // Tùy chỉnh lớp CSS cho nút Hủy
                onConfirm={handleOkModal}
                icon={<Image preview={false} width={20} src={IconExclamationCircle} alt="Icon Exclamation Circle" />}
              >
                <Col className="block-user">
                  <Button
                    className={detailOffice?.status ? 'btn-block-red' : 'btn-active-green'}
                    danger
                    type="primary"
                    htmlType="button"
                    // onClick={showModal}
                  >
                    <Image src={IcLock} preview={false} className="pb-1 pe-2" />
                    {detailOffice?.status ? t('Khóa văn phòng') : t('Mở văn phòng')}
                  </Button>
                </Col>
              </Popconfirm>
            </>
            <div className="modal-btn-user d-flex gap-12">
              <CancelBtn className="modal-cancel-btn" />
              <OkBtn />
            </div>
          </div>
        )}
      >
        <Form form={form} onFinish={onFinish} layout="vertical" className="form-create-office">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label={t('Tên văn phòng')} rules={validateNameOffice} className="required">
                <Input placeholder={t('Nhập tên văn phòng')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="name_online" label={t('Tên online')}>
                <Input placeholder={t('Nhập tên văn phòng')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} md={8}>
              <Form.Item
                name="province_id"
                label={t('Tỉnh/ Thành phố')}
                rules={validateProvinceOffice}
                className="required"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder={t('Chọn tỉnh/ thành phố')}
                  options={selectProvince}
                  onChange={(value: any, option: any) => handleChangeProvince(value, option)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="district_id"
                className="form-item-name required"
                label={t('Quận / Huyện')}
                validateTrigger={['onChange', 'onBlur']}
                rules={validateDistrictOffice}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder="Chọn quận / huyện"
                  options={selectDistrict}
                  onChange={(value: any, option: any) => handleChangeWard(value, option)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="ward_id"
                className="form-item-name required-none"
                label={t('Phường / Xã')}
                validateTrigger={['onChange', 'onBlur']}
                // rules={validateWardOffice}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder="Chọn phường / xã"
                  options={selectWard}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item
                name="user_id"
                label={t('Người quản lý')}
                rules={validateUserManageOffice}
                className="required"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder={t('Chọn người quản lý')}
                  options={selectUser}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="phone" label={t('Số điện thoại')} className="required" rules={phoneRulesRequired}>
                <Input placeholder={t('Nhập số điện thoại')} />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name="email" label={t('Email')} rules={validateEmailOffice} className="required">
                <Input placeholder={t('Nhập email')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="address" label={t('Địa chỉ')} rules={validateAddressOffice} className="required">
                <Input placeholder={t('Nhập địa chỉ')} />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ display: 'none' }}>
            <Button
              className="btn-locate"
              onClick={handleLocationClick}
              style={{
                marginBottom: 15,
              }}
            >
              <Image src={IcMap} preview={false} className="mb-1 pe-2" />
              Sử dụng vị trí hiện tại
            </Button>
          </Row>
          <Row gutter={24} style={{ display: 'none' }}>
            <Col xs={24} md={12}>
              <Form.Item name="longitude" className="form-item-name required" validateTrigger={['onChange', 'onBlur']}>
                <Input autoComplete="off" addonBefore="Longitude" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="latitude" className="form-item-name required" validateTrigger={['onChange', 'onBlur']}>
                <Input autoComplete="off" addonBefore="Latitude" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <a href="https://www.google.com/maps" target="_blank" rel="noreferrer">
              <Button
                className="btn-locate"
                style={{
                  marginBottom: 10,
                }}
              >
                <Image src={IcMap} preview={false} className="mb-1 pe-2" />
                {t('Sử dụng google map')}
              </Button>
            </a>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="map_link" className="required">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="note" label={t('Ghi chú')} rules={validateNoteOffice}>
                <Input.TextArea rows={4} placeholder={t('Nhập ghi chú')} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <span className="fw-500 text-color">Ảnh văn phòng</span>
              <Form.Item
                name="images"
                className="list_image_car"
                // rules={[{ required: true, message: "Vui lòng nhập ảnh văn phòng" }]}
              >
                <ImageUploading
                  multiple
                  value={form.getFieldValue('images')}
                  onChange={onChangeBusImage}
                  maxNumber={5}
                  acceptType={['jpg', 'gif', 'png', 'jpeg']}
                  onError={handleErrorUpload}
                >
                  {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                    <div className="Upload">
                      <Row className="h-100" gutter={[16, 16]}>
                        {imageList?.length > 0 &&
                          imageList?.map((image, index) => (
                            <Col span={6} key={index} className="Upload__item">
                              <div className="Upload__item__wrapper">
                                <img className="Upload__item__image" src={image?.dataURL} alt="" />
                                <div className="Upload__item__remove">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setPreviewImage(image?.dataURL as any);
                                      setOpenPreview(true);
                                    }}
                                    className="border-0 bg-transparent"
                                  >
                                    <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                  </button>
                                  <button
                                    type="button"
                                    className="border-0 bg-transparent"
                                    onClick={() => {
                                      const url: any = image?.dataURL;
                                      // Use split method to split the URL at the desired segment
                                      const baseUrl = url.split('/public/controlpanel/assets/images')[1];
                                      setFileListDelete((fileList) => [...fileList, baseUrl] as any);
                                      onImageRemove(index);
                                    }}
                                  >
                                    <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                  </button>
                                </div>
                              </div>
                            </Col>
                          ))}
                        <Col span={6} className="Upload__item">
                          <button
                            type="button"
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            className="Upload__item__button"
                            {...dragProps}
                          >
                            <div>
                              <div className="fs-18 fw-500 text-color-default">
                                <UploadOutlined className="fs-24" /> Tải ảnh lên
                              </div>
                            </div>
                          </button>
                        </Col>
                      </Row>
                    </div>
                  )}
                </ImageUploading>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal footer={null} open={openPreview} onCancel={() => setOpenPreview(false)} width={700}>
          <div className="w-100" style={{ padding: '16px' }}>
            <img className="w-100" src={previewImage} alt="preview" />
          </div>
        </Modal>
      </Modal>
    </>
  );
};

export default EditOffice;
