import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  type FormProps,
} from 'antd';
import { useEffect, useState } from 'react';

import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';
import icEdit from '../../assets/images/ic-edit.svg';
import IcPlus from '../../assets/images/ic-plus.svg';
import IcUnLock from '../../assets/images/ic-unlock.svg';
import iconChecked from '../../assets/images/icon-checked.svg';
import icPlus from '../../assets/images/schedules/ic-plus-box.svg';
import icCancel from '../../assets/images/trips/ic-cancel.svg';
import icNope from '../../assets/images/trips/ic-nope.svg';
import TabsFilterStatus from '../../components/TabsFilterStatus/TabsFilterStatus';
import { Constant, TabType } from '../../config/constant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  createTicket,
  getAllListRoute,
  getListTickets,
  updateStatusTicket,
  updateTicket,
} from '../../redux/slice/tickets/ticketsSlice';
import { formatPrice, handleGetUrlGuide } from '../../utils/helpers';
import './tickets.scss';

import type { GetProps } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TutorialsVideo from '../../components/TutorialsVideo';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

dayjs.extend(customParseFormat);

enum UNIT {
  price,
  percent,
}

enum ADJUST {
  desc,
  asc,
  contact,
}

type FieldTypeForm = {
  adjust_up: number;
  adjust_down: number;
  range_date: string[];
  reason: string;
  route_id: number[];
  unit: UNIT;
  adjustType: ADJUST;
};

const { RangePicker } = DatePicker;

// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};

function SettingSchedulePrice() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [statusDetail, setStatusDetail] = useState(false);
  const [idTicketUpdate, setIdTicketUpdate] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<TabType>(TabType.ALL);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);

  const [form] = Form.useForm();
  const unitValueWatch = Form.useWatch('unit', form);
  const adjustValueWatch = Form.useWatch('adjustType', form);
  const watchRouteId = Form.useWatch('route_id', form);

  const pagination = useAppSelector((state) => state?.tickets?.pagination);
  const listTickets = useAppSelector((state) => state?.tickets?.listTickets);
  const loadingListTickets = useAppSelector((state) => state?.tickets?.loading);

  const onSubmit: FormProps<FieldTypeForm>['onFinish'] = async (values) => {
    const isIncrease = form.getFieldValue('adjustType') === ADJUST.asc;
    const isContact = form.getFieldValue('adjustType') === ADJUST.contact;
    let price: number | null = null;
    let percent: number | null = null;
    if (form.getFieldValue('unit') === UNIT.percent) {
      percent = isIncrease ? values?.adjust_up : values?.adjust_down;
    }
    if (form.getFieldValue('unit') === UNIT.price) {
      price = isIncrease ? values?.adjust_up : values?.adjust_down;
    }
    const payload = {
      route_id: values?.route_id?.map((route: any) => route?.id),
      reason: values?.reason,
      is_increase: isContact ? null : isIncrease,
      percent: isContact ? null : percent,
      price: isContact ? null : price,
      is_price_contact: isContact ? isContact : false,
      start_date: dayjs(values?.range_date?.[0]).format('YYYY-MM-DD'),
      end_date: dayjs(values?.range_date?.[1]).format('YYYY-MM-DD'),
    };
    if (isUpdate) {
      const response = await dispatch(updateTicket({ ...payload, id: idTicketUpdate }));
      if (response?.payload?.success) {
        dispatch(
          getListTickets({
            page,
            limit,
            status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
          }),
        );
        form.resetFields();
        setOpenCreate(false);
      } else {
        if (response?.payload?.response?.data?.errors) {
          const listErrors = response?.payload?.response?.data?.errors;
          form.setFields(
            Object.keys(listErrors).map((fieldName) => ({
              name: fieldName,
              errors: [listErrors[fieldName]],
            })),
          );
        }
      }
    } else {
      const response = await dispatch(createTicket(payload));
      if (response?.payload?.success) {
        dispatch(
          getListTickets({
            page,
            limit,
            status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
          }),
        );
        form.resetFields();
        setOpenCreate(false);
      } else {
        if (response?.payload?.response?.data?.errors) {
          const listErrors = response?.payload?.response?.data?.errors;
          form.setFields(
            Object.keys(listErrors).map((fieldName) => ({
              name: fieldName,
              errors: [listErrors[fieldName]],
            })),
          );
        }
      }
    }
  };

  const handleOkModal = async () => {
    setIsModalVisible(false);
    if (statusDetail) {
      setStatusDetail(false);
    } else {
      setStatusDetail(true);
    }
    const response = await dispatch(updateStatusTicket({ id: idTicketUpdate, status: !statusDetail }));
    if (response?.payload?.success) {
      dispatch(
        getListTickets({
          page,
          limit,
          status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
        }),
      );
      setOpenCreate(false);
      form.resetFields();
    } else {
      if (response?.payload?.response?.data?.errors) {
        const listErrors = response?.payload?.response?.data?.errors;
        form.setFields(
          Object.keys(listErrors).map((fieldName) => ({
            name: fieldName,
            errors: [listErrors[fieldName]],
          })),
        );
      }
    }
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const cancelModal = () => {
    setOpenCreate(false);
    setIsUpdate(false);
    form.resetFields();
  };

  const scrollToErrorField = (errorFields: any) => {
    const [firstErrorField] = errorFields;
    if (firstErrorField) {
      form.scrollToField(firstErrorField.name);
    }
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = (record: any) => {
    setIdTicketUpdate(record?.id);
    setOpenCreate(true);
    setIsUpdate(true);
    form.setFieldsValue({
      reason: record?.reason,
      range_date: [dayjs(record?.start_date), dayjs(record?.end_date)],
      route_id: [
        {
          id: record?.route?.id,
          name: `${record?.route.depart_point} - ${record?.route.arrive_point}`,
        },
      ],
      adjustType: record?.is_price_contact ? ADJUST.contact : record?.is_increase ? ADJUST.asc : ADJUST.desc,
      adjust_down: record?.is_price_contact
        ? null
        : record?.is_increase
        ? null
        : record?.percent
        ? record?.percent
        : record?.price,
      adjust_up: record?.is_price_contact
        ? null
        : record?.is_increase
        ? record?.percent
          ? record?.percent
          : record?.price
        : null,
      unit: record?.is_price_contact ? null : record?.price ? UNIT.price : UNIT.percent,
    });
    setStatusDetail(record?.status);
  };

  const columns: any = [
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
      render: (_: any, record: any) => (
        <Space size="middle">
          {record.status ? (
            <FontAwesomeIcon icon={faCircle} className="green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="danger" size="xs" />
          )}
          <Typography className="text-color">{record.reason}</Typography>
        </Space>
      ),
    },
    {
      title: 'Tuyến xe áp dụng',
      dataIndex: 'route',
      key: 'route',
      render: (route: any) => `${route?.depart_point} - ${route?.arrive_point}`,
    },
    {
      title: 'Từ ngày',
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: (a: any, b: any) => moment(a.start_date).unix() - moment(b.start_date).unix(),
      render: (date: any) => moment(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Đến ngày',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '10%',
      render: (date: any) => moment(date).format('DD/MM/YYYY'),
      sorter: (a: any, b: any) => moment(a.end_date).unix() - moment(b.end_date).unix(),
    },
    {
      title: 'Tăng',
      dataIndex: 'asc',
      key: 'asc',
      render: (_: any, record: any) =>
        record?.is_increase
          ? `${record?.percent ? record?.percent + '%' : formatPrice(record?.price, _, `,`) + ' VND'}`
          : '--',
    },
    {
      title: 'Giảm',
      dataIndex: 'desc',
      key: 'desc',
      render: (_: any, record: any) =>
        record?.is_increase
          ? '--'
          : `${record?.percent ? record?.percent + '%' : formatPrice(record?.price, _, `,`) + ' VND'}`,
    },
    {
      title: 'Giá liên hệ',
      dataIndex: 'is_price_contact',
      key: 'is_contact',
      render: (_: any, record: any) => (!record?.is_price_contact ? '--' : <img src={iconChecked} alt="" />),
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Chỉnh sửa">
            <button className="Table__button__edit" onClick={() => handleUpdate(record)}>
              <Image width={17} height={17} className="icon-edit mr-20" src={icEdit} preview={false} />
            </button>
          </Tooltip>
        </Space>
      ),
      align: 'center',
      width: '120px',
    },
  ];

  const routes = useAppSelector((state) => state?.tickets?.listRoute);

  const renderOptionsRoute = () => {
    const optionsRoute = routes?.length
      ? routes?.map((item) => ({
          value: item.id,
          label: `${item.depart_point} - ${item.arrive_point}`,
        }))
      : [];
    return optionsRoute?.filter((item) => !watchRouteId?.find((route: any) => route?.id === item?.value));
  };

  useEffect(() => {
    dispatch(getAllListRoute({ keyword: '' }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getListTickets({
        page,
        limit,
        status: activeTab === TabType.ALL ? null : activeTab === TabType.ACTIVE ? true : false,
      }),
    );
  }, [dispatch, activeTab, limit, page]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const filterOptionRoute = (
    input: string,
    option?: {
      label: string;
      value: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const handleChangeTab = (key: TabType) => {
    setActiveTab(key);
    setPage(1);
  };
  return (
    <Layout className="min-vh-70 list-ticket">
      <Card>
        <TabsFilterStatus activeTab={activeTab} onChangeTab={handleChangeTab} />
        <Row className="fw-500 mt-4 mx-3 mb-3 d-flex justify-content-between">
          <Button type="primary" className={'btn-success height-btn-40'} onClick={() => setOpenCreate(true)}>
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t('Thêm lịch tăng giảm giá vé')}
          </Button>
          <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 5)} />
        </Row>
        <Table
          locale={{
            triggerDesc: 'Sắp xếp tăng dần',
            triggerAsc: 'Hủy sắp xếp',
            cancelSort: 'Sắp xếp giảm dần',
          }}
          dataSource={listTickets?.length > 0 ? listTickets : []}
          rowKey="id"
          rowClassName={(record) => (record?.status ? '' : 'bg-disabled')}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          className="mx-3 list-trips"
          loading={loadingListTickets}
        />
        <Modal
          centered
          className="create-ticket-setting Modal-ticket"
          open={openCreate}
          onOk={() => setOpenCreate(false)}
          onCancel={() => cancelModal()}
          styles={{
            body: { maxHeight: '90vh', overflowY: 'auto', overflowX: 'hidden' },
          }}
          width={900}
          footer={[]}
          maskClosable={false}
        >
          <Flex align="center" justify="space-between" className="mr-20">
            <Typography.Title className="fs-20 fw-500 mb-0">Chi tiết thông tin lịch tăng giảm giá vé</Typography.Title>
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 5)} />
          </Flex>

          <Form
            className="mg-16 mt-30 fw-500 text-color-default"
            form={form}
            labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
            requiredMark={false}
            onFinish={onSubmit}
            onFieldsChange={(changedFields, allFields) => {
              scrollToErrorField(allFields.filter((field) => field.errors && field.errors.length > 0));
            }}
            colon={false}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Col>
                  <span className="required">{t(`Lý do`)}</span>
                  <Form.Item<FieldTypeForm>
                    name="reason"
                    className="form_reason"
                    rules={[{ required: true, message: 'Vui lòng chọn lý do' }]}
                  >
                    <Input placeholder={t('Nhập lý do')} />
                  </Form.Item>
                </Col>
                <Form.List name="route_id">
                  {(fields, { add, remove }) => (
                    <>
                      <div className="form__route" style={{ padding: '0 8px' }}>
                        <span className="required">Tuyến xe áp dụng</span>
                        <Form.Item
                          className="form-item-name"
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn tuyến xe',
                            },
                          ]}
                        >
                          <Select
                            className="input-h40"
                            showSearch
                            placeholder="Chọn tuyến"
                            filterOption={filterOptionRoute}
                            size="large"
                            style={{ width: '100%' }}
                            options={renderOptionsRoute()}
                            optionRender={(option) => (
                              <Space className="w-100 fw-500 text-color-default">
                                <Image width={28} height={26} src={icPlus} preview={false} />
                                <span>{option.data.label}</span>
                              </Space>
                            )}
                            onChange={(value, option: any) => {
                              add({
                                id: value,
                                name: option?.label,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ padding: '0 8px' }}>
                        {fields.map((field) => {
                          return (
                            <Flex className="form__route__item" align="center" key={field.key}>
                              <Button
                                type="text"
                                onClick={() => remove(field.name)}
                                className="p-0 h-auto"
                                style={{ marginRight: '8px' }}
                              >
                                <Image
                                  width={37}
                                  height={37}
                                  src={icCancel}
                                  preview={false}
                                  style={{ width: '100%' }}
                                />
                              </Button>
                              <Form.Item className="d-none" name={[field.name, 'id']}>
                                <Input disabled />
                              </Form.Item>
                              <Form.Item className="form-name d-flex flex-grow-1" name={[field.name, 'name']}>
                                <Input disabled />
                              </Form.Item>
                            </Flex>
                          );
                        })}
                      </div>
                    </>
                  )}
                </Form.List>

                <Col style={{ marginTop: '20px' }}>
                  <Form.Item<FieldTypeForm> name="adjustType" initialValue={ADJUST.asc}>
                    <Radio.Group
                      size="small"
                      onChange={(e) => {
                        const adjustType = e.target.value;
                        if (adjustType === ADJUST.asc || adjustType === ADJUST.desc) {
                          form.setFieldsValue({ unit: UNIT.percent });
                        } else if (adjustType === ADJUST.contact) {
                          form.setFieldsValue({ unit: undefined });
                        }
                      }}
                    >
                      <Radio value={ADJUST.asc}>
                        <div>
                          <span className="text-color-default">Tăng</span>
                          <div
                            className={`form-item__adjust ${
                              adjustValueWatch === ADJUST.desc || adjustValueWatch === ADJUST.contact ? 'disabled' : ''
                            }`}
                          >
                            <Flex
                              className="input-flex-container"
                              gap={0}
                              align="center"
                              vertical={false}
                              style={{ position: 'relative' }}
                            >
                              <Form.Item<FieldTypeForm>
                                name="adjust_up"
                                dependencies={['unit', 'adjustType']}
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (getFieldValue('adjustType') === ADJUST.asc) {
                                        if (value) {
                                          if (getFieldValue('unit') === UNIT.percent) {
                                            if (value < 1)
                                              return Promise.reject(new Error('Giá trị tăng tối thiểu 1%'));
                                            if (value > 100)
                                              return Promise.reject(new Error('Giá trị tăng tối đa 100%'));
                                            return Promise.resolve();
                                          } else {
                                            if (value < 10000)
                                              return Promise.reject(new Error('Giá trị tăng tối thiểu 10,000VND'));
                                            if (value > 10000000)
                                              return Promise.reject(
                                                new Error('Giá trị tăng không vượt quá 10,000,000VND'),
                                              );
                                            return Promise.resolve();
                                          }
                                        } else {
                                          return Promise.reject(new Error('Vui lòng nhập giá trị tăng'));
                                        }
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber<number>
                                  formatter={(value) => {
                                    if (unitValueWatch === UNIT.price) {
                                      return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    } else {
                                      return `${value}`;
                                    }
                                  }}
                                  className="input__price"
                                  controls={false}
                                  disabled={adjustValueWatch === ADJUST.desc || adjustValueWatch === ADJUST.contact}
                                  placeholder="Nhập giá trị"
                                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                />
                              </Form.Item>
                              <div className="Modal__item__adjust">
                                <Divider type="vertical" />
                                <Flex className="input-suffix-container" gap={6} align="center" vertical={false}>
                                  <Form.Item<FieldTypeForm> name="unit" initialValue={UNIT.percent}>
                                    <Radio.Group
                                      disabled={adjustValueWatch === ADJUST.desc || adjustValueWatch === ADJUST.contact}
                                      size="small"
                                    >
                                      <Radio.Button value={UNIT.percent}>%</Radio.Button>
                                      <Radio.Button value={UNIT.price}>VND</Radio.Button>
                                    </Radio.Group>
                                  </Form.Item>
                                </Flex>
                              </div>
                            </Flex>
                          </div>
                        </div>
                      </Radio>
                      <Radio value={ADJUST.desc}>
                        <div>
                          <span className="text-color-default">Giảm</span>
                          <div
                            className={`form-item__adjust ${
                              adjustValueWatch === ADJUST.asc || adjustValueWatch === ADJUST.contact ? 'disabled' : ''
                            }`}
                          >
                            <Flex
                              className="input-flex-container"
                              gap={0}
                              align="center"
                              vertical={false}
                              style={{ position: 'relative' }}
                            >
                              <Form.Item<FieldTypeForm>
                                name="adjust_down"
                                dependencies={['unit', 'adjustType']}
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (getFieldValue('adjustType') === ADJUST.desc) {
                                        if (value) {
                                          if (getFieldValue('unit') === UNIT.percent) {
                                            if (value < 1)
                                              return Promise.reject(new Error('Giá trị giảm tối thiểu 1%'));
                                            if (value > 100)
                                              return Promise.reject(new Error('Giá trị giảm tối đa 100%'));
                                            return Promise.resolve();
                                          } else {
                                            if (value < 10000)
                                              return Promise.reject(new Error('Giá trị giảm tối thiểu 10,000VND'));
                                            if (value > 10000000)
                                              return Promise.reject(
                                                new Error('Giá trị giảm không vượt quá 10,000,000VND'),
                                              );
                                            return Promise.resolve();
                                          }
                                        } else {
                                          return Promise.reject(new Error('Vui lòng nhập giá trị giảm'));
                                        }
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber<number>
                                  formatter={(value) => {
                                    if (unitValueWatch === UNIT.price) {
                                      return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    } else {
                                      return `${value}`;
                                    }
                                  }}
                                  className="input__price"
                                  controls={false}
                                  placeholder="Nhập giá trị"
                                  disabled={adjustValueWatch === ADJUST.asc || adjustValueWatch === ADJUST.contact}
                                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                />
                              </Form.Item>
                              <div className="Modal__item__adjust">
                                <Divider type="vertical" />
                                <Flex className="input-suffix-container" gap={6} align="center" vertical={false}>
                                  <Form.Item<FieldTypeForm> name="unit" initialValue={form.getFieldValue('unit')}>
                                    <Radio.Group
                                      disabled={adjustValueWatch === ADJUST.asc || adjustValueWatch === ADJUST.contact}
                                      size="small"
                                    >
                                      <Radio.Button value={UNIT.percent}>%</Radio.Button>
                                      <Radio.Button value={UNIT.price}>VND</Radio.Button>
                                    </Radio.Group>
                                  </Form.Item>
                                </Flex>
                              </div>
                            </Flex>
                          </div>
                        </div>
                      </Radio>
                      <Radio value={ADJUST.contact}>
                        <div>
                          <span className="text-color-default">Giá liên hệ</span>
                          <div
                            className={`form-item__adjust ${
                              adjustValueWatch === ADJUST.asc || adjustValueWatch === ADJUST.desc ? 'disabled' : ''
                            }`}
                          >
                            <Flex
                              className="input-flex-container"
                              gap={0}
                              align="center"
                              vertical={false}
                              style={{ position: 'relative' }}
                            >
                              <div className="Modal__item__adjust">
                                <Divider type="vertical" />
                              </div>
                            </Flex>
                          </div>
                        </div>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Col>
              <Col span={12}>
                <span className="required">Thời gian áp dụng</span>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập thời gian áp dụng',
                    },
                  ]}
                  className="fw-500 fs-16 text-color-default form_range_date"
                  name="range_date"
                >
                  <RangePicker placeholder={['Từ ngày', 'Đến ngày']} disabledDate={disabledDate} format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
            </Row>
            <div className={`z-index1200 mt-5 d-flex ${isUpdate ? 'justify-content-between' : 'justify-content-end'}`}>
              {isUpdate && (
                <div className="d-flex justify-content-start">
                  {statusDetail ? (
                    <Button
                      className="btn-block-red h-btn-48"
                      danger
                      type="primary"
                      htmlType="button"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Image src={icNope} preview={false} className="pb-1 pe-2" />
                      {t('Khóa lịch tăng giảm giá vé')}
                    </Button>
                  ) : (
                    <Button
                      className="btn-active-green h-btn-48"
                      type="primary"
                      htmlType="button"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Image src={IcUnLock} preview={false} className="pb-1 pe-2" />
                      {t('Mở khóa lịch tăng giảm giá vé')}
                    </Button>
                  )}
                </div>
              )}
              <div className={`${isUpdate ? '' : ''}`}>
                <Button
                  key="cancel"
                  className={`${isUpdate ? 'w-160' : 'w-200'} h-btn-48`}
                  onClick={() => cancelModal()}
                >
                  {t('Đóng')}
                </Button>
                <Button
                  key="ok"
                  type="primary"
                  style={{ marginLeft: '16px' }}
                  className={`${isUpdate ? 'w-160' : 'w-200'} mr-0 ml-1 h-btn-48 btn-success`}
                  htmlType="submit"
                >
                  {t('Lưu')}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          title={
            <Flex align="center">
              <Image preview={false} width={22} height={22} src={IconExclamationCircle} alt="Icon Exclamation Circle" />
              <p style={{ marginLeft: '16px', marginBottom: 0 }}>
                {statusDetail ? 'Khóa lịch tăng giảm giá vé' : 'Mở khóa lịch tăng giảm giá vé'}
              </p>
            </Flex>
          }
          open={isModalVisible}
          onCancel={handleCancelModal}
          onOk={handleOkModal}
          okText={statusDetail ? <p>Khóa ngay</p> : <p>Mở khóa ngay</p>}
          cancelText="Trở lại"
        >
          <p style={{ paddingLeft: '38px' }}>
            {statusDetail
              ? 'Bạn đang thao tác khóa lịch tăng giảm giá vé này, bạn có chắc chắn muốn khóa?'
              : 'Bạn đang thao tác mở khóa lịch tăng giảm giá vé này, bạn có chắc chắn muốn mở khóa?'}
          </p>
        </Modal>
      </Card>
    </Layout>
  );
}

export default SettingSchedulePrice;
