import { Button, Checkbox, Col, Flex, Form, FormInstance, Image, Input, Modal, Radio, Row, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RadioChangeEvent } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconExclamationCircle from '../../../assets/images/ExclamationCircle.svg';
import '../../../assets/styles/desktop/busBooking.scss';
import { formattedPrice } from '../../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/slice/authSlice';
import { cancelContract } from '../../../redux/slice/contracts/cancelContract';
import { reasonAgentOptions, reasonOptions, sendOptions } from '../../../utils/reasonOption';
const ModalCancelContract: React.FC<any> = ({
  okText,
  cancelText,
  modalTitle,
  modalOpen,
  onCancel,
  onOk,
  modalContent,
  listContractDetail,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { group } = useAppSelector(selectAuth);
  const [formCancelContract]: [FormInstance] = Form.useForm();
  const [reason, setReason] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const isSendMail = Form.useWatch('send', formCancelContract);
  const cancelFee = Form.useWatch('cancel_fee', formCancelContract);
  const reasonCancel = Form.useWatch('reason_cancel', formCancelContract);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
    const cancelBookingData = {
      code: listContractDetail?.code,
      cancel_fee: formCancelContract.getFieldValue('cancel_fee'),
      cancel_reason: formCancelContract.getFieldValue('reason_cancel'),
      cancel_note: formCancelContract.getFieldValue('note'),
      is_send_mail: isSendMail?.length > 0 ? isSendMail.includes(1) : false,
    };
    dispatch(cancelContract(cancelBookingData));
  };

  const modalHandleCancel = () => {
    onCancel();
    formCancelContract.resetFields();
  };
  const onChangeReason = ({ target: { value } }: RadioChangeEvent) => {
    setReason(value);
  };
  const onChangeSendOption = () => {};

  useEffect(() => {
    if (listContractDetail) {
      if (reasonCancel === 'agent' || reasonCancel === 'car_company') {
        formCancelContract.setFieldsValue({
          refund: formattedPrice(listContractDetail?.price_commission),
          contract_value: formattedPrice(listContractDetail?.price_commission),
          cancel_fee: 0,
        });
      }
      formCancelContract.setFieldsValue({
        refund: formattedPrice(listContractDetail?.refund),
        contract_value: formattedPrice(listContractDetail?.price_commission),
        cancel_fee: formattedPrice(+listContractDetail?.price_commission - +listContractDetail?.refund),
      });
    }
  }, [cancelFee, listContractDetail, formCancelContract, reasonCancel]);

  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      width={601}
      maskClosable={false}
      className="modal-cancel-ticket"
    >
      <Form
        className="mg-16 mt-3"
        form={formCancelContract}
        labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
        requiredMark={false}
        onFinish={modalHandleOk}
        colon={false}
      >
        <Flex className="mt-3">
          <Image preview={false} width={20} src={IconExclamationCircle} alt="Icon Exclamation Circle" />
          <Typography.Text className="pl-15">
            <h5 className="fw-700">{modalTitle}</h5>
            <p className="fw-500">{modalContent}</p>
          </Typography.Text>
        </Flex>
        <div className="w-100">
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item name="contract_value" label={t('Tổng giá trị hợp đồng')}>
                <Input disabled suffix="VND" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="cancel_fee" className="fw-500" label={t('Tổng phí hủy')}>
                <Input defaultValue={0} min={0} suffix="VND" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="refund" className="data-picker" label={t('Hoàn tiền')}>
                <Input defaultValue={0} min={0} suffix="VND" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row className="reason-block-cancel">
            <Form.Item name="reason_cancel" label={t('Lý do')}>
              <Radio.Group
                options={group === 'agent' ? reasonAgentOptions : reasonOptions}
                onChange={onChangeReason}
                value={reason}
                buttonStyle="solid"
                className="send_method payment-checkbox-group"
                defaultValue={0}
              />
            </Form.Item>
          </Row>
          <Form.Item name="send">
            <Checkbox.Group className="checkbox-reason" options={sendOptions} onChange={onChangeSendOption} />
          </Form.Item>
          <Form.Item
            name="reason_note"
            className="form-item-name mt-2 reason-note"
            validateTrigger={['onChange', 'onBlur']}
            label={t('Ghi chú')}
          >
            <TextArea maxLength={100} placeholder="" style={{ height: 106, resize: 'none' }} value={reason} />
          </Form.Item>
        </div>
        <Row className="d-flex justify-content-end mt-4">
          <>
            <Button key="cancel" className="w-78 h-btn-32 me-3 btn-back agent" onClick={() => modalHandleCancel()}>
              Trở lại
            </Button>
            <Button
              key="ok"
              type="primary"
              className="w-84 h-btn-32 btn-success mr-0 d-flex align-center"
              style={{ justifyContent: 'center' }}
              htmlType="button"
              onClick={() => modalHandleOk()}
            >
              {okText}
            </Button>
          </>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalCancelContract;
