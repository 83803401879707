export const handleGetCancelReason = (cancel_reason: string) => {
    switch (cancel_reason) {
        case "car_company":
            return "Nhà xe hủy";
        case "customer":
            return "Khách hàng hủy";
        case "agent":
            return "Đại lý hủy";
        case "overdue":
            return "Quá hạn giữ chỗ";
        case "system":
            return "Quá hạn giữ chỗ";
        case "Hủy vé do quá hạn giữ chỗ":
            return "Quá hạn giữ chỗ";
        case "Không liên lạc được với khách":
            return "Không liên lạc được với khách";
        case "Khách hàng yêu cầu hủy":
            return "Khách hàng yêu cầu hủy";
        case "Khách cân nhắc lại":
            return "Khách cân nhắc lại";
        default:
            return "Lý do khác";
    }
}