import { getAxiosClientWithToken } from "./index";

export const getRechargeHistories = (params: any) => {
  return getAxiosClientWithToken().get(`/recharge-histories/agents?${params}`);
};
export const getListAgentHistories = () => {
  return getAxiosClientWithToken().get(`/recharge-histories/list-agent`);
};
export const getListCompanyHistories = () => {
  return getAxiosClientWithToken().get(`recharge-histories/companies`);
};
export const getRechargeHistoriesCompany = (params: any) => {
  return getAxiosClientWithToken().get(`/recharge-histories/companies?${params}`);
};

export const getListCompanyHistory = () => {
  return getAxiosClientWithToken().get(`/company`);
};

export const getListCompanyActive = () => {
  return getAxiosClientWithToken().get(`company/list`)
}

export const getRechargeHistoriesMainAgent = (params: any) => {
  return getAxiosClientWithToken().get(`/recharge-histories/main-agents?${params}`);
};
