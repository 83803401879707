import { Button, Card, Col, Form, InputNumber, Row, Spin, Switch } from "antd";
import "./SystemConfig.scss";
import { getSystemConfigApi, putSystemConfigApi } from "../../api/system";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkAuthorization } from "../../api";

function SystemConfig() {
  const [form] = Form.useForm();
  const [requestPassword, setRequestPassword] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const onFinish = async (values: any) => {
    const updateData = { ...values, is_strong_password: requestPassword };
    try {
      const response = await putSystemConfigApi(updateData);
      if (response.status === 200) {
        handleConfigSystem();
        toast.success("Cập nhật thành công");
        setIsEdit(false);
      }
    } catch (error) {
      checkAuthorization(error);
    }
  };
  const onChangeSwitch = (checked: boolean) => {
    setRequestPassword(checked);
  };

  const handleSubmit = () => {
    form.submit(); // Gọi phương thức submit của form instance
  };

  const handleConfigSystem = async () => {
    setIsFetching(true);
    try {
      const res = await getSystemConfigApi();
      if (res?.status === 200) {
        const valueRes = res?.data?.data;
        setRequestPassword(valueRes?.is_strong_password);
        window.localStorage.setItem(
          "limit_pagination",
          valueRes?.limit_pagination
        );

        form.setFieldsValue({
          is_strong_password: valueRes?.is_strong_password,
          refresh_time: valueRes?.refresh_time,
          export_report_month: valueRes?.export_report_month,
          warning_balance_agent: valueRes?.warning_balance_agent,
          warning_balance_company: valueRes?.warning_balance_company,
          limit_pagination: valueRes?.limit_pagination,
          openapi_reservation_time: valueRes?.openapi_reservation_time,
          openapi_hold_time: valueRes?.openapi_hold_time ?? 10,
          warning_balance_main_agent: valueRes?.warning_balance_main_agent,
          min_commission_api: valueRes?.min_commission_api,
        });
      }
    } catch (error) {}
    setIsFetching(false);
  };

  useEffect(() => {
    handleConfigSystem();
  }, []);

  return (
    <Spin spinning={isFetching}>
      <Card className="wrapper-system-config">
        <Col>
          <Form
            name="info-config-system"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            className="form-system-config"
          >
            <Col>
              <Form.Item
                label="Yêu cầu đặt Password mạnh"
                valuePropName="checked"
                name="is_strong_password"
              >
                <Switch
                  checked={requestPassword}
                  disabled={!isEdit}
                  onChange={onChangeSwitch}
                />
                <div className="note-password-system">
                  Password yêu cầu từ 6-12 ký tự ( trong đó có 1 chữ viết hoa, 1
                  số, 1 kí tự đặc biệt)
                </div>
              </Form.Item>
              <Form.Item
                label="Cấu hình thời gian Refresh Header"
                name="refresh_time"
                className="required refresh_time"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thời gian refresh header",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  placeholder="Nhập số giây"
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="Giây"
                />
              </Form.Item>
              <Form.Item
                label="Cấu hình hạn mức cảnh báo nạp tiền cho nhà xe"
                name="warning_balance_company"
                className="required refresh_time"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập cấu hình hạn mức cảnh báo nạp tiền cho nhà xe",
                  },
                ]}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  min={0}
                  placeholder=""
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="VNĐ"
                />
              </Form.Item>
              <Form.Item
                label="Cấu hình hạn mức cảnh báo nạp tiền cho tổng đại lý"
                name="warning_balance_main_agent"
                className="required refresh_time"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập cấu hình hạn mức cảnh báo nạp tiền cho tổng đại lý",
                  },
                ]}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  min={0}
                  placeholder=""
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="VNĐ"
                />
              </Form.Item>
              <Form.Item
                label={
                  <div style={{ height: 60, whiteSpace: "normal" }}>
                    Cấu hình mức hoa hồng tối thiểu cho đối tác carlink hiển thị
                    ra kết quả search Carlink API
                    <span style={{ color: "#d81717", marginLeft: 2 }}>*</span>
                  </div>
                }
                name="min_commission_api"
                className="refresh_time"
                style={{marginBottom: 12}}
                rules={[
                  {
                    required: true,
                    message:
                      "Cấu hình mức hoa hồng tối thiểu cho đối tác carlink hiển thị ra kết quả search Carlink API",
                  },
                  {
                    type: "number",
                    min: 0,
                    max: 100,
                    message: "Giá trị phải nằm trong khoảng từ 0 đến 100.",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  max={100}
                  placeholder=""
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="%"
                />
              </Form.Item>
              <Form.Item
                label="Cấu hình giới hạn số tháng xem/xuất báo cáo"
                name="export_report_month"
                className="required refresh_time"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập cấu hình giới hạn số tháng xem/xuất báo cáo",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  placeholder=""
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="Ngày"
                />
              </Form.Item>
              <Form.Item
                label="Cấu hình giới hạn số tháng xem/xuất báo cáo"
                name="export_report_month"
                className="required refresh_time"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập cấu hình hạn mức cảnh báo nạp tiền cho đại lý",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  placeholder=""
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="Ngày"
                />
              </Form.Item>
              <Form.Item
                label="Cấu hình số dòng / 1 trang"
                name="limit_pagination"
                className="required refresh_time"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập cấu hình hạn mức cảnh báo nạp tiền cho đại lý",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  placeholder=""
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="Dòng"
                />
              </Form.Item>
              <Form.Item
                label=" Thời gian giữ chỗ trên các đối tác carlink"
                name="openapi_reservation_time"
                className="required refresh_time"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập cấu hình thời gian giữ chỗ trên các đối tác carlink",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  placeholder=""
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="Phút"
                />
              </Form.Item>
              <Form.Item
                label=" Thời gian thanh toán trên các đối tác carlink"
                name="openapi_hold_time"
                className="required refresh_time"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập cấu hình thời gian thanh toán trên các đối tác carlink",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  placeholder=""
                  style={{ width: 157 }}
                  disabled={!isEdit}
                  suffix="Phút"
                />
              </Form.Item>
            </Col>
            <Row gutter={24} className="row-btn-system">
              {isEdit ? (
                <Col className="container-btn-system">
                  <Button onClick={() => setIsEdit(false)}>Trở lại</Button>
                  <Button type="primary" onClick={handleSubmit}>
                    Lưu
                  </Button>
                </Col>
              ) : (
                <Button
                  className="btn-info-system"
                  onClick={() => setIsEdit(true)}
                >
                  Chỉnh sửa
                </Button>
              )}
            </Row>
          </Form>
        </Col>
      </Card>
    </Spin>
  );
}

export default SystemConfig;
