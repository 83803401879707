import { InfoCircleFilled } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Rule } from 'antd/es/form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconBookingAgent from '../../assets/images/ic-booking-agent.svg';
import iconCancelPinCode from '../../assets/images/ic-cancel-pincode.svg';
import iconNumpad from '../../assets/images/ic-numpad.svg';
import '../../assets/styles/desktop/busBooking.scss';
import ModalInfoInsurance from '../../components/Modal/ModalInfoInsurance';
import ModalNumpad from '../../components/Modal/ModalNumpad';
import { Constant, USER_GROUP } from '../../config/constant';
import { convertTimeFormat, filterOption, formattedPrice } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { listAgentSelector } from '../../redux/slice/agents/listAgentSlice';
import { selectAuth } from '../../redux/slice/authSlice';
import {
  createBookingSelector,
  filterBookingCustomer,
  getPriceBooking,
  setPinCode,
} from '../../redux/slice/bookings/createBookingSlice';
import { getTicketCodeSelector } from '../../redux/slice/bookings/getTicketCodeSlice';
import {
  paymentAgentDepartOptions,
  paymentAgentOptions,
  paymentMultiDepartedOptions,
  paymentMultiOptions,
  validatorEmailBooking,
} from '../../utils/bookingItem';

function ModalMultiBooking1({
  dataBooking,
  formBooking,
  listBooking,
  numCustomer,
  loadingHold,
  openModalNumpad,
  setOpenModalNumpad,
}: any) {
  const { t } = useTranslation();
  const { group } = useAppSelector(selectAuth);
  const {
    loading: loadingCreate,
    listCustomers,
    booking_for_agent_id,
    pin_code,
    statusPin,
  } = useAppSelector(createBookingSelector);
  const { listAgentActive } = useAppSelector(listAgentSelector);
  const { ticketCode, loading: loadingTicket } = useAppSelector(getTicketCodeSelector);
  const dispatch = useAppDispatch();
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [openInfoInsurance, setOpenInfoInsurance] = useState(false);
  const [customerPhone, setCustomerPhone] = useState<string | undefined>(undefined);
  const [keywordCustomer, setKeywordCustomer] = useState<any>();
  const isMultiInsurance = Form.useWatch('is_insurance_multi', formBooking);
  const num = Form.useWatch('num_customer', formBooking);
  const isRepresent = Form.useWatch('is_represent', formBooking);
  const isAgentId = Form.useWatch('is_agent_id_multi', formBooking);
  const [otp, setOtp] = useState<string>('');
  const [commissionPrice, setCommissionPrice] = useState(0);

  const onChangePayment = ({ target: { value } }: RadioChangeEvent) => {
    setPaymentMethod(value);
  };
  const onChangeInsurance = () => {
    dispatchGetPriceBooking();
  };
  const handleOptionDropOff = () => {
    let options: any = [];
    options = options.concat(
      listBooking?.schedule?.transship_arrive
        ? listBooking.schedule.transship_arrive.map((item: any) => {
            return {
              value: item.id,
              label: `(${convertTimeFormat(item?.pickup_time)}) ${item.pickup_location_name}`,
            };
          })
        : [],
    );
    if (listBooking?.schedule?.is_arrive_transship) {
      options.push({
        value: 'other',
        label: 'Nhập điểm trung chuyển khác',
      });
    }
    return options;
  };
  const handleOptionPickup = () => {
    let options: any = [];
    options = options.concat(
      listBooking?.schedule?.transship_depart
        ? listBooking.schedule.transship_depart.map((item: any) => ({
            value: item.id,
            label: `(${convertTimeFormat(item.pickup_time)}) ${item.pickup_location_name}`,
          }))
        : [],
    );
    if (listBooking?.schedule?.is_depart_transship) {
      options.push({
        value: 'other',
        label: 'Nhập điểm trung chuyển khác',
      });
    }
    return options;
  };
  const onChangeIsAgent = () => {
    const currentChecked = formBooking.getFieldValue('is_agent_id_multi');
    formBooking.setFieldValue('is_agent_id_multi', !currentChecked);
  };
  const handlePickupChange = () => {
    dispatchGetPriceBooking();
  };

  const handleDropOffChange = () => {
    dispatchGetPriceBooking();
  };
  useEffect(() => {
    if (dataBooking?.seat_number?.length > 1) {
      const currentValues = formBooking.getFieldValue('custom_info') || [];
      dataBooking?.seat_number?.map(
        (item: any, index: any) =>
          (currentValues[index] = {
            seat: item,
            ticket_code: dataBooking?.ticket_code,
          }),
      );
    }
  }, [dataBooking, formBooking]);

  const NameRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập tên'),
    },
    {
      pattern: /^[^\s].*$/,
      message: t('Tên không được chứa chỉ khoảng trắng'),
    },
  ];
  const dispatchGetPriceBooking = () => {
    const pickUpValue = formBooking.getFieldValue('pickUpValue');
    const dropOffValue = formBooking.getFieldValue('dropOffValue');
    const customInfo = formBooking.getFieldValue('custom_info');
    const isMultiInsurance = formBooking.getFieldValue('is_insurance_multi');
    const scheduleId = listBooking?.schedule?.id;
    const seatNumber = dataBooking?.map((item: any) => item?.seat_number);

    if (scheduleId && dataBooking && pickUpValue !== null && dropOffValue !== null && customInfo?.length > 0) {
      const customInfoObject = customInfo.reduce((acc: any, obj: any, index: number) => {
        const pickupLocationId = obj.pickup_location_id === 'other' ? null : obj.pickup_location_id;
        const dropOffLocationId = obj.drop_off_location_id === 'other' ? null : obj.drop_off_location_id;
        if (obj?.seat && seatNumber) {
          const { seat } = obj;
          acc[seat] = {
            pickup_location_id: pickupLocationId,
            drop_off_location_id: dropOffLocationId,
          };
        } else {
          acc[index] = {
            pickup_location_id: pickupLocationId,
            drop_off_location_id: dropOffLocationId,
          };
        }
        return acc;
      }, {});
      dispatch(
        getPriceBooking({
          booking_type: Constant.BOOKING_MULTI_CUSTOM,
          schedule_id: scheduleId,
          seat_number: seatNumber,
          custom_info: customInfoObject,
          is_insurance: isMultiInsurance,
          depart_date: listBooking?.travel_info?.depart_date,
          amount: num,
        }),
      );
    }
  };
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    formBooking.setFieldsValue({ is_represent: e.target.checked });
  };
  const handleChangeAgentIdOption = (option: any) => {
    setCommissionPrice(option?.commission_percent);
  };

  const onCheckboxAgentIdChange = (e: CheckboxChangeEvent) => {
    formBooking.setFieldsValue({ is_agent_id_multi: e.target.checked });
  };
  const listAgentActiveOption = listAgentActive?.length
    ? listAgentActive?.map((item: any) => {
        return {
          commission_percent: item?.commission_percent,
          value: item?.id,
          label: (
            <div className="d-flex items-center gap-x-2">
              <img src={iconBookingAgent} alt="" />
              {item?.name}
            </div>
          ),
        };
      })
    : [];
  useEffect(() => {
    if (dataBooking?.length > 1) {
      const initialValues = dataBooking?.map((item: any, index: any) => ({
        seat: item?.seat_number,
        ticket_code: item?.ticket_code,
      }));
      formBooking.setFieldsValue({ custom_info: initialValues });
    } else if (numCustomer) {
      const initialValues = Array.from({ length: numCustomer }, () => ({}));
      formBooking.setFieldsValue({ custom_info: initialValues });
    }
  }, [dataBooking, formBooking, numCustomer]);

  useEffect(() => {
    if (!listBooking?.schedule?.is_diagram && ticketCode) {
      const initialValues = ticketCode?.map((ticketCodeItem: string) => ({
        ticket_code: ticketCodeItem,
      }));
      formBooking.setFieldsValue({ custom_info: initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBooking, ticketCode]);
  useEffect(() => {
    dispatch(filterBookingCustomer({ keyword: keywordCustomer }));
  }, [dispatch, keywordCustomer]);

  useEffect(() => {
    if (booking_for_agent_id) {
      formBooking.setFieldValue('agent_id_multi', booking_for_agent_id);
    }
  }, [booking_for_agent_id, formBooking]);
  const handleNumpadClick = () => {
    if (pin_code) {
      dispatch(setPinCode(null));
    } else {
      setOtp('');
      setOpenModalNumpad(true);
    }
  };
  useEffect(() => {
    if (statusPin) {
      setOpenModalNumpad(false);
    }
  }, [statusPin]);

  return (
    <Spin spinning={loadingHold || loadingCreate || loadingTicket}>
      <Col className="is_representative mb-1">
        <Form.Item name="is_represent">
          <Checkbox onChange={onCheckboxChange}>{t('Lấy thông tin khách hàng 1 làm thông tin chung')}</Checkbox>
        </Form.Item>
      </Col>
      {group === USER_GROUP.CAR_COMPANY ? (
        <Col>
          <div className="agent-booking-block" style={{ top: '-50px' }}>
            <div onClick={onChangeIsAgent} className={`${'cursor-pointer'} h-fit`}>
              <Form.Item name="is_agent_id_multi" className="is-agent" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </div>
            <Form.Item name="agent_id_multi" className="is-agent-select" required={isAgentId ? true : false}>
              <Select
                className="input-h36"
                disabled={!isAgentId}
                placeholder={
                  <div className="d-flex justify-center items-center " style={{ columnGap: '10px', width: '150px' }}>
                    <img src={iconBookingAgent} alt="" /> Đặt hộ đại lý
                  </div>
                }
                size="large"
                options={[
                  // {
                  //   value: '',
                  //   label: (
                  //     <div className="d-flex items-center gap-x-2">
                  //       <img src={iconBookingAgent} alt="" /> Không
                  //     </div>
                  //   ),
                  // },
                  ...listAgentActiveOption,
                ]}
                showSearch
                onChange={(value, option) => handleChangeAgentIdOption(option)}
                filterOption={filterOption}
              />
            </Form.Item>
          </div>
        </Col>
      ) : (
        ''
      )}
      <Form.List name="custom_info">
        {(fields) => (
          <>
            {fields?.map(({ key, name, fieldKey, ...restField }, index) => {
              return (
                <div key={key}>
                  <Row className="items-center">
                    <Col span={4}>
                      <Typography className="fw-600 fs-16">{t(`Hành khách ${index + 1}`)}</Typography>
                    </Col>
                  </Row>
                  <Row gutter={16} className="">
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, 'seat']}
                        label={t('Ghế đang chọn')}
                        className="w-100 booking-input-disable"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item {...restField} name={[name, 'ticket_code']} label={t('Mã vé')}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'customer_phone']}
                        label={t('Số điện thoại')}
                        className="require"
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input
                          className="input-h36 phone-select"
                          placeholder={t('Nhập số điện thoại')}
                          size="large"
                          value={customerPhone}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCustomerPhone(value);
                            setKeywordCustomer(value);
                          }}
                          onWheel={(event) => event.currentTarget.blur()}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            const value = e.target.value;
                            const currentValue = formBooking.getFieldValue('custom_info');
                            const selectedCustomer =
                              listCustomers?.length && listCustomers.find((customer: any) => customer.phone === value);
                            if (selectedCustomer) {
                              currentValue[index] = {
                                ...currentValue[index],
                                customer_name: selectedCustomer.name,
                                customer_email: selectedCustomer.email,
                                customer_phone: selectedCustomer.phone,
                              };
                            } else {
                              currentValue[index] = {
                                ...currentValue[index],
                                customer_phone: value,
                              };
                            }
                            formBooking.setFieldsValue({
                              custom_info: currentValue,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'customer_name']}
                        label={t('Họ và tên')}
                        className="require"
                        rules={isRepresent ? (index === 0 ? NameRules : undefined) : NameRules}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input placeholder="Nhập họ và tên" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'customer_email']}
                        label={t('Email')}
                        rules={validatorEmailBooking}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input placeholder="Nhập email" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'pickup_location_id']}
                        className="form-item-name require"
                        label={t('Đón tại')}
                      >
                        <Select
                          showSearch
                          filterOption={filterOption}
                          className="input-h36"
                          placeholder="Chọn điểm đón"
                          size="large"
                          style={{ width: '100%' }}
                          options={handleOptionPickup()}
                          onChange={handlePickupChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'drop_off_location_id']}
                        className="form-item-name require"
                        label={t('Trả tại')}
                      >
                        <Select
                          showSearch
                          filterOption={filterOption}
                          className="input-h36"
                          placeholder="Chọn điểm trả"
                          size="large"
                          style={{ width: '100%' }}
                          options={handleOptionDropOff()}
                          onChange={handleDropOffChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ display: 'flex' }}>
                    {formBooking.getFieldValue(['custom_info', index, 'pickup_location_id']) === 'other' && (
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'pickup_extra']}
                          className="form-item-name require"
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <Input
                            className="input-h36"
                            placeholder="Chọn điểm đón khác"
                            size="large"
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {formBooking.getFieldValue(['custom_info', index, 'drop_off_location_id']) === 'other' &&
                      formBooking.getFieldValue(['custom_info', index, 'pickup_location_id']) !== 'other' && (
                        <Col span={12}></Col>
                      )}
                    {formBooking.getFieldValue(['custom_info', index, 'drop_off_location_id']) === 'other' && (
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'drop_off_extra']}
                          className="form-item-name require"
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <Input
                            className="input-h36"
                            placeholder="Nhập điểm trả khác"
                            size="large"
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item {...restField} name={[name, 'note']} label={t('Ghi chú')}>
                        <Input placeholder="Nhập ghi chú" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        )}
      </Form.List>
      <Row gutter={20}>
        <Col span={6}>
          {group !== USER_GROUP.AGENT ? (
            <Flex className="block-numpad-input">
              <Form.Item name="price_multi" label={t('Tổng tiền')} className="booking-input-disable price">
                <InputNumber
                  suffix="VND"
                  disabled={!pin_code}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value?.replace(/[^0-9]/g, '') as unknown as number}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <div className="btn-numpad" onClick={() => handleNumpadClick()} style={{ marginTop: '25px' }}>
                <img src={pin_code ? iconCancelPinCode : iconNumpad} alt="" />
              </div>
            </Flex>
          ) : (
            <>
              <Form.Item name="price_multi" label={t('Tổng tiền vé')} className="price">
                <Input suffix="VND" disabled />
              </Form.Item>
            </>
          )}
          {isMultiInsurance && listBooking?.schedule?.is_diagram && (
            <Typography className="fs-14 fw-500 text-insurance">
              {listBooking?.policy?.insurance_policy?.percent && dataBooking?.length
                ? `+${+listBooking?.policy?.insurance_policy?.percent * +dataBooking?.length}% tiền bảo hiểm`
                : `+${formattedPrice(
                    +listBooking?.policy?.insurance_policy?.price * +dataBooking?.length,
                  )}đ tiền bảo hiểm`}
            </Typography>
          )}
          {isMultiInsurance && !listBooking?.schedule?.is_diagram && (
            <Typography className="fs-14 fw-500 text-insurance">
              {listBooking?.policy?.insurance_policy?.percent
                ? `+${+listBooking?.policy?.insurance_policy?.percent * +num}% tiền bảo hiểm`
                : `+${+listBooking?.policy?.insurance_policy?.price * +num}đ tiền bảo hiểm`}
            </Typography>
          )}
        </Col>
        {group === USER_GROUP.AGENT && (
          <Col span={6}>
            <Form.Item
              name="commission_price"
              label={t('Tổng tiền đại lý trả')}
              className="booking-input-disable price"
            >
              <Input suffix="VND" disabled />
            </Form.Item>
            <Typography className="fs-14 fw-500 text-discount">
              {`Đã trừ ${
                listBooking?.schedule?.agent?.commission_percent
                  ? listBooking?.schedule?.agent?.commission_percent
                  : '0'
              }% chiết khấu`}
            </Typography>
          </Col>
        )}
        {(listBooking?.policy?.insurance_policy?.percent || listBooking?.policy?.insurance_policy?.price) && (
          <Col className="d-flex">
            <Space className={group === USER_GROUP.AGENT ? '' : 'mt-3'}>
              <Form.Item name="is_insurance_multi" className="booking-input-disable">
                <Switch
                  defaultChecked={false}
                  onChange={onChangeInsurance}
                  className="mt-3"
                  disabled={
                    !listBooking?.policy?.insurance_policy?.percent && !listBooking?.policy?.insurance_policy?.price
                  }
                />
              </Form.Item>
              <Typography.Text className="fw-500 fs-14 ">
                {t(
                  `Bảo hiểm chuyến đi (${
                    listBooking?.policy?.insurance_policy?.percent
                      ? `${listBooking?.policy?.insurance_policy?.percent}% giá vé`
                      : `${formattedPrice(listBooking?.policy?.insurance_policy?.price)}đ/ghế`
                  }) `,
                )}
              </Typography.Text>
              <Tooltip title={'Xem chi tiết chính sách bảo hiểm'}>
                <InfoCircleFilled style={{ color: '#F8B507' }} onClick={() => setOpenInfoInsurance(true)} />
              </Tooltip>
            </Space>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Form.Item name="payment_method_multi" label={t('Phương thức thanh toán')} className="require">
            <Radio.Group
              options={group !== USER_GROUP.AGENT ? paymentMultiOptions : paymentAgentOptions}
              onChange={onChangePayment}
              value={paymentMethod}
              buttonStyle="solid"
              className="payment-checkbox-group"
            />
          </Form.Item>
        </Col>
      </Row>
      <ModalNumpad
        modalOpen={openModalNumpad}
        onCancel={() => {
          setOpenModalNumpad(false);
          setOtp('');
        }}
        onOk={() => false}
        otp={otp}
        setOtp={setOtp}
      />
      <ModalInfoInsurance
        modalOpen={openInfoInsurance}
        onCancel={() => setOpenInfoInsurance(false)}
        onOk={() => false}
        listBooking={listBooking}
      />
    </Spin>
  );
}

export default ModalMultiBooking1;
