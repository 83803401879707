import { getAxiosClientWithToken } from "./index";

export const getListTutorialsApi = () => {
    return getAxiosClientWithToken().get("/tutorials");
};

export const updateListTutorialsApi = (body: any) => {
    return getAxiosClientWithToken().put("/tutorials", body);
};

export const createTutorialsApi = (body: any) => {
    return getAxiosClientWithToken().post("/tutorials", body)
}