import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Constant } from '../../../config/constant';
import { getErrorMessage } from '../../../api';
import { ListScheduleRequest, ListScheduleState } from '../../../types/schedule';
import { getListScheduleAPI } from '../../../api/schedules';
import { getOfficeAllProvinces } from '../../../api/offices';

// @ts-ignore
export const fetchListSchedule = createAsyncThunk(
  'admin/schedule',
  async (params: ListScheduleRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListScheduleAPI(params ?? undefined);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListSchedule({ schedules: data.data, pagination: data.pagination, status: status }));
        return true;
      }
    } catch (error: any) {
      const { status } = error.response;
      dispatch(setListSchedule({ status: status }));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const getAllProvinces = createAsyncThunk('admin/allProvinces', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await getOfficeAllProvinces();
    const { data, status } = response;
    if (status === Constant.DEFAULT_STATUS) {
      dispatch(setListProvince(data?.data));
      return true;
    }
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});
export const ListScheduleSlice = createSlice({
  name: 'ListSchedule',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    schedules: [],
    listProvince: [],
    listSegment: [],
    pagination: {
      total: 0,
      per_page: Constant.PAGE_SIZE,
      current_page: Constant.DEFAULT_PAGE,
      last_page: Constant.DEFAULT_PAGE,
    },
  } as ListScheduleState,
  reducers: {
    setListSchedule: (state: ListScheduleState, { payload }) => {
      const { schedules, pagination, status } = payload;
      state.schedules = schedules;
      state.pagination = pagination;
      state.status = status;
    },
    setListProvince: (state: ListScheduleState, { payload }) => {
      state.listProvince = payload;
    },
    resetListSchedule: (state: ListScheduleState) => {
      state.schedules = [];
      state.status = Constant.DEFAULT_STATUS;
    },
    setListSegment: (state: ListScheduleState, { payload }) => {
      state.listSegment = payload;
    },

    // setServices: (state: ListScheduleState, {payload}) => {
    //     const {service} = payload;
    //     let data: never[] = [];
    //     convertOption(data, service)
    //     state.ListSchedules = data;
    // },
    // setListScheduleLoad: (state: ListScheduleState) => {
    //     state.services = [];
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListSchedule.pending, (state: ListScheduleState) => {
      state.loading = true;
    });
    builder.addCase(fetchListSchedule.fulfilled, (state: ListScheduleState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchListSchedule.rejected, (state: ListScheduleState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const ListScheduleSelector = (state: RootState) => state.listSchedule;
export const { setListSchedule, setListProvince, setListSegment } = ListScheduleSlice.actions;
