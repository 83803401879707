/* eslint-disable jsx-a11y/anchor-is-valid */
import { Image, Modal, Popconfirm, Space } from "antd";
import React, { useEffect, useState } from "react";
import type { TabsProps } from "antd";
import { Button, Col, Spin, Tabs } from "antd";
import "../../../assets/styles/app.scss";
import "../../../assets/styles/editUser.scss";
import { useTranslation } from "react-i18next";
import "../../../config/i18n/i18n";
import { listUserSelector } from "../../../redux/slice/userSlice";
import { useAppSelector } from "../../../redux/hooks";
import EditInfoUserContact from "./Tabs/EditInfoUserContact";
import EditUserPermissions from "./Tabs/EditUserPermissions";
import EditInfoUser from "./Tabs/EditInfoUser";
import "../../../assets/styles/editUser.scss";
import {
  getPermissions,
  getUserStatusByIdAPI,
  updateUserAPI,
} from "../../../api/user";
import IconClose from "../../../assets/images/listUser/iconsClose.svg";
import ModalInfoUser from "./Tabs/ModalInfoUser";
import IconInfoCircle from "../../../assets/images/listUser/info-circle.svg";
import IconExclamationCircle from "../../../assets/images/ExclamationCircle.svg";
import { checkAuthorization } from "../../../api";
import {
  ACTION_NAME,
  messageUpdateSuccess,
  messageWarning,
} from "../../../config/message";
import { Constant, USER_GROUP } from "../../../config/constant";
import IcLock from "../../../assets/images/ic-lock.svg";
import TutorialsVideo from "../../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../../utils/helpers";
import { PERMISSIONS } from "../../../config/permission";
import ManagePinCode from "./Tabs/ManagePinCode";
import { hasManagerCompanyAccount } from "../../../lib/utils";

const EditUser: React.FC<any> = ({
  open,
  setOpen,
  callListUserApi,
  detailUser,
  setDetailUser,
  disabledAction,
  infoUser,
  listTutorials,
}) => {
  const { t } = useTranslation();
  const { loading } = useAppSelector(listUserSelector);
  const [dataUser, setDataUser] = useState<any>({});
  const [activeKey, setActiveKey] = useState<string>("1");
  const [dataPermissions, setDataPermissions] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeOne, setActiveOne] = useState(false);
  const [activeThree, setActiveThree] = useState(false);
  const [activeFour, setActiveFour] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showModalChildren = () => {
    setIsModalOpen(true);
  };

  const handleCancelChildren = () => {
    setIsModalOpen(false);
  };

  const handleOnchangeInput = (e: any) => {
    let { name, value } = e.target;

    setDetailUser({ ...detailUser, [name]: value });
  };
  const onChangeContinue = (key: string) => {
    setActiveKey(key);
  };

  const callUpdateUserAPI = async () => {
    setIsLoading(true);
    const isImageURL = isURL(detailUser?.avatar);
    let modifiedDetailUser: any = { ...detailUser };
    if (isImageURL) {
      modifiedDetailUser = { ...detailUser };
      delete modifiedDetailUser.avatar;
    } else if (detailUser?.avatar) {
      modifiedDetailUser = { ...detailUser };
    } else {
      modifiedDetailUser = { ...detailUser, avatar: null };
    }
    let chooseGroup = {};
    if (infoUser?.group === USER_GROUP.CARLINK) {
      chooseGroup = { company_id: detailUser?.car_company?.id };
    } else if (infoUser?.group === USER_GROUP.CAR_COMPANY) {
      chooseGroup = { office_id: detailUser?.office_id };
    } else if (infoUser?.group === USER_GROUP.AGENT) {
      chooseGroup = { agent_id: detailUser?.agent?.id };
    }
    if (detailUser?.group === USER_GROUP.MAIN_AGENT) {
      chooseGroup = {
        main_agent_id: detailUser?.main_agent_id
          ? detailUser?.main_agent_id
          : detailUser?.main_agent?.id,
      };
    }

    const isDriver = {
      ...modifiedDetailUser,
      ...chooseGroup,
      role: "driver",
      permissions: [],
    };

    const valueBody = { ...modifiedDetailUser, ...chooseGroup };

    const formData =
      modifiedDetailUser?.permissions[0] === "driver" ? isDriver : valueBody;
    try {
      const res = await updateUserAPI(detailUser?.id, formData);
      if (res.status === Constant.DEFAULT_STATUS) {
        callListUserApi();
        messageUpdateSuccess(ACTION_NAME.USER);
        setOpen(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
    setIsLoading(false);
  };

  const checkPermissionManagerAccount = () => {
    if (
      infoUser?.permissions?.length > 0 &&
      infoUser.group === USER_GROUP.AGENT
    ) {
      const isManagerAccount = infoUser?.permissions?.includes(
        PERMISSIONS.AGENT.manager_agent_account
      );
      return isManagerAccount;
    }
    if (
      infoUser?.permissions?.length > 0 &&
      infoUser.group === USER_GROUP.CAR_COMPANY
    ) {
      const isManagerAccount = infoUser?.permissions?.includes(
        PERMISSIONS.CAR_COMPANY.manager_company_account
      );
      return isManagerAccount;
    }
    if (
      infoUser?.permissions?.length > 0 &&
      infoUser.group === USER_GROUP.CARLINK
    ) {
      const isManagerAccount = infoUser?.permissions?.includes(
        PERMISSIONS.CARLINK.manager_carlink_account
      );
      return isManagerAccount;
    }
    if (
      infoUser?.permissions?.length > 0 &&
      infoUser.group === USER_GROUP.MAIN_AGENT
    ) {
      const isManagerAccount = infoUser?.permissions?.includes(
        PERMISSIONS.MAIN_AGENT.manager_main_agent_account
      );
      return isManagerAccount;
    }
    return false;
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("Thông tin cơ bản"),
      children: (
        <EditInfoUser
          detailUser={detailUser}
          setDataUser={setDataUser}
          handleOnchangeInput={handleOnchangeInput}
          dataUser={dataUser}
          setDetailUser={setDetailUser}
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          setActiveOne={setActiveOne}
          activeOne={activeOne}
          infoUser={infoUser}
          disabledAction={disabledAction}
          checkManagerAccount={checkPermissionManagerAccount}
        />
      ),
    },
    {
      key: "2",
      label: t("Phân quyền"),
      children: (
        <EditUserPermissions
          dataPermissions={dataPermissions}
          detailUser={detailUser}
          handleOnchangeInput={handleOnchangeInput}
          dataUser={dataUser}
          setDetailUser={setDetailUser}
          infoUser={infoUser}
          // disabledAction={disabledAction}
        />
      ),
    },
    {
      key: "3",
      label: t("Thông tin liên hệ"),
      children: (
        <EditInfoUserContact
          detailUser={detailUser}
          handleOnchangeInput={handleOnchangeInput}
          dataUser={dataUser}
          setDetailUser={setDetailUser}
          setActiveThree={setActiveThree}
          activeThree={activeThree}
          callUpdateUserAPI={callUpdateUserAPI}
          infoUser={infoUser}
          checkManagerAccount={checkPermissionManagerAccount}
          setActiveKey={setActiveKey}
        />
      ),
    },
    ...(infoUser?.group === USER_GROUP.CAR_COMPANY &&
    hasManagerCompanyAccount(detailUser?.permissions)
      ? [
          {
            key: "4",
            label: t("Quản lý mã PIN"),
            children: (
              <ManagePinCode
                callUpdateUserAPI={callUpdateUserAPI}
                detailUser={detailUser}
                handleOnchangeInput={handleOnchangeInput}
                dataUser={dataUser}
                setDetailUser={setDetailUser}
                setActiveFour={setActiveFour}
                activeFour={activeFour}
              />
            ),
          },
        ]
      : []),
  ];

  const handleOkModal = async () => {
    try {
      const res = await getUserStatusByIdAPI(detailUser?.id, {
        status: !detailUser?.status,
      });
      if (res.status === Constant.DEFAULT_STATUS) {
        callListUserApi();
        messageUpdateSuccess(ACTION_NAME.USER);
        setOpen(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const callApiPermissions = async () => {
    try {
      const res = await getPermissions();
      if (res.status === Constant.DEFAULT_STATUS) {
        setDataPermissions(res.data.data);
      }
    } catch (error) {
      checkAuthorization(error);
    }
  };

  useEffect(() => {
    callApiPermissions();
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };

  function isURL(str: any) {
    // Biểu thức chính quy kiểm tra xem chuỗi có bắt đầu bằng 'http://' hoặc 'https://'
    const urlRegex = /^(https?:\/\/)/;

    return urlRegex.test(str);
  }

  const handleSubmitForm = () => {
    if (activeKey === "1") {
      setActiveOne(true);
    } else if (activeKey === "2") {
      if (detailUser.permissions.length > 0) {
        setActiveKey("3");
      } else {
        messageWarning(ACTION_NAME.PERMISSION);
      }
    } else if (activeKey === "3") {
      setActiveThree(true);
    }
    //  else if (activeKey === "4") {
    //   setActiveFour(true);
    // }
  };
  useEffect(() => {}, [activeKey]);

  return (
    <div className="wrapper-edit-user">
      <Modal
        open={open}
        maskClosable={false}
        confirmLoading={isLoading}
        title={
          <div className="title-modal-user">
            <div>{t("Chỉnh sửa thông tin")}</div>
            <div className="info-title-right">
              <Space size={10} align="center">
                <TutorialsVideo
                  url_youtube={handleGetUrlGuide(listTutorials, 10)}
                />
              </Space>
              <span onClick={showModalChildren} style={{ cursor: "pointer" }}>
                <a
                  style={{
                    textDecoration: "underline",
                    marginRight: 8,
                    color: "#F8B507",
                    fontSize: "14px",
                  }}
                >
                  {t("Thông tin")}
                </a>
                <Image src={IconInfoCircle} alt="logo" preview={false} />
              </span>
              <Image
                onClick={handleCancel}
                style={{ cursor: "pointer" }}
                src={IconClose}
                alt="logo icon"
                preview={false}
              />
            </div>
          </div>
        }
        onOk={handleSubmitForm}
        onCancel={handleCancel}
        className="modal-edit-user"
        okText={activeKey === `${items.length}` ? t("Lưu") : t("Tiếp tục")} // Đổi tên nút OK
        cancelText="Hủy" // Đổi tên nút Cancel
        width={980}
        closable={false}
        footer={(_) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 24,
            }}
            className="footer-edit-modal-user"
          >
            <>
              {infoUser?.id === detailUser?.id ? (
                <div></div>
              ) : (
                <Popconfirm
                  placement="topLeft"
                  className="popconfirm-custom"
                  title={
                    detailUser?.status ? t("Khóa tài khoản") : t("Mở tài khoản")
                  }
                  description={
                    <div className="content-pop-des">
                      {detailUser?.status
                        ? t(
                            "Bạn đang thao tác khóa tài khoản này, có chắc chắn muốn khóa?"
                          )
                        : t(
                            "Bạn đang thao tác mở tài khoản này, có chắc chắn muốn mở?"
                          )}
                    </div>
                  }
                  okText={detailUser?.status ? t("Khóa ngay") : t("Mở ngay")}
                  cancelText={t("Trở lại")}
                  okButtonProps={{ className: "custom-ok-button-class" }} // Tùy chỉnh lớp CSS cho nút OK
                  cancelButtonProps={{
                    className: "custom-cancel-button-class",
                  }} // Tùy chỉnh lớp CSS cho nút Hủy
                  onConfirm={handleOkModal}
                  icon={
                    <Image
                      preview={false}
                      width={20}
                      src={IconExclamationCircle}
                      alt="logo"
                    />
                  }
                >
                  <Col className="block-user">
                    <Button
                      disabled={disabledAction}
                      className={
                        detailUser?.status
                          ? "btn-block-red"
                          : "btn-active-green"
                      }
                      danger
                      type="primary"
                      htmlType="button"
                    >
                      <Image
                        src={IcLock}
                        preview={false}
                        className="pb-1 pe-2"
                      />
                      {detailUser?.status
                        ? t("Khóa tài khoản")
                        : t("Mở tài khoản")}
                    </Button>
                  </Col>
                </Popconfirm>
              )}
            </>
            <div className="modal-btn-user d-flex gap-12">
              {activeKey !== "4" && (
                <Button
                  disabled={!checkPermissionManagerAccount()}
                  loading={isLoading}
                  type="primary"
                  onClick={handleSubmitForm}
                  style={
                    checkPermissionManagerAccount() ? {} : { display: "none" }
                  }
                >
                  {activeKey === "3" ? t("Lưu") : t("Tiếp tục")}
                </Button>
              )}
            </div>
          </div>
        )}
      >
        <Spin spinning={loading}>
          <Tabs
            type="card"
            className="edit-user"
            defaultActiveKey="1"
            items={items}
            activeKey={activeKey}
            onChange={onChangeContinue}
          />
        </Spin>
        <ModalInfoUser
          isModalOpen={isModalOpen}
          detailUser={detailUser}
          handleCancelChildren={handleCancelChildren}
        />
      </Modal>
    </div>
  );
};

export default EditUser;
