import { Button, Card, Col, Form, Image, Input, message, Modal, Radio, Row, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconCopyApi from '../../assets/images/ic-copy-api.svg';
import IcLock from '../../assets/images/ic-lock.svg';
import IcUnLock from '../../assets/images/ic-unlock.svg';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import { filterOption } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { listBusCompanySelector } from '../../redux/slice/busCompanys/listBusCompanySlice';
import { createApiSelector } from '../../redux/slice/followApi/createApi';
import { updateFollowApiSelector, updateStatusFollowApi } from '../../redux/slice/followApi/lockFollowApi';
import { Constant } from '../../config/constant';
export const TypeApiOption = [
  {
    label: 'Tổng đại lý',
    value: 'agent_general',
  },
  {
    label: 'Nhà xe',
    value: 'car_company',
  },
];
const CreateApiFollow: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  loading,
  formApi,
  isDetail,
  listGeneralAgent,
  statusDetail,
  idDetail,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(updateFollowApiSelector);
  const { listBusCompanyActive } = useAppSelector(listBusCompanySelector);
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const [openModalLockApi, setOpenModalLockApi] = useState<boolean>(false);
  const typeApi = Form.useWatch('api_type', formApi);
  const [messageApi, contextHolder] = message.useMessage();
  const apiKey = Form.useWatch('key', formApi);
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  const onSearch = () => {};
  const info = () => {
    messageApi.success('Đã sao chép nội dung');
  };
  const handleCancelActive = () => {
    setOpenModalLockApi(false);
  };
  const handleActive = () => {
    dispatch(updateStatusFollowApi({ id: idDetail, status: statusDetail ? 0 : 1 }));
  };
  useEffect(() => {
    if (status === Constant.DEFAULT_STATUS) {
      setOpenModalLockApi(false);
    }
  }, [status]);

  return (
    <Modal
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      width={990}
      footer={[]}
    >
      {contextHolder}
      <Typography className="fs-20 fw-600">{isDetail ? 'Chỉnh sửa API' : 'Tạo API'} </Typography>
      <Card className="card-api-follow">
        <Row className="d-flex align-baseline">
          <Col span={4}>
            <Typography>
              Tên API <span className="red">*</span>
            </Typography>
          </Col>
          <Col span={6}>
            <Form.Item name="name" required>
              <Input placeholder="Nhập tên API" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="d-flex align-item-center mt-1">
          <Col span={4}>
            <Typography>
              API Key <span className="red">*</span>
            </Typography>
          </Col>
          <Col span={16} className="d-flex api-copy-input">
            <Form.Item name="key">
              <Input disabled />
            </Form.Item>
            <Button
              className="fw-400 fs-16 t-b btn-api-key"
              onClick={() => {
                navigator.clipboard.writeText(apiKey);
                info();
              }}
            >
              Sao chép <img src={iconCopyApi} alt="" />
            </Button>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col span={4} className="type-api-block">
            <Typography className="mt-1">
              Phân loại <span className="red">*</span>
            </Typography>
          </Col>
          <Col span={16} className="d-flex api-copy-input">
            <Form.Item name="api_type">
              <Radio.Group
                options={TypeApiOption}
                // onChange={onChangeTypeSeat}
                value={2}
                defaultValue={'car_company'}
                buttonStyle="solid"
                className="payment-checkbox-group"
                //   disabled={disableTypeSeat}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="d-flex align-item-center">
          <Col span={4}></Col>
          {typeApi === 'car_company' ? (
            <Col span={9}>
              <Form.Item
                labelCol={{ span: 24 }} //whole column
                name="car_company"
                className="car_company"
                // rules={[
                //   {
                //     required:
                //       detailUser?.group === USER_GROUP.CARLINK ? false : true,
                //     message: "Vui lòng chọn nhà xe",
                //   },
                // ]}
              >
                <Select
                  showSearch
                  placeholder={t('Tìm để chọn nhà xe')}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={
                    listBusCompanyActive?.length
                      ? listBusCompanyActive?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item?.name,
                          };
                        })
                      : []
                  }
                />
              </Form.Item>
            </Col>
          ) : (
            <Col span={9}>
              <Form.Item
                labelCol={{ span: 24 }} //whole column
                name="agent_general"
                className="car_company"
              >
                <Select
                  showSearch
                  placeholder={t('Tìm để chọn tổng đại lý')}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={
                    listGeneralAgent?.length >= 0
                      ? listGeneralAgent?.map((item: any) => ({
                          value: item?.id,
                          label: item?.name,
                        }))
                      : []
                  }
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row className="d-flex align-item-center mt-1">
          <Col span={4}>
            <Typography>
              Tên miền <span className="red">*</span>
            </Typography>
          </Col>
          <Col span={9} className="mt-2">
            <Form.Item name="domain" required>
              <Input placeholder="Nhập tên miền" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="d-flex align-item-center mt-1">
          <Col span={4}>
            <Typography>IP</Typography>
          </Col>
          <Col span={9}>
            <Form.Item name="ip">
              <Input placeholder="Nhập IP cho API" className="mt-1" />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Row className="d-flex mt-5">
        {isDetail && (
          <Col span={4} className="d-flex justify-content-start">
            {statusDetail ? (
              <Button
                className="btn-block-red type-bus w-160 h-btn-48"
                danger
                key={'btn-lock'}
                type="primary"
                htmlType="button"
                onClick={() => setOpenModalLockApi(true)}
              >
                <Image src={IcLock} preview={false} className="pb-1 pe-2" />
                {t('Khoá API')}
              </Button>
            ) : (
              <Button
                className="btn-active-green w-160 h-btn-48"
                danger
                key={'btn-unlock'}
                type="primary"
                htmlType="button"
                onClick={() => setOpenModalLockApi(true)}
              >
                <Image src={IcUnLock} preview={false} className="pb-1 pe-2" />
                {t('Mở API')}
              </Button>
            )}
          </Col>
        )}
        <Col span={isDetail ? 20 : 24} className="d-flex justify-content-end cl-8">
          <Button key="cancel" className="w-160 h-btn-48 btn-cancel" onClick={() => onCancel()}>
            {t('Đóng')}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            className="w-160 h-btn-48 btn"
            style={{ fontWeight: '600px', color: '#fff' }}
            onClick={() => formApi.submit()}
          >
            {isDetail ? t('Cập nhật') : t('Tạo API')}
          </Button>
        </Col>
      </Row>
      <ModalConfirm
        modalOpen={openModalLockApi}
        isAgentBus={true}
        okText={statusDetail ? t('Khóa ngay') : t('Mở ngay')}
        cancelText={t('Trở lại')}
        modalTitle={statusDetail ? t('Khoá API') : t('Mở API')}
        modalContent={
          statusDetail
            ? t('Bạn đang thao tác khóa API này, có chắc chắn muốn khóa?')
            : t('Bạn đang thao tác mở API này, có chắc chắn muốn mở?')
        }
        onCancel={handleCancelActive}
        onOk={handleActive}
      />
      {/* </Form> */}
    </Modal>
  );
};

export default CreateApiFollow;
