import {Button, Result} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';

function InternalServer() {
    const navigate = useNavigate();
    const redirectHome = () => {
        navigate(`/`);
    };
    return (
        <Result
            status='500'
            title='500'
            subTitle='Không thể kết nối tới sever'
            extra={
                <Button type='primary' onClick={redirectHome}>
                    Trở lại
                </Button>
            }
        />
    );
}

export default InternalServer;
