import {getAxiosClientWithToken} from "./index";
import {BusParams, ListBusRequest} from "../types";
import {handleFormData} from "../lib/utils";

export const getListBusAPI = (params: ListBusRequest) => {
    return getAxiosClientWithToken().get('/bus', {params});
};

export const createBusAPI = (params: BusParams) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post('/bus', formData);
};

export const updateBusAPI = (params: BusParams) => {
    const restClient = getAxiosClientWithToken();
    // const formData = handleFormData(params);
    return restClient.put(`/bus/${params.id}`, params);
};
export const updateStatusBusAPI = (params: { id: string, status: boolean }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.put(`/bus/status/${params.id}`, {status: params.status ? 0 : 1});
};
