import { UploadOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { RcFile } from "antd/es/upload";
import { UploadFile } from "antd/lib";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/styles/desktop/busCompany.scss";
import "../../assets/styles/listAgent.scss";
import {
  phoneRequiredRules,
  validatorEmail,
  validatorEmailOptional,
} from "../../config/validators/validators";
import { getBase64 } from "../../lib/utils";
import { checkAuthorization } from "../../api";
import {
  validateNameAgent,
  validateAddressAgent,
  validateCommissionPercentAgent,
} from "../../config/validators/validateAgent";
import { ACTION_NAME, messageCreateSuccess } from "../../config/message";
import { Constant } from "../../config/constant";
import TutorialsVideo from "../../components/TutorialsVideo";
import icClose from "../../assets/images/listOffice/icClose.svg";
import { handleGetUrlGuide } from "../../utils/helpers";
import "./GeneralAgents.scss";
import { createGeneralAgentAPI } from "../../api/generalAgent";

function AddGeneralAgents({
  openCreate,
  setOpenCreate,
  handleGeneralAgentApi,
  listTutorials,
}: any) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageLogo, setImageLogo] = useState<any>();
  const [outstandingBalance, setOutstandingBalance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commissionPercent, setCommissionPercent] = useState<any>(null);
  const [displayPrice, setDisplayPrice] = useState<any>(1);

  const commissionPercentCarlink = 100 - +commissionPercent;


  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    setOpenCreate(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const onChangePrintTicket = (checked: boolean) => {
    setDisplayPrice(checked ? 1 : 0);
  }

  //HANDLE SUBMIT
  const onFinish = async (values: any) => {
    setLoading(true)
    values.avatar = imageLogo;
    values.is_debt = outstandingBalance;
    values.display_price = displayPrice;
    try {
      const res = await createGeneralAgentAPI(values);
      if (res.status === Constant.DEFAULT_STATUS) {
        handleGeneralAgentApi();
        messageCreateSuccess(ACTION_NAME.AGENT);
        setOpenCreate(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
    setLoading(false)
  };

  // upload image
  const handleCancelImage = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList.length === 0) {
      setFileList(newFileList);
    } else {
      setFileList(newFileList.slice(-1));
      if (fileList.length !== 0 && fileList[0].url === undefined) {
        const dataBase64 = await getBase64(fileList[0].originFileObj as RcFile);
        setImageLogo(dataBase64);
      }
    }
  };

  const onChangeSwitch = (checked: boolean) => {
    setOutstandingBalance(checked);
  };

  const validateDebtAmount = (_: any, value: any) => {
    if (!outstandingBalance || (value >= 500000 && value <= 1000000000)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        "Số tiền phải nằm trong khoảng từ 500,000 đến 1,000,000,000 VND"
      )
    );
  };


  return (
    <Modal
    confirmLoading={loading}
      open={openCreate}
      title={
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography.Text className="fw-600 fs-18 ">
            {t("Thêm tổng đại lý mới")}
          </Typography.Text>
          <Space size={10} align="center">
            <TutorialsVideo
              url_youtube={handleGetUrlGuide(listTutorials, 11)}
            />
            <Image
              src={icClose}
              style={{ cursor: "pointer" }}
              preview={false}
              onClick={handleCancel}
            />
          </Space>
        </Row>
      }
      maskClosable={false}
      onCancel={handleCancel}
      className="modal-general-agent"
      cancelText="Hủy" // Đổi tên nút Cancel
      okText="Thêm tổng đại lý"
      onOk={handleOk}
      width={980}
      closable={false}
      footer={(_, { OkBtn, CancelBtn }: any) => (
        <div
          className="footer-modal-general-agent"
          // style={{
          //   justifyContent: "space-between",
          // }}
        >
        
          <div className="modal-btn-user modal-btn-agent d-flex gap-12">
            <CancelBtn className="modal-cancel-btn" />
            <OkBtn/>
          </div>
        </div>
      )}
    >
      <Form
        name="agent-basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="form-create-agent form-edit-user height-auto"
      >
        {/* <Spin> */}
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="name"
              label={t("Tên đại lý")}
              className="required"
              rules={validateNameAgent}
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item
              name="phone"
              rules={phoneRequiredRules}
              label={t("Số điện thoại")}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={24} xxl={24}>
            <Form.Item
              name="domain"
              label={t("Tên miền")}
              rules={validateAddressAgent}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={24} xxl={24}>
            <Form.Item
              name="address"
              label={t(" Địa chỉ ")}
              rules={validateAddressAgent}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={24} xxl={24}>
            <Form.Item
              name="email"
              label="Email liên hệ"
              rules={validatorEmail}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={24} xxl={24}>
            <Form.Item
              name="relevant_emails"
              label="Email nhận mặt vé"
              rules={validatorEmailOptional}
              labelCol={{ span: 12 }}
              // className="required"
              validateFirst
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="general-agent-commission_percent" xs={24} md={24} xl={24} xxl={24}>
            <Form.Item
              name="commission_percent"
              label="Chiết khấu tính trên % hoa hồng bán vé"
              rules={validateCommissionPercentAgent}
              labelCol={{ span: 12 }}
              className="required"
              validateFirst
            >
              <Input
                style={{ width: 117 }}
                type="number"
                suffix={"%"}
                value={commissionPercent}
                min={0}
                onChange={(e: any) => setCommissionPercent(e.target.value)}
              />
            </Form.Item>
            {commissionPercent && <span className="carlink-enjoys">Carlink được hưởng {commissionPercentCarlink}%</span>}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="col-debt-amount" xs={24} md={24} xl={24} xxl={24}>
            <Form.Item label="Cho phép dư nợ" className="required">
              <Switch checked={outstandingBalance} onChange={onChangeSwitch} />
            </Form.Item>
            {outstandingBalance && (
              <Form.Item
                name="debt_amount"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số tiền!",
                  },
                  {
                    validator: validateDebtAmount,
                  },
                ]}
                validateFirst
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  style={{marginLeft: 165, width: 282, marginBottom: 11 }}
                  suffix="VND"
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Form.Item name="display_price" label="Cho phép in giá vé lên mặt vé" className="required">
            <Switch checked={displayPrice === 1 ? true : false} onChange={onChangePrintTicket} />
          </Form.Item>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={24} xl={12} xxl={12}>
            <Form.Item className="upload-image required-none" label={t("Logo")}>
              <Upload
                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : (
                  <>
                    <UploadOutlined /> {t("Tải ảnh lên")}
                  </>
                )}
              </Upload>
              <Modal
                open={previewOpen}
                footer={null}
                onCancel={handleCancelImage}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="note"
          label={t("Ghi chú")}
          className="input-group note"
        >
          <Input.TextArea
            rows={4}
            cols={9}
            style={{ height: 96, width: 781, resize: "none" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddGeneralAgents;
