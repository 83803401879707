import { toast } from "react-toastify";
import { TicketsState } from "../../../types"
import { getErrorMessage } from "../../../api";
import { Constant } from "../../../config/constant";
import { ListRouteRequest } from "../../../types/route";
import { getAllListRoutesAPI } from "../../../api/route";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createTicketAPI, getListTicketsAPI, updateStatusTicketAPI, updateTicketAPI } from "../../../api/tickets";

const initialState = {
    listTickets: [],
    loading: false,
    success: false,
    errorDetail: {},
    message: '',
    pagination: {
        total: 0,
        per_page: Constant.PAGE_SIZE,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    listRoute: [],
} as TicketsState;

export const ticketsSlice = createSlice({
    name: 'tickets',
    initialState: initialState,
    reducers: {
        setListTickets(state, action) {
            state.listTickets = action.payload;
        },
        setListAllRoutes(state, action) {
            state.listRoute = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListTickets.pending, (state) => {
                state.loading = true;
            }).addCase(getListTickets.fulfilled, (state, action) => {
                state.loading = false;
                state.listTickets = action.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
            }).addCase(getListTickets.rejected, (state, action: any) => {
                state.loading = false;
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            }).addCase(createTicket.pending, (state) => {
                state.loading = true;
            }).addCase(createTicket.fulfilled, (state) => {
                state.loading = false;
            }).addCase(createTicket.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(updateTicket.pending, (state) => {
                state.loading = true;
            }).addCase(updateTicket.fulfilled, (state) => {
                state.loading = false;
            }).addCase(updateTicket.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            })
    }
})


export const { setListAllRoutes } = ticketsSlice.actions;

export const getListTickets = createAsyncThunk('tickets/listTickets', async (params: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await getListTicketsAPI(params);
        return response?.data;
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
})

export const createTicket = createAsyncThunk('tickets/createTicket', async (params: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await createTicketAPI(params);
        if (response?.data?.success) {
            toast.success(response?.data?.message);
        }
        return response?.data;
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const updateTicket = createAsyncThunk('tickets/updateTicket', async (params: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await updateTicketAPI(params);
        if (response?.data?.success) {
            toast.success(response?.data?.message);
        }
        return response?.data;
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const updateStatusTicket = createAsyncThunk('tickets/updateStatusTicket', async (params: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await updateStatusTicketAPI(params);
        if (response?.data?.success) {
            toast.success(response?.data?.message);
        }
        return response?.data;
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getAllListRoute = createAsyncThunk('tickets/getAllListRoute', async (params: ListRouteRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getAllListRoutesAPI(params);
        const {
            data,
            success,
        } = response?.data;
        if (success) {
            dispatch(setListAllRoutes(data));
            return true;
        }
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
})