import {Button, Result} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';

function Forbidden() {
    const navigate = useNavigate();
    const redirectHome = () => {
        navigate(`/`);
    };
    return (
        <Result
            status='403'
            title='403'
            subTitle='Không có quyền truy cập'
            extra={
                <Button type='primary' onClick={redirectHome}>
                    Trở lại
                </Button>
            }
        />
    );
}

export default Forbidden;
