import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { createAgentApi } from '../../../api/agent';
import { AgentState, CreateAgentState } from '../../../types/agent';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';
import { Action, CREATE_ERROR, CREATE_SUCCESS } from '../../../config/message';
import { Toast } from '../../../config/constant';

// @ts-ignore
export const createAgent = createAsyncThunk(
  'admin/create/agent',
  async (params: CreateAgentState, { dispatch, rejectWithValue }) => {
    try {
      const response = await createAgentApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
        dispatch(
          setToast({
            message: CREATE_SUCCESS(Action.AGENT),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: CREATE_ERROR(Action.AGENT),
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const createAgentSlice = createSlice({
  name: 'createAgent',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as AgentState,
  reducers: {
    setCreate: (state: AgentState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setCreateError: (state: AgentState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetRegisterCreateAgent: (state: AgentState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAgent.pending, (state: AgentState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createAgent.fulfilled, (state: AgentState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(createAgent.rejected, (state: AgentState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const createAgentSelector = (state: RootState) => state.createAgentSlice;
export const { setCreate, setCreateError, resetRegisterCreateAgent } = createAgentSlice.actions;
