import { Button, Flex, Form, Image, Modal, Row, TimePicker, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';
import dayjs from 'dayjs';

const ModalConfirmCopySchedule: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  loading,
  route,
  formCopySchedule,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
    formCopySchedule.resetFields();
  };
  return (
    <Modal
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      maskClosable={false}
      footer={[]}
    >
      <Flex className="mt-3">
        <Image preview={false} width={24} src={IconExclamationCircle} alt="Icon Exclamation Circle" />
        <h4 className="fs-18 fw-700 t-b pl-15">Nhân bản lịch chạy</h4>
      </Flex>
      <div className="pl-40 block-hour-copy-schedule">
        <Typography className="fs-16 fw-500 t-b  mt-1 mb-3">
          Bạn đang nhân bản chuyến
          <span className="fw-600">
            {` ${route?.route?.depart_point} - ${route?.route?.arrive_point}`}({route?.depart_time?.slice(0, 5)}),
          </span>{' '}
          Vui lòng nhập giờ đi mới.
        </Typography>
        <Form.Item
          name="depart_time"
          label={'Giờ đi (HH:mm):'}
          className="form-item-name required mt-2"
          required
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            () => ({
              validator(_, value) {
                const departTimeNumber = dayjs(value).format('HH:mm')?.replace(/:/g, '');
                if (Number(departTimeNumber) > 2359) {
                  return Promise.reject(new Error('Vui lòng nhập đúng định dạng thời gian'));
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <TimePicker suffixIcon={null} showNow={false} needConfirm={false} format={'HH:mm'} placeholder="00:00" />
        </Form.Item>
      </div>
      <Row className="d-flex justify-content-end mt-4">
        <>
          <Button loading={loading} key="cancel" className=" w-160 h-btn-48 me-3" onClick={() => modalHandleCancel()}>
            Đóng
          </Button>
          <Button
            key="ok"
            type="primary"
            loading={loading}
            className="w-160 h-btn-48 btn-success mr-0"
            htmlType="submit"
            onClick={() => modalHandleOk()}
          >
            Đồng ý
          </Button>
        </>
      </Row>
    </Modal>
  );
};

export default ModalConfirmCopySchedule;
