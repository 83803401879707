/* eslint-disable react-hooks/exhaustive-deps */
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Pagination,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { FormInstance } from "antd/lib";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import icEdit from "../../assets/images/ic-edit.svg";
import IcPlus from "../../assets/images/ic-plus.svg";
import "../../assets/styles/desktop/busCompany.scss";
import StatusFilter from "../../components/Modal/StatusFilter";
import { Constant } from "../../config/constant";

import { mbTrim } from "../../lib/utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { ListCompanyRequest } from "../../types";
import { Agent } from "../../types/agent";
// import UpdateAgent from './UpdateAgent';
import TutorialsVideo from "../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../utils/helpers";
import AddGeneralAgents from "./AddGeneralAgents";
import UpdateGeneralAgents from "./UpdateGeneralAgents";
import { fetchGeneralAgent } from "../../redux/slice/generalAgent/generalAgent";

function GeneralAgents() {
  const [keyword, setKeyword] = useState<ListCompanyRequest>({});
  const [status, setStatus] = useState<any>();
  const dispatch = useAppDispatch();
  const [formSearch]: [FormInstance] = Form.useForm();
  const [page, setPage] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState(false);
  const [detailData, setDetailData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? DEFAULT_PAGE_SIZE
    : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );

  const { t } = useTranslation();
  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );
  // const { loading, listAgent, pagination } = useAppSelector(listAgentSelector);
  const { result, pagination, isFetching } = useAppSelector(
    (state: any) => state?.generalAgent
  );

  let query = `page=${page}&limit=${limit}`;
  if(status !== undefined) {
    query += `&status=${status}`;
  }
  if (keyword.keyword) {
    query += `&keyword=${keyword.keyword}`;
  }
  const handleGeneralAgentApi = () => {
    dispatch(fetchGeneralAgent({ query }));
  };

  useEffect(() => {
    handleGeneralAgentApi();
  }, [query]);

  //DATA COLUMN
  const columns: ColumnsType<Agent> = [
    {
      title: "STT",
      key: "id",
      render: (_: any, record: any, index: number) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FontAwesomeIcon
              icon={faCircle}
              className={`${record.status ? "green" : "danger"} p-1`}
              size="xs"
            />
            <Typography className="text-color">
              {(page - 1) * limit + index + 1}
            </Typography>
          </div>
        );
      },
    },
    {
      title: "Tên tổng đại lý",
      dataIndex: "name",
      key: "garage_name",
        width: '200px',
      sorter: (a: any, b: any) => a?.name.localeCompare(b?.name),
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: "150px",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      // width: "250px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: '270px',
      sorter: (a: any, b: any) => a?.address.localeCompare(b?.address),
    },

    {
      title: "Chức năng",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div
            onClick={() => handleAgentDetail(record)}
            style={{ cursor: "pointer" }}
          >
            <Tooltip
              title={"Chỉnh sửa thông tin đại lý"}
              key={"edt"}
              placement="right"
            >
              <Image src={icEdit} preview={false} width={17} height={17} />
            </Tooltip>
          </div>
        </Space>
      ),
      align: "center",
      width: "120px",
    },
  ];

  //HANDLE STATUS
  const onChangeStatus = (value: number | undefined) => {
    setStatus(value);
    setPage(1);
  };

  //HANDLE TABLE
  const handleSearch = (values: any) => {
    formSearch.setFields([{ name: "keyword", value: mbTrim(values?.keyword) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(formSearch.getFieldsValue(["keyword"]));
  };
  //HANDLE NAVIGATE
  const handleAgentDetail = (record: any) => {
    setOpenModal(true);
    setDetailData(record);
  };
  const handleCreateAgent = () => {
    setOpenCreate(true);
  };

  const handlePagination = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const renderRowTable = (record: any) => {
    return record.status ? "" : "bg-disabled";
  };

  return (
    <Layout className="wrapper-list-agent min-vh-100 list_user agent agent-container">
      <Card>
        <StatusFilter status={status} onChangeStatus={onChangeStatus} />
        <Row className="fw-500 mt-4 mx-3">
          <Button
            type="primary"
            className={"btn-success height-btn-40"}
            onClick={handleCreateAgent}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t("Thêm tổng đại lý mới")}
          </Button>
        </Row>
        <Form
          className="mg-16 mt-3 mx-3"
          form={formSearch}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          onFinish={handleSearch}
          colon={false}
          style={{ position: "relative" }}
        >
          <Form.Item
            className="label-search"
            labelAlign="left"
            labelCol={{ md: 0 }}
            name="form-search"
          >
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item name="keyword">
                  <Input
                    placeholder={t(
                      "Nhập ID/Tên đại lý/số điện thoại để tìm kiếm"
                    )}
                  />
                </Form.Item>
              </Col>
              <Col className="text-center">
                <Button
                  htmlType="submit"
                  type="primary"
                  className={"btn-search height-btn-36"}
                  // onSubmit={handleSearch}
                  size="large"
                >
                  {t("Tìm kiếm")}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Space
            size={10}
            align="center"
            style={{ position: "absolute", right: 0, top: 7 }}
          >
            <TutorialsVideo
              url_youtube={handleGetUrlGuide(listTutorials, 11)}
            />
          </Space>
        </Form>
        <Table
          locale={{
            triggerDesc: "Sắp xếp giảm dần",
            triggerAsc: "Sắp xếp tăng dần",
            cancelSort: "Hủy sắp xếp",
          }}
          dataSource={result?.length > 0 ? result : undefined}
          rowKey="id"
          rowClassName={renderRowTable}
          columns={columns}
          className="mx-3 list-bus"
          //   pagination={false}
          loading={isFetching}
          //   pagination={{
          //     total: pagination?.total,
          //     current: page,
          //     pageSize: pagination?.per_page ?? limitPagination,
          //     position: ['bottomRight'],
          //     onChange: handlePagination,
          //     showSizeChanger: true,
          //   }}
        />
        <div className="pagination-table agency">
          <Pagination
            onChange={handlePagination}
            current={page}
            total={pagination?.total}
            pageSize={pagination?.per_page ?? limitPagination}
            showSizeChanger={true}
          />
        </div>
      </Card>

      {openCreate && (
        <AddGeneralAgents
          handleGeneralAgentApi={handleGeneralAgentApi}
          openCreate={openCreate}
          setOpenCreate={setOpenCreate}
          listTutorials={listTutorials}
        />
      )}

      {openModal && (
        <UpdateGeneralAgents
          handleGeneralAgentApi={handleGeneralAgentApi}
          openCreate={openModal}
          setOpenCreate={setOpenModal}
          detailData={detailData}
          listTutorials={listTutorials}
        />
      )}
    </Layout>
  );
}

export default GeneralAgents;
