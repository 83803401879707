import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { RootState } from '../../store';
import { officeParams } from '../../../types/office';
import { createOfficeAPI } from '../../../api/offices';
import { toast } from 'react-toastify';


// @ts-ignore
export const fetchCreateOffice = createAsyncThunk(
  'admin/create/office',
  async (params: officeParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await createOfficeAPI(params);
      const { data, status } = response;
      if (data.success) {
        dispatch(setCreate({ status: status, data: data }));
        toast.success(data.message)
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      toast.error(response.data.message); 
    }
  },
);


type createOfficeState = {
    error?: any,
    loading?: boolean,
    success?: boolean,
    status?: any,
    message?: any,
    errorDetail?: any,

}

export const createOfficeSlice = createSlice({
  name: 'createOffice',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
  } as createOfficeState,
  reducers: {
    setCreate: (state: createOfficeState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setCreateError: (state: createOfficeState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
      state.success = false;
    },
    resetRegisterCreateOffice: (state: createOfficeState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreateOffice.pending, (state: createOfficeState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(fetchCreateOffice.fulfilled, (state: createOfficeState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchCreateOffice.rejected, (state: createOfficeState, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const createOfficeSelector = (state: RootState) => state.createOffice;
export const { setCreate, setCreateError, resetRegisterCreateOffice } = createOfficeSlice.actions;
