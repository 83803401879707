import React from "react";
import { Tabs, type TabsProps } from "antd";
import { TabType } from "../../config/constant";

const itemsTabs: TabsProps["items"] = [
  {
    key: TabType.ALL,
    label: <p className="tab-title tab-title--first">Tất cả</p>,
  },
  {
    key: TabType.ACTIVE,
    label: <p className="tab-title">Đang hoạt động</p>,
  },
  {
    key: TabType.LOCK,
    label: <p className="tab-danger tab-title">Đã khóa</p>,
  },
];

const TabsFilterStatus = ({ activeTab, onChangeTab }: any) => {
  return (
    <Tabs
      defaultActiveKey={activeTab}
      activeKey={activeTab}
      items={itemsTabs}
      onChange={onChangeTab}
      className={`list-ticket__tabs ${
        activeTab === TabType.LOCK ? "danger" : ""
      }`}
    />
  );
};

export default TabsFilterStatus;
