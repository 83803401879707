import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

import {RootState} from "../store";
import {
    IDPickupLocation,
    ListPickupLocationRequest,
    ListPickupLocationState,
    ParamsLocationTransfer,
    UpdatePickupLocation
} from "../../types/pickupLocations";
import {Constant} from "../../config/constant";
import {
    createPickupLocationAPI,
    getListPickupLocationsAPI,
    getListProvinceWithPickupLocationsAPI,
    getLocationTransferAPI,
    getPickupLocationAPI,
    updatePickupLocationAPI,
    updateStatusPickupLocationAPI
} from "../../api/pickupLocations";
import {getErrorMessage} from "../../api";
import {setProvince} from "./provinceSlice";


// @ts-ignore

//Create pickup location
export const createPickupLocation = createAsyncThunk('pickupLocation/create', async (params: PickupLocationParams, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await createPickupLocationAPI(params);
        const {
            status
        } = response;
        if (status)
            return response.data;
        return true;
    } catch (error: any) {
        const {response} = error;
        dispatch(setError(response));
        return rejectWithValue(getErrorMessage(error));
    }
});

// List PickupLocation
export const fetchListPickupLocation = createAsyncThunk('pickupLocations/list', async (params: ListPickupLocationRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getListPickupLocationsAPI(params);
        const {
            data,
            success,
            pagination
        } = response?.data;
        if (success) {
            dispatch(setListPickupLocation({pickupLocations: data, pagination: pagination}));
            return true;
        }
    } catch (error: any) {
        dispatch(setListPickupLocation(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});


// List PickupLocation
export const fetchListProvincesWithPickupLocation = createAsyncThunk('pickupLocations/provinces', async (_: void, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getListProvinceWithPickupLocationsAPI();
        const {
            data,
            success
        } = response?.data;
        if (success) {
            dispatch(setListProvinces(data));
            return true;
        }
    } catch (error: any) {
        dispatch(setProvince(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const fetchInfoPickupLocation = createAsyncThunk('pickupLocation/id', async (params: IDPickupLocation, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getPickupLocationAPI(params);

        const {
            data,
            success,
        } = response?.data;
        if (success) {
            dispatch(setPickupLocation(data));
            return true;
        }
    } catch (error: any) {
        dispatch(setPickupLocation(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const updatePickupLocation = createAsyncThunk('pickupLocation/update', async (params: UpdatePickupLocation, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updatePickupLocationAPI(params.id, params);
        const {
            success,
        } = response?.data;

        if (success) {
            // dispatch(setPickupLocation({status: success, data: data}));
            return response.data;
        }
    } catch (error: any) {
        const {response} = error;
        dispatch(setError(response));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});


export const updateStatusPickupLocation = createAsyncThunk('pickupLocation/update-status', async (params: UpdatePickupLocation, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateStatusPickupLocationAPI(params.id, params.status);
        const {
            success,
        } = response?.data;

        if (success) {
            return response.data;
        }
    } catch (error: any) {
        dispatch(setPickupLocation(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const getPickUpLocationTransfer = createAsyncThunk('pickupLocation/transfer', async (params: ParamsLocationTransfer, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getLocationTransferAPI(params);
        const {
            success,
        } = response?.data;

        if (success) {
            dispatch(setListLocationTransfer(response?.data));
            return true;
        }
    } catch (error: any) {
        dispatch(setPickupLocation(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});



export const pickupLocationSlice = createSlice({
    name: 'pickupLocationSlice',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: false,
        message: '',
        errorDetail: null,
        pickupLocations: [],
        listPickUpTransfer:[],
        pickupLocation: {
            id: null,
            province_id: undefined,
            name: '',
            address: '',
            phone: '',
            latitude: '',
            longitude: '',
            status: undefined,
        },
        provinces: [],
        pagination: {
            total: 0,
            per_page: Constant.PAGE_SIZE,
            current_page: Constant.DEFAULT_PAGE,
            last_page: Constant.DEFAULT_PAGE,
        },
    } as ListPickupLocationState,
    reducers: {
        setListPickupLocation: (state: ListPickupLocationState, {payload}) => {
            const {pickupLocations, pagination} = payload;
            state.pickupLocations = pickupLocations;
            state.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setPickupLocation: (state: ListPickupLocationState, {payload}) => {
            state.pickupLocation = payload;
        },
        setListProvinces: (state: ListPickupLocationState, {payload}) => {
            state.provinces = payload;
        },
        setListLocationTransfer:(state: ListPickupLocationState, {payload}) => {
            state.listPickUpTransfer = payload?.data;
        },
        setError: (state: ListPickupLocationState, {payload}) => {
            const {errors} = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetState: (state: ListPickupLocationState) => {
            state.success = false;
            state.message = '';
            state.errorDetail = null;
        },
    },
    extraReducers: (builder) => {
        //Create PickupLocation
        builder.addCase(createPickupLocation.pending, (state: ListPickupLocationState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(createPickupLocation.fulfilled, (state: ListPickupLocationState, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.pickupLocation = action.payload.data;
        });
        builder.addCase(createPickupLocation.rejected, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });

        // Update PickupLocation
        builder.addCase(updatePickupLocation.pending, (state: ListPickupLocationState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(updatePickupLocation.fulfilled, (state: ListPickupLocationState, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.message = action?.payload?.message;
            state.pickupLocation = action.payload.data;
        });
        builder.addCase(updatePickupLocation.rejected, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });


        // Update status PickupLocation
        builder.addCase(updateStatusPickupLocation.pending, (state: ListPickupLocationState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(updateStatusPickupLocation.fulfilled, (state: ListPickupLocationState, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.message = action?.payload?.message;
            state.pickupLocation = action.payload.data;
        });
        builder.addCase(updateStatusPickupLocation.rejected, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });

        // List PickupLocation
        builder.addCase(fetchListPickupLocation.pending, (state: ListPickupLocationState) => {
            state.loading = true;
        });
        builder.addCase(fetchListPickupLocation.fulfilled, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(fetchListPickupLocation.rejected, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });

        // List PickupLocation
        builder.addCase(fetchListProvincesWithPickupLocation.pending, (state: ListPickupLocationState, action) => {
            state.loading = true;
        });
        builder.addCase(fetchListProvincesWithPickupLocation.fulfilled, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(fetchListProvincesWithPickupLocation.rejected, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });

        // Info PickupLocation
        builder.addCase(fetchInfoPickupLocation.pending, (state: ListPickupLocationState) => {
            state.loading = true;
        });
        builder.addCase(fetchInfoPickupLocation.fulfilled, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(fetchInfoPickupLocation.rejected, (state: ListPickupLocationState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
    },
});

export const pickupLocationSelector = (state: RootState) => state.pickupLocation;
export const {
    setListPickupLocation,
    setPickupLocation,
    resetState,
    setListProvinces,
    setError,
    setListLocationTransfer
} = pickupLocationSlice.actions;
