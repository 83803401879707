import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { getSendMailOrderApi } from '../../../api/booking';
import { Toast } from '../../../config/constant';
import { BookingState } from '../../../types/booking';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';

export const getSendBookingMail = createAsyncThunk(
  'admin/order/send-mail',
  async (params: { ticket_code: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getSendMailOrderApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setUpdate({ status: status, data: data }));
        dispatch(
          setToast({
            message: 'Gửi mail thành công',
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setUpdateError(response));
      dispatch(
        setToast({
          message: response?.data?.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const sendMailSlice = createSlice({
  name: 'sendMailOrder',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
    loadingSend: false,
  } as BookingState,
  reducers: {
    setUpdate: (state: BookingState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
    },
    setUpdateError: (state: BookingState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetUpdateBooking: (state: BookingState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSendBookingMail.pending, (state: BookingState) => {
      state.loadingSend = true;
      state.success = false;
    });
    builder.addCase(getSendBookingMail.fulfilled, (state: BookingState) => {
      state.loadingSend = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getSendBookingMail.rejected, (state: BookingState, { payload }) => {
      state.loadingSend = false;
      state.success = false;
      state.error = true;
      state.message = payload;
    });
  },
});

export const sendMailOrderSelector = (state: RootState) => state.sendMailOrder;
export const { setUpdateError, setUpdate, resetUpdateBooking } = sendMailSlice.actions;
