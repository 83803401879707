/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Table,
  Typography,
  Tag,
  Spin,
  Layout,
  Select,
  Modal,
  DatePicker,
  Image,
  Flex,
} from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { formatPrice, formatPriceInput } from "../../lib/utils";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { useEffect, useState } from "react";
import IcMoney from "../../assets/images/accountDeposit/icMoney.svg";
import IcBus from "../../assets/images/accountDeposit/icBus.svg";
import { validateAmountDeposit } from "../../config/validators/validateDeposit";
import "./AccountDeposit.scss";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import { fetchHistoryPromotion } from "../../redux/slice/system/historyPromotion";
import { getListCompanyActive } from "../../api/history";
import { createPromotionHistoryApi } from "../../api/system";
import { ACTION_NAME, messageCreateSuccess } from "../../config/message";
import { checkAuthorization } from "../../api";
import type { FormProps } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import icCircle from "../../assets/images/accountDeposit/depositCircle.svg";
import { TIMER_DEPOSIT } from "../../config/constant";
const { RangePicker } = DatePicker;

type FieldType = {
  company_id?: string;
  content?: string;
  amount?: any;
};

const { TextArea } = Input;

function AccountDepositPromotion() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination')
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  const [amountError, setAmountError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [capVal, setCapVal] = useState<boolean>(false);
  const [busCompanyId, setBusCompanyId] = useState("");

  const accountUser = useAppSelector((state) => state.authAccount?.user);

  const { result, pagination, isFetching, total } = useAppSelector(
    (state) => state?.promotionHistory
  );

  const { t } = useTranslation();
  const [status, setStatus] = useState<any>("");
  const [page, setPage] = useState<number>(1);
  const [depositAmount, setDepositAmount] = useState<any>("");
  const [listBusCompany, setListBusCompany] = useState<any>([]);

  const [content, setContent] = useState("");
  const [companyId, setCompanyId] = useState("");

  const onChangeDepositAmount = (e: any) => {
    const value = e.target.value;
    const formattedValue = formatPriceInput(value);
    setDepositAmount(formattedValue);

    // Custom validation logic
    validateFields(e.target.value);
  };

  const onChangeSelectAgent = (value: any) => {
    setBusCompanyId(value);
  };

  const [dateRange, setDateRange] = useState<any>("");
  const SITE_KEY_CAPTCHA = process.env.REACT_APP_SITE_KEY_CAPTCHA;

  const validateFields = (value: any) => {
    const checkNumber: any = parseFloat(formatPrice(value));
    const checkValue = formatPrice(value);
    let isValid = false;

    function isNumeric(value: any) {
      // Regular expression for numeric characters
      const numericRegex = /^\d+$/;
      // Check if the value matches the numeric regex
      return numericRegex.test(value);
    }

    if (!value) {
      setAmountError("Bạn hãy nhập số tiền cần nạp");
    } else if (!isNumeric(checkValue)) {
      setAmountError("Số tiền không đúng định dạng");
    } else if (checkNumber < 500000) {
      setAmountError("Số tiền tối thiểu cần nạp là 500.000");
    } else {
      setAmountError(null);
      isValid = true;
    }
    return isValid;
  };

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  };

  const handleResetFields = () => {
    form.resetFields();
    setAmountError(null);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Ngày nạp tiền",
      dataIndex: "deposit_date",
      key: "deposit_date",
      // width: 220,
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Nội dung chuyển khoản",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Tài khoản nạp",
      dataIndex: "deposit_user",
      key: "deposit_user",
    },
    {
      title: "Tên nhà xe",
      dataIndex: "company",
      key: "company",
      render: (_) => {
        return <>{_?.name}</>;
      },
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 90,
      render: (_, record) => {
        const status = record?.status; // Destructure status from record
        let color;

        switch (status) {
          case "wait_confirm":
            color = "wait_confirm_deposit_btn";
            break;
          case "confirmed":
            color = "confirmed_deposit_btn"; // Consistent green for awaiting confirmation and confirmed
            break;
          case "cancel":
            color = "cancel_deposit_btn"; // Set red for cancelled status
            break;
          default:
            color = "default_deposit_btn";
        }

        let textStatus;
        if (status === "wait_confirm") {
          textStatus = "Chờ xác nhận";
        }
        if (status === "confirmed") {
          textStatus = "Đã xác nhận";
        }
        if (status === "cancel") {
          textStatus = "Đã từ chối";
        }

        return (
          <Tag color={"#000000"} className={color} key={record?.id}>
            {textStatus}
          </Tag>
        );
      },
    },
  ];

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(1);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };
  const selectListBusCompany = listBusCompany?.length && listBusCompany?.map((item: any) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const filterNameBus =  listBusCompany?.length && listBusCompany?.filter(
    (item: any) => item?.id === companyId
  );
  const handleListCarCompanyApi = async () => {
    try {
      const res = await getListCompanyActive();
      if (res.status === 200) {
        setListBusCompany(res?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleListCarCompanyApi();
  }, []);

  const createDepositPromotion = async () => {
    setIsLoading(true);
    const values = {
      company_id: `${companyId}`,
      amount: formatPrice(depositAmount),
      content: content,
    };

    try {
      const res = await createPromotionHistoryApi(values);
      if (res.status === 200) {
        handleListHistory();
        setDepositAmount("");
        setContent("");
        setCompanyId("");
        handleResetFields();

        messageCreateSuccess(ACTION_NAME.REPOSIT);
        setIsOpen(false);
      }
    } catch (error) {
      checkAuthorization(error);
    }
    setIsLoading(false);
  };

  const handleIsCancel = () => {
    setIsOpen(false);
  };

  let query = `page=${page}&limit=${limit}&status=${status}&company_id=${busCompanyId}`;
  let actionSearch = `company_id=${busCompanyId}`;
  if (dateRange) {
    const [startDate, endDate] = dateRange;

    query += `&from=${startDate.format("YYYY-MM-DD")}&to=${endDate.format(
      "YYYY-MM-DD"
    )}`;
    actionSearch += `&from=${startDate.format(
      "YYYY-MM-DD"
    )}&to=${endDate.format("YYYY-MM-DD")}`;
  }
  const handleListHistory = () => {
    dispatch(fetchHistoryPromotion({ query }));
  };
  useEffect(() => {
    handleListHistory();
     // Thiết lập interval để gọi API mỗi 30 giây
     const intervalId = setInterval(handleListHistory, TIMER_DEPOSIT);
    
     // Dọn dẹp interval khi component bị unmount
     return () => clearInterval(intervalId);
  }, [query]);

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const isValid = validateFields(depositAmount);
    if (isValid) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [actionSearch]);

  return (
    <Layout className="wrapper-account-deposit">
      {accountUser?.is_superadmin && (
        <Spin spinning={false}>
          <Card className="container-card-deposit-promotion">
            <Form layout="vertical" onFinish={onFinish} form={form}>
              <Row gutter={12} className="mb-row-deposit">
                <Col>
                  <Form.Item
                    className="form-item-name required-deposit"
                    label={t("Nhà xe nhận khuyến mãi")}
                    name="company_id"
                    validateFirst
                    rules={[
                      { required: true, message: "Vui lòng chọn nhà xe" },
                    ]}
                  >
                    <Select
                      style={{ width: 276 }}
                      showSearch
                      placeholder="Chọn nhà xe"
                      optionFilterProp="children"
                      value={companyId}
                      onChange={(value) => setCompanyId(value)}
                      filterOption={filterOption}
                      options={
                       listBusCompany?.length ? [
                        { value: "", label: "Chọn nhà xe" },
                        ...selectListBusCompany,
                      ] :[ { value: "", label: "Chọn nhà xe" }]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    className="form-item-name required-deposit"
                    label={t("Số tiền khuyến mãi")}
                    validateFirst
                    rules={validateAmountDeposit}
                    help={amountError}
                    validateStatus={amountError ? "error" : ""}
                  >
                    <Input
                      suffix={"VND"}
                      value={depositAmount}
                      onChange={onChangeDepositAmount}
                      style={{ width: 348 }}
                      placeholder="Nhập số tiền (tối thiểu 500,000đ)"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6} className="mb-row-deposit">
                <Col>
                  <Form.Item
                    className="form-item-name required-deposit"
                    label={t("Nhà xe nhận khuyến mãi")}
                    name="content"
                    validateFirst
                    rules={[
                      { required: true, message: "Vui lòng nhập nội dung" },
                    ]}
                  >
                    <TextArea
                      style={{ width: 636 }}
                      value={content}
                      onChange={(e) => setContent(e?.target?.value)}
                      placeholder="Lời nhắn tới nhà xe"
                      autoSize={{ minRows: 5, maxRows: 5 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <ReCAPTCHA
                    sitekey={`${SITE_KEY_CAPTCHA}`}
                    onChange={(val: any) => setCapVal(val)}
                  />
                </Col>
                <Col className="captcha-message-promotion">
                  <Image src={icCircle} preview={false} />
                  <Typography className="captcha-promotion-text">
                    Nếu bạn trả lời sai 3 lần thì sẽ bị khoá tài khoản trong
                    vòng 24h!
                  </Typography>
                </Col>
              </Row>

              <Button
                className="btn-deposit-submit"
                disabled={!capVal}
                type="primary"
                onClick={validateFields}
                htmlType="submit"
              >
                Nạp tiền
              </Button>
            </Form>
          </Card>
        </Spin>
      )}
      <Card>
        <Row className="fw-500">
          <Typography className="title-account-deposit">
            {t("Lịch sử yêu cầu nạp tiền")}
          </Typography>
        </Row>

        <Form.Item
          className="label-search mb-0"
          labelAlign="left"
          labelCol={{ md: 0 }}
        >
          <Row gutter={10}>
            <Col>
              <Form.Item name="keyword">
                <Select
                  style={{ width: 312 }}
                  showSearch
                  placeholder="Chọn nhà xe"
                  optionFilterProp="children"
                  onChange={onChangeSelectAgent}
                  filterOption={filterOption}
                  options={
                    listBusCompany?.length ? [
                     { value: "", label: "Chọn nhà xe" },
                     ...selectListBusCompany,
                   ] :[ { value: "", label: "Chọn nhà xe" }]}
                />
              </Form.Item>
            </Col>
            {
              <Col>
                <Form.Item name="group">
                  <RangePicker
                    style={{ width: 304 }}
                    format="DD/MM/YYYY"
                    picker="date"
                    placeholder={["Từ ngày", "Đến ngày"]}
                    onChange={handleDateRangeChange}
                  />
                </Form.Item>
              </Col>
            }
          </Row>
        </Form.Item>
        <Table
          locale={{
            triggerDesc: "Sắp xếp tăng dần",
            triggerAsc: "Hủy sắp xếp",
            cancelSort: "Sắp xếp giảm dần",
          }}
          bordered={false}
          loading={isFetching}
          // rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ??  limitPagination,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          dataSource={result?.length > 0 ? result : []}
          summary={(pageData) => {
            return (
              result?.length > 0 &&
              page === pagination?.last_page && (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <Flex align="center" gap="middle">
                        <Typography.Text className="text-color-default fw-700 fs-16">
                          TỔNG
                        </Typography.Text>
                        <Typography.Text className="text-color-default fw-500 fs-16">
                          (VND)
                        </Typography.Text>
                      </Flex>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell align="right" index={10}>
                      <Row className="recharge-summary">
                        <Typography.Text className="text-color-default text-end fw-700 fs-16 recharge-total-3">
                          {total}
                        </Typography.Text>
                      </Row>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            );
          }}
        />
      </Card>
      <Modal
        open={isOpen}
        closable={false}
        width={600}
        onCancel={handleIsCancel}
        className="wrapper-confirm-deposit-modal"
        title={
          <div style={{ display: "flex", gap: 20 }}>
            <Image
              preview={false}
              width={20}
              src={IconExclamationCircle}
              alt="Icon Exclamation Circle"
            />
            <Typography className="confirm-title-deposit-modal">
              Xác nhận nạp tiền
            </Typography>
          </div>
        }
        footer={(_) => {
          return (
            <>
              <Col className="footer-content-confirm-deposit d-flex gap-12">
                <Button className="fs-18" onClick={handleIsCancel}>
                  {t("Trở lại")}
                </Button>
                <Button
                  className="btn-active-green fs-18"
                  type="primary"
                  htmlType="button"
                  onClick={() => createDepositPromotion()}
                  loading={isLoading}
                >
                  Xác nhận nạp tiền
                </Button>
              </Col>
            </>
          );
        }}
      >
        <div className="confirm-content-deposit-modal">
          <Typography
            style={{ marginBottom: 36, marginTop: 10 }}
            className="text-confirm-deposit-modal fs-20"
          >
            Bạn đang thao tác xác nhận yêu cầu nạp tiền này, bạn có chắc chắn
            muốn xác nhận?
          </Typography>
          <Col>
            <Row>
              <Col
                className="col-des-popconfirm"
                style={{ marginBottom: "16px" }}
              >
                <Image src={IcBus} />
                <span className="text-confirm-deposit-modal fs-20">
                  Nhà xe:
                </span>
              </Col>
              <Col className="text-confirm-deposit-modal fs-20 fw-600">
                {filterNameBus  ? filterNameBus[0]?.name :''}
              </Col>
            </Row>

            <Row>
              <Col
                className="col-des-popconfirm"
                style={{ marginBottom: "0px" }}
              >
                <Image src={IcMoney} />
                <span className="text-confirm-deposit-modal fs-20">
                  Số tiền:
                </span>
              </Col>
              <Col className="text-confirm-deposit-modal fs-20 fw-600">
                {formatPriceInput(depositAmount ? depositAmount : 0)} VND
              </Col>
            </Row>
          </Col>
        </div>
      </Modal>
    </Layout>
  );
}

export default AccountDepositPromotion;
