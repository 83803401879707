import { Button, Checkbox, Col, Flex, Form, Image, Input, Modal, Radio, Row, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RadioChangeEvent } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconExclamationCircle from '../../assets/images/ExclamationCircle.svg';
import '../../assets/styles/desktop/busBooking.scss';
import { useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/slice/authSlice';
import { USER_GROUP } from '../../config/constant';

export const reasonOptions = [
  { label: 'Nhà xe hủy', value: 'car_company' },
  { label: 'Đại lý hủy', value: 'agent' },
  { label: 'Khách hàng hủy', value: 'customer' },
];
export const reasonContractOptions = [
  { label: 'Nhà xe hủy', value: 'car_company' },
  { label: 'Khách hàng hủy', value: 'customer' },
];
const sendOptions = [
  // { label: 'Gửi SMS', value: 0 },
  { label: 'Gửi Email', value: 1 },
];
const reasonAgentOptions = [
  { label: 'Đại lý hủy', value: 'agent' },
  { label: 'Khách hàng hủy', value: 'customer' },
];

const ModalCancelTicket: React.FC<any> = ({
  okText,
  cancelText,
  modalTitle,
  modalOpen,
  onCancel,
  onOk,
  modalContent,
  isAgentId,
  agentId,
}: any) => {
  const { t } = useTranslation();
  const { group } = useAppSelector(selectAuth);
  const [reason, setReason] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };
  const onChangeReason = ({ target: { value } }: RadioChangeEvent) => {
    setReason(value);
  };
  const onChangeSendOption = () => {};
  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      centered
      width={601}
      className="modal-cancel-ticket"
    >
      <Flex>
        <Image preview={false} width={20} src={IconExclamationCircle} alt="Icon Exclamation Circle" />
        <Typography.Text className="pl-15">
          <h5 className="fw-700">{modalTitle}</h5>
          <span className="fw-500">{modalContent}</span>
        </Typography.Text>
      </Flex>
      <div className="w-100 mt-2">
        <Row gutter={12}>
          {group === USER_GROUP.CAR_COMPANY && isAgentId && agentId ? (
            <Col span={8}>
              <Form.Item name="final_price" label={t(`Tổng tiền`)}>
                <Input disabled suffix="VND" />
              </Form.Item>
            </Col>
          ) : (
            <Col span={8}>
              <Form.Item name="commission_price" label={t(`Tổng tiền`)}>
                <Input disabled suffix="VND" />
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item name="cancel_fee" className="fw-500" label={t('Tổng phí hủy')}>
              <Input defaultValue={0} min={0} suffix="VND" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="refund" className="data-picker" label={t('Hoàn tiền')}>
              <Input defaultValue={0} min={0} suffix="VND" disabled />
            </Form.Item>
          </Col>
        </Row>
        <span className="fw-500 fs-16 required" style={{ color: '#435869' }}>
          {t('Lý do')}
        </span>
        <Row className="reason-block-cancel">
          <Form.Item name="reason_cancel">
            <Radio.Group
              options={group === 'agent' ? reasonAgentOptions : reasonOptions}
              onChange={onChangeReason}
              value={reason}
              buttonStyle="solid"
              className="send_method payment-checkbox-group"
              defaultValue={0}
            />
          </Form.Item>
        </Row>
        <Form.Item name="send">
          <Checkbox.Group className="checkbox-reason" options={sendOptions} onChange={onChangeSendOption} />
        </Form.Item>
        <Form.Item
          name="reason_note"
          className="form-item-name reason-note"
          validateTrigger={['onChange', 'onBlur']}
          label={t('Ghi chú')}
        >
          <TextArea maxLength={100} placeholder="" style={{ height: 106, resize: 'none' }} value={reason} />
        </Form.Item>
      </div>
      <Row className="d-flex justify-content-end mt-4">
        <>
          <Button key="cancel" className="w-78 h-btn-32 me-3 btn-back agent" onClick={() => modalHandleCancel()}>
            Trở lại
          </Button>
          <Button
            key="ok"
            type="primary"
            className="w-84 h-btn-32 btn-success mr-0"
            htmlType="button"
            onClick={() => modalHandleOk()}
          >
            {okText}
          </Button>
        </>
      </Row>
    </Modal>
  );
};

export default ModalCancelTicket;
