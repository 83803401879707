import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {DriverParams, ListDriverState} from "../../types";
import {createDriverAPI} from "../../api/driver";
import {getErrorMessage} from "../../api";


// @ts-ignore
export const createDriver = createAsyncThunk('admin/create/driver', async (params: DriverParams, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await createDriverAPI(params);
        const {
            data,
            status
        } = response;
        if (status)
            dispatch(setCreate({status: status, data: data}));
        return true;
    } catch (error: any) {
        const {response} = error;
        dispatch(setCreateError(response));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});
export const createDriverSlice = createSlice({
    name: 'createDriver',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        message: '',
        errorDetail: null,
    } as ListDriverState,
    reducers: {
        setCreate: (state: ListDriverState, {payload}) => {
            const {message} = payload.data;
            state.status = payload?.status;
            state.message = message;
        },
        setCreateError: (state: ListDriverState, {payload}) => {
            const {errors} = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetState: (state: ListDriverState) => {
            state.success = false;
            state.message = '';
            state.errorDetail = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createDriver.pending, (state: ListDriverState) => {
            state.loading = true;
        });
        builder.addCase(createDriver.fulfilled, (state: ListDriverState, res) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(createDriver.rejected, (state: ListDriverState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });
    },
});

export const createDriverSelector = (state: RootState) => state.createDriver;
export const {setCreate, setCreateError, resetState} = createDriverSlice.actions;
