import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Flex,
  Image,
  Table,
  Select,
  Button,
  Divider,
  GetProps,
  DatePicker,
  Typography,
  ConfigProvider,
  type FormProps,
  type TableColumnsType,
} from "antd";
import "dayjs/locale/vi";
import ExcelJS from "exceljs";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import dayjs from "dayjs";
import "./Report.scss";
import { formatPrice, handleGetUrlGuide } from "../../utils/helpers";
import IconFileExport from "../../components/Icons/IconFileExport";
import IconExportExcel from "../../components/Icons/IconExportExcel";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import { DataReport } from "../../types/report";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant } from "../../config/constant";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setFilterRangeDate,
  setFilterContent,
  getListReportBalanceChangeMainAgentExcel,
  getListReportBalanceChangeMainAgent,
  setPerPage,
} from "../../redux/slice/report/reportBalanceChangeMainAgentSlice";
import moment from "moment";
import TutorialsVideo from "../../components/TutorialsVideo";
import TextDisplay from "../../components/Display/TextDisplay";

dayjs.locale("vn");
const { RangePicker } = DatePicker;
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

type FieldType = {
  range_date_report: string;
  content: string;
};

const optionsContent = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "cancel_ticket",
    label: "Hủy vé",
  },
  {
    value: "book_ticket",
    label: "Xuất vé",
  },
  {
    value: "recharge",
    label: "Nạp tiền",
  },
];

const ReportBalanceChangeMainAgent = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [timeReport, setTimeReport] = useState<RangePickerProps["value"]>();

  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

  const {
    filter: listFilter,
    loading: loadingListReport,
    listReport,
    pagination,
  } = useAppSelector((state) => state?.reportBalanceChangeMainAgent);

  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );

  const watchContent = Form.useWatch("content", form);

  const handleChangeContent = async (value: string) => {
    dispatch(setFilterContent(value));
  };

  const onChangePage = async (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      dispatch(setPerPage(pageSize));
    } else {
      setPage(page);
    }
    await dispatch(
      getListReportBalanceChangeMainAgent({
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        content: watchContent?.label ? watchContent?.value : watchContent,
        page: pagination?.per_page !== pageSize ? Constant.DEFAULT_PAGE : page,
        limit: pageSize,
      })
    );
  };

  const onFinish: FormProps<any>["onFinish"] = async (values) => {
    setPage(Constant.DEFAULT_PAGE);
    const response = await dispatch(
      getListReportBalanceChangeMainAgent({
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        content: watchContent?.label ? watchContent?.value : watchContent,
        page: Constant.DEFAULT_PAGE,
        limit: pagination?.per_page || limitPagination,
      })
    );
    if (response?.payload?.success) {
      toast.success(response?.payload?.message);
    }
  };

  const handleGetListReportBegin = async () => {
    setPage(Constant.DEFAULT_PAGE);
    if (listFilter?.from && listFilter?.to) {
      await dispatch(
        getListReportBalanceChangeMainAgent({
          from: dayjs(listFilter?.from).format("YYYY-MM-DD"),
          to: dayjs(listFilter?.to).format("YYYY-MM-DD"),
          content: listFilter?.content,
          page: Constant.DEFAULT_PAGE,
          limit: pagination?.per_page || limitPagination,
        })
      );
    }
  };

  useEffect(() => {
    handleGetListReportBegin();
    if (listFilter?.from && listFilter?.to) {
      setTimeReport([
        dayjs(listFilter?.from, "YYYY-MM-DD"),
        dayjs(listFilter?.to, "YYYY-MM-DD"),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: TableColumnsType<DataReport> = [
    {
      title: "Thời gian",
      dataIndex: "date",
      render: (date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {date ? moment(date).format("DD/MM/YYYY - HH:mm:ss") : ""}
        </Typography.Text>
      ),
      sorter: (a: any, b: any) =>
        moment(a?.date).unix() - moment(b?.date).unix(),
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      render: (content) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={content} />
        </Typography.Text>
      ),
    },
    {
      title: "Mã vé",
      dataIndex: "ticket_code",
      render: (ticket_code) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={ticket_code} />
        </Typography.Text>
      ),
    },
    {
      title: "Tài khoản thao tác",
      dataIndex: "user",
      render: (user) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={user?.name} />
        </Typography.Text>
      ),
    },
    {
      title: "Số tiền GD",
      dataIndex: "price",
      align: "right",
      render: (price, record) => (
        <Typography.Text
          className={`${
            record?.status ? "text-color-green-default" : "red"
          } fs-16 text-color fw-500`}
        >
          {`${record?.status ? "+" : "-"}${
            price ? formatPrice(Number(price), 0, ",") : 0
          }`}
        </Typography.Text>
      ),
    },
    {
      title: "Tiền BH",
      dataIndex: "isurrance",
      align: "right",
      render: (price, record) => (
        <Typography.Text className={`fs-16 text-color fw-500 red`}>
          {`- ${formatPrice(Number(price), 0, ",")}`}
        </Typography.Text>
      ),
    },
    {
      title: "Số dư",
      dataIndex: "main_agent_balance",
      align: "right",
      render: (main_agent_balance) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {main_agent_balance ? formatPrice(main_agent_balance, 0, ",") : 0}
        </Typography.Text>
      ),
    },
  ];

  const onChangeTimeReport = (value: RangePickerProps["value"]) => {
    setTimeReport(value);
    setTimeReport(value);
    dispatch(setFilterRangeDate(value));
  };

  const columnsExport: IExcelColumn[] = [
    {
      title: "Thời gian",
      dataIndex: "date",
      width: 40,
      __excelTitle__: "Thời gian",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      width: 25,
      __excelTitle__: "Nội dung",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Mã vé",
      dataIndex: "ticket_code",
      width: 20,
      __excelTitle__: "Mã đặt chỗ",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Tài khoản thao tác",
      dataIndex: "user",
      width: 30,
      __excelTitle__: "Tài khoản thao tác",
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Số tiền GD",
      __excelTitle__: "Số tiền GD",
      dataIndex: "price",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Tiền BH",
      __excelTitle__: "Tiền BH",
      dataIndex: "isurrance",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
    {
      title: "Số dư",
      dataIndex: "main_agent_balance",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Số dư",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        h: "right",
      },
    },
  ];

  const handleExportExcel = async () => {
    const response: any = await dispatch(
      getListReportBalanceChangeMainAgentExcel({
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        content: watchContent?.label ? watchContent?.value : watchContent,
      })
    );
    if (!response?.error) {
      const listData = response?.payload?.data;

      const listDataExcel =
        listData?.length > 0
          ? listData?.map((report: DataReport) => ({
              date: report?.date
                ? `${moment(report?.date).format("DD/MM/YYYY - HH:mm:ss")}`
                : "N/A",
              content: report?.content ? report?.content : "N/A",
              ticket_code: report?.ticket_code ? report?.ticket_code : "N/A",
              user: report?.user?.name ? report?.user?.name : "N/A",
              price: report?.status
                ? report?.price
                  ? `+${formatPrice(report?.price, 0, ",")}`
                  : 0
                : report?.price
                ? `-${formatPrice(report?.price, 0, ",")}`
                : 0,
              isurrance: report?.isurrance
                ? `-${formatPrice(report?.isurrance, 0, ",")}`
                : 0,
              main_agent_balance: report?.main_agent_balance
                ? Number(report?.main_agent_balance)
                : 0,
            }))
          : [];
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Báo cáo biến động số dư");
      const header = columnsExport.map((col) => ({
        header: col.title,
        key: col.dataIndex as string,
      }));
      worksheet.columns = header;
      columnsExport.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width || 15;
        worksheet.getColumn(index + 1).alignment = {
          horizontal:
            (col?.__style__?.h as
              | "center"
              | "left"
              | "right"
              | "fill"
              | "justify") || "left",
          vertical: "middle",
        };
        worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || "general";
      });
      worksheet.addRows(listDataExcel);
      worksheet.getRow(1).font = {
        name: "Times New Roman",
        size: 12,
        bold: true,
      };
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (colNumber < 8) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "3AB54A" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
      let firstRowHasData = false;
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (cell.value) {
          firstRowHasData = true;
          return;
        }
      });
      if (firstRowHasData) {
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber > 1) {
            row.eachCell((cell) => {
              cell.font = { name: "Times New Roman", size: 12 };
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          }
        });
      } else {
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            let hasData = false;
            row.eachCell((cell) => {
              if (cell.value) {
                hasData = true;
                return;
              }
            });
            if (hasData) {
              row.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        });
      }
      const startColumnIndex = 1;

      const lastRow = worksheet.addRow([]);
      lastRow.getCell(1).value = "@Bản quyền thuộc nhà xe Carlink";
      lastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      lastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };

      const endColumnIndex = startColumnIndex + columnsExport.length - 1;
      worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Báo cáo biến động số dư.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const handleGetLabelContent = (content: string) => {
    const contentCurrent = optionsContent.find(
      (time) => time.value === content
    );
    return contentCurrent?.label || "Tất cả";
  };
  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return (
          Math.abs(current.diff(from, "days")) >=
          systemConfig?.export_report_month
        );
      }
      return false;
    },
    [systemConfig?.export_report_month]
  );

  return (
    <div className="Report bg-white">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          range_date_report: [
            listFilter?.from
              ? dayjs(listFilter?.from, "DD/MM/YYYY")
              : undefined,
            listFilter?.to ? dayjs(listFilter?.to, "DD/MM/YYYY") : undefined,
          ],
          content:
            listFilter?.content === ""
              ? { value: listFilter?.content, label: "Nội dung" }
              : listFilter?.content,
        }}
      >
        <Row gutter={12}>
          <Col className="Report__rangeDateReport">
            <Form.Item<FieldType>
              name="range_date_report"
              label="Khoảng thời gian"
              className="required"
              rules={[
                { required: true, message: "Vui lòng chọn khoảng thời gian" },
              ]}
            >
              <RangePicker
                format="DD/MM/YYYY"
                disabledDate={disabledDaysDate}
                onChange={onChangeTimeReport}
                popupClassName="Report__timeReport__picker"
                placeholder={["Từ ngày", "Đến ngày"]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex align="center" gap={"small"} className="mb-24">
          <Image
            preview={false}
            width={20}
            height={20}
            className="d-flex"
            src={IconExclamationCircle}
            alt="Icon Exclamation Circle"
          />
          <Typography.Text className="fs-14 fw-500 text-color">
            Vui lòng chọn khoảng thời gian để xem báo cáo
          </Typography.Text>
        </Flex>
        <Typography.Text className="fs-6 fw-500 text-color d-block mb-8">
          Bộ lọc nâng cao
        </Typography.Text>
        <Flex gap={"small"}>
          <Form.Item<FieldType> className="Report__options" name="content">
            <Select
              onChange={handleChangeContent}
              options={optionsContent}
              popupClassName="Report__popup"
            />
          </Form.Item>
        </Flex>
        <Flex align="center" justify="space-between">
          <Form.Item className="Report__submit">
            <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
              <Button
                disabled={
                  timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)
                    ? false
                    : true
                }
                className="d-flex align-items-center h-auto"
                type="primary"
                htmlType="submit"
              >
                <IconFileExport width={16} height={16} />
                Xem báo cáo
              </Button>
            </ConfigProvider>
          </Form.Item>
          <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 2)} />
        </Flex>
        <Divider />
        <Flex>
          <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
            <Button
              ghost
              disabled={
                timeReport?.length === 2 || (listFilter?.from && listFilter?.to)
                  ? false
                  : true
              }
              onClick={handleExportExcel}
              className={`Report__exportExcel d-flex align-items-center mr-12 h-auto ${
                timeReport?.length === 2 || (listFilter?.from && listFilter?.to)
                  ? ""
                  : "disabled"
              }`}
              type="primary"
            >
              <IconExportExcel
                color={`${
                  timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)
                    ? "#435869"
                    : "#949494"
                }`}
                width={16}
                height={16}
              />
              Xuất Excel
            </Button>
          </ConfigProvider>
        </Flex>
        <Typography.Text className="d-block text-color-gray-3A3A3A fs-18 fw-600 mt-24 mb-8">
          Báo cáo biến động số dư
        </Typography.Text>
        <Flex wrap="wrap" align="center" className="Report__filter">
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Từ ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[0]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Đến ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[1]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          <Flex align="center" className="Report__filter__item">
            <Typography.Text className="d-block fs-16 fw-500">
              Nội dung:
            </Typography.Text>
            <Typography.Text className="d-block fs-16 fw-600">
              {handleGetLabelContent(watchContent)}
            </Typography.Text>
          </Flex>
        </Flex>
      </Form>
      <Table
        className="Report__table"
        locale={{
          triggerDesc: "Sắp xếp tăng dần",
          triggerAsc: "Hủy sắp xếp",
          cancelSort: "Sắp xếp giảm dần",
        }}
        pagination={{
          total: pagination?.total,
          current: page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ["bottomRight"],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        dataSource={listReport?.length > 0 ? listReport : []}
        loading={loadingListReport}
        columns={columns}
      />
    </div>
  );
};

export default ReportBalanceChangeMainAgent;
