import { getAxiosClientWithToken } from './index';

export const getListDashboardBooking = (params:any) => {
    return getAxiosClientWithToken().get(`/dashboard/bookings?${params}`);
}

export const getListDashboardUser = (params:any) => {
    return getAxiosClientWithToken().get(`/dashboard/users?${params}`);
}

export const getListDashboardCompany = (params:any) => {
    return getAxiosClientWithToken().get(`/dashboard/companies?${params}`);
}

export const getListDashboardAgent = (params:any) => {
    return getAxiosClientWithToken().get(`/dashboard/agents?${params}`);
}

export const getListDashboardRoute = (params:any) => {
    return getAxiosClientWithToken().get(`/dashboard/routes?${params}`);
}