import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  SelectProps,
  Switch,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/styles/desktop/typeBus.scss";
import { formatNumber } from "../../components/PriceInput2";
import PriceInputGroupSeat from "../../components/PriceInputGroupSeat";
import SeatMapRender from "../../components/SeatMapRender";
import { Constant, DRIVER, EXTRA_DRIVER } from "../../config/constant";
import { Message } from "../../config/message";
import { convertOptionCheckBox } from "../../lib/utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchListBus, listBusSelector } from "../../redux/slice/buses/bus";
import {
  getAllAvailableBusType,
  listBusTypeSelector,
} from "../../redux/slice/busTypes/listBusTypeSlice";
import {
  driverSelector,
  fetchListDriver,
  fetchListExtraDriver,
} from "../../redux/slice/driverSlice";
import { fetchListRoute, routeSelector } from "../../redux/slice/routeSlice";
import "./Schedules.scss";

// @ts-ignore
function ModalTab1({ form, detailSchedule, isDetail }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { buses } = useAppSelector(listBusSelector);
  const { drivers, extra_drivers } = useAppSelector(driverSelector);
  const { loading: loadingRoute } = useAppSelector(routeSelector);
  const { listAllRoute } = useAppSelector(routeSelector);
  const { listAllAvailableBusType } = useAppSelector(listBusTypeSelector);
  const [keyword, setKeyword] = useState<any>({});
  const [seatRender, setSeatRender] = useState<any>();
  const [groupSeat, setGroupSeat] = useState<any>();
  const [keywordBus, setKeywordBus] = useState<any>({});
  const [allSeatName, SetAllSeatName] = useState<any>();
  const [seatData, SetSeatData] = useState<any>();
  const isDiagram = Form.useWatch("is_diagram", form);
  const watchIsContract = Form.useWatch("is_contract", form);
  const [keywordDriver, setKeywordDriver] = useState<any>();
  const [KeywordBusList, setKeywordBusList] = useState<any>();
  const [selectBus, setSelectBus] = useState<undefined | string>(undefined);
  const [selectDriver, setSelectDriver] = useState<undefined | string>(
    undefined
  );
  const [selectExtraDriver, setSelectExtraDriver] = useState<
    undefined | string
  >(undefined);
  // const [priceCommon, setPriceCommon] = useState<any>('');
  const [keywordExtraDriver, setKeywordExtraDriver] = useState<any>();
  const autoOperation = Form.useWatch("auto_operated", form);

  const onChangeOptionSeat = Form.useWatch("agent_seats");
  const typeBusChange = Form.useWatch("bus_type_id");

  const findColor = (keyIndex: any) => {
    const item = groupSeat?.find((_: any, index: any) => +index === +keyIndex);
    return item ? item?.group_color : null;
  };

  const RouteRules = [
    {
      required: true,
      message: t(Message.ROUTE),
    },
  ];

  const BusTypeRules = [
    {
      required: true,
      message: t("Vui lòng chọn loại xe"),
    },
  ];
  function onSearchRoute(value: any) {
    setKeyword(value);
  }

  const filterOptionRoute = (
    input: string,
    option?: {
      label: string;
      value: string;
    }
  ) => (option?.label ?? "").toLowerCase()?.includes(input.toLowerCase());

  function onChangeGroup(values: any, option: any) {
    form.setFieldsValue({ agent_seats: [] });
    form.setFieldsValue({ seat_count: option?.seatMap?.seat_number });
    setSeatRender(option?.seatMap);
    setGroupSeat(option?.seatMap?.group_seat);
    let seatDataOption: any = [];
    let dataOption: any = [];
    option?.seatMap?.group_seat?.map((item: any) => {
      return (seatDataOption = [...seatDataOption, ...item.selected_seat]);
    });
    SetSeatData(seatDataOption);
    convertOptionCheckBox(dataOption, [...seatDataOption]?.sort());
    SetAllSeatName(dataOption);
    form.setFieldsValue({ price_json: [{ name: "", seat_price: "" }] });
    const currentValues = form.getFieldValue("price_json");
    option?.seatMap?.group_seat?.length > 0 &&
      option.seatMap.group_seat.map((item: any, index: number) => {
        return (currentValues[index] = {
          name: item.name,
          seat_price: 0,
        });
      });
    form.setFieldsValue({
      price_json: currentValues,
    });
    form.setFieldsValue({ agent_seats: seatDataOption });
  }

  //BUS TYPE
  function onSearchBusType(value: any) {
    setKeywordBus(value);
  }
  function onSearchBus(value: any) {
    setKeywordBusList(value);
  }
  const handleChangeBus = (value: string, option: any) => {
    setSelectBus(value);
    form.setFieldsValue({
      bus_id: option?.value,
    });
  };
  const filterOptionBus = (
    input: string,
    option?: {
      label?: any;
      value?: any;
      seatMap?: any;
    }
  ) => (option?.label ?? "").toLowerCase()?.includes(input.toLowerCase());

  //DRIVER
  const onSearchDriver = (value: string) => {
    setKeywordDriver(value);
  };
  const filterOptionDriver = (
    input: string,
    option?: {
      label: string;
      value: string;
      date_of_birth: string;
      phone: string;
      date_expired_license: string;
      license_class: string;
    }
  ) => (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());
  const handleChangeDriver = (value: string, option: any) => {
    setSelectDriver(value);
    form.setFieldsValue({
      driver_id: value,
    });
  };

  const BusOptions: SelectProps["options"] = listAllAvailableBusType?.length
    ? listAllAvailableBusType?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
          seatMap: item?.seat_map,
        };
      })
    : [];

  //EXTRA DRIVERS
  const handleChangeExtraDriver = (value: string, option: any) => {
    setSelectExtraDriver(value);
    form.setFieldsValue({
      extra_driver_id: value,
    });
  };
  const onSearchExtraDriver = (value: string) => {
    setKeywordExtraDriver(value);
  };

  const onFiedDetail = (values: any) => {
    setSeatRender(values?.bus_type?.seat_map);
    setGroupSeat(values?.bus_type?.seat_map?.group_seat);
    let seatDataOption: any = [];
    let dataOption: any = [];
    values?.bus_type?.seat_map?.group_seat?.map((item: any) => {
      return (seatDataOption = [...seatDataOption, ...item?.selected_seat]);
    });
    SetSeatData(seatDataOption);
    convertOptionCheckBox(dataOption, [...seatDataOption].sort());
    SetAllSeatName(dataOption);
    form.setFieldsValue({
      route_id: values?.route.id,
      bus_type_id: values?.bus_type.id,
      seat_count: values?.bus_type?.seat_map?.seat_number,
      note: values?.note,
      agent_seats: values?.agent_seats,
      is_diagram: values?.is_diagram,
      is_online: values?.is_online,
      price_common: values?.price_json[0]?.seat_price,
    });
    const currentValues = form.getFieldValue("price_json");
    values?.price_json?.length > 0 &&
      values?.price_json?.map((item: any, index: number) => {
        return (currentValues[index] = {
          name: item?.name,
          seat_price: formatNumber(item?.seat_price),
        });
      });
    form.setFieldsValue({
      price_json: currentValues,
    });
  };
  useEffect(() => {
    if (isDetail) {
      onFiedDetail(detailSchedule);
    }
  }, [isDetail]);

  useEffect(() => {
    dispatch(getAllAvailableBusType({ keyword: keywordBus }));
  }, [dispatch, keywordBus]);

  useEffect(() => {
    if (!typeBusChange) {
      setSeatRender({});
      SetAllSeatName([]);
    }
  }, [typeBusChange]);

  useEffect(() => {
    dispatch(fetchListRoute({ keyword: keyword, status: true }));
  }, [dispatch, keyword]);
  useEffect(() => {
    dispatch(
      fetchListDriver({
        keyword: keywordDriver,
        status: Constant.STATUS_ACTIVE,
        type: DRIVER,
      })
    );
  }, [dispatch, keywordDriver]);

  useEffect(() => {
    dispatch(
      fetchListExtraDriver({
        keyword: keywordExtraDriver,
        status: Constant.STATUS_ACTIVE,
        type: EXTRA_DRIVER,
      })
    );
  }, [dispatch, keywordExtraDriver]);
  useEffect(() => {
    dispatch(
      fetchListBus({
        keyword: KeywordBusList,
        bus_type_id: typeBusChange,
        status: true,
      })
    );
  }, [dispatch, KeywordBusList, typeBusChange]);
  const onToggleIsBooking = (checked: boolean) => {
    form.setFieldsValue({ is_diagram: checked });
  };

  return (
    <div className="ModalTab1">
      <Row gutter={20}>
        <Col span={6}>
          <span className="required pb-2">{t(`Tuyến`)}</span>
          <Form.Item
            name="route_id"
            className="form-item-name"
            validateTrigger={["onChange", "onBlur"]}
            rules={RouteRules}
          >
            <Select
              className="input-h36 mt-1 w-100"
              loading={loadingRoute}
              showSearch
              placeholder="Chọn tuyến"
              onSearch={onSearchRoute}
              filterOption={filterOptionRoute}
              size="large"
              options={
                listAllRoute?.length
                  ? listAllRoute?.map((item) => {
                      return {
                        value: item?.id,
                        label: `${item?.depart_point} - ${item?.arrive_point}`,
                      };
                    })
                  : []
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <span className="required mb-2">{t(`Chọn loại xe`)}</span>
          <Form.Item
            name="bus_type_id"
            className="form-item-name required  mt-1"
            validateTrigger={["onChange", "onBlur"]}
            rules={BusTypeRules}
          >
            <Select
              className="input-h36"
              showSearch
              placeholder="Loại xe"
              onChange={(value: any, option: any) =>
                onChangeGroup(value, option)
              }
              onSearch={onSearchBusType}
              filterOption={filterOptionBus}
              options={BusOptions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={3}>
          <span className="required mb-2">{t(`Số chỗ`)}</span>
          <Form.Item
            name="seat_count"
            className="form-item-name required  mt-1"
            validateTrigger={["onChange", "onBlur"]}
          >
            <Input autoComplete="off" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row
        className="d-flex"
        style={{ alignItems: "center", columnGap: "8px" }}
      >
        <Form.Item
          name="auto_operated"
          className="form-item-name required"
          valuePropName="checked"
          validateTrigger={["onChange", "onBlur"]}
        >
          <Switch className="mt-3" defaultChecked />
        </Form.Item>
        <Typography>{t("Tự động điều hành")}</Typography>
      </Row>
      {autoOperation && (
        <Row gutter={20}>
          <Col xs={10} sm={10} md={5}>
            <span className="required">{t(`Biển số xe`)}</span>
            <Form.Item
              name="plate_number"
              className="fw-500"
              rules={[
                {
                  required: true,
                  message: t("Vui lòng chọn xe"),
                },
              ]}
            >
              <Select
                showSearch
                onChange={handleChangeBus}
                value={selectBus}
                placeholder={t("Nhập biển số xe")}
                onSearch={onSearchBus}
                filterOption={filterOptionBus}
                className="mt-1"
                options={
                  buses?.length > 0
                    ? buses?.map((item: any) => ({
                        value: item?.id,
                        label: item?.plate_number,
                        bus_type: item?.bus_type?.name,
                        bus_name: item?.name,
                      }))
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <span className="required">{t(`Chọn tài xế`)}</span>
            <Form.Item
              name="driver_id"
              rules={[{ required: true, message: "Vui lòng chọn tài xế" }]}
            >
              <Select
                showSearch
                onChange={(value: any, option: any) =>
                  handleChangeDriver(value, option)
                }
                value={selectBus}
                placeholder={t("Chọn tài xế")}
                onSearch={onSearchDriver}
                className="mt-1 "
                filterOption={filterOptionDriver}
                options={
                  drivers?.length > 0
                    ? drivers?.map((item: any) => ({
                        value: item?.id,
                        label: item?.name,
                        date_of_birth: item?.date_of_birth,
                        phone: item?.phone,
                        date_expired_license: item?.date_expired_license,
                        license_class: item?.license_class,
                      }))
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <span>{t(`Chọn phụ xe`)}</span>
            <Form.Item name="extra_driver_id">
              <Select
                showSearch
                onChange={(value: any, option: any) =>
                  handleChangeExtraDriver(value, option)
                }
                value={selectBus}
                className="mt-1"
                placeholder={t("Chọn phụ xe")}
                onSearch={onSearchExtraDriver}
                filterOption={filterOptionDriver}
                options={
                  extra_drivers?.length > 0
                    ? extra_drivers?.map((item: any) => ({
                        value: item?.id,
                        label: item?.name,
                        date_of_birth: item?.date_of_birth,
                        phone: item?.phone,
                        date_expired_license: item?.date_expired_license,
                        license_class: item?.license_class,
                      }))
                    : []
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item name="bus_id" className={"d-none"}>
        <Input disabled />
      </Form.Item>
      <Row
        className="d-flex"
        style={{ alignItems: "center", columnGap: "8px" }}
      >
        <Form.Item
          name="is_online"
          className="form-item-name required"
          valuePropName="checked"
          validateTrigger={["onChange", "onBlur"]}
        >
          <Switch className="mt-3" defaultChecked />
        </Form.Item>
        <Typography>Cho phép bán online trên các đối tác Carlink</Typography>
      </Row>
      <Row
        className="d-flex"
        style={{ alignItems: "center", columnGap: "8px" }}
      >
        <Form.Item
          name="pay_later"
          className="form-item-name required"
          valuePropName="checked"
          validateTrigger={["onChange", "onBlur"]}
        >
          <Switch className="mt-3" defaultChecked />
        </Form.Item>
        <Typography>Cho phép thanh toán tại nhà xe</Typography>
      </Row>
      <Row
        className="d-flex"
        style={{ alignItems: "center", columnGap: "8px" }}
      >
        <Form.Item
          name="is_diagram"
          className="form-item-name required"
          valuePropName="checked"
          validateTrigger={["onChange", "onBlur"]}
        >
          <Switch
            onClick={onToggleIsBooking}
            className=" mt-3"
            defaultChecked
            // disabled={isDetail}
          />
        </Form.Item>
        <Typography>{t("Cho phép chọn chỗ trên sơ đồ")}</Typography>
      </Row>
      {seatRender?.count_floor && (
        <>
          {isDiagram && (
            <div className="seat-render-custom">
              <SeatMapRender
                column={seatRender?.column ?? 0}
                row={seatRender?.row ?? 0}
                seatCount={seatRender?.count_floor}
                seatMatrixData={seatRender}
              />
            </div>
          )}
        </>
      )}

      <Form.List name="price_json">
        {(fields) => (
          <div className={isDiagram ? `` : "d-none"}>
            {fields?.map(({ key, name }) => (
              <Row gutter={24} className="" key={key}>
                <Col xs={24} md={5}>
                  <span
                    className={`first-color-block json ${findColor(key)} `}
                  ></span>
                  <span className="">{t(`Nhóm ghế`)}</span>
                  <Form.Item
                    name={[name, "name"]}
                    className="form-item-name required group-input color"
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} md={5}>
                  <span className="required mb-2">{t(`Giá nhóm ghế`)}</span>
                  <PriceInputGroupSeat name={name} form={form} />
                </Col>
              </Row>
            ))}
          </div>
        )}
      </Form.List>
      <Col xs={24} md={6}>
        <div className={`${!isDiagram ? `` : "d-none"}`}>
          <span className={`required mb-2`}>{t(`Giá chung`)}</span>
          <Form.Item
            className="form-item-name form-item-name--price_common require price mt-2"
            name="price_common"
            dependencies={["is_diagram"]}
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!getFieldValue("is_diagram")) {
                    if (value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Vui lòng nhập giá chung")
                      );
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <InputNumber<number>
              className="w-100"
              addonAfter="VND"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              controls={false}
              placeholder="Nhập giá chung"
              parser={(value) =>
                value?.replace(/\$\s?|(,*)/g, "") as unknown as number
              }
            />
          </Form.Item>
        </div>
      </Col>

      {allSeatName && (
        <Row gutter={24} className="mt-3">
          {isDiagram && (
            <Col>
              <span className="mb-2 me-3">Ghế mở bán cho đại lý</span>
              {onChangeOptionSeat?.length > 0 &&
              onChangeOptionSeat?.length === seatData?.length ? (
                <Button
                  onClick={() => {
                    form.setFieldsValue({ agent_seats: [] });
                  }}
                >
                  Hủy chọn
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    form.setFieldsValue({ agent_seats: seatData });
                  }}
                  disabled={!isDiagram}
                >
                  Chọn tất cả
                </Button>
              )}
              <Form.Item
                name="agent_seats"
                className="form-item-name required mt-4"
              >
                <Checkbox.Group
                  className=""
                  options={allSeatName}
                  disabled={!isDiagram}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      )}

      <Flex align="center" gap="small">
        <Form.Item
          name="is_contract"
          className="form-item-name required"
          validateTrigger={["onChange", "onBlur"]}
        >
          <Switch className="mt-3" />
        </Form.Item>
        <Typography>Cho thuê xe hợp đồng</Typography>
      </Flex>

      <Col xs={24} md={6}>
        <div className={`${watchIsContract ? `` : "d-none"}`}>
          <span className={`required mb-2`}>Giá hợp đồng</span>
          <Form.Item
            className="form-item-name form-item-name--price_common require price mt-2"
            name="contract_value"
            dependencies={["is_contract"]}
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("is_contract")) {
                    if (value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Vui lòng nhập giá hợp đồng")
                      );
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <InputNumber<number>
              className="w-100"
              addonAfter="VND"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              controls={false}
              placeholder="Nhập giá hợp đồng"
              parser={(value) =>
                value?.replace(/\$\s?|(,*)/g, "") as unknown as number
              }
            />
          </Form.Item>
        </div>
      </Col>

      <Row gutter={24}>
        <Col xs={24} md={24}>
          <Form.Item
            name="note"
            className="form-item-name required"
            label={t("Ghi chú")}
            validateTrigger={["onChange", "onBlur"]}
          >
            <TextArea
              maxLength={100}
              placeholder=""
              style={{ height: 200, resize: "none" }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default ModalTab1;
