import { createNotify } from "../types/notify";
import { getAxiosClient, getAxiosClientWithToken} from "./index";

export const getListNotifyAPI = (params: any) => {
    return getAxiosClientWithToken().get(`/notification/list?${params}`);
};
export const getListNotificationAPI = (params: any) => {
    return getAxiosClientWithToken().get(`/notification?${params}`);
};

export const createNotifyApi = (params: createNotify) => {
    return getAxiosClient.post(`/notification`, params);
};

// export const createUserAPI = (params: UserParams) => {
//     // const formData = handleFormData(params);
//     return getAxiosClientWithToken().post(`/user`, params);
// };

export const updateNotifyAPI = (id: any, params: any) => {
    return getAxiosClientWithToken().put(`/notification/${id}`, params);
};  

export const deleteNotifyAPI = (id: any) => {
    return getAxiosClientWithToken().delete(`/notification/${id}`);
}; 

export const registerConfirmAPI = (params: any) => {
    return getAxiosClient.post(`/register/confirm`, params);
};  
