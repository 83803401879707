import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getListRegisterApi } from "../../../api/listRegister";

// First, create the thunk
export const fetchListRegister = createAsyncThunk(
  "register/fetchListRegister",
  async ({ query }: { query: string }) => {
    try {
      const response = await getListRegisterApi(query);
      return response.data;
    } catch (error) {
      
    }
  }
);

const initialState: any = {
  isFetching: true,
  pagination: {
    total: 0,
    per_page: 10,
    count: 0,
    current_page: 1,
    last_page: 1,
  },
  result: [],
  total: "0"
};

export const listRegisterSlide = createSlice({
  name: "register",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchListRegister.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(fetchListRegister.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(fetchListRegister.fulfilled, (state, action) => {
      state.isFetching = false;
      state.pagination = action.payload?.pagination;
      state.result = action.payload?.data;
      state.total = action.payload?.total;
   
    });
  },
});

export const {} = listRegisterSlide.actions;

export default listRegisterSlide.reducer;
