import { Image, Modal, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import type { TabsProps } from "antd";
import { Button, Col, Spin, Tabs } from "antd";
import "../../../assets/styles/app.scss";
import "../../../assets/styles/editUser.scss";
import { useTranslation } from "react-i18next";
import "../../../config/i18n/i18n";
import { listUserSelector } from "../../../redux/slice/userSlice";
import { useAppSelector } from "../../../redux/hooks";
import CreateInfoUserContact from "./Tabs/CreateInfoUserContact";
import CreateUserPermissions from "./Tabs/CreateUserPermissions";
import CreateInfoUser from "./Tabs/CreateInfoUser";
import "../../../assets/styles/editUser.scss";
import { createUserAPI, getPermissions } from "../../../api/user";
import IconClose from "../../../assets/images/listUser/iconsClose.svg";
import { checkAuthorization } from "../../../api";
import {
  ACTION_NAME,
  messageCreateSuccess,
  messageWarning,
} from "../../../config/message";
import { Constant, USER_GROUP } from "../../../config/constant";
import TutorialsVideo from "../../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../../utils/helpers";

const CreateUser: React.FC<any> = ({
  open,
  setOpen,
  callListUserApi,
  infoUser,
  listTutorials
}) => {
  const { t } = useTranslation();
  const { loading } = useAppSelector(listUserSelector);
  const [detailUser, setDetailUser] = useState<any>({
    username: "",
    group: infoUser?.group,
    permissions: [],
  });
  const [activeKey, setActiveKey] = useState<string>("1");
  const [dataPermissions, setDataPermissions] = useState<any>({});
  const [activeOne, setActiveOne] = useState(false);
  const [activeThree, setActiveThree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnchangeInput = (e: any) => {
    let { name, value } = e.target;
    setDetailUser({ ...detailUser, [name]: value });
  };

  const onChangeContinue = (key: string) => {
    setActiveKey(key);
  };

  const callCreateUserAPI = async () => {
    setIsLoading(true);
    const formDataUser =
      infoUser.group === USER_GROUP.AGENT
        ? { agent_id: infoUser?.agent?.id, ...detailUser }
        : detailUser;
    if (!detailUser?.birth) {
      delete formDataUser?.birth;
    }
    if(formDataUser?.permissions[0] === "driver") {
      formDataUser.role = "driver"
    }
    if(formDataUser.group === USER_GROUP.MAIN_AGENT && infoUser?.main_agent?.id) {
      formDataUser.main_agent_id = infoUser?.main_agent?.id
    }
    try {
      const response = await createUserAPI(formDataUser);
      const createdUser = response.data.success;
      if (createdUser) {
        callListUserApi();
        messageCreateSuccess(ACTION_NAME.USER);
        setOpen(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
    setIsLoading(false);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("Thông tin cơ bản"),
      children: (
        <CreateInfoUser
          detailUser={detailUser}
          handleOnchangeInput={handleOnchangeInput}
          setDetailUser={setDetailUser}
          activeOne={activeOne}
          setActiveKey={setActiveKey}
          setActiveOne={setActiveOne}
          infoUser={infoUser}
        />
      ),
    },
    {
      key: "2",
      label: t("Phân quyền"),
      children: (
        <CreateUserPermissions
          dataPermissions={dataPermissions}
          detailUser={detailUser}
          setDetailUser={setDetailUser}
          infoUser={infoUser}
        />
      ),
    },
    {
      key: "3",
      label: t("Thông tin liên hệ"),
      children: (
        <CreateInfoUserContact
          detailUser={detailUser}
          handleOnchangeInput={handleOnchangeInput}
          setDetailUser={setDetailUser}
          setActiveThree={setActiveThree}
          activeThree={activeThree}
          callCreateUserAPI={callCreateUserAPI}
          infoUser={infoUser}
        />
      ),
    },
  ];

  const itemsCarCompany: TabsProps["items"] = [
    {
      key: "1",
      label: t("Thông tin cơ bản"),
      children: (
        <CreateInfoUser
          detailUser={detailUser}
          handleOnchangeInput={handleOnchangeInput}
          setDetailUser={setDetailUser}
          activeOne={activeOne}
          setActiveKey={setActiveKey}
          setActiveOne={setActiveOne}
          infoUser={infoUser}
        />
      ),
    },
    {
      key: "2",
      label: t("Thông tin liên hệ"),
      children: (
        <CreateInfoUserContact
          detailUser={detailUser}
          handleOnchangeInput={handleOnchangeInput}
          setDetailUser={setDetailUser}
          setActiveThree={setActiveThree}
          activeThree={activeThree}
          callCreateUserAPI={callCreateUserAPI}
          infoUser={infoUser}
        />
      ),
    },
  ];

  const callApiPermissions = async () => {
    try {
      const res = await getPermissions();
      if (res.status === Constant.DEFAULT_STATUS) {
        setDataPermissions(res.data.data);
      }
    } catch (error) {
      checkAuthorization(error);
    }
  };

  useEffect(() => {
    callApiPermissions();
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleBack = () => {
    const currentIndex = items.findIndex((item) => item.key === activeKey);
    const prevIndex = (currentIndex - 1 + items.length) % items.length;
    const prevKey = items[prevIndex].key;
    onChangeContinue(prevKey);
  };

  const handleSubmitForm = () => {
    switch (activeKey) {
      case "1":
        setActiveOne(true);
        break;
      case "2":
        if (
          [
            USER_GROUP.CARLINK,
            USER_GROUP.CAR_COMPANY,
            USER_GROUP.AGENT,
            USER_GROUP.MAIN_AGENT
          ].includes(infoUser?.group) &&
          detailUser?.group === infoUser?.group &&
          detailUser?.permissions.length > 0
        ) {
          setActiveKey("3");
        } else if (
          ![
            USER_GROUP.CARLINK,
            USER_GROUP.CAR_COMPANY,
            USER_GROUP.AGENT,
            USER_GROUP.MAIN_AGENT
          ].includes(infoUser?.group) ||
          detailUser?.group !== infoUser?.group
        ) {
          setActiveThree(true);
        } else {
          messageWarning(ACTION_NAME.PERMISSION);
        }
        break;
      case "3":
        setActiveThree(true);
        break;
      default:
        // Xử lý trường hợp mặc định nếu cần
        break;
    }
  };

  const tabGroups = () => {
    if (infoUser?.group === USER_GROUP.CARLINK) {
      return detailUser?.group === USER_GROUP.CARLINK ? items : itemsCarCompany;
    } else if (infoUser?.group === USER_GROUP.CAR_COMPANY) {
      return detailUser?.group === USER_GROUP.CAR_COMPANY
        ? items
        : itemsCarCompany;
    } else if (infoUser?.group === USER_GROUP.AGENT) {
      return detailUser?.group === USER_GROUP.AGENT ? items : itemsCarCompany;
    } else if (infoUser?.group === USER_GROUP.MAIN_AGENT) {
      return detailUser?.group === USER_GROUP.MAIN_AGENT ? items : itemsCarCompany;
    }
  };

  const handleNumberKey = () => {
    if (infoUser?.group === USER_GROUP.CARLINK) {
      return detailUser?.group === USER_GROUP.CARLINK ? "3" : "2";
    } else if (infoUser?.group === USER_GROUP.CAR_COMPANY) {
      return detailUser?.group === USER_GROUP.CAR_COMPANY ? "3" : "2";
    } else if (infoUser?.group === USER_GROUP.AGENT) {
      return detailUser?.group === USER_GROUP.AGENT ? "3" : "2";
    } else if (infoUser?.group === USER_GROUP.MAIN_AGENT) {
      return detailUser?.group === USER_GROUP.MAIN_AGENT ? "3" : "2";
    }
  };

  return (
    <div className="wrapper-edit-user">
      <Modal
        open={open}
        maskClosable={false}
        confirmLoading={isLoading}
        title={
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text className="fw-600 fs-18 ">
              {t("Tạo tài khoản mới")}
            </Typography.Text>
            <Space size={10} align="center">
              <TutorialsVideo
                url_youtube={handleGetUrlGuide(listTutorials, 10)}
              />
              <Image
                src={IconClose}
                style={{ cursor: "pointer" }}
                preview={false}
                onClick={handleCancel}
              />
            </Space>
          </Row>
        }
       
        onCancel={handleCancel}
        className="modal-edit-user"
        cancelText="Đóng" // Đổi tên nút Cancel
        width={980}
        closable={false}
        footer={(_, { OkBtn, CancelBtn }: any) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
            }}
            className="footer-edit-modal-user"
          >
            {/* <div className="modal-btn-user">
              <CancelBtn className="modal-cancel-btn" />
            </div> */}
            <>
              <Col className="block-user d-flex gap-12">
                <Button
                  onClick={handleBack}
                  disabled={activeKey === "1"}
                  htmlType="button"
                >
                  {t("Trở lại")}
                </Button>
                <Button
                  loading={isLoading}
                  className="btn-active-green"
                  danger
                  type="primary"
                  htmlType="button"
                  onClick={handleSubmitForm}
                >
                  {activeKey === handleNumberKey()
                    ? t("Tạo tài khoản")
                    : t("Tiếp tục")}
                </Button>
              </Col>
            </>
          </div>
        )}
      >
        <Spin spinning={loading}>
          <Tabs
            type="card"
            className="edit-user"
            defaultActiveKey="1"
            items={tabGroups()}
            activeKey={activeKey}
            onChange={onChangeContinue}
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default CreateUser;
