/* eslint-disable react-hooks/exhaustive-deps */
import { Layout } from "antd";
import DepositAgent from "./DepositAgent";
import DepositCompany from "./DepositCompany";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import { fetchAccount } from "../../redux/slice/auth/accountSlice";
function AccountDeposit() {
  const accountUser = useAppSelector((state) => state.authAccount?.user);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchAccount());
  }, []);

  const handleCheckAccountGroup = () => {
    if (accountUser?.group === "agent") {
      return <DepositAgent />;
    }
    if (accountUser?.group === "car_company") {
      return <DepositCompany />;
    }
    return <></>;
  };
  return (
    <Layout className="wrapper-account-deposit">
      {handleCheckAccountGroup()}
    </Layout>
  );
}

export default AccountDeposit;
