import { InfoCircleFilled } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { Rule } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconBookingAgent from '../../assets/images/ic-booking-agent.svg';
import iconCancelPinCode from '../../assets/images/ic-cancel-pincode.svg';
import iconNumpad from '../../assets/images/ic-numpad.svg';
import '../../assets/styles/desktop/busBooking.scss';
import ModalInfoInsurance from '../../components/Modal/ModalInfoInsurance';
import ModalNumpad from '../../components/Modal/ModalNumpad';
import { Toast, USER_GROUP } from '../../config/constant';
import { validateMobileUser } from '../../config/validators/validateUser';
import { convertTimeFormat, filterOption, formattedPrice, getPaymentMethod } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { listAgentSelector } from '../../redux/slice/agents/listAgentSlice';
import { selectAuth } from '../../redux/slice/authSlice';
import { createBookingSelector, setPinCode } from '../../redux/slice/bookings/createBookingSlice';
import { listBookingSelector } from '../../redux/slice/bookings/listBooking';
import { setToast } from '../../redux/slice/toastNotifySlice';
import {
  agentBalanceOption,
  bankOption,
  cashOption,
  paymentAgentOptions,
  paymentMultiOptions,
  paymentOptions,
  paymentPaidCompanyOptions,
  validatorEmailBooking,
} from '../../utils/bookingItem';

const ModalBooking1: React.FC<any> = ({
  listBooking,
  isDetail,
  listBookingDetail,
  formBooking,
  isListTicket,
  dataCustomerBooking,
  isMoveTicket,
  dataMoveTicket,
  openModalNumpad,
  setOpenModalNumpad,
}) => {
  const { t } = useTranslation();
  const { listCustomers, errorDetail: errorCreate, statusPin, pin_code, price } = useAppSelector(createBookingSelector);
  const { loadingListBookingDetail } = useAppSelector(listBookingSelector);
  const { group, name } = useAppSelector(selectAuth);
  const { listAgentActive } = useAppSelector(listAgentSelector);
  const [otp, setOtp] = useState<string>('');
  const dispatch = useAppDispatch();
  const [paymentMethod, setPaymentMethod] = useState(undefined);
  const [customerPhone, setCustomerPhone] = useState<string>('');
  const [openModalInfo, setOpenModalInfo] = useState<boolean>(false);
  const isInSurance = Form.useWatch('is_insurance', formBooking);
  const pickUpValue = Form.useWatch('pickup_location_id', formBooking);
  const dropOffValue = Form.useWatch('drop_off_location_id', formBooking);
  const isAgentId = Form.useWatch('is_agent_id', formBooking);
  const agentId = Form.useWatch('agent_id', formBooking);
  const commisionPrice = Form.useWatch('commission_price', formBooking);
  const [commissionPrice, setCommissionPrice] = useState(0);

  const onChangeInsurance = () => {};
  const onChangePayment = ({ target: { value } }: RadioChangeEvent) => {
    setPaymentMethod(value);
  };
  const NameRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập tên'),
    },
    {
      pattern: /^[^\s].*$/,
      message: t('Tên không được chứa chỉ khoảng trắng'),
    },
  ];
  const handleOptionPickup = () => {
    let options: any = [];
    if (dataCustomerBooking && isListTicket) {
      options = options.concat(
        dataCustomerBooking?.transship_depart && isListTicket
          ? dataCustomerBooking?.transship_depart?.map((item: any) => {
              return {
                value: item.id,
                label: `(${convertTimeFormat(item?.pickup_time)}) ${item?.pickup_location_name}`,
              };
            })
          : [],
      );
    } else {
      options = options.concat(
        listBooking?.schedule?.transship_depart
          ? listBooking.schedule.transship_depart.map((item: any) => {
              return {
                value: item.id,
                label: `(${convertTimeFormat(item?.pickup_time)}) ${item?.pickup_location_name}`,
              };
            })
          : [],
      );
    }
    if (
      listBooking?.schedule?.is_depart_transship ||
      listBooking?.contract?.code ||
      dataCustomerBooking?.is_depart_transship
    ) {
      options.push({
        value: 'other',
        label: 'Nhập điểm trung chuyển khác',
      });
    }
    return options;
  };
  const onFillCustomer = (value: string) => {
    if (listCustomers?.length) {
      const selectedCustomer = listCustomers?.find((customer: any) => customer.phone === value);
      if (selectedCustomer) {
        formBooking.setFieldsValue({
          customer_name: selectedCustomer.name,
          customer_email: selectedCustomer.email,
          customer_phone: selectedCustomer.phone,
        });
      }
    }
  };
  const onChangePhone = (keyword: any) => {
    onFillCustomer(keyword);
  };
  const handleInputChange = (value: string) => {
    setCustomerPhone(value);
  };
  const handleOptionDropOff = () => {
    let options: any = [];
    if (dataCustomerBooking && isListTicket) {
      options = options.concat(
        dataCustomerBooking?.transship_arrive && isListTicket
          ? dataCustomerBooking?.transship_arrive?.map((item: any) => {
              return {
                value: item.id,
                label: `(${convertTimeFormat(item?.pickup_time)}) ${item?.pickup_location_name}`,
              };
            })
          : [],
      );
    } else {
      options = options.concat(
        listBooking?.schedule?.transship_arrive
          ? listBooking.schedule.transship_arrive.map((item: any) => {
              return {
                value: item.id,
                label: `(${convertTimeFormat(item?.pickup_time)}) ${item.pickup_location_name}`,
              };
            })
          : [],
      );
    }
    if (
      listBooking?.schedule?.is_arrive_transship ||
      listBooking?.contract?.code ||
      dataCustomerBooking?.is_arrive_transship
    ) {
      options.push({
        value: 'other',
        label: 'Nhập điểm trung chuyển khác',
      });
    }
    return options;
  };
  const listAgentActiveOption = listAgentActive?.length
    ? listAgentActive?.map((item: any) => {
        return {
          commission_percent: item?.commission_percent,
          value: item?.id,
          label: (
            <div className="d-flex items-center gap-x-2">
              <img src={iconBookingAgent} alt="" />
              {item?.name}
            </div>
          ),
        };
      })
    : [];
  useEffect(() => {
    if (errorCreate) {
      Object.keys(errorCreate).forEach((fieldName) => {
        const errorMessage = errorCreate[fieldName][0];
        dispatch(
          setToast({
            message: errorMessage,
            status: true,
            type: Toast.TYPE_ERROR,
          }),
        );
      });
    }
  }, [errorCreate, formBooking, dispatch]);

  const onChangeIsAgent = () => {
    if (
      !(
        listBookingDetail?.orderer?.company?.id ||
        listBookingDetail?.payer?.agent?.id ||
        listBookingDetail?.source === 'openapi' ||
        listBookingDetail?.source === 'company' ||
        (listBookingDetail?.orderer?.agent?.id && !listBookingDetail?.booking_for_agent)
      )
    ) {
      const currentChecked = formBooking.getFieldValue('is_agent_id');
      formBooking.setFieldsValue({ is_agent_id: !currentChecked });
    }
  };
  const getPaymentOptions = () => {
    const { payment_method } = listBookingDetail || {};
    switch (payment_method) {
      case 'bank_transfer':
        return bankOption;
      case 'cash':
        return cashOption;
      default:
        break;
    }
    if (group === USER_GROUP.AGENT) {
      switch (payment_method) {
        case 'paid_at_company':
        case 'reverse':
          return paymentPaidCompanyOptions;
        case 'agent_balance':
          return agentBalanceOption;
        default:
          return paymentAgentOptions;
      }
    }
    if (isDetail) {
      switch (payment_method) {
        case 'paid_at_company':
        case 'reverse':
          return paymentPaidCompanyOptions;
        case 'agent_balance':
          return agentBalanceOption;
        case 'cash':
          return cashOption;
        case 'bank_transfer':
          return bankOption;
        default:
          return paymentOptions;
      }
    }

    return paymentMultiOptions;
  };
  const handleChangeAgentIdOption = (option: any) => {
    setCommissionPrice(option?.commission_percent);
  };
  const handleNumpadClick = () => {
    if (pin_code) {
      dispatch(setPinCode(null));
    } else {
      setOtp('');
      setOpenModalNumpad(true);
    }
  };
  const options = getPaymentOptions();
  useEffect(() => {
    if (statusPin) {
      setOpenModalNumpad(false);
    }
  }, [statusPin]);

  useEffect(() => {
    if ((!pin_code || !listBookingDetail?.price_change) && commisionPrice) {
      formBooking.setFieldValue('commission_price_change', commisionPrice);
    } else {
      if (listBookingDetail?.price_change) {
        formBooking.setFieldValue('commission_price_change', listBookingDetail?.price_change);
      } else {
        if (agentId && isAgentId) {
          formBooking.setFieldValue('commission_price_change', price?.commission_price);
        }
      }
    }
  }, [pin_code, listBookingDetail, commisionPrice, agentId, isAgentId, price]);
  return (
    <Spin spinning={loadingListBookingDetail}>
      {group === USER_GROUP.CAR_COMPANY && listBookingDetail?.source !== 'company' ? (
        <div className="agent-booking-block" style={isDetail ? { position: 'unset' } : { position: 'absolute' }}>
          <div
            onClick={onChangeIsAgent}
            className={`${listBookingDetail?.payer?.agent?.id ? 'cursor-not-allow' : 'cursor-pointer'} h-fit`}
          >
            <Form.Item name="is_agent_id" className="is-agent" valuePropName="checked">
              <Checkbox
                disabled={
                  listBookingDetail?.payer?.agent?.id ||
                  (listBookingDetail?.orderer?.agent?.id && !listBookingDetail?.booking_for_agent) ||
                  listBookingDetail?.source === 'openapi' ||
                  listBookingDetail?.source === 'company'
                }
              />
            </Form.Item>
          </div>
          <Form.Item name="agent_id" className="is-agent-select" required={isAgentId ? true : false}>
            <Select
              className="input-h36"
              disabled={
                !isAgentId || listBookingDetail?.source === 'openapi' || listBookingDetail?.source === 'company'
              }
              placeholder={
                <div className="d-flex justify-center items-center " style={{ columnGap: '10px', width: '150px' }}>
                  <img src={iconBookingAgent} alt="" /> Đặt hộ đại lý
                </div>
              }
              size="large"
              options={[
                // {
                //   value: '',
                //   label: (
                //     <div className="d-flex items-center gap-x-2">
                //       <img src={iconBookingAgent} alt="" /> Không
                //     </div>
                //   ),
                // },
                ...listAgentActiveOption,
              ]}
              showSearch
              onChange={(value, option) => handleChangeAgentIdOption(option)}
              filterOption={filterOption}
            />
          </Form.Item>
        </div>
      ) : (
        ''
      )}
      <Row gutter={16} className={`${isDetail ? '' : 'mt-1'}`}>
        <Col span={5}>
          <Form.Item name="name_seat" label={t('Ghế đang chọn')} className="w-100 booking-input-disable">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="ticket_code" label={t('Mã vé')} className="booking-input-disable">
            <Input disabled />
          </Form.Item>
        </Col>

        {listBooking?.policy?.insurance_policy?.percent ||
        listBooking?.policy?.insurance_policy?.price ||
        dataCustomerBooking?.policy?.insurance_policy?.percent ||
        dataCustomerBooking?.policy?.insurance_policy?.price ||
        ((listBookingDetail?.source === 'openapi' || listBookingDetail?.source === 'company') &&
          listBookingDetail?.is_insurance) ? (
          <Col className="d-flex mt-4" style={{ columnGap: '11px' }}>
            <Form.Item name="is_insurance" className="booking-input-disable">
              <Switch
                onChange={onChangeInsurance}
                disabled={
                  (isDetail &&
                    (listBookingDetail?.payment_method === 'cash' ||
                      listBookingDetail?.payment_method ||
                      listBookingDetail?.payment_method === 'agent_balance')) ||
                  (!listBooking?.policy?.insurance_policy?.percent && !listBooking?.policy?.insurance_policy?.price)
                }
              />
            </Form.Item>
            <>
              <div className="mt-2" style={{ columnGap: '5px' }}>
                {listBookingDetail?.insurance_fee &&
                (listBookingDetail?.source === 'openapi' || listBookingDetail?.source === 'company') ? (
                  <Typography.Text className="fw-500 fs-14">
                    {t(
                      `Bảo hiểm chuyến đi (${
                        listBookingDetail?.insurance_fee
                          ? `${formattedPrice(listBookingDetail?.insurance_fee)}đ/ghế`
                          : ''
                      }) `,
                    )}
                  </Typography.Text>
                ) : (
                  ''
                )}
                {(listBooking?.policy?.insurance_policy?.percent || listBooking?.policy?.insurance_policy?.price) &&
                !(listBookingDetail?.source === 'openapi' || listBookingDetail?.source === 'company') ? (
                  <Typography.Text className="fw-500 fs-14">
                    {t(
                      `Bảo hiểm chuyến đi (${
                        listBooking?.policy?.insurance_policy?.percent
                          ? `${listBooking?.policy?.insurance_policy?.percent}% giá vé`
                          : `${formattedPrice(listBooking?.policy?.insurance_policy?.price)}đ/ghế`
                      }) `,
                    )}
                  </Typography.Text>
                ) : listBookingDetail?.source === 'openapi' || listBookingDetail?.source === 'company' ? (
                  ''
                ) : (
                  <Typography.Text className="fw-500 fs-14">
                    {t(
                      `Bảo hiểm chuyến đi (${
                        dataCustomerBooking?.policy?.insurance_policy?.percent
                          ? `${dataCustomerBooking?.policy?.insurance_policy?.percent}% giá vé`
                          : `${formattedPrice(dataCustomerBooking?.policy?.insurance_policy?.price)}đ/ghế`
                      }) `,
                    )}
                  </Typography.Text>
                )}

                <Tooltip title={'Xem chi tiết chính sách bảo hiểm'}>
                  <InfoCircleFilled style={{ color: '#F8B507' }} onClick={() => setOpenModalInfo(true)} />
                </Tooltip>
              </div>
            </>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <Form.Item
            name="customer_phone"
            className="require phone-select"
            label={t('Số điện thoại')}
            validateFirst
            rules={validateMobileUser}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Input
              className="input-h36 input-phone"
              placeholder={t('Nhập số điện thoại')}
              size="large"
              value={customerPhone}
              onChange={(e) => handleInputChange(e.target.value)}
              onBlur={() => {
                onChangePhone(customerPhone);
              }}
              onWheel={(event) => event.currentTarget.blur()}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            name="customer_name"
            label={t('Họ và tên')}
            className="require"
            validateTrigger={['onChange', 'onBlur']}
            rules={NameRules}
          >
            <Input placeholder="Nhập họ và tên" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            name="customer_email"
            label={t('Email')}
            validateTrigger={['onChange', 'onBlur']}
            rules={validatorEmailBooking}
          >
            <Input placeholder="Nhập email" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="pickup_location_id"
            className="form-item-name require"
            label={t('Đón tại')}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Select
              className="input-h36"
              placeholder="Chọn điểm đón"
              size="large"
              style={{ width: '100%' }}
              options={handleOptionPickup()}
              showSearch
              filterOption={filterOption}
              disabled={
                ((listBookingDetail?.payment_method === 'agent_balance' ||
                  listBookingDetail?.payment_method === 'bank_transfer' ||
                  listBookingDetail?.payment_method === 'cash') &&
                  !isMoveTicket &&
                  !dataMoveTicket?.ticket_code) ||
                listBookingDetail?.source === 'company' ||
                listBookingDetail?.source === 'openapi'
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="drop_off_location_id"
            className="form-item-name require"
            label={t('Trả tại')}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Select
              className="input-h36"
              placeholder="Chọn điểm trả"
              size="large"
              style={{ width: '100%' }}
              options={handleOptionDropOff()}
              showSearch
              filterOption={filterOption}
              disabled={
                ((listBookingDetail?.payment_method === 'agent_balance' ||
                  listBookingDetail?.payment_method === 'bank_transfer' ||
                  listBookingDetail?.payment_method === 'cash') &&
                  !isMoveTicket &&
                  !dataMoveTicket?.ticket_code) ||
                listBookingDetail?.source === 'company' ||
                listBookingDetail?.source === 'openapi'
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ display: 'flex' }}>
        {pickUpValue === 'other' && (
          <Col span={12}>
            <Form.Item name="pickup_extra" className="form-item-name require" validateTrigger={['onChange', 'onBlur']}>
              <Input className="input-h36" placeholder="Chọn điểm đón khác" size="large" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        )}
        {pickUpValue !== 'other' && dropOffValue === 'other' && <Col span={12}></Col>}
        {dropOffValue === 'other' && (
          <Col span={12}>
            <Form.Item
              name="drop_off_extra"
              className="form-item-name require"
              validateTrigger={['onChange', 'onBlur']}
            >
              <Input className="input-h36" placeholder="Nhập điểm trả khác" size="large" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={20}>
        <Col span={6}>
          {(listBookingDetail?.payment_method !== 'agent_balance' &&
            listBookingDetail?.payment_method !== 'paid_at_company') ||
          group === USER_GROUP.AGENT ? (
            <Flex className={group !== USER_GROUP.AGENT ? `block-numpad-input` : ''}>
              <Form.Item name="total_price" label={t('Tổng tiền')} className="booking-input-disable price">
                <InputNumber
                  suffix="VND"
                  disabled={
                    !pin_code || listBookingDetail?.source === 'openapi' || listBookingDetail?.source === 'company'
                  }
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value?.replace(/[^0-9]/g, '') as unknown as number}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(value) => {
                    formBooking.setFieldsValue({
                      commission_price_change: formattedPrice(value),
                    });
                  }}
                />
              </Form.Item>
              {group !== USER_GROUP.AGENT &&
                listBookingDetail?.source !== 'openapi' &&
                listBookingDetail?.source !== 'company' && (
                  <div className="btn-numpad" onClick={() => handleNumpadClick()} style={{ marginTop: '25px' }}>
                    <img src={pin_code ? iconCancelPinCode : iconNumpad} alt="" />
                  </div>
                )}
            </Flex>
          ) : (
            <>
              <Form.Item name="total_price1" label={t('Tổng tiền vé')} className="booking-input-disable price">
                <Input suffix="VND" disabled />
              </Form.Item>
              {group !== USER_GROUP.AGENT &&
                listBookingDetail?.status === 'reserve' &&
                listBookingDetail?.source !== 'openapi' &&
                listBookingDetail?.source !== 'company' && (
                  <div className="btn-numpad" onClick={() => handleNumpadClick()} style={{ marginTop: '25px' }}>
                    <img src={pin_code ? iconCancelPinCode : iconNumpad} alt="" />
                  </div>
                )}
            </>
          )}
          {isInSurance && (
            <>
              {listBookingDetail?.ticket_code && listBookingDetail?.insurance_fee ? (
                listBookingDetail?.price_change ? (
                  <Typography className="fs-14 fw-500 text-insurance">
                    {`Giá đã bao gồm ${formattedPrice(listBookingDetail?.insurance_fee)} đ tiền bảo hiểm`}
                  </Typography>
                ) : (
                  <Typography className="fs-14 fw-500 text-insurance">
                    {`+${formattedPrice(listBookingDetail?.insurance_fee)} đ tiền bảo hiểm`}
                  </Typography>
                )
              ) : (
                <Typography className="fs-14 fw-500 text-insurance">
                  {listBooking?.policy?.insurance_policy?.percent
                    ? `+${listBooking?.policy?.insurance_policy?.percent}% tiền bảo hiểm`
                    : `+${formattedPrice(listBooking?.policy?.insurance_policy?.price)}đ tiền bảo hiểm`}
                </Typography>
              )}
            </>
          )}
        </Col>
        {listBookingDetail?.payment_method === 'agent_balance' ||
        group === USER_GROUP.AGENT ||
        (listBookingDetail?.orderer?.agent?.id && !isAgentId && !agentId) ? (
          <Col span={6}>
            <Form.Item
              name="commission_price"
              label={t('Tổng tiền đại lý trả')}
              className="booking-input-disable price"
            >
              <Input suffix="VND" disabled />
            </Form.Item>
            {isDetail ? (
              <Typography className="fs-14 fw-500 text-discount">
                {`Đã trừ ${
                  listBookingDetail?.agent_commission
                    ? listBookingDetail?.agent_commission
                    : listBookingDetail?.payer?.agent?.commission_percent
                    ? listBookingDetail?.payer?.agent?.commission_percent
                    : listBookingDetail?.orderer?.agent?.commission_percent
                }% chiết khấu`}
              </Typography>
            ) : (
              <Typography className="fs-14 fw-500 text-discount">
                {`Đã trừ ${listBooking?.schedule?.agent?.commission_percent}% chiết khấu`}
              </Typography>
            )}
          </Col>
        ) : (
          ''
        )}
        {isAgentId && agentId && commissionPrice !== 0 && (pin_code || listBookingDetail?.price_change) ? (
          <Col span={6}>
            <Form.Item
              name={'commission_price_change'}
              label={t('Tổng tiền đại lý trả')}
              className="booking-input-disable price"
            >
              <InputNumber suffix="VND" disabled onLoad={agentId} onLoadedData={agentId} />
            </Form.Item>
          </Col>
        ) : (
          ''
        )}

        {isAgentId && agentId && !pin_code && !listBookingDetail?.price_change ? (
          <Col span={6}>
            <Spin spinning={!commisionPrice && !isListTicket}>
              <Form.Item
                name={'commission_price'}
                label={t('Tổng tiền đại lý trả')}
                className="booking-input-disable price"
              >
                <InputNumber
                  suffix="VND"
                  disabled
                  onLoad={agentId}
                  parser={(value) => value?.replace(/[^0-9]/g, '') as unknown as number}
                  onLoadedData={agentId}
                />
              </Form.Item>
              {isDetail ? (
                commissionPrice ? (
                  <Typography className="fs-14 fw-500 text-discount">{`Đã trừ ${commissionPrice}% chiết khấu`}</Typography>
                ) : (
                  <Typography className="fs-14 fw-500 text-discount">{`Đã trừ ${listBookingDetail?.orderer?.agent?.commission_percent}% chiết khấu`}</Typography>
                )
              ) : (
                !pin_code &&
                !listBookingDetail?.price_change && (
                  <Typography className="fs-14 fw-500 text-discount">{`Đã trừ ${commissionPrice}% chiết khấu`}</Typography>
                )
              )}
            </Spin>
          </Col>
        ) : (
          ''
        )}
        <Col
          span={
            ((listBookingDetail?.payment_method === 'agent_balance' || group === USER_GROUP.AGENT) &&
              !listBookingDetail?.price_change) ||
            (listBookingDetail?.orderer?.agent?.id && !pin_code && !listBookingDetail?.price_change) ||
            (isAgentId &&
              agentId &&
              !pin_code &&
              !listBookingDetail?.price_change &&
              listBookingDetail?.source !== 'openapi' &&
              listBookingDetail?.source !== 'company') ||
            ((pin_code || listBookingDetail?.price_change) && agentId && isAgentId)
              ? 12
              : 18
          }
        >
          <Form.Item name="note" label={t('Ghi chú')}>
            <Input placeholder="Nhập ghi chú" />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col span={24}>
          {listBookingDetail?.source === 'openapi' || listBookingDetail?.source === 'company' ? (
            <div className="mt-1">
              {listBookingDetail?.status === 'paid' ? (
                <Typography className="fs-16 text-green fw-500">Đã thanh toán cho Carlink API</Typography>
              ) : (
                <Typography className="fs-16 reserve fw-500">Vé đang chờ khách thanh toán cho Carlink API</Typography>
              )}
            </div>
          ) : (
            <Form.Item
              name="payment_method"
              label={t('Phương thức thanh toán')}
              className="require mt-1"
              required={true}
            >
              <Radio.Group
                options={options}
                onChange={onChangePayment}
                value={paymentMethod}
                buttonStyle="solid"
                className="payment-checkbox-group mt-1"
                disabled={
                  isDetail &&
                  (listBookingDetail?.payment_method === 'cash' ||
                    listBookingDetail?.payment_method === 'bank_transfer' ||
                    listBookingDetail?.payment_method === 'agent_balance')
                }
              />
            </Form.Item>
          )}
        </Col>
        {listBooking?.schedule?.id &&
          !listBooking?.schedule?.is_diagram &&
          listBookingDetail?.payment_method !== 'reserve' &&
          listBookingDetail?.payment_method !== 'paid_at_company' &&
          listBookingDetail?.payment_method !== 'hold' && (
            <Col>
              {listBookingDetail?.is_driver_pay ? (
                <Typography
                  className="fw-500 fs-16 "
                  style={{ marginTop: '-28px', color: '#3ab54a', paddingBottom: '40px' }}
                >
                  {`Tài xế đã thu bằng ${getPaymentMethod(listBookingDetail?.payment_method)} ${
                    listBookingDetail?.commission_price ? formattedPrice(listBookingDetail?.commission_price) : 0
                  }VNĐ `}
                </Typography>
              ) : (
                listBookingDetail?.source !== 'openapi' &&
                listBookingDetail?.source !== 'company' && (
                  <Typography
                    className="fw-500 fs-16"
                    style={{ marginTop: '-28px', color: '#3ab54a', paddingBottom: '40px' }}
                  >
                    {listBookingDetail?.payer?.name
                      ? listBookingDetail?.payer?.office_name
                        ? `${listBookingDetail?.payer?.name}_${listBookingDetail?.payer?.office_name}`
                        : listBookingDetail?.payer?.name
                      : listBookingDetail?.orderer?.name}
                    {listBookingDetail?.payer?.agent?.id ? ' đã thanh toán bằng ' : ' đã thu bằng '}
                    {getPaymentMethod(listBookingDetail?.payment_method)}
                    {` ${formattedPrice(
                      listBookingDetail?.payer?.agent?.id
                        ? listBookingDetail?.commission_price
                        : listBookingDetail?.price,
                    )}`}
                    VNĐ
                  </Typography>
                )
              )}
            </Col>
          )}
      </Row>
      <ModalNumpad
        modalOpen={openModalNumpad}
        onCancel={() => {
          setOpenModalNumpad(false);
          setOtp('');
        }}
        onOk={() => false}
        otp={otp}
        setOtp={setOtp}
      />
      <ModalInfoInsurance
        modalOpen={openModalInfo}
        onCancel={() => setOpenModalInfo(false)}
        onOk={() => false}
        listBooking={listBooking}
        dataPolicy={dataCustomerBooking?.policy?.insurance_policy}
      />
    </Spin>
  );
};
export default ModalBooking1;
