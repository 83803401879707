import { getListReportFeeExportTicketCompanyCarlinkAPI, getListReportFeeExportTicketCompanyCarlinkExcelAPI } from "../../../api/report";
import { ParamsReport, ReportFeeExportTicketCompanyCarlink, ValueOptions } from "../../../types/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    listRoute: [],
    listTimeRoute: [{ value: '', label: 'Tất cả' }],
    total_price: 0,
    total_price_export_ticket_fee: 0,
    total_ticket_paid: 0,
    filter: {
        booking_date: 'false',
        from: '',
        to: '',
        options: undefined,
        route_id: '',
        depart_time: '',
        company_id: undefined,
    }
} as ReportFeeExportTicketCompanyCarlink;

export const reportFeeExportTicketCompanyCarlinkSlice = createSlice({
    name: 'reportFeeExportTicketCompanyCarlink',
    initialState,
    reducers: {
        setFilterBookingDate(state, action: PayloadAction<string>) {
            state.filter.booking_date = action.payload;
        },
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setFilterOptions(state, action: PayloadAction<string>) {
            state.filter.options = action.payload;
        },
        setFilterRoute(state, action: PayloadAction<number | string>) {
            state.filter.route_id = action.payload;
        },
        setFilterDepartTime(state, action: PayloadAction<number | string>) {
            state.filter.depart_time = action.payload;
        },
        setFilterCompany(state, action: PayloadAction<number | undefined>) {
            state.filter.company_id = action.payload;
        },
        setListTimeRoute(state, action: PayloadAction<ValueOptions[]>) {
            state.listTimeRoute = action.payload;
        },
        setListAllRoutes(state, action) {
            state.listRoute = action.payload;
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportFeeExportTicketCompanyCarlink.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportFeeExportTicketCompanyCarlink.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_price_export_ticket_fee = action.payload?.total_price_export_ticket_fee;
                state.total_price = action.payload?.total_price;
                state.total_ticket_paid = action.payload?.total_ticket_paid;
            }).addCase(getListReportFeeExportTicketCompanyCarlink.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportFeeExportTicketCompanyCarlinkExcel.pending, (state) => {
            }).addCase(getListReportFeeExportTicketCompanyCarlinkExcel.fulfilled, (state, action) => {
            }).addCase(getListReportFeeExportTicketCompanyCarlinkExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            })
    }
})

export const getListReportFeeExportTicketCompanyCarlink = createAsyncThunk('ReportFeeExportTicketCompanyCarlink/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportFeeExportTicketCompanyCarlinkAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportFeeExportTicketCompanyCarlinkExcel = createAsyncThunk('ReportFeeExportTicketCompanyCarlink/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportFeeExportTicketCompanyCarlinkExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setPerPage, setFilterBookingDate, setListAllRoutes, setFilterOptions, setFilterRangeDate, setFilterRoute, setFilterDepartTime, setListTimeRoute, setFilterCompany } = reportFeeExportTicketCompanyCarlinkSlice.actions;