/* eslint-disable react-hooks/exhaustive-deps */
import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkAuthorization } from '../../api';
import { getInfoGeneralAgentApi, updateInfoGeneralAgentApi } from '../../api/generalAgent';
import { URL_API_VIET_QR } from '../../api/vietqr';
import '../../assets/styles/infoCompany.scss';
import { ACTION_NAME, messageUpdateSuccess } from '../../config/message';
import { filterOptionSearch, getBase64 } from '../../lib/utils';
import { useAppSelector } from '../../redux/hooks';

const InfoMainAgent: React.FC<any> = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [listBank, setListBank] = useState([]);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const [outstandingBalance, setOutstandingBalance] = useState(false);
  const [displayPrice, setDisplayPrice] = useState(1);

  const onChangeSwitch = (checked: boolean) => {
    setOutstandingBalance(checked);
  };

  const validateDebtAmount = (_: any, value: any) => {
    if (!outstandingBalance || (value >= 500000 && value <= 1000000000)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Số tiền phải nằm trong khoảng từ 500,000 đến 1,000,000,000 VND'));
  };

  const image: any = dataDetail?.avatar
    ? [
        {
          uid: '-1',
          name: 'No avatar',
          status: 'done',
          url: dataDetail?.avatar,
        },
      ]
    : [];

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewTitle, setPreviewTitle] = useState('');

  // upload image
  const handleCancelImage = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    // Giữ chỉ một tệp trong fileList
    setFileList(newFileList?.slice(-1));
  };

  useEffect(() => {
    setFileList(image);
  }, [dataDetail?.avatar]);

  const handleDetailAgentApi = async () => {
    setLoadingAgent(true);
    try {
      const res = await getInfoGeneralAgentApi();
      if (res.status === 200) {
        setDataDetail(res.data.data);
      }
    } catch (error) {}
    setLoadingAgent(false);
  };

  const handleListQR = () => {
    try {
      axios
        .get(URL_API_VIET_QR)
        .then((response) => {
          const res = response.data;
          setListBank(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {}
  };

  const listBankSelect = listBank.map((item: any) => {
    return {
      value: item?.bin,
      label: `${item?.name} (${item?.code})`,
      bank_name: `${item?.name} (${item?.code})`,
      bank_logo: item?.logo,
    };
  });

  const onChangePrintTicket = (checked: boolean) => {
    setDisplayPrice(checked ? 1 : 0);
  };

  useEffect(() => {
    handleListQR();
  }, []);

  const onLoad = () => {
    form.setFieldsValue({
      name: dataDetail?.name,
      address: dataDetail?.address,
      bank_account_name: dataDetail?.bank_account_name,
      phone: dataDetail?.phone,
      email: dataDetail?.email,
      debit_limit: dataDetail?.debit_limit,
      bank: dataDetail?.bank,
      bank_account_number: dataDetail?.bank_account_number,
      fax: dataDetail?.fax,
      sales_office: dataDetail?.sales_office,
      represent: dataDetail?.represent,
      business_code: dataDetail?.business_code,
      position: dataDetail?.position,
      commission_percent: dataDetail?.commission_percent,
      debt_amount: dataDetail?.debt_amount,
      relevant_emails: dataDetail?.relevant_emails,
    });
  };

  useEffect(() => {
    onLoad();
    setDisplayPrice(dataDetail?.display_price ? 1 : 0);
  }, [dataDetail, form]);

  const onFinish = async (values: any) => {
    values.avatar = fileList[0]?.thumbUrl;
    values.is_debt = outstandingBalance;
    values.display_price = displayPrice;
    try {
      const res = await updateInfoGeneralAgentApi({ ...values });
      if (res.status === 200) {
        messageUpdateSuccess(ACTION_NAME.AGENT);
        handleDetailAgentApi();
        setIsEdit(false);
      }
    } catch (error: any) {
      checkAuthorization(error);
    }
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onSearch = (value: string) => {};

  const handleChangeBank = (value: any, option: any) => {
    form.setFieldsValue({
      bank_name: option?.bank_name,
      bank_logo: option?.bank_logo,
    });
  };

  useEffect(() => {
    handleDetailAgentApi();
  }, []);

  useEffect(() => {
    setOutstandingBalance(dataDetail?.is_debt);
  }, [dataDetail?.is_debt]);

  return (
    <Spin spinning={loadingAgent}>
      <Card className="wrapper-info-company">
        <Col>
          <Form
            name="info-car-company"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            // validateTrigger="onSubmit"
            className="form-info-company"
          >
            <div>
              <Row>
                <Col>
                  <Form.Item name="bank_name" className="d-none">
                    <Input className="w-914" />
                  </Form.Item>
                  <Form.Item name="bank_logo" className="d-none">
                    <Input className="w-914" />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    className="required"
                    label={t('Tên tổng đại lý')}
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập tên đại lý'),
                      },
                    ]}
                    validateFirst
                  >
                    <Input disabled className="w-914" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="address"
                    label={t('Địa chỉ')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập địa chỉ'),
                      },
                    ]}
                  >
                    <Input disabled className="w-914" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="represent"
                    label="Đại diện"
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập tên người đại diện'),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="position"
                    label={t('Chức vụ')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập chức vụ'),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="business_code"
                    label={t('Mã số thuế (MSDN)')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập mã số thuế'),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-221" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    name="sales_office"
                    label={t('VPGD')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập tên văn phòng'),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    name="phone"
                    label={t('Điện thoại')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập số điện thoại'),
                      },
                    ]}
                  >
                    <Input disabled className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item name="fax" label={t('Fax')}>
                    <Input disabled={!isEdit} className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    className="required"
                    name="email"
                    label={t('Email liên hệ')}
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập số điện thoại'),
                      },
                    ]}
                  >
                    <Input disabled className="w-355" />
                  </Form.Item>
                </Col>
                <Col style={{ marginLeft: 25 }}>
                  <Form.Item name="relevant_emails" label={t('Email nhận mặt vé')}>
                    <Input disabled className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col>
                  <Form.Item
                    name="bank_account_number"
                    label={t('Tài khoản số')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập số tài khoản'),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} type="number" className="w-355" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="bank_account_name"
                    label={t('Chủ tài khoản')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập tên chủ tài khoản'),
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} className="w-355" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    name="bank"
                    label={t('Tại ngân hàng')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập tên ngân hàng'),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={onSearch}
                      optionFilterProp="label" // Đây là phần cần chỉnh sửa
                      filterOption={filterOptionSearch}
                      disabled={!isEdit}
                      className="w-914"
                      onChange={(value: any, option: any) => handleChangeBank(value, option)}
                      options={listBankSelect}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="commission_percent"
                    label={t('Chiết khấu tính trên % hoa hồng bán vé')}
                    className="required"
                    rules={[
                      {
                        required: true,
                        message: t('Vui lòng nhập chiết khấu'),
                      },
                    ]}
                  >
                    <Input disabled suffix={'%'} min={0} style={{ width: 104 }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col className="col-debt-amount" xs={24} md={24} xl={24} xxl={24}>
                  <Form.Item label="Cho phép dư nợ" className="required">
                    <Switch disabled checked={outstandingBalance} onChange={onChangeSwitch} />
                  </Form.Item>
                  {outstandingBalance && (
                    <Form.Item
                      name="debt_amount"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số tiền!',
                        },
                        {
                          validator: validateDebtAmount,
                        },
                      ]}
                      validateFirst
                    >
                      <InputNumber
                        disabled
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        style={{
                          marginLeft: 180,
                          width: 282,
                          marginBottom: 11,
                        }}
                        suffix="VND"
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row>
                <Form.Item name="display_price" label="Cho phép in giá vé lên mặt vé" className="required">
                  <Switch
                    disabled={!isEdit}
                    checked={displayPrice === 1 ? true : false}
                    onChange={onChangePrintTicket}
                  />
                </Form.Item>
              </Row>
              <Row gutter={24}>
                <Col style={{ width: '520px' }}>
                  <Form.Item
                    className={
                      !isEdit
                        ? 'upload-image upload-image-info-agent image-disabled'
                        : 'upload-image upload-image-info-agent'
                    }
                    name="logo"
                    label={t('Logo')}
                  >
                    <Upload
                      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      disabled={!isEdit}
                    >
                      {fileList.length >= 1 ? null : (
                        <>
                          <UploadOutlined /> {t('Tải ảnh lên')}
                        </>
                      )}
                    </Upload>
                    <Modal open={previewOpen} footer={null} onCancel={handleCancelImage}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </Form.Item>
                </Col>
                {isEdit ? (
                  <Col className="container-btn-company ">
                    <Button onClick={() => setIsEdit(false)}>Trở lại</Button>
                    <Button type="primary" onClick={handleSubmit}>
                      Lưu
                    </Button>
                  </Col>
                ) : (
                  <Button
                    style={!accountDetail?.is_admin ? { display: 'none' } : {}}
                    // type="primary"
                    className="btn-info-company"
                    onClick={() => setIsEdit(true)}
                  >
                    Chỉnh sửa
                  </Button>
                )}
              </Row>
            </div>
          </Form>
        </Col>
      </Card>
    </Spin>
  );
};

export default InfoMainAgent;
