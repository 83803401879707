import { Col, Flex, Row, Table, Typography } from "antd";

function Summary({total_cancel, total_waiting, total_confirmed, colSpan} : any) {
  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={colSpan}>
          <Flex align="center" gap="middle">
            <Typography.Text className="text-color-default fw-700 fs-16">
              TỔNG
            </Typography.Text>
            <Typography.Text className="text-color-default fw-500 fs-16">
              (VND)
            </Typography.Text>
          </Flex>
        </Table.Summary.Cell>

        <Table.Summary.Cell align="right" index={10}>
          <Row className="recharge-summary">
            <Col className="recharge-total-3" style={{display: "flex"}}>
            <Typography.Text className="text-color-default text-end fw-700 fs-16">
              Đã từ chối: {total_cancel}
            </Typography.Text>
            <Typography.Text className="text-color-default text-end fw-700 fs-16 ">
              Chờ xác nhận: {total_waiting}
            </Typography.Text>
            <Typography.Text className="text-color-default text-end fw-700 fs-16 ">
              Đã xác nhận: {total_confirmed}
            </Typography.Text>
            </Col>
          </Row>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
}
export default Summary;
