import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { USER_GROUP } from "../../config/constant";
import ReportCancelAgent from "./ReportCancelAgent";
import ReportCancelCompany from "./ReportCancelCompany";
import ReportCancelCarlink from "./ReportCancelCarlink";
import ReportCancelMainAgent from "./ReportCancelMainAgent";

const ReportCancel = () => {
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  if (accountDetail?.group === USER_GROUP.CAR_COMPANY) {
    return <ReportCancelCompany />;
  }
  if (accountDetail?.group === USER_GROUP.AGENT) {
    return <ReportCancelAgent />;
  }
  if (accountDetail?.group === USER_GROUP.CARLINK) {
    return <ReportCancelCarlink />;
  }
  if (accountDetail?.group === USER_GROUP.MAIN_AGENT) {
    return <ReportCancelMainAgent />;
  }
  return <></>;
};

export default ReportCancel;
