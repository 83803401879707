import { RouteConfigType } from "../../types/routeConfig";
import routes from "./routes";
type Config = {
    routes: RouteConfigType;
  };
  
  const config: Config = {
    routes,
  };
  
  export default config;