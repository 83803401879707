import { TicketParams } from "../types";
import {getAxiosClientWithToken} from "./index";

export const getListTicketsAPI = (params: any) => {
    return getAxiosClientWithToken().get('/price-schedules', { params });
};

export const createTicketAPI = (params: TicketParams) => {
    return  getAxiosClientWithToken().post('/price-schedules', params);
}

export const updateTicketAPI = (params: TicketParams) => {
    return getAxiosClientWithToken().put(`/price-schedules/${params?.id}`, params);
}

export const updateStatusTicketAPI = (params: TicketParams) => {
    return getAxiosClientWithToken().put(`/price-schedules/status/${params?.id}`, params);
}