import React from "react";
import { Link } from "react-router-dom";
import { IconVideoTutorials } from "./Icons";
import { Typography } from "antd";

const TutorialsVideo = ({ url_youtube }: { url_youtube: string }) => {
  return (
    <Link
      target="_blank"
      className="d-flex align-items-center"
      style={{ columnGap: "8px" }}
      to={url_youtube}
    >
      <IconVideoTutorials width={20} height={20} />
      <Typography.Text className="fs-14 fw-500 text-color-green-default">
        Xem video hướng dẫn
      </Typography.Text>
    </Link>
  );
};

export default TutorialsVideo;
