import { Button, Form, Input, Space } from 'antd';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { renderPinCodeAPI } from '../../../../api/user';
import icPhoneNumpad from '../../../../assets/images/listUser/phone_numpad.svg';
// import { toast } from "react-toastify";

function ManagePinCode({ callUpdateUserAPI, detailUser, setDetailUser, setActiveFour, activeFour }: any) {
  const [form] = Form.useForm();
  const handleRenderPinCodeApi = async () => {
    try {
      const res = await renderPinCodeAPI({ id: detailUser?.id });
      if (res.status === 200) {
        toast.success(detailUser?.pin_code ? 'Thay đổi mã pin thành công' : 'Tạo mã pin thành công');
        setDetailUser((prev: any) => ({ ...prev, pin_code: res?.data?.data?.pin_code }));
      }
    } catch (error) {}
  };

  const onFinish = async () => {
    await callUpdateUserAPI();
    setActiveFour(false);
  };

  useEffect(() => {
    if (activeFour) {
      form.submit();
      setActiveFour(false);
    }
  }, [activeFour]);
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="form-edit-user contact create-user-contact form-create-user"
    >
      <div className="container-form-user">
        <div className="content-pin-code">
          <label className="label-pin-code">Mã PIN cấp quyền sửa giá vé</label>
          <Space.Compact style={{ width: 424 }}>
            <Input
              disabled
              value={detailUser?.pin_code}
              onChange={(value) => {
                setDetailUser({ ...detailUser, pin_code: value });
              }}
            />
            <Button className="btn-pin-code" style={{ height: 36 }} onClick={handleRenderPinCodeApi}>
              <img src={icPhoneNumpad} alt="" /> <span className="text-pin-code">Thay đổi mã PIN</span>
            </Button>
          </Space.Compact>
        </div>
      </div>
    </Form>
  );
}

export default ManagePinCode;
