import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { getErrorMessage } from "../../../api";
import { getListPolicyAPI } from "../../../api/policies";
import { listPolicyRequest, listPolicyState } from "../../../types/policy";
import { Constant } from "../../../config/constant";

export const fetchListPolicy = createAsyncThunk(
  "admin/listPolicy",
  async (params: listPolicyRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListPolicyAPI(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListPolicy(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListPolicy(error));
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

// export const getDetailPolicy = createAsyncThunk(
//   "admin/getDetailPolicy",
//   async (id: any, { dispatch, rejectWithValue }) => {
//     try {
//       const response = await getPolicyByIdAPI(id);
//       const { data, status } = response;
//       if (status === Constant.DEFAULT_STATUS) {
//         dispatch(setDetailPolicy(data));
//         return true;
//       }
//     } catch (error: any) {
//       dispatch(setDetailPolicy(error));
//       return rejectWithValue(getErrorMessage(error));
//     }
//     return false;
//   }
// );

export const listPolicySlice = createSlice({
  name: "listPolicy",
  initialState: {
    error: false,
    loading: false,
    success: false,
    listPolicy: [],
    pagination: {
      total: 0,
      per_page: 10,
      current_page: 1,
      last_page: 1,
    },
    status: 0,
  } as listPolicyState,
  reducers: {
    setListPolicy: (state: listPolicyState, { payload }) => {
      state.listPolicy = payload?.data;
      state.pagination = payload?.pagination;
    },
    setListPolicyLoad: (state: listPolicyState) => {
      state.listPolicy = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListPolicy.pending, (state: listPolicyState) => {
      state.loading = true;
    });
    builder.addCase(fetchListPolicy.fulfilled, (state: listPolicyState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchListPolicy.rejected, (state: listPolicyState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const listPolicySelector = (state: RootState) => state.listPolicy;
export const { setListPolicy, setListPolicyLoad } =
  listPolicySlice.actions;
