import { getListReportCustomerAPI, getListReportCustomerExcelAPI } from "../../../api/report";
import { ParamsReport, ReportCustomer } from "../../../types/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    total_prices: 0,
    total_real_revenue: 0,
    filter: {
        booking_date: 'false',
        from: '',
        to: '',
    }
} as ReportCustomer;

export const reportCustomerSlice = createSlice({
    name: 'reportCustomer',
    initialState,
    reducers: {
        setFilterBookingDate(state, action: PayloadAction<string>) {
            state.filter.booking_date = action.payload;
        },
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportCustomer.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_prices = action.payload?.total_prices;
                state.total_real_revenue = action.payload?.total_real_revenue;
            }).addCase(getListReportCustomer.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportCustomerExcel.pending, (state) => {
            }).addCase(getListReportCustomerExcel.fulfilled, (state, action) => {
            }).addCase(getListReportCustomerExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            })
    }
})

export const getListReportCustomer = createAsyncThunk('ReportCustomer/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportCustomerAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportCustomerExcel = createAsyncThunk('ReportCustomer/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportCustomerExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setFilterBookingDate, setFilterRangeDate, setPerPage } = reportCustomerSlice.actions;