export const validateAmountDeposit = [
    { 
      required: true, 
      message: 'Vui lòng nhập số tiền cần nạp' 
    },
    {
      validator: (_:any, value: any) => {
        if (value && parseFloat(value) === 0) {
          return Promise.reject('Số tiền cần nạp không được phép là 0');
        }
        return Promise.resolve();
      }
    },
    {
      validator: (_:any, value: any) => {
        if (value && value.charAt(0) === '0') {
          return Promise.reject('Số tiền cần nạp không được bắt đầu bằng số 0');
        }
        return Promise.resolve();
      }
    },
    {
      validator: (_: any, value: any) => {
        if (value && parseFloat(value.replace(/,/g, '')) < 500000) {
          return Promise.reject('Số tiền tối thiểu cần nạp là 500.000');
        }
        return Promise.resolve();
      },
    },
    {
      validator: (_: any, value: any) => {
        const numericValue = value.replace(/,/g, ''); // Remove commas
        if (isNaN(numericValue) || !numericValue.match(/^\d+$/)) {
          return Promise.reject('Số tiền cần nạp phải là số');
        }
        return Promise.resolve();
      },
    },
  ]