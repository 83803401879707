import { Button, ConfigProvider, Flex, Modal } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import iconCancel from '../../../assets/images/icons/icon-cancel.svg';
import iconCheck from '../../../assets/images/icons/icon-check.svg';
import iconClose from '../../../assets/images/listUser/iconsClose.svg';
import iconCanCelFilled from '../../../assets/images/orders/icon-cancel-filled.svg';
import iconHistory from '../../../assets/images/orders/icon-history.svg';
import { USER_GROUP } from '../../../config/constant';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getAmountOrder, getListOrdersPending } from '../../../redux/slice/orders/ordersPendingSlide';
import { getListOrdersProcessed } from '../../../redux/slice/orders/ordersProcessedSlide';
import { OrderItemParams } from '../../../types/order';
import { formatPrice } from '../../../utils/helpers';
import { cancelAllTicketsAPI, exportAllTicketsAPI } from '../api';
import { TabStatusOrderProcessed, TypeModalOrder } from '../models/constants';
import '../styles/ModalOrderInfo.scss';
import { handleCheckArray } from '../views/OrderProcessed';
import ListTicket from './ListTicket';
import ModalCancel from './ModalCancel';
import ModalExport from './ModalExport';
import ModalHistoryOrder from './ModalHistoryOrder';
import iconAvatar from '../../../assets/images/orders/icon-avatar-default.svg';
import iconPhone from '../../../assets/images/orders/icon-phone.svg';
import iconEmail from '../../../assets/images/orders/icon-email.svg';
import iconFacebook from '../../../assets/images/orders/icon-facebook.svg';
import iconGoogle from '../../../assets/images/orders/icon-google.svg';

interface ModalOrderDataParams {
  openModalOrderData: boolean;
  setOpenModalOrderData: React.Dispatch<React.SetStateAction<boolean>>;
  orderData: OrderItemParams;
  typeModal: TypeModalOrder;
  getDetailOrder: any;
}

const ModalOrderData = ({
  openModalOrderData,
  setOpenModalOrderData,
  typeModal,
  orderData,
  getDetailOrder,
}: ModalOrderDataParams) => {
  const dispatch = useAppDispatch();
  const [openModalHistory, setOpenModalHistory] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);

  const accountDetail = useAppSelector((state) => state.authAccount?.user);

  const [openExportAll, setOpenExportAll] = useState<boolean>(false);
  const [openCancelAll, setOpenCancelAll] = useState<boolean>(false);

  const { filter: listFilterPending, pagination: paginationPending } = useAppSelector((state) => state.ordersPending);
  const { filter: listFilterProcessed, pagination: paginationProcessed } = useAppSelector(
    (state) => state.ordersProcessed,
  );

  const getTotalCancelFee = useMemo(() => {
    let totalCancelFee = 0;
    orderData?.data?.forEach((ticket) => {
      if (ticket?.status !== 'cancel') {
        totalCancelFee = totalCancelFee + ticket?.cancel_info?.cancel_fee;
      }
    });
    return totalCancelFee;
  }, [orderData?.data]);

  const getTotalRefund = useMemo(() => {
    let totalRefund = 0;
    orderData?.data?.forEach((ticket) => {
      if (ticket?.status !== 'cancel') {
        totalRefund = totalRefund + ticket?.cancel_info?.refund;
      }
    });
    return totalRefund;
  }, [orderData?.data]);

  const handleGetListOrdersPending = async () => {
    await dispatch(
      getListOrdersPending({
        page: 1,
        keyword: listFilterPending?.keyword,
        date_filter: listFilterPending?.date_filter,
        from: dayjs(listFilterPending?.from, 'YYYY-MM-DD', true).isValid()
          ? dayjs(listFilterPending?.from).format('YYYY-MM-DD')
          : undefined,
        to: dayjs(listFilterPending?.to, 'YYYY-MM-DD', true).isValid()
          ? dayjs(listFilterPending?.to).format('YYYY-MM-DD')
          : undefined,
        status: TabStatusOrderProcessed.UN_PROCESSED,
        limit: paginationPending?.per_page,
        is_full: '0',
        is_detail_booking: '0',
      }),
    );
  };

  const handleGetListOrdersProcessed = async () => {
    await dispatch(
      getListOrdersProcessed({
        page: 1,
        keyword: listFilterProcessed?.keyword,
        date_filter: listFilterProcessed?.date_filter,
        from: dayjs(listFilterProcessed?.from, 'YYYY-MM-DD', true).isValid()
          ? dayjs(listFilterProcessed?.from).format('YYYY-MM-DD')
          : undefined,
        to: dayjs(listFilterProcessed?.to, 'YYYY-MM-DD', true).isValid()
          ? dayjs(listFilterProcessed?.to).format('YYYY-MM-DD')
          : undefined,
        status: listFilterProcessed?.status,
        limit: paginationProcessed?.per_page,
        is_full: '0',
        is_detail_booking: '0',
      }),
    );
  };

  const handleExportAllTickets = async () => {
    setLoadingExport(true);
    try {
      const res = await exportAllTicketsAPI(orderData?.booking_code);
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        setOpenExportAll(false);
        dispatch(getAmountOrder());
        getDetailOrder();
        if (typeModal === TypeModalOrder.PENDING) {
          handleGetListOrdersPending();
        } else {
          handleGetListOrdersProcessed();
        }
      } else {
        toast.error('Có lỗi xảy ra vui lòng thử lại!');
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra vui lòng thử lại!');
    }
    setLoadingExport(false);
  };

  const handleCancelAllTickets = async ({ cancel_note }: { cancel_note: string }) => {
    setLoadingCancel(true);
    try {
      getDetailOrder();
      const res = await cancelAllTicketsAPI({
        booking_code: orderData?.booking_code,
        cancel_note,
      });
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        dispatch(getAmountOrder());
        getDetailOrder();
        if (typeModal === TypeModalOrder.PENDING) {
          handleGetListOrdersPending();
        } else {
          handleGetListOrdersProcessed();
        }
      } else {
        toast.error('Có lỗi xảy ra vui lòng thử lại!');
      }
      setLoadingCancel(false);
    } catch (error) {
      toast.error('Có lỗi xảy ra vui lòng thử lại!');
    }
    setLoadingCancel(false);
  };

  const handleGetGuestSource = (source: string) => {
    if (!source) {
      return iconAvatar;
    } else {
      switch (source) {
        case 'facebook':
          return iconFacebook;
        case 'google':
          return iconGoogle;
        default:
          return source;
      }
    }
  };

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      open={openModalOrderData}
      onCancel={() => setOpenModalOrderData(false)}
      width={960}
      closeIcon={false}
      rootClassName="ModalOrderInfo"
    >
      <div>
        <Flex className="mb-2" align="center" justify="space-between">
          <p className="ModalOrderInfo__title mb-0">Thông tin đơn hàng ({orderData?.booking_code})</p>
          <Flex
            className="ModalOrderInfo__close"
            align="center"
            justify="center"
            onClick={() => setOpenModalOrderData(false)}
          >
            <img className="object-fit-scale" width={24} height={24} src={iconClose} alt="" />
          </Flex>
        </Flex>
        <Flex className="gap-x-8 text-color" align="center">
          <p className="fs-16 mb-0 fw-500">Tổng tiền đơn hàng: </p>
          <p className="mb-0 fs-16 fw-600">{formatPrice(orderData?.total_price, 0, ',')}đ</p>
          <p className="fs-16 mb-0 fw-500">
            {orderData?.payment_method === 'vnpay'
              ? '(VNPAY)'
              : orderData?.payment_method === 'reserve'
              ? '(Thanh toán sau)'
              : orderData?.payment_method === 'paid_at_company'
              ? '(Thanh toán tại nhà xe)'
              : '(Chuyển khoản)'}
          </p>
        </Flex>

        <Flex align="center" className="text-color gap-x-40 mb-8">
          <Flex className="gap-x-8 text-color" align="center">
            <p className="fs-16 mb-0 fw-500">Tổng tiền xuất vé:</p>
            <p className="mb-0 fs-16 fw-600">{formatPrice(orderData?.total_export_price, 0, ',')}đ</p>
          </Flex>
          <Flex className="gap-x-8 text-color" align="center">
            <p className="fs-16 mb-0 fw-500">Tổng tiền bảo hiểm:</p>
            <p className="mb-0 fs-16 fw-600">{formatPrice(orderData?.total_insurance, 0, ',')}đ</p>
          </Flex>
          <Flex className="gap-x-8 text-color" align="center">
            <p className="fs-16 mb-0 fw-500">Tổng tiền khuyến mãi:</p>
            <p className="mb-0 fs-16 fw-600">{formatPrice(orderData?.total_discount, 0, ',')}đ</p>
          </Flex>
        </Flex>
        {handleCheckArray(['cancel'], orderData?.status) && (
          <Flex className="gap-x-8 mb-8" align="center">
            <img width={14} height={14} className="object-fit-scale" src={iconCanCelFilled} alt="" />
            <p className="mb-0 fw-500 text-danger">Đơn hàng bị huỷ!</p>
          </Flex>
        )}
        <Flex className="gap-x-16 mb-12" align="center">
          <Flex className="ModalOrderInfo__avatar" align="center" justify="center">
            <img
              width={40}
              height={40}
              className="object-fit-cover"
              src={handleGetGuestSource(orderData?.guest?.source)}
              alt=""
            />
          </Flex>
          <Flex justify="space-between" className="flex-column h-100">
            <p className="text-color fw-600 fs-16 leading-24 mb-0">
              {orderData?.guest?.name ? orderData?.guest?.name : 'Không đăng nhập'}
            </p>
            <Flex className="gap-x-28" align="center">
              <Flex className="gap-x-8" align="center">
                <img width={20} height={20} className="object-fit-scale" src={iconPhone} alt="" />
                <p className="fs-16 fw-400 leading-24 text-color mb-0">
                  {orderData?.guest?.phone ? orderData?.guest?.phone : 'N/A'}
                </p>
              </Flex>
              <Flex className="gap-x-8" align="center">
                <img width={20} height={20} className="object-fit-scale" src={iconEmail} alt="" />
                <p className="fs-16 fw-400 leading-24 text-color mb-0">
                  {orderData?.guest?.email ? orderData?.guest?.email : 'N/A'}
                </p>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex className="gap-x-8 mb-12" align="center">
          <>
            {(handleCheckArray(['reserve'], orderData?.status) || handleCheckArray(['hold'], orderData?.status)) &&
              accountDetail?.group !== USER_GROUP.CARLINK && (
                <Button
                  className="ModalOrderInfo__button__done d-flex align-items-center gap-x-8 h-auto"
                  type="primary"
                  onClick={() => setOpenExportAll(true)}
                  loading={loadingExport}
                >
                  <img width={16} height={16} className="object-fit-scale" src={iconCheck} alt="" />
                  <p className="mb-0 text-white fw-500 leading-20">Xuất toàn bộ vé trong đơn hàng</p>
                </Button>
              )}
            {!(handleCheckArray(['reserve'], orderData?.status) || handleCheckArray(['hold'], orderData?.status)) && (
              <ConfigProvider theme={{ token: { colorPrimary: '#435869' } }}>
                <Button
                  className="ModalOrderInfo__button__history d-flex align-items-center gap-x-8 h-auto"
                  type="primary"
                  onClick={() => setOpenModalHistory(true)}
                >
                  <img width={16} height={16} className="object-fit-scale" src={iconHistory} alt="" />
                  <p className="mb-0 text-color-default fw-500 leading-20">Lịch sử đơn hàng</p>
                </Button>
              </ConfigProvider>
            )}
            {!handleCheckArray(['cancel'], orderData?.status) &&
              accountDetail?.group !== USER_GROUP.CARLINK &&
              !orderData?.data?.some((ticket) => ticket?.status === 'cancel') && (
                <Button
                  className="ModalOrderInfo__button__cancel d-flex align-items-center gap-x-8 h-auto"
                  type="primary"
                  loading={loadingCancel}
                  onClick={() => setOpenCancelAll(true)}
                >
                  <img width={16} height={16} className="object-fit-scale" src={iconCancel} alt="" />
                  <p className="mb-0 fw-500 leading-20">Hủy toàn bộ vé trong đơn hàng</p>
                </Button>
              )}
          </>
        </Flex>
        <ListTicket
          getListOrdersPending={handleGetListOrdersPending}
          getListOrdersProcessed={handleGetListOrdersProcessed}
          typeModal={typeModal}
          getDetailOrder={getDetailOrder}
          listTicket={orderData?.data}
        />
        {!handleCheckArray(['reserve'], orderData?.status) && (
          <Flex className="mt-8 gap-x-8" align="center">
            <p className="mb-0 fw-500" style={{ color: '#647c8f' }}>
              Nhân viên xử lý:
            </p>
            <p className="mb-0 text-color-default fw-500">{orderData?.payer}</p>
          </Flex>
        )}
      </div>
      <ModalExport
        title="Xuất toàn bộ vé trong đơn hàng"
        desc="Bạn đang thao tác xuất toàn bộ vé trong đơn hàng này, bạn có chắc chắn muốn xuất?"
        open={openExportAll}
        setOpen={setOpenExportAll}
        onExport={handleExportAllTickets}
      />
      <ModalCancel
        open={openCancelAll}
        setOpen={setOpenCancelAll}
        onCancelTicket={handleCancelAllTickets}
        booking_code={orderData?.booking_code}
        total_price={orderData?.total_price}
        total_cancel_fee={getTotalCancelFee}
        refund={getTotalRefund}
      />
      <ModalHistoryOrder
        openHistory={openModalHistory}
        setOpenHistory={setOpenModalHistory}
        booking_code={orderData?.booking_code}
        price={formatPrice(orderData?.total_price, 0, ',')}
        bank_transfer={orderData?.payment_method === 'bank_transfer' ? 'Chuyển khoản ngân hàng' : ''}
      />
    </Modal>
  );
};

export default ModalOrderData;
