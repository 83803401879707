import { PromotionParams } from '../types';
import { getAxiosClientWithToken } from './index';

export const getPromotionAPI = (params: any) => {
  return getAxiosClientWithToken().get('/promotion', { params });
};

export const createPromotionAPI = (params: PromotionParams) => {
  return getAxiosClientWithToken().post('/promotion', params);
};

export const updatePromotionAPI = (params: PromotionParams) => {
  return getAxiosClientWithToken().put(`/promotion/${params?.id}`, params);
};

export const updateStatusPromotionAPI = (params: any) => {
  return getAxiosClientWithToken().put(`/promotion/status/${params?.id}`, params);
};
