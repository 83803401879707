import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  Row,
} from "antd";
import React from "react";
import "../styles/ModalCancel.scss";
import iconConfirm from "../../../assets/images/orders/icon-confirm.svg";
import { formatPrice } from "../../../utils/helpers";

interface ModalCancelProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelTicket: ({ cancel_note }: { cancel_note: string }) => Promise<void>;
  booking_code?: string;
  ticket_code?: string;
  total_price: number;
  total_cancel_fee: number;
  refund: number;
  status?: string;
}

type FieldType = {
  cancel_reason: string;
  note: string;
};

const ModalCancel = ({
  open,
  setOpen,
  onCancelTicket,
  booking_code,
  ticket_code,
  total_price,
  total_cancel_fee,
  refund,
  status,
}: ModalCancelProps) => {
  const [form] = Form.useForm();
  const watchCancelReason = Form.useWatch("cancel_reason", form);

  const onSubmit = async () => {
    try {
      const res = await form.validateFields();

      if (res) {
        onCancelTicket({
          cancel_note:
            watchCancelReason === "diff"
              ? form.getFieldValue("note")
              : watchCancelReason,
        });
        setOpen(false);
        form.resetFields();
      }
    } catch (error) {}
  };

  const handleInputChange = (e: any) => {
    return e.target.value.replace(/^\s+/, "");
  };
  return (
    <Modal
      width={600}
      open={open}
      onCancel={() => setOpen(false)}
      rootClassName="ModalCancel"
      footer={null}
      closeIcon={false}
      maskClosable={false}
    >
      <Flex className="gap-x-16 mb-16" align="start">
        <img
          width={22}
          height={22}
          className="object-fit-scale"
          src={iconConfirm}
          alt=""
        />
        <div className="flex-grow-1">
          <p className="mb-8 text-color-gray-3A3A3A fs-16 fw-700">
            {booking_code ? "Hủy toàn bộ vé trong đơn hàng" : "Hủy vé"}
          </p>
          <p className="text-color-default fs-16 fw-500">
            {ticket_code
              ? "Bạn đang thao tác hủy vé này, bạn có chắc chắn muốn hủy?"
              : "Bạn đang thao tác hủy toàn bộ vé trong đơn hàng này, bạn có chắc chắn muốn hủy?"}
          </p>
        </div>
      </Flex>
      <Row gutter={12} className="mb-16">
        <Col span={8}>
          <p className="mb-8 fs-16 fw-500 text-color-default">Tổng tiền</p>
          <Flex
            className="ModalCancel__money"
            justify="space-between"
            align="center"
          >
            <p className="text-color-default fs-16 mb-0 fw-500">
              {formatPrice(total_price, 0, ",")}
            </p>
            <p className="text-color-gray-7B7B7B fs-16 mb-0 fw-500">VND</p>
          </Flex>
        </Col>
        <Col span={8}>
          <p className="mb-8 fs-16 fw-500 text-color-default">Tổng phí hủy</p>
          <Flex
            className="ModalCancel__money"
            justify="space-between"
            align="center"
          >
            <p className="text-color-default fs-16 mb-0 fw-500">
              {watchCancelReason !== "diff"
                ? formatPrice(total_cancel_fee, 0, ",")
                : 0}
            </p>
            <p className="text-color-gray-7B7B7B fs-16 mb-0 fw-500">VND</p>
          </Flex>
        </Col>
        <Col span={8}>
          <p className="mb-8 fs-16 fw-500 text-color-default">Hoàn tiền</p>
          <Flex
            className="ModalCancel__money"
            justify="space-between"
            align="center"
          >
            <p className="text-color-default fs-16 mb-0 fw-500">
              {watchCancelReason !== "diff"
                ? formatPrice(refund, 0, ",")
                : formatPrice(total_price, 0, ",")}
            </p>
            <p className="text-color-gray-7B7B7B fs-16 mb-0 fw-500">VND</p>
          </Flex>
        </Col>
      </Row>
      {status === "paid" && (
        <Checkbox className="mb-16" checked disabled>
          Gửi Email
        </Checkbox>
      )}
      <Form layout="vertical" form={form}>
        <Form.Item<FieldType>
          name="cancel_reason"
          label="Lý do"
          className="required mb-0"
          rules={[{ required: true, message: "Vui lòng chọn lý do!" }]}
        >
          <Radio.Group className="d-flex flex-column">
            <Radio value="Không liên lạc được với khách">
              Không liên lạc được với khách
            </Radio>
            <Radio value="Khách hàng yêu cầu hủy">Khách hàng yêu cầu hủy</Radio>
            <Radio value="Khách cân nhắc lại">Khách cân nhắc lại</Radio>
            <Radio value="diff">Lý do khác:</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          rules={[
            {
              required: watchCancelReason === "diff",
              message: "Vui lòng nhập lý do!",
            },
          ]}
          getValueFromEvent={handleInputChange}
          name="note"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
      <Flex className="justify-content-end gap-x-12">
        <Button
          type="primary"
          ghost
          onClick={() => {
            setOpen(false);
            form.resetFields();
          }}
        >
          Trở lại
        </Button>
        <Button type="primary" onClick={onSubmit}>
          {booking_code ? "Hủy đơn" : "Hủy vé"}
        </Button>
      </Flex>
    </Modal>
  );
};

export default ModalCancel;
