import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Flex,
  Image,
  Table,
  Select,
  Button,
  Divider,
  GetProps,
  DatePicker,
  Typography,
  ConfigProvider,
  type FormProps,
  type TableColumnsType,
} from "antd";
import "dayjs/locale/vi";
import dayjs from "dayjs";
import "./Report.scss";
import { formatPrice, handleGetUrlGuide } from "../../utils/helpers";
import IconFileExport from "../../components/Icons/IconFileExport";
import IconExportExcel from "../../components/Icons/IconExportExcel";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import { DataReport } from "../../types/report";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { Constant } from "../../config/constant";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setFilterRangeDate,
  setFilterOptions,
  getListReportRechargeAgent,
  getListReportRechargeAgentExcel,
  setPerPage
} from "../../redux/slice/report/reportRechargeAgentSlice";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import ExcelJS from "exceljs";
import moment from "moment";
import TutorialsVideo from "../../components/TutorialsVideo";

dayjs.locale("vn");
const { RangePicker } = DatePicker;
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

type FieldType = {
  options: string;
  range_date_report: string;
};

export const optionsOption = [
  {
    value: "request_time",
    label: "Thời gian yêu cầu",
  },
  {
    value: "confirmed_time",
    label: "Theo thời gian xác nhận",
  },
];

const ReportRechargeAgent = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [timeReport, setTimeReport] = useState<RangePickerProps["value"]>();

  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

  const pagination = useAppSelector(
    (state) => state?.reportRechargeAgent?.pagination
  );
  const listReport = useAppSelector(
    (state) => state?.reportRechargeAgent?.listReport
  );
  const loadingListReport = useAppSelector(
    (state) => state?.reportRechargeAgent?.loading
  );
  const totalPrices = useAppSelector(
    (state) => state?.reportRechargeAgent?.total_prices
  );
  const listFilter = useAppSelector(
    (state) => state?.reportRechargeAgent?.filter
  );
  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );
  const watchOptions = Form.useWatch("options", form);

  const onChangePage = async (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      dispatch(setPerPage(pageSize));
    } else {
      setPage(page);
    }
    await dispatch(
      getListReportRechargeAgent({
        options: watchOptions,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        page: pagination?.per_page !== pageSize ? Constant.DEFAULT_PAGE : page,
        limit: pageSize,
      })
    );
  };

  const onFinish: FormProps<any>["onFinish"] = async (values) => {
    setPage(Constant.DEFAULT_PAGE);
    const response = await dispatch(
      getListReportRechargeAgent({
        options: watchOptions,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
        page: Constant.DEFAULT_PAGE,
        limit: pagination?.per_page || limitPagination,
      })
    );
    if (response?.payload?.success) {
      toast.success(response?.payload?.message);
    }
  };

  const handleGetListReportBegin = async () => {
    setPage(Constant.DEFAULT_PAGE);
    if (listFilter?.from && listFilter?.to) {
      await dispatch(
        getListReportRechargeAgent({
          options: listFilter?.options,
          from: dayjs(listFilter?.from).format("YYYY-MM-DD"),
          to: dayjs(listFilter?.to).format("YYYY-MM-DD"),
          page: Constant.DEFAULT_PAGE,
          limit: pagination?.per_page || limitPagination,
        })
      );
    }
  };

  useEffect(() => {
    handleGetListReportBegin();
    if (listFilter?.from && listFilter?.to) {
      setTimeReport([
        dayjs(listFilter?.from, "YYYY-MM-DD"),
        dayjs(listFilter?.to, "YYYY-MM-DD"),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetLabelOptions = (value: string) => {
    const typeCurrent = optionsOption?.find((type) => type?.value === value);
    return typeCurrent?.label;
  };

  const onChangeTimeReport = (value: RangePickerProps["value"]) => {
    setTimeReport(value);
    dispatch(setFilterRangeDate(value));
  };

  const onChangeOptions = (type: string) => {
    dispatch(setFilterOptions(type));
  };

  const columnsExport: IExcelColumn[] = [
    {
      title: "Tài khoản nạp",
      dataIndex: "deposit_user",
      __excelTitle__: "Tài khoản nạp",
      width: 40,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Tài khoản xác nhận",
      dataIndex: "confirm_user",
      __excelTitle__: "Tài khoản xác nhận",
      width: 40,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Thời gian yêu cầu",
      dataIndex: "created_at",
      __excelTitle__: "Thời gian yêu cầu",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Thời gian nhà xe xác nhận",
      dataIndex: "updated_at",
      __excelTitle__: "Thời gian xác nhận",
      width: 30,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Nội dung nạp tiền",
      dataIndex: "content",
      __excelTitle__: "Nội dung nạp tiền",
      width: 40,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
      },
    },
    {
      title: "Số tiền yêu cầu",
      dataIndex: "amount",
      __cellType__: "TypeNumeric",
      __excelTitle__: "Số tiền yêu cầu",
      __numFmt__: "#,##0",
      width: 20,
      __style__: {
        fontName: "Times New Roman",
        fontSize: 14,
        border: true,
        borderColor: "black",
        width: 15,
        h: "right",
      },
    },
  ];

  const columns: TableColumnsType<DataReport> = [
    {
      title: "Tài khoản nạp",
      dataIndex: "deposit_user",
      render: (deposit_user) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {deposit_user?.name ? deposit_user?.name : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Tài khoản xác nhận",
      dataIndex: "confirm_user",
      render: (confirm_user) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {confirm_user?.name ? confirm_user?.name : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Thời gian yêu cầu",
      dataIndex: "created_at",
      render: (created_at) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {created_at ? moment(created_at).format("DD/MM/YYYY - HH:mm:ss") : ""}
        </Typography.Text>
      ),
      sorter: (a: any, b: any) =>
        moment(a?.created_at).unix() - moment(b?.created_at).unix(),
    },
    {
      title: "Thời gian nhà xe xác nhận",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {updated_at ? moment(updated_at).format("DD/MM/YYYY - HH:mm:ss") : ""}
        </Typography.Text>
      ),
      sorter: (a: any, b: any) =>
        moment(a?.updated_at).unix() - moment(b?.updated_at).unix(),
    },
    {
      title: "Nội dung nạp tiền",
      dataIndex: "content",
      render: (content) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {content ? content : ""}
        </Typography.Text>
      ),
    },
    {
      title: "Số tiền yêu cầu",
      width: 200,
      dataIndex: "amount",
      align: "right",
      render: (amount) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {amount ? formatPrice(amount, 0, ",") : 0}
        </Typography.Text>
      ),
    },
  ];

  const handleExportExcel = async () => {
    const response: any = await dispatch(
      getListReportRechargeAgentExcel({
        options: watchOptions,
        from: dayjs(timeReport?.[0]).format("YYYY-MM-DD"),
        to: dayjs(timeReport?.[1]).format("YYYY-MM-DD"),
      })
    );
    if (!response?.error) {
      const listData = response?.payload?.data;

      const totalPrices = response?.payload?.total_prices;

      const listDataExcel =
        listData?.length > 0
          ? listData?.map((report: DataReport) => ({
              created_at: report?.created_at
                ? `${moment(report?.created_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )}`
                : "",
              updated_at: report?.updated_at
                ? `${moment(report?.updated_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )}`
                : "",
              deposit_user: report?.deposit_user?.name
                ? report?.deposit_user?.name
                : "",
              confirm_user: report?.confirm_user?.name
                ? report?.confirm_user?.name
                : "",
              amount: report?.amount ? Number(report?.amount) : 0,
              content: report?.content ? report?.content : '',
            }))
          : [];

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Báo cáo tiền nạp");
      const header = columnsExport.map((col) => ({
        header: col.title,
        key: col.dataIndex as string,
      }));
      worksheet.columns = header;
      columnsExport.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width || 15;
        worksheet.getColumn(index + 1).alignment = {
          horizontal:
            (col?.__style__?.h as
              | "center"
              | "left"
              | "right"
              | "fill"
              | "justify") || "left",
          vertical: "middle",
        };
        worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || "general";
      });
      worksheet.addRows(listDataExcel);
      worksheet.getRow(1).font = {
        name: "Times New Roman",
        size: 12,
        bold: true,
      };
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (colNumber < 7) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "3AB54A" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
      let firstRowHasData = false;
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        if (cell.value) {
          firstRowHasData = true;
          return;
        }
      });
      if (firstRowHasData) {
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber > 1) {
            row.eachCell((cell) => {
              cell.font = { name: "Times New Roman", size: 12 };
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          }
        });
      } else {
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            let hasData = false;
            row.eachCell((cell) => {
              if (cell.value) {
                hasData = true;
                return;
              }
            });
            if (hasData) {
              row.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        });
      }
      const startColumnIndex = 1;

      const subLastRow = worksheet.addRow([]);
      subLastRow.getCell(1).value = "TỔNG (VND)";
      subLastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      subLastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };
      const endColumnIndexTotal = startColumnIndex + columnsExport.length - 2;
      worksheet.mergeCells(
        subLastRow.number,
        1,
        subLastRow.number,
        endColumnIndexTotal
      );
      subLastRow.getCell(6).value = totalPrices;
      subLastRow.eachCell((cell) => {
        cell.font = { name: "Times New Roman", size: 14, bold: true };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      const lastRow = worksheet.addRow([]);
      lastRow.getCell(1).value = "@Bản quyền thuộc nhà xe Carlink";
      lastRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      lastRow.getCell(1).font = {
        name: "Times New Roman",
        size: 14,
        bold: true,
      };

      const endColumnIndex = startColumnIndex + columnsExport.length - 1;
      worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Báo cáo tiền nạp.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };
  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return (
          Math.abs(current.diff(from, "days")) >=
          systemConfig?.export_report_month
        );
      }
      return false;
    },
    [systemConfig?.export_report_month]
  );
  return (
    <div className="Report bg-white">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          options: listFilter?.options,
          range_date_report: [
            listFilter?.from
              ? dayjs(listFilter?.from, "DD/MM/YYYY")
              : undefined,
            listFilter?.to ? dayjs(listFilter?.to, "DD/MM/YYYY") : undefined,
          ],
        }}
      >
        <Row gutter={12}>
          <Col className="Report__typeDateReport">
            <Form.Item<FieldType>
              className="required"
              name="options"
              label="Khoảng thời gian"
              rules={[
                { required: true, message: "Vui lòng chọn khoảng thời gian" },
              ]}
            >
              <Select
                placeholder="Theo thời gian yêu cầu"
                options={optionsOption}
                onChange={onChangeOptions}
              />
            </Form.Item>
          </Col>
          <Col className="Report__rangeDateReport">
            <Form.Item<FieldType>
              name="range_date_report"
              rules={[
                { required: true, message: "Vui lòng chọn khoảng thời gian" },
              ]}
            >
              <RangePicker
                disabledDate={disabledDaysDate}
                format="DD/MM/YYYY"
                onChange={onChangeTimeReport}
                popupClassName="Report__timeReport__picker"
                placeholder={["Từ ngày", "Đến ngày"]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex align="center" gap={"small"} className="mb-24">
          <Image
            preview={false}
            width={20}
            height={20}
            className="d-flex"
            src={IconExclamationCircle}
            alt="Icon Exclamation Circle"
          />
          <Typography.Text className="fs-14 fw-500 text-color">
            Vui lòng chọn khoảng thời gian để xem báo cáo
          </Typography.Text>
        </Flex>
        <Flex align="center" justify="space-between">
          <Form.Item className="Report__submit">
            <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
              <Button
                disabled={
                  timeReport?.length === 2 || (listFilter?.from && listFilter?.to)
                    ? false
                    : true
                }
                className="d-flex align-items-center h-auto"
                type="primary"
                htmlType="submit"
              >
                <IconFileExport width={16} height={16} />
                Xem báo cáo
              </Button>
            </ConfigProvider>
          </Form.Item>
          <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 2)} />
        </Flex>
        <Divider />
        <Flex>
          <ConfigProvider theme={{ token: { colorPrimary: "#435869" } }}>
            <Button
              ghost
              disabled={
                timeReport?.length === 2 || (listFilter?.from && listFilter?.to)
                  ? false
                  : true
              }
              onClick={handleExportExcel}
              className={`Report__exportExcel d-flex align-items-center mr-12 h-auto ${
                timeReport?.length === 2 || (listFilter?.from && listFilter?.to)
                  ? ""
                  : "disabled"
              }`}
              type="primary"
            >
              <IconExportExcel
                color={`${
                  timeReport?.length === 2 ||
                  (listFilter?.from && listFilter?.to)
                    ? "#435869"
                    : "#949494"
                }`}
                width={16}
                height={16}
              />
              Xuất Excel
            </Button>
          </ConfigProvider>
        </Flex>
        <Typography.Text className="d-block text-color-gray-3A3A3A fs-18 fw-600 mt-24 mb-8">
          Báo cáo tiền nạp ({handleGetLabelOptions(watchOptions)})
        </Typography.Text>
        <Flex wrap="wrap" align="center" className="Report__filter">
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Từ ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[0]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
          {timeReport && (
            <Flex align="center" className="Report__filter__item">
              <Typography.Text className="d-block fs-16 fw-500">
                Đến ngày:
              </Typography.Text>
              <Typography.Text className="d-block fs-16 fw-600">
                {dayjs(timeReport?.[1]).format("DD/MM/YYYY")}
              </Typography.Text>
            </Flex>
          )}
        </Flex>
      </Form>
      <Table
        className="Report__table"
        locale={{
          triggerDesc: "Sắp xếp tăng dần",
          triggerAsc: "Hủy sắp xếp",
          cancelSort: "Sắp xếp giảm dần",
        }}
        pagination={{
          total: pagination?.total,
          current: page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ["bottomRight"],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        dataSource={listReport?.length > 0 ? listReport : []}
        loading={loadingListReport}
        columns={columns}
        summary={(pageData) => {
          return (
            listReport?.length > 0 &&
            page === pagination?.last_page && (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={5}>
                    <Flex align="center" gap="middle">
                      <Typography.Text className="text-color-default fw-700 fs-16">
                        TỔNG
                      </Typography.Text>
                      <Typography.Text className="text-color-default fw-500 fs-16">
                        (VND)
                      </Typography.Text>
                    </Flex>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={5}>
                    <Typography.Text className="text-color-default text-end fw-700 fs-16">
                      {totalPrices ? formatPrice(totalPrices, 0, ",") : 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          );
        }}
      />
    </div>
  );
};

export default ReportRechargeAgent;
