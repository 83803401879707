import { FormInstance } from 'antd';
import { Rule } from 'antd/es/form';
import { RcFile } from 'antd/es/upload';
import { NamePath } from 'antd/lib/form/interface';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { first, get, isArray, isBoolean, isEmpty, isNumber, isObject, isString, keysIn } from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateFormat, StatusTrip, USER_GROUP } from '../config/constant';
import { useAppDispatch } from '../redux/hooks';
import { logout } from '../redux/slice/authSlice';
import { PERMISSIONS } from '../config/permission';

export function useFormValidate<S = undefined>(
  form: FormInstance,
  keyMultiple?: string[],
): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const [messageErrors, setMessageErrors] = useState<S>();

  useEffect(() => {
    if (!isEmpty(messageErrors) && isObject(messageErrors)) {
      const firstKeyError = first(keysIn(messageErrors));
      isString(firstKeyError) &&
        form.scrollToField(firstKeyError.replace(/\.[0-9]/g, ''), {
          behavior: 'smooth',
        });
      form.setFields(
        keysIn(messageErrors).map((key) => {
          let name: NamePath = first(key?.split(/\.+/g)) ?? key.replace(/\.\d/g, '');
          if (keyMultiple?.includes(name) && key.split(/\.+/g)?.length > 0) {
            name = key.split(/\.+/g)?.map((val) => (/\d+/g.test(val) ? Number(val) : val));
          }
          return {
            name,
            errors: get(messageErrors, key),
            touched: true,
          };
        }),
      );
    }
  }, [form, keyMultiple, messageErrors]);

  return [messageErrors, setMessageErrors];
}

export const mbTrim = (value?: any) => {
  if (isNumber(value) || (!isEmpty(value) && !isString(value)) || isBoolean(value)) {
    return value;
  }
  if (isEmpty(value)) {
    return '';
  }
  return value?.replace(/^[\s　]+/u, '').replace(/[\s　]+$/u, '');
};

export const handleFormData = (params: object) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    const value = get(params, key);
    if (isArray(value)) {
      [...value].forEach((val, number) => {
        if (isObject(val)) {
          Object.keys(val).forEach((item) => {
            if (!isEmpty(get(val, item))) {
              formData.append(`${key}[${number}][${item}]`, mbTrim(get(val, item)));
            }
          });
        } else {
          formData.append(`${key}[]`, mbTrim(val));
        }
      });
    } else {
      formData.append(key, mbTrim(value));
    }
    if (value === undefined) {
      formData.delete(key);
    }
  });
  return formData;
};
export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 767);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};

export function useRedirectStatusError(value?: number, action?: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    switch (value) {
      case 500:
        dispatch(action);
        navigate('/internal-sever', { replace: true });
        break;
      case 429:
        navigate('/internal-sever', { replace: true });
        break;
      case 404:
        dispatch(action);
        navigate('/404', { replace: true });
        break;
      case 401:
        dispatch(logout);
        navigate('/login', { replace: true });
        break;
      case 403:
        dispatch(action);
        toast.error('Bạn không có quyền thực hiện chức năng này');
        // destroyLogged();
        navigate('/forbidden', { replace: true });
        break;
      // case 400:
      //     navigate('/400', { replace: true });
      //     dispatch(action);
      //     break;
      default:
        break;
    }
  }, [navigate, value, dispatch, action]);
}

export const convertOption = (option: any, data: any) => {
  // @ts-ignore
  // eslint-disable-next-line array-callback-return
  data?.map(({ id, name }) => {
    option.push({
      value: id,
      label: name,
    });
  });
};

export const convertOptionAutoComplete = (option: any, data: any) => {
  // @ts-ignore
  // eslint-disable-next-line array-callback-return
  data?.map(({ id, name }) => {
    option.push({
      value: name,
      key: id,
    });
  });
};

export const convertOptionCheckBox = (option: any, data: any) => {
  // @ts-ignore
  // eslint-disable-next-line array-callback-return
  data?.map((item: any) => {
    option.push({
      value: item,
      label: item,
    });
  });
};

export const showSelectOption = (value: any, data: any, setdata: any) => {
  if (value === '') return;

  const filteredOptions = data.filter((option: { value?: string }) => {
    if (typeof option.value === 'string') {
      return option.value.toLowerCase().includes(value);
    }
    return false;
  });
  setdata(filteredOptions);
};

export const scrollToTop = (behavior?: ScrollBehavior) =>
  window.scroll({
    top: 0,
    left: 0,
    behavior: behavior,
  });

export const formatDate = (date: any, type: string) => {
  return dayjs(date).format(type);
};
export const maxValidator: (max: number, message: string) => Rule = (max, message) => {
  return () => ({
    validator(_, value) {
      const trimValue = mbTrim(value).replace(/\n/g, '');
      if (value && trimValue.length > max) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    },
  });
};

export const filterOption = (
  input: string,
  option?: {
    label?: any;
    value?: any;
  },
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
export const convertImgUrlToBase64 = async (url: string) => {
  await fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );
};

export const setLocalStorage = (key: string, value: any): void => {
  const tmpData: string = CryptoJS.AES.encrypt(JSON.stringify(value), process.env.REACT_APP_HASH_KEY!).toString();
  localStorage.setItem(key, tmpData);
};

export const getLocalStorage = (key: string): any => {
  const itemStr: string | null = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const tmpData: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(
    itemStr === undefined || itemStr === null ? '' : itemStr,
    process.env.REACT_APP_HASH_KEY!,
  );

  return JSON?.parse(tmpData?.toString(CryptoJS.enc.Utf8));
};
export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const formattedPrice = (value: any) => {
  if (!value) {
    return 0;
  } else {
    return value.toLocaleString('en-US');
  }
};

export const isFindIndex = (data: any, value: any) => {
  const index = data?.findIndex((item: any) => +item.id === +value);
  if (index !== -1) {
    return true;
  }
  return false;
};

export const getStatusTrip = (statusCode: any) => {
  switch (statusCode) {
    case 'canceled':
      return StatusTrip.canceled;
    case 'not_departed':
      return StatusTrip.not_departed;
    case 'departed':
      return StatusTrip.departed;
    default:
      return '';
  }
};

export function convertTimeFormat(timeString?: string) {
  if (!timeString) {
    return null;
  } else {
    const [hours, minutes] = timeString.split(':');
    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
  }
}

export const getPermission = (urls: any) => {
  const url = urls.split('/')[1];
  switch (url) {
    case 'bus-management':
      return 'manager_operating';
    case 'booking-management':
      return StatusTrip.not_departed;
    case 'departed':
      return StatusTrip.departed;
    default:
      return '';
  }
};

export const isReadUrl = (permissions: any, urls: any) => {
  const url = urls.split('/')[1];
  if (url === 'agency-management') return permissions.includes(urls);
  return permissions.includes(url);
};
export const checkAuthorized = (authority: string[], objectItem: any, listRule: string[]) => {
  if (authority && listRule.length > 0) {
    const isPermission = listRule?.filter((rule) => authority.includes(rule));
    if (isPermission.length > 0) return objectItem;
    else return { ...objectItem, hideInMenu: true };
  }
  return objectItem;
};

export const isPermission = (permission: any, userPermission: any) => {
  if (permission) {
    return permission?.includes(userPermission);
  } else return false;
};

export const isOutDate = (date: any) => {
  if (date) return moment(new Date()).format(DateFormat) <= moment(new Date(date)).format(DateFormat);
  return false;
};

// export const calculateTimeSum = (time1: any, time2: any) => {
//   const [hours1, minutes1] = time1.split(":").map(Number);
//   const [hours2, minutes2] = time2.split(":").map(Number);

//   let totalHours = hours1 + hours2;
//   let totalMinutes = minutes1 + minutes2;

//   if (totalMinutes >= 60) {
//     totalHours += Math.floor(totalMinutes / 60);
//     totalMinutes %= 60;
//   }
//   if (totalHours >= 24) {
//     totalHours %= 24;
//   }
//   if (totalHours < 10) {
//     totalHours = `0${totalHours}`;
//   }
//   if (totalMinutes < 10) {
//     totalMinutes = `0${totalMinutes}`;
//   }
//   return `${totalHours}:${totalMinutes}`;
// };
export const calculateTimeSum = (time1: any, time2: any) => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);
  let totalHours = hours1 + hours2;
  let totalMinutes = minutes1 + minutes2;
  if (totalMinutes >= 60) {
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;
  }
  if (totalHours >= 24) {
    totalHours %= 24;
  }
  let formattedHours = totalHours.toString();
  let formattedMinutes = totalMinutes.toString();
  if (totalHours < 10) {
    formattedHours = `0${formattedHours}`;
  }
  if (totalMinutes < 10) {
    formattedMinutes = `0${formattedMinutes}`;
  }
  return `${formattedHours}:${formattedMinutes}`;
};

export const getUrl = (item: any) => {
  return true;
};

export const getStatusBooking = (statusBooking: any) => {
  switch (statusBooking) {
    case 'reserve':
      return 'Đang giữ chỗ';
    case 'paid':
      return 'Đã thanh toán';
    case 'cancel':
      return 'Đã hủy';
    default:
      return 'Đã hủy';
  }
};

export const getStatusBookingTrip = (statusCode: any) => {
  switch (statusCode) {
    case 'canceled':
      return 'Đã hủy';
    case 'departed':
      return 'Đã xuất bến';
    case 'not_departed':
      return 'Chưa xuất bến';
    default:
      return 'Chưa xuất bến';
  }
};
export const getColorBookingTrip = (statusCode: any) => {
  switch (statusCode) {
    case 'canceled':
      return 'red';
    case 'departed':
      return 'green';
    default:
      return '';
  }
};
export const getGroupUser = (group: any) => {
  switch (group) {
    case 'car_company':
      return 'nhà xe';
    case 'agent':
      return 'đại lý';
    default:
      return 'Carlink';
  }
};

export const convertToWords = (number: number): string => {
  const numberWords = ['', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];
  const unitWords = ['', 'nghìn', 'triệu', 'tỷ'];

  const convertChunk = (chunk: number): string => {
    let result = '';
    const hundred = Math.floor(chunk / 100);
    const ten = Math.floor((chunk % 100) / 10);
    const one = chunk % 10;
    if (hundred > 0) {
      result += `${numberWords[hundred]} trăm `;
    }
    if (ten > 1) {
      result += `${numberWords[ten]} mươi `;
      if (one === 1) {
        result += 'mốt ';
      } else if (one === 5) {
        result += 'năm ';
      } else if (one > 0) {
        result += `${numberWords[one]} `;
      }
    } else if (ten === 1) {
      result += 'mười ';
      if (one > 0) {
        result += `${numberWords[one]} `;
      }
    } else if (one > 0) {
      result += `${numberWords[one]} `;
    }

    return result.trim();
  };
  if (number === 0) return 'Không';
  const chunks = [];
  while (number > 0) {
    chunks.push(number % 1000);
    number = Math.floor(number / 1000);
  }

  let words = '';
  for (let i = chunks.length - 1; i >= 0; i--) {
    const chunk = chunks[i];
    if (chunk !== 0) {
      words += `${convertChunk(chunk)} ${unitWords[i]} `;
    } else if (i > 0 && chunks[i - 1] > 0) {
      words += `không trăm `;
    }
  }
  words = words.trim().replace(/\s+/g, ' ');
  return words.charAt(0).toUpperCase() + words.slice(1);
};

export const convertMinutes = (totalMinutes: number): string => {
  if (!totalMinutes) {
    return '';
  } else {
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}h${formattedMinutes}`;
  }
};

export const formatPrice = (value: number, round = 0) => {
  const stringWithoutCommas = `${value}`?.replace(/,/g, '');

  return stringWithoutCommas;
};

export const formatPriceInput = (value: number) => {
  const stringWithoutCommas = `${value}`?.replace(/,/g, '');

  return `${stringWithoutCommas}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatTime = (timestamp: any) => {
  // Kiểm tra nếu timestamp là null hoặc không hợp lệ
  if (!timestamp) {
    return 'Ngày không hợp lệ';
  }

  // Lấy thời gian hiện tại
  const now: any = new Date();

  // Chuyển đổi chuỗi thời gian sang định dạng Date
  const date: any = new Date(timestamp);

  // Kiểm tra nếu date không hợp lệ
  if (isNaN(date.getTime())) {
    return 'Ngày không hợp lệ';
  }

  // Tính toán thời gian chênh lệch
  const timeDiff = Math.floor((now - date) / 1000);

  // Định dạng thời gian chênh lệch
  let formattedTime;
  if (timeDiff < 60) {
    formattedTime = 'vừa xong';
  } else if (timeDiff < 3600) {
    formattedTime = `${Math.floor(timeDiff / 60)} phút trước`;
  } else if (timeDiff < 86400) {
    formattedTime = `${Math.floor(timeDiff / 3600)} tiếng trước`;
  } else if (timeDiff < 2678400) {
    formattedTime = `${Math.floor(timeDiff / 86400)} ngày trước`;
  } else if (timeDiff < 31536000) {
    formattedTime = `${Math.floor(timeDiff / 2678400)} tháng trước`;
  } else {
    formattedTime = `${Math.floor(timeDiff / 31536000)} năm trước`;
  }

  return formattedTime;
};

export const formatTimeDDMMYYYY = (text: any) => {
  return moment(text).format('DD/MM/YYYY');
};

export const getIsoTimestamp = (dateString: any) => {
  const date = new Date(Date.parse(dateString));
  date.setHours(0, 0, 0);
  const formattedDate = date.toISOString();
  return formattedDate;
};

export const validateDateRange = (rule: any, value: any) => {
  if (!value || !Array.isArray(value) || value.length !== 2) {
    return Promise.reject('Vui lòng chọn cả ngày bắt đầu và ngày kết thúc');
  }

  const [startDate, endDate] = value;

  // Check for minimum difference (optional)
  if (startDate && endDate && endDate.diff(startDate, 'day') < 1) {
    return Promise.reject('Ngày kết thúc phải cách ngày bắt đầu ít nhất 1 ngày');
  }

  // Additional validation rules can be added here (e.g., maximum date range)

  return Promise.resolve(); // Validation successful
};
export const getCancelReason = (reason?: string) => {
  switch (reason) {
    case 'car_company':
      return 'Nhà xe hủy';
    case 'agent':
      return 'Đại lý hủy';
    case 'customer':
      return 'Khách hàng yêu cầu hủy';
    case 'overdue':
      return 'Quá hạn giữ chỗ';
    case 'system':
      return 'Quá hạn giữ chỗ';
    default:
      return reason;
  }
};

export const checkPermissionManagerAccount = (infoUser: any) => {
  if (infoUser?.permissions?.length > 0 && infoUser.group === USER_GROUP.AGENT) {
    const isManagerAccount = infoUser?.permissions?.includes('manager_agent_account');
    return isManagerAccount;
  }
  if (infoUser?.permissions?.length > 0 && infoUser.group === USER_GROUP.CAR_COMPANY) {
    const isManagerAccount = infoUser?.permissions?.includes('manager_company_account');
    return isManagerAccount;
  }
  if (infoUser?.permissions?.length > 0 && infoUser.group === USER_GROUP.CARLINK) {
    const isManagerAccount = infoUser?.permissions?.includes('manager_carlink_account');
    return isManagerAccount;
  }
  return false;
};

// Filter `option.label` match the user type `input`
export const filterOptionSearch = (input: string, option?: { label: string; value: string }) => {
  const label = option?.label || '';
  // Loại bỏ dấu tiếng Việt và chuyển đổi sang chữ thường
  const normalizedInput = input
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const normalizedLabel = label
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return normalizedLabel.includes(normalizedInput);
};

export const getPaymentMethod = (payment_method: string) => {
  switch (payment_method) {
    case 'agent_balance':
      return 'tài khoản đại lý';
    case 'cash':
      return 'tiền mặt';
    case 'bank_transfer':
      return 'chuyển khoản';
    case 'paid_at_company':
      return 'thanh toán tại nhà xe';
    default:
      return 'thanh toán sau';
  }
};
export const removeCommas = (value: any) => {
  if (value) {
    return value.replace(/,/g, '');
  } else {
    return;
  }
};

export const isUrlImage = (str: any) => {
  try {
    // Kiểm tra nếu chuỗi bắt đầu với "http://" hoặc "https://"
    const urlRegex = /^(https?:\/\/)/;
    return urlRegex.test(str);
  } catch (e) {
    return false;
  }
};

export const handleDisplayTime = (pastDate: string) => {
  const now = moment();
  const past = moment(pastDate);
  const duration = moment.duration(now.diff(past));
  if (duration.asWeeks() > 1) {
    return past.format('DD MMMM [at] HH:mm');
  } else {
    return past.fromNow();
  }
};

export const hasManagerCompanyAccount = (permissions: string[]): boolean => {
  return permissions.includes(PERMISSIONS.CAR_COMPANY.manager_company_account);
};

export const getPaymentMethodCarlinkApi = (payment_method: string) => {
  switch (payment_method) {
    case 'paid_at_company':
      return 'thanh toán tại nhà xe';
    case 'bank_transfer':
      return 'chuyển khoản';
    default:
      return 'thanh toán sau';
  }
};

export const getPlatformPromotion = (platform: string) => {
  switch (platform) {
    case 'all':
      return 'Tất cả nền tảng';
    case 'web':
      return 'Nền tảng web';
    default:
      return 'Nền tảng mobild';
  }
};
