import React, { useState } from "react";
import { Form, Input } from "antd";

interface PriceInputProps {
  onChange?: (value: any) => void;
  placeholder?: string;
  value?: any;
  name: any;
  rules?: any;
  form: any;
}

export const formatNumber = (value: number) => {
  return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const PriceInput: React.FC<PriceInputProps> = ({
  form,
  onChange,
  placeholder,
  name,
  rules,
}) => {
  const [inputValue, setInputValue] = useState<string>("0");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const currentValues = form.getFieldValue("price_json");
    const numericValue = parseFloat(rawValue?.replace(/[^0-9.]/g, "")); // Lấy ra số từ chuỗi
    if (!isNaN(numericValue)) {
      currentValues[name] = {
        name: currentValues[name].name,
        seat_price: formatNumber(numericValue),
      };
      form.setFieldsValue({
        price_json: currentValues,
      });
    } else {
      currentValues[name] = {
        name: currentValues[name].name,
        seat_price: 0,
      };
      form.setFieldsValue({
        price_json: currentValues,
      });
    }

    if (onChange) onChange(formatNumber(numericValue));
  };

  // Hàm định dạng số với dấu phân tách phần ngàn
  return (
    <Form.Item
      name={[name, "seat_price"]}
      className="form-item-name required"
      validateTrigger={["onChange", "onBlur"]}
      rules={rules}
    >
      <Input
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder ?? ""}
        addonAfter="VND"
      />
    </Form.Item>
  );
};

export default PriceInput;
