import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Image, Layout, Row, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Link from 'antd/es/typography/Link';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IcPlus from '../../assets/images/ic-plus.svg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import icEdit from '../../assets/images/ic-edit.svg';
import StatusFilter from '../../components/Modal/StatusFilter';
import { Constant } from '../../config/constant';
import { filterOption } from '../../lib/utils';
import PickupModal from './PickupModal';

import { getListProvinceWithPickupLocationsAPI } from '../../api/pickupLocations';
import TutorialsVideo from '../../components/TutorialsVideo';
import { fetchPickupLocation } from '../../redux/slice/pickupLocationSlice/pickupLocationSlice';
import { handleGetUrlGuide } from '../../utils/helpers';

function PickupLocation() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  //   const [keyword, setKeyword] = useState<ListPickupLocationRequest>({});
  const [page, setPage] = useState<number>(1);
  const [province_id, setFilterProvinceId] = useState<any>('');
  const [status, setStatus] = useState<boolean | undefined>();
  const [openRouteModal, setOpenRouteModal] = useState<boolean>(false);
  const [provinceWithLocation, setProvinceWithLocation] = useState<any>([]);
  const [detailPickupLocation, setDetailPickupLocation] = useState<any>({});
  const { result, isFetching, pagination } = useAppSelector((state) => state.pickupLocations);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);

  let query = `page=${page}&limit=${limit}&province_id=${province_id}${status!==undefined ? `&status=${status}` : ''} `; //page=1&limit=10&province_id=1

  const handleListPickupLocation = () => {
    dispatch(fetchPickupLocation({ query }));
  };
  useEffect(() => {
    handleListPickupLocation();
  }, [query]);

  const handleListProvincesApi = async () => {
    try {
      const res = await getListProvinceWithPickupLocationsAPI();
      if (res.status === 200) {
        setProvinceWithLocation(res.data.data);
      }
    } catch (error) {}
  };

  const provinceOptions: any = provinceWithLocation.length
    ? provinceWithLocation.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    : [];

  const handleChangeStatus = (status: boolean | undefined) => {
    setStatus(status);
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const onChangeProvince = (value: number) => {
    setFilterProvinceId(value);
  };

  const renderRowTable = (record: any) => {
    return record.status ? '' : 'bg-disabled';
  };

  const columns: ColumnsType<any> = [
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: '36px',
      render: (_, record) => (
        <Space size="middle">
          {record.status === Constant.STATUS_ACTIVE ? (
            <FontAwesomeIcon icon={faCircle} className="p-1 green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="p-1 danger" size="xs" />
          )}
        </Space>
      ),
    },
    {
      title: 'Tên điểm đón trả',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Tỉnh/ Thành phố',
      dataIndex: 'province',
      key: 'province',
      render: (_, record) => `${record.province.name}`,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '120px',
      render: (_, record: any) => (
        <Space size="middle">
          <Link
            onClick={() => {
              setDetailPickupLocation(record);
              setOpenRouteModal(true);
            }}
          >
            <Image src={icEdit} preview={false} />
          </Link>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    handleListProvincesApi();
  }, []);

  return (
    <Layout className="min-vh-70 list_driver">
      <Card>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        <Row className="fw-500 mt-4 mx-3">
          <Button
            type="primary"
            className={'btn-success btn-add'}
            size="large"
            onClick={() => {
              //   dispatch(setPickupLocation(undefined));
              setDetailPickupLocation({});
              setOpenRouteModal(true);
            }}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t('Thêm điểm đón trả')}
          </Button>
        </Row>
        <Form
          className="mg-16 mt-3 mx-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          // onFinish={onSearch}
          colon={false}
          style={{ position: 'relative' }}
        >
          <Form.Item className="label-search mb-0" labelAlign="left" labelCol={{ md: 0 }}>
            <Row gutter={10}>
              <Col xs={12} sm={12} md={5}>
                <Form.Item className="mb-0" name="depart_province">
                  <Select
                    className="input-h36"
                    showSearch
                    filterOption={filterOption}
                    placeholder="Tỉnh / thành phố"
                    size="large"
                    onChange={onChangeProvince}
                    style={{ width: '100%' }}
                    options={[{ value: '', label: 'Tất cả' }, ...provinceOptions]}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} className="ml-auto d-flex justify-content-end align-items-center">
                <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 4)} />
              </Col>
            </Row>
          </Form.Item>
          <Space size={10} align="center" style={{ position: 'absolute', right: 0, top: 7 }}>
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 4)} />
          </Space>
        </Form>
        <Table
          bordered={false}
          loading={isFetching}
          rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          rowKey={(record) => record?.id}
          // scroll={{ y: 1100 }}
          columns={columns}
          dataSource={result?.length > 0 ? result : undefined}
          className="mt-0 mx-3"
        />
      </Card>
      <PickupModal
        open={openRouteModal}
        setOpen={setOpenRouteModal}
        provinceWithLocation={provinceWithLocation}
        detailPickupLocation={detailPickupLocation}
        handleListPickupLocation={handleListPickupLocation}
        listTutorials={listTutorials}
      />
    </Layout>
  );
}

export default PickupLocation;
