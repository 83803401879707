import { Col, Image, Modal, Row } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import IconClose from "../../../../assets/images/listUser/iconsClose.svg";
import { DateFormatSearch, TimeFormat } from "../../../../config/constant";

const ModalInfoUser = ({
  isModalOpen,
  detailUser,
  handleCancelChildren,
}: any) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isModalOpen}
      closable={false}
      className="modal-info-user-children"
      title={
        <div
          className="title-modal-children-user"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Thông tin khác</div>
          <Image
            className="image-modal-children-user"
            onClick={handleCancelChildren}
            src={IconClose}
            alt="logo icon"
            preview={false}
          />
        </div>
      }
      footer={null}
      centered
      style={{ marginTop: "-105px" }}
      width={600}
    >
      <Row className="form-edit-user">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="container-form-user"
          style={{ padding: 0, borderColor: "transparent" }}
        >
          <Col className="info-user-other">
            <Row>
              <Col className="label">{t("Người tạo")}:</Col>
              <Col>{detailUser.creator}</Col>
            </Row>
            <Row>
              <Col className="label">{t("Ngày tạo")}:</Col>
              <Col>
                {" "}
                <span className="format-hour-user">
                  {moment(detailUser.created_at).format(TimeFormat)}
                </span>{" "}
                {moment(detailUser.created_at).format(DateFormatSearch)}{" "}
              </Col>
            </Row>
            <Row>
              <Col className="label">{t("Cập nhật lần cuối")}:</Col>
              <Col>
                {" "}
                <span className="format-hour-user">
                  {moment(detailUser.updated_at).format(TimeFormat)}{" "}
                </span>{" "}
                {moment(detailUser.updated_at).format(DateFormatSearch)}
              </Col>
            </Row>
            <Row>
              <Col className="label">{t("Lần truy cập cuối")}:</Col>
              <Col style={{ display: "flex" }}>
                <p style={{ marginRight: 28 }}>
                  {detailUser.last_login_at ? (
                    <>
                      <span className="format-hour-user">
                        {moment(detailUser.last_login_at).format(TimeFormat)}
                      </span>
                      {moment(detailUser.last_login_at).format(DateFormatSearch)}
                    </>
                  ) : (
                    ""
                  )}
                </p>
                {detailUser.last_login_IP_address ? (
                  <> IP {detailUser.last_login_IP_address}</>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInfoUser;
