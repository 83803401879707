import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { postMoveTicketApi, postUpdatePickUpApi } from '../../../api/booking';
import { Toast } from '../../../config/constant';
import { MoveTicketParams, MoveTicketState, UpdateLocationParams } from '../../../types/booking';
import { RootState } from '../../store';
import { setToast } from '../toastNotifySlice';
import { Action, CANCEL_TICKET_SUCCESS } from '../../../config/message';

export const postMoveTicket = createAsyncThunk(
  'admin/bookings/move-ticket',
  async (params: MoveTicketParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await postMoveTicketApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreate({ status: status, data: data }));
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: response?.data?.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const postUpdatePickUp = createAsyncThunk(
  'admin/bookings/update-booking-pickup',
  async (params: UpdateLocationParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await postUpdatePickUpApi(params);
      const { data, status } = response;
      if (status) {
        dispatch(setCreatePickUp({ status: status, data: data }));
      }
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setCreateError(response));
      dispatch(
        setToast({
          message: response?.data?.message,
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
export const moveTicketSlice = createSlice({
  name: 'moveTicket',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: 0,
    message: '',
    errorDetail: null,
    is_have_pickup: false,
    dataMoveTicket: [],
    statusUpdateLocation: 0,
  } as MoveTicketState,
  reducers: {
    setCreate: (state: MoveTicketState, { payload }) => {
      const { message } = payload.data;
      state.status = payload?.status;
      state.message = message;
      state.is_have_pickup = payload?.data?.data?.is_have_pickup;
      state.dataMoveTicket = payload?.data?.data?.newBooking;
    },
    setCreatePickUp: (state: MoveTicketState, { payload }) => {
      const { message } = payload.data;
      state.statusUpdateLocation = payload?.status;
      state.message = message;
    },
    setCreateError: (state: MoveTicketState, { payload }) => {
      state.status = payload?.status;
    },
    resetMoveTicket: (state: MoveTicketState) => {
      state.success = false;
      state.message = '';
      state.status = 0;
      state.is_have_pickup = false;
      state.dataMoveTicket = [];
      state.statusUpdateLocation = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postMoveTicket.pending, (state: MoveTicketState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(postMoveTicket.fulfilled, (state: MoveTicketState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(postMoveTicket.rejected, (state: MoveTicketState, actions) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = actions?.payload;
    });
    builder.addCase(postUpdatePickUp.pending, (state: MoveTicketState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(postUpdatePickUp.fulfilled, (state: MoveTicketState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(postUpdatePickUp.rejected, (state: MoveTicketState, actions) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.message = actions?.payload;
    });
  },
});

export const moveTicketSelector = (state: RootState) => state.moveTicket;
export const { setCreate, setCreateError, resetMoveTicket, setCreatePickUp } = moveTicketSlice.actions;
