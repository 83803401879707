import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../../api";
import { updateStatusApiAPI } from "../../../api/followApi";
import { Toast } from "../../../config/constant";
import { ListBusState } from "../../../types";
import { RootState } from "../../store";
import { setToast } from "../toastNotifySlice";

export const updateStatusFollowApi = createAsyncThunk('admin/status/followApi', async (params: { id: string, status: number }, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateStatusApiAPI(params);
        const {
            data,
            status
        } = response;
        if (status) {
            dispatch(setUpdate({status: status, data: data}));
            dispatch(setToast({
                message: "Khóa API thành công",
                status: true,
                type: Toast.TYPE_SUCCESS,
            }))
        }
        return true;
    } catch (error: any) {
        const {response} = error;
        dispatch(setUpdateError(response));
        dispatch(setToast({
            message: response?.data?.message ?? 'Khóa API thất bại',
            status: true,
            type: Toast.TYPE_ERROR,
        }))
        return rejectWithValue(getErrorMessage(error));
    }
});
export const updateFollowApiSlice = createSlice({
    name: 'updateStatusApi',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        message: '',
        errorDetail: null,
    } as ListBusState,
    reducers: {
        setUpdate: (state: ListBusState, {payload}) => {
            const {message} = payload.data;
            state.status = payload?.status;
            state.message = message;
        },
        setUpdateError: (state: ListBusState, {payload}) => {
            const {errors} = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetUpdateFollow: (state: ListBusState) => {
            state.success = false;
            state.status = 0;
            state.message = '';
            state.errorDetail = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateStatusFollowApi.pending, (state: ListBusState) => {
            state.loading = true;
        });
        builder.addCase(updateStatusFollowApi.fulfilled, (state: ListBusState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateStatusFollowApi.rejected, (state: ListBusState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });

        // builder.addCase(updateStatusBus.pending, (state: ListBusState) => {
        //     state.loading = true;
        // });
        // builder.addCase(updateStatusBus.fulfilled, (state: ListBusState) => {
        //     state.loading = false;
        //     state.success = true;
        //     state.error = false;
        // });
        // builder.addCase(updateStatusBus.rejected, (state: ListBusState, {payload}) => {
        //     state.loading = false;
        //     state.success = false;
        //     state.error = true;
        //     state.message = payload;
        // });
    },
});

export const updateFollowApiSelector = (state: RootState) => state.updateFollowApi;
export const {setUpdate, setUpdateError, resetUpdateFollow} = updateFollowApiSlice.actions;
