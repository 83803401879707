import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../api';
import { login, logoutApi, refreshTokenAPI } from '../../api/auth';
import { Constant, Toast } from '../../config/constant';
import { destroyLogged, saveAccessToken, saveRefreshToken } from '../../utils/jwt';
import { setEmptyInfoUser } from '../slice/auth/accountSlice';
import { RootState } from '../store';
import { logout, setAuth } from './authSlice';
import { resetDataSearchBooking, setListBookingLoad } from './bookings/listBooking';
import { setToast } from './toastNotifySlice';
import { resetBooking, resetPinCode, setBookingForAgentId } from './bookings/createBookingSlice';

export type LoginState = {
  loading: boolean;
  success: boolean;
  error: boolean;
  messages: string | LoginValidate;
};

export type LoginValidate = {
  account: string | string[];
  password: string | string[];
};

export type LoginRequest = {
  account: string;
  password: string;
};

export const asyncLogin = createAsyncThunk('/login', async (params: LoginRequest, { dispatch, rejectWithValue }) => {
  try {
    const response = await login(params);
    const { status, data } = response;
    if (status === Constant.DEFAULT_STATUS) {
      saveAccessToken(data?.access_token);
      saveRefreshToken(data?.refresh_token);
      window.localStorage.setItem('limit_pagination', data?.user?.limit_pagination);
      dispatch(setAuth(data));
      dispatch(
        setToast({
          message: `Xin chào ${data?.user.name}`,
          type: Toast.TYPE_SUCCESS,
          status: true,
        }),
      );
      return true;
    }
    return false;
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});

export const refreshToken = createAsyncThunk('/refreshTokenAPI', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await refreshTokenAPI();
    const { status, data } = response;
    if (status === Constant.DEFAULT_STATUS) {
      dispatch(setAuth(data));
      return true;
    }
    return false;
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});

export const asyncLogout = createAsyncThunk('/logout', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await logoutApi();
    const { status } = response;
    if (status === Constant.DEFAULT_STATUS) {
      destroyLogged();
      dispatch(logout());
      dispatch(setEmptyInfoUser());
      dispatch(resetDataSearchBooking());
      dispatch(setListBookingLoad());
      dispatch(resetBooking());
      dispatch(setBookingForAgentId(undefined));
      dispatch(resetPinCode());
      return true;
    }
    return false;
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    loading: false,
    success: false,
    error: false,
    messages: '',
  } as LoginState,
  reducers: {
    resetLoginState: (state: LoginState) => {
      state.error = false;
      state.success = false;
      state.messages = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncLogin.pending, (state: LoginState) => {
        state.loading = true;
      })
      .addCase(asyncLogin.fulfilled, (state: LoginState) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(asyncLogin.rejected, (state: LoginState, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.messages = payload as string | LoginValidate;
      });
  },
});

export const loginSelector = (state: RootState) => state.login;
export const { resetLoginState } = loginSlice.actions;
