import React from 'react';

const DEFAULT_TEXT = 'N/A';

const TextDisplay = ({
    className,
    text,
    defaultText = DEFAULT_TEXT,
}: {
    className?: string;
    text?: string | number;
    defaultText?: string;
}) => {
    if (typeof text === 'undefined' || text === null || text === '') {
        return <span className={ className }> { defaultText } </span>;
    }
    return <span className={ className }> { text } </span>;
};

export default TextDisplay;
