import React from "react";
import { Button, Flex, Modal } from "antd";
import iconConfirm from "../../../assets/images/orders/icon-confirm.svg";

interface ModalExportProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  desc: string;
  onExport: () => void;
}

const ModalExport = ({
  open,
  setOpen,
  title,
  desc,
  onExport,
}: ModalExportProps) => {
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      rootClassName="ModalExport"
      footer={null}
      closeIcon={false}
      maskClosable={false}
    >
      <Flex className="gap-x-16 mb-16" align="start">
        <img
          width={22}
          height={22}
          className="object-fit-scale"
          src={iconConfirm}
          alt=""
        />
        <div className="flex-grow-1">
          <p className="mb-8 text-color-gray-3A3A3A fs-16 fw-700">{title}</p>
          <p className="text-color-default fs-16 fw-500">{desc}</p>
        </div>
      </Flex>
      <Flex className="justify-content-end gap-x-12">
        <Button type="primary" ghost onClick={() => setOpen(false)}>
          Trở lại
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onExport();
            setOpen(false);
          }}
        >
          Xuất vé
        </Button>
      </Flex>
    </Modal>
  );
};

export default ModalExport;
