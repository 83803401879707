import { Action, combineReducers, configureStore, Middleware, ThunkAction } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Constant, Toast } from '../config/constant';
import { getAccessToken } from '../utils/jwt';
import { createAgentSlice } from './slice/agents/createAgentSlice';
import { listAgentSlice } from './slice/agents/listAgentSlice';
import { updateAgentSlice } from './slice/agents/updateAgentSlice';
import accountReducer from './slice/auth/accountSlice';
import { authSlice } from './slice/authSlice';
import { cancelBookingSlice } from './slice/bookings/cancelBookingSlice';
import { createBookingSlice } from './slice/bookings/createBookingSlice';
import { destroyBookingSlice } from './slice/bookings/destroyBookingSlice';
import { getTicketCodeSlice } from './slice/bookings/getTicketCodeSlice';
import { holdingBookingSlice } from './slice/bookings/holdingBookingSlice';
import { listBookingSlice } from './slice/bookings/listBooking';
import { lockAllSeatSlice } from './slice/bookings/lockAllSeatSlice';
import { moveTicketSlice } from './slice/bookings/moveTicketSlice';
import { printTicketSlice } from './slice/bookings/printTicketSlice';
import { updateBookingSlice } from './slice/bookings/updateBooking';
import { updateDriverBookingSlice } from './slice/bookings/updateDriverSlice';
import { createBusCompanySlice } from './slice/busCompanys/createBusCompanySlice';
import { listBusCompanySlice } from './slice/busCompanys/listBusCompanySlice';
import { updateBusCompanySlice } from './slice/busCompanys/updateBusCompanySlice';
import { listBusSlice } from './slice/buses/bus';
import { createBusSlice } from './slice/buses/createBus';
import { updateBusSlice } from './slice/buses/updateBus';
import { createBusTypeSlice } from './slice/busTypes/createBusTypeSlice';
import { listBusTypeSlice } from './slice/busTypes/listBusTypeSlice';
import { updateBusTypeSlice } from './slice/busTypes/updateBusTypeSlice';
import { cancelContractSlice } from './slice/contracts/cancelContract';
import { listContractSlice } from './slice/contracts/listContractSlice';
import { createDriverSlice } from './slice/createDriver';
import { userSlice } from './slice/createUserSlice';
import dashboardReducerUser from './slice/dashboard/userSlice';
import { driverSlice } from './slice/driverSlice';
import { createApiSlice } from './slice/followApi/createApi';
import { listFollowApiSlice } from './slice/followApi/getListFollowApi';
import { updateFollowApiSlice } from './slice/followApi/lockFollowApi';
import generalAgentReducer from './slice/generalAgent/generalAgent';
import historyReducer from './slice/history/historySlice';
import listRegisterReducer from './slice/register/registerPM';
import bookingReducer from './slice/home/bookingSlice';
import { loginSlice } from './slice/loginSlice';
import listBusReducer from './slice/manageBus/manageBusSlice';
import notificationReducer from './slice/notify/notificationSlice';
import notifyReducer from './slice/notify/notifySlice';
import { createOfficeSlice } from './slice/offices/createOfficeSlice';
import { listOfficeSlice } from './slice/offices/listOfficeSlice';
import { ordersPendingSlide } from './slice/orders/ordersPendingSlide';
import { ordersProcessedSlide } from './slice/orders/ordersProcessedSlide';
import { ordersTicketSlide } from './slice/orders/ordersTicketSlice';
import { pickupLocationSlice } from './slice/pickupLocationSlice';
import pickupLocationReducer from './slice/pickupLocationSlice/pickupLocationSlice';
import { listPolicySlice } from './slice/policies/listPolicySlice';
import { provinceSlice } from './slice/provinceSlice';
import { reportBalanceChangeAgentSlice } from './slice/report/reportBalanceChangeAgentSlice';
import { reportBalanceChangeMainAgentSlice } from './slice/report/reportBalanceChangeMainAgentSlice';
import { reportCancelAgentSlice } from './slice/report/reportCancelAgentSlice';
import { reportCancelCarlinkSlice } from './slice/report/reportCancelCarlinkSlice';
import { reportCancelCompanySlice } from './slice/report/reportCancelCompanySlice';
import { reportCancelMainAgentSlice } from './slice/report/reportCancelMainAgentSlice';
import { reportContractAgentSlice } from './slice/report/reportContractAgentSlice';
import { reportContractCompanySlice } from './slice/report/reportContractCompanySlice';
import { reportCustomerSlice } from './slice/report/reportCustomerSlice';
import { reportDepositPromotionCompanyCarlinkSlice } from './slice/report/reportDepositPromotionCompanyCarlinkSlice';
import { reportDetailAgentSlice } from './slice/report/reportDetailAgentSlice';
import { reportDetailCompanySlice } from './slice/report/reportDetailCompanySlice';
import { reportDetailMainAgentSlice } from './slice/report/reportDetailMainAgentSlice';
import { reportDriverSlice } from './slice/report/reportDriverSlice';
import { reportExportTicketSlice } from './slice/report/reportExportTicketSlice';
import { reportFeeDepartCompanyCarlinkSlice } from './slice/report/reportFeeDepartCompanyCarlinkSlice';
import { reportFeeDepartSlice } from './slice/report/reportFeeDepartSlice';
import { reportFeeExportTicketCompanyCarlinkSlice } from './slice/report/reportFeeExportTicketCompanyCarlinkSlice';
import { reportRechargeAgentSlice } from './slice/report/reportRechargeAgentSlice';
import { reportRechargeCarlinkSlice } from './slice/report/reportRechargeCarlinkSlice';
import { reportRechargeCompanyCarlinkSlice } from './slice/report/reportRechargeCompanyCarlinkSlice';
import { reportRechargeCompanySlice } from './slice/report/reportRechargeCompanySlice';
import { reportSourceAPISlice } from './slice/report/reportSourceAPISlice';
import { reportTotalAgentSlice } from './slice/report/reportTotalAgentSlice';
import { reportTotalRouteAgentSlice } from './slice/report/reportTotalRouteAgentSlice';
import { reportTotalRouteCompanySlice } from './slice/report/reportTotalRouteCompanySlice';
import { reportTotalSlice } from './slice/report/reportTotalSlice';
import { routeSlice } from './slice/routeSlice';
import { createScheduleSlice } from './slice/schdules/createSchedule';
import { ListScheduleSlice } from './slice/schdules/scheduleSlice';
import { updateScheduleSlice } from './slice/schdules/updateSchedule';
import { createServiceSlice } from './slice/services/createService';
import { listServiceSlice } from './slice/services/serviceSlice';
import { updateServiceSlice } from './slice/services/updateService';
import promotionHistoryReducer from './slice/system/historyPromotion';
import { tutorialsSlice } from './slice/system/tutorialsSlice';
import systemConfigReducer from './slice/systemConfig/systemConfig';
import { ticketsSlice } from './slice/tickets/ticketsSlice';
import { setToast, toastSlice } from './slice/toastNotifySlice';
import { createTripSlice } from './slice/trips/createTrip';
import { listTripSlice } from './slice/trips/tripSlice';
import { updateTripSlice } from './slice/trips/updateTrip';
import { listUserSlice } from './slice/userSlice';
import { listRegisterAdvertiseSlice } from './slice/listRegisterAdvertise';
import countRegistrationsReducer from './slice/register/countRegistrations';
import { sendMailSlice } from './slice/bookings/sendMailSlice';
import { promotionSlice } from './slice/promotions/promotionSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [''],
  blacklist: [''],
};
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['email', 'name', 'id', 'role', 'lang', 'isShowMenu', 'isLogin', 'group', 'accountUser', 'is_superadmin'],
};
const bookingSearchPersistConfig = {
  key: 'search',
  storage,
  whitelist: ['dataSearchBooking', 'isShowAllFloor'],
};
const createBookingPersisConfig = {
  key: 'booking',
  storage,
  blacklist: ['pin_code'],
  whitelist: [''],
};
const ListSchedulePersisConfig = {
  key: 'schedule',
  storage,
  whitelist: ['listSegment'],
};
const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  login: loginSlice.reducer,
  listUsers: listUserSlice.reducer,
  driver: driverSlice.reducer,
  user: userSlice.reducer,
  createBus: createBusSlice.reducer,
  listBus: listBusSlice.reducer,
  createDriver: createDriverSlice.reducer,
  updateBus: updateBusSlice.reducer,
  listService: listServiceSlice.reducer,
  createService: createServiceSlice.reducer,
  updateService: updateServiceSlice.reducer,
  toastNotify: toastSlice.reducer,
  updateCompanyBusSlice: updateBusCompanySlice.reducer,
  createBusCompanySlice: createBusCompanySlice.reducer,
  listBusCompanySlice: listBusCompanySlice.reducer,
  listAgentSlice: listAgentSlice.reducer,
  createAgentSlice: createAgentSlice.reducer,
  updateAgentSlice: updateAgentSlice.reducer,
  route: routeSlice.reducer,
  province: provinceSlice.reducer,
  pickupLocation: pickupLocationSlice.reducer,
  listBusType: listBusTypeSlice.reducer,
  listSchedule: persistReducer(ListSchedulePersisConfig, ListScheduleSlice.reducer),
  createBusType: createBusTypeSlice.reducer,
  updateBusType: updateBusTypeSlice.reducer,
  listOffice: listOfficeSlice.reducer,
  createOffice: createOfficeSlice.reducer,
  listPolicy: listPolicySlice.reducer,
  createSchedule: createScheduleSlice.reducer,
  updateSchedule: updateScheduleSlice.reducer,
  sendMailOrder: sendMailSlice.reducer,
  authAccount: accountReducer,
  manageBus: listBusReducer,
  systemConfig: systemConfigReducer,
  holdingBooking: holdingBookingSlice.reducer,
  createBooking: persistReducer(createBookingPersisConfig, createBookingSlice.reducer),
  updateBooking: updateBookingSlice.reducer,
  listTrip: listTripSlice.reducer,
  listBooking: persistReducer(bookingSearchPersistConfig, listBookingSlice.reducer),
  updateTrip: updateTripSlice.reducer,
  createTrip: createTripSlice.reducer,
  cancelBooking: cancelBookingSlice.reducer,
  driverBooking: updateDriverBookingSlice.reducer,
  destroyBooking: destroyBookingSlice.reducer,
  listContract: listContractSlice.reducer,
  cancelContract: cancelContractSlice.reducer,
  printTicket: printTicketSlice.reducer,
  history: historyReducer,
  pickupLocations: pickupLocationReducer,
  ticketCode: getTicketCodeSlice.reducer,
  tickets: ticketsSlice.reducer,
  listNotify: notifyReducer,
  listNotification: notificationReducer,
  listBookingHome: bookingReducer,
  listDashboardUser: dashboardReducerUser,
  generalAgent: generalAgentReducer,
  reportDetailCompany: reportDetailCompanySlice.reducer,
  reportDetailAgent: reportDetailAgentSlice.reducer,
  reportTotalRouteCompany: reportTotalRouteCompanySlice.reducer,
  reportTotalRouteAgent: reportTotalRouteAgentSlice.reducer,
  reportTotalAgent: reportTotalAgentSlice.reducer,
  reportDriver: reportDriverSlice.reducer,
  reportCustomer: reportCustomerSlice.reducer,
  reportCancelCompany: reportCancelCompanySlice.reducer,
  reportCancelAgent: reportCancelAgentSlice.reducer,
  reportContractCompany: reportContractCompanySlice.reducer,
  reportContractAgent: reportContractAgentSlice.reducer,
  reportRechargeCompany: reportRechargeCompanySlice.reducer,
  reportRechargeAgent: reportRechargeAgentSlice.reducer,
  reportTotal: reportTotalSlice.reducer,
  reportBalanceChangeAgent: reportBalanceChangeAgentSlice.reducer,
  reportRechargeCarlink: reportRechargeCarlinkSlice.reducer,
  reportFeeDepart: reportFeeDepartSlice.reducer,
  reportExportTicket: reportExportTicketSlice.reducer,
  promotionHistory: promotionHistoryReducer,
  reportRechargeCompanyCarlink: reportRechargeCompanyCarlinkSlice.reducer,
  reportFeeDepartCompanyCarlink: reportFeeDepartCompanyCarlinkSlice.reducer,
  reportDepositPromotionCompanyCarlink: reportDepositPromotionCompanyCarlinkSlice.reducer,
  reportFeeExportTicketCompanyCarlink: reportFeeExportTicketCompanyCarlinkSlice.reducer,
  reportDetailMainAgent: reportDetailMainAgentSlice.reducer,
  reportSourceAPI: reportSourceAPISlice.reducer,
  tutorials: tutorialsSlice.reducer,
  ReportFeeExportTicketCompanyCarlink: reportFeeExportTicketCompanyCarlinkSlice.reducer,
  lockAllSeat: lockAllSeatSlice.reducer,
  moveTicket: moveTicketSlice.reducer,
  ordersPending: ordersPendingSlide.reducer,
  ordersProcessed: ordersProcessedSlide.reducer,
  ordersTicket: ordersTicketSlide.reducer,
  listFollowApi: listFollowApiSlice.reducer,
  updateFollowApi: updateFollowApiSlice.reducer,
  createApi: createApiSlice.reducer,
  reportCancelCarlink: reportCancelCarlinkSlice.reducer,
  reportCancelMainAgent: reportCancelMainAgentSlice.reducer,
  reportBalanceChangeMainAgent: reportBalanceChangeMainAgentSlice.reducer,
  listRegisterAdvertise: listRegisterAdvertiseSlice.reducer,
  listRegisterPM: listRegisterReducer,
  countRegistrations: countRegistrationsReducer,
  listPromotion: promotionSlice.reducer,
});
// const forbiddenMiddleware: Middleware = ({getState, dispatch}) => (next) => async (action) => {
//     const state = getState();
//     const isAuthenticated = state.auth.token;
//     if (action?.payload?.response?.status == Constant.FORBIDDEN) {
//         if (isAuthenticated) {
//             dispatch(setToast({
//                 message: "không có quyền",
//                 status: true,
//                 type: Toast.TYPE_ERROR,
//             }))
//             window.location.href = '/forbidden'
//         } else {
//             dispatch(setToast({
//                 message: "Truy cập bị Từ chối! Người dùng chưa đăng nhập.",
//                 status: true,
//                 type: Toast.TYPE_ERROR,
//             }))
//         }
//     } else if (action?.payload === Constant.UN_AUTH) {
//         if (isAuthenticated) {
//             dispatch(setToast({
//                 message: "Có lỗi sảy ra vui lòng tải lại trang hoặc bấm F5",
//                 status: true,
//                 type: Toast.TYPE_ERROR,
//             }))
//             await refreshTokenAPI().then((response) => {
//                 if (response.status === Constant.DEFAULT_STATUS) {
//                     return dispatch(setAuth(response.data));
//                 } else {
//                     // dispatch(logout)
//                     // dispatch(setToast({
//                     //     message: Message.UN_AUTH,
//                     //     status: true,
//                     //     statusCode: Constant.UN_AUTH_CODE
//                     // }))
//                 }
//             })
//         } else {
//             return
//         }
//     } else {
//         next(action);
//     }
// };

// const forbiddenMiddleware: Middleware = ({ getState, dispatch }) => {
//   let hasError = false; // Biến cờ để kiểm tra xem đã có lỗi xảy ra hay không

//   return (next) => async (action) => {
//     const state = getState();
//     const isAuthenticated = state.auth.token;

//     if (hasError) {
//       return; // Nếu đã có lỗi, không gọi next(action) và kết thúc middleware
//     }

//     if (action?.payload?.response?.status == Constant.FORBIDDEN) {
//       hasError = true; // Đặt cờ lỗi thành true khi gặp lỗi
//       if (isAuthenticated) {
//         next(
//           setToast({
//             message: "không có quyền",
//             status: true,
//             type: Toast.TYPE_ERROR,
//           })
//         );
//         window.location.href = "/forbidden";
//       } else {
//         next(
//           setToast({
//             message: "Truy cập bị Từ chối! Người dùng chưa đăng nhập.",
//             status: true,
//             type: Toast.TYPE_ERROR,
//           })
//         );
//       }
//     } else if (action?.payload === Constant.UN_AUTH) {
//       hasError = true; // Đặt cờ lỗi thành true khi gặp lỗi
//       if (isAuthenticated) {
//         next(
//           setToast({
//             message: "Có lỗi sảy ra vui lòng tải lại trang hoặc bấm F5",
//             status: true,
//             type: Toast.TYPE_ERROR,
//           })
//         );
//         await refreshTokenAPI()
//           .then((response) => {
//             if (response.status === Constant.DEFAULT_STATUS) {
//               next(setAuth(response.data));
//               return;
//             } else {
//               next(logout);
//               next(
//                 setToast({
//                   message: Message.UN_AUTH,
//                   status: true,
//                   statusCode: Constant.UN_AUTH_CODE,
//                 })
//               );
//               return;
//             }
//           })
//           .then(() => {
//             next(
//               setToast({
//                 message: "Có lỗi sảy ra vui lòng tải lại trang hoặc bấm F5",
//                 status: true,
//                 type: Toast.TYPE_ERROR,
//               })
//             );
//           });
//       } else {
//         return; // Dừng lại ở đây và không gọi next(action)
//       }
//     } else {
//       next(action); // Gọi next(action) trong các trường hợp khác
//     }
//   };
// };

const forbiddenMiddleware: Middleware = ({ getState, dispatch }) => {
  let hasError = false; // Biến cờ để kiểm tra xem đã có lỗi xảy ra hay không
  return (next) => async (action) => {
    const isAuthenticated = getAccessToken();
    if (hasError) {
      return; // Nếu đã có lỗi, không gọi next(action) và kết thúc middleware
    }
    if (action?.payload?.response?.status === Constant.FORBIDDEN) {
      hasError = true; // Đặt cờ lỗi thành true khi gặp lỗi
      if (isAuthenticated) {
        next(
          setToast({
            message: 'Bạn không có quyền thực hiện chức năng này',
            status: true,
            type: Toast.TYPE_ERROR,
          }),
        );
        window.location.href = '/forbidden';
        // destroyLogged();
      } else {
        next(
          setToast({
            message: 'Truy cập bị Từ chối! Người dùng chưa đăng nhập.',
            status: true,
            type: Toast.TYPE_ERROR,
          }),
        );
      }
    } else if (action?.payload === Constant.UN_AUTH) {
      hasError = true; // Đặt cờ lỗi thành true khi gặp lỗi
      if (isAuthenticated) {
        next(
          setToast({
            message: 'Có lỗi sảy ra vui lòng tải lại trang hoặc bấm F5',
            status: true,
            type: Toast.TYPE_ERROR,
          }),
        );
        // await refreshTokenAPI()
        //   .then((response) => {
        //     if (response.status === Constant.DEFAULT_STATUS) {
        //       next(setAuth(response.data));
        //       return;
        //     } else {
        //       next(logout);
        //       next(
        //         setToast({
        //           message: Message.UN_AUTH,
        //           status: true,
        //           statusCode: Constant.UN_AUTH_CODE,
        //         })
        //       );
        //       return;
        //     }
        //   })
        //   .then(() => {
        //     next(
        //       setToast({
        //         message: "Có lỗi sảy ra vui lòng tải lại trang hoặc bấm F5",
        //         status: true,
        //         type: Toast.TYPE_ERROR,
        //       })
        //     );
        //   });
      } else {
        return; // Dừng lại ở đây và không gọi next(action)
      }
    } else {
      next(action); // Gọi next(action) trong các trường hợp khác
    }
  };
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(forbiddenMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
