import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import "./SystemDepartureFee.scss";
import { getSystemConfigApi, postSystemDepartFee } from "../../api/system";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkAuthorization } from "../../api";
import icPhLink from "../../assets/images/system/ph_link.svg";
import IcPlus from "../../assets/images/policy/plus.svg";
import IcCircle from "../../assets/images/system/Circle.svg";
import icClose from "../../assets/images/system/close.svg";

function SystemDepartureFee() {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [listDepartFee, setListDepartFee] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: any) => {
    const departFee = listDepartFee.map((item: any, index: any) => ({
      seat_number: values[`seat_number_${index}`],
      price: values[`price_${index}`],
    }));

    const valueBody = { depart_fee: departFee };
    try {
      const response = await postSystemDepartFee(valueBody);
      if (response.status === 200) {
        handleConfigSystem();
        toast.success("Cập nhật thành công");
        setIsEdit(false);
      }
    } catch (error) {
      checkAuthorization(error);
    }
  };

  const handleSubmit = () => {
    form.submit(); // Gọi phương thức submit của form instance
  };

  const handleConfigSystem = async () => {
    setIsLoading(true);
    try {
      const res = await getSystemConfigApi();
      if (res?.status === 200) {
        const valueRes = res?.data?.data;
        setListDepartFee(valueRes?.depart_fee);
        const fieldsValue = valueRes?.depart_fee.reduce(
          (acc: any, item: any, index: number) => {
            acc[`seat_number_${index}`] = item.seat_number;
            acc[`price_${index}`] = item.price;
            return acc;
          },
          {}
        );
        form.setFieldsValue(fieldsValue);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    handleConfigSystem();
  }, []);

  const handleAddTimeline = () => {
    if (listDepartFee?.length < 10) {
      setListDepartFee([...listDepartFee, { id: Date.now() }]);
    } else {
      message.warning("Tối đa 10 mốc cấu hình phí xuất bến");
    }
  };

  const handleRemoveTimeline = (id: any) => {
    if (listDepartFee?.length > 1) {
      setListDepartFee(listDepartFee.filter((item: any) => item.id !== id));
    } else {
      message.warning("Phải có ít nhất một mốc cấu hình phí xuất bến");
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Card className="wrapper-system-departure-fee">
        <Col>
          <Form
            name="info-config-system"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            className="form-system-config"
          >
            <Col>
              <Col className="container-config-system">
                <Col>
                  {listDepartFee?.length ? (
                    listDepartFee?.map((item: any, index: number) => (
                      <Row key={index} gutter={12}>
                        <Col>
                          <Form.Item
                            name={`seat_number_${index}`}
                            label="Xe < số chỗ"
                            className="required"
                            rules={[
                              { required: true, message: "Số chỗ là bắt buộc" },
                              {
                                type: "number",
                                min: 1,
                                max: 99,
                                transform: (value) => Number(value),
                                message: "Số chỗ phải >0 và <100",
                              },
                            ]}
                          >
                            <InputNumber
                            style={{ width: 233 }}
                              type="number"
                              placeholder="Nhập số >0 và <100"
                              value={item?.seat_number}
                              disabled={!isEdit}
                              suffix={
                                <Space size={12}>
                                  <Divider type="vertical" />
                                  <span>Chỗ</span>
                                </Space>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Image style={{ marginTop: 35 }} src={icPhLink} preview={false}/>
                        </Col>
                        <Col>
                          <Form.Item
                            className="required"
                            name={`price_${index}`}
                            label="Phí xuất bến"
                            rules={[
                              {
                                required: true,
                                message: "Phí xuất bến là bắt buộc",
                              },
                              {
                                type: "number",
                                min: 7000,
                                transform: (value) => Number(value),
                                message: "Phí xuất bến không nhỏ hơn 7000",
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ width: 233 }}
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              placeholder="Nhập số tiền"
                              disabled={!isEdit}
                              value={item?.price}
                              suffix={
                                <Space size={12}>
                                  <Divider type="vertical" />
                                  <span>VNĐ</span>
                                </Space>
                              }
                            />
                          </Form.Item>
                        </Col>
                        {isEdit && (
                          <Col className="action-delete-btn">
                            <Button
                              onClick={() => handleRemoveTimeline(item.id)}
                            >
                              <Image src={icClose} preview={false} />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    ))
                  ) : (
                    <></>
                  )}
                </Col>
                <Space size={8} className="note-config-typography">
                  <Image src={IcCircle} />
                  <Typography>Tối đa 10 mốc cấu hình phí xuất bến</Typography>
                </Space>
              </Col>
              {isEdit && (
                <Space className="add-a-timeline" onClick={handleAddTimeline}>
                  Thêm mốc cấu hình phí
                  <Image src={IcPlus} alt="logo" preview={false} />
                </Space>
              )}
            </Col>

            <Row gutter={24} className="row-btn-system">
              {isEdit ? (
                <Col className="container-btn-system">
                  <Button onClick={() => setIsEdit(false)}>Trở lại</Button>
                  <Button type="primary" onClick={handleSubmit}>
                    Lưu
                  </Button>
                </Col>
              ) : (
                <Button
                  className="btn-info-system"
                  onClick={() => setIsEdit(true)}
                >
                  Chỉnh sửa
                </Button>
              )}
            </Row>
          </Form>
        </Col>
      </Card>
    </Spin>
  );
}

export default SystemDepartureFee;
