import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { FormInstance } from "antd/lib";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import icEdit from "../../assets/images/ic-edit.svg";
import IcPlus from "../../assets/images/ic-plus.svg";
import "../../assets/styles/desktop/busCompany.scss";
import StatusFilter from "../../components/Modal/StatusFilter";
import { Constant } from "../../config/constant";

import { mbTrim } from "../../lib/utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchManageBus } from "../../redux/slice/manageBus/manageBusSlice";
import CreateBus from "./CreateBus";
import EditBus from "./EditBus";

function ManageBusCompany() {
  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? DEFAULT_PAGE_SIZE
    : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const { t } = useTranslation();
  const { result, pagination, isFetching } = useAppSelector(
    (state) => state?.manageBus
  );

  const [formSearch]: [FormInstance] = Form.useForm();
  const [keyword, setKeyword] = useState<any>("");
  const [status, setStatus] = useState<any>(undefined);
  const [page, setPage] = useState<number>(1);
  const dispatch = useAppDispatch();

  const [openCreate, setOpenCreate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [detailData, setDetailData] = useState({});

  //HANDLE NAVIGATE
  const handleBusCompanyDetail = (record: any) => {
    setOpenModal(true);
    setDetailData(record);
  };

  const handleCreateCompany = () => {
    setOpenCreate(true);
  };

  //HANDLE STATUS NAV
  const onChangeStatus = (value: number | undefined) => {
    setStatus(value);
  };

  //COLUMN DATA TABLE
  const columns: ColumnsType<any> = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: '80px',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {record.status ? (
    //         <FontAwesomeIcon icon={faCircle} className="green" size="xs" />
    //       ) : (
    //         <FontAwesomeIcon icon={faCircle} className="danger" size="xs" />
    //       )}
    //       <Typography className="text-color">{`CL${text}`}</Typography>
    //     </Space>
    //   ),
    //   sorter: (a: any, b: any) => a?.id - b?.id,
    // },
    {
      title: "STT",
      key: "",
      width: "50px",
      render: (_: any, record: any, index: number) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FontAwesomeIcon
              icon={faCircle}
              className={`${record.status ? "green" : "danger"} p-1`}
              size="xs"
            />
            <Typography className="text-color">
              {(page - 1) * limit + index + 1}
            </Typography>
          </div>
        );
      },
    },
    {
      title: "Tên nhà xe",
      dataIndex: "name",
      key: "garage_name",
      width: "130px",
      sorter: (a: any, b: any) => a?.name.localeCompare(b?.name),
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: "110px",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      sorter: (a: any, b: any) => a?.address.localeCompare(b?.address),
      width: "260px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "170px",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      align: "center",
      key: "logo",
      width: "75px",

      render: (text) => (
        <Space size="middle">
          {
            <Image
              className="customize-image"
              height={46}
              alt=""
              src={text}
              preview={false}
            />
          }
        </Space>
      ),
    },
    {
      title: "Chức năng",
      dataIndex: "action",
      key: "action",
      width: "80px",
      render: (_, record) => (
        <Space size="middle">
          <div
            key={`edit-${record?.id}`}
            onClick={() => handleBusCompanyDetail(record)}
            style={{ cursor: "pointer" }}
          >
            <Tooltip
              title={"Chỉnh sửa thông tin nhà xe "}
              key={"edt"}
              placement="right"
            >
              <Image src={icEdit} preview={false} />
            </Tooltip>
          </div>
        </Space>
      ),
      align: "center",
    },
  ];

  //HANDLE TABLE
  const handleSearch = (values: any) => {
    formSearch.setFields([
      { name: "keyword", value: mbTrim(`CL${values.keyword}`) },
    ]);
    formSearch.setFields([{ name: "keyword", value: mbTrim(values.keyword) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(formSearch.getFieldsValue(["keyword"]));
  };

  const renderRowTable = (record: any) => {
    return record.status ? "" : "bg-disabled";
  };
  const handlePagination = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  let query = `page=${page}&limit=${limit}`; //&status=${status}&keyword=${keyword}
  if (keyword.keyword) {
    query += `&keyword=${keyword.keyword}`;
  }
  if (!status === undefined) {
    query += `&status=${status}`;
  }

  const handleListBusApi = () => {
    dispatch(fetchManageBus({ query }));
  };
  useEffect(() => {
    handleListBusApi();
  }, [query]);

  return (
    <Layout className="min-vh-100 list_user agent-container stt-table">
      <Card>
        <StatusFilter status={status} onChangeStatus={onChangeStatus} />
        <Row className="fw-500 mt-4 mx-3">
          <Button
            type="primary"
            className={"btn-success height-btn-40"}
            onClick={handleCreateCompany}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2 pr-16" />
            {t("Thêm nhà xe mới")}
          </Button>
        </Row>
        <Form
          className="mg-16 mt-3 mx-3"
          form={formSearch}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          onFinish={handleSearch}
          colon={false}
        >
          <Form.Item
            className="label-search"
            labelAlign="left"
            labelCol={{ md: 0 }}
            name="form-search"
          >
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item name="keyword">
                  <Input
                    placeholder={t(
                      "Nhập ID/Tên nhà xe/số điện thoại để tìm kiếm"
                    )}
                  />
                </Form.Item>
              </Col>
              <Col className="text-center">
                <Button
                  htmlType="submit"
                  type="primary"
                  className={"btn-search height-btn-36"}
                  onSubmit={handleSearch}
                  size="large"
                >
                  {t("Tìm kiếm")}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <Table
          locale={{
            triggerDesc: "Sắp xếp giảm dần",
            triggerAsc: "Sắp xếp tăng dần",
            cancelSort: "Hủy sắp xếp",
          }}
          dataSource={result?.length > 0 ? result : undefined}
          rowKey="id"
          rowClassName={renderRowTable}
          columns={columns}
          className="mx-3 list-bus"
          loading={isFetching}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ["bottomRight"],
            onChange: handlePagination,
            showSizeChanger: true,
          }}
        />
      </Card>
      {openCreate ? (
        <CreateBus
          openCreate={openCreate}
          setOpenCreate={setOpenCreate}
          handleListBusApi={handleListBusApi}
        />
      ) : (
        <></>
      )}

      <EditBus
        handleListBusApi={handleListBusApi}
        openCreate={openModal}
        setOpenCreate={setOpenModal}
        detailData={detailData}
      />
    </Layout>
  );
}
export default ManageBusCompany;
