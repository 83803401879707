import {getAxiosClientWithToken} from "./index";
import {ListServiceRequest, ServiceParams} from "../types";
import {handleFormData} from "../lib/utils";

export const getListServiceAPI = (params: ListServiceRequest) => {
    return getAxiosClientWithToken().get('/services', {params});
};
export const getAllServiceAPI = () => {
    return getAxiosClientWithToken().get('/services/get-all-service');
};

export const createServiceAPI = (params: ServiceParams) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post('/services', formData);
};

export const updateServiceAPI = (params: ServiceParams) => {
    const restClient = getAxiosClientWithToken();
    // const formData = handleFormData(params);
    return restClient.put(`/services/${params.id}`, params);
};
export const updateStatusServiceAPI = (params: { id: string, status: boolean }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put(`/services/status/${params.id}`, {status: params.status ? 0 : 1});
};