import { CopyOutlined, DeleteOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { Rule } from 'antd/es/form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconAutoFill from '../../assets/images/ic-auto-fill.svg';
import '../../assets/styles/desktop/typeBus.scss';
import BusTypeMatrix from '../../components/BusTypeMatrix';
import NumberInputName from '../../components/NumberInputName';
import { maxValidator } from '../../lib/utils';

const ModalTypeBus = ({ record, formTypeBus, isCreate, group }: any) => {
  const { t } = useTranslation();
  const [showSecondFloor, setShowSecondFloor] = useState(false);
  const [isResetSeat, setIsResetSeat] = useState<boolean>(false);
  const [isFillName, setIsFillName] = useState<boolean>(false);
  const [isDeleteName, setIsDeleteName] = useState<boolean>(false);
  const [isCopySeat, setIsCopySeat] = useState<boolean>(false);
  const [isDeleteFloor, setIsDeleteFloor] = useState<boolean>(false);
  const [floor, setFloor] = useState<number>(1);
  const [row, setRow] = useState<number>(0);
  const [column, setColumn] = useState<number>(0);
  const [countFloors, setCountFloors] = useState<number>(1);
  const [showErrorMatrix, setShowErrorMatrix] = useState<boolean>(false);

  const NameRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập tên'),
    },
    {
      pattern: /^[^\s].*$/,
      message: t('Tên không được chứa chỉ khoảng trắng'),
    },
    maxValidator(255, 'Số kí tự vượt quá 255 từ'),
  ];

  const ColRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập cột'),
    },
  ];
  const RowRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập hàng'),
    },
  ];

  const handleCreateDiagram = () => {
    const rowNum = formTypeBus.getFieldValue('row');
    const colNum = formTypeBus.getFieldValue('column');
    setRow(rowNum);
    setColumn(colNum);
    if (rowNum < 15 && rowNum < 15) {
      setShowErrorMatrix(false);
      formTypeBus.setFields([
        {
          name: 'row',
          errors: undefined,
        },
        {
          name: 'column',
          errors: undefined,
        },
      ]);
    } else {
      setShowErrorMatrix(true);
      if (rowNum >= 15) {
        formTypeBus.setFields([
          {
            name: 'row',
            errors: [''],
          },
        ]);
      }
      if (colNum >= 15) {
        formTypeBus.setFields([
          {
            name: 'column',
            errors: [''],
          },
        ]);
      }
    }
  };

  const handleAddFloor = () => {
    setShowSecondFloor(true);
    setCountFloors(countFloors + 1);
    onChangeFloor(countFloors + 1);
    setFloor(2);
  };
  const handleCopySeat = () => {
    if (countFloors > 1) {
      setIsCopySeat(true);
    } else {
      setIsCopySeat(true);
      setShowSecondFloor(true);
      setCountFloors(countFloors + 1);
      onChangeFloor(countFloors + 1);
    }
  };
  const onResetCopySeat = () => {
    setIsCopySeat(false);
  };

  const handleResetSeat = () => {
    setIsResetSeat(true);
  };
  const handleFillName = () => {
    setIsFillName(true);
  };
  const onResetFillName = () => {
    setIsFillName(false);
  };
  const handleDeleteName = () => {
    setIsDeleteName(true);
  };
  const onResetDeleteName = () => {
    setIsDeleteName(false);
  };
  const onResetSeat = () => {
    setIsResetSeat(false);
  };

  const onChangeFloor = (value: number | undefined) => {
    if ((value && value <= countFloors) || (value && value <= record?.seat_map?.count_floor)) {
      setFloor(value);
    }
  };
 
  const handleDeleteFloor = () => {
    if (floor === 2) {
      setCountFloors(countFloors - 1);
      onChangeFloor(floor === countFloors ? countFloors - 1 : floor);
      setIsDeleteFloor(true);
      formTypeBus.setFieldsValue({ count_floor: 1 });
    }
  };
  const onResetDelete = () => {
    setShowSecondFloor(false);
    setFloor(1);
    setIsDeleteFloor(false);
  };

  useEffect(() => {
    if (countFloors) {
      formTypeBus.setFieldsValue({ count_floor: countFloors || 1 });
    }
  }, [countFloors, formTypeBus]);

  const floorNum = Form.useWatch('count_floor', formTypeBus);
  useEffect(() => {
    if ((floorNum < 2 || !floorNum) && floorNum > 0) {
      setFloor(1);
      setCountFloors(floorNum);
    }
  }, [floorNum]);
  useEffect(() => {
    if (!isCreate) {
      setFloor(1);
      setCountFloors(1);
    }
  }, [isCreate]);

  return (
    <>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <Form.Item
            name="name_type"
            label={t('Tên loại xe')}
            validateFirst
            rules={NameRules}
            className="require"
            validateTrigger={['onChange', 'onBlur']}
          >
            <Input placeholder={t('Nhập tên loại xe')} disabled={record?.creator === 'carlink'} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={3} xl={3} xxl={3} className="seat-number-block">
          <Form.Item name="seat_number" label={t(`Số chỗ`)}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="count_floor" className="d-none">
        <Input disabled={record?.creator === 'carlink'} />
      </Form.Item>
      <Col className="mt-1 floor-block">
        <Space onClick={() => onChangeFloor(1)}>
          <Typography.Text className={`fs-16 floor-item ${floor === 1 ? 'active' : ''}`}>{t(`Tầng 1`)}</Typography.Text>
        </Space>
        {record
          ? floorNum === 2 && (
              <Space onClick={() => onChangeFloor(2)}>
                <Typography.Text className={`fs-16 floor-item ${floor === 2 ? 'active' : ''}`}>
                  {t(`Tầng 2`)}
                </Typography.Text>
              </Space>
            )
          : showSecondFloor &&
            countFloors === 2 && (
              <Space onClick={() => onChangeFloor(2)}>
                <Typography.Text className={`fs-16 floor-item ${floor === 2 ? 'active' : ''}`}>
                  {t(`Tầng 2`)}
                </Typography.Text>
              </Space>
            )}
      </Col>
      <Row className="mt-3 seat-form-item" style={{ columnGap: '12px' }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Space>
            <Form.Item label={t(`Số cột`)} className="mw-155" rules={ColRules} validateTrigger={['onChange', 'onBlur']}>
              <NumberInputName
                name={'column'}
                form={formTypeBus}
                placeholder="Nhập số cột"
                disabled={(record?.creator === 'carlink' && group !== 'carlink') || record?.id}
              />
            </Form.Item>
            <Form.Item
              label={t(`Số hàng`)}
              className={`mw-155`}
              rules={RowRules}
              validateTrigger={['onChange', 'onBlur']}
            >
              <NumberInputName
                name={'row'}
                form={formTypeBus}
                placeholder="Nhập số hàng"
                disabled={(record?.creator === 'carlink' && group !== 'carlink') || record?.id}
              />
            </Form.Item>

            <Space className="mt-1">
              <Button
                className="btn-create-seat"
                htmlType="button"
                onClick={() => handleCreateDiagram()}
                disabled={(record?.creator === 'carlink' && group !== 'carlink') || record?.id}
              >
                Tạo sơ đồ
              </Button>
              {!record?.id && (
                <>
                  {floor === 1 ? (
                    <>
                      <Button
                        className="btn-group-seat"
                        onClick={() => handleAddFloor()}
                        disabled={countFloors === 2 || (record?.creator === 'carlink' && group !== 'carlink')}
                      >
                        <PlusOutlined /> Thêm tầng 2
                      </Button>
                      <Button
                        className="btn-group-seat"
                        onClick={handleResetSeat}
                        disabled={record?.creator === 'carlink' && group !== 'carlink'}
                      >
                        <RedoOutlined /> Đặt lại từ đầu
                      </Button>
                      <Button
                        className="btn-group-seat"
                        onClick={() => handleFillName()}
                        disabled={record?.creator === 'carlink' && group !== 'carlink'}
                      >
                        <Typography>
                          <img src={iconAutoFill} alt="" /> {' Nhập tên nhanh'}
                        </Typography>
                      </Button>
                      <Button
                        className="btn-group-seat"
                        onClick={() => handleDeleteName()}
                        disabled={record?.creator === 'carlink' && group !== 'carlink'}
                      >
                        <Typography>
                          <DeleteOutlined />
                          {'  Xóa tên nhanh'}
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="btn-group-seat"
                        onClick={() => handleCopySeat()}
                        disabled={record?.creator === 'carlink' && group !== 'carlink'}
                      >
                        <CopyOutlined /> Sao chép tầng 1
                      </Button>
                      <Button className="btn-group-seat" onClick={handleResetSeat}>
                        <RedoOutlined /> Đặt lại từ đầu
                      </Button>

                      <Button
                        className="btn-group-seat"
                        onClick={() => handleFillName()}
                        disabled={record?.creator === 'carlink' && group !== 'carlink'}
                      >
                        <Typography>
                          <img src={iconAutoFill} alt="" /> {' Nhập tên nhanh'}
                        </Typography>
                      </Button>
                      <Button
                        className="btn-group-seat btn-delete"
                        disabled={floor === 1 || (record?.creator === 'carlink' && group !== 'carlink')}
                        onClick={handleDeleteFloor}
                      >
                        <DeleteOutlined style={{ color: '#D81717' }} /> Xóa tầng
                      </Button>
                    </>
                  )}
                </>
              )}
            </Space>
          </Space>
        </Col>
      </Row>
      <Row>{showErrorMatrix ? <span className="error-validate">Vui lòng nhập cột hoặc hàng nhỏ hơn 15</span> : ''}</Row>
      <BusTypeMatrix
        column={formTypeBus.getFieldValue('column')}
        row={formTypeBus.getFieldValue('row')}
        isReset={isResetSeat}
        handleReset={onResetSeat}
        statusFloor={floor}
        seatCount={countFloors}
        isCopy={isCopySeat}
        handleCopy={onResetCopySeat}
        isDelete={isDeleteFloor}
        handleDelete={onResetDelete}
        seatMatrixData={record ? record?.seat_map : undefined}
        isFillName={isFillName}
        onResetFillName={onResetFillName}
        formTypeBus={formTypeBus}
        isDeleteName={isDeleteName}
        onResetDeleteName={onResetDeleteName}
        record={record}
      />
    </>
  );
};
export default ModalTypeBus;
