import moment from 'moment';
import { Tutotial } from '../types';
import { DataCookie } from '../types/common';
import CryptoJS from 'crypto-js'; 

export const getCookie = (cookieName: string | undefined) => {
  if (!cookieName) {
    return;
  }
  const decodedCookie = decodeURIComponent(document.cookie);
  const listCookies = decodedCookie.split(';');
  for (let i = 0; i < listCookies.length; i++) {
    let cookie = listCookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length + 1, cookie.length);
    }
  }
  return '';
};

export const saveCookie = (dataCookie: DataCookie) => {
  if (!dataCookie?.name) {
    return;
  }
  const date = new Date();
  date.setTime(date.getTime() + dataCookie.expDay * 24 * 60 * 60 * 1000);
  document.cookie = `${dataCookie.name}=${dataCookie.value}; expires=${date.toUTCString()}; path=/; domain=${
    process.env.REACT_APP_SUB_DOMAIN
  }`;
  document.cookie = `${dataCookie.name}=${dataCookie.value}; expires=${date.toUTCString()}; path=/;`;
};

export const deleteCookie = (nameCookie: string | undefined) => {
  if (!nameCookie) {
    return;
  }
  document.cookie = nameCookie + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
  document.cookie =
    nameCookie + `=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${process.env.REACT_APP_SUB_DOMAIN}`;
};

export const scrollTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

const countDecimals = (value: number) => {
  if (!value) return 0;
  if (value % 1 !== 0) return value?.toString()?.split('.')[1]?.length;
  return 0;
};

export const formatPrice = (value: number, round = 0, division = '.') => {
  if (value !== 0 && !value) {
    return '';
  }
  const count = countDecimals(value);
  if (!round || !count) {
    return `${value?.toFixed(round)}`?.replace(/\B(?=(\d{3})+(?!\d))/g, division);
  } else {
    return `${value?.toFixed(round)}`?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
};

export const handleGetUrlGuide = (listUrl: Tutotial[], id: number) => {
  return listUrl?.find((item) => item?.id === id)?.url_youtube || '';
};

export const removeVietnameseTones = (str: string) => {
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  str = str.replace(/đ/g, 'd').replace(/Đ/g, 'D');
  return str;
};

export const filterOptionSelect = (
  input: string,
  option?: {
    label?: any;
    value?: any;
  },
) => {
  const normalizedInput = removeVietnameseTones(input.toLowerCase().trim());
  const normalizedLabel = removeVietnameseTones(option?.label.toLowerCase());
  return normalizedLabel.includes(normalizedInput);
};

export const handleDisplayTimeToNow = (pastDate: string) => {
  const now = moment();
  const past = moment(pastDate);
  const duration = moment.duration(now.diff(past));
  if (duration.asWeeks() > 1) {
    return past.format('DD MMMM [at] HH:mm');
  } else {
    return past.fromNow();
  }
};

// export const getSessionStorage = (key: string): any => {
//   const itemStr: string | null = sessionStorage.getItem(key);
//   if (!itemStr) {
//     return null;
//   }
//   const tmpData: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(
//     itemStr === undefined || itemStr === null ? '' : itemStr,
//     process.env.REACT_APP_HASH_KEY!,
//   );
//   return JSON?.parse(tmpData?.toString(CryptoJS.enc.Utf8));
// };
// export const setSessionStorage = (key: string, value: any): void => {
//   const tmpData: string = CryptoJS.AES.encrypt(JSON.stringify(value), process.env.REACT_APP_HASH_KEY!).toString();
//   sessionStorage.setItem(key, tmpData);
// };
