import { Font, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Button, Modal, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentTicket } from './DocumentTicket';

const ModalPrintTicket: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  listBooking,
  listBookingDetail,
  dataCustomerBooking,
  isListTicket,
  loadingDetail,
  listOrder,
  isOrder,
  loading,
  group,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      closeIcon={false}
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      footer={[]}
      width={1000}
      maskClosable={false}
      className="modal-print"
      confirmLoading={loading}
    >
      <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PDFViewer height={700} style={{ width: '100%' }} showToolbar={true}>
            {(listBookingDetail?.ticket_code || isOrder) && (
              <DocumentTicket
                listBooking={listBooking}
                listBookingDetail={listBookingDetail}
                dataCustomerBooking={dataCustomerBooking}
                isListTicket={isListTicket}
                loadingDetail={loadingDetail}
                listOrder={listOrder}
                isOrder={isOrder}
                group={group}
              />
            )}
          </PDFViewer>
        </div>
        <Row className="d-flex justify-content-end mt-3" style={{ paddingBottom: '16px' }}>
          <Button key="cancel" className=" w-160 h-btn-48 me-3 btn-back agent" onClick={() => modalHandleCancel()}>
            Trở lại
          </Button>
          <PDFDownloadLink
            document={
              <DocumentTicket
                listBooking={listBooking}
                listBookingDetail={listBookingDetail}
                dataCustomerBooking={dataCustomerBooking}
                listOrder={listOrder}
                isOrder={isOrder}
              />
            }
            fileName={`Chi tiết vé`}
          >
            <Button
              key="ok"
              type="primary"
              className="w-160 h-btn-48 btn-success"
              htmlType="submit"
              onClick={() => modalHandleOk()}
            >
              Tải xuống
            </Button>
          </PDFDownloadLink>
        </Row>
      </>
    </Modal>
  );
};

export default ModalPrintTicket;
