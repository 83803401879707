import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {ListServiceState, ServiceParams} from "../../../types";
import {getErrorMessage} from "../../../api";
import {updateServiceAPI, updateStatusServiceAPI} from "../../../api/services";
import {setToast} from "../toastNotifySlice";
import {Action, UPDATE_ERROR, UPDATE_SUCCESS} from "../../../config/message";
import {Toast} from "../../../config/constant";


// @ts-ignore
export const updateService = createAsyncThunk('admin/update/service', async (params: ServiceParams, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await updateServiceAPI(params);
        const {
            data,
            status
        } = response;
        if (status) {
            dispatch(setUpdate({status: status, data: data}));
            dispatch(setToast({
                message: UPDATE_SUCCESS(Action.SERVICE),
                status: true,
                type: Toast.TYPE_SUCCESS,
            }))
        }
        return true;
    } catch (error: any) {
        const {response} = error;
        dispatch(setUpdateError(response));
        dispatch(setToast({
            message: UPDATE_ERROR(Action.SERVICE),
            status: true,
            type: Toast.TYPE_ERROR,
        }))
        return rejectWithValue(getErrorMessage(error));
    }
});

export const updateStatusService = createAsyncThunk('admin/status/service', async (params: {
    id: string,
    status: boolean
}, {dispatch, rejectWithValue}) => {
    try {
        const response = await updateStatusServiceAPI(params);
        const {
            data,
            status
        } = response;
        if (status) {
            dispatch(setUpdate({status: status, data: data}));
            dispatch(setToast({
                message: UPDATE_SUCCESS(Action.SERVICE),
                status: true,
                type: Toast.TYPE_SUCCESS,
            }))
        }
        return true;
    } catch (error: any) {
        const {response} = error;
        dispatch(setUpdateError(response));
        dispatch(setToast({
            message: UPDATE_ERROR(Action.SERVICE),
            status: true,
            type: Toast.TYPE_ERROR,
        }))
        return rejectWithValue(getErrorMessage(error));
    }
});
export const updateServiceSlice = createSlice({
    name: 'updateService',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: 0,
        message: '',
        errorDetail: null,
    } as ListServiceState,
    reducers: {
        setUpdate: (state: ListServiceState, {payload}) => {
            const {message} = payload.data;
            state.status = payload?.status;
            state.message = message;
        },
        setUpdateError: (state: ListServiceState, {payload}) => {
            const {errors} = payload.data;
            state.status = payload?.status;
            state.errorDetail = errors;
        },
        resetUpdateService: (state: ListServiceState) => {
            state.success = false;
            state.status = 0;
            state.message = '';
            state.errorDetail = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateService.pending, (state: ListServiceState) => {
            state.loading = true;
        });
        builder.addCase(updateService.fulfilled, (state: ListServiceState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateService.rejected, (state: ListServiceState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });

        builder.addCase(updateStatusService.pending, (state: ListServiceState) => {
            state.loading = true;
        });
        builder.addCase(updateStatusService.fulfilled, (state: ListServiceState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(updateStatusService.rejected, (state: ListServiceState, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = payload;
        });
    },
});

export const updateServiceSelector = (state: RootState) => state.updateService;
export const {setUpdate, setUpdateError, resetUpdateService} = updateServiceSlice.actions;
