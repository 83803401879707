import { toast } from "react-toastify";
import { getErrorMessage } from "../../../api";
import { convertOption } from "../../../lib/utils";
import { Constant } from "../../../config/constant";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createServiceAPI, getAllServiceAPI, getListServiceAPI, updateServiceAPI } from "../../../api/services";
import { ListServiceRequest, ServiceParams, ServicesState } from "../../../types";

// @ts-ignore

const initialState = {
    listServices: [],
    loading: false,
    errorDetail: {},
    message: '',
    success: false,
    pagination: {
        total: 0,
        per_page: Constant.PAGE_SIZE,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
} as ServicesState;

export const listServiceSlice = createSlice({
    name: 'listService',
    initialState,
    reducers: {
        setListService(state, action) {
            state.listServices = action.payload;
        },
        setServices(state: ServicesState, { payload }) {
            const { service } = payload;
            let data: never[] = [];
            convertOption(data, service)
            state.listServices = data;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListService.pending, (state) => {
                state.loading = true;
            }).addCase(fetchListService.fulfilled, (state, action) => {
                state.loading = false;
                state.listServices = action.payload?.data;
                state.success = true;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
            }).addCase(fetchListService.rejected, (state) => {
                state.loading = false;
                state.success = false;
            }).addCase(createService.pending, (state) => {
                state.loading = true;
            }).addCase(createService.fulfilled, (state) => {
                state.loading = false;
            }).addCase(createService.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                      toast.error(`${[listErros[fieldName]]}`);
                    });
                  }
                state.loading = false;
            }).addCase(updateService.pending, (state) => {
                state.loading = true;
            }).addCase(updateService.fulfilled, (state) => {
                state.loading = false;
            }).addCase(updateService.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                      toast.error(`${[listErros[fieldName]]}`);
                    });
                  }
                state.loading = false;
            })
    },
});

export const fetchListService = createAsyncThunk('admin/services', async (params: ListServiceRequest, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getListServiceAPI(params);
        const { data, status } = response;
        if (status === Constant.DEFAULT_STATUS) {
            dispatch(setListService({ service: data?.data, pagination: data?.pagination, status: status }));
            return data;
        }
    } catch (error: any) {
        const { status } = error?.response;
        dispatch(setListService({ status: status }));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const fetchAllService = createAsyncThunk('admin/services/all', async (_, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await getAllServiceAPI();
        const {
            data,
            status
        } = response;

        if (status === Constant.DEFAULT_STATUS) {
            dispatch(setServices({ service: data.data }));
            return true;
        }
    } catch (error: any) {
        const {
            status
        } = error.response;
        dispatch(setListService({ status: status }));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const createService = createAsyncThunk('admin/create/service', async (params: ServiceParams, { dispatch, rejectWithValue }) => {
    try {
        const response = await createServiceAPI(params);
        if (response?.data?.success) {
            toast.success(response?.data?.message);
        }
        return response?.data;
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const updateService = createAsyncThunk('admin/update/service', async (params: ServiceParams, { dispatch, rejectWithValue }) => {
    try {
        const response = await updateServiceAPI(params);
        if (response?.data?.success) {
            toast.success(response?.data?.message);
        }
        return response?.data;
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setListService, setServices } = listServiceSlice.actions;
