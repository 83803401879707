import { getAxiosClientWithToken } from ".";
import { CancelContractRequest, ListContractRequest } from "../types/contract";

export const getListContractApi = (params:ListContractRequest) => {
    return getAxiosClientWithToken().get('/contract',{params});
};
export const getContractByIdApi =(id:string)=>{
    return getAxiosClientWithToken().get(`/contract/${id}`);
}
export const postCancelContractApi =(params:CancelContractRequest)=>{
    return getAxiosClientWithToken().post(`/contract/cancel`,params);
}
export const getBookingContractRouteApi = () => {
    return getAxiosClientWithToken().get('/contract/routes');
  };
  