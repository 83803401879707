import axios from "axios";

export const getListRegisterAdvertiseAPI = (params: {
    page?: number;
    limit?: number;
    is_handle?: number;
    keyword?: string;
}) => {
    return axios.get('https://apicmsvietapp.vietapp.vn/api/cms/register-advertising', { params });
};

export const handleRegisterAdvertise = (params: {
    id: number;
    is_handle: number;
    user_handle_name: string;
}) => {
    return axios.put(`https://apicmsvietapp.vietapp.vn/api/cms/register-advertising/handle/${params?.id}`, { is_handle: params?.is_handle, user_handle_name: params?.user_handle_name });
};