/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Row,
  Table,
  Typography,
  Layout,
  Col,
  Button,
  Input,
  Image,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { useEffect, useState } from "react";
import "./SystemGuide.scss";
import {
  getListTutorialsApi,
  updateListTutorialsApi,
} from "../../api/tutorials";
import { toast } from "react-toastify";
import { checkAuthorization } from "../../api";
import IcPlus from "../../assets/images/ic-plus.svg";
import CreateGuideModal from "./CreateGuideModal";
import { Link } from "react-router-dom";

function SystemGuide() {
  const [tutorials, setTutorials] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZE);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [openTutorial, setOpenTutorial] = useState(false);

  const convertObjectToArray = (obj: any) => {
    return Object.entries(obj).map(([key, value]) => ({
      id: key,
      url_youtube: value,
    }));
  };

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      key: "stt",
      render: (_text, _record, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Tên chức năng",
      dataIndex: "function",
      key: "function",
    },
    {
      title: "URL Youtube",
      dataIndex: "url_youtube",
      key: "url_youtube",
      render: (text, record) => {
        return (
          <>
            {isEdit ? (
              <Input
                width={852}
                value={formData[record.id] || text}
                onChange={(e) => handleInputChange(e, record.id)}
              />
            ) : (
              <a  target="blank" rel="noopener" href={text} className="url_youtube-guide">{text}</a>
            )}
          </>
        );
      },
    },
  ];

  const handleInputChange = (e: any, key: any) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    const arrFormData = convertObjectToArray(formData);
    try {
      const res = await updateListTutorialsApi(arrFormData);
      if (res.status === 200) {
        handleTutorialApi();
        toast.success("Cập nhật thành công");
        setIsEdit(false);
      }
    } catch (error) {
      checkAuthorization(error);
    }
  };

  const handleAddTutorial = () => {
    setOpenTutorial(true);
  };

  const handleTutorialApi = async () => {
    setFetching(true);
    try {
      const res = await getListTutorialsApi();
      if (res.status === 200) {
        setTutorials(res.data.data);
      }
    } catch (error) {}
    setFetching(false);
  };

  useEffect(() => {
    handleTutorialApi();
  }, []);

  return (
    <Layout>
      <Card className="wrapper-system-guide">
        {/* <Button style={{height: 48}} type="primary" onClick={handleAddTutorial}>Thêm hướng dẫn</Button> */}
        <Row className="fw-500 mb-2">
          <Button
            type="primary"
            className={"btn-success btn-add"}
            onClick={handleAddTutorial}
            size="large"
            // disabled={disabledAction}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            Thêm hướng dẫn sử dụng
          </Button>
        </Row>
        <Table
          locale={{
            triggerDesc: "Sắp xếp tăng dần",
            triggerAsc: "Hủy sắp xếp",
            cancelSort: "Sắp xếp giảm dần",
          }}
          bordered={false}
          loading={isFetching}
          pagination={false}
          columns={columns}
          // scroll={{ y: 600 }}

          dataSource={tutorials?.length > 0 ? tutorials : []}
        />

        <Row gutter={24} className="row-btn-system">
          {isEdit ? (
            <Col className="container-btn-system">
              <Button onClick={() => setIsEdit(false)}>Trở lại</Button>
              <Button type="primary" onClick={handleSubmit}>
                Lưu
              </Button>
            </Col>
          ) : (
            <Col>
              <Button
                className="btn-info-system"
                onClick={() => setIsEdit(true)}
              >
                Chỉnh sửa
              </Button>
            </Col>
          )}
        </Row>
      </Card>
      {openTutorial && (
        <CreateGuideModal
          openTutorial={openTutorial}
          setOpenTutorial={setOpenTutorial}
          handleTutorialApi={handleTutorialApi}
        />
      )}
    </Layout>
  );
}

export default SystemGuide;
