import React from 'react';
import './index.css';
import {persistStore} from 'redux-persist';
import {store} from './redux/store';
import {ConfigProviderProps} from 'antd/lib/config-provider';
import locale from 'antd/es/locale/en_GB';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {ConfigProvider} from 'antd';
import 'react-quill/dist/quill.snow.css';

import {BrowserRouter} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const persisTor = persistStore(store);

const antdConfig: ConfigProviderProps = {
    locale: locale,
    autoInsertSpaceInButton: false,
    theme: {
        token: {
            colorPrimary: "#3AB54A",
            borderRadius: 4,
            fontWeightStrong: 500,
            fontFamily: 'Inter',
        },
        components: {
            Button: {
                fontWeight: 500,
                defaultShadow: 'none',
                primaryShadow: 'none',
            },
            Radio: {
                buttonSolidCheckedActiveBg: '#3AB54A',
                buttonSolidCheckedBg: '#3AB54A',
                buttonSolidCheckedColor: '#3AB54A',
                buttonSolidCheckedHoverBg: '#3AB54A',
            },
            Input: {
                // controlHeight: 36
            },
            Select: {
                optionHeight: 36,
            },
            Table: {
                headerBg: '#F3F7FE',
            },
            Modal: {
                titleFontSize: 20,
                titleLineHeight: 1.5,
            },
            DatePicker: {
                controlHeight: 36,
                colorTextPlaceholder: "#435869",
            }
        },
    },
    // componentSize: "large"
};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate persistor={persisTor} loading={null}>
            <ConfigProvider {...antdConfig}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </ConfigProvider>
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
