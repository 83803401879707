import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Layout,
  Row,
  Select,
  SelectProps,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import iconAuto from '../../assets/images/ic-auto-operated.svg';
import icPlus from '../../assets/images/ic-duplicate.svg';
import icEdit from '../../assets/images/ic-edit.svg';
import IcPlus from '../../assets/images/ic-plus.svg';
import ModalConfirmCopySchedule from '../../components/Modal/ModalConfirmCopySchedule';
import TabsFilterStatus from '../../components/TabsFilterStatus/TabsFilterStatus';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant, TabType, TimeFormat, Toast } from '../../config/constant';
import { filterOption } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAllAvailableBusType, listBusTypeSelector } from '../../redux/slice/busTypes/listBusTypeSlice';
import { fetchListRoute, getAllRoute, routeSelector, setRoute } from '../../redux/slice/routeSlice';
import {
  createScheduleSelector,
  duplicateSchedule,
  resetRegisterCreateSchedule,
} from '../../redux/slice/schdules/createSchedule';
import { fetchListSchedule, ListScheduleSelector } from '../../redux/slice/schdules/scheduleSlice';
import { setToast } from '../../redux/slice/toastNotifySlice';
import { DuplicateScheduleParams, ScheduleParams } from '../../types/schedule';
import { handleGetUrlGuide } from '../../utils/helpers';
import ScheduleModal from './ScheduleModal';
import './Schedules.scss';

function Schedule() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [formCopySchedule] = Form.useForm();
  const [page, setPage] = useState<number>(1);
  const [provinceId, setProvinceId] = useState<number | undefined>();
  const [searchBusType, setSearchBusType] = useState<number | undefined>();
  const [statusTab, setStatusTab] = useState<TabType>(TabType.ACTIVE);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [openModalCopy, setOpenModalCopy] = useState<boolean>(false);
  const [detailShedule, setDetailShedule] = useState<any>();
  const [keywordBus, setKeywordBus] = useState<string>('');
  const { listAllRoute, route } = useAppSelector(routeSelector);
  const { loading, schedules, pagination } = useAppSelector(ListScheduleSelector);
  const { statusDuplicate } = useAppSelector(createScheduleSelector);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const { listAllAvailableBusType } = useAppSelector(listBusTypeSelector);
  const departTime = Form.useWatch('depart_time', formCopySchedule);

  const onChangeProvince = (value: number) => {
    setProvinceId(value);
    setPage(1);
  };

  const onChangeBusType = (value: number) => {
    setSearchBusType(value);
    setPage(1);
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };
  const onCancelSuccess = useCallback(() => {}, []);

  useEffect(() => {
    dispatch(
      fetchListSchedule({
        page,
        limit,
        status: statusTab === TabType.ALL ? null : statusTab === TabType.ACTIVE ? true : false,
        bus_type_id: searchBusType,
        route_id: provinceId,
      }),
    );
  }, [dispatch, page, limit, statusTab, searchBusType, provinceId]);

  useEffect(() => {
    dispatch(getAllAvailableBusType({ keyword: keywordBus }));
  }, [dispatch, keywordBus]);

  useEffect(() => {
    dispatch(fetchListRoute({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllRoute({}));
  }, [dispatch]);
  const columns: ColumnsType<ScheduleParams> = [
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: '36px',
      render: (_, record) => (
        <Space size="middle">
          {record.status === Constant.STATUS_ACTIVE ? (
            <FontAwesomeIcon icon={faCircle} className="p-1 green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="p-1 danger" size="xs" />
          )}
        </Space>
      ),
    },
    {
      title: 'Tuyến',
      dataIndex: 'route_name',
      key: 'route_name',
      render: (_, record) => {
        return (
          <Typography.Text className="route_name text-color-default">
            {record?.is_diagram ? (
              `${record.route.depart_point} - ${record.route.arrive_point}`
            ) : (
              <>
                {record.route.depart_point} -{' '}
                <span className="text-nowrap">
                  {record.route.arrive_point}
                  <span className="diagram">*</span>
                </span>
              </>
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Giờ đi',
      dataIndex: 'depart_time',
      key: 'depart_time',
      width: '100px',
      render: (_, record) => `${record.depart_time.slice(0, 5)}`,
      sorter: (a, b) => a?.depart_time.localeCompare(b?.depart_time),
    },
    {
      title: 'Giờ đến',
      dataIndex: 'arrive_time',
      key: 'arrive_time',
      width: '110px',
      render: (_, record) => `${record.arrive_time.slice(0, 5)}`,
      sorter: (a, b) => a?.arrive_time.localeCompare(b?.arrive_time),
    },
    {
      title: 'Loại xe',
      dataIndex: 'bus_type',
      key: 'bus_type',
      render: (_, record) => `${record.bus_type?.name}`,
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: 50,
      align: 'right',
      render: (_, record) => (
        <Space size="middle">
          {record?.auto_operated ? (
            <Tooltip title="Điều hành tự động">
              <Image src={iconAuto} preview={false}></Image>
            </Tooltip>
          ) : (
            ''
          )}
        </Space>
      ),
    },
    {
      title: 'Chuyến tiếp theo',
      dataIndex: 'next_trip_date',
      key: 'next_trip_date',
      render: (_, record: any) =>
        record?.next_trip_date ? (
          moment(record?.next_trip_date, 'DD/MM/YYYY').format('DD/MM/YYYY')
        ) : record?.is_extra_trip ? (
          <Typography.Text className="route_name text-color-default">Chuyến tăng cường</Typography.Text>
        ) : (
          ''
        ),
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '120px',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={'Chỉnh sửa'} placement="top">
            <Link
              onClick={() => {
                dispatch(setRoute(record));
                setDetailShedule(record);
                setIsDetail(true);
                setOpenScheduleModal(true);
              }}
            >
              <Image src={icEdit} preview={false} />
            </Link>
          </Tooltip>
          {record?.frequency_type !== 'specific_date' && (
            <Tooltip title={'Nhân bản chuyến này'} placement="top">
              <Link
                onClick={() => {
                  dispatch(setRoute(record));
                  setOpenModalCopy(true);
                }}
              >
                <Image width={21} height={21} src={icPlus} preview={false} />
              </Link>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const BusOptions: SelectProps['options'] = listAllAvailableBusType?.length
    ? listAllAvailableBusType?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
          seatMap: item?.seat_map,
        };
      })
    : [];

  const filterOptionRoute = (
    input: string,
    option?: {
      label?: any;
      value?: any;
    },
  ) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase());

  const RouteOptions: SelectProps['options'] = listAllRoute?.length
    ? listAllRoute?.map((item: any) => {
        return {
          value: item?.id,
          label: `${item?.depart_point} - ${item?.arrive_point}`,
        };
      })
    : [];

  const handleChangeTab = (key: TabType) => {
    setStatusTab(key);
    setPage(1);
  };
  // @ts-ignore

  const handleCancelModalCopy = () => {
    setOpenModalCopy(false);
    formCopySchedule.resetFields();
  };
  const handleCopySchedule = () => {
    if (departTime) {
      const tmpDataCopySchedule: DuplicateScheduleParams = {
        depart_time: dayjs(departTime).format(TimeFormat),
        id: route?.id,
        route_id: route?.route?.id,
      };
      dispatch(duplicateSchedule(tmpDataCopySchedule));
    } else {
      dispatch(
        setToast({
          message: 'Vui lòng nhập giờ đi mới',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
      formCopySchedule.setFields([
        {
          name: 'depart_time',
          errors: ['Vui lòng nhập giờ đi mới'],
        },
      ]);
    }
  };
  useEffect(() => {
    if (statusDuplicate === Constant.DEFAULT_STATUS) {
      handleCancelModalCopy();
      formCopySchedule.resetFields();
      dispatch(resetRegisterCreateSchedule());
      dispatch(
        fetchListSchedule({
          status: statusTab,
          route_id: provinceId,
          bus_type_id: searchBusType,
          limit,
          page,
        }),
      );
    }
  }, [statusDuplicate, statusTab, dispatch, provinceId, searchBusType, page, limit]);

  return (
    <Layout className="min-vh-70 list_schedule ">
      <Card>
        <TabsFilterStatus activeTab={statusTab} onChangeTab={handleChangeTab} />
        <Row className="fw-500 mt-4 mx-3">
          <Button
            type="primary"
            className={'btn-success btn-add'}
            size="large"
            onClick={() => {
              dispatch(setRoute(undefined));
              setOpenScheduleModal(true);
            }}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            Thêm lịch chạy đơn
          </Button>
        </Row>
        <Form
          className="mg-16 mt-3 mx-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          colon={false}
        >
          <Form.Item className="label-search mb-0" labelAlign="left" labelCol={{ md: 0 }}>
            <Row gutter={10}>
              <Col xs={12} sm={12} md={5}>
                <Form.Item className="mb-0" name="depart_province">
                  <Select
                    placeholder="Chọn tuyến đường"
                    className="input-h36 w-100"
                    showSearch
                    size="large"
                    options={[{ value: '', label: 'Tất cả' }, ...RouteOptions]}
                    filterOption={filterOptionRoute}
                    onChange={onChangeProvince}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={5}>
                <Form.Item className="mb-0" name="Loại xe">
                  <Select
                    className="input-h36"
                    showSearch
                    filterOption={filterOption}
                    placeholder="Loại xe"
                    size="large"
                    // onSearch={onSearchBusType}
                    onChange={onChangeBusType}
                    style={{ width: '100%' }}
                    options={[{ value: '', label: 'Tất cả' }, ...BusOptions]}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} className="ml-auto d-flex justify-content-end align-items-center">
                <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 6)} />
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <Table
          locale={{
            triggerDesc: 'Sắp xếp tăng dần',
            triggerAsc: 'Hủy sắp xếp',
            cancelSort: 'Sắp xếp giảm dần',
          }}
          bordered={false}
          loading={loading}
          rowClassName={(record) => (record?.status ? (record?.is_open_bus ? 'auto-operated' : '') : 'bg-disabled')}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={schedules?.length > 0 ? schedules : []}
          className="list_schedule__table mt-0 mx-3 mt-2"
        />
      </Card>
      <ScheduleModal
        open={openScheduleModal}
        setOpen={setOpenScheduleModal}
        setIsDetail={setIsDetail}
        onCancelSuccess={onCancelSuccess}
        isDetail={isDetail}
        detailSchedule={detailShedule}
        statusTab={statusTab === TabType.ALL ? null : statusTab === TabType.ACTIVE ? true : false}
        provinceId={provinceId}
        searchBusType={searchBusType}
        limit={limit}
        page={page}
      />
      <Form
        className="mg-16 mt-3 mx-3"
        form={formCopySchedule}
        labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
        requiredMark={false}
        colon={false}
      >
        <ModalConfirmCopySchedule
          modalOpen={openModalCopy}
          onCancel={handleCancelModalCopy}
          onOk={handleCopySchedule}
          route={route}
          formCopySchedule={formCopySchedule}
        />
      </Form>
    </Layout>
  );
}

export default Schedule;
