import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../store";

export type ToastState = {
    message: string;
    status?: boolean;
    statusCode?: number;
    type?: string;
};

export const toastSlice = createSlice({
    name: 'Toast',
    initialState: {
        message: '',
        status: false,
        statusCode: 0,
        type: '',
    } as ToastState,
    reducers: {
        setToast: (state: ToastState, action: PayloadAction<ToastState>) => {
            state.message = action.payload.message;
            state.status = action.payload.status;
            state.statusCode = action.payload?.statusCode;
            state.type = action.payload?.type;
        },
        resetToast: (state: ToastState) => {
            state.message = '';
            state.status = false;
            state.statusCode = 0;
            state.type = '';
        },
    },
});

export const toastSelector = (state: RootState) => state.toastNotify;
export const {setToast, resetToast} = toastSlice.actions;
