import { getAxiosClient, getAxiosClientWithToken } from './index';
import { IDUser, ListUserRequest, UpdateUser, UserParams } from '../types';
import { ListAgentRequest } from '../types/agent';
import { listOfficeRequest } from '../types/office';

export const getListUserAPI = (params: ListUserRequest) => {
  return getAxiosClientWithToken().get(`/user?`, { params });
};

export const getInfoUserAPI = () => {
  return getAxiosClient.get(`/user/detail`);
};
export const renderPinCodeAPI = (params: IDUser) => {
  return getAxiosClient.get(`user/render-pin-code`, { params });
};

export const createUserAPI = (params: UserParams) => {
  // const formData = handleFormData(params);
  return getAxiosClientWithToken().post(`/user`, params);
};

export const updateUserAPI = (id: IDUser, params: UpdateUser) => {
  // const formData = handleFormData(params);
  return getAxiosClientWithToken().put(`user/${id}`, params);
};

export const getPermissions = () => {
  return getAxiosClientWithToken().get('/permissions');
};
export const deleteUserAPI = (id: string | number) => {
  return getAxiosClientWithToken().delete(`user/${id}`);
};

export const getListCompanyAPI = (params: any) => {
  return getAxiosClientWithToken().get(`user/list-company`, { params });
};

export const getListAgentApi = (params: ListAgentRequest) => {
  return getAxiosClientWithToken().get('/user/list-agent', { params });
};

export const getUserByIdAPI = (id: any) => {
  return getAxiosClientWithToken().get(`user/${id}`);
};

export const getUserStatusByIdAPI = (id: any, params: any) => {
  return getAxiosClientWithToken().put(`user/status/${id}`, params);
};

export const getListOfficeAPI = (params: listOfficeRequest) => {
  return getAxiosClientWithToken().get(`user/list-office?`, { params });
};

export const getListGeneralAgentAPI = (params: any) => {
  return getAxiosClientWithToken().get(`user/list-main-agent?${params}`);
};
