export const PERMISSIONS = {
    CARLINK: {
        update_company: "update_company", // Quyền cập nhật nhà xe (CRUL)
        manager_carlink_account: "manager_carlink_account", // Quyền quản lý tài khoản nội bộ
        manager_content: "manager_content", // Quyền quản trị nội dung
        manager_comment: "manager_comment", // Quyền quản trị đánh giá
        manager_carlink_report: "manager_carlink_report", // Quyền báo cáo tổng hợp
        manager_agent_viewing: "manager_agent_viewing", // Quyền xem đại lý
        manager_operating_carlink: "manager_operating_carlink", // Quyền điều hành,
        manager_financial_carlink: "manager_financial_carlink" // Quyền quản trị tài chính
    },
    CAR_COMPANY: {
        manager_company_account: "manager_company_account", // Quyền quản trị tài khoản nội bộ
        manager_agent_account_for_company: "manager_agent_account_for_company", // Quyền quản trị đại lý
        manager_operating: "manager_operating", // Quyền điều hành
        manager_company_order_print_ticket: "manager_company_order_print_ticket", // Quyền đặt vé và in vé"
        manager_company_publish_ticket: "manager_company_publish_ticket", // Quyền xuất vé
        manager_bill: "manager_bill", // Quyền quản trị hóa đơn
        manager_company_report: "manager_company_report", // Quyền báo cáo tổng hợp
        manager_company_staff: "manager_company_staff" // Quyền quản lý nhân sự
    },
    AGENT: {
        manager_agent_account: "manager_agent_account", // Quyền quản trị nội bộ
        manager_agent_order_print_ticket: "manager_agent_order_print_ticket", // Quyền đặt vé và in vé
        manager_agent_publish_ticket: "manager_agent_publish_ticket", // Quyền xuất vé
        manager_agent_report: "manager_agent_report" // Quyền báo cáo tổng hợp
    },
    MAIN_AGENT: {
        manager_main_agent_account: "manager_main_agent_account", // Quyền quản trị nội bộ
        manager_main_agent_order_print_ticket: "manager_main_agent_order_print_ticket", // Quyền đặt vé và in vé
        manager_main_agent_publish_ticket: "manager_main_agent_publish_ticket", // Quyền xuất vé
        manager_main_agent_report: "manager_main_agent_report", // Quyền báo cáo tổng hợp
    }
}