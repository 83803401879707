import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Select, Space, Table, Typography } from 'antd';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconExport from '../../assets/images/ic-excel.svg';
import { Constant, DRIVER, USER_GROUP, YearFormat } from '../../config/constant';
import { convertTimeFormat } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/slice/authSlice';
import { getBookingInfo } from '../../redux/slice/bookings/listBooking';
import {
  resetDriverBooking,
  updateDriverBooking,
  updateDriverBookingSelector,
} from '../../redux/slice/bookings/updateDriverSlice';
import { driverSelector, fetchListDriver } from '../../redux/slice/driverSlice';

function ScheduleBookingList({ listBooking, formSearch }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { group, isLogin } = useAppSelector(selectAuth);
  const { drivers } = useAppSelector(driverSelector);
  const { status } = useAppSelector(updateDriverBookingSelector);
  const [keywordDriver, setKeywordDriver] = useState<any>();
  const [dataExportCsv1, setDataExportCsv1] = useState<any>([]);
  const [dataExportCsv2, setDataExportCsv2] = useState<any>([]);
  const searchDateBooking = dayjs(Form.useWatch('date_booking', formSearch)).format(YearFormat);
  const searchDepartTime = Form.useWatch('depart_time', formSearch);
  const provinceId = Form.useWatch('route_id', formSearch);

  const handleChangeDriver = (value: string, record: any) => {
    dispatch(
      updateDriverBooking({
        ticket_code: record?.ticket_code,
        seat_number: record?.seat_number,
        pickup_driver_id: value,
      }),
    );
  };
  const handleChangeDropoffDriver = (value: string, option: any, record: any) => {
    dispatch(
      updateDriverBooking({
        ticket_code: record?.ticket_code,
        seat_number: record?.seat_number,
        drop_off_driver_id: value,
      }),
    );
  };

  const onSearchDriver = (value: string) => {
    setKeywordDriver(value);
  };
  const filterOptionDriver = (
    input: string,
    option?: {
      label: string;
      value: string;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const columns1: ColumnsType<any> = [
    !listBooking?.contract?.code
      ? {
          title: 'Mã đặt vé',
          dataIndex: 'ticket_code',
          key: 'ticket_code_1',
          width: '130px',
          render: (text, record) => (
            <Space size="middle" key={record.seat_number}>
              <FontAwesomeIcon
                icon={faCircle}
                className={`${record?.status === 'reserve' ? 'yellow' : 'green'}`}
                size="xs"
              />
              <Typography>{text}</Typography>
            </Space>
          ),
          sorter: (a: any, b: any) => a?.ticket_code.localeCompare(b?.ticket_code),
        }
      : {
          width: '0',
        },
    {
      title: 'Họ và tên',
      dataIndex: 'customer',
      key: 'customer_name_1',
      width: '170px',
      render: (text) => <Typography>{text?.name}</Typography>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customer',
      key: 'phone_1',
      width: '120px',
      render: (text) => <Typography>{text?.phone}</Typography>,
    },
    {
      title: 'Ghế',
      dataIndex: 'seat_number',
      key: 'seat',
      width: '100px',
    },
    {
      title: 'Địa chỉ đón',
      dataIndex: 'pickup_location',
      key: 'address_pickup_1',
      width: '230px',
      render: (text, record) => <Typography>{text ? text?.location_name : record?.pickup_extra}</Typography>,
    },
    {
      title: 'Tài xế đón',
      dataIndex: 'pickup_driver',
      key: 'driver_pickup',
      width: '240px',
      render: (text, record) => (
        <Select
          showSearch
          disabled={listBooking?.trip?.status === 'departed' || listBooking?.trip?.status === 'canceled'}
          placeholder={t('Chọn tài xế đón')}
          onChange={(value: any, option: any) => handleChangeDriver(value, record)}
          value={text}
          onSearch={onSearchDriver}
          filterOption={filterOptionDriver}
          options={
            drivers?.length > 0
              ? drivers?.map((item: any) => ({
                  value: item?.id,
                  label: item?.name,
                }))
              : []
          }
        />
      ),
    },
  ];
  const columns1Export: IExcelColumn[] = [
    {
      title: 'Mã đặt vé',
      __excelTitle__: 'Mã đặt vé',
      dataIndex: 'ticket_code',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
    },
    {
      title: 'Họ và tên',
      dataIndex: 'customer_name',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 20,
      },
      __excelTitle__: 'Họ và tên',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customer_phone',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 20,
      },
      __excelTitle__: 'Số điện thoại',
    },
    {
      title: 'Ghế',
      dataIndex: listBooking?.schedule?.is_diagram ? 'seat_number' : ' ',
      align: 'center',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 10,
      },
      __excelTitle__: 'Ghế',
    },
    {
      title: 'Địa chỉ đón',
      dataIndex: 'pickup_location',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 25,
      },
      __excelTitle__: 'Địa chỉ đón',
      width: 25,
    },
    {
      title: 'Tài xế đón',
      dataIndex: 'pickup_driver',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 20,
      },
      __excelTitle__: 'Tài xế đón',
    },
  ];
  const columns2: ColumnsType<any> = [
    !listBooking?.contract?.code
      ? {
          title: 'Mã đặt vé',
          dataIndex: 'ticket_code',
          key: 'ticket_code_2',
          width: '130px',
          render: (text, record) => (
            <Space size="middle" key={record.id}>
              <FontAwesomeIcon
                icon={faCircle}
                className={`${record?.status === 'reserve' ? 'yellow' : 'green'}`}
                size="xs"
              />
              <Typography>{text}</Typography>
            </Space>
          ),
          sorter: (a: any, b: any) => a?.id.localeCompare(b?.id),
        }
      : { width: '0' },
    {
      title: 'Họ và tên',
      dataIndex: 'customer',
      key: 'customer_name_2',
      width: '170px',
      render: (text) => <Typography>{text?.name}</Typography>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customer',
      key: 'customer_phone_2',
      width: '120px',
      render: (text) => <Typography>{text?.phone}</Typography>,
    },
    {
      title: 'Ghế',
      dataIndex: 'seat_number',
      key: 'seat',
      width: '100px',
    },
    {
      title: 'Địa chỉ trả',
      dataIndex: 'drop_off_location',
      key: 'drop_off_location',
      width: '230px',
      render: (text, record) => <Typography>{text ? text?.location_name : record?.drop_off_extra}</Typography>,
    },
    {
      title: 'Tài xế trả',
      dataIndex: 'drop_off_driver',
      key: 'drop_off_driver',
      width: '240px',
      render: (text, record) => (
        <Select
          showSearch
          placeholder={t('Chọn tài xế trả')}
          disabled={listBooking?.trip?.status === 'departed' || listBooking?.trip?.status === 'canceled'}
          onChange={(value: any, option: any) => handleChangeDropoffDriver(value, option, record)}
          value={text}
          onSearch={onSearchDriver}
          filterOption={filterOptionDriver}
          options={
            drivers?.length > 0
              ? drivers?.map((item: any) => ({
                  value: item?.id,
                  label: item?.name,
                }))
              : []
          }
        />
      ),
    },
  ];
  const columns2Export: IExcelColumn[] = [
    {
      title: 'Mã đặt vé',
      __excelTitle__: 'Mã đặt vé',
      dataIndex: 'ticket_code',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
    },
    {
      title: 'Họ và tên',
      dataIndex: 'customer_name',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Họ và tên',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customer_phone',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 20,
      },
      __excelTitle__: 'Số điện thoại',
    },
    {
      title: 'Ghế',
      dataIndex: 'seat_number',
      align: 'center',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 10,
      },
      __excelTitle__: 'Ghế',
    },
    {
      title: '',
      dataIndex: 'drop_off_location',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 20,
      },
      __excelTitle__: 'Địa chỉ trả',
    },
    {
      title: '',
      dataIndex: 'drop_off_driver',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        border: true,
        borderColor: 'black',
        width: 25,
      },
      __excelTitle__: 'Tài xế trả',
      width: 25,
    },
  ];
  const handleExportCsv1 = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Danh sách trung chuyển đón');
    const header = columns1Export.map((col) => ({ header: col.title, key: col.dataIndex as string }));
    worksheet.columns = header;

    columns1Export.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width || 15;
    });
    worksheet.addRows(dataExportCsv1);
    worksheet.getRow(1).font = { name: 'Times New Roman', size: 12, bold: true };
    worksheet.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3AB54A' } };
    worksheet.getRow(1).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    let firstRowHasData = false;
    worksheet.getRow(1).eachCell((cell) => {
      if (cell.value) {
        firstRowHasData = true;
        return;
      }
    });

    if (firstRowHasData) {
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber > 1) {
          row.eachCell((cell) => {
            cell.font = { name: 'Times New Roman', size: 12 };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
        }
      });
    } else {
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          let hasData = false;
          row.eachCell((cell) => {
            if (cell.value) {
              hasData = true;
              return;
            }
          });
          if (hasData) {
            row.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          }
        }
      });
    }
    const lastRow = worksheet.addRow([]);
    lastRow.getCell(1).value = '@Bản quyền thuộc nhà xe Carlink ';
    lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    lastRow.getCell(1).font = { name: 'Times New Roman', size: 12 };
    const startColumnIndex = 2;
    const endColumnIndex = startColumnIndex + columns1Export.length - 1;
    worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Danh sách trung chuyển đón.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  const handleExportCsv2 = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Danh sách trung chuyển trả');
    const header = columns2Export.map((col) => ({ header: col.title, key: col.dataIndex as string }));
    worksheet.columns = header;

    columns2Export.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width || 15;
    });
    worksheet.addRows(dataExportCsv2);
    worksheet.getRow(1).font = { name: 'Times New Roman', size: 12, bold: true };
    worksheet.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3AB54A' } };
    worksheet.getRow(1).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    let firstRowHasData = false;
    worksheet.getRow(1).eachCell((cell) => {
      if (cell.value) {
        firstRowHasData = true;
        return;
      }
    });

    if (firstRowHasData) {
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber > 1) {
          row.eachCell((cell) => {
            cell.font = { name: 'Times New Roman', size: 12 };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
        }
      });
    } else {
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          let hasData = false;
          row.eachCell((cell) => {
            if (cell.value) {
              hasData = true;
              return;
            }
          });
          if (hasData) {
            row.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          }
        }
      });
    }
    const lastRow = worksheet.addRow([]);
    lastRow.getCell(1).value = '@Bản quyền thuộc nhà xe Carlink ';
    lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    lastRow.getCell(1).font = { name: 'Times New Roman', size: 12 };
    const startColumnIndex = 2;
    const endColumnIndex = startColumnIndex + columns2Export.length - 1;
    worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Danh sách trung chuyển trả.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  useEffect(() => {
    if (group !== USER_GROUP.AGENT && isLogin) {
      dispatch(fetchListDriver({ keyword: keywordDriver, status: Constant.STATUS_ACTIVE, type: DRIVER }));
    }
  }, [dispatch, keywordDriver, group, isLogin]);

  const filteredData = listBooking?.booking?.data
    ? listBooking.booking.data.filter((item: any) => item.status !== 'locked')
    : [];
  const onCancelSuccess = useCallback(() => {
    dispatch(resetDriverBooking());
    dispatch(
      getBookingInfo({
        route_id: provinceId,
        depart_date: searchDateBooking,
        depart_time: convertTimeFormat(searchDepartTime),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formSearch, provinceId, searchDateBooking, searchDepartTime]);
  useEffect(() => {
    if (status === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (listBooking?.booking?.data?.length) {
      const tmpListCustomerBookingCsv1 = listBooking?.booking?.data?.map((item: any) => {
        return {
          ...item,
          customer_phone: item?.customer?.phone,
          customer_name: item?.customer?.name,
          pickup_location: item?.pickup_location?.location_name
            ? item?.pickup_location?.location_name
            : item?.pickup_extra,
          pickup_driver: item?.pickup_driver ?? 'Không chỉ định',
        };
      });
      const tmpListCustomerBookingCsv2 = listBooking?.booking?.data?.map((item: any) => {
        return {
          ...item,
          customer_phone: item?.customer?.phone,
          customer_name: item?.customer?.name,
          drop_off_location: item?.drop_off_location?.location_name
            ? item?.drop_off_location?.location_name
            : item?.drop_off_extra,
          drop_off_driver: item?.drop_off_driver ?? 'Không chỉ định',
        };
      });
      setDataExportCsv1(tmpListCustomerBookingCsv1);
      setDataExportCsv2(tmpListCustomerBookingCsv2);
    } else {
      setDataExportCsv1([]);
      setDataExportCsv2([]);
    }
  }, [listBooking]);
  return (
    <>
      <div className="table-booking">
        <Space className="table-booking-title">
          <Typography className="fw-600 fs-16">{t('Danh sách trung chuyển đón')}</Typography>
          <Button className="btn-export" onClick={() => handleExportCsv1()}>
            <img src={iconExport} alt="excel" />
            Xuất excel
          </Button>
        </Space>
        <Table
          key="pickupTable"
          locale={{
            triggerDesc: 'Sắp xếp giảm dần',
            triggerAsc: 'Sắp xếp tăng dần',
            cancelSort: 'Hủy sắp xếp',
          }}
          dataSource={filteredData}
          rowKey="id"
          columns={columns1}
          className="list-bus"
          pagination={false}
        />
      </div>
      <div className="table-booking mt-3">
        <Space className="table-booking-title">
          <Typography className="fw-600 fs-16">{t('Danh sách trung chuyển trả')}</Typography>
          <Button className="btn-export" onClick={() => handleExportCsv2()}>
            <img src={iconExport} alt="excel" />
            Xuất excel
          </Button>
        </Space>
        <Table
          key="dropOffTable"
          locale={{
            triggerDesc: 'Sắp xếp giảm dần',
            triggerAsc: 'Sắp xếp tăng dần',
            cancelSort: 'Hủy sắp xếp',
          }}
          dataSource={filteredData}
          rowKey="id"
          columns={columns2}
          className="list-bus"
          pagination={false}
        />
      </div>
    </>
  );
}
export default ScheduleBookingList;
