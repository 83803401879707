import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import {
  getBookingByIdApi,
  getBookingInfoApi,
  getBookingSearchApi,
  getFilterBookingApi,
  getListBookingCustomerApi,
  getListDepartTimeApi,
  getListHistoryBookingApi,
  getListStaffApi,
} from '../../../api/booking';
import { Constant } from '../../../config/constant';
import {
  BookingInfoState,
  BookingsState,
  ListBookingCustomerType,
  ListDepartTimeType,
  ParamsBookingSearch,
  ParamsFilterBooking,
  ParamsHistoryBooking,
  getBookingByIdType,
} from '../../../types/booking';
import { RootState } from '../../store';

// @ts-ignore
export const getBookingInfo = createAsyncThunk(
  'admin/bookingInfo',
  async (params: BookingInfoState, { dispatch, rejectWithValue }) => {
    try {
      const response = await getBookingInfoApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListBooking(data));
        return true;
      }
    } catch (error: any) {
      const { response } = error;
      if (response?.data?.message === 'Không tìm thấy thông tin chuyến') {
        dispatch(resetListBooking());
      }
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const getListBookingDetail = createAsyncThunk(
  'admin/listBookingDetail',
  async (params: getBookingByIdType, { dispatch, rejectWithValue }) => {
    try {
      const response = await getBookingByIdApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListBookingDetail(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getListDepartTime = createAsyncThunk(
  'admin/listDepartTime',
  async (params: ListDepartTimeType, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListDepartTimeApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListDepartTime(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getListCustomerBooking = createAsyncThunk(
  'admin/listCustomerBooking',
  async (params: ListBookingCustomerType, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListBookingCustomerApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListCustomerBooking(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getListHistoryBooking = createAsyncThunk(
  'admin/listHistoryBooking',
  async (params: ParamsHistoryBooking, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListHistoryBookingApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListHistoryBooking(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getListFilterBooking = createAsyncThunk(
  'admin/listFilterBooking',
  async (params: ParamsFilterBooking, { dispatch, rejectWithValue }) => {
    try {
      const response = await getFilterBookingApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListFilterBooking(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getBookingSearch = createAsyncThunk(
  'admin/listBookingSearch',
  async (params: ParamsBookingSearch, { dispatch, rejectWithValue }) => {
    try {
      const response = await getBookingSearchApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListBookingSearch(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getListStaff = createAsyncThunk('admin/booking-staff', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await getListStaffApi();
    const { data, success } = response?.data;
    if (success) {
      dispatch(setListStaff(data));
      return true;
    }
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});

export const listBookingSlice = createSlice({
  name: 'listBooking',
  initialState: {
    error: false,
    loading: false,
    success: false,
    listBooking: [],
    pagination: [],
    paginationHistory: [],
    status: 0,
    message: '',
    listBookingDetail: {},
    listDepartTime: {},
    listCustomerBooking: {},
    listHistoryBooking: {},
    listFilterBooking: {},
    listBookingSearch: {},
    errorDetail: null,
    loadingCustomerBooking: false,
    loadingHistory: false,
    loadingListBooking: false,
    loadingListBookingDetail: false,
    loadingRoute: false,
    noLoading: false,
    paginationCustomer: [],
    isShowAllFloor: true,
    extra: [],
    listStaff: [],
    ticketCode: '',
    isMoveTicket: false,
    dataMoveTicket: {},
    bookingData: [],
    dataSearchBooking: {
      province_id: null,
      depart_time: null,
      depart_date: null,
      empty_seat: null,
    },
  } as BookingsState,
  reducers: {
    setListBooking: (state: BookingsState, { payload }) => {
      state.listBooking = payload?.data;
      state.pagination = payload?.pagination;
      state.bookingData = payload?.data?.booking?.data;
    },
    setListBookingDetail: (state: BookingsState, { payload }) => {
      state.listBookingDetail = payload?.data;
    },
    setListDepartTime: (state: BookingsState, { payload }) => {
      state.listDepartTime = payload?.data;
    },
    setListCustomerBooking: (state: BookingsState, { payload }) => {
      state.listCustomerBooking = payload?.data;
      state.paginationCustomer = payload?.pagination;
      state.extra = payload?.extra;
    },
    setListHistoryBooking: (state: BookingsState, { payload }) => {
      state.listHistoryBooking = payload?.data;
      state.paginationHistory = payload?.pagination;
    },
    setListBookingLoad: (state: BookingsState) => {
      state.listBooking = [];
      state.listBookingDetail = [];
      state.bookingData = [];
    },
    setListFilterBooking: (state: BookingsState, { payload }) => {
      state.listFilterBooking = payload?.data;
    },
    setListBookingSearch: (state: BookingsState, { payload }) => {
      state.listBookingSearch = payload?.data;
    },
    resetListBookingDetail: (state: BookingsState) => {
      state.listBookingDetail = [];
    },
    resetListBooking: (state: BookingsState) => {
      state.listBooking = [];
    },
    setDetectLoading: (state: BookingsState) => {
      state.noLoading = true;
    },
    resetDetectLoading: (state: BookingsState) => {
      state.noLoading = false;
    },
    setListStaff: (state: BookingsState, { payload }) => {
      state.listStaff = payload;
    },
    setIsMoveTicket: (state: BookingsState, { payload }) => {
      state.isMoveTicket = payload;
    },
    setDataMoveTicket: (state: BookingsState, { payload }) => {
      const { name, ticket_code, schedule_id, company_id } = payload;
      state.dataMoveTicket = {
        ...state.dataMoveTicket,
        ticket_code: ticket_code !== undefined ? ticket_code : state.dataMoveTicket.ticket_code,
        schedule_id: schedule_id !== undefined ? schedule_id : state.dataMoveTicket.schedule_id,
        company_id: company_id !== undefined ? company_id : state.dataMoveTicket.company_id,
        name: name !== undefined ? name : state.dataMoveTicket.name,
      };
    },
    resetDataMoveTicket: (state: BookingsState) => {
      state.dataMoveTicket = {};
      state.isMoveTicket = false;
    },
    setDataSearchBooking: (state, action) => {
      const { province_id, depart_time, depart_date, empty_seat } = action.payload;
      state.dataSearchBooking = {
        ...state.dataSearchBooking,
        province_id: province_id !== undefined ? province_id : state.dataSearchBooking.province_id,
        depart_time: depart_time !== undefined ? depart_time : state.dataSearchBooking.depart_time,
        depart_date: depart_date !== undefined ? depart_date : state.dataSearchBooking.depart_date,
        empty_seat: empty_seat !== undefined ? empty_seat : state.dataSearchBooking.empty_seat,
      };
    },
    setIsShowAllFloor: (state, { payload }) => {
      state.isShowAllFloor = payload;
    },
    resetDataSearchBooking: (state) => {
      state.dataSearchBooking = {
        province_id: null,
        depart_time: null,
        depart_date: null,
        empty_seat: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBookingInfo.pending, (state: BookingsState) => {
      state.loadingListBooking = true;
    });
    builder.addCase(getBookingInfo.fulfilled, (state: BookingsState) => {
      state.loadingListBooking = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getBookingInfo.rejected, (state: BookingsState) => {
      state.loadingListBooking = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getListBookingDetail.pending, (state: BookingsState) => {
      state.loadingListBookingDetail = true;
    });
    builder.addCase(getListBookingDetail.fulfilled, (state: BookingsState) => {
      state.loadingListBookingDetail = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListBookingDetail.rejected, (state: BookingsState) => {
      state.loadingListBookingDetail = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getListCustomerBooking.pending, (state: BookingsState) => {
      state.loadingCustomerBooking = true;
    });
    builder.addCase(getListCustomerBooking.fulfilled, (state: BookingsState) => {
      state.loadingCustomerBooking = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListCustomerBooking.rejected, (state: BookingsState) => {
      state.loadingCustomerBooking = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getListHistoryBooking.pending, (state: BookingsState) => {
      state.loadingHistory = true;
    });
    builder.addCase(getListHistoryBooking.fulfilled, (state: BookingsState) => {
      state.loadingHistory = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListHistoryBooking.rejected, (state: BookingsState) => {
      state.loadingHistory = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getListFilterBooking.pending, (state: BookingsState) => {
      state.loadingRoute = true;
    });
    builder.addCase(getListFilterBooking.fulfilled, (state: BookingsState) => {
      state.loadingRoute = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListFilterBooking.rejected, (state: BookingsState) => {
      state.loadingRoute = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const listBookingSelector = (state: RootState) => state.listBooking;
export const {
  setListBooking,
  setListBookingDetail,
  setListBookingLoad,
  setListDepartTime,
  setListCustomerBooking,
  setListHistoryBooking,
  setListFilterBooking,
  setListBookingSearch,
  resetListBookingDetail,
  setDetectLoading,
  resetDetectLoading,
  setListStaff,
  setDataSearchBooking,
  resetDataSearchBooking,
  resetListBooking,
  setIsShowAllFloor,
  setIsMoveTicket,
  setDataMoveTicket,
  resetDataMoveTicket,
} = listBookingSlice.actions;
