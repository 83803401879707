/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Col, Flex, Image, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";
import "../../assets/styles/home.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Carlink from "./components/Carlink";
import CarCompany from "./components/CarCompany";
import { fetchAccount } from "../../redux/slice/auth/accountSlice";
import { Constant, USER_GROUP } from "../../config/constant";
import { getListDashboardBooking } from "../../api/dashboard";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { formatTime, formatTimeDDMMYYYY } from "../../lib/utils";
import statusPending from "../../assets/images/orders/status-pending.svg";
import "../../assets/styles/home.scss";
import TextDisplay from "../../components/Display/TextDisplay";

type ColumnsType<T> = TableProps<T>["columns"];

interface DataType {
  name: {
    first: string;
    last: string;
  };
  gender: string;
  email: string;
  login: {
    uuid: string;
  };
}

const handleIcCircle = (status: any) => {
  if (status === "reserve") return "#FFE296";
  if (status === "paid") return "#3AB54A";
  if (status === "cancel") return "#D81717";
  return "#FFE296";
};

const Home: React.FC = () => {
  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? DEFAULT_PAGE_SIZE
    : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const [data, setData] = useState<DataType[]>([]);
  const accountUser = useAppSelector((state) => state.authAccount?.user);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<any>();
  const [page, setPage] = useState<number>(1);

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "Mã đặt vé",
    //   dataIndex: "ticket_code",
    //   sorter: (a: any, b: any) => a?.ticket_code?.localeCompare(b?.ticket_code),
    //   render: (text, record: any) => (
    //     <Space size={12}>
    //       <FontAwesomeIcon
    //         icon={faCircle}
    //         style={{color: `${handleIcCircle(record?.status)}` }}
    //         size="xs"
    //       />
    //       <Typography className="text-color">{text}</Typography>
    //     </Space>
    //   ),
    // },
    {
      title: "STT",
      key: "id",
      width: 85,

      render: (_: any, record: any, index: number) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            {record?.status === "reserve" ? (
              <img
                height={16}
                className="object-fit-scale w-auto"
                src={statusPending}
                alt=""
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: `${handleIcCircle(record?.status)}` }}
                size="xs"
              />
            )}

            <Typography className="text-color">
              {(page - 1) * limit + index + 1}
            </Typography>
          </div>
        );
      },
    },
    {
      title: "Tên hành khách",
      dataIndex: "customer",
      render: (text, record: any) => (
        <Flex
          className="Order__column__booking-code"
          style={{ gap: 15 }}
          align="center"
        >
          {/* <Flex style={{ width: "36px" }} align="center" justify="center">
            {record?.status === "reserve" && (
              <img
                height={16}
                className="object-fit-scale w-auto"
                src={statusPending}
                alt=""
              />
            )}
          </Flex> */}
          <Flex className="flex-column">
            <Typography.Text className="fs-16 text-color fw-500">
              <TextDisplay text={text?.name ? text?.name : "N/A"} />
            </Typography.Text>
            <Typography.Text className="fs-14 text-color-green-default fw-500">
              {formatTime(record?.created_at)}
            </Typography.Text>
          </Flex>
        </Flex>
      ),
      // sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
    },
    {
      title: "Số điện thoại",
      dataIndex: "customer",
      render: (text) => (
        <Typography className="text-color">
          {text?.phone ? text?.phone : "N/A"}
        </Typography>
      ),
    },
    {
      title: "Tuyến xe",
      dataIndex: "route",
      render: (text) => (
        <Typography className="text-color">
          {text?.depart_point} - {text?.arrive_point}
        </Typography>
      ),
    },
    {
      title: "Ngày đi",
      dataIndex: "depart_date",
      sorter: (a: any, b: any) => a?.depart_date?.localeCompare(b?.depart_date),
      render: (text) => (
        <Typography className="text-color">
          {formatTimeDDMMYYYY(text)}
        </Typography>
      ),
    },
    {
      title: "Giờ đi",
      dataIndex: "depart_time",
      sorter: (a: any, b: any) => a?.depart_time?.localeCompare(b?.depart_time),
    },
    {
      title: "Tài khoản đặt vé",
      dataIndex: "orderer",
      render: (text) => (
        <Typography className="text-color">{text?.name}</Typography>
      ),
    },
    // {
    //   title: "Ngày đặt",
    //   dataIndex: "created_at",
    //   sorter: (a: any, b: any) => a?.created_at?.localeCompare(b?.created_at),
    //   render: (text) => (
    //     <Typography className="text-color">
    //       {formatTimeDDMMYYYY(text)}
    //     </Typography>
    //   ),
    // },
    // {
    //   title: "",
    //   dataIndex: "is_new",
    //   render: (text) => {
    //     return <>{text && <Image src={icRealTime} preview={false} />}</>;
    //   },
    // },
  ];

  const dispatch = useAppDispatch();
  let query = `page=${page}&limit=${limit}`;

  const handleListDashboardBooking = async () => {
    setLoading(true);
    try {
      const res: any = await getListDashboardBooking(query);
      if (res?.status === 200) {
        setData(res?.data?.data);
        const { current_page, total, per_page } = res?.data;
        setPagination({ current_page, total, per_page });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleLayout = () => {
    if (accountUser?.group === USER_GROUP.CARLINK) {
      return (
        <>
          <Carlink />
        </>
      );
    }
    if (
      accountUser?.group === USER_GROUP.CAR_COMPANY ||
      accountUser?.group === USER_GROUP.AGENT ||
      accountUser?.group === USER_GROUP.MAIN_AGENT
    ) {
      return (
        <>
          <CarCompany />
        </>
      );
    }
    return <></>;
  };

  const renderRowTable = (record: any) => {
    return record?.status === "cancel" ? "bg-disabled" : "";
  };

  useEffect(() => {
    handleListDashboardBooking();
  }, [query]);

  useEffect(() => {
    dispatch(fetchAccount());
  }, []);

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };
  return (
    <div className="wrapper-page-home">
      <Card>
        <Row className="header-home">
          <Col className="text-header-left">Vé mới đặt</Col>
          <Col className="text-header-right">Danh sách vé đã đặt</Col>
        </Row>
        <Table
          columns={columns}
          rowKey={(record: any) => record?.id}
          dataSource={data?.length > 0 ? data : []}
          rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          loading={loading}
        />
      </Card>
      {handleLayout()}
    </div>
  );
};

export default Home;
