export const reasonOptions = [
    { label: 'Nhà xe hủy', value: 'car_company' },
    { label: 'Đại lý hủy', value: 'agent' },
    { label: 'Khách hàng hủy', value: 'customer' },
  ];
  export const reasonAgentOptions = [
    { label: 'Đại lý hủy', value: 'agent' },
    { label: 'Khách hàng hủy', value: 'customer' },
  ];
  export const reasonContractOptions = [
    { label: 'Nhà xe hủy', value: 'car_company' },
    { label: 'Khách hàng hủy', value: 'customer' },
  ];
  export const sendOptions = [
    { label: 'Gửi SMS', value: 0 },
    { label: 'Gửi Email', value: 1 },
  ];
  