import { getAxiosClientWithToken } from "./index";
import { handleFormData } from "../lib/utils";
// import {IDUser, ListUserRequest, UpdateUser, UserParams} from "../types";
import {
  IDDriver,
  ListDriverRequest,
  UpdateDriver,
  DriverParams,
} from "../types";

export const getListDriverAPI = (params: ListDriverRequest) => {
  return getAxiosClientWithToken().get("/drivers", { params });
};

export const getDriverAPI = (params: IDDriver) => {
  return getAxiosClientWithToken().get(`drivers/${params.id}`);
};

export const createDriverAPI = (params: DriverParams) => {
  const formData = handleFormData(params);
  return getAxiosClientWithToken().post(`/drivers`, formData);
};

export const updateDriverAPI = (id: IDDriver, params: UpdateDriver) => {
  // const formData = handleFormData(params);
  return getAxiosClientWithToken().put(`drivers/${id}`, params);
};

export const updateStatusDriverAPI = (
  id: IDDriver,
  status: boolean | undefined,
  reason: string | undefined,
  type: string
) => {
  // const formData = handleFormData(params);
  return getAxiosClientWithToken().put(`drivers/status/${id}`, {
    status: status,
    reason: reason,
    type: type
  });
};

// export const updateDriverAPI = (params: DriverParams) => {
//     const formData = handleFormData(params);
//     return getAxiosClientWithToken().put(`/Driver/${params.id}`, formData);
// };

export const deleteDriverAPI = (id: string | number) => {
  return getAxiosClientWithToken().delete(`drivers/${id}`);
};
