import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { DriverParams, ListDriverRequest } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  driverSelector,
  fetchListDriver,
  setDriver,
} from "../../redux/slice/driverSlice";
import { mbTrim, useRedirectStatusError } from "../../lib/utils";
import { Constant } from "../../config/constant";
import icEdit from "../../assets/images/ic-edit.svg";
import IcTimeOut from "../../assets/images/ic-time-out.svg";
import ExtraDriverModal from "./ExtraDriverModal";
import DriverModal from "./DriverModal";
import StatusFilter from "../../components/Modal/StatusFilter";
import IcPlus from "../../assets/images/ic-plus.svg";
import TutorialsVideo from "../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../utils/helpers";

const { Link } = Typography;

function ListDriver() {
  const limit_pagination: any = window.localStorage.getItem("limit_pagination");
  const limitPagination = isNaN(Number(limit_pagination))
    ? DEFAULT_PAGE_SIZE
    : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(
    limitPagination || DEFAULT_PAGE_SIZE
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [status, setStatus] = useState<boolean>();
  const [type, setDriverType] = useState<string>("");
  const [keyword, setKeyword] = useState<ListDriverRequest>({});
  const [page, setPage] = useState<number>(1);
  const [openDriverModal, setOpenDriverModal] = useState<boolean>(false);
  const [openExtraDriverModal, setOpenExtraDriverModal] =
    useState<boolean>(false);
  const listTutorials = useAppSelector(
    (state) => state?.tutorials?.listTutorials
  );

  const {
    loading,
    drivers,
    pagination,
    status: statusResponse,
  } = useAppSelector(driverSelector);

  const columns: ColumnsType<DriverParams> = [
    {
      title: "",
      key: "id",
      dataIndex: "id",
      width: "36px",
      render: (_, record) => (
        <Space size="middle">
          {record?.status === Constant.STATUS_ACTIVE ? (
            <FontAwesomeIcon icon={faCircle} className="p-1 green" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faCircle} className="p-1 danger" size="xs" />
          )}
        </Space>
      ),
    },
    {
      title: "Họ và tên",
      dataIndex: "name",
      key: "name",
      width: "280px",
      sorter: (a, b) => a?.name?.length - b?.name?.length,
    },
    {
      title: "Ngày sinh",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      align: "center",
      render: (_, record) => moment(record?.date_of_birth).format("DD/MM/YYYY"),
      sorter: (a, b) => {
        if (
          a &&
          a?.date_of_birth &&
          a?.date_of_birth?.length &&
          b &&
          b?.date_of_birth &&
          b?.date_of_birth?.length
        ) {
          return a?.date_of_birth.localeCompare(b?.date_of_birth);
        } else if (a && a?.date_of_birth && a?.date_of_birth?.length) {
          // that means be has null date_of_birth, so a will come first.
          return -1;
        } else if (b && b?.date_of_birth && b?.date_of_birth?.length) {
          // that means a has null date_of_birth so b will come first.
          return 1;
        }
        // both date_of_birth has null value so there will be no order change.
        return 0;
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      align: "center",
    },
    {
      title: "Hạng bằng lái",
      dataIndex: "license_class",
      key: "license_class",
      align: "center",
      sorter: (a, b) => {
        if (
          a &&
          a?.license_class &&
          a?.license_class?.length &&
          b &&
          b?.license_class &&
          b?.license_class?.length
        ) {
          return a?.license_class?.localeCompare(b?.license_class);
        } else if (a && a?.license_class && a?.license_class?.length) {
          // that means be has null license_class, so a will come first.
          return -1;
        } else if (b && b?.license_class && b?.license_class?.length) {
          // that means a has null license_class so b will come first.
          return 1;
        }
        // both license_class has null value so there will be no order change.
        return 0;
      },
    },
    {
      title: "Hạn bằng lái",
      dataIndex: "date_expired_license",
      key: "date_expired_license",
      render: (_, record) => {
        if (record?.type === "driver") {
          return (
            <Space size="middle">
              {moment(record?.date_expired_license)
                .subtract(7, "days")
                .unix() <= moment(new Date()).unix() ? (
                <>
                  <Typography className="text-time-out">
                    {moment(record?.date_expired_license).format("DD/MM/YYYY")}
                  </Typography>
                  <Image src={IcTimeOut} preview={false} className="mb-1" />
                </>
              ) : (
                <Typography className="text-color">
                  {moment(record?.date_expired_license).format("DD/MM/YYYY")}
                </Typography>
              )}
            </Space>
          );
        } else {
          return "";
        }
      },
      sorter: (a, b) => {
        if (
          a &&
          a?.date_expired_license &&
          a?.date_expired_license?.length &&
          b &&
          b?.date_expired_license &&
          b?.date_expired_license?.length
        ) {
          return a?.date_expired_license.localeCompare(b?.date_expired_license);
        } else if (
          a &&
          a?.date_expired_license &&
          a?.date_expired_license?.length
        ) {
          // that means be has null date_expired_license, so a will come first.
          return -1;
        } else if (
          b &&
          b?.date_expired_license &&
          b?.date_expired_license?.length
        ) {
          // that means a has null date_expired_license so b will come first.
          return 1;
        }
        // both date_expired_license has null value so there will be no order change.
        return 0;
      },
    },
    {
      title: "Chức năng",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "120px",
      render: (_, record) => (
        <Space size="middle">
          <Link
            onClick={() => {
              record?.type === "driver"
                ? setOpenDriverModal(true)
                : setOpenExtraDriverModal(true);
              dispatch(setDriver(record));
            }}
          >
            <Image src={icEdit} preview={false} />
          </Link>
        </Space>
      ),
    },
  ];

  const renderRowTable = (record: any) => {
    return record?.status ? "" : "bg-disabled";
  };

  const onSearch = (value: ListDriverRequest) => {
    form.setFields([{ name: "keyword", value: mbTrim(value?.keyword) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(form.getFieldsValue(["keyword"]));
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const handleChangeStatus = (value: boolean | undefined) => {
    setStatus(value);
    setPage(1);
  };

  const onChangeDriverType = (value: string) => {
    setDriverType(value);
  };

  useEffect(() => {
    dispatch(fetchListDriver({ ...keyword, type, page, status, limit }));
  }, [dispatch, keyword, type, page, status, limit]);

  const cancelModal = useCallback(() => {
    setOpenDriverModal(false);
    form.resetFields();
    form.setFieldValue("keyword", keyword?.keyword);
    form.setFieldValue("group", type);
  }, [form, type, keyword?.keyword]);

  const onCancelSuccess = useCallback(() => {
    dispatch(fetchListDriver({ ...keyword, page, status, limit, type }));
    cancelModal();
  }, [dispatch, page, keyword, status, limit, type, cancelModal]);

  useRedirectStatusError(statusResponse);

  return (
    <Layout className="min-vh-70 list_driver">
      <Card>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        <Row className="fw-500 mt-4 mx-3">
          <Button
            type="primary"
            className={"btn-success btn-add"}
            size="large"
            onClick={() => {
              dispatch(setDriver(undefined));
              setOpenDriverModal(true);
            }}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t("Thêm tài xế")}
          </Button>
          <Button
            type="primary"
            className={"btn-success btn-add"}
            size="large"
            onClick={() => {
              dispatch(setDriver(undefined));
              setOpenExtraDriverModal(true);
            }}
          >
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t("Thêm phụ xe")}
          </Button>
        </Row>
        <Form
          className="mg-16 mt-3 mx-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          onFinish={onSearch}
          colon={false}
          style={{ position: "relative" }}
        >
          <Form.Item
            className="label-search mb-0"
            labelAlign="left"
            labelCol={{ md: 0 }}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6}>
                <Form.Item name="keyword">
                  <Input
                    placeholder="Nhập họ tên / SĐT"
                    size="large"
                    className="input-h36 padding-normal"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={3}>
                <Form.Item name="group" initialValue={""}>
                  <Select
                    className="input-h36"
                    size="large"
                    onChange={onChangeDriverType}
                    style={{ width: "100%" }}
                    options={[
                      { value: "", label: "Tất cả" },
                      { value: "driver", label: "Tài xế" },
                      { value: "extra_driver", label: "Phụ xe" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col className="text-center">
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  className={"carlink-btn btn-search btn-small padding-normal"}
                >
                  Tìm kiếm
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Space
            size={10}
            align="center"
            style={{ position: "absolute", right: 0, top: 7 }}
          >
            <TutorialsVideo
              url_youtube={handleGetUrlGuide(listTutorials, 17)}
            />
          </Space>
        </Form>
        <Table
          locale={{
            triggerDesc: "Sắp xếp tăng dần",
            triggerAsc: "Hủy sắp xếp",
            cancelSort: "Sắp xếp giảm dần",
          }}
          bordered={false}
          loading={loading}
          rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          rowKey={(record) => record.id}
          scroll={{ y: 1100 }}
          columns={columns}
          dataSource={drivers?.length > 0 ? drivers : []}
          className="mt-0 mx-3"
        />
      </Card>
      <DriverModal
        open={openDriverModal}
        setOpen={setOpenDriverModal}
        onCancelSuccess={onCancelSuccess}
        listTutorials={listTutorials}
      />
      <ExtraDriverModal
        open={openExtraDriverModal}
        setOpen={setOpenExtraDriverModal}
        onCancelSuccess={onCancelSuccess}
        listTutorials={listTutorials}
      />
    </Layout>
  );
}

export default ListDriver;
