import { Button, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import iconDelete from '../../assets/images/icon-delete-numpad.svg';
import '../../assets/styles/desktop/busBooking.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { checkBookingPinCode, setCheckPinCode, setPinCode } from '../../redux/slice/bookings/createBookingSlice';

const itemNumpad = [
  { id: 1, label: 1 },
  { id: 2, label: 2 },
  { id: 3, label: 3 },
  { id: 4, label: 4 },
  { id: 5, label: 5 },
  { id: 6, label: 6 },
  { id: 7, label: 7 },
  { id: 8, label: 8 },
  { id: 9, label: 9 },
  { id: 'blank', label: '' },
  { id: 0, label: 0 },
  { id: 'back', label: <img src={iconDelete} alt="" /> },
];
const ModalNumpad: React.FC<any> = ({ modalOpen, onCancel, onOk, loading, otp, setOtp }) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const { loadingPin, statusPin } = useAppSelector((state) => state.createBooking);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    dispatch(checkBookingPinCode({ pin_code: `${otp}` }));
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
  };

  const handleButtonClick = (itemId: any) => {
    if (itemId !== 'blank') {
      if (itemId === 'back') {
        setOtp(otp.slice(0, -1));
      } else {
        setOtp(otp + itemId);
      }
    }
  };
  useEffect(() => {
    if (statusPin && otp) {
      dispatch(setCheckPinCode(false));
      dispatch(setPinCode(otp));
    }
  }, [statusPin, dispatch, otp]);

  return (
    <Modal
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      width={420}
      centered
      footer={[]}
      className="modal-numpad"
    >
      <Spin spinning={loadingPin}>
        <div className=" mb-3">
          <h3 className="fs-18 fw-600">Nhập mã PIN cấp quyền sửa giá vé</h3>
          <div className="header-border-bottom"></div>
          <div className="otp-block mt-4">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              shouldAutoFocus={true}
              renderInput={(props: any) => <input {...props} type="number" />}
            />
          </div>
        </div>
        <div className="block-button-num mt-4">
          {itemNumpad?.map((item: any, index: number) => (
            <>
              <button
                className={`button-num ${item?.id === 'blank' ? 'bg-blank' : ''}`}
                key={item?.key}
                onClick={() => handleButtonClick(item?.id)}
                disabled={otp.length >= 4 && item.id !== 'back'}
              >
                {item?.label}
              </button>
            </>
          ))}
        </div>
        <Row className="d-flex justify-between mt-4 w-100" style={{ columnGap: '10px' }}>
          <>
            <Button
              key="cancel"
              className=" w-49 h-btn-48 btn-back fs-16 fw-600 t-b"
              loading={loading}
              onClick={() => modalHandleCancel()}
            >
              Đóng
            </Button>
            <Button
              key="ok"
              className=" w-49  h-btn-48 btn-success text-white fs-16 fw-600 t-b mr-0"
              loading={loading}
              disabled={otp.length < 4}
              onClick={() => modalHandleOk()}
            >
              Xác nhận
            </Button>
          </>
        </Row>
      </Spin>
    </Modal>
  );
};

export default ModalNumpad;
