import { Button, Col, Flex, Form, Image, Modal, Row, Spin, Tabs, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import icLock2 from '../../assets/images/ic-lock-2.svg';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import { formatNumber } from '../../components/PriceInput2';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant, Toast, TYPE_HOUR, TYPE_MINUTE, TYPE_PRICE_UP, YearFormat } from '../../config/constant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetDataSearchBooking } from '../../redux/slice/bookings/listBooking';
import { setRoute } from '../../redux/slice/routeSlice';
import {
  createSchedule,
  createScheduleSelector,
  resetRegisterCreateSchedule,
} from '../../redux/slice/schdules/createSchedule';
import { fetchListSchedule } from '../../redux/slice/schdules/scheduleSlice';
import {
  resetUpdateSchedule,
  updateSchedule,
  updateScheduleSelector,
  updateStatusSchedule,
} from '../../redux/slice/schdules/updateSchedule';
import { setToast } from '../../redux/slice/toastNotifySlice';
import { handleGetUrlGuide } from '../../utils/helpers';
import ModalTab1 from './ModalTab1';
import ModalTab2, { ScheduleType } from './ModalTab2';
import './Schedules.scss';

function ScheduleModal({
  open,
  setOpen,
  isDetail,
  detailSchedule,
  setIsDetail,
  statusTab,
  provinceId,
  searchBusType,
  limit,
  page,
}: {
  open: boolean;
  setOpen: Function;
  setIsDetail: Function;
  onCancelSuccess?: Function;
  isDetail: boolean;
  detailSchedule: any;
  statusTab: boolean | null;
  provinceId: number | undefined;
  searchBusType: number | undefined;
  limit?: number;
  page?: number;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    errorDetail: errorCreate,
    status: statusCreate,
    loading: loadingCreate,
  } = useAppSelector(createScheduleSelector);

  const {
    errorDetail: errorUpdate,
    status: statusUpdate,
    loading: loadingUpdate,
  } = useAppSelector(updateScheduleSelector);

  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);

  const dispatch = useAppDispatch();
  const [tabActive, setTabActive] = useState<any>('1');
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState<boolean>(false);
  const isDiagram = Form.useWatch('is_diagram', form);
  const formRef = useRef<any>();

  const handleCancelModalConfirm = () => {
    setIsModalConfirmVisible(false);
  };

  const handleModalCancel = () => {
    setTabActive('1');
    setOpen(false);
    setIsDetail(false);
    form.resetFields();
    form.setFieldValue('open_before', 0);
    dispatch(setRoute(undefined));
    form.setFieldValue('is_diagram', true);
  };
  const onFieldDetail = (values: any) => {
    form.setFieldsValue({
      travel_time: values?.travel_time,
      arrive_time: dayjs(values?.arrive_time, 'HH:mm').format('HH:mm'),
      depart_time: dayjs(values?.depart_time, 'HH:mm'),
      open_before: values.open_before,
      open_before_time: values.open_before,
      close_before_type: values.close_before ? TYPE_MINUTE : TYPE_HOUR,
      close_before_time: (values.close_before ? values.close_before : values.close_before_hour) || 0,
      reservation_type: values.reservation_minute ? TYPE_MINUTE : TYPE_HOUR,
      reservation_time: (values.reservation_minute ? values.reservation_minute : values.reservation_hour) || 0,
      is_arrive_transship: values.is_arrive_transship,
      is_depart_transship: values.is_depart_transship,
      frequency_type: values.frequency_type,
      auto_operated: values.auto_operated,
      is_online: values?.is_online,
      extra_driver_id: values?.extra_drivers[0]?.id,
      bus_id: values?.bus?.id,
      pay_later: values.pay_later,
      plate_number: values?.bus?.plate_number,
      driver_id: values?.drivers[0]?.id,
      price_common: values?.price_json[0]?.seat_price,
      is_contract: values?.is_contract,
      contract_value: values?.contract_value,
    });
    if (values.frequency_type === ScheduleType.specific_date) {
      const day = values.frequency_value.split('/').reverse().join('/');
      form.setFieldValue('frequency_value', dayjs(day));
    } else {
      form.setFieldValue('frequency_value', values.frequency_value);
    }
    const currentValuesArrive = form.getFieldValue('transship_arrive');
    const currentValuesDepart = form.getFieldValue('transship_depart');
    values?.transship_arrive?.length > 0 &&
      values?.transship_arrive.map((item: any, index: number) => {
        return (currentValuesArrive[index] = {
          id: item.id,
          extra_price_value: formatNumber(item.extra_price_value),
          is_extra_price: item.is_extra_price,
          pickup_time: item.pickup_time.slice(0, 5),
          pickup_location_name: item.pickup_location_name,
          diff_day: item?.diff_day,
        });
      });

    values?.transship_depart?.length > 0 &&
      values?.transship_depart.map((item: any, index: number) => {
        return (currentValuesDepart[index] = {
          id: item.id,
          extra_price_value: formatNumber(item.extra_price_value),
          is_extra_price: item.is_extra_price,
          pickup_time: item.pickup_time.slice(0, 5),
          pickup_location_name: item.pickup_location_name,
          diff_day: item?.diff_day,
        });
      });

    form.setFieldsValue({
      transship_arrive: currentValuesArrive?.map((item: any) => ({
        pickup_time: dayjs(item?.pickup_time, 'HH:mm'),
        pickup_location_name: item?.pickup_location_name,
        is_extra_price: item?.is_extra_price,
        id: item?.id,
        extra_price_value: item?.extra_price_value,
        diff_day: item?.diff_day,
      })),
    });
    form.setFieldsValue({
      transship_depart: currentValuesDepart?.map((item: any) => ({
        pickup_time: dayjs(item?.pickup_time, 'HH:mm'),
        pickup_location_name: item?.pickup_location_name,
        is_extra_price: item?.is_extra_price,
        id: item?.id,
        extra_price_value: item?.extra_price_value,
        diff_day: item?.diff_day,
      })),
    });
  };

  useEffect(() => {
    if (isDetail) onFieldDetail(detailSchedule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetail]);

  const onFinish = (value: any) => {
    if (value.transship_arrive?.length === 0 || value.transship_depart?.length === 0) {
      dispatch(
        setToast({
          message: 'Vui lòng nhập điểm trung chuyển',
          status: true,
          type: Toast.TYPE_ERROR,
        }),
      );
    } else {
      value.is_contract = value?.is_contract ? true : false;
      value.contract_value = Number(`${value.contract_value}`.replace(/\$\s?|(,*)/g, ''));
      value.depart_time = dayjs(form.getFieldValue('depart_time')).format('HH:mm');
      value.travel_time = form.getFieldValue('travel_time');
      value.arrive_time = form.getFieldValue('arrive_time');
      value.close_before_type = form.getFieldValue('close_before_type');
      if (form.getFieldValue('close_before_type') === TYPE_MINUTE) {
        value.close_before = form.getFieldValue('close_before_time') || 0;
      } else {
        value.close_before_hour = form.getFieldValue('close_before_time') || 0;
      }
      value.reservation_type = form.getFieldValue('reservation_type');
      if (form.getFieldValue('reservation_type') === TYPE_MINUTE) {
        value.reservation_minute = form.getFieldValue('reservation_time') || 0;
      } else {
        value.reservation_hour = form.getFieldValue('reservation_time') || 0;
      }
      if (form.getFieldValue('frequency_type') === ScheduleType.specific_date) {
        value.frequency_value = dayjs(value?.frequency_value).format(YearFormat);
      }
      value.open_before = form.getFieldValue('open_before');
      value.frequency_type = form.getFieldValue('frequency_type');
      value.is_arrive_transship = form.getFieldValue('is_arrive_transship');
      value.is_depart_transship = form.getFieldValue('is_depart_transship');
      value.plate_number = undefined;
      value.price_common = undefined;
      const priceJsonCustom = form.getFieldValue('price_json');
      const updatedPriceJsonCustom =
        priceJsonCustom &&
        priceJsonCustom?.map((item: any) => ({
          ...item,
          seat_price: `${form.getFieldValue('price_common')}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        }));
      value.price_json = isDiagram ? value.price_json : updatedPriceJsonCustom;
      if (!value.agent_seats) {
        value.agent_seats = [];
      }
      if (isDetail) {
        if (!value?.frequency_value) {
          value.frequency_value = form.getFieldValue('frequency_value');
        }
        value.id = detailSchedule.id;
        return dispatch(updateSchedule(value));
      }
      // eslint-disable-next-line
      dispatch(createSchedule(value));
    }
  };

  const handleSubmit = async () => {
    try {
      if (isDetail) {
        form.submit();
        dispatch(resetDataSearchBooking());
      } else {
        if (tabActive === '1') {
          const priceJsonsFields = form.getFieldValue('is_diagram')
            ? form.getFieldValue('price_json').map((_: any, index: any) => ['price_json', index, 'seat_price'])
            : ['price_common'];
          const contractValueField = form.getFieldValue('is_contract') ? 'contract_value' : '';
          const agentSeatField = form.getFieldValue('is_diagram') ? 'agent_seats' : '';
          const autoOperatedField = form.getFieldValue('auto_operated') ? ['plate_number', 'driver_id'] : [''];
          const result = await formRef.current.validateFields([
            'route_id',
            'bus_type_id',
            ...priceJsonsFields,
            agentSeatField,
            ...autoOperatedField,
            contractValueField,
          ]);
          if (result) {
            setTabActive('2');
          }
        } else {
          form.submit();
        }
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [firstError, ...rest] = error?.errorFields;
      if (firstError?.name?.length === 1) {
        form.scrollToField(firstError?.name[0]);
      } else {
        form.scrollToField(firstError?.name);
      }
      error?.errorFields?.forEach((item: any) => {
        toast.error(item?.errors?.[0]);
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    errorInfo.errorFields.forEach((item: any) => {
      toast.error(item?.errors[0]);
    });

    const [firstErrorField] = errorInfo?.errorFields;
    if (firstErrorField) {
      form.scrollToField(firstErrorField.name);
    }
  };

  useEffect(() => {
    if (errorCreate) {
      form.setFields(
        Object.keys(errorCreate).map((fieldName) => ({
          name: fieldName,
          errors: [errorCreate[fieldName]],
        })),
      );
      const error = Object.keys(errorCreate).map((fieldName) => ({
        errors: [errorCreate[fieldName]],
      }));
      error.map((item) => toast.error(item?.errors[0][0]));
    }
  }, [errorCreate, form]);

  useEffect(() => {
    if (errorUpdate) {
      form.setFields(
        Object.keys(errorUpdate).map((fieldName) => ({
          name: fieldName,
          errors: [errorUpdate[fieldName]],
        })),
      );
      const error = Object.keys(errorUpdate).map((fieldName) => ({
        errors: [errorUpdate[fieldName]],
      }));
      error.map((item) => toast.error(item?.errors[0][0]));
    }
  }, [errorUpdate, form]);

  useEffect(() => {
    if (statusCreate === Constant.DEFAULT_STATUS) {
      handleModalCancel();
      dispatch(resetRegisterCreateSchedule());
      dispatch(
        fetchListSchedule({
          status: statusTab,
          route_id: provinceId,
          bus_type_id: searchBusType,
          limit,
          page,
        }),
      );
    }
  }, [statusCreate, statusTab, dispatch, provinceId, searchBusType, limit, page]);

  useEffect(() => {
    if (statusUpdate === Constant.DEFAULT_STATUS) {
      handleModalCancel();
      handleCancelModalConfirm();
      dispatch(
        fetchListSchedule({
          status: statusTab,
          route_id: provinceId,
          bus_type_id: searchBusType,
          limit,
          page,
        }),
      );
      dispatch(resetUpdateSchedule());
    }
  }, [statusUpdate, statusTab, dispatch, provinceId, searchBusType, page, limit]);

  const handleChangeTab = async (value: string) => {
    try {
      if (value === '1') {
        setTabActive(value);
      } else {
        const priceJsonsFields = form.getFieldValue('is_diagram')
          ? form.getFieldValue('price_json').map((_: any, index: any) => ['price_json', index, 'seat_price'])
          : ['price_common'];
        const contractValueField = form.getFieldValue('is_contract') ? 'contract_value' : '';
        const agentSeatField = form.getFieldValue('is_diagram') ? 'agent_seats' : '';
        const autoOperatedField = form.getFieldValue('auto_operated') ? ['plate_number', 'driver_id'] : [''];
        const result = await formRef.current.validateFields([
          'route_id',
          'bus_type_id',
          ...priceJsonsFields,
          agentSeatField,
          ...autoOperatedField,
          contractValueField,
        ]);
        if (result) {
          setTabActive(value);
        }
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [firstError, ...rest] = error?.errorFields;
      if (firstError?.name?.length === 1) {
        form.scrollToField(firstError?.name[0]);
      } else {
        form.scrollToField(firstError?.name);
      }
      error?.errorFields?.forEach((item: any) => {
        toast.error(item?.errors?.[0]);
      });
    }
  };
  return (
    <>
      <Modal
        className="schedule-modal"
        title={
          <Flex gap={'small'} align="center" justify="space-between" className="mr-24">
            <Flex align="center">
              <Typography.Title className="schedule-modal__heading fs-18 ">
                Chi tiết thông tin lịch chạy
              </Typography.Title>
            </Flex>
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 6)} />
          </Flex>
        }
        centered
        forceRender
        open={open}
        onCancel={handleModalCancel}
        maskClosable={false}
        width={'65%'}
        footer={[
          <Row className="d-flex w-100 justify-content-between p-0 m-0 mt-3">
            <Col span={6} className="d-flex justify-content-start">
              {isDetail ? (
                <Button
                  type="primary"
                  onClick={() => setIsModalConfirmVisible(true)}
                  size="large"
                  className={detailSchedule.status ? 'height-btn-40 btn-block-red' : 'height-btn-40 btn-active-green'}
                >
                  <Image src={icLock2} preview={false} className="mb-1 pe-2" />
                  {detailSchedule.status ? t('Khóa lịch chạy') : t('Mở khóa lịch chạy')}
                </Button>
              ) : null}
            </Col>
            <Col span={12} className={'d-flex w-100 justify-content-end'} style={{ columnGap: '15px' }}>
              <Button
                key="back"
                loading={loadingCreate}
                onClick={handleModalCancel}
                size="large"
                className="btn-cancel-modal w-200 height-btn-40"
              >
                {t('Đóng')}
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={handleSubmit}
                size="large"
                className="btn-save-modal w-200 height-btn-40"
                htmlType="submit"
              >
                {isDetail ? t('Lưu') : !isDetail && tabActive === '2' ? t('Lưu') : t('Tiếp tục')}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          name="extra-driver-form"
          layout="vertical"
          form={form}
          ref={formRef}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          className="mt-0"
          labelCol={{ span: 12 }}
          colon={false}
          labelAlign={'left'}
          scrollToFirstError={true}
          initialValues={{
            price_json: [],
            transship_depart: [],
            transship_arrive: [],
            reservation_type: TYPE_MINUTE,
            reservation_minute: 0,
            is_diagram: true,
            is_online: true,
            auto_operated: false,
            open_before: 0,
            travel_time: '00:00',
            close_before: 0,
            depart_time: dayjs().startOf('day').hour(0).minute(0),
            arrive_time: '00:00',
            close_before_type: TYPE_MINUTE,
            is_extra_price: TYPE_PRICE_UP,
            is_depart_transship: false,
            is_arrive_transship: false,
            pay_later: true,
          }}
          labelWrap
          onFinishFailed={onFinishFailed}
        >
          <Spin spinning={loadingCreate || loadingUpdate}>
            {isDetail && (
              <Typography.Title className="schedule-modal__heading schedule-modal__heading--warning">
                (Mọi thao tác thay đổi chỉ áp dụng cho chuyến tiếp theo)
              </Typography.Title>
            )}
            <Tabs
              activeKey={tabActive}
              type="card"
              onTabClick={(value) => handleChangeTab(value)}
              items={[
                {
                  label: 'Thiết lập tuyến, xe, giá vé',
                  key: '1',
                  children: <ModalTab1 form={form} detailSchedule={detailSchedule} isDetail={isDetail} />,
                },
                {
                  label: 'Thiết lập thời gian, điểm đón trả',
                  key: '2',
                  children: <ModalTab2 form={form} tabActive={tabActive} isDetail={isDetail} />,
                },
              ]}
            />
          </Spin>
          <Row className="comp-modal-footer" key={1}></Row>
        </Form>
      </Modal>

      <ModalConfirm
        modalOpen={isModalConfirmVisible}
        okText={detailSchedule && detailSchedule?.status ? t('Khóa ngay') : t('Mở ngay')}
        cancelText={t('Đóng')}
        modalTitle={detailSchedule && detailSchedule?.status ? t('Khoá lịch chạy') : t('Mở lịch chạy')}
        modalContent={
          detailSchedule && detailSchedule?.status
            ? t('Bạn đang thao tác lịch chạy này, bạn có chắc chắn muốn khóa?')
            : t('Bạn đang thao tác mở lịch chạy này, bạn có chắc chắn muốn mở?')
        }
        onCancel={handleCancelModalConfirm}
        loading={loadingUpdate}
        onOk={() =>
          dispatch(
            updateStatusSchedule({
              id: detailSchedule?.id,
              status: detailSchedule?.status,
            }),
          )
        }
      />
    </>
  );
}

export default ScheduleModal;
