import { Button, Col, Flex, Form, Image, Input, Modal, Row, Select, TimePicker, Tooltip } from 'antd';
import { Rule } from 'antd/es/form';
import { SelectProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconDelete from '../../../assets/images/schedules/icon-delete-segment.svg';
import iconPlus from '../../../assets/images/schedules/icon-plus.svg';
import { calculateTimeSum } from '../../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchListDistrict, fetchListWard } from '../../../redux/slice/provinceSlice';
import '../../schedules/Schedules.scss';
import { setListSegment } from '../../../redux/slice/schdules/scheduleSlice';

const SegmentSettingModal: React.FC<any> = ({
  okText,
  cancelText,
  modalOpen,
  onCancel,
  onOk,
  loading,
  routeActive,
  isActive,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const [formSegments] = Form.useForm();
  const { listProvince } = useAppSelector((state) => state.listSchedule);
  const { district, ward } = useAppSelector((state) => state?.province);
  const travelPattern = /^\d{1,2}:(?:[0-5][0-9]|59)$/;
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    formSegments.resetFields();
    onCancel();
  };
  const filterOption = (
    input: string,
    option?: {
      label?: any;
      value?: any;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const provinceRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng chọn tỉnh / thành phố'),
    },
  ];
  const handleChangeWard = (value: any, option: any) => {
    dispatch(fetchListWard({ district_id: value }));
    formSegments.setFieldValue('ward_id', undefined);
  };
  const handleChangeProvince = (value: any, option: any) => {
    dispatch(fetchListDistrict({ province_id: value }));
    formSegments.setFieldValue('district_id', undefined);
    formSegments.setFieldValue('ward_id', undefined);
  };
  const provincesOption: SelectProps['options'] = listProvince?.length
    ? listProvince?.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    : [];
  const districtOption: SelectProps['options'] = district?.length
    ? district?.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    : [];
  const updateArriveTime = (values: any) => {
    const timePattern = /^([0-9]{1,}|[01]?[0-9]|2[0-4]):([0-5][0-9])$/;
    const travelTime = formSegments.getFieldValue('travel_time');
    const departTimeValue = dayjs(formSegments.getFieldValue('depart_time')).format('HH:mm');
    const departTimeNumber = departTimeValue?.replace(/:/g, '');
    if (Number(departTimeNumber) <= 2359) {
      if (timePattern.test(departTimeValue) && travelPattern.test(travelTime)) {
        const formSegmentsattedTravelTime = travelTime;
        if (formSegmentsattedTravelTime !== '00:00') {
          return formSegments.setFieldsValue({
            arrive_time: calculateTimeSum(formSegmentsattedTravelTime, departTimeValue),
          });
        }
      }
    }
  };
  const onFinish = (values: any) => {
    dispatch(setListSegment(values));
    onCancel();
  };
  useEffect(() => {
    if (isActive) {
      formSegments.setFieldsValue({
        arrive_point: routeActive?.arrive_point,
        arrive_id: routeActive?.arrive_province?.name,
        depart_point: routeActive?.depart_point,
        depart_id: routeActive?.depart_province?.name,
      });
    }
  }, [isActive, routeActive]);
  // useEffect(() => {
  //   if (provinceId) {
  //     dispatch(fetchListDistrict({ province_id: provinceId }));
  //   }
  // }, [provinceId, dispatch]);

  // useEffect(() => {
  //   if (districtId) {
  //     dispatch(fetchListWard({ district_id: districtId }));
  //   }
  // }, [districtId, dispatch]);
  return (
    <Modal
      open={isModalOpen}
      onOk={modalHandleOk}
      onCancel={modalHandleCancel}
      cancelText={cancelText}
      okText={okText}
      centered
      width={1300}
      footer={
        <>
          <Row className="d-flex justify-content-end mt-5">
            <Button
              loading={loading}
              key="cancel"
              className="  w-160 h-btn-48 me-3"
              onClick={() => modalHandleCancel()}
            >
              Đóng
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              className="w-160 h-btn-48 btn-success mr-0"
              htmlType="submit"
              onClick={formSegments.submit}
            >
              Đồng ý
            </Button>
          </Row>
        </>
      }
      className="modal-segment-block"
      styles={{ body: { maxHeight: '650px', overflowY: 'auto', overflowX: 'hidden', paddingBottom: '30px' } }}
    >
      <Flex className="segment-header">
        <h5 className="fw-600 fs-18 title">Thiết lập chặng</h5>
      </Flex>
      <Form
        name="segment-setting-form"
        layout="vertical"
        form={formSegments}
        onFinish={onFinish}
        colon={false}
        validateTrigger="onSubmit"
        initialValues={{
          segment: [{}],
          depart_time: dayjs().startOf('day').hour(0).minute(0),
          arrive_time: '00:00',
          travel_time: '00:00',
        }}
      >
        <div className="border-b">
          <Row gutter={8} className="mt-4 block-segment-item ">
            <Col span={4}>
              <Form.Item name="depart_point" className="form-input">
                <Input disabled placeholder="Điểm đón" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="depart_id">
                <Select
                  className="input-h36"
                  showSearch
                  // filterOption={filterOption}
                  placeholder="Chọn tỉnh / thành phố"
                  size="large"
                  style={{ width: '100%' }}
                  disabled
                  options={provincesOption}
                  // onChange={(value: any, option: any) => handleChangeProvince(value, option)}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="travel_time"
                className="form-item-name required time-sum"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if ((value && value === '00:00') || !value) {
                        return Promise.reject(new Error('Vui lòng nhập tổng thời gian'));
                      }
                      if (!travelPattern.test(value)) {
                        return Promise.reject(new Error('Vui lòng nhập đúng định dạng 00:00'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input placeholder="00:00" onChange={updateArriveTime} prefix={'Tổng thời gian'} />
              </Form.Item>
            </Col>
            <Col span={4} className="time-schedule-block">
              <Form.Item
                name={'depart_time'}
                className="form-item-name form-item__pickup_time--depart required pickup-time"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập giờ đi',
                  },
                ]}
              >
                <TimePicker
                  suffixIcon={null}
                  showNow={false}
                  needConfirm={false}
                  format={'HH:mm'}
                  placeholder="00:00"
                  onChange={updateArriveTime}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="mt-3 border-b">
          <Form.List name={`segment`}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Row gutter={8} className="mt-3 block-segment-item">
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          className="input-seat form-input"
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập điểm dừng',
                            },
                          ]}
                        >
                          <Input placeholder="Nhập điểm dừng" />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '15%' }}>
                        <Form.Item {...restField} name={[name, 'country_id']} className="form-item-name">
                          <Select
                            className="input-h36"
                            showSearch
                            filterOption={filterOption}
                            placeholder="Chọn quốc gia"
                            size="large"
                            style={{ width: '100%' }}
                            defaultValue={1}
                            options={[{ value: 1, label: 'Việt Nam' }]}
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '18%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'province_id']}
                          className="form-item-name required"
                          validateTrigger={['onChange', 'onBlur']}
                          rules={provinceRules}
                        >
                          <Select
                            className="input-h36"
                            showSearch
                            filterOption={filterOption}
                            placeholder="Chọn tỉnh / thành phố"
                            size="large"
                            style={{ width: '100%' }}
                            options={provincesOption}
                            onChange={(value: any, option: any) => handleChangeProvince(value, option)}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'district_id']}
                          className="form-item-name required"
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <Select
                            className="input-h36"
                            showSearch
                            filterOption={filterOption}
                            placeholder="Chọn quận / huyện"
                            size="large"
                            style={{ width: '100%' }}
                            options={districtOption}
                            onChange={(value: any, option: any) => handleChangeWard(value, option)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4} className="time-schedule-block">
                        <Form.Item
                          {...restField}
                          name={[name, 'arrive_time']}
                          className="form-item-name form-item__pickup_time--arrive required pickup-time"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập giờ đến',
                            },
                          ]}
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <TimePicker
                            suffixIcon={null}
                            showNow={false}
                            needConfirm={false}
                            format={'HH:mm'}
                            placeholder="00:00"
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '140px' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'diff_day']}
                          className="form-item-name form-item__diff_day required diff-day"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 0, label: 'Trong ngày' },
                              { value: 1, label: '1 ngày sau' },
                              { value: 2, label: '2 ngày sau' },
                            ]}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col style={{ height: '40px' }} span={1}>
                        <Tooltip title="Xóa">
                          <Button type="text" onClick={() => remove(name)} className="p-0 btn-delete">
                            <Image width={16} height={16} src={iconDelete} preview={false} />
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Button
                  htmlType="button"
                  onClick={() => add()}
                  className="border-none text-success gap-x-8 d-flex items-center mt-2"
                  style={{ paddingLeft: 0 }}
                >
                  Thêm điểm dừng
                  <img src={iconPlus} alt="" />
                </Button>
              </>
            )}
          </Form.List>
        </div>
        <Row gutter={8} className="mt-4 block-segment-item ">
          <Col span={4}>
            <Form.Item name="arrive_point" className="form-input">
              <Input disabled placeholder="Điểm trả" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="arrive_id">
              <Select
                className="input-h36"
                showSearch
                placeholder="Chọn tỉnh / thành phố"
                size="large"
                style={{ width: '100%' }}
                disabled
                options={provincesOption}
              />
            </Form.Item>
          </Col>
          <Col span={4} className="time-schedule-block">
            <Form.Item name={'arrive_time'} className="form-item-name arrive arrive-time time-sum disabled">
              <Input disabled prefix={'Giờ đến'} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SegmentSettingModal;
