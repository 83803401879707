import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Constant } from '../../../config/constant';
import { OrderState, ParamsGetListOrder } from '../../../types/order';
import { getAmountOrderAPI, getListOrdersAPI } from '../../../pages/orders/api';
import { isArray } from 'lodash';

const limit_pagination: any = window.localStorage.getItem('limit_pagination');
const limitPagination = isNaN(Number(limit_pagination)) ? Constant.PAGE_SIZE : Number(limit_pagination);

const initialState = {
  listOrder: [],
  loading: false,
  filter: {
    keyword: '',
    date_filter: 'depart_date',
    from: '',
    to: '',
  },
  amount: 0,
  pagination: {
    total: 0,
    per_page: limitPagination,
    current_page: Constant.DEFAULT_PAGE,
    last_page: Constant.DEFAULT_PAGE,
  },
} as OrderState;

export const ordersPendingSlide = createSlice({
  name: 'ordersPending',
  initialState,
  reducers: {
    setPerPage(state, action: PayloadAction<number>) {
      state.pagination.per_page = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.pagination.current_page = action.payload;
    },
    setKeyword(state, action: PayloadAction<string>) {
      state.filter.keyword = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setAmount(state, action: PayloadAction<number>) {
      state.amount = action.payload;
    },
    setLoadingOrderPending(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListOrdersPending.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListOrdersPending.fulfilled, (state, action) => {
        state.listOrder = action.payload?.data;
        state.pagination = action.payload?.pagination;
        state.loading = false;
      })
      .addCase(getListOrdersPending.rejected, (state) => {})
      .addCase(getAmountOrder.fulfilled, (state, action) => {
        state.amount = action.payload;
        state.loading = false;
      });
  },
});

export const getListOrdersPending = createAsyncThunk(
  'OrdersPending/getList',
  async (params: ParamsGetListOrder, { dispatch, rejectWithValue }) => {
    try {
      const res = await getListOrdersAPI(params);
      if (res?.status === 200) {
        if (isArray(res?.data?.data)) {
          return res?.data;
        } else {
          return res?.data?.data;
        }
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getAmountOrder = createAsyncThunk('OrdersPending/getAmount', async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await getAmountOrderAPI();
    if (res?.status === 200) {
      return res?.data?.data;
    }
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const { setPerPage, setCurrentPage, setKeyword, setFilter, setAmount, setLoadingOrderPending } =
  ordersPendingSlide.actions;
