import { getListReportDetailMainAgentAPI, getListReportDetailMainAgentExcelAPI } from "../../../api/report";
import { ParamsReport, ReportSourceAPIState, ValueOptions } from "../../../types/report";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Constant } from "../../../config/constant";
import { toast } from "react-toastify";

const limit_pagination: any = window.localStorage.getItem("limit_pagination");
const limitPagination = isNaN(Number(limit_pagination))
    ? Constant.PAGE_SIZE
    : Number(limit_pagination);

const initialState = {
    listReport: [],
    loading: false,
    pagination: {
        total: 0,
        per_page: limitPagination,
        current_page: Constant.DEFAULT_PAGE,
        last_page: Constant.DEFAULT_PAGE,
    },
    listRoute: [],
    listTimeRoute: [{ value: '', label: 'Tất cả' }],
    total_price: 0,
    total_insurance: 0,
    total_commission_carlink: 0,
    total_commission_main_agent: 0,
    total_price_carlink: 0,
    total_real_revenue: 0,
    total_actually_paid: 0,
    filter: {
        booking_date: 'false',
        from: '',
        to: '',
        route_id: '',
        depart_time: '',
        payment_method: '',
        company_id: undefined,
        main_agent_id: undefined,
    }
} as ReportSourceAPIState;

export const reportSourceAPISlice = createSlice({
    name: 'reportSourceAPI',
    initialState,
    reducers: {
        setListAllRoutes(state, action) {
            state.listRoute = action.payload;
        },
        setFilterBookingDate(state, action: PayloadAction<string>) {
            state.filter.booking_date = action.payload;
        },
        setFilterRangeDate(state, action: PayloadAction<any>) {
            state.filter.from = action.payload?.[0];
            state.filter.to = action.payload?.[1];
        },
        setFilterRoute(state, action: PayloadAction<number | string>) {
            state.filter.route_id = action.payload;
        },
        setFilterDepartTime(state, action: PayloadAction<number | string>) {
            state.filter.depart_time = action.payload;
        },
        setFilterPaymentMethod(state, action: PayloadAction<string>) {
            state.filter.payment_method = action.payload;
        },
        setFilterCompany(state, action: PayloadAction<number | undefined>) {
            state.filter.company_id = action.payload;
        },
        setFilterOptions(state, action: PayloadAction<string>) {
            state.filter.options = action.payload;
        },
        setListTimeRoute(state, action: PayloadAction<ValueOptions[]>) {
            state.listTimeRoute = action.payload;
        },
        setPerPage(state, action: PayloadAction<number>) {
            state.pagination.per_page = action.payload;
        },
        setFilterMainAgentId(state, action: PayloadAction<number | undefined>) {
            state.filter.main_agent_id = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListReportSourceAPI.pending, (state) => {
                state.loading = true;
            }).addCase(getListReportSourceAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.listReport = action?.payload?.data;
                state.pagination.total = action.payload?.pagination?.total;
                state.pagination.per_page = action.payload?.pagination?.per_page;
                state.pagination.last_page = action.payload?.pagination?.last_page;
                state.pagination.current_page = action.payload?.pagination?.current_page;
                state.total_price = action.payload?.total_price;
                state.total_insurance = action.payload?.total_insurance;
                state.total_commission_carlink = action.payload?.total_commission_carlink;
                state.total_actually_paid = action.payload?.total_actually_paid;
            }).addCase(getListReportSourceAPI.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
                state.loading = false;
            }).addCase(getListReportSourceAPIExcel.pending, (state) => {
            }).addCase(getListReportSourceAPIExcel.fulfilled, (state, action) => {
            }).addCase(getListReportSourceAPIExcel.rejected, (state, action: any) => {
                const listErros = action?.payload?.response?.data?.errors;
                if (listErros) {
                    Object.keys(listErros)?.forEach((fieldName) => {
                        toast.error(`${[listErros[fieldName]]}`);
                    });
                }
            })
    }
})

export const getListReportSourceAPI = createAsyncThunk('ReportSourceAPI/getAll', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportDetailMainAgentAPI(params);
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const getListReportSourceAPIExcel = createAsyncThunk('ReportSourceAPI/getAllExcel', async (params: ParamsReport, {
    dispatch, rejectWithValue
}) => {
    try {
        const response = await getListReportDetailMainAgentExcelAPI(params)
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
})

export const { setPerPage, setListAllRoutes, setFilterBookingDate, setFilterRangeDate, setFilterRoute, setFilterDepartTime, setFilterPaymentMethod, setFilterCompany, setFilterOptions, setListTimeRoute, setFilterMainAgentId } = reportSourceAPISlice.actions;