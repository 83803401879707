import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../../api';
import { getContractByIdApi, getListContractApi } from '../../../api/contract';
import { Constant } from '../../../config/constant';
import { ListContractRequest, ListContractState } from '../../../types/contract';
import { RootState } from '../../store';

// @ts-ignore
export const getListContract = createAsyncThunk(
  'admin/contract',
  async (params: ListContractRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListContractApi(params);
      const { data, status } = response;
      if (status === Constant.DEFAULT_STATUS) {
        dispatch(setListContract(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setListContract(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getContractById = createAsyncThunk('admin/contract/id', async (id: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await getContractByIdApi(id);
    const { data, status } = response;
    if (status === Constant.DEFAULT_STATUS) {
      dispatch(setListContractDetail(data));
      return true;
    }
  } catch (error: any) {
    dispatch(setListContractDetail(error));
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});

export const listContractSlice = createSlice({
  name: 'listBusType',
  initialState: {
    error: false,
    loading: false,
    loadingContractDetail:false,
    success: false,
    listContract: [],
    pagination: [],
    status: 0,
    message: '',
    listContractDetail: [],
    errorDetail: null,
  } as ListContractState,
  reducers: {
    setListContract: (state: ListContractState, { payload }) => {
      state.listContract = payload?.data;
      state.pagination = payload?.pagination;
    },
    setListContractDetail: (state: ListContractState, { payload }) => {
      state.listContractDetail = payload?.data;
    },
    setListContractLoad: (state: ListContractState) => {
      state.listContract = [];
    },
    resetListContractDetail: (state: ListContractState) => {
      state.listContractDetail = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListContract.pending, (state: ListContractState) => {
      state.loading = true;
    });
    builder.addCase(getListContract.fulfilled, (state: ListContractState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getListContract.rejected, (state: ListContractState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getContractById.pending, (state: ListContractState) => {
      state.loadingContractDetail = true;
    });
    builder.addCase(getContractById.fulfilled, (state: ListContractState) => {
      state.loadingContractDetail = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(getContractById.rejected, (state: ListContractState) => {
      state.loadingContractDetail = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const listContractSelector = (state: RootState) => state.listContract;
export const { setListContract, setListContractDetail, setListContractLoad, resetListContractDetail } =
  listContractSlice.actions;
