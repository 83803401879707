import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../api';
import { getAllListRoutesAPI } from '../../../api/route';
import { createTicketAPI, getListTicketsAPI, updateStatusTicketAPI, updateTicketAPI } from '../../../api/tickets';
import { Constant } from '../../../config/constant';
import { PromotionState } from '../../../types';
import { ListRouteRequest } from '../../../types/route';
import {
  createPromotionAPI,
  getPromotionAPI,
  updatePromotionAPI,
  updateStatusPromotionAPI,
} from '../../../api/promotion';

const initialState = {
  listPromotion: [],
  loading: false,
  success: false,
  errorDetail: {},
  message: '',
  pagination: {
    total: 0,
    per_page: Constant.PAGE_SIZE,
    current_page: Constant.DEFAULT_PAGE,
    last_page: Constant.DEFAULT_PAGE,
  },
  listRoute: [],
} as PromotionState;

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState: initialState,
  reducers: {
    setListPromotion(state, action) {
      state.listPromotion = action.payload;
    },
    setListAllRoutes(state, action) {
      state.listRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListPromotion.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListPromotion.fulfilled, (state, action) => {
        state.loading = false;
        state.listPromotion = action.payload?.data;
        state.pagination.total = action.payload?.pagination?.total;
        state.pagination.per_page = action.payload?.pagination?.per_page;
        state.pagination.last_page = action.payload?.pagination?.last_page;
        state.pagination.current_page = action.payload?.pagination?.current_page;
      })
      .addCase(getListPromotion.rejected, (state, action: any) => {
        state.loading = false;
        const listErros = action?.payload?.response?.data?.errors;
        if (listErros) {
          Object.keys(listErros)?.forEach((fieldName) => {
            toast.error(`${[listErros[fieldName]]}`);
          });
        }
      })
      .addCase(createPromotion.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPromotion.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createPromotion.rejected, (state, action: any) => {
        const listErros = action?.payload?.response?.data?.errors;
        if (listErros) {
          Object.keys(listErros)?.forEach((fieldName) => {
            toast.error(`${[listErros[fieldName]]}`);
          });
        }
        state.loading = false;
      })
      .addCase(updatePromotion.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePromotion.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updatePromotion.rejected, (state, action: any) => {
        const listErros = action?.payload?.response?.data?.errors;
        if (listErros) {
          Object.keys(listErros)?.forEach((fieldName) => {
            toast.error(`${[listErros[fieldName]]}`);
          });
        }
        state.loading = false;
      });
  },
});

export const { setListAllRoutes } = promotionSlice.actions;

export const getListPromotion = createAsyncThunk(
  'promotion/listPromotion',
  async (params: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getPromotionAPI(params);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const createPromotion = createAsyncThunk(
  'promotion/createPromotion',
  async (params: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await createPromotionAPI(params);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updatePromotion = createAsyncThunk(
  'promotion/update',
  async (params: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await updatePromotionAPI(params);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateStatusPromotion = createAsyncThunk(
  'promotion/updateStatus',
  async (params: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateStatusPromotionAPI(params);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getAllListRoute = createAsyncThunk(
  'tickets/getAllListRoute',
  async (params: ListRouteRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getAllListRoutesAPI(params);
      const { data, success } = response?.data;
      if (success) {
        dispatch(setListAllRoutes(data));
        return true;
      }
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
