import { getAxiosClientWithToken } from '.';
import { IDepositStatus } from '../types/company';

export const callUpdateDepositCompanyApi = (params: IDepositStatus) => {
  return getAxiosClientWithToken().post(`/company/update-deposit`, params);
};

export const getCarlinkInfoTransferApi = () => {
  return getAxiosClientWithToken().get('/company/info-transfer');
};

export const getCreateCompanyDepositApi = (params: any) => {
  return getAxiosClientWithToken().post('/company/deposit', params);
};

export const getCreateCompanyDepositQRApi = (params: any) => {
  return getAxiosClientWithToken().get(`/company/get-deposit-qr?${params}`);
};

export const getListCompanyApi = () => {
  return getAxiosClientWithToken().get('/company');
};
export const getListCompanyActiveApi = (params: { limit: number}) => {
    return getAxiosClientWithToken().get('/company/active', {params});
};
