export const ListTypeCarItems = [
  {
    name: 'Xe Limousine 9 chỗ',
    seatNumber: 9,
    seatGroup: 'Ghế đầu, Ghế giữa, Ghế cuối',
    id: 1,
    status: true,
  },
  {
    name: 'Xe khách 16 chỗ',
    seatNumber: 16,
    seatGroup: 'Ghế đầu, Ghế giữa đơn, Ghế giữa kép, Ghế cuối',
    id: 2,
    status: true,
  },
  {
    name: 'Xe giường nằm cabin 25 chỗ',
    seatNumber: 25,
    seatGroup: 'Cabin thường, Cabin VIP',
    id: 3,
    status: false,
  },
  {
    name: 'Xe khách 45 chỗ',
    seatNumber: 45,
    seatGroup: 'Dãy ghế đầu, Dãy ghế giữa, Dãy ghế cuối',
    id: 4,
    status: true,
  },
  {
    name: 'Xe khách 50 chỗ',
    seatNumber: 50,
    seatGroup: 'Dãy ghế đầu, Dãy ghế giữa, Dãy ghế cuối',
    id: 5,
    status: true,
  },
];
export const TypeSeatItems = [
  {
    // id:2,
    label: 'Ghế tài xế',
    value: 1,
  },
  {
    // id:3,
    label: 'Ghế phụ xe',
    value: 2,
  },
  {
    // id:4,
    label: 'Ghế ngồi',
    value: 3,
  },
  {
    // id:5,
    label: 'Ghế giường nằm',
    value: 4,
  },
  {
    // id:6,
    label: 'Cabin đơn',
    value: 5,
  },
  {
    // id:7,
    label: 'Cabin đôi',
    value: 6,
  },
  {
    // id:7,
    label: 'Ghế massage',
    value: 7,
  },
];

export const matrixSeatData: any = [
  {
    id: 1,
    name: 'xe 6 chỗ',
    seat_map: {
      row: '3',
      column: '3',
      floor1: [
        [
          {
            id: '0-0',
            name: 'PHỤ XE',
            type: 2,
            color: null,
            deleted: false,
            selected: false,
            status: 0,
          },
          {
            id: '0-1',
            name: 'A5',
            type: 3,
            color: null,
            deleted: false,
            selected: false,
            status: 2,
            locked: true,
          },
          {
            id: '0-2',
            name: 'A6',
            type: 3,
            color: null,
            deleted: false,
            selected: false,
            status: 0,
          },
        ],
        [
          {
            id: '1-0',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
            status: 0,
          },
          {
            id: '1-1',
            name: 'A4',
            type: 3,
            color: null,
            deleted: false,
            selected: false,
          },
          {
            id: '1-2',
            name: 'A3',
            type: 3,
            color: null,
            deleted: false,
            selected: false,
            status: 1,
          },
        ],
        [
          {
            id: '2-0',
            name: 'TÀI XẾ',
            type: 1,
            color: null,
            deleted: false,
            selected: false,
            status: 0,
          },
          {
            id: '2-1',
            name: 'A1',
            type: 3,
            color: null,
            deleted: false,
            selected: false,
            status: 1,
          },
          {
            id: '2-2',
            name: 'A2',
            type: 3,
            color: null,
            deleted: false,
            selected: false,
            status: 3,
          },
        ],
      ],
      floor2: [
        [
          {
            id: '0-0',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
          {
            id: '0-1',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
          {
            id: '0-2',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
        ],
        [
          {
            id: '1-0',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
          {
            id: '1-1',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
          {
            id: '1-2',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
        ],
        [
          {
            id: '2-0',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
          {
            id: '2-1',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
          {
            id: '2-2',
            name: null,
            type: 0,
            color: null,
            deleted: false,
            selected: false,
          },
        ],
      ],
      group_seat: [
        {
          group_seat: 'Dãy ghế 1',
          group_color: 'green-seat',
          selected_seat: ['A5', 'A6', 'A4', 'A3'],
        },
        {
          group_seat: 'Dãy ghế 2',
          group_color: 'brown-seat',
          selected_seat: ['A1', 'A2'],
        },
      ],
      count_floor: 1,
      seat_number: 6,
    },
    status: true,
  },
];

export const listBooking: any = {
  schedule: {
    id: 144,
    company: {
      id: 5,
      theme_id: 5,
      name: 'Gerik Voaden',
      phone: '813-218-4243',
      address: '0 Texas Trail',
      email: 'gvoaden4@cmu.edu',
      logo: 'https://robohash.org/aspernatureavoluptas.png?size=50x50&set=set1',
      note: 'bibendum imperdiet nullam orci pede venenatis non sodales sed tincidunt eu felis fusce posuere felis sed lacus morbi sem',
      status: true,
    },
    route: {
      id: 100025,
      depart_point: 'Bến Xe Sơn La',
      depart_province: {
        id: 9,
        name: 'Tỉnh Sơn La',
      },
      arrive_point: 'Bến Xe Hà Nội',
      arrive_province: {
        id: 1,
        name: 'Thành phố Hà Nội',
      },
      status: true,
    },
    bus_type: {
      id: 40,
      name: 'Xe limousine 09 VIP',
      seat_map: {
        row: '5',
        seats: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9'],
        column: '3',
        floor1: [
          [
            {
              id: '0-0',
              name: 'TÀI XẾ',
              type: 1,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '0-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '0-2',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
          [
            {
              id: '1-0',
              name: 'A1',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '1-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '1-2',
              name: 'A2',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
          [
            {
              id: '2-0',
              name: 'A3',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '2-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '2-2',
              name: 'A4',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
          [
            {
              id: '3-0',
              name: 'A5',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '3-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '3-2',
              name: 'A6',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
          [
            {
              id: '4-0',
              name: 'A7',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '4-1',
              name: 'A8',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '4-2',
              name: 'A9',
              type: 3,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
        ],
        floor2: [
          [
            {
              id: '0-0',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '0-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '0-2',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
          [
            {
              id: '1-0',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '1-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '1-2',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
          [
            {
              id: '2-0',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '2-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '2-2',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
          [
            {
              id: '3-0',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '3-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '3-2',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
          [
            {
              id: '4-0',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '4-1',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
            {
              id: '4-2',
              name: null,
              type: 0,
              color: null,
              deleted: false,
              selected: false,
            },
          ],
        ],
        group_seat: [
          {
            name: 'Ghế đầu',
            group_color: 'orange-seat',
            selected_seat: ['A1', 'A2'],
          },
          {
            name: 'Ghế giữa',
            group_color: 'blue-seat',
            selected_seat: ['A3', 'A4', 'A5', 'A6'],
          },
          {
            name: 'Ghế cuối',
            group_color: 'green-seat',
            selected_seat: ['A7', 'A8', 'A9'],
          },
        ],
        count_floor: 1,
        seat_number: 9,
        bus_direction: 0,
      },
      status: true,
    },
    note: '333333',
    depart_time: '01:51:00',
    arrive_time: '05:55:00',
    open_before: 0,
    close_before: 0,
    close_before_hour: 0,
    reservation_hour: 0,
    reservation_minute: 0,
    frequency_type: 'daily',
    frequency_value: null,
    is_depart_transship: true,
    is_arrive_transship: true,
    price_json: [
      {
        name: 'Ghế đầu',
        seat_price: '120,000',
      },
      {
        name: 'Ghế giữa',
        seat_price: '150,000',
      },
      {
        name: 'Ghế cuối',
        seat_price: '100,000',
      },
    ],
    agent_seats: ['A3', 'A4', 'A5'],
    transship_depart: [
      {
        id: 100036,
        pickup_location_name: 'Bắc Giang',
        is_extra_price: 1,
        extra_price_value: 0,
        is_pickup_location: 1,
        pickup_time: '02:40:00',
      },
    ],
    transship_arrive: [
      {
        id: 100031,
        pickup_location_name: 'Bến Xe Mộc Châu',
        is_extra_price: 1,
        extra_price_value: 0,
        is_pickup_location: 0,
        pickup_time: '05:00:00',
      },
    ],
    next_trip_date: '01/03/2024',
    status: true,
  },
  trip: {
    id: 31,
    bus: {
      id: 497,
      name: 'heller.emie',
      plate_number: '90d3-43823',
      bus_license_deadline: '2021-06-27',
      insurance_deadline: '1979-04-30',
      bus_type: null,
      service: [],
      bus_license_img: null,
      insurance_img: null,
      bus_img: null,
      status: true,
      disable: true,
    },
    drivers: [
      {
        id: 100140,
        name: 'nguyen van a',
        phone: '0333987494',
        date_of_birth: '19-02-1998',
        license_class: 'D',
        date_expired_license: '07-03-2024',
        type: 'driver',
      },
    ],
    extra_drivers: [
      {
        id: 100141,
        name: 'nguyen van b',
        phone: '033987444',
        date_of_birth: '13-02-1998',
        license_class: null,
        date_expired_license: null,
        type: 'extra_driver',
      },
    ],
    total_amount: '545,000',
    status: 'Chưa xuất bến',
  },
  booking: {
    data: [
      {
        seat_number: 'A1',
        ticket_code: 'LSXIYO',
        is_insurance: true,
        customer: {
          name: 'Lê Zăn Thắng',
          phone: '098238444',
          email: 'zanthang@gmail.com',
        },
        pickup_location: {
          id: 100036,
          province: {
            id: 1,
            name: 'Thành phố Hà Nội',
          },
          name: 'Bắc Giang',
          phone: '11111222333',
          address: 'hà nội',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: false,
        },
        drop_off_location: {
          id: 100031,
          province: {
            id: 9,
            name: 'Tỉnh Sơn La',
          },
          name: 'Bến Xe Mộc Châu',
          phone: '0223115115',
          address: 'Mộc Châu',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: true,
        },
        pickup_extra: null,
        drop_off_extra: null,
        price: '175,000',
        payment_method: 'cash',
        note: 'noteeeeeeeeeeeeeeeeeeeee',
        orderer: 'quang nhat update',
        office: null,
        status: 'paid',
      },
      {
        seat_number: 'A2',
        ticket_code: 'QVJH01',
        is_insurance: false,
        customer: {
          name: 'Zan Thang',
          phone: '098234444',
          email: 'zanthang@gmail.com',
        },
        pickup_location: {
          id: 100036,
          province: {
            id: 1,
            name: 'Thành phố Hà Nội',
          },
          name: 'Bắc Giang',
          phone: '11111222333',
          address: 'hà nội',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: false,
        },
        drop_off_location: {
          id: 100031,
          province: {
            id: 9,
            name: 'Tỉnh Sơn La',
          },
          name: 'Bến Xe Mộc Châu',
          phone: '0223115115',
          address: 'Mộc Châu',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: true,
        },
        pickup_extra: null,
        drop_off_extra: null,
        price: '120,000',
        payment_method: 'bank_transfer',
        note: 'noteeeeeee',
        orderer: 'quang nhat update',
        office: null,
        status: 'paid',
      },
      {
        seat_number: 'A3',
        ticket_code: 'TG90R3',
        is_insurance: false,
        customer: {
          name: 'Phạm Thị Hoa',
          phone: '0936191410',
          email: 'hoaphampth183@gmail.com',
        },
        pickup_location: {
          id: 100036,
          province: {
            id: 1,
            name: 'Thành phố Hà Nội',
          },
          name: 'Bắc Giang',
          phone: '11111222333',
          address: 'hà nội',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: false,
        },
        drop_off_location: {
          id: 100031,
          province: {
            id: 9,
            name: 'Tỉnh Sơn La',
          },
          name: 'Bến Xe Mộc Châu',
          phone: '0223115115',
          address: 'Mộc Châu',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: true,
        },
        pickup_extra: null,
        drop_off_extra: null,
        price: '150,000',
        payment_method: 'bank_transfer',
        note: 'Khách đặt nhiều lần không đi',
        orderer: 'quang nhat update',
        office: null,
        status: 'cancel',
      },
      {
        seat_number: 'A9',
        ticket_code: 'M2TNNO',
        is_insurance: false,
        customer: {
          name: 'Mai Hoàng Anh',
          phone: '0936191411',
          email: 'Hoanganh01@gmail.com',
        },
        pickup_location: {
          id: 100036,
          province: {
            id: 1,
            name: 'Thành phố Hà Nội',
          },
          name: 'Bắc Giang',
          phone: '11111222333',
          address: 'hà nội',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: false,
        },
        drop_off_location: {
          id: 100031,
          province: {
            id: 9,
            name: 'Tỉnh Sơn La',
          },
          name: 'Bến Xe Mộc Châu',
          phone: '0223115115',
          address: 'Mộc Châu',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: true,
        },
        pickup_extra: null,
        drop_off_extra: null,
        price: '100,000',
        payment_method: 'cash',
        note: 'không note',
        orderer: 'quang nhat update',
        office: null,
        status: 'paid',
      },
      {
        seat_number: 'A7',
        ticket_code: 'ADNM3H',
        is_insurance: false,
        customer: {
          name: 'thang',
          phone: '099999232',
          email: 'thang@gmail.com',
        },
        pickup_location: {
          id: 100036,
          province: {
            id: 1,
            name: 'Thành phố Hà Nội',
          },
          name: 'Bắc Giang',
          phone: '11111222333',
          address: 'hà nội',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: false,
        },
        drop_off_location: {
          id: 100031,
          province: {
            id: 9,
            name: 'Tỉnh Sơn La',
          },
          name: 'Bến Xe Mộc Châu',
          phone: '0223115115',
          address: 'Mộc Châu',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: true,
        },
        pickup_extra: null,
        drop_off_extra: null,
        price: '100,000',
        payment_method: 'reserve',
        note: 'noteeeeeeee',
        orderer: 'quang nhat update',
        office: null,
        status: 'hold',
      },
      {
        seat_number: 'A5',
        ticket_code: 'AI6U4N',
        is_insurance: true,
        customer: {
          name: 'Vũ Lê Hiệp',
          phone: '0982382222',
          email: 'thangle2000@gmail.com',
        },
        pickup_location: {
          id: 100036,
          province: {
            id: 1,
            name: 'Thành phố Hà Nội',
          },
          name: 'Bắc Giang',
          phone: '11111222333',
          address: 'hà nội',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: false,
        },
        drop_off_location: {
          id: 100031,
          province: {
            id: 9,
            name: 'Tỉnh Sơn La',
          },
          name: 'Bến Xe Mộc Châu',
          phone: '0223115115',
          address: 'Mộc Châu',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: true,
        },
        pickup_extra: null,
        drop_off_extra: null,
        price: '205,000',
        payment_method: 'reserve',
        note: 'noteeeeeee',
        orderer: 'quang nhat update',
        office: null,
        status: 'cancel',
      },
      {
        seat_number: 'A4',
        ticket_code: 'QGX922',
        is_insurance: false,
        customer: {
          name: 'Thanh',
          phone: '09823222',
          email: 'thanh@gmail.com',
        },
        pickup_location: {
          id: 100036,
          province: {
            id: 1,
            name: 'Thành phố Hà Nội',
          },
          name: 'Bắc Giang',
          phone: '11111222333',
          address: 'hà nội',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: false,
        },
        drop_off_location: {
          id: 100031,
          province: {
            id: 9,
            name: 'Tỉnh Sơn La',
          },
          name: 'Bến Xe Mộc Châu',
          phone: '0223115115',
          address: 'Mộc Châu',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: true,
        },
        pickup_extra: null,
        drop_off_extra: null,
        price: '150,000',
        payment_method: 'cash',
        note: 'noteeeeeee',
        orderer: 'quang nhat update',
        office: null,
        status: 'paid',
      },
      {
        seat_number: 'A8',
        ticket_code: 'FHVCHC',
        is_insurance: false,
        customer: {
          name: 'thang',
          phone: '0982823232',
          email: 'thangg@gmail.com',
        },
        pickup_location: {
          id: 100036,
          province: {
            id: 1,
            name: 'Thành phố Hà Nội',
          },
          name: 'Bắc Giang',
          phone: '11111222333',
          address: 'hà nội',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: false,
        },
        drop_off_location: {
          id: 100031,
          province: {
            id: 9,
            name: 'Tỉnh Sơn La',
          },
          name: 'Bến Xe Mộc Châu',
          phone: '0223115115',
          address: 'Mộc Châu',
          latitude: '21.0654497',
          longitude: '105.7886822',
          status: true,
        },
        pickup_extra: null,
        drop_off_extra: null,
        price: '100,000',
        payment_method: 'reserve',
        note: 'noteeeeeeeee',
        orderer: 'quang nhat update',
        office: null,
        status: 'hold',
      },
    ],
  },
  customers: {
    data: [
      {
        ticket_code: 'LSXIYO',
        seat_number: 'A1',
        price: 175000,
        customer_name: 'Lê Zăn Thắng',
        customer_phone: '098238444',
        pickup_location: 'Bắc Giang',
        drop_off_location: 'Bến Xe Mộc Châu',
        orderer: 'quang nhat update',
      },
      {
        ticket_code: 'QVJH01',
        seat_number: 'A2',
        price: 120000,
        customer_name: 'Zan Thang',
        customer_phone: '098234444',
        pickup_location: 'Bắc Giang',
        drop_off_location: 'Bến Xe Mộc Châu',
        orderer: 'quang nhat update',
      },
      {
        ticket_code: 'TG90R3',
        seat_number: 'A3',
        price: 150000,
        customer_name: 'Phạm Thị Hoa',
        customer_phone: '0936191410',
        pickup_location: 'Bắc Giang',
        drop_off_location: 'Bến Xe Mộc Châu',
        orderer: 'quang nhat update',
      },
      {
        ticket_code: 'M2TNNO',
        seat_number: 'A9',
        price: 100000,
        customer_name: 'Mai Hoàng Anh',
        customer_phone: '0936191411',
        pickup_location: 'Bắc Giang',
        drop_off_location: 'Bến Xe Mộc Châu',
        orderer: 'quang nhat update',
      },
      {
        ticket_code: 'ADNM3H',
        seat_number: 'A7',
        price: 100000,
        customer_name: 'thang',
        customer_phone: '099999232',
        pickup_location: 'Bắc Giang',
        drop_off_location: 'Bến Xe Mộc Châu',
        orderer: 'quang nhat update',
      },
      {
        ticket_code: 'AI6U4N',
        seat_number: 'A5',
        price: 205000,
        customer_name: 'Vũ Lê Hiệp',
        customer_phone: '0982382222',
        pickup_location: 'Bắc Giang',
        drop_off_location: 'Bến Xe Mộc Châu',
        orderer: 'quang nhat update',
      },
      {
        ticket_code: 'QGX922',
        seat_number: 'A4',
        price: 150000,
        customer_name: 'Thanh',
        customer_phone: '09823222',
        pickup_location: 'Bắc Giang',
        drop_off_location: 'Bến Xe Mộc Châu',
        orderer: 'quang nhat update',
      },
      {
        ticket_code: 'FHVCHC',
        seat_number: 'A8',
        price: 100000,
        customer_name: 'thang',
        customer_phone: '0982823232',
        pickup_location: 'Bắc Giang',
        drop_off_location: 'Bến Xe Mộc Châu',
        orderer: 'quang nhat update',
      },
    ],
  },
  transship_info: [],
};
