import { ClockCircleOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Flex, Form, Image, Row, Tooltip, Typography } from 'antd';
import { CheckboxProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import IcEditTicket from '../../../assets/images/diagram/ic-edit.svg';
import IcMoveTicket from '../../../assets/images/diagram/ic-move-ticket.svg';
import IcMoveToast from '../../../assets/images/diagram/ic-move-toast.svg';
import IcPrintTicket from '../../../assets/images/diagram/ic-print.svg';
import IcSendMail from '../../../assets/images/diagram/ic-send-mail.svg';
import iconAgent from '../../../assets/images/ic-agent-2.svg';
import iconCarGroup from '../../../assets/images/ic-house-car.png';
import iconDriver from '../../../assets/images/ic-volang2.svg';
import iconLockContact from '../../../assets/images/icon-lock-contact.svg';
import IcLock from '../../../assets/images/typeBus/ic-block-seat.svg';
import '../../../assets/styles/desktop/busBooking.scss';
import { typeSeatItems } from '../../../components/BusTypeMatrix';
import Loader from '../../../components/layouts/Loader';
import ModalAlertBalance from '../../../components/Modal/ModalAlertBalance';
import ModalAlertBalanceAgent from '../../../components/Modal/ModalAlertBalanceAgent';
import { Constant, DateFormat, Toast, USER_GROUP, YearFormat } from '../../../config/constant';
import { Action, CANCEL_TICKET_SUCCESS } from '../../../config/message';
import { convertTimeFormat, formattedPrice, getPaymentMethod } from '../../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { resetReloadDetail, selectAuth, setReloadDetail } from '../../../redux/slice/authSlice';
import {
  createBookingSelector,
  filterBookingCustomer,
  resetBooking,
  resetPriceBooking,
} from '../../../redux/slice/bookings/createBookingSlice';
import {
  destroyBooking,
  destroyBookingSelector,
  resetDestroyBooking,
} from '../../../redux/slice/bookings/destroyBookingSlice';
import {
  holdingBookingSelector,
  postHoldingTicket,
  resetHoldingBooking,
} from '../../../redux/slice/bookings/holdingBookingSlice';
import {
  getBookingInfo,
  getListBookingDetail,
  listBookingSelector,
  resetDataMoveTicket,
  resetListBookingDetail,
  setDataMoveTicket,
  setDetectLoading,
  setIsMoveTicket,
  setIsShowAllFloor,
} from '../../../redux/slice/bookings/listBooking';
import { moveTicketSelector, postMoveTicket, resetMoveTicket } from '../../../redux/slice/bookings/moveTicketSlice';
import {
  getSendBookingMail,
  resetUpdateBooking,
  updateBookingSelector,
  updateStatusBooking,
} from '../../../redux/slice/bookings/updateBooking';
import { resetDriverBooking } from '../../../redux/slice/bookings/updateDriverSlice';
import { setToast } from '../../../redux/slice/toastNotifySlice';
import ModalChangeBooking from '../changeBooking/ModalChangeBooking';
import ModalBooking from '../ModalBooking';
import ModalMultiBooking from '../ModalMultiBooking';
import ModalPrintTicket from '../tickets/ModalPrintTicket';
import CountdownComponent from './Countdown';
import ListTicketCancel from './ListTicketCancel';

function BusDiagram({
  listBooking,
  formSearch,
  openDetailScheduleBooking,
  isContract,
  statusFloor,
  setStatusFloor,
  refs,
}: any) {
  const { status: statusResponseUpdate, loadingSend } = useAppSelector(updateBookingSelector);
  const { dataBooking } = useAppSelector(holdingBookingSelector);
  const { status: statusDestroy } = useAppSelector(destroyBookingSelector);
  const { status: statusHolding, bookingType } = useAppSelector(holdingBookingSelector);
  const {
    listBookingDetail,
    loading: loadingDetail,
    bookingData,
    isShowAllFloor,
    isMoveTicket,
    dataMoveTicket,
  } = useAppSelector(listBookingSelector);
  const { message } = useAppSelector(createBookingSelector);
  const { group } = useAppSelector(selectAuth);
  const {
    is_have_pickup,
    status: statusMoveTicket,
    statusUpdateLocation,
    loading: loadingMoveTicket,
  } = useAppSelector(moveTicketSelector);
  const dispatch = useAppDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const [targetTime, setTargetTime] = useState(0);
  const [targetTicket, setTargetTicket] = useState<any>();
  const [openModalMulti, setOpenModalMulti] = useState(false);
  const [busTypeData, setBusTypeData] = useState<any>();
  const [isMulti, setIsMulti] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [timeManage, setTimeManage]: any = useState<any>([]);
  const [openModalChangeBooking, setOpenModalChangeBooking] = useState(false);
  const [isChangeTicket, setIsChangeTicket] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [openModalAlertAgent, setOpenModalAlertAgent] = useState(false);
  const [showAllFLoor, setShowAllFloor] = useState<boolean>(false);
  const [dataPrint, setDataPrint] = useState();
  const [dataBookingList, setDataBookingList] = useState<any>([]);
  const searchDateBooking = dayjs(Form.useWatch('date_booking', formSearch)).format(YearFormat);
  const searchDepartTime = Form.useWatch('depart_time', formSearch);
  const provinceId = Form.useWatch('route_id', formSearch);
  const dateSelect = dayjs(Form.useWatch('date_booking', formSearch));
  const timeBooking = dayjs(`${listBooking?.travel_info?.arrive_time}T${listBooking?.schedule?.arrive_time}`);
  const closeBefore = listBooking?.travel_info?.close_before_time
    ? dayjs(`${listBooking?.travel_info?.close_before_time}`)
    : null;

  const now = dayjs();
  const isBooking = timeBooking.isBefore(now);
  const isDepart = !isBooking && listBooking?.trip?.status === 'departed';
  const date = dayjs(new Date());
  const isOpenBus = closeBefore ? dayjs(closeBefore).isAfter(now) : null;

  const cancelModal = () => {
    setIsDetail(false);
    setOpenCreate(false);
    setIsMulti(false);
    dispatch(resetPriceBooking());
  };
  const cancelChangBookingModal = () => {
    setOpenModalChangeBooking(false);
  };
  const handleOkChangeBooking = () => {};

  const cancelModalMulti = () => {
    setOpenModalMulti(false);
  };
  const handleOkModalBooking = () => {};

  const handleLockBooking = (name: string) => {
    if (listBooking?.schedule?.id && name) {
      const lockBookingData = {
        schedule_id: listBooking.schedule.id,
        seat_number: [name],
        depart_date: searchDateBooking,
        status: 1,
      };
      dispatch(updateStatusBooking(lockBookingData));
    }
  };
  const handleUnLockBooking = (name: string) => {
    if (listBooking?.schedule?.id && name) {
      const lockBookingData = {
        schedule_id: listBooking.schedule.id,
        seat_number: [name],
        depart_date: searchDateBooking,
        status: 0,
      };
      dispatch(updateStatusBooking(lockBookingData));
    }
  };
  const getDetailBooking = (name: string, ticketCode: string) => {
    setIsDetail(true);
    dispatch(getListBookingDetail({ seat_number: name, ticket_code: ticketCode }));
    setOpenCreate(true);
  };
  const getMoveTicket = (name: string, ticketCode: string, company_id: number, schedule_id: number) => {
    dispatch(setIsMoveTicket(true));
    dispatch(
      setDataMoveTicket({ name: name, ticket_code: ticketCode, company_id: company_id, schedule_id: schedule_id }),
    );
    if (isMoveTicket) {
      dispatch(setIsMoveTicket(false));
    }
  };
  const onCancelSuccess = useCallback(() => {
    dispatch(resetDriverBooking());
    dispatch(resetDestroyBooking());
    dispatch(resetUpdateBooking());
    dispatch(resetBooking());
    dispatch(
      getBookingInfo({
        route_id: provinceId,
        depart_date: searchDateBooking,
        depart_time: convertTimeFormat(searchDepartTime),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formSearch, provinceId, searchDateBooking, searchDepartTime]);

  const cancelModalPrint = () => {
    dispatch(resetReloadDetail());
    setOpenModalPrint(false);
    setDataPrint(undefined);
  };
  const cancelModalAlert = () => {
    setOpenModalAlert(false);
  };
  const cancelModalAlertAgent = () => {
    setOpenModalAlertAgent(false);
  };
  const handleModalPrint = (seatInfo: any, name: string, ticketCode: string) => {
    dispatch(setReloadDetail());
    setDataPrint(seatInfo);
    setOpenModalPrint(true);
    dispatch(getListBookingDetail({ seat_number: name, ticket_code: ticketCode }));
  };

  const handleCreateBooking = (name: string, rowIndex: any, colIndex: any) => {
    setIsMulti(false);
    dispatch(resetListBookingDetail());
    dispatch(filterBookingCustomer({}));
    if (bookingType !== 'contract') {
      setOpenCreate(true);
    }
    const createBookingData = {
      schedule_id: +listBooking?.schedule?.id,
      seat_number: name,
      hold_type: 'single',
      depart_date: searchDateBooking,
    };
    dispatch(postHoldingTicket(createBookingData));
    if (targetTicket !== `${rowIndex}-${colIndex}-${searchDateBooking}`) {
      setTargetTicket(`${rowIndex}-${colIndex}`);
      setTargetTime(new Date().getTime() + 5 * 60 * 1000);
      return;
    }
    if (targetTime <= 0) setTargetTime(new Date().getTime() + 5 * 60 * 1000);
  };
  const handleCreateMultiBooking = (name: any, rowIndex: any, colIndex: any) => {
    setIsMulti(true);
    dispatch(filterBookingCustomer({}));
    dispatch(resetListBookingDetail());
    if (bookingType !== 'contract') {
      setOpenModalMulti(true);
    }
    const createMultiBookingData = {
      schedule_id: +listBooking?.schedule?.id,
      seat_number: name,
      hold_type: 'multi',
      depart_date: searchDateBooking,
    };
    dispatch(postHoldingTicket(createMultiBookingData));
    if (targetTicket !== `${rowIndex}-${colIndex}-${searchDateBooking}`) {
      setTargetTicket(`${rowIndex}-${colIndex}`);
      setTargetTime(new Date().getTime() + 5 * 60 * 1000);
      return;
    }
    if (targetTime <= 0) setTargetTime(new Date().getTime() + 5 * 60 * 1000);
  };
  const handleLockMultiBooking = (name: any) => {
    setIsMulti(true);
    const createMultiBookingData = {
      schedule_id: +listBooking?.schedule?.id,
      seat_number: name,
      status: 1,
      depart_date: searchDateBooking,
    };
    dispatch(updateStatusBooking(createMultiBookingData));
  };
  useEffect(() => {
    if (statusResponseUpdate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusResponseUpdate]);
  const resetTargetTicket = (value: any) => {
    setTargetTicket(value);
  };
  const handleMoveTicket = (name: string, schedule_id: number, depart_date: string, depart_time: string) => {
    dispatch(
      postMoveTicket({
        depart_time: depart_time,
        old_ticket_code: dataMoveTicket?.ticket_code,
        depart_date: depart_date,
        schedule_id: schedule_id,
        seat_number: name,
      }),
    );
  };
  const onChangeFloor = (value: number | undefined) => {
    if (value && value <= 2) {
      setStatusFloor(value);
    }
  };
  const calculateFinalPrice = (seatFloors: any) => {
    const basePriceStr = listBooking?.schedule?.price_json?.find((priceEntry: any) => {
      const groupSeat = listBooking?.schedule?.bus_type?.seat_map?.group_seat?.find((group: any) =>
        group?.selected_seat?.includes(seatFloors?.name),
      );
      return groupSeat && groupSeat?.name === priceEntry?.name;
    })?.seat_price;

    if (!basePriceStr && basePriceStr !== 0) return null;
    const basePrice = basePriceStr !== 0 ? parseFloat(basePriceStr.replace(/,/g, '')) : 0;
    let finalPrice = basePrice;
    if (listBooking?.price_schedule?.percent) {
      if (!listBooking?.price_schedule?.is_increase) {
        finalPrice *= (100 - listBooking?.price_schedule?.percent) / 100;
      } else {
        finalPrice *= (100 + listBooking?.price_schedule?.percent) / 100;
      }
    } else if (listBooking?.price_schedule?.price) {
      if (!listBooking?.price_schedule?.is_increase) {
        finalPrice -= listBooking?.price_schedule?.price;
      }
      finalPrice += listBooking?.price_schedule?.price;
    }
    return finalPrice;
  };

  useEffect(() => {
    if (listBooking?.schedule?.bus_type) {
      const tmpDataBusType = { ...listBooking?.schedule?.bus_type?.seat_map };
      setBusTypeData(tmpDataBusType);
    }
  }, [listBooking]);
  const handleChangeTicket = useCallback(
    (rowIndex: number, colIndex: number, floor?: number) => {
      if (!isShowAllFloor || listBooking?.schedule?.bus_type?.seat_map?.count_floor === 1) {
        setBusTypeData((prevMatrix: any) => {
          const newMatrix = JSON.parse(JSON.stringify(prevMatrix));
          const seat = newMatrix[`floor${statusFloor}`][rowIndex][colIndex];
          seat.selected = !seat.selected;
          return newMatrix;
        });
      } else {
        if (floor === 1) {
          setBusTypeData((prevMatrix: any) => {
            const newMatrix = JSON.parse(JSON.stringify(prevMatrix));
            const seat = newMatrix[`floor1`][rowIndex][colIndex];
            seat.selected = !seat.selected;
            return newMatrix;
          });
        } else {
          setBusTypeData((prevMatrix: any) => {
            const newMatrix = JSON.parse(JSON.stringify(prevMatrix));
            const seat = newMatrix[`floor2`][rowIndex][colIndex];
            seat.selected = !seat.selected;
            return newMatrix;
          });
        }
      }
    },
    [statusFloor, isShowAllFloor, listBooking],
  );
  useEffect(() => {
    if (isShowAllFloor) {
      setShowAllFloor(isShowAllFloor);
    }
  }, [isShowAllFloor]);
  const handleChangeShowFloor: CheckboxProps['onChange'] = (e) => {
    dispatch(setIsShowAllFloor(e.target.checked));
    setIsShowAllFloor(true);
  };
  useEffect(() => {
    if (listBooking && provinceId && searchDateBooking && searchDepartTime) {
      setTimeManage([
        {
          id: listBooking?.schedule?.id,
        },
      ]);
    }
  }, [listBooking, provinceId, searchDateBooking, searchDepartTime]);
  useEffect(() => {
    if (timeManage?.length) {
      let timers: NodeJS.Timeout[] = [];
      const handleUpdateStatus = async (): Promise<void> => {
        dispatch(setDetectLoading());
        await dispatch(
          getBookingInfo({
            route_id: provinceId,
            depart_date: searchDateBooking,
            depart_time: convertTimeFormat(searchDepartTime),
          }),
        );
      };
      const setTimer = (index: number): void => {
        const timerId: NodeJS.Timeout = setTimeout(() => {
          if (
            openCreate ||
            openModalMulti ||
            busTypeData[`floor${statusFloor}`]?.some((row: any) => row.some((seat: any) => seat.selected)) ||
            isContract ||
            openDetailScheduleBooking ||
            openModalPrint
          ) {
            // Clear all timers
            timers.forEach(clearTimeout);
            return;
          }
          handleUpdateStatus();
          setTimer(index);
        }, Constant.TIMER_BOOKING);
        timers[index] = timerId;
      };
      // Start timers
      for (let i: number = 0; i < timeManage?.length; i++) {
        setTimer(i);
      }
      return (): void => {
        timers.forEach(clearTimeout);
      };
    }
  }, [
    timeManage,
    openCreate,
    openModalMulti,
    provinceId,
    searchDateBooking,
    searchDepartTime,
    dispatch,
    busTypeData,
    statusFloor,
    bookingType,
    openDetailScheduleBooking,
    openModalPrint,
    isContract,
  ]);

  useEffect(() => {
    if (openCreate || openModalMulti) {
      setBusTypeData((prevMatrix: any) => {
        const newMatrix = JSON.parse(JSON.stringify(prevMatrix));
        newMatrix[`floor${statusFloor}`].forEach((row: any) => {
          row.forEach((seat: any) => {
            seat.selected = false;
          });
        });
        return newMatrix;
      });
    }
  }, [openCreate, statusFloor, openModalMulti]);

  useEffect(() => {
    if (!(statusHolding === 200 || statusHolding === 0)) {
      setOpenCreate(false);
      setOpenModalMulti(false);
      dispatch(resetHoldingBooking());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusHolding]);

  useEffect(() => {
    if (statusMoveTicket === Constant.DEFAULT_STATUS) {
      if (is_have_pickup) {
        dispatch(
          setToast({
            message: CANCEL_TICKET_SUCCESS(Action.MOVE_TICKET),
            status: true,
            type: Toast.TYPE_SUCCESS,
          }),
        );
        onCancelSuccess();
        dispatch(resetMoveTicket());
        dispatch(resetDataMoveTicket());
      } else {
        onCancelSuccess();
        // dispatch(resetMoveTicket());
        // dispatch(resetDataMoveTicket());
        setIsDetail(true);
        setOpenCreate(true);
        setIsChangeTicket(true);
        dispatch(
          getListBookingDetail({ seat_number: dataMoveTicket?.seat_number, ticket_code: dataMoveTicket?.ticket_code }),
        );
      }
    }
  }, [dispatch, statusMoveTicket, is_have_pickup]);

  useEffect(() => {
    if (statusUpdateLocation === Constant.DEFAULT_STATUS) {
      dispatch(
        setToast({
          message: CANCEL_TICKET_SUCCESS(Action.MOVE_TICKET),
          status: true,
          type: Toast.TYPE_SUCCESS,
        }),
      );
      onCancelSuccess();
      dispatch(resetMoveTicket());
      dispatch(resetDataMoveTicket());
      setOpenCreate(false);
    }
  }, [dispatch, statusUpdateLocation]);

  useEffect(() => {
    if (listBooking?.schedule?.id !== dataBookingList?.schedule?.id) {
      setDataBookingList(listBooking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBooking]);

  useEffect(() => {
    if (statusDestroy === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDestroy]);

  useEffect(() => {
    if (message === 'Tài khoản nhà xe không đủ để xuất vé.') {
      setOpenModalAlert(true);
      if (dataBooking?.ticket_code) {
        dispatch(
          destroyBooking({
            ticket_code: [dataBooking?.ticket_code],
          }),
        );
      }
      setOpenCreate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (message === 'Tài khoản đại lý không đủ số dư') {
      setOpenModalAlertAgent(true);
      if (dataBooking?.ticket_code) {
        dispatch(
          destroyBooking({
            ticket_code: [dataBooking?.ticket_code],
          }),
        );
      }
      setOpenCreate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
  return (
    <>
      <Loader force={loadingSend || loadingMoveTicket} />
      {dataMoveTicket?.ticket_code && isMoveTicket && (
        <div className="move-ticket-block">
          <Flex gap={8} className="block-ticket-content">
            <img src={IcMoveToast} alt="" />
            <span className="fs-16 fw-500 text-white">Bạn đang di chuyển vé</span>
          </Flex>
          <Flex gap={24} className="pr-24">
            <span className="fs-16 fw-500 text-white"> Ghế đang chọn</span>
            <span className="fs-16 fw-500 text-white">{dataMoveTicket?.name}</span>
          </Flex>
          <Button className="btn-cancel" onClick={() => dispatch(setIsMoveTicket(false))}>
            Hủy
          </Button>
        </div>
      )}
      {listBooking?.schedule?.bus_type?.seat_map?.count_floor === 2 && (
        <>
          <div className="d-flex items-center" style={{ columnGap: '24px' }}>
            <div>
              <Button
                className={`btn-floor${statusFloor === 1 && !isShowAllFloor ? ' active' : ''}`}
                onClick={() => onChangeFloor(1)}
                disabled={isShowAllFloor}
              >
                Tầng 1
              </Button>
              <Button
                className={`btn-floor${statusFloor === 2 && !isShowAllFloor ? ' active' : ''}`}
                onClick={() => onChangeFloor(2)}
                disabled={isShowAllFloor}
              >
                Tầng 2
              </Button>
            </div>
            <Checkbox
              value={showAllFLoor}
              onChange={handleChangeShowFloor}
              className="text-blue fw-500"
              checked={isShowAllFloor ? isShowAllFloor : false}
            >
              Hiển thị 2 tầng trên 1 trang
            </Checkbox>
          </div>
        </>
      )}
      {!isShowAllFloor || listBooking?.schedule?.bus_type?.seat_map?.count_floor === 1 ? (
        <>
          {listBooking?.schedule?.bus_type?.seat_map[`floor${statusFloor}`]?.map((rowSeats: any, rowIndex: number) => {
            const nonEmptySeatsCount = rowSeats.filter((seat: any) => seat.name || seat.type !== 0).length;
            const isSingleSeatRow = nonEmptySeatsCount === 1;
            return (
              <Row
                gutter={[12, 12]}
                key={rowIndex}
                className="mt-2"
                style={{ display: 'grid', gridTemplateColumns: `repeat(${nonEmptySeatsCount}, 1fr)` }}
              >
                {rowSeats?.map((seatFloors: any, colIndex: number) => {
                  const seatEntries = listBooking?.booking?.data?.find(
                    (entry: any) => entry.seat_number === seatFloors.name,
                  );
                  const seatEntriesExpired = bookingData?.find((entry: any) => entry.seat_number === seatFloors.name);
                  const seatCustomerEntries = listBooking?.customers?.data?.find(
                    (entry: any) => entry.seat_number === seatFloors.name,
                  );
                  const seatBooking =
                    Array.isArray(listBooking?.schedule?.agent_seats) &&
                    listBooking?.schedule?.agent_seats?.includes(seatFloors?.name);
                  const selectedSeatNames = busTypeData?.[`floor${statusFloor}`].flatMap(
                    (row: any) =>
                      !date.isAfter(listBooking?.travel_info?.depart_time, 'day') &&
                      row.filter((seat: any) => seat.selected).map((seat: any) => seat.name),
                  );
                  if (seatEntriesExpired?.expired_at && seatEntriesExpired?.expired_at === +0) {
                    onCancelSuccess();
                  }
                  return (
                    (seatFloors.name || seatFloors.type !== Constant.DEFAULT_SEAT) && (
                      <Col
                        key={colIndex}
                        onClick={() =>
                          seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                          seatFloors.name &&
                          !seatEntries?.id &&
                          !(isMoveTicket && dataMoveTicket?.ticket_code) &&
                          handleChangeTicket(rowIndex, colIndex)
                        }
                        ref={(el) => {
                          refs.current[seatEntries?.ticket_code] = el;
                        }}
                      >
                        <Checkbox className="checkbox-diagram d-none" checked={seatFloors.selected} />
                        <Card
                          onClick={() =>
                            isMoveTicket &&
                            dataMoveTicket?.ticket_code &&
                            seatFloors?.type > 2 &&
                            (group !== USER_GROUP.AGENT ||
                              (group === USER_GROUP.AGENT &&
                                (searchDateBooking &&
                                listBooking?.schedule?.agent_seats_custom &&
                                listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                  ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                      seatFloors?.name,
                                    ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                  : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                    !listBooking?.booked_seat?.includes(seatFloors?.name)))) &&
                            !seatEntries?.ticket_code &&
                            handleMoveTicket(
                              seatFloors?.name,
                              listBooking?.schedule?.id,
                              listBooking?.travel_info?.depart_date,
                              listBooking?.schedule?.depart_time,
                            )
                          }
                          className={`mt-1 ant-card-diagram diagram
                        ${isSingleSeatRow && 'w-30'}
                        ${
                          busTypeData &&
                          busTypeData?.[`floor${statusFloor}`] &&
                          busTypeData?.[`floor${statusFloor}`][rowIndex] &&
                          busTypeData?.[`floor${statusFloor}`][rowIndex][colIndex] &&
                          busTypeData?.[`floor${statusFloor}`][rowIndex][colIndex]?.name === seatFloors?.name &&
                          busTypeData?.[`floor${statusFloor}`][rowIndex][colIndex]?.selected &&
                          seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                          seatEntries?.status !== 'locked' &&
                          !seatEntries?.payment_method &&
                          (group !== USER_GROUP.AGENT ||
                            (group === USER_GROUP.AGENT &&
                            searchDateBooking &&
                            listBooking?.schedule?.agent_seats_custom &&
                            listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                              ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                  seatFloors?.name,
                                ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                              : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                !listBooking?.booked_seat?.includes(seatFloors?.name))) &&
                          (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                          ((!isOpenBus && listBooking?.trip?.status !== 'canceled') || isOpenBus) &&
                          listBooking?.trip?.status !== 'canceled' &&
                          !(
                            dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                            listBooking?.schedule?.open_before === 0
                          ) &&
                          !(isMoveTicket && dataMoveTicket?.ticket_code) &&
                          (!listBooking?.price_schedule?.is_price_contact ||
                            (listBooking?.price_schedule?.is_price_contact && group !== USER_GROUP.AGENT))
                            ? 'selected'
                            : ''
                        }
                      ${
                        isMoveTicket &&
                        seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                        (group !== USER_GROUP.AGENT ||
                          (group === USER_GROUP.AGENT &&
                            (searchDateBooking &&
                            listBooking?.schedule?.agent_seats_custom &&
                            listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                              ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                  seatFloors?.name,
                                ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                              : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                !listBooking?.booked_seat?.includes(seatFloors?.name)))) &&
                        !seatEntries?.ticket_code
                          ? 'cursor-move'
                          : ''
                      }
                       ${isMoveTicket && seatEntries?.ticket_code === dataMoveTicket?.ticket_code ? 'move-ticket' : ''}
                       ${
                         (seatEntries?.source === 'openapi' || seatEntries?.source === 'company') &&
                         seatEntries?.status !== 'paid'
                           ? 'open_api'
                           : ''
                       }
                       ${
                         seatFloors.type === Constant.DRIVER_SEAT ||
                         seatFloors.type === Constant.EXTRA_DRIVER_SEAT ||
                         seatEntries?.status === 'locked' ||
                         ((group === USER_GROUP.AGENT &&
                           (searchDateBooking &&
                           listBooking?.schedule?.agent_seats_custom &&
                           listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                             ? !(
                                 listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                   seatFloors?.name,
                                 ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                               )
                             : !(
                                 listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                 !listBooking?.booked_seat?.includes(seatFloors?.name)
                               ) || seatEntries?.id) &&
                           (listBooking?.trip?.status !== 'departed' || listBooking?.trip?.status !== 'canceled')) ||
                         dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                         listBooking?.schedule?.open_before === 0 ||
                         (listBooking?.price_schedule?.is_price_contact && group === USER_GROUP.AGENT)
                           ? ' ban'
                           : '')
                           ? 'driver'
                           : (seatEntries?.payment_method === 'reserve' ||
                               seatEntries?.payment_method === 'paid_at_company' ||
                               seatEntries?.status === 'reserve') &&
                             seatEntries?.status !== 'paid'
                           ? 'reserve'
                           : seatEntries?.payment_method === 'cash' ||
                             seatEntries?.payment_method === 'bank_transfer' ||
                             seatEntries?.payment_method === 'agent_balance' ||
                             seatEntries?.status === 'paid'
                           ? 'payment'
                           : ''
                       }${
                            ((
                              group === USER_GROUP.AGENT &&
                              searchDateBooking &&
                              listBooking?.schedule?.agent_seats_custom &&
                              listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                ? !(
                                    listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                      seatFloors?.name,
                                    ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                  )
                                : !(
                                    listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                    !listBooking?.booked_seat?.includes(seatFloors?.name)
                                  )
                            )
                              ? ' ban'
                              : '') && seatFloors?.type < Constant.EXTRA_DRIVER_SEAT
                              ? ' ban'
                              : ''
                          } 
                              ${
                                listBooking?.price_schedule?.is_price_contact && group === USER_GROUP.AGENT
                                  ? 'price-contact'
                                  : ''
                              }
                     
                          `}
                          style={
                            seatFloors?.type === Constant.DRIVER_SEAT || seatFloors?.type === Constant.EXTRA_DRIVER_SEAT
                              ? {
                                  backgroundColor: 'transparent',
                                  minHeight: 'unset',
                                  height: '100px',
                                  // border: '2px solid #DCDCDC',
                                }
                              : group === USER_GROUP.AGENT && seatEntries?.id
                              ? (seatEntries?.payment_method === 'reserve' ||
                                  seatEntries?.payment_method === 'paid_at_company') &&
                                seatEntries?.status !== 'locked' &&
                                seatEntries?.status !== 'paid'
                                ? { backgroundColor: '#ffe296' }
                                : (seatEntries?.payment_method === 'cash' ||
                                    seatEntries?.payment_method === 'bank_transfer' ||
                                    seatEntries?.payment_method === 'agent_balance' ||
                                    seatEntries?.payment_method === 'paid_at_company' ||
                                    seatEntries?.status === 'paid') &&
                                  seatEntries?.status !== 'locked'
                                ? { backgroundColor: '#99d6a2' }
                                : {}
                              : {}
                          }
                          cover={
                            <div
                              className={`bus-diagram-card ${seatFloors?.type < Constant.NORMAL_SEAT ? 'driver' : ''}`}
                            >
                              {seatEntries?.status === 'locked' ? (
                                <>
                                  <div className="header-card-diagram locked">
                                    <Typography className="fw-600 title-card">
                                      <Image
                                        src={typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image}
                                        alt=""
                                        height={32}
                                        preview={false}
                                        className={`img-type-seat`}
                                      />
                                      <span className="seat-name-booking d-flex items-center mb-0">
                                        {seatFloors.name}
                                      </span>
                                    </Typography>
                                  </div>
                                  <div className="block-content">
                                    <Button
                                      className="btn btn-block"
                                      onClick={() => handleUnLockBooking(seatFloors?.name)}
                                    >
                                      <img src={IcLock} alt="" />
                                    </Button>
                                    <Typography className="fw-500 fs-16">Ghế này đã bị khoá!</Typography>
                                    <Typography className="fs-12 fw-400">{`Bị khóa bởi ${seatEntries?.orderer?.name}`}</Typography>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="header-card-diagram">
                                    <Typography
                                      className={`fw-600 ${seatEntries?.orderer?.name ? 'is-booking' : ''} title-card`}
                                    >
                                      <div
                                        className="title-seat-diagram"
                                        style={
                                          seatFloors?.type === Constant.DRIVER_SEAT ||
                                          seatFloors?.type === Constant.EXTRA_DRIVER_SEAT
                                            ? { flexDirection: 'row', alignItems: 'center' }
                                            : {}
                                        }
                                      >
                                        <Image
                                          src={
                                            typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image
                                          }
                                          alt=""
                                          height={32}
                                          preview={false}
                                          className={`img-type-seat w-fit`}
                                        />
                                        <p
                                          className={`seat-name-booking ${
                                            seatFloors.type > Constant.EXTRA_DRIVER_SEAT ? 'ticket' : ''
                                          } `}
                                          style={
                                            seatFloors.type === Constant.EXTRA_DRIVER_SEAT ||
                                            seatFloors.type === Constant.DRIVER_SEAT
                                              ? { marginBottom: 0 }
                                              : {}
                                          }
                                        >
                                          {seatFloors.type > Constant.EXTRA_DRIVER_SEAT ? '' : seatFloors.name}
                                        </p>
                                        {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && !seatEntries?.ticket_code && (
                                          <p className="seat-name-booking name-floor">{seatFloors?.name}</p>
                                        )}
                                      </div>
                                      {!listBooking?.contract?.code ? (
                                        <>
                                          {!seatEntries && seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                            <span className="seat-name-booking">
                                              {`${
                                                seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                                typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.name
                                              }`}
                                            </span>
                                          )}
                                          {!(
                                            (seatEntries?.status === 'hold' || seatEntries?.status === 'reserve') &&
                                            (seatEntries?.source === 'openapi' || seatEntries?.source === 'company')
                                          ) ? (
                                            <span className="seat-name-booking ticket-code">
                                              {seatEntries?.ticket_code
                                                ? `${seatEntries?.ticket_code} - ${seatFloors?.name}`
                                                : seatEntries?.ticket_code}
                                            </span>
                                          ) : (
                                            <span className="seat-name-booking ticket-code">{seatFloors?.name}</span>
                                          )}
                                        </>
                                      ) : (
                                        seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                          <p className={`seat-name-booking`}>{seatFloors?.name}</p>
                                        )
                                      )}
                                    </Typography>
                                  </div>
                                  {listBooking?.price_schedule?.is_price_contact &&
                                  group === USER_GROUP.AGENT &&
                                  seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                  ((listBooking?.trip?.status !== 'departed' &&
                                    listBooking?.trip?.status !== 'cancelled') ||
                                    isOpenBus) &&
                                  !seatEntries ? (
                                    <>
                                      <div className="block-content mt-3">
                                        <img src={iconLockContact} alt="" />
                                        <Typography className="fw-400 fs-13 mt-1">Chỗ này đã bị khoá lý do:</Typography>
                                        <Typography className="fs-14 fw-500 text-blue">{`${listBooking?.price_schedule?.reason}`}</Typography>
                                      </div>
                                      <div
                                        style={{ padding: '4px 12px', background: '#009EF8' }}
                                        className="rounded-3 w-fit text-center m-auto mt-2"
                                      >
                                        <p className="fs-14 fw-600 text-white">Liên hệ đặt vé</p>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  <div className="block-info">
                                    <Typography className="fw-500 fs-14 mr-5 orderer-name">
                                      {seatEntries?.orderer?.name}
                                    </Typography>
                                    {seatFloors?.type === Constant.DRIVER_SEAT && (
                                      <>
                                        {listBooking?.trip?.drivers?.map((driver: any, index: number) => (
                                          <Typography className="fw-500 fs-16" key={index}>
                                            <span className="fw-600">{driver.name}</span> <br />
                                            <span> {driver.phone}</span>
                                          </Typography>
                                        ))}
                                      </>
                                    )}
                                    {seatFloors?.type === Constant.EXTRA_DRIVER_SEAT && (
                                      <>
                                        {listBooking?.trip?.extra_drivers?.map((driver: any, index: number) => (
                                          <Typography key={index} className="fw-500 fs-16">
                                            <span className="fw-600">{driver.name}</span> <br />
                                            <span>{driver.phone}</span>
                                          </Typography>
                                        ))}
                                      </>
                                    )}
                                    {((seatFloors?.status !== 0 && !seatFloors.locked) ||
                                      seatFloors?.type === Constant.DRIVER_SEAT ||
                                      (seatBooking && group !== USER_GROUP.AGENT) ||
                                      seatFloors?.type === Constant.EXTRA_DRIVER_SEAT) && (
                                      <>
                                        {seatEntries?.payment_method === 'reserve' ||
                                        seatEntries?.payment_method === 'paid_at_company' ||
                                        (seatEntries?.payment_method === 'bank_transfer' &&
                                          seatEntries?.source === 'openapi') ? (
                                          <div
                                            style={{ display: 'flex', justifyContent: 'space-between' }}
                                            className="time-hold-line"
                                          >
                                            <Typography className="fw-600 mt-1">
                                              {seatEntries?.customer?.name}
                                            </Typography>
                                            {+seatEntriesExpired?.expired_at ? (
                                              <div className="fs-14 fw-500 time-hold mr-5">
                                                <ClockCircleOutlined color="#435869" />
                                                <CountdownComponent seatEntriesExpired={seatEntriesExpired} />
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        ) : (
                                          <Typography className="fw-600">{seatEntries?.customer?.name}</Typography>
                                        )}
                                        <Typography className="fw-500">
                                          {!(
                                            (seatEntries?.status === 'hold' || seatEntries?.status === 'reserve') &&
                                            seatEntries?.source === 'openapi'
                                          )
                                            ? seatEntries?.customer?.phone
                                            : 'Số điện thoại: N/A'}
                                        </Typography>
                                        {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                          <>
                                            <div
                                              className={`${seatEntries ? 'address-bus' : ''} fw-500 fs-14`}
                                              style={{ maxWidth: '90%' }}
                                            >
                                              <Typography className="fs-14">
                                                {seatEntries?.pickup_location?.location_name
                                                  ? `${
                                                      seatEntries?.pickup_location?.location_name
                                                    } (${seatEntries?.pickup_location?.time?.slice(0, 5)}${
                                                      (seatEntries?.pickup_location?.diff_day ||
                                                        seatEntries?.drop_off_location?.diff_day) > 0
                                                        ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                            ?.add(seatEntries?.pickup_location?.diff_day, 'day')
                                                            ?.format(DateFormat)}`
                                                        : ''
                                                    })`
                                                  : seatEntries?.pickup_extra &&
                                                    ` ${
                                                      seatEntries?.pickup_extra
                                                    } (${listBooking?.schedule?.depart_time?.slice(0, 5)})`}
                                              </Typography>
                                              <Typography className="fs-14">
                                                {seatEntries?.drop_off_location?.location_name
                                                  ? `${seatEntries?.drop_off_location?.location_name} (${
                                                      seatEntries?.drop_off_location?.time
                                                        ? seatEntries?.drop_off_location?.time?.slice(0, 5)
                                                        : listBooking?.schedule?.arrive_time?.slice(0, 5)
                                                    }${
                                                      (seatEntries?.pickup_location?.diff_day ||
                                                        seatEntries?.drop_off_location?.diff_day) > 0
                                                        ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                            ?.add(seatEntries?.drop_off_location?.diff_day, 'day')
                                                            ?.format(DateFormat)}`
                                                        : ''
                                                    })`
                                                  : seatEntries?.drop_off_extra &&
                                                    ` ${
                                                      seatEntries?.drop_off_extra
                                                    } (${listBooking?.schedule?.arrive_time?.slice(0, 5)})`}
                                              </Typography>
                                            </div>
                                            <div>
                                              <Typography className="fw-500 fs-14">
                                                {`${seatEntries?.note ?? ''}`}
                                              </Typography>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                    {seatFloors?.type < Constant.NORMAL_SEAT && (
                                      <Typography className="fw-600 mt-1">
                                        {seatCustomerEntries?.customer_name}
                                      </Typography>
                                    )}
                                  </div>
                                  <div className={`${seatEntries?.customer?.name ? '' : 'price-item'} mb-1 mt-1`}>
                                    <>
                                      {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                        (seatBooking ||
                                          !(group === USER_GROUP.AGENT &&
                                          searchDateBooking &&
                                          listBooking?.schedule?.agent_seats_custom &&
                                          listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                            ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                                seatFloors?.name,
                                              ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                            : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                              !listBooking?.booked_seat?.includes(seatFloors?.name)) ||
                                          (group !== USER_GROUP.AGENT && seatEntries?.price)) &&
                                        !listBooking?.contract?.code &&
                                        (!listBooking?.price_schedule?.is_price_contact ||
                                          (listBooking?.price_schedule?.is_price_contact &&
                                            group !== USER_GROUP.AGENT)) && (
                                          <>
                                            <Typography className="fw-500 fs-14">
                                              {(() => {
                                                const finalPrice = calculateFinalPrice(seatFloors);
                                                if (finalPrice === null) return null;
                                                const formattedPrice = finalPrice.toLocaleString('en-US');
                                                return seatEntries?.source === 'openapi' ||
                                                  seatEntries?.source === 'company'
                                                  ? `Giá vé: ${seatEntries?.final_price}đ`
                                                  : seatEntries?.price
                                                  ? `${seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'} ${
                                                      seatEntries?.price
                                                    }VNĐ`
                                                  : `${
                                                      seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'
                                                    } ${formattedPrice} VNĐ`;
                                              })()}
                                            </Typography>
                                            {seatEntries?.source === 'company' &&
                                              seatEntries?.payment_method === 'paid_at_company' && (
                                                <Typography
                                                  className={'fw-600 fs-12 mt-3 text-green'}
                                                  style={
                                                    listBooking?.price_schedule?.percent ||
                                                    listBooking?.price_schedule?.price
                                                      ? { minWidth: '85%' }
                                                      : { height: 0 }
                                                  }
                                                >
                                                  {seatEntries?.status !== 'paid' &&
                                                    `Vé thanh toán tại nhà xe của ${seatEntries?.orderer?.name}`}
                                                </Typography>
                                              )}
                                            {listBooking?.price_schedule?.percent ||
                                            listBooking?.price_schedule?.price ? (
                                              <Typography
                                                className={'fw-600 fs-12'}
                                                style={
                                                  listBooking?.price_schedule?.percent ||
                                                  listBooking?.price_schedule?.price
                                                    ? { minWidth: '85%' }
                                                    : { height: 0 }
                                                }
                                              >
                                                {`${
                                                  listBooking?.price_schedule?.is_increase
                                                    ? 'Giá trên đã tăng: '
                                                    : 'Giá trên đã giảm'
                                                } 
                                                ${
                                                  listBooking?.price_schedule?.percent
                                                    ? `${listBooking?.price_schedule?.percent}%`
                                                    : `${formattedPrice(listBooking?.price_schedule?.price)}đ`
                                                }- ${listBooking?.price_schedule?.reason}`}
                                              </Typography>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        )}
                                    </>
                                    {listBooking?.price_schedule?.is_price_contact &&
                                    seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                    !seatEntries ? (
                                      group !== USER_GROUP.AGENT ? (
                                        <p className="fw-500 fs-13" style={{ color: '#D81717' }}>
                                          Đang có chương trình giá liên hệ: {listBooking?.price_schedule?.reason}{' '}
                                        </p>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                    {seatFloors?.status !== 0 &&
                                      seatEntries &&
                                      ((seatEntries?.payment_method !== 'reserve' &&
                                        seatEntries?.payment_method !== 'paid_at_company') ||
                                        seatEntries?.status === 'paid') && (
                                        <>
                                          {seatEntries?.is_driver_pay ? (
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                columnGap: '4px',
                                                maxWidth: '86%',
                                              }}
                                            >
                                              <img src={iconDriver} alt="" width={12} className="mt--5" />
                                              <Typography className="fw-500 fs-12 mt-1">
                                                {`Tài xế đã thu bằng ${getPaymentMethod(seatEntries?.payment_method)} ${
                                                  seatEntries?.final_price
                                                    ? formattedPrice(seatEntries?.final_price)
                                                    : 0
                                                }VNĐ `}
                                              </Typography>
                                            </div>
                                          ) : (
                                            <>
                                              {(seatEntries?.orderer?.agent?.id || seatEntries?.payer) &&
                                              !listBooking?.contract?.id ? (
                                                <div
                                                  className="d-flex mt-1"
                                                  style={{
                                                    alignItems: 'center',
                                                    columnGap: '4px',
                                                    maxWidth: '86%',
                                                  }}
                                                >
                                                  <Typography className="fw-500 fs-12">
                                                    {(seatEntries?.source === 'openapi' ||
                                                      seatEntries?.source === 'company') &&
                                                    seatEntries?.status === 'reserve' ? (
                                                      ''
                                                    ) : (
                                                      <>
                                                        {seatEntries?.status === 'paid' &&
                                                        (seatEntries?.source === 'openapi' ||
                                                          seatEntries?.source === 'company') ? (
                                                          `Đã thanh toán cho ${seatEntries?.payer?.name}`
                                                        ) : (
                                                          <>
                                                            <img
                                                              src={
                                                                seatEntries?.payer?.agent?.id ? iconAgent : iconCarGroup
                                                              }
                                                              alt=""
                                                              width={12}
                                                              className="mt--5"
                                                            />
                                                            {seatEntries?.payer?.name
                                                              ? seatEntries?.payer?.office_name
                                                                ? `${seatEntries?.payer?.name}_${seatEntries?.payer?.office_name}`
                                                                : seatEntries?.payer?.name
                                                              : seatEntries?.orderer?.name}
                                                            {seatEntries?.payer?.agent?.id
                                                              ? ' đã thanh toán bằng '
                                                              : ' đã thu bằng '}
                                                            {getPaymentMethod(seatEntries?.payment_method)}
                                                            {!listBooking?.contract?.code &&
                                                              `${formattedPrice(
                                                                seatEntries?.final_price
                                                                  ? !seatEntries?.payer?.agent?.id &&
                                                                    seatEntries?.orderer?.agent?.id
                                                                    ? ` ${formattedPrice(seatEntries?.price)}`
                                                                    : ` ${formattedPrice(seatEntries?.final_price)}`
                                                                  : 0,
                                                              )}
                                                  VNĐ`}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </Typography>
                                                </div>
                                              ) : (
                                                <div
                                                  className="fw-500 fs-12 d-flex"
                                                  style={{
                                                    maxWidth: '400px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    columnGap: '4px',
                                                    width: '85%',
                                                  }}
                                                >
                                                  <img
                                                    src={seatEntries?.payer?.agent?.id ? iconAgent : iconCarGroup}
                                                    width={12}
                                                    alt=""
                                                  />
                                                  <Typography>
                                                    {`${seatEntries?.orderer?.name} ${
                                                      seatEntries?.payer?.agent?.id ? 'đã thanh toán bằng ' : 'đã thu '
                                                    } ${getPaymentMethod(seatEntries?.payment_method)} ${
                                                      formattedPrice(seatEntries?.commission_price) ?? 0
                                                    }VNĐ ${
                                                      seatEntries?.payment_method === 'cash'
                                                        ? ' tiền mặt'
                                                        : ' chuyển khoản'
                                                    } tại ${seatEntries?.payer?.agent?.id ? `đại lý` : `nhà xe `}`}
                                                  </Typography>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                  </div>
                                </>
                              )}
                              <div className="btn-diagram-block">
                                {seatEntries?.status === 'locked' ? (
                                  ''
                                ) : seatFloors?.type > Constant.EXTRA_DRIVER_SEAT ? (
                                  <>
                                    {seatEntries ? (
                                      <>
                                        <Button
                                          className="btn-diagram"
                                          onClick={() => getDetailBooking(seatFloors?.name, seatEntries?.ticket_code)}
                                        >
                                          <Tooltip title={'Chỉnh sửa'} key={'edt2'} placement="right">
                                            <img src={IcEditTicket} alt="" />
                                          </Tooltip>
                                        </Button>
                                        {!(
                                          (seatEntries?.source === 'openapi' || seatEntries?.source === 'company') &&
                                          seatEntries?.status !== 'paid'
                                        ) &&
                                          (!listBooking?.price_schedule?.is_price_contact ||
                                            (listBooking?.price_schedule?.is_price_contact &&
                                              group !== USER_GROUP.AGENT)) && (
                                            <Button
                                              className="btn-diagram"
                                              onClick={() =>
                                                getMoveTicket(
                                                  seatFloors?.name,
                                                  seatEntries?.ticket_code,
                                                  listBooking?.schedule?.company?.id,
                                                  listBooking?.schedule?.id,
                                                )
                                              }
                                              disabled={listBooking?.trip?.status !== 'not_departed'}
                                            >
                                              <Tooltip title={'Chuyển vé'} key={'cv2'} placement="right">
                                                <img src={IcMoveTicket} alt="" />
                                              </Tooltip>
                                            </Button>
                                          )}
                                        {seatEntries?.source !== 'openapi' && seatEntries?.source !== 'company' && (
                                          <>
                                            <Button
                                              className="btn-diagram"
                                              onClick={() =>
                                                handleModalPrint(
                                                  seatEntries,
                                                  seatFloors?.name,
                                                  seatEntries?.ticket_code,
                                                )
                                              }
                                            >
                                              <Tooltip title={'In vé'} key={'prt'} placement="right">
                                                <img src={IcPrintTicket} alt="" />
                                              </Tooltip>
                                            </Button>
                                            <Button
                                              className="btn-diagram"
                                              onClick={() => dispatch(getSendBookingMail(seatEntries?.id))}
                                            >
                                              <Tooltip title={'Gửi mail'} key={'eml'} placement="right">
                                                <img src={IcSendMail} alt="" />
                                              </Tooltip>
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {selectedSeatNames?.length > 1 &&
                                        selectedSeatNames.find((selected: any) => selected === seatFloors?.name) &&
                                        (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                        ((!isOpenBus && listBooking?.trip?.status !== 'canceled') || isOpenBus) &&
                                        !(
                                          dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                          listBooking?.schedule?.open_before === 0
                                        ) &&
                                        (group !== USER_GROUP.AGENT ||
                                          (group === USER_GROUP.AGENT &&
                                            (searchDateBooking &&
                                            listBooking?.schedule?.agent_seats_custom &&
                                            listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                              ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                                  seatFloors?.name,
                                                ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                              : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                                !listBooking?.booked_seat?.includes(seatFloors?.name)))) ? (
                                          <>
                                            {group !== USER_GROUP.AGENT && (
                                              <Button
                                                className="btn-diagram"
                                                disabled={seatEntries?.status === 'hold'}
                                                onClick={() => handleLockMultiBooking(selectedSeatNames)}
                                              >
                                                <Tooltip title={'Khóa ghế'} key={'bkt-mt'} placement="right">
                                                  <LockOutlined />
                                                </Tooltip>
                                              </Button>
                                            )}
                                            {!listBooking?.price_schedule?.is_price_contact ||
                                            (listBooking?.price_schedule?.is_price_contact &&
                                              group !== USER_GROUP.AGENT) ? (
                                              <Button
                                                className="btn-diagram"
                                                disabled={seatEntries?.status === 'hold'}
                                                onClick={() =>
                                                  handleCreateMultiBooking(selectedSeatNames, rowIndex, colIndex)
                                                }
                                              >
                                                <Tooltip title={'Đặt vé'} key={'bkt'} placement="right">
                                                  <PlusOutlined />
                                                </Tooltip>
                                              </Button>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {group !== USER_GROUP.AGENT &&
                                              (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                              ((!isOpenBus && listBooking?.trip?.status !== 'canceled') || isOpenBus) &&
                                              !(
                                                dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                                listBooking?.schedule?.open_before === 0
                                              ) && (
                                                <Button
                                                  className="btn-diagram"
                                                  disabled={seatEntries?.status === 'hold'}
                                                  onClick={() => {
                                                    if (
                                                      seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                                                      seatFloors.name
                                                    ) {
                                                      if (
                                                        !busTypeData?.[`floor${statusFloor}`][rowIndex][colIndex]
                                                          ?.selected
                                                      ) {
                                                        handleChangeTicket(rowIndex, colIndex);
                                                      }
                                                      handleLockBooking(seatFloors.name);
                                                    }
                                                  }}
                                                >
                                                  <Tooltip title={'Khóa ghế'} key={'lock3'} placement="right">
                                                    <LockOutlined />
                                                  </Tooltip>
                                                </Button>
                                              )}
                                            {(listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                              ((!isOpenBus && listBooking?.trip?.status !== 'canceled') || isOpenBus) &&
                                              !(
                                                dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                                listBooking?.schedule?.open_before === 0
                                              ) &&
                                              (group !== USER_GROUP.AGENT ||
                                                (group === USER_GROUP.AGENT &&
                                                  (searchDateBooking &&
                                                  listBooking?.schedule?.agent_seats_custom &&
                                                  listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                                    ? listBooking?.schedule?.agent_seats_custom[
                                                        searchDateBooking
                                                      ]?.includes(seatFloors?.name) &&
                                                      !listBooking?.booked_seat?.includes(seatFloors?.name)
                                                    : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name)) &&
                                                  !listBooking?.booked_seat?.includes(seatFloors?.name))) && (
                                                <>
                                                  {' '}
                                                  {!listBooking?.price_schedule?.is_price_contact ||
                                                  (listBooking?.price_schedule?.is_price_contact &&
                                                    group !== USER_GROUP.AGENT) ? (
                                                    <Button
                                                      className="btn-diagram"
                                                      onClick={() => {
                                                        if (
                                                          seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                                                          seatFloors.name
                                                        ) {
                                                          if (
                                                            !busTypeData?.[`floor${statusFloor}`][rowIndex][colIndex]
                                                              ?.selected
                                                          ) {
                                                            handleChangeTicket(rowIndex, colIndex);
                                                          }

                                                          handleCreateBooking(seatFloors.name, rowIndex, colIndex);
                                                        }
                                                      }}
                                                    >
                                                      <Tooltip title={'Đặt vé'} key={'bkt2'} placement="right">
                                                        <PlusOutlined />
                                                      </Tooltip>
                                                    </Button>
                                                  ) : (
                                                    ''
                                                  )}
                                                </>
                                              )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : seatFloors.type === Constant.DRIVER_SEAT ||
                                  seatFloors.type === Constant.EXTRA_DRIVER_SEAT ? (
                                  ''
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          }
                        />
                      </Col>
                    )
                  );
                })}
              </Row>
            );
          })}
        </>
      ) : (
        <div className="d-flex mt-3" style={{ columnGap: '24px' }}>
          <div className="w-50">
            <Typography className="title-box-floor1">Tầng 1</Typography>
            {listBooking?.schedule?.bus_type?.seat_map[`floor1`]?.map((rowSeats: any, rowIndex: number) => {
              const nonEmptySeatsCount = rowSeats.filter((seat: any) => seat.name || seat.type !== 0).length;
              const isSingleSeatRow = nonEmptySeatsCount === 1;

              return (
                <Row
                  gutter={[12, 12]}
                  key={rowIndex}
                  className="mt-2"
                  style={{ display: 'grid', gridTemplateColumns: `repeat(${nonEmptySeatsCount}, 1fr)` }}
                >
                  {rowSeats?.map((seatFloors: any, colIndex: number) => {
                    const seatEntries = listBooking?.booking?.data?.find(
                      (entry: any) => entry.seat_number === seatFloors.name,
                    );
                    const seatEntriesExpired = bookingData?.find((entry: any) => entry.seat_number === seatFloors.name);
                    const seatCustomerEntries = listBooking?.customers?.data?.find(
                      (entry: any) => entry.seat_number === seatFloors.name,
                    );
                    const seatBooking =
                      Array.isArray(listBooking?.schedule?.agent_seats) &&
                      listBooking?.schedule?.agent_seats?.includes(seatFloors?.name);
                    const selectedSeatNames = ['floor1', 'floor2'].flatMap((floor) =>
                      busTypeData?.[floor]?.flatMap(
                        (row: any) =>
                          !date.isAfter(listBooking?.travel_info?.depart_time, 'day') &&
                          row.filter((seat: any) => seat.selected).map((seat: any) => seat.name),
                      ),
                    );
                    if (seatEntriesExpired?.expired_at && seatEntriesExpired?.expired_at === +0) {
                      onCancelSuccess();
                    }
                    return (
                      (seatFloors.name || seatFloors.type !== Constant.DEFAULT_SEAT) && (
                        <Col
                          key={colIndex}
                          onClick={() =>
                            seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                            seatFloors.name &&
                            !seatEntries?.id &&
                            !(isMoveTicket && dataMoveTicket?.ticket_code) &&
                            handleChangeTicket(rowIndex, colIndex, 1)
                          }
                          ref={(el) => {
                            refs.current[seatEntries?.ticket_code] = el;
                          }}
                        >
                          <Checkbox
                            className="checkbox-diagram d-none"
                            checked={seatFloors.selected}
                            disabled={!(isMoveTicket && dataMoveTicket?.ticket_code)}
                          />
                          <Card
                            onClick={() =>
                              isMoveTicket &&
                              dataMoveTicket?.ticket_code &&
                              seatFloors?.type > 2 &&
                              (group !== USER_GROUP.AGENT ||
                                (group === USER_GROUP.AGENT &&
                                  (searchDateBooking &&
                                  listBooking?.schedule?.agent_seats_custom &&
                                  listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                    ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                        seatFloors?.name,
                                      ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                    : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                      !listBooking?.booked_seat?.includes(seatFloors?.name)))) &&
                              !seatEntries?.ticket_code &&
                              handleMoveTicket(
                                seatFloors?.name,
                                listBooking?.schedule?.id,
                                listBooking?.travel_info?.depart_date,
                                listBooking?.schedule?.depart_time,
                              )
                            }
                            className={`mt-1 ant-card-diagram diagram
                      ${isSingleSeatRow && 'w-30'}
                      ${
                        busTypeData &&
                        busTypeData?.[`floor1`] &&
                        busTypeData?.[`floor1`][rowIndex] &&
                        busTypeData?.[`floor1`][rowIndex][colIndex] &&
                        busTypeData?.[`floor1`][rowIndex][colIndex]?.name === seatFloors?.name &&
                        busTypeData?.[`floor1`][rowIndex][colIndex]?.selected &&
                        seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                        seatEntries?.status !== 'locked' &&
                        !seatEntries?.payment_method &&
                        (group !== USER_GROUP.AGENT ||
                          (group === USER_GROUP.AGENT &&
                          searchDateBooking &&
                          listBooking?.schedule?.agent_seats_custom &&
                          listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                            ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                seatFloors?.name,
                              ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                            : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                              !listBooking?.booked_seat?.includes(seatFloors?.name))) &&
                        (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                        ((!isOpenBus && listBooking?.trip?.status !== 'canceled') || isOpenBus) &&
                        !(
                          dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                          listBooking?.schedule?.open_before === 0
                        ) &&
                        !(isMoveTicket && dataMoveTicket?.ticket_code) &&
                        (!listBooking?.price_schedule?.is_price_contact ||
                          (listBooking?.price_schedule?.is_price_contact && group !== USER_GROUP.AGENT))
                          ? 'selected'
                          : ''
                      }
                           ${
                             isMoveTicket &&
                             seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                             (group !== USER_GROUP.AGENT ||
                               (group === USER_GROUP.AGENT &&
                                 (searchDateBooking &&
                                 listBooking?.schedule?.agent_seats_custom &&
                                 listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                   ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                       seatFloors?.name,
                                     ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                   : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                     !listBooking?.booked_seat?.includes(seatFloors?.name)))) &&
                             !seatEntries?.ticket_code
                               ? 'cursor-move'
                               : ''
                           }
                             ${
                               isMoveTicket && seatEntries?.ticket_code === dataMoveTicket?.ticket_code
                                 ? 'move-ticket'
                                 : ''
                             }
                           ${
                             (seatEntries?.source === 'openapi' || seatEntries?.source === 'company') &&
                             seatEntries?.status !== 'paid'
                               ? 'open_api'
                               : ''
                           }
                     ${
                       seatFloors.type === Constant.DRIVER_SEAT ||
                       seatFloors.type === Constant.EXTRA_DRIVER_SEAT ||
                       seatEntries?.status === 'locked' ||
                       ((group === USER_GROUP.AGENT &&
                         (searchDateBooking &&
                         listBooking?.schedule?.agent_seats_custom &&
                         listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                           ? !(
                               listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                 seatFloors?.name,
                               ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                             )
                           : !(
                               listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                               !listBooking?.booked_seat?.includes(seatFloors?.name)
                             ) || seatEntries?.id) &&
                         (listBooking?.trip?.status !== 'departed' || listBooking?.trip?.status !== 'canceled')) ||
                       dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                       listBooking?.schedule?.open_before === 0 ||
                       (listBooking?.price_schedule?.is_price_contact && group === USER_GROUP.AGENT)
                         ? ' ban'
                         : '')
                         ? 'driver'
                         : (seatEntries?.payment_method === 'reserve' ||
                             seatEntries?.payment_method === 'paid_at_company') &&
                           seatEntries?.status !== 'paid'
                         ? 'reserve'
                         : seatEntries?.payment_method === 'cash' ||
                           seatEntries?.payment_method === 'bank_transfer' ||
                           seatEntries?.payment_method === 'agent_balance' ||
                           seatEntries?.status === 'paid'
                         ? 'payment'
                         : ''
                     }${
                              ((
                                group === USER_GROUP.AGENT &&
                                searchDateBooking &&
                                listBooking?.schedule?.agent_seats_custom &&
                                listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                  ? !(
                                      listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                        seatFloors?.name,
                                      ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                    )
                                  : !(
                                      listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                      !listBooking?.booked_seat?.includes(seatFloors?.name)
                                    )
                              )
                                ? ' ban'
                                : '') && seatFloors?.type < Constant.EXTRA_DRIVER_SEAT
                                ? ' ban'
                                : ''
                            }
                                ${
                                  listBooking?.price_schedule?.is_price_contact && group === USER_GROUP.AGENT
                                    ? 'price-contact'
                                    : ''
                                }
                   `}
                            style={
                              seatFloors?.type === Constant.DRIVER_SEAT ||
                              seatFloors?.type === Constant.EXTRA_DRIVER_SEAT
                                ? {
                                    backgroundColor: 'transparent',
                                    minHeight: 'unset',
                                    height: '100px',
                                    // border: '2px solid #DCDCDC',
                                  }
                                : group === USER_GROUP.AGENT && seatEntries?.id
                                ? (seatEntries?.payment_method === 'reserve' ||
                                    seatEntries?.payment_method === 'paid_at_company') &&
                                  seatEntries?.status !== 'locked'
                                  ? { backgroundColor: '#ffe296' }
                                  : (seatEntries?.payment_method === 'cash' ||
                                      seatEntries?.payment_method === 'bank_transfer' ||
                                      seatEntries?.payment_method === 'agent_balance') &&
                                    seatEntries?.status !== 'locked'
                                  ? { backgroundColor: '#99d6a2' }
                                  : {}
                                : {}
                            }
                            cover={
                              <div
                                className={`bus-diagram-card ${
                                  seatFloors?.type < Constant.NORMAL_SEAT ? 'driver' : ''
                                }`}
                              >
                                {seatEntries?.status === 'locked' ? (
                                  <>
                                    <div className="header-card-diagram">
                                      <Typography className="fw-600 title-card">
                                        <Image
                                          src={
                                            typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image
                                          }
                                          alt=""
                                          height={32}
                                          preview={false}
                                          className={`img-type-seat`}
                                        />
                                        <span className="seat-name-booking d-flex items-center mb-0">
                                          {seatFloors?.name}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className="block-content">
                                      <Button
                                        className="btn btn-block"
                                        onClick={() => handleUnLockBooking(seatFloors?.name)}
                                      >
                                        <img src={IcLock} alt="" />
                                      </Button>
                                      <Typography className="fw-500 fs-16">Ghế này đã bị khoá!</Typography>
                                      <Typography className="fs-12 fw-400">{`Bị khóa bởi ${seatEntries?.orderer?.name}`}</Typography>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="header-card-diagram">
                                      <Typography
                                        className={`fw-600 ${
                                          seatEntries?.orderer?.name ? 'is-booking' : ''
                                        } title-card`}
                                      >
                                        <div
                                          className="title-seat-diagram"
                                          style={
                                            seatFloors?.type === Constant.DRIVER_SEAT ||
                                            seatFloors?.type === Constant.EXTRA_DRIVER_SEAT
                                              ? { flexDirection: 'row', alignItems: 'center' }
                                              : {}
                                          }
                                        >
                                          <Image
                                            src={
                                              typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image
                                            }
                                            alt=""
                                            height={32}
                                            preview={false}
                                            className={`img-type-seat w-fit`}
                                          />
                                          <p
                                            className={`seat-name-booking ${
                                              seatFloors.type > Constant.EXTRA_DRIVER_SEAT ? 'ticket' : ''
                                            } `}
                                            style={
                                              seatFloors.type === Constant.EXTRA_DRIVER_SEAT ||
                                              seatFloors.type === Constant.DRIVER_SEAT
                                                ? { marginBottom: 0 }
                                                : {}
                                            }
                                          >
                                            {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT ? '' : seatFloors?.name}
                                          </p>
                                          {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                            !seatEntries?.ticket_code && (
                                              <p className="seat-name-booking name-floor">{seatFloors?.name}</p>
                                            )}
                                        </div>
                                        {!listBooking?.contract?.code ? (
                                          <>
                                            {!seatEntries && seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                              <>
                                                <span className="seat-name-booking ">
                                                  {`${
                                                    seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                                                    typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)
                                                      ?.name
                                                  }`}
                                                  <br />
                                                </span>
                                              </>
                                            )}
                                            {!(
                                              (seatEntries?.status === 'reserve' || seatEntries?.status === 'hold') &&
                                              (seatEntries?.source === 'openapi' || seatEntries?.source === 'company')
                                            ) ? (
                                              <span className="seat-name-booking ticket-code">
                                                {seatEntries?.ticket_code
                                                  ? `${seatEntries?.ticket_code} - ${seatFloors?.name}`
                                                  : seatEntries?.ticket_code}
                                              </span>
                                            ) : (
                                              <span className="seat-name-booking ticket-code">{seatFloors?.name}</span>
                                            )}
                                          </>
                                        ) : (
                                          seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                            <p className={`seat-name-booking`}>{seatFloors?.name}</p>
                                          )
                                        )}
                                      </Typography>
                                    </div>
                                    {listBooking?.price_schedule?.is_price_contact &&
                                    group === USER_GROUP.AGENT &&
                                    seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                    ((listBooking?.trip?.status !== 'departed' &&
                                      listBooking?.trip?.status !== 'canceled') ||
                                      isOpenBus) &&
                                    !seatEntries ? (
                                      <>
                                        <div className="block-content mt-3">
                                          <img src={iconLockContact} alt="" />
                                          <Typography className="fw-400 fs-13 mt-1">
                                            Chỗ này đã bị khoá lý do:
                                          </Typography>
                                          <Typography className="fs-14 fw-500 text-blue">{`${listBooking?.price_schedule?.reason}`}</Typography>
                                        </div>
                                        <div
                                          style={{ padding: '4px 12px', background: '#009EF8' }}
                                          className="rounded-3 w-fit text-center m-auto mt-2"
                                        >
                                          <p className="fs-14 fw-600 text-white">Liên hệ đặt vé</p>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    <div className="block-info">
                                      <Typography className="fw-500 fs-14 mr-5 orderer-name">
                                        {seatEntries?.orderer?.name}
                                      </Typography>
                                      {seatFloors?.type === Constant.DRIVER_SEAT && (
                                        <>
                                          {listBooking?.trip?.drivers?.map((driver: any, index: number) => (
                                            <Typography className="fw-500 fs-16" key={index}>
                                              <span className="fw-600">{driver.name}</span> <br />
                                              <span> {driver.phone}</span>
                                            </Typography>
                                          ))}
                                        </>
                                      )}
                                      {seatFloors?.type === Constant.EXTRA_DRIVER_SEAT && (
                                        <>
                                          {listBooking?.trip?.extra_drivers?.map((driver: any, index: number) => (
                                            <Typography key={index} className="fw-500 fs-16">
                                              <span className="fw-600">{driver.name}</span> <br />
                                              <span>{driver.phone}</span>
                                            </Typography>
                                          ))}
                                        </>
                                      )}
                                      {((seatFloors?.status !== 0 && !seatFloors.locked) ||
                                        seatFloors?.type === Constant.DRIVER_SEAT ||
                                        (seatBooking && group !== USER_GROUP.AGENT) ||
                                        seatFloors?.type === Constant.EXTRA_DRIVER_SEAT) && (
                                        <>
                                          {seatEntries?.payment_method === 'reserve' ||
                                          seatEntries?.payment_method === 'paid_at_company' ||
                                          (seatEntries?.payment_method === 'bank_transfer' &&
                                            seatEntries?.source === 'openapi') ? (
                                            <div
                                              style={{ display: 'flex', justifyContent: 'space-between' }}
                                              className="time-hold-line"
                                            >
                                              <Typography className="fw-600 mt-1">
                                                {seatEntries?.customer?.name}
                                              </Typography>
                                              {seatEntriesExpired?.expired_at ? (
                                                <div className="fs-14 fw-500 time-hold mr-5">
                                                  <ClockCircleOutlined color="#435869" />
                                                  <CountdownComponent seatEntriesExpired={seatEntriesExpired} />
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          ) : (
                                            <Typography className="fw-600">{seatEntries?.customer?.name}</Typography>
                                          )}
                                          <Typography className="fw-500">
                                            {!(
                                              (seatEntries?.status === 'hold' || seatEntries?.status === 'reserve') &&
                                              seatEntries?.source === 'openapi'
                                            )
                                              ? seatEntries?.customer?.phone
                                              : 'Số điện thoại: N/A'}
                                          </Typography>
                                          {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                            <>
                                              <div
                                                className={`${seatEntries ? 'address-bus' : ''} fw-500 fs-14`}
                                                style={{ maxWidth: '90%' }}
                                              >
                                                <Typography className="fs-14">
                                                  {seatEntries?.pickup_location?.location_name
                                                    ? `${
                                                        seatEntries?.pickup_location?.location_name
                                                      } (${seatEntries?.pickup_location?.time?.slice(0, 5)}${
                                                        (seatEntries?.pickup_location?.diff_day ||
                                                          seatEntries?.drop_off_location?.diff_day) > 0
                                                          ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                              ?.add(seatEntries?.pickup_location?.diff_day, 'day')
                                                              ?.format(DateFormat)}`
                                                          : ''
                                                      })`
                                                    : seatEntries?.pickup_extra &&
                                                      ` ${
                                                        seatEntries?.pickup_extra
                                                      } (${listBooking?.schedule?.depart_time?.slice(0, 5)})`}
                                                </Typography>
                                                <Typography className="fs-14">
                                                  {seatEntries?.drop_off_location?.location_name
                                                    ? `${seatEntries?.drop_off_location?.location_name} (${
                                                        seatEntries?.drop_off_location?.time
                                                          ? seatEntries?.drop_off_location?.time?.slice(0, 5)
                                                          : listBooking?.schedule?.arrive_time?.slice(0, 5)
                                                      }${
                                                        (seatEntries?.pickup_location?.diff_day ||
                                                          seatEntries?.drop_off_location?.diff_day) > 0
                                                          ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                              ?.add(seatEntries?.drop_off_location?.diff_day, 'day')
                                                              ?.format(DateFormat)}`
                                                          : ''
                                                      })`
                                                    : seatEntries?.drop_off_extra &&
                                                      ` ${
                                                        seatEntries?.drop_off_extra
                                                      } (${listBooking?.schedule?.arrive_time?.slice(0, 5)})`}
                                                </Typography>
                                              </div>
                                              <div>
                                                <Typography className="fw-500 fs-14">
                                                  {`${seatEntries?.note ?? ''}`}
                                                </Typography>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      )}
                                      {seatFloors?.type < Constant.NORMAL_SEAT && (
                                        <Typography className="fw-600 mt-1">
                                          {seatCustomerEntries?.customer_name}
                                        </Typography>
                                      )}
                                    </div>
                                    <div className={`${seatEntries?.customer?.name ? '' : 'price-item'} mb-1 mt-1`}>
                                      <>
                                        {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                          (seatBooking ||
                                            !(group === USER_GROUP.AGENT &&
                                            searchDateBooking &&
                                            listBooking?.schedule?.agent_seats_custom &&
                                            listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                              ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                                  seatFloors?.name,
                                                ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                              : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                                !listBooking?.booked_seat?.includes(seatFloors?.name)) ||
                                            (group !== USER_GROUP.AGENT && seatEntries?.price)) &&
                                          !listBooking?.contract?.code &&
                                          (!listBooking?.price_schedule?.is_price_contact ||
                                            (listBooking?.price_schedule?.is_price_contact &&
                                              group !== USER_GROUP.AGENT)) && (
                                            <>
                                              <Typography className="fw-500 fs-14">
                                                {(() => {
                                                  const finalPrice = calculateFinalPrice(seatFloors);
                                                  if (finalPrice === null) return null;
                                                  const formattedPrice = finalPrice.toLocaleString('en-US');
                                                  return seatEntries?.source === 'openapi' ||
                                                    seatEntries?.source === 'company'
                                                    ? `Giá vé: ${seatEntries?.final_price}đ`
                                                    : seatEntries?.price
                                                    ? `${seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'} ${
                                                        seatEntries?.price
                                                      }VNĐ`
                                                    : `${
                                                        seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'
                                                      } ${formattedPrice} VNĐ`;
                                                })()}
                                              </Typography>
                                              {listBooking?.price_schedule?.is_price_contact &&
                                              seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                              !seatEntries ? (
                                                group !== USER_GROUP.AGENT ? (
                                                  <p className="fw-500 fs-13" style={{ color: '#D81717' }}>
                                                    Đang có chương trình giá liên hệ:{' '}
                                                    {listBooking?.price_schedule?.reason}{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )
                                              ) : (
                                                ''
                                              )}
                                              {listBooking?.price_schedule?.percent ||
                                              listBooking?.price_schedule?.price ? (
                                                <Typography
                                                  className={'fw-600 fs-12'}
                                                  style={
                                                    listBooking?.price_schedule?.percent ||
                                                    listBooking?.price_schedule?.price
                                                      ? { minWidth: '85%' }
                                                      : { height: 0 }
                                                  }
                                                >
                                                  {`${
                                                    listBooking?.price_schedule?.is_increase
                                                      ? 'Giá trên đã tăng: '
                                                      : 'Giá trên đã giảm'
                                                  } 
                                              ${
                                                listBooking?.price_schedule?.percent
                                                  ? `${listBooking?.price_schedule?.percent}%`
                                                  : `${formattedPrice(listBooking?.price_schedule?.price)}đ`
                                              }- ${listBooking?.price_schedule?.reason}`}
                                                </Typography>
                                              ) : (
                                                ''
                                              )}
                                            </>
                                          )}
                                      </>
                                      {seatFloors?.status !== 0 &&
                                        seatEntries &&
                                        ((seatEntries?.payment_method !== 'reserve' &&
                                          seatEntries?.payment_method !== 'paid_at_company') ||
                                          seatEntries?.status === 'paid') && (
                                          <>
                                            {seatEntries?.is_driver_pay ? (
                                              <div
                                                className="d-flex"
                                                style={{
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  columnGap: '4px',
                                                  maxWidth: '86%',
                                                }}
                                              >
                                                <img src={iconDriver} alt="" width={12} className="mt--5" />
                                                <Typography className="fw-500 fs-12 mt-1">
                                                  {`Tài xế đã thu bằng ${getPaymentMethod(
                                                    seatEntries?.payment_method,
                                                  )} ${
                                                    seatEntries?.final_price
                                                      ? formattedPrice(seatEntries?.final_price)
                                                      : 0
                                                  }VNĐ `}
                                                </Typography>
                                              </div>
                                            ) : (
                                              <>
                                                {(seatEntries?.orderer?.agent?.id || seatEntries?.payer) &&
                                                !listBooking?.contract?.id ? (
                                                  <div
                                                    className="d-flex mt-1"
                                                    style={{
                                                      alignItems: 'center',
                                                      columnGap: '4px',
                                                      maxWidth: '86%',
                                                    }}
                                                  >
                                                    <Typography className="fw-500 fs-12">
                                                      {(seatEntries?.source === 'openapi' ||
                                                        seatEntries?.source === 'company') &&
                                                      seatEntries?.status === 'reserve' ? (
                                                        ''
                                                      ) : (
                                                        <>
                                                          {seatEntries?.status === 'paid' &&
                                                          (seatEntries?.source === 'openapi' ||
                                                            seatEntries?.source === 'company') ? (
                                                            `Đã thanh toán cho ${seatEntries?.payer?.name}`
                                                          ) : (
                                                            <>
                                                              <img
                                                                src={
                                                                  seatEntries?.payer?.agent?.id
                                                                    ? iconAgent
                                                                    : iconCarGroup
                                                                }
                                                                alt=""
                                                                width={12}
                                                                className="mt--5"
                                                              />
                                                              {seatEntries?.payer?.name
                                                                ? seatEntries?.payer?.office_name
                                                                  ? `${seatEntries?.payer?.name}_${seatEntries?.payer?.office_name}`
                                                                  : seatEntries?.payer?.name
                                                                : seatEntries?.orderer?.name}
                                                              {seatEntries?.payer?.agent?.id
                                                                ? ' đã thanh toán bằng '
                                                                : ' đã thu bằng '}
                                                              {getPaymentMethod(seatEntries?.payment_method)}
                                                              {!listBooking?.contract?.code &&
                                                                `${formattedPrice(
                                                                  seatEntries?.final_price
                                                                    ? !seatEntries?.payer?.agent?.id &&
                                                                      seatEntries?.orderer?.agent?.id
                                                                      ? ` ${formattedPrice(seatEntries?.price)}`
                                                                      : ` ${formattedPrice(seatEntries?.final_price)}`
                                                                    : 0,
                                                                )}
                                                VNĐ`}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </Typography>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="fw-500 fs-12 d-flex"
                                                    style={{
                                                      maxWidth: '400px',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                      columnGap: '4px',
                                                      width: '85%',
                                                    }}
                                                  >
                                                    <img
                                                      src={seatEntries?.payer?.agent?.id ? iconAgent : iconCarGroup}
                                                      width={12}
                                                      alt=""
                                                    />
                                                    <Typography>
                                                      {`${seatEntries?.orderer?.name} ${
                                                        seatEntries?.payer?.agent?.id
                                                          ? 'đã thanh toán bằng '
                                                          : 'đã thu '
                                                      } ${getPaymentMethod(seatEntries?.payment_method)} ${
                                                        formattedPrice(seatEntries?.commission_price) ?? 0
                                                      }VNĐ ${
                                                        seatEntries?.payment_method === 'cash'
                                                          ? ' tiền mặt'
                                                          : ' chuyển khoản'
                                                      } tại ${seatEntries?.payer?.agent?.id ? `đại lý` : `nhà xe `}`}
                                                    </Typography>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                    </div>
                                  </>
                                )}
                                <div className="btn-diagram-block">
                                  {seatEntries?.status === 'locked' ? (
                                    ''
                                  ) : seatFloors?.type > Constant.EXTRA_DRIVER_SEAT ? (
                                    <>
                                      {seatEntries ? (
                                        <>
                                          <Button
                                            className="btn-diagram"
                                            onClick={() => getDetailBooking(seatFloors?.name, seatEntries?.ticket_code)}
                                          >
                                            <Tooltip title={'Chỉnh sửa'} key={'edt2'} placement="right">
                                              <img src={IcEditTicket} alt="" />
                                            </Tooltip>
                                          </Button>
                                          {!(
                                            (seatEntries?.source === 'openapi' || seatEntries?.source === 'company') &&
                                            seatEntries?.status !== 'paid'
                                          ) && (
                                            <>
                                              <Button
                                                className="btn-diagram"
                                                onClick={() =>
                                                  getMoveTicket(
                                                    seatFloors?.name,
                                                    seatEntries?.ticket_code,
                                                    listBooking?.schedule?.company?.id,
                                                    listBooking?.schedule?.id,
                                                  )
                                                }
                                                disabled={listBooking?.trip?.status !== 'not_departed'}
                                              >
                                                <Tooltip title={'Chuyển vé'} key={'cv2'} placement="right">
                                                  <img src={IcMoveTicket} alt="" />
                                                </Tooltip>
                                              </Button>
                                              {(seatEntries?.source !== 'openapi' ||
                                                seatEntries?.source === 'company') && (
                                                <>
                                                  <Button
                                                    className="btn-diagram"
                                                    onClick={() =>
                                                      handleModalPrint(
                                                        seatEntries,
                                                        seatFloors?.name,
                                                        seatEntries?.ticket_code,
                                                      )
                                                    }
                                                  >
                                                    <Tooltip title={'In vé'} key={'prt'} placement="right">
                                                      <img src={IcPrintTicket} alt="" />
                                                    </Tooltip>
                                                  </Button>

                                                  <Button
                                                    className="btn-diagram"
                                                    onClick={() => dispatch(getSendBookingMail(seatEntries?.id))}
                                                  >
                                                    <Tooltip title={'Gửi mail'} key={'eml'} placement="right">
                                                      <img src={IcSendMail} alt="" />
                                                    </Tooltip>
                                                  </Button>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {selectedSeatNames?.length > 1 &&
                                          selectedSeatNames.find((selected: any) => selected === seatFloors?.name) &&
                                          (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                          ((!isOpenBus && listBooking?.trip?.status !== 'canceled') || isOpenBus) &&
                                          !(
                                            dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                            listBooking?.schedule?.open_before === 0
                                          ) &&
                                          (group !== USER_GROUP.AGENT ||
                                            (group === USER_GROUP.AGENT &&
                                              (searchDateBooking &&
                                              listBooking?.schedule?.agent_seats_custom &&
                                              listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                                ? listBooking?.schedule?.agent_seats_custom[
                                                    searchDateBooking
                                                  ]?.includes(seatFloors?.name) &&
                                                  !listBooking?.booked_seat?.includes(seatFloors?.name)
                                                : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                                  !listBooking?.booked_seat?.includes(seatFloors?.name)))) ? (
                                            <>
                                              {group !== USER_GROUP.AGENT && (
                                                <Button
                                                  className="btn-diagram"
                                                  disabled={seatEntries?.status === 'hold'}
                                                  onClick={() => handleLockMultiBooking(selectedSeatNames)}
                                                >
                                                  <Tooltip title={'Khóa ghế'} key={'bkt-mt'} placement="right">
                                                    <LockOutlined />
                                                  </Tooltip>
                                                </Button>
                                              )}
                                              {!listBooking?.price_schedule?.is_price_contact ||
                                              (listBooking?.price_schedule?.is_price_contact &&
                                                group !== USER_GROUP.AGENT) ? (
                                                <Button
                                                  className="btn-diagram"
                                                  disabled={seatEntries?.status === 'hold'}
                                                  onClick={() =>
                                                    handleCreateMultiBooking(selectedSeatNames, rowIndex, colIndex)
                                                  }
                                                >
                                                  <Tooltip title={'Đặt vé'} key={'bkt'} placement="right">
                                                    <PlusOutlined />
                                                  </Tooltip>
                                                </Button>
                                              ) : (
                                                ''
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {group !== USER_GROUP.AGENT &&
                                                (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                                ((!isOpenBus && listBooking?.trip?.status !== 'canceled') ||
                                                  isOpenBus) &&
                                                !(
                                                  dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                                  listBooking?.schedule?.open_before === 0
                                                ) && (
                                                  <Button
                                                    className="btn-diagram"
                                                    disabled={seatEntries?.status === 'hold'}
                                                    onClick={() => {
                                                      if (
                                                        seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                                                        seatFloors.name
                                                      ) {
                                                        if (!busTypeData?.[`floor1`][rowIndex][colIndex]?.selected) {
                                                          handleChangeTicket(rowIndex, colIndex, 1);
                                                        }
                                                        handleLockBooking(seatFloors.name);
                                                      }
                                                    }}
                                                  >
                                                    <Tooltip title={'Khóa ghế'} key={'lock3'} placement="right">
                                                      <LockOutlined />
                                                    </Tooltip>
                                                  </Button>
                                                )}
                                              {(listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                                ((!isOpenBus && listBooking?.trip?.status !== 'canceled') ||
                                                  isOpenBus) &&
                                                !(
                                                  dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                                  listBooking?.schedule?.open_before === 0
                                                ) &&
                                                (group !== USER_GROUP.AGENT ||
                                                  (group === USER_GROUP.AGENT &&
                                                    (searchDateBooking &&
                                                    listBooking?.schedule?.agent_seats_custom &&
                                                    listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                                      ? listBooking?.schedule?.agent_seats_custom[
                                                          searchDateBooking
                                                        ]?.includes(seatFloors?.name) &&
                                                        !listBooking?.booked_seat?.includes(seatFloors?.name)
                                                      : listBooking?.schedule?.agent_seats?.includes(
                                                          seatFloors?.name,
                                                        )) &&
                                                    !listBooking?.booked_seat?.includes(seatFloors?.name))) && (
                                                  <>
                                                    {!listBooking?.price_schedule?.is_price_contact ||
                                                    (listBooking?.price_schedule?.is_price_contact &&
                                                      group !== USER_GROUP.AGENT) ? (
                                                      <Button
                                                        className="btn-diagram"
                                                        onClick={() => {
                                                          if (
                                                            seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                                                            seatFloors.name
                                                          ) {
                                                            if (
                                                              !busTypeData?.[`floor1`][rowIndex][colIndex]?.selected
                                                            ) {
                                                              handleChangeTicket(rowIndex, colIndex, 1);
                                                            }

                                                            handleCreateBooking(seatFloors.name, rowIndex, colIndex);
                                                          }
                                                        }}
                                                      >
                                                        <Tooltip title={'Đặt vé'} key={'bkt2'} placement="right">
                                                          <PlusOutlined />
                                                        </Tooltip>
                                                      </Button>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : seatFloors.type === Constant.DRIVER_SEAT ||
                                    seatFloors.type === Constant.EXTRA_DRIVER_SEAT ? (
                                    ''
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            }
                          />
                        </Col>
                      )
                    );
                  })}
                </Row>
              );
            })}
          </div>
          <div className="w-50">
            <Typography className="title-box-floor2">Tầng 2</Typography>
            {listBooking?.schedule?.bus_type?.seat_map[`floor2`]?.map((rowSeats: any, rowIndex: number) => {
              const nonEmptySeatsCount = rowSeats.filter((seat: any) => seat.name || seat.type !== 0).length;
              const isSingleSeatRow = nonEmptySeatsCount === 1;
              return (
                <Row
                  gutter={[12, 12]}
                  key={rowIndex}
                  style={
                    rowIndex === 0
                      ? {
                          display: 'grid',
                          gridTemplateColumns: `repeat(${nonEmptySeatsCount}, 1fr)`,
                          marginTop: '-2px',
                        }
                      : {
                          display: 'grid',
                          gridTemplateColumns: `repeat(${nonEmptySeatsCount}, 1fr)`,
                        }
                  }
                  className={rowIndex !== 0 ? 'mt-2' : ''}
                >
                  {rowSeats?.map((seatFloors: any, colIndex: number) => {
                    const seatEntries = listBooking?.booking?.data?.find(
                      (entry: any) => entry.seat_number === seatFloors.name,
                    );
                    const seatEntriesExpired = bookingData?.find((entry: any) => entry.seat_number === seatFloors.name);
                    const seatCustomerEntries = listBooking?.customers?.data?.find(
                      (entry: any) => entry.seat_number === seatFloors.name,
                    );
                    const seatBooking =
                      Array.isArray(listBooking?.schedule?.agent_seats) &&
                      listBooking?.schedule?.agent_seats?.includes(seatFloors?.name);
                    const selectedSeatNames = ['floor1', 'floor2'].flatMap((floor) =>
                      busTypeData?.[floor]?.flatMap(
                        (row: any) =>
                          !date.isAfter(listBooking?.travel_info?.depart_time, 'day') &&
                          row.filter((seat: any) => seat.selected).map((seat: any) => seat.name),
                      ),
                    );
                    if (seatEntriesExpired?.expired_at && seatEntriesExpired?.expired_at === +0) {
                      onCancelSuccess();
                    }
                    return (
                      (seatFloors.name || seatFloors.type !== Constant.DEFAULT_SEAT) && (
                        <Col
                          key={colIndex}
                          onClick={() =>
                            seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                            seatFloors.name &&
                            !seatEntries?.id &&
                            !(isMoveTicket && dataMoveTicket?.ticket_code) &&
                            handleChangeTicket(rowIndex, colIndex, 2)
                          }
                          ref={(el) => {
                            refs.current[seatEntries?.ticket_code] = el;
                          }}
                        >
                          <Checkbox className="checkbox-diagram d-none" checked={seatFloors.selected} />
                          <Card
                            onClick={() =>
                              isMoveTicket &&
                              dataMoveTicket?.ticket_code &&
                              seatFloors?.type > 2 &&
                              (group !== USER_GROUP.AGENT ||
                                (group === USER_GROUP.AGENT &&
                                  (searchDateBooking &&
                                  listBooking?.schedule?.agent_seats_custom &&
                                  listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                    ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                        seatFloors?.name,
                                      ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                    : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                      !listBooking?.booked_seat?.includes(seatFloors?.name)))) &&
                              !seatEntries?.ticket_code &&
                              handleMoveTicket(
                                seatFloors?.name,
                                listBooking?.schedule?.id,
                                listBooking?.travel_info?.depart_date,
                                listBooking?.schedule?.depart_time,
                              )
                            }
                            className={`mt-1 ant-card-diagram diagram
                      ${isSingleSeatRow && 'w-30'}
                      ${
                        busTypeData &&
                        busTypeData?.[`floor2`] &&
                        busTypeData?.[`floor2`][rowIndex] &&
                        busTypeData?.[`floor2`][rowIndex][colIndex] &&
                        busTypeData?.[`floor2`][rowIndex][colIndex]?.name === seatFloors?.name &&
                        busTypeData?.[`floor2`][rowIndex][colIndex]?.selected &&
                        seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                        seatEntries?.status !== 'locked' &&
                        !seatEntries?.payment_method &&
                        (group !== USER_GROUP.AGENT ||
                          (group === USER_GROUP.AGENT &&
                          searchDateBooking &&
                          listBooking?.schedule?.agent_seats_custom &&
                          listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                            ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                seatFloors?.name,
                              ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                            : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                              !listBooking?.booked_seat?.includes(seatFloors?.name))) &&
                        (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                        ((!isOpenBus && listBooking?.trip?.status !== 'canceled') || isOpenBus) &&
                        !(
                          dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                          listBooking?.schedule?.open_before === 0
                        ) &&
                        !(isMoveTicket && dataMoveTicket?.ticket_code) &&
                        (!listBooking?.price_schedule?.is_price_contact ||
                          (listBooking?.price_schedule?.is_price_contact && group !== USER_GROUP.AGENT))
                          ? 'selected'
                          : ''
                      }
                           ${
                             isMoveTicket &&
                             seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                             (group !== USER_GROUP.AGENT ||
                               (group === USER_GROUP.AGENT &&
                                 (searchDateBooking &&
                                 listBooking?.schedule?.agent_seats_custom &&
                                 listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                   ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                       seatFloors?.name,
                                     ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                   : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                     !listBooking?.booked_seat?.includes(seatFloors?.name)))) &&
                             !seatEntries?.ticket_code
                               ? 'cursor-move'
                               : ''
                           }
                            ${
                              isMoveTicket && seatEntries?.ticket_code === dataMoveTicket?.ticket_code
                                ? 'move-ticket'
                                : ''
                            }
                          ${
                            (seatEntries?.source === 'openapi' || seatEntries?.source === 'company') &&
                            seatEntries?.status !== 'paid'
                              ? 'open_api'
                              : ''
                          }
                     ${
                       seatFloors.type === Constant.DRIVER_SEAT ||
                       seatFloors.type === Constant.EXTRA_DRIVER_SEAT ||
                       seatEntries?.status === 'locked' ||
                       ((group === USER_GROUP.AGENT &&
                         (searchDateBooking &&
                         listBooking?.schedule?.agent_seats_custom &&
                         listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                           ? !(
                               listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                 seatFloors?.name,
                               ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                             )
                           : !(
                               listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                               !listBooking?.booked_seat?.includes(seatFloors?.name)
                             ) || seatEntries?.id) &&
                         (listBooking?.trip?.status !== 'departed' || listBooking?.trip?.status !== 'canceled')) ||
                       dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                       listBooking?.schedule?.open_before === 0 ||
                       (listBooking?.price_schedule?.is_price_contact && group === USER_GROUP.AGENT)
                         ? ' ban'
                         : '')
                         ? 'driver'
                         : (seatEntries?.payment_method === 'reserve' ||
                             seatEntries?.payment_method === 'paid_at_company') &&
                           seatEntries?.status !== 'paid'
                         ? 'reserve'
                         : seatEntries?.payment_method === 'cash' ||
                           seatEntries?.payment_method === 'bank_transfer' ||
                           seatEntries?.payment_method === 'agent_balance' ||
                           seatEntries?.status === 'paid'
                         ? 'payment'
                         : ''
                     }${
                              ((
                                group === USER_GROUP.AGENT &&
                                searchDateBooking &&
                                listBooking?.schedule?.agent_seats_custom &&
                                listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                  ? !(
                                      listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                        seatFloors?.name,
                                      ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                    )
                                  : !(
                                      listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                      !listBooking?.booked_seat?.includes(seatFloors?.name)
                                    )
                              )
                                ? ' ban'
                                : '') && seatFloors?.type < Constant.EXTRA_DRIVER_SEAT
                                ? ' ban'
                                : ''
                            }
                                ${
                                  listBooking?.price_schedule?.is_price_contact && group === USER_GROUP.AGENT
                                    ? 'price-contact'
                                    : ''
                                }
                   `}
                            style={
                              seatFloors?.type === Constant.DRIVER_SEAT ||
                              seatFloors?.type === Constant.EXTRA_DRIVER_SEAT
                                ? {
                                    backgroundColor: 'transparent',
                                    minHeight: 'unset',
                                    height: '100px',
                                    // border: '2px solid #DCDCDC',
                                  }
                                : group === USER_GROUP.AGENT && seatEntries?.id
                                ? (seatEntries?.payment_method === 'reserve' ||
                                    seatEntries?.payment_method === 'paid_at_company') &&
                                  seatEntries?.status !== 'locked'
                                  ? { backgroundColor: '#ffe296' }
                                  : (seatEntries?.payment_method === 'cash' ||
                                      seatEntries?.payment_method === 'bank_transfer' ||
                                      seatEntries?.payment_method === 'agent_balance') &&
                                    seatEntries?.status !== 'locked'
                                  ? { backgroundColor: '#99d6a2' }
                                  : {}
                                : {}
                            }
                            cover={
                              <div
                                className={`bus-diagram-card ${
                                  seatFloors?.type < Constant.NORMAL_SEAT ? 'driver' : ''
                                }`}
                              >
                                {seatEntries?.status === 'locked' ? (
                                  <>
                                    <div className="header-card-diagram">
                                      <Typography className="fw-600 title-card">
                                        <Image
                                          src={
                                            typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image
                                          }
                                          alt=""
                                          height={32}
                                          preview={false}
                                          className={`img-type-seat`}
                                        />
                                        <span className="seat-name-booking d-flex items-center mb-0">
                                          {seatFloors.name}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className="block-content">
                                      <Button
                                        className="btn btn-block"
                                        onClick={() => handleUnLockBooking(seatFloors?.name)}
                                      >
                                        <img src={IcLock} alt="" />
                                      </Button>
                                      <Typography className="fw-500 fs-16">Ghế này đã bị khoá!</Typography>
                                      <Typography className="fs-12 fw-400">{`Bị khóa bởi ${seatEntries?.orderer?.name}`}</Typography>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="header-card-diagram">
                                      <Typography
                                        className={`fw-600 ${
                                          seatEntries?.orderer?.name ? 'is-booking' : ''
                                        } title-card`}
                                      >
                                        <div
                                          className="title-seat-diagram"
                                          style={
                                            seatFloors?.type === Constant.DRIVER_SEAT ||
                                            seatFloors?.type === Constant.EXTRA_DRIVER_SEAT
                                              ? { flexDirection: 'row', alignItems: 'center' }
                                              : {}
                                          }
                                        >
                                          <Image
                                            src={
                                              typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image
                                            }
                                            alt=""
                                            height={32}
                                            preview={false}
                                            className={`img-type-seat w-fit`}
                                          />
                                          <p
                                            className={`seat-name-booking ${
                                              seatFloors.type > Constant.EXTRA_DRIVER_SEAT ? 'ticket' : ''
                                            } `}
                                            style={
                                              seatFloors.type === Constant.EXTRA_DRIVER_SEAT ||
                                              seatFloors.type === Constant.DRIVER_SEAT
                                                ? { marginBottom: 0 }
                                                : {}
                                            }
                                          >
                                            {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT ? '' : seatFloors?.name}
                                          </p>
                                          {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                            !seatEntries?.ticket_code && (
                                              <p className="seat-name-booking name-floor">{seatFloors?.name}</p>
                                            )}
                                        </div>
                                        {!listBooking?.contract?.code ? (
                                          <>
                                            {!seatEntries?.ticket_code &&
                                            seatFloors.type > Constant.EXTRA_DRIVER_SEAT ? (
                                              <span className="seat-name-booking ">
                                                {`${
                                                  seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                                                  typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)
                                                    ?.name
                                                }`}
                                              </span>
                                            ) : (
                                              <>
                                                {!(
                                                  (seatEntries?.status === 'hold' ||
                                                    seatEntries?.status === 'reserve') &&
                                                  (seatEntries?.source === 'openapi' ||
                                                    seatEntries?.source === 'company')
                                                ) ? (
                                                  <span className="seat-name-booking ticket-code">
                                                    {seatEntries?.ticket_code
                                                      ? `${seatEntries?.ticket_code} - ${seatFloors?.name}`
                                                      : seatEntries?.ticket_code}
                                                  </span>
                                                ) : (
                                                  <span className="seat-name-booking ticket-code">
                                                    {seatFloors?.name}
                                                  </span>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                            <p className={`seat-name-booking`}>{seatFloors?.name}</p>
                                          )
                                        )}
                                      </Typography>
                                    </div>
                                    {listBooking?.price_schedule?.is_price_contact &&
                                    group === USER_GROUP.AGENT &&
                                    seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                    ((listBooking?.trip?.status !== 'departed' &&
                                      listBooking?.trip?.status !== 'canceled') ||
                                      isOpenBus) &&
                                    !seatEntries ? (
                                      <>
                                        <div className="block-content mt-3">
                                          <img src={iconLockContact} alt="" />
                                          <Typography className="fw-400 fs-13 mt-1">
                                            Chỗ này đã bị khoá lý do:
                                          </Typography>
                                          <Typography className="fs-14 fw-500 text-blue">{`${listBooking?.price_schedule?.reason}`}</Typography>
                                        </div>
                                        <div
                                          style={{ padding: '4px 12px', background: '#009EF8' }}
                                          className="rounded-3 w-fit text-center m-auto mt-2"
                                        >
                                          <p className="fs-14 fw-600 text-white">Liên hệ đặt vé</p>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    <div className="block-info">
                                      <Typography className="fw-500 fs-14 mr-5 orderer-name">
                                        {seatEntries?.orderer?.name}
                                      </Typography>
                                      {seatFloors?.type === Constant.DRIVER_SEAT && (
                                        <>
                                          {listBooking?.trip?.drivers?.map((driver: any, index: number) => (
                                            <Typography className="fw-500 fs-16" key={index}>
                                              <span className="fw-600">{driver.name}</span> <br />
                                              <span> {driver.phone}</span>
                                            </Typography>
                                          ))}
                                        </>
                                      )}
                                      {seatFloors?.type === Constant.EXTRA_DRIVER_SEAT && (
                                        <>
                                          {listBooking?.trip?.extra_drivers?.map((driver: any, index: number) => (
                                            <Typography key={index} className="fw-500 fs-16">
                                              <span className="fw-600">{driver.name}</span> <br />
                                              <span>{driver.phone}</span>
                                            </Typography>
                                          ))}
                                        </>
                                      )}
                                      {((seatFloors?.status !== 0 && !seatFloors.locked) ||
                                        seatFloors?.type === Constant.DRIVER_SEAT ||
                                        (seatBooking && group !== USER_GROUP.AGENT) ||
                                        seatFloors?.type === Constant.EXTRA_DRIVER_SEAT) && (
                                        <>
                                          {seatEntries?.payment_method === 'reserve' ||
                                          seatEntries?.payment_method === 'paid_at_company' ||
                                          (seatEntries?.payment_method === 'bank_transfer' &&
                                            seatEntries?.source === 'openapi') ? (
                                            <div
                                              style={{ display: 'flex', justifyContent: 'space-between' }}
                                              className="time-hold-line"
                                            >
                                              <Typography className="fw-600 mt-1">
                                                {seatEntries?.customer?.name}
                                              </Typography>
                                              {seatEntriesExpired?.expired_at ? (
                                                <div className="fs-14 fw-500 time-hold mr-5">
                                                  <ClockCircleOutlined color="#435869" />
                                                  <CountdownComponent seatEntriesExpired={seatEntriesExpired} />
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          ) : (
                                            <Typography className="fw-600">{seatEntries?.customer?.name}</Typography>
                                          )}
                                          <Typography className="fw-500">
                                            {!(
                                              (seatEntries?.status === 'hold' || seatEntries?.status === 'reserve') &&
                                              seatEntries?.source === 'openapi'
                                            )
                                              ? seatEntries?.customer?.phone
                                              : 'Số điện thoại: N/A'}
                                          </Typography>
                                          {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT && (
                                            <>
                                              <div
                                                className={`${seatEntries ? 'address-bus' : ''} fw-500 fs-14`}
                                                style={{ maxWidth: '90%' }}
                                              >
                                                <Typography className="fs-14">
                                                  {seatEntries?.pickup_location?.location_name
                                                    ? `${
                                                        seatEntries?.pickup_location?.location_name
                                                      } (${seatEntries?.pickup_location?.time?.slice(0, 5)}${
                                                        (seatEntries?.pickup_location?.diff_day ||
                                                          seatEntries?.drop_off_location?.diff_day) > 0
                                                          ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                              ?.add(seatEntries?.pickup_location?.diff_day, 'day')
                                                              ?.format(DateFormat)}`
                                                          : ''
                                                      })`
                                                    : seatEntries?.pickup_extra &&
                                                      ` ${
                                                        seatEntries?.pickup_extra
                                                      } (${listBooking?.schedule?.depart_time?.slice(0, 5)})`}
                                                </Typography>
                                                <Typography className="fs-14">
                                                  {seatEntries?.drop_off_location?.location_name
                                                    ? `${seatEntries?.drop_off_location?.location_name} (${
                                                        seatEntries?.drop_off_location?.time
                                                          ? seatEntries?.drop_off_location?.time?.slice(0, 5)
                                                          : listBooking?.schedule?.arrive_time?.slice(0, 5)
                                                      }${
                                                        (seatEntries?.pickup_location?.diff_day ||
                                                          seatEntries?.drop_off_location?.diff_day) > 0
                                                          ? ` - ${dayjs(listBooking?.travel_info?.depart_date)
                                                              ?.add(seatEntries?.drop_off_location?.diff_day, 'day')
                                                              ?.format(DateFormat)}`
                                                          : ''
                                                      })`
                                                    : seatEntries?.drop_off_extra &&
                                                      ` ${
                                                        seatEntries?.drop_off_extra
                                                      } (${listBooking?.schedule?.arrive_time?.slice(0, 5)})`}
                                                </Typography>
                                              </div>
                                              <div>
                                                <Typography className="fw-500 fs-14">
                                                  {`${seatEntries?.note ?? ''}`}
                                                </Typography>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      )}
                                      {seatFloors?.type < Constant.NORMAL_SEAT && (
                                        <Typography className="fw-600 mt-1">
                                          {seatCustomerEntries?.customer_name}
                                        </Typography>
                                      )}
                                    </div>
                                    <div className={`${seatEntries?.customer?.name ? '' : 'price-item'} mb-1 mt-1`}>
                                      <>
                                        {seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                          (seatBooking ||
                                            !(group === USER_GROUP.AGENT &&
                                            searchDateBooking &&
                                            listBooking?.schedule?.agent_seats_custom &&
                                            listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                              ? listBooking?.schedule?.agent_seats_custom[searchDateBooking]?.includes(
                                                  seatFloors?.name,
                                                ) && !listBooking?.booked_seat?.includes(seatFloors?.name)
                                              : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                                !listBooking?.booked_seat?.includes(seatFloors?.name)) ||
                                            (group !== USER_GROUP.AGENT && seatEntries?.price)) &&
                                          !listBooking?.contract?.code &&
                                          (!listBooking?.price_schedule?.is_price_contact ||
                                            (listBooking?.price_schedule?.is_price_contact &&
                                              group !== USER_GROUP.AGENT)) && (
                                            <>
                                              <Typography className="fw-500 fs-14">
                                                {(() => {
                                                  const finalPrice = calculateFinalPrice(seatFloors);
                                                  if (finalPrice === null) return null;
                                                  const formattedPrice = finalPrice.toLocaleString('en-US');
                                                  return seatEntries?.source === 'openapi' ||
                                                    seatEntries?.source === 'company'
                                                    ? `Giá vé: ${seatEntries?.final_price}đ`
                                                    : seatEntries?.price
                                                    ? `${seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'} ${
                                                        seatEntries?.price
                                                      }VNĐ`
                                                    : `${
                                                        seatEntries?.orderer?.agent?.id ? 'Giá vé:' : 'Giá vé:'
                                                      } ${formattedPrice} VNĐ`;
                                                })()}
                                              </Typography>
                                              {listBooking?.price_schedule?.is_price_contact &&
                                              seatFloors?.type > Constant.EXTRA_DRIVER_SEAT &&
                                              !seatEntries ? (
                                                group !== USER_GROUP.AGENT ? (
                                                  <p className="fw-500 fs-13" style={{ color: '#D81717' }}>
                                                    Đang có chương trình giá liên hệ:{' '}
                                                    {listBooking?.price_schedule?.reason}{' '}
                                                  </p>
                                                ) : (
                                                  ''
                                                )
                                              ) : (
                                                ''
                                              )}
                                              {listBooking?.price_schedule?.percent ||
                                              listBooking?.price_schedule?.price ? (
                                                <Typography
                                                  className={'fw-600 fs-12'}
                                                  style={
                                                    listBooking?.price_schedule?.percent ||
                                                    listBooking?.price_schedule?.price
                                                      ? { minWidth: '85%' }
                                                      : { height: 0 }
                                                  }
                                                >
                                                  {`${
                                                    listBooking?.price_schedule?.is_increase
                                                      ? 'Giá trên đã tăng: '
                                                      : 'Giá trên đã giảm'
                                                  } 
                                              ${
                                                listBooking?.price_schedule?.percent
                                                  ? `${listBooking?.price_schedule?.percent}%`
                                                  : `${formattedPrice(listBooking?.price_schedule?.price)}đ`
                                              }- ${listBooking?.price_schedule?.reason}`}
                                                </Typography>
                                              ) : (
                                                ''
                                              )}
                                            </>
                                          )}
                                      </>
                                      {seatFloors?.status !== 0 &&
                                        seatEntries &&
                                        ((seatEntries?.payment_method !== 'reserve' &&
                                          seatEntries?.payment_method !== 'paid_at_company') ||
                                          seatEntries?.status === 'paid') && (
                                          <>
                                            {seatEntries?.is_driver_pay ? (
                                              <div
                                                className="d-flex"
                                                style={{
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  columnGap: '4px',
                                                  maxWidth: '86%',
                                                }}
                                              >
                                                <img src={iconDriver} alt="" width={12} className="mt--5" />
                                                <Typography className="fw-500 fs-12 mt-1">
                                                  {`Tài xế đã thu bằng ${getPaymentMethod(
                                                    seatEntries?.payment_method,
                                                  )} ${
                                                    seatEntries?.final_price
                                                      ? formattedPrice(seatEntries?.final_price)
                                                      : 0
                                                  }VNĐ `}
                                                </Typography>
                                              </div>
                                            ) : (
                                              <>
                                                {(seatEntries?.orderer?.agent?.id || seatEntries?.payer) &&
                                                !listBooking?.contract?.id ? (
                                                  <div
                                                    className="d-flex mt-1"
                                                    style={{
                                                      alignItems: 'center',
                                                      columnGap: '4px',
                                                      maxWidth: '86%',
                                                    }}
                                                  >
                                                    <Typography className="fw-500 fs-12">
                                                      {(seatEntries?.source === 'openapi' ||
                                                        seatEntries?.source === 'company') &&
                                                      seatEntries?.status === 'reserve' ? (
                                                        ''
                                                      ) : (
                                                        <>
                                                          {seatEntries?.status === 'paid' &&
                                                          (seatEntries?.source === 'openapi' ||
                                                            seatEntries?.source === 'company') ? (
                                                            `Đã thanh toán cho ${seatEntries?.payer?.name}`
                                                          ) : (
                                                            <>
                                                              <img
                                                                src={
                                                                  seatEntries?.payer?.agent?.id
                                                                    ? iconAgent
                                                                    : iconCarGroup
                                                                }
                                                                alt=""
                                                                width={12}
                                                                className="mt--5"
                                                              />
                                                              {seatEntries?.payer?.name
                                                                ? seatEntries?.payer?.office_name
                                                                  ? `${seatEntries?.payer?.name}_${seatEntries?.payer?.office_name}`
                                                                  : seatEntries?.payer?.name
                                                                : seatEntries?.orderer?.name}
                                                              {seatEntries?.payer?.agent?.id
                                                                ? ' đã thanh toán bằng '
                                                                : ' đã thu bằng '}
                                                              {getPaymentMethod(seatEntries?.payment_method)}
                                                              {!listBooking?.contract?.code &&
                                                                `${formattedPrice(
                                                                  seatEntries?.final_price
                                                                    ? !seatEntries?.payer?.agent?.id &&
                                                                      seatEntries?.orderer?.agent?.id
                                                                      ? ` ${formattedPrice(seatEntries?.price)}`
                                                                      : ` ${formattedPrice(seatEntries?.final_price)}`
                                                                    : 0,
                                                                )}
                                                VNĐ`}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </Typography>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="fw-500 fs-12 d-flex"
                                                    style={{
                                                      maxWidth: '400px',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                      columnGap: '4px',
                                                      width: '85%',
                                                    }}
                                                  >
                                                    <img
                                                      src={seatEntries?.payer?.agent?.id ? iconAgent : iconCarGroup}
                                                      width={12}
                                                      alt=""
                                                    />
                                                    <Typography>
                                                      {`${seatEntries?.orderer?.name} ${
                                                        seatEntries?.payer?.agent?.id
                                                          ? 'đã thanh toán bằng '
                                                          : 'đã thu '
                                                      } ${getPaymentMethod(seatEntries?.payment_method)} ${
                                                        formattedPrice(seatEntries?.commission_price) ?? 0
                                                      }VNĐ ${
                                                        seatEntries?.payment_method === 'cash'
                                                          ? ' tiền mặt'
                                                          : ' chuyển khoản'
                                                      } tại ${seatEntries?.payer?.agent?.id ? `đại lý` : `nhà xe `}`}
                                                    </Typography>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                    </div>
                                  </>
                                )}
                                <div className="btn-diagram-block">
                                  {seatEntries?.status === 'locked' ? (
                                    ''
                                  ) : seatFloors?.type > Constant.EXTRA_DRIVER_SEAT ? (
                                    <>
                                      {seatEntries ? (
                                        <>
                                          <Button
                                            className="btn-diagram"
                                            onClick={() => getDetailBooking(seatFloors?.name, seatEntries?.ticket_code)}
                                          >
                                            <Tooltip title={'Chỉnh sửa'} key={'edt2'} placement="right">
                                              <img src={IcEditTicket} alt="" />
                                            </Tooltip>
                                          </Button>
                                          {!(
                                            (seatEntries?.source === 'openapi' || seatEntries?.source === 'company') &&
                                            seatEntries?.status !== 'paid'
                                          ) && (
                                            <>
                                              <Button
                                                className="btn-diagram"
                                                onClick={() =>
                                                  getMoveTicket(
                                                    seatFloors?.name,
                                                    seatEntries?.ticket_code,
                                                    listBooking?.schedule?.company?.id,
                                                    listBooking?.schedule?.id,
                                                  )
                                                }
                                                disabled={listBooking?.trip?.status !== 'not_departed'}
                                              >
                                                <Tooltip title={'Chuyển vé'} key={'cv2'} placement="right">
                                                  <img src={IcMoveTicket} alt="" />
                                                </Tooltip>
                                              </Button>
                                              {seatEntries?.source !== 'openapi' &&
                                                seatEntries?.source !== 'company' && (
                                                  <>
                                                    <Button
                                                      className="btn-diagram"
                                                      onClick={() =>
                                                        handleModalPrint(
                                                          seatEntries,
                                                          seatFloors?.name,
                                                          seatEntries?.ticket_code,
                                                        )
                                                      }
                                                    >
                                                      <Tooltip title={'In vé'} key={'prt'} placement="right">
                                                        <img src={IcPrintTicket} alt="" />
                                                      </Tooltip>
                                                    </Button>

                                                    <Button
                                                      className="btn-diagram"
                                                      onClick={() => dispatch(getSendBookingMail(seatEntries?.id))}
                                                    >
                                                      <Tooltip title={'Gửi mail'} key={'eml'} placement="right">
                                                        <img src={IcSendMail} alt="" />
                                                      </Tooltip>
                                                    </Button>
                                                  </>
                                                )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {selectedSeatNames?.length > 1 &&
                                          selectedSeatNames.find((selected: any) => selected === seatFloors?.name) &&
                                          (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                          ((!isOpenBus && listBooking?.trip?.status !== 'canceled') || isOpenBus) &&
                                          !(
                                            dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                            listBooking?.schedule?.open_before === 0
                                          ) &&
                                          (group !== USER_GROUP.AGENT ||
                                            (group === USER_GROUP.AGENT &&
                                              (searchDateBooking &&
                                              listBooking?.schedule?.agent_seats_custom &&
                                              listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                                ? listBooking?.schedule?.agent_seats_custom[
                                                    searchDateBooking
                                                  ]?.includes(seatFloors?.name) &&
                                                  !listBooking?.booked_seat?.includes(seatFloors?.name)
                                                : listBooking?.schedule?.agent_seats?.includes(seatFloors?.name) &&
                                                  !listBooking?.booked_seat?.includes(seatFloors?.name)))) ? (
                                            <>
                                              {group !== USER_GROUP.AGENT && (
                                                <Button
                                                  className="btn-diagram"
                                                  disabled={seatEntries?.status === 'hold'}
                                                  onClick={() => handleLockMultiBooking(selectedSeatNames)}
                                                >
                                                  <Tooltip title={'Khóa ghế'} key={'bkt-mt'} placement="right">
                                                    <LockOutlined />
                                                  </Tooltip>
                                                </Button>
                                              )}
                                              {!listBooking?.price_schedule?.is_price_contact ||
                                              (listBooking?.price_schedule?.is_price_contact &&
                                                group !== USER_GROUP.AGENT) ? (
                                                <Button
                                                  className="btn-diagram"
                                                  disabled={seatEntries?.status === 'hold'}
                                                  onClick={() =>
                                                    handleCreateMultiBooking(selectedSeatNames, rowIndex, colIndex)
                                                  }
                                                >
                                                  <Tooltip title={'Đặt vé'} key={'bkt'} placement="right">
                                                    <PlusOutlined />
                                                  </Tooltip>
                                                </Button>
                                              ) : (
                                                ''
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {group !== USER_GROUP.AGENT &&
                                                (listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                                ((!isOpenBus && listBooking?.trip?.status !== 'canceled') ||
                                                  isOpenBus) &&
                                                !(
                                                  dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                                  listBooking?.schedule?.open_before === 0
                                                ) && (
                                                  <Button
                                                    className="btn-diagram"
                                                    disabled={seatEntries?.status === 'hold'}
                                                    onClick={() => {
                                                      if (
                                                        seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                                                        seatFloors.name
                                                      ) {
                                                        if (!busTypeData?.[`floor2`][rowIndex][colIndex]?.selected) {
                                                          handleChangeTicket(rowIndex, colIndex, 2);
                                                        }
                                                        handleLockBooking(seatFloors.name);
                                                      }
                                                    }}
                                                  >
                                                    <Tooltip title={'Khóa ghế'} key={'lock3'} placement="right">
                                                      <LockOutlined />
                                                    </Tooltip>
                                                  </Button>
                                                )}
                                              {(listBooking?.trip?.status !== 'departed' || !isBooking) &&
                                                ((!isOpenBus && listBooking?.trip?.status !== 'canceled') ||
                                                  isOpenBus) &&
                                                !(
                                                  dateSelect > dayjs(listBooking?.schedule?.open_before_day) ||
                                                  listBooking?.schedule?.open_before === 0
                                                ) &&
                                                (group !== USER_GROUP.AGENT ||
                                                  (group === USER_GROUP.AGENT &&
                                                    (searchDateBooking &&
                                                    listBooking?.schedule?.agent_seats_custom &&
                                                    listBooking?.schedule?.agent_seats_custom[searchDateBooking]
                                                      ? listBooking?.schedule?.agent_seats_custom[
                                                          searchDateBooking
                                                        ]?.includes(seatFloors?.name) &&
                                                        !listBooking?.booked_seat?.includes(seatFloors?.name)
                                                      : listBooking?.schedule?.agent_seats?.includes(
                                                          seatFloors?.name,
                                                        )) &&
                                                    !listBooking?.booked_seat?.includes(seatFloors?.name))) && (
                                                  <>
                                                    {!listBooking?.price_schedule?.is_price_contact ||
                                                    (listBooking?.price_schedule?.is_price_contact &&
                                                      group !== USER_GROUP.AGENT) ? (
                                                      <Button
                                                        className="btn-diagram"
                                                        onClick={() => {
                                                          if (
                                                            seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                                                            seatFloors.name
                                                          ) {
                                                            if (
                                                              !busTypeData?.[`floor2`][rowIndex][colIndex]?.selected
                                                            ) {
                                                              handleChangeTicket(rowIndex, colIndex, 2);
                                                            }

                                                            handleCreateBooking(seatFloors.name, rowIndex, colIndex);
                                                          }
                                                        }}
                                                      >
                                                        <Tooltip title={'Đặt vé'} key={'bkt2'} placement="right">
                                                          <PlusOutlined />
                                                        </Tooltip>
                                                      </Button>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : seatFloors.type === Constant.DRIVER_SEAT ||
                                    seatFloors.type === Constant.EXTRA_DRIVER_SEAT ? (
                                    ''
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            }
                          />
                        </Col>
                      )
                    );
                  })}
                </Row>
              );
            })}
          </div>
        </div>
      )}
      <ListTicketCancel listBooking={listBooking} listBookingDetail={listBookingDetail} refs={refs} />
      <ModalBooking
        okText={!isDetail ? 'Đặt vé' : 'Lưu'}
        modalOpen={openCreate}
        onCancel={cancelModal}
        cancelText={'Đóng'}
        onOk={handleOkModalBooking}
        listBooking={listBooking}
        formSearch={formSearch}
        isDetail={isDetail}
        listBookingDetail={listBookingDetail}
        loading={loadingDetail}
        targetTime={targetTime}
        setTargetTicket={resetTargetTicket}
        isMulti={isMulti}
        isDepart={isDepart}
        isBooking={isBooking}
        isChangeTicket={isChangeTicket}
        setIsChangeTicket={setIsChangeTicket}
      />
      <ModalChangeBooking
        okText={!isDetail ? 'Đổi vé' : 'Lưu'}
        modalOpen={openModalChangeBooking}
        onCancel={cancelChangBookingModal}
        cancelText={'Đóng'}
        onOk={handleOkChangeBooking}
        listBooking={listBooking}
        formSearch={formSearch}
        isDetail={isDetail}
        listBookingDetail={listBookingDetail}
        loading={loadingDetail}
        targetTime={targetTime}
        setTargetTicket={resetTargetTicket}
      />
      <ModalMultiBooking
        okText="Đặt vé"
        modalOpen={openModalMulti}
        onCancel={cancelModalMulti}
        cancelText={'Đóng'}
        onOk={() => setOpenModalMulti(false)}
        listBooking={listBooking}
        formSearch={formSearch}
        isMulti={isMulti}
        targetTime={targetTime}
        setTargetTicket={resetTargetTicket}
        isBooking={isBooking}
        isDepart={isDepart}
      />
      <ModalPrintTicket
        modalOpen={openModalPrint}
        onCancel={cancelModalPrint}
        onOk={() => setOpenModalPrint(false)}
        listBooking={listBooking}
        listBookingDetail={dataPrint}
        loadingDetail={loadingDetail}
      />
      <ModalAlertBalance modalOpen={openModalAlert} onCancel={cancelModalAlert} onOk={() => setOpenModalAlert(false)} />
      <ModalAlertBalanceAgent
        modalOpen={openModalAlertAgent}
        onCancel={cancelModalAlertAgent}
        onOk={() => setOpenModalAlertAgent(false)}
      />
    </>
  );
}
export default BusDiagram;
