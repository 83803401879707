import { getAxiosClientWithToken } from './index';
import { DuplicateScheduleParams, ListScheduleRequest } from '../types/schedule';

export const getListScheduleAPI = (params?: ListScheduleRequest) => {
  return getAxiosClientWithToken().get('/schedule', { params });
};

export const createScheduleAPI = (params: any) => {
  const restClient = getAxiosClientWithToken();
  return restClient.post('/schedule', params);
};

export const updateScheduleAPI = (params: any) => {
  const restClient = getAxiosClientWithToken();
  return restClient.put(`/schedule/${params.id}`, params);
};

export const updateStatusScheduleAPI = (params: { id: any; status: boolean }) => {
  const restClient = getAxiosClientWithToken();
  return restClient.put(`/schedule/status/${params.id}`, { status: params?.status ? 0 : 1 });
};

export const duplicateScheduleApi = (params: DuplicateScheduleParams) => {
  const restClient = getAxiosClientWithToken();
  return restClient.post(`/schedule/duplicate/${params?.id}`, params);
};
