import React, { useCallback, useEffect, useState } from 'react';
import IconExportExcel from '../../../components/Icons/IconExportExcel';
import {
  Form,
  Select,
  DatePicker,
  Button,
  ConfigProvider,
  Flex,
  Input,
  Table,
  Typography,
  Tabs,
  type TabsProps,
  Tooltip,
} from 'antd';
import type { TableProps } from 'antd';
import TutorialsVideo from '../../../components/TutorialsVideo';
import { handleDisplayTimeToNow, handleGetUrlGuide } from '../../../utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import iconAction from '../../../assets/images/ic-edit.svg';
import ExcelJS from 'exceljs';
import {
  getListOrdersProcessed,
  setCurrentPage,
  setFilter,
  setKeyword,
  setLoadingOrderProcess,
  setPerPage,
  setStatus,
} from '../../../redux/slice/orders/ordersProcessedSlide';
import dayjs from 'dayjs';
import { Constant } from '../../../config/constant';
import { OrderItemParams } from '../../../types/order';
import moment from 'moment';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { toast } from 'react-toastify';
import { getListOrdersAPI, getOrderDetailAPI } from '../api';
import { isArray } from 'lodash';
import '../styles/Order.scss';
import { columnsExcelProcessed, TabStatusOrderProcessed, TypeModalOrder } from '../models/constants';
import ModalOrderInfo from '../components/ModalOrderData';
import iconSearch from '../../../assets/images/icons/icon-search.svg';
import iconSwap from '../../../assets/images/orders/icon-swap.svg';
import iconHistory from '../../../assets/images/orders/icon-history.svg';
import iconVnpay from '../../../assets/images/icon-logo-vnpay.png';
import statusCancel from '../../../assets/images/orders/status-cancel.svg';
import statusPaid from '../../../assets/images/orders/status-paid.svg';
import statusPending from '../../../assets/images/orders/status-pending.svg';
import statusCancelPaid from '../../../assets/images/orders/status-cancel-paid.svg';
import statusCancelPending from '../../../assets/images/orders/status-cancel-pending.svg';
import statusPaidPending from '../../../assets/images/orders/status-paid-pending.svg';
import statusPaidPendingCancel from '../../../assets/images/orders/status-paid-pending-cancel.svg';
import TextDisplay from '../../../components/Display/TextDisplay';
import ModalHistoryOrderAll from '../components/ModalHistoryOrderAll';
import iconInsurance from '../../../assets/images/orders/icon-insurance.svg';
import logoCompanyDefault from '../../../assets/images/orders/logo-company-default.png';
import iconAvatar from '../../../assets/images/orders/icon-avatar.png';
import iconFacebook from '../../../assets/images/orders/icon-facebook.svg';
import iconGoogle from '../../../assets/images/orders/icon-google.svg';
import logoNapas from '../../../assets/images/orders/logo-napas.png';
import iconWeb from '../../../assets/images/icon-web.svg';
import iconMobile from '../../../assets/images/icon-mobile.svg';

type FieldType = {
  date_filter: string;
  range_date_filter: string[];
  keyword: string;
  status: string;
};

export const handleGetGuestSource = (source: string) => {
  if (!source) {
    return iconAvatar;
  } else {
    switch (source) {
      case 'facebook':
        return iconFacebook;
      case 'google':
        return iconGoogle;
      default:
        return source;
    }
  }
};

const { RangePicker } = DatePicker;

const optionsDateFilter = [
  {
    value: 'depart_date',
    label: 'Theo ngày khởi hành',
  },
  {
    value: 'created_at',
    label: 'Theo ngày đặt vé',
  },
];

const itemsTabs: TabsProps['items'] = [
  {
    key: TabStatusOrderProcessed.PROCESSED,
    label: <p className="tab-title tab-title--first">Tất cả</p>,
  },
  {
    key: TabStatusOrderProcessed.PAID,
    label: <p className="tab-title">Đã thanh toán</p>,
  },
  {
    key: TabStatusOrderProcessed.CANCEL,
    label: <p className="tab-danger tab-title">Đã hủy</p>,
  },
];

export const handleCheckArray = (pattern: string[], array: string[]) => {
  if (pattern?.length === array?.length) {
    return array?.every((role: string) => pattern?.includes(role));
  } else {
    return false;
  }
};

export const handleDisplayStatus = (status: string[]) => {
  if (handleCheckArray(['cancel'], status)) {
    return statusCancel;
  }
  if (handleCheckArray(['reserve'], status)) {
    return statusPending;
  }
  if (handleCheckArray(['cancel', 'paid'], status)) {
    return statusCancelPaid;
  }
  if (handleCheckArray(['cancel', 'reserve'], status)) {
    return statusCancelPending;
  }
  if (handleCheckArray(['paid'], status)) {
    return statusPaid;
  }
  if (handleCheckArray(['paid', 'reserve'], status)) {
    return statusPaidPending;
  }
  if (handleCheckArray(['paid', 'reserve', 'cancel'], status)) {
    return statusPaidPendingCancel;
  }
};

const OrderProcessed = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);
  const [openModalOrder, setOpenModalOrder] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderItemParams>({} as OrderItemParams);
  const [openModalHistory, setOpenModalHistory] = useState<boolean>(false);
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);
  const {
    filter: listFilter,
    listOrder,
    pagination,
    timer,
    loading,
  } = useAppSelector((state) => state.ordersProcessed);

  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? Constant.PAGE_SIZE : Number(limit_pagination);

  const onChangeActive = (key: string) => {
    dispatch(setStatus(key));
    dispatch(setCurrentPage(1));
    dispatch(setKeyword(form.getFieldValue('keyword')));
  };

  const systemConfig = useAppSelector((state) => state.systemConfig?.result);
  const disabledDaysDate = useCallback(
    (current: any, { from }: any) => {
      if (from) {
        return Math.abs(current.diff(from, 'days')) >= systemConfig?.export_report_month;
      }
      return false;
    },
    [systemConfig?.export_report_month],
  );

  const handleGetListOrders = useCallback(async () => {
    await dispatch(
      getListOrdersProcessed({
        page: pagination?.current_page,
        keyword: listFilter.keyword ? listFilter.keyword : '',
        date_filter: listFilter?.date_filter,
        from: dayjs(listFilter?.from, 'YYYY-MM-DD', true).isValid()
          ? dayjs(listFilter?.from).format('YYYY-MM-DD')
          : undefined,
        to: dayjs(listFilter?.to, 'YYYY-MM-DD', true).isValid()
          ? dayjs(listFilter?.to).format('YYYY-MM-DD')
          : undefined,
        status: listFilter?.status,
        limit: pagination?.per_page || limitPagination,
        is_full: '0',
        is_detail_booking: '0',
      }),
    );
  }, [
    dispatch,
    listFilter.keyword,
    listFilter?.date_filter,
    pagination?.current_page,
    listFilter?.from,
    listFilter?.to,
    pagination?.per_page,
    listFilter?.status,
    limitPagination,
  ]);

  useEffect(() => {
    handleGetListOrders();
  }, [handleGetListOrders]);

  useEffect(() => {
    const timerID = setInterval(() => {
      handleGetListOrders();
      dispatch(setLoadingOrderProcess(false));
    }, Number(systemConfig?.refresh_time ? systemConfig?.refresh_time : 10) * 1000);
    if (pagination?.current_page !== 1 || !timer) {
      clearInterval(timerID);
    }
    return () => clearInterval(timerID);
  }, [handleGetListOrders, timer, pagination?.current_page, systemConfig?.refresh_time, dispatch]);

  const onSearchKeyWord = (value: string) => {
    dispatch(setKeyword(value));
    dispatch(setCurrentPage(1));
  };

  const handleDisplayStatus = (status: string[]) => {
    if (handleCheckArray(['cancel'], status)) {
      return statusCancel;
    }
    if (handleCheckArray(['cancel', 'paid'], status)) {
      return statusCancelPaid;
    }
    if (handleCheckArray(['cancel', 'reserve'], status)) {
      return statusCancelPending;
    }
    if (handleCheckArray(['paid'], status)) {
      return statusPaid;
    }
    if (handleCheckArray(['paid', 'reserve'], status)) {
      return statusPaidPending;
    }
    if (handleCheckArray(['paid', 'reserve', 'cancel'], status)) {
      return statusPaidPendingCancel;
    }
  };

  const handleGetDetailOrder = async (booking_code: string) => {
    try {
      const res = await getOrderDetailAPI({
        booking_code: booking_code,
        ticket_code: '',
      });
      if (res?.status === 200) {
        setOrderData(res?.data?.data);
        setOpenModalOrder(true);
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra vui lòng thử lại!');
    }
  };

  const handleError = (e: any) => {
    e.target.onerror = null;
    e.target.src = logoCompanyDefault;
  };

  const columns: TableProps<OrderItemParams>['columns'] = [
    {
      title: 'STT',
      render: (_, record, index) => (
        <Flex className="Order__column__booking-code" align="center">
          <Flex align="center" justify="center">
            <img height={16} className="object-fit-scale w-auto" src={handleDisplayStatus(record?.status)} alt="" />
          </Flex>
          <Typography.Text className="fs-16 text-color fw-500 text-nowrap">
            {index + 1 + (pagination?.current_page - 1) * pagination?.per_page}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'customer_name',
      render: (customer_name, record) => (
        <Flex className="flex-column">
          <Flex align="center" className="gap-x-8">
            <img
              width={16}
              height={16}
              className="object-fit-scale"
              src={handleGetGuestSource(record?.guest_source)}
              alt=""
            />
            <Typography.Text className="fs-16 text-color fw-500 text-nowrap">
              <TextDisplay text={customer_name} />
            </Typography.Text>
          </Flex>
          <Typography.Text className="fs-14 text-color-green-default fw-500">
            {handleDisplayTimeToNow(record?.created_at)}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'booking_code',
      render: (booking_code, record) => (
        <Flex className="flex-column">
          <Flex className="gap-x-8" align="center">
            <Typography.Text className="fs-16 text-color fw-500">
              <TextDisplay text={booking_code} />
            </Typography.Text>
            {record?.data?.[0]?.is_insurance && (
              <Tooltip title="Đơn hàng này có bảo hiểm">
                <img width={16} height={16} className="object-fit-scale" src={iconInsurance} alt="" />
              </Tooltip>
            )}
          </Flex>
          <p className="mb-0 text-color-green-default">{record?.amount} vé</p>
        </Flex>
      ),
      sorter: (a, b) => a?.booking_code.localeCompare(b?.booking_code),
    },

    {
      title: 'Hành trình',
      dataIndex: 'route',
      render: (route, record) => (
        <Flex className="gap-x-8" align="center">
          <Tooltip title={record?.company?.name}>
            <img
              onError={handleError}
              src={record?.company_logo}
              width={20}
              height={20}
              className="object-fit-scale"
              alt=""
            />
          </Tooltip>
          <Typography.Text className="fs-16 text-color fw-500">
            {route ? `${route?.depart_point} - ${route?.arrive_point}` : 'N/A'}
            {record?.round_trip && (
              <img className="object-fit-scale ml-8" width={20} height={20} src={iconSwap} alt="" />
            )}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: 'Ngày đi',
      dataIndex: 'depart_date',
      render: (depart_date) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_date ? moment(depart_date).format('DD/MM/YYYY') : ''}
        </Typography.Text>
      ),
      sorter: (a: any, b: any) => moment(a?.depart_date).unix() - moment(b?.depart_date).unix(),
    },
    {
      title: 'Giờ đi',
      dataIndex: 'depart_time',
      render: (depart_time) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {depart_time ? moment(depart_time, 'HH:mm').format('HH:mm') : ''}
        </Typography.Text>
      ),
      sorter: (a, b) => moment(a.depart_time, 'HH:mm').unix() - moment(b.depart_time, 'HH:mm').unix(),
    },
    {
      title: 'Ngày đặt',
      hidden: true,
      dataIndex: 'created_at',
      render: (created_at) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {created_at ? moment(created_at).format('DD/MM/YYYY') : ''}
        </Typography.Text>
      ),
      sorter: (a: any, b: any) => moment(a?.created_at).unix() - moment(b?.created_at).unix(),
    },
    {
      title: 'Thanh toán',
      dataIndex: 'payment_method',
      render: (payment_method, record) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {payment_method === 'bank_transfer' ? (
            <Flex justify="center" align="center">
              <img width={78} height={16} className="object-fit-scale" src={logoNapas} alt="" />
            </Flex>
          ) : payment_method === 'reserve' && record?.data?.[0]?.status !== 'cancel' ? (
            'Thanh toán sau'
          ) : payment_method === 'vnpay' && record?.data?.[0]?.status !== 'cancel' ? (
            <img src={iconVnpay} alt="" />
          ) : payment_method === 'paid_at_company' && record?.data?.[0]?.status !== 'cancel' ? (
            'Thanh toán tại nhà xe'
          ) : (
            'Thanh toán sau'
          )}
        </Typography.Text>
      ),
    },
    {
      title: '',
      dataIndex: 'os',
      align: 'right',
      render: (os) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {os === 'web' ? <img src={iconWeb} alt="" /> : <img src={iconMobile} alt="" />}
        </Typography.Text>
      ),
    },
    {
      title: 'Tổng tiền đơn hàng',
      dataIndex: 'total_price',
      align: 'right',
      render: (total_price) => (
        <Typography.Text className="fs-16 text-color fw-500">{total_price ? total_price : 'N/A'}</Typography.Text>
      ),
      sorter: (a, b) => {
        return Number(a?.total_price) - Number(b?.total_price);
      },
    },
    {
      title: 'Tổng tiền đã xuất',
      dataIndex: 'total_export_price',
      align: 'right',
      render: (total_export_price) => (
        <Typography.Text className="fs-16 text-color fw-500">
          {total_export_price ? total_export_price : 'N/A'}
        </Typography.Text>
      ),
      sorter: (a, b) => {
        return Number(a?.total_export_price) - Number(b?.total_export_price);
      },
    },
    {
      title: 'Chức năng',
      dataIndex: 'booking_code',
      align: 'center',
      render: (booking_code) => (
        <Flex
          onClick={() => handleGetDetailOrder(booking_code)}
          className="Order__column__action"
          align="center"
          justify="center"
        >
          <img width={24} height={24} className="object-fit-scale" src={iconAction} alt="" />
        </Flex>
      ),
    },
  ];

  const handleExportExcel = async () => {
    try {
      setLoadingExcel(true);
      const res = await getListOrdersAPI({
        keyword: listFilter.keyword ? listFilter.keyword : '',
        date_filter: listFilter?.date_filter,
        from: dayjs(listFilter?.from, 'YYYY-MM-DD', true).isValid()
          ? dayjs(listFilter?.from).format('YYYY-MM-DD')
          : undefined,
        to: dayjs(listFilter?.to, 'YYYY-MM-DD', true).isValid()
          ? dayjs(listFilter?.to).format('YYYY-MM-DD')
          : undefined,
        status: listFilter?.status,
        is_full: '1',
        is_detail_booking: '0',
      });
      if (isArray(res?.data?.data)) {
        const listData = res?.data?.data;
        const listDataExcel =
          listData?.length > 0
            ? listData?.map((order: OrderItemParams, index: number) => ({
                key: index + 1,
                booking_code: order?.booking_code ? order?.booking_code : 'N/A',
                customer_name: order?.customer_name ? order?.customer_name : 'N/A',
                route: order?.route ? `${order?.route?.depart_point} - ${order?.route?.arrive_point}` : 'N/A',
                depart_date: order?.depart_date ? `${moment(order?.depart_date).format('DD/MM/YYYY')}` : 'N/A',
                depart_time: order?.depart_time ? `${moment(order?.depart_time, 'HH:mm').format('HH:mm')}` : 'N/A',
                created_at: order?.created_at ? `${moment(order?.created_at).format('DD/MM/YYYY')}` : 'N/A',
                source: order?.source ? order?.source : 'N/A',
                payment_method: order?.payment_method === 'bank_transfer' ? 'Chuyển khoản' : 'N/A',
                amount: order?.amount ? Number(order?.amount) : 0,
                total_export_price: order?.total_export_price ? Number(order?.total_export_price) : 0,
                total_price: order?.total_price ? Number(order?.total_price) : 0,
              }))
            : [];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Đơn hàng đã xử lý');
        const header = columnsExcelProcessed.map((col) => ({
          header: col.title,
          key: col.dataIndex as string,
        }));
        worksheet.columns = header;
        columnsExcelProcessed.forEach((col, index) => {
          worksheet.getColumn(index + 1).width = col.width || 15;
          worksheet.getColumn(index + 1).alignment = {
            horizontal: (col?.__style__?.h as 'center' | 'left' | 'right' | 'fill' | 'justify') || 'left',
            vertical: 'middle',
          };
          worksheet.getColumn(index + 1).numFmt = col.__numFmt__ || 'general';
        });
        worksheet.addRows(listDataExcel);
        worksheet.getRow(1).font = {
          name: 'Times New Roman',
          size: 12,
          bold: true,
        };
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          if (colNumber < 12) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '3AB54A' },
            };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          }
        });
        let firstRowHasData = false;
        worksheet.getRow(1).eachCell((cell, colNumber) => {
          if (cell.value) {
            firstRowHasData = true;
            return;
          }
        });
        if (firstRowHasData) {
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber > 1) {
              row.eachCell((cell) => {
                cell.font = { name: 'Times New Roman', size: 12 };
                cell.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' },
                };
              });
            }
          });
        } else {
          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
              let hasData = false;
              row.eachCell((cell) => {
                if (cell.value) {
                  hasData = true;
                  return;
                }
              });
              if (hasData) {
                row.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' },
                };
              }
            }
          });
        }

        const lastRow = worksheet.addRow([]);
        lastRow.getCell(1).value = '@Bản quyền thuộc nhà xe Carlink';
        lastRow.getCell(1).alignment = {
          vertical: 'middle',
          horizontal: 'left',
        };
        lastRow.getCell(1).font = {
          name: 'Times New Roman',
          size: 14,
          bold: true,
        };
        const endColumnIndex = 0 + columnsExcelProcessed.length;
        worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Đơn hàng đã xử lý.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
      setLoadingExcel(false);
    } catch (error) {
      setLoadingExcel(false);
      toast.error('Có lỗi xảy ra vui lòng thử lại!');
    }
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      dispatch(setCurrentPage(Constant.DEFAULT_PAGE));
      dispatch(setPerPage(pageSize));
    } else {
      dispatch(setCurrentPage(page));
    }
  };

  const onFinish = (values: FieldType) => {
    dispatch(
      setFilter({
        keyword: values?.keyword ? values?.keyword : '',
        date_filter: values?.date_filter,
        from: values?.range_date_filter?.[0] ? values?.range_date_filter?.[0] : '',
        to: values?.range_date_filter?.[1] ? values?.range_date_filter?.[1] : '',
        status: listFilter?.status,
      }),
    );
    dispatch(setCurrentPage(1));
  };
  return (
    <div className="Order">
      <Tabs
        defaultActiveKey={listFilter?.status}
        activeKey={listFilter?.status}
        items={itemsTabs}
        onChange={onChangeActive}
        className={`Order__tabs ${listFilter?.status === TabStatusOrderProcessed.CANCEL ? 'danger' : ''}`}
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          keyword: listFilter?.keyword ? listFilter?.keyword : '',
          date_filter: listFilter?.date_filter ? listFilter?.date_filter : 'depart_date',
          range_date_filter: [
            listFilter?.from ? dayjs(listFilter?.from, 'DD/MM/YYYY') : undefined,
            listFilter?.to ? dayjs(listFilter?.to, 'DD/MM/YYYY') : undefined,
          ],
        }}
      >
        <Flex className="Order__form" align="start" justify="space-between">
          <Flex className="Order__form" align="center">
            <div className="Order__keyword">
              <Form.Item<FieldType> name="keyword">
                <Input.Search
                  placeholder="Nhập mã đơn hàng để tìm kiếm"
                  suffix={<img className="object-fit-scale" width={16} height={16} src={iconSearch} alt="" />}
                  onSearch={onSearchKeyWord}
                />
              </Form.Item>
            </div>
            <div className="Order__dateFilter">
              <Form.Item<FieldType>
                name="date_filter"
                rules={[{ required: true, message: 'Vui lòng chọn khoảng thời gian' }]}
              >
                <Select placeholder="Theo ngày khởi hành" options={optionsDateFilter} />
              </Form.Item>
            </div>
            <div className="Order__rangeDate">
              <Form.Item<FieldType> name="range_date_filter">
                <RangePicker
                  disabledDate={disabledDaysDate}
                  format="DD/MM/YYYY"
                  popupClassName="Order__rangeDate__picker"
                  placeholder={['Từ ngày', 'Đến ngày']}
                />
              </Form.Item>
            </div>
            <ConfigProvider theme={{ token: { colorPrimary: '#435869' } }}>
              <Form.Item>
                <Button
                  ghost
                  className="Order__exportExcel d-flex align-items-center h-auto"
                  type="primary"
                  htmlType="submit"
                >
                  <img className="object-fit-scale" width={16} height={16} src={iconSearch} alt="" />
                  Xem
                </Button>
              </Form.Item>
            </ConfigProvider>
            <ConfigProvider theme={{ token: { colorPrimary: '#435869' } }}>
              <Form.Item>
                <Button
                  ghost
                  className="Order__history d-flex align-items-center h-auto"
                  type="primary"
                  onClick={() => setOpenModalHistory(true)}
                >
                  <img width={16} height={16} className="object-fit-scale" src={iconHistory} alt="" />
                  Lịch sử thao tác đơn hàng
                </Button>
              </Form.Item>
            </ConfigProvider>
            <ConfigProvider theme={{ token: { colorPrimary: '#435869' } }}>
              <Form.Item>
                <Button
                  ghost
                  className="Order__exportExcel d-flex align-items-center h-auto"
                  type="primary"
                  loading={loadingExcel}
                  onClick={handleExportExcel}
                >
                  <IconExportExcel color={'#435869'} width={16} height={16} />
                  Xuất Excel
                </Button>
              </Form.Item>
            </ConfigProvider>
          </Flex>
          <Flex align="center" className="Order__tutorials">
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 8)} />
          </Flex>
        </Flex>
      </Form>
      <Table
        className="Order__table"
        dataSource={listOrder?.length > 0 ? listOrder : []}
        locale={{
          triggerDesc: 'Sắp xếp tăng dần',
          triggerAsc: 'Hủy sắp xếp',
          cancelSort: 'Sắp xếp giảm dần',
        }}
        loading={loading}
        rowClassName={(record) => (handleCheckArray(['cancel'], record?.status) ? 'bg-disabled' : '')}
        pagination={{
          total: pagination?.total,
          current: pagination?.current_page,
          pageSize: pagination?.per_page ?? DEFAULT_PAGE_SIZE,
          position: ['bottomRight'],
          onChange: onChangePage,
          showSizeChanger: true,
        }}
        columns={columns}
      />
      <ModalOrderInfo
        orderData={orderData}
        typeModal={TypeModalOrder.PROCESSED}
        openModalOrderData={openModalOrder}
        setOpenModalOrderData={setOpenModalOrder}
        getDetailOrder={() => handleGetDetailOrder(orderData?.booking_code)}
      />
      <ModalHistoryOrderAll openHistoryAll={openModalHistory} setOpenHistoryAll={setOpenModalHistory} />
    </div>
  );
};

export default OrderProcessed;
