import { RouteConfigType } from '../../types/routeConfig';

const routes: RouteConfigType = {
  //PUBLIC ROUTES
  home: '/',
  notFound: '*',
  login: '/login',
  forbidden: '/forbidden',
  internalSever: '/internal-sever',
  error404: '/404',

  //DASHBOARD ROUTES
  dashboard: '/dashboard',
  companyInfo: 'info-company',
  agentInfo: 'info-agent',
  mainAgentInfo: 'info-main-agent',
  policy: 'salespolicy',
  insuranceConfig: 'insurance-config',
  listPromotion: 'list-promotion',

  //DEPOSIT ROUTES
  accountDeposit: 'account-deposit',
  accountPromotion: 'account-deposit-promotion',
  accountDepositCarCompany: 'account-deposit-company',
  accountDepositCarlink: 'account-deposit-carlink',
  accountDepositAgent: 'account-deposit-agent',
  accountDepositMainAgent: 'account-deposit-main-agent',
  historyDepositMainAgent: 'history-deposit-main-agent',

  listRegisterTrial: 'list-sign-up',
  listRegisterAdvertise: 'list-register-advertise',

  //LIST AGENT, CAR_COMPANY ROUTE
  listAgencyManagement: '/agency-management',
  listCarCompany: 'bus-company',
  listGeneralAgency: 'general-agents',
  listAgent: 'agent',

  //BOOKING ROUTE
  bookingManagement: '/booking-management',
  booking: 'booking',
  listTicket: 'tickets',
  listContract: 'contract',

  //ORDER MANAGEMENT ROUTES
  orderManagement: '/list-oder-online',
  orderPending: 'pending',
  orderSuccess: 'processed',
  orderTicket: 'order-ticket',

  //OPERATE MANAGEMENT ROUTES
  operationManagement: '/bus-management',
  listTrip: 'trips',
  listSchedule: 'schedules',
  segmentSetting: 'segments',
  locationSetting: 'locations',
  listRoute: 'routes',
  listOffice: 'offices',
  listPickUpLocation: 'pickup-locations',
  listBus: 'buses',
  listDriver: 'driver',
  listTicketSetting: 'ticket-setting-schedule',
  listBusType: 'bus-type',
  listService: 'services',

  //REPORT ROUTES
  reportManagement: '/report-manager',
  reportDetail: 'report-detail',
  reportRoute: 'report-route',
  reportTotal: 'report-total',
  reportAgent: 'report-agent',
  reportDriver: 'report-driver',
  reportCustomer: 'report-customer',
  reportCancel: 'report-cancel',
  reportContract: 'report-contract',
  reportRechargeAgent: 'report-recharge-agent',
  reportRecharge: 'report-recharge',
  reportBalanceChange: 'report-balance-change',
  reportRechargeCarlink: 'report-recharge-carlink',
  reportFeeDepart: 'report-fee-depart',
  reportExportTicket: 'report-export-ticket',
  reportRechargeCompany: 'report-recharge-company',
  reportFeeDepartCompany: 'report-fee-depart-company',
  reportFeeExportTicketCompany: 'report-fee-export-ticket-company',
  reportDepositPromotionCompany: 'report-deposit-promotion-company',
  reportSourceAPI: 'report-source-api',

  list: 'list',
  //USER MANAGEMENT ROUTES
  userManagement: '/user',
  listUser: 'list',

  //API MANAGEMENT ROUTES
  apiManagement: '/api-management',
  listFollow: 'follow',
  listApi: 'management',

  //EMPLOYEE MANAGEMENT ROUTES
  employeeManagement: '/employee-manager',
  listEmployee: 'employee',
  listSalary: 'salary',
  listChecking: 'checking',
  listWork: 'work',

  //SYSTEM MANAGEMENT ROUTES
  systemManagement: '/system-manager',
  listInfoCarlink: 'list',
  systemConfig: 'system-config',
  systemGuide: 'system-guide',
  systemDepartureFee: 'system-departure-fee',

  //NOTIFICATION ROUTES
  notificationManagement: '/notification',
  listNotification: 'list',

  //Promotion
};

export default routes;
