import { Button, Col, Form, Image, Input, Modal, Row, Select, SelectProps, Space, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import icLock2 from '../../assets/images/ic-lock-2.svg';
import IcMap from '../../assets/images/listOffice/map.svg';
import icLocate from '../../assets/images/locate.svg';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setPickupLocation, updateStatusPickupLocation } from '../../redux/slice/pickupLocationSlice';

import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Rule } from 'antd/es/form';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkAuthorization } from '../../api';
import { getOfficeAllProvinces } from '../../api/offices';
import { createPickupLocationAPI, updatePickupLocationAPI } from '../../api/pickupLocations';
import icClose from '../../assets/images/listOffice/icClose.svg';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant } from '../../config/constant';
import { validateDistrictOffice, validateWardOffice } from '../../config/validators/validateOffice';
import { filterOption } from '../../lib/utils';
import { fetchListDistrict, fetchListWard } from '../../redux/slice/provinceSlice';
import { handleGetUrlGuide } from '../../utils/helpers';
import './PickupModal.scss';
function PickupModal({
  open,
  setOpen,
  detailPickupLocation,
  provinceWithLocation,
  handleListPickupLocation,
  listTutorials,
}: {
  open: boolean;
  setOpen: Function;
  detailPickupLocation: any;
  provinceWithLocation: any;
  handleListPickupLocation: any;
  listTutorials: any;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [busImg, setBusImg] = useState([]);
  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const [previewImage, setPreviewImage] = useState('');
  const [fileListDelete, setFileListDelete] = useState<[]>([]);
  const [listProvince, setListProvince] = useState([]);
  const { district, ward } = useAppSelector((state) => state?.province);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState<boolean>(false);
  const provinceId = Form.useWatch('province_id', form);
  const districtId = Form.useWatch('district_id', form);

  const provincesOption: SelectProps['options'] = listProvince?.length
    ? listProvince?.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    : [];
  const districtOption: SelectProps['options'] = district?.length
    ? district?.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    : [];
  const wardOption: SelectProps['options'] = ward?.length
    ? ward?.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    : [];

  const nameRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập tên điểm đón trả'),
    },
  ];
  const nameOnlineRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập tên online'),
    },
  ];
  const provinceRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng chọn tỉnh / thành phố'),
    },
  ];

  const addressRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập địa chỉ'),
    },
  ];

  const handleCancelModalConfirm = () => {
    setIsModalConfirmVisible(false);
  };

  const onChangeBusImage = (imageList: ImageListType) => {
    setBusImg(imageList as never[]);
  };

  const handleErrorUpload = (errors: any, files: any) => {
    if (errors?.acceptType) {
      toast.error('Ảnh tải lên phải thuộc 1 trong 4 định dạng sau: png, jpg, jpeg, gif.');
    }
    if (errors?.maxNumber) {
      toast.error('Vui lòng tải lên tối đa 5 ảnh');
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      name: detailPickupLocation?.name,
      address: detailPickupLocation?.address,
      province_id: detailPickupLocation?.province?.id,
      longitude: detailPickupLocation?.longitude,
      latitude: detailPickupLocation?.latitude,
      images: detailPickupLocation?.images?.map((image: string) => ({
        dataURL: image,
      })),
      district_id: detailPickupLocation?.district?.id,
      ward_id: detailPickupLocation?.ward?.id ? detailPickupLocation?.ward?.id : undefined,
      map_link: detailPickupLocation?.map_link,
      phone: detailPickupLocation?.phone,
      name_online: detailPickupLocation?.name_online,
    });

    setIsModalConfirmVisible(false);
  }, [detailPickupLocation, open, form]);

  const handleModalCancel = () => {
    setOpen(false);
    form.resetFields();
    dispatch(setPickupLocation(undefined));
  };

  function handleLocationClick() {
    if (navigator.geolocation) {
      form.setFieldsValue({
        longitude: 'loading ...',
        latitude: 'loading ...',
      });
      navigator.geolocation.getCurrentPosition(successLocate, errorLocate);
    } else {
      toast.error('Geolocation not supported');
    }
  }

  function successLocate(position: any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    form.setFieldsValue({
      longitude: longitude,
      latitude: latitude,
    });
  }

  function errorLocate() {
    form.setFieldsValue({
      longitude: '',
      latitude: '',
    });
    toast.error('Không thể truy xuất vị trí của bạn');
  }

  const handleLockPickupLocation = (id: number, status: boolean) => () => {
    dispatch(
      updateStatusPickupLocation({
        id: id,
        status: status,
      }),
    )
      .then((response) => {
        toast.success('Thao tác thành công');
        setOpen(false);
        handleListPickupLocation();
        handleCancelModalConfirm();
      })
      .catch((err) => {
        toast.error('Thao tác thất bại');
      });
  };

  const onFinish = async (values: any) => {
    let listImages = busImg?.map((image: any) => image?.dataURL);
    values.images = listImages?.filter((image) => !image?.includes('https://'));

    if (fileListDelete.length) {
      values.images_delete = fileListDelete;
    }
    if (detailPickupLocation?.id) {
      values.id = detailPickupLocation?.id;
      try {
        const res = await updatePickupLocationAPI(detailPickupLocation?.id, values);
        if (res.status === 200) {
          toast.success('Thao tác thành công');
          handleListPickupLocation();
          setOpen(false);
        }
      } catch (error) {
        checkAuthorization(error);
      }
    } else {
      try {
        const res = await createPickupLocationAPI(values);
        if (res.status === 200) {
          toast.success('Thao tác thành công');
          handleListPickupLocation();
          setOpen(false);
        }
      } catch (error) {
        checkAuthorization(error);
      }
    }
  };
  const callOfficeAllProvinces = async () => {
    try {
      const res = await getOfficeAllProvinces();
      if (res.status === Constant.DEFAULT_STATUS) {
        setListProvince(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    callOfficeAllProvinces();
  }, []);
  const handleChangeProvince = (value: any, option: any) => {
    dispatch(fetchListDistrict({ province_id: value }));
    form.setFieldValue('district_id', undefined);
    form.setFieldValue('ward_id', undefined);
  };
  const handleChangeWard = (value: any, option: any) => {
    dispatch(fetchListWard({ district_id: value }));
    form.setFieldValue('ward_id', undefined);
  };
  useEffect(() => {
    if (provinceId) {
      dispatch(fetchListDistrict({ province_id: provinceId }));
    }
  }, [provinceId, dispatch]);

  useEffect(() => {
    if (districtId) {
      dispatch(fetchListWard({ district_id: districtId }));
    }
  }, [districtId, dispatch]);
  return (
    <>
      <Modal
        maskClosable={false}
        title={
          <Row style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
            <Typography.Text className="fw-600 fs-18 ">
              {detailPickupLocation?.id ? t('Chi tiết điểm đón trả') : t('Thêm điểm đón trả')}
            </Typography.Text>
            <Space size={10} align="center">
              <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 4)} />
              <Image src={icClose} style={{ cursor: 'pointer' }} preview={false} onClick={handleModalCancel} />
            </Space>
          </Row>
        }
        closeIcon={false}
        centered
        forceRender
        open={open}
        onCancel={handleModalCancel}
        styles={{
          body: { height: '550px', overflowY: 'auto', overflowX: 'hidden' },
        }}
        width={834}
        className="modal-create-office scroll-custom-modal"
        footer={[
          <Row
            className="comp-modal-footer"
            style={{
              marginTop: '25px',
            }}
            key={1}
          >
            <Col>
              {detailPickupLocation?.id ? (
                <Col xs={24} md={24}>
                  <Button
                    key="submit"
                    type="primary"
                    loading={false}
                    onClick={() => setIsModalConfirmVisible(true)}
                    size="large"
                    className={detailPickupLocation.status ? 'h-btn-48 btn-block-red' : 'h-btn-48 btn-active-green'}
                  >
                    <Image src={icLock2} preview={false} className="mb-1 pe-2" />
                    {detailPickupLocation.status ? t('Khóa điểm đón trả') : t('Mở khóa điểm đón trả')}
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </Col>
            <Col className="d-flex gap-12">
              <Button key="back" onClick={handleModalCancel} size="large" className="btn-cancel-modal">
                {t('Hủy')}
              </Button>
              <Button
                key="submit"
                type="primary"
                loading={false}
                onClick={form.submit}
                size="large"
                className="btn-save-modal"
                htmlType="submit"
              >
                {t('Lưu')}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          name="extra-driver-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          className="form-create-office"
          labelCol={{ span: 12 }}
          colon={false}
          labelAlign={'left'}
          labelWrap
        >
          <Spin spinning={false}>
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="name"
                  className="form-item-name required"
                  label={t('Tên điểm đón trả')}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={nameRules}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="name_online"
                  className="form-item-name required-none"
                  label={t('Tên online')}
                  validateTrigger={['onChange', 'onBlur']}
                  required={false}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="province_id"
                  className="form-item-name required"
                  label={t('Tỉnh / Thành phố')}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={provinceRules}
                >
                  <Select
                    className="input-h36"
                    showSearch
                    filterOption={filterOption}
                    placeholder="Chọn tỉnh / thành phố"
                    size="large"
                    // optionFilterProp="children"
                    style={{ width: '100%' }}
                    options={provincesOption}
                    onChange={(value: any, option: any) => handleChangeProvince(value, option)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="district_id"
                  className="form-item-name required"
                  label={t('Quận / Huyện')}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={validateDistrictOffice}
                >
                  <Select
                    className="input-h36"
                    showSearch
                    filterOption={filterOption}
                    placeholder="Chọn quận / huyện"
                    size="large"
                    // optionFilterProp="children"
                    style={{ width: '100%' }}
                    options={districtOption}
                    onChange={(value: any, option: any) => handleChangeWard(value, option)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="ward_id"
                  className="form-item-name required-none"
                  label={t('Phường / Xã')}
                  validateTrigger={['onChange', 'onBlur']}
                  // rules={validateWardOffice}
                >
                  <Select
                    className="input-h36"
                    showSearch
                    filterOption={filterOption}
                    placeholder="Chọn phường / xã"
                    size="large"
                    // optionFilterProp="children"
                    style={{ width: '100%' }}
                    options={wardOption}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="address"
                  className="form-item-name required"
                  label={t('Địa chỉ')}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={addressRules}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  className="form-item-name required-none"
                  label={t('Số điện thoại')}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ display: 'none' }}>
              <Button
                className="btn-locate"
                onClick={handleLocationClick}
                style={{
                  marginBottom: 15,
                }}
              >
                <Image src={icLocate} preview={false} className="mb-1 pe-2" />
                Sử dụng vị trí hiện tại
              </Button>
            </Row>
            <Row gutter={24} style={{ display: 'none' }}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="longitude"
                  className="form-item-name required"
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input autoComplete="off" addonBefore="Longitude" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="latitude" className="form-item-name required" validateTrigger={['onChange', 'onBlur']}>
                  <Input autoComplete="off" addonBefore="Latitude" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <a href="https://www.google.com/maps" target="_blank" rel="noreferrer">
                <Button
                  className="btn-locate"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Image src={IcMap} preview={false} className="mb-1 pe-2" />
                  {t('Sử dụng google map')}
                </Button>
              </a>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={16}>
                <Form.Item name="map_link" className="form-item-name required" validateTrigger={['onChange', 'onBlur']}>
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}></Row>
            <Row>
              <Col xs={24}>
                <span className="fw-500 text-color">Ảnh điểm đón trả</span>
                <Form.Item
                  name="images"
                  className="list_image_car"
                  // rules={[{ required: true, message: "Vui lòng nhập ảnh điểm đón trả" }]}
                >
                  <ImageUploading
                    multiple
                    value={form.getFieldValue('images')}
                    onChange={onChangeBusImage}
                    maxNumber={5}
                    acceptType={['jpg', 'gif', 'png', 'jpeg']}
                    onError={handleErrorUpload}
                  >
                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                      <div className="Upload">
                        <Row className="h-100" gutter={[16, 16]}>
                          {imageList?.length > 0 &&
                            imageList?.map((image, index) => (
                              <Col span={6} key={index} className="Upload__item">
                                <div className="Upload__item__wrapper">
                                  <img className="Upload__item__image" src={image?.dataURL} alt="" />
                                  <div className="Upload__item__remove">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setPreviewImage(image?.dataURL as any);
                                        setOpenPreview(true);
                                      }}
                                      className="border-0 bg-transparent"
                                    >
                                      <EyeOutlined className="fs-18 fw-500 white icon-remove" />
                                    </button>
                                    <button
                                      type="button"
                                      className="border-0 bg-transparent"
                                      onClick={() => {
                                        const url: any = image?.dataURL;
                                        // Use split method to split the URL at the desired segment
                                        const baseUrl = url.split('/public/controlpanel/assets/images')[1];
                                        setFileListDelete((fileList) => [...fileList, baseUrl] as any);
                                        onImageRemove(index);
                                      }}
                                    >
                                      <DeleteOutlined className="fs-18 fw-500 white icon-remove" />
                                    </button>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          <Col span={6} className="Upload__item">
                            <button
                              type="button"
                              style={isDragging ? { color: 'red' } : undefined}
                              onClick={onImageUpload}
                              className="Upload__item__button"
                              {...dragProps}
                            >
                              <div>
                                <div className="fs-18 fw-500 text-color-default">
                                  <UploadOutlined className="fs-24" /> Tải ảnh lên
                                </div>
                              </div>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </ImageUploading>
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </Form>
        <Modal footer={null} open={openPreview} onCancel={() => setOpenPreview(false)} width={700}>
          <div className="w-100" style={{ padding: '16px' }}>
            <img className="w-100" src={previewImage} alt="preview" />
          </div>
        </Modal>
      </Modal>
      {detailPickupLocation?.id ? (
        <ModalConfirm
          modalOpen={isModalConfirmVisible}
          okText={detailPickupLocation && detailPickupLocation.status ? t('Khóa ngay') : t('Mở ngay')}
          cancelText={t('Hủy')}
          modalTitle={
            detailPickupLocation && detailPickupLocation.status ? t('Khoá điểm đón trả') : t('Mở điểm đón trả')
          }
          modalContent={
            detailPickupLocation && detailPickupLocation.status
              ? t('Bạn đang thao tác khóa điểm đón trả này, bạn có chắc chắn muốn khóa?')
              : t('Bạn đang thao tác mở điểm đón trả này, bạn có chắc chắn muốn mở?')
          }
          onCancel={handleCancelModalConfirm}
          onOk={handleLockPickupLocation(detailPickupLocation?.id, !detailPickupLocation?.status)}
        />
      ) : null}
    </>
  );
}

export default PickupModal;
