/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Layout,
  Row,
  Select,
  Table,
  Typography,
  DatePicker,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { ListUserRequest } from "../../types";
import { formatPriceInput, mbTrim } from "../../lib/utils";
import { Constant, TIMER_DEPOSIT } from "../../config/constant";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import IconExclamationCircle from "../../assets/images/ExclamationCircle.svg";
import IcCharmTick from "../../assets/images/accountDeposit/charm_tick.svg";
import IcCancel from "../../assets/images/accountDeposit/icCancel.svg";
import IcUser from "../../assets/images/accountDeposit/icUser.svg";
import IcPrice from "../../assets/images/accountDeposit/icPrice.svg";
import { IDepositStatus } from "../../types/company";
import { ACTION_NAME, messageUpdateSuccess } from "../../config/message";
import {
  getRechargeHistoriesMainAgent,
} from "../../api/history";
import Summary from "../../components/summary/Summary";
import StatusFilter from "../accountDeposit/StatusFilter";
import { callUpdateDepositMainAgentApi } from "../../api/generalAgent";
import { getListGeneralAgentAPI } from "../../api/user";
const { RangePicker } = DatePicker;

function HistoryDepositMainAgent() {
  const [listHistory, setListHistory] = useState<any>({});
  const limit_pagination: any = window.localStorage.getItem('limit_pagination')
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [status, setStatus] = useState<any>("");
  const [page, setPage] = useState<number>(1);
  const [listBusCompany, setListBusCompany] = useState<any>([]);
  const [busCompanyId, setBusCompanyId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [detailModal, setDetailModal] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const [dateRange, setDateRange] = useState<any>("");


  const handleIsOpen = (record: any) => {
    setDetailModal(record);
    setIsOpen(true);
  };

  const handleIsCancel = () => {
    setIsOpen(false);
  };

  const { data, pagination, total_confirmed, total_waiting, total_cancel } =
    listHistory;
  const handleOkModal = async (id: any, status: string) => {
    try {
      const bodyStatus: IDepositStatus = {
        id: id,
        status: status,
      };
      const res = await callUpdateDepositMainAgentApi(bodyStatus);
      if (res.status === 200) {
        handleListHistory();
        messageUpdateSuccess(ACTION_NAME.REPOSIT);
        setIsOpen(false);
      }
    } catch (error) {}
  };


  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  };

  const onChangeSelectAgent = (value: any) => {
    setBusCompanyId(value);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Tên tổng đai lý",
      dataIndex: "main_agent",
      key: "main_agent",
      render: (text) => (
        <Typography className="text-color">{text?.name}</Typography>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      width: 160,
      render: (text) => (
        <Typography className="text-color">{formatPriceInput(text)}</Typography>
      ),
    },
    {
      title: "Nội dung chuyển khoản",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Ngày yêu cầu",
      dataIndex: "deposit_date",
      key: "deposit_date",
    },
    {
      title: "Tài khoản nạp",
      dataIndex: "deposit_user",
      key: "deposit_user",
    },
    {
      title: "Tài khoản thao tác",
      dataIndex: "confirm_user",
      key: "confirm_user",
    },
    {
      title: "Chức năng",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 315,
      render: (_, record) => {
        return (
          <>
            {record?.status === "wait_confirm" && (
              <Row
                gutter={8}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  htmlType="button"
                  className="btn-confirm"
                  onClick={() => handleIsOpen(record)}
                >
                  {t("Xác nhận")}
                </Button>

                <Col>
                  <Button
                    htmlType="button"
                    className="btn-confirm"
                    onClick={() => handleOkModal(record?.id, "deny")}
                  >
                    {t("Từ chối")}
                  </Button>
                </Col>
              </Row>
            )}{" "}
            {record?.status === "confirmed" && (
              <Typography className="text-confirmed">
                <Image src={IcCharmTick} preview={false} /> {t("Đã xác nhận")}
              </Typography>
            )}
            {record?.status === "cancel" && (
              <Typography className="text-cancel">
                <Image src={IcCancel} preview={false} /> {t("Đã từ chối")}
              </Typography>
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (value: ListUserRequest) => {
    form.setFields([{ name: "keyword", value: mbTrim(value.keyword) }]);
    setPage(Constant.DEFAULT_PAGE);
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const handleChangeStatus = (value: boolean | undefined) => {
    setStatus(value);
  };

  const selectListBusCompany =
    listBusCompany?.length > 0
      ? listBusCompany?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      : [];

  const handleListCarCompanyApi = async () => {
    const testStatus = true;
    let queryGeneralAgent = `status=${testStatus}`;
    try {
      const res = await getListGeneralAgentAPI(queryGeneralAgent);
      if (res.status === 200) {
        setListBusCompany(res?.data?.data);
      }
    } catch (error) {}
  };

  let query = `page=${page}&limit=${limit}&status=${status}&main_agent_id=${busCompanyId}`;
  let actionSearch = `status=${status}&main_agent_id=${busCompanyId}`
  if (dateRange) {
    const [startDate, endDate] = dateRange;

    query += `&from=${startDate.format("YYYY-MM-DD")}&to=${endDate.format(
      "YYYY-MM-DD"
    )}`;
    actionSearch += `&from=${startDate.format("YYYY-MM-DD")}&to=${endDate.format(
      "YYYY-MM-DD"
    )}`;
  }
  const handleListHistory = async () => {
    setIsFetching(true);

    try {
      const response: any = await getRechargeHistoriesMainAgent(query);
      setListHistory(response.data);
    } catch (error) {}
    setIsFetching(false);
  };
  useEffect(() => {
    handleListHistory();
     // Thiết lập interval để gọi API mỗi 30 giây
     const intervalId = setInterval(handleListHistory, TIMER_DEPOSIT);
    
     // Dọn dẹp interval khi component bị unmount
     return () => clearInterval(intervalId);
  }, [query]);

  useEffect(() => {
    handleListCarCompanyApi();
  }, [query]);

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    setPage(1)
  }, [actionSearch])

  return (
    <Layout className="wrapper-account-deposit">
      <Card>
        <Row className="fw-500">
          <Typography className="title-account-deposit">
            {t("Lịch sử yêu cầu nạp tiền")}
          </Typography>
        </Row>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        <Form
          className="mg-16 mt-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          onFinish={onSearch}
          colon={false}
        >
          <Form.Item
            className="label-search mb-0"
            labelAlign="left"
            labelCol={{ md: 0 }}
          >
            <Row gutter={10}>
              <Col>
                <Form.Item name="keyword">
                  <Select
                    style={{ width: 312 }}
                    showSearch
                    placeholder="Chọn tổng đại lý"
                    optionFilterProp="children"
                    onChange={onChangeSelectAgent}
                    // onSearch={onSearch}
                    filterOption={filterOption}
                    options={[
                      { value: "", label: "Chọn tổng đại lý" },
                      ...selectListBusCompany,
                    ]}
                  />
                </Form.Item>
              </Col>
              {
                <Col>
                  <Form.Item name="group">
                    <RangePicker
                      style={{ width: 304 }}
                      format="DD/MM/YYYY"
                      picker="date"
                      placeholder={["Từ ngày", "Đến ngày"]}
                      onChange={handleDateRangeChange}
                    />
                  </Form.Item>
                </Col>
              }
            </Row>
          </Form.Item>
        </Form>

        <Table
          className="Report__table"
          locale={{
            triggerDesc: "Sắp xếp tăng dần",
            triggerAsc: "Hủy sắp xếp",
            cancelSort: "Sắp xếp giảm dần",
          }}
          bordered={false}
          loading={isFetching}
          // rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={data?.length > 0 ? data : []}
          summary={(pageData) => {
            return (
              data?.length > 0 &&
              page === pagination?.last_page && (
                <Summary
                  total_confirmed={total_confirmed}
                  total_waiting={total_waiting}
                  total_cancel={total_cancel}
                  colSpan={6}
                />
              )
            );
          }}
        />
      </Card>

      <Modal
        open={isOpen}
        closable={false}
        width={600}
        onCancel={handleIsCancel}
        className="wrapper-confirm-deposit-modal"
        title={
          <div style={{ display: "flex", gap: 20 }}>
            <Image
              preview={false}
              width={20}
              src={IconExclamationCircle}
              alt="Icon Exclamation Circle"
            />
            <Typography className="confirm-title-deposit-modal">
              Xác nhận nạp tiền
            </Typography>
          </div>
        }
        footer={(_) => {
          return (
            <>
              <Col className="footer-content-confirm-deposit d-flex gap-12">
                <Button className="fs-18" onClick={handleIsCancel}>
                  {t("Trở lại")}
                </Button>
                <Button
                  className="btn-active-green fs-18"
                  type="primary"
                  htmlType="button"
                  onClick={() => handleOkModal(detailModal?.id, "confirm")}
                >
                  Xác nhận nạp tiền
                </Button>
              </Col>
            </>
          );
        }}
      >
        <div className="confirm-content-deposit-modal">
          <Typography
            style={{ marginBottom: 36, marginTop: 10 }}
            className="text-confirm-deposit-modal fs-20"
          >
            Bạn đang thao tác xác nhận yêu cầu nạp tiền này, bạn có chắc chắn
            muốn xác nhận?
          </Typography>
          <Col>
            <Row>
              <Col
                className="col-des-popconfirm col-des-pop-confirm"
                style={{ marginBottom: "16px" }}
              >
                <Image src={IcUser} />
                <span className="text-confirm-deposit-modal fs-20">
                  Tổng đại lý:
                </span>
              </Col>
              <Col className="text-confirm-deposit-modal fs-20 fw-600">
                {detailModal?.main_agent?.name}
              </Col>
            </Row>

            <Row>
              <Col
                className="col-des-popconfirm col-des-pop-confirm"
                style={{ marginBottom: "0px" }}
              >
                <Image src={IcPrice} />
                <span className="text-confirm-deposit-modal fs-20">
                  Số tiền:
                </span>
              </Col>
              <Col className="text-confirm-deposit-modal fs-20 fw-600">
                {formatPriceInput(detailModal?.amount)} VND
              </Col>
            </Row>
          </Col>
        </div>
      </Modal>
    </Layout>
  );
}

export default HistoryDepositMainAgent;
