import { Button, Card, Flex, Layout, Steps, Typography } from 'antd';
import iconRoute from '../../assets/images/segments/icon-route.svg';
import { useNavigate } from 'react-router-dom';

function StepSchedules({ step }: any) {
  const stepsCount = 5;
  const navigate = useNavigate();
  const handleRedirect = (step: any) => {
    switch (step) {
      case step === 1:
        return navigate('bus-management/locations');
      default:
        break;
    }
  };
  return (
    <div className="block-header-segment d-flex items-center justify-between">
      <div>
        <Typography.Text className="fw-500 fs-14">{`Bước ${step} / 5`}</Typography.Text>
        <div className="step-block">
          {[...Array(stepsCount)].map((_, index) => (
            <div key={index} className={`${step === index + 1 ? 'active' : ''} __item`}></div>
          ))}
        </div>
      </div>
      <Button
        htmlType="submit"
        className="btn-success continue d-flex items-center justify-center"
        
      >
        Tiếp tục
      </Button>
    </div>
  );
}

export default StepSchedules;
