/* eslint-disable react-hooks/exhaustive-deps */
import { SearchOutlined } from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Image, Input, Layout, Row, Select, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkAuthorization } from '../../api';
import { getOfficeAllProvinces, getOfficeAllUser } from '../../api/offices';
import icEdit from '../../assets/images/ic-edit.svg';
import IcPlus from '../../assets/images/ic-plus.svg';
import '../../assets/styles/listOffice.scss';
import StatusFilter from '../../components/Modal/StatusFilter';
import TutorialsVideo from '../../components/TutorialsVideo';
import { Constant } from '../../config/constant';
import { mbTrim } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchListOffice, listOfficeSelector } from '../../redux/slice/offices/listOfficeSlice';
import { ListUserRequest } from '../../types';
import { handleGetUrlGuide } from '../../utils/helpers';
import CreateOffice from './createOffice/CreateOffice';
import EditOffice from './editOffice/EditOffice';
function ListOffice() {
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [status, setStatus] = useState<boolean>();
  const [keyword, setKeyword] = useState<ListUserRequest>({});
  const [page, setPage] = useState<number>(1);
  const [province, setProvince] = useState<string>();
  // const [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZE);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [listUser, setListUser] = useState([]);
  const [listProvince, setListProvince] = useState([]);
  const [detailOffice, setDetailOffice] = useState();
  const listTutorials = useAppSelector((state) => state?.tutorials?.listTutorials);

  const { listOffices, loading, pagination } = useAppSelector(listOfficeSelector);

  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: any }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const renderRowTable = (record: any) => {
    return record.status ? '' : 'bg-disabled';
  };

  const columns: ColumnsType<any> = [
    // {
    //   title: "ID",
    //   key: "id",
    //   dataIndex: "id",
    //   render: (_, record) => {
    //     return (
    //       <Space size="middle">
    //         <FontAwesomeIcon
    //           icon={faCircle}
    //           className={`${record.status ? "green" : "danger"} p-1`}
    //           size="xs"
    //         />
    //         <Typography className="text-color">0{record.id}</Typography>
    //       </Space>
    //     );
    //   },
    // },
    {
      title: 'STT',
      key: 'id',
      render: (_: any, record: any, index: number) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <FontAwesomeIcon icon={faCircle} className={`${record.status ? 'green' : 'danger'} p-1`} size="xs" />
            <Typography className="text-color">{(page - 1) * limit + index + 1}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Tên văn phòng',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
    },
    {
      title: 'Tỉnh/ Thành phố',
      dataIndex: 'address',
      key: 'address',
      render: (_, record: any) => record?.province?.name,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Space size="middle" onClick={() => showModal(record)} style={{ cursor: 'pointer' }}>
              <Image src={icEdit} preview={false} />
            </Space>
          </>
        );
      },
    },
  ];

  const showModal = (item: any) => {
    setDetailOffice(item);
    setOpen(true);
  };

  const openModalCreateUser = () => {
    setOpenModal(true);
  };

  const onSearch = (value: ListUserRequest) => {
    form.setFields([{ name: 'keyword', value: mbTrim(value.keyword) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(form.getFieldsValue(['keyword']));
  };

  const onChangePage = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };

  const handleChangeStatus = (value: boolean | undefined) => {
    setStatus(value);
  };

  const callListOfficeApi = () => {
    dispatch(
      fetchListOffice({
        ...keyword,
        province_id: province,
        page,
        status,
        limit,
      }),
    );
  };

  useEffect(() => {
    callListOfficeApi();
  }, [dispatch, keyword, page, status, limit, province]);

  const onChangeProvince = (value: string) => {
    setProvince(value);
  };

  const callOfficeAllUser = async () => {
    try {
      const res = await getOfficeAllUser();
      if (res.status === Constant.DEFAULT_STATUS) {
        setListUser(res.data.data);
      }
    } catch (error) {
      checkAuthorization(error);
    }
  };
  const callOfficeAllProvinces = async () => {
    try {
      const res = await getOfficeAllProvinces();
      if (res.status === Constant.DEFAULT_STATUS) {
        setListProvince(res.data.data);
      }
    } catch (error) {
      checkAuthorization(error);
    }
  };
  useEffect(() => {
    callOfficeAllUser();
    callOfficeAllProvinces();
  }, []);

  const selectProvince =
    listProvince?.length > 0
      ? listProvince.map((item: any) => ({
          value: +item.id,
          label: item.name,
        }))
      : [];
  const selectAll = { value: '', label: 'Tất cả' };

  return (
    <Layout className="min-vh-100 wrapper_list_offices">
      <Card>
        <StatusFilter status={status} onChangeStatus={handleChangeStatus} />
        <Row style={{ display: 'flex', justifyContent: 'space-between' }} className="fw-500 mt-4 mx-3">
          <Button type="primary" className={'btn-success btn-add'} onClick={openModalCreateUser} size="large">
            <Image src={IcPlus} preview={false} className="mb-1 pe-2" />
            {t('Thêm văn phòng nhà xe')}
          </Button>
        </Row>

        <Form
          className="mg-16 mt-3 mx-3"
          form={form}
          labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
          requiredMark={false}
          onFinish={onSearch}
          colon={false}
          style={{ position: 'relative' }}
        >
          <Form.Item
            className="label-search mb-0"
            // style={{position: "relative", display: "flex"}}
            labelAlign="left"
            labelCol={{ md: 0 }}
          >
            <Row gutter={10}>
              <Col xs={24} sm={12} md={10} lg={10} xl={7} xxl={5}>
                <Form.Item name="keyword">
                  <Input
                    placeholder={t('Nhập tên văn phòng tìm kiếm')}
                    size="large"
                    autoComplete="off"
                    className="input-h36 padding-normal input-search-user"
                    prefix={
                      <SearchOutlined
                        style={{
                          color: '#435869',
                          fontSize: '14.5px',
                          marginRight: 5,
                        }}
                      />
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={6} xl={4} xxl={3}>
                <Form.Item name="group">
                  <Select
                    className="input-h36 input-search-user"
                    placeholder={t('Tỉnh/ Thành phố')}
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeProvince}
                    style={{ width: '100%' }}
                    options={[selectAll, ...selectProvince]}
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col className="text-center">
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  className={'carlink-btn btn-search btn-small padding-normal'}
                >
                  Tìm kiếm
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Space size={10} align="center" style={{ position: 'absolute', right: 0, top: 7 }}>
            <TutorialsVideo url_youtube={handleGetUrlGuide(listTutorials, 3)} />
          </Space>
        </Form>

        <Table
          locale={{
            triggerDesc: 'Sắp xếp tăng dần',
            triggerAsc: 'Hủy sắp xếp',
            cancelSort: 'Sắp xếp giảm dần',
          }}
          bordered={false}
          loading={loading}
          rowClassName={renderRowTable}
          pagination={{
            total: pagination?.total,
            current: page,
            pageSize: pagination?.per_page ?? limitPagination,
            position: ['bottomRight'],
            onChange: onChangePage,
            showSizeChanger: true,
          }}
          // scroll={{ y: 600 }}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={listOffices?.length > 0 ? listOffices : []}
          className="mx-3"
        />
      </Card>
      <>
        {open ? (
          <EditOffice
            listTutorials={listTutorials}
            listUser={listUser}
            listProvince={listProvince}
            callListOfficeApi={callListOfficeApi}
            setOpen={setOpen}
            open={open}
            detailOffice={detailOffice}
          />
        ) : (
          <></>
        )}
      </>
      {openModal ? (
        <CreateOffice
          listUser={listUser}
          listProvince={listProvince}
          callListOfficeApi={callListOfficeApi}
          setOpen={setOpenModal}
          open={openModal}
        />
      ) : (
        <></>
      )}
    </Layout>
  );
}

export default ListOffice;
