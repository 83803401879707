import { ParamsReport } from "../types/report";
import { getAxiosClientWithToken } from "./index";

// Báo cáo chi tiết
export const getListReportDetailAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/booking', { params });
};

export const getListReportDetailExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/booking/all', { params });
};

// Báo cáo tổng hợp theo tuyến
export const getListReportTotalRouteAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/routes', { params });
};

export const getListReportTotalRouteExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/routes/all', { params });
}

// Báo cáo tổng hợp theo đại lý
export const getListReportTotalAgentAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/agent', { params });
};

export const getListReportTotalAgentExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/agent/all', { params });
}

// Báo cáo tài xế, phụ xe
export const getListReportDriverAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/driver', { params });
}

export const getListReportDriverExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/driver/all', { params });
}

// Báo cáo khách hàng 
export const getListReportCustomerAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/user', { params });
}

export const getListReportCustomerExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/user/all', { params });
}

// Báo cáo hoàn hủy
export const getListReportCancelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/status', { params });
}

export const getListReportCancelExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/status/all', { params });
}

// Báo cáo hợp đồng thuê xe
export const getListReportContractAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/contract', { params });
}

export const getListReportContractExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/contract/all', { params });
}

// Báo cáo tiền nạp
export const getListReportRechargeAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/recharge', { params });
}

export const getListReportRechargeExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/recharge/all', { params });
}

// Báo cáo tổng hợp 
export const getListReportTotalAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/agent', { params });
}

export const getListReportTotalExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/agent/all', { params });
}

// Báo cáo biến động số dư 
export const getListReportBalanceChangeAgentAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/balance-changes', { params });
}

export const getListReportBalanceChangeAgentExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/balance-changes/all', { params });
}

// Báo cáo tiền nạp Carlink
export const getListReportRechargeCarlinkAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/recharge-companies', { params });
}

export const getListReportRechargeCarlinkExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/recharge-companies/all', { params });
}

// Báo cáo phí xuất bến
export const getListReportFeeDepartAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/departure-fee', { params });
}

export const getListReportFeeDepartExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/departure-fee/all', { params });
}

// Báo cáo phí xuất vé
export const getListReportExportTicketAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/export-ticket-fee', { params });
}

export const getListReportExportTicketExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/export-ticket-fee/all', { params });
}

// Báo cáo nạp tiền nhà xe 
export const getListReportRechargeCompanyCarlinkAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/recharge-companies', { params });
}

export const getListReportRechargeCompanyCarlinkExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/recharge-companies/all', { params });
}

// Báo cáo phí xuất bến nhà xe
export const getListReportFeeDepartCompanyCarlinkAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/departure-fee', { params });
}

export const getListReportFeeDepartCompanyCarlinkExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/departure-fee/all', { params });
}

// Báo cáo phí xuất vé nhà xe
export const getListReportFeeExportTicketCompanyCarlinkAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/export-ticket-fee', { params });
}

export const getListReportFeeExportTicketCompanyCarlinkExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/export-ticket-fee/all', { params });
}

// Báo cáo nạp tiền KM nhà xe
export const getListReportDepositPromotionCompanyCarlinkAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/recharge-companies-carlink', { params });
}

export const getListReportDepositPromotionCompanyCarlinkExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/recharge-companies-carlink/all', { params });
}

// Báo cáo hoàn hủy Carlink
export const getListReportCancelCarlinkAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/refund-cancel', { params });
}

export const getListReportCancelCarlinkExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/refund-cancel/all', { params });
}

// Danh sách tuyến báo cáo 
export const getListRouteAPI = () => {
    return getAxiosClientWithToken().get('/report/list-route');
}

// Danh sách văn phòng báo cáo
export const getListOfficeAPI = () => {
    return getAxiosClientWithToken().get('/report/list-office');
}

// Danh sách đại lý báo cáo
export const getListAgentAPI = () => {
    return getAxiosClientWithToken().get('/report/list-office');
}

// Danh sách thời gian khởi hành theo tuyến
export const getListDepartTimeByRouteAPI = (id: any) => {
    return getAxiosClientWithToken().get(`/report/list-depart-time?route_id=${id}`);
}

// Danh sách tài xế báo cáo
export const getListDriverAPI = () => {
    return getAxiosClientWithToken().get('/report/list-driver');
}

// Danh sách nhân viên bán vé báo cáo
export const getListUserAPI = () => {
    return getAxiosClientWithToken().get('/report/list-ticker-seller');
}

// Danh sách nhà xe
export const getListCompanyAPI = () => {
    return getAxiosClientWithToken().get('/report/list-car-company');
}

// Danh sách nhà xe tổng đại lý 
export const getListCompanyMainAgentAPI = () => {
    return getAxiosClientWithToken().get('/report/list-car-company/main-agent');
}

// Báo cáo tổng đại lý
export const getListReportDetailMainAgentAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/main-agents', { params });
};

export const getListReportDetailMainAgentExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/main-agents/all', { params });
};

// Danh sách tổng đại lý 
export const getListMainAgentAPI = () => {
    return getAxiosClientWithToken().get('/report/list-main-agent');
}

// Báo cáo biến động số dư tổng đại lý 
export const getListReportBalanceChangeMainAgentAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/balance-changes/main-agent', { params });
}

export const getListReportBalanceChangeMainAgentExcelAPI = (params?: ParamsReport) => {
    return getAxiosClientWithToken().get('/report/balance-changes/main-agent/all', { params });
}