import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { getErrorMessage } from '../../api';
import { getBookingContractRouteApi } from '../../api/contract';
import {
  createRouteAPI,
  getAllRouteApi,
  getListProvinceWithRoutesAPI,
  getListRoutesAPI,
  getRouteAPI,
  updateRouteAPI,
  updateStatusRouteAPI,
} from '../../api/route';
import { Constant } from '../../config/constant';
import { IDRoute, ListRouteRequest, ListRouteState, RouteParams, UpdateRoute } from '../../types/route';
import { RootState } from '../store';
import { setProvince } from './provinceSlice';

// @ts-ignore

//Create route
export const createRoute = createAsyncThunk(
  'route/create',
  async (params: RouteParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await createRouteAPI(params);
      const { data, status } = response;
      if (status) return response.data;
      return true;
    } catch (error: any) {
      const { response } = error;
      dispatch(setError(response));
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

// List route
export const fetchListRoute = createAsyncThunk(
  'routes/list',
  async (params: ListRouteRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListRoutesAPI(params);
      const { data, success, pagination } = response?.data;
      if (success) {
        dispatch(setListRoute({ routes: data, pagination: pagination }));
        return true;
      }
    } catch (error: any) {
      dispatch(setListRoute(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

// List route
export const fetchListProvincesWithRoute = createAsyncThunk(
  'routes/provinces',
  async (_: void, { dispatch, rejectWithValue }) => {
    try {
      const response = await getListProvinceWithRoutesAPI();
      const { data, success } = response?.data;
      if (success) {
        dispatch(setListProvinces(data));
        return true;
      }
    } catch (error: any) {
      dispatch(setProvince(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const fetchInfoRoute = createAsyncThunk('route/id', async (params: IDRoute, { dispatch, rejectWithValue }) => {
  try {
    const response = await getRouteAPI(params);

    const { data, success } = response?.data;
    if (success) {
      dispatch(setRoute(data));
      return true;
    }
  } catch (error: any) {
    dispatch(setRoute(error));
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});

export const updateRoute = createAsyncThunk(
  'route/update',
  async (params: UpdateRoute, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateRouteAPI(params.id, params);
      const { data, success } = response?.data;

      if (success) {
        // dispatch(setRoute({status: success, data: data}));
        return response.data;
      }
    } catch (error: any) {
      const { response } = error;
      dispatch(setError(response));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);

export const updateStatusRoute = createAsyncThunk(
  'route/update-status',
  async (params: UpdateRoute, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateStatusRouteAPI(params.id, params.status);
      const { data, success } = response?.data;

      if (success) {
        // dispatch(setRoute({status: success, data: data}));
        return response.data;
      }
    } catch (error: any) {
      dispatch(setRoute(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getAllRoute = createAsyncThunk(
  'routes/all-routes',
  async (params: ListRouteRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await getAllRouteApi(params);
      const { data, success, pagination } = response?.data;
      if (success) {
        dispatch(setListAllRoutes({ routes: data, pagination: pagination }));
        return true;
      }
    } catch (error: any) {
      dispatch(setListAllRoutes(error));
      return rejectWithValue(getErrorMessage(error));
    }
    return false;
  },
);
export const getContractRoute = createAsyncThunk('admin/route/contract', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await getBookingContractRouteApi();
    const { data, status } = response;
    if (status === Constant.DEFAULT_STATUS) {
      dispatch(setListRouteContract(data));
      return true;
    }
  } catch (error: any) {
    dispatch(setListRouteContract(error));
    return rejectWithValue(getErrorMessage(error));
  }
  return false;
});

export const routeSlice = createSlice({
  name: 'routeSlice',
  initialState: {
    error: false,
    loading: false,
    success: false,
    status: false,
    message: '',
    errorDetail: null,
    routes: [],
    route: {
      id: null,
      depart_province_id: undefined,
      arrive_province_id: undefined,
      depart_point: '',
      arrive_point: '',
      status: undefined,
    },
    routeContract: [],
    listAllRoute: [],
    provinces: [],
    pagination: {
      total: 0,
      per_page: Constant.PAGE_SIZE,
      current_page: Constant.DEFAULT_PAGE,
      last_page: Constant.DEFAULT_PAGE,
    },
  } as ListRouteState,
  reducers: {
    setListRoute: (state: ListRouteState, { payload }) => {
      const { routes, pagination } = payload;
      state.routes = routes;
      state.pagination = pagination;
      state.status = payload?.response?.status;
    },
    setRoute: (state: ListRouteState, { payload }) => {
      state.route = payload;
    },
    setListProvinces: (state: ListRouteState, { payload }) => {
      state.provinces = payload;
    },
    setError: (state: ListRouteState, { payload }) => {
      const { errors } = payload.data;
      state.status = payload?.status;
      state.errorDetail = errors;
    },
    resetState: (state: ListRouteState) => {
      state.success = false;
      state.message = '';
      state.errorDetail = null;
    },
    setListAllRoutes: (state: ListRouteState, { payload }) => {
      state.listAllRoute = payload?.routes;
    },
    setListRouteContract: (state: ListRouteState, { payload }) => {
      state.routeContract = payload?.data;
    },
  },
  extraReducers: (builder) => {
    //Create route
    builder.addCase(createRoute.pending, (state: ListRouteState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(createRoute.fulfilled, (state: ListRouteState, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.route = action.payload.data;
    });
    builder.addCase(createRoute.rejected, (state: ListRouteState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });

    // Update Route
    builder.addCase(updateRoute.pending, (state: ListRouteState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(updateRoute.fulfilled, (state: ListRouteState, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.message = action?.payload?.message;
      state.route = action.payload.data;
    });
    builder.addCase(updateRoute.rejected, (state: ListRouteState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });

    // Update status Route
    builder.addCase(updateStatusRoute.pending, (state: ListRouteState) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(updateStatusRoute.fulfilled, (state: ListRouteState, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.message = action?.payload?.message;
      state.route = action.payload.data;
    });
    builder.addCase(updateStatusRoute.rejected, (state: ListRouteState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });

    // List Route
    builder.addCase(fetchListRoute.pending, (state: ListRouteState, action) => {
      state.loading = true;
    });
    builder.addCase(fetchListRoute.fulfilled, (state: ListRouteState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchListRoute.rejected, (state: ListRouteState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });

    // List Route
    builder.addCase(fetchListProvincesWithRoute.pending, (state: ListRouteState, action) => {
      state.loading = true;
    });
    builder.addCase(fetchListProvincesWithRoute.fulfilled, (state: ListRouteState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchListProvincesWithRoute.rejected, (state: ListRouteState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });

    // Info Route
    builder.addCase(fetchInfoRoute.pending, (state: ListRouteState) => {
      state.loading = true;
    });
    builder.addCase(fetchInfoRoute.fulfilled, (state: ListRouteState) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(fetchInfoRoute.rejected, (state: ListRouteState) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export const routeSelector = (state: RootState) => state.route;
export const {
  setListRoute,
  setRoute,
  resetState,
  setListProvinces,
  setError,
  setListAllRoutes,
  setListRouteContract,
} = routeSlice.actions;
