import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { ColumnsType } from 'antd/es/table';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconBalance from '../../assets/images/ic-balance.svg';
import iconBank from '../../assets/images/ic-bank.svg';
import iconCash from '../../assets/images/ic-cash.svg';
import iconExport from '../../assets/images/ic-excel.svg';
import iconNext from '../../assets/images/ic-next.svg';
import iconPrev from '../../assets/images/ic-prev.svg';
import iconDebt from '../../assets/images/ic-debt.svg';
import { Constant, DateFormat, YearFormat } from '../../config/constant';
import { convertTimeFormat, formatDate, formattedPrice, getCancelReason, mbTrim } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getListHistoryBooking, getListStaff, listBookingSelector } from '../../redux/slice/bookings/listBooking';
import { DatePickerProps } from 'antd/lib';

function ModalHistoryBooking({
  okText,
  cancelText,
  modalOpen,
  onOk,
  onCancel,
  listBooking,
  historyDetail,
  isGetHistory,
  isCancel,
  dataCustomerBooking,
}: any) {
  const { listHistoryBooking, paginationHistory, loadingHistory, listStaff } = useAppSelector(listBookingSelector);
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { RangePicker } = DatePicker;
  const [formSearch]: [FormInstance] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(modalOpen);
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<any>({});
  const [staff_id, setStaffId] = useState<any>({});
  const [dataExportCsv, setDataExportCsv] = useState<any>([]);
  const currentDate = dayjs();

  const rangeDate = Form.useWatch('range_date', formSearch);
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const modalHandleOk = () => {
    onOk();
  };

  const modalHandleCancel = () => {
    onCancel();
    setPage(1);
    formSearch.resetFields();
    setStaffId(undefined);
  };
  useEffect(() => {
    setIsModalOpen(modalOpen);
  }, [modalOpen]);

  const handleSearch = (values: any) => {
    formSearch.setFields([{ name: 'keyword', value: mbTrim(`${values.keyword}`) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(formSearch.getFieldsValue(['keyword']));
  };
  const handleChangeStaff = (value: string) => {
    setStaffId(value);
    setPage(1);
  };
  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword({ ...keyword, keyword: e.target.value });
  };
  function onSearchBus() {}
  const columns: ColumnsType<any> = [
    {
      title: 'Thời gian',
      dataIndex: '',
      key: 'time_history',
      width: '100px',
      render: (record) => (
        <div className="align-start">
          <Typography style={{ color: '#435869' }}>{formatDate(record?.order_date, 'DD/MM/YYYY')}</Typography>
          <Typography style={{ color: '#7B7B7B' }}>{record?.order_time}</Typography>
        </div>
      ),
    },
    {
      title: 'Nhân viên',
      dataIndex: '',
      key: 'staff_name',
      width: '140px',
      render: (record) => (
        <div>
          <Typography style={{ color: '#435869' }}>{record?.staff}</Typography>
          <Typography style={{ color: '#7B7B7B' }}>{record?.office}</Typography>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      key: 'action_history',
      width: '430px',
      render: (record) => (
        <>
          <div className="d-flex" style={{ columnGap: '12px' }}>
            <Typography>
              <span
                className={record?.status === 'paid' ? 'green' : record?.status === 'cancel' ? 'red' : 'reserve'}
                style={
                  record?.action === 'Hủy vé do quá hạn giữ chỗ' || record?.action === ''
                    ? { color: '#D81717' }
                    : record?.action === 'Tạo hợp đồng'
                    ? { color: 'green' }
                    : {}
                }
              >
                {record?.action}
              </span>
              {record?.code || record?.ticket_code
                ? record?.code
                  ? ` - ${record?.code}`
                  : ` - ${record?.ticket_code}`
                : ''}
              {` ${
                record?.status === 'cancel' || record?.action === 'Hủy hợp đồng' || record?.action === 'Hủy vé'
                  ? `- Hoàn lại: ${
                     record?.action === 'Hủy vé do quá hạn giữ chỗ' ? 0 : record?.price_change ? record?.price_change : formattedPrice(record?.price)
                    }đ ${
                      record?.payer?.agent?.id
                        ? `cho tài khoản ${record?.payer?.name}`
                        : record?.group === 'main_agent'
                        ? 'cho Carlink API'
                        : 'cho khách'
                    }`
                  : record?.status === 'reserve' || record?.status === 'hold'
                  ? `- Giá vé: ${record?.price_change ? record?.price_change : formattedPrice(record?.price)}đ`
                  : record?.action === 'Hủy vé do quá hạn giữ chỗ'
                  ? ''
                  : `- Thanh toán: ${record?.price_change ? record?.price_change : formattedPrice(record?.price)}đ`
              }`}
            </Typography>
            {(record?.payment_method === 'agent_balance' ||
              record?.payment_method === 'cash' ||
              record?.payment_method === 'bank_transfer') &&
              record?.status !== 'cancel' &&
              record?.status !== 'reserve' && (
                <img
                  src={
                    record?.group === 'main_agent' && record?.status === 'paid'
                      ? iconDebt
                      : record?.payment_method === 'agent_balance'
                      ? iconBalance
                      : record?.payment_method === 'cash'
                      ? iconCash
                      : record?.payment_method === 'bank_transfer'
                      ? iconBank
                      : ''
                  }
                  alt=""
                />
              )}
          </div>
          {dataCustomerBooking ? (
            <>
              {dataCustomerBooking?.action === 'Chuyển vé' ? (
                <>
                  <Typography style={{ color: '#7B7B7B' }}>
                    Từ: {dataCustomerBooking?.old_ticket_info?.seat_number}
                    {`${dataCustomerBooking?.route?.depart_point ?? ''}  ${
                      dataCustomerBooking?.route?.arrive_point ? `- ${dataCustomerBooking?.route?.arrive_point}` : ''
                    } | ${
                      dataCustomerBooking?.old_ticket_info?.depart_time
                        ? dataCustomerBooking?.old_ticket_info?.depart_time?.slice(0, 5)
                        : ''
                    } - ${formatDate(dataCustomerBooking?.depart_date, 'DD/MM/YYYY')}`}
                  </Typography>
                  <Typography style={{ color: '#7B7B7B' }}>
                    Đến: {dataCustomerBooking?.old_ticket_info?.new_seat_number}
                    {`${dataCustomerBooking?.route?.depart_point ?? ''}  ${
                      dataCustomerBooking?.route?.arrive_point ? `- ${dataCustomerBooking?.route?.arrive_point}` : ''
                    } | ${
                      dataCustomerBooking?.depart_time ? dataCustomerBooking?.depart_time?.slice(0, 5) : ''
                    } - ${formatDate(dataCustomerBooking?.depart_date, 'DD/MM/YYYY')}`}
                  </Typography>
                  {record?.booking_for_agent ? (
                    <Typography style={{ color: '#3a3a3a' }}>
                      {`(Vé đặt hộ đại lý: ${record?.booking_for_agent})`}
                    </Typography>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Typography style={{ color: '#7B7B7B' }}>{`${dataCustomerBooking?.route?.depart_point ?? ''} ${
                  dataCustomerBooking?.route?.arrive_point ? `- ${dataCustomerBooking?.route?.arrive_point}` : ''
                } | ${
                  dataCustomerBooking?.depart_time ? dataCustomerBooking?.depart_time?.slice(0, 5) : ''
                } - ${formatDate(dataCustomerBooking?.arrive_date, 'DD/MM/YYYY')}`}</Typography>
              )}
            </>
          ) : !historyDetail ? (
            <>
              {record?.old_ticket_info?.seat_number ? (
                <>
                  <Typography style={{ color: '#7B7B7B' }}>
                    Từ: {record?.old_ticket_info?.seat_number} -
                    {` ${record?.depart_location ?? ''}  ${
                      record?.arrive_location ? ` ${record?.arrive_location ? `- ${record?.arrive_location}` : ''}` : ''
                    } | ${
                      record?.old_ticket_info?.depart_time ? record?.old_ticket_info?.depart_time?.slice(0, 5) : ''
                    } - ${formatDate(record?.depart_date, 'DD/MM/YYYY')}`}
                  </Typography>
                  <Typography style={{ color: '#7B7B7B' }}>
                    Đến: {record?.old_ticket_info?.new_seat_number} -
                    {` ${record?.depart_location ?? ''}  ${
                      record?.arrive_location ? ` ${record?.arrive_location ? `- ${record?.arrive_location}` : ''}` : ''
                    } | ${record?.depart_time ? record?.depart_time?.slice(0, 5) : ''} - ${formatDate(
                      record?.depart_date,
                      'DD/MM/YYYY',
                    )}`}
                  </Typography>
                  {record?.booking_for_agent ? (
                    <Typography style={{ color: '#3a3a3a' }}>
                      {`(Vé đặt hộ đại lý: ${record?.booking_for_agent})`}
                    </Typography>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <>
                  <Typography style={{ color: '#7B7B7B' }}>
                    {record?.seat_number ? `Ghế: ${record?.seat_number} - ` : ''}
                    {` ${record?.depart_location ?? ''}  ${
                      record?.arrive_location ? ` ${record?.arrive_location ? `- ${record?.arrive_location}` : ''}` : ''
                    } ${record?.depart_time ? `| ${record?.depart_time?.slice(0, 5)}` : ''}  ${
                      record?.depart_date ? `- ${dayjs(record?.depart_date).format('DD/MM/YYYY')}` : ''
                    }`}
                  </Typography>
                  {record?.booking_for_agent ? (
                    <Typography style={{ color: '#3a3a3a' }}>
                      {`(Vé đặt hộ đại lý: ${record?.booking_for_agent})`}
                    </Typography>
                  ) : (
                    ''
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {record?.old_ticket_info?.seat_number ? (
                <>
                  <Typography style={{ color: '#7B7B7B' }}>
                    Từ: {record?.old_ticket_info?.seat_number} -
                    {` ${record?.depart_location ?? ''}  ${
                      record?.arrive_location ? ` ${record?.arrive_location ? `- ${record?.arrive_location}` : ''}` : ''
                    } | ${
                      record?.old_ticket_info?.depart_time ? record?.old_ticket_info?.depart_time?.slice(0, 5) : ''
                    } - ${formatDate(record?.depart_date, 'DD/MM/YYYY')}`}
                  </Typography>
                  <Typography style={{ color: '#7B7B7B' }}>
                    Đến: {record?.old_ticket_info?.new_seat_number} -
                    {` ${record?.depart_location ?? ''}  ${
                      record?.arrive_location ? ` ${record?.arrive_location ? `- ${record?.arrive_location}` : ''}` : ''
                    } | ${record?.depart_time ? record?.depart_time?.slice(0, 5) : ''} - ${formatDate(
                      record?.depart_date,
                      'DD/MM/YYYY',
                    )}`}
                  </Typography>
                  {record?.booking_for_agent ? (
                    <Typography style={{ color: '#3a3a3a' }}>
                      {`(Vé đặt hộ đại lý: ${record?.booking_for_agent})`}
                    </Typography>
                  ) : (
                    ''
                  )}
                </>
              ) : record?.depart_location && record?.arrive_location ? (
                <>
                  <Typography style={{ color: '#7B7B7B' }}>
                    {record?.seat_number ? `Ghế: ${record?.seat_number} - ` : ''}
                    {`${record?.depart_location ?? ''}  ${
                      record?.arrive_location ? `- ${record?.arrive_location}` : ''
                    } | ${record?.depart_time ? record?.depart_time?.slice(0, 5) : ''} - ${formatDate(
                      record?.depart_date,
                      'DD/MM/YYYY',
                    )}`}
                  </Typography>
                  {record?.booking_for_agent ? (
                    <Typography style={{ color: '#3a3a3a' }}>
                      {`(Vé đặt hộ đại lý: ${record?.booking_for_agent})`}
                    </Typography>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Typography style={{ color: '#7B7B7B' }}>
                  {`${listBooking?.schedule?.route?.depart_point ?? ''}  ${
                    listBooking?.schedule?.route?.arrive_point ? `- ${listBooking?.schedule?.route?.arrive_point}` : ''
                  } | ${
                    listBooking?.schedule?.depart_time ? listBooking?.schedule?.depart_time?.slice(0, 5) : ''
                  } - ${formatDate(listBooking?.travel_info?.arrive_time, 'DD/MM/YYYY')}`}
                </Typography>
              )}
            </>
          )}
          {record?.status === 'cancel' && record?.cancel_reason && (
            <Typography>Lý do: {getCancelReason(record?.cancel_reason)}</Typography>
          )}
          {record?.price_change && record?.action !== 'Chuyển vé' && record?.status !== 'cancel' && (
            <Typography>
              {`Đã sửa giá vé từ`} <span className="text-blue">{record?.price} đ</span> sang
              <span className="text-blue"> {record?.price_change} đ</span>
              {` (Mã pin của ${record?.admin_change_price})`}
            </Typography>
          )}
        </>
      ),
    },
  ];

  const columnsExport: IExcelColumn[] = [
    {
      title: 'Ngày',
      __excelTitle__: 'Ngày đặt',
      dataIndex: 'order_date',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
        width: 15,
      },
      width: 15,
    },
    {
      title: 'Giờ đặt',
      __excelTitle__: 'Giờ đặt',
      dataIndex: 'orderer_time',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
        width: 15,
      },
      width: 15,
    },
    {
      title: 'Mã vé',
      dataIndex: 'ticket_code',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Mã vé',
    },
    {
      title: 'Tuyến',
      dataIndex: 'depart_location',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
        width: 30,
      },
      width: 30,
      __excelTitle__: 'Tuyến',
    },
    {
      title: 'Ngày khởi hành',
      dataIndex: 'depart_date',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
      },
      __excelTitle__: 'Ngày khởi hành',
    },
    {
      title: 'Giờ khởi hành',
      dataIndex: 'depart_time',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
      },
      __excelTitle__: 'Giờ khởi hành',
    },
    {
      title: 'Giá vé',
      dataIndex: 'price',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Giá vé',
    },
    {
      title: 'Nhân viên',
      dataIndex: 'staff',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
      },
      __excelTitle__: 'Nhân viên',
    },
    {
      title: 'Văn phòng',
      dataIndex: 'office',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
        width: 20,
      },
      __excelTitle__: 'Văn phòng',
      width: 20,
    },
    {
      title: 'Hoàn lại',
      dataIndex: 'refund',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
        width: 15,
      },
      __excelTitle__: 'Thao tác',
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      __style__: {
        fontName: 'Times New Roman',
        fontSize: 14,
        borderColor: 'black',
        width: 50,
      },
      __excelTitle__: 'Thao tác',
    },
  ];
  const handlePagination = (page: number, pageSize: number) => {
    if (paginationHistory?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };
  const filterOptionStaff = (
    input: string,
    option?: { label: string; value: string; bus_name: string; bus_type: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (listHistoryBooking?.length) {
      const tmpListCustomerBooking = listHistoryBooking?.map((item: any) => {
        return {
          ...item,
          orderer_date: `${item?.orderer_date ? formatDate(item?.orderer_date, 'DD/MM/YYYY') : ''}`,
          orderer_time: item?.order_time,
          staff: item?.staff ?? '',
          office: item?.office?.name ?? 'Không có',
          price: item?.price ? `${item?.price}đ` : '',
          depart_location: `${item?.depart_location ?? ''}-${item?.arrive_location ?? ''}`,
          depart_date: item?.depart_date ? formatDate(item?.depart_date, 'DD/MM/YYYY') : '',
          depart_time: item?.depart_time ? convertTimeFormat(item?.depart_time) : '',
          refund: item?.status === 'cancel' ? `${item?.price}đ` : '0',
        };
      });
      setDataExportCsv(tmpListCustomerBooking);
    } else {
      setDataExportCsv([]);
    }
  }, [listHistoryBooking]);
  const handleClick = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Lịch sử thao tác vé');
    const header = columnsExport.map((col) => ({ header: col.title, key: col.dataIndex as string }));
    worksheet.columns = header;

    columnsExport.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width || 15;
    });
    worksheet.addRows(dataExportCsv);
    worksheet.getRow(1).font = { name: 'Times New Roman', size: 12, bold: true };
    worksheet.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3AB54A' } };
    worksheet.getRow(1).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    let firstRowHasData = false;
    worksheet.getRow(1).eachCell((cell) => {
      if (cell.value) {
        firstRowHasData = true;
        return;
      }
    });

    if (firstRowHasData) {
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber > 1) {
          row.eachCell((cell) => {
            cell.font = { name: 'Times New Roman', size: 12 };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
        }
      });
    } else {
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          let hasData = false;
          row.eachCell((cell) => {
            if (cell.value) {
              hasData = true;
              return;
            }
          });
          if (hasData) {
            row.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          }
        }
      });
    }
    const lastRow = worksheet.addRow([]);
    lastRow.getCell(1).value = '@Bản quyền thuộc nhà xe Carlink ';
    lastRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
    lastRow.getCell(1).font = { name: 'Times New Roman', size: 12 };
    const startColumnIndex = 2;
    const endColumnIndex = startColumnIndex + columnsExport.length - 1;
    worksheet.mergeCells(lastRow.number, 1, lastRow.number, endColumnIndex);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Lịch sử thao tác vé.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (isGetHistory) {
      dispatch(
        getListHistoryBooking({
          ...keyword,
          page,
          limit,
          from: rangeDate
            ? dayjs(rangeDate[0]).format(YearFormat)
            : accountDetail?.export_report_day
            ? dayjs(currentDate.subtract(accountDetail?.export_report_day, 'day')).format('YYYY-MM-DD')
            : undefined,
          to: rangeDate ? dayjs(rangeDate[1]).format(YearFormat) : undefined,
          staff_id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, limit, isGetHistory, keyword, rangeDate, staff_id, accountDetail]);

  useEffect(() => {
    dispatch(getListStaff());
  }, [dispatch]);

  const disabledRangeDaysDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    const exportDay = accountDetail?.export_report_day || 30;
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= exportDay;
    }
    return false;
  };
  return (
    <Modal
      open={isModalOpen}
      cancelText={cancelText}
      onCancel={modalHandleCancel}
      onOk={modalHandleOk}
      okText={okText}
      footer={[]}
      width={950}
      centered={true}
      maskClosable={false}
      className="history-booking-modal"
    >
      {!historyDetail ? (
        <>
          <Typography className="fw-600 fs-18">{t('Lịch sử thao tác vé')}</Typography>
          <Form
            className="mg-16 mt-2 form-history-search"
            form={formSearch}
            labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
            requiredMark={false}
            onFinish={handleSearch}
            colon={false}
          >
            <Form.Item className="label-search" labelAlign="left" labelCol={{ md: 0 }} name="form-search">
              <Row gutter={12}>
                <Col span={7}>
                  <Form.Item name="keyword">
                    <Input
                      placeholder={t('Nhập mã vé để tìm kiếm')}
                      suffix={<SearchOutlined className="ic-search-input" />}
                      onChange={handleKeywordChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={'staff_id'}>
                    <Select
                      placeholder="Nhân viên"
                      showSearch
                      onChange={handleChangeStaff}
                      value={staff_id}
                      onSearch={onSearchBus}
                      filterOption={filterOptionStaff}
                      options={
                        listStaff?.length > 0
                          ? listStaff?.map((item: any) => ({
                              value: item?.id,
                              label: item?.name,
                            }))
                          : []
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name="range_date" className="data-picker booking">
                    <RangePicker
                      placeholder={['Từ ngày', 'Đến ngày']}
                      style={{ height: '36px' }}
                      format={DateFormat}
                      onChange={() => setPage(1)}
                      disabledDate={disabledRangeDaysDate}
                      minDate={
                        accountDetail?.export_report_day
                          ? currentDate.subtract(accountDetail?.export_report_day, 'day')
                          : undefined
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Button className="btn-export" onClick={() => handleClick()}>
                    <img src={iconExport} alt="excel" />
                    Xuất excel
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <Typography className="fw-600 fs-18">{t('Lịch sử thao tác vé')}</Typography>
          <Row gutter={36} className="mt-2">
            <Col>
              <Typography className="history-info">
                Mã vé:
                <span className="history-detail"> {`${historyDetail?.history?.data[0]?.ticket_code ?? ''}`} </span>
              </Typography>
            </Col>
            <Col>
              <Typography className="history-info">
                Hành khách: <span className="history-detail">{historyDetail?.customer?.name ?? ''}</span>
              </Typography>
            </Col>
            <Col>
              <Typography className="history-info">
                {`Số điện thoại: `}
                <span className="history-detail">
                  {historyDetail?.source === 'openapi' &&
                  historyDetail?.status === 'cancel' &&
                  !historyDetail?.payer?.name
                    ? 'N/A'
                    : historyDetail?.customer?.phone ?? ''}
                </span>
              </Typography>
            </Col>
            <Col></Col>
          </Row>
          {isCancel ? (
            <>
              <Row gutter={36} className="mt-2">
                <Col>
                  <Typography className="history-info">
                    Tổng tiền:
                    <span className="history-detail">
                      {`${historyDetail?.final_price ? ` ${historyDetail?.final_price}đ` : ' 0đ'}`}
                    </span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="history-info">
                    Tổng phí hủy:
                    <span className="history-detail">{`${
                      historyDetail?.cancel_fee ? ` ${historyDetail?.cancel_fee}đ` : ' 0đ'
                    }`}</span>
                  </Typography>
                </Col>
                <Col>
                  <Typography className="history-info">
                    Hoàn trả:
                    <span className="history-detail">{`${
                      historyDetail?.refund ? ` ${historyDetail?.refund}đ` : ' 0đ'
                    }`}</span>
                  </Typography>
                </Col>
              </Row>
            </>
          ) : (
            ''
          )}
        </>
      )}
      {historyDetail ? (
        <Table
          locale={{
            triggerDesc: 'Sắp xếp giảm dần',
            triggerAsc: 'Sắp xếp tăng dần',
            cancelSort: 'Hủy sắp xếp',
          }}
          dataSource={historyDetail?.history?.data?.length > 0 ? historyDetail?.history?.data : []}
          key="historyTable"
          columns={columns}
          rowKey={`history`}
          className="list-bus mt-2"
          pagination={false}
        />
      ) : (
        <Table
          key="bookingHistoryTable"
          locale={{
            triggerDesc: 'Sắp xếp giảm dần',
            triggerAsc: 'Sắp xếp tăng dần',
            cancelSort: 'Hủy sắp xếp',
          }}
          dataSource={listHistoryBooking?.length > 0 ? listHistoryBooking : []}
          columns={columns}
          rowKey={`history-booking`}
          className="list-bus mt-2"
          pagination={false}
          loading={loadingHistory}
        />
      )}
      {!historyDetail && (
        <div className="pagination-table agency mt-3">
          <Pagination
            onChange={handlePagination}
            current={page}
            total={paginationHistory?.total}
            pageSize={paginationHistory?.per_page ?? limitPagination}
            showSizeChanger={true}
            prevIcon={<img src={iconPrev} alt="" />}
            nextIcon={<img src={iconNext} alt="" />}
          />
        </div>
      )}
    </Modal>
  );
}
export default ModalHistoryBooking;
