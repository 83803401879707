import {getAxiosClientWithToken} from "./index";
import { listOfficeRequest, officeParams } from "../types/office";

export const getListOfficeAPI = (params: listOfficeRequest) => {
    return getAxiosClientWithToken().get(`/offices?`, {params});
};

export const createOfficeAPI = (params: officeParams) => {
    return getAxiosClientWithToken().post(`/offices`, params);
};

export const updateOfficeAPI = (id: any, params: officeParams) => {
    return getAxiosClientWithToken().put(`offices/${id}`, params);
};

export const deleteOfficesAPI = (id: string | number) => {
    return getAxiosClientWithToken().delete(`offices/${id}`);
};


export const getOfficeByIdAPI = (id: any) => {
    return getAxiosClientWithToken().get(`offices/${id}`)
}

export const getOfficeStatusByIdAPI = (id:any,  params: any) => {
    return getAxiosClientWithToken().put(`offices/status/${id}`, params)
}

export const getOfficeAllUser = () => {
    return getAxiosClientWithToken().get(`/offices/get-all-user`)
}

export const getOfficeAllProvinces = () => {
    return getAxiosClientWithToken().get(`/area/provinces`)
}

export const getOfficeStatusByIdApi = (id: any, params: any) => {
    return getAxiosClientWithToken().put(`/offices/status/${id}`, params)
}
