import React, { useState } from "react";
import { Button, Flex, Modal } from "antd";
import "../styles/ModalRegisterDetail.scss";
import iconClose from "../../../assets/images/listUser/iconsClose.svg";
import { RegisterAdvertiseParams } from "../../../types/listRegisterAdvertise";
import { toast } from "react-toastify";
import { handleRegisterAdvertise } from "../api";
import { registerConfirmAPI } from "../../../api/notify";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchNotification } from "../../../redux/slice/notify/notificationSlice";
import { fetchCountRegistrations } from "../../../redux/slice/register/countRegistrations";

interface ModalRegisterDetailProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: RegisterAdvertiseParams;
  handleGetListAdvertise: () => void;
}

const ModalRegisterDetail = ({
  open,
  setOpen,
  data,
  handleGetListAdvertise,
}: ModalRegisterDetailProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);

  const dispatch = useAppDispatch();
  const handleProcess = async () => {
    setLoading(true);
    try {
      const res = await handleRegisterAdvertise({
        id: data?.id,
        is_handle: 1,
        user_handle_name: accountDetail?.username
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        setLoading(false);
        setOpen(false);
        handleGetListAdvertise();
        const resConfirm = await registerConfirmAPI({
          register_id: data?.id,
          type: "advertising",
        });
        if (resConfirm.status === 200) {
          let query = `page=1&limit=20`;
          dispatch(fetchNotification({ query }));
          dispatch(fetchCountRegistrations());
        }
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra vui lòng thử lại!");
      setLoading(false);
    }
  };
  return (
    <Modal
      width={690}
      closeIcon={false}
      footer={null}
      open={open}
      onCancel={() => setOpen(false)}
      rootClassName="ModalRegisterDetail"
    >
      <Flex align="center" justify="space-between">
        <p className="ModalRegisterDetail__heading">
          Thông tin đăng ký quảng cáo
        </p>
        <Flex
          className="cursor-pointer"
          align="center"
          justify="center"
          onClick={() => setOpen(false)}
        >
          <img
            className="object-fit-scale"
            width={24}
            height={24}
            src={iconClose}
            alt=""
          />
        </Flex>
      </Flex>
      <Flex className="flex-column gap-y-24 mt-24">
        <Flex align="center" className="gap-x-12">
          <p className="ModalRegisterDetail__title">Người liên hệ</p>
          <p className="ModalRegisterDetail__desc">{data?.name}</p>
        </Flex>
        <Flex align="center" className="gap-x-12">
          <p className="ModalRegisterDetail__title">Điện thoại</p>
          <p className="ModalRegisterDetail__desc">{data?.phone}</p>
        </Flex>
        <Flex align="center" className="gap-x-12">
          <p className="ModalRegisterDetail__title">Email</p>
          <p className="ModalRegisterDetail__desc">{data?.email}</p>
        </Flex>
        <Flex align="center" className="gap-x-12">
          <p className="ModalRegisterDetail__title">Gói quảng cáo đăng ký</p>
          <p className="ModalRegisterDetail__desc">{data?.package_name}</p>
        </Flex>
        <Flex align="center" className="gap-x-12">
          <p className="ModalRegisterDetail__title">Giá trị gói</p>
          <p className="ModalRegisterDetail__desc">{data?.package_value}</p>
        </Flex>
        <Flex align="center" className="gap-x-12">
          <p className="ModalRegisterDetail__title">Trạng thái</p>
          {data?.is_handle ? (
            <Flex
              className="status status--processed"
              justify="center"
              align="center"
            >
              Đã xử lý
            </Flex>
          ) : (
            <Flex className="status" justify="center" align="center">
              Chưa xử lý
            </Flex>
          )}
        </Flex>
        {data?.user_handle_name && (
          <Flex align="center" className="gap-x-12">
            <p className="ModalRegisterDetail__title">Người xử lý</p>
            <p className="ModalRegisterDetail__desc">
              {data?.user_handle_name}
            </p>
          </Flex>
        )}
      </Flex>
      {data?.is_handle ? (
        <Flex align="center" justify="flex-end" className="gap-x-8 mt-24">
          <Button
            className="ModalRegisterDetail__button ModalRegisterDetail__button--close"
            onClick={() => setOpen(false)}
          >
            Đóng
          </Button>
        </Flex>
      ) : (
        <Flex align="center" justify="flex-end" className="gap-x-8 mt-24">
          <Button
            className="ModalRegisterDetail__button ModalRegisterDetail__button--close"
            onClick={() => setOpen(false)}
          >
            Đóng
          </Button>
          <Button
            className="ModalRegisterDetail__button ModalRegisterDetail__button--submit"
            onClick={handleProcess}
            loading={loading}
            type="primary"
          >
            Nhận xử lý
          </Button>
        </Flex>
      )}
    </Modal>
  );
};

export default ModalRegisterDetail;
