import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import icLock2 from "../../assets/images/ic-lock-2.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  createRoute,
  routeSelector,
  setRoute,
  updateRoute,
  updateStatusRoute,
} from "../../redux/slice/routeSlice";
import { Constant } from "../../config/constant";
import { fetchPermissions } from "../../redux/slice/authSlice";
import { listUserSelector } from "../../redux/slice/userSlice";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import { provinceSelector } from "../../redux/slice/provinceSlice";
import { toast } from "react-toastify";
import { filterOption } from "../../lib/utils";
import { Rule } from "antd/es/form";
import "./routes.scss";
import TutorialsVideo from "../../components/TutorialsVideo";
import { handleGetUrlGuide } from "../../utils/helpers";
import icClose from "../../assets/images/listOffice/icClose.svg";

function RouteModal({
  open,
  setOpen,
  onCancelSuccess,
  listTutorials,
}: {
  open: boolean;
  setOpen: Function;
  onCancelSuccess: Function;
  listTutorials: any;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [userStatus, setUserStatus] = useState(Boolean);
  const dispatch = useAppDispatch();
  const { user, loading, success } = useAppSelector(listUserSelector);
  const [isModalConfirmVisible, setIsModalConfirmVisible] =
    useState<boolean>(false);

  const {
    loading: loadingRoute,
    success: isSuccess,
    route,
    errorDetail,
  } = useAppSelector(routeSelector);

  const { provinces } = useAppSelector(provinceSelector);

  const provincesOption: SelectProps["options"] = provinces.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const handleCancelModalConfirm = () => {
    setIsModalConfirmVisible(false);
  };

  const departRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập điểm đầu"),
    },
  ];

  const departProvinceRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng chọn tỉnh / thành phố"),
    },
  ];

  const arriveRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng nhập điểm cuối"),
    },
  ];

  const arriveProvinceRules: Rule[] = [
    {
      required: true,
      message: t("Vui lòng chọn tỉnh / thành phố"),
    },
  ];

  useEffect(() => {
    if (route) {
      form.setFieldsValue({
        depart_point: route.depart_point,
        arrive_point: route.arrive_point,
        depart_province_id: route.depart_province?.id,
        arrive_province_id: route.arrive_province?.id,
      });
    }
    setIsModalConfirmVisible(false);
  }, [route]);

  useEffect(() => {
    if (isSuccess === Constant.STATUS_ACTIVE) {
      handleModalCancel();
      onCancelSuccess();
    }
  }, [isSuccess]);

  const handleModalCancel = () => {
    setOpen(false);
    form.resetFields();
    dispatch(setRoute(undefined));
  };

  useEffect(() => {
    if (errorDetail) {
      form.setFields(
        Object.keys(errorDetail).map((fieldName) => ({
          name: fieldName,
          errors: [errorDetail[fieldName]],
        }))
      );
    }
  }, [errorDetail]);

  const handleLockRoute = (id: number, status: boolean) => () => {
    dispatch(
      updateStatusRoute({
        id: id,
        status: status,
      })
    )
      .then((response) => {
        toast.success("Thao tác thành công");
      })
      .catch((err) => {
        toast.error("Thao tác thất bại");
      });
  };

  const onFinish = (values: any) => {
    if (route) {
      values.id = route.id;
      dispatch(updateRoute(values))
        .then((response) => {
          toast.success("Thao tác thành công");
        })
        .catch((err) => {
          toast.error("Thao tác thất bại");
        });
    } else {
      dispatch(createRoute(values))
        .then((response) => {
          toast.success("Thao tác thành công");
        })
        .catch((err) => {
          toast.error("Thao tác thất bại");
        });
    }
  };

  // useEffect(() => {
  //     dispatch(fetchPermissions())
  // }, [dispatch]);

  useEffect(() => {
    if (success) {
      if (user.status === 1) {
        setUserStatus(true);
      } else {
        setUserStatus(false);
      }
    }
  }, [success]);

  return (
    <>
      <Modal
        className="route-modal"
        title={
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 15,
            }}
          >
            <Typography.Text className="fw-600 fs-18 ">
              {route ? t("Chi tiết tuyến") : t("Thêm tuyến xe")}
            </Typography.Text>
            <Space size={10} align="center">
              <TutorialsVideo
                url_youtube={handleGetUrlGuide(listTutorials, 18)}
              />
              <Image
                src={icClose}
                style={{ cursor: "pointer" }}
                preview={false}
                onClick={handleModalCancel}
              />
            </Space>
          </Row>
        }
        centered
        forceRender
        maskClosable={false}
        closeIcon={false}
        open={open}
        onCancel={handleModalCancel}
        styles={{
          body: { maxHeight: "90vh", overflowY: "auto", overflowX: "hidden" },
        }}
        width={742}
        footer={[
          <Row
            className="comp-modal-footer"
            style={{
              marginTop: "120px",
            }}
            key={1}
          >
            <Col>
              {route ? (
                <Col xs={24} md={24}>
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => setIsModalConfirmVisible(true)}
                    size="large"
                    className={
                      route.status
                        ? "h-btn-48 btn-block-red"
                        : "h-btn-48 btn-active-green"
                    }
                    disabled={loadingRoute}
                  >
                    <Image
                      src={icLock2}
                      preview={false}
                      className="mb-1 pe-2"
                    />
                    {route.status ? t("Khóa tuyến") : t("Mở khóa tuyến")}
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Col>
            <Col className="d-flex gap-12">
              <Button
                key="back"
                onClick={handleModalCancel}
                size="large"
                className="btn-cancel-modal"
                disabled={loadingRoute}
              >
                {t("Đóng")}
              </Button>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={form.submit}
                size="large"
                className="btn-save-modal"
                htmlType="submit"
                disabled={loadingRoute}
              >
                {t("Lưu")}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        {/* <Title level={4}>Chi tiết thông tin tài xế</Title> */}
        <Form
          name="extra-driver-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          validateTrigger="onSubmit"
          className="mt-0 w-85"
          labelCol={{ span: 12 }}
          colon={false}
          labelAlign={"left"}
          labelWrap
        >
          <Spin spinning={loadingRoute}>
            <Space direction="vertical" size={30} style={{ width: "100%" }}>
              <Row gutter={20}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="depart_point"
                    className="form-item-name required"
                    label={t("Điểm đầu")}
                    rules={departRules}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="depart_province_id"
                    className="form-item-name required"
                    label={t("Thuộc tỉnh / thành phố")}
                    rules={departProvinceRules}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Select
                      className="input-h36"
                      showSearch
                      filterOption={filterOption}
                      placeholder="Chọn tỉnh/ TP"
                      size="large"
                      // onChange={onChangeDriverType}
                      style={{ width: "100%" }}
                      options={provincesOption}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="arrive_point"
                    className="form-item-name required"
                    label={t("Điểm cuối")}
                    rules={arriveRules}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="arrive_province_id"
                    className="form-item-name required"
                    label={t("Thuộc tỉnh / thành phố")}
                    rules={arriveProvinceRules}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Select
                      className="input-h36"
                      showSearch
                      filterOption={filterOption}
                      placeholder="Chọn tỉnh/ TP"
                      size="large"
                      // onChange={onChangeDriverType}
                      style={{ width: "100%" }}
                      options={provincesOption}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Spin>
        </Form>
      </Modal>
      {route ? (
        <ModalConfirm
          modalOpen={isModalConfirmVisible}
          okText={route && route.status ? t("Khóa ngay") : t("Mở ngay")}
          cancelText={t("Đóng")}
          modalTitle={route && route.status ? t("Khoá tuyến") : t("Mở tuyến")}
          modalContent={
            route && route.status
              ? t(
                  "Bạn đang thao tác khóa tuyến này, bạn có chắc chắn muốn khóa?"
                )
              : t("Bạn đang thao tác mở tuyến này, bạn có chắc chắn muốn mở?")
          }
          onCancel={handleCancelModalConfirm}
          onOk={handleLockRoute(route.id, !route.status)}
        />
      ) : null}
    </>
  );
}

export default RouteModal;
