import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { convertTimeFormat, convertToWords, formattedPrice, getPaymentMethod } from '../../../lib/utils';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontWeight: 300 },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 600 },
  ],
});

// Define styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Roboto',
  },
  headerTitle: {
    fontSize: 11,
    fontWeight: 400,
  },
  title: {
    marginTop: 15,
  },
  titleContent: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 16,
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '1px',
  },
  textNormal: {
    fontSize: 11,
    fontWeight: 400,
  },
  container: {
    padding: 26,
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  containerPage2: {
    padding: 26,
  },

  text: {
    fontSize: 9,
    fontWeight: 500,
    color: '#435869',
  },
  textXs: {
    fontSize: 8,
    fontWeight: 500,
    color: '#435869',
  },
  logo: {
    width: 60,
    height: 50,
    objectFit: 'contain',
  },
  headerBooking: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 16,
    columnGap: 14,
    margin: 2,
  },
  fw600: {
    fontWeight: 600,
  },
  fw500: {
    fontWeight: 500,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  cardContent: {
    padding: 8,
  },
  blockTicket: {
    width: 251,
    height: 128,
    borderRadius: 4,
    border: '1px solid #DCDCDC',
    columnGap: 15,
    marginTop: 8,
    display: 'flex',
    marginRight: 10,
  },
  addressTicket: {
    paddingLeft: 4,
    borderLeft: '1px solid #435869',
    minHeight: 22,
  },
  mh100: {
    height: 50,
  },
  mt2: {
    marginTop: 5,
  },
  mt3: {
    marginTop: 3,
  },
  mt5: {
    marginTop: 5,
  },
  mt10: {
    marginTop: 18,
  },
  ml6: {
    marginLeft: 6,
  },
  bgSeatNoBooking: {
    backgroundColor: '#DCDCDC',
  },
  h158: {
    height: 158,
  },
  mt20: {
    marginTop: 15,
  },
  mauto: {
    margin: '0 auto',
  },
  fw700: {
    fontWeight: 700,
  },
  mb: {
    marginBottom: 23,
  },
  text1: {
    fontSize: 20,
    fontWeight: 700,
    color: '#3AB54A',
  },
  text2: {
    fontWeight: 400,
    fontSize: 11,
  },
  textBold: {
    fontWeight: 600,
    fontSize: 11,
    lineHeight: '2px',
  },
  textSemi: {
    fontWeight: 500,
    fontSize: 11,
  },
  textValue: {
    fontWeight: 500,
    fontSize: 11,
    color: '#647C8F',
    marginTop: 2,
    lineHeight: '2px',
  },
  textValue1: {
    fontWeight: 500,
    fontSize: 11,
    color: '#647C8F',
  },
  mt7: {
    marginTop: 7,
  },
  mt21: {
    marginTop: 21,
  },
  titleContract: {
    paddingLeft: 9,
    borderLeft: '4px solid #3AB54A',
  },
  pl17: {
    paddingLeft: 14,
  },
  titleHead: {
    paddingRight: 14,
    borderRight: '1px solid #BBBBBB',
    display: 'flex',
    alignItems: 'center',
  },
  dFlex: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '14px',
    alignItems: 'center',
  },
  mt16: {
    marginTop: 15,
  },
  button: {
    width: 320,
    height: 28,
    marginRight: 20,
    backgroundColor: '#E8EDF1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#374A5E',
    fontSize: 13,
    fontWeight: 700,
  },
  mt40: {
    marginTop: 40,
  },
});

export const DocumentContract = ({ accountUser, listContractDetail }: any) => {
  const formatDateContract = (dateString: string): string => {
    if (dateString) {
      const date = dayjs(dateString);
      const day = date.format('DD');
      const month = date.format('MM');
      const year = date.format('YYYY');
      return `ngày ${day} tháng ${month} năm ${year}`;
    } else {
      return '';
    }
  };
  return (
    <Document>
      <Page size="A4">
        <View style={styles.container}>
          <View style={styles.headerTitle}>
            <Text style={styles.fw700}>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</Text>
            <Text>Độc lập - Tự do - Hạnh phúc</Text>
          </View>
          <View style={styles.title}>
            <Text style={[styles.text1, styles.mt20]}>HỢP ĐỒNG THUÊ XE</Text>
            <Text style={[styles.text2, styles.mt2]}>Hợp đồng mã: {listContractDetail?.code}</Text>
            <View style={styles.titleContent}>
              <Text style={styles.mt2}>
                - Căn cứ vào luật thương mại năm 2005 được Quốc hội nước cộng hoà xã hội chủ nghĩa
              </Text>
              <Text style={styles.mt2}>
                Việt Nam khoá XI, kỳ họp thứ 7 thông qua ngày 14/06/2005 có hiệu lực từ ngày 01/01/2006.
              </Text>
              <Text style={styles.mt7}>- Căn cứ vào bộ luật dân sự năm 2015.</Text>
              <Text style={styles.mt7}>- Căn cứ vào nhu cầu thực tế của hai bên.</Text>
              <Text style={styles.mt21}>Hôm nay {formatDateContract(listContractDetail?.created_at)}</Text>
              <Text style={[styles.textNormal, styles.mt7]}>Chúng tôi gồm có:</Text>
              <Text style={[styles.textBold, styles.mt7]}>Đơn vị cho thuê xe: (Bên A)</Text>
              <Text style={styles.textBold}>{listContractDetail?.user?.company?.name}</Text>
              <Text style={styles.textBold}>
                Đại diện: <Text style={styles.textNormal}>Ông (bà) </Text>
                <Text style={styles.textValue}>
                  {listContractDetail?.user?.agent?.name
                    ? listContractDetail?.user?.agent?.name
                    : listContractDetail?.user?.name}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Chức vụ:
                <Text style={styles.textValue}>
                  {` Nhân viên ${listContractDetail?.agent?.id ? 'đại lý' : 'nhà xe'}`}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Địa chỉ:
                <Text style={styles.textValue}>
                  {listContractDetail?.user?.agent?.id
                    ? ` ${listContractDetail?.user?.agent?.address}`
                    : ` ${listContractDetail?.user?.company?.address}`}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                VPGD:
                <Text style={styles.textValue}>
                  {listContractDetail?.user?.office_name ? ` ${listContractDetail?.user?.office_name}` : ''}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Số điện thoại:
                <Text style={styles.textValue}>{listContractDetail?.company_info?.phone ?? ''}</Text>
              </Text>
              <Text style={styles.textNormal}>
                Mã số thuế (Mã số doanh nghiệp):
                <Text style={styles.textValue}>
                  {listContractDetail?.company_info?.tax_code ? ` ${listContractDetail?.company_info?.tax_code}` : ''}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Tài khoản số:
                <Text style={styles.textValue}>
                  {' '}
                  {listContractDetail?.company_info?.bank_account_number
                    ? ` ${listContractDetail?.company_info?.bank_account_number}`
                    : ''}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Ngân hàng:
                <Text style={styles.textValue}> {listContractDetail?.bank_name} </Text>
              </Text>

              <Text style={[styles.textBold, styles.mt10]}>Đơn vị thuê xe: (Bên B)</Text>
              <Text style={styles.textBold}>
                Đại diện: <Text style={styles.textNormal}>Ông (bà)</Text>
                <Text style={styles.textValue}>{` ${listContractDetail?.partner_name}`}</Text>
              </Text>
              <Text style={styles.textNormal}>
                Chức vụ:
                <Text style={styles.textValue}>
                  {listContractDetail?.partner_position ? ` ${listContractDetail?.partner_position}` : ''}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Công ty:
                <Text style={styles.textValue}>
                  {listContractDetail?.partner_company ? ` ${listContractDetail?.partner_company} ` : ''}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Địa chỉ:
                <Text style={styles.textValue}>
                  {listContractDetail?.partner_address ? ` ${listContractDetail?.partner_address}` : ''}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Số điện thoại:
                <Text style={styles.textValue}>
                  {listContractDetail?.partner_phone ? ` ${listContractDetail?.partner_phone}` : ''}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Email:
                <Text style={styles.textValue}>
                  {listContractDetail?.partner_email ? ` ${listContractDetail?.partner_email}` : ''}
                </Text>
              </Text>
              <Text style={styles.textNormal}>
                Mã số thuế (Mã số doanh nghiệp):
                <Text style={styles.textValue}>
                  {listContractDetail?.partner_tax ? ` ${listContractDetail?.partner_tax}` : ''}
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page style={[styles.page]}>
        <View style={styles.containerPage2}>
          <Text style={styles.textNormal}>Hai bên đã thỏa thuận và ký kết hợp đồng thuê xe như sau:</Text>
          <View style={[styles.textSemi, styles.mt10, styles.titleContract]}>
            <Text>Điều 1: Số lượng xe và thời gian thuê</Text>
          </View>
          <View style={[styles.dFlex, styles.mt7]}>
            <Text style={[styles.textNormal]}>
              Số lượng xe: <Text style={styles.textValue}>1</Text>
            </Text>
            <Text style={[styles.textNormal, styles.pl17]}>
              Loại xe: <Text style={styles.textValue}>{listContractDetail?.bus_type}</Text>
            </Text>
          </View>
          <View style={[styles.textNormal, styles.dFlex]}>
            <Text style={styles.titleHead}>Thời gian thuê </Text>
            <View style={[styles.dFlex]}>
              <Text>
                Từ ngày:
                <Text style={styles.textValue1}> {dayjs(listContractDetail?.depart_date)?.format('DD/MM/YYYY')}</Text>
              </Text>
              <Text>
                Đến ngày:
                <Text style={styles.textValue1}> {dayjs(listContractDetail?.arrive_date)?.format('DD/MM/YYYY')}</Text>
              </Text>
            </View>
          </View>

          <View style={[styles.textNormal, styles.dFlex, styles.mt7]}>
            <Text style={styles.titleHead}>Thời gian đón khách</Text>
            <View style={[styles.dFlex]}>
              <Text>
                Giờ đi:
                <Text style={styles.textValue1}> {convertTimeFormat(listContractDetail?.depart_time)}</Text>
              </Text>
              <Text>
                Giờ đến:
                <Text style={styles.textValue1}> {convertTimeFormat(listContractDetail?.arrive_time)}</Text>
              </Text>
            </View>
          </View>

          <View style={[styles.textNormal, styles.dFlex, styles.mt7]}>
            <Text style={styles.titleHead}>Lịch trình</Text>
            <View style={[styles.dFlex]}>
              <Text>
                Đón tại:
                <Text style={styles.textValue1}> {listContractDetail?.depart_point}</Text>
              </Text>
              <Text>
                Trả lúc:
                <Text style={styles.textValue1}> {listContractDetail?.arrive_point}</Text>
              </Text>
            </View>
          </View>
          <View style={[styles.dFlex, styles.mt16, styles.titleContract]}>
            <Text style={[styles.textSemi]}>Điều 2: Phương thức thanh toán</Text>
            <Text style={styles.textNormal}>
              Thanh toán bằng {getPaymentMethod(listContractDetail?.payment_method)}
            </Text>
          </View>
          <View style={[styles.dFlex, styles.mt7]}>
            <Text style={[styles.textNormal]}>
              Giá hợp đồng:
              <Text style={styles.textValue}>
                {listContractDetail?.is_insurance
                  ? ` ${formattedPrice(listContractDetail?.price_with_insurance)}đ`
                  : ` ${formattedPrice(listContractDetail?.contract_value)}đ`}
              </Text>
            </Text>
            <Text style={[styles.textNormal, styles.pl17]}>
              Bằng chữ:
              <Text style={styles.textValue}>
                {listContractDetail?.is_insurance
                  ? ` ${convertToWords(listContractDetail?.price_with_insurance)} đồng`
                  : ` ${convertToWords(listContractDetail?.contract_value)} đồng`}
              </Text>
            </Text>
          </View>
          <Text style={[styles.mt3, styles.textNormal]}> Giá đã bao gồm VAT</Text>
          <View style={[styles.mt16, styles.titleContract]}>
            <Text style={[styles.textSemi]}>Điều 3: Cam kết chung</Text>
          </View>
          <View style={[styles.mt7, styles.textNormal]}>
            <Text style={styles.mt3}>
              1. Sau khi ký hợp đồng Bên B muốn thay đổi địa điểm và thời gian đón phải báo trước cho bên
            </Text>
            <Text style={styles.mt3}>
              A Trước 24h. Trong trường hợp xe đã đến đia điểm đón khách, nhưng phải về không thì bên
            </Text>
            <Text style={styles.mt3}>B phải Thanh Toán 50% tổng giá trị hợp đồng.</Text>
          </View>
          <View style={[styles.mt5, styles.textNormal]}>
            <Text style={styles.mt3}>
              2. Bên A đảm bảo xe tốt, đúng chủng loại khách yêu cầu đảm bảo đón khách đúng giờ, lái xe
            </Text>
            <Text style={styles.mt3}>
              phục vụ tận tình, xe đảm bảo có đầy đủ bảo hiểm cho hành khách theo đúng Quy định.
            </Text>
          </View>
          <View style={[styles.mt5, styles.textNormal]}>
            <Text style={styles.mt3}>
              3. Lái xe có quyền từ chối vận chuyển vượt quá số người theo quy định, không đi vào đường
            </Text>
            <Text style={styles.mt3}>
              cấm, đường chật hẹp, nguy hiểm, không vận chuyển hàng hoá, hành lý, hàng cấm trái pháp
            </Text>
            <Text>luật.</Text>
          </View>
          <View style={[styles.mt5, styles.textNormal]}>
            <Text style={styles.mt3}>
              4. Hợp đồng được thành lập 02 bản có giá trị pháp lý như nhau, mỗi bên giữ 01 bản.
            </Text>
          </View>
          <View style={[styles.mt5, styles.textNormal]}>
            <Text style={styles.mt3}>Các thỏa thuận khác ...</Text>
          </View>
          <View style={[styles.dFlex, styles.mt40]}>
            <View style={styles.button}>
              <Text>ĐẠI DIỆN BÊN A</Text>
            </View>
            <View style={styles.button}>
              <Text>ĐẠI DIỆN BÊN B</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
