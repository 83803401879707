import React, { useCallback, useEffect, useState } from "react";
import { Flex, Modal, Table, Typography, type TableProps } from "antd";
import "../styles/ModalHistory.scss";
import iconClose from "../../../assets/images/listUser/iconsClose.svg";
import { historyTicketAPI } from "../api";
import { HistoryParams } from "../../../types/order";
import moment from "moment";
import TextDisplay from "../../../components/Display/TextDisplay";
import { isArray } from "lodash";
import { DEFAULT_PAGE_SIZE } from "antd/es/table/hooks/usePagination";
import { toast } from "react-toastify";
import { formatPrice } from "../../../utils/helpers";
import { handleGetCancelReason } from "../models/constants";

const ModalHistoryTicket = ({
  openHistory,
  setOpenHistory,
  ticket_item,
}: {
  openHistory: boolean;
  setOpenHistory: React.Dispatch<React.SetStateAction<boolean>>;
  ticket_item: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataHistory, setDataHistory] = useState<HistoryParams[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const handleGetHistoryTicket = useCallback(async () => {
    setLoading(true);
    try {
      const res = await historyTicketAPI({
        ticket_code: ticket_item?.ticket_code
          ? ticket_item?.ticket_code
          : undefined,
        page: page,
      });
      if (res?.status === 200) {
        if (isArray(res?.data?.data)) {
          setDataHistory(res?.data?.data);
        } else {
          setDataHistory(res?.data?.data?.data);
        }
        setTotal(res?.data?.pagination?.total);
        setPage(res?.data?.pagination?.current_page);
        setPageSize(res?.data?.pagination?.per_page);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Có lỗi xảy ra vui lòng thử lại!");
    }
  }, [ticket_item?.ticket_code, page]);

  useEffect(() => {
    if (openHistory) {
      handleGetHistoryTicket();
    }
  }, [handleGetHistoryTicket, openHistory]);

  const columns: TableProps<HistoryParams>["columns"] = [
    {
      title: "Thời gian",
      dataIndex: "order_date",
      render: (order_date, record) => (
        <Flex className="flex-column gap-y-4">
          <p className="mb-0 text-color-default fs-16 fw-500">
            {moment(order_date).format("DD/MM/YYYY")}
          </p>
          <p className="mb-0 text-color-gray-7B7B7B fw-500">
            {moment(record?.order_time, "HH:mm:ss").format("HH:mm:ss")}
          </p>
        </Flex>
      ),
    },
    {
      title: "Nhân viên",
      dataIndex: "staff",
      render: (staff) => (
        <Typography.Text className="fs-16 text-color fw-500">
          <TextDisplay text={staff} />
        </Typography.Text>
      ),
    },
    {
      title: "Thao tác",
      dataIndex: "booking_code",
      render: (booking_code, record) => {
        switch (record?.status) {
          case "cancel":
            return (
              <Flex className="flex-column gap-y-4">
                <Flex className="gap-x-8 fs-16 fw-500" align="center">
                  <p className="text-danger mb-0">Hủy vé</p>
                  <p className="mb-0 text-color-default">
                    - Hoàn trả: {formatPrice(record?.refund, 0, ",")}đ - Phí
                    hủy: {formatPrice(record?.cancel_fee, 0, ",")}đ
                  </p>
                </Flex>
                <p className="mb-0 text-color-gray-7B7B7B fw-500">
                  {handleGetCancelReason(record?.cancel_reason, record?.staff)}{" "}
                  - Giá vé: {formatPrice(record?.ticket_price_cancel, 0, ",")}đ
                </p>
              </Flex>
            );
          case "paid":
            return (
              <div>
                <p className="text-color-green-default fs-16 fw-500 mb-0">
                  Xuất vé{" "}
                  <span className="text-color-gray-7B7B7B fw-500">
                    - Giá vé: {formatPrice(record?.ticket_price_paid, 0, ",")}đ
                  </span>
                </p>
                <p className="text-color-gray-7B7B7B fs-16 fw-500 mb-0">
                  Tiền bảo hiểm: {formatPrice(record?.insurance, 0, ",")}đ
                </p>
              </div>
            );
          case "reserve":
            return (
              <p className="text-time-out fs-16 fw-500 mb-0">
                Giữ chỗ{" "}
                <span className="text-color-gray-7B7B7B fw-500">
                  - Giá vé: {formatPrice(record?.ticket_price_reserve, 0, ",")}đ
                </span>
              </p>
            );
        }
      },
    },
  ];

  const onChangePage = (page: number) => {
    setPage(page);
  };

  return (
    <Modal
      rootClassName="ModalHistory"
      closeIcon={false}
      footer={false}
      maskClosable={false}
      width={900}
      open={openHistory}
      onCancel={() => setOpenHistory(false)}
    >
      <div>
        <Flex className="mb-12" align="center" justify="space-between">
          <p className="ModalHistory__title mb-0">Lịch sử thao tác vé</p>
          <Flex
            className="ModalHistory__close"
            align="center"
            justify="center"
            onClick={() => setOpenHistory(false)}
          >
            <img
              className="object-fit-scale"
              width={24}
              height={24}
              src={iconClose}
              alt=""
            />
          </Flex>
        </Flex>
        <Flex className="gap-x-40 mb-12" align="center">
          <Flex
            className="fs-16 leading-24 gap-x-8 text-color-default"
            align="center"
          >
            <p className="fw-500 mb-0">Mã vé:</p>
            <p className="fw-600 mb-0">{ticket_item?.ticket_code}</p>
          </Flex>
          <Flex
            className="fs-16 leading-24 gap-x-8 text-color-default"
            align="center"
          >
            <p className="fw-500 mb-0">Hành khách:</p>
            <p className="fw-600 mb-0">{ticket_item?.customer?.name}</p>
          </Flex>
          <Flex
            className="fs-16 leading-24 gap-x-8 text-color-default"
            align="center"
          >
            <p className="fw-500 mb-0">Số điện thoại:</p>
            <p className="fw-600 mb-0">{ticket_item?.customer?.phone}</p>
          </Flex>
        </Flex>
        <Table
          loading={loading}
          dataSource={dataHistory}
          className="ModalHistory__table"
          columns={columns}
          pagination={{
            total: total,
            current: page,
            pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
            position: ["bottomRight"],
            onChange: onChangePage,
            showSizeChanger: false,
          }}
        />
      </div>
    </Modal>
  );
};

export default ModalHistoryTicket;
