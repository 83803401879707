import { Button, Card, Checkbox, Col, Form, Image, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constant } from '../config/constant';
import { SeatRenderProps } from '../types/seatType';
import { typeSeatItems } from './BusTypeMatrix';

const SeatMapRender = ({
  seatMatrixData,
  isChooseSeat,
  onCheckboxChange,
  formTypeBus,
  isSelectedAllSeat,
  isChangeBooking,
}: SeatRenderProps) => {
  const { t } = useTranslation();
  const [seatMatrix, setSeatMatrix] = useState<{ [key: string]: any }>({
    floor1: [],
    floor2: [],
    group_seat: [],
  });
  const [statusFloor, setStatusFloor] = useState<number>(1);
  const currentValues = Form.useWatch('group_seat', formTypeBus);
  const selectedSeats = currentValues && currentValues?.flatMap((group: any) => group?.selected_seat || []);
  const [isChooseAllSeat, setIsChooseAllSeat] = useState<boolean>(false);
  const busDirection = Form.useWatch('bus_direction', formTypeBus) ?? seatMatrixData?.bus_direction ?? 0;

  const handleChooseAllSeats = () => {
    setSeatMatrix((prevMatrix: any) => {
      const newMatrix = {
        ...prevMatrix,
        [`floor${statusFloor}`]: prevMatrix[`floor${statusFloor}`].map((row: any) =>
          row.map((seat: any) => ({
            ...seat,
            selected:
              seat.type > Constant.EXTRA_DRIVER_SEAT &&
              seat.name &&
              !currentValues.some((group: any) => group.selected_seat.includes(seat.name)),
          })),
        ),
      };
      const allSelectedSeats = newMatrix[`floor${statusFloor}`].flatMap((row: any) =>
        row.filter((seat: any) => seat.selected).map((seat: any) => seat.name),
      );
      if (allSelectedSeats && onCheckboxChange) {
        onCheckboxChange(1, 1, allSelectedSeats);
      }
      return newMatrix;
    });
    setIsChooseAllSeat(true);
    if (isChooseSeat === 1) {
      setIsChooseAllSeat(false);
    }
  };

  const handleCancelChooseAllSeats = () => {
    setSeatMatrix((prevMatrix: any) => {
      const newMatrix = {
        ...prevMatrix,
        [`floor${statusFloor}`]: prevMatrix[`floor${statusFloor}`].map((row: any) =>
          row.map((seat: any) => ({
            ...seat,
            selected: false,
          })),
        ),
      };
      const allSelectedSeats = newMatrix[`floor${statusFloor}`].flatMap((row: any) =>
        row.filter((seat: any) => seat.selected).map((seat: any) => seat.name),
      );
      if (allSelectedSeats && onCheckboxChange) {
        onCheckboxChange(1, 1, allSelectedSeats);
      }
      return newMatrix;
    });
    setIsChooseAllSeat(false);
  };

  const handleCheckboxChange = (rowIndex: number, colIndex: number) => {
    setSeatMatrix((prevMatrix: any) => {
      const newMatrix = { ...prevMatrix };
      const seat = newMatrix[`floor${statusFloor}`][rowIndex][colIndex];
      seat.selected = !seat.selected;
      const selectedSeatNames = ['floor1', 'floor2']?.flatMap((floor) =>
        newMatrix[floor]?.flatMap((row: any) =>
          row?.filter((seat: any) => seat.selected)?.map((seat: any) => seat?.name),
        ),
      );

      if (selectedSeatNames && onCheckboxChange) {
        onCheckboxChange(rowIndex, colIndex, selectedSeatNames);
      }
      return newMatrix;
    });
  };
  const onChangeFloor = (value: number | undefined) => {
    if (value && value <= seatMatrixData?.count_floor) {
      setStatusFloor(value);
    }
  };

  useEffect(() => {
    if (seatMatrixData && !isChooseAllSeat) {
      setSeatMatrix(seatMatrixData);
    }
  }, [seatMatrixData, seatMatrix, isChooseAllSeat]);

  useEffect(() => {
    if (isChooseSeat === 1) {
      handleCancelChooseAllSeats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChooseSeat]);

  const getGroupColor = (seatName: string) => {
    return formTypeBus
      ? currentValues?.find((group: any) => {
          return group?.selected_seat ? group.selected_seat?.includes(seatName) : '';
        })?.group_color || ''
      : seatMatrix?.group_seat.find((group: any) => {
          return group?.selected_seat ? group.selected_seat?.includes(seatName) : '';
        })?.group_color || '';
  };

  const renderColor = (seatFloors: any) => {
    return (
      typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image && (
        <Space className="bus-type-item group" style={{ columnGap: '4px' }}>
          <Image
            src={typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image}
            alt=""
            width={60}
            preview={false}
            style={{ maxHeight: '40px' }}
            className={`${+seatFloors.type > Constant.EXTRA_DRIVER_SEAT && getGroupColor(seatFloors.name)} ${
              busDirection === Constant.HORIZONTAL_DIRECTION ? 'horizontal' : ''
            }`}
          />
        </Space>
      )
    );
  };
  const isAllSeatsSelected = () => {
    const selectedSeatsInCurrentFloor = seatMatrix[`floor${statusFloor}`]
      .flatMap((row: any) => row.map((seat: any) => seat.name))
      .filter((seatName: any) => currentValues?.some((group: any) => group?.selected_seat?.includes(seatName)));
    if (
      selectedSeatsInCurrentFloor.length !== 0 &&
      currentValues?.flatMap((group: any) => group?.selected_seat || []).length !== 0
    ) {
      return (
        selectedSeatsInCurrentFloor.length === currentValues?.flatMap((group: any) => group?.selected_seat || []).length
      );
    } else {
      return false;
    }
  };

  return (
    <>
      <Row className={`${isChangeBooking ? '' : 'mt-4'} floor-block group-seat`}>
        <Space onClick={() => onChangeFloor(1)}>
          <Typography.Text className={`fs-16 floor-item ${statusFloor === 1 ? 'active' : ''}`}>
            {t(`Tầng 1`)}
          </Typography.Text>
        </Space>
        {seatMatrixData?.count_floor === 2 && (
          <Space onClick={() => onChangeFloor(2)}>
            <Typography.Text className={`fs-16 floor-item ${statusFloor === 2 ? 'active' : ''}`}>
              {t(`Tầng 2`)}
            </Typography.Text>
          </Space>
        )}
      </Row>
      {isSelectedAllSeat && isChooseSeat === 2 ? (
        <Row className="d-flex justify-content-end mt-3 " style={{ columnGap: '10px' }}>
          <Button onClick={() => handleChooseAllSeats()} disabled={isChooseAllSeat || isAllSeatsSelected()}>
            {statusFloor === 1 ? 'Chọn tất cả ghế tầng 1' : 'Chọn tất cả ghế tầng 2'}
          </Button>
          <Button onClick={() => handleCancelChooseAllSeats()} disabled={!isChooseAllSeat}>
            {'Hủy'}
          </Button>
        </Row>
      ) : (
        ''
      )}
      <Row className={`d-flex justify-content-center ${busDirection === Constant.HORIZONTAL_DIRECTION ? 'mt-3' : ''}`}>
        <div className={`seat-render-content group`}>
          {seatMatrix[`floor${statusFloor}`]?.map((rowSeats: any, rowIndex: number) => (
            <Row
              key={rowIndex}
              className={`seat-list group ${busDirection === Constant.HORIZONTAL_DIRECTION ? 'horizontal' : ''}`}
            >
              {rowSeats?.map((seatFloors: any, colIndex: number) => (
                <Col key={colIndex}>
                  <Card
                    style={{ width: 54, height: 54, border: 'none' }}
                    className={`card-seat-item mt-1`}
                    bodyStyle={{ display: 'none' }}
                    cover={
                      +seatFloors.type !== Constant.DEFAULT_SEAT && (
                        <Space
                          className={`seat-block-item render${
                            typeSeatItems?.find((item: any) => +item?.id === +seatFloors.type)?.image ? ' ic-type' : ''
                          }`}
                          onClick={() =>
                            seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                            isChooseSeat === 2 &&
                            seatFloors.name &&
                            !selectedSeats.includes(seatFloors.name) &&
                            handleCheckboxChange(rowIndex, colIndex)
                          }
                        >
                          {seatFloors.type > Constant.EXTRA_DRIVER_SEAT &&
                            isChooseSeat === 2 &&
                            !selectedSeats.includes(seatFloors.name) &&
                            seatFloors.name && (
                              <Checkbox className="seat-checkbox-item" checked={seatFloors.selected} />
                            )}
                          <Space className="ic-type-seat">
                            {renderColor(seatFloors)}
                            {+seatFloors.type > Constant.EXTRA_DRIVER_SEAT ? (
                              <Typography.Text className="seat-name">{seatFloors.name}</Typography.Text>
                            ) : (
                              ''
                            )}
                          </Space>
                        </Space>
                      )
                    }
                  ></Card>
                </Col>
              ))}
            </Row>
          ))}
        </div>
      </Row>
    </>
  );
};
export default SeatMapRender;
