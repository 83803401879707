import { InfoCircleFilled, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Pagination,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Rule } from 'antd/es/form';
import { ColumnsType } from 'antd/es/table';
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination';
import Link from 'antd/es/typography/Link';
import { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icEdit from '../../../assets/images/ic-edit.svg';
import iconNext from '../../../assets/images/ic-next.svg';
import iconPrev from '../../../assets/images/ic-prev.svg';
import StatusFilterBooking from '../../../components/Modal/StatusFilterBooking';
import { Constant, USER_GROUP, YearFormat } from '../../../config/constant';
import { phoneRequiredRules, validatorEmail } from '../../../config/validators/validators';
import { convertTimeFormat, formatDate, formattedPrice, maxValidator, mbTrim } from '../../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/slice/authSlice';
import { cancelBookingSelector, resetCancelBooking } from '../../../redux/slice/bookings/cancelBookingSlice';
import {
  createBooking,
  createBookingSelector,
  filterBookingCustomer,
  resetBooking,
} from '../../../redux/slice/bookings/createBookingSlice';
import { resetBookingType, resetDataBooking } from '../../../redux/slice/bookings/holdingBookingSlice';
import { resetUpdateBooking } from '../../../redux/slice/bookings/updateBooking';
import {
  getContractById,
  getListContract,
  listContractSelector,
  resetListContractDetail,
} from '../../../redux/slice/contracts/listContractSlice';
import { fetchListRoute, getContractRoute, routeSelector } from '../../../redux/slice/routeSlice';
import { ListContractRequest } from '../../../types/contract';
import ModalCancelContract from './ModalCancelContract';
import ModalPrintContract from './ModalPrintContract';
import { agentContractPaidOptions, paymentContractOptions } from '../../../utils/bookingItem';
import ModalInfoInsurance from '../../../components/Modal/ModalInfoInsurance';

function ListContract() {
  const {
    loading: loadingContract,
    listContract,
    pagination,
    listContractDetail,
    loadingContractDetail,
  } = useAppSelector(listContractSelector);
  const { status: statusCreateUpdate, listCustomers } = useAppSelector(createBookingSelector);
  const { group, accountUser, isLogin } = useAppSelector(selectAuth);
  const { status: statusCancel } = useAppSelector(cancelBookingSelector);
  const { routes: routeOption, routeContract } = useAppSelector(routeSelector);
  const accountDetail = useAppSelector((state) => state.authAccount?.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [formSearch]: [FormInstance] = Form.useForm();
  const [formContract] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [status, setStatus] = useState<any>();
  const [keyword, setKeyword] = useState<ListContractRequest>({});
  const [page, setPage] = useState<number>(1);
  const [keywordRoute, setKeywordRoute] = useState<any>();
  const [route_id, setProvinceId] = useState<any>('');
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const [dataPolicy, setDataPolicy] = useState<any>();
  const [isRepresentChecked, setIsRepresentChecked] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  const [openInfoInsurance, setOpenInfoInsurance] = useState(false);
  const [isOpenModalCancelContract, setIsOpenModalCancelContract] = useState(false);
  const [customerPhone, setCustomerPhone] = useState<string>('');
  const limit_pagination: any = window.localStorage.getItem('limit_pagination');
  const limitPagination = isNaN(Number(limit_pagination)) ? DEFAULT_PAGE_SIZE : Number(limit_pagination);
  const [limit, setLimit] = useState<number>(limitPagination || DEFAULT_PAGE_SIZE);
  const type = Form.useWatch('contract_type', formSearch);
  const methodType = Form.useWatch('contract_type', formContract);
  const filterDate = Form.useWatch('date_filter', formSearch);
  const rangeDate = Form.useWatch('range_date', formSearch);
  const currentDate = dayjs();

  const onFieldDetail = (record: any) => {
    dispatch(filterBookingCustomer({}));
    setOpenModalDetail(true);
    dispatch(getContractById(record?.id));
    setDataPolicy(record?.policy?.insurance_policy);

    const contractsData = Object.keys(record?.customer_info?.data).map((seatName) => ({
      name_seat: seatName,
      name: record?.customer_info?.data[seatName].name,
      phone: record?.customer_info?.data[seatName].phone,
      email: record?.customer_info?.data[seatName].email,
    }));
    if (record?.booking_type === 'contract_same') {
      setIsRepresentChecked(true);
    }
    if (record?.booking_type === 'contract_custom') {
      setIsRepresentChecked(false);
    }
    formContract.setFieldsValue({
      ticket_code: record?.code,
      partner_name: record?.partner_name,
      partner_phone: record?.partner_phone,
      partner_email: record?.partner_email,
      note: record?.note,
      contract_value: formattedPrice(record?.contract_value),
      payment_method: record?.payment_method,
      depart_point: record?.depart_point,
      arrive_point: record?.arrive_point,
      partner_address: record?.partner_address,
      partner_company: record?.partner_company,
      partner_position: record?.partner_position,
      partner_tax: record?.partner_tax,
      contract_type: record?.type,
      contracts: contractsData,
      bus_type: record?.bus_type,
      total_price: record?.contract_value,
      is_represent: record?.booking_type === 'contract_custom' ? false : true,
      is_insurance: record?.is_insurance,
    });
  };

  const columns1: ColumnsType<any> = [
    {
      title: 'Họ và tên',
      dataIndex: '',
      key: 'partner_name',
      width: '220px',
      render: (text, record) => (
        <Space size="middle">
          <FontAwesomeIcon
            icon={faCircle}
            className={`${record?.status === 'canceled' ? 'danger' : 'green'}`}
            size="xs"
          />
          <Typography>{record?.partner_name}</Typography>
        </Space>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'partner_phone',
      key: 'partner_phone',
      width: '180px',
    },
    {
      title: 'Loại hợp đồng',
      dataIndex: 'type',
      key: 'partner_phone',
      width: '140px',
      render: (text) => <>{`${text === 'enterprise' ? 'Doanh nghiệp' : 'Cá nhân'}`}</>,
    },
    {
      title: 'Hành trình',
      dataIndex: '',
      key: 'trip',
      width: '260px',
      render: (record) => <>{`${record?.depart_point} - ${record?.arrive_point}`}</>,
    },
    {
      title: 'Ngày đi',
      dataIndex: 'depart_date',
      key: 'seat',
      width: '110px',
      sorter: (a, b) => dayjs(a.depart_date).unix() - dayjs(b.depart_date).unix(),
      render: (text) => <Typography>{text ? dayjs(text)?.format('DD/MM/YYYY') : ''}</Typography>,
    },
    {
      title: 'Giờ đi',
      dataIndex: 'depart_time',
      key: 'driver_pickup',
      width: '120px',
      render: (text) => <Typography>{convertTimeFormat(text)}</Typography>,
      sorter: (a, b) => a?.depart_time.localeCompare(b?.depart_time),
    },
    {
      title: 'Giá trị HĐ',
      dataIndex: 'contract_value',
      key: 'contract_value',
      width: '140px',
      render: (text) => <Typography>{text ? `${formattedPrice(text)}đ` : '0đ'}</Typography>,
      sorter: (a, b) => a.contract_value - b.contract_value,
    },
    {
      title: 'Chức năng',
      dataIndex: 'action',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle">
          <Tooltip title="Chỉnh sửa">
            <Link
              onClick={() => {
                onFieldDetail(record);
              }}
            >
              <Image width={17} height={17} className="icon-edit mr-20" src={icEdit} preview={false} />
            </Link>
          </Tooltip>
        </Space>
      ),
      align: 'center',
      width: '120px',
    },
  ];

  const onChangeStatus = (value: number | undefined) => {
    setStatus(value);
  };
  const handleSearch = (values: any) => {
    formSearch.setFields([{ name: 'keyword', value: mbTrim(`${values.keyword}`) }]);
    setPage(Constant.DEFAULT_PAGE);
    setKeyword(formSearch.getFieldsValue(['keyword']));
  };
  function onSearchRoute(value: any) {
    setKeywordRoute(value);
  }
  const RouteOptions: SelectProps['options'] = routeOption?.length
    ? routeOption?.map((item) => {
        return {
          value: item.id,
          label: `${item?.depart_point} - ${item?.arrive_point}`,
        };
      })
    : [];
  const RouteAgentOptions: SelectProps['options'] = routeContract?.length
    ? routeContract?.map((item) => {
        return {
          value: item.id,
          label: `${item?.depart_point} - ${item?.arrive_point}`,
        };
      })
    : [];
  const onChangeSearchRoute = (value: any) => {
    setProvinceId(value);
    setPage(1);
  };
  const filterOptionRoute = (
    input: string,
    option?: {
      label?: any;
      value?: any;
    },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const modalHandleOk = () => {};
  const modalHandleCancel = () => {
    setOpenModalDetail(false);
    formContract.resetFields();
    dispatch(resetListContractDetail());
    setDataPolicy('');
  };

  const onChangePayment = ({ target: { value } }: RadioChangeEvent) => {
    setPaymentMethod(value);
  };

  const handlePagination = (page: number, pageSize: number) => {
    if (pagination?.per_page !== pageSize) {
      setPage(Constant.DEFAULT_PAGE);
      setLimit(pageSize);
      return;
    }
    setPage(page);
  };
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsRepresentChecked(e.target.checked);
    formContract.setFieldsValue({ is_represent: e.target.checked });
  };
  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword({ ...keyword, keyword: e.target.value });
  };
  const handleCancelContract = () => {
    setIsOpenModalCancelContract(false);
  };
  const handleOkeContract = () => {};
  const onFinish = (values: any) => {
    const contractsData = values.contracts.reduce((acc: any, contract: any) => {
      acc[contract.name_seat] = {
        customer_phone: contract.phone,
        customer_email: contract.email,
        customer_name: contract.name,
      };
      return acc;
    }, {});
    if (isRepresentChecked) {
      const tmpDataRepresent = {
        booking_type: 'contract_same',
        id: listContractDetail?.id,
        schedule_id: listContractDetail?.schedule_id,
        depart_date: listContractDetail?.depart_date,
        is_diagram: listContractDetail?.is_diagram,
        is_insurance: listContractDetail?.is_insurance,
        ticket_code: [values?.ticket_code],
        payment_method: values.payment_method,
        pickup_extra: !listContractDetail?.pickup_location_id ? values.depart_point : undefined,
        pickup_location_id: listContractDetail?.pickup_location_id,
        drop_off_location_id: listContractDetail?.drop_off_location_id,
        drop_off_extra: !listContractDetail?.drop_off_location_id ? values.arrive_point : undefined,
        contract_type: values.contract_type ? values.contract_type : 'personal',
        partner_phone: values.partner_phone,
        partner_name: values.partner_name,
        partner_position: methodType === 'enterprise' ? values.partner_position : undefined,
        partner_company: methodType === 'enterprise' ? values.partner_company : undefined,
        partner_tax: methodType === 'enterprise' ? values.partner_tax : undefined,
        partner_email: values.partner_email,
        partner_address: values.partner_address,
        note: values.note,
      };
      dispatch(createBooking(tmpDataRepresent));
    } else {
      const tmpDataNotPresent = {
        booking_type: 'contract_custom',
        id: listContractDetail?.id,
        ticket_code: [values?.ticket_code],
        schedule_id: listContractDetail?.schedule_id,
        depart_date: listContractDetail?.depart_date,
        is_diagram: listContractDetail?.is_diagram,
        is_insurance: listContractDetail?.is_insurance,
        payment_method: values.payment_method,
        pickup_extra: !listContractDetail?.pickup_location_id ? values.depart_point : undefined,
        pickup_location_id: listContractDetail?.pickup_location_id,
        drop_off_location_id: listContractDetail?.drop_off_location_id,
        drop_off_extra: !listContractDetail?.drop_off_location_id ? values.arrive_point : undefined,
        contract_type: values.contract_type ? values.contract_type : 'personal',
        custom_info: contractsData,
        partner_phone: values.partner_phone,
        partner_name: values.partner_name,
        partner_email: values.partner_email,
        partner_position: methodType === 'enterprise' ? values.partner_position : undefined,
        partner_company: methodType === 'enterprise' ? values.partner_company : undefined,
        partner_tax: methodType === 'enterprise' ? values.partner_tax : undefined,
        partner_address: values.partner_address,
        note: values.note,
      };
      dispatch(createBooking(tmpDataNotPresent));
    }
  };

  useEffect(() => {
    dispatch(
      getListContract({
        ...keyword,
        status,
        page,
        limit,
        type,
        route_id,
        from: rangeDate
          ? dayjs(rangeDate[0]).format(YearFormat)
          : accountDetail?.export_report_day
          ? dayjs(currentDate.subtract(accountDetail?.export_report_day, 'day')).format('YYYY-MM-DD')
          : undefined,
        to: rangeDate ? dayjs(rangeDate[1]).format(YearFormat) : undefined,
        date_filter: filterDate ? filterDate : 'depart_date',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keyword, status, page, limit, type, route_id, rangeDate, filterDate, accountDetail]);

  useEffect(() => {
    if (group !== USER_GROUP.AGENT && isLogin) {
      dispatch(fetchListRoute({ keyword: keywordRoute }));
    } else {
      dispatch(getContractRoute());
    }
  }, [dispatch, keywordRoute, group, isLogin]);

  const NameRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập họ và tên'),
    },
    {
      pattern: /^[^\s].*$/,
      message: t('Tên không được chứa chỉ khoảng trắng'),
    },
    maxValidator(255, 'Số kí tự vượt quá 255 từ'),
  ];
  const NameCompanyRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập tên công ty'),
    },
    maxValidator(255, 'Số kí tự vượt quá 255 từ'),
  ];
  const TaxRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập MST'),
    },
  ];

  const routePickerRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập điểm xuất phát'),
    },
    {
      pattern: /^[^\s].*$/,
      message: t('Điểm đón không được chứa chỉ khoảng trắng'),
    },
    maxValidator(255, 'Số kí tự vượt quá 255 từ'),
  ];
  const routeBackRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập điểm trả khách'),
    },
    {
      pattern: /^[^\s].*$/,
      message: t('Điểm trả không được chứa chỉ khoảng trắng'),
    },
    maxValidator(255, 'Số kí tự vượt quá 255 từ'),
  ];

  const priceRules: Rule[] = [
    {
      required: true,
      message: t('Vui lòng nhập giá trị hợp đồng'),
    },
  ];
  const handleInputChange = (value: string) => {
    setCustomerPhone(value);
  };
  const onFillCustomer = (value: string) => {
    if (listCustomers?.length) {
      const selectedCustomer = listCustomers?.find((customer: any) => customer.phone === value);
      if (selectedCustomer) {
        formContract.setFieldsValue({
          partner_name: selectedCustomer.name,
          partner_phone: selectedCustomer.phone,
        });
      }
    }
  };
  const onChangePhone = (keyword: any) => {
    onFillCustomer(keyword);
  };
  const disabledRangeDaysDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    const exportDay = accountDetail?.export_report_day || 30;
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= exportDay;
    }
    return false;
  };

  const onCancelSuccess = useCallback(() => {
    modalHandleCancel();
    handleCancelContract();
    dispatch(resetUpdateBooking());
    dispatch(resetBooking());
    formContract.resetFields();
    dispatch(resetDataBooking());
    dispatch(resetBookingType());
    dispatch(resetCancelBooking());
    dispatch(
      getListContract({
        ...keyword,
        status,
        page,
        limit,
        type,
        route_id,
        from: rangeDate
          ? dayjs(rangeDate[0]).format(YearFormat)
          : accountDetail?.export_report_day
          ? dayjs(currentDate.subtract(accountDetail?.export_report_day, 'day')).format('YYYY-MM-DD')
          : undefined,
        to: rangeDate ? dayjs(rangeDate[1]).format(YearFormat) : undefined,
        date_filter: filterDate ? filterDate : 'depart_date',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keyword, status, page, limit, type, route_id, rangeDate, accountDetail, filterDate, formContract]);
  useEffect(() => {
    if (statusCreateUpdate === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateUpdate]);
  useEffect(() => {
    if (statusCancel === Constant.DEFAULT_STATUS) {
      onCancelSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCancel]);

  useEffect(() => {
    if (listContractDetail?.code) {
      formContract.setFieldsValue({
        commission_price: listContractDetail?.is_insurance
          ? formattedPrice(listContractDetail?.price_commission_insurance)
          : formattedPrice(listContractDetail?.price_commission),
      });
    }
  }, [listContractDetail, formContract]);
  return (
    <Layout className="min-vh-100 list_user agent agent-container">
      <Spin spinning={loadingContract}>
        <Card>
          <StatusFilterBooking status={status} setPage={setPage} onChangeStatus={onChangeStatus} isContract={true} />
          <div className="table-booking mx-2">
            <Form
              className="mg-16 mt-3"
              form={formSearch}
              labelCol={{ xxl: 5, xl: 6, lg: 7, md: 24, sm: 24 }}
              requiredMark={false}
              onFinish={handleSearch}
              colon={false}
            >
              <Form.Item className="label-search" labelAlign="left" labelCol={{ md: 0 }} name="form-search">
                <Row gutter={12}>
                  <Col span={6}>
                    <Form.Item name="keyword">
                      <Input
                        placeholder={t('Nhập SĐT / họ tên khách hàng để tìm kiếm')}
                        suffix={<SearchOutlined className="ic-search-input" />}
                        onChange={handleKeywordChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="trip">
                      <Select
                        className="input-h36"
                        showSearch={group === USER_GROUP.AGENT ? false : true}
                        onSearch={onSearchRoute}
                        filterOption={filterOptionRoute}
                        placeholder="Hành trình"
                        size="large"
                        onChange={onChangeSearchRoute}
                        style={{ width: '100%' }}
                        options={
                          group === USER_GROUP.AGENT
                            ? [{ value: '', label: 'Tất cả' }, ...RouteAgentOptions]
                            : [{ value: '', label: 'Tất cả' }, ...RouteOptions]
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={'date_filter'}>
                      <Select
                        defaultValue="depart_date"
                        options={[
                          { value: 'depart_date', label: 'Theo ngày khởi hành' },
                          { value: 'order_date', label: 'Theo ngày đặt vé' },
                        ]}
                        onChange={() => setPage(1)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="range_date" className="data-picker booking">
                      <RangePicker
                        placeholder={['Từ ngày', 'Đến ngày']}
                        style={{ height: '36px' }}
                        onChange={() => setPage(1)}
                        disabledDate={disabledRangeDaysDate}
                        minDate={
                          accountDetail?.export_report_day
                            ? currentDate.subtract(accountDetail?.export_report_day, 'day')
                            : undefined
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name="contract_type">
                      <Select
                        placeholder="Loại hợp đồng"
                        style={{ width: '100%' }}
                        options={[
                          { value: '', label: 'Tất cả' },
                          { value: 'personal', label: 'Cá nhân' },
                          { value: 'enterprise', label: 'Doanh nghiệp' },
                        ]}
                        onChange={() => setPage(1)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
            <Table
              locale={{
                triggerDesc: 'Sắp xếp giảm dần',
                triggerAsc: 'Sắp xếp tăng dần',
                cancelSort: 'Hủy sắp xếp',
              }}
              dataSource={listContract?.length ? listContract : []}
              rowKey="id"
              columns={columns1}
              className="list-bus"
              pagination={false}
              loading={loadingContract}
            />
            <div className="pagination-table agency mt-3">
              <Pagination
                onChange={handlePagination}
                current={page}
                total={pagination?.total}
                pageSize={pagination?.per_page ?? limitPagination}
                showSizeChanger={true}
                prevIcon={<img src={iconPrev} alt="" />}
                nextIcon={<img src={iconNext} alt="" />}
              />
            </div>
          </div>
          <Modal
            open={openModalDetail}
            onOk={modalHandleOk}
            onCancel={modalHandleCancel}
            footer={[]}
            width={900}
            className="modal-bus-booking"
            maskClosable={false}
          >
            <Spin spinning={loadingContractDetail}>
              <Card className="card-modal-booking">
                <Typography className="fw-600 fs-18">{t('Chi tiết hợp đồng thuê xe')}</Typography>
                <Row gutter={22} className="mt-2">
                  <Col>
                    <Typography className="history-info">
                      Tuyến:
                      <span className="history-detail">
                        {` ${listContractDetail?.depart_point ?? ''} - ${listContractDetail?.arrive_point ?? ''}`}
                      </span>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography className="history-info">
                      Ngày:
                      <span className="history-detail">
                        {listContractDetail?.depart_date
                          ? ` ${formatDate(listContractDetail?.depart_date, 'DD/MM/YYYY')}`
                          : ''}
                      </span>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography className="history-info">
                      Giờ đi:
                      <span className="history-detail"> {convertTimeFormat(listContractDetail?.depart_time)}</span>
                    </Typography>
                  </Col>
                </Row>
                {listContractDetail?.status === 'canceled' ? (
                  <div
                    style={{ height: '600px', overflowY: 'auto', overflowX: 'hidden' }}
                    className="contract-cancelled"
                  >
                    <Row gutter={14} className="fw-500 mt-3">
                      <Col span={10}>
                        <Typography className="cyan">Mã hợp đồng</Typography>
                        <Typography className="heavy-cyan">{listContractDetail?.code}</Typography>
                      </Col>
                      <Col span={6}>
                        <Typography className="cyan">Loại hình hợp đồng</Typography>
                        <Typography className="heavy-cyan">
                          {listContractDetail?.type === 'enterprise' ? 'Doanh nghiệp' : 'Cá nhân'}
                        </Typography>
                      </Col>
                      <Col span={7}>
                        <Typography className="cyan">Loại xe</Typography>
                        <Typography className="heavy-cyan">{listContractDetail?.bus_type}</Typography>
                      </Col>
                    </Row>
                    <Row gutter={14} className="fw-500 mt-3">
                      {listContractDetail?.type === 'enterprise' && (
                        <>
                          <Col span={10}>
                            <Typography className="cyan">Công ty</Typography>
                            <Typography className="heavy-cyan">{listContractDetail?.partner_company}</Typography>
                          </Col>
                          <Col span={6}>
                            <Typography className="cyan">Mã số thuế</Typography>
                            <Typography className="heavy-cyan">{listContractDetail?.partner_tax}</Typography>
                          </Col>
                        </>
                      )}
                      <Col span={7}>
                        <Typography className="cyan">Email</Typography>
                        <Typography className="heavy-cyan">{listContractDetail?.partner_email}</Typography>
                      </Col>
                    </Row>
                    {listContractDetail?.type === 'enterprise' && (
                      <Row className="fw-500 mt-3">
                        <Col span={24}>
                          <Typography className="cyan">Địa chỉ</Typography>
                          <Typography className="heavy-cyan">{listContractDetail?.partner_address}</Typography>
                        </Col>
                      </Row>
                    )}
                    <Row className="fw-500 mt-3">
                      <Col span={10}>
                        <Typography className="cyan">Điểm xuất phát</Typography>
                        <Typography className="heavy-cyan">{listContractDetail?.depart_point}</Typography>
                      </Col>
                      <Col span={10}>
                        <Typography className="cyan">Điểm trả khách</Typography>
                        <Typography className="heavy-cyan">{listContractDetail?.arrive_point}</Typography>
                      </Col>
                    </Row>
                    <Row className="fw-500 mt-3">
                      <Col span={24}>
                        <Typography className="cyan">Ghi chú chuyến</Typography>
                        <Typography className="heavy-cyan">{listContractDetail?.note ?? 'Không có ghi chú'}</Typography>
                      </Col>
                    </Row>
                    <Row className="fw-500 mt-3">
                      <Col span={10}>
                        <Typography className="cyan">Giá trị hợp đồng</Typography>
                        <Typography className="heavy-cyan">
                          {formattedPrice(listContractDetail?.contract_value)}
                        </Typography>
                      </Col>
                      <Col span={10}>
                        <Typography className="cyan">Phương thức thanh toán</Typography>
                        <Typography className="heavy-cyan">
                          {listContractDetail?.payment_method === 'cash' ? 'Tiền mặt' : 'Chuyển khoản ngân hàng'}
                        </Typography>
                      </Col>
                    </Row>
                    <Typography className="fw-600 fs-16 mt-3">Danh sách hành khách</Typography>
                    <Row className="mt-2">
                      <Col span={2}>
                        <Typography className="cyan">Ghế</Typography>
                      </Col>
                      <Col span={7}>
                        <Typography className="cyan">Họ và tên</Typography>
                      </Col>
                      <Col span={6}>
                        <Typography className="cyan">Số điện thoại</Typography>
                      </Col>
                      <Col span={8}>
                        <Typography className="cyan">Email</Typography>
                      </Col>
                    </Row>
                    {Object.keys(listContractDetail?.customer_info?.data).map((key, index) => {
                      const item = listContractDetail?.customer_info?.data[key];
                      return (
                        <div key={index}>
                          <Row className="fw-500 mt-2">
                            <Col span={2}>
                              <Typography className="heavy-cyan">{key}</Typography>
                            </Col>
                            <Col span={7}>
                              <Typography className="heavy-cyan">{item?.name}</Typography>
                            </Col>
                            <Col span={6}>
                              <Typography className="heavy-cyan">{item?.phone}</Typography>
                            </Col>
                            <Col span={8}>
                              <Typography className="heavy-cyan">{item?.email}</Typography>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <Typography className="fw-600 fs-16 mt-3"> </Typography>
                  </div>
                ) : (
                  <Form
                    layout="vertical"
                    className="mt-3"
                    form={formContract}
                    onFinish={onFinish}
                    autoComplete="off"
                    validateTrigger="onSubmit"
                    labelCol={{ span: 12 }}
                    colon={false}
                    labelAlign={'left'}
                    labelWrap
                  >
                    <div className="form-booking-block contract">
                      <Row gutter={16}>
                        <Col span={7}>
                          <Form.Item name="contract_type" className="fw-500" label={t('Loại hình hợp đồng')}>
                            <Select
                              defaultValue={'personal'}
                              options={[
                                { value: 'personal', label: 'Cá nhân' },
                                { value: 'enterprise', label: 'Doanh nghiệp' },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item name="ticket_code" label={t('Mã hợp đồng')}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        {methodType === 'enterprise' && (
                          <Col span={7}>
                            <Form.Item name="bus_type" label={t('Loại xe')}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                      <Row gutter={12}>
                        <Col span={6}>
                          <Form.Item
                            name="partner_phone"
                            label={t('Số điện thoại')}
                            className="require"
                            rules={phoneRequiredRules}
                            required={true}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <Input
                              className="input-h36 input-phone"
                              placeholder={t('Nhập số điện thoại')}
                              size="large"
                              value={customerPhone}
                              onChange={(e) => handleInputChange(e.target.value)}
                              onBlur={(e) => {
                                onChangePhone(customerPhone);
                              }}
                              onWheel={(event) => event.currentTarget.blur()}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item
                            name="partner_name"
                            label={t('Họ và tên')}
                            className="require"
                            rules={NameRules}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <Input placeholder="Nhập họ và tên" />
                          </Form.Item>
                        </Col>
                        {methodType === 'enterprise' ? (
                          <Col span={9}>
                            <Form.Item name="partner_position" label={t('Chức vụ')}>
                              <Input placeholder="Nhập chức vụ" />
                            </Form.Item>
                          </Col>
                        ) : (
                          <Col span={9}>
                            <Form.Item name="partner_email" label={t('Email')} validateTrigger={['onChange', 'onBlur']}>
                              <Input placeholder="Nhập email" />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                      {methodType === 'enterprise' && (
                        <>
                          <Row gutter={12}>
                            <Col span={10}>
                              <Form.Item
                                name="partner_company"
                                label={t('Công ty')}
                                className="require"
                                rules={NameCompanyRules}
                                validateTrigger={['onChange', 'onBlur']}
                              >
                                <Input placeholder="Nhập tên công ty" />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                name="partner_tax"
                                label={t('Mã số thuế')}
                                className="require"
                                rules={TaxRules}
                                validateTrigger={['onChange', 'onBlur']}
                              >
                                <Input placeholder="Nhập MST" />
                              </Form.Item>
                            </Col>
                            <Col span={9}>
                              <Form.Item
                                name="partner_email"
                                label={t('Email')}
                                className="require"
                                rules={validatorEmail}
                                validateTrigger={['onChange', 'onBlur']}
                              >
                                <Input placeholder="Nhập email công ty" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item name="partner_address" label={t('Địa chỉ')} className="require">
                                <Input placeholder="Nhập địa chỉ công ty" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name="depart_point"
                            className="form-item-name require"
                            label={t('Điểm xuất phát')}
                            rules={routePickerRules}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <Input
                              className="input-h36"
                              placeholder="Nhập điểm xuất phát"
                              style={{ width: '100%' }}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="arrive_point"
                            className="form-item-name require"
                            label={t('Điểm trả khách')}
                            rules={routeBackRules}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <Input
                              className="input-h36"
                              placeholder="Nhập điểm trả"
                              size="large"
                              style={{ width: '100%' }}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={20}>
                        <Col span={6}>
                          <Form.Item
                            name="contract_value"
                            label={t('Giá trị hợp đồng')}
                            className="require price"
                            rules={priceRules}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <Input suffix="VND " disabled />
                          </Form.Item>
                        </Col>
                        {listContractDetail?.payment_method === 'agent_balance' && (
                          <Col span={6}>
                            <Form.Item
                              name="commission_price"
                              label={t('Tổng tiền đại lý trả')}
                              className="booking-input-disable price"
                            >
                              <Input suffix="VND" disabled />
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={18}>
                          <Form.Item name="note" label={t('Ghi chú chuyến')}>
                            <Input placeholder="Nhập ghi chú" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Item name="payment_method" label={t('Phương thức thanh toán')} className="require">
                            <Radio.Group
                              options={
                                listContractDetail?.payment_method === 'agent_balance'
                                  ? agentContractPaidOptions
                                  : paymentContractOptions
                              }
                              onChange={onChangePayment}
                              value={paymentMethod}
                              buttonStyle="solid"
                              className="payment-checkbox-group contract"
                              disabled={true}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="d-flex"
                          style={
                            group === 'agent'
                              ? { columnGap: '11px', marginTop: '20px', marginLeft: '-30px' }
                              : { columnGap: '11px', marginTop: '20px', marginLeft: '4px' }
                          }
                        >
                          <Form.Item name="is_insurance" className="booking-input-disable mt-3">
                            <Switch disabled />
                          </Form.Item>
                          <>
                            <div className="mt-4" style={{ columnGap: '5px' }}>
                              <Typography.Text className="fw-500 fs-14">
                                {t(
                                  `Bảo hiểm chuyến đi (${
                                    listContractDetail?.policy?.insurance_policy?.percent
                                      ? `${listContractDetail?.policy?.insurance_policy?.percent}% giá vé`
                                      : `${formattedPrice(listContractDetail?.policy?.insurance_policy?.price)}đ/ghế`
                                  }) `,
                                )}
                              </Typography.Text>
                              <Tooltip title={'Xem chi tiết chính sách bảo hiểm'}>
                                <InfoCircleFilled
                                  style={{ color: '#F8B507' }}
                                  onClick={() => setOpenInfoInsurance(true)}
                                />
                              </Tooltip>
                            </div>
                          </>
                        </Col>
                      </Row>
                      <Row gutter={20} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Col>
                          <Typography className="fs-16 fw-600">{t('Danh sách hành khách')}</Typography>
                        </Col>
                        <Col>
                          <Form.Item name="is_represent">
                            <Checkbox checked={isRepresentChecked} onChange={onCheckboxChange}>
                              {t('Lấy thông tin người đại diện')}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="customer-list-contract">
                        <Col span={2}>
                          <Typography>{t('Ghế')}</Typography>
                        </Col>
                        <Col span={8}>
                          <Typography>{t('Họ và tên')}</Typography>
                        </Col>
                        <Col span={6} className="pl-9">
                          <Typography>{t('Số điện thoại')}</Typography>
                        </Col>
                        <Col span={8} className="pl-9">
                          <Typography>{t('Email')}</Typography>
                        </Col>
                      </Row>
                      <Form.List name={`contracts`}>
                        {(fields) => {
                          return (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <div key={key}>
                                  <Row gutter={18} className="mt-1">
                                    <Col span={2}>
                                      <Form.Item {...restField} name={[name, 'name_seat']} className="input-seat">
                                        <Input disabled />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item name={[name, 'name']} className="group-input color">
                                        <Input placeholder="Nhập họ và tên" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item name={[name, 'phone']} className={'btn-group-color'}>
                                        <Input placeholder="Nhập SĐT" />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item name={[name, 'email']}>
                                        <Input placeholder="Nhập email" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </>
                          );
                        }}
                      </Form.List>
                    </div>
                    <Row className="d-flex mt-3" style={{ justifyContent: 'space-between' }}>
                      <Col>
                        {listContractDetail?.status !== 'completed' && (
                          <Button
                            key="pdf-contract"
                            type="primary"
                            className="mw-164 h-btn-48 fw-700"
                            htmlType="button"
                            danger
                            style={{ backgroundColor: '#D81717' }}
                            onClick={() => setIsOpenModalCancelContract(true)}
                          >
                            <span>
                              <StopOutlined />
                              {' Hủy hợp đồng'}
                            </span>
                          </Button>
                        )}
                      </Col>
                      <Col>
                        <Button key="cancel" className=" w-160 h-btn-48 mr-16" onClick={() => modalHandleCancel()}>
                          {'Đóng'}
                        </Button>
                        <Button
                          key="export"
                          type="primary"
                          className="w-160 h-btn-48 btn-success"
                          htmlType="button"
                          onClick={() => setOpenModalPrint(true)}
                        >
                          {'Xem hợp đồng'}
                        </Button>
                        {listContractDetail?.status !== 'completed' && (
                          <Button key="ok" type="primary" className="w-160 h-btn-48 btn-success" htmlType="submit">
                            {'Lưu'}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
                <Row className="d-flex justify-content-end">
                  {listContractDetail?.status === 'canceled' && (
                    <Button key="cancel" className=" w-160 h-btn-48 mr-16" onClick={() => modalHandleCancel()}>
                      {'Trở lại'}
                    </Button>
                  )}
                </Row>
              </Card>
            </Spin>
          </Modal>
          <ModalCancelContract
            modalOpen={isOpenModalCancelContract}
            okText={t('Hủy hợp đồng')}
            cancelText={t('Trở lại')}
            modalTitle={t('Hủy hợp đồng')}
            onCancel={handleCancelContract}
            onOk={handleOkeContract}
            modalContent={t('Bạn đang thao tác hủy hợp đồng này, bạn chắc chắn muốn hủy?')}
            listContractDetail={listContractDetail}
          />
          <ModalPrintContract
            modalOpen={openModalPrint}
            onOk={() => false}
            okText="Lưu"
            cancelText="Đóng"
            onCancel={() => setOpenModalPrint(false)}
            accountUser={accountUser}
            listContractDetail={listContractDetail}
          />
          <ModalInfoInsurance
            modalOpen={openInfoInsurance}
            onCancel={() => setOpenInfoInsurance(false)}
            onOk={() => false}
            dataPolicy={dataPolicy}
          />
        </Card>
      </Spin>
    </Layout>
  );
}
export default ListContract;
